import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { Divider, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import SimplePopover from 'components/ConfirmDialog/SimplePopover';
import CreateFileDownloadLink from 'components/FileDownload/FileDowload';
import AttachmentConfrimBody from './AttachmentConfrimBody';

const AttachmentList = ({ attachments, searchPattern, getFile, hideEmpty = false, removeFile, ...props }) => {
	const intl = useIntl();
	const theme = useTheme();

	const [attachmentData, setAttachmentData] = useState(null);
	const getWithSearchPattern = () => {
		return attachments?.filter(attachment => {
			if (searchPattern && attachment.attachmentParentid !== searchPattern) return false;

			return true;
		});
	};

	useEffect(() => {
		const data = !searchPattern ? attachments : getWithSearchPattern();

		setAttachmentData(data);
	}, [attachments, searchPattern]); // eslint-disable-line react-hooks/exhaustive-deps

	const FileColumn = ({ fileName, timestamp, blobFileVersion, created, attachmentParentType }) => {
		return (
			<Box>
				<Typography
					sx={{
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{fileName}
				</Typography>
				<Stack direction='row' spacing={1}>
					<Typography
						sx={{
							fontSize: '0.8rem',
							fontStyle: 'italic',
							color: 'rgba(0,0,0, 0.6)',
						}}
					>
						{blobFileVersion && moment(timestamp).isBefore(blobFileVersion, 'year')
							? moment(blobFileVersion).format('D.M.yyyy HH:mm')
							: created && moment(timestamp).isBefore(created, 'year')
								? moment(created).format('D.M.yyyy HH:mm')
								: moment(timestamp).format('D.M.yyyy HH:mm')}
					</Typography>
					<ParentColumn {...{ attachmentParentType }} />
				</Stack>
			</Box>
		);
	};

	const ParentColumn = ({ attachmentParentType }) => {
		if (!attachmentParentType) return null;

		return (
			<Typography sx={{ fontSize: '0.8rem' }}>
				{intl.formatMessage({ id: `attachments.table.attachmentparenttype.${attachmentParentType}` })}
			</Typography>
		);
	};

	const AttachmentListItem = ({ attachment }) => {
		const { attachmentId, fileName, timestamp, blobFileVersion, created, attachmentParentType } = attachment;

		const [anchorEl, setAnchorEl] = useState(null);
		const [downloadId, setDownloadId] = useState(false);
		const [confirmOpen, setConfirmOpen] = useState(false);

		const getFileDownloadLink = async attachment => {
			const file = await getFile(attachment.attachmentId);

			setDownloadId(false); // set downloading false

			if (!file) {
				toast.error(<FormattedMessage id='attachment.nofile' />);
				return;
			}

			CreateFileDownloadLink(file, attachment.fileName);
		};

		const openPopper = (e, attachment) => {
			setAnchorEl(e.currentTarget);
			setConfirmOpen(attachment.attachmentId);
		};

		const handleClose = e => {
			setAnchorEl(null);
			setConfirmOpen(null);
			e.ignore = true;
		};

		const downloadFile = attachment => {
			setDownloadId(attachment.attachmentId); // set downloading id

			return getFileDownloadLink(attachment);
		};

		const clickDownloadFile = (e, attachment) => {
			e.ignore = true;
			if (downloadId) return;

			downloadFile(attachment);
		};

		return (
			<Box
				display='flex'
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					py: 1,
					px: 1,
					'&:hover': {
						cursor: 'pointer',
						backgroundColor: theme.palette.secondary.main,
					},
				}}
				onClick={e => {
					if (!e.ignore) openPopper(e, attachment);
				}}
			>
				<Box sx={{ maxWidth: 'calc(100% - 3.5rem)' }}>
					<FileColumn {...{ fileName, timestamp, blobFileVersion, created, attachmentParentType }} />
				</Box>
				<Box sx={{ mr: 0.5 }}>
					<IconButton
						aria-label='download'
						disabled={downloadId === attachmentId}
						color='primary'
						onClick={e => clickDownloadFile(e, attachment)}
					>
						<FileDownloadOutlinedIcon
							sx={{
								'&:hover': {
									transform: 'scale(1.2)',
								},
							}}
						/>
					</IconButton>
					<SimplePopover
						handleClose={handleClose}
						isopen={confirmOpen === attachmentId}
						anchorElement={anchorEl}
						cancelText={intl.formatMessage({ id: 'attachments.confirm.cancel' })}
						bodyText={<AttachmentConfrimBody attachment={attachment} />}
						attachmentId={attachmentId}
						attachmentsLoading={false}
						downloading={downloadId}
						downloadFile={e => clickDownloadFile(e, attachment)}
						removeFile={removeFile}
					/>
				</Box>
			</Box>
		);
	};

	const hasData = attachmentData?.length > 0;

	if (hideEmpty && !hasData) return null;

	if (attachments?.length === 0 || attachments?.length === 0) {
		return (
			<Box sx={{ fontSize: '0.9rem', color: 'rgba(0,0,0, 0.6)' }}>
				<FormattedMessage id='attachments.noattachments' />
			</Box>
		);
	}

	return (
		<Stack
			sx={{
				overflowY: 'auto',
				overflowX: 'hidden',
				maxHeight: '15.5rem',
				'&::-webkit-scrollbar': {
					width: '5px',
				},
				...props.sx,
			}}
			divider={<Divider orientation='horizontal' flexItem />}
		>
			{!hasData ? (
				<Box sx={{ fontSize: '0.875rem', color: '#000' }}>{intl.formatMessage({ id: 'attachments.table.nodata' })}</Box>
			) : (
				attachmentData?.map(attachment => <AttachmentListItem key={attachment.attachmentId} attachment={attachment} />)
			)}
		</Stack>
	);
};

export default AttachmentList;
