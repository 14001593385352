import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

/**
 * Example replacement: 
 * if (domNode.name === 'p') {
		return <div>{domNode.children}</div>;
	}
 */
export function replaceNode() { } // custom code for replacement

export const HtmlParserComponent = ({ html, options = {} }) => {
	if (!html) return null;

	const parsedHtml = HtmlParser(html, options);

	return parsedHtml;
};

const HtmlParser = (html, opts = {}) => {
	return parse(DOMPurify.sanitize(html), {
		// ...{
		//     replace: replaceNode,
		// },
		...opts,
	});
};

export default HtmlParser;
