import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { isNil } from 'lodash';
import { Company, CompanyCustomer } from 'types/dataTypes';

type FetchCompany = {
	companyId: string | null;
};

type FetchCompanyOnboarded = {
	companyId: string | null;
};

type UpdateCompany = {
	companyId: string | null;
	company: Partial<Company>;
};

type CreateCompany = {
	companyId: string | null;
	company: Partial<Company>;
};

type UpdateCompanyOnboarded = {
	companyId: string | null;
};

type FetchCompanyCustomer = {
	companyId: string | null;
};

const Tags = {
	Company: 'company',
	CompanyOnboarded: 'companyOnboarded',
};

const baseUrl = 'company';

export const companyV2Api = createApi({
	reducerPath: 'companyV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.Company, Tags.CompanyOnboarded],
	endpoints: builder => ({
		fetchCompany: builder.query<Company, FetchCompany>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.Company, id: params?.companyId ?? '' }];
			},
		}),
		updateCompany: builder.mutation<Company, UpdateCompany>({
			query: ({ companyId, company }) => {
				if (isNil(companyId) || isNil(company)) {
					throw new Error('parameter error');
				}
				return {
					method: 'PUT',
					url: `/update/${companyId}`,
					data: company,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.Company, id: params?.companyId ?? '' }];
			},
		}),
		createCompany: builder.mutation<Company, CreateCompany>({
			query: ({ companyId, company }) => {
				if (isNil(companyId) || isNil(company)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: '/create',
					data: company,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.Company, id: data?.companyId ?? '' }];
			},
			transformResponse: response => {
				return response?.company;
			},
		}),
		fetchCompanyOnboarded: builder.query<boolean, FetchCompanyOnboarded>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/update/${companyId}/markflowdone`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.CompanyOnboarded, id: params?.companyId ?? '' }];
			},
		}),
		updateCompanyOnboarded: builder.mutation<boolean, UpdateCompanyOnboarded>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'PUT',
					url: `/update/${companyId}/markflowdone`,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.CompanyOnboarded, id: params?.companyId ?? '' }];
			},
		}),
		fetchCompanyCustomer: builder.query<CompanyCustomer, FetchCompanyCustomer>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/companydata`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.CompanyOnboarded, id: params?.companyId ?? '' }];
			},
		}),
	}),
});

export const {
	useFetchCompanyQuery,
	useUpdateCompanyMutation,
	useCreateCompanyMutation,
	useFetchCompanyOnboardedQuery,
	useUpdateCompanyOnboardedMutation,
	useFetchCompanyCustomerQuery,
} = companyV2Api;
