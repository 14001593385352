import { Box, Typography } from '@mui/material';
import InstructionBox from 'components/Form/InstructionBox';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type WYSIWYGEditorProps = {
	title: string | undefined;
	value: string | undefined;
	defaultValue: string | undefined;
	name: string | undefined;
	placeholder: string | undefined;
	height: number | undefined;
	maxHeight?: number | undefined;
	noPaddings: boolean | undefined;
	light?: boolean | undefined;
	disabled?: boolean | undefined;
	menubar?: boolean | undefined;
	toolbar?: boolean | undefined;
	onChange: any;
	instructions: string | undefined | null;
	inline?: boolean | undefined;
	minHeight?: string | number | undefined;
	hasError?: boolean | undefined;
	fixedToolbar?: boolean | undefined;
	charactersLimit?: number | undefined;
	onBlurAction?: any;
};

const WYSIWYGEditor = ({
	onChange,
	value,
	onBlurAction,
	placeholder,
	name,
	disabled,
	charactersLimit,
	instructions,
	fixedToolbar = false,
}: WYSIWYGEditorProps) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [updated, setUpdated] = useState(false);
	const [length, setLength] = useState(0);

	const replaceDotsFromName = name?.replaceAll(/[\[\].]/g, '-');

	const onBlur = () => {
		if (onBlurAction) onBlurAction(value);

		setIsFocused(false);
	};

	useEffect(() => {
		if (!updated) {
			const defaultValue = value ? value : '';
			const blocksFromHtml = htmlToDraft(defaultValue);
			const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
			const newEditorState = EditorState.createWithContent(contentState);
			setEditorState(newEditorState);
			const plainText = contentState.getPlainText();
			setLength(plainText.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const getContentLength = () => {
		const contentState = editorState.getCurrentContent();
		const plainText = contentState.getPlainText();

		setLength(plainText.length);
	};

	const onEditorStateChange = (editorState: any) => {
		setUpdated(true);
		setEditorState(editorState);
		getContentLength();

		return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
	};

	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
		setIsFocused(true);
	};

	return (
		<>
			<Box
				id={`${replaceDotsFromName}-editor-toolbar`}
				className='editor'
				sx={{
					border: fixedToolbar ? '1px solid #ccc' : 'none',
					padding: fixedToolbar ? '1rem' : 0,
					'& .toolbar-class': {
						display: 'flex',
					},
					'& .hidden-class': {
						display: fixedToolbar ? 'flex' : 'none',
					},
				}}
			>
				<Editor
					readOnly={disabled}
					toolbarHidden={disabled}
					placeholder={placeholder}
					toolbarOnFocus={!fixedToolbar}
					onFocus={handleFocus}
					onBlur={onBlur}
					toolbarClassName={isFocused && !disabled ? 'toolbar-class' : 'hidden-class'}
					editorState={editorState}
					onEditorStateChange={onEditorStateChange}
				/>
			</Box>
			{!disabled && charactersLimit !== undefined && (
				<Typography align='right' sx={{ color: 'secondary.grey700', fontSize: '0.9rem', mt: 1 }}>
					<Typography component='span' sx={{ fontSize: '0.9rem', color: length > charactersLimit ? 'red' : 'secondary.grey700' }}>
						{length}
					</Typography>
					/{charactersLimit}
				</Typography>
			)}
			<InstructionBox value={instructions} />
		</>
	);
};

export default WYSIWYGEditor;
