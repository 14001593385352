import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

// @ts-ignore
const PanelTitle = styled(Typography, {
	shouldForwardProp: (prop: any) => prop !== 'color' && prop !== 'variant' && prop !== 'sx',
	overridesResolver: (props: any, styles: any) => [
		styles.root,
		props.color === 'primary' && styles.primary,
		props.color === 'secondary' && styles.secondary,
	],
})(({ theme }: any) => ({
	fontSize: '1.2rem',
	color: theme.palette.secondary.grey900,
	fontFamily: 'Poppins,sans-serif',
	fontWeight: 400,
}));

type PanelTitleItemProps = {
	id?: string | undefined;
	className?: string | undefined;
	variant?: any;
	sx?: any;
	children: any;
};

const PanelTitleItem = ({ id, className, variant = 'h2', sx = {}, children }: PanelTitleItemProps) => {
	return (
		<PanelTitle id={id} className={className} variant={variant} sx={sx}>
			{children}
		</PanelTitle>
	);
};

export default PanelTitleItem;
