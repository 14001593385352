import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { aiApi } from 'containers/AI/aiApi';
import { passwordV2Api } from 'containers/Account/passwordV2Api';
import { companyNotesV2Api } from 'containers/Admin/UsersCompany/companyNotesV2Api';
import { analyticsApi } from 'containers/Analytics/analyticsApi';
import { contractApi } from 'containers/App/contractApi';
import { businessPlanV2Api } from 'containers/BusinessPlan/businessPlanV2Api';
import { conversationsApi } from 'containers/Collaboration/collaborationApi';
import { companyV2Api } from 'containers/Company/companyV2Api';
import { companySearchV2Api } from 'containers/CompanySearch/companySearchV2Api';
import { customerSearchApi } from 'containers/CustomerData/customerSearchApi';
import { financialPlanV2Api } from 'containers/FinancialPlan/financialPlanV2Api';
import { instrumentAnalysisApi } from 'containers/Instruments/InstrumentAIAnalysis/instrumentAnalysisApi';
import { instrumentV2Api } from 'containers/Instruments/instrumentV2Api';
import { rfpCommonApi } from 'containers/Marketplace/rfpCommonApi';
import { rfpProfileApi } from 'containers/Marketplace/rfpProfileApi';
import { rfpProposalApi } from 'containers/Marketplace/rfpProposalApi';
import { rfpRequestApi } from 'containers/Marketplace/rfpRequestApi';
import { messagesApi } from 'containers/Messages/api';
import { projectsApi } from 'containers/Projects/projectRTKApi';
import { projectSupplementaryApi } from 'containers/Projects/projectSupplementaryApi';
import { registrationV2Api } from 'containers/Register/registrationV2Api';
import { scenarioV2Api } from 'containers/Scenario/scenarioV2Api';
import { stakeholderApi } from 'containers/Stakeholder/stakeholderApi';

import createReducer from 'reducers';
import history from 'utils/misc/history';

const configureStore = (initialState = {}, history) => {
	let composeEnhancers = compose;

	const middlewares = [
		thunk,
		routerMiddleware(history),
		conversationsApi.middleware,
		messagesApi.middleware,
		stakeholderApi.middleware,
		rfpCommonApi.middleware,
		rfpProfileApi.middleware,
		rfpRequestApi.middleware,
		rfpProposalApi.middleware,
		companyV2Api.middleware,
		aiApi.middleware,
		analyticsApi.middleware,
		projectSupplementaryApi.middleware,
		companySearchV2Api.middleware,
		scenarioV2Api.middleware,
		businessPlanV2Api.middleware,
		projectsApi.middleware,
		instrumentV2Api.middleware,
		passwordV2Api.middleware,
		registrationV2Api.middleware,
		financialPlanV2Api.middleware,
		companyNotesV2Api.middleware,
		contractApi.middleware,
		instrumentAnalysisApi.middleware,
		customerSearchApi.middleware,
	];

	// If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
	// eslint-disable-next-line no-undef
	if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
		if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
			composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
			// const { logger } = require('redux-logger');
			// middlewares.push(logger);
		}
	}

	// Create the store with two middlewares
	// 2. routerMiddleware: Syncs the location/URL path to the state

	const enhancers = [applyMiddleware(...middlewares)];

	const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

	// Extensions
	store.injectedReducers = {}; // Reducer registry

	// Make reducers hot reloadable
	if (module.hot) {
		module.hot.accept('./reducers', () => {
			store.replaceReducer(createReducer(store.injectedReducers));
		});
	}

	// TODO: basically replaces the whole reducer inject, but needs some logic not to create continuous store update from simple inject from redux component
	// store.asyncReducers = {};
	// store.injectReducer = (key, asyncReducer) => {
	//     store.asyncReducers[key] = asyncReducer;
	//     store.replaceReducer(createReducer(store.asyncReducers));
	// };

	return store;
};

export default configureStore({}, history);
