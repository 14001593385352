import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Skeleton,
	Typography,
} from '@mui/material';
import { isEmpty, isNil, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { RfpProposalItemCost } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { openPopover } from 'containers/Marketplace/popoverSlice';
import {
	useFetchRfpProposalItemCostsQuery,
	useRemoveRfpProposalItemCostMutation,
	useUpsertRfpProposalItemCostMutation,
} from 'containers/Marketplace/rfpProposalApi';

import RfpProposalItemCostTotalPrice from 'containers/Marketplace/proposal/RfpProposalItemCostTotalPrice';

type Props = {
	proposalId?: string | null;
	proposalItemId?: string | null;
	label?: string | null;
	helperText?: string | null;
} & Omit<FormControlProps, 'onChange'>;

const RfpProposalItemCostList = React.forwardRef<any, Props>(({ proposalId, proposalItemId, label, helperText, ...otherProps }, ref) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const {
		data: rfpProposalItemCosts,
		isLoading,
		isError: isRfpProposalItemCostsError,
	} = useFetchRfpProposalItemCostsQuery(
		{ companyId, proposalId, proposalItemId },
		{ skip: isNil(companyId) || isNil(proposalId) || isNil(proposalItemId) }
	);

	const [upsertRfpProposalItemCost, { isError: isUpsertError }] = useUpsertRfpProposalItemCostMutation();
	const [removeRfpProposalItemCost, { isError: isRemoveError }] = useRemoveRfpProposalItemCostMutation();

	return (
		<FormControl {...otherProps} ref={ref}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			{isRfpProposalItemCostsError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpProposalItemCosts.error.message' />
				</Alert>
			)}
			{isUpsertError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='mutation.error.title' />
					</AlertTitle>
					<FormattedMessage id='upsertRfpProposalItemCost.error.message' />
				</Alert>
			)}
			{isRemoveError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='mutation.error.title' />
					</AlertTitle>
					<FormattedMessage id='removeRfpProposalItemCost.error.message' />
				</Alert>
			)}
			{isLoading ? (
				<>
					<Skeleton height={60} />
					<Skeleton height={60} />
					<Skeleton height={60} />
					<Skeleton height={60} />
				</>
			) : (
				<List>
					{isEmpty(rfpProposalItemCosts) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpProposalItemCosts.empty' />
						</Typography>
					)}
					{map(rfpProposalItemCosts, rfpProposalItemCost => (
						<ListItem
							key={rfpProposalItemCost.rfpItemCostId}
							secondaryAction={
								<IconButton
									color='error'
									onClick={event => {
										dispatch(
											openPopover({
												name: 'ConfirmRemovePopover',
												data: {
													anchorEl: event.currentTarget,
													text: formatMessage({ id: 'rfpProposalItemCosts.confirmRemove' }),
													description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
													onSubmit: async () => {
														try {
															await removeRfpProposalItemCost({
																companyId,
																proposalId,
																proposalItemId,
																proposalItemCostId: rfpProposalItemCost.rfpItemCostId,
															}).unwrap();
															toast.success(
																formatMessage({ id: 'removeRfpProposalItemCost.success.message' })
															);
														} catch (error) {
															console.error(error);
														}
													},
												},
											})
										);
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
							disablePadding
						>
							<ListItemButton
								onClick={() => {
									dispatch(
										openDialog({
											name: 'UpsertRfpProposalItemCostDialog',
											data: {
												fieldValues: rfpProposalItemCost,
												onSubmit: async (fieldValues: Partial<RfpProposalItemCost>) => {
													try {
														await upsertRfpProposalItemCost({
															companyId,
															proposalId,
															proposalItemId,
															rfpProposalItemCost: fieldValues,
														}).unwrap();
														toast.success(formatMessage({ id: 'upsertRfpProposalItemCost.success.message' }));
													} catch (error) {
														console.error(error);
													}
												},
											},
										})
									);
								}}
							>
								<ListItemText
									primary={<FormattedMessage id={`rfpCostClass.${rfpProposalItemCost.costClass}`} />}
									secondary={
										<>
											<Typography component='span' variant='subtitle2' sx={{ mr: 1 }}>
												<FormattedMessage id={`rfpCostType.${rfpProposalItemCost.costType}`} />
											</Typography>
											<RfpProposalItemCostTotalPrice
												component='span'
												totalPrice={rfpProposalItemCost.totalPrice}
												totalPriceMax={rfpProposalItemCost.totalPriceMax}
											/>
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
			<Box>
				<Button
					size='small'
					variant='text'
					onClick={() => {
						dispatch(
							openDialog({
								name: 'UpsertRfpProposalItemCostDialog',
								data: {
									fieldValues: {},
									onSubmit: async (fieldValues: Partial<RfpProposalItemCost>) => {
										try {
											await upsertRfpProposalItemCost({
												companyId,
												proposalId,
												proposalItemId,
												rfpProposalItemCost: fieldValues,
											}).unwrap();
											toast.success(formatMessage({ id: 'upsertRfpProposalItemCost.success.message' }));
										} catch (error) {
											console.error(error);
										}
									},
								},
							})
						);
					}}
					startIcon={<AddIcon />}
				>
					<FormattedMessage id='rfpProposalItemCosts.add' />
				</Button>
			</Box>
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
});

export default RfpProposalItemCostList;
