import React from 'react';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';

const StyledBox = styled(Box, {
	shouldForwardProp: prop => prop !== 'sx',
})(({ theme }) => ({
	fontSize: '1rem',
	color: theme.palette.error.main,
}));

const FormError = ({ error, sx = {} }) => {
	const errorMsg = typeof error === 'string' ? error : error?.message;

	return error ? (
		<StyledBox className='FormError-root' sx={{ ...sx }}>
			{errorMsg}
		</StyledBox>
	) : (
		<></>
	);
};

export default FormError;
