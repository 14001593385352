import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

const CustomTooltip = withStyles(theme => ({
    tooltip: {
        padding: theme.spacing(1),
        border: '1px solid #ccc',
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        maxWidth: '400px',
    },
    // tooltipPlacementTop: {
    //     margin: theme.spacing(0.5),
    // },
}))(Tooltip);

export default CustomTooltip;
