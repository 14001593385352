import React, { memo, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useFieldArray, } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { FormControlWithoutLabel } from 'components/Form';
import InstrumentAutocomplete from 'containers/FinancialPlan/InstrumentModal/InstrumentAutocomplete';
import { FromControlledHiddenField } from 'components/Form';

const NestedInstrumentWrapper = ({ provided, isDraggingOver, nestIndex, instruments, fields, errors, control, remove, setValue }) => {
    return (
        <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
                bgcolor: isDraggingOver ? 'primary.secondary' : 'primary.white',
            }}
        >
            <NestedInstrumentList {...{ nestIndex, instruments, fields, errors, control, remove, setValue }} />
            {provided.placeholder}
        </Box>
    );
};

const NestedInstrumentList = memo(({ nestIndex, instruments, fields, errors, control, remove, setValue }) => {
    return (
        fields.map((field, index) => {
            const instrument = field;
            const error = errors?.categories && errors?.categories[nestIndex]?.instruments && errors?.categories[nestIndex]?.instruments[index]?.name;

            return <NestedInstrument key={field.id} id={field.id} {... { index, nestIndex, error, instrument, instruments, control, remove, setValue }} />;
        })
    );
});

const NestedInstrument = ({ id, index, nestIndex, error, instrument, instruments, control, remove, setValue }) => {
    const [renderError, setRenderError] = useState({});
    useEffect(() => {
        setRenderError(error);
    }, [setRenderError, error]);

    const setInstrument = (index, data) => {
        setValue(`categories[${nestIndex}].instruments[${index}]`, data, { shouldDirty: true, shouldValidate: true });
        setValue(`categories[${nestIndex}].instruments[${index}].instrumentId`, data.id);
        // clearErrors(); --> it does trigger
        // clearErrors(`categories[${nestIndex}].instruments[${index}].name`); --> it does not trigger for some reason

        if (error?.message) {
            setRenderError({}); // reset name error
        }
    };

    const getProvider = () => {
        const item = instruments.find(({ id }) => id === instrument.instrumentId);

        return item?.provider ?? '';
    };

    return (
        <Draggable draggableId={id} index={index}>
            {provided => {
                return (
                    <Stack
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        direction='row'
                        alignItems='center'
                        spacing={1}
                        sx={{
                            p: 1,
                            bgcolor: 'primary.secondary',
                            outline: '2px solid white',
                            outlineOffset: '-2px'
                        }}
                    >
                        <IconButton sx={{ p: 0 }} {...provided.dragHandleProps}>
                            <DragIndicatorIcon sx={{ mb: error?.message ? 2 : 0 }} />
                        </IconButton>
                        <FormControlWithoutLabel error={renderError}>
                            <FromControlledHiddenField
                                name={`categories[${nestIndex}].instruments[${index}].name`}
                                defaultValue={instrument.name ?? ''}
                                control={control}
                            />
                            <FromControlledHiddenField
                                name={`categories[${nestIndex}].instruments[${index}].provider`}
                                defaultValue={instrument.provider ?? getProvider()}
                                control={control}
                            />
                            <FromControlledHiddenField
                                name={`categories[${nestIndex}].instruments[${index}].instrumentId`}
                                defaultValue={instrument.instrumentId ?? ''}
                                control={control}
                            />
                            <InstrumentAutocomplete
                                variant='standard'
                                onChange={data => setInstrument(index, data)}
                                instrument={instrument}
                                instruments={instruments}
                                control={control}
                                noCreation={true}
                            />
                        </FormControlWithoutLabel>
                        <IconButton variant='text' color='error' onClick={() => remove(index)} >
                            <HighlightOffIcon sx={{ mb: error?.message ? 2 : 0 }} />
                        </IconButton>
                    </Stack>
                );
            }}
        </Draggable>
    );
};

const NestedInstruments = memo(forwardRef(({ nestIndex, category, control, errors, instruments, setValue, clearErrors }, ref) => {
    const intl = useIntl();

    const { fields, remove, append, move } = useFieldArray({
        control,
        name: `categories[${nestIndex}].instruments`
    });

    useImperativeHandle(ref, () => ({
        appendNested() {
            append({ name: '', instrumentId: '', provider: '' });
            clearErrors(`categories[${nestIndex}].instruments`);
        }
    }));

    const handleDragEnd = ({ destination, source }) => {
        try {
            if (!destination) { // item dropped outside the column
                return;
            }

            if (destination.droppableId === source.droppableId && destination.index === source.index) { // item has not been moved
                return;
            }

            move(source.index, destination.index);

            toast.success(intl.formatMessage({ id: 'instrument.order.success' }, { category }));
        } catch {
            toast.error(intl.formatMessage({ id: 'instrument.order.failed' }, { category }));
        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='nestedInstruments'>
                {(provided, { isDraggingOver }) => <NestedInstrumentWrapper {...{ provided, isDraggingOver, nestIndex, instruments, fields, errors, control, remove, setValue }} />}
            </Droppable>
        </DragDropContext>
    );
}));

export default NestedInstruments;