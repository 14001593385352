import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import TeamMemberAvatar from 'containers/BusinessPlan/TeamMemberPanel/TeamMemberCard/TeamMemberAvatar';

const useStyles = makeStyles(theme => ({
    nameContainer: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    name: {
        display: 'inline-block',
        fontSize: '1.1rem',
        color: theme.palette.secondary.grey900,
        '&:first-child': {
            paddingRight: theme.spacing(0.5),
        }
    },
    position: {
        marginTop: theme.spacing(0.5),
        fontSize: '0.9rem',
        color: theme.palette.secondary.grey700,
    },
    description: {
        fontSize: '0.9rem',
        color: theme.palette.secondary.grey600,
    },
    link: {
        fontSize: '0.9rem',
        color: theme.palette.secondary.grey600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const PrintTeamMember = ({ avatar, firstname, lastname, position, description, linkedInUrl }) => {
    const classes = useStyles();

    return (
        <Grid container wrap='nowrap' spacing={2} id='qwert'>
            <Grid item>
                <TeamMemberAvatar avatar={avatar} firstname={firstname} lastname={lastname} size={80} />
            </Grid>
            <Grid item sx={{ flex: 1, maxWidth: 'calc(100% - 96px)' }}>
                <Box className={classes.nameContainer}>
                    <Typography className={classes.name}>{firstname}</Typography>
                    <Typography className={classes.name}>{lastname}</Typography>
                </Box>
                <Typography className={classes.position}>{position}</Typography>
                <Typography className={classes.description}>{description}</Typography>
                {linkedInUrl &&
                    <Box>
                        <Link href={linkedInUrl} target='_blank'>
                            <Typography className={classes.link}>{linkedInUrl}</Typography>
                        </Link>
                    </Box>
                }
            </Grid>
        </Grid>
    );
};

export default PrintTeamMember;
