import React from 'react';
import { Helmet, } from 'react-helmet-async';

import UserList from 'containers/Admin/UsersCompany/UserList';

const UserListPage = () => {
    return (
        <>
            <Helmet>
                <title>GrantedAI - user details</title>
            </Helmet>
            <UserList />
        </>
    );
};

export default UserListPage;