import * as React from 'react';
import { map } from 'lodash';

import { Box, Pagination } from '@mui/material';

import { usePaginator } from './PaginationContext';
import { setPage } from './paginatorSlice';

export type ListItemType<T> = {
	data?: T;
} & IListItem;

export interface IListItem {
	data?: any;
}

type DataListProps<T> = {
	dataListItem: React.FunctionComponentElement<T>;
};

const DataList: React.FC<DataListProps<IListItem>> = ({ dataListItem }) => {
	const {
		state: { pagedData },
	} = usePaginator();

	return (
		<>
			{map(pagedData, (dataItem, index) => (
				<React.Fragment key={index}>{React.cloneElement(dataListItem, { data: dataItem })}</React.Fragment>
			))}
		</>
	);
};

const PageActions = () => {
	const {
		state: { page, rowsPerPage, data },
		dispatch,
	} = usePaginator();

	const pagecount = Math.ceil(data?.length / rowsPerPage);

	if (pagecount <= 1) return null;

	return (
		<Box sx={{ my: '1rem' }}>
			<Pagination count={pagecount} page={page} onChange={(_, val) => dispatch(setPage(val))} />
		</Box>
	);
};

export { DataList, PageActions };
