import React, { useState } from 'react';
import { useIntl, FormattedDate, FormattedTimeParts } from 'react-intl';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import InstrumentMessageDetails from './InstrumentMessageDetails';
import TimelineMessageContent from './TimelineMessageContent';

const useStyles = makeStyles(theme => ({
    item: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:before, &:after': {
            content: "''",
            position: 'absolute',
            left: theme.spacing(3.685),
            width: '1px',
            height: theme.spacing(4),
            backgroundColor: theme.palette.secondary.main,
        },
        '&:after': {
            height: '100%',
            top: 0,
        },
        '&:first-child:after': {
            top: theme.spacing(5),
        },
        '&:first-child:before': {
            top: theme.spacing(2),
            display: 'none',
        },
        '&:last-child:before': {
            top: 0,
        },
        '&:last-child:after': {
            display: 'none',
        },
        '&:hover': {
            //boxShadow: '-1px 0px 17px -7px rgba(0,0,0,0.75)',
            // boxShadow: `-1px 0px ${theme.palette.primary.main}`
        }
    },
    newItem: {
        '&:before': {
            top: 0,
            height: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    circle: {
        borderRadius: '50%',
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
        marginTop: theme.spacing(2),
        zIndex: 5,
    },
    circleGreen: {
        backgroundColor: theme.palette.success.main,
    },
    itemText: {
        margin: 0,
        paddingRight: theme.spacing(4),
        marginLeft: theme.spacing(2),
    },
    message: {
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    newMessage: {
        display: 'block',
        position: 'relative',
        right: theme.spacing(4),
        paddingRight: theme.spacing(3),
        color: theme.palette.primary.main,
    },
    newMessageTitle: {
        color: theme.palette.success.main,
        paddingBottom: theme.spacing(2),
    },
    newDate: {
        position: 'relative',
        right: theme.spacing(4),
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    buttonGreen: {
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.success.main,
    },
    accordionWrapper: {
        width: '100%',
    },
    accordion: {
        boxShadow: 'none',
    },
    accordionWhite: {
        backgroundColor: theme.palette.primary.white,
    },
    accordionSummary: {
        backgroundColor: theme.palette.primary.secondary,
        '&.Mui-expanded': {
            minHeight: theme.spacing(6),
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0.75rem 0',
        },
    },
    accordionDetails: {
        paddingBottom: 0,
        backgroundColor: theme.palette.primary.secondary,
    },
    icon: {
        color: theme.palette.primary.main,
    },
    iconGreen: {
        color: theme.palette.success.main,
    },
}));

const MessageItem = ({ index, rowKey, message, created, isRead, data, markMessageAsRead }) => {
    const classes = useStyles();
    const intl = useIntl();

    const [expanded, setExpanded] = useState(false);

    const handleChange = panel => (event, newExpanded) => setExpanded(newExpanded ? panel : false);

    const handleClick = () => {
        if (isRead) return;

        markMessageAsRead(rowKey);
    };

    const getDate = created => {
        const date = new Date(created);

        return (
            <>
                <FormattedDate value={date} />{' '}
                <FormattedTimeParts value={date}>
                    {parts => (
                        <>
                            {parts[0].value}:{parts[2].value}
                        </>
                    )}
                </FormattedTimeParts>
            </>
        );
    };

    const primary = !isRead ? (
        <Typography component='p' className={`${classes.message} ${classes.newMessageTitle}`}>
            {intl.formatMessage({ id: 'notification.message.one' }).toUpperCase()}
        </Typography>
    ) : (
        <Typography component='p' className={classes.message} gutterBottom>
            {intl.formatMessage({ id: `messages.type.${message}` })}
        </Typography>
    );

    const secondary = !isRead ? (
        <>
            <Typography component='span' variant='body2' className={classes.newMessage} gutterBottom>
                {intl.formatMessage({ id: `messages.type.${message}` })}
            </Typography>
            <Typography component='span' className={classes.newDate}>
                {getDate(created)}
            </Typography>
        </>
    ) : (
        <Typography component='p' color='primary'>
            {getDate(created)}
        </Typography>
    );

    const messageDetails = () => {
        switch (message) {
            case 'NewInstrumentAvailable':
                return <InstrumentMessageDetails data={data} />;
            case 'TimelinePublished':
                return <TimelineMessageContent />;
            default:
                return null;
        }
    };

    return (
        <ListItem
            alignItems='flex-start'
            className={`${classes.item} ${!isRead && classes.newItem}`}
            style={{ backgroundColor: !isRead && '#fff' }}
        >
            <Box>
                <Box className={`${classes.circle} ${!isRead && classes.circleGreen}`} />
            </Box>
            <Box className={classes.accordionWrapper}>
                <Accordion
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                    square
                    TransitionProps={{ unmountOnExit: true }}
                    className={classes.accordion}
                >
                    <AccordionSummary
                        aria-controls={`${index}-message`}
                        expandIcon={<ExpandMoreIcon fontSize='large' className={`${isRead ? classes.icon : classes.iconGreen}`} />}
                        onClick={handleClick}
                        className={classes.accordionSummary}
                        style={{ backgroundColor: !isRead && '#fff' }}
                    >
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <ListItemText primary={primary} secondary={secondary} className={classes.itemText} />
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                        className={classes.accordionDetails}
                        style={{ backgroundColor: !isRead && '#fff' }}
                    >
                        {messageDetails()}
                    </AccordionDetails>
                </Accordion>
            </Box>
        </ListItem>
    );
};

export default MessageItem;
