import CancelIcon from '@mui/icons-material/Cancel';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import ContentLoaderContainer from 'components/Containers/ContentLoaderContainer';
import FormControlledInput from 'components/Form/FormControlledInput';
import { storeCustomerInfo } from 'containers/Advanced/slices';

import { getDirtyKeys } from 'components/Form/utils/utils';

const CustomerForm = ({ data, loading, customerId }) => {
	const intl = useIntl();
	const theme = useTheme();
	const dispatch = useDispatch();

	const { control, reset, handleSubmit, formState: { dirtyFields, errors } } = useForm({
		defaultValues:
		{
			data,
		},
	});

	const onSubmit = async (data) => {
		data.rowKey = customerId;
		const success = await dispatch(storeCustomerInfo(data));

		if (success) {
			toast.success(intl.formatMessage({ id: 'advanced.customer.notes.success' }));
			reset(success);

			return;
		}

		toast.error(intl.formatMessage({ id: 'advanced.customer.notes.fail' }));
	};

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	return (
		<ContentLoaderContainer loading={loading}>
			<form onSubmit={handleSubmit(data => onSubmit(data))}>
				<FormControlledInput
					defaultValue={data?.notes ?? ''}
					name='notes'
					control={control}
					multiline
					rows={5}
					error={errors?.notes}
				/>
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					spacing={2}
				>
					{isDirty && <>
						<IconButton onClick={reset}>
							<CancelIcon
								sx={{
									borderRadius: '50%',
									'&:hover': {
										color: theme.palette.error.main,
									}
								}}
							/>
						</IconButton>
						<IconButton onClick={handleSubmit(data => onSubmit(data))}>
							<CloudDoneIcon sx={{
								borderRadius: '50%',
								'&:hover': {
									color: theme.palette.success.main,
								}
							}} />
						</IconButton>
					</>}
				</Stack>
			</form>
		</ContentLoaderContainer>
	);
};

export default CustomerForm;
