import UpdateIcon from '@mui/icons-material/Update';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

import InstrumentToChat from 'containers/Instruments/InstrumentList/InstrumentItem/InstrumentToChat';

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: '2rem',
	},
	message: {
		fontSize: '1.5rem',
		width: '100%',
	},
	messageSmall: {
		fontSize: '1rem',
	},
	icon: {
		paddingRight: '1rem',
		fontSize: '5rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '4rem',
			textAlign: 'center',
			paddingRight: 0,
			paddingTop: '1rem',
		},
	},
	button: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.primary.white,
	},
}));

const License = () => {
	const classes = useStyles();
	const intl = useIntl();

	return (
		<Grid container justifyContent='center' alignItems='center' className={classes.root}>
			<Grid item xs={11} sm={11} md={8} lg={6} xl={6}>
				<Grid container justifyContent='center' alignItems='center'>
					<Grid item xs={12} sm={2}>
						<Typography color='primary' gutterBottom className={classes.message}>
							<UpdateIcon className={classes.icon} />
						</Typography>
					</Grid>
					<Grid item xs={10}>
						<Typography color='primary' gutterBottom className={classes.message}>
							{intl.formatMessage({ id: 'confirm.update.license.notification' })}
						</Typography>
						<Typography color='primary' gutterBottom className={`${classes.message} ${classes.messageSmall}`}>
							{intl.formatMessage({ id: 'confirm.update.license.notification.description' })}
						</Typography>
						<Typography
							color='primary'
							gutterBottom
							className={`${classes.message} ${classes.messageSmall}`}
							sx={{ mt: '1rem' }}
						>
							<InstrumentToChat />
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default License;
