import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { isContributorToken } from 'utils/auth/token';
import { getCompanyId } from 'utils/auth/company';

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 64px - 164px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 64px - 134px)',
        },
    },
    message: {
        fontSize: '1.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
        },
    },
    box: {
        height: '4rem',
        width: '4rem',
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            height: '5.25rem',
            width: '5.25rem',
            marginTop: theme.spacing(3),
        },
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& svg': {
            fontSize: '3rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '4rem',
            },
        },
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.secondary.main,
            cursor: 'pointer',
        },
    },
    home: {
        margin: '-0.75rem',
        fontSize: '1rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        },
    },
}));

const NotFound = ({ profile = false }) => {
    const history = useHistory();
    const intl = useIntl();
    const classes = useStyles();

    const isContibutor = isContributorToken();
    const businessId = getCompanyId();

    const redirectToHome = () => history.push(isContibutor ? `/company/${businessId}` : '/');

    return (
        <Grid container direction='column' justifyContent='center' alignItems='center' className={classes.root}>
            <Grid item>
                <Typography variant='h1' color='primary' className={classes.message}>
                    {intl.formatMessage({ id: '404.message.sorry' })}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h1' color='primary' className={classes.message}>
                    {intl.formatMessage({ id: '404.message.not.found' })}
                </Typography>
            </Grid>
            {!profile && (
                <Grid item>
                    <Box textAlign='center' onClick={redirectToHome} className={classes.box}>
                        <HomeIcon color='primary' />
                        <Typography color='primary' className={classes.home}>
                            {intl.formatMessage({ id: 'common.home' })}
                        </Typography>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default NotFound;
