import * as yup from 'yup';
import moment from 'moment';

const schema = (intl: any) => {
	return yup.object().shape({
		type: yup
			.string()
			.required(`${intl.formatMessage({ id: 'license.form.type' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
		salesPerson: yup
			.string()
			.required(`${intl.formatMessage({ id: 'license.form.salesPerson' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
		startDate: yup
			.date()
			.required(`${intl.formatMessage({ id: 'license.form.startDate' })} ${intl.formatMessage({ id: 'message.isRequired' })}`)
			.nullable()
			.default(null)
			.typeError(
				`${intl.formatMessage({ id: 'license.form.startDate' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			),
		endDate: yup
			.date()
			.nullable()
			.default(null)
			.test('is-greater', intl.formatMessage({ id: 'license.endTime.mindate' }), (value, { parent }) => {
				const { startDate } = parent;

				if (!startDate || !value) return true; // if either one is false no need for validation

				const start = moment(startDate);

				return moment(value, 'dd.MM.yyyy').isSameOrAfter(moment(start, 'dd.MM.yyyy'));
			})
			.test('temporary-contract', intl.formatMessage({ id: 'license.form.endTime.contractType' }), (value, { parent }) => {
				const { contractType } = parent;

				if (contractType === 'temporary' && !value) return false; // if either one is false no need for validation

				return true;
			}),
		contractType: yup
			.string()
			.required(`${intl.formatMessage({ id: 'license.form.contractType' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
		contractPeriod: yup
			.string()
			.required(
				`${intl.formatMessage({ id: 'license.form.contractPeriodType' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
			),
		contractPeriodMonth: yup
			.number()
			.min(1, '>=1')
			.required(
				`${intl.formatMessage({ id: 'license.form.contractPeriodMonth' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
			),
		contractBillingPeriod: yup.string().required(
			`${intl.formatMessage({ id: 'license.form.contractBillingPeriodType' })} ${intl.formatMessage({
				id: 'message.isRequired',
			})}`
		),
		contractBillingPeriodMonth: yup
			.number()
			.min(1, '>=1')
			.required(
				`${intl.formatMessage({ id: 'license.form.billingPeriodMonthlyDuration' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			),
		contractMonetaryValue: yup
			.number()
			.required(
				`${intl.formatMessage({ id: 'license.form.contractMonetaryValue' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
			),
	});
};

export default schema;
