import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { Collapse, Grid, Skeleton } from '@mui/material';

import FormControlledDatepicker from 'components/Form/FormControlledDatepicker';
import FormControlledInput from 'components/Form/FormControlledInput';
import FormControlledSelect from 'components/Form/FormControlledSelect';
import { useSaveShareLinkMutation } from 'containers/Stakeholder/stakeholderApi';
import { compact, map, reverse, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { getCompanyId } from 'utils/auth/company';
import RecipientList from './RecipientList/RecipientList';
import ShareTransferList from './ShareTransferList/ShareTransferList';
import { getSchema } from './schema';

const ShareLinkForm = forwardRef(({ sharelink = {} }, formRef) => {
	const intl = useIntl();

	const schema = getSchema(intl);

	const projectsState = useSelector(state => state?.kanban?.projects);
	const isProjectsLoading = projectsState?.loading;
	const projects = reverse(sortBy(compact(projectsState, 'timestamp')));

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: useMemo(() => sharelink, [sharelink]),
		resolver: yupResolver(schema),
	});

	const sharedData = watch('sharedData');

	const [saveLinkMutation] = useSaveShareLinkMutation();

	const saveLink = async data => {
		const saveResponse = await saveLinkMutation({ companyId: getCompanyId(), linkData: data });
		if (saveResponse?.data?.rowKey) {
			toast.success(intl.formatMessage({ id: 'stakeholder.form.link.create.success' }));
			return;
		}
		toast.error(intl.formatMessage({ id: 'stakeholder.form.link.create.error' }));
	};

	let today = new Date();
	let tomorrow = new Date();
	tomorrow.setDate(today.getDate() + 1);

	const fixedItems = useMemo(() => {
		return [
			{
				key: 'CompanyData',
				value: 'CompanyData',
				title: intl.formatMessage({ id: 'stakeholder.form.sharedData.CompanyData' }),
			},
			{
				key: 'Scenario',
				value: 'Scenario',
				title: intl.formatMessage({ id: 'stakeholder.form.sharedData.Scenario' })
			},
			{ key: 'Score', value: 'Score', title: intl.formatMessage({ id: 'stakeholder.form.sharedData.Score' }) },
			{
				key: 'BusinessPlan',
				value: 'BusinessPlan',
				title: intl.formatMessage({ id: 'stakeholder.form.sharedData.BusinessPlan' }),
			},
			{
				key: 'Instruments',
				value: 'Instruments',
				title: intl.formatMessage({ id: 'stakeholder.form.sharedData.Instruments' }),
			},
		];
	}, [intl]);

	const items = useMemo(() => {
		return [
			{
				key: 'Analysis',
				value: 'Analysis',
				title: intl.formatMessage({ id: 'stakeholder.form.sharedData.Analysis' }),
			},
			{
				key: 'Projects',
				value: 'Projects',
				title: intl.formatMessage({ id: 'stakeholder.form.sharedData.Projects' }),
			},
			{
				key: 'Project',
				value: 'Project',
				title: intl.formatMessage({ id: 'stakeholder.form.sharedData.Project' }),
			},
			{
				key: 'FinancialPlan',
				dependency: 'Projects',
				value: 'FinancialPlan',
				title: intl.formatMessage({ id: 'stakeholder.form.sharedData.FinancialPlan' }),
			},
		];
	}, [intl]);

	const passSelected = useCallback(
		selected => {
			setValue(
				'sharedData',
				selected?.map(item => item.key)
			);
		},
		[setValue]
	);

	return (
		<form ref={formRef} onSubmit={handleSubmit(saveLink)}>
			<Grid container spacing={4} sx={{ padding: '2rem' }}>
				<Grid item xs={12}>
					<FormControlledInput
						defaultValue={sharelink?.sharelinkName ?? null}
						name='sharelinkName'
						control={control}
						error={errors?.sharelinkName?.message}
						label={intl.formatMessage({ id: 'stakeholder.form.sharelinkName' })}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlledDatepicker
						defaultValue={sharelink?.expires ?? null}
						name='expires'
						control={control}
						error={errors?.expires?.message}
						label={intl.formatMessage({ id: 'stakeholder.form.expires' })}
						minDate={tomorrow}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlledSelect
						defaultValue={sharelink?.contactRequestType ?? 'email'}
						disabled={true}
						name='contactRequestType'
						control={control}
						error={errors?.contactRequestType?.message}
						label={intl.formatMessage({ id: 'stakeholder.form.type' })}
						options={[
							{
								value: 'email',
								label: intl.formatMessage({ id: 'stakeholder.form.type.email' }),
							},
						]}
					/>
				</Grid>
				<Grid item xs={12}>
					<ShareTransferList
						title={intl.formatMessage({ id: 'stakeholder.form.transfer.title' })}
						option={true}
						passSelected={passSelected}
						items={items}
						fixedItems={fixedItems}
					/>
				</Grid>
				<Grid item xs={12}>
					<Collapse in={sharedData?.includes('Project')}>
						{isProjectsLoading ? (
							<Skeleton variant='rectangular' height={64} />
						) : (
							<FormControlledSelect
								defaultValue={sharelink?.project ?? ''}
								name='project'
								control={control}
								error={errors?.project?.message}
								label={intl.formatMessage({ id: 'stakeholder.form.project' })}
								options={map(projects, project => ({ label: project?.projectName, value: project?.projectId }))}
							/>
						)}
					</Collapse>
				</Grid>
			</Grid>
			<RecipientList {...{ control, errors }} />
		</form>
	);
});

export default ShareLinkForm;
