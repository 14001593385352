import React from 'react';
import { Box } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

const useStyles = makeStyles(() => ({
    cropped: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const LeftRightContainer = styled(Box)(({ alignItems }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: alignItems ? alignItems : 'center', // TODO: check effect on other
    width: '100%'
}));

const Right = styled(Box)(() => ({
    marginLeft: 'auto',
}));

export default function ({ left, right, alignItems, cropped = false, ...other }) {
    const classes = useStyles();

    return (
        <LeftRightContainer alignItems={alignItems} {...other}>
            <Box className={cropped && classes.cropped}>{left}</Box>
            <Right>{right}</Right>
        </LeftRightContainer>
    );
}