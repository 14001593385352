import { yupResolver } from '@hookform/resolvers/yup';
import { Check as CheckIcon } from '@mui/icons-material';
import { Box, Link, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import devices from 'assets/images/devices.png';

import { FreemiumAccount } from 'types/dataTypes';

import { useRegisterFreemiumAccountMutation } from 'containers/Register/registrationV2Api';

import Button from 'components/Button/Button';
import DataPrivacyStatementDialog from 'containers/Register/DataPrivacyStatementDialog';
import RegisterForm from 'containers/Register/RegisterForm';
import Logo from 'icons/Logo';

type FieldValues = Partial<FreemiumAccount>;

const Register: React.FC = () => {
	const history = useHistory();

	const { formatMessage } = useIntl();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const [registerFreemiumAccount] = useRegisterFreemiumAccountMutation();

	const defaultValues = React.useMemo(() => {
		return {
			firstName: '',
			lastName: '',
			email: '',
			companyName: '',
			businessId: '',
			phoneNumber: '',
			internationalization: 'fi-FI',
			interests: '',
			haveAcceptedDataPrivacyStatement: false,
			quideArea: '',
		};
	}, []);

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required(),
		lastName: Yup.string().required(),
		email: Yup.string().email().required(),
		phoneNumber: Yup.string().phoneNumber().required(),
		companyName: Yup.string().required(),
		businessId: Yup.string().nullable(),
		haveAcceptedDataPrivacyStatement: Yup.boolean()
			.oneOf([true], formatMessage({ id: 'register.form.haveAcceptedDataPrivacyStatement.required' }))
			.required(formatMessage({ id: 'register.form.haveAcceptedDataPrivacyStatement.required' })),
	});

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema),
	});

	const handleFormSubmit = React.useCallback(
		async (fieldValues: FieldValues) => {
			try {
				const captchaToken = await executeRecaptcha?.('register');
				if (isNil(captchaToken)) {
					return toast.error(<FormattedMessage id='register.error.captcha' />);
				}
				await registerFreemiumAccount({ account: fieldValues, captchaToken }).unwrap();
				toast.success(<FormattedMessage id='register.success' />);
				history.push('/login');
			} catch (error: any) {
				if (error.status === 409) {
					return toast.error(<FormattedMessage id='register.error.email' />);
				}
				toast.error(<FormattedMessage id='register.error' />);
			}
		},
		[executeRecaptcha, registerFreemiumAccount, history]
	);

	return (
		<>
			<DataPrivacyStatementDialog />
			<Stack
				direction={{ xs: 'column', md: 'row' }}
				spacing={2}
				sx={{ minHeight: { xs: '100rem', md: '100%' }, padding: '1.143rem', backgroundColor: theme => theme.palette.primary.main }}
				useFlexGap
			>
				<Stack direction='column' spacing={2} sx={{ flex: 1, padding: '2.286rem', backgroundColor: '#3C2640' }} useFlexGap>
					<Logo />
					<Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Stack direction='column' spacing={2} sx={{ width: '100%', maxWidth: '30rem' }} useFlexGap>
							<Typography
								variant='h1'
								sx={{
									color: theme => theme.palette.primary.contrastText,
									fontSize: '1.714rem',
									fontWeight: 700,
									lineHeight: 1.3,
									textAlign: 'center',
								}}
							>
								<FormattedMessage id='register.title' />
							</Typography>
							<Box
								component='img'
								src={devices}
								sx={{ display: 'block', width: '274px', height: '274px', margin: '0 auto' }}
							/>
							<Stack direction='column' spacing={2} sx={{ color: theme => theme.palette.primary.contrastText }}>
								<Stack direction='row' spacing={2}>
									<CheckIcon />
									<Typography>
										<FormattedMessage id='register.benefits.1.title' />
									</Typography>
								</Stack>
								<Stack direction='row' spacing={2}>
									<CheckIcon />
									<Typography>
										<FormattedMessage id='register.benefits.2.title' />
									</Typography>
								</Stack>
								<Stack direction='row' spacing={2}>
									<CheckIcon />
									<Typography>
										<FormattedMessage id='register.benefits.3.title' />
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Box>
				</Stack>
				<Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Stack direction='column' spacing={2} sx={{ width: '100%', maxWidth: '30rem' }} useFlexGap>
						<RegisterForm id='register-form' control={control} errors={errors} setValue={setValue} />
						{/* @ts-ignore */}
						<Button
							color='success'
							sx={{ p: '0.85714rem 1.21429rem', lineHeight: '1.28571rem', fontSize: '1.14286rem' }}
							loading={isSubmitting}
							onClick={handleSubmit(handleFormSubmit)}
							fullWidth
						>
							<FormattedMessage id='register.submit' />
						</Button>
						<Typography sx={{ color: theme => theme.palette.primary.contrastText }}>
							<FormattedMessage id='register.alreadyRegistered' />
							<Link component={RouterLink} to='/login' sx={{ color: 'inherit', fontWeight: 600, ml: '0.5rem' }}>
								<FormattedMessage id='register.login' />
							</Link>
						</Typography>
						<Box id='google-recaptcha' sx={{ display: 'flex', justifyContent: 'flex-start', visibility: 'hidden' }} />
						<small style={{ color: '#fff' }}>
							{formatMessage({ id: 'register.google.captcha' })}&nbsp;
							<a target='_blank' style={{ color: '#fff' }} href='https://policies.google.com/privacy?hl=fi' rel='noreferrer'>
								{formatMessage({ id: 'register.google.captcha.privacy-link' })}
							</a>
							{formatMessage({ id: 'register.google.captcha.and' })}
							<a target='_blank' style={{ color: '#fff' }} href='https://policies.google.com/terms?hl=fi' rel='noreferrer'>
								{formatMessage({ id: 'register.google.captcha.terms-link' })}
							</a>
						</small>
					</Stack>
				</Box>
			</Stack>
		</>
	);
};

export default Register;
