import { Skeleton, Stack } from '@mui/material';
import { useGetProjectDataQuery } from 'containers/Stakeholder/stakeholderApi';
import DeminimisWidget from 'views/Dashboard/DeminimisWidget';

export const DeminimisSkeleton = () => {
	return (
		<Stack direction='row' sx={{ mb: '1.5rem' }}>
			<Skeleton variant='rectangular' height={100} sx={{ width: '25%' }} />
			<Skeleton variant='rectangular' height={100} sx={{ ml: '20px', width: '25%' }} />
			<Skeleton variant='rectangular' height={100} sx={{ ml: '20px', width: '25%' }} />
			<Skeleton variant='rectangular' height={100} sx={{ ml: '20px', width: '25%' }} />
		</Stack>
	);
};

const DeminimisCounter = ({ linkId }) => {
	const {
		data: projectsData,
		isFetching: projectsIsFetching,
		isLoading: projectsIsLoading,
		isSuccess: projectsIsSuccess,
	} = useGetProjectDataQuery(linkId);

	if (projectsIsFetching || projectsIsLoading) return <DeminimisSkeleton />;

	if (!projectsIsSuccess) return null;

	return (
		<>
			<DeminimisWidget projects={projectsData?.projects} sx={{ mb: '1.5rem' }} />
		</>
	);
};

export default DeminimisCounter;
