import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button } from '@mui/material';
import CustomButton from 'components/Button/Button';
import NotFoundContainer from 'components/Containers/NotFoundContainer';
import MessageNoItem from 'components/MessageNoItem';

const NoInstruments = ({ hasInstruments, renderInstruments, phrase, profile, action, clearFilters }) => {
	const intl = useIntl();

	if (hasInstruments && renderInstruments?.length === 0 && phrase) {
		return (
			<NotFoundContainer>
				<Typography align='center' sx={{ mt: 2, color: 'secondary.grey600' }}>
					{intl.formatMessage({ id: 'filter.no.items' })}
				</Typography>
			</NotFoundContainer>
		);
	}
	if (hasInstruments && renderInstruments?.length === 0 && !phrase) {
		return (
			<NotFoundContainer>
				<Typography align='center' sx={{ mt: 2, color: 'secondary.grey600' }}>
					{intl.formatMessage({ id: 'instrument.no.instruments.filter' })}
				</Typography>
				<Button endIcon={<HighlightOffIcon />} variant='contained' color='success' onClick={clearFilters} sx={{ mt: '1rem' }}>
					{intl.formatMessage({ id: 'button.remove.filters' })}
				</Button>
			</NotFoundContainer>
		);
	}

	return (
		<>
			<MessageNoItem messageId={profile ? 'instrument.data.notfound.short' : 'instrument.data.notfound.complete.profile'} />
			{!profile && (
				<CustomButton color='success' mt='0.5rem' onClick={action}>
					{intl.formatMessage({ id: 'instrument.data.notfound.profile.button' })}
				</CustomButton>
			)}
		</>
	);
};
export default NoInstruments;
