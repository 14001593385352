import { yupResolver } from '@hookform/resolvers/yup';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import ModalDialog from 'components/Dialog/ModalDialog';
import TeamMemberForm from './TeamMemberForm';

import { getDirtyKeys } from 'components/Form/utils/utils';
import { createTeamMember, removeTeamMember, updateTeamMember } from '../../slices';

const TeamMemberModal = ({ teamMember, open, setOpen, readonly }) => {
	const intl = useIntl();
	const formRef = useRef();
	const dispatch = useDispatch();

	const schema = yup.object().shape({
		firstname: yup
			.string()
			.required(`${intl.formatMessage({ id: 'business.plan.form.firstname' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
		lastname: yup
			.string()
			.required(`${intl.formatMessage({ id: 'business.plan.form.lastname' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
		position: yup
			.string()
			.required(`${intl.formatMessage({ id: 'business.plan.form.position' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
		description: yup
			.string()
			.required(
				`${intl.formatMessage({ id: 'business.plan.form.description' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
			),
		linkedInUrl: yup
			.string()
			.url(intl.formatMessage({ id: 'message.correct.url' }))
			.nullable(),
	});

	const {
		handleSubmit,
		control,
		register,
		unregister,
		reset,
		setValue,
		formState: { dirtyFields, errors },
	} = useForm({
		defaultValues: useMemo(() => {
			return teamMember;
		}, [teamMember]),
		resolver: yupResolver(schema),
	});

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	useEffect(() => {
		register('avatar');

		return () => {
			unregister('avatar');
		};
	}, [register, unregister]);

	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const closeModal = () => {
		if (dirtyFields && Object.keys(dirtyFields)?.length > 0) {
			setIsConfirmOpen(true);
		} else {
			setOpen(false);
		}
	};

	const closeConfirm = () => setIsConfirmOpen(false);

	const closeConfirmAndModal = () => {
		setIsConfirmOpen(false);
		setOpen(false);
		reset();
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmPopperOpen, setConfirmPopperOpen] = useState(false);

	const confirm = e => {
		setConfirmPopperOpen(true);
		setAnchorEl(e.currentTarget);
	};

	const closeConfirmPopper = () => {
		setAnchorEl(null);
		setConfirmPopperOpen(false);
	};

	const onSubmit = async data => {
		setSaveLoading(true);

		const success = data.memberId ? await dispatch(updateTeamMember(data)) : await dispatch(createTeamMember(data));

		setSaveLoading(false);

		if (success) {
			reset(data);
			toast.success(intl.formatMessage({ id: 'business.plan.member.saving.successed' }));
		} else {
			toast.error(intl.formatMessage({ id: 'business.plan.membe.saving.failed' }));
		}

		reset();
		setOpen(false);
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const handleRemove = async () => {
		setDeleteLoading(true);
		const success = await dispatch(removeTeamMember(teamMember.memberId));
		setDeleteLoading(false);

		if (success) {
			toast.success(intl.formatMessage({ id: 'business.plan.member.delete.successed' }));
		} else {
			toast.error(intl.formatMessage({ id: 'business.plan.member.delete.failed' }));
		}

		setOpen(false);
	};

	return (
		<>
			<ModalDialog
				onClose={closeModal}
				open={open}
				title={
					teamMember?.memberId
						? intl.formatMessage({ id: 'business.plan.modal.title.member.update' })
						: intl.formatMessage({ id: 'business.plan.modal.title.member' })
				}
				actions={
					<LeftRightContainer
						left={<MuiButton onClick={closeModal}>{intl.formatMessage({ id: 'common.close' })}</MuiButton>}
						right={
							<Grid container spacing={2} alignItems='center'>
								{teamMember?.memberId && !readonly && (
									<Grid item>
										<Button
											disabled={deleteLoading}
											color='error'
											onClick={e => confirm(e)}
											startIcon={<DeleteOutlinedIcon />}
										>
											{intl.formatMessage({ id: 'common.delete' })}
											{deleteLoading && <ButtonSpinner />}
										</Button>
									</Grid>
								)}
								<Grid item>
									{!readonly && (
										<Button disabled={saveLoading || !isDirty} color='success' onClick={submitForm}>
											{intl.formatMessage({ id: 'shared.save' })}
											{saveLoading && <ButtonSpinner />}
										</Button>
									)}
								</Grid>
							</Grid>
						}
					/>
				}
			>
				<Box sx={{ p: 3 }}>
					<TeamMemberForm
						ref={formRef}
						control={control}
						errors={errors}
						teamMember={teamMember}
						onSubmit={onSubmit}
						handleSubmit={handleSubmit}
						setValue={setValue}
						readonly={readonly}
					/>
				</Box>
			</ModalDialog>
			<ConfirmUnsaved
				open={isConfirmOpen}
				handleCancel={closeConfirm}
				handleConfirm={closeConfirmAndModal}
				dirtyFields={dirtyFields}
				translation='business.plan.form'
			/>
			<ConfirmPopper
				confirmAction={handleRemove}
				handleClose={closeConfirmPopper}
				isopen={confirmPopperOpen}
				anchorElement={anchorEl}
				confirmText={intl.formatMessage({ id: 'business.plan.member.delete.confirmText' })}
				cancelText={intl.formatMessage({ id: 'business.plan.member.delete.cancelText' })}
				confirmBodyText={intl.formatMessage({ id: 'business.plan.member.delete.confirmBodyText' })}
			/>
		</>
	);
};

export default TeamMemberModal;
