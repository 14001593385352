import { filter, isArray, isNil, replace, some, truncate } from 'lodash';
import * as React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';

import PostAddIcon from '@mui/icons-material/PostAdd';
import {
	Alert,
	AlertTitle,
	Box,
	BoxProps,
	Button,
	ListItem,
	ListItemButton,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';

import { ListItemType } from 'components/Pagination/Paginator';
import { openDrawer } from 'containers/Marketplace/drawerSlice';
import CanUpsertRfpProposal from 'containers/Marketplace/proposal/CanUpsertRfpProposal';
import { useFetchRfpProposalsQuery } from 'containers/Marketplace/rfpProposalApi';
import { useFetchPublishedRfpRequestsQuery } from 'containers/Marketplace/rfpRequestApi';
import { useDispatch } from 'react-redux';
import { RfpProposal, RfpRequest } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';
import FormatCurrency from '../../../../components/Formatters/FormatCurrency';
import RequestProposalList, { RfpRequestProposalsButton } from './RequestProposalList';
import { RequestListing } from './RfpRequestList';

type ListItemProps = {
	rfpProposals: { drafts: RfpProposal[]; published: RfpProposal[] } | undefined;
} & ListItemType<RfpRequest>;
const DataListItem: React.FC<ListItemProps> = ({ data, rfpProposals }) => {
	const dispatch = useDispatch();

	const [expanded, setExpanded] = React.useState(false);

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	return (
		<>
			<ListItem
				key={data?.rfpRequestId}
				secondaryAction={
					<Stack direction='row'>
						<RfpRequestProposalsButton
							rfpProposals={rfpProposals}
							rfpRequest={data}
							onClick={() => setExpanded(!expanded)}
							active={expanded}
						/>
						<CanUpsertRfpProposal rfpRequest={data} rfpProposal={undefined}>
							{canUpsert =>
								canUpsert && (
									<Button
										size='small'
										endIcon={<PostAddIcon />}
										onClick={() => {
											dispatch(
												openDrawer({
													name: 'UpsertRfpProposalDrawer',
													data: { requestId: data?.rfpRequestId, published: data?.status === 'Published' },
												})
											);
										}}
									>
										<FormattedMessage id='publishedRfpRequests.rfpProposal.create' />
									</Button>
								)
							}
						</CanUpsertRfpProposal>
					</Stack>
				}
				sx={{
					mb: '.5rem',
					'&>.MuiListItemButton-root': {
						pr: 15,
					},
				}}
				disablePadding
			>
				<ListItemButton
					onClick={() => {
						dispatch(
							openDrawer({
								name: 'ReadRfpRequestDrawer',
								data: {
									requestId: data?.rfpRequestId,
									published: data?.status === 'Published',
									createdByMe: data?.companyId === companyId,
								},
							})
						);
					}}
				>
					<ListItemText
						primary={data?.projectTitle}
						secondary={
							<>
								<Typography component='span' variant='subtitle1' sx={{ mr: 1 }}>
									{data?.companyName}
								</Typography>
								{truncate(replace(data?.projectIngress || '', /\r|\n|\r\n/g, ' '), { length: 100 })}
								{data?.projectBudget && (
									<Typography component='span' sx={{ display: 'block', mt: '.5rem' }}>
										<Typography variant='subtitle2' component='span' sx={{ mr: '0.5rem' }}>
											<FormattedMessage id='rfpProposal.projectBudget.label' />
										</Typography>
										<Typography variant='subtitle2' component='span' sx={{ mr: '0.5rem' }}>
											<FormatCurrency value={data?.projectBudget} />
										</Typography>
									</Typography>
								)}
								<Typography component='span' variant='subtitle1' sx={{ mr: 1 }}>
									<Typography variant='subtitle2' component='span' sx={{ mr: '0.5rem' }}>
										<FormattedMessage id='rfpRequest.proposal.responseDeadline.label' />
									</Typography>
									<Typography variant='subtitle2' component='span'>
										<FormattedDate value={data?.responseDeadline ?? ''} />{' '}
										<FormattedTime value={data?.responseDeadline ?? ''} />
									</Typography>
								</Typography>
							</>
						}
					/>
				</ListItemButton>
			</ListItem>
			<RequestProposalList rfpProposals={rfpProposals} rfpRequest={data} expanded={expanded} />
		</>
	);
};

const filterRequestsWithMyProposals = (
	rfpRequest: RfpRequest,
	rfpProposals:
		| {
				drafts: RfpProposal[];
				published: RfpProposal[];
		  }
		| undefined
): boolean => {
	const publishedProposals = some(rfpProposals?.published, item => item.requestId === rfpRequest.rfpRequestId);
	const draftProposals = some(rfpProposals?.drafts, item => item.requestId === rfpRequest.rfpRequestId);

	return !!publishedProposals || !!draftProposals;
};

type Props = BoxProps & { filterByCompanyProposals?: boolean };
const PublishedRfpRequestList: React.FC<Props> = ({ sx, filterByCompanyProposals = false, ...otherProps }) => {
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;
	const { formatMessage } = useIntl();
	const {
		data: publishedRfpRequests,
		isLoading,
		isFetching,
		isError,
	} = useFetchPublishedRfpRequestsQuery({ companyId }, { skip: isNil(companyId) });

	const {
		data: rfpProposals,
		isLoading: isProposalsLoading,
		isFetching: isProposalsFetching,
		//isError: isProposalError,
	} = useFetchRfpProposalsQuery({ companyId }, { skip: isNil(companyId) });

	const dataLoading = isLoading || isFetching || isProposalsLoading || isProposalsFetching;

	if (dataLoading) {
		return (
			<>
				<Skeleton height={60} />
				<Skeleton height={60} />
				<Skeleton height={60} />
				<Skeleton height={60} />
			</>
		);
	}

	const filtered = filterByCompanyProposals
		? filter(publishedRfpRequests, request => filterRequestsWithMyProposals(request, rfpProposals))
		: publishedRfpRequests;

	return (
		<Box {...otherProps} sx={[{ pt: 2 }, ...(isArray(sx) ? sx : [sx])]}>
			{isError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchPublishedRfpRequests.error.message' />
				</Alert>
			)}

			<Stack>
				<RequestListing
					title={formatMessage({
						id: filterByCompanyProposals ? 'publishedRfpRequests.proposals.title' : 'publishedRfpRequests.title',
					})}
					emptyMessage={formatMessage({
						id: filterByCompanyProposals ? 'publishedRfpRequests.proposals.empty' : 'publishedRfpRequests.empty',
					})}
					dataListItem={<DataListItem rfpProposals={rfpProposals} />}
					rfpRequests={filtered?.filter(item => !item.expired)}
					rowsPerPage={15}
				/>

				<RequestListing
					title={formatMessage({
						id: filterByCompanyProposals ? 'publishedRfpRequests.proposals.closed.title' : 'publishedRfpRequests.closed.title',
					})}
					emptyMessage={formatMessage({
						id: filterByCompanyProposals ? 'publishedRfpRequests.proposals.empty' : 'publishedRfpRequests.empty',
					})}
					dataListItem={<DataListItem rfpProposals={rfpProposals} />}
					rfpRequests={filtered?.filter(item => item.expired)}
					rowsPerPage={10}
				/>
			</Stack>
		</Box>
	);
};

export default PublishedRfpRequestList;
