import { Button as MuiButton } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

import ButtonSpinner from './ButtonSpinner';

const StyledButton = styled(MuiButton, {
	shouldForwardProp: prop => {
		return prop !== 'sx' && prop !== 'borderColor' && prop !== 'textCase' && prop !== 'fullWidth';
	},
	overridesResolver: (props, styles) => [
		styles.root,
		props.color === 'primary' && styles.primary,
		props.color === 'success' && styles.secondary,
	],
})(({ theme, color, variant, borderColor, textCase, mt, mb, fullWidth }) => {
	return {
		width: fullWidth ? '100%' : undefined,
		textTransform: textCase,
		textDecoration: variant === 'text' ? 'underline' : 'none',
		fontWeight: 500,
		color:
			variant === 'contained'
				? theme.palette.primary.white
				: variant === 'outlined' && !borderColor
					? theme.palette[color].main
					: variant === 'outlined' && borderColor && theme.palette.secondary[borderColor],
		borderColor: borderColor && theme.palette.secondary[borderColor],
		marginTop: mt ?? 'unset',
		marginBottom: mb ?? 'unset',
		'&:hover': {
			borderColor: borderColor && theme.palette.secondary.grey900,
			color: borderColor && theme.palette.secondary.grey900,
			textDecoration: variant === 'text' ? 'underline' : 'none',
		},
		'&:disabled': {
			opacity: '0.4',
			color:
				variant === 'contained'
					? theme.palette.primary.white
					: variant === 'outlined' && !borderColor
						? theme.palette[color].main
						: variant === 'outlined' && borderColor && theme.palette.secondary[borderColor],
			backgroundColor:
				variant === 'contained' && color ? theme.palette[color].main : variant === 'outlined' && theme.palette.primary.white,
			borderColor: borderColor && theme.palette.secondary[borderColor],
		},
	};
});

const Button = ({
	variant = 'contained',
	disabled,
	children,
	onClick,
	icon,
	href,
	color,
	startIcon,
	borderColor,
	loading,
	textCase = 'uppercase',
	mt,
	mb,
	fullWidth = false,
	...props
}) => {
	if (loading) {
		startIcon = <ButtonSpinner position='relative' />;
	}

	return (
		<Box sx={{ position: 'relative', display: 'inline-block', width: fullWidth ? '100%' : undefined }}>
			<StyledButton
				onClick={onClick}
				variant={variant}
				disabled={disabled || loading}
				endIcon={icon}
				startIcon={startIcon}
				href={href}
				color={color}
				borderColor={borderColor}
				textCase={textCase}
				fullWidth={fullWidth}
				mt={mt}
				mb={mb}
				size={props?.size ?? 'large'}
				{...props}
			>
				{children}
			</StyledButton>
		</Box>
	);
};

export default Button;
