import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import FormErrorList from 'components/Form/FormErrorList';
import { getErrorFields } from 'components/Form/utils/utils';

const useFormErrorEffect = errors => {
    useEffect(() => {
        try {
            if (errors && Object.keys(errors)?.length > 0) {
                showToastAndScroll(errors);
            }
        }
        catch {
            // ignore exceptions
        }
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

    const showToastAndScroll = errors => {
        const errorFields = getErrorFields([], errors);
        const errorIds = errorFields.map(({ id }) => id);
        const nodeList = document.querySelectorAll(errorIds);
        const topElement = nodeList[0]; // scroll to top element always

        const idsSorted = [...nodeList].map(({ id }) => ('#' + id));

        const errorMessages = idsSorted.map(id => errorFields.find(el => el.id === id).message);

        showToast(errorMessages);
        scrollIntoViewWithOffset(topElement);
    };

    const showToast = errorMessages => toast.error(<FormErrorList errorMessages={errorMessages} />);

    const scrollIntoViewWithOffset = (topElement, offset = 50) => {
        const elementPosition = topElement?.getBoundingClientRect().top;
        const offsetPosition = elementPosition ? elementPosition + window.pageYOffset - offset : 0;

        window.scrollTo({
            behavior: 'smooth',
            top: offsetPosition
        });
    };

    return null;
};

export default useFormErrorEffect;
