import { useIntl } from 'react-intl';

import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Button from '@mui/material/Button';

import AuthenticatedContainer from 'components/Containers/AuthenticatedContainer';

const InstrumentToChat = ({ instrumentName, variant = 'contained', color = 'primary', mt = 0 }) => {
	const intl = useIntl();

	return (
		<AuthenticatedContainer>
			<Button
				startIcon={<ConnectWithoutContactIcon />}
				href='https://grants.fi/yhteystiedot/'
				target='_blank'
				variant='outlined'
				color='primary'
			>
				{intl.formatMessage({ id: 'instrument.to.chat.button' })}
			</Button>

			{/* <Button
				sx={{ mt }}
				variant={variant}
				color={color}
				onClick={() => dispatch(sendMessage(intl.formatMessage({ id: 'chat.input.question' }, { question: instrumentName }), true))}
			>
				{intl.formatMessage({ id: 'instrument.to.chat.button' })}
				{loading && <ButtonSpinner color={theme.palette[color].main} />}
			</Button> */}
		</AuthenticatedContainer>
	);
};

export default InstrumentToChat;
