import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { generateUid } from 'utils/helpers/uid';
import FormatCurrency from '../../../../components/Formatters/FormatCurrency';
import { LicenseDialog } from './LicenseDialog';
import { StatisticItem } from './MRRStatistics';

export const flatData = (statisticItems: any[]) => {
	if (!statisticItems) return null;

	let res = [];

	for (const arrItem of statisticItems) {
		res.push(arrItem);

		if (!arrItem.sub) continue;

		for (const subItem of arrItem.sub) {
			res.push({ isSub: true, ...subItem });
		}
	}

	return res;
};

export const StatisticItemInfoBoxList: React.FC<{ statisticItems: StatisticItem[] }> = ({ statisticItems }) => {
	const { formatMessage } = useIntl();

	const columns: GridColDef[] = [
		{
			field: 'lclass',
			headerName: formatMessage({ id: 'mrr.statistics.lclass' }),
			width: 250,
			editable: false,
			renderCell: (params: any) => (
				<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
					{formatMessage({ id: `mrr.statistics.${params.row.lclass}` })}
				</span>
			),
		},
		{
			field: 'active',
			headerName: 'aktiiviset',
			width: 70,
			editable: false,
			sortable: false,
			type: 'number',
		},
		{
			field: 'total',
			headerName: 'kaikki',
			width: 60,
			editable: false,
			sortable: false,
			type: 'number',
		},
		{
			field: 'activeMrr',
			headerName: 'MRR',
			width: 110,
			editable: false,
			sortable: false,
			renderCell: ({ value }) => {
				return <FormatCurrency value={value} maximumFractionDigits={0} />;
			},
			type: 'number',
		},
		{
			field: 'averageMrr',
			headerName: 'avg',
			width: 110,
			editable: false,
			sortable: false,
			renderCell: ({ value }) => {
				return <FormatCurrency value={value} maximumFractionDigits={0} />;
			},
			type: 'number',
		},
		{
			field: 'maxMrr',
			headerName: 'max',
			width: 110,
			editable: false,
			sortable: false,
			renderCell: ({ value }) => {
				return <FormatCurrency value={value} maximumFractionDigits={0} />;
			},
			type: 'number',
		},
		{
			field: 'minMrr',
			headerName: 'min',
			width: 110,
			editable: false,
			sortable: false,
			renderCell: ({ value }) => {
				return <FormatCurrency value={value} maximumFractionDigits={0} />;
			},
			type: 'number',
		},
	];

	let flattenData = flatData(statisticItems);

	const [selectedRowData, setSelectedRowData] = useState(null);
	const [isLicenseDialogOpen, setIsLicenseDialogOpen] = useState(false);

	return (
		<>
			{/* {map(statisticItems, item => (
				<Grid item xs={12} md={4} lg={3}>
					<StatisticInfoBox statisticItem={item} />
				</Grid>
			))} */}
			<LicenseDialog open={isLicenseDialogOpen} data={selectedRowData} closeModal={() => setIsLicenseDialogOpen(false)} />
			{flattenData && (
				<DataGrid
					rowHeight={37}
					sx={{
						'&:hover': { cursor: 'pointer' },
						background: '#fff',
						'& .MuiDataGrid-cell:hover': {
							color: 'primary.main',
						},
						'.highlight': {
							bgcolor: '#fbfbfb',
							fontSize: '.8rem',
							'.MuiDataGrid-cell:first-child': {
								pl: '2.5rem',
							},
						},
					}}
					getRowClassName={params => {
						return params.row.isSub === true ? 'highlight' : '';
					}}
					getRowId={() => generateUid()}
					rows={flattenData}
					columns={columns}
					initialState={{
						pagination: {
							// @ts-ignore
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					autoHeight
					onRowClick={row => {
						setIsLicenseDialogOpen(true);
						setSelectedRowData(row.row);
					}}
				/>
			)}
		</>
	);
};
