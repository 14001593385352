import { yupResolver } from '@hookform/resolvers/yup';
import { parseISO, set } from 'date-fns';
import { isNil } from 'lodash';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { DeleteSharp, Publish as PublishIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Alert,
	AlertTitle,
	Box,
	BoxProps,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputAdornment,
	Skeleton,
	Stack,
	Switch,
	TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';

import { RfpAttachment, RfpProfile, RfpRequest } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';
import { mergeFieldValues } from 'utils/misc/formUtils';

import { openPopover } from 'containers/Marketplace/popoverSlice';
import { useFetchRfpProfileQuery } from 'containers/Marketplace/rfpProfileApi';
import {
	useDeleteRfpRequestMutation,
	useFetchRfpRequestQuery,
	usePublishRfpRequestMutation,
	useUpsertRfpRequestMutation,
} from 'containers/Marketplace/rfpRequestApi';

import { DateTimePicker } from '@mui/x-date-pickers';
import RfpAttachmentInput from 'containers/Marketplace/attachment/RfpAttachmentInput';
import RfpAttachmentList from 'containers/Marketplace/attachment/RfpAttachmentList';
import CurrencyInput from 'containers/Marketplace/common/CurrencyInput';
import DateController from 'containers/Marketplace/common/DateController';
import NumberController from 'containers/Marketplace/common/NumberController';
import RfpLastUpdatedTypography from 'containers/Marketplace/common/RfpLastUpdatedTypography';
import RfpStatusChip from 'containers/Marketplace/common/RfpStatusChip';
import RfpVisibilityToggle from 'containers/Marketplace/common/RfpVisibilityToggle';
import RfpProfileStatusDraftAlert from 'containers/Marketplace/profile/RfpProfileStatusDraftAlert';
import SelectPublicRfpProfileDialog from 'containers/Marketplace/profile/SelectPublicRfpProfilesDialog';
import RfpRequestItemInput from 'containers/Marketplace/request/RfpRequestItemInput';
import RfpRequestItemList from 'containers/Marketplace/request/RfpRequestItemList';
import RfpRequestTargetCompanyInput from 'containers/Marketplace/request/RfpRequestTargetCompanyInput';
import UpsertRfpRequestItemDialog from 'containers/Marketplace/request/UpsertRfpRequestItemDialog';
import { StyledTooltip } from '../common/StyledTooltip';

type Props = BoxProps & {
	requestId: string | null | undefined;
	published: boolean;
	onAfterPublish?: () => void;
	onAfterRemove?: () => void;
	onDirtyChange?: (isDirty: boolean) => void;
};

type FieldValues = Partial<RfpRequest>;

const convertRfpProfileToFieldValues = (rfpProfile?: RfpProfile | null): FieldValues => {
	return {
		companyId: rfpProfile?.companyId,
		companyName: rfpProfile?.companyName,
		projectContactDetail: rfpProfile?.contactInformation,
	};
};

const UpsertRfpRequestForm: React.FC<Props> = ({ requestId, published, onAfterPublish, onAfterRemove, onDirtyChange, ...otherProps }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const {
		data: rfpProfile,
		isLoading: isRfpProfileLoading,
		isError: isRfpProfileError,
	} = useFetchRfpProfileQuery({ companyId }, { skip: isNil(companyId) });

	const {
		data: rfpRequest,
		isLoading: isRfpRequestLoading,
		isError: isRfpRequestError,
	} = useFetchRfpRequestQuery({ companyId, requestId, published }, { skip: isNil(companyId) || isNil(requestId) });

	const [upsertRfpRequest, { data: upsertedRfpRequest, isError: isUpsertError }] = useUpsertRfpRequestMutation();
	const [publishRfpRequest, { isError: isPublishError, isLoading: isPublishing }] = usePublishRfpRequestMutation();
	const [deleteRfpRequest, { isError: isDeleteError, isLoading: isDeleting }] = useDeleteRfpRequestMutation();

	const validationSchema = React.useMemo(
		() =>
			yup.object({
				responseDeadline: yup
					.string()
					.nullable()
					.isoDate()
					.minIsoDate(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }))
					.required(),
				projectTimeStart: yup
					.string()
					.nullable()
					.minIsoDate(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }))
					.maxIsoDate(yup.ref<string>('projectTimeEnd'))
					.required(),
				projectTimeEnd: yup.string().nullable().isoDate().minIsoDate(yup.ref<string>('projectTimeStart')).required(),
				projectTitle: yup.string().required(),
				projectDescription: yup.string().max(2000).required(),
				projectOverview: yup.string().max(2000).required(),
				projectTimeframe: yup.string().max(2000).required(),
				projectChallengesAndGoals: yup.string().max(2000),
				projectRequirementsAndEvaluationCriteria: yup.string().max(2000),
				projectProposalSubmissionRequirements: yup.string().max(2000),
				projectContactDetail: yup.string().max(2000).required(),
				projectBudget: yup.number().nullable().positive().required(),
				isTargetedRequest: yup.boolean().nullable(),
				proposalRequestTargetCompanies: yup
					.array()
					.nullable()
					.when('isTargetedRequest', {
						is: true,
						then: schema => schema.min(1),
						otherwise: schema => schema.min(0),
					}),
			}),
		[]
	);

	const defaultValues = React.useMemo(
		() => ({
			rfpRequestId: null,
			status: 'Draft' as const,
			createDate: null,
			updateDate: null,
			companyId: null,
			companyName: null,
			responseDeadline: null,
			projectTitle: '',
			projectIngress: '',
			projectDescription: '',
			projectOverview: '',
			projectTimeframe: '',
			projectTimeStart: '',
			projectTimeEnd: '',
			projectChallengesAndGoals: '',
			projectRequirementsAndEvaluationCriteria: '',
			projectProposalSubmissionRequirements: '',
			projectContactDetail: '',
			projectBudget: null,
			isTargetedRequest: false,
			proposalRequestTargetCompanies: [],
			rfpRequestItems: [],
			attachments: [],
		}),
		[]
	);

	const {
		control,
		handleSubmit,
		watch,
		reset,
		formState: { isSubmitting, isDirty },
		getValues,
		setValue,
	} = useForm<FieldValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const [rfpRequestId, updateDate, createDate, status, projectTimeStart, projectTimeEnd] = watch([
		'rfpRequestId',
		'updateDate',
		'createDate',
		'status',
		'projectTimeStart',
		'projectTimeEnd',
		'rfpRequestItems',
		'attachments',
	]);

	const hasRequestId = Boolean(rfpRequestId);
	const isDraft = status === 'Draft';

	const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
		async fieldValues => {
			try {
				await upsertRfpRequest({
					companyId,
					rfpRequest: fieldValues,
					published: fieldValues?.status === 'Published',
				}).unwrap();
				toast.success(formatMessage({ id: 'upsertRfpRequest.success.message' }));
			} catch (error) {
				console.error(error);
			}
		},
		[upsertRfpRequest, formatMessage, companyId]
	);

	React.useEffect(() => {
		onDirtyChange?.(isDirty);
	}, [onDirtyChange, isDirty]);

	React.useEffect(() => {
		if (!isNil(rfpRequest) || !isNil(rfpProfile)) {
			reset(mergeFieldValues(defaultValues, rfpRequest, convertRfpProfileToFieldValues(rfpProfile)));
		}
	}, [reset, defaultValues, rfpRequest, rfpProfile]);

	React.useEffect(() => {
		if (!isNil(upsertedRfpRequest)) {
			reset(mergeFieldValues(defaultValues, upsertedRfpRequest));
		}
	}, [reset, defaultValues, upsertedRfpRequest]);

	const onRemoveAttachment = (removedAttachmentId: string) => {
		const attachments = getValues()?.attachments;
		setValue('attachments', attachments?.filter(item => item.attachmentId !== removedAttachmentId) ?? []);
	};

	const onRemoveItem = (removedItemId: string) => {
		const items = getValues()?.rfpRequestItems;
		setValue('rfpRequestItems', items?.filter(item => item.rfpRequestItemId !== removedItemId) ?? []);
	};

	// const onUpsertItem = (upsertItem: RfpRequestItem) => {
	// 	const items = getValues()?.rfpRequestItems;

	// 	if (!items || items.length === 0) return;

	// 	const index = items.findIndex(item => item.rfpRequestItemId === upsertItem.rfpRequestItemId);

	// 	if (index < 0) {
	// 		items.push(upsertItem);
	// 	} else {
	// 		items[index] = upsertItem;
	// 	}

	// 	setValue('rfpRequestItems', items);
	// };

	const onUpsertAttachment = (upsertItem: RfpAttachment) => {
		const attachments = getValues()?.attachments;

		if (!attachments || attachments.length === 0) return;

		const index = attachments.findIndex(item => item.attachmentId === upsertItem.attachmentId);

		if (index < 0) {
			attachments.push(upsertItem);
		} else {
			attachments[index] = upsertItem;
		}

		setValue('attachments', attachments);
	};

	const isLoading = isRfpRequestLoading || isRfpProfileLoading;

	return (
		<>
			<UpsertRfpRequestItemDialog />
			<SelectPublicRfpProfileDialog />
			<Box {...otherProps} component='form' onSubmit={handleSubmit(handleFormSubmit)}>
				<DialogTitle>
					<FormattedMessage id='rfpRequest.title' />
				</DialogTitle>
				<DialogContent>
					{isRfpProfileError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchRfpProfile.error.message' />
						</Alert>
					)}
					{isRfpRequestError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchRfpRequest.error.message' />
						</Alert>
					)}
					{isUpsertError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='mutation.error.title' />
							</AlertTitle>
							<FormattedMessage id='upsertRfpRequest.error.message' />
						</Alert>
					)}
					{isPublishError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='mutation.error.title' />
							</AlertTitle>
							<FormattedMessage id='publishRfpRequest.error.message' />
						</Alert>
					)}
					{isDeleteError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='mutation.error.title' />
							</AlertTitle>
							<FormattedMessage id='deleteRfpRequest.error.message' />
						</Alert>
					)}
					<RfpProfileStatusDraftAlert />
					<Stack spacing={3}>
						<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
							<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
								{isLoading ? (
									<Skeleton height={120} />
								) : (
									<>
										<Controller
											name='projectTitle'
											control={control}
											render={({ field, fieldState }) => (
												<TextField
													{...field}
													error={!isNil(fieldState.error)}
													label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
													placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
													helperText={fieldState.error?.message}
												/>
											)}
										/>
										<Stack flexDirection={{ xs: 'column', sm: 'row' }}>
											<NumberController
												name='projectBudget'
												control={control}
												render={({ field, fieldState }) => (
													<TextField
														{...field}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message}
														InputProps={{
															inputComponent: CurrencyInput,
															endAdornment: <InputAdornment position='end'>€</InputAdornment>,
														}}
													/>
												)}
											/>
											<DateController
												name='responseDeadline'
												control={control}
												render={({ field, fieldState }) => (
													<DateTimePicker
														{...field}
														renderInput={params => (
															<TextField
																{...params}
																label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
																placeholder={formatMessage({
																	id: `rfpRequest.${field.name}.placeholder`,
																})}
																error={!isNil(fieldState.error)}
																helperText={fieldState.error?.message}
															/>
														)}
														disablePast
													/>
												)}
											/>
										</Stack>
									</>
								)}
							</Stack>
							<Stack flex='1' maxWidth={{ xs: '100%', md: '40%' }}>
								{isLoading ? (
									<Skeleton width={200} height={100} />
								) : (
									<Stack flexDirection='row' alignItems='flex-start' justifyContent='flex-end' flexWrap='wrap'>
										<LoadingButton
											startIcon={<DeleteSharp />}
											variant='outlined'
											color='error'
											loading={isDeleting}
											disabled={!hasRequestId || isDirty}
											onClick={event => {
												dispatch(
													openPopover({
														name: 'ConfirmRemovePopover',
														data: {
															anchorEl: event.currentTarget,
															text: formatMessage({ id: 'rfpRequest.confirmRemove' }),
															description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
															onSubmit: async () => {
																try {
																	await deleteRfpRequest({
																		companyId,
																		requestId: rfpRequestId,
																		published: status === 'Published',
																	}).unwrap();
																	toast.success(
																		formatMessage({ id: 'deleteRfpRequest.success.message' })
																	);
																	onAfterRemove?.();
																} catch (error) {
																	console.error(error);
																}
															},
														},
													})
												);
											}}
										>
											<FormattedMessage id='rfpRequest.delete' />
										</LoadingButton>
										<LoadingButton
											type='submit'
											variant={isDraft ? 'outlined' : 'contained'}
											color={isDraft ? 'neutral' : 'primary'}
											loading={isSubmitting}
										>
											<FormattedMessage id='rfpRequest.submit' />
										</LoadingButton>
										{isDraft && (
											<LoadingButton
												endIcon={<PublishIcon />}
												loading={isPublishing}
												disabled={!hasRequestId || isDirty || rfpProfile?.status !== 'Published'}
												onClick={async event => {
													dispatch(
														openPopover({
															name: 'ConfirmPublishPopover',
															data: {
																anchorEl: event.currentTarget,
																text: formatMessage({ id: 'rfpRequest.confirmPublish' }),
																onSubmit: async () => {
																	try {
																		await publishRfpRequest({
																			companyId,
																			requestId: rfpRequestId,
																		}).unwrap();
																		toast.success(
																			formatMessage({ id: 'publishRfpRequest.success.message' })
																		);
																		onAfterPublish?.();
																	} catch (error) {
																		console.error(error);
																	}
																},
															},
														})
													);
												}}
											>
												<FormattedMessage id='rfpRequest.publish' />
											</LoadingButton>
										)}
									</Stack>
								)}
							</Stack>
						</Stack>
						<Stack flexDirection='row' alignItems='center'>
							{isLoading ? (
								<Skeleton width={300} height={60} />
							) : (
								<>
									<RfpStatusChip status={status} />
									<RfpLastUpdatedTypography createDate={createDate} updateDate={updateDate} />
								</>
							)}
						</Stack>
						<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
							<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
								{isLoading ? (
									<>
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
									</>
								) : (
									<>
										<Stack flexDirection={{ xs: 'column', md: 'row' }}>
											<DateController
												name='projectTimeStart'
												control={control}
												render={({ field, fieldState }) => {
													return (
														<DatePicker
															{...field}
															maxDate={isNil(projectTimeEnd) ? undefined : parseISO(projectTimeEnd)}
															renderInput={params => (
																<TextField
																	{...params}
																	label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
																	placeholder={formatMessage({
																		id: `rfpRequest.${field.name}.placeholder`,
																	})}
																	error={!isNil(fieldState.error)}
																	helperText={fieldState.error?.message}
																/>
															)}
															disablePast
														/>
													);
												}}
											/>
											<DateController
												name='projectTimeEnd'
												control={control}
												render={({ field, fieldState }) => {
													return (
														<DatePicker
															{...field}
															minDate={isNil(projectTimeStart) ? undefined : parseISO(projectTimeStart)}
															renderInput={params => (
																<TextField
																	{...params}
																	label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
																	placeholder={formatMessage({
																		id: `rfpRequest.${field.name}.placeholder`,
																	})}
																	error={!isNil(fieldState.error)}
																	helperText={fieldState.error?.message}
																/>
															)}
															disablePast
														/>
													);
												}}
											/>
										</Stack>
										<Controller
											name='projectDescription'
											control={control}
											render={({ field, fieldState }) => (
												// @ts-ignore
												<StyledTooltip title={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}>
													<TextField
														{...field}
														error={!isNil(fieldState.error)}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='projectOverview'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='projectTimeframe'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='projectChallengesAndGoals'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='projectRequirementsAndEvaluationCriteria'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='projectProposalSubmissionRequirements'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='projectContactDetail'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpRequest.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
									</>
								)}
							</Stack>
							<Stack flex='1' maxWidth={{ xs: '100%', md: '40%' }}>
								{isLoading ? (
									<>
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
									</>
								) : (
									<>
										<Controller
											name='isTargetedRequest'
											control={control}
											render={({ field }) => (
												<FormControl>
													<FormLabel sx={theme => ({ ...theme.typography.h4 })}>
														<FormattedMessage id={`rfpRequest.${field.name}.label`} />
													</FormLabel>
													<FormControlLabel
														control={<Switch {...field} checked={isNil(field.value) ? false : field.value} />}
														label={formatMessage({ id: `rfpRequest.${field.name}.controlLabel` })}
													/>
												</FormControl>
											)}
										/>
										<RfpVisibilityToggle control={control} name='isTargetedRequest' value={true}>
											<Controller
												name='proposalRequestTargetCompanies'
												control={control}
												render={({ field, fieldState }) => (
													<RfpRequestTargetCompanyInput
														{...field}
														label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message}
													/>
												)}
											/>
										</RfpVisibilityToggle>
										<Controller
											name='rfpRequestItems'
											control={control}
											render={({ field, fieldState }) => (
												<>
													{hasRequestId ? (
														<RfpRequestItemList
															control={control}
															onRemove={onRemoveItem}
															// onUpsert={onUpsertItem}
															requestId={rfpRequestId}
															label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													) : (
														<RfpRequestItemInput
															{...field}
															label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													)}
												</>
											)}
										/>
										<Controller
											name='attachments'
											control={control}
											render={({ field, fieldState }) => (
												<>
													{hasRequestId ? (
														<RfpAttachmentList
															onRemoveAttachment={onRemoveAttachment}
															onUpsertAttachment={onUpsertAttachment}
															parentType='RFPRequest'
															proposalId={undefined}
															requestId={rfpRequestId}
															label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													) : (
														<RfpAttachmentInput
															{...field}
															label={formatMessage({ id: `rfpRequest.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													)}
												</>
											)}
										/>
									</>
								)}
							</Stack>
						</Stack>
					</Stack>
				</DialogContent>
			</Box>
		</>
	);
};

export default UpsertRfpRequestForm;
