import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

import Spinner from 'components/Spinner/Spinner';
import Panel from '../Panel';
import TeamMemberCard from './TeamMemberCard';

const TeamMemberPanel = ({ loading, data, readonly }) => {
    const intl = useIntl();
    const theme = useTheme();

    return (
        <Panel
            readonly={readonly}
            loading={loading}
            title={intl.formatMessage({ id: 'business.plan.member.card.title' })}
            subtitle={intl.formatMessage({ id: 'business.plan.member.card.subtitle' })}
            button={intl.formatMessage({ id: 'business.plan.member.card.button' })}
            modalIgniter='team'
            mb={2}
        >
            {loading
                ? <Spinner size={16} mt={4} />
                : data?.length === 0
                    ? <Typography align='center' sx={{ color: theme.palette.secondary.grey400, mt: 4 }}>{intl.formatMessage({ id: 'business.plan.member.card.noItems' })}</Typography>
                    : data?.map(teamMember => <TeamMemberCard key={teamMember.memberId} teamMember={teamMember} readonly={readonly} />)
            }
        </Panel>
    );
};

export default TeamMemberPanel;
