import React from 'react';
import { useIntl } from 'react-intl';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		border: `1px dashed ${theme.palette.secondary.grey700}`,
		backgroundColor: 'rgba(255,255,255)',
		opacity: 0.5,
		'&:hover': {
			cursor: 'pointer',
			border: `1px dashed ${theme.palette.primary.main}`,
			opacity: 1,
			'& svg, p': {
				color: theme.palette.primary.main,
			},
		},
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '26.1rem',
	},
	addIcon: {
		color: theme.palette.secondary.grey700,
	},
	addText: {
		fontSize: '1.2rem',
		color: theme.palette.secondary.grey700,
	},
}));

const ReferenceAddCard = ({ addCategory }) => {
	const classes = useStyles();
	const intl = useIntl();

	return (
		<Card className={classes.root} onClick={addCategory}>
			<div className={classes.container}>
				<AddIcon fontSize='large' className={classes.addIcon} />
				<Typography className={classes.addText}>{intl.formatMessage({ id: 'references.add.new' })}</Typography>
			</div>
		</Card>
	);
};

export default ReferenceAddCard;
