
import ContentContainer from 'components/Containers/ContentContainer';
import Analysis from 'containers/Analysis';

const AnalysisPage = () => {
	return (
		<ContentContainer>
			<Analysis />
		</ContentContainer>
	);
};

export default AnalysisPage;
