import React from 'react';
import { useIntl } from 'react-intl';

import CircleIcon from '@mui/icons-material/Circle';
import Chip from '@mui/material/Chip';

const ChipItem = ({ item, label, toggleOne, clicked, filtered }) => {
    const intl = useIntl();

    return (
        <Chip
            key={item}
            variant='outlined'
            size='small'
            label={`${label === 'providers' ||
                label === 'projectAims' ||
                label === 'projectObjects' ||
                label === 'projectValues'
                ? item
                : intl.formatMessage({ id: `check.item.${item}` })}`}
            icon={
                item === 0 || item === 1 || item === 2
                    ? <CircleIcon
                        style={{
                            fontSize: 8,
                            marginLeft: 8,
                        }}
                    />
                    : null
            }
            onClick={() => toggleOne(item)}
            sx={{
                backgroundColor: clicked?.includes(item) && !filtered ? 'primary.white' : 'secondary.grey200',
                borderColor: !clicked?.includes(item) && !filtered ? 'secondary.grey200' : 'secondary.grey300',
                color: clicked?.includes(item) && !filtered ? '#303F5A' : '#73798C',
                mt: '.3rem',
                mr: '.3rem',
                '& .MuiChip-labelSmall': {
                    pt: '1px'
                },
                '& .MuiChip-icon': {
                    color: item === 0 ? 'error.main' : item === 1 ? 'warning.main' : item === 2 ? 'success.main' : 'primary.main'
                },
            }}
        />
    );
};

export default ChipItem;