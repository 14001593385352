import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { Box, Button, FormControl, Stack } from '@mui/material';

import { FormControlledInput, FromControlledHiddenField } from 'components/Form';
import { map } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getCompanyId } from 'utils/auth/company';
import { ExtendedSelectOption } from '../../AIContextSelector/AIContextSelector';
import { usePostChatMessageMutation } from '../../aiApi';
import { OpenAIMessage } from '../AiChat';

type ChatProps = {
	promptBase: Array<ExtendedSelectOption> | null;
	messages: Array<OpenAIMessage>;
	chatId: string;
	loading: boolean;
	chatHistory: any;
	setAiLoading: (loading: boolean) => void;
	setUserWriting?: () => void;
	deleteButton: any;
	inputDefaultValue?: string;
	showInput?: boolean;
	handledResponse?: (response: any) => void;
	buttonText?: string;
};

const ChatForm: React.FC<ChatProps> = ({
	promptBase,
	messages,
	chatId,
	loading,
	chatHistory,
	setAiLoading = () => {},
	setUserWriting = (isWriting: boolean) => {},
	deleteButton,
	inputDefaultValue = '',
	showInput = true,
	handledResponse,
	buttonText,
}) => {
	const intl = useIntl();
	const companyId = getCompanyId();
	const [isLoading, setLoading] = useState(false);

	const schema = yup.object().shape({
		content: showInput ? yup.string().required() : yup.string(),
	});

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	const [postMessage] = usePostChatMessageMutation();
	const handleAiPostMessage = async (question: any) => {
		setLoading(true);
		setAiLoading(true);
		const reqMessages = [...messages];

		if (chatHistory != null && chatHistory.chatContent != null && chatHistory.chatContent.length > 0) {
			reqMessages.push(...chatHistory.chatContent.slice(-20));
		}

		if (question.content) {
			reqMessages.push(question);
		}

		const mappedPromptBase = map(promptBase, item => {
			return {
				role: 'user',
				content: item.data,
			};
		});

		const prompt = [...mappedPromptBase, ...reqMessages];

		let response = await postMessage({ companyId, chatId, messages: prompt });

		setLoading(false);
		setAiLoading(false);

		// @ts-ignore
		if (response.data?.message) {
			// @ts-ignore
			toast.error(response.data?.message);

			return;
		}

		if (handledResponse) {
			// @ts-ignore
			handledResponse(response.data[0]);
		}

		reset();
	};

	return (
		<>
			<Box
				component='form'
				onSubmit={handleSubmit(data => handleAiPostMessage(data))}
				sx={{ flex: '0 0 auto', px: '1rem', pb: '1rem' }}
			>
				{/* @ts-ignore */}
				<FromControlledHiddenField name='role' defaultValue='user' control={control} />
				{showInput && ( // @ts-ignore
					<FormControlledInput
						disabled={isLoading || loading}
						sx={{ mt: '1rem' }}
						rows={10}
						maxRows={10}
						defaultValue={inputDefaultValue}
						name='content'
						control={control}
						error={errors?.content?.message} // @ts-ignore
						onMouseEnter={() => setUserWriting(true)}
						onMouseLeave={() => setUserWriting(false)}
					/>
				)}
				<Stack
					flexDirection='row'
					justifyContent={deleteButton ? 'space-between' : 'flex-end'}
					alignItems='center'
					flex={1}
					spacing={1}
					useFlexGap
				>
					{deleteButton}
					<Stack sx={{ mt: '.5rem' }} justifyContent='flex-end' flexDirection='row' alignItems='center' spacing={1}>
						<FormControl sx={{ m: '0 !important' }}>
							<Button
								sx={{ mt: '0 !important', ml: '1rem' }}
								variant='contained'
								disabled={isLoading || loading}
								onClick={handleSubmit(data => {
									handleAiPostMessage(data);
								})}
								startIcon={<PsychologyAltIcon />}
							>
								{buttonText ?? intl.formatMessage({ id: 'ai.chat.form.input' })}
							</Button>
						</FormControl>
					</Stack>
				</Stack>
			</Box>
		</>
	);
};

export default ChatForm;
