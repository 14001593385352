import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadAnalysisByLink } from 'containers/Analysis/slices';

import Analysis from 'containers/Analysis';

const useStyles = makeStyles(theme => ({
	spinnerContainer: {
		height: 'calc(100vh - 64px - 164px)',
		[theme.breakpoints.up('sm')]: {
			height: 'calc(100vh - 64px - 134px)',
		},
	},
	expiredWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 64px - 164px)',
		[theme.breakpoints.up('sm')]: {
			height: 'calc(100vh - 64px - 134px)',
		},
	},
	expiredMessage: {
		fontSize: '1.5rem',
		[theme.breakpoints.up('sm')]: {
			fontSize: '2rem',
		},
	},
}));

const AnalysisLinkPage = () => {
	const intl = useIntl();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { link } = useParams();

	const analysis = useSelector(state => state.analysis);

	useEffect(() => {
		if (!analysis || analysis?.isLoaded) return;

		dispatch(loadAnalysisByLink(link));
	}, [analysis, dispatch, link]);

	const loading = !analysis?.isLoaded;
	const error = analysis?.error;

	return loading && !error ? (
		<Grid container justifyContent='center' alignItems='center' className={classes.spinnerContainer}>
			<Grid item>
				<CircularProgress size={24} color='primary' />
			</Grid>
		</Grid>
	) : !loading && error && error?.response?.status === 400 ? (
		<div className={classes.expiredWrapper}>
			<Typography variant='h1' color='primary' className={classes.expiredMessage}>
				{intl.formatMessage({ id: 'profile.page.400' })}
			</Typography>
		</div>
	) : !loading && Object.keys(analysis).length > 0 ? (
		<Analysis link={true} error={error} />
	) : (
		<div>Problem rendering component</div>
	);
};

export default AnalysisLinkPage;
