import { AxiosError, AxiosRequestConfig } from 'axios';
import axios from '.';

import { addAdvancedCompanyQuery, getCompany, hasCompanyAcceptedAgreement } from 'utils/auth/company';
import { validateHttpStatus } from 'utils/helpers/uriHelper';

export const axiosBaseQuery =
	({ baseUrl } = { baseUrl: '' }, disableContractChecks = false) =>
	async ({ url, params, ...otherConfigs }: AxiosRequestConfig) => {
		try {
			if (!disableContractChecks) {
				const isAgreementAccepted = hasCompanyAcceptedAgreement();

				if (!isAgreementAccepted) {
					return {
						error: {
							status: 403,
							data: 'Company has not accepted the agreement',
						},
					};
				}
			}

			if (addAdvancedCompanyQuery()) {
				const company = getCompany() as any;
				params = Object.assign({}, params, { advancedCompanyId: company?.CompanyId });
			}

			const addCompanyId = addAdvancedCompanyQuery();

			if (addCompanyId) {
				const company = getCompany();
				params = { ...params, advancedCompanyId: company?.CompanyId };
			}

			const { data } = await axios(`${baseUrl}${url}`, { params, validateStatus: validateHttpStatus, ...otherConfigs });

			return { data };
		} catch (error: any) {
			let axiosError = error as AxiosError;
			return {
				error: {
					status: axiosError.response?.status,
					data: axiosError.response?.data || axiosError.message,
				},
			};
		}
	};
