import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Avatar,
	Box,
	Button,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import {
	clone,
	every,
	filter,
	find,
	findIndex,
	includes,
	intersection,
	isEmpty,
	isNil,
	join,
	map,
	pick,
	some,
	toLower,
	values,
} from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { PaginationToken, RfpCategory, RfpProfile } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import { closeDialog, getDialogData, isDialogOpen } from 'containers/Marketplace/dialogSlice';
import { useGetPublicProfilesQuery } from 'containers/Marketplace/rfpProfileApi';

import BlobDownloader from 'containers/Marketplace/attachment/BlobDownloader';
import RfpCategoryAutocomplete from 'containers/Marketplace/common/RfpCategoryAutocomplete';

type Props = Omit<DialogProps, 'open'> & {};

type ListItemProps = ListChildComponentProps & {};

const filterRfpProfileByCategories = (rfpProfile: RfpProfile, categories: RfpCategory[]) => {
	return !isEmpty(intersection(rfpProfile.categories, categories));
};

const filterRfpProfileBySearchTerm = (rfpProfile: RfpProfile, searchTerm: string) => {
	const pickedValues = values(pick(rfpProfile, 'companyId', 'companyName'));
	return some(pickedValues, value => {
		return isNil(value) ? false : includes(toLower(value), toLower(searchTerm));
	});
};

const RfpProfileListItem: React.FC<ListItemProps> = ({ index, style, data }) => {
	const { formatMessage } = useIntl();

	const { filteredRfpProfiles, selectedRfpProfiles, onSelectClick } = data;
	const rfpProfile = filteredRfpProfiles[index];
	const isSelected = !isNil(find(selectedRfpProfiles, { companyId: rfpProfile.companyId }));

	return (
		<ListItem key={rfpProfile.companyId} style={style} disablePadding>
			<ListItemButton selected={isSelected} onClick={onSelectClick(rfpProfile)}>
				<ListItemIcon>
					<BlobDownloader url={rfpProfile?.logo}>{src => <Avatar variant='logo3' src={src} />}</BlobDownloader>
				</ListItemIcon>
				<ListItemText
					id={`rfpProfileListItemText${index}`}
					primary={rfpProfile.companyName}
					secondary={
						<>
							<Chip component='span' variant='filled' label={rfpProfile.companyId} sx={{ mb: 0.5 }} />
							<Typography component='span' sx={{ display: 'block', fontSize: 'inherit' }}>
								{join(
									map(rfpProfile.categories, rfpCategory => {
										return formatMessage({ id: `rfpCategory.${rfpCategory}` });
									}),
									', '
								)}
							</Typography>
						</>
					}
				/>
				<ListItemSecondaryAction>
					<Checkbox
						edge='start'
						checked={isSelected}
						tabIndex={-1}
						disableRipple
						inputProps={{ 'aria-labelledby': `rfpProfileListItemText${index}` }}
					/>
				</ListItemSecondaryAction>
			</ListItemButton>
		</ListItem>
	);
};

const SelectPublicRfpProfilesDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const dialogOpen = useSelector(isDialogOpen('SelectPublicRfpProfilesDialog'));
	const dialogData = useSelector(getDialogData('SelectPublicRfpProfilesDialog'));

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const [fetchToken] = React.useState<PaginationToken>({ take: 1000, nextPartitionKey: null, nextRowKey: null });

	const { data: rfpProfiles, isLoading, isError } = useGetPublicProfilesQuery({ token: fetchToken }, { skip: isNil(companyId) });

	const [selectedRfpProfiles, setSelectedRfpProfiles] = React.useState<RfpProfile[]>([]);
	const [filteredRfpProfiles, setFilteredRfpProfiles] = React.useState<RfpProfile[]>([]);
	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const [categories, setCategories] = React.useState<RfpCategory[]>([]);

	const timeoutRef = React.useRef<number>();

	React.useEffect(() => {
		if (!isNil(dialogData?.selectedRfpProfiles)) {
			setSelectedRfpProfiles(dialogData?.selectedRfpProfiles);
		}
	}, [dialogData]);

	React.useEffect(() => {
		window.clearTimeout(timeoutRef.current);
		timeoutRef.current = window.setTimeout(() => {
			const nextFilteredRfpProfile = filter(rfpProfiles?.data, rfpProfile => {
				const hits = [];
				if (!isEmpty(categories)) {
					hits.push(filterRfpProfileByCategories(rfpProfile, categories));
				}
				if (!isEmpty(searchTerm)) {
					hits.push(filterRfpProfileBySearchTerm(rfpProfile, searchTerm));
				}
				return isEmpty(hits) ? true : every(hits);
			});
			setFilteredRfpProfiles(nextFilteredRfpProfile);
		}, 300);
		return () => {
			window.clearTimeout(timeoutRef.current);
		};
	}, [rfpProfiles, searchTerm, categories]);

	const handleSelectClick = React.useCallback(
		(rfpProfile: RfpProfile) => () => {
			setSelectedRfpProfiles(prevSelectedRfpProfiles => {
				const nextSelectedRfpProfiles = clone(prevSelectedRfpProfiles);
				if (isNil(rfpProfile)) {
					return nextSelectedRfpProfiles;
				}
				const index = findIndex(nextSelectedRfpProfiles, { companyId: rfpProfile?.companyId });
				if (index !== -1) {
					nextSelectedRfpProfiles.splice(index, 1);
				} else {
					nextSelectedRfpProfiles.push(rfpProfile);
				}
				return nextSelectedRfpProfiles;
			});
		},
		[]
	);

	const handleDialogClose = React.useCallback(() => {
		dispatch(closeDialog({ name: 'SelectPublicRfpProfilesDialog' }));
	}, [dispatch]);

	const handleDialogSubmit = React.useCallback(() => {
		dialogData?.onSubmit(selectedRfpProfiles);
		dispatch(closeDialog({ name: 'SelectPublicRfpProfilesDialog' }));
	}, [dispatch, dialogData, selectedRfpProfiles]);

	return (
		<>
			<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='smd' fullWidth>
				<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					<FormattedMessage id='selectPublicRfpProfiles.title' />
				</DialogTitle>
				<DialogContent>
					{isError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='getPublicRfpProfiles.error.message' />
						</Alert>
					)}
					<Stack>
						<TextField
							name='searchTerm'
							label={formatMessage({ id: 'selectPublicRfpProfiles.searchTerm.label' })}
							placeholder={formatMessage({ id: 'selectPublicRfpProfiles.searchTerm.placeholder' })}
							value={searchTerm}
							onChange={event => setSearchTerm(event.target.value)}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
						<RfpCategoryAutocomplete
							value={categories}
							onChange={(event, value) => setCategories(value)}
							renderInput={params => (
								<TextField
									{...params}
									label={formatMessage({ id: 'selectPublicRfpProfiles.categories.label' })}
									placeholder={formatMessage({ id: 'selectPublicRfpProfiles.categories.placeholder' })}
								/>
							)}
						/>
					</Stack>
					{isLoading ? (
						<>
							<Skeleton height={30} />
							<Skeleton height={30} />
							<Skeleton height={30} />
							<Skeleton height={30} />
						</>
					) : (
						<List>
							{isEmpty(filteredRfpProfiles) && (
								<Typography variant='lightText'>
									<FormattedMessage id='selectPublicRfpProfiles.empty' />
								</Typography>
							)}
							<FixedSizeList
								height={400}
								width='100%'
								itemSize={91}
								itemCount={filteredRfpProfiles.length}
								itemData={{ filteredRfpProfiles, selectedRfpProfiles, onSelectClick: handleSelectClick }}
							>
								{RfpProfileListItem}
							</FixedSizeList>
						</List>
					)}
				</DialogContent>
				<DialogActions>
					<Box sx={{ flex: 1 }}>
						<Typography color='text.muted'>
							<FormattedMessage
								id='selectPublicRfpProfiles.selectedCount'
								values={{ selectedCount: selectedRfpProfiles.length }}
							/>
						</Typography>
					</Box>
					<Button variant='text' size='small' color='neutral' onClick={handleDialogClose}>
						<FormattedMessage id='selectPublicRfpProfiles.cancel' />
					</Button>
					<Button size='small' onClick={handleDialogSubmit}>
						<FormattedMessage id='selectPublicRfpProfiles.submit' />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SelectPublicRfpProfilesDialog;
