import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { Breakpoint } from '@mui/system';
import { forwardRef } from 'react';

const StyledDialogTitle = (props: any) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle {...other} sx={{ color: 'primary.main' }}>
			<Grid container justifyContent='space-between' alignItems='center'>
				<Grid item>{children}</Grid>
				{onClose && (
					<Grid>
						<IconButton aria-label='close' onClick={onClose} sx={{ marginRight: '-0.8rem' }}>
							<CloseIcon color='primary' />
						</IconButton>
					</Grid>
				)}
			</Grid>
		</DialogTitle>
	);
};

const StyledDialogContent = (props: any) => {
	const { children, ...other } = props;

	return (
		<DialogContent id='customized-dialog-title' sx={{ p: 0 }} {...other}>
			{children}
		</DialogContent>
	);
};

const StyledDialogActions = (props: any) => {
	const { children, ...other } = props;

	return (
		<DialogActions sx={{ p: 2 }} {...other}>
			{children}
		</DialogActions>
	);
};

const Transition = forwardRef(function Transition(props, ref) {
	// @ts-ignore
	return <Slide direction='up' ref={ref} {...props} />;
});

type ModalDialogProps = {
	children: any;
	title: string;
	actions: any;
	open: boolean;
	onClose: any;
	position?: string;
	top?: string;
	left?: string;
	fixedHeight?: boolean;
	maxHeight?: string | number;
	maxWidth?: false | Breakpoint | undefined;
};

const ModalDialog: React.FC<ModalDialogProps> = ({
	children,
	title = null,
	actions = null,
	open = false,
	onClose = null,
	position = 'relative',
	top = 'unset',
	left = 'center',
	fixedHeight = null,
	maxHeight = null,
	maxWidth = 'md',
	...other
}) => {
	return (
		<Dialog
			fullWidth
			maxWidth={maxWidth}
			scroll='paper'
			open={open}
			onClose={onClose} // @ts-ignore
			TransitionComponent={Transition} // @ts-ignore
			sx={{
				'& .MuiDialog-paper': {
					position: position,
					top: top,
					left: left,
					height: fixedHeight && `calc(100% - ${fixedHeight}rem)`,
					maxHeight: maxHeight || 'calc(100% - 64px)',
				},
			}}
			{...other}
		>
			{title && (
				<StyledDialogTitle id='customized-dialog-title' onClose={onClose}>
					{title}
				</StyledDialogTitle>
			)}
			<StyledDialogContent id='customized-dialog-content' dividers>
				{children}
			</StyledDialogContent>
			{actions && <StyledDialogActions id='customized-dialog-actions'>{actions}</StyledDialogActions>}
		</Dialog>
	);
};

export default ModalDialog;
