import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';

const SimplePopover = ({ anchorElement, isopen, handleClose, cancelText, bodyText, width = '50rem', attachmentId, attachmentsLoading = false, downloading = false, downloadFile, removeFile = null }) => {
    const intl = useIntl();

    const id = isopen ? 'simple-popover' : undefined;

    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmPopperOpen, setConfirmPopperOpen] = useState(false);

    const confirm = e => {
        setConfirmPopperOpen(true);
        setAnchorEl(e.currentTarget);
    };

    const closeConfirmPopper = () => {
        setAnchorEl(null);
        setConfirmPopperOpen(false);
    };

    return (
        <>
            <Popover
                id={id}
                open={isopen}
                anchorEl={anchorElement}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            >
                <Box sx={{ p: 3, pt: 4, width: width }}>{bodyText}</Box>
                <LeftRightContainer
                    sx={{ p: 2 }}
                    left={
                        <Button variant='text' color='primary' onClick={handleClose}>{cancelText}</Button>
                    }
                    right={
                        <Stack direction='row' spacing={2}>
                            {removeFile &&
                                <Button size='small' color='error' disabled={attachmentsLoading} startIcon={<DeleteIcon />} onClick={e => confirm(e)}>
                                    {intl.formatMessage({ id: 'common.delete' })}
                                    {attachmentsLoading && <ButtonSpinner />}
                                </Button>
                            }
                            <Button size='small' color='primary' disabled={!!downloading} startIcon={<FileDownloadOutlinedIcon />} onClick={downloadFile}>
                                {intl.formatMessage({ id: 'attachments.table.load' })}
                                {downloading && <ButtonSpinner />}
                            </Button>
                        </Stack>
                    }
                />
            </Popover>
            <ConfirmPopper
                confirmAction={() => { closeConfirmPopper(); removeFile(attachmentId); }}
                handleClose={closeConfirmPopper}
                isopen={confirmPopperOpen}
                anchorElement={anchorEl}
                confirmText={intl.formatMessage({ id: 'attachment.delete.confirmText' })}
                cancelText={intl.formatMessage({ id: 'attachment.delete.cancelText' })}
                confirmBodyText={intl.formatMessage({ id: 'attachment.delete.confirmBodyText' })}
            />
        </>
    );
};

export default SimplePopover;