import { isNil } from 'lodash';
import { useSelector } from 'react-redux';

import { getCompanyId } from 'utils/auth/company';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchScenarioQuery } from 'containers/Scenario/scenarioV2Api';

import PageSpinner from 'components/Spinner/PageSpinner';
import FinancialPlan from './FinancialPlan';

const FinancialPlanPage = () => {
	const companyId = getCompanyId();

	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: isNil(companyId) });

	const { data: scenario, isLoading: isScenarioLoading } = useFetchScenarioQuery(
		{ companyId, businessId: company?.businessId },
		{ skip: isNil(companyId) || isCompanyLoading || isCompanyFetching }
	);

	const financialPlan = useSelector(state => state.financialPlan);
	const financialPlanLoading = financialPlan?.loading;
	const financialPlanData = financialPlan?.financialPlan;
	const publishedFinancialPlan = financialPlan?.publishedFinancialPlan;

	const kanban = useSelector(state => state.kanban);
	const projects = kanban?.projects;
	const mock = financialPlanData?.mock && projects?.mock;
	const projectsLoading = !projects?.isLoaded;

	const instruments = useSelector(state => state.instruments);
	const instrumentsData = instruments?.instruments?.data;
	const instrumentsLoading = instruments?.instruments?.loading;

	const loading = (isCompanyLoading || isScenarioLoading || financialPlanLoading || projectsLoading || instrumentsLoading) ?? false;

	return loading ? (
		<PageSpinner />
	) : (
		<FinancialPlan
			company={company}
			financialPlan={financialPlanData}
			publishedFinancialPlan={publishedFinancialPlan}
			projects={projects.data}
			mock={mock}
			scenario={scenario}
			instruments={instrumentsData}
		/>
	);
};

export default FinancialPlanPage;
