const companyNoteBriefTemplate = `
<h3>Liiketoiminnan kuvaus:</h3>
<p><i>Kuvaa tähän asiakkaan liiketoiminnan perusteet</i></p>

<h3>Kehityskustannukset:</h3>
<p><i>Millaisia kehityskustannuksia asiakkaalla on.</i></p>

<h3>Aikaisemmat instrumentit:</h3>
<p><i>Onko asiakkaalla aiempia rahoitusinstrumentteja.</i></p>

<h3>Yrityksen taloustilanne:</h3>
<p><i>Kuvaa millainen asiakkaan taloustilanne on nykyhetkellä.</i></p>

<h3>Muut muistiinpanot:</h3>
<p><i>Muista muisiinpanoja asiakkaan tilanteesta tai potentiaalista.</i></p>
`;

export default companyNoteBriefTemplate;
