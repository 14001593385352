import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Box, Divider, InputAdornment, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Attachments from 'components/Attachments';
import BoxContainer from 'components/Containers/BoxContainer';
import { FormControlledCurrencyInput, FormControlledDatepicker, FormControlledInput, FormControlledSelect } from 'components/Form';
import ProjectCostAttachments from '../ProjectCostAttachments';

import CurrencyValue from 'components/Currency/CurrencyValue';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import FromControlledHiddenField from 'components/Form/FromControlledHiddenField';
import { getDirtyKeys } from 'components/Form/utils/utils';
import { useFetchProjectQuery } from 'containers/Projects/projectRTKApi';
import { useParams } from 'react-router-dom';
import {
	CostCategory,
	CostCategoryProviderEnumValues,
	Instrument,
	ProjectCost,
	ProjectTask,
	costCategoryArray,
	hasProviderFlag,
	salaryExpenseTypeEnum,
} from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import { isAdminToken } from 'utils/auth/token';
import { Column } from '../../../slices';
import { getProjectCostValidationSchema } from '../CostValidationSchema';

export const salaryExpenseTypes = {
	monetaryConst: 'monetary',
	percentageConst: 'percentage',
};

export const calculateExpenseAmount = (
	type: salaryExpenseTypeEnum | null | undefined,
	amount: number | null | undefined,
	salary: number
) => {
	if (!amount || !salary) return 0;

	if (type === salaryExpenseTypes.monetaryConst) return amount;

	return Math.round(salary * (amount / 100) * 10) / 10;
};

export const calculatedExpensePercentage = (type: string | null | undefined, amount: number | null | undefined, salary: number) => {
	if (!amount || !salary) return 0;

	if (type === salaryExpenseTypes.percentageConst) return amount;

	return Math.round((amount / salary) * 100 * 10) / 10;
};

export const calculateSideCosts = (type: string, salaryValue: number, amountAppliedExpense: number) => {
	return type === salaryExpenseTypes.percentageConst
		? Math.round(salaryValue * (amountAppliedExpense / 100) * 10) / 10
		: Math.round((amountAppliedExpense / salaryValue) * 100 * 10) / 10;
};

export type SalaryExpenseProps = {
	salaryValue: number;
	name: string;
	cost: ProjectCost;
	control: any;
	errors: any;
	watch: any;
	setValue: any;
	hideLabel?: boolean;
};

/**
 * Salary expense control
 *
 * @param {*} param0
 * @returns
 */
export const SalaryExpense = ({ salaryValue, name, cost, control, errors, watch, setValue, hideLabel }: SalaryExpenseProps) => {
	const intl = useIntl();

	const [salaryExpenseType, setSalaryExpenseType] = useState(cost?.salaryExpenseType ?? salaryExpenseTypes.percentageConst);

	const amountAppliedExpense = watch(name);
	const sideCosts = (type: string) => calculateSideCosts(type, salaryValue, amountAppliedExpense);

	const handleExpenseChange = (__: any, type: string) => {
		setSalaryExpenseType(type);

		const sideCostsVal = sideCosts(
			type === salaryExpenseTypes.monetaryConst ? salaryExpenseTypes.percentageConst : salaryExpenseTypes.monetaryConst
		);

		setValue(name, sideCostsVal);
		setValue(`${name}Type`, type);
	};

	useEffect(() => {
		setValue(`${name}Type`, salaryExpenseType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salaryExpenseType]);

	return (
		<>
			<Stack sx={{ width: !hideLabel ? '100%' : '60%' }} direction='row' alignContent='space-between' alignItems='center'>
				{!hideLabel && <Box sx={{ flexGrow: 1 }}>{intl.formatMessage({ id: 'project.cost.form.salary.expense' })}</Box>}

				<Box sx={{ textAlign: !hideLabel ? 'right' : 'left' }}>
					{salaryExpenseType === salaryExpenseTypes.percentageConst ? (
						<CurrencyValue value={sideCosts(salaryExpenseType)} />
					) : (
						`${sideCosts(salaryExpenseType)} %`
					)}
				</Box>
			</Stack>
			<FromControlledHiddenField
				name={`${name}Type`}
				defaultValue={cost?.salaryExpenseType ?? salaryExpenseTypes.percentageConst}
				control={control}
			/>
			<Stack direction='row'>
				<ToggleButtonGroup
					sx={{ mr: '.5rem' }}
					size='small'
					value={salaryExpenseType}
					exclusive
					onChange={handleExpenseChange}
					aria-label='salary expense'
				>
					<ToggleButton value={salaryExpenseTypes.monetaryConst} aria-label='left aligned'>
						€
					</ToggleButton>
					<ToggleButton value={salaryExpenseTypes.percentageConst} aria-label='centered'>
						%
					</ToggleButton>
				</ToggleButtonGroup>
				{/* @ts-ignore */}
				<FormControlledInput
					sx={{ minWidth: '8rem' }}
					defaultValue={cost?.salaryExpense}
					name={name}
					control={control}
					error={errors?.salaryExpense?.message}
					type='number'
					step={1}
					size='small'
					startAdornment={
						<InputAdornment position='start'>
							{salaryExpenseType === salaryExpenseTypes.percentageConst ? '%' : '€'}
						</InputAdornment>
					}
				/>
			</Stack>
			<Divider sx={{ mt: '1rem' }} />
		</>
	);
};

type ProjectCostFormType = {
	cost: ProjectCost;
	onSubmit: any;
	passIsDirty: any;
	isTaskPage: boolean;
	instrument?: Instrument;
};

const ProjectCostForm: React.FC<ProjectCostFormType> = forwardRef(
	({ cost, onSubmit, passIsDirty, isTaskPage = false, instrument }, formRef) => {
		const intl = useIntl();

		const { id }: { id: string } = useParams();
		const companyId = getCompanyId();
		const isAdmin = isAdminToken();

		const {
			data: project,
			isLoading: isProjectLoading,
			isFetching: isProjectFetching,
		} = useFetchProjectQuery({ companyId, projectId: id, draft: isAdmin, withData: true }, { skip: !companyId || !id });

		const costSchema = getProjectCostValidationSchema(intl);

		const {
			handleSubmit,
			control,
			setValue,
			register,
			unregister,
			watch,
			formState: { dirtyFields, errors },
		} = useForm({
			defaultValues: useMemo(() => {
				return cost;
			}, [cost]),
			resolver: yupResolver(costSchema),
		});

		const dirtyKeys = getDirtyKeys(dirtyFields);
		const isDirty = dirtyKeys.length > 0;

		useEffect(() => {
			register('files');

			return () => {
				unregister('files');
			};
		}, [register, unregister]);

		useEffect(() => {
			passIsDirty && passIsDirty(isDirty, dirtyFields);
		}, [isDirty, dirtyFields, passIsDirty]);

		// @ts-ignore
		const tasks = useSelector(state => state.kanban?.tasks); // @ts-ignore
		const columns = useSelector(state => state.kanban?.columns); // @ts-ignore

		const category = watch('category');
		const amountApplied = watch('amountApplied');

		const filterByProvider = (category: CostCategory) => {
			if (!instrument) return true;

			if (instrument?.provider === 'Business Finland') return hasProviderFlag(category.provider, CostCategoryProviderEnumValues.BF);
			if (instrument?.provider === 'ELY-keskus') return hasProviderFlag(category.provider, CostCategoryProviderEnumValues.ELY);

			return true;
		};

		return (
			// @ts-ignore
			<form ref={formRef} noValidate onSubmit={handleSubmit(data => onSubmit(data))}>
				<BoxContainer>
					<Controller
						name='id'
						defaultValue={cost?.id ?? ''}
						control={control}
						render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
					/>
					<Controller
						name='eTag'
						defaultValue={cost?.eTag ?? ''}
						control={control}
						render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
					/>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={8}>
							<Grid container direction='column' spacing={2}>
								<Grid item>
									<FormControlledSelect
										defaultValue={cost?.category ?? ''}
										name='category'
										control={control}
										error={errors?.category?.message}
										label={intl.formatMessage({ id: 'project.cost.form.category' })}
										options={costCategoryArray?.filter(filterByProvider).map(category => {
											return {
												value: category.value,
												label: intl.formatMessage({ id: `project.cost.categories.${category.value}` }),
											};
										})}
									/>
								</Grid>
								<Grid item>
									{/* @ts-ignore */}
									<FormControlledInput
										defaultValue={cost?.name ?? ''}
										name='name'
										control={control}
										error={errors?.name?.message}
										label={intl.formatMessage({ id: 'project.cost.form.name' })}
									/>
								</Grid>
								<Grid item>
									{/* 
								// @ts-ignore */}
									<FormControlledInput
										defaultValue={cost?.supplierName ?? ''}
										name='supplierName'
										control={control}
										error={errors?.supplierName?.message}
										label={intl.formatMessage({ id: 'project.cost.form.supplierName' })}
									/>
								</Grid>
								{category === 'salary' && (
									<Grid item>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={8}>
												{/* @ts-ignore */}
												<FormControlledInput
													defaultValue={cost?.employeeName ?? ''}
													name='employeeName'
													control={control}
													error={errors?.employeeName?.message}
													label={intl.formatMessage({ id: 'project.cost.form.employeeName' })}
												/>
											</Grid>
											<Grid item xs={12} sm={8}>
												{/* @ts-ignore */}
												<FormControlledInput
													defaultValue={cost?.employeeRole ?? ''}
													name='employeeRole'
													control={control}
													error={errors?.employeeRole?.message}
													label={intl.formatMessage({ id: 'project.cost.form.employeeRole' })}
												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												{/* @ts-ignore */}
												<FormControlledInput
													type='number'
													defaultValue={cost?.workEstimate ?? ''}
													name='workEstimate'
													control={control}
													error={errors?.workEstimate?.message}
													label={intl.formatMessage({ id: 'project.cost.form.workEstimate' })}
												/>
											</Grid>
										</Grid>
									</Grid>
								)}
								<Grid item>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											{/* 
										// @ts-ignore */}
											<FormControlledDatepicker
												defaultValue={cost?.startDate ?? ''}
												name='startDate'
												control={control}
												error={errors?.startDate?.message}
												label={intl.formatMessage({ id: 'project.cost.form.startDate' })}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											{/* 
										// @ts-ignore */}
											<FormControlledDatepicker
												defaultValue={cost?.endDate ?? ''}
												name='endDate'
												control={control}
												error={errors?.endDate?.message}
												label={intl.formatMessage({ id: 'project.cost.form.endDate' })}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									{/* 
								// @ts-ignore */}
									<FormControlledDatepicker
										defaultValue={cost?.paidDate ?? ''}
										name='paidDate'
										control={control}
										error={errors?.paidDate?.message}
										label={intl.formatMessage({ id: 'project.cost.form.paidDate' })}
									/>
								</Grid>
								<Grid item>
									<FormControlledSelect
										defaultValue={cost?.projectTaskId ?? ''}
										name='projectTaskId'
										control={control}
										error={errors?.projectTaskId?.message}
										label={intl.formatMessage({ id: 'project.cost.form.projectTaskId' })}
										options={tasks?.map((task: ProjectTask) => {
											return {
												value: task.projectTaskId,
												label: task.projectTaskName,
											};
										})}
										disabled={isTaskPage}
									/>
								</Grid>
								<Grid item>
									<FormControlledSelect
										defaultValue={cost?.phase ?? ''}
										name='phase'
										control={control}
										error={errors?.phase?.message}
										label={intl.formatMessage({ id: 'project.cost.form.phase' })}
										options={columns?.map((column: Column) => {
											return {
												value: column.id,
												label: intl.formatMessage({ id: `project.column.name.${column.id}` }),
											};
										})}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Grid container direction='column' spacing={2}>
								<Grid item>
									<FormControlledCurrencyInput
										defaultValue={cost?.amountApplied ?? 0}
										name='amountApplied'
										control={control}
										error={undefined}
										label={intl.formatMessage({ id: 'project.cost.form.amountApplied' })}
									/>
								</Grid>
								{category === 'salary' && (
									<Grid item xs={12}>
										<SalaryExpense
											cost={cost}
											salaryValue={amountApplied}
											name='salaryExpense'
											// expenseDefaultValue={cost?.salaryExpense}
											// expenseDefaultType={cost?.salaryExpenseType ?? 'percentage'}
											{...{ setValue, watch, control, errors }}
										/>
									</Grid>
								)}

								<Grid item>
									<FormControlledCurrencyInput
										defaultValue={cost?.amountGranted ?? 0}
										name='amountGranted'
										control={control}
										error={undefined}
										label={intl.formatMessage({ id: 'project.cost.form.amountGranted' })}
									/>
								</Grid>
								<Grid item>
									<FormControlledCurrencyInput
										defaultValue={cost?.amountReported ?? 0}
										name='amountReported'
										control={control}
										error={undefined}
										label={intl.formatMessage({ id: 'project.cost.form.amountReported' })}
									/>
								</Grid>
								<Grid item>
									<FormControlledCurrencyInput
										defaultValue={cost?.amountPaid ?? 0}
										name='amountPaid'
										control={control}
										error={undefined}
										label={intl.formatMessage({ id: 'project.cost.form.amountPaid' })}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							{/* 
						// @ts-ignore */}
							<FormControlledWysiwyg
								marginTop={0}
								inline={true}
								fixedToolbar={true}
								toolbar={true}
								name='description'
								control={control}
								defaultValue={cost?.description ?? ''}
								error={errors?.description?.message}
								label={intl.formatMessage({ id: 'project.cost.form.description' })}
								instructions={intl.formatMessage({ id: 'project.cost.form.description.instructions' })}
								placeholder={intl.formatMessage({ id: 'project.cost.form.description.placeholder' })}
							/>
						</Grid>
						<Grid item xs={12}>
							<Attachments setValue={setValue} loading={undefined} />
						</Grid>
					</Grid>
					<ProjectCostAttachments searchPattern={cost?.id} project={project} />
				</BoxContainer>
			</form>
		);
	}
);

export default ProjectCostForm;
