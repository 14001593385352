import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { getCompanyId, isAdvancedToken } from 'utils/auth/company';
import { regexCompany } from 'utils/constants/regex';

const useStyles = makeStyles(theme => ({
	title: {
		marginLeft: theme.spacing(2),
		fontWeight: 300,
	},
	instrument: {
		marginLeft: theme.spacing(2),
		fontSize: '1rem',
		fontWeight: 300,
	},
	button: {
		marginLeft: theme.spacing(2),
		padding: 0,
		color: theme.palette.primary.main,
		// '&:hover, &:focus': {
		//     textDecoration: 'underline',
		// },
	},
}));

const TimelineMessageContent = () => {
	const classes = useStyles();
	const intl = useIntl();
	const { pathname } = useLocation();
	const history = useHistory();
	const isAdvanced = isAdvancedToken();

	const companyId = getCompanyId();

	return (
		<Box>
			<Typography component='p' gutterBottom className={classes.instrument}>
				{intl.formatMessage({ id: 'financial.plan.message.content' })}
			</Typography>
			<Button
				onClick={
					regexCompany.test(pathname) && pathname !== `/company/${companyId}/financialplan`
						? () => history.push(`/company/${companyId}/financialplan`)
						: !isAdvanced && pathname !== '/financialplan'
							? () => history.push('/financialplan')
							: null
				}
				className={classes.button}
			>
				{intl.formatMessage({ id: 'financial.plan.open.button' })} {'>>'}
			</Button>
		</Box>
	);
};

export default TimelineMessageContent;
