import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import {
	beigeColor,
	blueColor,
	borderColor,
	errorColor,
	grayishBlue,
	grey200Color,
	grey300Color,
	grey400Color,
	grey500Color,
	grey600Color,
	grey700Color,
	grey900Color,
	greyColor,
	lightBeigeColor,
	lightBoxFooter,
	lightGreyColor,
	lightGreyColor2,
	lightGreyColor3,
	lightGreyColor4,
	lightGreyColor5,
	lightPurpleColor,
	menuActiveColor,
	pillColor,
	pillTextColor,
	primaryColor,
	successColor,
	successLightColor,
	warningColor,
	whiteColor,
} from './colors';

export const colors = {
	green: '#057871',
	purple: '#482E51',
	yellow: '#D4A712',
	darkRed: '#8e0e44',
	lightGreen: '#459F78',
	white: '#FFFFFF',
	grayscale900: '#303F5A',
	grayscale700: '#5A647C',
	grayscale600: '#73798C',
	grayscale500: '#8F94A3',
	grayscale400: '#C5C3C6',
	grayscale300: '#E7E4DF',
	grayscale200: '#EEECE9',
	grayscale100: '#F7F5F0',
	grayscale50: '#FBFAF8',
};

const fonts = {
	aleo: 'Aleo',
	poppins: 'Poppins',
};

const theme = createTheme(
	deepmerge(
		{
			//spacing: [0, 3.5, 7, 14, 28, 56],
			breakpoints: {
				keys: ['xs', 'xsm', 'sm', 'smd', 'md', 'mdl', 'lg', 'lgx', 'xl'],
				values: {
					xs: 0,
					xsm: 450,
					sm: 600,
					smd: 750,
					md: 960,
					mdl: 1050,
					lg: 1280,
					lgx: 1450,
					xl: 1920,
				},
			},
			typography: {
				fontFamily: fonts.poppins,
				fontSize: 14,
				h1: {
					fontFamily: fonts.aleo,
					fontWeight: 700,
					fontSize: '2rem',
					lineHeight: 1.2,
					color: colors.purple,
				},
				h2: {
					fontFamily: fonts.aleo,
					fontWeight: 600,
					fontSize: '1.3rem',
					lineHeight: 1.2,
					color: colors.purple,
				},
				h3: {
					fontFamily: fonts.aleo,
					fontWeight: 700,
					fontSize: '1.2rem',
					lineHeight: 1.2,
					color: colors.purple,
				},
				h4: {
					fontFamily: fonts.poppins,
					fontWeight: 500,
					fontSize: '1rem',
					lineHeight: 1.2,
					fontStyle: 'normal',
					color: colors.purple,
					marginTop: '1rem',
					marginBottom: '1rem',
				},
				subtitle1: {
					fontFamily: fonts.poppins,
					fontWeight: 500,
					fontSize: '1rem',
					lineHeight: 1.2,
				},
				subtitle2: {
					fontFamily: fonts.poppins,
					fontWeight: 500,
					fontSize: '0.875rem',
					lineHeight: 1.2,
				},
				button: {
					fontFamily: fonts.poppins,
					fontWeight: 500,
					fontSize: '1rem',
					lineHeight: 1.2,
					textTransform: 'none',
				},
				label: {
					fontFamily: fonts.poppins,
					fontWeight: 500,
					fontSize: '0.875rem',
					lineHeight: 1.5,
				},
				listItem: {
					marginTop: '.2rem',
					fontSize: '.875rem',
					display: '-webkit-box',
					WebkitLineClamp: 3,
					WebkitBoxOrient: 'vertical',
					overflow: 'hidden',
					maxHeight: '4rem',
				},
				lightText: {
					fontFamily: fonts.poppins,
					color: colors.grayscale600,
					lineHeight: 1.2,
					fontSize: '0.875rem',
				},
				stamp: {
					fontFamily: fonts.poppins,
					color: '#bbb',
					lineHeight: 1,
					fontSize: '0.875rem',
				},
			},
			palette: {
				primary: {
					main: primaryColor,
					secondary: lightGreyColor,
					white: whiteColor,
					darkYellow: warningColor,
					beige: beigeColor,
					grey: greyColor,
					grayishBlue: grayishBlue,
				},
				secondary: {
					main: lightGreyColor2,
					light: lightGreyColor3,
					line: lightGreyColor4,
					grey: lightGreyColor5,
					lightBeige: lightBeigeColor,
					grey200: grey200Color,
					grey300: grey300Color,
					grey400: grey400Color,
					grey500: grey500Color,
					grey600: grey600Color,
					grey700: grey700Color,
					grey900: grey900Color,
					border: borderColor,
					footerGrey: lightBoxFooter,
					menuActive: menuActiveColor,
				},
				error: {
					main: errorColor,
				},
				warning: {
					main: warningColor,
				},
				success: {
					main: successColor,
					secondary: successLightColor,
				},
				text: {
					primary: colors.grayscale900,
					secondary: colors.grayscale700,
					muted: colors.grayscale600,
				},
				info: {
					main: blueColor,
				},
				textPrimary: {
					main: greyColor,
					secondary: lightPurpleColor,
					white: whiteColor,
					beige: beigeColor,
				},
				background: {
					default: whiteColor,
					main: whiteColor,
				},
				white: {
					main: whiteColor,
				},
				pill: {
					main: pillColor,
					secondary: pillTextColor,
				},
			},
			overrides: {
				MuiDrawer: {
					paperAnchorRight: {
						backgroundColor: lightGreyColor,
					},
				},
				MuiPaper: {
					root: {
						backgroundColor: lightGreyColor,
					},
				},
				MuiBadge: {
					badge: {
						fontWeight: 900,
					},
					colorSecondary: {
						backgroundColor: successColor,
						color: whiteColor,
					},
				},
				MuiChip: {
					label: {
						fontSize: '1rem',
					},
				},
				MuiPickersCalendarHeader: {
					switchHeader: {
						backgroundColor: primaryColor,
						'& p': {
							color: whiteColor,
						},
					},
					dayLabel: {
						color: primaryColor,
					},
					iconButton: {
						backgroundColor: primaryColor,
						color: whiteColor,
					},
				},
				MuiPickersDay: {
					day: {
						color: primaryColor,
					},
					daySelected: {
						backgroundColor: primaryColor,
						'&:hover': {
							backgroundColor: primaryColor,
							opacity: 0.9,
						},
					},
					dayDisabled: {
						color: '#ccc',
					},
					current: {
						color: primaryColor,
					},
				},
			},
			components: {
				MuiInputBase: {
					styleOverrides: {
						root: {
							'&.Mui-disabled': {
								'&:before': {
									border: 'none',
									borderBottomStyle: 'hidden !important',
								},
								'& input': {
									textFillColor: 'rgba(0, 0, 0, 0.7)',
								},
								'& select': {
									textFillColor: 'rgba(0, 0, 0, 0.7)',
									'& option': {
										textFillColor: 'rgba(0, 0, 0, 0.7)',
									},
								},
							},
						},
					},
				},
				MuiNativeSelect: {
					styleOverrides: {
						root: {
							'&.Mui-disabled': {
								'& select option:disabled': {
									margin: '1rem',
									color: 'black',
									textFillColor: 'rgba(0, 0, 0, 0.7)',
								},
							},
						},
					},
				},
				MuiTypography: {
					styleOverrides: {
						root: {
							//fontSize: '.9rem'
						},
					},
				},
			},
		},
		locales.fiFI
	)
);

export default theme;
