import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Skeleton,
	Typography,
} from '@mui/material';
import { isEmpty, isNil, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { getCompany, getCompanyId } from 'utils/auth/company';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { openPopover } from 'containers/Marketplace/popoverSlice';
import {
	useFetchRfpProfileInfoItemsQuery,
	useRemoveRfpProfileInfoItemMutation,
	useUpsertRfpProfileInfoItemMutation,
} from 'containers/Marketplace/rfpProfileApi';

type Props = Omit<FormControlProps, 'onChange'> & { label?: string | null; helperText?: string | null };

const RfpProfileInfoItemList = React.forwardRef<any, Props>(({ label, helperText, ...otherProps }, ref) => {
	const dispatch = useDispatch();
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const { formatMessage } = useIntl();

	const {
		data: rfpProfileInfoItems,
		isLoading,
		isError: isRfpProfileInfoItemsError,
	} = useFetchRfpProfileInfoItemsQuery({ companyId: companyId }, { skip: isNil(companyId) });

	const [upsertRfpProfileInfoItem, { isError: isUpsertError }] = useUpsertRfpProfileInfoItemMutation();
	const [removeRfpProfileInfoItem, { isError: isRemoveError }] = useRemoveRfpProfileInfoItemMutation();

	return (
		<FormControl {...otherProps} ref={ref}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			{isRfpProfileInfoItemsError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpProfileInfoItems.error.message' />
				</Alert>
			)}
			{isUpsertError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='mutation.error.title' />
					</AlertTitle>
					<FormattedMessage id='upsertRfpProfileInfoItem.error.message' />
				</Alert>
			)}
			{isRemoveError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='mutation.error.title' />
					</AlertTitle>
					<FormattedMessage id='removeRfpProfileInfoItem.error.message' />
				</Alert>
			)}
			{isLoading ? (
				<>
					<Skeleton height={60} />
					<Skeleton height={60} />
					<Skeleton height={60} />
					<Skeleton height={60} />
				</>
			) : (
				<List>
					{isEmpty(rfpProfileInfoItems) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpProfileInfoItems.empty' />
						</Typography>
					)}
					{map(rfpProfileInfoItems, rfpProfileInfoItem => (
						<ListItem
							sx={{ mb: '1rem' }}
							key={rfpProfileInfoItem.infoId}
							secondaryAction={
								<IconButton
									color='error'
									onClick={event => {
										dispatch(
											openPopover({
												name: 'ConfirmRemovePopover',
												data: {
													anchorEl: event.currentTarget,
													text: formatMessage({ id: 'rfpProfileInfoItems.confirmRemove' }),
													description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
													onSubmit: async () => {
														try {
															await removeRfpProfileInfoItem({
																companyId,
																infoItemId: rfpProfileInfoItem.infoId,
															}).unwrap();
															toast.success(
																formatMessage({ id: 'removeRfpProfileInfoItem.success.message' })
															);
														} catch (error) {
															console.error(error);
														}
													},
												},
											})
										);
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
							disablePadding
						>
							<ListItemButton
								onClick={() => {
									dispatch(
										openDialog({
											name: 'UpsertRfpProfileInfoItemDialog',
											data: {
												fieldValues: rfpProfileInfoItem,
												onSubmit: async (fieldValues: any) => {
													try {
														await upsertRfpProfileInfoItem({
															companyId,
															rfpProfileInfoItem: fieldValues,
														}).unwrap();
														toast.success(formatMessage({ id: 'upsertRfpProfileInfoItem.success.message' }));
													} catch (error) {
														console.error(error);
													}
												},
											},
										})
									);
								}}
							>
								<ListItemText primary={rfpProfileInfoItem.title} secondary={rfpProfileInfoItem.value} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
			<Box>
				<Button
					variant='text'
					size='small'
					onClick={() => {
						dispatch(
							openDialog({
								name: 'UpsertRfpProfileInfoItemDialog',
								data: {
									fieldValues: {},
									onSubmit: async (fieldValues: any) => {
										try {
											await upsertRfpProfileInfoItem({ companyId, rfpProfileInfoItem: fieldValues }).unwrap();
											toast.success(formatMessage({ id: 'upsertRfpProfileInfoItem.success.message' }));
										} catch (error) {
											console.error(error);
										}
									},
								},
							})
						);
					}}
					startIcon={<AddIcon />}
				>
					<FormattedMessage id='rfpProfileInfoItems.add' />
				</Button>
			</Box>
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
});

export default RfpProfileInfoItemList;
