import React from 'react';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        backgroundColor: theme.palette.primary.white,
        maxWidth: '80%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '60%',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40%',
        },
    },
    popoverContent: {
        padding: theme.spacing(1),
        fontSize: '0.8rem',
    },
}));

const CustomPopover = ({ id, content, }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <HelpOutlineIcon color='primary' />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <Typography className={classes.popoverContent}>{content}</Typography>
            </Popover>
        </>
    );
};

export default CustomPopover;
