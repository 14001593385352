import SmsIcon from '@mui/icons-material/Sms';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
	collectLinks,
	createTemporaryLink,
	filterConversations,
	isCollaborationActive,
	resetLinks,
	toggleCollaborationDrawer,
} from 'containers/Collaboration/slices';

const CollaborationLauncher = ({ link, linkTitle, spacing = 0.25, size = 'medium', color = 'primary', sx, disabled = false }) => {
	const theme = useTheme();
	const xlUp = useMediaQuery(theme.breakpoints.up('xl'));

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(collectLinks(link));
		return () => {
			dispatch(resetLinks());
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!isCollaborationActive()) return null;

	const toggleDrawer = () => {
		if (xlUp) return;

		dispatch(toggleCollaborationDrawer());
	};

	const filterConversationsByLink = e => {
		e && e.stopPropagation();
		toggleDrawer();
		dispatch(filterConversations(link));
	};

	const createConversation = e => {
		e && e.stopPropagation();
		filterConversationsByLink();
		dispatch(createTemporaryLink({ link, linkTitle }));
	};

	if (disabled) return null;

	return (
		<Stack direction='row' spacing={spacing} sx={{ ...sx }}>
			<IconButton color={color} size={size} onClick={createConversation}>
				<SmsIcon
					sx={{
						'&:hover': {
							transform: 'scale(1.1)',
						},
					}}
				/>
			</IconButton>
		</Stack>
	);
};

export default CollaborationLauncher;
