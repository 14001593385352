import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import FormatCurrency from 'components/Formatters/FormatCurrency';
import { costTaskSelector } from 'containers/Projects/utils';
import ProjectPrintTableCell from '../ProjectPrintTableItems/ProjectPrintTableCell';
import ProjectPrintTableCellTitle from '../ProjectPrintTableItems/ProjectPrintTableCellTitle';

const PurchasingTable = ({ tasks, costs }) => {
	const intl = useIntl();

	let sum = 0;

	return (
		<TableContainer
			sx={{
				background: '#fff',
				'@media print': {
					overflowX: 'visible',
					pageBreakInside: 'avoid',
				},
			}}
		>
			<Table sx={{ width: '100%' }}>
				<TableBody>
					<TableRow>
						<ProjectPrintTableCellTitle tableColumnHeader={true} rowHeader={true}>
							{intl.formatMessage({ id: 'project.resourse.purchasing.services.table.subcontractor' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCellTitle tableColumnHeader={true}>
							{intl.formatMessage({ id: 'project.resourse.purchasing.services.table.work' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCellTitle sx={{ width: '250px', textAlign: 'right' }} tableColumnHeader={true} lastColumn={true}>
							{intl.formatMessage({ id: 'project.resourse.purchasing.services.table.cost.total' })}
						</ProjectPrintTableCellTitle>
					</TableRow>
					{tasks?.map(task => {
						const taskCosts = costTaskSelector(costs, task?.projectTaskId).sort(item => item.supplierName);

						return taskCosts.map(({ id, supplierName, name, amountApplied = 0, category }) => {
							if (category === 'salary') return null;

							sum += amountApplied;

							return (
								<TableRow key={id}>
									<ProjectPrintTableCell>{supplierName}</ProjectPrintTableCell>
									<ProjectPrintTableCell>{name}</ProjectPrintTableCell>
									<ProjectPrintTableCell sx={{ width: '200px', textAlign: 'right' }}>
										{amountApplied ? <FormatCurrency value={amountApplied} /> : '-'}
									</ProjectPrintTableCell>
								</TableRow>
							);
						});
					})}
					<TableRow>
						<ProjectPrintTableCell>
							<Typography component='span' sx={{ fontWeight: 600, fontSize: '1rem' }}>
								{intl.formatMessage({ id: 'project.cost.total' })}
							</Typography>
						</ProjectPrintTableCell>
						<ProjectPrintTableCell></ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ width: '200px', textAlign: 'right' }} lastColumn={true}>
							<Typography component='span' sx={{ fontWeight: 600, fontSize: '1rem' }}>
								{sum > 0 ? <FormatCurrency value={sum} /> : '-'}
							</Typography>
						</ProjectPrintTableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default PurchasingTable;
