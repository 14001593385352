import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.primary.main,
    },
    contentWrapper: {
        flex: 1,
    },
    contentContainer: {
        height: '100%',
    },
    innerContainer: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    title: {
        fontSize: '1.2rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
        },
        fontWeight: 700,
        color: theme.palette.primary.white,
    },
    description: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
        fontWeight: 600,
        color: theme.palette.primary.white,
        marginTop: theme.spacing(4),
    },
    ul: {
        marginTop: theme.spacing(4),
        paddingLeft: theme.spacing(2),
    },
    li: {
        color: theme.palette.primary.white,
        paddingLeft: theme.spacing(1),
    },
    liText: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
        color: theme.palette.primary.white,
    },
    imageWrapper: {
        height: '100%',
    },
    image: {
        maxWidth: '500px',
    },
    grants: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        paddingBottom: theme.spacing(4),
        color: theme.palette.primary.white,
    },
}));

const Slide3 = ({ maxHeight }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container direction='column' alignItems='center' className={classes.root} style={{ maxHeight }}>
            <Grid item className={classes.contentWrapper}>
                <Grid container direction='column' justifyContent='center' className={classes.contentContainer}>
                    <Grid item>
                        <Grid container className={classes.innerContainer}>
                            <Grid item xs={12} md={6}>
                                <Box textAlign='left'>
                                    <Typography className={classes.title}>
                                        Grants on kasvurahoitushakujen digitaalinen asiantuntija
                                    </Typography>
                                    <Typography className={classes.description}>
                                        Tehtävämme Grantsilla on tarjota apua yrityksille, jotka etsivät rahoitusta kasvunsa
                                        mahdollistamiseksi – Jotta loistavat kasvuideat saisivat ansaitsemansa rahoituksen.
                                    </Typography>
                                    <ul className={classes.ul}>
                                        <li className={classes.li}>
                                            <Typography gutterBottom className={classes.liText}>
                                                Asiakkaita 600+
                                            </Typography>
                                        </li>
                                        <li className={classes.li}>
                                            <Typography gutterBottom className={classes.liText}>
                                                94% hakuasiakkaista sai positiivisen rahoituspäätöksen
                                            </Typography>
                                        </li>
                                        <li className={classes.li}>
                                            <Typography gutterBottom className={classes.liText}>
                                                Asiakkaiden suositteluhalukkuus 9,3
                                            </Typography>
                                        </li>
                                        <li className={classes.li}>
                                            <Typography gutterBottom className={classes.liText}>
                                                Grantsilaisia 15
                                            </Typography>
                                        </li>
                                        <li className={classes.li}>
                                            <Typography gutterBottom className={classes.liText}>
                                                Liikevaihto n 1M€
                                            </Typography>
                                        </li>
                                        <li className={classes.li}>
                                            <Typography gutterBottom className={classes.liText}>
                                                Yritys on perustettu 2019
                                            </Typography>
                                        </li>
                                    </ul>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container justifyContent='center' alignItems='center' className={classes.imageWrapper}>
                                    <Grid item>
                                        <img src='fromSlides/3.png' alt='slide3' className={classes.image} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography className={classes.grants}>{intl.formatMessage({ id: 'common.grants' })}</Typography>
            </Grid>
        </Grid>
    );
};

export default Slide3;
