import { createSlice } from '@reduxjs/toolkit';

import { getInstrumentTags } from 'containers/Instruments/slices';
import { getCompanyId } from 'utils/auth/company';
import { handleFiles } from 'utils/helpers/fileHelper';
import { businessPlanApi } from './api';
import { businessPlanV2Api } from './businessPlanV2Api';

export const name = 'businessPlan';

export const initialState = {
	main: {
		loading: false,
		error: false,
		data: {},
	},
	infoItems: {
		loading: false,
		error: false,
		data: [],
	},
	teamMembers: {
		loading: false,
		error: false,
		data: [],
	},
	templates: {
		loading: false,
		error: false,
		data: {},
	},
	economicalData: {
		loading: false,
		error: null,
		data: [],
	},
	tags: {
		loading: false,
		error: false,
		data: {},
		projectTagsData: {},
	},
	attachments: {
		loading: false,
		isLoaded: false,
		error: false,
		data: {},
	},
};

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		// reset
		resetData: () => initialState,
		// main
		setMainLoading: state => {
			state.main.loading = true;
			state.main.error = false;
		},
		loadMainError: (state, action) => {
			state.main.loading = false;
			state.main.error = action.payload;
			state.main.data = initialState.main.data;
		},
		loadMainSuccess: (state, action) => {
			state.main.loading = false;
			state.main.error = false;
			state.main.data = action.payload;
		},
		updateMainError: (state, action) => {
			state.main.loading = false;
			state.main.error = action.payload;
		},
		updateMainSuccess: (state, action) => {
			state.main.loading = false;
			state.main.error = false;
			state.main.data = { ...action.payload };
		},
		// infoItems
		setInfoItemsLoading: state => {
			state.infoItems.loading = true;
			state.infoItems.error = false;
		},
		loadInfoItemsError: (state, action) => {
			state.infoItems.loading = false;
			state.infoItems.error = action.payload;
			state.infoItems.data = initialState.infoItems.data;
		},
		loadInfoItemsSuccess: (state, action) => {
			state.infoItems.loading = false;
			state.infoItems.error = false;
			state.infoItems.data = action.payload;
		},
		createInfoItemError: (state, action) => {
			state.infoItems.error = action.payload;
		},
		createInfoItemSuccess: (state, action) => {
			state.infoItems.data.push(action.payload);
		},
		updateInfoItemError: (state, action) => {
			state.infoItems.error = action.payload;
		},
		updateInfoItemSuccess: (state, action) => {
			const updatedItem = action.payload;
			const updatedInfoItemIndex = state.infoItems.data.findIndex(item => item.infoId === updatedItem.infoId);

			state.infoItems.data[updatedInfoItemIndex].title = updatedItem.title;
			state.infoItems.data[updatedInfoItemIndex].value = updatedItem.value;
		},
		removeInfoItemError: (state, action) => {
			state.infoItems.error = action.payload;
		},
		removeInfoItemSuccess: (state, action) => {
			state.infoItems.data = state.infoItems.data.filter(item => item.infoId !== action.payload);
		},
		// teamMembers
		setTeamMembersLoading: state => {
			state.teamMembers.loading = true;
			state.teamMembers.error = false;
		},
		loadTeamMembersError: (state, action) => {
			state.teamMembers.loading = false;
			state.teamMembers.error = action.payload;
			state.teamMembers.data = initialState.teamMembers.data;
		},
		loadTeamMembersSuccess: (state, action) => {
			state.teamMembers.loading = false;
			state.teamMembers.error = false;
			state.teamMembers.data = action.payload;
		},
		createTeamMemberError: (state, action) => {
			state.teamMembers.error = action.payload;
		},
		createTeamMemberSuccess: (state, action) => {
			state.teamMembers.data.push(action.payload);
		},
		updateTeamMemberError: (state, action) => {
			state.teamMembers.error = action.payload;
		},
		updateTeamMemberSuccess: (state, action) => {
			const updatedTeamMember = action.payload;
			const updatedTeamMemberIndex = state.teamMembers.data.findIndex(item => item.memberId === updatedTeamMember.memberId);

			state.teamMembers.data[updatedTeamMemberIndex].firstname = updatedTeamMember.firstname;
			state.teamMembers.data[updatedTeamMemberIndex].lastname = updatedTeamMember.lastname;
			state.teamMembers.data[updatedTeamMemberIndex].position = updatedTeamMember.position;
			state.teamMembers.data[updatedTeamMemberIndex].description = updatedTeamMember.description;
			state.teamMembers.data[updatedTeamMemberIndex].linkedInUrl = updatedTeamMember.linkedInUrl;
			state.teamMembers.data[updatedTeamMemberIndex].avatar = updatedTeamMember.avatar;
		},
		removeTeamMemberError: (state, action) => {
			state.teamMembers.error = action.payload;
		},
		removeTeamMemberSuccess: (state, action) => {
			state.teamMembers.data = state.teamMembers.data.filter(item => item.memberId !== action.payload);
		},
		// templates
		setTemplatesLoading: state => {
			state.templates.loading = true;
			state.templates.error = false;
		},
		loadTemplatesError: (state, action) => {
			state.templates.loading = false;
			state.templates.error = action.payload;
			state.templates.data = initialState.templates.data;
		},
		loadTemplatesSuccess: (state, action) => {
			state.templates.loading = false;
			state.templates.error = false;
			state.templates.data = action.payload;
		},
		// economical data
		setEconomicalDataLoading: state => {
			state.economicalData.loading = true;
			state.economicalData.error = null;
		},
		economicalDataError: (state, action) => {
			state.economicalData.loading = false;
			state.economicalData.error = action.payload;
		},
		loadEconomicalDataSuccess: (state, action) => {
			state.economicalData.loading = false;
			state.economicalData.error = null;
			state.economicalData.data = action.payload;
		},
		saveEconomicalDataSuccess: (state, action) => {
			state.economicalData.loading = false;
			state.economicalData.error = null;

			const updateIndex = state.teamMembers.data.findIndex(item => item.year === action.payload.year);
			if (updateIndex > -1) {
				state.economicalData.data[updateIndex] = action.payload;
			} else {
				state.economicalData.data.push(action.payload);
			}
		},
		saveEconomicalDataArraySuccess: (state, action) => {
			state.economicalData.loading = false;
			state.economicalData.error = null;

			state.economicalData.data = action.payload;
		},
		// tags
		setBusinessPlanTagsLoading: state => {
			state.tags.loading = true;
			state.tags.error = false;
		},
		loadBusinessPlanTagsError: (state, action) => {
			state.tags.loading = false;
			state.tags.error = action.payload;
			state.tags.data = initialState.tags.data;
		},
		loadBusinessPlanTagsSuccess: (state, action) => {
			state.tags.loading = false;
			state.tags.error = false;
			state.tags.data = action.payload;
		},
		loadBusinessProjectTagsSuccess: (state, action) => {
			state.tags.projectTagsData = action.payload;
		},
		// attachments
		setAttachmentsLoading: state => {
			state.attachments.loading = true;
			state.attachments.error = false;
		},
		loadAttachmentsError: (state, action) => {
			state.attachments.loading = false;
			state.attachments.error = action.payload;
			state.attachments.isLoaded = true;
		},
		loadAttachmentsSuccess: (state, action) => {
			state.attachments.loading = false;
			state.attachments.error = false;
			state.attachments.data = action.payload;
			state.attachments.isLoaded = true;
		},
		updateAttachments: (state, action) => {
			state.attachments.loading = false;
			state.attachments.error = false;
			state.attachments.data = [...state.attachments.data, ...action.payload];
			state.attachments.isLoaded = true;
		},
		removeAttachment: state => {
			state.attachments.loading = true;
			state.attachments.error = false;
		},
		removeAttachmentError: (state, action) => {
			state.attachments.loading = false;
			state.attachments.error = action.payload;
			state.attachments.isLoaded = true;
		},
		removeAttachmentSuccess: (state, action) => {
			state.attachments.loading = false;
			state.attachments.error = false;
			state.attachments.isLoaded = true;
			state.attachments.data = state.attachments.data.filter(item => item.attachmentId !== action.payload);
		},
	},
});

export const { reducer } = slice;
export const {
	loadMainError,
	loadMainSuccess,
	loadInfoItemsError,
	loadInfoItemsSuccess,
	loadTeamMembersError,
	loadTeamMembersSuccess,
	loadTemplatesError,
	loadTemplatesSuccess,
	economicalDataError,
	loadEconomicalDataSuccess,
	loadAttachmentsError,
	loadAttachmentsSuccess,
} = slice.actions;

// reset
export const resetBusinessPlan = () => async dispatch => {
	dispatch(slice.actions.resetData());
};

// load data
const loadMain = () => async dispatch => {
	try {
		let companyId = getCompanyId();

		dispatch(slice.actions.setMainLoading());

		const request = await dispatch(businessPlanV2Api.endpoints.fetchBusinessPlan.initiate({ companyId }));

		dispatch(slice.actions.loadMainSuccess(request?.data));
	} catch (err) {
		dispatch(slice.actions.loadMainError(err));
	}
};

const loadInfoItems = () => async dispatch => {
	try {
		dispatch(slice.actions.setInfoItemsLoading());

		const infoItems = await businessPlanApi.getInfoItems();

		dispatch(slice.actions.loadInfoItemsSuccess(infoItems));
	} catch (err) {
		dispatch(slice.actions.loadInfoItemsError(err));
	}
};

const loadTeamMembers = () => async dispatch => {
	try {
		let companyId = getCompanyId();

		dispatch(slice.actions.setTeamMembersLoading(true));

		const request = await dispatch(businessPlanV2Api.endpoints.fetchBusinessPlanTeamMembers.initiate({ companyId }));
		const teamMembers = request.data;
		// const teamMembers = await businessPlanApi.getTeamMembers();

		dispatch(slice.actions.loadTeamMembersSuccess(teamMembers));
	} catch (err) {
		dispatch(slice.actions.loadTeamMembersError(err));
	}
};

const loadTemplates = () => async dispatch => {
	try {
		dispatch(slice.actions.setTemplatesLoading(true));

		const templates = await businessPlanApi.getTemplates();

		dispatch(slice.actions.loadTemplatesSuccess(templates));
	} catch (err) {
		dispatch(slice.actions.loadTemplatesError(err));
	}
};

export const loadEconomicalData = () => async dispatch => {
	try {
		dispatch(slice.actions.setEconomicalDataLoading());

		const data = await businessPlanApi.getEconomicalData();

		dispatch(slice.actions.loadEconomicalDataSuccess(data));
	} catch (err) {
		dispatch(slice.actions.economicalDataError(err));
	}
};

export const loadAttachments = () => async dispatch => {
	try {
		dispatch(slice.actions.setAttachmentsLoading(true));

		const data = await businessPlanApi.getAttachments();

		dispatch(slice.actions.loadAttachmentsSuccess(data));
	} catch (err) {
		dispatch(slice.actions.loadAttachmentsError(err));
	}
};

export const loadBusinessPlan = () => async dispatch => {
	dispatch(loadMain());
	dispatch(loadInfoItems());
	dispatch(loadTeamMembers());
	dispatch(loadTemplates());
	dispatch(loadEconomicalData());
	dispatch(loadAttachments());
};

// create data
export const createInfoItem = data => async dispatch => {
	try {
		const infoItem = await businessPlanApi.saveInfoItem(data);

		dispatch(slice.actions.createInfoItemSuccess(infoItem));

		return true;
	} catch (err) {
		dispatch(slice.actions.createInfoItemError(err));

		return false;
	}
};

export const createTeamMember = data => async dispatch => {
	try {
		let companyId = getCompanyId();
		const request = await dispatch(businessPlanV2Api.endpoints.upsertBusinessPlanTeamMember.initiate({ companyId, teamMember: data }));
		const teamMember = request.data;

		dispatch(slice.actions.createTeamMemberSuccess(teamMember));

		return true;
	} catch (err) {
		dispatch(slice.actions.createTeamMembersError(err));

		return false;
	}
};

/**
 * Saves economical data using the API
 * @param {object/array} data accepts both object of economical data or array of data
 * @returns saved data
 */
export const saveEconomicalDataArray = data => async dispatch => {
	try {
		if (data && data.length === 0) return null;

		data = data.filter(item => item.isEditable);

		if (data && data.length === 0) return null;

		const savedDataReturn = await businessPlanApi.saveEconomicalData(data);

		dispatch(slice.actions.saveEconomicalDataArraySuccess(savedDataReturn));

		return savedDataReturn;
	} catch (err) {
		dispatch(slice.actions.economicalDataError(err));

		return null;
	}
};

// update data
export const updateMain = data => async dispatch => {
	try {
		let companyId = getCompanyId();

		const economicalData = data.economicalData;

		dispatch(saveEconomicalDataArray(economicalData));

		const request = await dispatch(businessPlanV2Api.endpoints.upsertBusinessPlan.initiate({ companyId, businessPlan: data }));

		dispatch(slice.actions.updateMainSuccess(request.data));

		return true;
	} catch (err) {
		dispatch(slice.actions.updateMainError(err));

		return false;
	}
};

export const updateInfoItem = data => async dispatch => {
	try {
		const infoItem = await businessPlanApi.saveInfoItem(data);

		dispatch(slice.actions.updateInfoItemSuccess(infoItem));

		return infoItem;
	} catch (err) {
		dispatch(slice.actions.updateInfoItemError(err));

		return null;
	}
};

export const updateTeamMember = data => async dispatch => {
	try {
		let companyId = getCompanyId();

		const request = await dispatch(businessPlanV2Api.endpoints.upsertBusinessPlanTeamMember.initiate({ companyId, teamMember: data }));
		const teamMember = request.data;

		dispatch(slice.actions.updateTeamMemberSuccess(teamMember));

		return teamMember;
	} catch (err) {
		dispatch(slice.actions.updateTeamMemberError(err));

		return null;
	}
};

export const addAttachment = files => async dispatch => {
	try {
		const attachments = await handleFiles(files);

		const savedAttachments = await businessPlanApi.addAttachment({ attachments });

		dispatch(slice.actions.updateAttachments(savedAttachments));

		return true;
	} catch (err) {
		return false;
	}
};

export const getAttachment = async attachmentId => {
	try {
		return await businessPlanApi.getAttachmentFile(attachmentId);
	} catch {
		return null;
	}
};

// remove data
export const removeInfoItem = id => async dispatch => {
	try {
		const item = await businessPlanApi.removeInfoItem(id);

		dispatch(slice.actions.removeInfoItemSuccess(item.infoId));

		return true;
	} catch (err) {
		dispatch(slice.actions.removeInfoItemError(err));

		return false;
	}
};

export const removeTeamMember = id => async dispatch => {
	try {
		let companyId = getCompanyId();

		const request = await dispatch(businessPlanV2Api.endpoints.removeBusinessPlanTeamMember.initiate({ companyId, teamMemberId: id }));
		const teamMember = request.data;

		dispatch(slice.actions.removeTeamMemberSuccess(teamMember.memberId));

		return true;
	} catch (err) {
		dispatch(slice.actions.removeTeamMemberError(err));

		return false;
	}
};

export const removeAttachment = id => async dispatch => {
	try {
		dispatch(slice.actions.removeAttachment());

		await businessPlanApi.removeAttachment(id); // return true

		dispatch(slice.actions.removeAttachmentSuccess(id));

		return true;
	} catch (err) {
		dispatch(slice.actions.removeAttachmentError(err));

		return false;
	}
};

// get tags
export const getTags = instruments => async dispatch => {
	try {
		dispatch(slice.actions.setBusinessPlanTagsLoading());

		const tags = await dispatch(getInstrumentTags(instruments));

		const businessPlanTags = await businessPlanApi.loadTags();

		dispatch(slice.actions.loadBusinessPlanTagsSuccess({ ...tags, businessPlanTags }));
		dispatch(slice.actions.loadBusinessProjectTagsSuccess(tags));

		return { ...tags, businessPlanTags };
	} catch (error) {
		dispatch(slice.actions.loadBusinessPlanTagsError(error));

		return false;
	}
};

export default slice;
