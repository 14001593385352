import { Box, BoxProps } from '@mui/material';
import * as React from 'react';

interface Props extends Omit<BoxProps, 'onDrag'> {
	onDrag?: (x: number, y: number) => void;
}

const DraggableBox: React.FC<Props> = ({ onDrag, ...otherProps }) => {
	const [mouseDown, setMouseDown] = React.useState(false);

	const previousTouch = React.useRef<Touch | null>(null);

	React.useEffect(() => {
		const handleMouseUp = () => {
			setMouseDown(false);
		};

		const handleTouchEnd = () => {
			setMouseDown(false);
			previousTouch.current = null;
		};

		window.addEventListener('mouseup', handleMouseUp);
		window.addEventListener('touchend', handleTouchEnd);

		return () => {
			window.addEventListener('mouseup', handleMouseUp);
			window.addEventListener('touchend', handleTouchEnd);
		};
	}, []);

	React.useEffect(() => {
		const ratio = window.devicePixelRatio;

		const handleMouseMove = (event: MouseEvent) => {
			if (onDrag) onDrag(event.movementX / ratio, event.movementY / ratio);
		};

		const handleTouchMove = (event: TouchEvent) => {
			if (!event.touches?.length) return;

			const touch = event.touches[0];

			if (previousTouch.current) {
				if (onDrag) onDrag(touch.pageX - previousTouch.current.pageX, touch.pageY - previousTouch.current.pageY);
			}

			previousTouch.current = event.touches[0];
		};

		if (mouseDown) {
			window.addEventListener('mousemove', handleMouseMove);
			window.addEventListener('touchmove', handleTouchMove);
		}

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('touchmove', handleTouchMove);
		};
	}, [mouseDown, onDrag]);

	const handleMouseDown = React.useCallback(() => setMouseDown(true), []);

	return <Box {...otherProps} onMouseDown={handleMouseDown} onTouchStart={handleMouseDown} />;
};

export default DraggableBox;
