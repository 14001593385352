import { ListItem, Typography } from '@mui/material';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { useDispatch } from 'react-redux';

import { RfpProposal } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import { openDrawer } from '../../drawerSlice';

interface Props {
	proposalItem: RfpProposal | null;
}

const RequestProposalListItem: React.FC<Props> = ({ proposalItem }) => {
	const dispatch = useDispatch();

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	return (
		<ListItem
			onClick={() =>
				dispatch(
					openDrawer({
						name: 'ReadRfpProposalDrawer',
						data: {
							requestId: proposalItem?.requestId,
							proposalId: proposalItem?.rfpProposalId,
							published: proposalItem?.status === 'Published',
							createdByMe: proposalItem?.companyId === companyId,
						},
					})
				)
			}
			sx={{
				mb: '.7rem',
				'&:hover': {
					cursor: 'pointer',
					boxShadow: 2,
				},
			}}
		>
			<FormattedDate value={proposalItem?.createDate || ''} />
			<Typography sx={{ ml: '1.5rem', fontWeight: 500 }}>{proposalItem?.companyName}</Typography>
		</ListItem>
	);
};

export default RequestProposalListItem;
