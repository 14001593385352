import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getCompany, getCompanyId, isAdvancedToken } from 'utils/auth/company';
import { isContributorToken } from 'utils/auth/token';
import ContractView from 'views/Contracts/Contract/ContractView';
import { contractApi, useApproveContractMutation, useGetCompanyContractQuery, useGetLatestContractQuery } from '../../App/contractApi';
import { handleCompanyContractSuccessResponse } from '../../App/contractSlice';

const TermsAndConditions = () => {
	const company = getCompany();
	const companyId = company?.CompanyId ?? getCompanyId();
	const dispatch = useDispatch();
	const location = useLocation();

	const {
		data: contract,
		isLoading: isContractLoading,
		isFetching: isContractFetching,
	} = useGetCompanyContractQuery({ companyId, type: 'TermsContract' }, { skip: !companyId });

	const {
		data: latestContract,
		isLoading: isLatestContractLoading,
		isFetching: isLatestContractFetching,
	} = useGetLatestContractQuery({ type: 'TermsContract' }, { skip: !companyId });

	const [approveContract, { isLoading: isApproveLoading }] = useApproveContractMutation();

	const isLoading = isApproveLoading || isContractLoading || isContractFetching || isLatestContractLoading || isLatestContractFetching;

	const isSigned: boolean = (contract || isContractLoading || isContractFetching) ?? false;

	const confirmAgreement = React.useCallback(
		async (fieldValues: any) => {
			try {
				if (!latestContract) {
					toast.error(<FormattedMessage id='request.contract.save.error-no-contract' />);
					return;
				}

				const isContributor = isContributorToken();

				const _ = await approveContract({
					companyId: companyId,
					contract: {
						CompanyId: companyId,
						ContractType: latestContract.partitionKey,
						ContractVersion: latestContract.rowKey,
					},
				}).unwrap();

				dispatch(contractApi.util.resetApiState());

				const isAdvanced = isAdvancedToken();

				let redirectUrl = '/';
				// @ts-ignore
				const pathname: string | null = location?.state?.pathname;
				if (pathname) {
					redirectUrl = pathname.startsWith('/guides') ? `/company/${companyId}${pathname}` : pathname;
				} else if (isContributor) {
					redirectUrl = '/admin/search';
				} else if (!isContributor && isAdvanced) {
					redirectUrl = '/customers';
				} else {
					redirectUrl = `/company/${companyId}`;
				}

				dispatch(handleCompanyContractSuccessResponse(isContributor, redirectUrl));

				toast.success(<FormattedMessage id='request.contract.save.success' />);
			} catch (error) {
				toast.error(<FormattedMessage id='request.contract.save.error' />);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[approveContract, latestContract]
	);

	// header --> agreement:
	const [state, setState] = useState({
		specific: false,
	});

	const handleChange = (event: any) => {
		setState({
			...state,
			[event.target.name]: event.target.checked,
		});
	};

	const { specific } = state;
	const error = [specific]?.filter(item => item)?.length !== 1;

	return (
		<ContractView
			error={error}
			specific={specific}
			handleChange={handleChange}
			confirmAgreement={confirmAgreement}
			isSigned={isSigned}
			loading={isLoading}
		/>
	);
};

export default TermsAndConditions;
