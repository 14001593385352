import {
	Autocomplete,
	InputBase,
	InputBaseProps,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridColumns,
	GridEventListener,
	GridRenderCellParams,
	GridRenderEditCellParams,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	GridRowsProp,
	MuiEvent,
	useGridApiContext,
} from '@mui/x-data-grid';
import AddButton from 'components/Button/AddButton';
import {
	FieldValues,
	UseFieldArrayAppend,
	UseFieldArrayRemove,
	UseFieldArrayUpdate,
	useFieldArray,
	useFormContext,
	useWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
	CostCategory,
	CostCategoryEnum,
	CostCategoryProviderEnumValues,
	Instrument,
	ProjectCost,
	ProjectTask,
	ProjectTaskCategory,
	costCategoryArray,
	hasProviderFlag,
	projectCategoryArray,
} from 'types/dataTypes';
import { generateCurrentTimeTicks } from 'utils/helpers/dateExtension';
import { calculateSideCosts, salaryExpenseTypes } from '../../Costs/ProjectCostForm/ProjectCostForm';

import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import { sumBy } from 'lodash';
import * as React from 'react';
import { gridTranslations } from 'translations/fi/gridTranslations';
import { currencyFormatter, decimalFormatter } from 'utils/formatters/numberFormatters';

type TaskCostProps = {
	costs: Array<ProjectCost> | null;
	task: ProjectTask;
	taskIndex: string;
	control: any;
	errors: any;
	watch: any;
	setValue: any;
	instrument?: Instrument;
};

interface SelectCostCategoryProps extends GridRenderCellParams {
	instrument?: Instrument;
}

function EditTextarea(props: GridRenderEditCellParams<any>) {
	const { id, field, value, colDef, hasFocus } = props;
	const [valueState, setValueState] = React.useState(value);
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
	const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
	const apiRef = useGridApiContext();

	React.useLayoutEffect(() => {
		if (hasFocus && inputRef) {
			inputRef.focus();
		}
	}, [hasFocus, inputRef]);

	const handleRef = React.useCallback((el: HTMLElement | null) => {
		setAnchorEl(el);
	}, []);

	const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
		event => {
			const newValue = event.target.value;
			setValueState(newValue);
			apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
		},
		[apiRef, field, id]
	);

	return (
		<div style={{ position: 'relative', alignSelf: 'flex-start' }}>
			<div
				ref={handleRef}
				style={{
					height: 1,
					width: colDef.computedWidth,
					display: 'block',
					position: 'absolute',
					top: 0,
				}}
			/>
			{anchorEl && (
				<Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
					<InputBase
						multiline
						rows={1}
						value={valueState}
						sx={{ textarea: { resize: 'both' }, width: '100%' }}
						onChange={handleChange}
						inputRef={ref => setInputRef(ref)}
					/>
				</Paper>
			)}
		</div>
	);
}
interface EditToolbarProps {
	setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
	task: ProjectTask;
	append: any;
	category: CostCategoryEnum;
	data: any;
	personnelSideCost: number;
}

const PersonnelSum = ({ data, category }: { data: Array<ProjectCost>; category: CostCategoryEnum }) => {
	const totalSumPersonnel = sumBy(data, (item: ProjectCost) => {
		const salaryExpense: number = item.salaryExpense ?? 0;
		const workEstimate: number = item.workEstimate ?? 0;

		const total = workEstimate * item.amountApplied ?? 0;
		const sideCosts = calculateSideCosts(item.salaryExpenseType ?? 'percentage', total, salaryExpense);

		return total + sideCosts;
	});

	const totalSumPersonnelNoSidecosts = sumBy(data, (item: ProjectCost) => {
		const workEstimate: number = item.workEstimate ?? 0;
		const total = workEstimate * item.amountApplied ?? 0;

		return total;
	});

	const workEstimate = sumBy(data, (item: ProjectCost) => {
		const workEstimate: number = item.workEstimate ?? 0;

		return workEstimate;
	});

	const totalSum = sumBy(data, (item: ProjectCost) => {
		return item.amountApplied;
	});

	return (
		<>
			<Table
				sx={{
					td: {
						borderBottom: 'none',
						textAlign: 'right',
						p: 0,
						fontWeight: 600,
					},
				}}
			>
				<TableBody>
					<TableRow>
						<TableCell width='180' sx={{ p: 0, textAlign: 'left !important' }}>
							Yhteensä
						</TableCell>
						<TableCell width='100'>{''}</TableCell>
						<TableCell width='350'>{''}</TableCell>
						<TableCell width='100'>{''}</TableCell>
						<TableCell width='100'>{''}</TableCell>
						<TableCell width={category === 'salary' ? 70 : 50}>
							{category === 'salary' ? decimalFormatter.format(workEstimate ?? 0) : ''}
						</TableCell>
						<TableCell width='152'>
							{category === 'salary' ? currencyFormatter.format(totalSumPersonnelNoSidecosts) : ''}
						</TableCell>
						<TableCell width='100'>
							{category === 'salary' ? currencyFormatter.format(totalSumPersonnel) : currencyFormatter.format(totalSum)}
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</>
	);
};

function EditToolbar(props: EditToolbarProps) {
	const { setRows, setRowModesModel, task, append, category, data, personnelSideCost } = props;
	const { formatMessage } = useIntl();

	const row = {
		id: `${task.projectTaskId}_${generateCurrentTimeTicks()}`,
		companyId: task.companyId,
		timelineId: task.timelineId,
		projectId: task.projectId,
		projectTaskId: task.projectTaskId,
		category: category,
		amountApplied: 0,
		amountBudget: 0,
		amountGranted: 0,
		amountReported: 0,
		amountPaid: 0,
		startDate: null,
		endDate: null,
		reportedDate: null,
		paidDate: null,
		phase: '',
		description: null,
		type: '',
		name: '',
		salaryExpense: personnelSideCost,
		salaryExpenseType: 'percentage',
	};

	const handleClick = () => {
		setRows(oldRows => [...oldRows, row]);
		setRowModesModel(oldModel => ({
			...oldModel,
			[row.id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
		}));

		if (row.category === 'expense') {
			row.category = 'purchasedServices'; // set default purchasedServices
		}

		append(row);
	};

	const label =
		category === 'salary' ? formatMessage({ id: 'project.cost.add.person' }) : formatMessage({ id: 'project.cost.add.generalcost' });

	return (
		<Stack alignContent='flex-end' alignItems='flex-end' sx={{ p: 1, display: 'flex', width: '100%' }}>
			<PersonnelSum data={data} category={category} />
			{/* @ts-ignore */}
			<AddButton onClick={handleClick}>{label}</AddButton>
		</Stack>
	);
}

function SelectCostCategory(props: SelectCostCategoryProps) {
	const { id, value, field, row, instrument } = props;
	const apiRef = useGridApiContext();
	const { formatMessage } = useIntl();

	const defaultValue = costCategoryArray.find(category => category.value === row.category);

	const handleChange = async (event: any) => {
		await apiRef.current.setEditCellValue({ id, field, value: event });
		apiRef.current.stopCellEditMode({ id, field });
	};

	const filterByProvider = (category: CostCategory) => {
		if (category.value === 'salary') return false;

		if (!instrument) return true;

		if (instrument?.provider === 'Business Finland') return hasProviderFlag(category.provider, CostCategoryProviderEnumValues.BF);

		return true;
	};

	const defaultProps = {
		options: costCategoryArray.filter(filterByProvider),
		getOptionLabel: (option: CostCategory) => option.value,
	};

	if (value === 'salary') {
		return <div>{formatMessage({ id: `project.cost.categories.${value}` })}</div>;
	}

	return (
		<Autocomplete
			componentsProps={{
				paper: {
					sx: {
						border: '1px solid lightgray',
						width: 400,
					},
				},
			}}
			defaultValue={defaultValue}
			sx={{ width: '100%' }}
			{...defaultProps}
			id='clear-on-escape'
			clearOnEscape
			onChange={(_, data) => {
				if (data !== null) return handleChange(data.value);
			}}
			getOptionLabel={category => formatMessage({ id: `project.cost.categories.${category.value}` })}
			renderInput={params => <TextField {...params} variant='standard' />}
		/>
	);
}

function SelectSalaryExpenseType(props: GridRenderCellParams) {
	const { id, value, field, row } = props;
	const apiRef = useGridApiContext();

	const handleAlignment = async (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
		await apiRef.current.setEditCellValue({
			id: id,
			field: field,
			value: newAlignment,
		});
		apiRef.current.stopCellEditMode({ id, field });
	};

	return (
		<ToggleButtonGroup
			defaultValue={row.salaryExpenseType}
			sx={{ mr: '.5rem' }}
			size='small'
			value={row.salaryExpenseType}
			exclusive
			onChange={handleAlignment}
			aria-label='salary expense'
		>
			<ToggleButton value={salaryExpenseTypes.monetaryConst} aria-label='left aligned'>
				€
			</ToggleButton>
			<ToggleButton value={salaryExpenseTypes.percentageConst} aria-label='centered'>
				%
			</ToggleButton>
		</ToggleButtonGroup>
	);
}

const RFPCostCategorySelect = (props: GridRenderCellParams) => {
	const { id, value, field, row } = props;
	const apiRef = useGridApiContext();
	const { formatMessage } = useIntl();

	const handleChange = async (event: any) => {
		await apiRef.current.setEditCellValue({ id, field, value: event });
		apiRef.current.stopCellEditMode({ id, field });
	};

	const defaultProps = {
		options: projectCategoryArray,
		getOptionLabel: (option: ProjectTaskCategory) => option.category,
	};

	const defaultValue = projectCategoryArray.find(category => category.value === props.value) ?? props.value;

	return (
		<Autocomplete
			componentsProps={{
				paper: {
					sx: {
						border: '1px solid lightgray',
						width: 400,
					},
				},
			}}
			defaultValue={defaultValue}
			sx={{ width: '100%' }}
			{...defaultProps}
			id='clear-on-escape'
			clearOnEscape
			onChange={(_, data) => {
				if (data !== null) return handleChange(data.value);
			}}
			getOptionLabel={category => {
				if (typeof category === 'string') {
					return category;
				}

				// @ts-ignore
				return category.category;
			}}
			renderInput={params => <TextField {...params} variant='standard' placeholder='Kategoria toimittajapalveluun' />}
		/>
	);
};

type CostGridParameters = {
	data: any;
	remove: UseFieldArrayRemove;
	append: UseFieldArrayAppend<FieldValues, `${string}.costs`>;
	update: UseFieldArrayUpdate<FieldValues, `${string}.costs`>;
	task: ProjectTask;
	category?: CostCategoryEnum;
	personnelSideCost: number;
	instrument?: Instrument;
};
export function CostGrid({ data, remove, append, update, task, category, personnelSideCost, instrument }: CostGridParameters) {
	const [rows, setRows] = React.useState(data);
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
	const intl = useIntl();
	const { formatMessage } = intl;

	const { setValue } = useFormContext();

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = false;
	};

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = false;
	};

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
	};

	const handleDeleteClick = (id: GridRowId) => () => {
		setRows(rows.filter((row: ProjectCost) => row.id !== id));

		const cost = data.find((item: ProjectCost) => item.id === id);

		if (!cost) return;

		remove(cost.index);
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row: ProjectCost) => row.id === id);
		// @ts-ignore
		if (editedRow!.isNew) {
			setRows(rows.filter((row: ProjectCost) => row.id !== id));
		}
	};

	const processRowUpdate = (newRow: GridRowModel) => {
		const updatedRow = { ...newRow, isNew: false };
		setRows(rows.map((row: ProjectCost) => (row.id === newRow.id ? updatedRow : row)));

		const cost = data.find((item: ProjectCost) => item.id === newRow.id);

		if (cost) {
			update(cost.index, updatedRow);
		}

		return updatedRow;
	};

	const duplicateCost = React.useCallback(
		(id: GridRowId) => () => {
			setRows((prevRows: ProjectCost[]) => {
				const rowToDuplicate = prevRows.find((row: ProjectCost) => row.id === id)!;
				const newId = `${task.projectTaskId}_${generateCurrentTimeTicks()}`;

				append({ ...rowToDuplicate, id: newId, rowKey: newId });
				return [...prevRows, { ...rowToDuplicate, id: newId, rowKey: newId }];
			});
		},
		[]
	);

	const renderSelectCostCategory = (params: GridRenderCellParams, instrument?: Instrument) => {
		return <SelectCostCategory {...params} instrument={instrument} />;
	};

	const renderSelectSalaryExpenseType: GridColDef['renderCell'] = params => {
		return <SelectSalaryExpenseType {...params} />;
	};

	const renderSelectCostRFPCategory: GridColDef['renderCell'] = params => {
		return <RFPCostCategorySelect {...params} />;
	};

	const multilineColumn: GridColDef['renderCell'] = params => {
		return <EditTextarea {...params} />;
	};

	const columns: GridColumns = [
		{
			field: 'index',
			headerName: 'index',
			filterable: false,
			sortable: true,
			hide: true,
		},
		{
			field: 'projectTaskId',
			headerName: 'projectTaskId',
			filterable: false,
			sortable: true,
			hide: true,
		},
		{
			field: 'id',
			headerName: 'id',
			filterable: false,
			sortable: true,
			hide: true,
		},
		{
			field: 'eTag',
			headerName: 'eTag',
			filterable: false,
			sortable: true,
			hide: true,
		},
		{ field: 'supplierName', headerName: 'Toimittajan nimi', width: 250, editable: true, hide: category === 'salary' },
		{ field: 'employeeName', headerName: 'Työntekijän nimi', width: 200, type: 'string', editable: true, hide: category !== 'salary' },
		{ field: 'employeeRole', headerName: 'Rooli', type: 'string', editable: true, hide: category !== 'salary' },
		{
			field: 'name',
			headerName:
				category === 'salary'
					? intl.formatMessage({ id: 'project.task.table.costs.task' })
					: intl.formatMessage({ id: 'project.resourse.purchasing.services.table.work' }),
			width: 350,
			editable: true,
			type: 'string',
		},
		{
			field: 'category',
			headerName: 'Kulukategoria',
			renderEditCell: params => renderSelectCostCategory(params, instrument),
			editable: true,
			width: 150,
			valueFormatter: ({ value }) => formatMessage({ id: `project.cost.categories.${value}` }),
			hide: category === 'salary',
		},
		{
			field: 'salaryExpense',
			headerName: 'Sivukulut',
			type: 'number',
			editable: true,
			hide: category !== 'salary',
		},
		{
			field: 'salaryExpenseType',
			headerName: '',
			renderEditCell: renderSelectSalaryExpenseType,
			editable: true,
			valueFormatter: ({ value }) => (value === 'percentage' ? '%' : '€'),
			hide: category !== 'salary',
		},
		{
			field: 'rfpCategory',
			headerName: 'Kategoria toimittajapalveluun',
			editable: true,
			width: 250,
			renderEditCell: renderSelectCostRFPCategory,
			hide: category === 'salary',
			valueFormatter: ({ value }) => projectCategoryArray.find(category => category.value === value)?.category ?? value,
		},
		{
			field: 'workEstimate',
			width: 70,
			headerName: 'htkk',
			type: 'number',
			editable: true,
			valueFormatter: ({ value }) => decimalFormatter.format(value),
			hide: category !== 'salary',
		},
		{
			field: 'amountApplied',
			headerName: category === 'salary' ? 'Kuukausipalkka' : 'Kustannus',
			width: 150,
			type: 'number',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value),
		},
		{
			field: 'salary',
			headerName: 'Yhteensä',
			valueGetter: (params: any) => {
				const cost: ProjectCost = params.row;
				// @ts-ignore
				const total = cost?.workEstimate * cost.amountApplied ?? 0;

				const sideCosts = calculateSideCosts(cost.salaryExpenseType ?? 'percentage', total, cost.salaryExpense ?? 0);

				return total + sideCosts;
			},
			editable: false,
			type: 'number',
			hide: category !== 'salary',
			valueFormatter: ({ value }) => (!value ? currencyFormatter.format(0) : currencyFormatter.format(value)),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Toiminnot',
			width: 100,
			cellClassName: 'actions',
			getActions: ({ id }) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(id)}
							color='inherit'
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label='Edit'
						className='textPrimary'
						onClick={handleEditClick(id)}
						color='inherit'
					/>,
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label='Poista kulu'
						onClick={handleDeleteClick(id)}
						color='inherit'
						showInMenu
					/>,
					<GridActionsCellItem icon={<FileCopyIcon />} label='Kopioi kulu' onClick={duplicateCost(id)} showInMenu />,
				];
			},
		},
	];

	return (
		<Stack spacing={4}>
			<Typography
				component='h3'
				sx={{
					color: 'rgb(55, 65, 81)',
					fontSize: '1rem',
					fontWeight: 500,
				}}
			>
				{category === 'salary'
					? formatMessage({ id: 'project.cost.table.personnel.title' })
					: formatMessage({ id: 'project.cost.table.other.title' })}
			</Typography>
			<DataGrid
				localeText={gridTranslations(intl)}
				autoHeight
				rows={data}
				columns={columns}
				editMode='row'
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				getRowHeight={() => 'auto'}
				components={{
					// Toolbar: EditToolbar,
					Footer: EditToolbar,
				}}
				componentsProps={{
					// toolbar: { setRows, setRowModesModel, task, append, category, label },
					footer: { setRows, setRowModesModel, task, append, category, data, personnelSideCost },
				}}
				experimentalFeatures={{ newEditingApi: true }}
				hideFooterPagination
				hideFooter={false}
			/>
		</Stack>
	);
}

const CostGridWrapper = (params: CostGridParameters) => {
	const { data, remove, append, update, task, personnelSideCost, instrument } = params;
	const parsedData = data.map((item: ProjectCost, index: number) => Object.assign(item, { index }));

	const personnelCosts = parsedData?.filter((item: ProjectCost) => item.category === 'salary');
	const otherCosts = parsedData?.filter((item: ProjectCost) => item.category !== 'salary');

	return (
		<Stack spacing={4}>
			<CostGrid data={personnelCosts} {...{ remove, append, task, update, category: 'salary', personnelSideCost, instrument }} />
			<CostGrid data={otherCosts} {...{ remove, append, task, update, category: 'expense', personnelSideCost, instrument }} />
		</Stack>
	);
};

const ProjectTaskCostForm: React.FC<TaskCostProps> = ({ costs, task, taskIndex, control, errors, watch, setValue, instrument }) => {
	const { fields, move, remove, append, update } = useFieldArray({
		control,
		name: `${taskIndex}.costs`,
		keyName: 'key',
	});

	const personnelSideCost = useWatch({
		control,
		name: 'personnelSideCost',
	});

	return (
		<Stack>
			<CostGridWrapper data={fields} {...{ remove, append, task, update, personnelSideCost, instrument }} />
		</Stack>
	);
};

export default ProjectTaskCostForm;
