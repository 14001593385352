import { isEmpty, isNil, map, truncate } from 'lodash';
import * as React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';

import { RfpRequestItem } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import FormatCurrency from 'components/Formatters/FormatCurrency';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import { openPopover } from 'containers/Marketplace/popoverSlice';

import { useRemoveRfpRequestItemMutation } from '../rfpRequestApi';

type Props = {
	label?: string | null;
	helperText?: string | null;
	requestId: string | null | undefined;
	onRemove?: any;
	onUpsert?: any;
	control: any;
} & Omit<FormControlProps, 'onChange'>;

const RfpRequestItemList = React.forwardRef<any, Props>(
	({ requestId, label, helperText, onRemove, onUpsert, control, ...otherProps }, ref) => {
		const dispatch = useDispatch();
		const { formatMessage } = useIntl();

		const companyId = getCompanyId() ?? getCompany()?.CompanyId;

		const {
			fields: rfpRequestItems,
			append,
			remove,
			update,
		} = useFieldArray({
			control,
			name: 'rfpRequestItems',
		});

		const [removeRfpRequestItem, { isError: isRemoveError }] = useRemoveRfpRequestItemMutation();

		return (
			<FormControl {...otherProps} ref={ref}>
				{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
				<List>
					{isEmpty(rfpRequestItems) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpRequestItems.empty' />
						</Typography>
					)}
					{map(rfpRequestItems, (rfpRequestItem: RfpRequestItem, index: number) => (
						<ListItem
							key={rfpRequestItem.rfpRequestItemId}
							secondaryAction={
								<IconButton
									color='error'
									onClick={event => {
										dispatch(
											openPopover({
												name: 'ConfirmRemovePopover',
												data: {
													anchorEl: event.currentTarget,
													text: formatMessage({ id: 'rfpRequestItems.confirmRemove' }),
													description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
													onSubmit: async () => {
														try {
															if (rfpRequestItem.rfpRequestItemId) {
																await removeRfpRequestItem({
																	companyId,
																	requestId,
																	requestItemId: rfpRequestItem.rfpRequestItemId,
																}).unwrap();
															}

															remove(index);
														} catch (error) {
															console.error(error);
														}
													},
												},
											})
										);
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
							disablePadding
						>
							<ListItemButton
								onClick={() => {
									dispatch(
										openDialog({
											name: 'UpsertRfpRequestItemDialog',
											data: {
												fieldValues: rfpRequestItem,
												onSubmit: async (fieldValues: Partial<RfpRequestItem>) => {
													try {
														update(index, Object.assign({ companyId, requestId }, rfpRequestItem, fieldValues));
													} catch (error) {
														console.error(error);
													}
												},
											},
										})
									);
								}}
							>
								<ListItemText
									primary={rfpRequestItem.requestItemTitle}
									secondary={
										<>
											<Typography variant='subtitle2' component='span' sx={{ mr: 1 }}>
												<FormattedMessage id={`rfpCategory.${rfpRequestItem.requestItemCategory}`} />
											</Typography>
											{truncate(rfpRequestItem.requestItemDescription || '', { length: 80 })}
											{rfpRequestItem.budget && (
												<Typography component='span' sx={{ display: 'block', mt: '.5rem' }}>
													<Typography variant='subtitle2' component='span' sx={{ mr: '0.5rem' }}>
														<FormattedMessage id='rfpProposal.projectBudget.label' />
													</Typography>
													<FormatCurrency value={rfpRequestItem.budget} />
												</Typography>
											)}
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
				{/* )} */}
				<Box>
					<Button
						size='small'
						variant='text'
						onClick={() => {
							dispatch(
								openDialog({
									name: 'UpsertRfpRequestItemDialog',
									data: {
										fieldValues: {},
										onSubmit: async (fieldValues: Partial<RfpRequestItem>) => {
											try {
												append(Object.assign({ companyId, requestId }, fieldValues));
											} catch (error) {
												console.error(error);
											}
										},
									},
								})
							);
						}}
						startIcon={<AddIcon />}
					>
						<FormattedMessage id='rfpRequestItems.add' />
					</Button>
				</Box>
				{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
			</FormControl>
		);
	}
);

export default RfpRequestItemList;
