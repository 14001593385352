import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';

import getCompanyLink from 'utils/auth/getCompanyLink';

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(2),
        fontWeight: 300,
    },
    link: {
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover, &:focus': {
            textDecoration: 'underline',
        },
    },
    instrument: {
        fontSize: '1rem',
        fontWeight: 300,
    },
}));

const MessageDetails = ({ data }) => {
    const classes = useStyles();
    const intl = useIntl();
    const location = useLocation();

    const messageData = JSON.parse(data);
    const newItems = messageData?.filter(item => item.IsNew === undefined || item.IsNew === true);
    const oldItems = messageData?.filter(item => item.IsNew === false);

    const messageItems = (items, title) => {
        if (items.length === 0) return null;

        return (
            <Box>
                <Typography component='p' gutterBottom className={classes.title}>
                    {intl.formatMessage({ id: title })}
                </Typography>
                <List>
                    {data &&
                        typeof data === 'string' &&
                        items.map(({ InstrumentId, InstrumentName }) => {
                            return (
                                <ListItem key={InstrumentId} alignItems='flex-start' style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography component='span' variant='subtitle1' color='primary' style={{ float: 'left' }}>
                                                    -&nbsp;
                                                </Typography>
                                                <Link to={getCompanyLink(`/instruments/${InstrumentId}`, location)} color='inherit' className={classes.link}>
                                                    <Typography
                                                        component='span'
                                                        variant='subtitle1'
                                                        color='primary'
                                                        className={classes.instrument}
                                                    >
                                                        {InstrumentName}
                                                    </Typography>
                                                </Link>
                                            </>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                </List>
            </Box>
        );
    };

    return (
        <Box>
            {messageItems(newItems, 'notification.modal.new.messages')}
            {messageItems(oldItems, 'notification.modal.old.messages')}
        </Box>
    );
};

export default MessageDetails;
