import * as React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

import { closeDialog, getDialogData, isDialogOpen } from 'containers/Marketplace/dialogSlice';
import { useDeleteCompanyNoteMutation, useUpsertCompanyNoteMutation } from 'containers/Admin/UsersCompany/companyNotesV2Api';
import { getCompanyId } from 'utils/auth/company';

import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved/ConfirmUnsaved';
import UpsertCompanyNoteForm from 'containers/Admin/UsersCompany/CompanyDetails/CompanyNotes/UpsertCompanyNoteForm';
import ConfirmButton from 'components/Button/ConfirmButton';
import Button from 'components/Button/Button';

interface Props {}

const UpsertCompanyNoteDialog: React.FC<Props> = () => {
	const companyId = getCompanyId();

	const { formatMessage } = useIntl();

	const dispatch = useDispatch();

	const dialogOpen = useSelector(isDialogOpen('UpsertCompanyNoteDialog'));
	const companyNote = useSelector(getDialogData('UpsertCompanyNoteDialog'));

	const [upsertCompanyNote, { isLoading: isUpsertLoading }] = useUpsertCompanyNoteMutation();
	const [deleteCompanyNote, { isLoading: isDeleteLoading }] = useDeleteCompanyNoteMutation();

	const formRef = React.useRef<HTMLFormElement>(null);
	const isDirty = React.useRef<boolean>();
	const dirtyFields = React.useRef<{ [key: string]: boolean }>();

	const [confirmOpen, setConfirmOpen] = React.useState(false);

	const isNewCompanyNote = React.useMemo(() => {
		return !companyNote?.companyNotesId;
	}, [companyNote]);

	const handleDialogClose = React.useCallback(() => {
		if (isDirty.current) {
			setConfirmOpen(true);
		} else {
			dispatch(closeDialog({ name: 'UpsertCompanyNoteDialog' }));
		}
	}, [dispatch]);

	const handleConfirmClose = React.useCallback(() => {
		setConfirmOpen(false);
	}, []);

	const handleConfirmSubmit = React.useCallback(() => {
		setConfirmOpen(false);
		dispatch(closeDialog({ name: 'UpsertCompanyNoteDialog' }));
	}, [dispatch]);

	const handleFormSubmit = React.useCallback(
		async (fieldValues: any) => {
			try {
				await upsertCompanyNote({ companyId, companyNote: { ...fieldValues } }).unwrap();
				toast.success(formatMessage({ id: 'company.notes.form.upsert.success' }));
				dispatch(closeDialog({ name: 'UpsertCompanyNoteDialog' }));
			} catch (error) {
				toast.error(formatMessage({ id: 'company.notes.form.upsert.error' }));
			}
		},
		[dispatch, formatMessage, upsertCompanyNote, companyId]
	);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleDialogClose}
			PaperProps={{ sx: { backgroundColor: '#fff' } }}
			scroll='paper'
			maxWidth='lg'
			fullWidth
		>
			<DialogTitle>
				<Stack flexDirection='row' spacing={1} alignItems='center' justifyContent='space-between' useFlexGap>
					<Typography sx={{ fontSize: '1.286rem', fontWeight: 500 }}>
						{formatMessage({
							id: isNewCompanyNote ? 'company.notes.form.create.title' : 'company.notes.form.update.title',
						})}
					</Typography>
					<IconButton sx={{ fontSize: '2rem' }} onClick={handleDialogClose}>
						<CloseIcon />
					</IconButton>
				</Stack>
			</DialogTitle>
			{/*  @ts-ignore */}
			<ConfirmUnsaved
				open={confirmOpen}
				handleCancel={handleConfirmClose}
				handleConfirm={handleConfirmSubmit}
				dirtyFields={dirtyFields.current}
				translation='company.notes.form'
			/>
			<DialogContent sx={{ borderTop: 1, borderBottom: 1, borderColor: 'divider', '.MuiDialogTitle-root+&': { pt: '1rem' } }}>
				<UpsertCompanyNoteForm
					ref={formRef}
					companyNote={companyNote}
					onFormSubmit={handleFormSubmit}
					onDirtyChange={(nextIsDirty, nextDirtyFields) => {
						isDirty.current = nextIsDirty;
						dirtyFields.current = nextDirtyFields;
					}}
				/>
			</DialogContent>
			<DialogActions sx={{ padding: '1rem' }}>
				<Stack
					flexDirection='row'
					justifyContent={isNewCompanyNote ? 'flex-end' : 'space-between'}
					spacing={1}
					sx={{ flex: 1 }}
					useFlexGap
				>
					{isNewCompanyNote ? null : (
						<>
							{/* @ts-ignore */}
							<ConfirmButton
								variant='outlined'
								color='error'
								loading={isDeleteLoading}
								confirmAction={async () => {
									try {
										await deleteCompanyNote({
											companyId,
											companyNotesId: companyNote?.companyNotesId ?? null,
										}).unwrap();
										toast.success(formatMessage({ id: 'company.notes.form.delete.success' }));
									} catch (error) {
										toast.error(formatMessage({ id: 'company.notes.form.delete.error' }));
									}
								}}
								confirmText={formatMessage({ id: 'company.notes.form.confirmDelete.text' })}
								cancelText={formatMessage({ id: 'company.notes.form.confirmDelete.cancel' })}
								confirmBodyText={formatMessage({ id: 'company.notes.form.confirmDelete.body' })}
								buttonText={formatMessage({ id: 'common.delete' })}
							/>
						</>
					)}
					{/* @ts-ignore */}
					<Button
						color='success'
						loading={isUpsertLoading}
						onClick={() => {
							formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
						}}
					>
						{formatMessage({ id: 'company.notes.form.upsert' })}
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};

export default UpsertCompanyNoteDialog;
