import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		paddingLeft: '3.25rem',
		'&>div:first-child li': {
			paddingTop: 0,
		},
		[theme.breakpoints.between('lg', 'xl')]: {
			maxWidth: '22.5rem',
		},
		[theme.breakpoints.up('xl')]: {
			maxWidth: '22.5rem',
		},
	},
	nestedList: {
		paddingTop: 0,
		paddingBottom: '0.25rem',
	},
	listItemMain: {
		padding: '0.25rem 1rem 0.25rem 0.5rem',
	},
	listItemNested: {
		padding: '0.25rem 1rem 0.25rem 2.5rem',
		'&:first-child': {
			paddingTop: 0,
		},
		'&:last-child': {
			paddingBottom: 0,
		},
	},
	itemText: {
		'& span': {
			fontSize: '1rem',
			fontWeight: 600,
			color: theme.palette.primary.secondary,
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		'& a': {
			color: theme.palette.primary.secondary,
		},
		'&:hover': {
			cursor: 'pointer',
		},
	},
	itemTextNested: {
		'& span': {
			fontWeight: 400,
		},
	},
	activeItem: {
		'& a': {
			color: theme.palette.primary.beige,
		},
	},
	listIcon: {
		minWidth: '2rem',
		justifyContent: 'center',
	},
	icon: {
		width: 5,
		height: 5,
		color: theme.palette.primary.secondary,
	},
	activeIcon: {
		width: 10,
		height: 10,
		color: theme.palette.primary.beige,
	},
}));

const Headings = ({ headings, activeId, isLoaded, isMainContentMounted }) => {
	const classes = useStyles();
	const { hash } = useLocation();

	const scrollIntoViewWithOffset = (id, offset = 10) => {
		const element = document.getElementById(id);
		const elementPosition = element?.getBoundingClientRect().top;
		const offsetPosition = elementPosition ? elementPosition + window.pageYOffset - offset : 0;

		window.scrollTo({
			behavior: 'smooth',
			top: id === 'coverLetter' ? 0 : offsetPosition,
		});
	};

	useEffect(() => {
		if (hash && isMainContentMounted) {
			const id = hash.slice(1);
			scrollIntoViewWithOffset(id);
		}
	}, [isMainContentMounted]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		(isLoaded || isMainContentMounted) && (
			<List disablePadding className={classes.root}>
				{headings.map(({ id, title, items }) => (
					<Fragment key={id}>
						<ListItem className={classes.listItemMain}>
							<ListItemIcon className={classes.listIcon}>
								<FiberManualRecordIcon className={id === activeId ? classes.activeIcon : classes.icon} />
							</ListItemIcon>
							<ListItemText
								onClick={e => {
									e.preventDefault();
									scrollIntoViewWithOffset(id);
								}}
								className={`${classes.itemText} ${id === activeId && classes.activeItem}`}
							>
								<Link component={RouterLink} to={`#${id}`} underline='none'>
									{title}
								</Link>
							</ListItemText>
						</ListItem>
						<Collapse in={true}>
							{items.length > 0 && (
								<List className={classes.nestedList}>
									{items.map(({ id, title }) => (
										<ListItem key={id} className={classes.listItemNested}>
											<ListItemText
												onClick={e => {
													e.preventDefault();
													scrollIntoViewWithOffset(id);
												}}
												className={`${classes.itemText} ${classes.itemTextNested} ${id === activeId && classes.activeItem
													}`}
											>
												<Link component={RouterLink} to={`#${id}`} underline='none'>
													{title}
												</Link>
											</ListItemText>
										</ListItem>
									))}
								</List>
							)}
						</Collapse>
					</Fragment>
				))}
			</List>
		)
	);
};

const useHeadingsData = isMainContentMounted => {
	const [nestedHeadings, setNestedHeadings] = useState([]);

	useEffect(() => {
		const headingElements = Array.from(document.querySelectorAll('.tocTitle, main  .tocTitle'));

		const newNestedHeadings = getNestedHeadings(headingElements);
		setNestedHeadings(newNestedHeadings);
	}, [isMainContentMounted]);

	return nestedHeadings;
};

const getNestedHeadings = headingElements => {
	const nestedHeadings = [];

	headingElements.forEach(heading => {
		const { innerText: title, id } = heading;

		if (heading.nodeName === 'H2') {
			nestedHeadings.push({ id, title, items: [] });
		} else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
			nestedHeadings[nestedHeadings.length - 1].items.push({
				id,
				title,
			});
		}
	});

	return nestedHeadings;
};

const useIntersectionObserver = (setActiveId, isMainContentMounted) => {
	const headingElementsRef = useRef({});

	useEffect(() => {
		const callback = headings => {
			headingElementsRef.current = headings.reduce((map, headingElement) => {
				map[headingElement.target.id] = headingElement;
				return map;
			}, headingElementsRef.current);

			const visibleHeadings = [];
			Object.keys(headingElementsRef.current).forEach(key => {
				const headingElement = headingElementsRef.current[key];
				if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
			});

			const getIndexFromId = id => headingElements.findIndex(heading => heading.id === id);

			if (visibleHeadings.length === 1) {
				setActiveId(visibleHeadings[0].target.id);
			} else if (visibleHeadings.length > 1) {
				const sortedVisibleHeadings = visibleHeadings.sort((a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id));
				setActiveId(sortedVisibleHeadings[0].target.id);
			}
		};

		const observer = new IntersectionObserver(callback, {
			threshold: 0.025,
		});

		const headingElements = Array.from(document.querySelectorAll('.tocTitle, main  .tocTitle'));
		headingElements.forEach(element => observer.observe(element));

		// const headingElements = Array.from(document.querySelectorAll('div[id]:not([value=""])'));
		// headingElements
		//     .filter(({ id }) => id !== 'undefined' && id !== 'timeline-content' && id !== 'root') // FIXME: if main content will be changed
		//     .forEach(element => observer.observe(element));

		return () => observer.disconnect();
	}, [setActiveId, isMainContentMounted]);
};

const TableOfContents = () => {
	const financialPlan = useSelector(state => state.financialPlan);
	const isLoaded = financialPlan?.isLoaded;
	const isMainContentMounted = financialPlan?.isMainContentMounted;

	const nestedHeadings = useHeadingsData(isMainContentMounted);

	const [activeId, setActiveId] = useState();
	useIntersectionObserver(setActiveId, isMainContentMounted);

	return (
		<nav aria-label='Table of contents'>
			<Headings headings={nestedHeadings} activeId={activeId} isLoaded={isLoaded} isMainContentMounted={isMainContentMounted} />
		</nav>
	);
};

export default TableOfContents;
