import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import BellIcon from 'components/BellIcon';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import MenuListItem from 'components/List/MenuListItem';
import MenuListItemIcon from 'components/List/MenuListItemIcon';
import MenuTextItem from 'components/List/MenuTextItem';
import NotificationBadge from 'components/NotificationBadge';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { pollingInterval, usePollMessagesQuery } from 'containers/Messages/api';
import { getCompanyPath } from 'utils/auth/getCompanyPath';
import { matchPath } from 'utils/helpers/pathMatch';

import UpgradeFreemiumChip from 'containers/MasterPage/MenuDrawer/CompanyMenuItems/UpgradeFreemiumChip';

const MessagesItem = ({
	companyId,
	localCompanyId,
	pathname,
	isDrawerExpanded,
	isFreemium,
	isAuthenticated,
	isAgreement,
	isAdmin,
	isAdvanced,
	regexCompany,
	smDown,
	betweenSmLg,
	onClose,
	disabled,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const { data: messages } = usePollMessagesQuery('messages', {
		skip: !companyId,
		pollingInterval,
	});
	const messagesNumber = messages?.filter(({ isRead }) => !isRead).length;

	if (
		isDrawerExpanded &&
		isAuthenticated &&
		(localCompanyId || companyId) &&
		(isAgreement || isAdmin) &&
		(!isAdvanced || regexCompany.test(pathname)) &&
		pathname !== '/search' &&
		pathname !== '/admin/search'
	)
		return (
			<MenuListItem
				button
				disabled={disabled}
				onClick={() =>
					isFreemium
						? dispatch(openDialog({ name: 'FreemiumCTADialog' }))
						: onClose(getCompanyPath('/messages', pathname, companyId))
				}
				conditionalHovered={matchPath('/messages', location)}
				secondaryAction={
					<>
						{isFreemium ? (
							<UpgradeFreemiumChip />
						) : (
							<Box
								sx={{
									display: messagesNumber > 0 ? 'block' : 'none',
									textAlign: 'center',
									backgroundColor: '#584b67', // --> primary to rgba?!
									width: '2.5rem',
									borderRadius: '0.75rem',
								}}
							>
								<Typography sx={{ color: 'primary.white' }}>{messagesNumber}</Typography>
							</Box>
						)}
					</>
				}
			>
				<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
					<BellIcon />
				</MenuListItemIcon>
				<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.notifications' })}</MenuTextItem>} />
			</MenuListItem>
		);

	if (
		!isDrawerExpanded &&
		(isAgreement || isAdmin) &&
		(localCompanyId || companyId) &&
		(!isAdvanced || regexCompany.test(pathname)) &&
		pathname !== '/search' &&
		pathname !== 'admin/search'
	)
		return (
			<MenuListItem
				button
				disabled={disabled}
				onClick={() =>
					isFreemium
						? dispatch(openDialog({ name: 'FreemiumCTADialog' }))
						: onClose(getCompanyPath('/messages', pathname, companyId))
				}
				conditionalStyled={!isDrawerExpanded}
				conditionalHovered={matchPath('/messages', location)}
			>
				<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
					<NotificationBadge icon='notification' messagesNumber={messagesNumber} iconSize={21} noColor={true} />
				</MenuListItemIcon>
			</MenuListItem>
		);

	return null;
};

export default MessagesItem;
