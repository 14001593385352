import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmButton from 'components/Button/ConfirmButton';
import BoxContainer from 'components/Containers/BoxContainer';
import CustomPrompt from 'components/CustomPrompt';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';

import useFormErrorEffect from 'components/Form/utils/useFormErrorEffect';
import { getDirtyKeys } from 'components/Form/utils/utils';
import { getFinancialPlanSchema, maxLength } from './getFinancialPlanSchema';

const useStyles = makeStyles(() => ({
	form: {
		width: '100%',
	},
	expertComments: {
		'& textarea': {
			// backgroundColor: '#E9E9E9',
		},
	},
	controls: {
		display: 'flex',
		alignItems: 'flex-end',
		flexDirection: 'row-reverse',
		'& button:last-child': {
			marginRight: '1rem',
		},
	},
	internal: {
		backgroundColor: '#ccc',
		'& label': {
			color: '#000',
		},
	},
}));

const FinancialPlanForm = forwardRef(
	(
		{
			data,
			company,
			saveTimeline,
			passIsDirty = null,
			timelineLoading,
			isDraftLoading,
			isPublishLoading,
			updateLocalBackup = () => {},
		},
		ref
	) => {
		const classes = useStyles();
		const intl = useIntl();

		const schema = getFinancialPlanSchema(intl);

		const {
			control,
			handleSubmit,
			reset,
			getValues,
			formState: { dirtyFields, errors },
		} = useForm({
			defaultValues: useMemo(() => data, [data]),
			resolver: yupResolver(schema),
		});

		useEffect(() => {
			reset(data);
		}, [reset, data]);

		const dirtyKeys = getDirtyKeys(dirtyFields);
		const isDirty = dirtyKeys.length > 0;

		useFormErrorEffect(errors);

		useEffect(() => {
			passIsDirty && passIsDirty(isDirty);
		}, [isDirty, passIsDirty]);

		useEffect(() => {
			if ((isDraftLoading || isPublishLoading) && data) {
				reset(data);
				passIsDirty && passIsDirty(isDirty);
			}
		}, [isDraftLoading, isPublishLoading, isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

		useImperativeHandle(ref, () => ({
			getFormData(action, draft) {
				return handleSubmit(data => action(data, draft));
			},
		}));

		if (!data) return null;

		const saveDraft = data => {
			data.isDraft = true;
			saveTimeline(data, true);
		};

		const publishTimeline = data => {
			data.isDraft = false;
			saveTimeline(data, false);
		};

		return (
			<form className={classes.form}>
				<CustomPrompt dirtyFields={dirtyFields} isDirty={isDirty} translation='timeline.financeplan' />
				<Grid container spacing={2}>
					<Grid item xs={12} id='internalInformation' className={classes.editor}>
						<BoxContainer>
							<FormControlledWysiwyg
								autoresize={true}
								noPaddings={false}
								inline={true}
								toolbar={true}
								name='internalInformation'
								control={control}
								defaultValue={data.internalInformation ?? ''}
								error={errors?.internalInformation?.message}
								label={intl.formatMessage({ id: 'timeline.financeplan.internalInformation' })}
								charactersLimit={maxLength}
								marginTop={1}
								onBlurAction={() => updateLocalBackup(getValues())}
							/>
						</BoxContainer>
					</Grid>
					<Grid item xs={12} id='base' className={classes.editor}>
						<BoxContainer>
							<FormControlledWysiwyg
								autoresize={true}
								noPaddings={false}
								inline={true}
								toolbar={true}
								name='base'
								control={control}
								defaultValue={data.base ?? ''}
								error={errors?.base?.message}
								label={intl.formatMessage({ id: 'timeline.financeplan.base' })}
								charactersLimit={maxLength}
								marginTop={1}
								onBlurAction={() => updateLocalBackup(getValues())}
							/>
						</BoxContainer>
					</Grid>
					<Grid item xs={12} id='expertComments' className={classes.editor}>
						<BoxContainer>
							<FormControlledWysiwyg
								autoresize={true}
								noPaddings={false}
								inline={true}
								toolbar={true}
								name='expertComments'
								control={control}
								defaultValue={data.expertComments ?? ''}
								error={errors?.expertComments?.message}
								label={intl.formatMessage({ id: 'timeline.financeplan.expertComments' })}
								charactersLimit={maxLength}
								marginTop={1}
								onBlurAction={() => updateLocalBackup(getValues())}
							/>
						</BoxContainer>
					</Grid>
					<Grid item xs={12} id='precondition' className={classes.editor}>
						<BoxContainer>
							<FormControlledWysiwyg
								autoresize={true}
								noPaddings={false}
								inline={true}
								toolbar={true}
								name='precondition'
								control={control}
								defaultValue={data.precondition ?? ''}
								error={errors?.precondition?.message}
								label={intl.formatMessage({ id: 'timeline.financeplan.precondition' })}
								charactersLimit={maxLength}
								marginTop={1}
								onBlurAction={() => updateLocalBackup(getValues())}
							/>
						</BoxContainer>
					</Grid>
					<Grid item xs={12} id='notice' className={classes.editor}>
						<BoxContainer>
							<FormControlledWysiwyg
								autoresize={true}
								noPaddings={false}
								inline={true}
								toolbar={true}
								name='notice'
								control={control}
								defaultValue={data.notice ?? ''}
								error={errors?.notice?.message}
								label={intl.formatMessage({ id: 'timeline.financeplan.notice' })}
								charactersLimit={maxLength}
								marginTop={1}
								onBlurAction={() => updateLocalBackup(getValues())}
							/>
						</BoxContainer>
					</Grid>
					<Grid item xs={12} id='followup' className={classes.editor}>
						<BoxContainer>
							<FormControlledWysiwyg
								autoresize={true}
								noPaddings={false}
								inline={true}
								toolbar={true}
								name='followup'
								control={control}
								defaultValue={data.followup ?? ''}
								error={errors?.followup?.message}
								label={intl.formatMessage({ id: 'timeline.financeplan.followup' })}
								charactersLimit={maxLength}
								marginTop={1}
								onBlurAction={() => updateLocalBackup(getValues())}
							/>
						</BoxContainer>
					</Grid>
					<Grid item xs={12} className={classes.controls} sx={{ mt: '2rem' }}>
						<Button
							color='success'
							disabled={timelineLoading || isDraftLoading || !isDirty}
							onClick={handleSubmit(data => saveDraft(data))}
						>
							{intl.formatMessage({ id: 'timeline.saveTimeline.draft' })}
							{isDraftLoading && <ButtonSpinner />}
						</Button>
						<ConfirmButton
							confirmAction={handleSubmit(data => publishTimeline(data))}
							confirmText={intl.formatMessage({ id: 'timeline.saveTimeline.publish' })}
							cancelText={intl.formatMessage({ id: 'timeline.saveTimeline.publish.cancel' })}
							confirmBodyText={intl.formatMessage({ id: 'timeline.saveTimeline.publish.description' })}
							buttonText={intl.formatMessage({ id: 'timeline.saveTimeline.publish' })}
							color='primary'
							disabled={timelineLoading}
							loading={isPublishLoading}
						/>
					</Grid>
				</Grid>
			</form>
		);
	}
);

export default FinancialPlanForm;
