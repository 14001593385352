import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    contentWrapper: {
        flex: 1,
    },
    contentContainer: {
        height: '100%',
    },
    title: {
        fontSize: '1.8rem',
        fontWeight: 700,
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
            paddingRight: theme.spacing(10),
            paddingLeft: theme.spacing(10),
        },
    },
    description: {
        fontSize: '1rem',
        fontWeight: 600,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
            paddingRight: theme.spacing(10),
            paddingLeft: theme.spacing(10),
        },
    },
    container: {
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
    },
    textContainer: {
        height: '100%',
    },
    textItemLeft: {
        textAlign: 'right',
    },
    textItemLeftCenter: {
        textAlign: 'right',
        marginRight: theme.spacing(4),
    },
    textItemRight: {
        textAlign: 'left',
    },
    textItemRightCenter: {
        textAlign: 'left',
        marginLeft: theme.spacing(4),
    },
    subtitle: {
        fontWeight: 600,
    },
    subtitleSmDown: {
        fontWeight: 600,
        paddingTop: theme.spacing(2),
    },
    imageWrapper: {
        textAlign: 'center',
    },
    image: {
        maxWidth: '400px',
    },
    textBox: {
        marginTop: theme.spacing(4),
    },
    grants: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        paddingBottom: theme.spacing(4),
        color: theme.palette.primary.main,
    },
}));

const Slide5 = ({ maxHeight }) => {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Grid container direction='column' alignItems='center' className={classes.root} style={{ maxHeight }}>
            <Grid item className={classes.contentWrapper}>
                <Grid container direction='column' justifyContent='center' className={classes.contentContainer}>
                    <Grid item>
                        <Box textAlign='center'>
                            <Typography className={classes.title}>Kasvurahoituskentässä on ongelma</Typography>
                            <Typography className={classes.description}>
                                Kasvurahoituskenttää nimitetään usein viidakoksi, jossa navigoimiseen vaaditaan asiantuntijaa – Oppaan
                                käyttö navigoinnissa oli erinomainen idea, kunnes GPS-navigointi astui kuvaan.
                            </Typography>
                        </Box>
                        {smDown ? (
                            <>
                                <img src='fromSlides/5.png' alt='slide5' className={classes.image} />
                                <Box textAlign='left' className={classes.textBox}>
                                    <Typography className={classes.subtitleSmDown}>1. Ehdot elävät</Typography>
                                    <Typography>Rahoittajat muuttavat ehtojaan toistuvasti.</Typography>
                                    <Typography className={classes.subtitleSmDown}>2. Yritykset kehittyvät</Typography>
                                    <Typography>Yritysten tilanteet muuttuvat ajassa.</Typography>
                                    <Typography className={classes.subtitleSmDown}>3. Ajantasalla pysyminen on vaikeaa</Typography>
                                    <Typography>Vie aikaa seurata aina uusimpia käänteitä.</Typography>
                                    <Typography className={classes.subtitleSmDown}>4. Ajantasalla pysyminen on vaikeaa</Typography>
                                    <Typography>Vie aikaa seurata aina uusimpia käänteitä.</Typography>
                                    <Typography className={classes.subtitleSmDown}>5. Yritykset kehittyvät</Typography>
                                    <Typography>Yritysten tilanteet muuttuvat ajassa.</Typography>
                                    <Typography className={classes.subtitleSmDown}>6. Rahoittajamäärä</Typography>
                                    <Typography>Relevanttien rahoittajien märä kasvaa kovaa vauhtia.</Typography>
                                </Box>
                            </>
                        ) : (
                            <Grid container className={classes.container}>
                                <Grid item xs={12} md={4}>
                                    <Grid container direction='column' justifyContent='space-between' className={classes.textContainer}>
                                        <Grid item className={classes.textItemLeft} style={{ paddingTop: lgUp && '1rem' }}>
                                            <Typography className={classes.subtitle}>Rahoittajamäärä</Typography>
                                            <Typography>Relevanttien rahoittajien märä kasvaa kovaa vauhtia.</Typography>
                                        </Grid>
                                        <Grid item className={classes.textItemLeftCenter}>
                                            <Typography className={classes.subtitle}>Hajautunut tieto</Typography>
                                            <Typography>Keskeinen tieto hajautuu kymmeniin eri paikkoihin.</Typography>
                                        </Grid>
                                        <Grid item className={classes.textItemLeft}>
                                            <Typography className={classes.subtitle}>Instrumenttitiedon tarkkuus</Typography>
                                            <Typography style={{ paddingBottom: lgUp && '1.5rem' }}>
                                                Tuotekuvaukset ja tosielämä eivät aina kohtaa.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <img src='fromSlides/5.png' alt='slide5' className={classes.image} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Grid container direction='column' justifyContent='space-between' className={classes.textContainer}>
                                        <Grid item className={classes.textItemRight}>
                                            <Typography className={classes.subtitle} style={{ paddingTop: lgUp && '1.5rem' }}>
                                                Ehdot elävät
                                            </Typography>
                                            <Typography>Rahoittajat muuttavat ehtojaan toistuvasti.</Typography>
                                        </Grid>
                                        <Grid item className={classes.textItemRightCenter}>
                                            <Typography className={classes.subtitle}>Yritykset kehittyvät</Typography>
                                            <Typography>Yritysten tilanteet muuttuvat ajassa.</Typography>
                                        </Grid>
                                        <Grid item className={classes.textItemRight}>
                                            <Typography className={classes.subtitle}>Ajantasalla pysyminen on vaikeaa</Typography>
                                            <Typography style={{ paddingBottom: lgUp && '1.5rem' }}>
                                                Vie aikaa seurata aina uusimpia käänteitä.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography className={classes.grants}>{intl.formatMessage({ id: 'common.grants' })}</Typography>
            </Grid>
        </Grid>
    );
};

export default Slide5;
