import { Attachment } from 'types/commonTypes';

export const convertBase64 = (file: File) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);

		fileReader.onload = () => {
			resolve(fileReader.result);
		};

		fileReader.onerror = error => {
			reject(error);
		};
	});
};

export const handleFiles = async (files: File[]) => {
	const attachments: Attachment[] = [];

	if (!files || !files?.length) return attachments;

	for (let i = 0; i < files.length; i++) {
		const base64 = (await convertBase64(files[i])) as string;

		attachments.push({
			base64Data: base64,
			contentType: files[i].type,
			length: files[i].size,
			name: files[i].name,
			fileName: (files[i] as any).path,
		});
	}

	return attachments;
};
