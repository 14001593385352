import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/system';
import { useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';

import NotFoundContainer from 'components/Containers/NotFoundContainer';
import FormControlledCurrencyInput from 'components/Form/FormControlledCurrencyInput';
import FormControlledInput from 'components/Form/FormControlledInput';
import FormControlledMonthPicker from 'components/Form/FormControlledMonthPicker';
import FormControlledSelect from 'components/Form/FormControlledSelect';

const StyledButton = styled(Button)(({ theme }) => ({
	marginTop: theme.spacing(2),
	textTransform: 'none',
}));

const CostItem = ({ children, id, sx }) => {
	return (
		<TableCell id={id} sx={{ border: 'none', p: 0, pr: '.5rem', verticalAlign: 'bottom', ...sx }}>
			{children}
		</TableCell>
	);
};

const HeaderCell = ({ children }) => {
	return <TableCell sx={{ p: 0, pr: '.5rem' }}>{children}</TableCell>;
};

const CostForm = ({ fields, control, watch, errors, tags, removeCostItem, readonly, onInputBlur = () => {} }) => {
	const intl = useIntl();
	const theme = useTheme();

	return (
		<Table sx={{ mb: '2rem' }}>
			<TableHead>
				<TableRow>
					<HeaderCell>{intl.formatMessage({ id: 'businessPlan.costs.costName' })}</HeaderCell>
					<HeaderCell>{intl.formatMessage({ id: 'businessPlan.costs.costCategory' })}</HeaderCell>
					<HeaderCell>{intl.formatMessage({ id: 'businessPlan.costs.costAmount' })}</HeaderCell>
					<HeaderCell>{intl.formatMessage({ id: 'businessPlan.costs.startTime' })}</HeaderCell>
					<HeaderCell>{intl.formatMessage({ id: 'businessPlan.costs.endTime' })}</HeaderCell>
					{!readonly && <HeaderCell>{intl.formatMessage({ id: 'businessPlan.costs.remove' })}</HeaderCell>}
				</TableRow>
			</TableHead>
			<TableBody>
				{!fields ||
					(fields.length === 0 && (
						<TableRow>
							<TableCell colSpan={6}>
								<NoExpenses {...{ intl }} />
							</TableCell>
						</TableRow>
					))}
				{fields?.map(({ id, costName, costCategory, costAmount, startTime, endTime }, index) => {
					const watchStartTime = watch(`costs.${index}.startTime`, startTime);
					return (
						<TableRow key={id}>
							<CostItem id={`costs-${index}-costName`}>
								<FormControlledInput
									variant='standard'
									defaultValue={costName ?? ''}
									name={`costs.${index}.costName`}
									control={control}
									error={errors?.costs?.[index]?.costName?.message}
									placeholder={intl.formatMessage({ id: 'businessPlan.costs.costName' })}
									disabled={readonly}
									onInputBlur={onInputBlur}
								/>
							</CostItem>
							<CostItem id={`costs-${index}-costCategory`}>
								<FormControlledSelect
									variant='standard'
									name={`costs.${index}.costCategory`}
									defaultValue={
										costCategory ?? [
											{
												value: '',
												label: 'Valitse kulukategoria',
											},
										]
									}
									control={control}
									options={tags?.map(item => {
										return {
											value: item,
											label: item,
										};
									})}
									disabledLable={true}
									error={errors?.costs?.[index]?.costCategory?.message}
									disabled={readonly}
									onInputBlur={onInputBlur}
								/>
							</CostItem>
							<CostItem id={`costs-${index}-costAmount`}>
								<FormControlledCurrencyInput
									variant='standard'
									defaultValue={costAmount ?? ''}
									name={`costs.${index}.costAmount`}
									control={control}
									error={errors?.costs?.[index]?.costAmount?.message}
									placeholder={intl.formatMessage({ id: 'businessPlan.costs.costAmount' })}
									disabled={readonly}
									onInputBlur={onInputBlur}
								/>
							</CostItem>
							<CostItem id={`costs-${index}-startTime`} sx={{ pr: '2rem', width: '9rem' }}>
								<FormControlledMonthPicker
									variant='standard'
									defaultValue={startTime ?? ''}
									name={`costs.${index}.startTime`}
									control={control}
									error={errors?.costs?.[index]?.startTime?.message}
									placeholder={intl.formatMessage({ id: 'businessPlan.costs.startTime' })}
									disabled={readonly}
									onInputBlur={onInputBlur}
								/>
							</CostItem>
							<CostItem id={`costs-${index}-endTime`} sx={{ pr: '2rem', width: '9rem' }}>
								<FormControlledMonthPicker
									variant='standard'
									defaultValue={endTime ?? ''}
									name={`costs.${index}.endTime`}
									control={control}
									error={errors?.costs?.[index]?.endTime?.message}
									placeholder={intl.formatMessage({ id: 'businessPlan.costs.endTime' })}
									minDate={watchStartTime ?? new Date()}
									disabled={readonly}
									onInputBlur={onInputBlur}
								/>
							</CostItem>
							{!readonly && (
								<CostItem>
									<IconButton sx={{ pt: theme.spacing(1), mt: theme.spacing(1) }} onClick={() => removeCostItem(index)}>
										<DeleteIcon />
									</IconButton>
								</CostItem>
							)}
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};

const NoExpenses = ({ intl }) => {
	return (
		<NotFoundContainer>
			<Box sx={{ mb: '1rem' }}>{intl.formatMessage({ id: 'businessPlan.costs.noexpenses' })}</Box>
		</NotFoundContainer>
	);
};

const CostArray = ({ control, watch, errors, tags, readonly, onInputBlur = () => {} }) => {
	const intl = useIntl();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'costs',
		defaultValue: [],
	});

	if (!tags) return null;

	const getTags = tags => {
		if (tags) return [...tags.uniqueProjectObjects];

		return [];
	};

	const removeCostItem = costItemIndex => {
		remove(costItemIndex);
	};

	return (
		<>
			<Stack direction='row' alignItems='center' spacing={0.5} justifyContent='space-between'>
				<Typography component='h3' sx={{ fontWeight: 500, my: '1rem' }}>
					{intl.formatMessage({ id: 'businessPlan.costs.title' })}
				</Typography>
				{!readonly && (
					<StyledButton
						color='success'
						size='large'
						startIcon={<AddIcon />}
						onClick={() => append({ costName: '', costCategory: '', costAmount: 0, startTime: '', endTime: '' })}
					>
						{intl.formatMessage({ id: 'businessPlan.costs.append' })}
					</StyledButton>
				)}
			</Stack>
			<CostForm {...{ control, fields, watch, errors, tags: getTags(tags), removeCostItem, readonly, onInputBlur }} />
		</>
	);
};

export default CostArray;
