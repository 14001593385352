export const getLiteScoreColor = value => {
    if (value < 30) return '#8e0e44';
    if (value >= 30 && value <= 59) return '#cca111';
    if (value > 59) return '#077871';
};

export const getGrantedScoreColor = value => {
    if (value < 30) return '#8e0e44';
    if (value >= 30 && value <= 80) return '#cca111';
    if (value > 80) return '#077871';
};