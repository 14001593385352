import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import Timeline from 'containers/FinancialPlan';

export default function TimelineContainer({ projects, loading, timeline, scrollToNoInsruments, companyId, instruments, pathname = '', printView = false }) {

    const timelineRef = useRef();
    const padding = 8; // padding = 8px --> Timeline.js, container style

    const timelineContainerRef = useRef();
    const [timelineContainerWidth, setTimelineContainerWidth] = useState(null);

    useLayoutEffect(() => {
        const updateWidth = () => {
            setTimelineContainerWidth(timelineContainerRef.current?.clientWidth);
        };

        window.addEventListener('resize', updateWidth);
        updateWidth();

        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const [xScrollWidth, setXScrollWidth] = useState(timelineContainerWidth);
    const [xScrollLeft, setXScrollLeft] = useState(0);

    useEffect(() => {
        // should be initially at least 1px bigger than (timelineContainerWidth + padding)
        setXScrollWidth(timelineContainerWidth + padding + 1);
    }, [timelineContainerWidth]);

    const onScroll = () => {
        const xScrollWidth = timelineRef.current?.scrollWidth;
        const xScrollLeft = timelineRef.current?.scrollLeft;
        setXScrollWidth(xScrollWidth);
        setXScrollLeft(xScrollLeft);
    };

    const [isHorizontalScrollbar, setIsHorizontalScrollbar] = useState(false);
    const hasHorizontalScrollbar = tableWidth => setIsHorizontalScrollbar(tableWidth + padding + 1 >= timelineContainerWidth);
    const isRenderLeftSmooth = isHorizontalScrollbar && xScrollLeft > padding;
    const isRenderRightSmooth = isHorizontalScrollbar && xScrollWidth + 32 > xScrollLeft + timelineContainerWidth;

    return (
        <Timeline
            ref={timelineRef}
            loading={loading}
            timeline={timeline}
            projects={projects}
            instruments={instruments}
            padding={padding}
            onScroll={onScroll}
            hasHorizontalScrollbar={hasHorizontalScrollbar}
            isRenderRightSmooth={isRenderRightSmooth}
            isRenderLeftSmooth={isRenderLeftSmooth}
            scrollToNoInsruments={scrollToNoInsruments}
            hideContactCalendar={true}
            isStaticProfileConst={true}
            companyId={companyId}
            pathname={pathname}
            timelineContainerWidth={timelineContainerWidth}
            printView={printView}
            maxHeight='100%'
            readonly={true}
        />
    );
}