import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/styles';

import { isFreemiumToken } from 'utils/auth/company';

import ScoreBox from './ScoreBox';
import { Stack, Typography } from '@mui/material';

const Score = ({ loading, score, missingValues, openProfileModal, profile, hideAnalysis = false, height, analysisLinkUrl }) => {
	const intl = useIntl();
	const theme = useTheme();

	const loader = () => {
		return (
			<Box
				sx={{
					height: '345px',
					textAlign: 'center',
					'& h3': {},
				}}
			>
				<h3>{intl.formatMessage({ id: 'score.calculating' })}</h3>
				<CircularProgress size={24} color='secondary' />
			</Box>
		);
	};

	const hasMissingValues = missingValues !== null && missingValues?.length > 0;
	const showLoading = loading && !hasMissingValues;

	return (
		<Box
			display='flex'
			sx={{
				height: height ?? '30rem',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: theme.palette.primary.main,
				'& h3': {
					textAlign: 'center',
					fontWeight: 600,
					padding: 0,
					margin: 0,
					marginBottom: '1.2rem',
					textTransform: 'uppercase',
					color: theme.palette.primary.white,
				},
				[theme.breakpoints.up('md')]: {
					height: height ?? '35rem',
				},
			}}
		>
			{showLoading ? (
				loader()
			) : (
				<>
					<ScoreBox
						analysisLinkUrl={analysisLinkUrl}
						score={score}
						missingValues={missingValues}
						openProfileModal={openProfileModal}
						profile={profile}
						hideAnalysis={hideAnalysis}
					/>
				</>
			)}
		</Box>
	);
};

export default Score;
