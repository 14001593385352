import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';

import { FormControlledInput, FromControlledHiddenField } from 'components/Form';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import AccordionItem from '../../../../components/AccordionItem/AccordionItem';
import { colors } from '../../../../config/theme';
import TestSortableItem from '../../CategoriesPage/TestSortableContainer/TestSortableItem';
import NestedArray from './NestedFieldArray';

const FieldArray = ({ control, register, setValue, getValues, errors, children, loading }: any) => {
	const { fields, append, remove, prepend, move } = useFieldArray({
		control,
		name: 'fields',
	});

	const [dragging, setDragging] = useState(false);
	const { formatMessage } = useIntl();

	const handleDragStart = () => setDragging(true);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragEnd = ({ active, over }: any) => {
		setDragging(false);
		const activeId = active.id;
		const overId = over.id;

		try {
			if (activeId === overId) return;

			if (activeId !== overId) {
				const oldIndex = fields.findIndex(({ id }) => id === activeId);
				const newIndex = fields.findIndex(({ id }) => id === overId);

				move(oldIndex, newIndex);
				toast.success(formatMessage({ id: 'categories.order.success' }));
			}
		} catch {
			toast.error(formatMessage({ id: 'categories.order.failed' }));
		}
	};

	return (
		<>
			<Stack flexDirection='row' justifyContent='space-between' justifyItems='center' spacing={1} useFlexGap sx={{ my: '2rem' }}>
				<Box>
					<Button
						disabled={loading}
						startIcon={<AddIcon />}
						variant='contained'
						color='success'
						sx={{ mr: '1rem' }}
						onClick={() => {
							prepend({
								title: 'Uusi aliosio',
								description: '',
								placeholder: '',
								id: '',
								type: 'section',
								mapping: null,
								scale: null,
								params: null,
								validations: null,
							});
						}}
					>
						Lisää osio alkuun
					</Button>
					<Button
						disabled={loading}
						startIcon={<AddIcon />}
						variant='contained'
						color='success'
						onClick={() =>
							append({
								title: 'Uusi aliosio',
								description: '',
								placeholder: '',
								id: '',
								type: 'section',
								mapping: null,
								scale: null,
								params: null,
								validations: null,
							})
						}
						sx={{ mr: '1rem' }}
					>
						Lisää osio loppuun
					</Button>
				</Box>
				{children}
			</Stack>

			{errors.fields?.message && <Box sx={{ color: 'red' }}>{errors.fields?.message}</Box>}

			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
				<SortableContext items={fields} strategy={verticalListSortingStrategy}>
					{fields.map((item: any, index: number) => (
						<TestSortableItem id={item.id} handle={true} value={item.id} key={item.id}>
							<AccordionItem
								color={errors?.fields?.[index] ? colors.darkRed : colors.purple}
								close={dragging}
								maxHeight='70rem'
								title={
									<Stack
										flexDirection='row'
										spacing={1}
										useFlexGap
										sx={{
											color: errors?.fields?.[index] ? colors.darkRed : colors.purple,
										}}
									>
										{errors?.fields?.[index] && <ErrorIcon />}
										{getValues(`fields.${index}.title`)}
									</Stack>
								}
							>
								<FromControlledHiddenField defaultValue={'section'} name={`fields.${index}.type`} control={control} />

								<Box flexGrow={1}>
									<Grid container spacing={2} sx={{ mb: '1rem' }}>
										<Grid item xs={12} md={6}>
											<FormControlledInput
												defaultValue={item.id ?? ''}
												name={`fields.${index}.id`}
												control={control}
												error={errors?.fields?.[index]?.id?.message}
												label={'Id'}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControlledInput
												defaultValue={item.title ?? ''}
												name={`fields.${index}.title`}
												control={control}
												error={errors?.fields?.[index]?.title?.message}
												label={'Title'}
											/>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Button
											disabled={loading}
											startIcon={<DeleteIcon />}
											variant='contained'
											color='error'
											onClick={() => remove(index)}
										>
											Poista osio
										</Button>
									</Grid>
									{errors?.fields?.fields && <Box sx={{ color: 'red' }}>{errors?.fields?.[index]?.fields?.message}</Box>}
									<Divider sx={{ my: '2rem' }} />
									<NestedArray nestIndex={index} {...{ control, register, errors, getValues, loading }} />
								</Box>
							</AccordionItem>
						</TestSortableItem>
					))}
				</SortableContext>
			</DndContext>

			<Stack flexDirection='row' justifyContent='space-between' justifyItems='center' spacing={1} useFlexGap sx={{ my: '2rem' }}>
				<Box>
					<Button
						disabled={loading}
						startIcon={<AddIcon />}
						variant='contained'
						color='success'
						sx={{ mr: '1rem' }}
						onClick={() => {
							prepend({
								title: 'Uusi aliosio',
								description: '',
								placeholder: '',
								id: '',
								type: 'section',
								mapping: null,
								scale: null,
								params: null,
								validations: null,
							});
						}}
					>
						Lisää osio alkuun
					</Button>
					<Button
						disabled={loading}
						startIcon={<AddIcon />}
						variant='contained'
						color='success'
						onClick={() =>
							append({
								title: 'Uusi aliosio',
								description: '',
								placeholder: '',
								id: '',
								type: 'section',
								mapping: null,
								scale: null,
								params: null,
								validations: null,
							})
						}
						sx={{ mr: '1rem' }}
					>
						Lisää osio loppuun
					</Button>
				</Box>
				{children}
			</Stack>
		</>
	);
};

export default FieldArray;
