import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import FormattedDate from 'components/Formatters/FormattedDate';

const UserContactPanel = ({ loading, userContact, user, classes }) => {
    const intl = useIntl();

    return (
        <>
            <Stack direction='row' spacing={2} alignItems='flex-end'>
                <Typography variant='h2' className={classes.title}>{intl.formatMessage({ id: 'userdetails.usercontact.title' })}</Typography>
                {loading && <Box sx={{ mb: 2 }}><CircularProgress size={16} sx={{ mb: 0.5 }} /></Box>}
            </Stack>
            {(!loading && !user || !loading && user && Object.keys(user).length === 0) && (!loading && !userContact || !loading && userContact && Object.keys(userContact).length === 0)
                ? <Typography component='p'>{intl.formatMessage({ id: 'userdetails.no.userContact' })}</Typography>
                : <>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.username' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userContact?.grantedUsername}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.firstname' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userContact?.firstname}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.lastname' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userContact?.lastname}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.streetAddress' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userContact?.streetAddress}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.postalCode' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userContact?.postalCode}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.email' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userContact?.email}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.emailConfirmed' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {intl.formatMessage({ id: `admin.tables.${user?.emailConfirmed + ''}` })}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.phoneNumber' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userContact?.phoneNumber}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.phoneNumberConfirmed' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {intl.formatMessage({ id: `admin.tables.${user?.phoneNumberConfirmed + ''}` })}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.created' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={userContact?.created} />
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companylist.table.timestamp' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={userContact?.timestamp} />
                            </Typography>
                        }
                    </Stack>
                </>
            }
        </>
    );
};

export default UserContactPanel;