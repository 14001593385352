import React from 'react';
import { useIntl } from 'react-intl';

import htmlParser from 'components/HtmlParser';
import ProjectPrintContent from '../ProjectPrintTableItems/ProjectPrintContent';

const OverviewTable = ({ project }) => {
	const intl = useIntl();

	return (
		<>
			<ProjectPrintContent
				separateTitle={true}
				title={intl.formatMessage({ id: 'project.form.projectBackground' })}
				content={htmlParser(project?.projectBackground)}
			/>
			<ProjectPrintContent
				separateTitle={true}
				title={intl.formatMessage({ id: 'project.form.projectService' })}
				content={htmlParser(project?.projectService)}
			/>
			<ProjectPrintContent
				separateTitle={true}
				title={intl.formatMessage({ id: 'project.form.projectMarket' })}
				content={htmlParser(project?.projectMarket)}
			/>
			<ProjectPrintContent
				separateTitle={true}
				title={intl.formatMessage({ id: 'project.form.projectVision' })}
				content={htmlParser(project?.projectVision)}
			/>
			<ProjectPrintContent
				separateTitle={true}
				title={intl.formatMessage({ id: 'project.form.projectDevelopment' })}
				content={htmlParser(project?.projectDevelopment)}
			/>
		</>
	);
};

export default OverviewTable;
