import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';

const StyledCell = styled(TableCell)(() => ({
    borderBottom: 'none',
}));

export default function AttachmentConfrimBody({ attachment }) {
    const intl = useIntl();

    if (!attachment) return null;

    return (
        <Table size='small'>
            <TableBody>
                <TableRow>
                    <StyledCell>{intl.formatMessage({ id: 'attachments.table.filename' })}</StyledCell>
                    <StyledCell>{attachment.fileName}</StyledCell>
                </TableRow>
                <TableRow>
                    <StyledCell>{intl.formatMessage({ id: 'attachments.table.created' })}</StyledCell>
                    <StyledCell>{moment(attachment.timestamp).format('DD.M.yyyy HH:mm')}</StyledCell>
                </TableRow>
                <TableRow>
                    <StyledCell>{intl.formatMessage({ id: 'attachments.table.contenttype' })}</StyledCell>
                    <StyledCell>{attachment.contentType}</StyledCell>
                </TableRow>
                {attachment.attachmentParentType &&
                    <TableRow>
                        <StyledCell>{intl.formatMessage({ id: 'attachments.table.attachmentparenttype' })}</StyledCell>
                        <StyledCell>{intl.formatMessage({ id: `attachments.table.attachmentparenttype.${attachment.attachmentParentType}` })}</StyledCell>
                    </TableRow>
                }
                <TableRow>
                    <StyledCell>{intl.formatMessage({ id: 'attachments.table.author' })}</StyledCell>
                    <StyledCell>{attachment.author}</StyledCell>
                </TableRow>
                <TableRow>
                    <StyledCell>{intl.formatMessage({ id: 'attachments.table.length' })}</StyledCell>
                    <StyledCell>{attachment.length / 1000} kb</StyledCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
