import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { BusinessPlan, ITeamMember } from 'types/dataTypes';

type FetchBusinessPlan = {
	companyId: string | null;
};

type UpsertBusinessPlan = {
	companyId: string | null;
	businessPlan: Partial<BusinessPlan> | null;
};

type UpsertTeamMember = {
	companyId: string | null;
	teamMember: ITeamMember;
};

type RemoveTeamMember = {
	companyId: string | null;
	teamMemberId: string;
};

const Tags = {
	BusinessPlan: 'businessPlan',
	TeamMembers: 'teamMembers',
};

const baseUrl = 'businessplan';

export const businessPlanV2Api = createApi({
	reducerPath: 'businessPlanV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.BusinessPlan, Tags.TeamMembers],
	endpoints: builder => ({
		fetchBusinessPlan: builder.query<BusinessPlan, FetchBusinessPlan>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}`,
				};
			},
			providesTags: businessPlan => {
				return [{ type: Tags.BusinessPlan, id: businessPlan?.companyId ?? '' }];
			},
		}),
		upsertBusinessPlan: builder.mutation<BusinessPlan, UpsertBusinessPlan>({
			query: ({ companyId, businessPlan }) => {
				if (isNil(companyId) || isNil(businessPlan)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/${companyId}`,
					data: businessPlan,
				};
			},
			invalidatesTags: businessPlan => {
				return [{ type: Tags.BusinessPlan, id: businessPlan?.companyId ?? '' }];
			},
		}),
		fetchBusinessPlanTeamMembers: builder.query<Array<ITeamMember>, FetchBusinessPlan>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/team`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.TeamMembers, id: params.companyId ?? '' }];
			},
		}),
		upsertBusinessPlanTeamMember: builder.mutation<ITeamMember, UpsertTeamMember>({
			query: ({ companyId, teamMember }) => {
				if (isNil(companyId) || isNil(teamMember)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/${companyId}/team`,
					data: teamMember,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.TeamMembers, id: params.companyId ?? '' }];
			},
		}),
		removeBusinessPlanTeamMember: builder.mutation<ITeamMember, RemoveTeamMember>({
			query: ({ companyId, teamMemberId }) => {
				if (isNil(companyId) || isNil(teamMemberId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/${companyId}/team/${teamMemberId}`,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.TeamMembers, id: params.companyId ?? '' }];
			},
		}),
	}),
});

export const { useFetchBusinessPlanQuery, useUpsertBusinessPlanMutation, useFetchBusinessPlanTeamMembersQuery } = businessPlanV2Api;
