const myLastItemsKey = 'myLastItems';

const saveToLocalStorage = company => {
	let items = [];
	try {
		items = JSON.parse(localStorage.getItem(myLastItemsKey)) ?? [];
	} catch (error) {
		console.error(error);
	}

	const item = { company, time: Date.now() };

	upsert(items, item);
	localStorage.setItem(myLastItemsKey, JSON.stringify(items));
};

const getFromLocalStorage = () => {
	let items = [];
	try {
		items = JSON.parse(localStorage.getItem(myLastItemsKey)) ?? [];
	} catch (error) {
		console.error(error);
	}

	return items.sort((a, b) => b.time - a.time);
};

function upsert(array, item) {
	const arrayLimit = 200;
	const i = array.findIndex(_item => _item.company.id === item.company.id);

	if (i > -1) {
		array[i] = item;
	} else {
		array.push(item);
	}

	if (array.length > arrayLimit) {
		try {
			array.splice(0, array.length - arrayLimit);
		} catch (ex) {
			console.error(ex);
		}
	}
}

export { getFromLocalStorage, saveToLocalStorage };
