import moment from 'moment';

export const demoEconomicalData = [
    {
        year: moment().year() - 2,
        balance: 610.0,
        currentRatio: 2.8,
        ebitdas: 11.7,
        ebitda: 11.7,
        employees: 0,
        operatingMargin: 10.7,
        profits: 110.0,
        quickRatio: 2.8,
        solvency: 67.0,
        percentageChange: 37.1,
        turnover: 1483.0,
        equity: 408,
    },
    {
        year: moment().year() - 1,
        balance: 795.0,
        currentRatio: 3.0,
        ebitdas: 12.3,
        ebitda: 11.7,
        employees: 18,
        operatingMargin: 11.5,
        profits: 168.0,
        quickRatio: 3.0,
        solvency: 68.0,
        percentageChange: 22.1,
        turnover: 1811.0,
        equity: 540,
    },
    {
        year: moment().year(),
        balance: 872.0,
        currentRatio: 2.5,
        ebitdas: 3.5,
        ebitda: 11.7,
        employees: 19,
        operatingMargin: 2.9,
        profits: 53.0,
        quickRatio: 2.5,
        solvency: 63.1,
        percentageChange: -9.8,
        turnover: 1633.0,
        equity: 549,
    },
];
