import moment from 'moment';

const companyNamePlaceholder = '{{company.name}}';
const companyFoundedPlaceholder = '{{company.founded}}';

export const replaceCompanyInformation = (data, company) => {
    if (!data) return;
    if (!company) return;

    data = data.replaceAll(companyNamePlaceholder, company.name);
    data = data.replaceAll(companyFoundedPlaceholder, moment(company.registrationDate).format('DD.MM.YYYY'));

    return data;
};