import { Box } from '@mui/material';
import * as React from 'react';
import { Control, useWatch } from 'react-hook-form';

import { RfpCostType, RfpProposalItemCost } from 'types/dataTypes';

type Props = { control: Control<RfpProposalItemCost>; costType: RfpCostType; children: React.ReactNode };

const ToggleRfpProposalItemCostCostType: React.FC<Props> = ({ control, costType: costTypeA, children }) => {
	const costTypeB = useWatch({ control, name: 'costType' });

	return <Box sx={{ display: costTypeA === costTypeB ? 'block' : 'none' }}>{children}</Box>;
};

export default ToggleRfpProposalItemCostCostType;
