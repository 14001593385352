import emailToName from 'email-to-name';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchScenarioQuery } from 'containers/Scenario/scenarioV2Api';
import { isTrialToken } from 'utils/auth/company';
import { getCompanyPath } from 'utils/auth/getCompanyPath';
import { matchPath } from 'utils/helpers/pathMatch';

import MenuListItem from 'components/List/MenuListItem';
import MenuListItemIcon from 'components/List/MenuListItemIcon';
import MenuTextItem from 'components/List/MenuTextItem';

const AnalysisListItem = ({ onClose, pathname, companyId, isDrawerExpanded, userName, disabled }) => {
	const location = useLocation();
	const intl = useIntl();

	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: isNil(companyId) });

	const { data: scenario, isLoading: isScenarioLoading } = useFetchScenarioQuery(
		{ companyId, businessId: company?.businessId },
		{ skip: isNil(companyId) || isCompanyLoading || isCompanyFetching }
	);

	const missingValues = scenario?.missingValues;

	let name = userName ? emailToName.process(userName) : '';

	if (name.includes('-')) {
		const index = name.indexOf('-');

		if (name.charAt(index + 1) !== name.charAt(index + 1).toUpperCase()) {
			name = name.slice(0, index + 1) + name.charAt(index + 1).toUpperCase() + name.slice(index + 2);
		}
	}

	const isTrialLicense = isTrialToken();
	const hasMissingScenarioValues = missingValues !== null && missingValues?.length > 0;
	const disabledNoScrenarion = hasMissingScenarioValues || isTrialLicense;

	return (
		<MenuListItem
			button
			disabled={disabledNoScrenarion || disabled}
			onClick={() => onClose(getCompanyPath('/analysis', pathname, companyId))}
			conditionalStyled={!isDrawerExpanded}
			conditionalHovered={matchPath('/analysis', location, false)}
			secondaryAction={
				isTrialLicense ? (
					<Box
						textAlign='center'
						sx={{
							backgroundColor: 'rgba(255,255,255,0.5)',
							width: '3.5rem',
							borderRadius: '0.75rem',
						}}
					>
						PRO
					</Box>
				) : null
			}
		>
			<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
				<ArticleOutlinedIcon />
			</MenuListItemIcon>
			{isDrawerExpanded && (
				<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.analysis' })}</MenuTextItem>} />
			)}
		</MenuListItem>
	);
};

export default AnalysisListItem;
