import * as React from 'react';
import { IconButton, FormControlProps, FormControl, Avatar, Stack, FormLabel, FormHelperText, AvatarProps } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { isNil } from 'lodash';

import AttachmentDropZone from 'containers/Marketplace/attachment/AttachmentDropZone';
import BlobDownloader from 'containers/Marketplace/attachment/BlobDownloader';

type Props = {
	label?: string;
	helperText?: string | null;
	value?: string | null;
	onChange?: (value: string | null) => void;
	AvatarProps?: AvatarProps;
} & FormControlProps;

const RfpAttachmentAvatarInput = React.forwardRef<any, Props>(({ label, helperText, value, onChange, AvatarProps, ...otherProps }, ref) => {
	return (
		<FormControl {...otherProps} ref={ref}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			{isNil(value) ? (
				<AttachmentDropZone
					options={{ accept: ['image/*'], maxFiles: 1 }}
					onDrop={attachments => {
						onChange?.(attachments[0]?.base64Data);
					}}
				/>
			) : (
				<Stack flexDirection='row'>
					<BlobDownloader url={value}>{src => <Avatar alt='avatar' src={src} {...AvatarProps} />}</BlobDownloader>
					<IconButton
						color='error'
						onClick={() => {
							onChange?.(null);
						}}
					>
						<DeleteIcon />
					</IconButton>
				</Stack>
			)}
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
});

export default RfpAttachmentAvatarInput;
