import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import GridOffIcon from '@mui/icons-material/GridOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/styles';

import { Button, Chip, Stack, Switch, Typography } from '@mui/material';
import Error from 'containers/Stakeholder/Views/Components/Error';
import Loader from 'containers/Stakeholder/Views/Components/Loader';
import {
	useDisableLinkMutation,
	useGetCompanySharedLinksQuery,
	useSwitchReceiverDisabledStatusMutation,
} from 'containers/Stakeholder/stakeholderApi';
import { useIntl } from 'react-intl';
import { getCompanyId } from 'utils/auth/company';
import { formatDateTime } from 'utils/formatters/formatDate';
import SharelinkFormModalDialog from '../ShareLinkFormContainer/SharelinkFormModalDialog';

const ReceiverRow = ({ receiver, disabled }) => {
	const intl = useIntl();

	const [disableEnableLinkForReceiver] = useSwitchReceiverDisabledStatusMutation();
	const handleChange = async () => {
		const response = await disableEnableLinkForReceiver({ companyId: getCompanyId(), receiverId: receiver.rowKey });

		if (response?.data) {
			toast.success(intl.formatMessage({ id: 'stakeholder.form.link.receiver.success' }));

			return;
		}

		toast.error(intl.formatMessage({ id: 'stakeholder.form.link.receiver.error' }));
	};

	return (
		<TableRow key={receiver.receiverId}>
			<TableCell component='th' scope='row'>
				{receiver.receiver}
			</TableCell>
			<TableCell>{receiver.isRead ? <MarkEmailReadIcon /> : <MarkEmailUnreadIcon htmlColor='#ccc' />}</TableCell>
			<TableCell>{formatDateTime(receiver.readTime)}</TableCell>
			<TableCell>
				<Stack direction='row' spacing={1} alignItems='center'>
					<Switch
						disabled={disabled}
						checked={!receiver.disabled}
						onChange={handleChange}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
					<Typography>
						{receiver.disabled ? (
							intl.formatMessage({ id: 'stakeholder.form.state.disabled' })
						) : (
							<Typography>{intl.formatMessage({ id: 'stakeholder.form.state.active' })}</Typography>
						)}
					</Typography>
				</Stack>
			</TableCell>
		</TableRow>
	);
};

const ReceiverTable = ({ receivers, disabled }) => {
	const intl = useIntl();

	return (
		<Box sx={{ margin: 1 }}>
			<Table size='small' aria-label='recipients'>
				<TableHead>
					<TableRow>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.receiver.table.recipient' })}</TableCell>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.receiver.table.read' })}</TableCell>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.receiver.table.readtime' })}</TableCell>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.receiver.table.status' })}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{receivers?.length === 0 && <NoData id='stakeholder.form.receiver.nodata' isreceiver />}
					{receivers.map(receiver => (
						<ReceiverRow key={receiver.receiverId} receiver={receiver} disabled={disabled} />
					))}
				</TableBody>
			</Table>
		</Box>
	);
};

const ShareTypeList = ({ sharedData }) => {
	const intl = useIntl();
	const ret = [];

	for (let index = 0; index < sharedData.length; index++) {
		const suffix = index === sharedData.length - 1 ? '' : ', ';
		const data = intl.formatMessage({ id: `stakeholder.form.sharedData.${sharedData[index]}` });

		ret.push(`${data}${suffix}`);
	}

	return ret;
};

const Row = ({ row }) => {
	const intl = useIntl();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const [disableLinkMutation] = useDisableLinkMutation();
	const disableLink = async () => {
		let response = await disableLinkMutation({ companyId: getCompanyId(), linkId: row.link.rowKey });

		if (response?.data?.disabled) {
			toast.success(intl.formatMessage({ id: 'stakeholder.form.link.disable.success' }));

			return;
		}

		toast.error(intl.formatMessage({ id: 'stakeholder.form.link.disable.error' }));
	};

	return (
		<>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell sx={{ width: '3rem' }}>
					<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell sx={{ maxWidth: '30rem' }} component='th' scope='row'>
					<Box
						sx={{
							display: row.link.sharelinkName ? 'block' : 'none',
							fontWeight: 500,
							mb: '.3rem',
							fontSize: '.9rem',
						}}
					>
						{row.link.sharelinkName}
					</Box>
					<ShareTypeList sharedData={row.link.sharedData} />
				</TableCell>
				<TableCell>{intl.formatMessage({ id: `stakeholder.form.sharedData.type.${row.link.contactRequestType}` })}</TableCell>
				<TableCell>{formatDateTime(row.link.contactRequestTime)}</TableCell>
				<TableCell>{formatDateTime(row.link.expires)}</TableCell>
				<TableCell>
					{row.link.disabled
						? intl.formatMessage({ id: 'stakeholder.form.state.disabled' })
						: row.link.isExpired
							? intl.formatMessage({ id: 'stakeholder.form.state.expired' })
							: intl.formatMessage({ id: 'stakeholder.form.state.active' })}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					sx={{
						paddingBottom: 0,
						paddingTop: 0,
						pl: '4rem',
						background: theme.palette.secondary.light,
					}}
					colSpan={7}
				>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<ReceiverTable disabled={row.link.disabled || row.link.isExpired} receivers={row.receivers} />
						<Stack
							direction='column'
							alignItems='flex-end'
							sx={{
								width: '100%',
								pr: '1rem',
								m: '1rem 1.5rem 1rem 0',
							}}
						>
							{!row.link.disabled && !row.link.isExpired && (
								<Button color='error' onClick={() => disableLink()} startIcon={<ClearIcon />}>
									{intl.formatMessage({ id: 'stakeholder.form.link.disable.button' })}
								</Button>
							)}
						</Stack>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const NoData = ({ id, isreceiver = false }) => {
	const intl = useIntl();
	const theme = useTheme();

	return (
		<TableRow>
			<TableCell colSpan={6}>
				<Stack
					sx={{
						width: '100%',
						p: '2rem',
					}}
					alignItems='center'
				>
					<GridOffIcon
						sx={{
							width: 60,
							height: 60,
						}}
						htmlColor={theme.palette.secondary.border}
					/>
					<Typography
						sx={{
							my: '1rem',
							color: theme.palette.secondary.border,
						}}
					>
						{intl.formatMessage({ id: id })}
					</Typography>
					{!isreceiver && <SharelinkFormModalDialog />}
				</Stack>
			</TableCell>
		</TableRow>
	);
};

const LinksTable = ({ data }) => {
	const intl = useIntl();

	const LinksTableData = () => {
		if (!data || data.length === 0) {
			return <NoData id='stakeholder.form.nodata' />;
		}

		return data?.map(row => <Row key={row?.link?.sharelinkId} row={row} />);
	};

	return (
		<TableContainer component={Paper}>
			<Table aria-label='collapsible table'>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.link.table.parts' })}</TableCell>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.link.table.type' })}</TableCell>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.link.table.shareTime' })}</TableCell>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.link.table.expires' })}</TableCell>
						<TableCell>{intl.formatMessage({ id: 'stakeholder.form.link.table.status' })}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<LinksTableData />
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const ChipItem = ({ active, disabled, setActive, item, title = 'rajoitin' }) => {
	return (
		<Chip
			disabled={disabled}
			key={1}
			variant='outlined'
			size='small'
			label={title}
			onClick={() => {
				if (item === active) return;

				setActive(item);
			}}
			sx={{
				backgroundColor: active === item ? 'primary.white' : 'secondary.grey200',
				borderColor: !active === item ? 'secondary.grey200' : 'secondary.grey300',
				color: active === item ? '#303F5A' : '#73798C',
				mt: '.3rem',
				mr: '.3rem',
				'& .MuiChip-labelSmall': {
					pt: '1px',
				},
				'& .MuiChip-icon': {
					color: item === 0 ? 'error.main' : item === 1 ? 'warning.main' : item === 2 ? 'success.main' : 'primary.main',
				},
			}}
		/>
	);
};

const SharedLinkList = () => {
	const intl = useIntl();
	const [active, setActive] = useState(1);

	const companyId = getCompanyId();
	const { data, isFetching, isLoading, isSuccess } = useGetCompanySharedLinksQuery(companyId);

	if (isFetching || isLoading) return <Loader />;

	if (!isSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.sharedlinklist.error' })}</Error>;

	const activeItems = data ? data?.filter(item => !item.link.disabled && !item.link.isExpired) : [];
	const inActiveItems = data ? data?.filter(item => item.link.disabled || item.link.isExpired) : [];

	const getData = () => {
		if (active == 2) return inActiveItems;

		return activeItems;
	};

	return (
		<>
			<Stack direction='row' alignItems='space-between' alignContent='center' sx={{ mb: '1rem' }}>
				<Box sx={{ flexGrow: 1 }}>
					<ChipItem
						disabled={activeItems?.length <= 0}
						item={1}
						active={active}
						setActive={setActive}
						title={intl.formatMessage({ id: 'stakeholder.filter.active' }, { value: activeItems?.length })}
					/>
					<ChipItem
						disabled={inActiveItems?.length <= 0}
						item={2}
						active={active}
						setActive={setActive}
						title={intl.formatMessage({ id: 'stakeholder.filter.inactive' }, { value: inActiveItems?.length })}
					/>
				</Box>
				<SharelinkFormModalDialog />
			</Stack>

			<LinksTable data={getData()} />
		</>
	);
};

export default SharedLinkList;
