import * as React from 'react';

import { Box, Stack } from '@mui/material';

import { getCompanyId } from 'utils/auth/company';

import { AIContextSelector } from '../AIContextSelector/AIContextSelector';
import ChatFlow from '../AiChatWrapper/ChatFlow';
import ChatForm from '../AiChatWrapper/ChatForm';

export interface OpenAIMessage {
	role: string;
	content: string;
}

export interface Props {
	messages: Array<OpenAIMessage>;
	disabled: boolean;
	chatId: string;
	title: string;
	handleResponse?: (response: any) => void;
}

const AiGenerator = ({ messages = [], chatId, title, handleResponse }: Props) => {
	const companyId = getCompanyId();

	const [aiLoading, setAiLoading] = React.useState(false);
	const [promptBase, setPromptBase] = React.useState(null);

	const onSelectedChanged = (values: any) => {
		setPromptBase(values);
	};

	return (
		<Stack flexDirection='column' sx={{ bgcolor: 'background.paper' }}>
			<Box>
				<AIContextSelector companyId={companyId} onSelectedChanged={onSelectedChanged} preSelectedItems={['businessPlan.pitch']} />
				<ChatFlow sx={{ width: '100%' }} loading={false} chat={{ chatContent: messages }} aiLoading={aiLoading} />
			</Box>
			<ChatForm // @ts-ignore
				showInput={false}
				inputDefaultValue={messages?.[0].content}
				promptBase={promptBase}
				chatHistory={null}
				chatId={chatId}
				messages={messages}
				loading={false}
				setAiLoading={setAiLoading}
				deleteButton={null}
				handledResponse={handleResponse}
				buttonText='Generoi hakemus'
			/>
		</Stack>
	);
};

export default AiGenerator;
