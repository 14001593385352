import React from 'react';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        backgroundColor: theme.palette.primary.white,
        maxWidth: '80%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '60%',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '40%',
        },
    },
    popoverContent: {
        padding: theme.spacing(1),
        fontSize: '0.8rem',
    },
}));

const HoverPopover = ({ content, title, sx = {} }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Typography
                sx={sx}
                component='div'
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {title}
            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography className={classes.popoverContent}>{content}</Typography>
            </Popover>
        </>
    );
};

export default HoverPopover;
