import React from 'react';

import AiGenerator from '../AiGenerator/AiGenerator';
import { useFetchInstrumentPromptQuery } from '../aiApi';

type AIInstrumentContentGeneratorProps = {
	instrumentId: string;
	instrumentPromptId: string;
	chatId: string;
	handleResponse?: (response: any) => void;
};

export const AIInstrumentContentGenerator: React.FC<AIInstrumentContentGeneratorProps> = ({
	instrumentId,
	instrumentPromptId,
	chatId,
	handleResponse,
}) => {
	const { data } = useFetchInstrumentPromptQuery(
		{
			instrumentId: instrumentPromptId,
		},
		{ skip: !instrumentPromptId }
	);

	if (!data) return null;

	return (
		<>
			<AiGenerator
				chatId={chatId}
				title={`${chatId} - ${instrumentId}`}
				messages={data}
				disabled={false}
				handleResponse={handleResponse}
			/>
		</>
	);
};
