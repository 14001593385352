import React from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import { makeStyles } from '@mui/styles';
import TextContainer from 'components/Containers/TextContainer';
import htmlParser from 'components/HtmlParser';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        marginTop: '1rem',
        color: theme.palette.primary.main,
    }
}));

const ScoreSuggestions = ({ suggestions }) => {
    const classes = useStyles();
    const intl = useIntl();

    if (!suggestions || suggestions.length === 0) return null;

    const getQuide = (quide) => {
        if (!quide) return null;

        return (
            <Typography component='span'>
                {htmlParser(' - ' + quide)}
            </Typography>
        );
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography sx={{
                fontSize: '0.8rem',
                fontWeight: 600,
                mb: 2,
                color: 'primary.main',
            }}>
                {intl.formatMessage({ id: 'analysis.suggestions.header' })?.toUpperCase()}
            </Typography>
            {suggestions?.map(({ identifier, description, quide }, index) => {
                return (
                    <div key={quide + index} className={classes.container}>
                        <EmojiObjectsOutlinedIcon sx={{ mr: 2 }} />
                        <div>
                            <Typography
                                gutterBottom
                                sx={{
                                    fontWeight: 600
                                }}
                            >
                                {intl.formatMessage({ id: `instrument.radar.choice.${identifier}` })}
                                {getQuide(quide)}
                            </Typography>
                            <TextContainer sx={{ p: 0 }}>{description}</TextContainer>
                        </div>
                    </div>
                );
            })}
        </Box>
    );
};

export default ScoreSuggestions;
