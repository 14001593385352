import axios from 'utils/axios';
import { buildUri, validateHttpStatus, buildPlainUri, } from 'utils/helpers/uriHelper';

const baseUrl = 'collaboration';

class CollaborationApi {
	async getAllConversations() {
		const url = buildPlainUri(baseUrl, 'conversation');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getCompanyConversations() {
		const url = buildUri(baseUrl, 'conversation');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getCompanyConversationById(conversationId) {
		const url = buildUri(baseUrl, `conversation/${conversationId}`);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async createConversation(data) {
		const url = buildUri(baseUrl, 'conversation');

		const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async updateConversation(conversationId, data) {
		const url = buildUri(baseUrl, `conversation/${conversationId}`);
		const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async deleteConversation(conversationId) {
		const url = buildUri(baseUrl, `conversation/${conversationId}`);
		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async markConversationAsResolved(conversationId) {
		const url = buildUri(baseUrl, `conversation/${conversationId}/resolve`);
		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async markConversationAsRead(conversationId) {
		const url = buildUri(baseUrl, `conversation/${conversationId}/markasread`);
		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getComments(conversationId) {
		const url = buildUri(baseUrl, `conversation/${conversationId}/comments`);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getCommentById(conversationId, commentId) {
		const url = buildUri(baseUrl, `conversation/${conversationId}/comments/${commentId}`);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async deleteComment(conversationId, commentId) {
		const url = buildUri(baseUrl, `conversation/${conversationId}/comments/${commentId}`);
		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async createComment(conversationId, data) {
		const url = buildUri(baseUrl, `conversation/${conversationId}/comments`);
		const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async updateComment(conversationId, commentId, data) {
		const url = buildUri(baseUrl, `conversation/${conversationId}/comments/${commentId}`);
		const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const collaborationApi = new CollaborationApi();
