import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import StarIcon from '@mui/icons-material/Star';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

import Customers from 'containers/Advanced/Customers';
import Prospects from 'containers/Advanced/Prospects';
import CreatedList from 'containers/Advanced/CreatedList';
import ImportantList from 'containers/Advanced/ImportantList';
import ContentContainer from 'components/Containers/ContentContainer';
import MainTitle from 'components/Titles/MainTitle';
import ConfirmButton from 'components/Button/ConfirmButton';
import AddButton from 'components/Button/AddButton';
import FilterInput from 'components/FilterInput';
import AddCustomerModal from './AddCustomerModal';
import CreateNewListModal from './CreateNewListModal';
import AddToAnotherListModal from './AddToAnotherListModal';

import { createCustomerCompany, addCustomer, loadLists, createList, deleteListById, deleteCompaniesFromList, addCompaniesToList, addImportantMark, removeImportantMark } from 'containers/Advanced/slices';
import { resetSelectedCompany } from 'containers/CompanySearch/slices';

const SelectedButton = ({ item, selected, setIsData, setPhrase }) => {
    const intl = useIntl();
    const history = useHistory();

    const name = item === 'customers' || item === 'prospects' || item === 'important' ? intl.formatMessage({ id: `advanced.drawer.${item}` }) : item.name;
    const hash = item === 'customers' || item === 'prospects' || item === 'important' ? item : item.companyListId;

    return (
        <MuiButton
            disabled={typeof item === 'string' ? item === selected : item.companyListId === selected.companyListId}
            startIcon={item === 'important' && <StarIcon />}
            sx={{
                '&:disabled': {
                    bgcolor: 'primary.main',
                    color: 'primary.white'
                },
                minWidth: 'unset',
                maxWidth: '10rem'
            }}
            onClick={() => {
                history.push(`${history.location.pathname}#${hash}`);
                setIsData(false);
                setPhrase('');
            }}
        >
            <Typography
                component='span'
                sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {name}
            </Typography>
        </MuiButton>
    );
};

const staticLists = ['customers', 'prospects'];

const CustomersPage = () => {
    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const { hash } = useLocation();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const customers = useSelector(state => state.customers);
    const isLoaded = customers?.customers?.isLoaded;
    const data = customers?.customers?.data;
    const createLoading = customers?.creatingCustomerLoading;
    const lists = customers?.lists || [];
    const createListLoading = customers?.createListLoading;
    const deleteListByIdLoading = customers?.deleteListByIdLoading;
    const deleteCompaniesFromListLoading = customers?.deleteCompaniesFromListLoading;
    const addCompaniesToListLoading = customers?.addCompaniesToListLoading;
    const isImportantFound = customers?.customers?.isImportantFound || customers?.prospects?.isImportantFound;

    const profile = useSelector(state => state.profile);
    const profileLoading = profile?.loading;
    const profileLink = profile?.profileLink;

    const createdListRef = useRef(null);

    const [selected, setSelected] = useState(staticLists.at(0));

    useEffect(() => {
        dispatch(loadLists());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const ash = hash.slice(1);

        if (hash && staticLists.includes(ash) || /* isImportantFound && */ ash === 'important') {
            setSelected(ash);
        } else if (hash && lists.length > 0 && lists?.some(item => item.companyListId === ash)) {
            const selected = lists.find(item => item.companyListId === ash);
            setSelected(selected);
        }
    }, [hash, lists.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const removeListById = async listId => {
        await dispatch(deleteListById(listId));
        history.push(`${history.location.pathname}#customers`);
    };

    const removeCompaniesFromList = async (listId, companiesIds) => {
        await dispatch(deleteCompaniesFromList(listId, companiesIds));
        setSelectedIds([]);
        createdListRef.current.closeModal();
    };

    const toggleImportant = (customerCompanyId, isImportant) => isImportant ? dispatch(removeImportantMark(customerCompanyId)) : dispatch(addImportantMark(customerCompanyId));

    // add customer modal
    const addCustomerRef = useRef();
    const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);

    const [isData, setIsData] = useState(false);
    const [phrase, setPhrase] = useState('');
    const filterItems = event => setPhrase(event.target.value);

    const [option, setOption] = useState(null);
    const [addLoading, setAddLoading] = useState(false);

    const addAsCustomer = async () => {
        setAddLoading(true);

        const success = await dispatch(addCustomer(option));

        dispatch(resetSelectedCompany());

        setOption(null);
        setAddLoading(false);

        if (isAddCustomerModalOpen) setIsAddCustomerModalOpen(false);

        if (success) {
            toast.success(<FormattedMessage id='advanced.customers.add.success' />);

            return;
        }

        toast.error(<FormattedMessage id='advanced.customers.add.failed' />);
    };

    const onSubmit = async data => {
        const sendedData = {
            ...data,
            companyForm: data.companyForm.key
        };

        const success = await dispatch(createCustomerCompany(sendedData));

        if (isAddCustomerModalOpen) setIsAddCustomerModalOpen(false);

        if (success) {
            toast.success(intl.formatMessage({ id: 'customer.page.modal.form.saving.successed' }));
        } else {
            toast.error(intl.formatMessage({ id: 'customer.page.modal.form.saving.failed' }));
        }
    };

    const submitForm = () => addCustomerRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

    // create list modal:
    const createListRef = useRef();
    const [isOpenCreateListModal, setIsOpenCreateListModal] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const removeSelectedCompany = companyIndex => setSelectedIds(selectedIds.filter((_, index) => index !== companyIndex));

    const onSubmitCreateList = async data => {
        const list = {
            ...data,
            companies: selectedIds
        };

        const createdId = await dispatch(createList(list));

        setIsOpenCreateListModal(false);

        if (createdId) {
            history.push(`${history.location.pathname}#${createdId}`);
        }
    };

    const submitCreateListForm = () => createListRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

    // add to another list modal:
    const addToAnotherListRef = useRef();
    const [isOpenAnotherListModal, setIsOpenAnotherListModal] = useState(false);

    const onSubmitAddToAnotherList = async data => {
        const listId = data.customerCompany.key;

        const success = await dispatch(addCompaniesToList(listId, selectedIds));

        setSelectedIds([]);
        setIsOpenAnotherListModal(false);

        if (success) {
            history.push(`${history.location.pathname}#${listId}`);
        }
    };

    const submitAddToAnotherListForm = () => addToAnotherListRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

    const showList = (selected, lists) => {
        // if (selected === 'important') return <ImportantList profileLoading={profileLoading} profileLink={profileLink} toggleImportant={toggleImportant} />;
        if (selected === 'customers') return <Customers setIsData={setIsData} phrase={phrase} selectedIds={selectedIds} setSelectedIds={setSelectedIds} toggleImportant={toggleImportant} />;
        if (selected === 'prospects') return <Prospects setIsData={setIsData} phrase={phrase} toggleImportant={toggleImportant} />;

        return <CreatedList
            ref={createdListRef}
            id={selected?.companyListId}
            lists={lists}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            removeCompany={removeCompaniesFromList}
            removeCompanyLoading={deleteCompaniesFromListLoading}
            profileLoading={profileLoading}
            profileLink={profileLink}
            toggleImportant={toggleImportant} />;
    };

    return (
        <>
            <ContentContainer>
                <Stack direction={smDown ? 'column' : 'row'} justifyContent='space-between' spacing={smDown ? 0 : 4}>
                    <MainTitle>
                        {intl.formatMessage({ id: 'advanced.drawer.customers' })}
                    </MainTitle>
                    <Stack
                        direction='row'
                        justifyContent='end'
                        alignItems='center'
                        spacing={1}
                        sx={{
                            width: '100%',
                            maxWidth: smDown ? 'unset' : theme.spacing(50),
                            mb: smDown && 2
                        }}
                    >
                        {isData &&
                            <FilterInput
                                value={phrase}
                                onChange={filterItems}
                                placeholder={selected === 'customers' ? 'customer.page.customers' : 'customer.page.prospects'}
                            />
                        }
                        {selected === 'customers' &&
                            <IconButton disabled={!isLoaded} onClick={() => setIsAddCustomerModalOpen(true)}>
                                <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                        }
                    </Stack>
                </Stack>
                <Box display='flex' sx={{ mt: 1 }}>
                    <Stack direction='row' spacing={1} sx={{ minWidth: 'auto', pb: 0.5 }}>
                        {/* {isImportantFound && <SelectedButton item='important' {...{ selected, setIsData, setPhrase }} />} */}
                        {staticLists?.map((item, index) => {
                            return <SelectedButton key={item + index} {...{ index, item, selected, setIsData, setPhrase }} />;
                        })}
                    </Stack>
                    {lists?.length > 0 && <Divider flexItem orientation='vertical' sx={{ borderColor: 'primary.main', mx: 1, mb: 0.5 }} />}
                    <Stack
                        direction='row'
                        spacing={1}
                        sx={{
                            overflowX: 'auto',
                            '&::-webkit-scrollbar': {
                                height: '3px'
                            },
                            pb: 0.5
                        }}
                    >
                        {lists?.map((item, index) => {
                            return <SelectedButton key={item.companyListId} {...{ index, item, selected, setIsData, setPhrase }} />;
                        })}
                    </Stack>
                </Box>
                {showList(selected, lists)}

                <Box display='flex' sx={{ justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                        {selectedIds.length > 0 && selected !== 'prospects' && selected !== 'important' &&
                            <AddButton
                                size='medium'
                                onClick={() => setIsOpenCreateListModal(true)}
                                sx={{ textTransform: 'upperCase' }}
                            >
                                {intl.formatMessage({ id: 'customer.page.create.list' })}
                            </AddButton>
                        }
                        {lists.length > 0 && selectedIds.length > 0 && selected !== 'prospects' && selected !== 'important' &&
                            <AddButton
                                size='medium'
                                onClick={() => setIsOpenAnotherListModal(true)}
                                sx={{ textTransform: 'upperCase' }}
                            >
                                {intl.formatMessage({ id: 'customer.page.add.companies.to.list' })}
                            </AddButton>
                        }
                    </Stack>
                    <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                        {selectedIds.length > 0 && selected !== 'customers' && selected !== 'prospects' && selected !== 'important' &&
                            <ConfirmButton
                                color='error'
                                variant='text'
                                disabled={deleteCompaniesFromListLoading}
                                isRemove={true}
                                confirmAction={() => removeCompaniesFromList(selected.companyListId, selectedIds)}
                                confirmText={intl.formatMessage({ id: 'common.yes' })}
                                cancelText={intl.formatMessage({ id: 'shared.cancel' })}
                                confirmBodyText={intl.formatMessage({ id: 'customer.page.delete.companies.from.list.body' })}
                                buttonText={intl.formatMessage({ id: 'customer.page.delete.companies.from.list' })}
                            />
                        }
                        {selected !== 'customers' && selected !== 'prospects' && selected !== 'important' &&
                            <ConfirmButton
                                color='error'
                                variant='text'
                                disabled={deleteListByIdLoading}
                                isRemove={true}
                                confirmAction={() => removeListById(selected.companyListId)}
                                confirmText={intl.formatMessage({ id: 'common.yes' })}
                                cancelText={intl.formatMessage({ id: 'shared.cancel' })}
                                confirmBodyText={intl.formatMessage({ id: 'customer.page.delete.list.body' })}
                                buttonText={intl.formatMessage({ id: 'customer.page.delete.list' })}
                            />
                        }
                    </Stack>
                </Box>

            </ContentContainer>
            <AddCustomerModal {...{ ref: addCustomerRef, data, isOpen: isAddCustomerModalOpen, close: () => setIsAddCustomerModalOpen(false), selected, createLoading, addLoading, submitForm, addAsCustomer, setOption, onSubmit }} />
            <CreateNewListModal  {...{ ref: createListRef, selectedIds, isOpen: isOpenCreateListModal, close: () => setIsOpenCreateListModal(false), submitForm: submitCreateListForm, onSubmit: onSubmitCreateList, createListLoading, removeSelectedCompany }} />
            <AddToAnotherListModal  {...{ ref: addToAnotherListRef, lists, selectedIds, isOpen: isOpenAnotherListModal, close: () => setIsOpenAnotherListModal(false), submitForm: submitAddToAnotherListForm, onSubmit: onSubmitAddToAnotherList, addCompaniesToListLoading, removeSelectedCompany }} />
        </>
    );
};

export default CustomersPage;
