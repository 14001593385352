import axios from 'utils/axios';
import { buildPlainUri, buildUri, validateHttpStatus } from 'utils/helpers/uriHelper';

const baseUrl = 'instruments';

class InstrumentsApi {
	async getInstruments() {
		const url = buildUri(baseUrl);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getInstrumentRatings(instrumentId) {
		const url = buildPlainUri(baseUrl, `${instrumentId}/rating`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getInstrumentsMarked() {
		// all
		const url = buildUri(`${baseUrl}/mark`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getInstrumentMarked(instrumentId) {
		const url = buildUri(`${baseUrl}/mark`, instrumentId); // api/instruments/mark/3173449-6/1f2d2dfa-a9ba-4bd0-a57d-b42e8185443c

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveInstrumentMarked(instrumentId) {
		const url = buildUri(`${baseUrl}/mark`, instrumentId);

		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async removeInstrumentMarked(instrumentId) {
		const url = buildUri(`${baseUrl}/mark`, instrumentId);

		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getInstrumentCategories() {
		const url = buildPlainUri(`${baseUrl}/categories`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getInstrumentCategoriesByName(name) {
		const url = buildPlainUri(`${baseUrl}/categories/${name}`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveInstrumentCategories(categories) {
		const url = buildPlainUri(`${baseUrl}/categories`);

		const response = await axios.post(url, categories, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveInstrumentCategoriesByName(categories, name) {
		const url = buildPlainUri(`${baseUrl}/categories/${name}`);

		const response = await axios.post(url, categories, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const instrumentsApi = new InstrumentsApi();
