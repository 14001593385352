import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const MissingInfoBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#eeece9',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
    fontWeight: '500',
    '& div': {
        textTransform: 'uppercase',
    },
    '& p': {
        width: '80%',
        textAlign: 'center',
        color: '#5a647C',
    },
    '& p:first-of-type': {
        fontWeight: 500,
        color: '#303f5a',
    }
}));

export default MissingInfoBox;
