import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import TimelineProjectItem from './TimelineProjectItem';

import './index.css';

const useStyles = makeStyles(theme => ({
    td: {
        textOverflow: 'ellipsis',
        padding: 0,
        textAlign: 'center',
    },
    gridContainer: {
        height: theme.spacing(5),
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        backgroundColor: theme.palette.primary.beige,
        cursor: 'pointer',
    },
    gridItem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        zIndex: 10,
        width: '100%',
    },
    gridContainerPrint: {
        height: theme.spacing(5),
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    name: {
        display: 'inline-block',
        fontSize: '0.8rem',
        fontWeight: 700,
        color: theme.palette.primary.white,
        height: '100%',
    },
    namePrintRight: {
        fontSize: '0.8rem',
        fontWeight: 700,
        paddingLeft: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    namePrintLeft: {
        fontSize: '0.8rem',
        fontWeight: 700,
        paddingRight: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    transparent: {
        display: 'none',
    },
}));

const TimelineMain = ({ projects, numberOfMonth, instruments, printView, profile, readonly }) => {
    const classes = useStyles();

    return projects?.map((instrument, index, array) => {
        const {
            rowKey,
            projectName,
            instrumentId,
            instrumentName,
            level,
            endDate,
            monthFromStart,
            monthDuration,
            isCustom = !instrument.instrumentType,
        } = instrument;

        const freeColumnsFromRight = numberOfMonth - (monthFromStart + monthDuration) ?? null;
        const freeColumnsFromLeft = monthFromStart ?? null;
        const instrumentTextSide = freeColumnsFromLeft - freeColumnsFromRight ?? null;

        return (
            <tr key={rowKey ?? instrumentId + endDate}>
                {monthFromStart > 0 && instrumentTextSide <= 0 && <td colSpan={monthFromStart} className={classes.td}></td>}
                {instrumentTextSide > 0 && (
                    <td colSpan={monthFromStart} className={classes.td}>
                        <div style={{ marginTop: array.length === 1 ? '0.5rem' : null }} className={`${classes.transparent} visible`}>
                            <Grid container justifyContent='flex-end' alignItems='center' className={classes.gridContainerPrint}>
                                <Grid item>
                                    <Typography variant='subtitle1' className={classes.namePrintLeft}>
                                        {instrumentName?.toUpperCase()}&nbsp;-
                                    </Typography>
                                    <Typography variant='subtitle1' className={classes.namePrintLeft}>
                                        &nbsp;{projectName?.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </td>
                )}
                <td colSpan={monthDuration} className={`${classes.td} tdPrint`}>
                    <div style={{ marginTop: array.length === 1 ? '0.5rem' : '' }} >
                        <TimelineProjectItem
                            readonly={readonly}
                            isCustom={isCustom}
                            instruments={instruments}
                            project={instrument}
                            printView={printView}
                            profile={profile}
                            level={level}
                            instrumentId={instrumentId}
                        />
                    </div>
                </td>
                {instrumentTextSide <= 0 && (
                    <td colSpan={freeColumnsFromRight} className={classes.td}>
                        <div
                            style={{ marginTop: array.length === 1 || index === 0 ? '0.5rem' : null }}
                            className={`${classes.transparent} visible`}
                        >
                            <Grid container justifyContent='flex-start' alignItems='center' className={classes.gridContainerPrint}>
                                <Grid item>
                                    <Typography variant='subtitle1' className={classes.namePrintRight}>
                                        {instrumentName?.toUpperCase()}&nbsp;-
                                    </Typography>
                                    <Typography variant='subtitle1' className={classes.namePrintRight}>
                                        &nbsp;{projectName?.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </td>
                )}
            </tr>
        );
    });
};

export default TimelineMain;
