import jwt_decode from 'jwt-decode';
import { storageKeys } from 'utils/constants/constants';
import storage from 'utils/localStorage/localStorageFunctions';
// @ts-ignore
import { matchPath } from 'react-router';
import { ADAccessToken, IAccessToken, ISAccessToken, JwtToken } from 'types/dataTypes';

const minutesConst = 2; // secure that token is not old, when making a request

/**
 * SET local storage token object
 * @param {token object} token
 */
const setToken = (token: JwtToken) => {
	storage.set(storageKeys.TOKEN_KEY, token);
};

/**
 * GET local storage token object
 */
const getToken = (): any => {
	return storage.get(storageKeys.TOKEN_KEY) as JwtToken;
};

/**
 * GET local storage token object and combines token type to token for authorization
 */
const getAccessToken = () => {
	const token = getToken();

	return parseAccessTokenFromTokenData(token);
};

const parseAccessTokenFromTokenData = (tokenData: any) => {
	if (!tokenData) return null;

	if (tokenData.access_token) return `${tokenData.token_type} ${tokenData.access_token}`;

	// @ts-ignore this is azure ad token
	return `${tokenData.tokenType} ${tokenData.accessToken}`;
};

/**
 * Get refresh token from local storage
 */
const getRefreshToken: any = () => {
	const token = getToken();

	if (!token) return null;

	return token.refresh_token;
};

/**
 * Check if given token is expired
 * @param {token object} token
 */
const isExpired = (token: JwtToken, minutes = minutesConst) => {
	if (token === undefined || token === null) return false;

	const now = new Date();

	return token?.expireTime > now.getTime() + minutes * 60000;
};

/**
 * Get decoded access token
 */
const getDecodedAccessToken = <T extends IAccessToken>(): T | null => {
	const accessToken = getAccessToken();

	if (accessToken == null) return null;

	return jwt_decode<T>(accessToken);
};

export const isAdToken = (obj: IAccessToken): boolean => {
	if (typeof obj === 'object' && obj.hasOwnProperty('preferred_username')) {
		return true;
	}

	return false;
};

const getUsernameFromToken = () => {
	const decodedAccessToken = getDecodedAccessToken<IAccessToken>();

	if (decodedAccessToken == null) return null;

	if (isAdToken(decodedAccessToken)) {
		const adToken = decodedAccessToken as ADAccessToken;
		return adToken?.preferred_username;
	}

	const isToken = decodedAccessToken as ISAccessToken;
	return isToken?.username;
};

const isContributorToken = () => {
	const decodedAccessToken = getDecodedAccessToken<ADAccessToken>();

	if (decodedAccessToken == null) return false;

	if (adContributor(decodedAccessToken, 'Granted.Contributor')) return true;

	return false;
};

const isAdminToken = () => {
	const decodedAccessToken = getDecodedAccessToken<ADAccessToken>();

	if (decodedAccessToken == null) return false;

	if (adContributor(decodedAccessToken, 'Granted.UserAdmin')) return true;

	return false;
};

const adContributor = (accessToken: ADAccessToken, role: string) => {
	if (!accessToken) return null;

	const isContributor =
		accessToken.roles && Array.isArray(accessToken.roles)
			? accessToken.roles.some(existingRole => existingRole === role)
			: accessToken.role === role;

	return isContributor ?? false;
};

const isStaticProfile = () => {
	try {
		const match = matchPath<{ link?: string }>(window.location.pathname, {
			path: '/profile/:link',
		});

		if (match?.params?.link) {
			return true;
		}
	} catch (error) {
		// do not remove this
	}

	return false;
};

const isLoggedIn = () => {
	return getDecodedAccessToken() !== null;
};

export {
	getAccessToken,
	getDecodedAccessToken,
	getRefreshToken,
	getToken,
	getUsernameFromToken,
	isAdminToken,
	isContributorToken,
	isExpired,
	isLoggedIn,
	isStaticProfile,
	parseAccessTokenFromTokenData,
	setToken,
};
