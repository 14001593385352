import admin from './admin.json';
import advanced from './advanced.json';
import ai from './ai.json';
import businessPlan from './businessPlan.json';
import collaboration from './collaboration.json';
import instrument from './instrument.json';
import main from './main.json';
import marketplace from './marketplace.json';
import onboarding from './onboarding.json';
import privacy from './privacy.json';
import project from './project.json';
import stakeholder from './stakeholder.json';
import timeline from './timeline.json';

const translations = {
	...main,
	...marketplace,
	...project,
	...instrument,
	...admin,
	...timeline,
	...businessPlan,
	...advanced,
	...collaboration,
	...stakeholder,
	...ai,
	...onboarding,
	...privacy,
};

export default translations;
