import { useIntl } from 'react-intl';

import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/material';

import Button from 'components/Button/Button';
import ConfirmButton from 'components/Button/ConfirmButton';
import AdminContentContainer from 'components/Containers/AdminContentContainer';

import { publishDraftProject, saveProject, saveProjectAsDraft } from 'containers/Projects/slices';

const ProjectSaveButtonContainer = ({ isDirty, loading, handleSubmit, onSubmit, state, isLocalBackup, extraButtons }) => {
	const intl = useIntl();
	const draftConst = 'Draft';

	return (
		<Stack
			direction='row'
			spacing={1}
			sx={{
				width: '100%',
				justifyContent: {
					xs: 'space-between',
					smd: 'flex-end',
				},
			}}
		>
			<AdminContentContainer
				alternateContent={
					<Button
						color='success'
						disabled={loading ? true : isLocalBackup ? false : !isDirty}
						onClick={handleSubmit(data => onSubmit(data, saveProject, true))}
						loading={loading}
					>
						{intl.formatMessage({ id: 'shared.save' })}
					</Button>
				}
			>
				{extraButtons}
				<ConfirmButton
					startIcon={<PublishIcon />}
					disabled={(loading ? true : isLocalBackup ? false : !isDirty) && state !== draftConst}
					color='success'
					confirmAction={handleSubmit(data => onSubmit(data, publishDraftProject, true))}
					confirmText={intl.formatMessage({ id: 'project.dashboard.menu.published.confirmText' })}
					cancelText={intl.formatMessage({ id: 'project.dashboard.menu.published.cancelText' })}
					confirmBodyText={intl.formatMessage({ id: 'project.dashboard.menu.published.confirmBodyText' })}
					buttonText={intl.formatMessage({ id: 'project.plan.save.publish' })}
				/>
				<Button
					startIcon={<SaveIcon />}
					color='success'
					disabled={loading ? true : isLocalBackup ? false : !isDirty}
					onClick={handleSubmit(data => onSubmit(data, saveProjectAsDraft, true))}
					loading={loading}
				>
					{intl.formatMessage({ id: 'project.plan.save.draft' })}
				</Button>
			</AdminContentContainer>
		</Stack>
	);
};

export default ProjectSaveButtonContainer;
