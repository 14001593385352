import React from 'react';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

export default function ThankYouCard({ title, description, sx }) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                color: theme.palette.primary.main,
                padding: '1rem',
                textAlign: 'center',
                ...sx,
            }}
        >
            <CardHeader
                action={null}
                title={<Typography sx={{ fontSize: '1.5rem' }}>{title}</Typography>}
                subheader={null}
            />
            <CardContent>
                <Typography sx={{ fontSize: '1.2rem' }}>{description}</Typography>
            </CardContent>
        </Box>
    );
}