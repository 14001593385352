import ReactGA4 from 'react-ga4';

import config from 'config/environment';
import { storageKeys } from 'utils/constants/constants';
import storage from 'utils/localStorage/localStorageFunctions';

class Analytics {
	constructor(key) {
		try {
			const consent = storage.get(storageKeys.COOKIE_CONSENT_KEY);
			if (!consent || consent === 'false') return;

			ReactGA4.initialize([{ trackingId: key }]);
		} catch (error) {
			console.error(error);
		}
	}

	addPageView(path) {
		try {
			const consent = storage.get(storageKeys.COOKIE_CONSENT_KEY);
			if (!consent || consent === 'false') return;

			ReactGA4.send('pageview', path);
		} catch (error) {
			console.error(error);
		}
	}

	addCompanyPageView(pathname, isContributor, isAdvanced, company) {
		try {
			const consent = storage.get(storageKeys.COOKIE_CONSENT_KEY);
			if (!consent || consent === 'false') return;

			if (isContributor) {
				ReactGA4.send('pageview', pathname + window.location.search);
			}
			if (isAdvanced) {
				ReactGA4.send('pageview', `advanced/${company?.CompanyId}${pathname}${window.location.search}`);
			} else {
				if (company) {
					ReactGA4.send('pageview', `${company?.CompanyId}${pathname}${window.location.search}`);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}
}

export const analytics = new Analytics(config.GATAG);
