import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

// @ts-ignore
const Subtitle = styled(Typography, {
	shouldForwardProp: (prop: any) => prop !== 'color' && prop !== 'variant' && prop !== 'sx',
	overridesResolver: (props: any, styles: any) => [
		styles.root,
		props.color === 'primary' && styles.primary,
		props.color === 'secondary' && styles.secondary,
	],
})(({ theme, mt, mb, padding }: { theme: any; mt: string | number; mb: string | number; padding: string | number }) => ({
	color: theme.palette.primary.main,
	padding: padding,
	fontWeight: 600,
	marginTop: mt ?? 'revert',
	marginBottom: mb ?? 'revert',
}));

const SubtitleComponent = ({
	id = '',
	className = '',
	variant = 'h2',
	mt = 'revert',
	mb = 'revert',
	padding = '1rem 0',
	sx = {},
	children,
}: {
	id?: string;
	className?: string;
	variant?: any;
	mt?: string | number;
	mb?: string | number;
	padding?: string | number;
	sx?: any;
	children: any;
}) => {
	let styles = Object.assign({ fontSize: '1.3rem' }, sx);

	return (
		<Subtitle id={id} className={className} variant={variant} mt={mt} mb={mb} padding={padding} sx={styles}>
			{children}
		</Subtitle>
	);
};

export default SubtitleComponent;
