import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ReferenceForm from 'containers/Admin/ReferencesPage/ReferenceForm';
import Spinner from 'components/Spinner/CenteredSpinner';
import MainTitle from 'components/Titles/MainTitle';
import ContentContainer from 'components/Containers/ContentContainer';
import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';

import { reducer, name, loadInstruments } from '../InstrumentsEditPage/slices';

import { useInjectReducer } from 'utils/injectors/injectReducer';
import { useFetchInstrumentCategoriesQuery, useUpsertInstrumentCategoriesMutation } from 'containers/Instruments/instrumentV2Api';
import { toast } from 'react-toastify';

const ReferencesPage = () => {
	useInjectReducer({ key: name, reducer: reducer });

	const dispatch = useDispatch();
	const formRef = useRef();
	const intl = useIntl();

	const [upsertInstrumentCategories, { isLoading: isUpsertLoading }] = useUpsertInstrumentCategoriesMutation();

	const adminInstruments = useSelector(state => state.admininstruments);
	const instrumentsData = adminInstruments?.instruments;
	const instrumentsLoading = adminInstruments?.loading;

	const { data: categories, isLoading: isCategoriesLoading } = useFetchInstrumentCategoriesQuery({ name: 'references' });

	const loading = instrumentsLoading || isCategoriesLoading;

	const [isFormDirty, setIsFormDirty] = useState(false);

	useEffect(() => {
		dispatch(loadInstruments());
	}, [dispatch]);

	const onSubmit = React.useCallback(
		async fieldValues => {
			try {
				await upsertInstrumentCategories({ name: 'references', categories: fieldValues.categories }).unwrap();
				toast.success(intl.formatMessage({ id: 'references.saving.successed' }));
			} catch (error) {
				toast.error(intl.formatMessage({ id: 'references.saving.failed' }));
			}
		},
		[intl, upsertInstrumentCategories]
	);

	const submitForm = () => formRef.current?.submitForm();
	const resetForm = () => formRef.current?.resetForm();

	return loading ? (
		<Spinner />
	) : (
		<ContentContainer>
			<Box display='flex' sx={{ justifyContent: 'space-between' }}>
				<MainTitle>{intl.formatMessage({ id: 'references.page.title' })}</MainTitle>
				<Stack direction='row' spacing={2}>
					<Button disabled={loading || isUpsertLoading || !isFormDirty} variant='outlined' color='primary' onClick={resetForm}>
						{intl.formatMessage({ id: 'reset.form' })}
					</Button>
					<Button disabled={loading || isUpsertLoading || !isFormDirty} variant='contained' color='success' onClick={submitForm}>
						{intl.formatMessage({ id: 'shared.save' })}
						{(loading || isUpsertLoading) && <ButtonSpinner />}
					</Button>
				</Stack>
			</Box>
			<ReferenceForm
				ref={formRef}
				categories={categories}
				instruments={instrumentsData}
				setIsFormDirty={setIsFormDirty}
				onSubmit={onSubmit}
			/>
		</ContentContainer>
	);
};

export default ReferencesPage;
