import moment from 'moment';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Divider, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import WidgetContainer from 'components/Containers/WidgetContainer';

import getCompanyLink from 'utils/auth/getCompanyLink';
// import { stubFalse } from 'lodash';

const SectionTitle = styled(Typography)(() => ({
	fontWeight: 600,
	paddingBottom: '1rem',
}));

const StyledTitle = styled(Typography)(() => ({
	fontSize: '1.1rem',
}));

const iterateDynamicData = obj => {
	const ret = {};

	const iterate = (obj, stack) => {
		for (let property in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(property)) {
				if (typeof obj[property] == 'object') {
					iterate(obj[property], stack + '.' + property);
				} else {
					let stackName = stack.replace('.', '');

					if (typeof obj[property] == 'string') {
						if (!ret[stackName]) ret[stackName] = {};

						ret[stackName][property] = obj[property] ? true : false;
					}
				}
			}
		}
	};

	iterate(obj, '');

	return ret;
};

const ItemCollection = ({ obj, title, hasTemplate = false }) => {
	if (!obj) return null;

	return (
		<Grid item xs={12} md={hasTemplate ? 4 : 6}>
			<SectionTitle>{title}</SectionTitle>
			{Object.keys(obj).map(item => (
				<Item key={item} title={item} value={obj[item]} />
			))}
		</Grid>
	);
};

const Item = ({ children, title, prefixedTitle = false, value, sx = {} }) => {
	const intl = useIntl();

	if (!title) return null;

	return (
		<>
			<Stack
				sx={{
					marginTop: '.5rem',
					width: '85%',
					overflow: 'hidden',
					'& span': {
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					},
					...sx,
				}}
				direction='row'
				justifyContent='space-between'
			>
				<span>{!prefixedTitle ? intl.formatMessage({ id: `project.form.${title}` }) : title}</span>
				{value ? <DoneIcon color='success' /> : <CheckBoxOutlineBlankIcon />}
			</Stack>
			{children}
		</>
	);
};

const TemplateItemNew = ({ template, dynamic }) => {
	if (!template?.fields || !dynamic) return null;

	return template.fields.map((item, index) => {
		const dynamicDataItem = dynamic[item.id];

		if (item.fields?.every(field => field.type === 'table' || field.type === 'object')) return null; // don't iterate dynamic tables

		const childrenAreInvalid = dynamicDataItem ? Object.keys(dynamicDataItem)?.some(child => dynamicDataItem[child] === false) : true;

		// const itemChildren = item?.fields?.map((field, fieldIndex) => {
		//     if (field.type === 'table' || field.type === 'object') return null;

		//     let resultChild = false;

		//     if (dynamicDataItem && dynamicDataItem[field.id]) {
		//         resultChild = dynamicDataItem[field.id];
		//     }

		//     return (
		//         <Item prefixedTitle={true} key={field.id + fieldIndex} sx={{ pl: '1rem' }} title={field.title} value={resultChild} />
		//     );
		// });

		return (
			<Item key={item.id + index} prefixedTitle={true} title={item?.title} value={!childrenAreInvalid} sx={{ mt: '.5rem' }}>
				{/* {childrenAreInvalid ? itemChildren : null} */}
			</Item>
		);
	});
};

export default function ProjectPlanWidget({ project, template }) {
	const location = useLocation();
	const intl = useIntl();

	if (!project || !template) return null;

	const projectApplicationItems = iterateDynamicData(project.dynamic, '');

	const projectBase = {
		projectName: project.projectName ? true : false,
		instrumentName: project.instrumentName ? true : false,
		startDate: project.startDate ? true : false,
		endDate: project.endDate ? true : false,
		projectSize: project.projectSize !== null ? true : false,
		amountToApply: project.amountToApply !== null ? true : false,
		deminimisAmount: project.deminimisAmount !== null ? true : false,
		projectSummary: project.projectSummary ? true : false,
	};

	const projectPlanItems = {
		projectApplication: project.projectApplication ? true : false,
		projectBackground: project.projectBackground ? true : false,
		projectDevelopment: project.projectDevelopment ? true : false,
		projectMarket: project.projectMarket ? true : false,
		projectName: project.projectName ? true : false,
		projectService: project.projectService ? true : false,
		projectSummary: project.projectSummary ? true : false,
		projectVision: project.projectVision ? true : false,
	};

	const Summary = () => {
		return (
			<>
				<Divider sx={{ mb: '1rem' }} />
				<Grid
					container
					sx={{
						overflow: 'auto',
						textOverflow: 'ellipsis',
						maxHeight: '23rem',
					}}
				>
					<ItemCollection
						hasTemplate={template && template?.showProjectPlan}
						title={intl.formatMessage({ id: 'project.widget.common.title' })}
						obj={projectBase}
					/>
					{template?.showProjectPlan ? (
						<ItemCollection
							hasTemplate={template}
							title={intl.formatMessage({ id: 'project.widget.plan.title' })}
							obj={projectPlanItems}
						/>
					) : null}
					{projectApplicationItems && template && (
						<Grid item xs={12} md={template.showProjectPlan ? 4 : 6}>
							<SectionTitle>{intl.formatMessage({ id: 'project.widget.application.title' })}</SectionTitle>
							{/* {Object.keys(projectApplicationItems).map(item =>
                                <TemplateItem key={item} {...{ template, item: { key: item, children: projectApplicationItems[item] } }} />
                            )}
                            <hr /> */}
							<TemplateItemNew {...{ template, dynamic: projectApplicationItems }} />
						</Grid>
					)}
				</Grid>
			</>
		);
	};

	return (
		<WidgetContainer
			sx={{
				height: '100%',
				maxHeight: '50rem',
				pb: '2rem',
				background: 'white',
			}}
			title={<FormattedMessage id='project.plan.title' />}
			footerContent={<Summary />}
			href={getCompanyLink(`/projects/${project?.projectId}/plan`, location)}
			link={<FormattedMessage id='project.plan.link' />}
		>
			<Grid alignItems='center' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<StyledTitle color='textPrimary' variant='subtitle2'>
						<FormattedNumber value={project.projectSize} style={'currency'} currency='EUR' />
					</StyledTitle>
					<Typography color='textSecondary' variant='body2'>
						<FormattedMessage id='project.size' />
					</Typography>
				</Grid>
				<Grid item>
					<Stack direction='row' spacing={2}>
						<Box>
							<StyledTitle color='textPrimary' variant='subtitle2'>
								{moment(project.startDate).format('MM-YYYY')}
							</StyledTitle>
							<Typography color='textSecondary' variant='body2'>
								<FormattedMessage id='project.start' />
							</Typography>
						</Box>
						<Box>-</Box>
						<Box>
							<StyledTitle color='textPrimary' variant='subtitle2'>
								{moment(project.endDate).format('MM-YYYY')}
							</StyledTitle>
							<Typography color='textSecondary' variant='body2'>
								<FormattedMessage id='project.ends' />
							</Typography>
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</WidgetContainer>
	);
}
