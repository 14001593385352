import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import CustomPrompt from 'components/CustomPrompt';
import { FormControlledAutocomplete } from 'components/Form';

import { getDirtyKeys } from 'components/Form/utils/utils';

const getSchema = intl =>
	yup.object().shape({
		customerCompany: yup
			.object()
			.nullable()
			.required(
				`${intl.formatMessage({ id: 'customer.page.modal.form.companyListId.label' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			),
	});

const AddToAnotherListForm = forwardRef(({ lists = [], onSubmit, setIsDirty, addCompaniesToListLoading }, ref) => {
	const intl = useIntl();
	const schema = getSchema(intl);

	const {
		control,
		handleSubmit,
		formState: { dirtyFields, errors },
	} = useForm({
		defaultValues: {
			customerCompany: null,
		},
		resolver: yupResolver(schema),
	});

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	useEffect(() => {
		setIsDirty(isDirty);
	}, [isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

	const options = lists.map(({ companyListId, name }) => ({ key: companyListId, value: name }));

	return (
		<form ref={ref} onSubmit={handleSubmit(data => onSubmit(data))}>
			<CustomPrompt dirtyFields={dirtyFields} isDirty={isDirty} translation='test' /> {/* TODO: Check prompt does not work */}
			<FormControlledAutocomplete
				name='customerCompany'
				label={intl.formatMessage({ id: 'customer.page.modal.form.listName.label' })}
				error={errors?.customerCompany?.message}
				control={control}
				options={options}
				disabled={addCompaniesToListLoading}
				defaultValue={null}
				placeholder='Valitse...'
			/>
		</form>
	);
});

export default AddToAnotherListForm;
