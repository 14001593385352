import { isNil } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import EditIcon from '@mui/icons-material/Edit';
import { Alert, AlertTitle, Box, BoxProps, Button, DialogContent, DialogTitle, Skeleton, Stack, Typography } from '@mui/material';

import { getCompany, getCompanyId } from 'utils/auth/company';

import { useFetchRfpProposalQuery, useFetchRfpRequestProposalQuery } from 'containers/Marketplace/rfpProposalApi';
import { useFetchRfpRequestQuery } from '../../rfpRequestApi';

import CanUpsertRfpProposal from 'containers/Marketplace/proposal/CanUpsertRfpProposal';
import ReadRfpProposalAttachmentList from 'containers/Marketplace/proposal/Read/Attachments/ReadRfpProposalAttachmentList';
import RfpProposalTeamMemberDialog from 'containers/Marketplace/proposal/Read/Team/RfpProposalTeamMemberDialog';
import RfpProposalTeamMemberList from 'containers/Marketplace/proposal/Read/Team/RfpProposalTeamMemberList';
import CompanyProfileBadge from '../../profile/CompanyProfileBadge';
import RfpProposalItemList from './Items/RfpProposalItemList';
import PrintRfpProposal from './PrintRfpProposal';

type Props = BoxProps & {
	requestId: string | null;
	proposalId: string | null;
	published: boolean;
	createdByMe: boolean;
	onEditClick?: () => void;
};

const ReadRfpProposalForm: React.FC<Props> = ({ requestId, proposalId, published, createdByMe, onEditClick, ...otherProps }) => {
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const {
		data: rfpProposal1,
		isLoading: isRfpProposalLoading1,
		isError: isRfpProposalError1,
	} = useFetchRfpProposalQuery({ companyId, proposalId, published }, { skip: isNil(companyId) || isNil(requestId) || !createdByMe });

	const {
		data: rfpProposal2,
		isLoading: isRfpProposalLoading2,
		isError: isRfpProposalError2,
	} = useFetchRfpRequestProposalQuery(
		{ companyId, requestId, proposalId },
		{ skip: isNil(companyId) || isNil(requestId) || isNil(proposalId) || createdByMe }
	);

	const {
		data: rfpRequest,
		isLoading: isRfpRequestLoading,
		isError: isRfpRequestError,
	} = useFetchRfpRequestQuery({ companyId, requestId, published: true }, { skip: isNil(companyId) || isNil(requestId) });

	const rfpProposal = rfpProposal1 ?? rfpProposal2;
	const isRfpProposalLoading = isRfpProposalLoading1 ?? isRfpProposalLoading2;
	const isRfpProposalError = isRfpProposalError1 ?? isRfpProposalError2;

	const isLoading = isRfpRequestLoading || isRfpProposalLoading;

	return (
		<Box {...otherProps}>
			<DialogTitle>
				<Typography component='span' sx={{ fontWeight: 600 }}>
					<FormattedMessage id='rfpProposal.title' /> -
				</Typography>
				<Typography component='span' sx={{ ml: '.5rem' }}>
					{rfpRequest?.projectTitle} -
				</Typography>
				<Typography component='span' sx={{ ml: '.5rem', fontWeight: 600 }}>
					{rfpProposal?.companyName}
				</Typography>
			</DialogTitle>
			<DialogContent>
				{isRfpProposalError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='query.error.title' />
						</AlertTitle>
						<FormattedMessage id='fetchRfpProposal.error.message' />
					</Alert>
				)}
				{isRfpRequestError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='query.error.title' />
						</AlertTitle>
						<FormattedMessage id='fetchRfpRequest.error.message' />
					</Alert>
				)}
				<Stack spacing={3}>
					<Stack flex='1' flexDirection='row' alignItems='flex-start' justifyContent='flex-end'>
						{isLoading ? (
							<Skeleton width={200} height={100} />
						) : (
							<>
								<PrintRfpProposal {...{ requestId, proposalId, published, createdByMe }} />
								<CanUpsertRfpProposal rfpRequest={rfpRequest} rfpProposal={rfpProposal}>
									{canUpsert =>
										canUpsert && (
											<Button size='small' endIcon={<EditIcon />} onClick={() => onEditClick?.()}>
												<FormattedMessage id='rfpProposal.edit' />
											</Button>
										)
									}
								</CanUpsertRfpProposal>
							</>
						)}
					</Stack>
					<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
						<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
							{isLoading ? (
								<>
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
								</>
							) : (
								<>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpProposal.projectOutline.label' />
										</Typography>
										<Typography>{rfpProposal?.projectOutline}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpProposal.projectBudget.label' />
										</Typography>
										<Typography>{rfpProposal?.projectBudget}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpProposal.projectTimeline.label' />
										</Typography>
										<Typography>{rfpProposal?.projectTimeline}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpProposal.contactInformation.label' />
										</Typography>
										<Typography>{rfpProposal?.contactInformation}</Typography>
									</Box>
								</>
							)}
						</Stack>
						<Stack flex='1' maxWidth={{ xs: '100%', md: '40%' }}>
							{isLoading ? (
								<>
									<Skeleton height={100} />
									<Skeleton height={100} />
								</>
							) : (
								<>
									<CompanyProfileBadge companyId={rfpProposal?.companyId} />
									<RfpProposalTeamMemberList teamMembers={rfpProposal?.rfpProposalTeamMembers} />
									<RfpProposalTeamMemberDialog />
									<RfpProposalItemList rfpProposalItems={rfpProposal?.rfpProposalItems} />
									<ReadRfpProposalAttachmentList
										requestId={requestId}
										proposalId={rfpProposal?.rfpProposalId}
										createdByMe={createdByMe}
										attachments={rfpProposal?.attachments}
									/>
								</>
							)}
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
		</Box>
	);
};

export default ReadRfpProposalForm;
