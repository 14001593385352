import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';

import { FormControlledInput } from 'components/Form';

const InfoItemForm = forwardRef(({ control, errors, infoItem, handleSubmit, onSubmit, readonly }, formRef) => {
    const intl = useIntl();

    return (
        <form ref={formRef} noValidate onSubmit={handleSubmit(data => onSubmit(data))}>
            <Controller
                name='infoId'
                defaultValue={infoItem.infoId ?? ''}
                control={control}
                render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} xl={6}>
                    <FormControlledInput
                        disabled={readonly}
                        defaultValue={infoItem.title ?? ''}
                        name='title'
                        control={control}
                        error={errors?.title?.message}
                        label={intl.formatMessage({ id: 'business.plan.form.title' })}
                    />
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormControlledInput
                        disabled={readonly}
                        defaultValue={infoItem.value ?? ''}
                        name='value'
                        control={control}
                        error={errors?.value?.message}
                        label={intl.formatMessage({ id: 'business.plan.form.value' })}
                    />
                </Grid>
            </Grid>
        </form>
    );
});

export default InfoItemForm;
