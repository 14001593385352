import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import MateriaButton from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme, withStyles } from '@mui/styles';

import TextContainer from 'components/Containers/TextContainer';
import Subtitle from 'components/Titles/Subtitle';
import ProjectModalForm from 'containers/Projects/Project/ProjectForm/ProjectModalForm';
import InstrumentAttributes from './Instrument/InstrumentAttributes';
import InstrumentDrawer from './InstrumentDrawer';
import InstrumentRadar from './InstrumentRadar';
import InstrumentRadarFreemium from './InstrumentRadarFreemium';
import InstrumentToChat from './InstrumentToChat';

import { getCompanyId, isAdvancedToken, isFreemiumToken } from 'utils/auth/company';
import { getUsernameFromToken, isAdminToken } from 'utils/auth/token';
import { regexCompany } from 'utils/constants/regex';

import Button from 'components/Button/Button';
import htmlParser from 'components/HtmlParser';
import { useCreateInstrumentAnalyticsMutation } from 'containers/Analytics/analyticsApi';
import InstrumentProviderLinks from 'containers/Instruments/InstrumentList/InstrumentItem/InstrumentProviderLink/InstrumentProviderLink';
import { useDispatch } from 'react-redux';

import AdminContentContainer from 'components/Containers/AdminContentContainer';
import InstrumentAnalysis from '../../InstrumentAIAnalysis/InstrumentAnalysis';
import InstrumentTagItem from './InstrumentTagItem';

const useStyles = makeStyles((theme: any) => ({
	root: {
		padding: 0,
		marginBottom: theme.spacing(0.5),
		'&:last-child': {
			marginBottom: 0,
		},
	},
	accordion: {
		boxShadow: 'none',
		width: '100%',
	},
	accordionSummary: {
		backgroundColor: theme.palette.primary.white,
		margin: 0,
		'& .MuiAccordionSummary-content': {
			margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
		},
		'&.Mui-expanded': {
			minHeight: theme.spacing(6),
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {},
		'& .MuiAccordionSummary-expandIconWrapper': {},
	},
	expandIcon: {
		color: theme.palette.primary.main,
		'@media print': {
			display: 'none',
		},
	},
	lineBetweenSummaryAndDetails: {
		height: theme.spacing(0.25),
		backgroundColor: theme.palette.primary.secondary,
	},
	accordionDetails: {
		maxWidth: '100%',
		backgroundColor: theme.palette.primary.white,
	},
	detailsContainer: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			paddingBottom: theme.spacing(2),
		},
	},
	name: {
		fontSize: '1.1rem',
		fontWeight: 500,
		wordBreak: 'break-word',
		color: theme.palette.primary.main,
		paddingBottom: theme.spacing(0.5),
		[theme.breakpoints.up('md')]: {
			marginRight: theme.spacing(2),
		},
	},
	provider: {
		fontSize: '0.85rem',
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			marginRight: theme.spacing(2),
		},
	},
	chip: {
		height: '22px',
		marginRight: theme.spacing(2),
	},
	type: {
		fontSize: '0.8rem',
		color: theme.palette.primary.main,
	},
	divider: {
		backgroundColor: theme.palette.primary.main,
		height: '1rem',
		margin: '0 1rem -0.25rem',
	},
	description: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		width: '100%',
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
	},
	buttonsContainer: {
		marginTop: theme.spacing(3),
		flexGrow: 1,
		alignItems: 'flex-end',
	},
	ingress: {
		width: '100%',
		fontSize: '1rem',
	},
	noValidContainer: {
		marginTop: '3rem',
	},
	attributesContainer: {
		marginTop: '2rem',
	},
}));

const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.primary.white,
		color: theme.palette.secondary.grey700,
		boxShadow: theme.shadows[1],
		fontSize: '0.85rem',
	},
}))(Tooltip);

const RelatedInstrumentsList: React.FC<any> = ({
	instrument,
	selectInstrument,
	instruments,
	currentInstrumentId,
	company,
	link,
	profile,
	changeMarked,
	readonly,
}) => {
	return instrument?.relatedInstruments.map(({ id }: { id: string }, index: number) => {
		const instrument = selectInstrument(id);

		if (!instrument) return null;

		return (
			<InstrumentItem
				key={instrument.id + index}
				instrument={instrument}
				instruments={instruments}
				currentInstrumentId={currentInstrumentId}
				company={company}
				related={true}
				link={link}
				profile={profile}
				boxShadow='rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
				component='div'
				showDetails={false}
				changeMarked={changeMarked}
				selectInstrument={selectInstrument}
				readonly={readonly}
			/>
		);
	});
};

type InstrumentItemProps = {
	instrument: any;
	currentInstrumentId: string | null;
	instruments: any[];
	company: any;
	newInstruments?: any[];
	improvedInstruments?: any[];
	link: any;
	scrollToTimeline?: any;
	scrollToCurrentInstrument?: any;
	related: any;
	profile: any;
	boxShadow: string;
	component: any;
	showDetails: boolean;
	changeMarked: any;
	category?: any;
	costs?: any[];
	readonly: boolean;
	selectInstrument: any;
};

const InstrumentItem: React.FC<InstrumentItemProps> = ({
	instrument,
	currentInstrumentId = null,
	instruments,
	company,
	newInstruments = [],
	improvedInstruments = [],
	link = false,
	scrollToTimeline = null,
	scrollToCurrentInstrument = null,
	related = false,
	profile = false,
	boxShadow = 'unset',
	component = 'li',
	showDetails = true,
	changeMarked = null,
	category = null,
	costs = [],
	readonly,
	selectInstrument,
}) => {
	const { id, name, provider, ingress, level, type, projectAimArr, projectObjectsArr, projectValueArr, limitators, isMarked } =
		instrument;

	const isFreemium = isFreemiumToken();

	const projectTags = [].concat(projectAimArr ?? [], projectObjectsArr ?? []);

	const history = useHistory();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const params: any = useParams();
	const intl = useIntl();
	const isAdvanced = isAdvancedToken();
	const isAdmin = isAdminToken();
	const companyId = getCompanyId();
	const classes = useStyles();
	const theme: any = useTheme();
	const smdDown = useMediaQuery(theme.breakpoints.down('smd'));
	const down1530 = useMediaQuery(theme.breakpoints.down(1530));
	const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));

	const [createInstumentAnalytics] = useCreateInstrumentAnalyticsMutation();

	const noAttributesToRender =
		!instrument?.isDeminimis &&
		!instrument?.projectSizeEur &&
		!instrument?.expectedPendingTime &&
		!instrument?.amountMinPercentage &&
		!instrument?.amountMaxPercentage &&
		!instrument?.minAmount &&
		!instrument?.maxAmount &&
		!instrument?.prepayment &&
		!instrument?.timeFrame &&
		!instrument?.installmentsFreeTime;

	// const selectInstrument = id => allInstruments?.find(item => item.id === id);

	const currentInstrument = selectInstrument(params?.id);
	const currentInstrumentName = currentInstrument?.name;

	const [expanded, setExpanded] = useState(currentInstrumentId);

	const handleChange = (id: string) => (event: any, isExpanded: boolean) => {
		if (isExpanded) {
			createInstumentAnalytics({
				action: 'CardOpen',
				instrumentId: id,
				companyId: companyId,
				url: null,
				customData: `user: ${getUsernameFromToken()}, windowUrl: ${window?.location?.href}`,
			});
		}
		setExpanded(isExpanded ? id : null);
	};

	const isNew = newInstruments.includes(id);
	const isImproved = improvedInstruments.includes(id);

	const openInstrumentPage = useCallback(() => {
		const state = {
			pathname: pathname,
			name: currentInstrumentName,
		};

		let instrumentUrl = `instruments/${id}`;

		instrumentUrl = category ? `${instrumentUrl}?category=${category}` : instrumentUrl;

		regexCompany.test(pathname)
			? history.push(`/company/${companyId}/${instrumentUrl}`, state)
			: history.push(`/${instrumentUrl}`, state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, pathname, id, currentInstrumentName, companyId]);

	const [isOpenDrawer, setIsOpenDrawer] = useState(false);
	const openInstrumentDrawer = () => setIsOpenDrawer(true);
	const closeInstrumentDrawer = () => {
		setIsOpenDrawer(false);
	};

	const parsedIngress = ingress?.replace(/(<p>&nbsp;<\/p>)+/g, '');

	useEffect(() => {
		if (history.action === 'POP' && scrollToCurrentInstrument && currentInstrumentId && id === currentInstrumentId) {
			setTimeout(() => scrollToCurrentInstrument(id), 500); // setTimeout is required to render correctly --> TODO: check it
		}
	}, [id, currentInstrumentId, scrollToCurrentInstrument, history.action]);

	useEffect(() => {
		if (pathname === `/instruments/${id}`) {
			setExpanded(id);
			openInstrumentPage();
		}
	}, [pathname, id, openInstrumentPage]);

	const radarSize = useMemo(() => {
		let size = mdAndUp ? 400 : 300;
		if (related) {
			size -= 50;
		}
		return size;
	}, [mdAndUp, related]);

	const display = { xs: 'none', lg: related ? 'none' : 'block', xl: 'block' };

	const handleMark = (event: any) => {
		event.stopPropagation();
		changeMarked && changeMarked(id, isMarked);
	};

	const levelColor = useMemo(() => {
		if (isFreemium) {
			return theme.palette.primary.grayishBlue;
		}
		return level === 1
			? theme.palette.primary.darkYellow
			: level === 0
			? theme.palette.error.main
			: level === 2
			? theme.palette.success.main
			: theme.palette.error.main;
	}, [isFreemium, level, theme]);

	return (
		<ListItem id={id} component={component} className={classes.root} sx={{ boxShadow }}>
			<Accordion
				square
				expanded={expanded === id}
				onChange={handleChange(id)}
				TransitionProps={{ unmountOnExit: true }}
				className={classes.accordion}
			>
				<AccordionSummary
					aria-controls={`${id}-content`}
					expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
					className={classes.accordionSummary}
					style={{
						background: `linear-gradient(to right, ${levelColor} 0% 8px, ${theme.palette.primary.white} 8px 100%)`,
					}}
				>
					<Stack flexDirection='row' justifyContent='space-between' alignItems='center' flex={1} spacing={1} useFlexGap>
						<Box flex={1}>
							<Stack flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ md: 'center' }} flexWrap='wrap' useFlexGap>
								<Typography className={classes.name}>{name}</Typography>
								<Typography className={classes.provider}>{provider}</Typography>
								{isNew && (
									<LightTooltip title={intl.formatMessage({ id: 'chip.tooltip.new' })}>
										<Chip
											label={intl.formatMessage({ id: 'common.new' })}
											size='small'
											className={classes.chip}
											sx={{
												backgroundColor: 'success.secondary',
												color: 'primary.white',
												'&:hover': {
													cursor: 'pointer',
												},
											}}
										/>
									</LightTooltip>
								)}
								{isImproved && (
									<LightTooltip title={intl.formatMessage({ id: 'arrow.tooltip.improved' })}>
										<Chip
											label={intl.formatMessage({ id: 'chip.improved' })}
											size='small'
											className={classes.chip}
											sx={{
												backgroundColor: 'primary.beige',
												color: 'secondary.grey900',
												'&:hover': {
													cursor: 'pointer',
												},
											}}
										/>
									</LightTooltip>
								)}
								{projectValueArr?.length > 0 && (
									<Divider
										orientation='vertical'
										flexItem
										sx={{ display: display, mr: 1.5, mt: 0.5, mb: 0.5, borderColor: 'secondary.border' }}
									/>
								)}
								{projectValueArr?.map((item: any, index: number, arr: []) => (
									<Typography
										key={item + index}
										color='primary'
										sx={{
											display: display,
											fontSize: '0.85rem',
											mr: index === arr.length - 1 ? 0 : 0.5,
										}}
									>
										{index === arr.length - 1 ? item : item + ', '}
									</Typography>
								))}
							</Stack>
							<Stack
								flexDirection='row'
								sx={{ display: display, flexWrap: 'wrap', width: { xs: '100%', lg: 'calc(100% - 10px)' } }}
								useFlexGap
							>
								{projectTags?.map((item: any, index: number, arr: any[]) => {
									const lastItem = index === arr.length - 1;
									const highlightedItem = costs?.some(
										(cost: any) => cost?.costCategory?.toLowerCase() === item.toLowerCase()
									);

									return <InstrumentTagItem key={item + index} {...{ item, highlightedItem, lastItem }} />;
								})}
							</Stack>
						</Box>
						<Box>
							<Stack
								flexDirection={{ xs: 'column', md: 'row' }}
								justifyContent='space-between'
								alignItems={{ xs: 'center', md: 'flex-end' }}
								spacing={1}
								useFlexGap
							>
								<Stack flexDirection='row' alignItems='flex-end' spacing={0.75} useFlexGap>
									<BusinessCenterIcon color='primary' fontSize='small' />
									<Typography component='p' className={classes.type}>
										{intl.formatMessage({ id: `check.item.${type}` })}
									</Typography>
								</Stack>
								{profile ? (
									<StarIcon sx={{ color: isMarked ? 'primary.main' : 'secondary.grey300' }} />
								) : (
									<IconButton disabled={readonly} sx={{ p: 0 }} onClick={handleMark}>
										<StarIcon
											sx={{
												color: isMarked ? 'primary.main' : 'secondary.grey300',
												'&:hover': {
													transform: 'scale(1.3)',
												},
											}}
										/>
									</IconButton>
								)}
							</Stack>
						</Box>
					</Stack>
				</AccordionSummary>
				<div className={classes.lineBetweenSummaryAndDetails} />
				<AccordionDetails classes={{ root: classes.accordionDetails }}>
					<Stack
						className={classes.detailsContainer}
						sx={{
							flexDirection: smdDown || (down1530 && related) ? 'column' : 'row',
							alignItems: smdDown || (down1530 && related) ? 'center' : 'stretch',
						}}
					>
						<Box
							sx={{
								width: smdDown ? '100%' : `${radarSize} px`,
								display: 'block', // smdDown ? 'contents' : 'block', <-- didn't work in safari
								position: 'relative',
							}}
						>
							{isFreemium ? (
								<InstrumentRadarFreemium
									instrument={instrument}
									name={name}
									companyName={company?.name}
									radarSize={radarSize}
									smDown={undefined}
								/>
							) : (
								<InstrumentRadar
									instrument={instrument}
									data={limitators}
									name={name}
									companyName={company?.name}
									radarSize={radarSize}
									smDown={undefined}
								/>
							)}
							{isFreemium && (
								<Box
									sx={{
										position: 'absolute',
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
										width: '100%',
										height: `${radarSize + 25}px`,
										backgroundColor: 'rgba(255, 255, 255, 0.6)',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Typography sx={{ textAlign: 'center', p: '1rem' }}>
										<FormattedMessage id='instrument.radar.upgrade.freemium' />
									</Typography>
									<MateriaButton
										variant='contained'
										color='primary'
										href='https://grants.fi/maksuton-konsultaatio-tukirahoituksesta/'
										target='_blank'
										sx={{
											textTransform: 'none', // this will keep the button text case as it is - example: do not uppercase
										}}
									>
										<FormattedMessage id='freemium.upgrade.card.button' />
									</MateriaButton>
								</Box>
							)}
							{!isFreemium && showDetails && !noAttributesToRender && (
								<Box className={classes.attributesContainer}>
									<InstrumentAttributes instrument={instrument} />
								</Box>
							)}
						</Box>
						<Box className={classes.description} sx={{ mt: { xs: '2rem', md: 0 } }}>
							{/* @ts-ignore */}
							<Subtitle mb={0} mt={0} padding='0'>
								{name}
								<span style={{ fontSize: '1rem', paddingLeft: '.5rem' }}> - {provider}</span>
							</Subtitle>
							<Box sx={{ display: { xs: 'block', lg: related ? 'block' : 'none', xl: 'none' }, mt: 1.5 }}>
								<Stack direction='row' sx={{ flexWrap: 'wrap' }}>
									{projectValueArr?.map((item: any, index: number, arr: []) => (
										<Typography
											key={item + index}
											color='primary'
											sx={{
												fontSize: '0.85rem',
												mr: index === arr.length - 1 ? 0 : 0.5,
											}}
										>
											{index === arr.length - 1 ? item : item + ', '}
										</Typography>
									))}
								</Stack>
								{projectTags?.map((item: any, index: number, arr: any[]) => {
									const renderItem = index === 0 ? item : item.toLowerCase();
									return (
										<Typography
											key={item + index}
											component='span'
											sx={{
												fontSize: '0.8rem',
												color: 'secondary.grey500',
											}}
										>
											{index === arr.length - 1 ? renderItem : `${renderItem}, `}
										</Typography>
									);
								})}
							</Box>
							<Typography component='span' className={classes.ingress}>
								<TextContainer sx={{ p: 0, maxWidth: '70rem', wordBreak: 'break-word' }}>
									{htmlParser(parsedIngress)}
								</TextContainer>
							</Typography>
							{showDetails && <InstrumentProviderLinks instrument={instrument} profile={profile} />}
							{showDetails && instrument?.relatedInstruments?.length > 0 && (
								<Box sx={{ mt: 4, mb: profile ? 4 : 'unset' }}>
									<Subtitle mt={0} mb={'1.5rem'} padding='0' id={undefined} className={undefined} sx={undefined}>
										{intl.formatMessage({ id: 'related.instruments.title' })}
									</Subtitle>
									<RelatedInstrumentsList
										{...{
											instrument,
											selectInstrument,
											instruments,
											currentInstrumentId,
											company,
											link,
											profile,
											changeMarked,
											readonly,
										}}
									/>
								</Box>
							)}
							<AdminContentContainer>
								<InstrumentAnalysis instrument={instrument} companyId={companyId} sx={{ mt: '4rem', maxWidth: '70rem' }} />
							</AdminContentContainer>
							<Grid
								container
								spacing={2}
								justifyContent='space-between'
								alignItems='center'
								className={classes.buttonsContainer}
							>
								<Grid item>
									<Grid container alignItems='center' spacing={2}>
										{(profile && related) || (link && related) ? null : (
											<Grid item>
												<Button
													color='success'
													onClick={profile || readonly ? openInstrumentDrawer : openInstrumentPage}
													disabled={undefined}
													icon={undefined}
													href={undefined}
													startIcon={undefined}
													borderColor={undefined}
													loading={undefined}
													mt={undefined}
													mb={undefined}
												>
													{intl.formatMessage({ id: 'button.text.acquaintance' })}
												</Button>
											</Grid>
										)}
										{showDetails && !link && !readonly ? (
											<Grid item>
												<ProjectModalForm
													project={{
														instrumentId: instrument.id,
														instrumentIsCustom: false,
														instrumentName: instrument.name,
														instrumentProvider: instrument.provider,
														instrumentType: instrument.type,
													}}
													instruments={instruments}
													scrollToTimeline={scrollToTimeline}
													title={intl.formatMessage({ id: 'project.form.add.instrument' })}
													readonly={undefined}
												/>
											</Grid>
										) : null}
									</Grid>
								</Grid>
								{!link && !isAdvanced && !isAdmin ? (
									<Grid item>
										<InstrumentToChat instrumentName={name} variant='outlined' />
									</Grid>
								) : null}
							</Grid>
						</Box>
						<InstrumentDrawer
							open={isOpenDrawer}
							close={closeInstrumentDrawer}
							instrument={instrument}
							instruments={instruments}
							company={company}
						/>
					</Stack>
				</AccordionDetails>
			</Accordion>
		</ListItem>
	);
};

export default InstrumentItem;
