import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { AnyMap } from 'immer/dist/internal';
import { Instrument } from 'types/dataTypes';

const InstrumentsSelector: React.FC<{ instruments: Instrument[] | undefined; onInstrumentChange?: any; value: AnyMap }> = ({
	instruments,
	onInstrumentChange,
	value,
}) => {
	if (!instruments || instruments.length === 0) return null;

	return (
		<>
			<Autocomplete
				id='grouped-instruments' // @ts-ignore
				options={instruments?.slice().sort((a, b) => {
					const nameA = a.provider?.toUpperCase() ?? ''; // ignore upper and lowercase
					const nameB = b.provider?.toUpperCase() ?? ''; // ignore upper and lowercase
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}

					return 0;
				})} // @ts-ignore
				value={value}
				groupBy={option => option.provider ?? 'Muut'}
				getOptionLabel={option => option.name ?? ''}
				sx={{ width: '100%', p: '0 .7rem' }}
				disablePortal
				multiple
				renderInput={params => <TextField {...params} label='Instrumenttidata' variant='standard' />}
				onChange={(event, data) => {
					// @ts-ignore
					onInstrumentChange(data);
				}}
				size='small'
			/>
		</>
	);
};

export default InstrumentsSelector;
