import { isNil } from 'lodash';
import * as React from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';

import { Alert, AlertTitle, Box, BoxProps, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';

import { getCompany, getCompanyId } from 'utils/auth/company';

import { useFetchRfpRequestQuery } from 'containers/Marketplace/rfpRequestApi';

import RfpLastUpdatedTypography from 'containers/Marketplace/common/RfpLastUpdatedTypography';
import RfpStatusChip from 'containers/Marketplace/common/RfpStatusChip';
import CompanyProfileBadge from 'containers/Marketplace/profile/CompanyProfileBadge';
import ReadRfpRequestAttachmentList from 'containers/Marketplace/request/Read/ReadRfpRequestAttachmentList';
import ReadRfpRequestItemList from 'containers/Marketplace/request/Read/ReadRfpRequestItemList';
import ReadRfpRequestTargetCompanyList from 'containers/Marketplace/request/Read/ReadRfpRequestTargetCompanyList';
import RfpPrintButton from '../../common/RfpPrintButton';
import RequestProposalList from '../RequestProposalList/RequestProposalList';

type Props = BoxProps & {
	requestId: string | null;
	published: boolean;
	createdByMe: boolean;
	sx?: any;
};

const PrintRftRequest: React.FC<Props> = ({ requestId, published, createdByMe, sx = {}, ...otherProps }) => {
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const { data: rfpRequest, isError } = useFetchRfpRequestQuery(
		{ companyId, requestId, published: published || createdByMe === false },
		{ skip: isNil(companyId) || isNil(requestId) }
	);

	return (
		<Box sx={sx}>
			<RfpPrintButton documentTitle={<FormattedMessage id='rfpRequest.title' />}>
				<Box {...otherProps}>
					<DialogTitle>
						<FormattedMessage id='rfpRequest.title' />
					</DialogTitle>
					<DialogContent>
						{isError && (
							<Alert severity='error'>
								<AlertTitle>
									<FormattedMessage id='query.error.title' />
								</AlertTitle>
								<FormattedMessage id='fetchRfpRequest.error.message' />
							</Alert>
						)}
						<Stack spacing={3}>
							<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
								<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
									<>
										<Box flex='1'>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectTitle.label' />
											</Typography>
											<Typography>{rfpRequest?.projectTitle}</Typography>
										</Box>
										<Stack flexDirection={{ xs: 'column', sm: 'row' }}>
											<Box flex='1'>
												<Typography variant='h4'>
													<FormattedMessage id='rfpRequest.projectBudget.label' />
												</Typography>
												<Typography>
													<FormattedNumber
														value={rfpRequest?.projectBudget ?? 0}
														currency='EUR'
														style='currency'
													/>
												</Typography>
											</Box>
											<Box flex='1'>
												<Typography variant='h4'>
													<FormattedMessage id='rfpRequest.responseDeadline.label' />
												</Typography>
												<Typography>
													<FormattedDate value={rfpRequest?.responseDeadline ?? ''} />
													<Typography component='span' sx={{ ml: '0.5rem' }}>
														<FormattedTime value={rfpRequest?.responseDeadline ?? ''} />
													</Typography>
												</Typography>
											</Box>
										</Stack>
									</>
								</Stack>
							</Stack>
							<Stack flexDirection='row' alignItems='center'>
								<>
									<RfpStatusChip status={rfpRequest?.status} />
									<RfpLastUpdatedTypography updateDate={rfpRequest?.updateDate} />
								</>
							</Stack>
							<Divider />
							<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
								<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
									<>
										<RequestProposalList proposals={rfpRequest?.proposals ?? []} />
										<Box>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectIngress.label' />
											</Typography>
											<Typography>{rfpRequest?.projectIngress}</Typography>
										</Box>
										<Box>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectDescription.label' />
											</Typography>
											<Typography>{rfpRequest?.projectDescription}</Typography>
										</Box>
										<Box>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectOverview.label' />
											</Typography>
											<Typography>{rfpRequest?.projectOverview}</Typography>
										</Box>
										<Box>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectTimeframe.label' />
											</Typography>
											<Typography>{rfpRequest?.projectTimeframe}</Typography>
										</Box>
										<Stack flexDirection='row'>
											<Box sx={{ flex: 1 }}>
												<Typography variant='h4'>
													<FormattedMessage id='rfpRequest.projectTimeStart.label' />
												</Typography>
												<Typography>
													<FormattedDate value={rfpRequest?.projectTimeStart ?? ''} />
												</Typography>
											</Box>
											<Box sx={{ flex: 1 }}>
												<Typography variant='h4'>
													<FormattedMessage id='rfpRequest.projectTimeEnd.label' />
												</Typography>
												<Typography>
													<FormattedDate value={rfpRequest?.projectTimeEnd ?? ''} />
												</Typography>
											</Box>
										</Stack>
										<Box>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectChallengesAndGoals.label' />
											</Typography>
											<Typography>{rfpRequest?.projectChallengesAndGoals}</Typography>
										</Box>
										<Box>
											<ReadRfpRequestItemList rfpRequestItems={rfpRequest?.rfpRequestItems} />
										</Box>
										<Box>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectRequirementsAndEvaluationCriteria.label' />
											</Typography>
											<Typography>{rfpRequest?.projectRequirementsAndEvaluationCriteria}</Typography>
										</Box>
										<Box>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectProposalSubmissionRequirements.label' />
											</Typography>
											<Typography>{rfpRequest?.projectProposalSubmissionRequirements}</Typography>
										</Box>
										<Box>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectContactDetail.label' />
											</Typography>
											<Typography>{rfpRequest?.projectContactDetail}</Typography>
										</Box>
									</>
								</Stack>
								<Stack flex='1' maxWidth={{ xs: '100%', md: '40%' }}>
									<>
										<CompanyProfileBadge companyId={rfpRequest?.companyId} print />
										{createdByMe && rfpRequest?.isTargetedRequest && (
											<ReadRfpRequestTargetCompanyList companyIds={rfpRequest?.proposalRequestTargetCompanies} />
										)}
										<ReadRfpRequestAttachmentList
											requestId={requestId}
											createdByMe={createdByMe}
											attachments={rfpRequest?.attachments}
										/>
									</>
								</Stack>
							</Stack>
						</Stack>
					</DialogContent>
				</Box>
			</RfpPrintButton>
		</Box>
	);
};

export default PrintRftRequest;
