import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    badgeWrapper: {
        marginLeft: '0.75rem',
    },
    text: {
        fontWeight: 500,
        color: theme.palette.success.main,
        paddingLeft: '1.5rem'
    },
}));

const AlertMessage = ({ number }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.root}>
            <div className={classes.badgeWrapper}>
                <Badge color='secondary' badgeContent={number} />
            </div>
            <Typography component='p' className={classes.text}>
                {number > 1 ? intl.formatMessage({ id: 'notification.message.many' }) : intl.formatMessage({ id: 'notification.message.one' })}
            </Typography>
        </div >
    );
};

export default AlertMessage;
