import * as React from 'react';
import { useReactToPrint } from 'react-to-print';

import PrintIcon from '@mui/icons-material/Print';
import { Box, BoxProps, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type Props = BoxProps & {
	sx?: any;
	children: any;
	documentTitle: any;
};

const RfpPrintButton: React.FC<Props> = ({ children, documentTitle, sx = {} }) => {
	const printReference = React.useRef();

	const handlePrint = useReactToPrint({
		content: () => printReference?.current ?? null,
		documentTitle: documentTitle,
	});

	return (
		<Box>
			{/* <IconButton aria-label='print' color='secondary' onClick={handlePrint}>
				<PrintIcon
					sx={{
						'&:hover': {
							transform: 'scale(1.2)',
						},
					}}
				/>
			</IconButton> */}
			<Button
				variant='text'
				aria-label='print'
				color='secondary'
				onClick={handlePrint}
				startIcon={
					<PrintIcon
						sx={{
							'&:hover': {
								transform: 'scale(1.2)',
							},
						}}
					/>
				}
			>
				<FormattedMessage id='common.print' />
			</Button>

			<Box
				sx={{
					position: 'absolute',
					left: 0,
					top: 0,
					visibility: 'hidden',
					height: '100px',
					maxHeight: '100px',
					overflow: 'hidden',
					'@media print': {
						//overflow: 'hidden',
						visibility: 'visible',
						'* ::-webkit-scrollbar': {
							display: 'none !important',
						},
						height: 'auto',
						maxHeight: 'auto',
						overflow: 'visible',
					},
				}}
				ref={printReference}
			>
				{children}
			</Box>
		</Box>
	);
};

export default RfpPrintButton;
