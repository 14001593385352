import React from 'react';

import TextContainer from 'components/Containers/TextContainer';

const ScoreResult = ({ description }) => {

    return (
        <TextContainer>
            {description}
        </TextContainer>
    );
};

export default ScoreResult;
