import { Chip } from '@mui/material';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

const UpgradeFreemiumChip: React.FC = () => {
	const dispatch = useDispatch();

	return (
		<Chip
			label={<FormattedMessage id='menudrawer.freemium.upgrade' />}
			sx={{
				backgroundColor: '#170e18', // #3C2640 darkened by 62% = #170e18
				color: 'primary.white',
				fontSize: '0.786rem',
				lineHeight: 1.2,
				height: '1.429rem',
				'.MuiChip-label': {
					padding: '0.214rem 0.429rem',
				},
			}}
			onClick={() => {
				dispatch(openDialog({ name: 'FreemiumCTADialog' }));
			}}
		/>
	);
};

export default UpgradeFreemiumChip;
