import { FilterList as FilterListIcon, Search as SearchIcon } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Chip,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { every, filter, includes, intersection, isEmpty, isNil, map, pick, some, toLower, values } from 'lodash';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { PaginatorProvider } from 'components/Pagination/PaginationContext';
import { DataList, ListItemType, PageActions } from 'components/Pagination/Paginator';
import { PaginationToken, RfpCategory, RfpProfile } from 'types/dataTypes';

import { openDialog } from '../../dialogSlice';
import { useGetPublicProfilesQuery } from '../../rfpProfileApi';

import FilterPublicRfpProfilesDialog from 'containers/Marketplace/profile/PublicProfileListing/FilterPublicRfpProfilesDialog';
import BlobDownloader from '../../attachment/BlobDownloader';

type ProfileListProps = {};

const filterRfpProfileByCategories = (rfpProfile: RfpProfile, categories: RfpCategory[]) => {
	return !isEmpty(intersection(rfpProfile.categories, categories));
};

const filterRfpProfileBySearchTerm = (rfpProfile: RfpProfile, searchTerm: string) => {
	const pickedValues = values(pick(rfpProfile, 'companyId', 'companyName'));
	return some(pickedValues, value => {
		return isNil(value) ? false : includes(toLower(value), toLower(searchTerm));
	});
};

const ProfileListItem: React.FC<ListItemType<RfpProfile>> = ({ data }) => {
	const dispatch = useDispatch();

	return (
		<>
			<ListItem
				sx={{
					marginBottom: 1,
				}}
				secondaryAction={
					<Button
						size='small'
						onClick={() => dispatch(openDialog({ name: 'ReadRfpProfileDialog', data: { companyId: data?.companyId } }))}
						sx={{ marginRight: 2 }}
					>
						<FormattedMessage id='marketplace.profile.open' />
					</Button>
				}
				disablePadding
			>
				<ListItemButton
					onClick={() => dispatch(openDialog({ name: 'ReadRfpProfileDialog', data: { companyId: data?.companyId } }))}
					sx={{ '.MuiListItem-root>&': { pr: 15 } }}
				>
					<ListItemText
						primary={
							<Stack direction='row'>
								<BlobDownloader url={data?.logo}>{src => <Avatar variant='logo2' src={src} />}</BlobDownloader>
								<Box sx={{ pl: '1rem' }}>
									<Typography sx={{ fontSize: 'inherit', fontWeight: 500 }}>{data?.companyName}</Typography>
									<Chip sx={{ mt: '.5rem' }} variant='filled' label={data?.companyId} />
									<Box sx={{ mt: '.5rem' }}>
										{map(data?.categories, (category, index) => (
											<Typography component='span' variant='lightText' key={index}>
												<FormattedMessage id={`rfp.rfpCategory.${category}`} />
												{data?.categories?.length !== index + 1 ? ', ' : null}
											</Typography>
										))}
									</Box>
								</Box>
							</Stack>
						}
					/>
				</ListItemButton>
			</ListItem>
		</>
	);
};

const PublicProfileList: React.FC<ProfileListProps> = ({ ...otherProps }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const timeoutRef = React.useRef<number>();

	const [fetchToken, setFetchToken] = React.useState<PaginationToken>({ take: 1000, nextPartitionKey: null, nextRowKey: null });
	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const [categories, setCategories] = React.useState<RfpCategory[]>([]);
	const [filteredRfpProfiles, setFilteredRfpProfiles] = React.useState<RfpProfile[]>([]);

	const { data: rfpProfiles, isLoading, isFetching } = useGetPublicProfilesQuery({ token: fetchToken });

	React.useEffect(() => {
		window.clearTimeout(timeoutRef.current);
		timeoutRef.current = window.setTimeout(() => {
			const nextFilteredRfpProfiles = filter(rfpProfiles?.data, rfpProfile => {
				const hits = [];
				if (!isEmpty(categories)) {
					hits.push(filterRfpProfileByCategories(rfpProfile, categories));
				}
				if (!isEmpty(searchTerm)) {
					hits.push(filterRfpProfileBySearchTerm(rfpProfile, searchTerm));
				}
				return isEmpty(hits) ? true : every(hits);
			});
			setFilteredRfpProfiles(nextFilteredRfpProfiles);
		}, 300);
		return () => {
			window.clearTimeout(timeoutRef.current);
		};
	}, [rfpProfiles, searchTerm, categories]);

	const dataLoading = isLoading || isFetching;

	const data = rfpProfiles?.data ?? [];

	return (
		<>
			<FilterPublicRfpProfilesDialog />
			<Stack flexDirection='row' alignItems='center' justifyContent='flex-end'>
				<TextField
					variant='standard'
					value={searchTerm}
					onChange={event => setSearchTerm(event.target.value)}
					placeholder={formatMessage({ id: 'publicRfpProfiles.searchTerm.placeholder' })}
					sx={{ maxWidth: '20rem' }}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
				<IconButton
					onClick={() => {
						dispatch(
							openDialog({
								name: 'FilterPublicRfpProfilesDialog',
								data: {
									filterValues: { categories },
									onSubmit: (filterValues: any) => {
										setCategories(filterValues.categories);
									},
								},
							})
						);
					}}
				>
					{/* <Badge badgeContent={categories?.length} color='secondary'> */}
					<FilterListIcon sx={{ color: categories?.length > 0 ? 'secondary.main' : 'default' }} />
					{/* </Badge> */}
				</IconButton>
			</Stack>
			<List {...otherProps}>
				{dataLoading && map([1, 2, 3, 4, 5], index => <Skeleton key={index} height={100} width='100%' />)}
				{filteredRfpProfiles.length > 0 && (
					<PaginatorProvider rowsPerPage={25} data={filteredRfpProfiles}>
						<DataList dataListItem={<ProfileListItem />} />
						<PageActions />
					</PaginatorProvider>
				)}
			</List>
			<Button
				size='small'
				sx={{ display: rfpProfiles?.token ? 'block' : 'none' }}
				onClick={() => {
					if (rfpProfiles?.token) {
						setFetchToken({ ...rfpProfiles?.token, take: 2 });
					}
				}}
			>
				<FormattedMessage id='profiles.next' />
			</Button>
		</>
	);
};

export default PublicProfileList;
