import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

const baseUrl = 'collaboration';

export const conversationsApi = createApi({
	reducerPath: 'conversations',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	// tagTypes: ['Conversations'],
	endpoints: builder => ({
		getConversations: builder.query({
			query: () => {
				return {
					method: 'GET',
					url: '/conversations',
					params: { take: 1000 },
				};
			},
			providesTags: ['Conversations'],
		}),
		getConversation: builder.query({
			query: ({ companyId, conversationId }) => {
				return {
					method: 'GET',
					url: `/${companyId}/conversation/${conversationId}`,
					params: { comments: true },
				};
			},
			providesTags: (_result, _err, data) => [{ type: 'Conversation', id: data.conversationId }],
			async onQueryStarted(uniqueIdentifier, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					conversationsApi.util.updateQueryData('getConversations', 'conversations', draft => {
						let itemIndex = draft?.conversations?.findIndex(item => item.conversationId === uniqueIdentifier?.conversationId);

						if (itemIndex >= 0) draft.conversations[itemIndex].isRead = true;
					})
				);
				try {
					const results = await queryFulfilled;

					if (results?.data && !results?.data?.isRead)
						dispatch(conversationsApi.endpoints.markConversationRead.initiate(uniqueIdentifier)); // mark as read if it is negative
				} catch {
					patchResult.undo();
				}
			},
		}),
		deleteConversation: builder.mutation({
			query: ({ companyId, conversationId }) => {
				return {
					method: 'DELETE',
					url: `/${companyId}/conversation/${conversationId}`,
				};
			},
			invalidatesTags: (_result, _err, data) => ['Conversations', { type: 'Conversation', id: data.conversationId }],
		}),
		markConversationResolved: builder.mutation({
			query: ({ companyId, conversationId }) => {
				return {
					method: 'POST',
					url: `/${companyId}/conversation/${conversationId}/resolve`,
				};
			},
			invalidatesTags: (_result, _err, data) => ['Conversations', { type: 'Conversation', id: data.conversationId }],
		}),
		markConversationRead: builder.mutation({
			query: ({ companyId, conversationId }) => {
				return {
					method: 'POST',
					url: `/${companyId}/conversation/${conversationId}/markasread`,
				};
			},
			// NOTICE: loop comes when invalidate -> do not invalidate tags
		}),
		deleteComment: builder.mutation({
			query: ({ companyId, conversationId, commentId }) => {
				return {
					method: 'DELETE',
					url: `/${companyId}/conversation/${conversationId}/comments/${commentId}`,
				};
			},
			invalidatesTags: (_result, _err, data) => ['Conversations', { type: 'Conversation', id: data.conversationId }],
		}),
		addComment: builder.mutation({
			query: ({ companyId, conversationId, comment }) => {
				return {
					method: 'POST',
					url: `/${companyId}/conversation/${conversationId}/comments`,
					data: comment,
				};
			},
			invalidatesTags: (_result, _err, data) => ['Conversations', { type: 'Conversation', id: data.conversationId }],
		}),
	}),
});

export const {
	useGetConversationsQuery,
	useGetConversationQuery,
	useDeleteCommentMutation,
	useDeleteConversationMutation,
	useMarkConversationResolvedMutation,
	useAddCommentMutation,
	useMarkConversationReadMutation,
} = conversationsApi;
