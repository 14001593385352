import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { DataGrid, GridFeatureMode } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Footer from './Footer';
import Paginator from './Paginator';

import { gridTranslations } from 'translations/fi/gridTranslations';

const StyledDataGrid = styled(DataGrid)(({ theme, mt, hover }: any) => ({
	marginTop: theme.spacing(mt),
	backgroundColor: theme.palette.primary.white,
	'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
		outline: 'none',
	},
	'& .MuiDataGrid-columnHeader--sortable, & .MuiDataGrid-columnHeader': {
		'&:focus, &:focus-within': {
			outline: 'none',
		},
	},
	'& .MuiTablePagination-caption': {
		display: 'none',
	},
	'& .MuiDataGrid-overlay': {
		// top: '112px !important', --> fixed by MUI
	},
	'& .MuiCircularProgress-root': {
		height: '1.5rem !important',
		width: '1.5rem !important',
	},

	'& .MuiDataGrid-row': {
		'&:hover': hover,
	},
}));

/**
 * Pagination helper for array slicing, show always the correct page
 * @param {*} array
 * @param {*} pageSize
 * @param {*} pageNumber
 * @returns
 */
const paginate = (array: any[], pageSize: number, pageNumber: number) => {
	if (!array) return [];

	return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
};

type CustomDataGridProps = {
	idField?: string;
	autoHeight?: boolean;
	heigth: number | string;
	loading?: boolean;
	data: any[];
	columns: any;
	token: any;
	rowsPerPage?: number;
	paginationMode?: GridFeatureMode;
	fetchdata?: any;
	onRowClick?: any;
	classes: any;
	mt?: number;
	sx?: any;
	useHoverStyles?: boolean;
};

const CustomDataGrid = ({
	idField = 'id',
	autoHeight = true,
	heigth,
	loading = false,
	data = [],
	columns,
	token,
	rowsPerPage = 25,
	paginationMode = 'server',
	fetchdata = null,
	onRowClick,
	classes,
	mt = 0,
	useHoverStyles = false,
	sx = {},
	...other
}: CustomDataGridProps) => {
	const intl = useIntl();

	const [page, setPage] = useState(0);

	// data for render
	const [renderData, setRenderData] = useState<any[]>([]);
	const dataLength = renderData?.length;

	// sorting
	const [sortModel, setSortModel] = useState<any>({});
	const [sortedData, setSortedData] = useState<any[]>([]);

	const sortMode = sortModel.sort;
	const sortField = sortModel.field;

	useEffect(() => {
		if (!sortMode) {
			setSortedData(data);
		} else {
			const dataForSort = [...data];

			setSortedData(
				// @ts-ignore
				dataForSort?.sort((a, b) => {
					if (sortMode === 'asc') {
						return a[sortField] > b[sortField] ? 1 : -1;
					} else if (sortMode === 'desc') {
						return a[sortField] > b[sortField] ? -1 : 1;
					}
				})
			);
		}
	}, [sortMode, sortField, data]);

	// filtering
	const [filterModel, setFilterModel] = useState<any>({});
	const [filteredData, setFilteredData] = useState<any[]>([]);

	const filterOperator = filterModel.operatorValue;
	const filterField = filterModel.columnField;
	const filterValue = filterModel.value;

	useEffect(() => {
		if (!filterOperator) {
			setFilteredData(data);
		} else {
			switch (filterOperator) {
				case 'contains':
					setFilteredData(data.filter(item => item[filterField]?.toString().toLowerCase().includes(filterValue.toLowerCase())));
					break;
				case 'equals':
					setFilteredData(data.filter(item => item[filterField] == filterValue)); // ignore type comparison
					break;
				case 'startsWith':
					setFilteredData(data.filter(item => item[filterField]?.toString().toLowerCase().startsWith(filterValue.toLowerCase())));
					break;
				case 'endsWith':
					setFilteredData(data.filter(item => item[filterField]?.toString().toLowerCase().endsWith(filterValue.toLowerCase())));
					break;
				case 'isEmpty':
					setFilteredData(data.filter(item => !item[filterField]));
					break;
				case 'isNotEmpty':
					setFilteredData(data.filter(item => item[filterField]));
					break;
				default:
					return setFilteredData(data);
			}
		}
	}, [filterModel, filterOperator, filterField, filterValue, data]);

	useEffect(() => {
		if (!sortMode && !filterOperator) {
			setRenderData(data);
		} else if (sortMode && !filterOperator) {
			setRenderData(sortedData);
		} else if (!sortMode && filterOperator) {
			setRenderData(filteredData);
		} else {
			setRenderData(sortedData.filter(value => filteredData.includes(value)));
		}
	}, [sortMode, filterOperator, sortedData, filteredData, data]);

	const handlePageChange = (newPage: any) => {
		if (fetchdata && newPage > page && newPage + 1 >= dataLength / rowsPerPage) {
			fetchdata(rowsPerPage);
		}

		setPage(newPage);
	};

	const paginatedRows = paginate(renderData, rowsPerPage, page);

	const rows = paginatedRows.map(item => ({
		id: item[idField],
		...item,
	}));

	// wrap with useCallback if problems
	const handleSortModelChange = (sortModel: any) => (sortModel.length === 0 ? setSortModel({}) : setSortModel(sortModel[0]));

	const handleFilterModelChange = (filterModel: any) => {
		const model = filterModel.items[0];

		if (model.operatorValue === 'isEmpty' || model.operatorValue === 'isNotEmpty' || model.value) {
			setFilterModel(model);
		} else {
			setFilterModel({});
		}
	};

	return (
		<Box
			sx={{
				'& .styled-header': {
					color: 'primary.main',
				},
				height: heigth ?? '100%',
			}}
		>
			<StyledDataGrid // @ts-ignore
				hover={
					!useHoverStyles
						? null
						: {
								cursor: 'pointer',
						  }
				}
				loading={loading}
				rows={rowsPerPage && paginationMode === 'server' ? rows : renderData}
				columns={columns}
				autoHeight={autoHeight}
				heigth={heigth}
				localeText={gridTranslations(intl)}
				pagination
				paginationMode={paginationMode}
				sortingMode={paginationMode}
				filterMode={paginationMode}
				onSortModelChange={handleSortModelChange}
				onFilterModelChange={handleFilterModelChange}
				page={page}
				pageSize={rowsPerPage ?? 20}
				rowCount={dataLength}
				onPageChange={handlePageChange}
				onRowClick={onRowClick}
				components={{
					Footer: () => {
						if (rowsPerPage >= dataLength) return null;

						return (
							<Footer loading={loading}>
								<Paginator token={token} disabled={loading} />
							</Footer>
						);
					},
				}}
				classes={classes}
				mt={mt}
				sx={sx}
				{...other}
			/>
		</Box>
	);
};

export default CustomDataGrid;
