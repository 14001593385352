import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Chip,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Typography,
} from '@mui/material';
import { compact, find, isEmpty, isNil, join, map, without } from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { useGetPublicProfilesQuery } from 'containers/Marketplace/rfpProfileApi';
import { PaginationToken, RfpProfile } from 'types/dataTypes';

import BlobDownloader from 'containers/Marketplace/attachment/BlobDownloader';

type Props = {
	label?: string | null;
	helperText?: string | null;
	value?: string[] | null;
	onChange?: (value: string[]) => void;
} & Omit<FormControlProps, 'onChange'>;

const RfpRequestTargetCompanyInput = React.forwardRef<any, Props>(({ label, helperText, value, onChange, ...otherProps }, ref) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const [fetchToken] = React.useState<PaginationToken>({ take: 1000, nextPartitionKey: null, nextRowKey: null });

	const { data: publicRfpProfiles, isLoading } = useGetPublicProfilesQuery({ token: fetchToken });

	const selectedRfpProfiles = React.useMemo(() => {
		return compact(map(value, companyId => find(publicRfpProfiles?.data, { companyId })));
	}, [value, publicRfpProfiles]);

	return (
		<FormControl {...otherProps} ref={ref}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			{isLoading ? (
				<>
					<Skeleton height={100} />
					<Skeleton height={100} />
				</>
			) : (
				<List>
					{isEmpty(selectedRfpProfiles) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpRequestTargetCompanies.empty' />
						</Typography>
					)}
					{map(selectedRfpProfiles, (rfpProfile, index) => (
						<ListItem
							key={rfpProfile.companyId}
							secondaryAction={
								<IconButton
									color='error'
									onClick={() => {
										onChange?.(without(value, rfpProfile.companyId || ''));
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
							disablePadding
						>
							<ListItemButton
								onClick={() => {
									dispatch(openDialog({ name: 'ReadRfpProfileDialog', data: { companyId: rfpProfile.companyId } }));
								}}
							>
								<ListItemIcon>
									<BlobDownloader url={rfpProfile?.logo}>{src => <Avatar variant='logo3' src={src} />}</BlobDownloader>
								</ListItemIcon>
								<ListItemText
									id={`rfpProfileListItemText${index}`}
									primary={rfpProfile.companyName}
									secondary={
										<>
											<Chip component='span' variant='filled' label={rfpProfile.companyId} sx={{ mb: 0.5 }} />
											<Typography component='span' sx={{ display: 'block', fontSize: 'inherit' }}>
												{join(
													map(rfpProfile.categories, rfpCategory => {
														return formatMessage({ id: `rfpCategory.${rfpCategory}` });
													}),
													', '
												)}
											</Typography>
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
			<Box>
				<Button
					size='small'
					variant='text'
					onClick={() => {
						dispatch(
							openDialog({
								name: 'SelectPublicRfpProfilesDialog',
								data: {
									selectedRfpProfiles,
									onSubmit: (selectedRfpProfiles: RfpProfile[]) => {
										onChange?.(compact(map(selectedRfpProfiles, rfpProfile => rfpProfile.companyId)));
									},
								},
							})
						);
					}}
					startIcon={<AddIcon />}
				>
					<FormattedMessage id='rfpRequestTargetCompanies.select' />
				</Button>
			</Box>
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
});

export default RfpRequestTargetCompanyInput;
