import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/MenuItem';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';

import StyledMenu from 'components/StyledMenu';
import SearchField from './SearchField';
import Link from '@mui/material/Link';

const useStyles = makeStyles(theme => ({
    companyName: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            minWidth: '18rem',
        },
        minWidth: '30rem',
        '&:hover': {
            backgroundColor: theme.palette.primary.white,
            cursor: 'auto',
        },
        justifyContent: 'space-between',
        '& a': {
            fontWeight: 300,
            textDecoration: 'underline',
            fontSize: '0.9rem',
        },
    },
    menuItem: {
        '& .MuiListItemText-root': {
            color: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '14rem',
            },
        },
        '& .MuiListItemText-primary': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemText-root, & .MuiListItemText-secondary': {
                color: theme.palette.primary.white,
            },
        },
    },
    currentItem: {
        backgroundColor: theme.palette.secondary.lightBeige,
    },
    listItemIcon: {
        minWidth: '0.5rem',
        [theme.breakpoints.down('sm')]: {
            minWidth: '2rem',
        },
    },
    customerName: {
        fontSize: '1rem',
        fontWeight: 600,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    businessId: {
        fontSize: '1rem',
        paddingRight: 0,
    },
    noCustomers: {
        fontSize: '1rem',
        color: theme.palette.textPrimary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.white,
            cursor: 'auto',
        },
    },
    loading: {
        paddingTop: theme.spacing(1),
    },
}));

const CompaniesDropdown = props => {
    const { customers, loading, companyId, companyName, handleChange, onClose } = props;
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const divProps = { ...props };
    delete divProps.loading;
    delete divProps.companyId;
    delete divProps.companyName;
    delete divProps.handleChange;

    return (
        <StyledMenu {...divProps}>
            <Typography className={`${classes.companyName}`}>
                <span>{companyName}</span>
                <Link href='/advanced'>Näytä kaikki</Link>
            </Typography>
            <SearchField handleChange={handleChange} />
            {loading ? (
                <Box textAlign='center' className={classes.loading}>
                    <CircularProgress size={24} />
                </Box>
            ) : customers?.length > 0 ? (
                customers?.map(({ rowKey, advancedCustomerName, scenario }) => (
                    <MenuItem
                        key={rowKey}
                        onClick={() => onClose(rowKey)}
                        className={`${classes.menuItem} ${companyId === rowKey && classes.currentItem}`}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <FiberManualRecordIcon
                                fontSize='small'
                                style={{
                                    color: !scenario?.grantedScore
                                        ? '#bab3a8'
                                        : scenario?.grantedScore < 30
                                            ? '#8e0e44'
                                            : scenario?.grantedScore >= 30 && scenario?.grantedScore <= 80
                                                ? '#cca111'
                                                : scenario?.grantedScore > 80 && '#077871',
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                smDown ? (
                                    advancedCustomerName
                                ) : (
                                    <Grid container alignItems='center'>
                                        <Grid item style={{ maxWidth: '20rem' }}>
                                            <Typography component='span' className={classes.customerName}>
                                                {advancedCustomerName}
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 'auto', paddingLeft: '3rem' }}>
                                            <Typography component='span' className={classes.businessId}>
                                                {rowKey}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            secondary={smDown ? rowKey : null}
                        />
                    </MenuItem>
                ))
            ) : (
                <Typography className={classes.noCustomers}>{intl.formatMessage({ id: 'customerlist.no.customers' })}</Typography>
            )}
        </StyledMenu>
    );
};

export default CompaniesDropdown;
