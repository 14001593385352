import List from '@mui/material/List';
import moment from 'moment';
import * as React from 'react';

import PanelTitle from 'components/Titles/PanelTitle';
import InstrumentCategoryDescription from 'containers/Instruments/InstrumentList/GroupedInstruments/InstrumentCategoryDescription';
import InstrumentItem from 'containers/Instruments/InstrumentList/InstrumentItem';

const GroupedInstruments = ({
	categoryId,
	title,
	description,
	company,
	instruments = [],
	filteredInstruments = [],
	changeMarked,
	profile,
	currentInstrumentId,
	category,
	costs,
	scrollToTimeline,
	readonly,
}) => {
	if (filteredInstruments?.length === 0) return null;

	if (categoryId === 'business' && company) {
		// TODO: make dynamic filtering for instruments with dynamic operators
		try {
			const myDate = moment(company?.registrationDate, 'YYYY-MM-DD').toDate();
			const years = moment().diff(myDate, 'years');

			if (years >= 3) {
				return null;
			}
		} catch {
			// don't mind filtering exceptions
		}
	}

	const selectInstrument = id => {
		return instruments?.find(item => item.id === id);
	};

	return (
		<>
			{description ? (
				<InstrumentCategoryDescription description={description} />
			) : (
				<PanelTitle sx={{ mt: '1.5rem', mb: '.5rem', padding: 0 }}>{title}</PanelTitle>
			)}
			<List>
				{filteredInstruments.map(instrument => {
					return (
						<InstrumentItem
							key={instrument.id}
							company={company}
							instrument={instrument}
							instruments={instruments}
							changeMarked={changeMarked}
							profile={profile}
							currentInstrumentId={currentInstrumentId}
							category={category}
							scrollToTimeline={scrollToTimeline}
							costs={costs}
							readonly={readonly}
							selectInstrument={selectInstrument}
						/>
					);
				})}
			</List>
		</>
	);
};

export default React.memo(GroupedInstruments);
