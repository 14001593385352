import { Delete as DeleteIcon, FileCopy as FileCopyIcon } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Box,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Typography,
} from '@mui/material';
import { isEmpty, isNil, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { RfpAttachment } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';
import bytesToSize from 'utils/converters/bytesToSize';

import { openPopover } from 'containers/Marketplace/popoverSlice';
import {
	useFetchRfpAttachmentsQuery,
	useLazyFetchRfpProposalAttachmentFileQuery,
	useLazyFetchRfpRequestAttachmentFileQuery,
	useRemoveRfpAttachmentMutation,
	useUpsertRfpAttachmentMutation,
} from 'containers/Marketplace/rfpCommonApi';

import AttachmentDropZone from 'containers/Marketplace/attachment/AttachmentDropZone';

type Props = {
	parentType: 'RFPProposal' | 'RFPRequest';
	requestId: string | null | undefined;
	proposalId: string | null | undefined;
	label?: string | null;
	helperText?: string | null;
	onRemoveAttachment?: any;
	onUpsertAttachment?: any;
} & FormControlProps;

const RfpAttachmentList: React.FC<Props> = ({
	parentType,
	requestId,
	proposalId,
	label,
	helperText,
	onRemoveAttachment,
	onUpsertAttachment,
	...otherProps
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const downloadLink = React.useRef<HTMLLinkElement>(null);

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;
	const parentId = parentType === 'RFPRequest' ? requestId : parentType === 'RFPProposal' ? proposalId : null;

	const {
		data: rfpAttachments,
		isLoading,
		isError: isRfpAttachmentsError,
	} = useFetchRfpAttachmentsQuery({ companyId, parentId }, { skip: isNil(companyId) || isNil(parentId) });

	const [upsertRfpAttachment, { isLoading: isUpsertLoading, isError: isUpsertError }] = useUpsertRfpAttachmentMutation();
	const [removeRfpAttachment, { isError: isRemoveError }] = useRemoveRfpAttachmentMutation();
	const [fetchRfpRequestAttachmentFile, { isError: isRfpRequestAttachmentFileError }] = useLazyFetchRfpRequestAttachmentFileQuery();
	const [fetchRfpProposalAttachmentFile, { isError: isRfpProposalAttachmentFileError }] = useLazyFetchRfpProposalAttachmentFileQuery();

	const handleDownloadClick = React.useCallback(
		(rfpAttachment: RfpAttachment) => async () => {
			let rfpAttachmentFile;
			if (parentType === 'RFPRequest') {
				const { data: rfpRequestAttachmentFile } = await fetchRfpRequestAttachmentFile({
					companyId,
					requestId,
					attachmentId: rfpAttachment.attachmentId,
					published: false,
				});
				rfpAttachmentFile = rfpRequestAttachmentFile;
			} else if (parentType === 'RFPProposal') {
				const { data: rfpProposalAttachmentFile } = await fetchRfpProposalAttachmentFile({
					companyId,
					requestId,
					proposalId,
					attachmentId: rfpAttachment.attachmentId,
					published: false,
				});
				rfpAttachmentFile = rfpProposalAttachmentFile;
			}
			if (!isNil(rfpAttachmentFile)) {
				const downloadUrl = window.URL.createObjectURL(rfpAttachmentFile);
				const fileName = rfpAttachment.fileName ?? 'attachment';
				if (!isNil(downloadLink.current)) {
					downloadLink.current.href = downloadUrl;
					downloadLink.current.setAttribute('download', fileName);
					downloadLink.current.click();
				}
				window.URL.revokeObjectURL(downloadUrl);
			}
		},
		[fetchRfpRequestAttachmentFile, fetchRfpProposalAttachmentFile, companyId, parentType, requestId, proposalId]
	);

	return (
		<FormControl {...otherProps}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			{isRfpAttachmentsError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpAttachments.error.message' />
				</Alert>
			)}
			{isRfpRequestAttachmentFileError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpProposalAttachmentFile.error.message' />
				</Alert>
			)}
			{isRfpProposalAttachmentFileError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpRequestAttachmentFile.error.message' />
				</Alert>
			)}
			{isUpsertError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='mutation.error.title' />
					</AlertTitle>
					<FormattedMessage id='upsertRfpAttachment.error.message' />
				</Alert>
			)}
			{isRemoveError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='mutation.error.title' />
					</AlertTitle>
					<FormattedMessage id='removeRfpAttachment.error.message' />
				</Alert>
			)}
			{isLoading ? (
				<>
					<Skeleton height={30} />
					<Skeleton height={30} />
					<Skeleton height={30} />
				</>
			) : (
				<List>
					{isEmpty(rfpAttachments) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpAttachments.empty' />
						</Typography>
					)}
					{map(rfpAttachments, rfpAttachment => (
						<ListItem
							key={rfpAttachment.attachmentId}
							secondaryAction={
								<IconButton
									color='error'
									onClick={event => {
										dispatch(
											openPopover({
												name: 'ConfirmRemovePopover',
												data: {
													anchorEl: event.currentTarget,
													text: formatMessage({ id: 'rfpAttachments.confirmRemove' }),
													description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
													onSubmit: async () => {
														try {
															await removeRfpAttachment({
																companyId,
																parentId,
																attachmentId: rfpAttachment.attachmentId,
															}).unwrap();

															if (onRemoveAttachment) {
																onRemoveAttachment(rfpAttachment.attachmentId);
															}

															toast.success(formatMessage({ id: 'removeRfpAttachment.success.message' }));
														} catch (error) {
															console.error(error);
														}
													},
												},
											})
										);
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
							disablePadding
						>
							<ListItemButton onClick={handleDownloadClick(rfpAttachment)}>
								<ListItemIcon>
									<FileCopyIcon />
								</ListItemIcon>
								<ListItemText primary={rfpAttachment.name} secondary={bytesToSize(rfpAttachment.length)} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
			<Box component='a' ref={downloadLink} sx={{ display: 'none' }} />
			<AttachmentDropZone
				loading={isUpsertLoading}
				onDrop={async attachments => {
					try {
						await upsertRfpAttachment({
							parentType,
							companyId,
							parentId,
							rfpAttachments: attachments,
						}).unwrap();

						if (onUpsertAttachment) {
							onUpsertAttachment(attachments);
						}

						toast.success(formatMessage({ id: 'upsertRfpAttachment.success.message' }));
					} catch (error) {
						console.error(error);
					}
				}}
			/>
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
};

export default RfpAttachmentList;
