export const getInitials = name =>
    name
        .replace(/[^A-Za-z0-9À-ÿ ]/gi, '') // taking care of accented characters as well
        .replace(/ +/gi, ' ') // replace multiple spaces to one
        .split(/ /) // break the name into parts
        .reduce((acc, item) => acc + item[0], '') // assemble an abbreviation from the parts
        .concat(name.substr(1)) // what if the name consist only one part
        .concat(name) // what if the name is only one character
        .substr(0, 2) // get the first two characters an initials
        .toUpperCase(); // uppercase, but you can format it with CSS as well
