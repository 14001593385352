import { Box, Checkbox, Divider, FormControl, FormControlLabel, Link, Stack, Switch, TextField, Typography, alpha } from '@mui/material';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FormControlledInput, FormError } from 'components/Form';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import { Company } from 'types/dataTypes';

import CompanySearchAutocomplete from 'containers/Register/CompanySearchAutocomplete';

type Props = {
	id: string;
	control: any;
	errors: any;
	setValue: any;
	onSubmit?: any;
};

// Use Material UI. Make a registration form. Form has fields for fisrt name, last name, email, company name, business id, phone number.
const RegisterForm = React.forwardRef<any, Props>(({ id, control, errors, setValue, onSubmit }, ref) => {
	const { formatMessage } = useIntl();

	const dispatch = useDispatch();

	const [selectedCompany, setSelectedCompany] = React.useState<Partial<Company> | string | null>(null);
	const [haveInputCompanyManually, setIsCompanyInputedManually] = React.useState<boolean>(false);

	return (
		<Box
			id={id}
			component='form'
			ref={ref}
			onSubmit={onSubmit}
			sx={{
				'& .MuiInputBase-root.MuiOutlinedInput-root': {
					backgroundColor: 'primary.white',
				},
				'& .FormError-root': {
					color: 'primary.white',
				},
			}}
		>
			<Stack direction='column' spacing={2} useFlexGap>
				{/* @ts-ignore */}
				<FormControlledInput
					name='firstName'
					control={control}
					error={errors?.firstName?.message}
					placeholder={formatMessage({ id: 'register.form.firstName' })}
					label={<Typography sx={{ color: '#fff' }}>{formatMessage({ id: 'register.form.firstName' })}</Typography>}
				/>
				{/* @ts-ignore */}
				<FormControlledInput
					name='lastName'
					control={control}
					error={errors?.lastName?.message}
					placeholder={formatMessage({ id: 'register.form.lastName' })}
					label={<Typography sx={{ color: '#fff' }}>{formatMessage({ id: 'register.form.lastName' })}</Typography>}
				/>
				{/* @ts-ignore */}
				<FormControlledInput
					name='email'
					control={control}
					error={errors?.email?.message}
					placeholder={formatMessage({ id: 'register.form.email' })}
					label={<Typography sx={{ color: '#fff' }}>{formatMessage({ id: 'register.form.email' })}</Typography>}
				/>
				{/* @ts-ignore */}
				<FormControlledInput
					name='phoneNumber'
					control={control}
					error={errors?.phoneNumber?.message}
					placeholder={formatMessage({ id: 'register.form.phoneNumber' })}
					label={<Typography sx={{ color: '#fff' }}>{formatMessage({ id: 'register.form.phoneNumber' })}</Typography>}
				/>
				<Divider />
				<Box sx={{ my: '1rem' }}>
					<CompanySearchAutocomplete
						value={selectedCompany}
						disabled={haveInputCompanyManually}
						onChange={(event, value) => {
							if (typeof value === 'string') {
								setSelectedCompany(value);
							} else if (value) {
								setSelectedCompany(value);
								setValue('companyName', value?.name ?? '', { shouldValidate: true });
								setValue('businessId', value?.businessId ?? '', { shouldValidate: true });
							} else {
								setSelectedCompany(null);
							}
						}}
						renderInput={params => (
							<Box>
								<Typography sx={{ color: '#fff', mb: '0.5rem' }}>
									{formatMessage({ id: 'register.form.companySearch' })}
								</Typography>
								<TextField
									{...params}
									variant='outlined'
									placeholder={formatMessage({ id: 'register.form.companySearch.placeholder' })}
									InputProps={{
										...params.InputProps,
										sx: {
											'.MuiAutocomplete-root &': {
												p: '0',
												pl: '1rem',
											},
										},
									}}
								/>
								{!haveInputCompanyManually && <FormError error={errors?.companyName?.message} />}
							</Box>
						)}
					/>
					<FormControlLabel
						sx={{ color: 'primary.white' }}
						control={
							<Switch
								checked={haveInputCompanyManually}
								onChange={event => setIsCompanyInputedManually(event.target.checked)}
								sx={{
									'& .MuiSwitch-switchBase.Mui-checked': {
										color: 'primary.white',
										'&:hover': {
											backgroundColor: theme => alpha('#fff', theme.palette.action.hoverOpacity),
										},
									},
									'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
										backgroundColor: 'primary.white',
									},
								}}
							/>
						}
						label={<FormattedMessage id='register.form.haveInputCompanyManually' />}
					/>
					{haveInputCompanyManually && (
						<>
							{/* @ts-ignore */}
							<FormControlledInput
								name='companyName'
								control={control}
								error={errors?.companyName?.message}
								placeholder={formatMessage({ id: 'register.form.companyName' })}
								label={<Typography sx={{ color: '#fff' }}>{formatMessage({ id: 'register.form.companyName' })}</Typography>}
							/>
							{/* @ts-ignore */}
							<FormControlledInput
								name='businessId'
								control={control}
								error={errors?.businessId?.message}
								placeholder={formatMessage({ id: 'register.form.businessId' })}
								label={<Typography sx={{ color: '#fff' }}>{formatMessage({ id: 'register.form.businessId' })}</Typography>}
							/>
						</>
					)}
				</Box>
				<Divider />

				{/* @ts-ignore */}
				<Controller
					name='haveAcceptedDataPrivacyStatement'
					control={control}
					render={({ field, fieldState }) => (
						<FormControl>
							<Stack flexDirection='column' spacing={1} sx={{ mb: '1rem' }} useFlexGap>
								<FormControlLabel
									sx={{ color: 'primary.white' }}
									control={
										<Checkbox
											{...field}
											checked={!!field.value}
											sx={{ color: 'primary.white', '&.Mui-checked': { color: 'primary.white' } }}
										/>
									}
									label={<FormattedMessage id='register.form.haveAcceptedDataPrivacyStatement' />}
								/>
								<FormError error={fieldState.error?.message} />
								<Link
									sx={{ color: 'primary.white', textDecoration: 'underline', cursor: 'pointer' }}
									onClick={() => {
										dispatch(openDialog({ name: 'DataPrivacyStatementDialog' }));
									}}
								>
									<FormattedMessage id='register.form.open.data.privacy.statement' />
								</Link>
							</Stack>
						</FormControl>
					)}
				/>
			</Stack>
		</Box>
	);
});

export default RegisterForm;
