import * as React from 'react';
import { Box } from '@mui/material';

export type Direction = 'top' | 'topLeft' | 'topRight' | 'right' | 'bottom' | 'bottomLeft' | 'bottomRight' | 'left';

interface Props {
	onResize: (direction: Direction, x: number, y: number) => void;
}

const ResizableBox: React.FC<Props> = ({ onResize }) => {
	const [direction, setDirection] = React.useState<Direction | undefined>();
	const [mouseDown, setMouseDown] = React.useState<boolean>(false);

	React.useEffect(() => {
		const handleMouseMove = (event: any) => {
			if (!direction) return;

			const ratio = window.devicePixelRatio;

			onResize(direction, event.movementX / ratio, event.movementY / ratio);
		};

		if (mouseDown) {
			window.addEventListener('mousemove', handleMouseMove);
		}

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, [mouseDown, direction, onResize]);

	React.useEffect(() => {
		const handleMouseUp = () => setMouseDown(false);

		window.addEventListener('mouseup', handleMouseUp);

		return () => {
			window.removeEventListener('mouseup', handleMouseUp);
		};
	}, []);

	const handleMouseDown = (direction: Direction) => () => {
		setDirection(direction);
		setMouseDown(true);
	};

	return (
		<>
			<Box
				sx={{
					position: 'absolute',
					cursor: 'nwse-resize',
					height: '10px',
					width: '10px',
					zIndex: 2,
					left: 0,
					top: 0,
				}}
				onMouseDown={handleMouseDown('topLeft')}
			/>
			<Box
				sx={{
					position: 'absolute',
					cursor: 'ns-resize',
					height: '4px',
					width: '100%',
					zIndex: 1,
					left: 0,
					top: 0,
				}}
				onMouseDown={handleMouseDown('top')}
			/>
			<Box
				sx={{
					position: 'absolute',
					cursor: 'nesw-resize',
					height: '10px',
					width: '10px',
					zIndex: 2,
					right: 0,
					top: 0,
				}}
				onMouseDown={handleMouseDown('topRight')}
			/>
			<Box
				sx={{
					position: 'absolute',
					cursor: 'ew-resize',
					height: '100%',
					width: '4px',
					zIndex: 1,
					right: 0,
					top: 0,
				}}
				onMouseDown={handleMouseDown('right')}
			/>
			<Box
				sx={{
					position: 'absolute',
					cursor: 'nwse-resize',
					height: '10px',
					width: '10px',
					zIndex: 2,
					right: 0,
					bottom: 0,
				}}
				onMouseDown={handleMouseDown('bottomRight')}
			/>
			<Box
				sx={{
					position: 'absolute',
					cursor: 'ns-resize',
					height: '4px',
					width: '100%',
					zIndex: 1,
					left: 0,
					bottom: 0,
				}}
				onMouseDown={handleMouseDown('bottom')}
			/>
			<Box
				sx={{
					position: 'absolute',
					cursor: 'nesw-resize',
					height: '10px',
					width: '10px',
					zIndex: 2,
					left: 0,
					bottom: 0,
				}}
				onMouseDown={handleMouseDown('bottomLeft')}
			/>
			<Box
				sx={{
					position: 'absolute',
					cursor: 'ew-resize',
					height: '100%',
					width: '4px',
					zIndex: 1,
					left: 0,
					top: 0,
				}}
				onMouseDown={handleMouseDown('left')}
			/>
		</>
	);
};

export default ResizableBox;
