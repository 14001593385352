import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { Button, Stack } from '@mui/material';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import LoginWrapper from 'components/LoginWrapper';
import { logout } from 'containers/App/appSlice';
import { IdTokenData } from 'utils/auth/msal/DataDisplay';
import { loginRequest } from 'utils/auth/msal/msalConfig';
import { setToken } from 'utils/auth/token';
import { setUser } from 'utils/auth/user';

const MainContent = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const intl = useIntl();

	/**
	 * useMsal is a hook that returns the PublicClientApplication instance.
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
	 */
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();

	const setTokenAndRedirect = async (redirect = true) => {
		const customApiToken = await instance.acquireTokenSilent(loginRequest);

		// @ts-ignore
		setToken(customApiToken);

		setUser({
			email: customApiToken.account?.idTokenClaims?.preferred_username,
			name: customApiToken.account?.idTokenClaims?.name,
			company: null,
			companies: [],
		});

		if (redirect) {
			history.push('/');
		}
	};

	const handleLoginPopup = () => {
		/**
		 * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
		 * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
		 * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
		 */
		// instance
		// 	.acquireTokenPopup({
		// 		...loginRequest,
		// 	})
		// 	.catch(error => console.onInstrumentChange(error));
		// instance
		// 	.acquireTokenPopup(loginRequest)
		// 	.then(function (accessTokenResponse) {

		// 		// @ts-ignore
		// 		setToken(accessTokenResponse.accessToken);

		// 		setUser({
		// 			email: accessTokenResponse.account?.idTokenClaims?.preferred_username,
		// 			name: accessTokenResponse.account?.idTokenClaims?.name,
		// 			company: null,
		// 			companies: [],
		// 		});
		// 	})
		// 	.catch(function (error) {
		// 		// Acquire token interactive failure
		// 		if (error instanceof InteractionRequiredAuthError) {
		// 			instance.acquireTokenRedirect(loginRequest);
		// 		}
		// 	});
		instance
			.loginRedirect({
				...loginRequest,
				prompt: 'create',
			})
			.then(function (accessTokenResponse: any) {
				// @ts-ignore
				setToken(accessTokenResponse.accessToken);

				setUser({
					email: accessTokenResponse.account?.idTokenClaims?.preferred_username,
					name: accessTokenResponse.account?.idTokenClaims?.name,
					company: null,
					companies: [],
				});
			})
			.catch(function (error) {
				// Acquire token interactive failure
				console.error(error);

				if (error instanceof InteractionRequiredAuthError) {
					instance.acquireTokenRedirect(loginRequest);
				}
			});
	};

	const setTokenData = async () => {
		const activeAccount = instance.getActiveAccount();

		if (activeAccount) {
			await setTokenAndRedirect(true);
		}
	};

	if (activeAccount) {
		setTokenAndRedirect(false);
	}

	/**
	 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
	 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
	 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
	 */
	return (
		<LoginWrapper>
			<AuthenticatedTemplate>
				<Stack direction='column' sx={{ p: '2rem', px: '4rem' }}>
					<Button
						sx={{ mb: '1rem' }}
						type='submit'
						fullWidth
						color='success'
						variant='contained'
						startIcon={<ArrowRightAltOutlinedIcon fontSize='large' />}
						to={'/admin/search'}
						component={Link}
					>
						{intl.formatMessage({ id: 'login.navigate-to-service' })}
					</Button>
					<Button
						type='submit'
						fullWidth
						color='success'
						variant='contained'
						startIcon={<ArrowRightAltOutlinedIcon fontSize='large' />}
						onClick={() => dispatch(logout(false))}
					>
						{intl.formatMessage({ id: 'logout' })}
					</Button>
				</Stack>
				{activeAccount ? <IdTokenData idTokenClaims={activeAccount.idTokenClaims} /> : null}
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Stack direction='column' sx={{ p: '2rem', px: '4rem' }}>
					<Button
						type='submit'
						fullWidth
						color='success'
						variant='contained'
						startIcon={<ArrowRightAltOutlinedIcon fontSize='large' />}
						onClick={handleLoginPopup}
					>
						{intl.formatMessage({ id: 'login.submit' })}
					</Button>
					{activeAccount && <Button onClick={setTokenData}>Set token to local storage</Button>}
				</Stack>
			</UnauthenticatedTemplate>
		</LoginWrapper>
	);
};

const MsalLogin = () => {
	return <MainContent />;
};

export default MsalLogin;
