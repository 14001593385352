import React from 'react';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/system';

const StyledListItem = styled(ListItem, {
	shouldForwardProp: prop =>
		prop !== 'button' &&
		prop !== 'conditionalStyled' &&
		prop !== 'sx' &&
		prop !== 'conditionalHovered' &&
		prop !== 'hoverCursorDefault' &&
		prop !== 'nested' &&
		prop !== 'beige' &&
		prop !== 'adminPanelItem' /* && prop !== 'adminPanelTitle' */,
})(({ theme, conditionalStyled, conditionalHovered, hoverCursorDefault, nested, beige, adminPanelItem, button }) => ({
	padding: adminPanelItem ? '0.25rem' : '1rem',
	paddingLeft: nested && !conditionalStyled ? '4rem' : conditionalStyled ? '1rem' : adminPanelItem ? '4.5rem' : '2rem',
	'&.MuiListItem-button:hover': {
		'& p, svg': {
			color: theme.palette.primary.beige,
		},
	},
	'& .MuiListItemText-primary': {
		fontWeight: beige && 600,
		color: beige && theme.palette.primary.beige,
	},
	// conditionally if betweenSmLg && !isDrawerExpanded
	display: conditionalStyled && 'flex',
	justifyContent: conditionalStyled && 'center',
	// conditionally if listItem active and hovered
	backgroundColor: conditionalHovered && theme.palette.secondary.menuActive,
	'&:hover': {
		backgroundColor: conditionalHovered && theme.palette.secondary.menuActive,
		cursor: !button || conditionalHovered || hoverCursorDefault ? 'default' : 'pointer',
	},
}));

const MenuListItem = ({
	button = false,
	onClick = null,
	conditionalStyled = false,
	conditionalHovered = false,
	hoverCursorDefault = false,
	nested = false,
	beige = false,
	adminPanelItem = false,
	children,
	...props
}) => {
	return (
		<StyledListItem
			button={button}
			onClick={onClick}
			conditionalStyled={conditionalStyled}
			conditionalHovered={conditionalHovered}
			hoverCursorDefault={hoverCursorDefault}
			nested={nested}
			beige={beige}
			adminPanelItem={adminPanelItem}
			// adminPanelTitle={adminPanelTitle}
			{...props}
		>
			{children}
		</StyledListItem>
	);
};

export default MenuListItem;
