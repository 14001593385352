import { useTheme } from '@mui/styles';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import FormDescription from './FormDescription';
import FormError from './FormError';
import FormLabel from './FormLabel';
import FormStyledTextfield from './FormStyledTextfield';
import InstructionBox from './InstructionBox';

type InputProps = {
	control: any;
	name: string;
	defaultValue?: any;
	error?: any;
	type?: string;
	label?: any;
	placeholder?: string;
	icon?: React.ReactNode;
	endAdornment?: React.ReactNode;
	startAdornment?: React.ReactNode;
	rows?: number;
	maxRows?: number;
	description?: string;
	disabled?: boolean;
	setInstruction?: any | null;
	instructions?: any | null;
	fullWidth?: boolean;
	variant?: 'outlined' | 'filled' | 'standard';
	size?: 'small' | 'medium' | 'large';
	step?: number;
	toggle?: boolean;
	toggleApproveAction?: () => void;
	toggleDeclineAction?: () => void;
	spacing?: number;
	sx?: any;
	InputProps?: object;
	focused?: boolean;
	isActiveFunc?: (isTrue: boolean) => boolean;
	onInputBlur?: () => void;
};

const FormControlledInput: React.FC<InputProps> = ({
	control,
	name,
	defaultValue,
	error,
	type,
	label,
	placeholder,
	icon,
	endAdornment,
	startAdornment,
	rows,
	maxRows,
	description,
	disabled,
	setInstruction = null,
	instructions = null,
	fullWidth = true,
	variant = 'outlined',
	size = 'small',
	step,
	toggle = false,
	toggleApproveAction,
	toggleDeclineAction,
	spacing = 2,
	sx,
	InputProps,
	focused,
	isActiveFunc,
	onInputBlur = () => {},
	...props
}) => {
	const [modify, setModify] = useState(true);
	const theme = useTheme();

	const setToggle = () => {
		setModify(!modify);
	};

	const approve = () => {
		setModify(!modify);

		if (toggleApproveAction) {
			toggleApproveAction();
		}
	};

	const decline = () => {
		setModify(!modify);

		if (toggleApproveAction) {
			toggleApproveAction();
		}
	};

	const [isActive, setIsActive] = useState(false);

	return (
		<FormControl fullWidth={fullWidth} sx={sx}>
			{(label || toggle) && (
				<FormLabel>
					<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={spacing}>
						{label}
						{toggle && (
							<IconButton onClick={setToggle}>
								<EditIcon />
							</IconButton>
						)}
					</Stack>
				</FormLabel>
			)}
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field: { onChange, value } }) => {
					return (
						<FormStyledTextfield
							value={value}
							onChange={onChange}
							onFocus={() => {
								if (setInstruction) {
									setInstruction({ text: instructions, top });
								}
								if (isActiveFunc) {
									isActiveFunc(true);
								}

								setIsActive(true);
							}}
							onBlur={() => {
								if (setInstruction) {
									setInstruction('');
								}
								if (isActiveFunc) {
									isActiveFunc(false);
								}
								setIsActive(false);
								onInputBlur();
							}}
							error={error}
							type={type}
							placeholder={placeholder}
							icon={icon}
							rows={rows}
							maxRows={maxRows}
							variant={variant}
							size={size}
							step={step}
							postalCode={name === 'postalCode'}
							disabled={disabled || (toggle && modify)}
							showDisabledAsText={toggle}
							focused={focused}
							endAdornment={endAdornment}
							startAdornment={startAdornment}
							sx={{
								textDecoration: 'none',
							}}
							InputProps={InputProps}
							{...props}
						/>
					);
				}}
			/>
			{toggle && !modify && (
				<Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={spacing}>
					<IconButton onClick={decline}>
						<CancelIcon
							sx={{
								borderRadius: '50%',
								'&:hover': {
									// @ts-ignore
									color: theme.palette.error.main,
								},
							}}
						/>
					</IconButton>
					<IconButton onClick={approve}>
						<DoneIcon color='success' />
					</IconButton>
				</Stack>
			)}
			<FormError error={error} />
			<FormDescription>{description}</FormDescription>
			{isActive && <InstructionBox value={instructions ? instructions : null} />}
		</FormControl>
	);
};

export default FormControlledInput;
