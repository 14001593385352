import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChangePasswordForm from './ChangePasswordForm';
import { Helmet } from 'react-helmet-async';
import { useChangeCurrentPasswordMutation } from 'containers/Account/passwordV2Api';
import { toast } from 'react-toastify';
import { Stack } from '@mui/material';
import Logo from 'icons/Logo';

interface Props {}

interface FieldValues {
	id: string | null;
	username: string | null;
	password: string | null;
	newpassword: string | null;
	confirmpassword: string | null;
}

const ChangePassword: React.FC<Props> = () => {
	const [changeCurrentPassword, { isLoading }] = useChangeCurrentPasswordMutation();

	const onSubmit = React.useCallback(
		async (fieldValues: FieldValues) => {
			if (fieldValues?.newpassword !== fieldValues?.confirmpassword) {
				toast.error(<FormattedMessage id='message.passwordsNotMatch' />);
				return;
			}
			try {
				await changeCurrentPassword(fieldValues).unwrap();
				toast.success(<FormattedMessage id='change.password.success.message' />);
			} catch (error: any) {
				toast.error(
					Array.isArray(error?.response?.data) ? (
						<FormattedMessage id={`error.code.${error?.response?.data[0]?.code}`} />
					) : (
						<FormattedMessage id={`error.code.${error?.response?.data}`} />
					)
				);
			}
		},
		[changeCurrentPassword]
	);

	return (
		<>
			<Helmet>
				<title>GrantedAI - change password</title>
			</Helmet>
			<Stack
				direction='column'
				spacing={2}
				sx={{ minHeight: { xs: '40rem', md: '100%' }, padding: '1.143rem', backgroundColor: theme => theme.palette.primary.main }}
				useFlexGap
			>
				<Logo />
				<Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Stack direction='column' spacing={2} sx={{ width: '100%', maxWidth: '30rem' }} useFlexGap>
						<Typography
							variant='h1'
							sx={{
								color: theme => theme.palette.primary.contrastText,
								fontSize: '1.714rem',
								fontWeight: 700,
								lineHeight: 1.3,
								textAlign: 'center',
							}}
						>
							<FormattedMessage id='title.change.temporary.password' />
						</Typography>
						<Typography
							sx={{
								color: theme => theme.palette.primary.contrastText,
								fontSize: '1.143rem',
								fontWeight: 400,
								lineHeight: 1.5,
								textAlign: 'center',
							}}
						>
							<FormattedMessage id='title.fill.form' />
						</Typography>
						{/* @ts-ignore */}
						<ChangePasswordForm onSubmit={onSubmit} loading={isLoading} />
					</Stack>
				</Box>
			</Stack>
		</>
	);
};

export default ChangePassword;
