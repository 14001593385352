import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextFieldProps, TextField, MenuItem, InputAdornment, CircularProgress, Alert, AlertTitle } from '@mui/material';
import { map } from 'lodash';

import { useFetchCommonDataQuery } from 'containers/Marketplace/rfpCommonApi';

type Props = {} & TextFieldProps;

const RfpCostTypeSelect = React.forwardRef<any, Props>(({ placeholder, ...otherProps }, ref) => {
	const { data: commonData, isLoading, isError } = useFetchCommonDataQuery();

	return (
		<>
			{isError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpCommonData.error.message' />
				</Alert>
			)}
			<TextField
				{...otherProps}
				placeholder={placeholder}
				ref={ref}
				InputProps={{
					...otherProps.InputProps,
					endAdornment: isLoading ? (
						<InputAdornment position='end' sx={{ mr: 3 }}>
							<CircularProgress size='1.143rem' />
						</InputAdornment>
					) : null,
				}}
				select
			>
				<MenuItem value=''>{placeholder}</MenuItem>
				{map(commonData?.rfpCostType, costType => (
					<MenuItem key={costType} value={costType}>
						<FormattedMessage id={`rfpCostType.${costType}`} />
					</MenuItem>
				))}
			</TextField>
		</>
	);
});

export default RfpCostTypeSelect;
