import React from 'react';
import { FormattedNumber } from 'react-intl';

export default function FormatCurrency({ value, style = 'currency', minimumFractionDigits = 0, maximumFractionDigits = 0 }) {
    try {
        return <FormattedNumber
            style={style}
            value={value}
            currency='EUR'
            minimumFractionDigits={minimumFractionDigits}
            maximumFractionDigits={maximumFractionDigits}
        />;
    }
    catch { return value; }
}