import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';

import DataGrid from 'components/DataGrid';
import Button from 'components/Button/Button';
import FormattedDate from 'components/Formatters/FormattedDate';

const useStyles = makeStyles(theme => ({
    cell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.primary.main,
    },
}));

const UserTable = ({ loading, users, fetchUserDetails }) => {
    const intl = useIntl();
    const classes = useStyles();

    const columns = [
        {
            field: 'id',
            headerName: intl.formatMessage({ id: 'userlist.table.id' }),
            headerClassName: 'styled-header',
            width: 350,
            disableClickEventBubbling: true,
            renderCell: params => (
                <Button
                    variant='text'
                    size='small'
                    color='primary'
                    textCase='none'
                    disabled={!params.row.id}
                    onClick={() => fetchUserDetails(params.row.id)}
                >
                    {params.row.id}
                </Button>
            ),
            disableColumnSelector: true,
        },
        {
            field: 'isActive',
            headerName: intl.formatMessage({ id: 'userlist.table.isActive' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.isActive + ''}` })}
                </span>
            ),
        },
        {
            field: 'timestamp',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userlist.table.timestamp' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.timestamp} />
                </span>
            ),
        },
        {
            field: 'emailConfirmed',
            headerName: intl.formatMessage({ id: 'userlist.table.emailConfirmed' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.emailConfirmed + ''}` })}
                </span>
            ),
        },
        {
            field: 'phoneNumber',
            headerName: intl.formatMessage({ id: 'userlist.table.phoneNumber' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.phoneNumber}
                </span>
            ),
        },
        {
            field: 'phoneNumberConfirmed',
            headerName: intl.formatMessage({ id: 'userlist.table.phoneNumberConfirmed' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.phoneNumberConfirmed + ''}` })}
                </span>
            ),
        },
        {
            field: 'twoFactorEnabled',
            headerName: intl.formatMessage({ id: 'userlist.table.twoFactorEnabled' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.twoFactorEnabled + ''}` })}
                </span>
            ),
        },
        {
            field: 'lockoutEnabled',
            headerName: intl.formatMessage({ id: 'userlist.table.lockoutEnabled' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.lockoutEnabled + ''}` })}
                </span>
            ),
        },
        {
            field: 'lockoutEndDate',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userlist.table.lockoutEndDate' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.lockoutEndDate} />
                </span>
            )
        },
        {
            field: 'accessFailedCount',
            headerName: intl.formatMessage({ id: 'userlist.table.accessFailedCount' }),
            headerClassName: 'styled-header',
            width: 220,
            renderCell: params => (
                <span className={classes.cell}>{params.row.accessFailedCount}</span>
            ),
        },
        {
            field: 'userName',
            headerName: intl.formatMessage({ id: 'userlist.table.userName' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={classes.cell}>{params.row.userName}</span>
            ),
        },
        {
            field: 'email',
            headerName: intl.formatMessage({ id: 'userlist.table.email' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={classes.cell}>{params.row.email}</span>
            ),
        }
    ];

    return (
        <DataGrid
            sx={{
                '&:hover': { cursor: 'pointer' }
            }}
            idField='rowKey'
            loading={loading}
            data={users}
            columns={columns}
            paginationMode='client'
            mt={1}
            onRowClick={(params) => fetchUserDetails(params.row.id)}
        />
    );
};

export default UserTable;