import { matchPath } from 'react-router-dom';

/**
 * Parses url with company prefix
 *
 * @param url suffix uri to the company uri
 * @returns parsed uri
 */
export const getCompanyPath = (url, path, companyId, admin = false) => {
    if (!path) throw Error('Path is required in getCompanyPath');

    const match = matchPath(path, {
        path: ['/company/:businessId', '/admin/company/:businessId'],
        exact: false,
        strict: false
    });

    return match && admin ? `/admin/company/${companyId}${url}` : match && !admin ? `/company/${companyId}${url}` : url;
};