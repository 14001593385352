import { map } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

import CurrencyValue from 'components/Currency/CurrencyValue';
import { RfpProposalItemCost } from 'types/dataTypes';

type Props = {
	costs: RfpProposalItemCost[] | null | undefined;
};

type PriceTagProps = {
	cost: RfpProposalItemCost;
};

type TotalPriceProps = {
	costs: RfpProposalItemCost[];
};

type CurrencyRangeProps = {
	min: number | bigint | null | undefined;
	max: number | bigint | null | undefined;
};

const StyledPriceSecondary = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.muted,
	fontSize: '.875rem',
}));

const StyledTotalPrice = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: '.875rem',
}));

const StyledCostsTotalPrice = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.muted,
	fontSize: '.875rem',
}));

export const calculateCostMinMax = (costs: Array<RfpProposalItemCost> | null) => {
	if (!costs) return { minPrice: 0, maxPrice: 0 };

	const minPrice = costs.reduce((a, b) => {
		const min = !b.totalPrice ? 0 : b.totalPrice;

		return a + min;
	}, 0);

	const maxPrice = costs.reduce((a, b) => {
		const max = !b.totalPriceMax ? 0 : b.totalPriceMax;

		return a + max;
	}, 0);

	return { minPrice, maxPrice };
};

export const CurrencyRange: React.FC<CurrencyRangeProps> = ({ min, max }) => {
	if (!max) {
		return <CurrencyValue value={min} />;
	}

	return (
		<>
			<CurrencyValue numberStyle='decimal' value={min} />-<CurrencyValue value={max} />
		</>
	);
};

const TotalPrice: React.FC<TotalPriceProps> = ({ costs }) => {
	if (!costs) return null;

	const costMinMax = calculateCostMinMax(costs);

	return (
		<>
			<Stack direction='column' sx={{ width: '100%', mt: '1rem' }}>
				<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
					<StyledCostsTotalPrice>
						<FormattedMessage id='rfp.proposal.item.cost.total' />
					</StyledCostsTotalPrice>

					<StyledCostsTotalPrice>
						<CurrencyRange min={costMinMax.minPrice} max={costMinMax.maxPrice} />
					</StyledCostsTotalPrice>
				</Stack>
			</Stack>
		</>
	);
};

const PriceTag: React.FC<PriceTagProps> = ({ cost }) => {
	const Price = ({ children }: any) => {
		switch (cost.costType) {
			case 'Fixed':
				return (
					<Stack direction='column' sx={{ width: '100%' }}>
						<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
							{children}
							<StyledTotalPrice>
								<CurrencyValue value={cost.price} />
							</StyledTotalPrice>
						</Stack>
					</Stack>
				);
			case 'FixedScale':
				return (
					<Stack direction='column' sx={{ width: '100%' }}>
						<Stack direction='row' sx={{ width: '100%' }}>
							{children}
							<StyledTotalPrice>
								<CurrencyRange min={cost.price} max={cost.priceMax} />
							</StyledTotalPrice>
						</Stack>
					</Stack>
				);
			case 'Hourly':
				return (
					<Stack direction='column' sx={{ width: '100%' }}>
						<Stack direction='row' sx={{ width: '100%' }}>
							{children}
							<StyledTotalPrice>
								<CurrencyValue value={cost.totalPrice} />
							</StyledTotalPrice>
						</Stack>
						<Stack direction='row'>
							<StyledPriceSecondary>{cost.hourEstimate}h</StyledPriceSecondary>
							<StyledPriceSecondary>
								(<CurrencyValue value={cost.price} /> / h)
							</StyledPriceSecondary>
						</Stack>
					</Stack>
				);
			case 'HourlyEstimate':
				return (
					<Stack direction='column' sx={{ width: '100%' }}>
						<Stack direction='row' sx={{ width: '100%' }}>
							{children}
							<StyledTotalPrice>
								<CurrencyRange min={cost.totalPrice} max={cost.totalPriceMax} />
							</StyledTotalPrice>
						</Stack>
						<Stack direction='row'>
							<StyledPriceSecondary>
								{cost.hourEstimate}-{cost.hourEstimateMax}h
							</StyledPriceSecondary>
							<StyledPriceSecondary>
								(<CurrencyValue value={cost.price} /> / h)
							</StyledPriceSecondary>
						</Stack>
					</Stack>
				);
			default:
				return <div>PROBLEM</div>;
		}
	};

	return (
		<>
			<Price>
				<Box flexGrow={1}>
					<Typography variant='subtitle2' component='span'>
						<FormattedMessage id={`rfpCostClass.${cost.costClass}`} />
					</Typography>
					{' - '}
					<Typography variant='subtitle2' component='span'>
						<FormattedMessage id={`rfpCostType.${cost.costType}`} />
					</Typography>
				</Box>
			</Price>
		</>
	);
};

const RfpProposalItemCostList: React.FC<Props> = ({ costs }) => {
	if (!costs) return null;

	if (!costs || costs.length === 0) return <div>-</div>;

	return (
		<>
			{map(costs, (rfpItemCost, index) => {
				return (
					<Stack key={index} direction='row'>
						<PriceTag cost={rfpItemCost} />
					</Stack>
				);
			})}
			<TotalPrice costs={costs} />
		</>
	);
};

export default RfpProposalItemCostList;
