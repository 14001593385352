import React from 'react';

import ChipList from '../SuitableInstrumentsPanel/ChipList';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const QuickCrop = ({
    filterList,
    uniqueLevels,
    allLevels,
    setAllLevels,
    clickedLevels,
    setClickedLevels,
    uniqueTypes,
    allTypes,
    setAllTypes,
    clickedTypes,
    setClickedTypes,
}) => {
    return (
        <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
            <ChipList
                filterList={filterList}
                uniqueItems={uniqueLevels?.sort().reverse()}
                all={allLevels}
                setAll={setAllLevels}
                clicked={clickedLevels}
                setClicked={setClickedLevels}
            />
            <Box sx={{ display: { xs: 'none', md: 'block' }, ml: '1rem' }}>
                <ChipList
                    filterList={filterList}
                    uniqueItems={uniqueTypes}
                    all={allTypes}
                    setAll={setAllTypes}
                    clicked={clickedTypes}
                    setClicked={setClickedTypes}
                />
            </Box>
        </Stack>
    );
};

export default QuickCrop;
