import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import NotificationBadge from 'components/NotificationBadge';
import { usePollMessagesQuery, pollingInterval } from 'containers/Messages/api';
import { isAdminToken } from 'utils/auth/token';

const MessagesItem = ({ companyId, isAgreement, pathname, regexProfile, staticAnalysis, openMessagesDrawer }) => {
    const { data: messages } = usePollMessagesQuery(
        'messages',
        {
            skip: !companyId,
            pollingInterval
        }
    );
    const messagesNumber = messages?.filter(({ isRead }) => !isRead).length;

    const isAdmin = isAdminToken();

    if ((isAgreement || isAdmin) && pathname !== '/search' && pathname !== '/customers' && !regexProfile.test(pathname) && !staticAnalysis) return (
        <>
            <Divider
                flexItem
                light
                orientation='vertical'
                sx={{
                    height: '4rem',
                    marginLeft: 1.5,
                    marginRight: 1.5,
                    backgroundColor: 'textPrimary.main',
                    opacity: 0.25,
                }}
            />
            <Grid container justifyContent='center' alignItems='center'>
                <Grid item>
                    <IconButton aria-label='notifications' onClick={openMessagesDrawer}>
                        <NotificationBadge icon='notification' messagesNumber={messagesNumber} iconSize={20} />
                    </IconButton>
                </Grid>
            </Grid>
        </>

    );

    return null;
};

export default MessagesItem;
