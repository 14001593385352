import * as React from 'react';
import { Dialog, DialogProps, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import { closeDialog, isDialogOpen, openDialog } from 'containers/Marketplace/dialogSlice';

import UpsertRfpProfileForm from 'containers/Marketplace/profile/UpsertRfpProfileForm';

type Props = Omit<DialogProps, 'open'> & {};

const UpsertRfpProfileDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const dialogOpen = useSelector(isDialogOpen('UpsertRfpProfileDialog'));

	const [isDirty, setIsDirty] = React.useState(false);

	const handleDialogClose = React.useCallback(() => {
		if (isDirty) {
			dispatch(
				openDialog({
					name: 'ConfirmCloseDialog',
					data: {
						onSubmit: () => {
							dispatch(closeDialog({ name: 'UpsertRfpProfileDialog' }));
						},
					},
				})
			);
		} else {
			dispatch(closeDialog({ name: 'UpsertRfpProfileDialog' }));
		}
	}, [dispatch, isDirty]);

	const handleAfterPublish = React.useCallback(() => {
		dispatch(closeDialog({ name: 'UpsertRfpProfileDialog' }));
	}, [dispatch]);

	const handleDirtyChange = React.useCallback((nextIsDirty: boolean) => {
		setIsDirty(nextIsDirty);
	}, []);

	return (
		<>
			<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='smd' fullWidth>
				<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
					<CloseIcon />
				</IconButton>
				<UpsertRfpProfileForm
					onAfterPublish={handleAfterPublish}
					onCloseClick={handleDialogClose}
					onDirtyChange={handleDirtyChange}
				/>
			</Dialog>
		</>
	);
};

export default UpsertRfpProfileDialog;
