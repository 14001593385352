import * as React from 'react';

import { Chip, ChipProps, useTheme } from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';
import { ThemeProvider } from '@mui/system';

import { FormattedMessage } from 'react-intl';
import { isArray } from 'lodash';
import themeV2 from 'config/themeV2';

type Props = ChipProps & {
	status?: string | null;
};

const ThemeWrapper: React.FC<Props> = ({ status, sx, ...otherProps }) => {
	return (
		<ThemeProvider theme={themeV2}>
			<StatusChip {...{ status, sx, ...otherProps }} />
		</ThemeProvider>
	);
};

const StatusChip: React.FC<Props> = ({ status, sx, ...otherProps }) => {
	const theme = useTheme();

	const color = React.useMemo(() => {
		if (status === 'Draft') {
			return theme.palette.status.draft;
		}
		if (status === 'Published') {
			return theme.palette.status.published;
		}
		if (status === 'Archived') {
			return theme.palette.status.archived;
		}
	}, [theme, status]);

	if (!status) return null;

	return (
		<Chip
			{...otherProps}
			variant='filled'
			icon={<CircleIcon />}
			sx={[{ '& .MuiChip-icon': { color } }, ...(isArray(sx) ? sx : [sx])]}
			label={<FormattedMessage id={`rfpStatus.${status}`} />}
		/>
	);
};

export default ThemeWrapper;
