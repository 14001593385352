import * as yup from 'yup';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LockOpenOutlined as LockOpenOutlinedIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import CustomTooltip from '../CustomTooltip';
import { FromControlledHiddenField } from 'components/Form';
import FormControlledInput from 'components/Form/FormControlledInput';
import { Box, Fade, Link, Stack } from '@mui/material';
import Button from 'components/Button/Button';

const useStyles = makeStyles(theme => ({
	form: {
		width: '100%',
	},
	wrapper: {
		margin: theme.spacing(3, 0, 2),
		position: 'relative',
	},
	button: {
		marginTop: '2rem',
		padding: theme.spacing(2),
		fontWeight: 600,
		color: theme.palette.primary.white,
		backgroundColor: theme.palette.success.main,
		'&:hover': {
			opacity: '0.8',
			backgroundColor: theme.palette.success.main,
		},
		'&:disabled': {
			// FIXME: this has no effect
			opacity: '0.6',
			backgroundColor: theme.palette.success.main,
		},
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: theme.spacing(-1.5),
		marginLeft: theme.spacing(-1.5),
		color: theme.palette.primary.white,
	},
	link: {
		color: theme.palette.primary.white,
		fontSize: '1.1rem',
	},
}));

const ActivateAccountForm = ({ id, activate, loading, onSubmit }) => {
	const classes = useStyles();
	const intl = useIntl();

	const schema = yup.object().shape({
		newpassword: yup.string().required(
			`${intl.formatMessage({ id: 'login.placeholder.newpassword' })} ${intl.formatMessage({
				id: 'message.isRequired',
			})}`
		),
		confirmpassword: yup
			.string()
			.required(
				`${intl.formatMessage({ id: 'login.placeholder.confirmpassword' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			)
			.oneOf([yup.ref('newpassword'), null], intl.formatMessage({ id: 'message.passwordsNotMatch' })),
	});

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			id: id,
			username: '',
			password: '',
			newpassword: '',
			confirmpassword: '',
		},
		resolver: yupResolver(schema),
	});

	const password = React.useRef({});
	password.current = watch('newpassword');

	return (
		<Box
			component='form'
			onSubmit={handleSubmit(onSubmit)}
			sx={{
				'& .MuiInputBase-root.MuiOutlinedInput-root': {
					backgroundColor: theme => theme.palette.primary.contrastText,
				},
				'& .FormError-root': {
					color: 'primary.white',
				},
			}}
			noValidate
		>
			<Stack spacing={2} direction='column' useFlexGap>
				<FromControlledHiddenField name='id' defaultValue={id} control={control} />
				{errors.passwordId && <p>{errors.passwordId.message}</p>}
				<CustomTooltip
					title={intl.formatMessage({ id: 'password.validation.strength' })}
					placement='top'
					disableHoverListener
					enterTouchDelay={0}
					leaveTouchDelay={2000}
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 500 }}
				>
					<span>
						<FormControlledInput
							disabled={loading}
							loading={loading}
							defaultValue=''
							name='newpassword'
							type='password'
							control={control}
							error={errors?.newpassword?.message}
							placeholder={intl.formatMessage({ id: 'login.placeholder.newpassword' })}
							icon={<LockOpenOutlinedIcon color='primary' />}
							InputProps={{
								sx: { p: '0.4286rem 1.2143rem 0.4286rem 1.2143rem' },
							}}
						/>
					</span>
				</CustomTooltip>
				<FormControlledInput
					disabled={loading}
					loading={loading}
					defaultValue=''
					name='confirmpassword'
					type='password'
					control={control}
					error={errors?.confirmpassword?.message}
					placeholder={intl.formatMessage({ id: 'login.placeholder.confirmpassword' })}
					icon={<LockOpenOutlinedIcon color='primary' />}
					InputProps={{
						sx: { p: '0.4286rem 1.2143rem 0.4286rem 1.2143rem' },
					}}
				/>
				<Button
					type='submit'
					color='success'
					sx={{ p: '0.85714rem 1.21429rem', lineHeight: '1.28571rem', fontSize: '1.14286rem' }}
					loading={loading}
					fullWidth
				>
					{intl.formatMessage({ id: 'common.submit' })}
				</Button>
				{!activate && (
					<Link component={RouterLink} to='/login' className={classes.link}>
						{intl.formatMessage({ id: 'login.forgotpassword.backtologin' })}
					</Link>
				)}
			</Stack>
		</Box>
	);
};

export default ActivateAccountForm;
