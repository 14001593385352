import store from 'configureStore';
import { logout } from 'containers/App/appSlice';
import { omit } from 'lodash';
import { getAccessToken, parseAccessTokenFromTokenData } from 'utils/auth/token';
import { attachTokenToRequest, handleTokenRefresh, refreshUri, setTokenData, shouldIntercept } from './axiosFunctions';

const { dispatch } = store;

/**
 * export axios client to the requester for implementing axios requests
 */
const axiosInterceptor = (axiosClient, customOptions = {}) => {
	let isRefreshing = false;
	let failedQueue = [];

	const options = {
		attachTokenToRequest,
		handleTokenRefresh,
		setTokenData,
		shouldIntercept,
		...customOptions,
	};

	const isReFreshRequestError = error => {
		try {
			const isRefreshRequest = error.config.url === refreshUri;

			if (isRefreshRequest) {
				// if request uri is refresh throw user out to login, maybe refresh request has mulfunction
				failedQueue = [];

				dispatch(logout(true));

				return true;
			}
		} catch {
			// don't handle exceptions
		}
	};

	const processQueue = (error, token = null) => {
		failedQueue.forEach(prom => {
			if (error) {
				prom.reject(error);
			} else {
				prom.resolve(token);
			}
		});

		failedQueue = [];
	};

	const responseInterceptor = async error => {
		if (isReFreshRequestError(error)) {
			return Promise.reject(error);
		}

		if (!options.shouldIntercept(error)) {
			return Promise.reject(error);
		}

		if (error.config._retry || error.config._queued) {
			return Promise.reject(error);
		}

		const originalRequest = error.config;
		if (isRefreshing) {
			return new Promise(function (resolve, reject) {
				failedQueue.push({ resolve, reject });
			})
				.then(token => {
					originalRequest._queued = true;
					options.attachTokenToRequest(originalRequest, token);

					return axiosClient.request(originalRequest);
				})
				.catch(err => {
					return Promise.reject(err); // Ignore refresh token request's "err" and return actual "error" for the original request
				});
		}

		originalRequest._retry = true;
		isRefreshing = true;

		return await getRefreshToken(originalRequest);
	};

	const getRefreshToken = async originalRequest => {
		try {
			const tokenData = await options.handleTokenRefresh();
			options.setTokenData(tokenData, axiosClient);

			const accessToken = parseAccessTokenFromTokenData(tokenData.token);

			options.attachTokenToRequest(originalRequest, accessToken);

			processQueue(null, accessToken);

			const response = await axiosClient.request(originalRequest);

			return response;
		} catch (err) {
			processQueue(err, null);
			throw err;
		} finally {
			isRefreshing = false;
		}
	};

	axiosClient.interceptors.request.use(
		async config => {
			const accessToken = getAccessToken();

			config.headers = {
				...config.headers,
				Authorization: accessToken,
			};

			if (!config?.headers?.Authorization) {
				config.headers = omit(config.headers, 'Authorization');
			}

			return config;
		},
		error => {
			Promise.reject(error);
		}
	);

	axiosClient.interceptors.response.use(response => {
		return response;
	}, responseInterceptor);
};

export default axiosInterceptor;
