import { createSlice } from '@reduxjs/toolkit';

import appSlice, { resetAllData } from 'containers/App/appSlice';
import { push } from 'react-router-redux';
import { isAdvancedToken } from 'utils/auth/company';
import { getRefreshToken, setToken } from 'utils/auth/token';
import axios from 'utils/axios';
import { refreshUri } from 'utils/axios/axiosFunctions';
import { storageKeys } from 'utils/constants/constants';
import storage from 'utils/localStorage/localStorageFunctions';
import { loadCustomers } from '../Advanced/slices';

const storedAgreement = storage.get(storageKeys.AGREEMENT_KEY);

interface ContractState {
	loading: boolean;
	isDataLoaded: boolean;
	agreement: {} | null;
	sign: {} | null;
	error: {} | null;
}

const initialState: ContractState = {
	loading: false,
	isDataLoaded: false,
	agreement: storedAgreement,
	sign: null,
	error: null,
};

export const name = 'contract';

const contractSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		authAgreementStart: state => {
			state.loading = true;
		},
		authAgreementError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.agreement = initialState.agreement;
		},
		authAgreementSuccess: (state, action) => {
			state.loading = false;
			state.error = false;
			state.agreement = action.payload;
		},
	},
});

export const { reducer } = contractSlice;
export default contractSlice;

export const handleCompanyContractSuccessResponse = (isContributor: boolean, redirect: string) => async (dispatch: any, getState: any) => {
	try {
		dispatch(contractSlice.actions.authAgreementStart());

		const refreshToken = getRefreshToken();

		const authResponse = await axios.post(refreshUri, { refreshToken });

		dispatch(contractSlice.actions.authAgreementSuccess(authResponse));

		setToken(authResponse?.data?.token);
		storage.set(storageKeys.USER_KEY, authResponse.data.user);
		storage.set(storageKeys.COMPANY_KEY, authResponse.data.user.company);
		storage.set(storageKeys.TOKEN_KEY, authResponse.data.token);

		dispatch(appSlice.actions.authSuccess(authResponse.data.user));

		await dispatch(resetAllData());

		const isAdvanced = isAdvancedToken();

		if (!isContributor && isAdvanced) dispatch(loadCustomers());

		if (redirect) dispatch(push(redirect));

		return true;
	} catch (error) {
		dispatch(contractSlice.actions.authAgreementError(error));
	}
};
