import { each, findIndex, includes, map, toLower } from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';

import Analysis from '../Views/Analysis';
import BusinessPlan from '../Views/BusinessPlan';
import Instruments from '../Views/Instruments';
import Landing from '../Views/Landing';
import Projects from '../Views/Projects/Projects';
import Scenario from '../Views/Scenario';
import Timeline from '../Views/Timeline';

const TabPanel = ({ children, value, index, ...other }) => {
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

const a11yProps = index => {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
};

const DynamicTabContentById = ({ name, linkId }) => {
	const tabs = {
		landing: <Landing linkId={linkId} />,
		financialplan: <Timeline linkId={linkId} />,
		analysis: <Analysis linkId={linkId} />,
		businessplan: <BusinessPlan linkId={linkId} />,
		scenario: <Scenario linkId={linkId} />,
		instruments: <Instruments linkId={linkId} />,
		projects: <Projects linkId={linkId} />,
	};

	return tabs[name] ?? null;
};

const TabList = ({ tabs, value, handleChange, linkId }) => {
	const intl = useIntl();

	return (
		<Tabs
			sx={{ my: '1rem' }}
			value={value}
			onChange={handleChange}
			indicatorColor='primary'
			textColor='primary'
			variant='scrollable'
			scrollButtons='auto'
			aria-label='profile tabs'
		>
			{map(tabs, ({ name }, index) => {
				let linkUrl = `/stakeholder/${linkId}/${name}`;

				return (
					<Tab
						key={name}
						component={Link}
						to={linkUrl}
						label={intl.formatMessage({ id: `stakeholder.tabs.${name}` })}
						{...a11yProps(index)}
					/>
				);
			})}
		</Tabs>
	);
};

const TabContents = ({ tabs, value, handleChangeIndex, linkId }) => {
	const theme = useTheme();

	return (
		<SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
			{map(tabs, ({ name }, index) => (
				<TabPanel key={name} value={value} index={index} dir={theme.direction}>
					<DynamicTabContentById name={name} linkId={linkId} />
				</TabPanel>
			))}
		</SwipeableViews>
	);
};

const StakeholderProfileDataMenu = ({ linkId, linkParts }) => {
	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const tabs = [{ name: 'landing' }];

	each(linkParts, name => {
		if (includes(['businessplan', 'analysis', 'financialplan', 'projects'], toLower(name))) {
			tabs.push({ name: toLower(name) });
		}
	});

	const { area } = useParams();

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = index => {
		setValue(index);
	};

	useEffect(() => {
		const tabIndex = findIndex(tabs, ({ name }) => name === area);
		setValue(tabIndex >= 0 ? tabIndex : 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [area]);

	return (
		<>
			<Box sx={{ bgcolor: theme.palette.primary.secondary, width: '100%' }}>
				<TabList tabs={tabs} value={value} linkId={linkId} handleChange={handleChange} />
				<TabContents tabs={tabs} value={value} linkId={linkId} onChangeIndex={handleChangeIndex} />
			</Box>
		</>
	);
};

export default StakeholderProfileDataMenu;
