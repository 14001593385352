import { map } from 'lodash';
import * as React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { Box, Grid, Stack, Typography } from '@mui/material';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchInstrumentCategoriesQuery, useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import { getCompanyId, getFirstCompany } from 'utils/auth/company';

import guideImg from 'assets/images/grants_guide.webp';
import InstrumentCategoryList from 'containers/Instruments/InstrumentList/InstrumentCategoryList';
import getCompanyLink from 'utils/auth/getCompanyLink';
import { SubMenuItem } from '../MasterPage/MenuDrawer/CompanyMenuItems/MenuSubItem/MenuSubItem';

export const GetGuidesMenuList = () => {
	const { guideId: selectedGuide }: any = useParams();

	const { data: referenceCategories, isLoading: isReferenceCategoriesLoading } = useFetchInstrumentCategoriesQuery({
		name: 'references',
	});

	if (isReferenceCategoriesLoading) return null;

	return map(referenceCategories, (item: any) => {
		const isSelected = item.referenceId === selectedGuide;

		return (
			<SubMenuItem
				key={item.category}
				active={isSelected}
				title={item.category}
				navigateUrl={getCompanyLink(`/guides/${item.referenceId}`, location)}
			/>
		);
	});
};

const ReferenceCategoriesList: React.FC<any> = ({ referenceCategories, selectedCategory, setGuide }) => {
	return (
		<Grid container spacing={4}>
			{map(referenceCategories, (item: any) => {
				const isSelected = item.referenceId === selectedCategory;

				return (
					<Grid item xs={12} md={6} lg={4} key={item.category}>
						<Stack
							onClick={() => !isSelected && setGuide(item.referenceId)}
							sx={{
								background: isSelected ? '#dddddd' : '#fff',
								boxShadow: isSelected ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none',
								py: '2rem',
								'&:hover': {
									cursor: isSelected ? 'default' : 'pointer',
									boxShadow: isSelected
										? 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
										: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
								},
							}}
							alignItems='center'
						>
							<Box component='img' src={guideImg} sx={{ width: '15rem', margin: '0 auto' }} />
							<Typography sx={{ mt: '2rem' }} variant='h3'>
								{item.category}
							</Typography>
						</Stack>
					</Grid>
				);
			})}
		</Grid>
	);
};

const GuidesIndex: React.FC<{ guideId?: string | null }> = ({ guideId }) => {
	const companyId = getCompanyId();
	const navigate = useHistory();

	const [selectedGuide, setGuide] = React.useState(guideId);

	React.useEffect(() => {
		setGuide(guideId);
	}, [guideId]);

	const {
		data: instruments,
		isLoading: isInstrumenstLoading,
		isFetching: isInstrumenstFetching,
	} = useFetchInstrumentsByCompanyQuery({ companyId }, { skip: !companyId });

	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const { data: referenceCategories, isLoading: isReferenceCategoriesLoading } = useFetchInstrumentCategoriesQuery({
		name: 'references',
	});

	const categories = React.useMemo(() => {
		if (referenceCategories) {
			const filteredReferenceCategories = referenceCategories.filter((category: any) => {
				return category?.categoryId === selectedGuide || category?.referenceId === selectedGuide;
			});

			if (filteredReferenceCategories && filteredReferenceCategories.length !== 0) {
				return filteredReferenceCategories;
			}

			setGuide(referenceCategories?.[0].referenceId);
			return referenceCategories;
		}
	}, [selectedGuide, referenceCategories]);

	if (!companyId && getFirstCompany()?.CompanyId) {
		if (guideId) return <Redirect to={{ pathname: `/company/${getFirstCompany()?.CompanyId}/guides/${guideId}` }} />;

		return <Redirect to={{ pathname: `/company/${getFirstCompany()?.CompanyId}/guides` }} />;
	}

	const navigateSelectedGuide = (guideId: string) => {
		setGuide(guideId);

		if (companyId) navigate.push(`/company/${companyId}/guides/${guideId}`);
	};

	return (
		<>
			<ReferenceCategoriesList
				referenceCategories={referenceCategories}
				selectedCategory={selectedGuide}
				setGuide={navigateSelectedGuide}
			/>
			{categories && categories.length > 0 && (
				// @ts-ignore
				<InstrumentCategoryList
					loading={
						isInstrumenstLoading ||
						isInstrumenstFetching ||
						isReferenceCategoriesLoading ||
						isCompanyLoading ||
						isCompanyFetching
					}
					categories={categories}
					company={company}
					instruments={instruments}
					profile={false}
					hideTitle={true}
					sx={{ mt: '2rem' }}
				/>
			)}
		</>
	);
};

export default GuidesIndex;
