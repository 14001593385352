import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ModalDialog from 'components/Dialog/ModalDialog';
import ScenarioForm from 'containers/Scenario/ScenarioForm';
import ProgressBar from './ProgressBar';
import ProgressStep from './ProgressStep';

import { getDate } from '../../Company/CompanyInfo/utils';
import { allValues, basicValues, businessValues, companyValues, financialValues } from './groupedValues';

export const ScenarioStepper = ({ scenario = {}, onSubmit, profile = false, passIsDirty, readonly }) => {
	const intl = useIntl();

	const [activeStep, setActiveStep] = useState(0);
	const [zeroFields, setZeroFields] = useState(null);

	const zeroFieldsArray = zeroFields ? zeroFields.split(',') : [];
	const zeroFieldsArrayLowerCased = zeroFieldsArray?.map(value => value.toLowerCase());

	const scenarioFormValues = Object.fromEntries(Object.entries(scenario).filter(([key]) => allValues.includes(key)));

	scenarioFormValues.advanceCollectionRegister =
		scenarioFormValues.advanceCollectionRegister === 0 ? 1 : scenarioFormValues.advanceCollectionRegister;
	scenarioFormValues.employeeRegister = scenarioFormValues.employeeRegister === 0 ? 1 : scenarioFormValues.employeeRegister;
	scenarioFormValues.vatRegister = scenarioFormValues.vatRegister === 0 ? 1 : scenarioFormValues.vatRegister;

	const completed = (basicValues, zeroValues) => {
		if (!zeroValues) return;

		return basicValues.reduce((acc, basic) => {
			return acc + !zeroValues.includes(basic.toLowerCase());
		}, 0);
	};

	const steps = [
		{
			index: 0,
			label: intl.formatMessage({ id: 'scenario.form.step.info' }),
			done: completed([...basicValues, ...companyValues], zeroFieldsArrayLowerCased),
			total: [...basicValues, ...companyValues].length,
		},
		{
			index: 1,
			label: intl.formatMessage({ id: 'scenario.form.step.business' }),
			done: completed(businessValues, zeroFieldsArrayLowerCased),
			total: businessValues.length,
		},
		{
			index: 2,
			label: intl.formatMessage({ id: 'scenario.form.step.financial' }),
			done: completed(financialValues, zeroFieldsArrayLowerCased),
			total: financialValues.length,
		},
	];

	return (
		<Grid container sx={{ p: 3 }}>
			<Grid item xs={12} smd={5} md={4}>
				<ProgressStep steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
			</Grid>
			<Grid item xs={12} smd={7} md={8}>
				<ScenarioForm
					scenario={scenarioFormValues}
					onSubmit={onSubmit}
					passIsDirty={passIsDirty}
					passZeroFields={zeroFields => setZeroFields(zeroFields)}
					activeStep={activeStep}
					profile={profile}
					readonly={readonly}
				/>
			</Grid>
		</Grid>
	);
};

const ScenarioDialog = ({
	formRef,
	open,
	close,
	initialLoading,
	saveLoading,
	scenario = {},
	onSubmit,
	submitForm,
	profile = false,
	isDirty,
	passIsDirty,
	readonly,
}) => {
	const intl = useIntl();

	const [activeStep, setActiveStep] = useState(0);
	const [zeroFields, setZeroFields] = useState(null);

	useEffect(() => {
		if (!open) setActiveStep(0);
	}, [open]);

	const totalValues = scenario?.totalValues;

	const zeroFieldsArray = zeroFields ? zeroFields.split(',') : [];
	const zeroFieldsArrayLowerCased = zeroFieldsArray?.map(value => value.toLowerCase());

	const totalProgress = Math.round(((totalValues - zeroFieldsArray?.length) * 100) / totalValues) || 0;

	const scenarioFormValues = Object.fromEntries(Object.entries(scenario).filter(([key]) => allValues.includes(key)));

	scenarioFormValues.advanceCollectionRegister =
		scenarioFormValues.advanceCollectionRegister === 0 ? 1 : scenarioFormValues.advanceCollectionRegister;
	scenarioFormValues.employeeRegister = scenarioFormValues.employeeRegister === 0 ? 1 : scenarioFormValues.employeeRegister;
	scenarioFormValues.vatRegister = scenarioFormValues.vatRegister === 0 ? 1 : scenarioFormValues.vatRegister;

	const completed = (basicValues, zeroValues) => {
		if (!zeroValues) return;

		return basicValues.reduce((acc, basic) => {
			return acc + !zeroValues.includes(basic.toLowerCase());
		}, 0);
	};

	const steps = [
		{
			index: 0,
			label: intl.formatMessage({ id: 'scenario.form.step.info' }),
			done: completed([...basicValues, ...companyValues], zeroFieldsArrayLowerCased),
			total: [...basicValues, ...companyValues].length,
		},
		{
			index: 1,
			label: intl.formatMessage({ id: 'scenario.form.step.business' }),
			done: completed(businessValues, zeroFieldsArrayLowerCased),
			total: businessValues.length,
		},
		{
			index: 2,
			label: intl.formatMessage({ id: 'scenario.form.step.financial' }),
			done: completed(financialValues, zeroFieldsArrayLowerCased),
			total: financialValues.length,
		},
	];

	const goBack = () => {
		if (activeStep === 0) return;
		setActiveStep(activeStep - 1);
	};

	const goForward = () => {
		if (activeStep === 2) return;
		setActiveStep(activeStep + 1);
	};

	return (
		<ModalDialog
			onClose={close}
			open={open}
			title={
				<>
					<Stack direction='row' spacing={2}>
						{intl.formatMessage({ id: 'editing.panel.profileinfo' })}
						<ProgressBar value={totalProgress} />
					</Stack>
					<Typography variant='stamp'>
						{intl.formatMessage({ id: 'timeline.updated' })}: {scenario.creator} - {getDate(scenario.created)}
					</Typography>
				</>
			}
			actions={
				<LeftRightContainer
					left={
						<Stack direction='row' spacing={2}>
							<Button
								variant='text'
								color='primary'
								disabled={activeStep < 1}
								onClick={goBack}
								startIcon={<ArrowBackIosIcon size='small' />}
							>
								{intl.formatMessage({ id: 'button.back' })}
							</Button>
							<Button
								variant='text'
								color='primary'
								disabled={activeStep > 1}
								onClick={() => setActiveStep(goForward)}
								endIcon={<ArrowForwardIosIcon size='small' />}
							>
								{intl.formatMessage({ id: 'button.continue' })}
							</Button>
						</Stack>
					}
					right={
						<Stack direction='row' spacing={2}>
							{!readonly && (
								<Button
									type='submit'
									variant='contained'
									color='success'
									disabled={saveLoading || !isDirty}
									onClick={submitForm}
									startIcon={<SaveIcon />}
								>
									{intl.formatMessage({ id: 'button.save.scenario' })}
									{saveLoading && <ButtonSpinner />}
								</Button>
							)}
						</Stack>
					}
				/>
			}
			fixedHeight={4}
			maxHeight='1025px'
		>
			{
				initialLoading ? (
					<CircularProgress size={24} />
				) : (
					<Grid container sx={{ p: 3 }} >
						<Grid item xs={12} smd={5} md={4}>
							<ProgressStep steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
						</Grid>
						<Grid item xs={12} smd={7} md={8}>
							<ScenarioForm
								ref={formRef}
								scenario={scenarioFormValues}
								onSubmit={onSubmit}
								passIsDirty={passIsDirty}
								passZeroFields={zeroFields => setZeroFields(zeroFields)}
								activeStep={activeStep}
								profile={profile}
								readonly={readonly}
							/>
						</Grid>
					</Grid >
				)}
		</ModalDialog >
	);
};

export default ScenarioDialog;
