import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { default as Box, default as Typography } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ModalDialog from 'components/Dialog/ModalDialog';
import InstrumentForm from 'containers/Admin/InstrumentsEditPage/InstrumentForm';

const InstrumentModal = ({
	open,
	close,
	instrument,
	instruments,
	title,
	onSubmit,
	saveLoading,
	providers,
	projectAims,
	projectObjects,
	projectValues,
}) => {
	const intl = useIntl();
	const formRef = useRef();

	const [isDraft, setIsDraft] = useState();
	const [isFormDirty, setIsFormDirty] = useState(false);

	const submitForm = isDraft => {
		setIsDraft(isDraft);
		return formRef.current.dispatchEvent(new CustomEvent('submit', { cancelable: true, bubbles: true, detail: isDraft }));
	};

	// Tabs
	const [tab, setTab] = useState(0);
	const handleChange = (_, tab) => setTab(tab);

	const [error, setError] = useState(false);
	const [instrumentName, setInstrumentName] = useState(null);

	useEffect(() => {
		// is needed to prevent name updating when saving and instrument.name = undefined
		if (instrument.name) {
			setInstrumentName(instrument.name);
		}
	}, [instrument.name]);

	return (
		<ModalDialog
			open={open}
			onClose={close}
			title={
				<>
					<Stack direction='row' spacing={4} alignItems='center'>
						<Box sx={{ maxWidth: '20rem' }}>
							<Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{title === 'edit'
									? instrumentName
									: title === 'copy' && intl.formatMessage({ id: `instrument.modal.title.${title}` })}
							</Typography>
						</Box>
						<Box sx={{ position: 'relative' }}>
							<Tabs value={tab} onChange={handleChange}>
								<Tab label={intl.formatMessage({ id: 'instrument.edit.form.main' })} />
								<Tab label={intl.formatMessage({ id: 'instrument.edit.form.limiters' })} />
								<Tab label={intl.formatMessage({ id: 'instrument.edit.form.ratings' })} />
								<Tab label={intl.formatMessage({ id: 'instrument.edit.form.links' })} />
								<Tab label={intl.formatMessage({ id: 'instrument.edit.form.template' })} />
							</Tabs>
							{error && (
								<ErrorOutlineOutlinedIcon
									color='error'
									sx={{
										position: 'absolute',
										top: '0.2em',
										left: '6.8rem',
									}}
								/>
							)}
						</Box>
					</Stack>
					{instrument.id && (
						<Typography sx={{ fontSize: '.875rem' }} variant='body2'>
							{instrument.id}
						</Typography>
					)}
				</>
			}
			maxWidth='xl'
			actions={
				<LeftRightContainer
					alignItems='flex-start'
					left={
						<Button variant='text' color='primary' onClick={close}>
							{intl.formatMessage({ id: 'common.close' })}
						</Button>
					}
					right={
						<Stack direction='row' spacing={2}>
							<Button type='submit' color='success' disabled={!isFormDirty} onClick={() => submitForm(true)}>
								{intl.formatMessage({ id: 'instrument.form.save.draft' })}
								{saveLoading && isDraft === true && <ButtonSpinner />}
							</Button>
							<Button type='submit' color='primary' disabled={!isFormDirty} onClick={() => submitForm(false)}>
								{intl.formatMessage({ id: 'instrument.form.save' })}
								{saveLoading && isDraft === false && <ButtonSpinner />}
							</Button>
						</Stack>
					}
				/>
			}
			fixedHeight={4}
		>
			<Box sx={{ p: 3 }}>
				<InstrumentForm
					ref={formRef}
					onSubmit={onSubmit}
					instrument={instrument}
					providers={providers}
					projectAims={projectAims}
					projectObjects={projectObjects}
					projectValues={projectValues}
					instruments={instruments}
					tab={tab}
					setError={setError}
					setIsFormDirty={setIsFormDirty}
				/>
			</Box>
		</ModalDialog>
	);
};

export default InstrumentModal;
