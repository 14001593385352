import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { CircularProgress, Divider, Grid, IconButton, Link, Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';

import Logo from 'components/Logo';
import NotificationBadge from 'components/NotificationBadge';
import MessagesItem from './MessagesItem';

import { addAdvancedCompanyQuery } from 'utils/auth/company';
import { regexProfile } from 'utils/constants/regex';
import { matchPath } from 'utils/helpers/pathMatch';

import './header.css';

const useStyles = makeStyles((theme: any) => ({
	offset: theme.mixins.toolbar,
	divider: {
		height: theme.spacing(8),
		marginLeft: theme.spacing(1.5),
		marginRight: theme.spacing(1.5),
		backgroundColor: theme.palette.textPrimary.main,
		opacity: 0.25,
	},
	noRightMargin: {
		marginLeft: theme.spacing(2),
		marginRight: 0,
	},
	noLeftMargin: {
		marginLeft: 0,
	},
	companyName: {
		fontSize: '1rem',
		fontWeight: 400,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		color: theme.palette.primary.white,
	},
	companyLoading: {
		color: theme.palette.primary.white,
	},
	customerDropdownTitle: {
		fontSize: '0.8rem',
		fontWeight: 600,
		color: theme.palette.secondary.main,
		marginRight: theme.spacing(1),
	},
	valign: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	flex: {
		display: 'flex',
		backgroundColor: '#3B2543',
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(3),
	},
	noCustomer: {
		color: '#716177',
	},
}));

type HeaderProps = {
	pathname: string;
	isContributor: boolean;
	isAdvanced: boolean;
	localCompanyName: string;
	localCompanyId: string;
	companyId: string | null;
	companyName: string | null | undefined;
	companies: any[];
	companyLoading: boolean;
	toggleExpandDrawer: any;
	openCompanies: any;
	openOwnCompanies: any;
	openDropdown: any;
	openMessagesDrawer: any;
	handleClose: any;
	isAgreement: boolean;
};

const Header: React.FC<HeaderProps> = ({
	pathname,
	isContributor,
	isAdvanced,
	localCompanyName,
	localCompanyId,
	companyId,
	companyName,
	companies,
	companyLoading,
	toggleExpandDrawer,
	openCompanies,
	openOwnCompanies,
	openDropdown,
	openMessagesDrawer,
	handleClose,
	isAgreement,
}) => {
	const location = useLocation();
	const classes = useStyles();
	const intl = useIntl();
	const theme = useTheme();
	// @ts-ignore
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));

	const staticAnalysis = matchPath('/analysis/:id', location, true);

	return (
		<AppBar position='sticky'>
			<Toolbar>
				<Grid container direction='row-reverse' justifyContent='space-between' alignItems='center' wrap='nowrap'>
					<Grid item>
						<Logo pathname={pathname} width={smDown ? '170' : '214'} slideshow={undefined} />
					</Grid>
					<Grid item>
						<Grid container wrap='nowrap'>
							<>
								{!smDown && (
									<>
										<div className={classes.valign}>
											{pathname !== '/' && !regexProfile.test(pathname) && !staticAnalysis ? (
												<Link href='/'>
													<Typography className={classes.companyName}>{localCompanyName}</Typography>
												</Link>
											) : (
												<Typography className={classes.companyName}>{localCompanyName}</Typography>
											)}
										</div>
										{companies?.length > 1 && (
											<Grid container justifyContent='center' alignItems='center'>
												<Grid item>
													<IconButton onClick={openOwnCompanies}>
														<NotificationBadge icon='dropdown' iconSize={20} noColor={undefined} />
													</IconButton>
												</Grid>
											</Grid>
										)}
									</>
								)}
								{!smDown && isAdvanced && !regexProfile.test(pathname) && !staticAnalysis && (
									<>
										<Divider
											orientation='vertical'
											flexItem
											light
											className={`${classes.divider} ${classes.noRightMargin}`}
										/>
										<div className={classes.flex}>
											<div className={classes.valign}>
												{companyLoading ? (
													<Box textAlign='center'>
														<CircularProgress size={24} className={classes.companyLoading} />
													</Box>
												) : (
													<div>
														<Typography component='span' className={classes.customerDropdownTitle}>
															{intl.formatMessage({ id: 'header.company.dropdown.customer.title' })}
														</Typography>
														<Typography component='span' className={classes.companyName}>
															{companyId === localCompanyId && !addAdvancedCompanyQuery() ? (
																<span className={classes.noCustomer}>
																	<i>
																		{intl.formatMessage({
																			id: 'header.company.dropdown.personalprofile',
																		})}
																	</i>
																</span>
															) : (
																companyName ?? (
																	<span className={classes.noCustomer}>
																		<i>
																			{intl.formatMessage({
																				id: 'header.company.dropdown.noCustomer',
																			})}
																		</i>
																	</span>
																)
															)}
														</Typography>
													</div>
												)}
											</div>
											<Grid container justifyContent='center' alignItems='center'>
												<Grid item>
													<IconButton aria-label='drop down' onClick={openCompanies}>
														<NotificationBadge icon='dropdown' iconSize={20} noColor={undefined} />
													</IconButton>
												</Grid>
											</Grid>
										</div>
									</>
								)}
							</>
							{!regexProfile.test(pathname) && !staticAnalysis && (
								<Grid container justifyContent='center' alignItems='center'>
									<Grid item>
										<IconButton aria-label='menu' onClick={smDown ? toggleExpandDrawer : openDropdown}>
											<NotificationBadge icon='menu' iconSize={20} noColor={undefined} />
										</IconButton>
									</Grid>
								</Grid>
							)}
							<MessagesItem {...{ companyId, isAgreement, pathname, regexProfile, staticAnalysis, openMessagesDrawer }} />
							{isContributor && !smDown && !regexProfile.test(pathname) && !staticAnalysis && (
								<Divider orientation='vertical' flexItem light className={classes.divider} />
							)}
							{isContributor && !smDown && !regexProfile.test(pathname) && !staticAnalysis && (
								<Grid container justifyContent='center' alignItems='center'>
									<Grid item>
										<IconButton aria-label='slideshow' onClick={() => handleClose('slideshow')}>
											<NotificationBadge icon='slideshow' iconSize={20} noColor={undefined} />
										</IconButton>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
