import { calculateExpenseAmount } from 'containers/Projects/Project/Costs/ProjectCostForm/ProjectCostForm';
import { Project, ProjectCost, ProjectTask, projectStateEnum } from 'types/dataTypes';
import { findItemByIdWithField } from './slices';

export const projectState: { draft: projectStateEnum; published: projectStateEnum } = {
	draft: 'Draft',
	published: 'Published',
};

export const taskSelector = (tasks: Array<ProjectTask>, taskId: string | null) => {
	if (!tasks) return null;

	const task = tasks?.find(item => item.projectTaskId === taskId);

	return task;
};

export const costSelector = (costs: Array<ProjectCost>, costId: string | null) => {
	if (!costs) return null;

	const cost = costs.find(item => item.id === costId);

	return cost;
};

export const costTaskSelector = (costs: Array<ProjectCost>, taskId: string | null) => {
	if (!costs) return null;

	return costs.filter(cost => {
		if (!cost || cost.projectTaskId !== taskId) return false;

		return true;
	});
};

export const isProjectDraft = (projects: Array<Project>, projectId: string | null) => {
	if (!projects || !projectId) return null;

	const project = findItemByIdWithField(projects, 'projectId', projectId);
	// eslint-disable-next-line eqeqeq
	return project?.projectState == 'Draft';
};

export const calculateCostsTotalSum = (costs: Array<ProjectCost>, field: string | null) => {
	if (!costs) return null;

	let sum = 0;

	costs?.forEach(cost => {
		// @ts-ignore
		const fieldValue = cost[field];
		// @ts-ignore
		const expensesAmount = calculateExpenseAmount(cost.salaryExpenseType, cost.salaryExpense, fieldValue);

		sum = sum + fieldValue + expensesAmount;
	});

	return sum;
};

export const sort = (obj: any, valSelector: any) => {
	const sortedEntries = Object.values(obj).sort((a, b) =>
		valSelector(a) > valSelector(b) ? 1 : valSelector(a) < valSelector(b) ? -1 : 0
	);

	return sortedEntries;
};
