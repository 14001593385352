import { isEmpty, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, BoxProps, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

import { RfpRequestItem } from 'types/dataTypes';
import FormatCurrency from '../../../../components/Formatters/FormatCurrency';

type Props = BoxProps & {
	rfpRequestItems: RfpRequestItem[] | null | undefined;
};

const ReadRfpRequestItemList: React.FC<Props> = ({ rfpRequestItems, ...otherProps }) => {
	return (
		<Box {...otherProps}>
			<Typography variant='h4'>
				<FormattedMessage id='rfpRequest.rfpRequestItems.label' />
			</Typography>
			<List>
				{isEmpty(rfpRequestItems) && (
					<Typography variant='lightText'>
						<FormattedMessage id='rfpRequestItems.empty' />
					</Typography>
				)}
				{map(rfpRequestItems, rfpRequestItem => (
					<ListItem
						key={rfpRequestItem.rfpRequestItemId}
						sx={{
							mb: '1rem',
							'@media print': {
								p: 0,
							},
						}}
					>
						<ListItemText
							sx={{
								'& .MuiListItemText-secondary': {
									color: '#303F5A',
								},
								'@media print': {
									'& .MuiListItemText-secondary': {
										fontSize: '1rem',
									},
								},
							}}
							primary={
								<Stack direction='column'>
									<Box sx={{ flexGrow: 1 }}>{rfpRequestItem.requestItemTitle}</Box>
									<Typography variant='subtitle2' component='span' sx={{ mr: '0.5rem', mb: '.5rem' }}>
										{rfpRequestItem.requestItemCategory}
									</Typography>
								</Stack>
							}
							secondary={
								<>
									{rfpRequestItem.requestItemDescription}
									{rfpRequestItem.budget && (
										<Typography component='span' sx={{ display: 'block', mt: '.5rem' }}>
											<Typography variant='subtitle2' component='span' sx={{ mr: '0.5rem' }}>
												<FormattedMessage id='rfpProposal.projectBudget.label' />
											</Typography>
											<FormatCurrency value={rfpRequestItem.budget} />
										</Typography>
									)}
								</>
							}
						/>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default ReadRfpRequestItemList;
