import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';

import { getInitials } from 'utils/helpers/getInitials';
import Spinner from 'components/Spinner/Spinner';

const TeamMemberAvatar = ({ avatar = '', firstname = '', lastname = '', size = 50, circular = false, isForm = false, spinnerSize = 16 }) => {
    const [avatarImage, setAvatarImage] = useState(avatar);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const getImage = async url => {
        if (!avatar) {
            setAvatarImage(null);
            return;
        }

        if (!url.startsWith('http:') && !url.startsWith('https:')) { // not a uri
            setAvatarImage(avatar);
            setIsDataLoaded(true);

            return;
        }

        try {
            const response = await fetch(url, { method: 'GET' });
            const text = await response.text();

            setAvatarImage(text);
        } catch {
            setAvatarImage(null);
        }

        setIsDataLoaded(true);
    };

    useEffect(() => {
        getImage(avatar);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatar]);

    if (avatar && !isDataLoaded) return <Spinner size={spinnerSize} />;

    return (
        avatarImage && isForm
            ? <img alt='Avatar' src={avatarImage} style={{ maxWidth: '100%', borderRadius: '50%' }} />
            : avatarImage && !isForm
                ? <img src={avatarImage} alt='Avatar' width={size} height={size} style={{ borderRadius: circular ? size / 2 : 5 }} />
                : <Avatar id='qwert' variant={circular ? 'circular' : 'rounded'} sx={{ width: size, height: size, fontSize: 0.5 * size }}>{getInitials(`${firstname} ${lastname}`)}</Avatar>
    );
};

export default TeamMemberAvatar;