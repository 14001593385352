import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { compact } from 'lodash';

import { collaborationApi } from './api';
import { separator, getLinkSplitted } from './utils';
import { isAdvancedToken } from 'utils/auth/company';

export const name = 'collaboration';

export const initialState = {
	conversations: {
		isLoaded: false,
		loading: false,
		error: null,
		data: [],
		filteredBy: null,
	},
	resolveConversationLoading: false,
	createConversationLoading: false,
	removeConversationLoading: false,
	createCommentLoading: false,
	removeCommentLoading: false,
	isDrawerOpen: false,
	links: [], // used to populate dynamically and filter conversations on particular page
	temporaryLink: null, // used to create a new conversation with comment
};

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData: () => initialState,
		toggleDrawer: state => {
			state.isDrawerOpen = !state.isDrawerOpen;
		},
		createTemporaryLink: (state, action) => {
			state.temporaryLink = action.payload;
		},
		resetTemporaryLink: state => {
			state.temporaryLink = initialState.temporaryLink;
		},
		collectLinks: (state, action) => {
			state.links.push(action.payload);
		},
		resetLinks: state => {
			state.links = initialState.links;
		},
		setFilteredBy: (state, action) => {
			state.conversations.filteredBy = action.payload;
		},
		resetFilteredBy: state => {
			state.conversations.filteredBy = initialState.conversations.filteredBy;
		},
		// conversations
		loadConversations: state => {
			state.conversations.loading = true;
			state.conversations.error = false;
		},
		loadConversationsSuccess: (state, action) => {
			state.conversations.loading = false;
			state.conversations.error = null;
			state.conversations.data = action.payload;
			state.conversations.isLoaded = true;
		},
		loadConversationsError: (state, action) => {
			state.conversations.loading = false;
			state.conversations.error = false;
			state.conversations.data = action.payload;
			state.conversations.isLoaded = true;
		},
		createConversation: state => {
			state.createConversationLoading = true;
		},
		createConversationError: (state /*, action */) => {
			state.createConversationLoading = false;
			// TODO: add error?!
		},
		createConversationSuccess: (state, action) => {
			state.createConversationLoading = false;
			state.conversations.data.unshift(action.payload);
		},
		resolveConversation: (state, action) => {
			state.resolveConversationLoading = action.payload;
		},
		resolveConversationError: (state /*, action */) => {
			state.resolveConversationLoading = false;
			// TODO: add error?!
		},
		resolveConversationSuccess: (state, action) => {
			state.resolveConversationLoading = false;
			state.conversations.data = state.conversations.data.filter(item => item.conversationId !== action.payload);
		},
		removeConversation: (state, action) => {
			state.removeConversationLoading = action.payload;
		},
		removeConversationError: (state /*, action */) => {
			state.removeConversationLoading = false;
			// TODO: add error?!
		},
		removeConversationSuccess: (state, action) => {
			state.removeConversationLoading = false;
			state.conversations.data = state.conversations.data.filter(item => item.conversationId !== action.payload);
		},
		updateConversationsSuccess: (state, action) => {
			state.conversations.data = state.conversations.data.map(item => {
				const updatedItem = action.payload.find(({ conversationId }) => conversationId === item.conversationId);

				return updatedItem ?? item;
			});
		},
		markConversationAsReadSuccess: (state, action) => {
			state.conversations.data.find(item => item.conversationId === action.payload).isRead = true;
		},
		// comments
		loadComments: (state, action) => {
			const conversationIndex = state.conversations.data.findIndex(item => item.conversationId === action.payload);

			if (conversationIndex < 0) return;

			let item = state.conversations.data[conversationIndex];
			item.comments = {
				isLoaded: false,
				loading: true,
				data: [],
				error: null,
			};

			state.conversations.data[conversationIndex] = item;
		},
		loadCommentsSuccess: (state, action) => {
			const conversationIndex = state.conversations.data.findIndex(item => item.conversationId === action.payload.conversationId);

			if (conversationIndex < 0) return;

			let item = state.conversations.data[conversationIndex];
			item.comments = {
				isLoaded: true,
				loading: false,
				data: action.payload.data,
				error: null,
			};

			state.conversations.data[conversationIndex] = item;
		},
		loadCommentsError: (state, action) => {
			const conversationIndex = state.conversations.data.findIndex(item => item.conversationId === action.payload.conversationId);

			if (conversationIndex < 0) return;

			let item = state.conversations.data[conversationIndex];
			item.comments = {
				isLoaded: true,
				loading: false,
				data: [],
				error: action.payload,
			};

			state.conversations.data[conversationIndex] = item;
		},
		createComment: (state, action) => {
			state.createCommentLoading = action.payload;
		},
		createCommentError: (state /*, action */) => {
			state.createCommentLoading = false;
			// TODO: add error?!
		},
		createCommentSuccess: (state, action) => {
			state.createCommentLoading = false;

			const conversationIndex = state.conversations.data.findIndex(item => item.conversationId === action.payload.conversationId);

			state.conversations.data[conversationIndex].comments.data.unshift(action.payload);
			state.conversations.data[conversationIndex].commentsCount = state.conversations.data[conversationIndex].commentsCount + 1;
		},
		removeComment: (state, action) => {
			state.removeCommentLoading = action.payload.commentId;
		},
		removeCommentError: (state /*, action */) => {
			state.removeCommentLoading = false;
			// TODO: add error?!
		},
		removeCommentSuccess: (state, action) => {
			state.removeCommentLoading = false;

			const conversationIndex = state.conversations.data.findIndex(item => item.conversationId === action.payload.conversationId);

			state.conversations.data[conversationIndex].comments.data = state.conversations.data[conversationIndex].comments.data.filter(
				({ commentId }) => commentId !== action.payload.commentId
			);
		},
	},
});

export const { reducer } = slice;

export const resetCollaboration = () => dispatch => dispatch(slice.actions.resetData());

export const toggleCollaborationDrawer = () => dispatch => dispatch(slice.actions.toggleDrawer());

export const collectLinks = link => dispatch => dispatch(slice.actions.collectLinks(link));

export const resetLinks = () => dispatch => dispatch(slice.actions.resetLinks());

export const createTemporaryLink = link => dispatch => {
	const splittedLink = getLinkSplitted(link?.link);

	dispatch(slice.actions.createTemporaryLink({ ...link, splitted: splittedLink }));
};

export const resetTemporaryLink = () => dispatch => dispatch(slice.actions.resetTemporaryLink());

export const filterConversations = link => dispatch => dispatch(slice.actions.setFilteredBy(link));

export const unfilterConversations = () => dispatch => dispatch(slice.actions.resetFilteredBy());

export const getConversations = () => async dispatch => {
	try {
		dispatch(slice.actions.loadConversations());

		const data = await collaborationApi.getAllConversations();

		dispatch(slice.actions.loadConversationsSuccess(data));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadConversationsError(error));

		return false;
	}
};

// conversations
export const getConversationsByCompanyId =
	(isFreemium = false) =>
	async dispatch => {
		try {
			if (isFreemium) return true;

			dispatch(slice.actions.loadConversations());

			const data = await collaborationApi.getCompanyConversations();

			dispatch(slice.actions.loadConversationsSuccess(data));

			return true;
		} catch (error) {
			dispatch(slice.actions.loadConversationsError(error));

			return false;
		}
	};

export const getConversationById = conversationId => async dispatch => {
	try {
		dispatch(slice.actions.loadConversations());

		const data = await collaborationApi.getCompanyConversationById(conversationId);

		dispatch(slice.actions.loadConversationsSuccess(data));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadConversationsError(error));

		return false;
	}
};

export const createConversation = (link, content, linkTitle, companyName) => async dispatch => {
	try {
		dispatch(slice.actions.createConversation());

		const conversation = await collaborationApi.createConversation({ link, content, linkTitle, companyName });

		dispatch(slice.actions.createConversationSuccess(conversation));

		return true;
	} catch (error) {
		dispatch(slice.actions.createConversationError(error));

		return false;
	}
};

export const markConversationAsResolved = id => async dispatch => {
	try {
		dispatch(slice.actions.resolveConversation(id));

		await collaborationApi.markConversationAsResolved(id);

		dispatch(slice.actions.resolveConversationSuccess(id));

		return true;
	} catch (error) {
		dispatch(slice.actions.resolveConversationError(error));

		return false;
	}
};

export const removeConversation = id => async dispatch => {
	try {
		dispatch(slice.actions.removeConversation(id));

		await collaborationApi.deleteConversation(id);

		dispatch(slice.actions.removeConversationSuccess(id));

		return true;
	} catch (error) {
		dispatch(slice.actions.removeConversationError(error));

		return false;
	}
};

// remove conversation when project (+taks, + costs), task (+costs) or cost was removed
export const removeRelatedConversations = id => async (dispatch, getState) => {
	try {
		const state = getState();

		const conversationsToRemove = state?.collaboration?.conversations?.data?.filter(({ link }) =>
			getLinkSplitted(link, '/').includes(id)
		);

		if (!conversationsToRemove || conversationsToRemove.length === 0) return;

		await Promise.all(
			conversationsToRemove.map(async ({ conversationId }) => {
				dispatch(slice.actions.removeConversation(conversationId));

				return await collaborationApi.deleteConversation(conversationId);
			})
		);

		conversationsToRemove.map(({ conversationId }) => dispatch(slice.actions.removeConversationSuccess(conversationId)));

		toast.success(<FormattedMessage id='collaboration.remove.related.conversations.succeded' />);

		return true;
	} catch (error) {
		dispatch(slice.actions.removeConversationError(error));
		toast.success(<FormattedMessage id='collaboration.remove.related.conversations.failed' />);

		return false;
	}
};

// comments
export const getComments = (conversationId, isRead) => async dispatch => {
	try {
		dispatch(slice.actions.loadComments(conversationId));

		const data = await collaborationApi.getComments(conversationId);

		dispatch(slice.actions.loadCommentsSuccess({ data, conversationId }));

		if (isRead) return true;

		await collaborationApi.markConversationAsRead(conversationId);

		dispatch(slice.actions.markConversationAsReadSuccess(conversationId));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadCommentsError(error));

		return false;
	}
};

export const createComment = (conversationId, comment) => async dispatch => {
	try {
		dispatch(slice.actions.createComment(conversationId));

		const data = await collaborationApi.createComment(conversationId, { comment });

		dispatch(slice.actions.createCommentSuccess(data));

		return true;
	} catch (error) {
		dispatch(slice.actions.createCommentError(error));

		return false;
	}
};

export const removeComment = (conversationId, commentId) => async dispatch => {
	try {
		dispatch(slice.actions.removeComment({ conversationId, commentId }));

		await collaborationApi.deleteComment(conversationId, commentId);

		dispatch(slice.actions.removeCommentSuccess({ conversationId, commentId }));

		return true;
	} catch (error) {
		dispatch(slice.actions.removeCommentError(error));

		return false;
	}
};

// linkTitle updating when project, task or cost is modified
export const updateConversationLink = (id, name) => async (dispatch, getState) => {
	try {
		// add some loading if necessary --> dispatch(slice.actions.updateConversations());

		const state = getState();
		const conversationsToUpdate = state?.collaboration?.conversations?.data?.filter(({ link }) =>
			getLinkSplitted(link, '/').includes(id)
		);

		if (!conversationsToUpdate || conversationsToUpdate.length === 0) return;

		const getUpdatedConversation = conversation => {
			const linkSplitted = getLinkSplitted(conversation.link, '/');
			const matchIndex = linkSplitted.findIndex(item => item === id);

			const linkTitleSplitted = getLinkSplitted(conversation.linkTitle, separator);

			if (linkTitleSplitted[matchIndex] === name) return;

			linkTitleSplitted[matchIndex] = name;

			const updatedConversation = {
				...conversation,
				comments: conversation.comments,
				linkTitle: linkTitleSplitted.join(separator),
			};

			return updatedConversation;
		};

		const updatedConversations = conversationsToUpdate.map(item => getUpdatedConversation(item));

		if (compact(updatedConversations).length === 0) return;

		await Promise.all(
			updatedConversations.map(async conversation => {
				return await collaborationApi.updateConversation(conversation.conversationId, conversation);
			})
		);

		dispatch(slice.actions.updateConversationsSuccess(updatedConversations));

		toast.success(<FormattedMessage id='collaboration.links.update.succeeded' />);

		return true;
	} catch (error) {
		// add some loading if necessary -->  dispatch(slice.actions.updateConversationsError(error));
		toast.error(<FormattedMessage id='collaboration.links.update.failed' />);

		return false;
	}
};

export const isCollaborationActive = () => {
	return !isAdvancedToken();
};

export default slice;
