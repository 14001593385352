import Box from '@mui/material/Box';
import { styled } from '@mui/styles';

const StyledBox = styled(Box)(({ shadow }) => ({
	background: '#fff',
	display: 'flex',
	flexDirection: 'column',
	overflow: 'hidden',
	boxShadow: shadow,
}));

const BoxContent = styled(Box)(({ theme }) => ({
	padding: theme.spacing(3),
}));

const BoxFooter = styled(Box)(({ theme }) => ({
	padding: theme.spacing(3),
	paddingTop: 0,
	paddingBottom: '1rem',
	backgroundColor: theme.palette.secondary.footerGrey,
}));

const BoxContainer = ({ shadow = true, children, footer = false, show = true, ...other }) => {
	if (!show) return null;

	return (
		<StyledBox shadow={shadow ? 'rgba(0, 0, 0, 0.16) 5px 1px 6px' : 'none'} {...other}>
			<BoxContent>{children}</BoxContent>
			{footer && <BoxFooter>{footer}</BoxFooter>}
		</StyledBox>
	);
};

export default BoxContainer;
