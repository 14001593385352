import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { Divider, Typography } from '@mui/material';

import { getUser } from 'utils/auth/user';
import { useChangeCurrentPasswordMutation } from 'containers/Account/passwordV2Api';

import ContentContainer from 'components/Containers/ContentContainer';
import MainTitle from 'components/Titles/MainTitle';
import Subtitle from 'components/Titles/Subtitle';
import ChangePasswordForm from 'views/Account/ChangePassword/ChangePasswordForm';

type FieldValues = {
	id: string | null;
	username: string | null;
	password: string | null;
	newpassword: string | null;
	confirmpassword: string | null;
};

const useStyles = makeStyles((theme: any) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	itemKey: {
		fontWeight: 600,
		color: theme.palette.primary.main,
		paddingRight: '0.5rem',
	},
	divider: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	title: {
		fontSize: '1.5rem',
		marginBottom: '1.5rem',
	},
}));

const UserPage: React.FC = () => {
	const classes = useStyles();

	const [changeCurrentPassword, { isLoading }] = useChangeCurrentPasswordMutation();

	const localStorageUser = getUser();

	const onSubmit = React.useCallback(
		async (fieldValues: FieldValues) => {
			if (fieldValues?.newpassword !== fieldValues?.confirmpassword) {
				toast.error(<FormattedMessage id='message.passwordsNotMatch' />);
				return;
			}
			try {
				await changeCurrentPassword(fieldValues).unwrap();
				toast.success(<FormattedMessage id='change.password.success.message' />);
			} catch (error: any) {
				toast.error(
					Array.isArray(error?.response?.data) ? (
						<FormattedMessage id={`error.code.${error?.response?.data[0]?.code}`} />
					) : (
						<FormattedMessage id={`error.code.${error?.response?.data}`} />
					)
				);
			}
		},
		[changeCurrentPassword]
	);

	return (
		<>
			{/* @ts-ignore */}
			<ContentContainer>
				<MainTitle>
					<FormattedMessage id='common.own.information' />
				</MainTitle>
				<div className={classes.container}>
					<Typography component='p' className={classes.itemKey}>
						<FormattedMessage id='common.user.id' />
					</Typography>
					<Typography component='p' color='primary'>
						{localStorageUser?.email ?? '---//---'}
					</Typography>
				</div>
				<Divider light className={classes.divider} />
				{/* @ts-ignore */}
				<Subtitle>
					<FormattedMessage id='userpage.change.password' />:
				</Subtitle>
				<ChangePasswordForm loading={isLoading} onSubmit={onSubmit} />
			</ContentContainer>
		</>
	);
};

export default UserPage;
