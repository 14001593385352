import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Spinner from 'components/Spinner/Spinner';
import FormatCurrency from 'components/Formatters/FormatCurrency';

const DeminimisCard = ({ loading, title, value, subtitle }) => {

    return (
        <Box
            display='flex'
            sx={{
                alignvalues: 'center',
                width: '100%',
                height: '100%',
                minHeight: '91.75px',
                bgcolor: 'primary.white',
                p: 2,
            }}
        >
            {loading
                ? <Spinner size={16} />
                : <Box sx={{ overflow: 'hidden' }}>
                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 500, color: 'textPrimary.main', textTransform: 'uppercase', /* textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' */ }}>
                        {title}
                    </Typography>
                    <Typography sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
                        <FormatCurrency value={value} />
                    </Typography>
                    <Typography sx={{ fontSize: '0.7rem', fontWeight: 500, color: 'textPrimary.main', /* textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' */ }}>
                        {subtitle}
                    </Typography>
                </Box>
            }
        </Box>
    );
};

export default DeminimisCard;