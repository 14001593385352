import axios from 'utils/axios';

import { addParam, buildPlainUri, validateHttpStatus } from 'utils/helpers/uriHelper';

const baseUrl = 'admin';

class UserCompanyAdminApi {
	async getLicensesByStatusAndType(licenseActiveStatus, licenseType) {
		let licenseTypeParam = licenseActiveStatus !== 'all' && licenseType !== 'all' ? `&licenseType=${licenseType}` : '';

		const url = buildPlainUri(baseUrl, `licenses?activeStatus=${licenseActiveStatus}${licenseTypeParam}`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getCompanyData(id) {
		const url = buildPlainUri('company/admin', `${id}`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getCompanyDetails(id) {
		const url = buildPlainUri(baseUrl, `company/${id}`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveCompanyLicense(id, license, silently) {
		let url = buildPlainUri(baseUrl, `license/${id}`);
		if (silently) {
			url = addParam(url, 'silent', true);
		}

		const response = await axios.post(url, license, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveCompanyUser(id, user) {
		const url = buildPlainUri(baseUrl, `user/${id}`);

		const response = await axios.post(url, user, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async terminateCompanyLicense(id) {
		const url = buildPlainUri(baseUrl, `license/${id}/terminate`);

		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getUserDetails(id) {
		const url = buildPlainUri(baseUrl, `users/${id}`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getUsersActiveStatus(userActiveStatus) {
		const url = buildPlainUri(baseUrl, `users/${userActiveStatus}`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async deactivateUser(user) {
		const url = buildPlainUri(baseUrl, 'user/deactivate');

		const response = await axios.post(url, { 'Username': user?.userName }, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async deleteCompanyRoles(companyRoles) {
		const url = buildPlainUri(baseUrl, 'user/companyroles/delete');

		const response = await axios.post(url, companyRoles, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveCompanyNotes(companyId, notes) {
		const url = buildPlainUri('company', `notes/${companyId}`);

		const response = await axios.post(url, notes, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getLicenseConstantData() {
		const url = buildPlainUri(baseUrl, 'license/constants');

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async sendActivationLinkToUser(username) {
		const url = buildPlainUri(baseUrl, `user/reactivate/${username}`);

		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const userCompanyAdminApi = new UserCompanyAdminApi();
