import { Alert, AlertTitle, Box, Skeleton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { getCompanyId } from 'utils/auth/company';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchScenarioQuery, useUpsertScenarioMutation } from 'containers/Scenario/scenarioV2Api';

import Button from 'components/Button/Button';
import ScenarioForm from 'containers/Scenario/ScenarioForm/ScenarioForm';
import { isNil } from 'lodash';

type Props = {
	hideOnPrevStep: false | RegExpMatchArray | null;
	onPrevStepClick: () => void;
	onNextStepClick: () => void;
	passIsDirty: (isDirty: boolean, dirtyFields: any) => void;
	passZeroFields: (zeroFields: string[]) => void;
};

const OnboardingStep2 = React.forwardRef<any, Props>(
	({ hideOnPrevStep, onNextStepClick, onPrevStepClick, passIsDirty, passZeroFields, ...otherProps }, ref) => {
		const formRef = React.useRef<HTMLFormElement>(null);

		const companyId = getCompanyId();

		const {
			data: company,
			isLoading: isCompanyLoading,
			isFetching: isCompanyFetching,
		} = useFetchCompanyQuery({ companyId }, { skip: isNil(companyId) });

		const {
			data: scenario,
			isLoading,
			isFetching,
			isError: isFetchError,
		} = useFetchScenarioQuery(
			{ companyId, businessId: company?.businessId },
			{ skip: !companyId || isCompanyLoading || isCompanyFetching }
		);

		const [upsertScenario, { isLoading: isUpsertLoading, isError: isUpsertError }] = useUpsertScenarioMutation();

		const handleFormSubmit = React.useCallback(() => {
			formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}, [formRef]);

		const loading = isLoading || isFetching || isUpsertLoading;

		return (
			<Box {...otherProps} ref={ref} sx={{ p: '2rem', width: '100%', maxWidth: { xs: '100%', md: '60rem' } }}>
				<Stack spacing={2} flexDirection='column' useFlexGap>
					<Typography sx={{ fontFamily: 'Aleo', fontWeight: 700, fontSize: '1.429rem', lineHeight: 1.3 }}>
						<FormattedMessage id='onboarding.step2.title' />
					</Typography>
					{isFetchError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchScenario.error.message' />
						</Alert>
					)}
					{isUpsertError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='mutation.error.title' />
							</AlertTitle>
							<FormattedMessage id='upsertScenario.error.message' />
						</Alert>
					)}
					<Typography variant='label'>
						<FormattedMessage id='onboarding.step2.description' />
					</Typography>
					{isLoading ? (
						<Skeleton height={600} variant='rectangular' />
					) : (
						<ScenarioForm
							// @ts-ignore
							ref={formRef}
							// @ts-ignore
							scenario={scenario}
							// @ts-ignore
							activeStep={0}
							// @ts-ignore
							onSubmit={async (fieldValues: any) => {
								await upsertScenario({ companyId, scenario: fieldValues });
								onNextStepClick();
							}}
							// @ts-ignore
							passIsDirty={passIsDirty}
							// @ts-ignore
							passZeroFields={passZeroFields}
						/>
					)}
					{isLoading ? (
						<Skeleton height={40} variant='rectangular' />
					) : (
						<Stack direction='row' spacing={2}>
							{/* @ts-ignore */}
							<Button
								color='primary'
								variant='outlined'
								disabled={hideOnPrevStep?.valueOf() ? true : false}
								sx={{ p: '0.64286rem 1.21429rem', lineHeight: '1.14286rem', fontSize: '1rem' }}
								onClick={onPrevStepClick}
								fullWidth
							>
								<FormattedMessage id='onboarding.step.prev' />
							</Button>
							{/* @ts-ignore */}
							<Button
								color='primary'
								variant='contained'
								sx={{ p: '0.64286rem 1.21429rem', lineHeight: '1.14286rem', fontSize: '1rem' }}
								loading={loading}
								onClick={handleFormSubmit}
								fullWidth
							>
								<FormattedMessage id='onboarding.step.next' />
							</Button>
						</Stack>
					)}
				</Stack>
			</Box>
		);
	}
);

export default OnboardingStep2;
