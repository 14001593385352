import axios from 'utils/axios';
import { buildUri, validateHttpStatus } from 'utils/helpers/uriHelper';

const baseUrl = 'financialplan';

class FinancialPlanApi {
    async getFinancialPlan() {
        const url = buildUri(baseUrl, 'document');
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getFinancialPlanPublished() {
        const url = buildUri(baseUrl, 'document/published');
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveFinancialPlanData(data) {
        const url = buildUri(baseUrl, 'document');
        const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async publishFinancialPlanData(data) {
        const url = buildUri(baseUrl, 'document/publish');
        const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getInstruments() {
        const url = buildUri(baseUrl, 'instruments');
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }
}

export const financialPlanApi = new FinancialPlanApi();
