import React, { useState } from 'react';
import { useTheme } from '@mui/styles';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const scoreRGBColors = {
    low: '133,36,68',
    med: '197,159,57',
    high: '7,120,113',
};


const scoreLevel = {
    low: 'low',
    med: 'med',
    high: 'high',
};

const Pill = ({ title, score, highText, mediumText, lowText, getScoreLevel, disabled }) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const level = getScoreLevel(score);
    const rgbaPillFillColor = disabled ? theme.palette.pill.secondary : `rgba(${scoreRGBColors[level]},1)`;
    const baseGradient = `linear-gradient(0deg, ${rgbaPillFillColor} ${score}%, ${theme.palette.pill.main} ${score}%)`;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const displayScore = score === undefined || disabled ? '-' : score;

    return (
        <Box sx={{
            width: '6rem',
            color: theme.palette.primary.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            <Box>{displayScore}</Box>
            <Box sx={{
                height: '9rem',
                width: '4.5rem',
                borderRadius: '80px',
                backgroundColor: theme.palette.pill.main,
                opacity: disabled ? '0.8' : 1,
                '&:hover': {
                    opacity: '0.8',
                    cursor: !disabled && 'pointer',
                },
            }}
                style={{ background: baseGradient }}
                onClick={handleClickOpen}
            >
                &nbsp;
            </Box>
            <Box sx={{
                textAlign: 'center',
                textTransform: 'uppercase',
                fontWeight: 600,
                marginTop: '0.5rem',
            }}>
                {title}
            </Box>
            {!disabled &&
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography sx={{
                        p: 2,
                        maxWidth: '35rem',
                    }}>
                        {level === scoreLevel.high ? highText : level === scoreLevel.med ? mediumText : lowText} <strong>{displayScore}</strong>.
                    </Typography>
                </Popover>}
        </Box>
    );
};

export default Pill;
