import { isEmpty } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Button, IconButton } from '@mui/material';

import { openDrawer } from 'containers/Marketplace/drawerSlice';
import { RfpRequest } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import CanUpsertRfpProposal from 'containers/Marketplace/proposal/CanUpsertRfpProposal';
import CanUpsertRfpRequest from 'containers/Marketplace/request/CanUpsertRfpRequest';

type Props = {
	rfpRequest: Partial<RfpRequest> | undefined;
	onClick: () => void;
	active?: boolean;
};

const RfpRequestProposals: React.FC<Props> = ({ rfpRequest, onClick, active, ...otherProps }) => {
	const dispatch = useDispatch();
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	if (!rfpRequest) return null;

	if (rfpRequest.companyId === companyId && rfpRequest.status === 'Published' && !isEmpty(rfpRequest.proposals)) {
		if (rfpRequest.proposals?.length === 1) {
			return (
				<Button
					size='small'
					variant='text'
					onClick={onClick}
					endIcon={active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				>
					<FormattedMessage id='rfp.list.proposals.single' values={{ proposalCount: rfpRequest.proposals?.length }} />
				</Button>
			);
		}

		return (
			<Button size='small' variant='text' onClick={onClick} endIcon={active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
				<FormattedMessage id='rfp.list.proposals.count' values={{ proposalCount: rfpRequest.proposals?.length }} />
			</Button>
		);
	}

	return (
		<>
			<CanUpsertRfpRequest rfpRequest={rfpRequest}>
				{canUpsert =>
					canUpsert && (
						<IconButton
							size='small'
							onClick={() =>
								dispatch(
									openDrawer({
										name: 'UpsertRfpRequestDrawer',
										data: { requestId: rfpRequest.rfpRequestId, published: rfpRequest?.status === 'Published' },
									})
								)
							}
						>
							<EditIcon />
						</IconButton>
					)
				}
			</CanUpsertRfpRequest>
			<CanUpsertRfpProposal rfpRequest={rfpRequest} rfpProposal={undefined}>
				{canUpsert =>
					canUpsert && (
						<Button
							endIcon={<PostAddIcon />}
							size='small'
							onClick={() =>
								dispatch(
									openDrawer({
										name: 'UpsertRfpProposalDrawer',
										data: { requestId: rfpRequest.rfpRequestId, published: rfpRequest?.status === 'Published' },
									})
								)
							}
						>
							<FormattedMessage id='marketplace.rfp.proposal.create' />
						</Button>
					)
				}
			</CanUpsertRfpProposal>
		</>
	);
};

export default RfpRequestProposals;
