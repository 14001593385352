import React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

const PrintTags = ({ businessId, tagsArray }) => {
    return (
        <Stack direction='row' spacing={1.5} my={1}>
            {businessId &&
                <Chip
                    label={businessId}
                    size='small'
                    sx={{
                        color: 'seconary.grey700',
                    }}
                />
            }
            {tagsArray.map((item, index) =>
                <Chip
                    key={index}
                    label={item}
                    size='small'
                    color='primary'
                />
            )}
        </Stack>
    );
};

export default PrintTags;
