import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PsychologyIcon from '@mui/icons-material/Psychology';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { Box, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { toast } from 'react-toastify';
import { generateUid } from 'utils/helpers/uid';
import useWindowDimensions from 'utils/misc/window';

const useStyles = makeStyles((theme: any) => ({
	root: {
		overflow: 'auto',
	},
	name: {
		fontSize: '0.85rem',
		fontWeight: 400,
		color: theme.palette.primary.main,
	},
	incoming: {
		width: '75%',
		float: 'left',
		borderRadius: theme.spacing(1),
		margin: theme.spacing(1),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: 0,
	},
	outgoing: {
		width: '75%',
		float: 'right',
		borderRadius: theme.spacing(1),
		margin: theme.spacing(1),
		marginLeft: 0,
		backgroundColor: theme.palette.primary.secondary,
	},
	userAvatar: {
		color: theme.palette.primary.beige,
		backgroundColor: theme.palette.primary.white,
	},
	botAvatar: {
		color: theme.palette.primary.white,
		backgroundColor: theme.palette.primary.main,
	},
	messageText: {
		whiteSpace: 'pre-wrap',
	},
	messageFooter: {
		display: 'block',
		marginTop: '0.5rem',
	},
}));

const ChatFlow = ({ chat, loading, aiLoading, sx, messages }: any) => {
	const classes = useStyles();
	const intl = useIntl();
	const { height } = useWindowDimensions();

	const messagesEndRef = useRef(null);
	const chatMessages = !chat?.chatContent || chat?.chatContent.length === 0 ? messages : chat?.chatContent;

	const scrollToBottom = () => {
		// @ts-ignore
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [chatMessages]);

	if (loading) {
		return (
			<Stack direction='column' sx={{ p: '1rem' }}>
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 25 }} />
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 25 }} />
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
				<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			</Stack>
		);
	}

	const copyText = (text: string) => {
		navigator.clipboard.writeText(text);
		toast.info(intl.formatMessage({ id: 'app.toast.copied', defaultMessage: 'Copied!' }));
	};

	const MessageItem = ({ role, content }: any) => {
		const isAssistant = role === 'assistant' || role?.label === 'assistant';
		const className = isAssistant ? classes.incoming : classes.outgoing;

		return (
			<ListItem sx={{ ...sx }} key={generateUid()} alignItems='flex-start' className={className}>
				<ListItemAvatar>
					{isAssistant ? (
						<PsychologyIcon color='primary' sx={{ fontSize: '3rem' }} />
					) : (
						<AccountCircleIcon color='primary' sx={{ fontSize: '3rem' }} />
					)}
				</ListItemAvatar>
				<ListItemText
					primary={
						<Typography
							component='span'
							variant='subtitle1'
							color='primary'
							className={classes.messageText}
							sx={{
								cursor: isAssistant ? 'pointer' : 'normal',
								'&:hover': {
									opacity: isAssistant ? 0.8 : 1,
								},
							}}
							onClick={() => copyText(content)}
						>
							{content ?? 'Ei sisältöä'}
						</Typography>
					}
					secondary={
						<span className={classes.messageFooter}>
							<Typography component='span' variant='body2' color='primary' className={classes.name}>
								{isAssistant
									? intl.formatMessage({ id: 'ai.chat.bot.message.assistant' })
									: intl.formatMessage({ id: 'ai.chat.bot.message.you' })}
							</Typography>
						</span>
					}
				/>
			</ListItem>
		);
	};

	const AiLoading = () => {
		return (
			<Stack direction='row' sx={{ pt: '1.5rem' }}>
				<Skeleton variant='circular' width={5} height={5} />
				<Skeleton variant='circular' width={5} height={5} sx={{ ml: '.5rem' }} />
				<Skeleton variant='circular' width={5} height={5} sx={{ ml: '.5rem' }} />
			</Stack>
		);
	};

	const MessageList = () => {
		return (
			<List className={classes.root}>
				{chatMessages?.length ? (
					chatMessages?.map(({ role, content }: any) => <MessageItem key={generateUid()} {...{ role, content }} />)
				) : (
					<ListItem>
						<ListItemAvatar>
							<Avatar alt='chat bot' className={classes.botAvatar}>
								<PsychologyIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={
								<Typography component='p' color='primary'>
									{intl.formatMessage({ id: 'ai.chat.bot.message' })}
								</Typography>
							}
							secondary={null}
						/>
					</ListItem>
				)}
				{aiLoading && <MessageItem role='assistant' content={<AiLoading />} />}
			</List>
		);
	};

	const formHeight = 265;
	const maxListHeight = 700;
	const maxPopperHeight = formHeight + maxListHeight;
	const maxHeight = height <= maxPopperHeight ? height - formHeight : maxListHeight;

	return (
		<Box
			sx={{
				flex: '1 1 auto',
				overflowY: 'auto',
				px: '1rem',
			}}
		>
			<MessageList />
			<div ref={messagesEndRef} />
		</Box>
	);
};

export default ChatFlow;
