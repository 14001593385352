import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Avatar,
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Skeleton,
	Typography,
} from '@mui/material';
import { isEmpty, isNil, map, truncate } from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { getCompany, getCompanyId } from 'utils/auth/company';
import { getInitials } from 'utils/helpers/getInitials';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { openPopover } from 'containers/Marketplace/popoverSlice';
import {
	useFetchRfpProfileTeamMembersQuery,
	useRemoveRfpProfileTeamMemberMutation,
	useUpsertRfpProfileTeamMemberMutation,
} from 'containers/Marketplace/rfpProfileApi';

import BlobDownloader from 'containers/Marketplace/attachment/BlobDownloader';

type Props = Omit<FormControlProps, 'onChange'> & { label?: string | null; helperText?: string | null };

const RfpProfileTeamMemberList = React.forwardRef<any, Props>(({ label, helperText, ...otherProps }, ref) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const {
		data: rfpProfileTeamMembers,
		isLoading,
		isError: isRfpProfileTeamMembersError,
	} = useFetchRfpProfileTeamMembersQuery({ companyId: companyId }, { skip: isNil(companyId) });

	const [upsertRfpProfileTeamMember, { isError: isUpsertError }] = useUpsertRfpProfileTeamMemberMutation();
	const [removeRfpProfileTeamMember, { isError: isRemoveError }] = useRemoveRfpProfileTeamMemberMutation();

	return (
		<FormControl {...otherProps} ref={ref}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			{isRfpProfileTeamMembersError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpProfileTeamMembers.error.message' />
				</Alert>
			)}
			{isUpsertError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='mutation.error.title' />
					</AlertTitle>
					<FormattedMessage id='upsertRfpProfileTeamMember.error.message' />
				</Alert>
			)}
			{isRemoveError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='mutation.error.title' />
					</AlertTitle>
					<FormattedMessage id='removeRfpProfileTeamMember.error.message' />
				</Alert>
			)}
			{isLoading ? (
				<>
					<Skeleton height={60} />
					<Skeleton height={60} />
					<Skeleton height={60} />
					<Skeleton height={60} />
				</>
			) : (
				<List>
					{isEmpty(rfpProfileTeamMembers) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpProfileTeamMembers.empty' />
						</Typography>
					)}
					{map(rfpProfileTeamMembers, rfpProfileTeamMember => (
						<ListItem
							sx={{ mb: '1rem' }}
							key={rfpProfileTeamMember.teamMemberId}
							secondaryAction={
								<IconButton
									color='error'
									onClick={event => {
										dispatch(
											openPopover({
												name: 'ConfirmRemovePopover',
												data: {
													anchorEl: event.currentTarget,
													text: formatMessage({ id: 'rfpProfileTeamMembers.confirmRemove' }),
													description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
													onSubmit: async () => {
														try {
															await removeRfpProfileTeamMember({
																companyId,
																teamMemberId: rfpProfileTeamMember.teamMemberId,
															}).unwrap();
															toast.success(
																formatMessage({ id: 'removeRfpProfileTeamMember.success.message' })
															);
														} catch (error) {
															console.error(error);
														}
													},
												},
											})
										);
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
							disablePadding
						>
							<ListItemButton
								onClick={() => {
									dispatch(
										openDialog({
											name: 'UpsertRfpProfileTeamMemberDialog',
											data: {
												fieldValues: rfpProfileTeamMember,
												onSubmit: async (fieldValues: any) => {
													try {
														await upsertRfpProfileTeamMember({
															companyId,
															rfpProfileTeamMember: fieldValues,
														}).unwrap();
														toast.success(formatMessage({ id: 'upsertRfpProfileTeamMember.success.message' }));
													} catch (error) {
														console.error(error);
													}
												},
											},
										})
									);
								}}
							>
								<ListItemAvatar>
									<BlobDownloader url={rfpProfileTeamMember.avatar}>
										{src => (
											<Avatar alt='avatar' src={src} variant='portrait1'>
												{getInitials(`${rfpProfileTeamMember.firstname} ${rfpProfileTeamMember.lastname}`)}
											</Avatar>
										)}
									</BlobDownloader>
								</ListItemAvatar>
								<ListItemText
									primary={`${rfpProfileTeamMember.firstname} ${rfpProfileTeamMember.lastname}`}
									secondary={
										<>
											<Typography component='span' variant='subtitle2' sx={{ mr: 1 }}>
												{rfpProfileTeamMember.position}
											</Typography>
											{truncate(rfpProfileTeamMember.description || '', { length: 100 })}
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
			<Box>
				<Button
					size='small'
					variant='text'
					onClick={() => {
						dispatch(
							openDialog({
								name: 'UpsertRfpProfileTeamMemberDialog',
								data: {
									fieldValues: {},
									onSubmit: async (fieldValues: any) => {
										try {
											await upsertRfpProfileTeamMember({ companyId, rfpProfileTeamMember: fieldValues }).unwrap();
											toast.success(formatMessage({ id: 'upsertRfpProfileTeamMember.success.message' }));
										} catch (error) {
											console.error(error);
										}
									},
								},
							})
						);
					}}
					startIcon={<AddIcon />}
				>
					<FormattedMessage id='rfpProfileTeamMembers.add' />
				</Button>
			</Box>
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
});

export default RfpProfileTeamMemberList;
