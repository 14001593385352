import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import ModalDialog from 'components/Dialog/ModalDialog';
import ColumnAddButton from 'components/Kanban/ColumnAddButton';
import ProjectCostForm from '../ProjectCostForm';

import { createCost } from 'containers/Projects/slices';

const AddProjectCost = ({ project, column, cost, instrument }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const formRef = useRef();

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = hideUnsavedConfirm => {
		if (isDirty && !hideUnsavedConfirm) {
			setIsUnsavedConfirmOpen(true);
		} else {
			setOpen(false);
		}
	};

	const [loading, setLoading] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [dirtyFields, setDirtyFields] = useState({});

	const onSubmit = async data => {
		setLoading(true);
		await handleCreate(data);
		setLoading(false);
	};

	const handleCreate = async data => {
		try {
			await dispatch(createCost(data.phase, data));

			if (handleClose) handleClose(true);

			toast.success(intl.formatMessage({ id: 'project.cost.form.success' }));
		} catch (err) {
			console.error(err);
			toast.error(intl.formatMessage({ id: 'project.cost.form.fail' }));
		}
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const [isUnsavedConfirmOpen, setIsUnsavedConfirmOpen] = useState(false);
	const closeUnsavedConfirm = () => setIsUnsavedConfirmOpen(false);
	const closeUnsavedConfirmAndModal = () => {
		setIsUnsavedConfirmOpen(false);
		setOpen(false);
	};

	const initialCost = {
		amountApplied: 0,
		amountGranted: 0,
		amountReported: 0,
		amountPaid: 0,
		category: '',
		description: '',
		eTag: '',
		employeeName: '',
		employeeRole: '',
		endDate: project?.endDate,
		files: undefined,
		id: '',
		name: '',
		paidDate: '',
		phase: column?.id,
		projectTaskId: '',
		startDate: project?.startDate,
		supplierName: '',
		workEstimate: 0,
	};

	return (
		<>
			<ColumnAddButton onClick={handleOpen}>
				<FormattedMessage id='project.cost.add' />
			</ColumnAddButton>
			<ModalDialog
				onClose={() => handleClose(false)}
				open={open}
				title={<FormattedMessage id='project.cost.add.title' />}
				actions={
					<LeftRightContainer
						left={null}
						right={
							<Button disabled={loading || !isDirty} color='success' onClick={submitForm}>
								{intl.formatMessage({ id: 'shared.save' })}
								{loading && <ButtonSpinner />}
							</Button>
						}
					/>
				}
			>
				<ProjectCostForm
					ref={formRef}
					cost={!cost ? initialCost : cost}
					onSubmit={onSubmit}
					passIsDirty={(isDirty, dirtyFields) => {
						setIsDirty(isDirty);
						setDirtyFields(dirtyFields);
					}}
					instrument={instrument}
				/>
				<ConfirmUnsaved
					open={isUnsavedConfirmOpen}
					handleCancel={closeUnsavedConfirm}
					handleConfirm={closeUnsavedConfirmAndModal}
					dirtyFields={dirtyFields}
					translation='project.cost.form'
				/>
			</ModalDialog>
		</>
	);
};

export default AddProjectCost;
