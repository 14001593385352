import InfoIcon from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import CustomButton from 'components/Button/Button';

const NoCategoryInstrument = ({ profile, action }) => {
	const intl = useIntl();

	const error = profile ? 'instrument.data.notfound.short' : 'instrument.data.notfound.complete.profile';

	return (
		<Stack
			alignItems='center'
			sx={{
				width: '100%',
				background: 'transparent',
				py: '4rem',
			}}
		>
			<Stack direction='row' alignItems='center' sx={{ mb: '1rem' }}>
				<InfoIcon
					fontSize='50'
					sx={{
						fontSize: '2rem',
						mr: '1rem',
					}}
				/>
				<Typography sx={{ fontSize: '1.2rem' }}>{intl.formatMessage({ id: error })}</Typography>
			</Stack>
			{!profile && (
				<CustomButton color='success' mt='0.5rem' onClick={action}>
					{intl.formatMessage({ id: 'instrument.data.notfound.profile.button' })}
				</CustomButton>
			)}
		</Stack>
	);
};
export default NoCategoryInstrument;
