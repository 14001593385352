import React from 'react';

import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ScoreHeader from './ScoreHeader';
import ScoreResult from './ScoreResult';
import ScoreBackground from './ScoreBackground';
import ScoreSuggestions from './ScoreSuggestions';
import htmlParser from 'components/HtmlParser';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '1.5rem 0',
        backgroundColor: theme.palette.primary.white,
    },
    divider: {
        borderBottomWidth: 1,
        backgroundColor: 'transparent',
    }
}));

const ScoreCard = ({ type, data }) => {
    const classes = useStyles();

    const score = data?.score;

    const scoreLevel = {
        LOW: 'low',
        MED: 'med',
        HIGH: 'high',
    };

    const getScoreLevel = (score, type) => {
        if (type === 'general') {
            if (score >= 80) return scoreLevel.HIGH;
            if (score < 80 && score >= 60) return scoreLevel.MED;
            if (score < 60) return scoreLevel.LOW;
        }
        else if (type === 'grant') {
            if (score >= 85) return scoreLevel.HIGH;
            if (score < 85 && score >= 60) return scoreLevel.MED;
            if (score < 60) return scoreLevel.LOW;
        }
        else if (type === 'loan') {
            if (score >= 80) return scoreLevel.HIGH;
            if (score < 80 && score >= 50) return scoreLevel.MED;
            if (score < 50) return scoreLevel.LOW;
        }
        else { // investment and general
            if (score >= 80) return scoreLevel.HIGH;
            if (score < 80 && score >= 40) return scoreLevel.MED;
            if (score < 40) return scoreLevel.LOW;
        }
    };

    const level = getScoreLevel(score, type);

    return (
        <div className={classes.root}>
            <ScoreHeader type={type} score={score} scoreLevel={scoreLevel} level={level} />
            <Divider className={classes.divider} />
            <ScoreResult description={htmlParser(data?.description)} />
            <Divider className={classes.divider} />
            {type === 'general'
                ? <ScoreBackground
                    companyType={data?.companyType}
                    businessDescription={data?.businessDescription}
                    companyInfo={data?.companyInfo}
                    market={data?.markets}
                    financial={data?.financial}
                    solidity={data?.solidity} />
                : null}
            <ScoreSuggestions suggestions={data?.suggestions} />
        </div>
    );
};

export default ScoreCard;
