import React from 'react';
import { Helmet, } from 'react-helmet-async';

import CompanyDetails from 'containers/Admin/UsersCompany/CompanyDetails';

const CompanyDetailsPage = () => {
    return (
        <>
            <Helmet>
                <title>GrantedAI - company details</title>
            </Helmet>
            <CompanyDetails />
        </>
    );
};

export default CompanyDetailsPage;