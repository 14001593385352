export const findRecursive = (id: string, currentNode: any): any => {
	if (id === currentNode.value) {
		return currentNode;
	}

	const arr = currentNode?.options ?? currentNode;

	if (Array.isArray(arr)) {
		for (const currentChild of arr) {
			const val = findRecursive(id, currentChild);

			if (val) return val;
		}
	}
};
