import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AddButton from 'components/Button/AddButton';
import AuthenticatedContainer from 'components/Containers/AuthenticatedContainer';
import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import ProjectModalFormBase from './ProjectModalFormBase';

const ProjectModalForm = ({ project = {}, instruments, scrollToTimeline, title, readonly, first = false }) => {
	const [dirtyFields, setDirtyFields] = useState({});

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = dirtyFields => {
		if (dirtyFields && Object.keys(dirtyFields)?.length > 0) {
			setDirtyFields(dirtyFields);
		} else {
			setOpen(false);
		}
	};

	const closeConfirm = () => setDirtyFields(false);
	const closeConfirmAndModal = () => {
		setDirtyFields(false);
		setOpen(false);
	};

	return (
		<>
			<ConfirmUnsaved
				open={Object.keys(dirtyFields)?.length > 0}
				handleCancel={closeConfirm}
				handleConfirm={closeConfirmAndModal}
				dirtyFields={dirtyFields}
				translation='project.form'
			/>
			<AuthenticatedContainer>
				<AddButton color={first ? 'success' : 'primary'} onClick={handleOpen}>
					{title ?? <FormattedMessage id={first ? 'project.form.add.first' : 'project.form.add'} />}
				</AddButton>
			</AuthenticatedContainer>
			<ProjectModalFormBase
				readonly={readonly}
				project={project}
				instruments={instruments}
				open={open}
				handleClose={handleClose}
				onSuccessAction={scrollToTimeline}
				first={first}
			/>
		</>
	);
};

export default ProjectModalForm;
