import { isEmpty, map } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Avatar, Box, ListItemAvatar, Stack, Typography } from '@mui/material';

import { RfpProposalTeamMember } from 'types/dataTypes';
import { getInitials } from 'utils/helpers/getInitials';
import BlobDownloader from '../../../attachment/BlobDownloader';
import { openDialog } from '../../../dialogSlice';
import RfpProposalTeamMemberDialog from './RfpProposalTeamMemberDialog';

interface ITeamMembersListProps {
	teamMembers: RfpProposalTeamMember[] | null | undefined;
	print?: boolean;
}

const RfpProposalTeamMemberList = ({ teamMembers, print }: ITeamMembersListProps) => {
	const dispatch = useDispatch();

	if (!teamMembers) return null;

	return (
		<>
			<RfpProposalTeamMemberDialog />

			<Box sx={{ mb: '2rem' }}>
				<Typography variant='h4'>
					<FormattedMessage id='rfpProposal.team.title' />
				</Typography>
				{isEmpty(teamMembers) && (
					<Typography variant='lightText'>
						<FormattedMessage id='rfpProposalTeamMembers.empty' />
					</Typography>
				)}
				{map(teamMembers, (teamMember, index) => (
					<Stack
						key={index}
						direction='row'
						sx={{
							p: '.5rem',
							pb: '1rem',
							'&:hover': {
								cursor: 'pointer',
								background: 'rgba(0, 0, 0, 0.04)',
							},
						}}
						onClick={() => {
							dispatch(
								openDialog({
									name: 'ReadRfpTeamMemberDialog',
									data: teamMember,
								})
							);
						}}
					>
						<ListItemAvatar>
							<BlobDownloader url={teamMember.avatar}>
								{text => (
									<Avatar alt='avatar' src={text} variant='portrait1'>
										{getInitials(`${teamMember.firstname} ${teamMember.lastname}`)}
									</Avatar>
								)}
							</BlobDownloader>
						</ListItemAvatar>
						<Box flexGrow={1}>
							<Typography variant='subtitle1'>
								{teamMember.firstname} {teamMember.lastname}
							</Typography>
							<Typography>{teamMember.position}</Typography>
							<Typography variant={print ? 'body2' : 'listItem'}>{teamMember.description}</Typography>
						</Box>
					</Stack>
				))}
			</Box>
		</>
	);
};

export default RfpProposalTeamMemberList;
