import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { find, findIndex, isNil, remove, set } from 'lodash';

type DrawerState = {
	drawers: {
		name: string;
		data?: any;
	}[];
};

const initialState = { drawers: [] } as DrawerState;

const drawerSlice = createSlice({
	name: 'drawer',
	initialState,
	reducers: {
		openDrawer(state, action: PayloadAction<{ name: string; data?: any }>) {
			let index = findIndex(state.drawers, { name: action.payload.name });
			if (index <= -1) {
				index = state.drawers.length;
			}
			set(state.drawers, index, { ...action.payload });
		},
		closeDrawer(state, action: PayloadAction<{ name: string }>) {
			remove(state.drawers, { name: action.payload.name });
		},
	},
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;

export const isDrawerOpen = (name: string) => (state: any) => {
	const drawerState = state[drawerSlice.name] as DrawerState;
	const drawer = find(drawerState.drawers, { name });
	return !isNil(drawer);
};

export const getDrawerData = (name: string) => (state: any) => {
	const drawerState = state[drawerSlice.name] as DrawerState;
	const drawer = find(drawerState.drawers, { name });
	return drawer?.data;
};

export default drawerSlice;
