import React from 'react';

import { getCompanyId } from 'utils/auth/company';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';

import Messages from 'containers/Messages';

const MessagesPage = ({ openFinancialPlanDrawer, closeFinancialPlanDrawer }) => {
	const companyId = getCompanyId();

	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	return (
		<Messages
			companyLoading={isCompanyLoading}
			companyName={company?.name}
			openFinancialPlanDrawer={openFinancialPlanDrawer}
			closeFinancialPlanDrawer={closeFinancialPlanDrawer}
		/>
	);
};

export default MessagesPage;
