import { createSlice /* current */ } from '@reduxjs/toolkit';
import { delay } from 'utils/misc/delay';
import { isAdminToken } from 'utils/auth/token';

import { scoreApi } from './api';

export const name = 'score';

export const initialState = {
	loading: false,
	error: false,
	score: null,
	lite: {
		loading: false,
		isLoaded: false,
		error: null,
		score: {},
	},
};

//reducer actions & reducer state
/**
 * You can call reducer actions directly from React component with dispatch or using actions wrapper (see actions below)
 */
const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},
		setLoading(state, action) {
			state.loading = action.payload;
			state.error = false;
		},
		loadScore(state, action) {
			state.score = action.payload;
			state.loading = false;
			state.error = false;
		},
		loadScoreError(state, action) {
			state.error = action.payload;
			state.loading = false;
			state.score = initialState.score;
		},
		loadLiteScore(state) {
			state.lite.loading = true;
			state.lite.isLoaded = false;
			state.lite.error = null;
		},
		loadLiteScoreSuccess(state, action) {
			state.lite.score = action.payload;
			state.lite.loading = false;
			state.lite.isLoaded = true;
			state.lite.error = null;
		},
		loadLiteScoreError(state, action) {
			state.lite.loading = false;
			state.lite.isLoaded = true;
			state.lite.error = action.payload;
		},
	},
});

export const { reducer } = slice;

export const resetScoreData = () => async dispatch => {
	dispatch(slice.actions.resetData());
};

export const setGrantedScore = score => async dispatch => {
	const isAdmin = isAdminToken();

	if (!score && !isAdmin) {
		dispatch(
			slice.actions.loadScore({
				grant: 0,
				grantedScore: 0,
				investment: 0,
				loan: 0,
			})
		);

		return true;
	}

	if (!score && isAdmin) {
		dispatch(
			slice.actions.loadScore({
				grant: 0,
				grantedScore: 0,
				investment: 0,
				loan: 0,
			})
		);

		return true;
	}

	score.grant = score.grant >= 0 ? score.grant : 0;
	score.grantedScore = score.grantedScore >= 0 ? score.grantedScore : 0;
	score.investment = score.investment >= 0 ? score.investment : 0;
	score.loan = score.loan >= 0 ? score.loan : 0;

	dispatch(slice.actions.loadScore(score));

	return true;
};

export const getGrantedScore = () => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.setLoading(true));

		let state = getState();

		let tryCount = 1;
		let scenario = state.scenario;

		do {
			state = getState();
			scenario = state.scenario;

			tryCount++;
			if (tryCount <= 15 && scenario?.loading?.scenarios && !scenario?.scenarios?.rowKey) {
				await delay(1000);
			}
		} while (tryCount <= 15 && scenario?.loading?.scenarios && !scenario?.scenarios?.rowKey);

		const grantedScore = await scoreApi.getGrantedScore();

		dispatch(setGrantedScore(grantedScore));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadScoreError(error));

		return false;
	}
};

export const getLiteScore = () => async dispatch => {
	try {
		dispatch(slice.actions.loadLiteScore());
		const liteScore = await scoreApi.getLiteScore();

		dispatch(slice.actions.loadLiteScoreSuccess(liteScore));
	} catch (error) {
		dispatch(slice.actions.loadLiteScoreError(error));
	}
};

export const getScenarioGrantedScore = scenario => async dispatch => {
	try {
		dispatch(slice.actions.setLoading(true));

		let tryCount = 1;
		let response;

		do {
			response = await scoreApi.getGrantedScore();

			if (response?.rowKey === scenario?.rowKey) {
				// compare that the score is calculated to correct scenario
				dispatch(setGrantedScore(response));

				return true;
			}

			tryCount++;
			if (response?.rowKey !== scenario?.rowKey && tryCount <= 3) {
				await delay(2000);
			} // give some time for counting scores TODO: replace with socket
		} while (response?.rowKey !== scenario?.rowKey && tryCount <= 3);

		dispatch(setGrantedScore(response));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadScoreError(error));

		return false;
	}
};

export default slice;
