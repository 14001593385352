import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadProspectsNextPage, loadProspects } from 'containers/Advanced/slices';
import { ROWS_PER_PAGE } from 'config/environment';
import ProspectList from './ProspectList';

const Prospects = ({ setIsData, phrase, toggleImportant }) => {
    const dispatch = useDispatch();

    const customers = useSelector(state => state.customers);
    const isProspectsLoaded = customers?.prospects?.isLoaded;

    useEffect(() => {
        if (isProspectsLoaded === false) dispatch(loadProspects(ROWS_PER_PAGE));
    }, [isProspectsLoaded]); // eslint-disable-line

    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

    const fetchdata = rowCount => {
        if (customers.loading) {
            return false;
        }

        setRowsPerPage(rowCount);
        dispatch(loadProspectsNextPage(rowCount));
    };

    const reloadCustomers = rowCount => {
        setRowsPerPage(rowCount);
        dispatch(loadProspects(rowCount));
    };

    return (
        <ProspectList
            customersLoading={customers?.loading}
            removeLoading={customers?.removeLoading}
            prospects={customers?.prospects}
            rowsPerPage={rowsPerPage}
            fetchdata={fetchdata}
            reloadCustomers={reloadCustomers}
            setIsData={setIsData}
            phrase={phrase}
            toggleImportant={toggleImportant}
        />
    );
};

export default Prospects;
