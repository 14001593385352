import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { instrumentsAdminApi } from './api';

export const name = 'admininstruments';

export const initialState = {
	loading: false,
	error: false,
	instruments: [],
	modalOpen: false,
	loadingInstrument: false,
};

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData: () => initialState,
		loadInstruments: state => {
			state.loading = true;
		},
		loadInstrumentsError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
			state.instruments = initialState.instruments;
		},
		loadInstrumentsSuccess: (state, action) => {
			state.loading = false;
			state.error = false;
			state.instruments = action.payload;
		},
		saveInstrument: state => {
			state.loadingInstrument = true;
		},
		saveInstrumentError: (state, action) => {
			state.loadingInstrument = false;
			state.error = action.payload;
		},
		saveInstrumentSuccess: (state, action) => {
			state.loadingInstrument = false;
			state.error = false;

			if (action.payload.isNew) {
				state.instruments.push(action.payload.data);
			} else {
				const index = state.instruments.findIndex(item => item.id === action.payload.data.id);
				state.instruments[index] = action.payload.data;
			}
		},
		toggleInstrumentModal: (state, action) => {
			state.modalOpen = action.payload;
		},
	},
});

export const { reducer } = slice;

export const loadInstruments = () => async dispatch => {
	try {
		dispatch(slice.actions.loadInstruments());

		const data = await instrumentsAdminApi.getInstruments(true);

		dispatch(slice.actions.loadInstrumentsSuccess(data));

		return true;
	} catch (err) {
		dispatch(slice.actions.loadInstrumentsError(err));

		return false;
	}
};

export const saveInstrument = (instrument, isDraft) => async dispatch => {
	instrument.limitators = instrument.limitators.map(limitator => ({
		companyValue: null,
		compareProperty: limitator.type,
		comparerStringType: 'IntLimitator',
		isValid: null,
		requiredValue: limitator.values.reduce((a, b) => {
			return a + b;
		}, 0),
	}));

	try {
		dispatch(slice.actions.saveInstrument());

		const data = await instrumentsAdminApi.saveInstrument(instrument, isDraft);

		let isNew = false;
		if (!instrument.id) {
			isNew = true;
		}

		dispatch(slice.actions.saveInstrumentSuccess({ data, isNew }));
		dispatch(closeInstrumentModal());
		toast.success('Success', isNew ? 'Instrument added successfully' : 'Instrument edited successfully');

		return true;
	} catch (err) {
		dispatch(slice.actions.saveInstrumentError(err));
		dispatch(closeInstrumentModal());
		toast.error('Error' + err.response?.status, err.response?.statusText);

		return false;
	}
};

export const publishSelected = (silent, selected) => async dispatch => {
	try {
		await instrumentsAdminApi.publishSelectedInstruments(silent, selected);

		dispatch(loadInstruments());
		toast.success('Success', 'Instrumentit julkaistu onnistuneesti');

		return true;
	} catch (err) {
		dispatch(slice.actions.saveInstrumentError(err));
		toast.error('Error' + err.response?.status, err.response?.statusText);

		return false;
	}
};

export const openInstrumentModal = () => async dispatch => {
	dispatch(slice.actions.toggleInstrumentModal(true));
};

export const closeInstrumentModal = () => async dispatch => {
	dispatch(slice.actions.toggleInstrumentModal(false));
};
