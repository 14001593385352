import React from 'react';
import { useIntl } from 'react-intl';
import { useFieldArray } from 'react-hook-form';

import { Grid, IconButton, } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { FormControlledInput } from 'components/Form';
import AddButton from 'components/Button/AddButton';
import FormLabel from 'components/Form/FormLabel';
import FormError from 'components/Form/FormError';

const RecipientList = ({ control, errors }) => {
    const intl = useIntl();

    const { fields, remove, append } = useFieldArray({
        control,
        name: 'recipients'
    });

    return (
        <Grid sx={{ padding: '1rem 2rem' }} container spacing={1}>
            <Grid item xs={12}>
                <FormLabel>
                    {intl.formatMessage({ id: 'stakeholder.form.recipients.title' })}
                </FormLabel>
                <FormError error={errors?.recipients?.message} />
            </Grid>
            {fields?.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <Grid item xs={10}>
                            <FormControlledInput
                                control={control}
                                name={`recipients.${index}`}
                                error={errors?.recipients?.[index]?.message}
                                placeholder={intl.formatMessage({ id: 'stakeholder.form.recipients.email.placeholder' })}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton onClick={() => remove(index)}>
                                <DeleteIcon color='error' />
                            </IconButton>
                        </Grid>
                    </React.Fragment>
                );
            })}
            <Grid item xs={12}>
                <AddButton sx={{ mt: 0.5 }} onClick={() => append('')}>
                    {intl.formatMessage({ id: 'stakeholder.form.recipients.add' })}
                </AddButton>
            </Grid>
        </Grid>
    );
};

export default RecipientList;