import React from 'react';

import { Grid, useMediaQuery } from '@mui/material';

import ProjectPrintContentValue from './ProjectPrintContentValue';
import ProjectPrintContentTitle from './ProjectPrintContentTitle';

const ProjectPrintContent = ({ title, content, separateTitle = false }) => {
    const matchesPrint = useMediaQuery('print');

    return (
        <Grid className='printContents' container sx={{ mt: '1rem', display: matchesPrint ? 'block' : 'flex' }}>
            <ProjectPrintContentTitle lg={separateTitle ? 12 : 2} sx={{ mb: '1rem' }}>
                {title}
            </ProjectPrintContentTitle>
            <ProjectPrintContentValue lg={separateTitle ? 12 : 10}>
                {content ?? '-'}
            </ProjectPrintContentValue>
        </Grid>
    );
};

export default ProjectPrintContent;