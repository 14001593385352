import React from 'react';
import { useIntl } from 'react-intl';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, Tooltip } from 'recharts';
import { useTheme } from '@mui/styles';

import { getHighestIntegerValue, getLowestIntegerValue } from 'utils/misc/bitwiseOperations';
import CustomTooltip from './CustomTooltip';
import './radar.css';

const InstrumentRadar = ({ name, data, companyName, radarSize, smDown, instrument }) => {
	const theme = useTheme();
	const intl = useIntl();

	const fill =
		instrument?.level === 2
			? theme.palette.success.main
			: instrument?.level === 1
			? theme.palette.warning.main
			: theme.palette.error.main;

	const radar =
		data && data.length
			? data
					.filter(item => item.hideItem !== true && item.requiredValue !== null && item.requiredValue !== 0)
					.map(item => ({
						...item,
						requiredHighInt: getHighestIntegerValue(item.requiredValue),
						companyHighInt: getHighestIntegerValue(item.companyValue),
						requiredLowInt: getLowestIntegerValue(item.requiredValue),
					}))
			: {
					...data,
					requiredHighInt: getHighestIntegerValue(data?.requiredValue),
					companyHighInt: getHighestIntegerValue(data?.companyValue),
					requiredLowInt: getLowestIntegerValue(data?.requiredValue),
			  };

	const translatedValue = key => {
		const val = intl.formatMessage({ id: key });

		return val === key ? 'undefined' : val;
	};

	return (
		<RadarChart
			data={radar}
			width={radarSize}
			height={radarSize}
			margin={{
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,
			}}
		>
			<PolarGrid gridType='circle' />
			<PolarAngleAxis dataKey='compareProperty' tickFormatter={() => ''} />
			<PolarRadiusAxis angle={90} domain={[0, 5]} tick={false} tickCount={6} />
			<Tooltip content={<CustomTooltip translatedValue={translatedValue} />} />
			<Radar name={name} dataKey='requiredLowInt' stroke={fill} strokeWidth={3} fill={fill} fillOpacity={0.4} />
			<Radar
				name={companyName ?? translatedValue('instrument.radar.tooltip.compareValue')}
				dataKey='companyHighInt'
				stroke={theme.palette.primary.main}
				fill={theme.palette.primary.main}
				fillOpacity={0.3}
				strokeWidth={3}
			/>
			<Legend
				iconType='circle'
				iconSize={smDown && 10}
				layout='vertical'
				verticalAlign='bottom'
				wrapperStyle={{ maxWidth: '100%', bottom: '-1rem' }}
			/>
		</RadarChart>
	);
};

export default InstrumentRadar;
