import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/styles';

import FormError from './FormError';
import FormLabel from './FormLabel';

export const StyledSelector = styled(TextField)(({ theme }) => ({
	'& input': {
		fontSize: '1rem',
		color: theme.palette.secondary.grey500,
	},
	'& select': {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}));

export const StyledOption = styled('option')(({ theme }) => ({
	color: theme.palette.secondary.grey500,
}));

export const SelectOption = ({ option }) => {
	return (
		<option key={option.value} value={option.value}>
			{option.label}
		</option>
	);
};

export const Selector = ({
	disabled,
	hasError,
	variant,
	onChange,
	onInputChange,
	onInputBlur,
	value,
	disabledLable,
	selectLabel,
	options,
}) => {
	return (
		<StyledSelector
			disabled={disabled}
			error={hasError}
			fullWidth
			select
			SelectProps={{ native: true }}
			variant={variant}
			size='small'
			onChange={event => {
				onChange(event);
				onInputChange(event);
			}}
			onBlur={onInputBlur}
			value={value}
			InputProps={
				{
					// disableUnderline: disabled ? true : false,
				}
			}
		>
			<StyledOption value='' disabled={disabledLable}>
				{selectLabel}
			</StyledOption>
			{options?.map(option => (
				<SelectOption key={option.value} option={option} />
			))}
		</StyledSelector>
	);
};

/**
 * Controlled Select, dependencies hoook-form, mui
 *
 * @param {*} defaultValue
 * @param {*} name
 * @param {*} control
 * @param {*} error
 * @param {*} label
 * @param {*} options
 * @returns
 */
const FormControlledSelect = ({
	defaultValue,
	name,
	control,
	error,
	label,
	options,
	disabled,
	disabledLable = false,
	variant = 'outlined',
	onInputBlur = () => { },
	onInputChange = () => { },
}) => {
	const intl = useIntl();
	const hasError = error ? true : false;

	const selectLabel = label
		? `${intl.formatMessage({ id: 'project.form.common.select' })} ${label}`
		: intl.formatMessage({ id: 'project.form.common.select' });

	return (
		<FormControl fullWidth>
			<FormLabel>{label}</FormLabel>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field: { onChange, value } }) => (
					<Selector
						{...{
							disabled,
							hasError,
							variant,
							onChange,
							onInputChange,
							onInputBlur,
							value,
							disabledLable,
							selectLabel,
							options,
						}}
					/>
				)}
			/>
			<FormError error={error} />
		</FormControl>
	);
};

export default FormControlledSelect;
