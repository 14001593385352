import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { compact, concat, isEmpty, isNil, map, truncate, without } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { RfpProposalTeamMember } from 'types/dataTypes';
import { replaceArrayFieldValues } from 'utils/misc/formUtils';

import { openDialog } from 'containers/Marketplace/dialogSlice';

type Props = {
	label?: string | null;
	helperText?: string | null;
	value?: Partial<RfpProposalTeamMember>[] | null;
	onChange?: (value: Partial<RfpProposalTeamMember>[]) => void;
} & FormControlProps;

const RfpProposalTeamMemberInput = React.forwardRef<any, Props>(({ label, helperText, value, onChange, ...otherProps }, ref) => {
	const dispatch = useDispatch();

	return (
		<FormControl {...otherProps} ref={ref}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			<List>
				{isEmpty(value) && (
					<Typography variant='lightText'>
						<FormattedMessage id='rfpProposalTeamMembers.empty' />
					</Typography>
				)}
				{map(value, (rfpProposalTeamMember, index) => (
					<ListItem
						key={index}
						secondaryAction={
							<IconButton
								color='error'
								onClick={() => {
									onChange?.(without(value, rfpProposalTeamMember));
								}}
							>
								<DeleteIcon />
							</IconButton>
						}
						disablePadding
					>
						<ListItemButton
							onClick={() => {
								dispatch(
									openDialog({
										name: 'UpsertRfpProposalTeamMemberDialog',
										data: {
											fieldValues: rfpProposalTeamMember,
											onSubmit: (fieldValues: Partial<RfpProposalTeamMember>) => {
												onChange?.(replaceArrayFieldValues(value, index, fieldValues));
											},
										},
									})
								);
							}}
						>
							<ListItemText
								primary={`${rfpProposalTeamMember.firstname} ${rfpProposalTeamMember.lastname}`}
								secondary={
									<>
										<Typography variant='subtitle2' component='span' sx={{ mr: 1 }}>
											{rfpProposalTeamMember.position}
										</Typography>
										{truncate(rfpProposalTeamMember.description || '', { length: 80 })}
									</>
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Box>
				<Button
					size='small'
					variant='text'
					onClick={() => {
						dispatch(
							openDialog({
								name: 'UpsertRfpProposalTeamMemberDialog',
								data: {
									fieldValues: {},
									onSubmit: (fieldValues: Partial<RfpProposalTeamMember>) => {
										onChange?.(compact(concat([], value, fieldValues)));
									},
								},
							})
						);
					}}
					startIcon={<AddIcon />}
				>
					<FormattedMessage id='rfpProposalTeamMembers.add' />
				</Button>
			</Box>
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
});

export default RfpProposalTeamMemberInput;
