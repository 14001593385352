import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '0.5rem',
    },
    message: {
        flex: 1,
    },
}));

const MessageNoItem = ({ messageId }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.container} >
            <InfoIcon color='primary' className={classes.icon} />
            <Typography color='primary' className={classes.message}>
                {intl.formatMessage({ id: messageId })}
            </Typography>
        </div>
    );
};

export default MessageNoItem;
