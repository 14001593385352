import React from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 28,
    height: 28,
    fontSize: '1rem',
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.grey900,
    color: theme.palette.primary.white
}));

const CustomAvatar = ({ initials, ...props }) => {
    return (
        <StyledAvatar {...props}>
            {initials}
        </StyledAvatar>

    );
};

export default CustomAvatar;