import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getCompany, getCompanyId } from 'utils/auth/company';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { openDrawer } from 'containers/Marketplace/drawerSlice';

import { getCompanyDetails } from 'containers/Admin/UsersCompany/slices';
import ConfirmCloseDialog from 'containers/Marketplace/common/ConfirmCloseDialog';
import ConfirmPublishPopover from 'containers/Marketplace/common/ConfirmPublishPopover';
import ConfirmRemovePopover from 'containers/Marketplace/common/ConfirmRemovePopover';
import RfpListTabs from 'containers/Marketplace/common/RfpListTabs';
import ReadRfpProfileDialog from 'containers/Marketplace/profile/Read/ReadRfpProfileDialog';
import UpsertRfpProfileDialog from 'containers/Marketplace/profile/UpsertRfpProfileDialog';
import ReadRfpProposalDrawer from 'containers/Marketplace/proposal/Read/ReadRfpProposalDrawer';
import UpsertRfpProposalDrawer from 'containers/Marketplace/proposal/UpsertRfpProposalDrawer';
import CanUpsertRfpRequest from 'containers/Marketplace/request/CanUpsertRfpRequest';
import ReadRfpRequestDrawer from 'containers/Marketplace/request/Read/ReadRfpRequestDrawer';
import UpsertRfpRequestDrawer from 'containers/Marketplace/request/UpsertRfpRequestDrawer';
import { isAdminToken, isContributorToken } from 'utils/auth/token';

const Marketplace: React.FC = () => {
	const dispatch = useDispatch();

	// @ts-ignore
	const admin = useSelector(state => state.admin);

	let company: any = null;
	let companyId: string | null = null;
	let currentCompanyId: string | null = null;

	if (isAdminToken()) {
		company = admin?.companyData;
		companyId = company?.businessId?.trim();
		currentCompanyId = getCompanyId();
	} else {
		company = getCompany();
		companyId = company.CompanyId;
		currentCompanyId = getCompanyId() ?? company.CompanyId;
	}

	const isContributor = isContributorToken();

	React.useEffect(() => {
		if (!isContributor) return;

		if (!company || Object.keys(company).length === 0 || companyId !== currentCompanyId) dispatch(getCompanyDetails(currentCompanyId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company, companyId, currentCompanyId, dispatch]);

	return (
		<>
			<ConfirmRemovePopover />
			<ConfirmCloseDialog />
			<ConfirmPublishPopover />
			<ReadRfpRequestDrawer />
			<ReadRfpProposalDrawer />
			<ReadRfpProfileDialog />
			<UpsertRfpRequestDrawer />
			<UpsertRfpProposalDrawer />
			<UpsertRfpProfileDialog />
			<Container sx={{ m: 0, padding: { xs: 2, md: 4 } }} maxWidth='lg'>
				<Stack flexDirection={{ xs: 'column', smd: 'row' }} sx={{ mb: 3 }}>
					<Typography variant='h1' sx={{ flex: 1 }}>
						<FormattedMessage id='marketplace.title' />
					</Typography>
					<Stack spacing={2} flexDirection='row' justifyContent='flex-end'>
						<Button
							endIcon={<CorporateFareIcon />}
							size='small'
							onClick={() => dispatch(openDialog({ name: 'ReadRfpProfileDialog', data: { companyId } }))}
						>
							<FormattedMessage id='marketplace.profile.open' />
						</Button>
						<CanUpsertRfpRequest rfpRequest={undefined}>
							{canUpsert =>
								canUpsert && (
									<Button
										endIcon={<PostAddIcon />}
										size='small'
										onClick={() => dispatch(openDrawer({ name: 'UpsertRfpRequestDrawer', data: {} }))}
									>
										<FormattedMessage id='marketplace.rfp.create' />
									</Button>
								)
							}
						</CanUpsertRfpRequest>
					</Stack>
				</Stack>
				<Box sx={{ my: '1rem' }}>
					<Typography variant='body1'>
						<FormattedMessage id='marketpace.desceription' />
					</Typography>
				</Box>
				<RfpListTabs />
			</Container>
		</>
	);
};

export default Marketplace;
