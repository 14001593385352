import React from 'react';
import { useIntl } from 'react-intl';
import { useInjectReducer } from 'utils/injectors/injectReducer';

import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import AddNonExistingCompany from 'containers/CompanySearch/AddNonExistingCompany';
import BrowseHistory from 'containers/CompanySearch/AddNonExistingCompany/BrowseHistory';

import { conversationsApi, useGetConversationsQuery } from 'containers/Collaboration/collaborationApi';
import { getFromLocalStorage } from 'utils/localStorage/localStorageHandler';

import CompanySearch from './CompanySearch';
import Conversations from './Conversations';

const TabPanel = props => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			sx={{ mt: '1rem' }}
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
};

const tabProps = index => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

const ContentTabs = () => {
	const intl = useIntl();
	const [value, setValue] = React.useState(0);

	useInjectReducer({ key: conversationsApi.reducerPath, reducer: conversationsApi.reducer });

	const conversations = useGetConversationsQuery('conversations');

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ mt: '2rem', width: '100%', bgcolor: 'background.paper' }}>
			<Tabs
				value={value}
				onChange={handleChange}
				aria-label='tabs'
				centered
				indicatorColor='secondary'
				textColor='inherit'
				variant='fullWidth'
			>
				<Tab label={intl.formatMessage({ id: 'admin.landing.tabs.history' })} {...tabProps(0)} />
				<Tab label={intl.formatMessage({ id: 'admin.landing.tabs.conversations' })} {...tabProps(1)} />
			</Tabs>

			<TabPanel value={value} index={0}>
				<BrowseHistory data={getFromLocalStorage()} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Conversations conversations={conversations} />
			</TabPanel>
		</Box>
	);
};

const Landing = () => {
	return (
		<>
			<CompanySearch />
			<AddNonExistingCompany />
			<ContentTabs />
		</>
	);
};

export default Landing;
