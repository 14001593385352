import { Box, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from 'components/Breadcrumbs';
import ConfirmDialog from 'components/ConfirmDialog';
import CustomPrompt from 'components/CustomPrompt';
import ChatIcon from 'containers/Chat/ChatIcon';
import AdminFunctions from 'views/Dashboard/AdminFunctions/AdminFunctions';
import AdminFunctionsButton from 'views/Dashboard/AdminFunctions/AdminFunctionsButton';
import CompaniesDropdown from './CompaniesDropdown';
import DropdownMenu from './DropdownMenu';
import Footer from './Footer';
import Header from './Header';
import MenuDrawer from './MenuDrawer';
import MessagesDrawer from './MessagesDrawer';
import OwnCompaniesDropdown from './OwnCompaniesDropdown';
import SlideshowModal from './SlideshowModal';

import { collaborationDrawerWidth, menuDrawerWidthExpanded, menuDrawerWidthMinimized } from 'config/drawerWidth';
import { themeConst } from 'config/routeConst';
import AiChatIcon from 'containers/AI/AiChatIcon';
import { logout } from 'containers/App/appSlice';
import { setLastVisit } from 'containers/Chat/chatSlice';
import { isCollaborationActive } from 'containers/Collaboration/slices';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import useOpenOnboardingDialog from 'containers/MasterPage/Onboarding/useOpenOnboardingDialog';
import OnboardingDialog from 'containers/Onboarding/OnboardingDialog';
import { find, isNil } from 'lodash';
import { getCompany, getCompanyId, isAdvancedToken, isFreemiumToken } from 'utils/auth/company';
import { getAccessToken, isAdminToken, isContributorToken } from 'utils/auth/token';
import { getChat, getUser } from 'utils/auth/user';
import { storageKeys } from 'utils/constants/constants';
import { regexProfile } from 'utils/constants/regex';
import { matchPath } from 'utils/helpers/pathMatch';
import storage from 'utils/localStorage/localStorageFunctions';
import { useLocalStorage } from 'utils/localStorage/useLocalStorage';
import { useGetCompanyContractQuery } from '../App/contractApi';
import CollaborationDrawerWrapper from './CollaborationDrawerWrapper';
import FreemiumCTADialog from './Freemium/FreemiumCTADialog';

const useStyles = makeStyles((theme: any) => ({
	container: {
		minHeight: '100%',
	},
	menuDrawerExpanded: {
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: menuDrawerWidthExpanded,
		},
	},
	menuDrawerMinimized: {
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: menuDrawerWidthMinimized,
		},
	},
}));

type MasterPageProps = {
	component: any;
	hideFooter: boolean;
	pageTheme: string;
	isLicenseExpired: boolean;
	history: any;
	location: any;
	match: any;
	title: string;
};

const MasterPage: React.FC<MasterPageProps> = ({ component, hideFooter, pageTheme, isLicenseExpired, history, location, match, title }) => {
	const intl = useIntl();
	const classes = useStyles();
	const theme: any = useTheme();
	const dispatch = useDispatch();
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));
	const betweenSmLg = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
	const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
	const xlUp = useMediaQuery(theme.breakpoints.up('xl'));

	useOpenOnboardingDialog();

	const companyId = getCompanyId();

	const { pathname } = location;
	const name = component.name;
	const props = { history, location, match, name };
	const Component = component;

	const isContributor = isContributorToken();
	const isAdvanced = isAdvancedToken();
	const isFreemium = isFreemiumToken();
	const isAuthenticated = !!getAccessToken();

	const {
		data: contractData,
		// isLoading: isContractLoading,
		// isFetching: isContractFetching,
	} = useGetCompanyContractQuery({ companyId, type: 'TermsContract' }, { skip: isNil(companyId) });

	const isAgreement: boolean = (contractData || isAdvanced) ?? false;

	const user = getUser();
	const companies = user?.companies;
	const email = user?.email;

	const chat: any = getChat();
	const instructionBox = chat?.instructionBox;

	const localCompany = getCompany();

	const selectedCompanyInfo = find(companies, item => item.CompanyId === localCompany?.CompanyId) ?? localCompany;

	const migratedCompany = useSelector((state: any) => state.migratedCompany);
	const collaboration = useSelector((state: any) => state.collaboration);

	const isCollaborationDrawerOpen = collaboration?.isDrawerOpen;
	const isMigratedDataLoaded = migratedCompany?.isLoaded;
	const migratedSelected = migratedCompany?.selected;

	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const [isAdminFunctionsRendered, setIsAdminFunctionsRendered] = useState(false);
	const [isDrawerExpanded, setIsDrawerExpanded] = useLocalStorage(storageKeys.MENU_DRAWER_KEY, null);
	const [isMessagesDrawerOpen, setIsMessagesDrawerOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [isChatDrawerOpen, setIsChatDrawerOpen] = useState(false);
	const [question, setQuestion] = useState<{ title: string; text: string } | null>(null);
	const [pageProps, setPageProps] = useState(null);
	const [open, setOpen] = useState(false);

	const toggleExpandDrawer = () => setIsDrawerExpanded(!isDrawerExpanded);
	const openMessagesDrawer = () => setIsMessagesDrawerOpen(true);
	const closeMessagesDrawer = () => setIsMessagesDrawerOpen(false);
	const exit = () => dispatch(logout(false));
	const openModal = () => setOpen(true);
	const closeModal = () => setOpen(false);

	const isMenuDrawerExpanded = (isDrawerExpanded === null && lgUp) || isDrawerExpanded;

	const openChatDrawer = ({ title, text }: { title: string; text: string }) => {
		dispatch(setLastVisit());
		setQuestion(null);

		if (typeof text === 'string') setQuestion({ title, text });

		setIsChatDrawerOpen(true);
	};

	const closeChatDrawer = () => setIsChatDrawerOpen(false);

	const [showInstructionBox, setShowInstructionBox] = useState(instructionBox);
	const handleInstructionBoxClick = () => {
		chat.instructionBox = false;
		storage.set(storageKeys.CHAT_KEY, chat);
		setShowInstructionBox(false);
	};

	const closeMenuDrawer = () => {
		if (isDrawerExpanded) setIsDrawerExpanded(false);
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const isOpen = Boolean(anchorEl);
	const openDropdown = (event: any) => setAnchorEl(event.currentTarget);

	const [isCustomPrompt, setCustomPrompt] = useState('');

	useEffect(() => {
		if (isCustomPrompt) {
			history.push(isCustomPrompt);
			setCustomPrompt('');
		}
	}, [isCustomPrompt, history]);

	const handleClose = (path: string) => {
		if (anchorEl !== null) setAnchorEl(null);

		if (!lgUp) setIsDrawerExpanded(false);
		/* wait for localStorage to update */
		window.setTimeout(() => {
			if (
				isMigratedDataLoaded &&
				path &&
				typeof path === 'string' &&
				(path === '/admin/userlist' ||
					path === '/admin/companylist' ||
					path === '/admin/instruments' ||
					path === '/search' ||
					path === '/admin/search' ||
					path === '/user')
			) {
				setCustomPrompt(path);
			} else if (path && typeof path === 'string' && path !== '/slideshow') {
				history.push(path);
			} else if (path && typeof path === 'string' && path === '/slideshow') {
				openModal();
			} else if (path === undefined) {
				setConfirmOpen(true);
			}
		}, 100);
	};

	const [anchorCompanies, setAnchorCompanies] = useState(null);
	const isOpenCompanies = Boolean(anchorCompanies);
	const openCompanies = (event: any) => setAnchorCompanies(event.currentTarget);
	const handleCloseCompanies = (id: string) => {
		if (anchorCompanies !== null) setAnchorCompanies(null);
		id && typeof id === 'string' && history.push(`/company/${id}`);
	};

	const [anchorOwnCompanies, setAnchorOwnCompanies] = useState(null);
	const isOpenOwnCompanies = Boolean(anchorOwnCompanies);
	const openOwnCompanies = (event: any) => setAnchorOwnCompanies(event.currentTarget);

	const customers = useSelector((state: any) => state.customers);
	const customersData = customers?.customers?.data;
	const customersLoading = customers?.loading;

	const [filteredCustomers, setFilteredCustomers] = useState(customersData);

	useEffect(() => {
		if (customersData !== undefined) {
			setFilteredCustomers(customersData);
		}
	}, [customersData]);

	const filterCustomers = (event: any) => {
		const keyword = event.target.value;

		if (keyword !== '') {
			const filteredCustomers = customersData.filter(({ advancedCustomerName }: { advancedCustomerName: string }) =>
				advancedCustomerName.toLowerCase().includes(keyword.toLowerCase())
			);
			setFilteredCustomers(filteredCustomers);
		} else {
			setFilteredCustomers(customersData);
		}
	};

	const themeBackgroundColor = pageTheme === themeConst.beige ? theme.palette.primary.secondary : theme.palette.primary.white;

	const isStaticAnalysis = matchPath('/analysis/:id', location, true);
	const isDashboard = matchPath('/', location, true);
	const isProfile = regexProfile.test(pathname);

	const isCollaborationDrawerAvailable = useMemo(() => {
		const isValidPath =
			matchPath('/businessPlan', location, true) ||
			matchPath('/projects', location, false) ||
			matchPath('/financialplan', location, true);

		return isCollaborationActive() && (isDashboard || isValidPath);
	}, [isDashboard, location]);

	const contentStyle = useMemo(() => {
		const menuWidth = isMenuDrawerExpanded ? menuDrawerWidthExpanded : menuDrawerWidthMinimized;
		const collaborationWidth = isCollaborationDrawerOpen ? collaborationDrawerWidth : '0px';

		if (isProfile || isStaticAnalysis) {
			return {
				width: '100%',
				marginLeft: '0px',
			};
		}

		return {
			width: '100%',
			marginLeft: '0px',
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${menuDrawerWidthMinimized})`,
				marginLeft: menuDrawerWidthMinimized,
			},
			[theme.breakpoints.up('lg')]: {
				width: `calc(100% - ${menuWidth})`,
				marginLeft: menuWidth,
			},
			[theme.breakpoints.up('xl')]: {
				width: `calc(100% - (${menuWidth} + ${collaborationWidth}))`,
				marginLeft: menuWidth,
			},
		};
	}, [theme, isProfile, isStaticAnalysis, isCollaborationDrawerOpen, isMenuDrawerExpanded]);

	return (
		<Stack sx={{ minHeight: '100vh', background: themeBackgroundColor }}>
			<Helmet>
				<title>GrantedAI - {title}</title>
			</Helmet>
			<CustomPrompt
				isDirty={!!isCustomPrompt}
				dirtyFields={migratedSelected}
				translation={'custom.prompt.dialog.migration'}
				migration={true}
			/>
			<Stack flexDirection='column' sx={{ minHeight: '100vh', background: themeBackgroundColor }}>
				<Box
					sx={{
						maxWidth: '100%',
						flex: 1,
					}}
				>
					{(smDown || isProfile || isStaticAnalysis) && (
						<Header
							pathname={pathname}
							isContributor={isContributor}
							isAdvanced={isAdvanced}
							localCompanyName={selectedCompanyInfo?.CompanyName}
							localCompanyId={selectedCompanyInfo?.CompanyId}
							companyId={companyId}
							companyName={company?.name}
							companies={companies}
							companyLoading={isCompanyLoading}
							toggleExpandDrawer={toggleExpandDrawer}
							openCompanies={openCompanies}
							openOwnCompanies={openOwnCompanies}
							openDropdown={openDropdown}
							openMessagesDrawer={openMessagesDrawer}
							handleClose={handleClose}
							isAgreement={isAgreement}
						/>
					)}
					<Box sx={contentStyle}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Breadcrumbs {...{ pathname, companyName: company?.name, companyId, isContributor, isAdvanced, pageProps }} />
							<AdminFunctionsButton
								{...{ companyId, isContributor, isAdminFunctionsRendered, setIsAdminFunctionsRendered }}
							/>
						</Box>
						<AdminFunctions show={isAdminFunctionsRendered} history={history} companyId={companyId} />
						<Component
							companyId={companyId}
							pathname={pathname}
							isContributor={isContributor}
							passPageProps={setPageProps}
							openChat={openChatDrawer}
							{...props}
						/>
					</Box>
				</Box>
				{!hideFooter && (
					<Box sx={{ flexShrink: 0 }}>
						<Box sx={contentStyle}>
							<Footer />
						</Box>
					</Box>
				)}
			</Stack>
			{!isProfile && !isStaticAnalysis && (
				<>
					<MenuDrawer
						isAuthenticated={isAuthenticated}
						isContributor={isContributor}
						isAdvanced={isAdvanced}
						isFreemium={isFreemium}
						isAgreement={isAgreement}
						isLicenseExpired={isLicenseExpired}
						pathname={pathname}
						localCompanyId={selectedCompanyInfo?.CompanyId}
						localCompanyName={selectedCompanyInfo?.CompanyName}
						companyId={companyId}
						companyName={company?.name}
						companies={companies}
						userName={email}
						isDrawerExpanded={isMenuDrawerExpanded}
						onClose={handleClose}
						closeDrawer={closeMenuDrawer}
						toggleExpandDrawer={toggleExpandDrawer}
						openOwnCompanies={openOwnCompanies}
						smDown={smDown}
						betweenSmLg={betweenSmLg}
					/>
					<CollaborationDrawerWrapper
						{...{ isCollaborationDrawerAvailable, instructionBox, location, hasChat: isAdminToken() }}
					/>
				</>
			)}
			<MessagesDrawer isDrawerOpen={isMessagesDrawerOpen} closeDrawer={closeMessagesDrawer} />
			<CompaniesDropdown
				anchorEl={anchorCompanies}
				open={isOpenCompanies}
				onClose={handleCloseCompanies}
				customers={filteredCustomers}
				loading={customersLoading}
				companyId={companyId}
				companyName={selectedCompanyInfo?.CompanyName}
				handleChange={filterCustomers}
			/>
			<OwnCompaniesDropdown
				anchorOwnCompanies={anchorOwnCompanies}
				open={isOpenOwnCompanies}
				user={user}
				localCompanyId={selectedCompanyInfo?.CompanyId}
				pathname={pathname}
				isMenuDrawerOpen={isMenuDrawerExpanded}
				setIsMenuDrawerOpen={() => {}}
				setAnchorOwnCompanies={setAnchorOwnCompanies}
			/>
			<DropdownMenu
				anchorEl={anchorEl}
				open={isOpen}
				onClose={handleClose}
				isContributor={isContributor}
				isAdvanced={isAdvanced}
				pathname={pathname}
			/>
			<SlideshowModal open={open} close={closeModal} companyName={company?.name} />
			<ConfirmDialog
				title='logout'
				open={confirmOpen}
				setOpen={setConfirmOpen}
				onConfirm={exit}
				submitApproveText={intl.formatMessage({ id: 'logout' })}
			>
				{intl.formatMessage({ id: 'confirm.title.logout' })}
			</ConfirmDialog>
			<OnboardingDialog />
			<FreemiumCTADialog />
			{pathname !== '/agreement' && pathname !== '/search' && pathname !== '/customers' && !isProfile && !isStaticAnalysis && (
				<ChatIcon
					pathname={pathname}
					question={question}
					isChatDrawerOpen={isChatDrawerOpen}
					openChatDrawer={openChatDrawer}
					closeChatDrawer={closeChatDrawer}
					instructionBox={showInstructionBox}
					handleClick={handleInstructionBoxClick}
					isCollaborationDrawerAvailable={isCollaborationDrawerAvailable}
					xlUp={xlUp}
				/>
			)}
			<AiChatIcon />
		</Stack>
	);
};

export default MasterPage;
