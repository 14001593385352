import RemoveIcon from '@mui/icons-material/Remove';
import { useState } from 'react';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';

export default function ConfirmButton({
	confirmAction,
	confirmText,
	cancelText,
	confirmBodyText,
	buttonText,
	disabled,
	children,
	loading = false,
	variant = 'contained',
	color = 'primary',
	stopPropagation,
	hoverRed = false,
	isRemove = false,
	...props
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const confirm = e => {
		setConfirmOpen(true);
		setAnchorEl(e.currentTarget);
	};

	const confirmActionLaunch = () => {
		if (confirmAction) confirmAction();

		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
		setConfirmOpen(false);
	};

	return (
		<>
			<Button
				disabled={loading || disabled}
				variant={variant}
				color={color}
				startIcon={isRemove ? <RemoveIcon size='small' /> : null}
				onClick={e => {
					stopPropagation && e.stopPropagation();
					confirm(e);
				}}
				sx={{
					'&:hover': {
						borderColor: hoverRed && 'error.main',
						backgroundColor: hoverRed && 'error.main',
						color: hoverRed && 'primary.white',
					},
					borderColor: hoverRed && confirmOpen && 'error.main',
					backgroundColor: hoverRed && confirmOpen && 'error.main',
					color: hoverRed && confirmOpen && 'primary.white',
				}}
				{...props}
			>
				{buttonText}
				{children}
				{loading && <ButtonSpinner />}
			</Button>
			<ConfirmPopper
				confirmAction={confirmActionLaunch}
				handleClose={handleClose}
				isopen={confirmOpen}
				anchorElement={anchorEl}
				confirmText={confirmText}
				cancelText={cancelText}
				confirmBodyText={confirmBodyText}
			/>
		</>
	);
}
