import React, { useRef, } from 'react';
import { useIntl } from 'react-intl';

import Stack from '@mui/material/Stack';

import Spinner from 'components/Spinner/CenteredSpinner';
import ContentContainer from 'components/Containers/ContentContainer';
import { ScoreCard } from './ScoreCard';
import SuggestedInstrumentsList from './SuggestedInstrumentsList';
import ContactItems from './ContactItems';

const AnalysisNoData = ({ link, error, company, analysis, instruments, scenario, }) => {
    const intl = useIntl();

    // Company
    const companyLoading = company?.loading;
    const companyData = company?.company;

    // Analysis
    const analysisLoading = analysis?.loading;
    const analysisData = analysis?.analysis;

    // Instruments
    const instrumentsLoading = instruments?.instruments?.loading;
    const instrumentsData = instruments?.instruments?.data;
    const currentInstrumentId = instruments?.currentInstrumentId;

    // CompanyProfile & Infocard
    const scenarios = scenario?.scenario;

    const missingValues = scenarios?.missingValues;

    const scrollToCurrentInstrument = id => {
        const headerOffset = 8;
        const element = document.getElementById(id);
        const elementPosition = element?.getBoundingClientRect().top ?? headerOffset;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    const loading = (companyLoading || analysisLoading || instrumentsLoading) ?? false;

    const printRef = useRef();
    const hasFullScenario = missingValues == null || missingValues?.length === 0;

    if (analysis === undefined || analysis === null) {
        return null;
    }

    if (error && error.response?.status === 500) {
        return (
            <Stack alignContent='center' alignItems='center' sx={{ p: '2rem' }}>
                {intl.formatMessage({ id: 'analysis.error' })}
            </Stack>
        );
    }

    if (loading) return < Spinner />;

    if (!hasFullScenario) {
        return (
            <Stack sx={{ background: '#fff', p: '2rem' }}>
                {intl.formatMessage({ id: 'analysis.missingScenarioData' })}
            </Stack>
        );
    }

    return (
        <ContentContainer sx={{ padding: 0 }}>
            <div ref={printRef}>
                {
                    analysisData && analysisData?.analysisSections?.length > 0 && analysisData.analysisSections.map((section, index) => {
                        return <ScoreCard key={section + index} type={section.type} data={section} />;
                    })
                }
                {link && <ContactItems contactData={analysis.contactData} isContacting={analysis.isContacting} link={analysis.linkId} />}
                <SuggestedInstrumentsList
                    title={intl.formatMessage({ id: 'analysis.instruments.internationalization' })}
                    scrollToCurrentInstrument={scrollToCurrentInstrument}
                    company={companyData}
                    currentInstrumentId={currentInstrumentId}
                    instruments={instrumentsData}
                    suggests={analysisData?.instruments?.internationalization}
                    link={link}
                    changeMarked={() => { }}
                />
                <SuggestedInstrumentsList
                    title={intl.formatMessage({ id: 'analysis.instruments.domesticinvestment' })}
                    scrollToCurrentInstrument={scrollToCurrentInstrument}
                    company={companyData}
                    currentInstrumentId={currentInstrumentId}
                    instruments={instrumentsData}
                    suggests={analysisData?.instruments?.domestic}
                    link={link}
                    changeMarked={() => { }}
                />
                <SuggestedInstrumentsList
                    title={intl.formatMessage({ id: 'analysis.instruments.load' })}
                    scrollToCurrentInstrument={scrollToCurrentInstrument}
                    company={companyData}
                    currentInstrumentId={currentInstrumentId}
                    instruments={instrumentsData}
                    suggests={analysisData?.instruments?.capital}
                    link={link}
                    changeMarked={() => { }}
                />
            </div>
        </ContentContainer>
    );
};

export default AnalysisNoData;
