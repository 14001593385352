import React, { useRef } from 'react';

import { useIntl } from 'react-intl';
import moment from 'moment';
import * as _ from 'lodash';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import MainSection from './MainSection';
import TimelineContainer from './TimelineContainer';

import { getDate, getAddress } from 'containers/Company/CompanyInfo/utils';
import { getEuroFormatted } from 'utils/formatters/getEuroFormatted';
import { sort } from 'containers/Projects/utils';
import htmlParser from 'components/HtmlParser';

const useStyles = makeStyles(theme => ({
    main: {
        padding: '3rem',
        backgroundColor: theme.palette.primary.white,
        height: '100vh',
        overflow: 'auto',
    },
    contentHeader: {
        fontSize: '1.6rem',
        fontWeight: 900,
        marginBottom: theme.spacing(3),
    },
    keyText: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    propText: {
        fontSize: '1rem',
        '& p': {
            marginBottom: 0,
        },
    },
    fontSize09rem: {
        fontSize: '0.9rem',
    },
    fontSize08rem: {
        fontSize: '0.8rem',
    },
    profileContainer: {
        [theme.breakpoints.down('md')]: {
            '&>div:nth-child(odd)': {
                backgroundColor: theme.palette.primary.secondary,
            },
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.secondary,
            '& div:nth-child(4n), div:nth-child(4n-1)': {
                backgroundColor: theme.palette.primary.white,
            },
        },
    },
    profileBox: {
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '50%',
            minHeight: theme.spacing(8),
            // maxHeight: theme.spacing(8),
        },
    },
    instrumentTitle: {
        fontSize: '1.1rem',
        fontWeight: 500,
    },
    instrumentText: {
        fontSize: '1rem',
        color: theme.palette.primary.main,
    },
    instrumentName: {
        fontSize: '1.1rem',
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    instrumentDate: {
        fontSize: '1.0rem',
        fontWeight: 500,
        paddingTop: '1px',
    },
    cell: {
        padding: '6px 16px 6px 0',
        borderBottom: 'none',
    },
}));

const MainContent = ({ company, scenario, timeline, projects, financialPlan, instruments, mock, selectedId, printRef, mdUp }) => {
    const classes = useStyles();
    const intl = useIntl();

    const scenarioValues = [0, 1, 2, 4, 8, 16, 32];

    const getIndex = (value, scenarioValues) =>
        scenarioValues.reduce((acc, item, index) => {
            return item === value ? index : acc;
        }, 0);

    const timelineProjects = projects && Object.values(projects) && sort(projects, item => item.startDate);

    const ref = useRef();

    return (
        <Box ref={ref} className={classes.main}>
            <Box ref={printRef}>
                {/* Cover letter */}
                <MainSection
                    hidden={!financialPlan?.base}
                    id='coverLetter'
                    selected={mdUp ? selectedId : null}
                >
                    <Typography id='coverLetter' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.coverLetter' })}
                    </Typography>
                    <Typography component='div' className={classes.propText}>
                        {htmlParser(financialPlan?.base)}
                    </Typography>
                </MainSection>
                {/* CompanyInfo: */}
                <MainSection id='companyinfo' selected={mdUp ? selectedId : null}>
                    <Typography id='companyinfo' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'editing.panel.companyinfo' })}
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item>
                            <Grid container direction='column'>
                                <Grid item>
                                    <Typography component='p' className={classes.keyText}>
                                        {intl.formatMessage({ id: 'common.business.id' })}:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography component='p' className={classes.keyText}>
                                        {intl.formatMessage({ id: 'common.address' })}:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography component='p' className={classes.keyText}>
                                        {intl.formatMessage({ id: 'common.foundation.year' })}:
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction='column'>
                                <Grid item>
                                    <Typography component='p' className={classes.propText}>
                                        {company?.businessId}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color='primary' className={classes.propText}>
                                        {getAddress(company?.address)}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography component='p' className={classes.propText}>
                                        {getDate(company?.registrationDate) ?? '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainSection>
                {/* Profile Info: */}
                <MainSection id='financialInfo' selected={mdUp ? selectedId : null}>
                    <Typography id='financialInfo' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'basic.financial.info' })}
                    </Typography>
                    <Box className={`${classes.profileContainer} profileContainerPrint`}>
                        {_.map(scenario, (value, key) => {
                            const isMissing = scenario?.missingValues?.find(item => item?.toLowerCase() === key?.toLowerCase());

                            if (typeof value === 'number' && key !== 'grantedScore' && key !== 'totalValues')
                                return (
                                    <Box key={key} className={`${classes.profileBox} profileBoxPrint`}>
                                        <Grid container>
                                            <Grid item xs={12} lgx={6}>
                                                <Typography
                                                    component='label'
                                                    htmlFor={key}
                                                    className={classes.keyText}
                                                >
                                                    {intl.formatMessage({ id: `instrument.radar.choice.${key}` })}:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} lgx={6}>
                                                <Typography component='span' className={classes.propText} >
                                                    {isMissing ? '-' : intl.formatMessage({
                                                        id: `instrument.radar.choice.${key}.${getIndex(value, scenarioValues)}`,
                                                    })}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                );
                        })}
                    </Box>
                </MainSection>
                {/* General Conditions: */}
                <MainSection
                    hidden={!financialPlan?.precondition}
                    id='generalConditions'
                    selected={mdUp ? selectedId : null}
                >
                    <Typography id='generalConditions' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.generalConditionsTitle' })}
                    </Typography>
                    <Typography component='div' className={classes.propText}>
                        {htmlParser(financialPlan?.precondition)}
                    </Typography>
                </MainSection>
                {/* Notice: */}
                <MainSection
                    hidden={!financialPlan?.notice}
                    id='notice'
                    selected={mdUp ? selectedId : null}
                >
                    <Typography id='notice' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.noticeTitle' })}
                    </Typography>
                    <Typography component='div' className={classes.propText}>
                        {htmlParser(financialPlan?.notice)}
                    </Typography>
                </MainSection>
                {/* Recomendations: */}
                <MainSection
                    hidden={!financialPlan?.expertComments}
                    id='recommendations'
                    selected={mdUp ? selectedId : null}
                >
                    <Typography id='recommendations' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.recomendationsTitle' })}
                    </Typography>
                    {timeline && Object.keys(timeline).length !== 0 && (
                        <Typography component='div' className={classes.propText}>
                            {htmlParser(financialPlan?.expertComments)}
                        </Typography>
                    )}
                </MainSection>
                {/* Timeline: */}
                <MainSection id='timeline' selected={mdUp ? selectedId : null}>
                    <Typography id='timeline' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.timeline' })}
                    </Typography>
                    <TimelineContainer
                        timeline={timeline}
                        projects={projects}
                        mock={mock}
                        companyId={company?.businessId}
                        scrollToNoInsruments={null}
                        instruments={instruments}
                        printView={true}
                    />
                </MainSection>
                {timelineProjects?.map(
                    ({ instrumentId, created, startDate, endDate, instrumentName, projectSummary, instrumentProvider, amountToApply, projectSize }, index) => {
                        const instrumentIngress = instruments?.reduce((acc, item) => {
                            return item.id === instrumentId ? item.ingress : acc;
                        }, '');

                        return (
                            <MainSection key={instrumentId + created} id={`instrument_${index + 1}`}>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item>
                                        <Typography id={`instrument_${index + 1}`} component='h3' className={`${classes.instrumentName} tocTitle`}>
                                            {index + 1}. {instrumentName}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography component='p' className={classes.instrumentDate}>
                                            ({moment(startDate).format('MM.YYYY')}-{moment(endDate).format('MM.YYYY')})
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography component='div' className={classes.propText} sx={{
                                    '& p, span, ul, li': {
                                        color: '#482E51 !important'
                                    }
                                }}>
                                    {htmlParser(instrumentIngress?.replace(/(<p>&nbsp;<\/p>)+/g, ''))}
                                </Typography>
                                <Typography component='div' className={classes.propText}>
                                    {htmlParser(projectSummary)}
                                </Typography>
                                <TableContainer>
                                    <Table aria-label='timeline' size='small'>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={classes.cell}>
                                                    <Typography component='p' className={classes.keyText}>
                                                        {intl.formatMessage({ id: 'timeline.tooltip.schedule' })}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    <Typography component='p' className={classes.propText}>
                                                        {moment(startDate).format('DD.MM.YYYY')}-{moment(endDate).format('DD.MM.YYYY')}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.cell}>
                                                    <Typography component='p' className={classes.keyText}>
                                                        {intl.formatMessage({ id: 'timeline.tooltip.provider' })}:
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    <Typography component='p' className={classes.propText}>
                                                        {instrumentProvider}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.cell}>
                                                    <Typography component='p' className={classes.keyText}>
                                                        {intl.formatMessage({ id: 'timeline.tooltip.grantAmount' })}:
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    <Typography component='p' className={classes.propText}>
                                                        {getEuroFormatted(amountToApply)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.cell}>
                                                    <Typography component='p' className={classes.keyText}>
                                                        {intl.formatMessage({ id: 'timeline.tooltip.projectSize' })}:
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    <Typography component='p' className={classes.propText}>
                                                        {getEuroFormatted(projectSize)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </MainSection>
                        );
                    }
                )}
                {/* Follow Up: */}
                <MainSection
                    hidden={!financialPlan?.followup}
                    id='followup'
                    selected={mdUp ? selectedId : null}
                >
                    <Typography id='followup' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.followupTitle' })}
                    </Typography>
                    <Typography component='div' className={classes.propText}>
                        {htmlParser(financialPlan?.followup)}
                    </Typography>
                </MainSection>
                {/* Instruments: */}
                {/* TODO: figure out what should be here?! */}
                {/* {timeline?.instruments?.some(item => item.instrumentDescription !== null) ? (
                    <MainSection id='instruments' selected={mdUp ? selectedId : null}>
                        <Typography id='instruments' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                            {intl.formatMessage({ id: 'financial.plan.instrumentDescriptions' })}
                        </Typography>
                        {timeline?.instruments?.map(instrument => {
                            const { id, name, instrumentDescription, provider, endTime } = instrument;

                            if (!instrumentDescription) return null;

                            return (
                                <Box key={id + endTime}>
                                    <Typography component='p' className={classes.instrumentTitle}>
                                        {provider} * {name}
                                    </Typography>
                                    <Typography component='div' className={classes.instrumentText}>
                                        {htmlParser(instrumentDescription)}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </MainSection>
                ) : null} */}
            </Box>
        </Box>
    );
};

export default MainContent;
