import React from 'react';


import Typography from '@mui/material/Typography';
import { Button, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import Icon from '@mui/material/Icon';

export default function ContactCard({ title, sx, action, actionText, icon }) {
    const theme = useTheme();

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}

            onClick={action}
            sx={{
                background: theme.palette.success.main,
                color: '#fff',
                padding: '2rem',
                height: '100%',
                '&:hover':
                {
                    cursor: 'pointer',
                    background: '#fff',
                    color: 'rgba(7, 120, 113, 1)',
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                    '& .MuiButton-root': {
                        background: 'rgba(7, 120, 113, 1)',
                        fontSize: '1.2rem´', color: '#fff'
                    }
                },
                ...sx,
            }}
        >

            <Stack direction='row' alignItems='center'>
                {icon && <Icon sx={{ fontSize: '44px !important', mr: '1rem' }}>{icon}</Icon>}

                <Typography sx={{ fontSize: '1.1rem', width: '100%' }}>{title}</Typography>
            </Stack>

            <Typography variant="title" component='div' sx={{ textAlign: 'center' }}>
                <Button sx={{ fontSize: '1.2rem', color: 'rgba(7, 120, 113, 1)', background: '#fff' }} variant='contained'>{actionText}</Button>
            </Typography>
        </Stack>
    );
}