import { useState } from 'react';
import { useIntl } from 'react-intl';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';

import FormatCurrency from 'components/Formatters/FormatCurrency';
import { filter } from 'lodash';
import MRRLicenseDataGrid from '../MRRLicenseDataGrid/MRRLicenseDataGrid';
import { SingleLicenceDialog } from './SingleLicenseDialog';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

export const LicenseDialog = ({ open, data, closeModal }: { open: boolean; data: any; closeModal: any }) => {
	const { formatMessage } = useIntl();

	const [selectedRowData, setSelectedRowData] = useState(null);
	const [isLicenseDialogOpen, setIsLicenseDialogOpen] = useState(false);

	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	if (!data) return null;

	return (
		<>
			<SingleLicenceDialog
				open={isLicenseDialogOpen}
				license={selectedRowData}
				closeModal={() => setIsLicenseDialogOpen(!isLicenseDialogOpen)}
			/>
			<Dialog
				aria-labelledby={'licenses'}
				aria-describedby={'licenses'}
				fullWidth
				maxWidth='lg'
				open={open}
				onClose={closeModal}
				scroll='paper'
			>
				<DialogContent>
					<Stack sx={{ width: '100%' }} direction='row' alignItems='center' alignContent='space-between'>
						<Typography sx={{ fontSize: '1.2rem', fontWeight: 500, flexGrow: 1 }}>
							{formatMessage({ id: `mrr.statistics.${data.lclass}` })}
						</Typography>
						<Box textAlign='right'>
							<IconButton aria-label='close' onClick={closeModal}>
								<CloseIcon />
							</IconButton>
						</Box>
					</Stack>
					<Stack sx={{ width: '100%' }} direction='row' alignItems='center' alignContent='space-between'>
						<Box sx={{ width: '12rem' }}>Nettoarvo:</Box>
						<Box sx={{ fontWeight: 400 }}>
							<FormatCurrency value={data.activeMrr} maximumFractionDigits={0} />
						</Box>
					</Stack>

					<Box sx={{ borderBottom: 1, mt: '1rem', borderColor: 'divider' }}>
						<Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
							<Tab label={formatMessage({ id: 'mrr.active-license-table' })} />
							<Tab label={formatMessage({ id: 'mrr.closed-license-table' })} />
						</Tabs>
					</Box>

					<CustomTabPanel value={value} index={0}>
						{/* @ts-ignore */}
						<MRRLicenseDataGrid
							showValueChange={false}
							rowData={filter(data?.licenses, (item: any) => item.isActive)}
							loading={false}
							autoHeight={false}
							rowsPerPage={100}
							heigth={'60vh'}
							rowClick={(row: any) => {
								setIsLicenseDialogOpen(true);
								setSelectedRowData(row.row);
							}}
						/>
					</CustomTabPanel>
					<CustomTabPanel value={value} index={1}>
						{/* @ts-ignore */}
						<MRRLicenseDataGrid
							showValueChange={false}
							rowData={filter(data?.licenses, (item: any) => !item.isActive)}
							loading={false}
							autoHeight={false}
							rowsPerPage={100}
							heigth={'60vh'}
							rowClick={(row: any) => {
								setIsLicenseDialogOpen(true);
								setSelectedRowData(row.row);
							}}
						/>
					</CustomTabPanel>
				</DialogContent>
			</Dialog>
		</>
	);
};
