import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import CurrencyInput from 'react-currency-input-field';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
	},
	formGroup: {
		padding: 0,
		'&:hover': {
			'&:before, &:after': {
				borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
			},
		},
		'&:before, &:after': {
			left: 0,
			right: 0,
			bottom: 0,
			content: "''",
			position: 'absolute',
			transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
			pointerEvents: 'none',
		},
	},
	formGroupOutlined: {
		border: `1px solid ${theme.palette.secondary.border}`,
		borderRadius: '5px',
		padding: '0.3rem',
		paddingRight: '0.8125rem',
		paddingLeft: '0.8125rem',
		'&:before, &:after': {
			borderBottom: 'none',
		},
		'&:hover': {
			border: `1px solid ${theme.palette.secondary.grey700}`,
			'&:before, &:after': {
				borderBottom: 'none',
			},
		},
	},
	formLabel: {
		fontSize: '0.75rem',
		color: 'rgba(0, 0, 0, 0.54)',
	},
	formInput: {
		borderColor: 'transparent',
		backgroundColor: 'transparent',
		width: '100%',
		padding: 0,
		'&:focus-visible': {
			outline: 'none',
		},
	},
	prefix: {
		paddingRight: '0.25rem',
	},
	postfix: {
	},
}));

const CurrencyField = ({
	label = '',
	prefix = '€',
	postfix = '',
	value = 0,
	basicdata = {},
	disabled = false,
	light = false,
	forbidNegative = false,
	positionEnd = false,
	outlined = false,
	onChange,
	decimals = 2,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const intl = useIntl();

	const handleFocus = event => event.target.select();

	return (
		<div className={classes.root}>
			<div className={`${classes.formGroup} ${outlined && classes.formGroupOutlined}`}>
				{label && (
					<label htmlFor={label} className={classes.formLabel}>
						{Object.keys(basicdata)?.length > 0 ? intl.formatMessage(basicdata[label]) : intl.formatMessage({ id: label })}
					</label>
				)}
				<Grid
					container
					alignItems='center'
					justifyContent={positionEnd ? 'flex-end' : 'flex-start'}
					wrap='nowrap'
					className={classes.inputContainer}
				>
					<Grid item>
						<Typography className={classes.prefix} style={{ color: light && theme.palette.secondary.grey500 }}>
							{prefix}
						</Typography>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<CurrencyInput
							id={label}
							placeholder='0'
							groupSeparator=' '
							decimalSeparator=','
							defaultValue={value}
							decimalsLimit={decimals}
							decimalScale={decimals}
							fixedDecimalLength
							allowNegativeValue={forbidNegative ? false : true}
							maxLength={15}
							onValueChange={value => onChange(value)}
							onFocus={handleFocus}
							disabled={disabled}
							step={1}
							className={classes.formInput}
							style={{
								color: disabled ? 'rgba(0, 0, 0, 0.38)' : light && theme.palette.secondary.grey500,
								textAlign: positionEnd && 'right',
								paddingRight: positionEnd && '0.75rem',
								paddingTop: '8px',
								paddingBottom: '8px',
							}}
						/>
					</Grid>
					{postfix && (
						<Typography className={classes.postfix} style={{ color: light && theme.palette.secondary.grey500 }}>
							{postfix}
						</Typography>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default CurrencyField;
