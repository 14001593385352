import { createSlice } from '@reduxjs/toolkit';
import { matchPath } from 'react-router';

import { userCompanyAdminApi } from './api';

export const initialState = {
	// company
	companyData: {},
	loading: true,
	error: false,
	// companyDetails
	companyDetails: {},
	companyDetailsLoading: false,
	companyDetailsError: false,
	// notes
	notes: {
		saving: false,
		error: null,
	},
};

export const name = 'admin';

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},

		loadCompany(state) {
			state.companyDataLoading = true;
			state.companyDataError = false;
		},
		loadCompanySuccess(state, action) {
			state.companyDataLoading = false;
			state.companyDataError = false;
			state.companyData = action.payload;
		},
		loadCompanyError(state, action) {
			state.companyDataError = action.payload;
			state.companyDataLoading = false;
			state.companyData = initialState.companyData;
		},

		loadCompanyDetails(state) {
			state.companyDetailsLoading = true;
			state.companyDetailsError = false;
		},
		loadCompanyDetailsSuccess(state, action) {
			state.companyDetailsLoading = false;
			state.companyDetailsError = false;
			state.companyDetails = action.payload;
		},
		loadCompanyDetailsError(state, action) {
			state.companyDetailsError = action.payload;
			state.companyDetailsLoading = false;
			state.companyDetails = initialState.companyDetails;
		},

		saveCompanyNotes(state) {
			state.notes.saving = true;
		},
		saveCompanyNotesSuccess(state, action) {
			state.notes.saving = false;
			state.notes.error = null;
			state.companyData.descriptions = action.payload.descriptions;
		},
		saveCompanyNotesError(state, action) {
			state.notes.saving = false;
			state.notes.error = action.payload;
		},
	},
});

export const { reducer } = slice;

export const getCompany = id => async dispatch => {
	try {
		dispatch(slice.actions.loadCompany());

		if (!id) {
			const match = matchPath(window.location.pathname, {
				path: '/admin/company/:id',
			});
			id = match?.params?.id;
		}

		const data = await userCompanyAdminApi.getCompanyData(id);
		const company = data?.company ? data.company : data;

		dispatch(slice.actions.loadCompanySuccess(company));
	} catch (error) {
		dispatch(slice.actions.loadCompanyError(error));
	}
};

export const getCompanyDetails = id => async dispatch => {
	try {
		dispatch(slice.actions.loadCompanyDetails());

		if (!id) {
			const match = matchPath(window.location.pathname, {
				path: '/admin/company/:id',
			});
			id = match?.params?.id;
		}

		dispatch(getCompany(id));

		const data = await userCompanyAdminApi.getCompanyDetails(id);
		dispatch(slice.actions.loadCompanyDetailsSuccess(data));

		return data;
	} catch (error) {
		dispatch(slice.actions.loadCompanyDetailsError(error));
	}
};

export const saveCompanyNotes = (companyId, notes) => async dispatch => {
	try {
		dispatch(slice.actions.saveCompanyNotes());

		const data = await userCompanyAdminApi.saveCompanyNotes(companyId, notes);

		dispatch(slice.actions.saveCompanyNotesSuccess(data));

		return true;
	} catch (error) {
		dispatch(slice.actions.saveCompanyNotesError(error));

		return false;
	}
};

export default slice;
