import { MaterialReactTable } from 'material-react-table';
import { Contact } from '../types';

export const ContactTable: React.FC<{ data: Array<Contact> }> = ({ data, ...otherProps }) => {
	if (!data || data.length === 0) return null;

	return (
		<MaterialReactTable
			{...otherProps}
			data={data ?? []}
			columns={[
				{
					accessorKey: 'email',
					header: 'Email',
					size: 150,
				},
				{
					accessorKey: 'phoneNumber',
					header: 'Tel',
					size: 150,
				},
				{
					accessorKey: 'firstName',
					header: 'Etunimi',
					size: 150,
				},
				{
					accessorKey: 'lastName',
					header: 'Sukunimi',
					size: 150,
				},
				{
					accessorKey: 'title',
					header: 'Rooli',
					size: 150,
				},
				{
					accessorKey: 'source',
					header: 'Source',
					size: 150,
				},
			]}
		/>
	);
};
