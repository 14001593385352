import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

import DataGrid from 'components/DataGrid';
import FormattedDate from 'components/Formatters/FormattedDate';

const UserActivationsTable = ({ loading, data, classes }) => {
    const intl = useIntl();

    const columns = [
        {
            field: 'isActivated',
            headerName: intl.formatMessage({ id: 'userdetails.userActivation.isActivated' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.isActivated + ''}` })}
                </span>
            ),
        },
        {
            field: 'linkCreateTime',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userdetails.userActivation.linkCreateTime' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.linkCreateTime} />
                </span>
            ),
        },
        {
            field: 'activateTime',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userdetails.userActivation.activateTime' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.activateTime} />
                </span>
            ),
        },
        {
            field: 'isExpired',
            headerName: intl.formatMessage({ id: 'userdetails.userActivation.isExpired' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${!!params.row.isExpired + ''}` })}
                </span>
            ),
        },
        {
            field: 'expirationReason',
            headerName: intl.formatMessage({ id: 'userdetails.userActivation.expirationReason' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={`${classes.cell} ${classes.companyName}`}>{params.row.expirationReason}</span>
            ),
        },
        {
            field: 'expirationTime',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userdetails.userActivation.expirationTime' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.expirationTime} />
                </span>
            ),
        },
        {
            field: 'validUntil',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userdetails.userActivation.validUntil' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.validUntil} />
                </span>
            ),
        },
        {
            field: 'timestamp',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userlist.table.timestamp' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.timestamp} />
                </span>
            ),
        },
        {
            field: 'userId',
            headerName: intl.formatMessage({ id: 'userdetails.role.userId' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={classes.cell}>{params.row.userId}</span>
            ),
        }
    ];

    return (
        <>
            <Typography variant='h2' className={classes.title}>{intl.formatMessage({ id: 'userdetails.userActivation.title' })}</Typography>
            {
                loading || !loading && data?.length !== 0
                    ? <DataGrid
                        idField='rowKey'
                        loading={loading}
                        data={data}
                        columns={columns}
                        paginationMode='client'
                    />
                    : <Typography component='p'>{intl.formatMessage({ id: 'userdetails.no.userActivation' })}</Typography>
            }
        </>
    );
};

export default UserActivationsTable;