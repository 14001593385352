import { useSelector } from 'react-redux';

import { useInjectReducer } from 'utils/injectors/injectReducer';
import { stakeholderApi } from './stakeholderApi';

import StakeholderDataContainer from './StakeholderDataContainer';

const Stakeholder = () => {
	useInjectReducer({ key: stakeholderApi.reducerPath, reducer: stakeholderApi.reducer }); // async process, wait until loaded the state

	const profile = useSelector(state => state.stakeholder);

	if (!profile) return null;

	return <StakeholderDataContainer />;
};

export default Stakeholder;
