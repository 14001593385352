import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import FileDropzone from 'components/FileDropzone';
import FormLabel from 'components/Form/FormLabel';

const calculateTotalSize = files =>
	files.reduce((acc, { size }) => {
		return acc + size * 1e-6;
	}, 0);

const Attachments = ({ setValue, formLabel = true, showFiles = true, loading = false }) => {
	const intl = useIntl();

	const maxFiles = 10;
	const maxSize = 10;

	const [files, setFiles] = useState([]);
	const [error, setError] = useState(null);

	const handleDrop = useCallback(
		droppedFiles => {
			if (!files?.length && droppedFiles.length <= maxFiles && calculateTotalSize(droppedFiles) <= maxSize) {
				error && setError(null);

				if (showFiles) setFiles(droppedFiles);

				setValue('files', droppedFiles, { shouldValidate: true, shouldDirty: true });
			} else if (
				!!files?.length &&
				files.length + droppedFiles.length <= maxFiles &&
				calculateTotalSize([...files, ...droppedFiles]) <= maxSize
			) {
				error && setError(null);

				if (showFiles) setFiles([...files, ...droppedFiles]);

				setValue('files', [...files, ...droppedFiles], { shouldValidate: true, shouldDirty: true });
			} else if (
				(!files?.length && droppedFiles.length > maxFiles) ||
				(!!files?.length && files.length + droppedFiles.length > maxFiles)
			) {
				setError(intl.formatMessage({ id: 'project.form.validation.max.files' }, { value: maxFiles }));
			} else if (
				(!files?.length && calculateTotalSize(droppedFiles) > maxSize) ||
				(!!files?.length && calculateTotalSize([...files, ...droppedFiles]) > maxSize)
			) {
				setError(intl.formatMessage({ id: 'project.form.validation.max.size' }, { value: maxSize }));
			}
		},
		[setValue, files, error, intl] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const handleRemove = file => {
		setFiles(prevFiles => prevFiles.filter(_file => _file.path !== file.path));
		setError(null);
	};

	return (
		<div style={{ marginTop: !formLabel && '1rem' }}>
			{formLabel && <FormLabel> {intl.formatMessage({ id: 'project.form.attachments' })}</FormLabel>}
			<FileDropzone files={files} error={error} onDrop={handleDrop} onRemove={handleRemove} loading={loading} />
		</div>
	);
};

export default Attachments;
