import { createSlice } from '@reduxjs/toolkit';
import { includes } from 'lodash';
import { batch } from 'react-redux';
import { push } from 'react-router-redux';

import { API_ROOT } from 'config/environment';
import { licenseExpired } from 'config/routeConst';
import { getCompany, getCompanyId, isAdvancedToken } from 'utils/auth/company';
import { initializeMsalInstance } from 'utils/auth/msal/msalConfig';
import { getAccessToken, isAdminToken, isContributorToken, setToken } from 'utils/auth/token';
import axios from 'utils/axios';
import { storageKeys } from 'utils/constants/constants';
import storage from 'utils/localStorage/localStorageFunctions';

import { resetMigratedCompanyData } from 'containers/Admin/CompanyMigration/slices';
import { getLicenses } from 'containers/Admin/UsersCompany/licenseSlice';
import { getUsersActiveStatus } from 'containers/Admin/UsersCompany/userSlice';
import { resetCustomers } from 'containers/Advanced/slices';
import { loadAnalysis, resetAnalysisData } from 'containers/Analysis/slices';
import { contractApi } from 'containers/App/contractApi';
import { loadBusinessPlan, resetBusinessPlan } from 'containers/BusinessPlan/slices';
import { getLastVisit, loadChat, resetChat } from 'containers/Chat/chatSlice';
import { resetCollaboration } from 'containers/Collaboration/slices';
import { resetSelectedCompany } from 'containers/CompanySearch/slices';
import { loadFinancialPlan, resetFinancialPlanData } from 'containers/FinancialPlan/slices';
import { loadInstruments, resetInstruments } from 'containers/Instruments/slices';
import { messagesApi } from 'containers/Messages/api';
import { getProjects, resetProjects } from 'containers/Projects/slices';
import { loadScenarios } from 'containers/Scenario/actions';
import { RESET_SCENARIO } from 'containers/Scenario/constants';
import { getGrantedScore, resetScoreData } from 'containers/Score/slices';

const storedAgreement = storage.get(storageKeys.AGREEMENT_KEY);

interface AppState {
	user: {} | null;
	isAdmin: boolean;
	loading: boolean;
	error: {} | null;
	isDataLoaded: boolean;
	isAdminDataLoaded: boolean;
	agreement: {} | null;
	sign: {} | null;
	isAdminPanelCollapsed: boolean;
	isProjectFormExtendedTemplateMounted: boolean;
}

const initialState: AppState = {
	user: null,
	isAdmin: false,
	loading: false,
	error: null,
	isDataLoaded: false,
	isAdminDataLoaded: false,
	agreement: storedAgreement,
	sign: null,
	isAdminPanelCollapsed: false,
	isProjectFormExtendedTemplateMounted: false,
};

export const name = 'app';

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		loadData: state => {
			state.loading = true;
			state.error = null;
			state.isDataLoaded = false;
		},
		loadDataSuccess: state => {
			state.loading = false;
			state.error = null;
			state.isDataLoaded = true;
		},
		loadDataError: (state, action: any) => {
			state.error = action.payload.error;
			state.loading = false;
		},
		loadAdminData: state => {
			state.loading = true;
			state.error = false;
		},
		loadAdminDataError: (state, action: any) => {
			state.error = action.error;
			state.loading = false;
		},
		loadAdminDataSuccess: state => {
			state.loading = false;
			state.error = false;
			state.isAdminDataLoaded = true;
		},
		auth: state => {
			state.error = false;
			state.loading = true;
		},
		authFail: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		authSuccess: (state, action) => {
			state.user = action.payload;
			state.error = false;
			state.loading = false;
		},
		logout: state => {
			state.user = null;
			state.loading = false;
		},
		logoutSuccess: state => {
			state.user = null;
			state.loading = false;
		},
		isAdminSuccess: (state, action) => {
			state.isAdmin = action.payload;
		},
		isAdminError: (state, action) => {
			state.isAdmin = false;
		},
		resetIsDataLoaded: state => {
			state.isDataLoaded = false;
		},
		isAdminPanelCollapsed: (state, action) => {
			state.isAdminPanelCollapsed = action.payload;
		},
		isProjectFormExtendedTemplateMounted: (state, action) => {
			state.isProjectFormExtendedTemplateMounted = action.payload;
		},
	},
});

export const { reducer } = slice;

export const { resetIsDataLoaded } = slice.actions;

export const loadAllData = () => (dispatch: any) => {
	const companyId = getCompanyId();

	if (!companyId) return;

	batch(() => {
		dispatch(loadScenarios());
		dispatch(loadInstruments());
		dispatch(loadAnalysis());
		dispatch(loadFinancialPlan(isContributorToken()));
		dispatch(getProjects(true));
		dispatch(getGrantedScore());
		dispatch(loadBusinessPlan());
		dispatch(loadChat());
		dispatch(getLastVisit());
	});
};

export const resetAllData = () => (dispatch: any) => {
	batch(() => {
		dispatch(slice.actions.resetIsDataLoaded());
		dispatch({ type: RESET_SCENARIO });
		dispatch(resetProjects());
		dispatch(resetChat());
		dispatch(messagesApi.util.resetApiState());
		dispatch(resetInstruments());
		dispatch(resetAnalysisData());
		dispatch(resetFinancialPlanData());
		dispatch(resetScoreData());
		dispatch(resetProjects());
		dispatch(resetBusinessPlan());
		dispatch(resetSelectedCompany());
		dispatch(resetCustomers());
		dispatch(resetMigratedCompanyData());
		dispatch(resetCollaboration());
	});
};

export const resetCompanyData = () => (dispatch: any) => {
	batch(() => {
		dispatch(slice.actions.resetIsDataLoaded());
		dispatch({ type: RESET_SCENARIO });
		dispatch(resetProjects());
		dispatch(resetChat());
		dispatch(messagesApi.util.resetApiState());
		dispatch(resetInstruments());
		dispatch(resetAnalysisData());
		dispatch(resetFinancialPlanData());
		dispatch(resetScoreData());
		dispatch(resetProjects());
		dispatch(resetBusinessPlan());
		dispatch(resetSelectedCompany());
		dispatch(resetMigratedCompanyData());
		dispatch(resetCollaboration());
	});
};

export const loadAdminData = () => (dispatch: any) => {
	try {
		batch(() => {
			dispatch(slice.actions.loadAdminData());
			if (isContributorToken()) {
				dispatch(getLicenses('active', 'all'));
				dispatch(getUsersActiveStatus('all'));
			}
			dispatch(slice.actions.loadAdminDataSuccess());
		});
	} catch (err) {
		dispatch(slice.actions.loadAdminDataError(err));
	}
};

export const loadData = () => (dispatch: any) => {
	try {
		const company = getCompany();
		batch(() => {
			dispatch(slice.actions.loadData());
			if (company?.License === 'expired') {
				dispatch(push(licenseExpired));
				return;
			}
			if (getAccessToken()) {
				dispatch(loadAllData());
			}
			dispatch(slice.actions.loadDataSuccess());
		});
	} catch (err) {
		dispatch(slice.actions.loadDataError(err));
		return false;
	}
	return true;
};

export const logout =
	(forcedRedirect: boolean, url = '/login') =>
	(dispatch: any) => {
		storage.remove(storageKeys.TOKEN_KEY);
		storage.remove(storageKeys.USER_KEY);
		storage.remove(storageKeys.COMPANY_KEY);
		storage.remove(storageKeys.CHAT_KEY);

		const msalInstance = initializeMsalInstance();
		if (msalInstance) {
			const currentAccount = msalInstance.getActiveAccount();
			if (currentAccount) msalInstance.logoutPopup({ account: currentAccount });
		}
		if (forcedRedirect === true) {
			window.location.assign(url);
		}

		batch(() => {
			dispatch(slice.actions.logout());
			dispatch(contractApi.util.resetApiState());
			dispatch(resetAllData());
			dispatch(slice.actions.logoutSuccess());
			dispatch(push('/login'));
		});
	};

export const auth = (username: string, password: string, captchaToken: string, state: any | null) => async (dispatch: any) => {
	dispatch(slice.actions.auth());

	try {
		const response = await axios.post(
			`${API_ROOT}/authentication`,
			{
				username: username?.trim(),
				password,
			},
			{
				headers: {
					xCaptchaToken: captchaToken,
				},
			}
		);

		response.data.token.expirationDate = new Date(response.data.token.expireTime * 1000);

		storage.set(storageKeys.USER_KEY, response.data.user);
		storage.set(storageKeys.COMPANY_KEY, response.data.user.company);
		storage.set(storageKeys.CHAT_KEY, { instructionBox: true });

		setToken(response.data.token);

		let noAgreement = false;
		if (response.data.user !== null && response.data.user.company !== undefined && response.data.user.company?.Roles !== undefined) {
			noAgreement = includes(response.data.user.company.Roles, 'Company.ContractMissing');
		}

		const selectedCompany = getCompany();
		const companyId = selectedCompany?.CompanyId;

		batch(() => {
			dispatch(isAdmin());
			dispatch(slice.actions.authSuccess(response.data.user));
			if (!noAgreement) {
				const isContributor = isContributorToken();
				const isAdvanced = isAdvancedToken();

				if (isContributor) {
					dispatch(push(state?.pathname ?? '/admin/search'));
				} else if (!isContributor && isAdvanced) {
					dispatch(push(state?.pathname ?? '/customers'));
				} else {
					if (state?.pathname && state?.pathname !== '/login') dispatch(push(state?.pathname));
					else if (companyId) dispatch(push(`/company/${companyId}`));
					else dispatch(push('/'));
				}
			} else {
				dispatch(push(`/company/${companyId}/agreement`, state));
			}
		});
	} catch (error: any) {
		dispatch(slice.actions.authFail(error.response));
		return false;
	}
	return true;
};

export const isAdmin = () => async (dispatch: any) => {
	try {
		if (!isAdminToken()) {
			dispatch(slice.actions.isAdminError('not admin'));
			return;
		}

		const response = await axios.get(`${API_ROOT}/admin`);
		dispatch(slice.actions.isAdminSuccess(response.data));
	} catch (error) {
		dispatch(slice.actions.isAdminError(error));
		return false;
	}
	return true;
};

export const toggleIsAdminPanelCollapsed = (isCollapsed: boolean) => (dispatch: any) => {
	dispatch(slice.actions.isAdminPanelCollapsed(isCollapsed));
};

export const setIsMountedProjectFormExtended = (isMounted: boolean) => (dispatch: any) => {
	dispatch(slice.actions.isProjectFormExtendedTemplateMounted(isMounted));
};

export default slice;
