import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha } from '@mui/material/styles';
import * as React from 'react';

import { Divider } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';

const StyledMenu = styled(
	props => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			{...props}
		/>
	),
	{
		shouldForwardProp: prop => prop !== 'menuBackground' && prop !== 'menuColor',
	}
)(({ theme, menuBackground, menuColor }) => {
	return {
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 180,
			color: menuColor ?? theme.palette.text.secondary,
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '4px 0',
				backgroundColor: menuBackground,
			},
			'& .MuiMenuItem-root': {
				'&:hover': {
					backgroundColor: alpha(menuColor ?? theme.palette.primary.main, theme.palette.action.selectedOpacity),
				},
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: menuColor ?? theme.palette.text.secondary,
					marginRight: theme.spacing(1.5),
				},
				'&:active': {
					backgroundColor: alpha(menuColor ?? theme.palette.primary.main, theme.palette.action.selectedOpacity),
				},
			},
		},
	};
});

function Item({
	disabled,
	icon,
	index,
	text,
	onClick,
	requireConfirm,
	confirmAction,
	confirmText,
	cancelText,
	confirmBodyText,
	dividerPosition,
	parentHandleClose,
	isDraft,
}) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [confirmOpen, setConfirmOpen] = React.useState(false);

	const handleClick = event => {
		if (onClick) onClick();

		if (confirmOpen && requireConfirm) {
			handleClose();

			return;
		}

		if (isDraft) {
			setAnchorEl(event.currentTarget);
			if (requireConfirm) setConfirmOpen(true);
			else if (confirmAction) {
				confirmAction();
				handleClose();
				parentHandleClose();
			}
		} else if (confirmAction) {
			confirmAction();
			// parentHandleClose();
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		setConfirmOpen(false);
	};

	return (
		<>
			{dividerPosition === 'top' && <Divider />}
			<MenuItem
				key={text + index}
				onClick={e => {
					handleClick(e);
				}}
				disableRipple
				disabled={disabled}
			>
				{icon}
				{text}
				{requireConfirm && (
					<ConfirmPopper
						confirmAction={confirmAction}
						handleClose={handleClose}
						isopen={confirmOpen}
						anchorElement={anchorEl}
						confirmText={confirmText}
						cancelText={cancelText}
						confirmBodyText={confirmBodyText}
					/>
				)}
			</MenuItem>
			{dividerPosition === 'bottom' && <Divider />}
		</>
	);
}

export default function DropDownMenuButton({ loading = false, text, items, light, icon, isDraft, sx }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [generatedId] = React.useState(() => `${Math.random().toString(16).slice(2)}`);

	const theme = useTheme();
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const MenuItems = ({ items }) => {
		return items.map((item, index) => <Item key={item + index} parentHandleClose={handleClose} isDraft={isDraft} {...item} />);
	};

	return (
		<>
			<Button
				id={`${generatedId}-button`}
				aria-controls={open ? `${generatedId}-menu` : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				variant='contained'
				disabled={loading}
				disableElevation
				onClick={handleClick}
				endIcon={icon ?? <KeyboardArrowDownIcon />}
				size='large'
				sx={{
					backgroundColor: light ? theme.palette.primary.white : theme.palette.primary.main,
					color: light ? theme.palette.secondary.grey700 : theme.palette.primary.white,
					border: `1px solid ${theme.palette.secondary.border}`,
					'&:hover': {
						backgroundColor: light ? theme.palette.secondary.main : theme.palette.primary.main,
					},
					'&:disabled': {
						opacity: '0.6',
						color: light ? theme.palette.secondary.grey700 : theme.palette.primary.white,
						backgroundColor: light ? theme.palette.secondary.main : theme.palette.primary.main,
					},
					...sx,
				}}
			>
				{text}
				{loading && <ButtonSpinner color={light && theme.palette.primary.main} />}
			</Button>
			<StyledMenu
				id={`${generatedId}-menu`}
				MenuListProps={{ 'aria-labelledby': `${generatedId}-button` }}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				menuBackground={light ? theme.palette.primary.white : theme.palette.primary.main}
				menuColor={light ? theme.palette.secondary.grey700 : theme.palette.primary.white}
			>
				<MenuItems items={items} />
			</StyledMenu>
		</>
	);
}
