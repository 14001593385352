import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import IosShareIcon from '@mui/icons-material/IosShare';
import AddLinkIcon from '@mui/icons-material/AddLink';
// import StarIcon from '@mui/icons-material/Star';
// import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { makeStyles } from '@mui/styles';

import DataGrid from 'components/DataGrid';
import ModalDialog from 'components/Dialog/ModalDialog';
import LinkPopover from './LinkPopover';
import InstructionPopover from 'components/CustomPopover';
import HoverPopover from 'components/CustomPopover/HoverPopover';
import ConfirmIconButton from 'components/Button/ConfirmIconButton';
import ConfirmButton from 'components/Button/ConfirmButton';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ModalContent from '../../ModalContent';
import ModalTitle from '../../ModalTitle';
import FormattedDate from 'components/Formatters/FormattedDate';

import { removeCustomer } from 'containers/Advanced/slices';
import { getGrantedScoreColor, getLiteScoreColor } from '../../utils';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(theme => ({
    cell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1rem',
        color: theme.palette.primary.main,
    },
    customerName: {
        fontWeight: 700,
    },
    circle: {
        height: '0.875rem',
        width: '0.875rem',
        borderRadius: '0.875rem',
    },
    grantedScore: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    error: {
        color: theme.palette.error.main,
    },
    errorStatus: {
        color: theme.palette.error.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingTop: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.white,
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.primary.main,
        },
    },
    buttonLoading: {
        position: 'absolute',
        color: theme.palette.primary.main,
    },
    row: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
}));

const CustomerList = ({
    customersLoading,
    customers,
    removeLoading,
    rowsPerPage,
    fetchdata,
    reloadCustomers,
    setIsData,
    phrase,
    selectedIds,
    setSelectedIds,
    toggleImportant
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const profile = useSelector(state => state.profile);
    const profileLoading = profile?.loading;
    const profileLink = profile?.profileLink;

    const [currentId, setCurrentId] = useState(null);

    // popover
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl) && !profileLoading;

    // modal
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const toggleModal = data => {
        setModalOpen(!modalOpen);
        setModalData(data);
    };

    const isLoaded = customers?.isLoaded;
    const token = customers?.token;
    const data = customers?.data;

    useEffect(() => {
        if (data?.length > 0) {
            setIsData(true);
        }
    }, [data, setIsData]);

    const filteredCustomers = data?.filter(({ rowKey, advancedCustomerName }) => rowKey?.toLowerCase().includes(phrase.toLowerCase()) || advancedCustomerName?.toLowerCase().includes(phrase.toLowerCase()));

    const dataWithoutNegativeScores = filteredCustomers?.map(item => {
        return ({
            ...item,
            grantedScore: !item.grantedScore
                ? null
                : +item.grantedScore < 0
                    ? 0
                    : +item.grantedScore
        });
    });

    const [rowCount, setRowCount] = useState(0);

    const removeCustomerAction = async customer => {
        setCurrentId(customer.rowKey);
        const success = await dispatch(removeCustomer(customer));
        setRowCount(rowCount - 1);

        if (success) {
            toast.success(<FormattedMessage id='advanced.customers.remove.success' />);

            if (modalOpen) setModalOpen(false);
            if (modalData) setModalData(null);

            return;
        }

        toast.error(<FormattedMessage id='advanced.customers.remove.failed' />);
    };

    const columns = [
        {
            field: 'liteScoreValue',
            headerClassName: 'styled-header',
            width: 100,
            renderHeader: () => (
                <HoverPopover
                    sx={{ fontSize: '0.875rem' }}
                    id='popperitemlite'
                    content={intl.formatMessage({ id: 'customerlist.table.liteScore.instructions' })}
                    title={intl.formatMessage({ id: 'customerlist.table.liteScoreValue' })}
                />
            ),
            renderCell: params => {
                const liteScoreValue = params.row.liteScoreValue;

                return (
                    <Grid container alignItems='center'>
                        <Grid item style={{ paddingBottom: '0.125rem' }}>
                            {liteScoreValue !== null &&
                                <div
                                    className={classes.circle}
                                    style={{
                                        backgroundColor: getLiteScoreColor(liteScoreValue)
                                    }}
                                />
                            }
                        </Grid>
                        <Grid item style={{ paddingLeft: '0.375rem' }}>
                            <Typography
                                className={classes.grantedScore}
                                style={{
                                    color: getLiteScoreColor(liteScoreValue)
                                }}
                            >
                                {liteScoreValue}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: 'grantedScore',
            headerName: intl.formatMessage({ id: 'customerlist.table.grantedScore' }),
            headerClassName: 'styled-header',
            width: 100,
            renderCell: params => {
                const grantedScores = params.row.grantedScore; // >= 0 ? params.row.grantedScore : 0;

                return (
                    <Grid container alignItems='center'>
                        <Grid item style={{ paddingBottom: '0.125rem' }}>
                            {grantedScores !== null &&
                                <div
                                    className={classes.circle}
                                    style={{
                                        backgroundColor: getGrantedScoreColor(grantedScores)
                                    }}
                                />
                            }
                        </Grid>
                        <Grid item style={{ paddingLeft: '0.375rem' }}>
                            <Typography
                                className={classes.grantedScore}
                                style={{
                                    color: getGrantedScoreColor(grantedScores)
                                }}
                            >
                                {grantedScores}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: 'rowKey',
            headerName: intl.formatMessage({ id: 'customerlist.table.profile.navigate' }),
            headerClassName: 'styled-header',
            width: 120,
            renderCell: params => (
                <span className={classes.cell}>
                    <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        onClick={e => {
                            e.stopPropagation();
                            history.push(`/company/${params.id}`);
                        }}
                        className={classes.button}
                    >
                        {params.id}
                    </Button>
                </span>
            ),
        },
        // {
        //     field: 'important',
        //     headerName: intl.formatMessage({ id: 'customerlist.table.important' }),
        //     headerClassName: 'styled-header',
        //     width: 60,
        //     renderCell: params => (
        //         <span className={classes.cell}>
        //             <IconButton
        //                 color='primary'
        //                 onClick={e => {
        //                     e.stopPropagation();
        //                     toggleImportant(params.row.customerCompanyId, params.row.important);
        //                 }}
        //             >
        //                 {params.row.important ? <StarIcon /> : <StarOutlineIcon />}
        //             </IconButton>
        //         </span>
        //     ),
        // },
        {
            field: 'advancedCustomerName',
            headerName: intl.formatMessage({ id: 'customerlist.table.name' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={`${classes.cell} ${classes.customerName}`}>{params.row.advancedCustomerName}</span>
            ),
        },
        {
            field: 'contactInfo',
            headerName: intl.formatMessage({ id: 'customerlist.table.contact.info' }),
            headerClassName: 'styled-header',
            sortable: false,
            width: 120,
            renderCell: params => {
                if (params.row.contactProfile) {
                    return (
                        <CheckIcon color='primary' />
                    );
                }
            },
        },
        {
            field: 'created',
            type: 'date',
            headerName: intl.formatMessage({ id: 'customerlist.table.profile.creation.date' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.created} />
                </span>
            ),
        },
        {
            field: 'createProfile',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderHeader: () => (
                <>
                    {intl.formatMessage({ id: 'customerlist.table.create.profile.link' })}
                    <InstructionPopover
                        id='popperitem'
                        content={intl.formatMessage({ id: 'customerlist.table.create.profile.link.instructions' })}
                    />
                </>
            ),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <Box textAlign='center'>
                    <Button
                        variant='outlined'
                        size='small'
                        color='primary'
                        disabled={profileLoading && !modalOpen && currentId === params.row.rowKey}
                        onClick={e => {
                            e.stopPropagation();
                            history.push(`/company/${params.row.rowKey}/share`);
                        }}
                    >
                        {intl.formatMessage({ id: 'customerlist.table.create.profile.link' })}
                        {profileLoading && !modalOpen && currentId === params.row.rowKey && <CircularProgress size={24} className={classes.buttonLoading} />}
                    </Button>
                </Box>
            ),
        },
        // {
        //     field: 'markAsHandled',
        //     disableColumnMenu: true,
        //     sortable: false,
        //     renderHeader: params => (
        //         <Typography component='p' className={classes.header}>
        //             {intl.formatMessage({ id: 'customerlist.table.markashandled' })}
        //             <InstructionPopover
        //                 id='popperitem'
        //                 content={intl.formatMessage({ id: 'customerlist.table.markashandled.instructions' })}
        //             />
        //         </Typography>
        //     ),
        //     width: 150,
        //     renderCell: params =>
        //         params.row.statusValue === 1 ? (
        //             <Box textAlign='center'>
        //                 <Button
        //                     variant='outlined'
        //                     size='small'
        //                     color='primary'
        //                     disabled={profileLoading && currentId === params.row.rowKey}
        //                     onClick={() => {
        //                         dispatch(markAsHandled(params.row));
        //                     }}
        //                 >
        //                     {intl.formatMessage({ id: 'customerlist.table.markashandled.link' })}
        //                 </Button>
        //             </Box>
        //         ) : null,
        // },
        {
            field: 'removeCustomer',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderHeader: () => (
                <>
                    {intl.formatMessage({ id: 'customerlist.table.remove' })}
                    <InstructionPopover
                        id='popperitem'
                        content={intl.formatMessage({ id: 'customerlist.table.removeCustomer.instructions' })}
                    />
                </>
            ),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <Box textAlign='center'>
                    <ConfirmIconButton
                        color='primary'
                        loading={removeLoading && currentId === params.row.rowKey}
                        disabled={removeLoading && currentId === params.row.rowKey}
                        confirmText={intl.formatMessage({ id: 'advanced.prospects.remove.confirm' })}
                        cancelText={intl.formatMessage({ id: 'advanced.prospects.remove.cancel' })}
                        confirmBodyText={intl.formatMessage({ id: 'advanced.prospects.remove.body' })}
                        confirmAction={() => removeCustomerAction(params.row)}
                        stopPropagation={true}
                        sx={{ mr: 2 }}
                    >
                        <DeleteIcon />
                    </ConfirmIconButton>
                </Box>
            ),
        },
    ];

    return (
        !isLoaded || isLoaded && filteredCustomers?.length !== 0 ? (
            <>
                <DataGrid
                    idField='rowKey'
                    loading={!isLoaded || customersLoading}
                    data={dataWithoutNegativeScores}
                    columns={columns}
                    token={token}
                    rowsPerPage={rowsPerPage}
                    fetchdata={fetchdata}
                    reloadData={reloadCustomers}
                    onRowClick={params => toggleModal(params.row)}
                    checkboxSelection
                    onSelectionModelChange={selected => setSelectedIds(selected)}
                    selectionModel={selectedIds}
                    disableSelectionOnClick={true}
                    classes={{
                        row: classes.row
                    }}
                    mt={2}
                />
                <ModalDialog
                    open={modalOpen}
                    onClose={() => toggleModal(null)}
                    title={<ModalTitle advancedCustomerName={modalData?.advancedCustomerName} rowKey={modalData?.rowKey} />}
                    actions={
                        <LeftRightContainer
                            alignItems='flex-start'
                            left={
                                <Button variant='text' color='primary' onClick={() => toggleModal(null)}>
                                    {intl.formatMessage({ id: 'common.close' })}
                                </Button>
                            }
                            right={
                                <Grid container justifyContent='flex-end' spacing={2}>
                                    <Grid item>
                                        <ConfirmButton
                                            variant='outlined'
                                            color='primary'
                                            loading={removeLoading}
                                            disabled={removeLoading}
                                            confirmAction={() => removeCustomerAction(modalData)}
                                            confirmText={intl.formatMessage({ id: 'advanced.prospects.remove.confirm' })}
                                            cancelText={intl.formatMessage({ id: 'advanced.prospects.remove.cancel' })}
                                            confirmBodyText={intl.formatMessage({ id: 'advanced.prospects.remove.body' })}
                                            startIcon={<DeleteIcon />}
                                            hoverRed={true}
                                        >
                                            {intl.formatMessage({ id: 'common.delete' })}
                                        </ConfirmButton>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            startIcon={<IosShareIcon />}
                                            disabled={profileLoading}
                                            onClick={() => {
                                                history.push(`/company/${modalData?.rowKey}/share`);
                                            }}
                                        >
                                            {intl.formatMessage({ id: 'customerlist.table.create.profile.link' })}
                                            {profileLoading && <CircularProgress size={24} className={classes.buttonLoading} />}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' color='primary' startIcon={<AddLinkIcon />} onClick={() => history.push(`/company/${modalData?.rowKey}`)}>
                                            {intl.formatMessage({ id: 'customerlist.table.open.profile' })}
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        />
                    }
                >
                    <ModalContent
                        created={modalData?.created}
                        author={modalData?.author}
                        botProfileRowKey={modalData?.botProfileRowKey}
                        contactProfile={modalData?.contactProfile}
                        advancedCustomerName={modalData?.advancedCustomerName}
                        rowKey={modalData?.rowKey}
                    />
                </ModalDialog>
            </>
        ) : (
            <Box sx={{ mt: 2 }}>
                <Typography component='p' color='primary' align='center'>
                    {intl.formatMessage({ id: phrase ? 'filter.no.items' : 'customerlist.no.customers' })}
                </Typography>
            </Box>
        )
    );
};

export default CustomerList;
