import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { generateUid } from 'utils/helpers/uid';

import ReferenceAddCard from './ReferenceAddCard';
import ReferenceCard from './ReferenceCard';

const ReferenceForm = forwardRef(({ categories, instruments, setIsFormDirty, onSubmit }, ref) => {
	const intl = useIntl();

	const schema = yup.object().shape({
		categories: yup.array().of(
			yup.object().shape({
				category: yup
					.string()
					.required(`${intl.formatMessage({ id: 'reference.required' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
				level: yup
					.string()
					.required(`${intl.formatMessage({ id: 'level.required' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
				description: yup
					.string()
					.required(`${intl.formatMessage({ id: 'description.required' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
				referenceId: yup
					.string()
					.required(`${intl.formatMessage({ id: 'referenceId.required' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
				instruments: yup
					.array()
					.required()
					.min(1, intl.formatMessage({ id: 'atleast.one.instrument.required' }))
					.of(
						yup.object().shape({
							name: yup
								.string()
								.required(
									`${intl.formatMessage({ id: 'name.required' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
								),
						})
					),
			})
		),
	});

	const {
		control,
		handleSubmit,
		setValue,
		reset,
		formState: { errors, isDirty },
		clearErrors,
	} = useForm({
		defaultValues: useMemo(() => {
			return {
				categories,
			};
		}, [categories]),
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setIsFormDirty && setIsFormDirty(isDirty);
	}, [isDirty, setIsFormDirty]);

	const { fields, move, remove, append } = useFieldArray({
		control,
		name: 'categories',
	});

	const levelOptions = [
		{ value: 0, label: intl.formatMessage({ id: 'check.item.all' }) },
		{ value: 1, label: intl.formatMessage({ id: 'check.item.possible' }) },
		{ value: 2, label: intl.formatMessage({ id: 'check.item.propable' }) },
	];

	useImperativeHandle(ref, () => ({
		submitForm() {
			handleSubmit(onSubmit)();
		},
		resetForm() {
			reset({ categories });
		},
	}));

	const addCategory = () => {
		const categoryId = generateUid();

		const newCategory = {
			categoryId,
			category: '',
			referenceId: '',
			description: '',
			level: '',
			instruments: [{ name: '' }],
		};

		append(newCategory);
	};

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const [cursor, setCursor] = useState('grab');
	const handleDragStart = () => setCursor('grabbing');

	const handleDragEnd = ({ active, over }) => {
		setCursor('grab');

		const activeId = active.id;
		const overId = over.id;

		try {
			if (activeId === overId) return;

			if (activeId !== overId) {
				const oldIndex = fields.findIndex(({ id }) => id === activeId);
				const newIndex = fields.findIndex(({ id }) => id === overId);

				move(oldIndex, newIndex);
				toast.success(intl.formatMessage({ id: 'references.order.success' }));
			}
		} catch {
			toast.error(intl.formatMessage({ id: 'references.order.failed' }));
		}
	};

	return (
		<form ref={ref}>
			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
				<Grid container spacing={2} alignItems='stretch'>
					<SortableContext items={fields} strategy={rectSortingStrategy}>
						{fields.map(({ id, categoryId, category, referenceId, level, description }, index) => (
							<Grid item key={id} xs={12} md={6} lgx={6} xl={4}>
								<ReferenceCard
									handle={true}
									setValue={setValue}
									{...{
										index,
										id,
										categoryId,
										category,
										referenceId,
										level,
										levelOptions,
										description,
										control,
										errors,
										instruments,
										cursor,
										clearErrors,
										remove,
									}}
								/>
							</Grid>
						))}
					</SortableContext>
					<Grid item xs={12} md={6} lgx={4} xl={3}>
						<ReferenceAddCard {...{ addCategory }} />
					</Grid>
				</Grid>
			</DndContext>
		</form>
	);
});

export default ReferenceForm;
