import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ maxHeight = '10rem', height = '10rem', offset = 0.8 }) => ({
    position: 'relative',
    maxHeight: maxHeight,
    overflow: 'hidden',
    '&:after': {
        content: '\'\'',
        position: 'absolute',
        top: `calc(${offset} * ${maxHeight})`,
        left: 0,
        height: height,
        width: '100%',
        background: '-webkit-linear-gradient(rgba(255, 255,255, .4), rgba(255, 255, 255, 1))'
    }
}));

const BlurredBox = ({ maxHeight, height, offset, children, ...props }) => {
    return (
        <StyledBox
            maxHeight={maxHeight}
            height={height}
            offset={offset}
            {...props}
        >
            {children}
        </StyledBox>
    );
};

export default BlurredBox;
