import { FormattedMessage } from 'react-intl';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import htmlParser from 'components/HtmlParser';
import { useGetCompanyContractQuery, useGetContractWithVersionQuery, useGetLatestContractQuery } from 'containers/App/contractApi';
import { useMemo } from 'react';
import { getCompany, getCompanyId } from 'utils/auth/company';
import styles from '../priceStyles';

const PricingView = () => {
	const company = getCompany();
	const companyId = company?.CompanyId ?? getCompanyId();

	const {
		data: companyContract,
		isLoading: isCompanyContractLoading,
		isFetching: isCompanyContractFetching,
	} = useGetCompanyContractQuery({ companyId, type: 'TermsContract' }, { skip: !companyId });

	const {
		data: contractData,
		isLoading: isContractLoading,
		isFetching: isContractFetching,
	} = useGetContractWithVersionQuery(
		{ companyId, type: companyContract?.contractType, version: companyContract?.contractVersion },
		{ skip: !companyId || !companyContract }
	);

	const {
		data: newContractData,
		isLoading: isNewContractLoading,
		isFetching: isNewContractFetching,
	} = useGetLatestContractQuery(
		{ companyId, type: 'TermsContract' },
		{ skip: contractData || isCompanyContractLoading || isCompanyContractFetching || isContractLoading || isContractFetching }
	);

	const isLoading =
		isNewContractLoading ||
		isNewContractFetching ||
		isContractLoading ||
		isContractFetching ||
		isCompanyContractLoading ||
		isCompanyContractFetching;

	const pricingHtml = useMemo(() => {
		return htmlParser(contractData?.contractPricingData ?? newContractData?.contractPricingData);
	}, [contractData, newContractData]);

	return (
		<Container>
			<Typography component='div' align='left' color='primary'>
				<h1>
					<FormattedMessage id='pricing.modal.header' />
				</h1>
				{isLoading ? (
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '10rem' }}>
						<CircularProgress />
					</Box>
				) : (
					<Box sx={{ ...styles }}>{pricingHtml}</Box>
				)}
			</Typography>
		</Container>
	);
};

export default PricingView;
