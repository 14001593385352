import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type Props = SvgIconProps & {};

const Logo: React.FC<Props> = ({ sx, ...otherProps }) => {
	return (
		<SvgIcon
			{...otherProps}
			sx={[
				{
					width: '187px',
					height: '20px',
				},
				...(Array.isArray(sx) ? sx : [sx]),
			]}
			viewBox='0 0 187 20'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M39.9903 10.8543C40.6032 9.69587 40.9071 8.3991 40.873 7.08902C40.9253 6.14887 40.7624 5.20917 40.3966 4.34149C40.0309 3.47381 39.472 2.70102 38.7625 2.08197C37.0559 0.799792 34.9476 0.168895 32.8172 0.302822H24.1266V10.4273H22.4414L20.924 14.2891H24.1275V19.6414H29.1763V14.2891H32.9285L35.7425 19.6414H41.4504L37.4234 13.3239C38.5055 12.7743 39.3993 11.9144 39.9903 10.8543ZM35.2311 9.48818C34.5634 10.0405 33.7098 10.3171 32.845 10.2612H29.1763V4.32984H32.8415C33.7039 4.27417 34.5557 4.54484 35.2277 5.08811C35.5167 5.36653 35.7416 5.70448 35.887 6.07851C36.0323 6.45255 36.0946 6.85373 36.0694 7.25423C36.0974 7.66487 36.0371 8.07675 35.8925 8.46211C35.7479 8.84747 35.5224 9.19734 35.2311 9.48818Z'
				fill='#F7F5F0'
			/>
			<path
				d='M79.9979 11.8699L71.1978 0.227936H66.7568V19.5665H71.4743V8.00807L80.2466 19.5674H84.6606V0.227936H79.971L79.9979 11.8699Z'
				fill='#F7F5F0'
			/>
			<path d='M103.846 0.227936H87.2659V4.39323H93.0051V19.5665H98.0539V4.39409H103.847L103.846 0.227936Z' fill='#F7F5F0' />
			<path
				d='M51.6966 0.301971L47.3487 10.4265H44.8521L43.3347 14.2883H45.6904L43.3921 19.6405H48.5783L50.6818 14.2883H57.5515L59.6132 19.6405H64.9907L56.8802 0.301971H51.6966ZM52.201 10.4265L54.1523 5.46119L56.0654 10.4265H52.201Z'
				fill='#F7F5F0'
			/>
			<path
				d='M7.86539 5.01943C8.70655 4.51934 9.66955 4.26144 10.648 4.2742C11.5745 4.28348 12.4895 4.48049 13.3377 4.85334C14.2303 5.22798 15.0481 5.76057 15.7516 6.42554L18.6212 2.89418C17.5519 1.96644 16.316 1.25026 14.9794 0.783704C13.6022 0.271216 12.1453 0.00626882 10.6759 0.00108817C8.78022 -0.0250621 6.9078 0.420479 5.22709 1.29763C3.64731 2.11727 2.32134 3.35275 1.39224 4.87074C0.459943 6.40307 -0.0222208 8.16687 0.00091274 9.96039C-0.0235459 11.7617 0.443977 13.5356 1.35311 15.0909C2.2409 16.605 3.52343 17.8493 5.06361 18.691C6.68294 19.5758 8.50379 20.0264 10.3489 19.9988C11.7967 19.9815 13.2334 19.7443 14.6099 19.2953C15.9663 18.8959 17.2478 18.276 18.403 17.4605V10.4256H12.6968L11.1794 14.2874H14.0446V14.757C13.0185 15.3215 11.8763 15.6422 10.7063 15.6944C9.71813 15.7055 8.74551 15.4479 7.89235 14.9492C7.05359 14.4603 6.36625 13.7492 5.90623 12.8943C5.42057 11.9921 5.17363 10.9806 5.18883 9.95603C5.17483 8.94488 5.42193 7.94721 5.90623 7.05947C6.36482 6.21629 7.04144 5.51173 7.86539 5.01943Z'
				fill='#F7F5F0'
			/>
			<path
				d='M106.446 8.04032H105.363L103.846 11.9021H106.446V19.5666H123.026V15.5413H111.495V11.9039H118.471L119.989 8.04206H111.496V4.3959H123.027V0.230621H106.446V8.04032Z'
				fill='#F7F5F0'
			/>
			<path
				d='M134.912 0.227936H125.632V19.5665H134.912C139.82 19.5665 144.303 15.5882 144.303 10.6803V9.11504C144.302 4.20626 139.82 0.227936 134.912 0.227936ZM139.747 10.3655C139.747 11.7377 139.202 13.0538 138.231 14.0241C137.261 14.9944 135.945 15.5395 134.573 15.5395H130.68V4.25583H134.573C135.945 4.25583 137.261 4.80095 138.231 5.77127C139.202 6.74158 139.747 8.0576 139.747 9.42983V10.3655Z'
				fill='#F7F5F0'
			/>
			<path d='M165.662 11.649H168.492L167.077 8.04895L165.662 11.649Z' fill='white' />
			<path
				d='M176.954 0.227936H163.274C158.366 0.227936 153.883 4.20627 153.883 9.11418V10.6794C153.883 15.5873 158.365 19.5657 163.274 19.5657H176.953C181.861 19.5657 186.345 15.5873 186.345 10.6794V9.11418C186.345 4.20627 181.861 0.227936 176.954 0.227936ZM170.078 15.6421L169.397 13.936H164.759L164.077 15.6421H160.803L165.441 3.92365H168.715L173.353 15.6421H170.078ZM177.409 15.6421H174.492V3.92279H177.409V15.6421Z'
				fill='white'
			/>
		</SvgIcon>
	);
};

export default Logo;
