import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';

import ContentContainer from 'components/Containers/ContentContainer';
import htmlParser from 'components/HtmlParser';
import MainTitle from 'components/Titles/MainTitle';
import Subtitle from 'components/Titles/Subtitle';
import MainSection from 'views/FinancialPlanPage/FinancialPlan/MainContent/MainSection';
import ChartToggleTabs from './ChartToggleTabs';
import EconomicalChart from './EconomicalChart';
import PrintEconomicalTable from './PrintEconomicalTable';
import PrintInfoItem from './PrintInfoItem';
import PrintTags from './PrintTags';
import PrintTeamMember from './PrintTeamMember';

import { getCompanyId } from 'utils/auth/company';
import { combineEconomyData, parseEconomicalData } from '../utils';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';

import './index.css';

const useStyles = makeStyles(theme => ({
	spinnerContainer: {
		height: 'calc(100vh - 64px - 164px)',
		[theme.breakpoints.up('sm')]: {
			height: 'calc(100vh - 64px - 134px)',
		},
	},
	icon: {
		position: 'fixed',
		right: theme.spacing(1),
		top: theme.spacing(9),
		[theme.breakpoints.up('sm')]: {
			top: theme.spacing(1),
		},
	},
	section: {
		'& h2, h3, h4, p, label, span, li, td': {
			color: theme.palette.primary.main,
		},
	},
}));

const BusinessPlanPrintPage = () => {
	const intl = useIntl();
	const classes = useStyles();

	const companyId = getCompanyId();

	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const companyEconomicalData = company?.economicalData;

	const businessPlan = useSelector(state => state.businessPlan);
	// main + tags
	const main = businessPlan?.main;
	const mainLoading = main?.loading;
	const mainData = main?.data;
	const businessId = mainData?.businessId;
	// infoItems
	const infoItems = businessPlan?.infoItems;
	const infoItemsLoading = infoItems?.loading;
	const infoItemsData = infoItems?.data;
	// teamMembers
	const teamMembers = businessPlan?.teamMembers;
	const teamMembersLoading = teamMembers?.loading;
	const teamMembersData = teamMembers?.data;
	// economicaldata
	const economics = businessPlan?.economicalData;
	const economicalData = economics?.data;
	const economicalDataLoading = economics?.loading /* || !companyEconomicalData */ || isCompanyLoading;

	const [tab, setTab] = useState(0);

	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
		documentTitle: `${intl.formatMessage({ id: 'business.plan.title' })}: ${company?.name}, ${company?.businessId}`,
	});
	const loading = isCompanyLoading || mainLoading || infoItemsLoading || teamMembersLoading || economicalDataLoading;

	return loading ? (
		<Grid container justifyContent='center' alignItems='center' className={classes.spinnerContainer}>
			<Grid item>
				<CircularProgress size={24} color='primary' />
			</Grid>
		</Grid>
	) : (
		<>
			<ContentContainer sx={{ maxWidth: 'lg' }}>
				<div ref={printRef}>
					<MainTitle margin={0}>{company?.name}</MainTitle>
					{(businessId || mainData?.tagsArray) && <PrintTags businessId={businessId} tagsArray={mainData?.tagsArray} />}
					<MainSection hidden={infoItemsData?.length === 0}>
						<Grid container spacing={2} sx={{ mt: 0 }}>
							{infoItemsData?.map(({ infoId, title, value }) => (
								<Grid item key={infoId} xs={12} sm={6} lg={3}>
									<PrintInfoItem title={title} value={value} />
								</Grid>
							))}
						</Grid>
					</MainSection>
					<MainSection hidden={teamMembersData?.length === 0}>
						<Subtitle mt={0} mb={0}>
							{intl.formatMessage({ id: 'business.plan.member.card.title' })}
						</Subtitle>
						<Grid container spacing={2}>
							{teamMembersData?.map(({ memberId, avatar, firstname, lastname, position, description, linkedInUrl }) => (
								<Grid item key={memberId} xs={12} sm={6} lg={3}>
									<PrintTeamMember
										avatar={avatar}
										firstname={firstname}
										lastname={lastname}
										position={position}
										description={description}
										linkedInUrl={linkedInUrl}
									/>
								</Grid>
							))}
						</Grid>
					</MainSection>
					<MainSection hidden={false}>
						<Stack direction='row' spacing={2}>
							<Subtitle mt={0} mb={0}>
								{intl.formatMessage({ id: 'business.plan.economical.table.title' })}
							</Subtitle>
							<ChartToggleTabs tab={tab} setTab={setTab} />
						</Stack>
						{tab === 0 ? (
							<EconomicalChart
								data={
									companyEconomicalData
										? combineEconomyData(companyEconomicalData, economicalData)
										: parseEconomicalData(economicalData)
								}
							/>
						) : (
							<PrintEconomicalTable
								data={
									companyEconomicalData
										? combineEconomyData(companyEconomicalData, economicalData)
										: parseEconomicalData(economicalData)
								}
							/>
						)}
					</MainSection>
					{mainData?.pitch && (
						<div className={classes.section}>
							<Subtitle mt={0} mb={0}>
								{intl.formatMessage({ id: 'businessplan.pitch' })}
							</Subtitle>
							{htmlParser(mainData?.pitch)}
						</div>
					)}
					{mainData?.problem && (
						<div className={classes.section}>
							<Subtitle mt={0} mb={0}>
								{intl.formatMessage({ id: 'businessplan.problem' })}
							</Subtitle>
							{htmlParser(mainData?.problem)}
						</div>
					)}
					{mainData?.solution && (
						<div className={classes.section}>
							<Subtitle mt={0} mb={0}>
								{intl.formatMessage({ id: 'businessplan.solution' })}
							</Subtitle>
							{htmlParser(mainData?.solution)}
						</div>
					)}
					{mainData?.markets && (
						<div className={classes.section}>
							<Subtitle mt={0} mb={0}>
								{intl.formatMessage({ id: 'businessplan.markets' })}
							</Subtitle>
							{htmlParser(mainData?.markets)}
						</div>
					)}
					{mainData?.globalMarket && (
						<div className={classes.section}>
							<Subtitle mt={0} mb={0}>
								{intl.formatMessage({ id: 'businessplan.globalMarket' })}
							</Subtitle>
							{htmlParser(mainData?.globalMarket)}
						</div>
					)}
					{mainData?.vision && (
						<div className={classes.section}>
							<Subtitle mt={0} mb={0}>
								{intl.formatMessage({ id: 'businessplan.vision' })}
							</Subtitle>
							{htmlParser(mainData?.vision)}
						</div>
					)}
				</div>
			</ContentContainer>
			<IconButton aria-label='print' onClick={handlePrint} className={classes.icon}>
				<PrintIcon color='primary' />
			</IconButton>
		</>
	);
};

export default BusinessPlanPrintPage;
