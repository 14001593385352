import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { DragDropContext } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import WidgetContainer from 'components/Containers/WidgetContainer';
import { ProjectTaskColumn } from 'containers/Projects/Project/Task';
import ProjectCostColumn from './ProjectCostColumn';

import { moveCost } from 'containers/Projects/slices';

const ProjectCostTitle = ({ hideTitle }) => {
	if (hideTitle) return null;

	return <FormattedMessage id='project.costs' />;
};

const ProjectCostList = ({ project, tasks, costs, padding = 0, hideTitle = false }) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const mdUp = useMediaQuery(theme.breakpoints.up('md'));

	const columns = useSelector(state => state.kanban?.columns);

	const handleDragEnd = async ({ source, destination, draggableId }) => {
		try {
			// Dropped outside the column
			if (!destination) {
				return;
			}

			// Card has not been moved
			if (source.droppableId === destination.droppableId && source.index === destination.index) {
				return;
			}

			if (source.droppableId === destination.droppableId) {
				await dispatch(moveCost(draggableId, destination.index));
			} else {
				await dispatch(moveCost(draggableId, destination.index, destination.droppableId));
			}

			toast.success(<FormattedMessage id='project.cost.move.success' />);
		} catch {
			toast.error(<FormattedMessage id='project.cost.move.failed' />);
		}
	};

	return (
		<WidgetContainer padding={padding} title={<ProjectCostTitle hideTitle={hideTitle} />}>
			<div id='costs' style={{ display: mdUp ? 'flex' : 'block' }}>
				<Box
					sx={{
						p: '.5rem',
						pl: 0,
						borderRight: {
							sx: 'none',
							md: '1px solid rgb(247, 245, 240)',
						},
						mr: {
							sx: 0,
							md: '.5rem',
						},
						width: {
							xs: '100%',
							md: '20%',
						},
						minWidth: '18rem',
					}}
				>
					<ProjectTaskColumn project={project} tasks={tasks} costs={costs} />
				</Box>
				<Box sx={{ overflowX: 'auto', width: '100%' }}>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Grid
							container
							sx={{
								'& .MuiGrid-item': {
									pl: {
										sm: 0,
										md: 0,
									},
									'&:nth-of-type(even)': {
										pl: {
											sm: 1,
											md: 0,
										},
									},
								},
								minWidth: mdUp && '900px', // mdUp && '100%'
							}}
						>
							{columns?.map(column => (
								<Grid
									key={column.id}
									sx={{
										padding: {
											xs: '.5rem 0',
											md: '.5rem',
										},
										mt: {
											xs: 1,
											md: 0,
										},
									}}
									item
									xs={12}
									sm={6}
									md={3}
									lg={3}
								>
									<ProjectCostColumn project={project} columnId={column.id} key={column.id} tasks={tasks} />
								</Grid>
							))}
						</Grid>
					</DragDropContext>
				</Box>
			</div>
		</WidgetContainer>
	);
};

export default ProjectCostList;
