import React from 'react';
import { FormattedDateParts } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
    text: {
        color: theme.palette.textPrimary.secondary,
    },
}));

const FooterLabel = ({ sx = {} }) => {
    const classes = useStyles();

    return (
        <Grid container justifyContent='center' alignItems='center' className={classes.root} sx={sx}>
            <Grid item>
                <Typography component='p' className={classes.text}>
                    <FormattedDateParts value={new Date()} year='numeric'>
                        {parts => parts[0].value}
                    </FormattedDateParts>
                </Typography>
            </Grid>
            <Grid item>
                <Typography component='p' className={classes.text}>
                    &nbsp;© Grants Funding Oy
                </Typography>
            </Grid>
        </Grid >
    );
};

export default FooterLabel;
