export const generateMrrMonths = () => {
	let currentYear = new Date();
	let start = new Date('2021-01-01'); //yyyy-mm-dd
	let end = new Date(`${currentYear.getFullYear() + 1}-12-31`); //yyyy-mm-dd

	let ret = [];

	while (start <= end) {
		let mm = start.getMonth() + 1 >= 10 ? start.getMonth() + 1 : '0' + (start.getMonth() + 1);
		let yyyy = start.getFullYear();

		ret.push(`${yyyy}-${mm}`);

		start = new Date(start.getFullYear(), start.getMonth() + 1, 1);
	}

	return ret;
};

const parseMrrDataToCsvCompliant = (mrrData: any) => {
	const mrrMonths = generateMrrMonths();
	let monthsChurn: any = [];

	for (const month of mrrMonths) {
		monthsChurn[month] = { gainValue: 0, lostValue: 0, new: [], lost: [] };
	}

	let data = mrrData.map((item: any) => {
		let monthsData: any[] = [];
		let pushItem = { companyName: item.companyName, companyId: item.companyId, monthsData };
		let monthIndex = 0;

		for (const month of mrrMonths) {
			const monthData = item.months[month];

			if (!monthData) {
				pushItem.monthsData.push({
					month,
					value: '',
				});

				if (pushItem.monthsData[monthIndex - 1] && pushItem.monthsData[monthIndex - 1].value > 0) {
					monthsChurn[month].lost.push({
						companyName: item.companyName,
						companyId: item.companyId,
						items: pushItem.monthsData[monthIndex - 1],
					});
					monthsChurn[month].lostValue = monthsChurn[month].lostValue + pushItem.monthsData[monthIndex - 1].value;
				}

				monthIndex++;

				continue;
			}

			if (pushItem.monthsData[monthIndex - 1] && pushItem.monthsData[monthIndex - 1].value === 0) {
				monthsChurn[month].new.push({
					companyName: item.companyName,
					companyId: item.companyId,
					items: Object.assign({}, monthData, { month }),
				});
				monthsChurn[month].gainValue = monthsChurn[month].gainValue + monthData;
			}

			pushItem.monthsData.push(Object.assign({}, { value: monthData.value }, { month }));

			monthIndex++;
		}

		const obj: any = {};

		pushItem.monthsData.forEach(element => {
			obj[element.month] = Number(element.value) ? parseInt(element.value) : '';
		});

		return {
			companyName: item.companyName,
			companyId: item.companyId,
			clientValue: Number(item.clientValue) ? parseInt(item.clientValue) : '',
			...obj,
		};
	});

	data?.sort((a: any, b: any) => {
		const nameA = a.companyName?.toUpperCase(); // ignore upper and lowercase
		const nameB = b.companyName?.toUpperCase(); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		// names must be equal
		return 0;
	});

	return data;
};

const ConvertToCSV = (objArray: any[]) => {
	let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
	let str = '';

	let headingLine = objArray[0];
	let line = '';

	// get headings line
	for (let heading in headingLine) {
		if (line !== '') line += ';';

		line += heading;
	}

	// insert line change
	str += line + '\r\n';

	for (let i = 0; i < array.length; i++) {
		let line = '';
		for (let index in array[i]) {
			if (line !== '') line += ';';

			try {
				let value = array[i][index];
				line += value.replaceAll(';', ' ').replaceAll('.', ',');
			} catch (error) {
				line += array[i][index];
			}
		}

		str += line + '\r\n';
	}

	return str;
};

export const CSVGenerator = ({ mrrByCompany }: any) => {
	const parsedMrrData = parseMrrDataToCsvCompliant(mrrByCompany);

	return ConvertToCSV(parsedMrrData);
};
