import React from 'react';
import { styled } from '@mui/styles';
import { Box } from '@mui/material';

const CenteringContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export default function ({ children, component = 'div' }) {
    return <CenteringContainer component={component}>{children}</CenteringContainer>;
}