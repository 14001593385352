import React, { forwardRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormControlledInput } from 'components/Form';
import CustomPrompt from 'components/CustomPrompt';

import { getDirtyKeys } from 'components/Form/utils/utils';

const getSchema = intl => yup.object().shape({
    name: yup.string().required(
        `${intl.formatMessage({ id: 'advanced.new.list.name' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
    ),
});

const CreateListForm = forwardRef(({ onSubmit, setIsDirty, createListLoading }, ref) => {
    const intl = useIntl();
    const schema = getSchema(intl);

    const { control, handleSubmit, formState: { dirtyFields, errors } } = useForm({
        defaultValues: {
            name: ''
        },
        resolver: yupResolver(schema),
    });

    const dirtyKeys = getDirtyKeys(dirtyFields);
    const isDirty = dirtyKeys.length > 0;

    useEffect(() => {
        setIsDirty(isDirty);
    }, [isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <form ref={ref} onSubmit={handleSubmit(data => onSubmit(data))}>
            <CustomPrompt dirtyFields={dirtyFields} isDirty={isDirty} translation='test' />
            <FormControlledInput
                name='name'
                defaultValue=''
                disabled={createListLoading}
                control={control}
                error={errors?.name?.message}
                label={intl.formatMessage({ id: 'customer.page.modal.form.listName.label' })}
                placeholder={intl.formatMessage({ id: 'customer.page.modal.form.listName.placeholder' })}
            />
        </form>
    );
});

export default CreateListForm;
