import React from 'react';
import { useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
	title: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		textAlign: 'unset',
		padding: theme.spacing(1, 1, 1, 2),
		backgroundColor: theme.palette.primary.white,
	},
	titleText: {
		flexGrow: 1,
		fontSize: '1.15rem',
		fontWeight: 600,
		color: theme.palette.primary.main,
	},
}));

const HtmlDialog = ({
	content,
	title,
	buttonText,
	loading = false,
	fullWidth = false,
	maxWidth = 'xl',
	color = 'primary',
	size = 'small',
	variant = 'text',
}) => {
	const intl = useIntl();
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState('paper');

	const handleClickOpen = scrollType => () => {
		setOpen(true);
		setScroll(scrollType);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	return (
		<React.Fragment>
			<Button size={size} color={color} onClick={handleClickOpen('paper')} variant={variant}>
				{buttonText}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={scroll}
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'
				fullWidth={fullWidth}
				maxWidth={maxWidth}
			>
				<DialogTitle align='center' id='confirm-dialog' className={classes.title}>
					<Typography className={classes.titleText}>{title}</Typography>
					<IconButton aria-label='close' onClick={handleClose}>
						<CloseIcon className={classes.closeIcon} />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers={scroll === 'paper'} className={classes.bgWhite}>
					{loading ? (
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '10rem' }}>
							<CircularProgress />
						</Box>
					) : (
						<DialogContentText component='div' id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
							{content}
						</DialogContentText>
					)}
				</DialogContent>
				<DialogActions className={classes.bgWhite}>
					<Button onClick={handleClose}>{intl.formatMessage({ id: 'common.close' })}</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

export default HtmlDialog;
