import Menu from '@mui/material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	menu: {
		backgroundColor: theme.palette.primary.white,
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: theme.palette.secondary.lightBeige,
		borderRadius: 0,
	},
}));

const StyledMenu = props => {
	const { children, variant } = props;
	const classes = useStyles();
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<Menu
			anchorOrigin={{
				vertical: variant === 'menu' ? 'top' : 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: variant === 'menu' ? 'top' : 'bottom',
				horizontal: 'left',
			}}
			classes={{
				paper: classes.menu,
			}}
			PaperProps={{
				style: {
					transform: smUp && variant !== 'menu' && 'translate(1.25rem, 1rem)',
				},
			}}
			{...props}
		>
			{children}
		</Menu>
	);
};

export default StyledMenu;
