/* eslint-disable no-undef */
const config = {
	SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
	ENVIRONMENT: process.env.REACT_APP_ENV,
	GATAG: process.env.REACT_APP_GA_TAG,
	RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
	API_ROOT: 'api',
	ROWS_PER_PAGE: 25,
	CUSTOMER_FETCH_SIZE: 1000,
	TINY_MCE_APIKEY: 'ti4oskfqq9phlq73w61y7ppjr41gf72l7b32jbo97ei1qrnx',
};

export const API_ROOT = config.API_ROOT;
export const ROWS_PER_PAGE = config.ROWS_PER_PAGE;
export const TINY_MCE_APIKEY = config.TINY_MCE_APIKEY;
export const CUSTOMER_FETCH_SIZE = config.CUSTOMER_FETCH_SIZE;

export default config;
