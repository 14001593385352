import * as React from 'react';
import { Box, BoxProps } from '@mui/material';
import { isArray, isEqual } from 'lodash';
import { Control, FieldValues, FieldPath, useWatch } from 'react-hook-form';

type Props<FV extends FieldValues> = BoxProps & { control: Control<FV>; name: FieldPath<FV>; value: any; children?: React.ReactNode };

function RfpVisibilityToggle<FV extends FieldValues>({ control, name, value, sx, ...otherProps }: Props<FV>) {
	const fieldValue = useWatch({ control, name });

	return <Box {...otherProps} sx={[{ display: isEqual(fieldValue, value) ? 'block' : 'none' }, ...(isArray(sx) ? sx : [sx])]} />;
}

export default RfpVisibilityToggle;
