import React from 'react';

import FormControl from '@mui/material/FormControl';

import FormLabel from './FormLabel';
import FormError from './FormError';

/**
 * Controlled Textfield, dependencies hoook-form, mui
 * 
 * @param {*} error
 * @param {*} label
 * @param {*} children
 * @returns 
 */
const FormControlWithLabel = ({ error, label, children, sx }) => {
	return (
		<FormControl fullWidth sx={sx}>
			<FormLabel>{label}</FormLabel>
			{children}
			<FormError error={error} />
		</FormControl>
	);
};

export default FormControlWithLabel;
