import { useIntl } from 'react-intl';

import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	IconButton,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';

import FormatCurrency from 'components/Formatters/FormatCurrency';
import { useHistory } from 'react-router-dom';

export const SingleLicenceDialog = ({ open, license, closeModal }: { open: boolean; license: any; closeModal: any }) => {
	const { formatMessage } = useIntl();
	const history = useHistory();

	if (!license) return null;

	return (
		<Dialog
			aria-labelledby={'licenses'}
			aria-describedby={'licenses'}
			fullWidth
			maxWidth='lg'
			open={open}
			onClose={closeModal}
			scroll='paper'
		>
			<DialogContent>
				<Stack sx={{ width: '100%', mb: '2rem' }} direction='row' alignItems='flex-start' alignContent='space-between'>
					<Box sx={{ flexGrow: 1 }}>
						<Button variant='contained' onClick={() => history.push(`/admin/company/${license.companyId}`)}>
							<Typography sx={{ fontSize: '1.2rem', fontWeight: 500, flexGrow: 1 }}>
								{license.companyName} - {license.companyId}
							</Typography>
						</Button>
					</Box>
					<Box textAlign='right'>
						<IconButton aria-label='close' onClick={closeModal}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Stack>

				<TableContainer component={Paper}>
					<Table aria-label='License Details'>
						<TableBody>
							<TableRow>
								<TableCell>{formatMessage({ id: 'mrr.companyName' })}</TableCell>
								<TableCell>{license.companyName}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.comapanyId' })}</TableCell>
								<TableCell>{license.companyId}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.form.type' })}</TableCell>
								<TableCell>{formatMessage({ id: `mrr.statistics.${license.type}` })}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.form.licensePackage' })}</TableCell>
								<TableCell>{license.package}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.form.contractPeriodType' })}</TableCell>
								<TableCell>{license.contractPeriod}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.contractBillingPeriod' })}</TableCell>
								<TableCell>{license.contractBillingPeriod}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.form.contractMonetaryValue' })}</TableCell>
								<TableCell>
									<FormatCurrency value={license.contractMonetaryValue} maximumFractionDigits={0} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.contractMonthlyValue' })}</TableCell>
								<TableCell>
									<FormatCurrency value={license.contractMonthlyValue} maximumFractionDigits={0} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'mrr.startDate' })}</TableCell>
								<TableCell>{license.startDate}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'mrr.endDate' })}</TableCell>
								<TableCell>{license.endDate}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.form.mrrContractStartDate' })}</TableCell>
								<TableCell>{license.mrrContractStartDate}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.contractFirstCheckDate' })}</TableCell>
								<TableCell>{license.contractFirstCheckDate}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'advanced.modal.author' })}</TableCell>
								<TableCell>{license.author}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'companydetails.license.terminationDate' })}</TableCell>
								<TableCell>{license.terminationDate}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'companydetails.license.comments' })}</TableCell>
								<TableCell>{license.comments}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.form.salesPerson' })}</TableCell>
								<TableCell>{license.salesPerson}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.form.payingCustomer' })}</TableCell>
								<TableCell>{license.payingCustomer}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'license.form.severaLink' })}</TableCell>
								<TableCell>{license.severaLink}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'companylist.table.isExpired' })}</TableCell>
								<TableCell>{license.isExpired ? 'Kyllä' : 'Ei'}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'companylist.table.isTerminated' })}</TableCell>
								<TableCell>{license.isTerminated ? 'Kyllä' : 'Ei'}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{formatMessage({ id: 'userdetails.user.isActive' })}</TableCell>
								<TableCell>{license.isActive ? 'Kyllä' : 'Ei'}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};
