import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import LinkIcon from '@mui/icons-material/Link';
import { Stack } from '@mui/material';

import TextLink from 'components/Button/TextLink';
import { WidgetTitle } from 'components/Containers/WidgetContainer';
import ModalDialog from 'components/Dialog/ModalDialog';
import getCompanyLink from 'utils/auth/getCompanyLink';
import ProjectForm from './ProjectForm';
import ProjectModalActions from './ProjectModalActions';
import { getProjectSchema } from './ProjectValidationSchema';

import StatusChip from 'components/Chip/StatusChip';
import { getDirtyKeys } from 'components/Form/utils/utils';
import { archiveProject, findItemByIdWithField } from 'containers/Projects/slices';
import { isContributorToken } from 'utils/auth/token';

const initialProject = {
	projectId: '',
	projectType: '',
	instrumentId: '',
	projectName: '',
	projectSize: 0,
	amountToApply: 0,
	deminimisAmount: 0,
	dynamic: {},
	isUsed: false,
	projectSummary: '',
	projectBackground: '',
	projectService: '',
	projectMarket: '',
	projectVision: '',
	projectDevelopment: '',
	startDate: '',
	endDate: '',
	projectPhase: '',
	instrumentName: '',
	instrumentType: '',
	instrumentProvider: '',
};

/**
 * Project form base for modal update
 *
 * @param {boolean} datareset - determines do the reset form with the initial project or use form updated data (new vs. update). Ensure that the form data is updated on update process. 
 * @returns React component ProjectModalFormBase
 */

const ProjectModalFormBase = ({
	project = {},
	instruments,
	handleClose,
	open,
	onSuccessAction = null,
	first = false,
	datareset = false,
	readonly,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const location = useLocation();

	const kanban = useSelector(state => state.kanban);
	const selectedProject = findItemByIdWithField(
		kanban?.projects?.data,
		'projectId',
		typeof project !== 'object' ? project : project.projectId
	);

	const isDeleting = useSelector(state => state.kanban?.isDeleting);

	const [loading, setLoading] = useState(false);
	const [init, setInit] = useState(null);
	const schema = getProjectSchema(intl);

	if (Object.keys(project)?.length === 0) {
		project = initialProject;
	}

	const {
		control,
		register,
		unregister,
		setValue,
		watch,
		handleSubmit,
		reset,
		getValues,
		formState: { dirtyFields, errors },
	} = useForm({
		defaultValues: {
			project,
		},
		resolver: yupResolver(schema),
	});

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	useEffect(() => {
		if (!init && project) {
			setInit(true);
			reset(project);
		}
	}, [project]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = async (data, projectSaveFunction) => {
		setLoading(true);

		const success = await dispatch(projectSaveFunction(data));

		!first && setLoading(false);

		if (success) {
			if (onSuccessAction) onSuccessAction();
			toast.success(<FormattedMessage id='project.saving.successed' />);

			if (datareset) {
				reset(data);
			} else {
				reset(/* initialProject */);
			}

			!first && handleClose();

			return;
		}

		toast.error(<FormattedMessage id='project.saving.failed' />);
	};

	const handleDraftPublish = data => {
		if (datareset) {
			reset(data);
		} else {
			reset(initialProject);
		}
	};

	const archive = async () => {
		const success = await dispatch(archiveProject(project.projectId));

		if (success) handleClose();
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const handleSuccess = data => {
		handleClose();
		handleDraftPublish(data);
	};

	const isLoading = loading;

	const ModalHeader = () => {
		if (readonly) {
			return <WidgetTitle>{project?.projectId ? project?.projectName : <FormattedMessage id='project.new.project' />}</WidgetTitle>;
		}

		return (
			<Stack sx={{ pr: '3rem' }} direction='row'>
				<TextLink
					disabled={!isContributorToken()}
					color={'transparent'}
					href={getCompanyLink(`/projects/${project?.projectId}`, location)}
				>
					<WidgetTitle>
						<Stack direction='row' alignItems='center'>
							{project?.projectId ? project?.projectName : <FormattedMessage id='project.new.project' />}&nbsp;
							<LinkIcon sx={{ position: 'relative' }} />
							<StatusChip sx={{ ml: '1rem' }} status={project?.projectState} />
						</Stack>
					</WidgetTitle>
				</TextLink>
			</Stack>
		);
	};

	return (
		<ModalDialog
			open={open}
			onClose={() => handleClose(dirtyFields)}
			disableEnforceFocus
			title={<ModalHeader />}
			actions={
				!readonly ? (
					<ProjectModalActions
						{...{
							project,
							isLoading,
							isDeleting,
							isDirty,
							confirmOpen,
							anchorEl,
							setAnchorEl,
							handleSuccess,
							handleSubmit,
							onSubmit,
							setConfirmOpen,
							archive,
						}}
					/>
				) : null
			}
		>
			<ProjectForm
				readonly={readonly}
				isLoading={isLoading}
				isDeleting={isDeleting}
				isDirty={isDirty}
				dirtyFields={dirtyFields}
				project={selectedProject ?? project}
				instruments={instruments}
				handleSubmit={handleSubmit}
				control={control}
				register={register}
				unregister={unregister}
				watch={watch}
				errors={errors}
				setValue={setValue}
				onSubmit={onSubmit}
				showExtended={false}
				noMargin={true}
				summary={true}
				closeModal={handleClose}
				onSuccess={handleDraftPublish}
				reset={reset}
				getValues={getValues}
			/>
		</ModalDialog>
	);
};

export default ProjectModalFormBase;
