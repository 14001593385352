import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { IntlGlobalProvider } from 'utils/helpers/intlGlobalProvider';
import fi_translations from '../translations/fi';

const formats = {
	number: {
		EUR: {
			style: 'currency',
			currency: 'EUR',
		},
	},
};

const Context = React.createContext();

const myCustomErrorFunction = err => {
	if (err.message.includes('MISSING_TRANSLATION')) return;

	console.error(err);
};

const IntlProviderWrapper = ({ children }) => {
	const [state] = useState({
		locale: 'fi',
		messages: fi_translations,
	});

	return (
		<Context.Provider value={state}>
			<IntlProvider
				key={state.locale}
				locale={state.locale}
				messages={state.messages}
				defaultLocale='fi'
				formats={formats}
				defaultFormats={formats}
				onError={myCustomErrorFunction}
			>
				<IntlGlobalProvider>{children}</IntlGlobalProvider>
			</IntlProvider>
		</Context.Provider>
	);
};

export { Context as IntlContext, IntlProviderWrapper };
