import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

type RequestForgottenPassword = {
	username: string | null;
};

type ChangeForgottenPassword = {
	id: string | null;
	username: string | null;
	password: string | null;
	newpassword: string | null;
	confirmpassword: string | null;
};

type ChangeCurrentPassword = {
	password: string | null;
	newpassword: string | null;
};

type ActivateAccount = {
	id: string | null;
	username: string | null;
	password: string | null;
	newpassword: string | null;
	confirmpassword: string | null;
};

const Tags = {
	Password: 'password',
};

const baseUrl = 'password';

export const passwordV2Api = createApi({
	reducerPath: 'passwordV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.Password],
	endpoints: builder => ({
		requestForgottenPassword: builder.mutation<any, RequestForgottenPassword>({
			query: ({ username }) => {
				if (isNil(username)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: '/forgot',
					data: { username },
				};
			},
		}),
		changeForgottenPassword: builder.mutation<any, ChangeForgottenPassword>({
			query: ({ id, username, password, newpassword, confirmpassword }) => {
				if (isNil(id) || isNil(username) || isNil(password) || isNil(newpassword) || isNil(confirmpassword)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: '/changetemppassword',
					data: { id, username, password, newpassword, confirmpassword },
				};
			},
		}),
		changeCurrentPassword: builder.mutation<any, ChangeCurrentPassword>({
			query: ({ password, newpassword }) => {
				if (isNil(password) || isNil(newpassword)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: '/change',
					data: { password, newpassword },
				};
			},
		}),
		activateAccount: builder.mutation<any, ActivateAccount>({
			query: ({ id, username, password, newpassword, confirmpassword }) => {
				if (isNil(id) || isNil(username) || isNil(password) || isNil(newpassword) || isNil(confirmpassword)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/activate/${id}`,
					data: { newpassword, confirmpassword },
				};
			},
		}),
	}),
});

export const {
	useActivateAccountMutation,
	useChangeCurrentPasswordMutation,
	useChangeForgottenPasswordMutation,
	useRequestForgottenPasswordMutation,
} = passwordV2Api;
