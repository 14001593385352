import React from 'react';
import { useIntl } from 'react-intl';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

const Connector = styled(StepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
        minHeight: '2rem',
        borderColor: theme.palette.secondary.grey400,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.secondary.grey400,
        },
    },
}));

const ProgressStep = ({ steps, activeStep, setActiveStep }) => {
    const intl = useIntl();

    return (
        <Box
            sx={{
                position: {
                    sm: 'unset',
                    smd: 'fixed'
                },
                mb: 2
            }}
        >
            <Typography sx={{ ml: 1, mb: 2, color: 'secondary.grey500', fontWeight: 500 }}>{intl.formatMessage({ id: 'scenario.stepper.title' })}</Typography>
            <Stepper activeStep={2} orientation='vertical' connector={<Connector />}>
                {steps.map(({ index, label, done, total }) => {
                    const StepIconComponent = ({ active, completed }) => {
                        return (
                            <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                sx={{
                                    width: 25
                                }}
                            >
                                {done === total
                                    ? (
                                        <CheckIcon
                                            sx={{
                                                fontSize: 15,
                                                color: (active || completed) && activeStep === index ? 'success.main' : 'secondary.grey400',
                                                marginLeft: '0.2rem'
                                            }}
                                        />
                                    )
                                    : (
                                        <CircleIcon
                                            sx={{
                                                fontSize: 12,
                                                color: (active || completed) && activeStep === index ? 'primary.main' : 'secondary.grey400',
                                            }}
                                        />
                                    )
                                }
                            </Box>
                        );
                    };

                    return (
                        <Step key={label}>
                            <Link
                                component='button'
                                underline='none'
                                onClick={() => activeStep !== index ? setActiveStep(index) : null}
                                sx={{
                                    '& p': {
                                        color: activeStep !== index && 'secondary.grey400',
                                    },
                                    '&:hover *': {
                                        color: activeStep !== index && 'secondary.grey700',
                                    },
                                    '&:hover': {
                                        cursor: activeStep === index && 'default',
                                    }
                                }}
                            >
                                <StepLabel
                                    StepIconComponent={StepIconComponent}
                                    sx={{
                                        padding: 0,
                                        '& .MuiStepLabel-label': {
                                            color: 'secondary.grey500'
                                        },
                                        '& .Mui-active, & .Mui-completed': {
                                            color: 'primary.main'
                                        },
                                    }}
                                >
                                    <Stack direction='row' spacing={1}>
                                        <Typography>{label}</Typography>
                                        <Typography>{done}/{total}</Typography>
                                    </Stack>
                                </StepLabel>
                            </Link>
                        </Step>
                    );
                })}
            </Stepper>
        </Box >
    );
};

export default ProgressStep;