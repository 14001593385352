import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import KanbanColumn from 'components/Kanban/KanbanColumn';
import Spinner from 'components/Spinner/Spinner';
import { useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import { find } from 'lodash';
import { getCompanyId } from 'utils/auth/company';
import { projectCostSelector } from '../../../slices';
import AddProjectCost from '../AddProjectCost/AddProjectCost';
import ProjectCostCard from './ProjectCostCard/ProjectCostCard';

const columnSelector = (state, columnId) => {
	const { columns } = state.kanban;

	return find(columns, column => column.id === columnId);
};

const ErrorNoTasks = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	textAlign: 'center',
	fontStyle: 'italic',
	color: theme.palette.secondary.grey500,
	fontSize: '1rem',
}));

const ColumnCostList = ({ column, costs, tasks }) => {
	if (tasks?.length === 0) return <ErrorNoTasks>Lisää projektitehtävä, jotta voit kohdistaa projektille kuluja</ErrorNoTasks>;
	if (column.costIds.length === 0) return <ErrorNoTasks>Ei kuluja</ErrorNoTasks>;

	const existingCosts = column.costIds.filter(costId => {
		const cost = costs.find(item => item.id === costId);

		if (cost) return true;

		return false;
	});

	if (!existingCosts || existingCosts.length === 0) {
		return <ErrorNoTasks>Ei kuluja</ErrorNoTasks>;
	}

	return (
		<>
			{existingCosts.map((costId, index) => {
				const cost = costs.find(item => item.id === costId);
				if (!cost) return null;

				const task = tasks.data?.find(task => task.projectTaskId === cost.projectTaskId);

				return (
					<Draggable draggableId={cost.id} index={index} key={cost.id}>
						{_provided => (
							<ProjectCostCard
								cost={cost}
								costId={cost.id}
								task={task}
								index={index}
								key={cost.id}
								column={column}
								ref={_provided.innerRef}
								style={{ ..._provided.draggableProps.style }}
								{..._provided.draggableProps}
								{..._provided.dragHandleProps}
							/>
						)}
					</Draggable>
				);
			})}
		</>
	);
};

const ProjectCostColumn = ({ project, columnId, tasks }) => {
	const column = useSelector(state => columnSelector(state, columnId));
	const [sum, setSum] = useState(0);
	const companyId = getCompanyId();

	const loading = !project;
	const costs = projectCostSelector(project);

	useEffect(() => {
		// always calculate the costs sum
		const sumOfCosts = column.totalSum(costs);
		setSum(sumOfCosts);
	}, [column, costs]); // eslint-disable-line react-hooks/exhaustive-deps

	const {
		data: instruments,
		isLoading: isInstrumenstLoading,
		isFetching: isInstrumenstFetching,
	} = useFetchInstrumentsByCompanyQuery({ companyId }, { skip: !companyId });

	const instrument = instruments?.find(instrument => instrument.id === project.instrumentId);

	return (
		<KanbanColumn
			name={<FormattedMessage id={`project.column.name.${column.id}`} />}
			sum={sum}
			footer={
				tasks?.length > 0 ? <AddProjectCost project={project} columnId={column.id} column={column} instrument={instrument} /> : null
			}
		>
			{loading ? (
				<Spinner size={20} />
			) : (
				<Droppable droppableId={column.id} type='card'>
					{provided => (
						<Box
							ref={provided.innerRef}
							sx={{
								flexGrow: 1,
								minHeight: 80,
							}}
						>
							<ColumnCostList column={column} costs={costs} tasks={tasks} />
							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			)}
		</KanbanColumn>
	);
};

export default ProjectCostColumn;
