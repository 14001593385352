import { forwardRef } from 'react';

import Spinner from 'components/Spinner/Spinner';

import ProjectTaskCostColumn from '../ProjectTaskCostColumn';
import ProjectTaskForm from '../ProjectTaskForm/ProjectTaskForm';

// const validateRequired = (value: string) => !!value?.length;

// function validateUser(cost: any) {
// 	return {
// 		name: !validateRequired(cost.name) ? 'name is Required' : '',
// 		category: !validateRequired(cost.category) ? 'category is Required' : '',
// 	};
// }

// type ChildProps = {
// 	data: Array<any>;
// 	addTaskCost: any;
// 	task: any;
// };

// const CostTable: React.FC<ChildProps> = ({ data, task, addTaskCost, ...otherProps }) => {
// 	const [editedUsers, setEditedUsers] = useState<Record<string, any>>({});
// 	const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

// 	function updateUser(values: any) {
// 		values.projectTaskId = task.projectTaskId;
// 		values.projectCostId = `${task.projectTaskId}_${generateCurrentTimeTicks()}`;

// 		addTaskCost(values);
// 	}
// 	const handleSaveUser: MRT_TableOptions<any>['onEditingRowSave'] = async ({ values, table }) => {
// 		const newValidationErrors = validateUser(values);
// 		if (Object.values(newValidationErrors).some(error => error)) {
// 			setValidationErrors(newValidationErrors);
// 			return;
// 		}
// 		setValidationErrors({});
// 		await updateUser(values);
// 		table.setEditingRow(null); //exit editing mode
// 	};
// 	const handleCreateUser: MRT_TableOptions<any>['onCreatingRowSave'] = async ({ values, table }) => {
// 		const newValidationErrors = validateUser(values);
// 		if (Object.values(newValidationErrors).some(error => error)) {
// 			setValidationErrors(newValidationErrors);
// 			return;
// 		}
// 		setValidationErrors({});
// 		await updateUser(values);
// 		table.setCreatingRow(null); //exit creating mode
// 	};

// 	const columns = [
// 		{
// 			accessorKey: 'projectTaskId',
// 			header: 'projectTaskId',
// 			enableEditing: false,
// 		},
// 		{
// 			accessorKey: 'name',
// 			header: 'name',
// 			enableEditing: true,
// 			size: 150,
// 			muiEditTextFieldProps: {
// 				required: true,
// 				error: !!validationErrors?.firstName,
// 				helperText: validationErrors?.firstName,
// 				//remove any previous validation errors when user focuses on the input
// 				onFocus: () =>
// 					setValidationErrors({
// 						...validationErrors,
// 						firstName: undefined,
// 					}),
// 				//optionally add validation checking for onBlur or onChange
// 			},
// 		},
// 		{
// 			accessorKey: 'category',
// 			header: 'category',
// 			enableEditing: true,
// 			size: 150,
// 			editVariant: 'select',
// 			editSelectOptions: ['123', '123123'],
// 			muiEditTextFieldProps: ({ row }: any) => ({
// 				select: true,
// 				error: !!validationErrors?.state,
// 				helperText: validationErrors?.state,
// 				onChange: (event: any) =>
// 					setEditedUsers({
// 						...editedUsers,
// 						[row.id]: { ...row.original, state: event.target.value },
// 					}),
// 			}),
// 		},
// 		{
// 			accessorKey: 'author',
// 			header: 'author',
// 			enableEditing: false,
// 			size: 150,
// 		},
// 	];

// 	const table = useMaterialReactTable({
// 		// @ts-ignore
// 		columns,
// 		// @ts-ignore
// 		data,
// 		createDisplayMode: 'row', //default ('row', and 'custom' are also available)
// 		editDisplayMode: 'row', //default ('row', 'cell', 'table', and 'custom' are also available)
// 		enableEditing: true,
// 		getRowId: row => row.id,
// 		onEditingRowCancel: () => setValidationErrors({}),
// 		onEditingRowSave: handleSaveUser,
// 		onCreatingRowCancel: () => setValidationErrors({}),
// 		onCreatingRowSave: handleCreateUser,
// 		renderRowActions: ({ row, table }) => (
// 			<Box sx={{ display: 'flex', gap: '1rem' }}>
// 				<Tooltip title='Edit'>
// 					<IconButton onClick={() => table.setEditingRow(row)}>
// 						<EditIcon />
// 					</IconButton>
// 				</Tooltip>
// 				<Tooltip title='Delete'>
// 					<IconButton color='error' onClick={() => console.error(row)}>
// 						<DeleteIcon />
// 					</IconButton>
// 				</Tooltip>
// 			</Box>
// 		),
// 		enableColumnActions: false,
// 		enableColumnFilters: false,
// 		enablePagination: false,
// 		enableSorting: false,
// 		mrtTheme: theme => ({
// 			baseBackgroundColor: theme.palette.background.default, //change default background color
// 		}),
// 		muiTableBodyRowProps: { hover: false },
// 		renderCaption: ({ table }) => (
// 			<Button
// 				variant='contained'
// 				onClick={() => {
// 					table.setCreatingRow(true);
// 				}}
// 			>
// 				Create New cost
// 			</Button>
// 		),
// 		renderTopToolbarCustomActions: ({ table }) => (
// 			<Button
// 				variant='contained'
// 				onClick={() => {
// 					table.setCreatingRow(true);
// 				}}
// 			>
// 				Create New cost
// 			</Button>
// 		),
// 	});

// 	// eslint-disable-next-line react/jsx-pascal-case
// 	return <MRT_Table table={table} />;
// };

const ProjectTask = forwardRef(
	// @ts-ignore
	({ project, task, costs, loaded, passIsDirty, setTaskLoading, isPrompt, setIsPrompt, onSubmit, addTaskCost }, formRef) => {
		return (
			<>
				{loaded ? (
					<>
						<ProjectTaskForm
							ref={formRef} // @ts-ignore
							projectId={project?.projectId}
							task={task}
							passIsDirty={passIsDirty}
							setTaskLoading={setTaskLoading}
							setIsPrompt={setIsPrompt}
							isPrompt={isPrompt}
							onSubmit={onSubmit}
						/>
						{task && task.projectTaskId && <ProjectTaskCostColumn project={project} task={task} costs={costs} />}
					</>
				) : (
					// @ts-ignore
					<Spinner />
				)}
			</>
		);
	}
);

export default ProjectTask;
