import { each, isNaN, isNil } from 'lodash';

import { RfpProposalItem, RfpProposalItemCost } from 'types/dataTypes';

const toNumber = (number: null | number | undefined) => {
	return !isNil(number) && !isNaN(number) ? number : 0;
};

export const calculateRfpProposalItemCost = (rfpProposedItemCost: Partial<RfpProposalItemCost> | null) => {
	let totalPrice = 0;
	let totalPriceMax = 0;

	if (!isNil(rfpProposedItemCost)) {
		const costType = rfpProposedItemCost.costType;
		const price = toNumber(rfpProposedItemCost.price);
		const priceMax = toNumber(rfpProposedItemCost.priceMax);
		const hourEstimate = toNumber(rfpProposedItemCost.hourEstimate);
		const hourEstimateMax = toNumber(rfpProposedItemCost.hourEstimateMax);

		if (costType === 'Fixed') {
			totalPrice = price;
			totalPriceMax = price;
		} else if (costType === 'FixedScale') {
			totalPrice = price;
			totalPriceMax = priceMax;
		} else if (costType === 'Hourly') {
			totalPrice = price * hourEstimate;
			totalPriceMax = price * hourEstimate;
		} else if (costType === 'HourlyEstimate') {
			totalPrice = price * hourEstimate;
			totalPriceMax = price * hourEstimateMax;
		}
	}

	return {
		totalPrice,
		totalPriceMax,
	};
};

export const calculateRfpProposalItemCosts = (rfpProposedItem: Partial<RfpProposalItem> | null) => {
	let grandTotalPrice = 0;
	let grandTotalPriceMax = 0;

	if (!isNil(rfpProposedItem)) {
		const rfpProposalItemCosts = rfpProposedItem.costs;
		each(rfpProposalItemCosts, rfpProposalItemCost => {
			const { totalPrice, totalPriceMax } = calculateRfpProposalItemCost(rfpProposalItemCost);
			grandTotalPrice += totalPrice;
			grandTotalPriceMax += totalPriceMax;
		});
	}

	return {
		grandTotalPrice,
		grandTotalPriceMax,
	};
};
