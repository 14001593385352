import { isNil } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { getCompanyId } from 'utils/auth/company';
import { storageKeys } from 'utils/constants/constants';
import storage from 'utils/localStorage/localStorageFunctions';

import { useFetchCompanyOnboardedQuery, useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import { useFetchScenarioQuery } from 'containers/Scenario/scenarioV2Api';

const useOpenOnboardingDialog = () => {
	const dispatch = useDispatch();

	const companyId = getCompanyId();
	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const onboarding = storage.get(storageKeys.ONBOARDING_KEY);

	// Fetch companyOnboarded with useFetchCompanyOnboardedQuery. Use companyId as argument. Skip fetching if companyId is undefined or null.
	const { data: companyOnboarded } = useFetchCompanyOnboardedQuery({ companyId }, { skip: isNil(companyId) });

	// Fetch scenario with useFetchScenarioQuery. Use companyId as argument. Skip fetching if companyId is undefined or null.
	const { data: scenario, isLoading: isScenarioLoading } = useFetchScenarioQuery(
		{ companyId, businessId: company?.businessId },
		{ skip: isNil(companyId) || isCompanyLoading || isCompanyFetching }
	);

	// Make const hasMissingValues with useMemo. Check if scenario?.missingValues is not empty array.
	const hasMissingValues = React.useMemo(() => {
		return scenario && scenario?.missingValues?.length !== 0;
	}, [scenario]);

	// Make useEffect hook. Dispatch openDialog with {name: 'OnboardingDialog'} if companyOnboarded is false and hasMissingValues is true. Skip opening if isLoading is true.
	React.useEffect(() => {
		if (companyOnboarded === false && hasMissingValues) {
			const hasDialogBeenOpened = onboarding?.[companyId ?? ''];
			if (!hasDialogBeenOpened) {
				dispatch(openDialog({ name: 'OnboardingDialog' }));
				storage.set(storageKeys.ONBOARDING_KEY, { ...onboarding, [companyId ?? '']: true });
			}
		}
	}, [companyId, onboarding, companyOnboarded, dispatch, hasMissingValues]);

	return null;
};

export default useOpenOnboardingDialog;
