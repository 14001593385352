import { Close as CloseIcon } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Skeleton,
} from '@mui/material';
import { isNil, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getCompany, getCompanyId } from 'utils/auth/company';

import { closeDialog, getDialogData, isDialogOpen } from 'containers/Marketplace/dialogSlice';
import { useFetchRfpProfileTeamMembersQuery } from 'containers/Marketplace/rfpProfileApi';

type Props = {} & Omit<DialogProps, 'open'>;

const SelectRfpProfileTeamMemberDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const dialogOpen = useSelector(isDialogOpen('SelectRfpProfileTeamMemberDialog'));
	const dialogData = useSelector(getDialogData('SelectRfpProfileTeamMemberDialog'));

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const {
		data: rfpProfileTeamMembers,
		isLoading,
		isError,
	} = useFetchRfpProfileTeamMembersQuery({ companyId: companyId }, { skip: isNil(companyId) });

	const handleDialogClose = React.useCallback(() => {
		dispatch(closeDialog({ name: 'SelectRfpProfileTeamMemberDialog' }));
	}, [dispatch]);

	return (
		<>
			<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='xs' fullWidth>
				<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					<FormattedMessage id='rfpProfileTeamMemberSelect.title' />
				</DialogTitle>
				<DialogContent>
					{isError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchRfpProfileTeamMembers.error.message' />
						</Alert>
					)}
					{isLoading ? (
						<>
							<Skeleton height={30} />
							<Skeleton height={30} />
							<Skeleton height={30} />
							<Skeleton height={30} />
						</>
					) : (
						<List>
							{map(rfpProfileTeamMembers, rfpProfileTeamMember => (
								<ListItem
									key={rfpProfileTeamMember.teamMemberId}
									secondaryAction={
										<Button
											size='small'
											onClick={() => {
												dialogData?.onSelect(rfpProfileTeamMember);
												handleDialogClose();
											}}
										>
											<FormattedMessage id='rfpProfileTeamMemberSelect.select' />
										</Button>
									}
								>
									<ListItemText
										primary={`${rfpProfileTeamMember.firstname} ${rfpProfileTeamMember.lastname}`}
										secondary={rfpProfileTeamMember.position}
									/>
								</ListItem>
							))}
						</List>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant='text' size='small' color='neutral' onClick={handleDialogClose}>
						<FormattedMessage id='rfpProfileTeamMemberSelect.cancel' />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SelectRfpProfileTeamMemberDialog;
