import { isEmpty, isNil, map, truncate } from 'lodash';
import * as React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';

import { RfpProposalTeamMember } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { openPopover } from 'containers/Marketplace/popoverSlice';
import { useRemoveRfpProposalTeamMemberMutation } from 'containers/Marketplace/rfpProposalApi';
import { toast } from 'react-toastify';

type Props = {
	proposalId?: string | null;
	requestId?: string | null;
	label?: string | null;
	helperText?: string | null;
	control: any;
} & Omit<FormControlProps, 'onChange'>;

const RfpProposalTeamMemberList = React.forwardRef<any, Props>(
	({ proposalId, requestId, label, helperText, control, ...otherProps }, ref) => {
		const dispatch = useDispatch();
		const { formatMessage } = useIntl();

		const companyId = getCompanyId() ?? getCompany()?.CompanyId;

		const {
			fields: rfpProposalTeamMembers,
			append,
			remove,
			update,
		} = useFieldArray({
			control,
			name: 'rfpProposalTeamMembers',
		});

		// const {
		// 	data: rfpProposalTeamMembers,
		// 	isLoading,
		// 	isError: isRfpProposalTeamMembersError,
		// } = useFetchRfpProposalTeamMembersQuery({ companyId, proposalId }, { skip: isNil(companyId) || isNil(proposalId) });

		//const [upsertRfpProposalTeamMember, { isError: isUpsertError }] = useUpsertRfpProposalTeamMemberMutation();
		const [removeRfpProposalTeamMember, { isError: isRemoveError }] = useRemoveRfpProposalTeamMemberMutation();

		return (
			<FormControl {...otherProps} ref={ref}>
				{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
				{/* {isRfpProposalTeamMembersError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='query.error.title' />
						</AlertTitle>
						<FormattedMessage id='fetchRfpProposalItemCosts.error.message' />
					</Alert>
				)} */}
				{/* {isUpsertError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='mutation.error.title' />
						</AlertTitle>
						<FormattedMessage id='upsertRfpProposalItemCost.error.message' />
					</Alert>
				)} */}
				{isRemoveError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='mutation.error.title' />
						</AlertTitle>
						<FormattedMessage id='removeRfpProposalItemCost.error.message' />
					</Alert>
				)}
				{/* {isLoading ? (
					<>
						<Skeleton height={60} />
						<Skeleton height={60} />
						<Skeleton height={60} />
						<Skeleton height={60} />
					</>
				) : ( */}
				<List>
					{isEmpty(rfpProposalTeamMembers) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpProposalTeamMembers.empty' />
						</Typography>
					)}
					{map(rfpProposalTeamMembers, (rfpProposalTeamMember: RfpProposalTeamMember, index: number) => (
						<ListItem
							key={rfpProposalTeamMember.teamMemberId}
							secondaryAction={
								<IconButton
									color='error'
									onClick={event => {
										dispatch(
											openPopover({
												name: 'ConfirmRemovePopover',
												data: {
													anchorEl: event.currentTarget,
													text: formatMessage({ id: 'rfpProposalTeamMembers.confirmRemove' }),
													description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
													onSubmit: async () => {
														try {
															if (rfpProposalTeamMember.teamMemberId) {
																await removeRfpProposalTeamMember({
																	companyId,
																	proposalId,
																	proposalTeamMemberId: rfpProposalTeamMember.teamMemberId,
																}).unwrap();

																toast.success(
																	formatMessage({ id: 'removeRfpProposalTeamMember.success.message' })
																);
															}
															remove(index);
														} catch (error) {
															console.error(error);
														}
													},
												},
											})
										);
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
							disablePadding
						>
							<ListItemButton
								onClick={() => {
									dispatch(
										openDialog({
											name: 'UpsertRfpProposalTeamMemberDialog',
											data: {
												requestId,
												fieldValues: rfpProposalTeamMember,
												onSubmit: async (fieldValues: Partial<RfpProposalTeamMember>) => {
													try {
														// await upsertRfpProposalTeamMember({
														// 	companyId,
														// 	proposalId,
														// 	rfpProposalTeamMember: fieldValues,
														// }).unwrap();
														//toast.success(formatMessage({ id: 'upsertRfpProposalTeamMember.success.message' }));

														update(
															index,
															Object.assign(
																{
																	companyId,
																	proposalId,
																},
																rfpProposalTeamMember,
																fieldValues
															)
														);
													} catch (error) {
														console.error(error);
													}
												},
											},
										})
									);
								}}
							>
								<ListItemText
									primary={`${rfpProposalTeamMember.firstname} ${rfpProposalTeamMember.lastname}`}
									secondary={
										<>
											<Typography variant='subtitle2' component='span' sx={{ mr: 1 }}>
												{rfpProposalTeamMember.position}
											</Typography>
											{truncate(rfpProposalTeamMember.description || '', { length: 80 })}
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
				{/* )} */}
				<Box>
					<Button
						size='small'
						variant='text'
						onClick={() => {
							dispatch(
								openDialog({
									name: 'UpsertRfpProposalTeamMemberDialog',
									data: {
										requestId,
										fieldValues: {},
										onSubmit: async (fieldValues: Partial<RfpProposalTeamMember>) => {
											try {
												// await upsertRfpProposalTeamMember({
												// 	companyId,
												// 	proposalId,
												// 	rfpProposalTeamMember: fieldValues,
												// }).unwrap();
												// toast.success(formatMessage({ id: 'upsertRfpProposalTeamMember.success.message' }));
												append(Object.assign({ companyId, proposalId }, fieldValues));
											} catch (error) {
												console.error(error);
											}
										},
									},
								})
							);
						}}
						startIcon={<AddIcon />}
					>
						<FormattedMessage id='rfpProposalTeamMembers.add' />
					</Button>
				</Box>
				{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
			</FormControl>
		);
	}
);

export default RfpProposalTeamMemberList;
