import { isEmpty, isNil, map } from 'lodash';
import * as React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';

import { RfpProposalItem } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { openPopover } from 'containers/Marketplace/popoverSlice';
import { useRemoveRfpProposalItemMutation } from 'containers/Marketplace/rfpProposalApi';
import TextCollapse from '../../../components/TextCollapse/TextCollapse';

type Props = {
	proposalId?: string | null;
	requestId?: string | null;
	label?: string | null;
	helperText?: string | null;
	onRemove?: any;
	onUpsert?: any;
	control: any;
} & Omit<FormControlProps, 'onChange'>;

const RfpProposalItemList = React.forwardRef<any, Props>(
	({ proposalId, requestId, label, helperText, onRemove, onUpsert, control, ...otherProps }, ref) => {
		const dispatch = useDispatch();
		const { formatMessage } = useIntl();

		const companyId = getCompanyId() ?? getCompany()?.CompanyId;

		const {
			fields: rfpProposalItems,
			append,
			remove,
			update,
		} = useFieldArray({
			control,
			name: 'rfpProposalItems',
		});
		// const {
		// 	data: rfpProposalItems,
		// 	isLoading,
		// 	isError: isRfpProposalItemsError,
		// } = useFetchRfpProposalItemsQuery({ companyId, proposalId }, { skip: isNil(companyId) || isNil(proposalId) });

		// const [upsertRfpProposalItem, { isError: isUpsertError }] = useUpsertRfpProposalItemMutation();
		const [removeRfpProposalItem, { isError: isRemoveError }] = useRemoveRfpProposalItemMutation();

		return (
			<FormControl {...otherProps} ref={ref}>
				{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
				{/* {isRfpProposalItemsError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='query.error.title' />
						</AlertTitle>
						<FormattedMessage id='fetchRfpProposalItemCosts.error.message' />
					</Alert>
				)}
				{isUpsertError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='mutation.error.title' />
						</AlertTitle>
						<FormattedMessage id='upsertRfpProposalItemCost.error.message' />
					</Alert>
				)} */}
				{isRemoveError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='mutation.error.title' />
						</AlertTitle>
						<FormattedMessage id='removeRfpProposalItemCost.error.message' />
					</Alert>
				)}
				{/* {isLoading ? (
					<>
						<Skeleton height={60} />
						<Skeleton height={60} />
						<Skeleton height={60} />
						<Skeleton height={60} />
					</>
				) : ( */}
				<List>
					{isEmpty(rfpProposalItems) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpProposalItems.empty' />
						</Typography>
					)}
					{map(rfpProposalItems, (rfpProposalItem: RfpProposalItem, index: number) => (
						<ListItem
							key={rfpProposalItem.rfpProposalItemId}
							secondaryAction={
								<IconButton
									color='error'
									onClick={event => {
										dispatch(
											openPopover({
												name: 'ConfirmRemovePopover',
												data: {
													anchorEl: event.currentTarget,
													text: formatMessage({ id: 'rfpProposalItems.confirmRemove' }),
													description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
													onSubmit: async () => {
														try {
															if (rfpProposalItem.rfpProposalItemId) {
																await removeRfpProposalItem({
																	companyId,
																	proposalId,
																	proposalItemId: rfpProposalItem.rfpProposalItemId,
																}).unwrap();

																toast.success(
																	formatMessage({
																		id: 'removeRfpProposalItem.success.message',
																	})
																);
															}
															// if (onRemove) {
															// 	onRemove(rfpProposalItem.rfpProposalItemId);
															// }

															remove(index);
														} catch (error) {
															console.error(error);
														}
													},
												},
											})
										);
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
							disablePadding
						>
							<ListItemButton
								onClick={() => {
									dispatch(
										openDialog({
											name: 'UpsertRfpProposalItemDialog',
											data: {
												requestId,
												fieldValues: rfpProposalItem,
												onSubmit: async (fieldValues: Partial<RfpProposalItem>) => {
													try {
														// await upsertRfpProposalItem({
														// 	companyId,
														// 	proposalId,
														// 	rfpProposalItem: fieldValues,
														// }).unwrap();

														// if (onUpsert) {
														// 	onUpsert(fieldValues);
														// }

														// toast.success(formatMessage({ id: 'upsertRfpProposalItem.success.message' }));

														update(
															index,
															Object.assign({ companyId, proposalId }, rfpProposalItem, fieldValues)
														);
													} catch (error) {
														console.error(error);
													}
												},
											},
										})
									);
								}}
							>
								<ListItemText
									primary={rfpProposalItem.requestItemTitle}
									secondary={
										<>
											<Typography variant='subtitle2' component='span' sx={{ mr: 1 }}>
												<FormattedMessage id={`rfpCategory.${rfpProposalItem.proposalItemCategory}`} />
											</Typography>
											<TextCollapse text={rfpProposalItem?.description} />
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
				{/* )} */}
				<Box>
					<Button
						size='small'
						variant='text'
						onClick={() => {
							dispatch(
								openDialog({
									name: 'UpsertRfpProposalItemDialog',
									data: {
										requestId,
										fieldValues: {},
										onSubmit: async (fieldValues: Partial<RfpProposalItem>, index: number) => {
											try {
												// await upsertRfpProposalItem({
												// 	companyId,
												// 	proposalId,
												// 	rfpProposalItem: fieldValues,
												// }).unwrap();
												//toast.success(formatMessage({ id: 'upsertRfpProposalItem.success.message' }));
												append(Object.assign({ companyId, proposalId }, fieldValues));
											} catch (error) {
												console.error(error);
											}
										},
									},
								})
							);
						}}
						startIcon={<AddIcon />}
					>
						<FormattedMessage id='rfpProposalItems.add' />
					</Button>
				</Box>
				{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
			</FormControl>
		);
	}
);

export default RfpProposalItemList;
