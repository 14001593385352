import React from 'react';
import { Chip } from '@mui/material';

export default function ({ label, sx, color = 'primary' }) {
    if (!label) return null;

    return (
        <Chip
            sx={{
                height: '1.5rem',
                fontWeight: 500,
                ...sx
            }}
            color={color}
            label={label}
        />
    );
}