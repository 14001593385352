import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { getCompanyId } from 'utils/auth/company';

import { useCreateCompanyMutation } from 'containers/Company/companyV2Api';

import CreateCustomCompanyForm from 'containers/Advanced/CreateCustomCompanyForm';
import ModalDialog from 'components/Dialog/ModalDialog';
import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';

const CustomersPage = () => {
	const intl = useIntl();
	const formRef = useRef();
	const history = useHistory();

	const companyId = getCompanyId;

	const [modalOpen, setModalOpen] = useState(false);
	const [isFormDirty, setIsFormDirty] = useState(false);

	const [createCompany, { isLoading: isCreateLoading }] = useCreateCompanyMutation();

	const onSubmit = async data => {
		const sendedData = {
			...data,
			companyForm: data.companyForm.key,
		};
		const createdCompany = await createCompany({ companyId, company: sendedData }).unwrap();
		if (modalOpen) setModalOpen(false);
		if (createdCompany) {
			toast.success(intl.formatMessage({ id: 'customer.page.modal.form.saving.successed' }));
			history.push(`/company/${createdCompany?.companyId}`);
		} else {
			toast.error(intl.formatMessage({ id: 'customer.page.modal.form.saving.failed' }));
		}
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const initialValues = {
		businessId: null,
		name: '',
		companyForm: null,
		detailsUri: '',
		address: {
			street: '',
			postCode: '',
			city: '',
			country: '',
		},
	};

	return (
		<>
			<Box sx={{ mt: '1rem' }}>
				<Button onClick={() => setModalOpen(true)} endIcon={<AddCircleOutlineOutlinedIcon />}>
					{intl.formatMessage({ id: 'customer.page.modal.add.new.company' })}
				</Button>
			</Box>
			<ModalDialog
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				title={intl.formatMessage({ id: 'customer.page.modal.add.new.company' })}
				actions={
					<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
						<Button variant='text' color='primary' onClick={() => setModalOpen(false)}>
							{intl.formatMessage({ id: 'common.close' })}
						</Button>
						<Button color='success' disabled={!isFormDirty || isCreateLoading} onClick={submitForm}>
							{intl.formatMessage({ id: 'shared.save' })}
							{isCreateLoading && <ButtonSpinner />}
						</Button>
					</Stack>
				}
			>
				<Box sx={{ p: 3 }}>
					<CreateCustomCompanyForm
						ref={formRef}
						disabled={isCreateLoading}
						initialValues={initialValues}
						onSubmit={onSubmit}
						passIsDirty={isDirty => setIsFormDirty(isDirty)}
					/>
				</Box>
			</ModalDialog>
		</>
	);
};

export default CustomersPage;
