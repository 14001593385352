import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageSpinner from 'components/Spinner/PageSpinner';
import ProjectDashboard from 'containers/Projects/Project/ProjectDashboard';
import { useFetchProjectQuery } from 'containers/Projects/projectRTKApi';
import { getCompanyId } from 'utils/auth/company';
import { isAdminToken } from 'utils/auth/token';

const ProjectPlanPage = ({ passPageProps }) => {
	const { id } = useParams();

	const instruments = useSelector(state => state.instruments);
	const instrumentsData = instruments?.instruments?.data;
	const instrumentsLoading = instruments?.instruments?.loading;

	const companyId = getCompanyId();
	const isAdmin = isAdminToken();

	const {
		data: project,
		isLoading: isProjectLoading,
		isFetching: isProjectFetching,
	} = useFetchProjectQuery({ companyId, projectId: id, draft: isAdmin, withData: true }, { skip: !companyId || !id });
	const projectDataLoaded = true; //!isProjectLoading && !isProjectFetching;

	useEffect(() => {
		if (!project) return;

		passPageProps([{ id: project.projectId, name: project.projectName }]);
	}, [passPageProps, project]);

	const loading = (!projectDataLoaded || instrumentsLoading) ?? false;

	if (loading || !project) return <PageSpinner />;

	return <ProjectDashboard project={project} instruments={instrumentsData} />;
};

export default ProjectPlanPage;
