import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { removeRelatedConversations, updateConversationLink } from 'containers/Collaboration/slices';
import { getStartDateArr, handleProject } from 'containers/FinancialPlan/parserTimeline';
import { find, map } from 'lodash';
import { Project, ProjectCost, ProjectFinance, ProjectTask } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import { umlautsDecode } from 'utils/formatters/umlautsDecode';
import { handleFiles } from 'utils/helpers/fileHelper';
import { appIntl } from 'utils/helpers/intlGlobalProvider';
import { objFromArray } from 'utils/helpers/objFromArray';
import { projectApi } from './projectApi';
import { TAGS, projectsApi } from './projectRTKApi';
import { calculateCostsTotalSum, costSelector, isProjectDraft, projectState, taskSelector } from './utils';

export const finance: ProjectFinance[] = [
	{
		index: 0,
		id: 'eugovfunding',
		name: 'Haettava EU- ja valtion rahoitus',
		amount: 0,
		amountPercent: 0,
	},
	{
		index: 1,
		id: 'privatefundingown',
		name: 'Omarahoitus: Yksityinen rahoitus',
		amount: 0,
		amountPercent: 0,
	},
	{
		index: 2,
		id: 'communityfunding',
		name: 'Kuntarahoitus',
		amount: 0,
		amountPercent: 0,
	},
	{
		index: 3,
		id: 'otherpublicfunding',
		name: 'Muu julkinen rahoitus',
		amount: 0,
		amountPercent: 0,
	},
	{
		index: 4,
		id: 'privatefunding',
		name: 'Yksityinen rahoitus',
		amount: 0,
		amountPercent: 0,
	},
];

export const projectTaskSelector = (project: Project): ProjectTask[] => {
	if (!project) return [];

	const tasks = project.projectTaskGroups?.flatMap(group => group.tasks ?? []) ?? [];
	const nonNullTasks = tasks.filter((task): task is ProjectTask => task !== null);

	return nonNullTasks;
};
export const projectCostSelector = (project: Project): ProjectCost[] => {
	const tasks = projectTaskSelector(project);
	if (!tasks || tasks.length === 0) return [];

	const costs = tasks?.flatMap(task => task.costs ?? []) ?? [];
	const nonNulCosts = costs.filter((cost): cost is ProjectCost => cost !== null);

	return nonNulCosts;
};

interface Identifiable {
	id: number | string;
}
export interface Column extends Identifiable {
	id: string;
	totalSum: (costs: Array<ProjectCost>) => number | null;
	costIds: string[];
}

interface InitialState {
	isLoaded: boolean;
	projectDataLoaded: boolean;
	timeline: {
		isLoaded: boolean;
		loading: boolean;
		error: null | Error;
		data: any;
	};
	projects: {
		isLoaded: boolean;
		loading: boolean;
		error: null | Error;
		mock: null | string;
		data: Project[];
		isSidebarOpen: boolean;
	};
	columns: Column[];
	costs: ProjectCost[];
	tasks: ProjectTask[];
	attachments: any[];
	isDeleting: boolean;
}

export function findItemById<T extends Identifiable>(items: T[], id: number | string): T | undefined {
	if (!items || items.length === 0) return undefined;

	return find(items, item => item.id === id);
}

export function findItemByIdWithField<T>(items: T[], field: string, id: number | string): T | undefined {
	if (!items || items.length === 0) return undefined;

	const dynamicKey = field as keyof T;

	return find(items, item => {
		const myDynamicPropValue = item[dynamicKey];

		return myDynamicPropValue === id;
	});
}

export const initialState: InitialState = {
	isLoaded: false,
	projectDataLoaded: false,
	timeline: {
		isLoaded: false,
		loading: false,
		error: null,
		data: {},
	},
	projects: {
		isLoaded: false,
		loading: false,
		error: null,
		mock: null,
		data: [],
		isSidebarOpen: false,
	},
	columns: [
		{
			id: 'todo',
			totalSum: (costs: Array<ProjectCost>) =>
				calculateCostsTotalSum(
					costs?.filter(item => item.phase === 'todo'),
					'amountApplied'
				),
			costIds: [],
		},
		{
			id: 'approved',
			totalSum: (costs: Array<ProjectCost>) =>
				calculateCostsTotalSum(
					costs?.filter(item => item.phase === 'approved'),
					'amountGranted'
				),
			costIds: [],
		},
		{
			id: 'reported',
			totalSum: (costs: Array<ProjectCost>) =>
				calculateCostsTotalSum(
					costs?.filter(item => item.phase === 'reported'),
					'amountReported'
				),
			costIds: [],
		},
		{
			id: 'paid',
			totalSum: (costs: Array<ProjectCost>) =>
				calculateCostsTotalSum(
					costs?.filter(item => item.phase === 'paid'),
					'amountPaid'
				),
			costIds: [],
		},
	],
	costs: [],
	tasks: [],
	attachments: [],
	isDeleting: false,
};

export const name = 'kanban';

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},
		getTimeline(state) {
			state.timeline.loading = true;
			state.timeline.error = null;
			state.timeline.isLoaded = false;
		},
		getTimelineError: (state, action) => {
			state.timeline.loading = false;
			state.timeline.error = action.payload;
			state.timeline.data = initialState.timeline.data;
			state.timeline.isLoaded = true;
		},
		getTimelineSuccess: (state, action) => {
			state.timeline.loading = false;
			state.timeline.error = null;
			state.timeline.data = action.payload;
			state.timeline.isLoaded = true;
		},
		getProjects: state => {
			state.projects.loading = true;
			state.projects.error = null;
			state.projects.isLoaded = false;
		},
		getProjectsError(state, action) {
			state.projects.loading = false;
			state.projects.isLoaded = action.payload;
			state.projects.data = initialState.projects.data;
			state.projects.isLoaded = true;
		},
		getProjectsSuccess(state, action) {
			state.projects.loading = false;
			state.projects.error = null;
			state.projects.data = action.payload.projects;
			state.projects.mock = null;
			state.projects.isLoaded = true;
		},
		setProjectDataLoaded(state, action) {
			state.projectDataLoaded = action.payload;
		},
		showProjectsSidebar(state, action) {
			state.projects.isSidebarOpen = action.payload;
		},
		getProject(state, action) {
			const project = action.payload;

			const exists = state.projects.data.find((item: Project) => item.projectId === project.projectId);

			if (!exists) state.projects.data.push(project);

			state.projects.isLoaded = true;
		},
		createProject(state, action) {
			const project = action.payload;
			state.projects.data.push(project);
		},
		updateProject(state, action) {
			const project = action.payload;

			const indexToUpdate = state.projects.data.findIndex((item: Project) => item.projectId === project.projectId);
			if (indexToUpdate !== -1) {
				state.projects.data[indexToUpdate] = project;
			}
		},
		archiveProject(state) {
			state.isDeleting = true;
		},
		archiveProjectSuccess(state, action) {
			state.isDeleting = false;
			const projectId = action.payload;

			state.projects.data = state.projects.data.filter((item: Project) => item.projectId !== projectId);
			state.projectDataLoaded = false;
		},
		clearProjectBoard(state) {
			state.attachments = initialState.attachments;
			state.costs = initialState.costs;
			state.tasks = initialState.tasks;
			state.columns = initialState.columns;
		},
		getTasks(state, action) {
			state.tasks = action.payload;
		},
		getAttachments(state, action) {
			state.attachments = action.payload;
		},
		updateAttachments(state, action) {
			action.payload.forEach((attachment: any) => {
				for (let i = 0; i < state.attachments.length; i++) {
					if (state.attachments[i].attachmentId !== attachment.attachmentId) continue;

					state.attachments[i] = attachment;
				}
			});
		},
		removeAttachmentSuccess(state, action) {
			const attachmenId = action.payload;
			state.attachments = state.attachments.filter(attachment => attachment.attachmentId !== attachmenId);
		},
		getCosts(state, action) {
			const costs = action.payload;

			try {
				for (let i = 0; i < state.columns.length; i++) {
					const column = state.columns[i];

					column.costIds = []; // reset column data
				}
			} catch {
				// ignore
			}

			state.costs = objFromArray(costs);

			for (const cost of state.costs) {
				let column = state.columns.find(item => item.id === cost.phase) ?? state.columns[0];

				const index = column?.costIds?.findIndex(columnCost => columnCost === cost.id);
				if (index >= 0) {
					continue; // no duplicates
				}

				column.costIds.push(cost.id ?? '');
			}
		},
		updateTasksAmounts(state) {
			for (const task of state.tasks) {
				for (const cost of state.costs) {
					if (!cost || cost.projectTaskId !== task.projectTaskId) continue;

					cost.amountApplied += cost.amountApplied;
					cost.amountBudget += cost.amountBudget;
					cost.amountGranted += cost.amountGranted;
					cost.amountReported += cost.amountReported;
					cost.amountPaid += cost.amountPaid;
				}
			}
		},
		createCost(state, action) {
			const cost = action.payload;

			state.costs.push(cost);

			// Add the costId reference to the column
			let foundItem = findItemById(state.columns, cost.phase);
			if (foundItem) state.columns.push(foundItem);
		},
		updateCost(state, action) {
			const updateItem = action.payload;

			for (let i = 0; i < state.costs.length; i++) {
				if (state.costs[i].id !== updateItem.id) continue;

				state.costs[i] = updateItem;
			}
		},
		moveCost(state, action) {
			const { costId, position, columnId } = action.payload;

			const cost = state.costs.find(item => item.id === costId);

			if (!cost) return;

			const sourceColumnId = cost.phase && cost.phase.length > 0 ? cost.phase : 'todo';

			let foundItem = findItemById(state.columns, sourceColumnId);
			if (foundItem) foundItem.costIds = foundItem.costIds.filter(_costId => _costId !== costId);

			// If columnId exists, it means that we have to add the cost to the new column
			if (columnId) {
				// Change cost's columnId reference
				let foundItem = findItemById(state.columns, columnId);
				// Push the costId to the specified position
				foundItem?.costIds.splice(position, 0, costId);
			} else {
				let foundItem = findItemById(state.columns, sourceColumnId);
				// Push the costId to the specified position
				foundItem?.costIds.splice(position, 0, costId);
			}
		},
		deleteCost(state, action) {
			const costId = action.payload;
			const cost = state.costs.find(costId);

			if (!cost) return;

			const columnId = cost.phase;

			state.costs = state.costs.filter(item => item.id !== costId);

			let foundItem = findItemById(state.columns, columnId);
			if (foundItem) foundItem.costIds = foundItem.costIds.filter(_costId => _costId !== costId);
		},
		createTask(state, action) {
			const task = action.payload;

			state.tasks.push(task);
		},
		updateTask(state, action) {
			const updateItem = action.payload;

			for (let i = 0; i < state.tasks.length; i++) {
				if (state.tasks[i].projectTaskId !== updateItem.projectTaskId) continue;

				state.tasks[i] = updateItem;
			}
		},
		deleteTask(state, action) {
			const taskId = action.payload;

			state.tasks = state.tasks.filter(item => item.projectTaskId !== taskId);
		},
	},
	extraReducers: builder => {
		builder.addMatcher(projectsApi.endpoints.fetchProject.matchFulfilled, (state, { payload }) => {
			let project = handleProject(payload, state.timeline.data);

			if (!project.projectFinance || project.projectFinance.length === 0) {
				project.projectFinance = finance;
			}

			const exists = state.projects.data.find((item: Project) => item.projectId === project.projectId);

			if (!exists) state.projects.data.push(project);

			const allTasks = map(project?.projectTaskGroups, item => item.tasks).reduce((acc, current) => acc.concat(current), []);
			const allCosts = map(allTasks, item => item.costs).reduce((acc, current) => acc.concat(current), []);

			state.tasks = allTasks;

			try {
				for (let i = 0; i < state.columns.length; i++) {
					const column = state.columns[i];

					column.costIds = []; // reset column data
				}
			} catch {
				// ignore
			}

			state.costs = allCosts;

			for (const cost of allCosts) {
				let column = state.columns.find(item => item.id === cost.phase) ?? state.columns[0];

				const index = column?.costIds?.findIndex(columnCost => columnCost === cost.id);
				if (index >= 0) {
					continue; // no duplicates
				}

				column.costIds.push(cost.id);
			}
		});
	},
});

export const { reducer } = slice;

export const { getTimelineError, getTimelineSuccess, getProjectsError, getProjectsSuccess } = slice.actions;
export const loadProjects = slice.actions.getProjects;

export const resetProjects = () => async (dispatch: any) => {
	try {
		dispatch(slice.actions.resetData());
	} catch (error) {
		toast.error(appIntl().formatMessage({ id: 'project.reset.failed' }));
	}
};

export const getProjects =
	(forceRefetch = false) =>
	async (dispatch: any) => {
		try {
			const companyId = getCompanyId();

			dispatch(slice.actions.getTimeline());
			dispatch(slice.actions.getProjects());

			const timelineRequest = await dispatch(projectsApi.endpoints.fetchTimeline.initiate({ companyId }, { forceRefetch }));
			const projectsRequest = await dispatch(projectsApi.endpoints.fetchProjects.initiate({ companyId }, { forceRefetch }));

			let projects = projectsRequest?.data;
			let timeline = timelineRequest?.data;

			const decodedProjects = projects?.map((project: Project) => {
				return { ...project, dynamic: project.dynamic ?? null, projectSummary: umlautsDecode(project.projectSummary) };
			});

			const mutatedTimeline = getStartDateArr(!decodedProjects.length ? [] : decodedProjects, timeline[0]); // mutates the response object

			dispatch(slice.actions.getTimelineSuccess(mutatedTimeline?.timeline));
			dispatch(slice.actions.getProjectsSuccess({ projects: mutatedTimeline?.projects, mock: null }));
		} catch (err) {
			dispatch(slice.actions.getTimelineError(err));
			dispatch(slice.actions.getProjectsError(err));

			toast.error(appIntl().formatMessage({ id: 'project.get.failed' }));
		}
	};

export const handleTimelineProjectsForUi = (projects: Array<Project>) => {
	const decodedProjects = projects?.map(project => {
		return { ...project, dynamic: project.dynamic ?? null, projectSummary: umlautsDecode(project.projectSummary) };
	});

	const mutatedTimeline = getStartDateArr(decodedProjects); // mutates the response object

	return { timeline: mutatedTimeline?.timeline, projects: mutatedTimeline?.projects };
};

export const toggleProjectsSidebar = (bool: boolean) => (dispatch: any) => {
	dispatch(slice.actions.showProjectsSidebar(bool));
};

export const getProjectData = (projectId: string) => async (dispatch: any, getState: any) => {
	try {
		const companyId = getCompanyId();

		const state = getState();
		const kanban = state.kanban;
		const projects = kanban.projects;
		const timeline = kanban.timeline;

		const draft = isProjectDraft(projects?.data, projectId) ?? false;

		dispatch(slice.actions.clearProjectBoard());

		const projectRequest = await dispatch(projectsApi.endpoints.fetchProject.initiate({ companyId, projectId, draft, withData: true }));
		let project = projectRequest?.data;

		if (project.archived) {
			return;
		} // if project is archived (on delete i.e. don't return data)

		const attachments = projectRequest?.data?.attachments; // await attachmentsFunc;

		project = handleProject(project, timeline.data);

		const allTasks = map(project?.projectTaskGroups, item => item.tasks).reduce((acc, current) => acc.concat(current), []);
		const allCosts = map(allTasks, item => item.costs).reduce((acc, current) => acc.concat(current), []);

		dispatch(slice.actions.getProject(project));
		dispatch(slice.actions.getTasks(allTasks));
		dispatch(slice.actions.getCosts(allCosts));
		dispatch(slice.actions.getAttachments(attachments));
		dispatch(slice.actions.setProjectDataLoaded(projectId));
	} catch (err) {
		console.error(err, appIntl());
		toast.error(appIntl().formatMessage({ id: 'getProjectData.fail' }));
	}
};

export const saveProjectAsDraft = (project: Project) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban: InitialState = state.kanban;
		const timeline = kanban.timeline;
		const companyId = getCompanyId();

		const length = project?.projectId?.length ?? 0;
		const exists = length > 0;

		let projectEntity = { ...project };

		projectEntity.projectState = projectState.draft;

		const updatedProject = await handleProjectObject(timeline, projectEntity, false);

		const results = await dispatch(projectsApi.endpoints.upsertProject.initiate({ companyId, project: updatedProject, draft: true }));

		let storedProject = results.data.project ?? results.data;

		storedProject = handleProject(storedProject, timeline.data);

		if (!exists) {
			dispatch(slice.actions.createProject(storedProject));
			await dispatch(getProjects(true)); // ensure that the mock is overriden
		} else {
			storedProject.dynamic = storedProject.dynamic ?? {};

			dispatch(slice.actions.updateProject(storedProject));
			dispatch(updateConversationLink(storedProject.projectId, storedProject.projectName));
		}

		return storedProject;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const publishDraftProject = (project: Project) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban: InitialState = state.kanban;
		const timeline = kanban.timeline;

		const length = project?.projectId?.length ?? 0;
		const exists = length > 0;

		let projectEntity = { ...project };

		projectEntity.projectState = projectState.draft;

		const updatedProject = await handleProjectObject(timeline, projectEntity, false);

		const data = await projectApi.publishProjectDraft(updatedProject);
		let storedProject = data.project ?? data;

		storedProject = handleProject(storedProject, timeline.data);

		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECT, id: storedProject.projectId }]));

		if (!exists) {
			dispatch(slice.actions.createProject(storedProject));
		} else {
			storedProject.dynamic = storedProject.dynamic ?? {};

			dispatch(slice.actions.updateProject(storedProject));
			dispatch(updateConversationLink(storedProject.projectId, storedProject.projectName));
		}

		return storedProject;
	} catch (error) {
		return null;
	}
};

export const publishAllDraftProjects = () => async (dispatch: any, getState: any) => {
	try {
		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECTS }]));

		const state = getState();
		const kanban: InitialState = state.kanban;
		const projects = kanban.projects;

		if (!projects) return true;

		const draftProjects: Array<Project> = [];

		for (const project of projects.data) {
			// eslint-disable-line no-unused-vars
			if (project?.projectState?.toLowerCase() !== projectState.draft.toLowerCase()) continue;

			draftProjects.push(project);
		}

		if (!draftProjects || draftProjects.length <= 0) return true;

		for (const draftProject of draftProjects) {
			const success = await dispatch(publishDraftProject(draftProject));

			if (success)
				toast.success(
					appIntl().formatMessage({
						id: 'project.published',
						values: {
							name: draftProject.projectName,
						},
					})
				);
			else
				toast.error(
					appIntl().formatMessage({
						id: 'project.published.failed',
						values: {
							name: draftProject.projectName,
						},
					})
				);
		}

		return true;
	} catch (error) {
		return false;
	}
};

export const saveProject = (project: Project) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const timeline = kanban.timeline;
		const projects = kanban.projects;
		const companyId = getCompanyId();

		const length = project?.projectId?.length ?? 0;
		const exists = length > 0;

		const updatedProject = await handleProjectObject(timeline, project, false);

		const draft = isProjectDraft(projects?.data, project?.projectId);

		const { data } = await dispatch(projectsApi.endpoints.upsertProject.initiate({ companyId, project: updatedProject, draft })); // await projectApi.addUpdateProjectWithAttachments(updatedProject, draft);

		let storedProject = data.project;

		storedProject = handleProject(storedProject, timeline.data);

		if (!exists) {
			await dispatch(slice.actions.createProject(storedProject));
			await dispatch(getProjects(true)); // ensure that the mock is overriden
		} else {
			storedProject.dynamic = storedProject.dynamic ?? {};

			dispatch(slice.actions.updateProject(storedProject));
			dispatch(updateConversationLink(storedProject.projectId, storedProject.projectName));
		}

		return storedProject;
	} catch (error) {
		return null;
	}
};

export const archiveProject = (projectId: string) => async (dispatch: any) => {
	try {
		dispatch(slice.actions.archiveProject());
		await projectApi.archiveProject(projectId);
		dispatch(slice.actions.archiveProjectSuccess(projectId));

		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECT, id: projectId }]));

		await dispatch(getProjects(true)); // ensure that mock is loaded if is the last one

		toast.success(appIntl().formatMessage({ id: 'project.removed.success' }));
		dispatch(removeRelatedConversations(projectId));

		return true;
	} catch (error) {
		toast.error(appIntl().formatMessage({ id: 'project.removed.failed' }));

		return false;
	}
};

export const addAttachment = (parentId: string, parentType: string, files: any) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const projects = kanban.projects;

		const draft = isProjectDraft(projects?.data, parentId);

		const attachments = await handleFiles(files);

		const savedAttachments = await projectApi.addAttachment(
			{ attachmentParentId: parentId, attachmentType: parentType, attachments },
			draft
		);

		dispatch(slice.actions.updateAttachments(savedAttachments));

		return true;
	} catch (err) {
		return false;
	}
};

export const removeAttachment = (id: string) => async (dispatch: any) => {
	try {
		const response = await projectApi.removeAttachment(id); // return true

		if (!response) throw Error('attachment delete failed');

		dispatch(slice.actions.removeAttachmentSuccess(id));

		return true;
	} catch (err) {
		return false;
	}
};

export const handleProjectObject = async (timeline: any, project: any, migration = false) => {
	const attachments = await handleFiles(project.files);

	const updatedProject = { ...project, attachments };
	delete updatedProject.files;

	updatedProject.timelineId = timeline?.data?.timelineId;

	if (migration) {
		delete updatedProject.projectId;
		delete updatedProject.rowKey;
	}

	if (!updatedProject.timelineId) {
		toast.error(appIntl().formatMessage({ id: 'project.saving.notimeline' }));

		throw Error('project.saving.notimeline');
	}

	return updatedProject;
};

export const createCost = (columnId: string, cost: ProjectCost) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const tasks = kanban.tasks;
		const projects = kanban.projects;

		const task = taskSelector(tasks, cost?.projectTaskId);
		const draft = isProjectDraft(projects?.data, task?.projectId ?? null);

		const attachments = await handleFiles(cost.files);

		const updatedCost = { ...cost, attachments };
		delete updatedCost.files;

		const data = await projectApi.addUpdateCostWithAttachments(updatedCost, draft);

		dispatch(slice.actions.createCost(data.cost));
		if (data.attachments) {
			dispatch(slice.actions.updateAttachments(data.attachments));
		}
	} catch (err) {
		toast.error(appIntl().formatMessage({ id: 'createCost.fail' }));
	}
};

export const updateCost = (costId: string, update: ProjectCost) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const projects = kanban.projects;
		const tasks = kanban.tasks;

		const task = tasks.find((item: ProjectTask) => item.projectTaskId === update.projectTaskId);
		const draft = isProjectDraft(projects?.data, task.projectId);

		const attachments = await handleFiles(update.files);

		const updatedCost = { ...update, attachments };
		delete updatedCost.files;

		if (update.phase !== updatedCost.phase) {
			const position = 1;
			const columnId = updatedCost.phase;

			if (columnId === 'approved') {
				if (updatedCost.amountGranted === 0) updatedCost.amountGranted = updatedCost.amountApplied;
			}
			if (columnId === 'reported') {
				if (updatedCost.amountReported === 0) updatedCost.amountReported = updatedCost.amountGranted;
			}
			if (columnId === 'paid') {
				if (updatedCost.amountPaid === 0) updatedCost.amountPaid = updatedCost.amountReported;
			}

			dispatch(
				slice.actions.moveCost({
					costId,
					position,
					columnId,
				})
			);
		}
		const data = await projectApi.addUpdateCostWithAttachments(updatedCost, draft);

		dispatch(slice.actions.updateCost(data.cost));
		if (data.attachments) {
			dispatch(slice.actions.updateAttachments(data.attachments));
		}
		dispatch(updateConversationLink(data.cost.id, data.cost.name));

		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECT, id: data.cost.projectId }]));
	} catch (exception) {
		console.error(exception);
		toast.error(appIntl().formatMessage({ id: 'project.updateCost.failed' }));
	}
};

export const moveCost = (costId: string, position: any, columnId: string) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const projects = kanban.projects;
		const costs = kanban.costs;
		const tasks = kanban.tasks;

		const cost = costSelector(costs, costId);
		const task = taskSelector(tasks, cost?.projectTaskId ?? null);
		const draft = isProjectDraft(projects?.data, task?.projectId ?? null);

		dispatch(
			slice.actions.moveCost({
				costId,
				position,
				columnId,
			})
		);

		const update = Object.assign({}, cost, { phase: columnId });

		if (columnId === 'approved') {
			if (update.amountGranted === 0) update.amountGranted = update.amountApplied;
		}
		if (columnId === 'reported') {
			if (update.amountReported === 0) update.amountReported = update.amountGranted;
		}
		if (columnId === 'paid') {
			if (update.amountPaid === 0) update.amountPaid = update.amountReported;
		}

		const data = await projectApi.updateCost(costId, update, draft);
		dispatch(slice.actions.updateCost(data)); // @ts-ignore
		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECT, id: task?.projectId }]));
	} catch (ex) {
		toast.error(appIntl().formatMessage({ id: 'moveCost.fail' }));
	}
};

export const deleteCost = (costId: string, projectId: string) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const projects = kanban.projects;

		const draft = isProjectDraft(projects?.data, projectId);
		const cost = kanban.costs.find((c: ProjectCost) => c.id === costId);

		await projectApi.deleteCost(costId, draft);

		dispatch(slice.actions.deleteCost(costId));
		dispatch(removeRelatedConversations(costId));
		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECT, id: cost?.projectId }]));
	} catch (err) {
		toast.error(appIntl().formatMessage({ id: 'deleteCost.fail' }));
	}
};

export const createProjectTask = (task: ProjectTask) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const projects = kanban.projects;

		const draft = isProjectDraft(projects?.data, task.projectId);

		const taskData = await projectApi.createTask(task, draft);

		dispatch(slice.actions.createTask(taskData));
		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECT, id: taskData?.projectId }]));

		return taskData;
	} catch {
		return false;
	}
};

export const updateProjectTask = (task: ProjectTask) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const projects = kanban.projects;

		const draft = isProjectDraft(projects?.data, task.projectId);

		const taskData = await projectApi.updateTask(task, draft);

		dispatch(slice.actions.updateTask(taskData));
		dispatch(updateConversationLink(taskData.projectTaskId, taskData.projectTaskName));
		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECT, id: taskData?.projectId }]));

		return taskData;
	} catch {
		return false;
	}
};

export const deleteProjectTask = (taskId: string, projectId: string) => async (dispatch: any, getState: any) => {
	try {
		const state = getState();
		const kanban = state.kanban;
		const projects = kanban.projects;

		const draft = isProjectDraft(projects?.data, projectId);
		const deleted = await projectApi.deleteTask(taskId, draft);

		dispatch(slice.actions.deleteTask(taskId));
		dispatch(removeRelatedConversations(taskId));

		// fetch cost after task deletion from API -> removed costs attached to the task
		const costs = await projectApi.getProjectCosts(projectId, draft);
		dispatch(slice.actions.getCosts(costs));
		await dispatch(projectsApi.util.invalidateTags([{ type: TAGS.PROJECT, id: projectId }]));

		return deleted;
	} catch (err) {
		return null;
	}
};

export const getFile = async (attachmentId: string, draft: any) => {
	try {
		return await projectApi.getAttachmentFile(attachmentId, draft);
	} catch {
		return null;
	}
};

export const getInstrumentTemplate = (instrumentId: string, instrumentTemplateVersion?: string) => async (dispatch: any) => {
	try {
		const templateRequest = await dispatch(
			projectsApi.endpoints.fetchInstumentTemplate.initiate({
				instrumentId,
				templateVersion: instrumentTemplateVersion,
			})
		);
		const template = templateRequest?.data;

		return template;
	} catch (err) {
		return null;
	}
};

export default slice;
