import { API_ROOT } from 'config/environment';
import { getCompany, isAdvancedToken } from 'utils/auth/company';
import axios from 'utils/axios';
import { validateHttpStatus } from 'utils/helpers/uriHelper';

class CompanySearchApi {
	async getSearchCompanyData(name, match) {
		const isAdvanced = isAdvancedToken();
		const company = getCompany();

		let url = !match ? `${API_ROOT}/company/name/${name}` : `${API_ROOT}/company/${name}`;

		if (isAdvanced) {
			url = `${url}?advancedCompanyId=${company.CompanyId}`;
		}

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const companySearchApi = new CompanySearchApi();
