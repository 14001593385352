import React from 'react';
import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import Subtitle from 'components/Titles/Subtitle';
import TolSelector from 'components/TolSelector';

export default function TolClasses({ errors, control, instrument }) {
    const intl = useIntl();

    return (
        <>
            <Subtitle mt={0} mb={2}>
                {intl.formatMessage({ id: 'instrument.edit.form.tols' })}
            </Subtitle>
            <Grid container>
                <Grid item xs={12}>
                    <TolSelector
                        name={'tolClasses'}
                        control={control}
                        defaultValue={instrument.tolClasses ?? []}
                        errors={errors}
                        label={intl.formatMessage({ id: 'instrument.edit.form.tols.choose' })}
                    />
                </Grid>
            </Grid>
        </>
    );
}