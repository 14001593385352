import React from 'react';
import { styled } from '@mui/styles';

const FormDescription = styled('div')(({ theme }) => (
    {
        color: theme.palette.secondary.grey500,
    }
));

export default function ({ children }) {
    return <FormDescription>{children}</FormDescription>;
}