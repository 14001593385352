import axios from 'utils/axios';
import deepCopy from 'utils/helpers/deepCopy';

import { Project, ProjectTask } from 'types/dataTypes';
import { buildUri, validateHttpStatus } from 'utils/helpers/uriHelper';

const costItemConst = {
	id: '',
	projectTaskId: null,
	attachments: [],
	checklists: [],
	comments: [],
	cover: null,
	description: '',
	name: '',
	phase: null,
	category: '',
	type: '',
	amountApplied: 0.0,
	amountBudget: 0.0,
	amountGranted: 0.0,
};

const baseUrl = 'project';

class ProjectApi {
	async getProjectAttachments(projectId: string, draft: boolean | null) {
		const url = buildUri(baseUrl, `attachments/${projectId}`, draft);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getAttachmentFile(attachmentId: string, draft: boolean | null) {
		const url = buildUri(baseUrl, `attachment/${attachmentId}`, draft);
		const response = await axios.get(url, {
			responseType: 'blob', // DO NOT TOUCH: important -> won't work without
			validateStatus: validateHttpStatus,
		});

		return response.data;
	}

	async addAttachment(data: any, draft: boolean | null) {
		const url = buildUri(baseUrl, 'attachment', draft);
		const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async publishProjectDraft(project: Project) {
		const url = buildUri(baseUrl, 'project/publish');
		const response = await axios.post(url, project, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async createTask(task: ProjectTask, draft: boolean | null) {
		const url = buildUri(baseUrl, 'task', draft);
		const response = await axios.post(url, task, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async updateTask(task: ProjectTask, draft: boolean | null) {
		const url = buildUri(baseUrl, 'task', draft);
		const response = await axios.post(url, task, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async deleteTask(taskId: string, draft: boolean | null) {
		const url = buildUri(baseUrl, `task/${taskId}`, draft);
		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getProjectCosts(projectId: string, draft: boolean | null) {
		const url = buildUri(baseUrl, `costs/${projectId}`, draft);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async addUpdateCostWithAttachments(update: any, draft: boolean | null) {
		const url = buildUri(baseUrl, 'cost/attachments', draft);
		const response = await axios.post(url, update, { validateStatus: validateHttpStatus });

		const updatedCost = Object.assign({}, costItemConst, response.data.cost);

		return { cost: deepCopy(updatedCost), attachments: response.data.attachments };
	}

	async updateCost(costId: string, update: any, draft: boolean | null) {
		const url = buildUri(baseUrl, 'cost', draft);
		const response = await axios.post(url, update, { validateStatus: validateHttpStatus });

		const updatedCost = Object.assign({}, costItemConst, response.data);

		return deepCopy(updatedCost);
	}

	async deleteCost(costId: string, draft: boolean | null) {
		const url = buildUri(baseUrl, `cost/${costId}`, draft);

		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		if (!response.data) throw new Error('Internal server error: archive failed');

		return response.data;
	}

	async archiveProject(projectId: string) {
		const url = buildUri(baseUrl, `project/${projectId}`);
		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		if (!response.data) throw new Error('Internal server error: archive failed');

		return response.data;
	}

	async removeAttachment(attachmentId: string) {
		const url = buildUri(baseUrl, `attachment/${attachmentId}`);
		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const projectApi = new ProjectApi();
