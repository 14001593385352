import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { useTheme } from '@mui/styles';
import { useIntl } from 'react-intl';

import Button from 'components/Button/Button';

const AgreementActions = ({ error, specific, handleChange, confirmAgreement, loading }: any) => {
	const intl = useIntl();
	const theme: any = useTheme();

	const argreementContainer = {
		width: '100%',
		display: 'block',
		position: 'sticky',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing(2),
		boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
		borderRadius: '5px',
		color: '#fff',
	};

	return (
		<Stack sx={{ ...argreementContainer }}>
			<Grid container direction='row' justifyContent='flex-end' alignItems='center' wrap='nowrap'>
				<Grid item>
					<FormControl required error={error}>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={specific}
										onChange={handleChange}
										name='specific'
										sx={{ color: `${theme.palette.primary.white} !important` }}
									/>
								}
								label={intl.formatMessage({ id: 'terms.and.conditions.accept.specific' })}
							/>
						</FormGroup>
					</FormControl>
				</Grid>
				<Grid item>
					{/* 
					// @ts-ignore */}
					<Button variant='contained' size='medium' color='success' disabled={error || loading} onClick={confirmAgreement}>
						{intl.formatMessage({ id: 'terms.and.conditions.button' })}
					</Button>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default AgreementActions;
