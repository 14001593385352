export const licenseExpired = '/license_expired';
export const notFound = '/not_found';

export const projectsAdd = '/projects/add';
export const financialplanEdit = '/financialplan/edit';

export const themeConst = {
	beige: 'beige',
	white: 'white',
};
