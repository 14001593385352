import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { isNil } from 'lodash';

import StakeholderProfileDataMenu from './StakeholderProfileDataMenu';
import SingleProject from 'containers/Stakeholder/Views/SingleProject/SingleProject';

import { useGetBaseDataQuery, useGetCompanyDataQuery, useGetProfileDataLinkPartsQuery, useGetProfileDataLinkQuery } from './stakeholderApi';

import FooterLabel from '../../components/FooterLabel/FooterLabel';
import Error from './Views/Components/Error';
import Header from './Views/Header';
import { HeaderSkeleton } from './Views/Header/Header';

const Stakeholder = () => {
	const intl = useIntl();
	const theme = useTheme();

	const { linkId } = useParams();

	const {
		data: linkData,
		isFetching: isLinkFetching,
		isLoading: isLinkLoading,
		isSuccess: isLinkSuccess,
	} = useGetProfileDataLinkQuery(linkId);

	const {
		data: linkPartsData,
		isFetching: isLinkPartsFetching,
		isLoading: isLinkPartsLoading,
		isSuccess: isLinkPartsSuccess,
	} = useGetProfileDataLinkPartsQuery(linkId);

	const {
		data: baseData,
		isFetching: isBaseDataFetching,
		isLoading: isBaseDataLoading,
		isSuccess: isBaseDataSuccess,
	} = useGetBaseDataQuery(linkId);

	const {
		error,
		data: companyData,
		isFetching: isCompanyFetching,
		isLoading: isCompanyLoading,
		isSuccess: isCompanySuccess,
	} = useGetCompanyDataQuery(linkId);

	const isSingleProject = !isNil(linkData?.project?.projectId);

	const isLoading =
		isLinkFetching ||
		isLinkLoading ||
		isLinkPartsFetching ||
		isLinkPartsLoading ||
		isBaseDataFetching ||
		isBaseDataLoading ||
		isCompanyFetching ||
		isCompanyLoading;

	if (isLoading) {
		return (
			<Stack
				alignItems='center'
				alignContent='center'
				sx={{
					background: theme.palette.primary.secondary,
					minHeight: '100%',
				}}
			>
				<Box
					sx={{
						[theme.breakpoints.up('lg')]: {
							maxWidth: '100rem',
							width: '100rem',
						},
						[theme.breakpoints.down('md')]: {
							maxWidth: '100%',
							width: '100%',
						},
					}}
				>
					<HeaderSkeleton />
				</Box>
			</Stack>
		);
	}

	if (!isLoading && (!isLinkSuccess || !isLinkPartsSuccess || !isBaseDataSuccess || !isCompanySuccess)) {
		return <Error {...{ error }}>{intl.formatMessage({ id: 'stakeholder.company.error' })}</Error>;
	}

	if (!linkPartsData || !baseData) {
		return <Error {...{ error }}>{intl.formatMessage({ id: 'stakeholder.company.error' })}</Error>;
	}

	return (
		<Stack
			alignItems='center'
			alignContent='center'
			sx={{
				background: theme.palette.primary.secondary,
				minHeight: '100%',
				paddingX: '1.5rem',
			}}
		>
			<Box
				sx={{
					width: '100%',
					[theme.breakpoints.up('lg')]: {
						maxWidth: '100rem',
					},
					[theme.breakpoints.down('md')]: {
						maxWidth: '100%',
					},
				}}
			>
				<Header
					linkId={linkId}
					company={companyData?.company}
					linkParts={linkPartsData}
					hideAnalysis={linkPartsData?.find(item => item === 'Analysis')}
				/>
				{isSingleProject ? (
					<SingleProject linkId={linkId} />
				) : (
					<StakeholderProfileDataMenu linkParts={linkPartsData} linkId={linkId} />
				)}
				<FooterLabel sx={{ my: '2rem' }} />
			</Box>
		</Stack>
	);
};

export default Stakeholder;
