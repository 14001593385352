import moment from 'moment';
import { useIntl } from 'react-intl';

import DataGrid from 'components/DataGrid';
import { generateUid } from 'utils/helpers/uid';

type MrrLicenseGridParams = {
	rowData: any;
	loading: boolean;
	rowClick: any;
	extraColumns: any[] | null;
	autoHeight: boolean;
	heigth: number;
	rowsPerPage: number | null | undefined;
};

const MRRCompanyLicenseGrid: React.FC<MrrLicenseGridParams> = ({
	rowData,
	loading,
	rowClick,
	extraColumns,
	autoHeight = true,
	heigth,
	rowsPerPage = 100,
}) => {
	const intl = useIntl();

	const columns = [
		{
			field: 'companyName',
			headerName: intl.formatMessage({ id: 'mrr.companyName' }),
			width: 300,
			renderCell: (params: any) => (
				<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.companyName}</span>
			),
		},
		{
			field: 'companyId',
			headerName: intl.formatMessage({ id: 'mrr.companyId' }),
			width: 100,
			renderCell: (params: any) => {
				return params.row.companyId;
			},
		},
		{
			field: 'isActive',
			headerName: intl.formatMessage({ id: 'mrr.isActive' }),
			width: 75,
			type: 'bool',
			renderCell: (params: any) => (params.row.isActive ? 'Kyllä' : 'Ei'),
		},
		{
			field: 'contractPeriodMonth',
			type: 'number',
			renderHeader: () => <>{intl.formatMessage({ id: 'mrr.contractPeriodMonth' })}</>,
			width: 80,
			renderCell: (params: any) => Number(parseInt(params.row.contractPeriodMonth)),
		},
		{
			field: 'contractMonetaryValue',
			type: 'number',
			renderHeader: () => <>{intl.formatMessage({ id: 'mrr.contractMonetaryValue' })}</>,
			width: 70,
			renderCell: (params: any) => Number(parseInt(params.row.contractMonetaryValue)),
		},
		{
			field: 'startDate',
			headerName: intl.formatMessage({ id: 'mrr.startDate' }),
			width: 120,
			type: 'date',
			renderCell: (params: any) => moment(params.row.startDate).format('DD.MM.YYYY'),
		},
		{
			field: 'endDate',
			headerName: intl.formatMessage({ id: 'mrr.endDate' }),
			width: 120,
			type: 'date',
			renderCell: (params: any) => (params.row.endDate ? moment(params.row.endDate).format('DD.MM.YYYY') : null),
		},
		{
			field: 'type',
			headerName: intl.formatMessage({ id: 'mrr.type' }),
			width: 150,
			valueGetter: (params: any) =>
				intl.formatMessage({ id: `license.form.licenseType.${params.row.type ?? params.row.licenseType}` }),
		},
		{
			field: 'package',
			headerName: intl.formatMessage({ id: 'mrr.package' }),
			width: 150,
			valueGetter: (params: any) => {
				return !params.row.package ? null : intl.formatMessage({ id: `license.form.licensePackage.${params.row.package}` });
			},
		},
	];

	if (extraColumns && extraColumns.length > 0) {
		for (let index = 0; index < extraColumns.length; index++) {
			columns.push(extraColumns[index]);
		}
	}

	return (
		<>
			<DataGrid
				sx={{
					'&:hover': { cursor: 'pointer' },
				}}
				autoHeight={autoHeight}
				heigth={heigth}
				rowHeight={37}
				getRowId={generateUid}
				idField='rowKey'
				loading={loading}
				data={rowData}
				columns={columns}
				onRowClick={rowClick}
				initialState={{ pinnedColumns: { left: ['companyName'] } }}
				paginationMode='client'
				// @ts-ignore
				rowsPerPage={rowsPerPage}
			/>
		</>
	);
};

export default MRRCompanyLicenseGrid;
