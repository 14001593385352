import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { AddButton } from 'components/Form/CustomButton';
import Spinner from 'components/Spinner/CenteredSpinner';
import MainTitle from 'components/Titles/MainTitle';
import { projectsAdd } from 'config/routeConst';
import ProjectGridList from 'containers/Projects/ProjectList/ProjectGridList';

import { Stack } from '@mui/material';
import ContentContainer from 'components/Containers/ContentContainer';
import getCompanyLink from 'utils/auth/getCompanyLink';

const Projects = () => {
	const history = useHistory();
	const location = useLocation();
	// @ts-ignore
	const kanban = useSelector(state => state.kanban);

	const projects = kanban?.projects;
	const isLoaded = projects?.isLoaded;
	const mock = projects?.mock;

	const addProject = () => history.push(getCompanyLink(projectsAdd, location));

	if (!isLoaded) return <Spinner />;

	return (
		<ContentContainer>
			<Stack direction='row' alignContent='space-between' alignItems='center' sx={{ mb: '1.5rem' }}>
				<MainTitle margin={false} sx={{ flexGrow: 1 }}>
					<FormattedMessage id='project.projects.title' />
				</MainTitle>
				<AddButton onClick={addProject}>
					<FormattedMessage id='project.add.new' />
				</AddButton>
			</Stack>
			<ProjectGridList id='general' projects={mock ? {} : projects} addProject={addProject} />
		</ContentContainer>
	);
};

export default Projects;
