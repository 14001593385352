import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { find, findIndex, isNil, remove, set } from 'lodash';

type DialogState = {
	dialogs: {
		name: string;
		data?: any;
	}[];
};

const initialState = { dialogs: [] } as DialogState;

const dialogSlice = createSlice({
	name: 'dialog',
	initialState,
	reducers: {
		openDialog(state, action: PayloadAction<{ name: string; data?: any }>) {
			let index = findIndex(state.dialogs, { name: action.payload.name });
			if (index <= -1) {
				index = state.dialogs.length;
			}
			set(state.dialogs, index, { ...action.payload });
		},
		closeDialog(state, action: PayloadAction<{ name: string }>) {
			remove(state.dialogs, { name: action.payload.name });
		},
	},
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export const isDialogOpen = (name: string) => (state: any) => {
	const dialogState = state[dialogSlice.name] as DialogState;
	const dialog = find(dialogState.dialogs, { name });
	return !isNil(dialog);
};

export const getDialogData = (name: string) => (state: any) => {
	const dialogState = state[dialogSlice.name] as DialogState;
	const dialog = find(dialogState.dialogs, { name });
	return dialog?.data;
};

export default dialogSlice;
