import { useSelector } from 'react-redux';

import { aiApi } from 'containers/AI/aiApi';
import { useInjectReducer } from 'utils/injectors/injectReducer';

const AiGeneratorWrapper = ({ children }: any) => {
	const ai = useSelector((state: any) => state.ai);

	useInjectReducer({ key: aiApi.reducerPath, reducer: aiApi.reducer }); // async process, wait until loaded the state

	if (!ai) return null;

	return children;
};

export default AiGeneratorWrapper;
