import { storageKeys } from 'utils/constants/constants';
import storage from 'utils/localStorage/localStorageFunctions';

/**
 * SET local storage user object
 * @param {token object} user
 */
const setUser = (user: any) => {
	storage.set(storageKeys.USER_KEY, user);
};

/**
 * GET local storage user object
 */
const getUser = (): any => {
	return storage.get(storageKeys.USER_KEY);
};

const getChat = () => {
	return storage.get(storageKeys.CHAT_KEY);
};

export { getChat, getUser, setUser };
