import React from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/styles';

const StyledContainer = styled(Grid)(() => ({
    height: 'calc(100vh - 40px - 129px)', // 40px --> breadcrumb heigth, 129px --> footer height
}));

const PageSpinner = () => (
    <StyledContainer container justifyContent='center' alignItems='center'>
        <Grid item>
            <CircularProgress size={24} color='primary' />
        </Grid>
    </StyledContainer>
);

export default PageSpinner;