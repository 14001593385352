import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Chip, LinearProgress, Typography } from '@mui/material';

import { Box } from '@mui/system';
import MultiSelect, { SelectOption } from 'components/Multiselect/Multiselect';
import { Instrument } from 'types/dataTypes';
import { generateUid } from 'utils/helpers/uid';
import {
	SelectOptions,
	addBusinessPlan,
	addCompany,
	addFinancialPlan,
	addNotes,
	addProjects,
	addScenario,
	addTeamMembers,
	selectAIPromptBaseData,
} from './AIContextSelectorUtils';

import { useFetchBusinessPlanQuery, useFetchBusinessPlanTeamMembersQuery } from 'containers/BusinessPlan/businessPlanV2Api';
import { useGetFinancialPlanQuery } from 'containers/FinancialPlan/financialPlanV2Api';
import { findRecursive } from '../../../utils/helpers/array';
import { useFetchCompanyNotesQuery } from '../../Admin/UsersCompany/companyNotesV2Api';
import { useFetchCompanyQuery } from '../../Company/companyV2Api';
import { useFetchInstrumentsByCompanyQuery } from '../../Instruments/instrumentV2Api';
import { useFetchProjectsQuery } from '../../Projects/projectRTKApi';
import { useFetchScenarioQuery } from '../../Scenario/scenarioV2Api';
import InstrumentsSelector from './InstrumentsSelector';

type AIContextSelectorProps = {
	companyId: string | null;
	sx?: {};
	onSelectedChanged: (values: ExtendedSelectOption[]) => void;
	preSelectedItems?: Array<string>;
};

export type ExtendedSelectOption = SelectOption & { data?: string | null | undefined };

export const AIContextSelector: React.FC<AIContextSelectorProps> = ({ companyId, onSelectedChanged, preSelectedItems = [], sx }) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: isNil(companyId) });
	const { data: businessPlan, isLoading: isBusinessPlanLoading } = useFetchBusinessPlanQuery({ companyId }, { skip: isNil(companyId) });
	const { data: team, isLoading: isTeamLoading } = useFetchBusinessPlanTeamMembersQuery({ companyId }, { skip: isNil(companyId) });
	const { data: financialPlan, isLoading: isFinancialPlanLoading } = useGetFinancialPlanQuery({ companyId }, { skip: isNil(companyId) });
	const { data: projects, isLoading: isProjectsLoading } = useFetchProjectsQuery({ companyId }, { skip: isNil(companyId) });
	const { data: notes, isLoading: isNotesLoading } = useFetchCompanyNotesQuery({ companyId }, { skip: isNil(companyId) });
	const { data: instruments, isLoading: isInstrumentsLoading } = useFetchInstrumentsByCompanyQuery(
		{ companyId },
		{ skip: isNil(companyId) }
	);
	const { data: scenario, isLoading: isScenarioLoading } = useFetchScenarioQuery(
		{ companyId, businessId: company?.businessId },
		{ skip: isNil(companyId) || isCompanyLoading || isCompanyFetching }
	);

	const [chips, setChips] = useState<any[]>([]);
	const [selectOptions, setSelectedOptions] = useState<SelectOptions>(null);
	const [selectedInstruments, setSelectedInstruments] = useState<Array<Instrument>>([]);

	useEffect(() => {
		if (!businessPlan || !projects || !financialPlan || notes === undefined || !scenario || !team || !company || !instruments) return;

		const callItems = async () => {
			const companyData = addCompany(company, formatMessage);
			const businessPlanData = addBusinessPlan(businessPlan, formatMessage);
			const projectData = await addProjects(projects, instruments, formatMessage, dispatch);
			const financialPlanData = addFinancialPlan(financialPlan, formatMessage);
			const notesData = addNotes(notes, formatMessage);
			const teamData = addTeamMembers(team);
			const scenarioData = addScenario(scenario, formatMessage);

			if (companyId && (!selectOptions?.options || selectOptions?.options.length === 0)) {
				setSelectedOptions({
					companyId,
					options: [companyData, businessPlanData, projectData, financialPlanData, notesData, teamData, scenarioData].filter(
						item => item
					),
				});
			}
		};
		callItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId, company, businessPlan, projects, financialPlan, notes, team, scenario, instruments]);

	useEffect(() => {
		if (!company || !businessPlan || !projects || !financialPlan || notes === undefined || !scenario || !team) return;

		if (!selectOptions) return;

		let selectedItems = [];
		for (const item of preSelectedItems) {
			const res = findRecursive(item, selectOptions.options);

			if (res) {
				selectedItems.push(res);
			}
		}

		setChips(selectedItems);
		selectAIPromptBaseData({
			values: selectedItems as Array<ExtendedSelectOption>,
			company,
			businessPlan,
			financialPlan,
			projects,
			notes,
			instruments,
			team,
			scenario,
			formatMessage,
		});
		onSelectedChanged(selectedItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectOptions]);

	const handleChipDelete = (chip: any) => {
		const currentChips = chips.filter(prevChip => chip !== prevChip);
		setChips(currentChips);
		selectAIPromptBaseData({
			values: currentChips as Array<ExtendedSelectOption>,
			company,
			businessPlan,
			financialPlan,
			projects,
			notes,
			instruments,
			team,
			scenario,
			formatMessage,
		});
		onSelectedChanged(currentChips);

		const instrumentsSelected = instruments?.filter((item: any) => {
			// @ts-ignore
			const hasChip = currentChips.find((chip: any) => {
				return chip.value === `instruments.${item.id}`;
			});

			return hasChip;
		});
		setSelectedInstruments(instrumentsSelected ?? []);
	};

	const handleMultiSelectChange = (value: any) => {
		setChips(value);
		selectAIPromptBaseData({
			values: value as Array<ExtendedSelectOption>,
			company,
			businessPlan,
			financialPlan,
			projects,
			notes,
			instruments,
			team,
			scenario,
			formatMessage,
		}); // TODO: fix mutate array object -> add data
		onSelectedChanged(value);
	};

	const setInstrumentsData = (data: Array<Instrument>) => {
		// @ts-ignore
		setSelectedInstruments(data);
		const currentChips = chips.filter((chip: any) => !chip.value.startsWith('instruments.'));

		const instrumentChips = [];
		for (const instrument of data) {
			instrumentChips.push({
				label: instrument.name,
				value: `instruments.${instrument.id}`,
			});
		}

		selectAIPromptBaseData({
			values: [...currentChips, ...instrumentChips] as Array<ExtendedSelectOption>,
			company,
			businessPlan,
			financialPlan,
			projects,
			notes,
			instruments,
			team,
			scenario,
			formatMessage,
		}); // TODO: fix mutate array object -> add data
		setChips([...currentChips, ...instrumentChips]);
		onSelectedChanged([...currentChips, ...instrumentChips]);
	};

	if (
		isBusinessPlanLoading ||
		isFinancialPlanLoading ||
		isProjectsLoading ||
		isNotesLoading ||
		isTeamLoading ||
		isInstrumentsLoading ||
		isScenarioLoading ||
		isCompanyLoading
	)
		return (
			<Box sx={{ p: 1, width: '100%' }}>
				<LinearProgress />
			</Box>
		);

	if (!selectOptions?.options || selectOptions?.options.length === 0)
		return (
			<Box sx={{ p: 1, width: '100%' }}>
				<LinearProgress />
			</Box>
		);

	return (
		<Box sx={{ boxShadow: '0px 1px 8px 1px rgba(72, 46, 81, .2)', ...sx }}>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					p: 1,
				}}
			>
				{selectOptions?.options.map((option: any) => (
					<React.Fragment key={generateUid()}>
						<MultiSelect label={option.label} onChange={handleMultiSelectChange} options={option.options} value={chips}>
							{option?.children?.map((child: any) => (
								<MultiSelect
									sx={{ mt: '1rem' }}
									key={generateUid()}
									label={child.label}
									onChange={handleMultiSelectChange}
									options={option?.options}
									value={chips}
								/>
							))}
						</MultiSelect>
					</React.Fragment>
				))}
				{/* @ts-ignore */}
				<InstrumentsSelector instruments={instruments} onInstrumentChange={setInstrumentsData} value={selectedInstruments} />
				<Box sx={{ flexGrow: 1 }} />
			</Box>

			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					p: 2,
					overflowX: 'hidden',
					overflowY: 'auto',
					maxHeight: '8rem',
				}}
			>
				{chips.map(chip => (
					<Chip
						size='small'
						key={generateUid()}
						label={chip.label}
						onDelete={() => handleChipDelete(chip)}
						sx={{ mr: '.5rem', mb: '.5rem' }}
						variant='outlined'
					/>
				))}
				{(!chips || chips.length === 0) && <Typography variant='lightText'>Ei mitään</Typography>}
			</Box>
		</Box>
	);
};
