import { useIntl } from 'react-intl';

import GroupIcon from '@mui/icons-material/Group';
import MapIcon from '@mui/icons-material/Map';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';

import { Typography } from '@mui/material';

import { NoteCategory } from 'types/dataTypes';

const Item = ({ title, icon }: { title: string; icon: any }) => {
	return (
		<>
			{icon}
			<Typography
				component='div'
				sx={{
					fontSize: '0.8rem',
				}}
			>
				<Typography>{title}</Typography>
			</Typography>
		</>
	);
};

const NoteByCategory = ({ category }: { category: NoteCategory }) => {
	const { formatMessage } = useIntl();

	switch (category) {
		case 'brief':
			return (
				<Item
					title={formatMessage({ id: `company.notes.list.item.category.${category}` })}
					icon={<MapIcon color='primary' fontSize='small' />}
				/>
			);
		case 'start':
			return (
				<Item
					title={formatMessage({ id: `company.notes.list.item.category.${category}` })}
					icon={<GroupIcon color='primary' fontSize='small' />}
				/>
			);
		case 'general':
			return (
				<Item
					title={formatMessage({ id: `company.notes.list.item.category.${category}` })}
					icon={<SpeakerNotesIcon color='primary' fontSize='small' />}
				/>
			);
		case 'financePlan':
			return (
				<Item
					title={formatMessage({ id: `company.notes.list.item.category.${category}` })}
					icon={<ViewTimelineOutlinedIcon color='primary' fontSize='small' />}
				/>
			);
		default:
			return (
				<Item
					title={formatMessage({ id: `company.notes.list.item.category.${category}` })}
					icon={<SpeakerNotesIcon color='primary' fontSize='small' />}
				/>
			);
	}
};

export default NoteByCategory;
