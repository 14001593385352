import * as yup from 'yup';

/**
 * Return YUP validation schema for project costs
 * @param {*} intl 
 * @returns 
 */
export const getProjectCostValidationSchema = (intl) => {
    return yup.object().shape({
        name: yup
            .string()
            .required(`${intl.formatMessage({ id: 'project.cost.form.name' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
        startDate: yup
            .date().nullable().default(null)
            .typeError(intl.formatMessage({ id: 'project.form.dateformat' })),
        endDate: yup
            .date().nullable().default(null)
            .min(yup.ref('startDate'), intl.formatMessage({ id: 'project.form.endDate.mindate' }))
            .typeError(intl.formatMessage({ id: 'project.form.dateformat' })),
        projectTaskId: yup
            .string()
            .required(`${intl.formatMessage({ id: 'project.cost.form.projectTaskId' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
        category: yup
            .string()
            .required(`${intl.formatMessage({ id: 'project.cost.form.category' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
        phase: yup
            .string()
            .required(`${intl.formatMessage({ id: 'project.cost.form.phase' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
        employeeName: yup
            .string()
            .nullable()
            .when('category', {
                is: category => category === 'salary',
                then: yup
                    .string()
                    .nullable()
                    .required(`${intl.formatMessage({ id: 'project.cost.form.employeeName' })} ${intl.formatMessage({ id: 'message.isRequired' })}`)
            })
    });
};