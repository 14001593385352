import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Bar, BarChart, Brush, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { demoEconomicalData } from 'utils/mock/demoEconomicalData';
import './chart.css';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		backgroundColor: theme.palette.primary.white,
		minHeight: '220px',
		padding: theme.spacing(3),
		height: '100%',
		'& .recharts-surface': {},
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 700,
		paddingBottom: theme.spacing(4),
	},
	noDataTextWrapper: {
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		width: '100%',
	},
	noDataText: {
		color: '#a0a0a0',
		fontSize: '1.8rem',
		fontWeight: 500,
		zIndex: 5,
		'&:hover': {
			cursor: 'default',
		},
	},
	dialogRoot: {
		'& .MuiDialog-paperFullWidth': {
			width: '100%',
		},
		'& .MuiDialog-paperScrollPaper': {
			[theme.breakpoints.down('sm')]: {
				maxHeight: '100%',
				height: '100%',
			},
		},
		'& .MuiDialog-paper': {
			[theme.breakpoints.down('sm')]: {
				margin: 0,
			},
		},
		'& .MuiDialogContent-root:first-child': {
			paddingTop: 0,
		},
	},
	dialogContent: {
		backgroundColor: theme.palette.primary.white,
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0.5),
		},
	},
	closeIcon: {
		color: theme.palette.primary.main,
	},
	tableContainer: {
		boxShadow: 'none',
		maxHeight: '100%',
		height: 'calc(100% - 3rem)',
	},
	tableRoot: {
		backgroundColor: theme.palette.primary.white,
		height: '100%',
	},
	name: {
		fontWeight: 500,
	},
}));

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const Analysis = ({ data, loading, height }) => {
	const classes = useStyles();
	const intl = useIntl();

	const chartData =
		data?.length > 0
			? data
				.map(item => ({
					...item,
					turnover: +item.turnover.toFixed(0),
					equity: +item.equity.toFixed(0),
					ebitdas: +item.ebitdas.toFixed(0),
				}))
				.reverse()
			: demoEconomicalData;

	const minValue = Math.min(...chartData.map(({ turnover, equity }) => (turnover <= equity ? turnover : equity)));
	const maxValue = Math.max(...chartData.map(({ turnover, equity }) => (turnover >= equity ? turnover : equity)));
	const absMaxValue = Math.abs(maxValue) >= Math.abs(minValue) ? Math.abs(maxValue) : Math.abs(minValue);
	const getAbsMaxValue = Math.ceil(absMaxValue).toString().length - 1;
	const absMaxValueRounded = Math.ceil(absMaxValue * +`1e-${getAbsMaxValue}`) * +`1e+${getAbsMaxValue}`;

	const translatedValue = key => {
		const val = intl.formatMessage({ id: key });

		return val === key ? 'undefined' : val;
	};

	const CustomizedLabel = ({ x, y, width, value }) => {
		const dy = value >= 0 ? -5 : 15;

		return (
			<text x={x + width / 2} y={y} dy={dy} fill={data?.length > 0 ? '#482e51' : '#000'} fontSize={13} textAnchor='middle'>
				{value}
			</text>
		);
	};

	const [activeYear, setActiveYear] = useState(null);
	const [open, setOpen] = useState(false);
	const openModal = id => setOpen(id);
	const closeModal = () => setOpen(false);

	const createData = (name, array) => ({ name, array });

	// converted values
	const turnoverArray = data?.map(({ year, turnover }) => ({
		year,
		prop: turnover.toFixed(0),
	}));
	const profitsArray = data?.map(({ year, profits }) => ({
		year,
		prop: profits.toFixed(0),
	}));
	const balanceArray = data?.map(({ year, balance }) => ({
		year,
		prop: balance.toFixed(0),
	}));
	const equityArray = data?.map(({ year, equity }) => ({
		year,
		prop: equity.toFixed(0),
	}));
	const ebitdasArray = data?.map(({ year, ebitdas }) => ({
		year,
		prop: ebitdas.toFixed(0),
	}));
	const solvencyArray = data?.map(({ year, solvency }) => ({ year, prop: solvency }));

	// other
	const currentRatioArray = data?.map(({ year, currentRatio }) => ({ year, prop: currentRatio }));
	const ebitdaArray = data?.map(({ year, ebitda }) => ({ year, prop: ebitda }));
	const employeesArray = data?.map(({ year, employees }) => ({ year, prop: employees }));
	const percentageChangeArray = data?.map(({ year, turnoverChange }) => ({ year, prop: turnoverChange }));
	const quickRatioArray = data?.map(({ year, quickRatio }) => ({ year, prop: quickRatio }));
	const yearArray = data?.map(({ year }) => year);

	const rows = [
		createData('Liikevaihto tEUR', turnoverArray),
		createData('Tase tEUR', balanceArray),
		createData('Oma pääoma tEUR', equityArray),
		createData('Liiketulos tEUR', profitsArray),
		createData('Vakavaraisuus %', solvencyArray),
		createData('Liikevaihdon muutos %', percentageChangeArray),
		createData('Käyttökate tEUR', ebitdasArray),
		createData('Käyttökate %', ebitdaArray),
		createData('Quick ratio', quickRatioArray),
		createData('Current ratio', currentRatioArray),
		createData('Henkilöstö', employeesArray),
	];

	const showAllData = data => {
		setActiveYear(data.year);
		openModal(true);
	};

	return (
		<div className={classes.root}>
			<>
				<Typography variant='h2' color='primary' className={classes.title}>
					{intl.formatMessage({ id: 'analysis.title' })}
				</Typography>
				{loading ? (
					<>
						<div
							className={classes.noDataTextWrapper}
							style={{
								top: 220,
							}}
						>
							<CircularProgress size={32} color='primary' />
						</div>
						<ResponsiveContainer width='100%' height={height}>
							<BarChart
								data={chartData}
								margin={{
									top: 30,
									right: 40,
									left: 0,
									bottom: 0,
								}}
							>
								<CartesianGrid vertical={false} />
								<XAxis dataKey='year' tickFormatter={() => 'vuosi'} />
								<YAxis tickFormatter={value => (value === 0 ? 0 : 'EUR')} />
								<Bar dataKey='turnover' fill='transparent' isAnimationActive={false} />
								<Bar dataKey='equity' fill='transparent' isAnimationActive={false} />
								<Bar dataKey='ebitdas' fill='transparent' isAnimationActive={false} />
							</BarChart>
						</ResponsiveContainer>
					</>
				) : (
					<Stack direction='row' justifyContent='center' alignItems='flex-end'>
						{(!data || data.length === 0) && (
							<div
								className={classes.noDataTextWrapper}
								style={{
									top: 225,
								}}
							>
								<Typography component='span' className={classes.noDataText}>
									Ei taloustietoja
								</Typography>
							</div>
						)}
						<ResponsiveContainer width='100%' height={height}>
							<BarChart
								width={500}
								height={300}
								data={chartData}
								barGap={data?.length === 1 ? 25 : data?.length === 2 ? 10 : 5}
								barCategoryGap={data?.length === 1 ? 20 : data?.length === 2 ? 15 : 10}
							>
								<CartesianGrid vertical={false} />
								<XAxis dataKey='year' />
								{maxValue > 0 ? (
									<YAxis>
										<Label
											value={'Tuhatta euroa'}
											position='insideLeft'
											angle={-90}
											style={{ textAnchor: 'middle', fill: '#696969' }}
										/>
									</YAxis>
								) : (
									<YAxis
										domain={[-absMaxValueRounded, absMaxValueRounded / 2]}
										ticks={[-absMaxValueRounded, -absMaxValueRounded / 2, 0, absMaxValueRounded / 2]}
									>
										<Label
											value={'Tuhatta euroa'}
											position='insideLeft'
											angle={-90}
											style={{ textAnchor: 'middle', fill: '#696969' }}
										/>
									</YAxis>
								)}
								<Tooltip separator=': ' itemStyle={{ color: '#482e51' }} />
								<Legend />
								{data?.length > 3 && (
									<Brush
										dataKey='year'
										startIndex={data.length - 3}
										endIndex={data.length - 1}
										travellerWidth={10}
										height={20}
										stroke='#482e51'
									/>
								)}
								<Bar
									dataKey='turnover'
									fill={data?.length > 0 ? '#8e0e44' : 'rgba(204, 204, 204, 0.25)'}
									name={translatedValue('analysis.turnover')}
									label={data?.length > 0 && <CustomizedLabel />}
									isAnimationActive={false}
									cursor={data?.length > 0 ? 'pointer' : 'default'}
									onClick={data?.length > 0 && showAllData}
								/>
								<Bar
									dataKey='equity'
									fill={data?.length > 0 ? '#f2eee5' : 'rgba(179, 179, 179, 0.25)'}
									name={translatedValue('analysis.equity')}
									label={data?.length > 0 && <CustomizedLabel />}
									isAnimationActive={false}
									cursor={data?.length > 0 ? 'pointer' : 'default'}
									onClick={data?.length > 0 && showAllData}
								/>
								<Bar
									dataKey='ebitdas'
									fill={data?.length > 0 ? '#482E51' : 'rgba(179, 179, 179, 0.25)'}
									name={translatedValue('analysis.ebitda')}
									label={data?.length > 0 && <CustomizedLabel />}
									isAnimationActive={false}
									cursor={data?.length > 0 ? 'pointer' : 'default'}
									onClick={data?.length > 0 && showAllData}
								/>
							</BarChart>
						</ResponsiveContainer>
						<Dialog
							aria-labelledby={intl.formatMessage({ id: 'analysis.title' })}
							aria-describedby={intl.formatMessage({ id: 'analysis.title' })}
							fullWidth
							maxWidth='md'
							open={open}
							onClose={closeModal}
							scroll='paper'
							TransitionComponent={Transition}
							classes={{
								root: classes.dialogRoot,
							}}
						>
							<DialogContent className={classes.dialogContent}>
								<Box textAlign='right'>
									<IconButton aria-label='close' onClick={closeModal}>
										<CloseIcon className={classes.closeIcon} />
									</IconButton>
								</Box>
								<TableContainer
									component={Paper}
									classes={{
										root: classes.tableContainer,
									}}
								>
									<Table
										size='small'
										aria-label='economical data table'
										classes={{
											root: classes.tableRoot,
										}}
									>
										<TableHead>
											<TableRow>
												<TableCell />
												{yearArray?.map(year => (
													<TableCell
														key={year}
														align='right'
														style={{
															backgroundColor: year === activeYear && 'rgba(72, 46, 81, 0.8)',
															color: year === activeYear && '#fff',
														}}
													>
														{year}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{rows?.map(({ name, array }) => (
												<TableRow key={name}>
													<TableCell component='th' scope='row' className={classes.name}>
														{name}
													</TableCell>
													{array?.map(({ year, prop }, index) => (
														<TableCell
															key={index}
															align='right'
															style={{
																backgroundColor: year === activeYear && 'rgba(72, 46, 81, 0.8)',
																color: year === activeYear && '#fff',
															}}
														>
															{prop}
														</TableCell>
													))}
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</DialogContent>
						</Dialog>
					</Stack>
				)}
			</>
		</div>
	);
};

export default Analysis;
