import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ConfirmButton from 'components/Button/ConfirmButton';
import ConfirmIconButton from 'components/Button/ConfirmIconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DataGrid from 'components/DataGrid';
import Button from 'components/Button/Button';
import { Box, Stack } from '@mui/material';
import FormattedDate from 'components/Formatters/FormattedDate';
import { useTheme } from '@mui/styles';

const CompanyRolesTable = ({ loading, data, classes, fetchCompanyDetails = null, fetchUserDetails = null, handleDeleteCompanyRoles = null }) => {
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();

    const [selectedRoleIds, setSelectedRoleIds] = useState([]);

    const onClickDeleteCompanyRoles = (selectedCompanyRoleIds) => {
        const selectedCompanyRoles = selectedCompanyRoleIds.map(
            selectedRoleId => data.find(companyRole => companyRole.id === selectedRoleId)
        );

        handleDeleteCompanyRoles(selectedCompanyRoles);
        setSelectedRoleIds([]);
    };

    const onClickDeleteCompanyRole = (companyRoleId) => {

        const role = data.find(companyRole => companyRole.id === companyRoleId);

        handleDeleteCompanyRoles([role]);
        setSelectedRoleIds([]);
    };

    const columns = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'companydetails.role.name' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>{params.row.name}</span>
            ),
        },
        {
            field: 'timestamp',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userlist.table.timestamp' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.timestamp} />
                </span>
            ),
        },
        {
            field: 'deleteCompanyRole',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'companyroles.table.role.delete' }),
            headerClassName: 'styled-header',
            width: 120,
            renderCell: params => (
                <Box textAlign='center'>
                    <ConfirmIconButton
                        color='primary'
                        loading={loading}
                        disabled={loading}
                        confirmText={intl.formatMessage({ id: 'companyroles.delete.confirm' })}
                        cancelText={intl.formatMessage({ id: 'companyroles.delete.cancel' })}
                        confirmBodyText={intl.formatMessage({ id: 'companyroles.table.role.delete.body' })}
                        confirmAction={() => onClickDeleteCompanyRole(params.id)}
                        stopPropagation={true}
                        sx={{ mr: 2 }}
                    >
                        <DeleteIcon />
                    </ConfirmIconButton>
                </Box>
            ),
        },
    ];

    if (fetchCompanyDetails) {
        columns.splice(0, 0, {
            field: 'companyId',
            headerName: intl.formatMessage({ id: 'companylist.table.id' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <Button
                    size='small'
                    color='primary'
                    textCase='none'
                    disabled={loading || !params.row.companyId || params.row.companyId === 'undefined'}
                    onClick={() => history.push(`/admin/company/${params.row.companyId}`)}
                >
                    {params.row.companyId}
                </Button>
            ),
        });
        // columns.splice(0, 0, {
        //     field: 'companyName',
        //     headerName: intl.formatMessage({ id: 'companylist.table.name' }),
        //     headerClassName: 'styled-header',
        //     width: 250,
        //     renderCell: params => (
        //         <>
        //             {params.row.companyName}
        //         </>
        //     ),
        // });
        columns.splice(2, 0, {
            field: 'companyDetails',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'companylist.table.companyDetails' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <Button
                    className={classes.detailsButton}
                    variant='outlined'
                    size='small'
                    color='primary'
                    disabled={loading || !params.row.companyId || params.row.companyId === 'undefined'}
                    onClick={() => fetchCompanyDetails(params.row.companyId)}
                >
                    {intl.formatMessage({ id: 'companylist.table.companyDetails.link' })}
                </Button>
            ),
        });
    }

    if (fetchUserDetails) {
        columns.splice(0, 0, {
            field: 'username',
            headerName: intl.formatMessage({ id: 'companydetails.users.username' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <>
                    {params.row.userId}
                </>
            ),
        });
    }

    if (data?.length === 0) { return null; }

    return (
        <>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ mt: '3rem', width: '100%' }}
            >
                <Typography variant='h2' sx={{
                    fontSize: '1.2rem',
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                }}>
                    {intl.formatMessage({ id: 'userdetails.companyroles.title' })}
                </Typography>
                <ConfirmButton
                    variant='text'
                    color='error'
                    startIcon={<DeleteIcon size='small' />}
                    confirmAction={() => onClickDeleteCompanyRoles(selectedRoleIds)}
                    confirmText={intl.formatMessage({ id: 'companyroles.delete.confirm' })}
                    cancelText={intl.formatMessage({ id: 'companyroles.delete.cancel' })}
                    confirmBodyText={intl.formatMessage({ id: 'companyroles.delete.body' })}
                    buttonText={intl.formatMessage({ id: 'companyroles.delete.remove' })}
                    loading={loading}
                    disabled={loading || !data || !selectedRoleIds || selectedRoleIds.length === 0}
                />
            </Stack>
            {
                loading || !loading && data?.length !== 0
                    ? <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectedRoleIds(newSelectionModel);
                        }}
                        selectionModel={selectedRoleIds}
                        idField='rowKey'
                        loading={loading}
                        data={data}
                        columns={columns}
                        paginationMode='client'
                    />
                    : <Typography component='p'>{intl.formatMessage({ id: 'userdetails.no.companyroles' })}</Typography>
            }
        </>
    );
};

export default CompanyRolesTable;