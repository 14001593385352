import { find, isArray, isEmpty, isNil, replace, truncate } from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import {
	Alert,
	AlertTitle,
	Avatar,
	Box,
	BoxProps,
	Chip,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';

import { PaginatorProvider } from 'components/Pagination/PaginationContext';
import { DataList, IListItem, ListItemType, PageActions } from 'components/Pagination/Paginator';
import { openDrawer } from 'containers/Marketplace/drawerSlice';
import { useFetchRfpRequestsQuery } from 'containers/Marketplace/rfpRequestApi';
import { PublicProfilesList, RfpProposal, RfpRequest } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import BlobDownloader from 'containers/Marketplace/attachment/BlobDownloader';
import CanUpsertRfpRequest from 'containers/Marketplace/request/CanUpsertRfpRequest';
import { useGetPublicProfilesQuery } from 'containers/Marketplace/rfpProfileApi';
import RfpRequestProposals from './RfpRequestProposals';

type Props = BoxProps & {};

interface IRequestProposalListItem extends ListItemType<RfpProposal> {
	data?: RfpProposal | undefined;
	publicRfpProfiles?: PublicProfilesList | undefined;
}
const RequestProposalListItem: React.FC<IRequestProposalListItem> = ({ publicRfpProfiles, data }) => {
	const dispatch = useDispatch();

	const CompanyLogo = ({ companyId }: { companyId: string | null | undefined }) => {
		if (!companyId || !publicRfpProfiles) return null;

		const company = find(publicRfpProfiles?.data, item => item.companyId === companyId);

		if (!company) return null;

		return <BlobDownloader url={company.logo}>{src => <Avatar variant='logo2' src={src} />}</BlobDownloader>;
	};

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	return (
		<ListItem disablePadding>
			<ListItemButton
				onClick={() =>
					dispatch(
						openDrawer({
							name: 'ReadRfpProposalDrawer',
							data: {
								requestId: data?.requestId,
								proposalId: data?.rfpProposalId,
								published: data?.status === 'Published',
								createdByMe: data?.companyId === companyId,
							},
						})
					)
				}
			>
				<ListItemText
					primary={
						<Stack direction='row'>
							<CompanyLogo companyId={data?.companyId} />
							<Box sx={{ pl: '1rem' }}>
								<Box>
									<Typography sx={{ fontSize: 'inherit', fontWeight: 500 }}>{data?.companyName}</Typography>
									<Chip sx={{ mt: '.5rem' }} variant='filled' label={data?.companyId} />
								</Box>
								<Typography component='span' variant='body2' sx={{ mt: 2, mr: 1, display: 'block' }}>
									{truncate(replace(data?.projectOutline || '', /\r|\n|\r\n/g, ' '), { length: 500 })}
								</Typography>
							</Box>
						</Stack>
					}
					// secondary={
					// 	<>
					// 		<Typography component='span' variant='body2' sx={{ mt: 2, mr: 1, display: 'block' }}>
					// 			{truncate(replace(proposal?.projectOutline || '', /\r|\n|\r\n/g, ' '), { length: 500 })}
					// 		</Typography>
					// 	</>
					// }
				/>
			</ListItemButton>
		</ListItem>
	);
};

type ProposalListProps = {
	data: RfpRequest | undefined;
};

const ProposalList: React.FC<ProposalListProps> = ({ data }) => {
	const { data: publicRfpProfiles, isLoading, isError } = useGetPublicProfilesQuery({ token: { take: 1000 } });

	if (!data?.proposals || data?.proposals.length === 0) return null;

	return (
		<PaginatorProvider rowsPerPage={5} data={data?.proposals}>
			<DataList dataListItem={<RequestProposalListItem publicRfpProfiles={publicRfpProfiles} />} />
			<PageActions />
		</PaginatorProvider>
	);
};

interface IDataListItemProps extends ListItemType<RfpRequest> {
	data?: RfpRequest;
}

const DataListItem: React.FC<IDataListItemProps> = ({ data }) => {
	const dispatch = useDispatch();

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	return (
		<ListItem
			key={data?.rfpRequestId}
			secondaryAction={
				<CanUpsertRfpRequest rfpRequest={data}>
					{canUpsert =>
						canUpsert && (
							<IconButton
								onClick={() => {
									dispatch(
										openDrawer({
											name: 'UpsertRfpRequestDrawer',
											data: {
												requestId: data?.rfpRequestId,
												published: data?.status === 'Published',
											},
										})
									);
								}}
							>
								<EditIcon />
							</IconButton>
						)
					}
				</CanUpsertRfpRequest>
			}
			sx={{
				'&>.MuiListItemButton-root': {
					pr: 15,
				},
			}}
			disablePadding
		>
			<ListItemButton
				onClick={() => {
					dispatch(
						openDrawer({
							name: 'ReadRfpRequestDrawer',
							data: {
								requestId: data?.rfpRequestId,
								published: data?.status === 'Published',
								createdByMe: data?.companyId === companyId,
							},
						})
					);
				}}
			>
				<ListItemText
					primary={data?.projectTitle}
					secondary={
						<>
							<Typography component='span' variant='subtitle1' sx={{ mr: 1 }}>
								{data?.companyName}
							</Typography>
							<Typography component='span' variant='body2' sx={{ mr: 1, display: 'block' }}>
								{truncate(replace(data?.projectIngress || '', /\r|\n|\r\n/g, ' '), { length: 500 })}
							</Typography>
						</>
					}
				/>
			</ListItemButton>
		</ListItem>
	);
};

const PublishedDataListItem: React.FC<IDataListItemProps> = ({ data }) => {
	const dispatch = useDispatch();

	const [expanded, setExpanded] = React.useState(false);

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	return (
		<>
			<ListItem
				key={data?.rfpRequestId}
				disablePadding
				secondaryAction={<RfpRequestProposals active={expanded} onClick={() => setExpanded(!expanded)} rfpRequest={data} />}
			>
				<ListItemButton
					onClick={() => {
						dispatch(
							openDrawer({
								name: 'ReadRfpRequestDrawer',
								data: {
									requestId: data?.rfpRequestId,
									published: data?.status === 'Published',
									createdByMe: data?.companyId === companyId,
								},
							})
						);
					}}
				>
					<ListItemText
						primary={data?.projectTitle}
						secondary={
							<>
								<Typography component='span' variant='subtitle1' sx={{ mr: 1 }}>
									{data?.companyName}
								</Typography>
								<Typography component='span' variant='body2' sx={{ mr: 11, display: 'block' }}>
									{truncate(replace(data?.projectIngress || '', /\r|\n|\r\n/g, ' '), { length: 500 })}
								</Typography>
							</>
						}
					/>
				</ListItemButton>
			</ListItem>
			<Collapse
				unmountOnExit
				in={expanded}
				sx={{ width: '100%', backgroundColor: 'background.paper', m: 0, p: 0, top: '-.5rem', position: 'relative' }}
			>
				<ProposalList data={data} />
			</Collapse>
		</>
	);
};

type RequestListingProps = {
	rfpRequests: RfpRequest[] | undefined;
	title: string;
	emptyMessage: string;
	dataListItem: React.FunctionComponentElement<IListItem>;
	rowsPerPage?: number;
};
export const RequestListing: React.FC<RequestListingProps> = ({ rfpRequests, title, emptyMessage, dataListItem, rowsPerPage = 5 }) => {
	return (
		<>
			<Typography variant='h2'>{title}</Typography>
			<List>
				{isEmpty(rfpRequests) && <Typography>{emptyMessage}</Typography>}
				{rfpRequests && rfpRequests.length > 0 && (
					<PaginatorProvider rowsPerPage={rowsPerPage} data={rfpRequests}>
						<DataList dataListItem={dataListItem} />
						<PageActions />
					</PaginatorProvider>
				)}
			</List>
		</>
	);
};

const RfpRequestList: React.FC<Props> = ({ sx, ...otherProps }) => {
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;
	const { formatMessage } = useIntl();

	const { data: rfpRequests, isLoading, isError } = useFetchRfpRequestsQuery({ companyId }, { skip: isNil(companyId) });

	return (
		<Box {...otherProps} sx={[{ pt: 2 }, ...(isArray(sx) ? sx : [sx])]}>
			{isError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpRequests.error.message' />
				</Alert>
			)}
			{isLoading ? (
				<>
					<Skeleton height={60} />
					<Skeleton height={60} />
					<Skeleton height={60} />
					<Skeleton height={60} />
				</>
			) : (
				<Stack>
					<RequestListing
						title={formatMessage({ id: 'rfpRequests.published.title' })}
						emptyMessage={formatMessage({ id: 'rfpRequests.published.empty' })}
						dataListItem={<PublishedDataListItem />}
						rfpRequests={rfpRequests?.published}
						rowsPerPage={10}
					/>
					<RequestListing
						title={formatMessage({ id: 'rfpRequests.drafts.title' })}
						emptyMessage={formatMessage({ id: 'rfpRequests.drafts.empty' })}
						dataListItem={<DataListItem />}
						rfpRequests={rfpRequests?.drafts}
						rowsPerPage={10}
					/>
				</Stack>
			)}
		</Box>
	);
};

export default RfpRequestList;
