import * as React from 'react';
import { isNil, startsWith } from 'lodash';

type Props = {
	url: string | null | undefined;
	children: (src: string) => React.ReactNode;
};

const BlobDownloader: React.FC<Props> = ({ url, children }) => {
	const controllerRef = React.useRef<AbortController | null>();

	const [src, setSrc] = React.useState('');

	React.useEffect(() => {
		const downloadBlob = async () => {
			if (!isNil(controllerRef.current)) {
				controllerRef.current.abort();
			}
			controllerRef.current = new AbortController();
			try {
				const res = await fetch(url as string, { signal: controllerRef.current?.signal });
				const text = await res.text();
				setSrc(text);
				controllerRef.current = null;
			} catch (error) {
				console.error(error);
			}
		};

		if (!isNil(url)) {
			if (startsWith(url, 'http')) {
				downloadBlob();
			} else {
				setSrc(url);
			}
		} else {
			setSrc('');
		}

		return () => {
			if (!isNil(controllerRef.current)) {
				controllerRef.current.abort();
			}
		};
	}, [url]);

	return <>{children(src)}</>;
};

export default BlobDownloader;
