import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    section: {
        paddingBottom: '3rem',
    },
    content: {
        '& h2, h3, h4, p, label, span, li, td': {
            color: theme.palette.primary.main,
        },
    },
}));

const MainSection = ({ id, children, selected, hidden }) => {
    const classes = useStyles();

    if (hidden) return null;

    return (
        <div
            id={id}
            className={`${classes.section} ${selected === id && classes.selected} ${id === 'timeline' && 'timelineBox'} sectionPrint`}
        >
            <div className={`${classes.content} contentPrint`}>{children}</div>
        </div>
    );
};

export default MainSection;
