import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const StyledTypography = styled(Typography, {
    shouldForwardProp: prop => prop !== 'adminPanelItem',
})(({ theme, adminPanelItem }) => ({
    fontWeight: adminPanelItem ? 400 : 500,
    color: theme.palette.primary.white,
}));

const MenuTextItem = ({ adminPanelItem = false, children }) => {
    return (
        <StyledTypography adminPanelItem={adminPanelItem}>{children}</StyledTypography>
    );
};

export default MenuTextItem;