import * as React from 'react';
import { Link as DomLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import { styled } from '@mui/styles';

const ButtonWrapper = styled(DomLink)(() => ({
    textDecoration: 'none'
}));

const WidgetLink = styled(Button, {
    shouldForwardProp: prop => prop !== 'backgroundcolor',
    overridesResolver: (props, styles) => [
        styles.root,
        props.backgroundcolor === 'primary' && styles.primary,
    ],
})(({ backgroundcolor }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '170%',
    color: backgroundcolor,
    textTransform: 'none',
    '&:hover': {
        opacity: '0.8',
        backgroundColor: backgroundcolor,
        color: '#fff'
    },
}));

export default function Link({ color, disabled, children, icon, href }) {
    return (
        <ButtonWrapper to={href}>
            <WidgetLink
                disabled={disabled}
                endIcon={icon}
                backgroundcolor={color ?? '#057871'}
            >
                {children}
            </WidgetLink>
        </ButtonWrapper>
    );
}