import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(142, 14, 68, 0.05)',
    padding: '1rem',
    marginTop: '0.5rem'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  icon: {
    color: theme.palette.error.main,
    marginRight: '0.5rem'
  },
  property: {
    fontWeight: 600,
    color: theme.palette.error.main,
  },
  description: {
    color: theme.palette.primary.main,
  }
}));

const NoValid = ({ compareProperty }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <ErrorIcon className={classes.icon} />
        <Typography className={classes.property}>{intl.formatMessage({ id: `instrument.radar.choice.${compareProperty}` })}</Typography>
      </div>
      <Typography component='p' className={classes.description}>{intl.formatMessage({ id: `notice.item.${compareProperty}` })}</Typography>
    </div>
  );
};

export default NoValid;