import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = ({ size = 24, mt }) => {
    return (
        <Box display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }} >
            <CircularProgress size={size} color='primary' sx={{ mt: mt ?? 'unset' }} />
        </Box>
    );
};

export default Spinner;