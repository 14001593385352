import moment from 'moment';
import { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';

import Currency from 'components/Currency';
import htmlParser from 'components/HtmlParser';
import PanelTitle from 'components/Titles/PanelTitle';
import Subtitle from 'components/Titles/Subtitle';
import { EconomicalTable } from 'containers/Projects/Project/ProjectForm/ProjectFormExtendedTemplate/EconomicalTable';
import { controlTypes, fetchData } from 'containers/Projects/Project/ProjectForm/ProjectFormExtendedTemplate/utils';
import { Project } from 'types/dataTypes';
import { FinancialReport } from 'utils/formatters/formatEconomicalData';
import { ProjectSummarySection, ProjectSummaryTextSection } from '../Components';
import { ShowHideInPrintButton } from '../Components/ProjectSummarySection';

type SubSectionProps = { item: any; name: string; errors?: any; project: Project; theme: any };

const SubSection = ({ item, name, errors, project }: SubSectionProps) => {
	return (
		<>
			<PanelTitle id={item?.id} className='panelTitle' variant='h3' sx={{ mb: '1rem', mt: '1rem' }}>
				{item.title}
			</PanelTitle>
			<Grid container columnSpacing={2} rowSpacing={0}>
				{item.fields.map((field: any) => {
					const scale = field.scale ? field.scale : { xs: 12 };

					return (
						<Grid item key={`${name}.${field.id}`} id={`${name.replaceAll(',', '-')}-${field.id}`} {...scale}>
							<CustomControl {...{ field: field, name: `${name}.${field.id}`, project }} />
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

const CustomControl = ({ field, name, project }: { field: any; name: string; project: Project }) => {
	const defaultValue = fetchData(name, project);
	const theme = useTheme();

	switch (field.type) {
		case controlTypes.currency:
			return (
				<ProjectSummaryTextSection variant='h4' title={field.title}>
					{defaultValue ? <Currency leftAlign value={defaultValue} /> : '-'}
				</ProjectSummaryTextSection>
			);
		case controlTypes.date:
			return (
				<ProjectSummaryTextSection variant='h4' title={field.title}>
					{defaultValue ? moment(defaultValue).format('DD.MM.YYYY') : '-'}
				</ProjectSummaryTextSection>
			);
		case controlTypes.text:
			return <ProjectSummaryTextSection title={field.title}>{defaultValue ? defaultValue : '-'}</ProjectSummaryTextSection>;
		case controlTypes.section:
			return <SubSection {...{ item: field, name, project, theme }} />;
		case controlTypes.boolean: {
			return (
				<ProjectSummaryTextSection title={field.title}>
					{defaultValue ? field.checkedLabel : field.notCheckedLabel}
				</ProjectSummaryTextSection>
			);
		}
		case controlTypes.table: {
			const array = fetchData(name.replaceAll('.economy', ''), project); // TODO: fix this
			let data: FinancialReport[] = [];
			if (array && array.length > 0) {
				data = [...array];
			}

			data?.sort((a: any, b: any) => a?.year - b?.year);

			return <EconomicalTable field={field} data={data} />;
		}
		default:
			return (
				<ProjectSummarySection level='h4' title={field.title} id={name} showToggle>
					{defaultValue ? htmlParser(defaultValue) : '-'}
				</ProjectSummarySection>
			);
	}
};

const DynamicFieldContainer = ({ parentField, childField, project }: { parentField: any; childField: any; project: Project }) => {
	const scale = childField?.scale ? childField?.scale : { xs: 12 };
	const name = `dynamic.${parentField?.id}.${childField?.id}`;

	return (
		<Box key={childField?.id} id={`dynamic-${parentField?.id}-${childField?.id}`} {...scale}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
				<CustomControl {...{ field: childField, name, project }} />
			</Box>
		</Box>
	);
};

const TemplateField = ({ parentField, project, limits }: { parentField: any; project: Project; limits: string[] | null | undefined }) => {
	const [isPrintable, setIsPrintable] = useState(true);

	if (limits && limits.find(item => item !== parentField?.id)) {
		return null;
	}

	return (
		<Box sx={{ mt: 2, displayPrint: isPrintable ? 'block' : 'none' }}>
			{!limits && (
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Subtitle id={parentField?.id} className='panelTitle' variant='h3' mb='1rem' mt='2rem'>
						{parentField?.title}
					</Subtitle>
					<ShowHideInPrintButton {...{ isPrintable, setIsPrintable, showToggle: true }} />
				</Box>
			)}
			{parentField?.fields?.map((childField: any, index: number) => {
				return <DynamicFieldContainer key={index} childField={childField} parentField={parentField} project={project} />;
			})}
		</Box>
	);
};

const DynamicProjectApplication = ({ project, template, limits }: { project: Project; template: any; limits?: string[] | null }) => {
	if (!project.dynamic || !template) return null;

	return template?.fields?.map((parentField: any) => (
		<TemplateField key={parentField?.id} parentField={parentField} project={project} limits={limits} />
	));
};

export default DynamicProjectApplication;
