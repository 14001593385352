import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import CurrencyValue from 'components/Currency/CurrencyValue';
import { calculateExpenseAmount } from 'containers/Projects/Project/Costs/ProjectCostForm/ProjectCostForm';
import { projectCostSelector } from 'containers/Projects/slices';
import { costTaskSelector } from 'containers/Projects/utils';
import { forEach } from 'lodash';
import { Instrument, MainCostCategoryEnum, Project, ProjectCost, ProjectTask, costCategoryArray } from 'types/dataTypes';
import { percentFormatterWithFractionDigits } from '../../../../../../utils/formatters/numberFormatters';
import ProjectPrintTableCell from '../ProjectPrintTableItems/ProjectPrintTableCell';
import ProjectPrintTableCellTitle from '../ProjectPrintTableItems/ProjectPrintTableCellTitle';

export type CostSums = {
	expenses: number;
	salary: number;
	personnelSideCosts: number;
	sum: number;
	other: number;
	personnelCostPercent: number;
	costByCategory: {
		categories: { [k: string]: any };
		investments: number;
		development: number;
		other: number;
	};
};

export const parseCosts = (costs: ProjectCost[]) => {
	let costSums: CostSums = {
		expenses: 0,
		salary: 0,
		personnelSideCosts: 0,
		sum: 0,
		other: 0,
		personnelCostPercent: 0,
		costByCategory: {
			categories: {},
			investments: 0,
			development: 0,
			other: 0,
		},
	};

	forEach(costs, ({ category, amountApplied, salaryExpense, salaryExpenseType, workEstimate }) => {
		const mainCategory = costCategoryArray.find(item => item.value === category);
		if (category === 'salary') {
			costSums.salary += amountApplied * (workEstimate ?? 0); //@ts-ignore
			costSums.personnelSideCosts += calculateExpenseAmount(salaryExpenseType, salaryExpense, amountApplied) * (workEstimate ?? 0);
			costSums.costByCategory.development += amountApplied * (workEstimate ?? 0);
			// calculateExpenseAmount(salaryExpenseType, salaryExpense, amountApplied) * (workEstimate ?? 0);
		} else if (mainCategory?.mainCategory === 'development') {
			costSums.costByCategory.development += amountApplied;
		} else if (mainCategory?.mainCategory === 'investment') {
			costSums.costByCategory.investments += amountApplied;
		} else {
			costSums.costByCategory.other += amountApplied;
		}

		if (category !== 'salary') {
			costSums.expenses += amountApplied;

			if (!costSums.costByCategory.categories[category ?? 'other']) {
				costSums.costByCategory.categories[category ?? 'other'] = 0;
			}

			costSums.costByCategory.categories[category ?? 'other'] += amountApplied;
		}

		costSums.sum = costSums.salary + costSums.personnelSideCosts + costSums.expenses;
	});

	costSums.personnelCostPercent = costSums.personnelSideCosts / costSums.salary;

	return costSums; //{ salary, personnelSideCosts, expenses, sum, investments, development };
};

export const parseTasksCosts = (tasks: any, costs: ProjectCost[]) => {
	let taskSums: CostSums = {
		expenses: 0,
		salary: 0,
		personnelSideCosts: 0,
		sum: 0,
		other: 0,
		costByCategory: {
			categories: {},
			investments: 0,
			development: 0,
			other: 0,
		},
		personnelCostPercent: 0,
	};

	forEach(tasks, (task: ProjectTask) => {
		const taskCosts: ProjectCost[] | null = costTaskSelector(costs, task?.projectTaskId);

		if (!task || !taskCosts) return;

		const cost = parseCosts(taskCosts);

		Object.keys(cost.costByCategory.categories).forEach(key => {
			if (!taskSums.costByCategory.categories[key]) {
				taskSums.costByCategory.categories[key] = 0;
			}

			taskSums.costByCategory.categories[key] += cost.costByCategory.categories[key];
		});

		taskSums.expenses += cost.expenses;
		taskSums.salary += cost.salary;
		taskSums.personnelSideCosts += cost.personnelSideCosts;
		taskSums.expenses += cost.expenses;
		taskSums.other += cost.salary + cost.expenses;
		taskSums.sum += cost.sum;
		taskSums.costByCategory.investments += cost.costByCategory.investments;
		taskSums.costByCategory.development += cost.costByCategory.development;
		taskSums.costByCategory.other += cost.costByCategory.other;
	});

	return taskSums;
};

export const calculateFlatrateSum = (
	project: Project,
	instrument: Instrument | null | undefined,
	flatRatePercent: number,
	taskCosts: CostSums,
	categoryType: MainCostCategoryEnum
) => {
	let expenses =
		categoryType === 'development'
			? taskCosts.costByCategory.development
			: categoryType === 'investment'
			? taskCosts.costByCategory.investments
			: taskCosts.costByCategory.other;

	const exceptions = ['170fc21a-6b72-4e14-92aa-b750aed8df3c', 'fbefc815-986e-4337-ad31-22fc0e1ea433']; // T&K laina / avustus
	if (instrument && exceptions.find(item => item === instrument.id) && categoryType === 'development') {
		expenses = expenses + taskCosts.personnelSideCosts;
	}

	//const fromProject = expenses / (project.projectSize ?? 0);

	if (categoryType === 'development') {
		return (project.projectSize ?? 0) * (flatRatePercent / 100) ?? 0;
	}

	return expenses * (flatRatePercent / 100) ?? 0;
};

export const calculateOptimalFlatrateSum = (
	project: Project,
	categoryType: MainCostCategoryEnum,
	instrument: Instrument | null | undefined
) => {
	const costs: Array<ProjectCost> = projectCostSelector(project);

	const taskCosts = parseCosts(costs);

	// let expenses =
	// 	categoryType === 'development'
	// 		? taskCosts.costByCategory.development
	// 		: categoryType === 'investment'
	// 		? taskCosts.costByCategory.investments
	// 		: taskCosts.costByCategory.other;

	const projectSize = project.projectSize ?? 0;
	//const addedCosts = !categoryType || categoryType === 'development' ? taskCosts.personnelSideCosts : 0; // if development then sideCosts

	const costSums = parseCosts(costs);

	let missingSum =
		projectSize - taskCosts.sum - calculateFlatrateSum(project, instrument, project.flatRateInvestment ?? 0, costSums, 'investment'); //- taskCosts.costByCategory.investments - taskCosts.costByCategory.other; // projectSize - (expenses + addedCosts);

	// const exceptions = ['170fc21a-6b72-4e14-92aa-b750aed8df3c', 'fbefc815-986e-4337-ad31-22fc0e1ea433']; // T&K laina / avustus
	// if (instrument && exceptions.find(item => item === instrument.id) && categoryType === 'development') {
	// 	missingSum = projectSize - (expenses + addedCosts);
	// }

	const missingFlatRatePercent = (missingSum / (project.projectSize ?? 0)) * 100;

	// console.log(taskCosts, taskCosts.sum, missingSum, missingFlatRatePercent);

	return { missingSum, missingFlatRatePercent };
};

const CostsTable = ({
	project,
	tasks,
	costs,
	flatRatePercent,
	flatRateInvestment,
	instrument,
}: {
	project: Project;
	tasks: ProjectTask[];
	costs: ProjectCost[];
	flatRatePercent: number;
	flatRateInvestment: number;
	instrument: Instrument | null | undefined;
}) => {
	const intl = useIntl();

	const costSums = parseCosts(costs);

	const personnelCostPercent = costSums.personnelSideCosts / costSums.salary;

	const flatrateDevelopmentSum = calculateFlatrateSum(project, instrument, flatRatePercent, costSums, 'development');
	const flatrateInvestmentSum = calculateFlatrateSum(project, instrument, flatRateInvestment, costSums, 'investment');

	return (
		<TableContainer
			sx={{
				background: '#fff',
				'@media print': {
					overflowX: 'visible',
					pageBreakInside: 'avoid',
				},
			}}
		>
			<Table>
				<TableBody>
					<TableRow>
						<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true}>
							{intl.formatMessage({ id: 'project.cost.estimate' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true} sx={{ textAlign: 'right' }}>
							{intl.formatMessage({ id: 'project.cost.amount' })}
						</ProjectPrintTableCellTitle>
					</TableRow>
					<TableRow>
						<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.salary' })}</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{costSums.salary ? <CurrencyValue value={costSums.salary} /> : '-'}
						</ProjectPrintTableCell>
					</TableRow>
					<TableRow>
						<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.personnel.costs' })}</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{costSums.personnelSideCosts ? <CurrencyValue value={costSums.personnelSideCosts} /> : '-'}
						</ProjectPrintTableCell>
					</TableRow>
					<TableRow>
						<ProjectPrintTableCell>
							{intl.formatMessage({ id: 'project.cost.personnel.costs.fromsalary' })}
						</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{costSums.personnelSideCosts ? (
								<div>{percentFormatterWithFractionDigits.format(personnelCostPercent)}</div>
							) : (
								'-'
							)}
						</ProjectPrintTableCell>
					</TableRow>
					{/* <TableRow>
						<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.purchased.services' })}</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{taskCosts.expenses ? <CurrencyValue value={taskCosts.expenses} /> : '-'}
						</ProjectPrintTableCell>
					</TableRow> */}
					{Object.keys(costSums.costByCategory.categories).map(key => (
						<TableRow key={key}>
							<ProjectPrintTableCell>{intl.formatMessage({ id: `project.cost.categories.${key}` })}</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								<CurrencyValue value={costSums.costByCategory.categories[key]} />
							</ProjectPrintTableCell>
						</TableRow>
					))}
					<TableRow>
						<ProjectPrintTableCell>
							{intl.formatMessage(
								{ id: 'project.cost.table.other.flatrate.title' }, 
								{
									percent: percentFormatterWithFractionDigits.format(flatrateDevelopmentSum / (project.projectSize ?? 0)),
								}
							)}
						</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{flatrateDevelopmentSum ? <CurrencyValue value={flatrateDevelopmentSum} /> : '-'}
						</ProjectPrintTableCell>
					</TableRow>

					{flatrateInvestmentSum > 0 && (
						<TableRow>
							<ProjectPrintTableCell>
								{intl.formatMessage(
									{ id: 'project.cost.flatrate.investment' },
									{ percent: percentFormatterWithFractionDigits.format(flatRateInvestment / 100) }
								)}
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								<CurrencyValue value={flatrateInvestmentSum} />
							</ProjectPrintTableCell>
						</TableRow>
					)}

					<TableRow>
						<ProjectPrintTableCell lastColumn={true}>
							<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
								{intl.formatMessage({ id: 'project.cost.total' })}
							</Typography>
						</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }} lastColumn={true}>
							<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
								{costSums.sum > 0 ? (
									<CurrencyValue value={costSums.sum + flatrateDevelopmentSum + flatrateInvestmentSum} />
								) : (
									'-'
								)}
							</Typography>
						</ProjectPrintTableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CostsTable;
