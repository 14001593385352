import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { keyframes } from '@mui/system';

const Footer = ({ loading, children }) => {
    return (
        <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} sx={{ minHeight: '52px' }}>
            {loading &&
                <Typography
                    color='primary'
                    sx={{
                        animation: `${keyframes`from { opacity: 0.25; } to { opacity: 0.75; }`} 2s linear infinite`,
                    }}
                >
                    Ladataan tietoja...
                </Typography>
            }
            <Box>{children}</Box>
        </Stack >
    );
};

export default Footer;