import * as React from 'react';
import { ThemeProvider } from '@mui/material';

import theme from 'config/themeV2';
import Marketplace from 'containers/Marketplace';

const MarketplacePage: React.FC = () => {
	return (
		<ThemeProvider theme={theme}>
			<Marketplace />
		</ThemeProvider>
	);
};

export default MarketplacePage;
