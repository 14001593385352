import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PublicIcon from '@mui/icons-material/Public';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${theme.palette.secondary.line}`,
        borderRadius: 5,
        padding: theme.spacing(1.5),
        height: '100%'
    },
    icon: {
        color: theme.palette.secondary.grey500,
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    title: {
        fontSize: '1.2rem',
        color: theme.palette.secondary.grey900,
        paddingBottom: theme.spacing(0.5)
    },
    value: {
        fontSize: '0.9rem',
        color: theme.palette.secondary.grey400,
    },
}));

const PrintInfoItem = ({ title, value }) => {
    const classes = useStyles();

    return (
        <Grid container alignItems='center' className={classes.root}>
            <Grid item>
                <PublicIcon className={classes.icon} />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
                <Typography className={classes.position}>{title}</Typography>
                <Typography className={classes.value}>{value}</Typography>
            </Grid>
        </Grid>
    );
};

export default PrintInfoItem;
