import React from 'react';
import { useIntl } from 'react-intl';

import { useGetAnalysisDataQuery, useGetInstrumentsDataQuery, useGetScoreDataQuery, useGetScenarioDataQuery, useGetCompanyDataQuery } from 'containers/Stakeholder/stakeholderApi';
import AnalysisNoData from 'containers/Analysis/AnalysisNoData';
import Loader from '../Components/Loader';
import Error from '../Components/Error';

const Analysis = ({ linkId }) => {
    const intl = useIntl();

    const {
        data: analysis,
        isFetching: isAnalysisFetching,
        isLoading: isAnalysisLoading,
        isSuccess: isAnalysisSuccess
    } = useGetAnalysisDataQuery(linkId);

    const {
        data: instruments,
        isFetching: isInstrumentsFetching,
        isLoading: isInstrumentsLoading,
        isSuccess: isInstrumentsSuccess
    } = useGetInstrumentsDataQuery(linkId);

    const {
        data: score,
        isFetching: isScoreFetching,
        isLoading: isScoreLoading,
        isSuccess: isScoreSuccess
    } = useGetScoreDataQuery(linkId);

    const {
        data: scenario,
        isFetching: isScenarioFetching,
        isLoading: isScenarioLoading,
        isSuccess: isScenarioSuccess
    } = useGetScenarioDataQuery(linkId);

    const {
        data: company,
        isFetching: isCompanyFetching,
        isLoading: isCompanyLoading,
        isSuccess: isCompanySuccess
    } = useGetCompanyDataQuery(linkId);

    if (isAnalysisFetching || isAnalysisLoading || isInstrumentsLoading || isInstrumentsFetching || isScoreFetching || isScoreLoading || isScenarioFetching || isScenarioLoading || isCompanyFetching || isCompanyLoading) return <Loader />;

    if (!isAnalysisSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.analysis.error' })}</Error>;

    if (!analysis) return null;

    return (
        <>
            <AnalysisNoData
                error={{}}
                company={company}
                analysis={analysis}
                instruments={instruments}
                scenario={scenario}
                score={score}
            />
        </>
    );
};

export default Analysis;