import React from 'react';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            minWidth: '18rem'
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '25rem'
        },
    },
    input: {
        padding: '10px 14px 10px 0'
    }
}));

const FilterInput = ({ value, onChange, placeholder }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <TextField
            id='search'
            value={value || ''}
            onChange={onChange}
            placeholder={intl.formatMessage({ id: `filter.placeholder.${placeholder}` })}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <SearchIcon />
                    </InputAdornment>
                ),
                classes: { input: classes.input }
            }}
            className={classes.root}
        />
    );
};

export default FilterInput;
