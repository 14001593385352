import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Drawer, DrawerProps } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { closeDrawer, getDrawerData, isDrawerOpen, openDrawer } from 'containers/Marketplace/drawerSlice';

import ReadRfpProposalForm from 'containers/Marketplace/proposal/Read/ReadRfpProposalForm';

type Props = DrawerProps & {};

const ReadRfpProposalDrawer: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const drawerOpen = useSelector(isDrawerOpen('ReadRfpProposalDrawer'));
	const drawerData = useSelector(getDrawerData('ReadRfpProposalDrawer'));

	const handleDrawerClose = React.useCallback(() => {
		dispatch(closeDrawer({ name: 'ReadRfpProposalDrawer' }));
	}, [dispatch]);

	const handleEditClick = React.useCallback(() => {
		dispatch(closeDrawer({ name: 'ReadRfpProposalDrawer' }));
		dispatch(
			openDrawer({
				name: 'UpsertRfpProposalDrawer',
				data: {
					requestId: drawerData?.requestId,
					proposalId: drawerData?.proposalId,
					published: drawerData.published,
				},
			})
		);
	}, [dispatch, drawerData]);

	return (
		<Drawer
			{...otherProps}
			anchor='right'
			open={drawerOpen}
			onClose={handleDrawerClose}
			PaperProps={{
				sx: { width: { xs: '100%', md: '80%', lg: '60%' } },
			}}
		>
			<IconButton sx={{ position: 'absolute', right: 0, zIndex: 1000 }} onClick={handleDrawerClose}>
				<CloseIcon />
			</IconButton>
			<ReadRfpProposalForm
				requestId={drawerData?.requestId}
				proposalId={drawerData?.proposalId}
				published={drawerData?.published}
				createdByMe={drawerData?.createdByMe}
				onEditClick={handleEditClick}
			/>
		</Drawer>
	);
};

export default ReadRfpProposalDrawer;
