import { yupResolver } from '@hookform/resolvers/yup';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { RfpProposalItem } from 'types/dataTypes';
import { mergeFieldValues } from 'utils/misc/formUtils';

import { closeDialog, getDialogData, isDialogOpen, openDialog } from 'containers/Marketplace/dialogSlice';

import RfpProposalItemCostInput from 'containers/Marketplace/proposal/RfpProposalItemCostInput';
import RfpProposalItemCostList from 'containers/Marketplace/proposal/RfpProposalItemCostList';
import RfpProposalItemRequestItemInput from 'containers/Marketplace/proposal/RfpProposalItemRequestItemInput';
import UpsertRfpProposalItemCostDialog from 'containers/Marketplace/proposal/UpsertRfpProposalItemCostDialog';
import { StyledTooltip } from '../common/StyledTooltip';

type FieldValues = Partial<RfpProposalItem>;

type Props = {} & Omit<DialogProps, 'open'>;

const UpsertRfpProposalItemDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const { formatMessage } = useIntl();

	const dialogOpen = useSelector(isDialogOpen('UpsertRfpProposalItemDialog'));
	const dialogData = useSelector(getDialogData('UpsertRfpProposalItemDialog'));

	const validationSchema = React.useMemo(
		() =>
			yup.object({
				rfpRequestItemId: yup.string().nullable().required(),
				description: yup.string().max(2000),
			}),
		[]
	);

	const defaultValues = React.useMemo(
		() => ({
			rfpProposalId: null,
			rfpRequestItemId: null,
			rfpProposalItemId: null,
			requestItemTitle: null,
			proposalItemCategory: null,
			description: '',
			costs: [],
		}),
		[]
	);

	const {
		control,
		reset,
		handleSubmit,
		setValue,
		watch,
		formState: { isDirty, isSubmitting },
	} = useForm<FieldValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const [rfpProposalId, rfpProposalItemId] = watch(['rfpProposalId', 'rfpProposalItemId']);

	const handleDialogClose = React.useCallback(() => {
		if (isDirty) {
			dispatch(
				openDialog({
					name: 'ConfirmCloseDialog',
					data: {
						onSubmit: () => {
							reset(defaultValues);
							dispatch(closeDialog({ name: 'UpsertRfpProposalItemDialog' }));
						},
					},
				})
			);
		} else {
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProposalItemDialog' }));
		}
	}, [dispatch, reset, isDirty, defaultValues]);

	const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
		async fieldValues => {
			await dialogData?.onSubmit(fieldValues);
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProposalItemDialog' }));
		},
		[dispatch, reset, defaultValues, dialogData]
	);

	React.useEffect(() => {
		if (!isNil(dialogData?.fieldValues)) {
			reset(mergeFieldValues(defaultValues, dialogData?.fieldValues), { keepDirtyValues: true });
		}
	}, [reset, defaultValues, dialogData]);

	return (
		<>
			<UpsertRfpProposalItemCostDialog />
			<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='sm' fullWidth>
				<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
					<CloseIcon />
				</IconButton>
				<Box component='form' onSubmit={handleSubmit(handleFormSubmit)}>
					<DialogTitle>
						<FormattedMessage id='rfpProposalItem.title' />
					</DialogTitle>
					<DialogContent>
						<Stack>
							<Controller
								name='rfpRequestItemId'
								control={control}
								render={({ field, fieldState }) => (
									<RfpProposalItemRequestItemInput
										{...field}
										requestId={dialogData?.requestId}
										onChange={rfpRequestItem => {
											field.onChange(rfpRequestItem?.rfpRequestItemId);
											setValue('requestItemTitle', rfpRequestItem?.requestItemTitle);
											setValue('proposalItemCategory', rfpRequestItem?.requestItemCategory);
										}}
										error={!isNil(fieldState.error)}
										label={formatMessage({ id: `rfpProposalItem.${field.name}.label` })}
										helperText={fieldState.error?.message}
									/>
								)}
							/>
							<Controller
								name='costs'
								control={control}
								render={({ field, fieldState }) => (
									<>
										{isNil(rfpProposalItemId) ? (
											<RfpProposalItemCostInput
												{...field}
												label={formatMessage({ id: `rfpProposalItem.${field.name}.label` })}
												error={!isNil(fieldState.error)}
												helperText={fieldState.error?.message}
											/>
										) : (
											<RfpProposalItemCostList
												{...field}
												proposalId={rfpProposalId}
												proposalItemId={rfpProposalItemId}
												label={formatMessage({ id: `rfpProposalItem.${field.name}.label` })}
												error={!isNil(fieldState.error)}
												helperText={fieldState.error?.message}
											/>
										)}
									</>
								)}
							/>
							<Controller
								name='description'
								control={control}
								render={({ field, fieldState }) => (
									<StyledTooltip title={formatMessage({ id: `rfpProposalItem.${field.name}.placeholder` })}>
										<TextField
											{...field}
											label={formatMessage({ id: `rfpProposalItem.${field.name}.label` })}
											placeholder={formatMessage({ id: `rfpProposalItem.${field.name}.placeholder` })}
											error={!isNil(fieldState.error)}
											helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
											inputProps={{ maxLength: 2000 }}
											minRows={3}
											multiline
										/>
									</StyledTooltip>
								)}
							/>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button size='small' variant='text' color='neutral' onClick={handleDialogClose}>
							<FormattedMessage id='rfpProposalItem.cancel' />
						</Button>
						<LoadingButton type='submit' loading={isSubmitting}>
							<FormattedMessage id='rfpProposalItem.submit' />
						</LoadingButton>
					</DialogActions>
				</Box>
			</Dialog>
		</>
	);
};

export default UpsertRfpProposalItemDialog;
