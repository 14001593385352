import { useEffect } from 'react';

export const useScrollIntoViewWithOffset = (id, hash, offset) => {
	useEffect(() => {
		if (id === hash.slice(1)) {
			scrollIntoViewWithOffset(id, offset);
		}
	}, [id, hash, offset]);

	const scrollIntoViewWithOffset = (id, offset = 15) => {
		const element = document.getElementById(id);

		const elementPosition = element?.getBoundingClientRect().top;
		const offsetPosition = elementPosition ? elementPosition + window.scrollY - offset : 0;

		window.scrollTo({
			behavior: 'smooth',
			top: offsetPosition,
		});
	};

	return null;
};
