import * as React from 'react';
import {
	Alert,
	AlertTitle,
	Avatar,
	Box,
	BoxProps,
	Chip,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetPublicProfilesQuery } from 'containers/Marketplace/rfpProfileApi';
import { compact, find, isEmpty, join, map } from 'lodash';
import { useDispatch } from 'react-redux';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import BlobDownloader from 'containers/Marketplace/attachment/BlobDownloader';

type Props = BoxProps & {
	companyIds: string[] | null | undefined;
};

const ReadRfpRequestTargetCompanyList: React.FC<Props> = ({ companyIds, ...otherProps }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const { data: publicRfpProfiles, isLoading, isError } = useGetPublicProfilesQuery({ token: { take: 1000 } });

	const filteredRfpProfiles = React.useMemo(() => {
		return compact(map(companyIds, companyId => find(publicRfpProfiles?.data, { companyId })));
	}, [companyIds, publicRfpProfiles]);

	return (
		<Box {...otherProps}>
			<Typography variant='h4'>
				<FormattedMessage id='rfpRequest.proposalRequestTargetCompanies.label' />
			</Typography>
			{isError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='getPublicRfpProfiles.error.message' />
				</Alert>
			)}
			{isLoading ? (
				<>
					<Skeleton height={60} />
					<Skeleton height={60} />
				</>
			) : (
				<List>
					{isEmpty(publicRfpProfiles?.data) && (
						<Typography variant='lightText'>
							<FormattedMessage id='rfpRequestTargetCompanies.empty' />
						</Typography>
					)}
					{map(filteredRfpProfiles, rfpProfile => (
						<ListItem key={rfpProfile.companyId} disablePadding>
							<ListItemButton
								onClick={() => {
									dispatch(openDialog({ name: 'ReadRfpProfileDialog', data: { companyId: rfpProfile.companyId } }));
								}}
							>
								<ListItemIcon>
									<BlobDownloader url={rfpProfile?.logo}>{src => <Avatar variant='logo3' src={src} />}</BlobDownloader>
								</ListItemIcon>
								<ListItemText
									primary={rfpProfile.companyName}
									secondary={
										<>
											<Chip component='span' variant='filled' label={rfpProfile.companyId} sx={{ mb: 0.5 }} />
											<Typography component='span' sx={{ display: 'block', fontSize: 'inherit' }}>
												{join(
													map(rfpProfile.categories, rfpCategory => {
														return formatMessage({ id: `rfpCategory.${rfpCategory}` });
													}),
													', '
												)}
											</Typography>
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			)}
		</Box>
	);
};

export default ReadRfpRequestTargetCompanyList;
