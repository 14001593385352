import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertTitle, Box, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { get, isNil, split } from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { Company } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import { getUser } from 'utils/auth/user';
import { mergeFieldValues } from 'utils/misc/formUtils';

import { useFetchCompanyQuery, useUpdateCompanyMutation } from 'containers/Company/companyV2Api';

import Button from 'components/Button/Button';
import CompanySearchAutocomplete from 'containers/CompanySearch/CompanySearchAutocomplete';

type FieldValues = Partial<Company>;

type Props = {
	onNextStepClick: () => void;
	passIsDirty: (isDirty: boolean, dirtyFields: any) => void;
	passZeroFields: (zeroFields: string[]) => void;
};

const OnboardingStep1 = React.forwardRef<any, Props>(({ onNextStepClick, passIsDirty, passZeroFields, ...otherProps }, ref) => {
	const { formatMessage } = useIntl();

	const user = getUser();
	const companyId = getCompanyId();
	const [searchedCompany, setSearchedCompany] = React.useState<Company | string | null>(null);

	const validationSchema = React.useMemo(() => {
		return yup.object().shape({
			businessId: yup.string(),
		});
	}, []);

	const defaultValues = React.useMemo<FieldValues>(() => {
		return {
			businessId: null,
		};
	}, []);

	const {
		handleSubmit,
		reset,
		setValue,
		formState: { isDirty, dirtyFields },
	} = useForm<FieldValues>({
		defaultValues,
		resolver: yupResolver(validationSchema),
	});

	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
		isError: isCompanyError,
	} = useFetchCompanyQuery({ companyId }, { skip: isNil(companyId) });

	const [updateCompany, { data: updatedCompany, isLoading: isUpdateLoading, isError: isUpdateError }] = useUpdateCompanyMutation();

	const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
		async fieldValues => {
			try {
				await updateCompany({ companyId, company: fieldValues }).unwrap();
				onNextStepClick();
			} catch (error) {
				console.error(error);
			}
		},
		[updateCompany, onNextStepClick, companyId]
	);

	React.useEffect(() => {
		passIsDirty?.(isDirty, dirtyFields);
	}, [passIsDirty, isDirty, dirtyFields]);

	React.useEffect(() => {
		if (!isNil(company) || !isNil(updatedCompany)) {
			reset(mergeFieldValues(defaultValues, updatedCompany, company));
		}
	}, [reset, defaultValues, updatedCompany, company]);

	const loading = isCompanyLoading || isCompanyFetching || isUpdateLoading;

	return (
		<Box
			{...otherProps}
			ref={ref}
			component='form'
			onSubmit={handleSubmit(handleFormSubmit)}
			sx={{ p: '2rem', width: '100%', maxWidth: { xs: '100%', md: '40rem' } }}
		>
			<Stack spacing={2} flexDirection='column' useFlexGap>
				<Typography sx={{ fontFamily: 'Aleo', fontWeight: 700, fontSize: '1.715rem', lineHeight: 1.3, textAlign: 'center' }}>
					<FormattedMessage id='onboarding.step1.title' values={{ name: get(split(user?.name, ' '), 0) ?? '-' }} />
				</Typography>
				<Typography sx={{ fontSize: '1.145rem', lineHeight: 1.5, textAlign: 'center' }}>
					<FormattedMessage id='onboarding.step1.description' />
				</Typography>
				{isCompanyError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='query.error.title' />
						</AlertTitle>
						<FormattedMessage id='fetchBusinessPlan.error.message' />
					</Alert>
				)}
				{isUpdateError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='mutation.error.title' />
						</AlertTitle>
						<FormattedMessage id='updateCompany.error.message' />
					</Alert>
				)}
				{isCompanyLoading || isCompanyFetching ? (
					<Skeleton height={60} variant='rectangular' />
				) : (
					<>
						<CompanySearchAutocomplete
							searchTermParam={company?.name ?? ''}
							value={searchedCompany}
							onChange={(_, value) => {
								if (typeof value === 'string') {
									setSearchedCompany(value);
								} else if (value) {
									setSearchedCompany(value);
									setValue('businessId', value?.businessId ?? null, { shouldDirty: true });
								} else {
									setSearchedCompany(null);
								}
							}}
							renderInput={params => (
								<TextField
									{...params}
									label={formatMessage({ id: 'onboarding.step1.company.label' })}
									placeholder={formatMessage({ id: 'onboarding.step1.company.placeholder' })}
								/>
							)}
						/>
					</>
				)}
				{isCompanyLoading ? (
					<Skeleton height={40} variant='rectangular' />
				) : (
					<>
						{isDirty ? (
							<>
								{/* @ts-ignore */}
								<Button
									color='primary'
									variant='contained'
									type='submit'
									sx={{ p: '0.64286rem 1.21429rem', lineHeight: '1.14286rem', fontSize: '1rem' }}
									loading={loading}
									fullWidth
								>
									<FormattedMessage id='onboarding.step.next' />
								</Button>
							</>
						) : (
							<>
								{/* @ts-ignore */}
								<Button
									variant='contained'
									sx={{ p: '0.64286rem 1.21429rem', lineHeight: '1.14286rem', fontSize: '1rem' }}
									onClick={onNextStepClick}
									fullWidth
								>
									<FormattedMessage id='onboarding.step.next' />
								</Button>
							</>
						)}
					</>
				)}
			</Stack>
		</Box>
	);
});

export default OnboardingStep1;
