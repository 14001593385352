import { isEmpty, map } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Divider, List, ListItemText, Stack, Typography } from '@mui/material';
import { RfpProposalItem } from 'types/dataTypes';
import TextCollapse from '../../../../../components/TextCollapse/TextCollapse';
import RfpProposalItemCostList, { CurrencyRange, calculateCostMinMax } from './RfpProposalItemCostList';

type Props = {
	rfpProposalItems: RfpProposalItem[] | null | undefined;
	print?: boolean;
};

const TotalPrice: React.FC<Props> = ({ rfpProposalItems }) => {
	if (!rfpProposalItems) return null;

	const minPrice = rfpProposalItems.reduce((a, b) => {
		const costsTotal = calculateCostMinMax(b.costs);

		return a + costsTotal.minPrice;
	}, 0);

	const maxPrice = rfpProposalItems.reduce((a, b) => {
		const costsTotal = calculateCostMinMax(b.costs);

		return a + costsTotal.maxPrice;
	}, 0);

	return (
		<>
			<Divider sx={{ mt: '1rem' }} />
			<Stack direction='column' sx={{ width: '100%', mt: '1rem' }}>
				<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
					<Typography>
						<FormattedMessage id='rfp.proposal.items.total' />
					</Typography>

					<Typography>
						<CurrencyRange min={minPrice} max={maxPrice} />
					</Typography>
				</Stack>
			</Stack>
		</>
	);
};

const RfpProposalItemList: React.FC<Props> = ({ rfpProposalItems, print, ...otherProps }) => {
	if (!rfpProposalItems) return <div>Ei mitään</div>;

	return (
		<>
			<Typography variant='h4'>
				<FormattedMessage id='rfpProposal.rfpProposalItems.label' />
			</Typography>
			<List>
				{isEmpty(rfpProposalItems) && (
					<Typography variant='lightText'>
						<FormattedMessage id='rfpProposalItems.empty' />
					</Typography>
				)}
				{map(rfpProposalItems, (rfpProposalItem, index) => (
					<Stack key={rfpProposalItem.rfpProposalItemId}>
						<ListItemText
							sx={{
								'& .MuiListItemText-secondary': {
									color: '#303F5A',
								},
								'@media print': {
									'& .MuiListItemText-secondary': {
										fontSize: '1rem',
									},
								},
							}}
							primary={
								<Stack direction='column'>
									<Box sx={{ flexGrow: 1 }}>{rfpProposalItem.requestItemTitle}</Box>
									<Typography variant='subtitle2' component='span' sx={{ mr: '0.5rem', mb: '.5rem' }}>
										{rfpProposalItem.proposalItemCategory}
									</Typography>
								</Stack>
							}
							secondary={
								<>
									<TextCollapse
										print={print}
										text={rfpProposalItem.description ? rfpProposalItem.description : undefined}
									/>
								</>
							}
						/>
						<RfpProposalItemCostList costs={rfpProposalItem.costs} />
						<Divider
							sx={{
								display: rfpProposalItems?.length !== index + 1 ? 'block' : 'none',
							}}
						/>
					</Stack>
				))}
				<TotalPrice rfpProposalItems={rfpProposalItems} />
			</List>
		</>
	);
};

export default RfpProposalItemList;
