import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import ConfirmIconButton from 'components/Button/ConfirmIconButton';
import Spinner from 'components/Spinner/Spinner';
import CustomAvatar from '../CustomAvatar';
import TimeAgo from '../TimeAgo';

import { convertEmailToName } from 'utils/converters/convertEmailToName';
import { getInitials } from 'utils/helpers/getInitials';

const CommentsList = ({ conversationId, comments, isAdmin, removeCommentLoading, deleteComment }) => {
	const intl = useIntl();

	const commentsForReverse = [...comments];
	const reversedComments = commentsForReverse.reverse();
	// const sortedComments = comments.sort((a, b) => new Date(a.createdTime) - new Date(b.createdTime));

	return (
		<List sx={{ p: 0 }}>
			{reversedComments.map(({ commentId, author, comment, createdTime }, index) => {
				const name = convertEmailToName(author);
				const initials = getInitials(name);

				return (
					<ListItem key={commentId + index} sx={{ alignItems: 'flex-start' }}>
						<CustomAvatar initials={initials} sx={{ mr: 1.5, mt: 0.25 }} />
						<ListItemText
							primary={
								<Box display='flex' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
									<Typography sx={{ color: 'secondary.grey700', fontWeight: 600, fontSize: '0.85rem' }}>
										{name}
									</Typography>
									<TimeAgo time={createdTime} sx={{ color: 'secondary.grey700', fontSize: '0.8rem' }} />
								</Box>
							}
							secondary={
								<Typography
									sx={{
										wordWrap: 'break-word',
										color: 'secondary.grey700',
										fontSize: '0.85rem',
										pt: 1,
										whiteSpace: 'break-spaces',
									}}
								>
									{comment}
								</Typography>
							}
						/>
						{isAdmin && (
							<ConfirmIconButton
								disabled={!!removeCommentLoading}
								tooltip={intl.formatMessage({ id: 'collaboration.comment.remove.confirm' })}
								confirmText={intl.formatMessage({ id: 'collaboration.comment.remove.confirm' })}
								cancelText={intl.formatMessage({ id: 'collaboration.comment.remove.cancel' })}
								confirmBodyText={intl.formatMessage({ id: 'collaboration.comment.remove.body' })}
								confirmAction={() => deleteComment(conversationId, commentId)}
								stopPropagation={true}
								sx={{ ml: 1, background: 'rgba(72, 46, 81, 0.08)' }}
							>
								{removeCommentLoading === commentId ? (
									<Spinner size={16} />
								) : (
									<DeleteIcon
										sx={{
											'&:hover': {
												transform: 'scale(1.2)',
											},
										}}
									/>
								)}
							</ConfirmIconButton>
						)}
					</ListItem>
				);
			})}
		</List>
	);
};

export default CommentsList;
