import { find } from 'lodash';
import { CompanyRole, GrantedAIFeature, ISAccessToken, License } from 'types/dataTypes';
// @ts-ignore
import { matchPath } from 'react-router';

import { getDecodedAccessToken } from 'utils/auth/token';
import { getUser } from 'utils/auth/user';
import { storageKeys } from 'utils/constants/constants';
import storage from 'utils/localStorage/localStorageFunctions';

const getCompanyFromArray = (companyId: string) => {
	const user = getUser();

	try {
		return user.companies.find((item: any) => item.CompanyId === companyId);
	} catch {
		// continue regardless of error
	}

	return null;
};

const getFirstCompany = () => {
	let company = storage.get(storageKeys.COMPANY_KEY) as any;

	if (!company) {
		//fallback if not company
		const user = getUser();

		if (!user || !user.company) return null;

		company = user.company;
	}

	return company;
};

const getCompany = () => {
	const selectedCompanyId = getCompanyId();
	let company = storage.get(storageKeys.COMPANY_KEY) as any;

	if (!company) {
		//fallback if not company
		const user = getUser();

		if (!user || !user.company) return null;

		company = user.company;
	}

	// eslint-disable-next-line eqeqeq
	if (!company.CompanyName && company != null && company != 'null') {
		//@ts-ignore
		const name = getCompanyFromArray(selectedCompanyId ?? company.CompanyId);
		company.CompanyName = name ? name.CompanyName : '';

		setCompany(company);
	}

	// if (company.CompanyId !== selectedCompanyId && selectedCompanyId) {
	// 	const selectedCompany = getCompanyFromArray(selectedCompanyId);

	// 	if (selectedCompany) {
	// 		setCompany(selectedCompany);

	// 		return selectedCompany;
	// 	}
	// }

	return company;
};

const hasCompanyAcceptedAgreement = () => {
	let hasAccepted = true;
	const company = getCompany();
	const roles = company?.Roles;

	if (roles) {
		hasAccepted = !roles.includes('Company.ContractMissing');
	}

	return hasAccepted;
};

const canSignCompanyContract = () => {
	const company = getCompany();
	const currectCompanyRoles = company?.Roles;

	return currectCompanyRoles?.includes('Company.ContractCanSign') || currectCompanyRoles?.includes('Company.Contributor');
};

const setCompany = (company: any) => {
	storage.set(storageKeys.COMPANY_KEY, company);
};

/**
 * GET local storage user object and companyid
 */
const getCompanyId = (): string | null => {
	try {
		const match = matchPath<{ companyId?: string }>(window.location.pathname, {
			path: ['/company/:companyId', '/admin/company/:companyId'],
		});

		if (match?.params.companyId) {
			return match?.params.companyId;
		}
	} catch (err) {
		console.error('error', err);
		// continue regardless of error
	}

	return null;
};

/**
 * Find information from the token and local storage if the chosen company is advanced company
 */
const isAdvancedToken = () => {
	const decodedAccessToken = getDecodedAccessToken<ISAccessToken>();
	const company = getCompany();

	if (!company) return false;

	const isChosenCompanyAdvanced = (comp: CompanyRole) => comp?.License === 'advanced';

	if (decodedAccessToken == null) return false;

	if (
		decodedAccessToken.CompanyRoles &&
		decodedAccessToken.CompanyRoles.some(isChosenCompanyAdvanced) &&
		company.License === 'advanced'
	) {
		return true;
	}

	return false;
};

/**
 * Find information from the token and local storage if the chosen company is trial company
 */
const isTrialToken = () => {
	const trialLicense: License = 'trial';

	return findCompanyLicense(trialLicense);
};

/**
 * Find information from the token and local storage if the chosen company is freemium company
 */
const isFreemiumToken = () => {
	const license: License = 'freemium';

	return findCompanyLicense(license);
};

const findCompanyLicense = (licenseName: License) => {
	const company = getCompany();

	if (!company) return false;

	const decodedAccessToken = getDecodedAccessToken<ISAccessToken>();

	const isRoleMatchingLicense = (role: any) => role.License === licenseName && role.CompanyId === company.CompanyId;

	if (!decodedAccessToken || !decodedAccessToken.CompanyRoles) return false;

	if (decodedAccessToken.CompanyRoles.some(isRoleMatchingLicense) && company.License === licenseName) {
		return true;
	}

	return false;
};

const hasMarketplaceProviderFeature = () => {
	return findCompanyFeature('GrantsMarketplaceProvider');
};

const findCompanyFeature = (featureName: GrantedAIFeature) => {
	const decodedAccessToken = getDecodedAccessToken<ISAccessToken>();
	const selectedCompany = getCompanyId() ?? getCompany()?.CompanyId;

	if (decodedAccessToken == null) return false;

	const companyRole = find(decodedAccessToken.CompanyRoles, (role: any) => role.CompanyId === selectedCompany);

	if (!companyRole) return false;

	return companyRole?.Features?.some((feature: string) => feature === featureName);
};

/**
 * Should get customer data for advanced company
 */
const addAdvancedCompanyQuery = () => {
	const isAdvanced = isAdvancedToken();

	if (!isAdvanced) return false;

	try {
		const match = matchPath<{ businessId?: string }>(window.location.pathname, {
			path: '/company/:businessId',
		});

		if (match?.params?.businessId) {
			return true;
		}
	} catch (error) {
		// do not remove this
	}

	return false;
};

const isAdvancedOrCustomerToken = () => {
	if (isAdvancedToken()) return true;

	return isAdvancedCustomer();
};

const isAdvancedCustomer = () => {
	const company = getCompany();

	if (!company) return false;

	if (typeof company?.Host !== 'undefined' && company?.Host) {
		return true;
	}

	return false;
};

export {
	addAdvancedCompanyQuery,
	canSignCompanyContract,
	getCompany,
	getCompanyId,
	getFirstCompany,
	hasCompanyAcceptedAgreement,
	hasMarketplaceProviderFeature,
	isAdvancedCustomer,
	isAdvancedOrCustomerToken,
	isAdvancedToken,
	isFreemiumToken,
	isTrialToken,
	setCompany,
};
