import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import ConfirmDialog from 'components/ConfirmDialog';
import ContentContainer from 'components/Containers/ContentContainer';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import DataGrid from 'components/DataGrid';
import FilterInput from 'components/FilterInput';
import MainTitle from 'components/Titles/MainTitle';
import InstrumentModal from './InstrumentModal';

import { closeInstrumentModal, loadInstruments, name, openInstrumentModal, publishSelected, reducer, saveInstrument } from './slices';

import { Box } from '@mui/material';
import { getInstrumentRatings } from 'containers/Instruments/slices';
import { useInjectReducer } from 'utils/injectors/injectReducer';
import { initialLimitators } from './limitators';

const useStyles = makeStyles(theme => ({
	dataGrid: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		// TODO: same for enEn
		'& .row-bg--true': {
			backgroundColor: theme.palette.primary.secondary,
		},
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: theme.palette.primary.main,
	},
}));

const InstrumentsEditPage = () => {
	useInjectReducer({ key: name, reducer: reducer });

	const classes = useStyles();
	const intl = useIntl();
	const dispatch = useDispatch();

	const instruments = useSelector(state => state.admininstruments);
	const instrumentsData = instruments?.instruments;
	const instrumentsLoading = instruments?.loading;

	const isModalOpen = instruments?.modalOpen ?? false;
	const saveLoading = instruments?.loadingInstrument;

	const [phrase, setPhrase] = useState('');
	const filterInstruments = event => setPhrase(event.target.value);

	const filteredInstruments = instrumentsData?.filter(({ name }) => name.toLowerCase().includes(phrase.toLowerCase()));

	const [editedInstrument, setEditedInstrument] = useState({ limitators: initialLimitators, relatedInstruments: [], tolClasses: [] });
	const [modalTitle, setModalTitle] = useState('');
	const [openConfirm, setOpenConfirm] = useState(false);
	const [data, setData] = useState({});
	const [selected, setSelected] = useState({});
	const [openPublishSelectedSilentConfirm, setOpenPublishSelectedSilentConfirm] = useState(false);
	const [openPublishSelectedConfirm, setOpenPublishSelectedConfirm] = useState(false);

	useEffect(() => {
		if (!instruments) {
			dispatch(loadInstruments());
		}
	}, [instruments, dispatch]);

	useEffect(() => {
		return () => {
			if (isModalOpen) {
				dispatch(closeInstrumentModal());
			}
		};
	}, [isModalOpen, dispatch]);

	const openModal = title => {
		setModalTitle(title);
		dispatch(openInstrumentModal());
	};

	const closeModal = () => {
		dispatch(closeInstrumentModal());
		setModalTitle('');
		setEditedInstrument({ limitators: initialLimitators, relatedInstruments: [], tolClasses: [] });
	};

	const handleEdit = async id => {
		const instrument = filteredInstruments.reduce((acc, item) => {
			return item.id === id ? item : acc;
		}, null);

		const editedInstrument = {
			...instrument,
			relatedInstruments:
				instrument.relatedInstruments?.length > 0
					? instrument.relatedInstruments.map(({ id: instrumentId, ...rest }) => ({ instrumentId, ...rest }))
					: [],
			tolClasses: instrument.tolClasses || [],
		};

		const ratings = await dispatch(getInstrumentRatings(id));

		editedInstrument.ratings = ratings;

		setEditedInstrument(editedInstrument);
		openModal('edit');
	};

	const handleCopy = id => {
		const instrument = filteredInstruments.reduce((acc, item) => {
			return item.id === id ? item : acc;
		}, null);

		const editedInstrument = {
			...instrument,
			id: null,
			name: `[KOPIO] ${instrument.name}`,
			relatedInstruments:
				instrument.relatedInstruments?.length > 0
					? instrument.relatedInstruments.map(({ id: instrumentId, ...rest }) => ({ instrumentId, ...rest }))
					: [],
			tolClasses: instrument.tolClasses || [],
		};

		setEditedInstrument(editedInstrument);
		openModal('copy');
	};

	const onSubmit = (data, isDraft) => {
		if (Array.isArray(data.projectAim)) {
			if (data.projectAim.length > 0) {
				data.projectAim = data.projectAim.join(';');
			} else {
				data.projectAim = '';
			}
		}

		if (Array.isArray(data.projectObjects)) {
			if (data.projectObjects.length > 0) {
				data.projectObjects = data.projectObjects.join(';');
			} else {
				data.projectObjects = '';
			}
		}

		if (Array.isArray(data.projectValue)) {
			if (data.projectValue.length > 0) {
				data.projectValue = data.projectValue.join(';');
			} else {
				data.projectValue = '';
			}
		}

		if (isDraft) {
			dispatch(saveInstrument(data, isDraft));
		} else {
			setData(data);
			setOpenConfirm(true);
		}

		setEditedInstrument({ limitators: initialLimitators, relatedInstruments: [], tolClasses: [] });
	};

	const publish = () => {
		dispatch(saveInstrument(data, false));
		setData({});
	};

	const columns = [
		{
			field: 'editing',
			headerName: intl.formatMessage({ id: 'instruments.edit.actions' }),
			headerClassName: 'styled-header',
			width: 100,
			disableColumnMenu: true,
			sortable: false,
			filterable: false,
			renderCell: ({ row }) => (
				<Button
					size='small'
					color='success'
					onClick={e => {
						e.stopPropagation();
						handleEdit(row.id);
					}}
				>
					{intl.formatMessage({ id: 'common.edit' })}
				</Button>
			),
		},
		{
			field: 'copy',
			headerName: intl.formatMessage({ id: 'instruments.edit.actions' }),
			headerClassName: 'styled-header',
			width: 60,
			disableColumnMenu: true,
			sortable: false,
			filterable: false,
			renderCell: ({ row }) => (
				<IconButton
					aria-label='copy'
					onClick={e => {
						e.stopPropagation();
						handleCopy(row.id);
					}}
					color='primary'
				>
					<FileCopyIcon />
				</IconButton>
			),
		},
		{
			field: 'isDraft',
			headerName: intl.formatMessage({ id: 'instruments.edit.status' }),
			headerClassName: 'styled-header',
			width: 100,
			renderCell: params => (
				<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.isDraft}</span>
			),
		},
		{
			field: 'id',
			headerName: intl.formatMessage({ id: 'instruments.edit.id' }),
			headerClassName: 'styled-header',
			width: 150,
			hide: true,
			renderCell: params => <span className={classes.cell}>{params.id}</span>,
		},
		{
			field: 'name',
			headerName: intl.formatMessage({ id: 'instruments.edit.name' }),
			width: 700,
			renderCell: params => {
				return (
					<>
						<span className={classes.cell}>{params.row.name}</span>
					</>
				);
			},
		},
		{
			field: 'provider',
			headerName: intl.formatMessage({ id: 'instruments.edit.provider' }),
			headerClassName: 'styled-header',
			width: 150,
			renderCell: params => <span className={classes.cell}>{params.row.provider}</span>,
		},
		{
			field: 'type',
			headerName: intl.formatMessage({ id: 'instruments.edit.type' }),
			headerClassName: 'styled-header',
			width: 100,
			renderCell: params => <span className={classes.cell}>{params.row.type}</span>,
		}
	];

	const rows = filteredInstruments
		?.slice()
		?.sort(({ isDraft }) => (isDraft ? -1 : 1))
		?.map(({ id, name, provider, type, projectAimArr, projectObjectsArr, projectValueArr, isDraft }) => {
			return {
				id,
				name,
				provider,
				type: intl.formatMessage({ id: `instruments.edit.check.item.${type}` }),
				projectAimArr: projectAimArr.join(', '),
				projectObjectsArr: projectObjectsArr.join(', '),
				projectValueArr: projectValueArr.join(', '),
				isDraft: intl.formatMessage({ id: `instruments.edit.draft.${isDraft}` }),
				draft: isDraft,
			};
		});

	const providers = instrumentsData?.map(({ provider }) => provider);
	const projectAims = instrumentsData?.map(({ projectAimArr }) => projectAimArr);
	const projectObjects = instrumentsData?.map(({ projectObjectsArr }) => projectObjectsArr);
	const projectValues = instrumentsData?.map(({ projectValueArr }) => projectValueArr);

	const uniqueProviders = providers ? [...new Set(providers)] : [];
	const uniqueProjectAims = projectAims ? [...new Set([].concat(...projectAims))] : [];
	const uniqueProjectObjects = projectObjects ? [...new Set([].concat(...projectObjects))] : [];
	const uniqueProjectValues = projectValues ? [...new Set([].concat(...projectValues))] : [];

	const publishSelectedInstruments = (silent = true) => {
		if (selected && selected.length > 0) {
			dispatch(publishSelected(silent, selected));
			setSelected([]);
		}
	};

	const AddButton = () => (
		<Button color='primary' endIcon={<AddIcon />} onClick={() => openModal('add')}>
			{intl.formatMessage({ id: 'instruments.edit.add.button' })}
		</Button>
	);

	return (
		<ContentContainer>
			<LeftRightContainer
				left={<MainTitle margin={false}>{intl.formatMessage({ id: 'instrument.edit.title' })}</MainTitle>}
				right={
					((!instrumentsLoading && rows && rows.length !== 0) || phrase) && (
						<FilterInput value={phrase} onChange={filterInstruments} placeholder='admin.instruments' />
					)
				}
			/>
			{instrumentsLoading || (!instrumentsLoading && rows && rows.length !== 0) ? (
				<Box>
					<DataGrid
						idField='rowKey'
						loading={instrumentsLoading}
						data={rows}
						columns={columns}
						getRowClassName={params => `row-bg--${params.row.draft}`}
						checkboxSelection
						isRowSelectable={params => params.row.draft === true}
						onSelectionModelChange={e => setSelected(e)}
						paginationMode='client'
						className={classes.dataGrid}
					/>
					<LeftRightContainer
						left={
							selected &&
							selected.length > 0 && (
								<Stack direction='row' spacing={2}>
									<Button
										color='primary'
										endIcon={<VolumeOffIcon />}
										onClick={() => setOpenPublishSelectedSilentConfirm(true)}
									>
										{intl.formatMessage({ id: 'instruments.edit.publish.silent.button' })}
									</Button>
									<Button color='primary' endIcon={<VolumeUpIcon />} onClick={() => setOpenPublishSelectedConfirm(true)}>
										{intl.formatMessage({ id: 'instruments.edit.publish.button' })}
									</Button>
									<ConfirmDialog
										title={intl.formatMessage({ id: 'instrument.confirm.publish.selected.silent.title' })}
										open={openPublishSelectedSilentConfirm}
										setOpen={setOpenPublishSelectedSilentConfirm}
										onConfirm={() => publishSelectedInstruments(true)}
										buttonOkId='instrument.confirm.publish.button'
										titleId='instrument.confirm.publish.selected.silent.title'
										submitApproveText={intl.formatMessage({ id: 'instrument.confirm.publish.button' })}
									>
										<p>{intl.formatMessage({ id: 'instrument.confirm.publish.selected.silent.body' })}</p>
									</ConfirmDialog>
									<ConfirmDialog
										title={intl.formatMessage({ id: 'instrument.confirm.publish.selected.title' })}
										open={openPublishSelectedConfirm}
										setOpen={setOpenPublishSelectedConfirm}
										onConfirm={() => publishSelectedInstruments(false)}
										buttonOkId='instrument.confirm.publish.button'
										titleId='instrument.confirm.publish.selected.title'
										submitApproveText={intl.formatMessage({ id: 'instrument.confirm.publish.button' })}
									>
										<p>{intl.formatMessage({ id: 'instrument.confirm.publish.selected.body' })}</p>
									</ConfirmDialog>
								</Stack>
							)
						}
						right={!instrumentsLoading && rows && rows.length !== 0 && <AddButton />}
					/>
				</Box>
			) : !instrumentsLoading && rows && rows.length === 0 && phrase ? (
				<Typography component='p' color='primary'>
					{intl.formatMessage({ id: 'filter.no.items' })}
				</Typography>
			) : (
				<Stack spacing={2}>
					<Typography component='p' color='primary'>
						{intl.formatMessage({ id: 'instruments.edit.no.instruments' })}
					</Typography>
					<AddButton sx={{ mt: 0.5 }} />
				</Stack>
			)}
			<InstrumentModal
				open={isModalOpen}
				close={closeModal}
				instrument={editedInstrument}
				instruments={instruments?.instruments}
				title={modalTitle}
				onSubmit={onSubmit}
				saveLoading={saveLoading}
				providers={uniqueProviders}
				projectAims={uniqueProjectAims}
				projectObjects={uniqueProjectObjects}
				projectValues={uniqueProjectValues}
			/>
			<ConfirmDialog
				title={intl.formatMessage({ id: 'instrument.publish.title' })}
				open={openConfirm}
				setOpen={setOpenConfirm}
				onConfirm={publish}
				buttonOkId='instrument.confirm.save'
				titleId='instrument.confirm.save'
				submitApproveText={intl.formatMessage({ id: 'instrument.confirm.save' })}
			>
				<p>{intl.formatMessage({ id: 'instrument.confirm.title' })}</p>
			</ConfirmDialog>
		</ContentContainer>
	);
};

export default InstrumentsEditPage;
