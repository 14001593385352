
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { ToastContainer, Zoom } from 'react-toastify';

import { beigeColor, errorColor, successColor, warningColor } from 'config/colors';

const useStyles = makeStyles(() => ({
	container: {
		maxWidth: '80%',
		top: '1rem',
		transform: 'translateX(-50%)',
	},
	info: {
		backgroundColor: 'rgb(236, 244, 251) ',
		color: 'rgb(28, 58, 87)',
	},
	error: {
		backgroundColor: errorColor,
	},
	success: {
		backgroundColor: successColor,
	},
	warning: {
		backgroundColor: warningColor,
	},
	default: {
		backgroundColor: beigeColor,
	},
}));

const Toaster = () => {
	const minWidth = useMediaQuery('(min-width:480px)');
	const classes = useStyles();

	const contextClass = {
		container: `Toastify__toast-container--top-center ${classes.container}`,
		error: `Toastify__toast ${classes.error}`,
		success: `Toastify__toast ${classes.success}`,
		warning: `Toastify__toast ${classes.warning}`,
		default: `Toastify__toast ${classes.default}`,
		info: `Toastify__toast ${classes.info}`,
	};

	return (
		<ToastContainer
			newestOnTop
			transition={Zoom}
			position='top-center'
			draggablePercent={60}
			autoClose={5000}
			style={{ fontSize: '1rem' }}
			toastClassName={({ type }) =>
				minWidth ? contextClass[type || 'default'] : contextClass[type || 'default'] + ' ' + contextClass.container
			}
		/>
	);
};

export default Toaster;
