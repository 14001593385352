import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, DrawerProps, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { closeDrawer, getDrawerData, isDrawerOpen } from 'containers/Marketplace/drawerSlice';
import { openDialog } from 'containers/Marketplace/dialogSlice';

import UpsertRfpProposalForm from 'containers/Marketplace/proposal/UpsertRfpProposalForm';

type Props = DrawerProps & {};

const UpsertRfpProposalDrawer: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const drawerOpen = useSelector(isDrawerOpen('UpsertRfpProposalDrawer'));
	const drawerData = useSelector(getDrawerData('UpsertRfpProposalDrawer'));

	const [isDirty, setIsDirty] = React.useState(false);

	const handleDrawerClose = React.useCallback(() => {
		if (isDirty) {
			dispatch(
				openDialog({
					name: 'ConfirmCloseDialog',
					data: {
						onSubmit: () => {
							dispatch(closeDrawer({ name: 'UpsertRfpProposalDrawer' }));
						},
					},
				})
			);
		} else {
			dispatch(closeDrawer({ name: 'UpsertRfpProposalDrawer' }));
		}
	}, [dispatch, isDirty]);

	const handleAfterPublish = React.useCallback(() => {
		dispatch(closeDrawer({ name: 'UpsertRfpProposalDrawer' }));
	}, [dispatch]);

	const handleAfterRemove = React.useCallback(() => {
		dispatch(closeDrawer({ name: 'UpsertRfpProposalDrawer' }));
	}, [dispatch]);

	const handleDirtyChange = React.useCallback((nextIsDirty: boolean) => {
		setIsDirty(nextIsDirty);
	}, []);

	return (
		<>
			<Drawer
				{...otherProps}
				anchor='right'
				open={drawerOpen}
				onClose={handleDrawerClose}
				PaperProps={{
					sx: { width: { xs: '100%', md: '80%', lg: '60%' } },
				}}
			>
				<IconButton sx={{ position: 'absolute', right: 0, top: 0 }} onClick={handleDrawerClose}>
					<CloseIcon />
				</IconButton>
				<UpsertRfpProposalForm
					requestId={drawerData?.requestId}
					proposalId={drawerData?.proposalId}
					published={drawerData?.published}
					onAfterPublish={handleAfterPublish}
					onAfterRemove={handleAfterRemove}
					onDirtyChange={handleDirtyChange}
				/>
			</Drawer>
		</>
	);
};

export default UpsertRfpProposalDrawer;
