export default [
    {
        code: 0,
        name: 'Valitse...',
    },
    {
        code: '01',
        name: 'Uusimaa'
    },
    {
        code: '02',
        name: 'Varsinais-Suomi'
    },
    // {
    //     code: '03',
    //     name: ''
    // },
    {
        code: '04',
        name: 'Satakunta'
    },
    {
        code: '05',
        name: 'Kanta-Häme'
    },
    {
        code: '06',
        name: 'Pirkanmaa'
    },
    {
        code: '07',
        name: 'Päijät-Häme'
    },
    {
        code: '08',
        name: 'Kymenlaakso'
    },
    {
        code: '09',
        name: 'Etelä-Karjala'
    },
    {
        code: '10',
        name: 'Etelä-Savo'
    },
    {
        code: '11',
        name: 'Pohjois-Savo'
    },
    {
        code: '12',
        name: 'Pohjois-Karjala'
    },
    {
        code: '13',
        name: 'Keski-Suomi'
    },
    {
        code: '14',
        name: 'Etelä-Pohjanmaa'
    },
    {
        code: '15',
        name: 'Pohjanmaa'
    },
    {
        code: '16',
        name: 'Keski-Pohjanmaa'
    },
    {
        code: '17',
        name: 'Pohjois-Pohjanmaa'
    },
    {
        code: '18',
        name: 'Kainuu'
    },
    {
        code: '19',
        name: 'Lappi'
    },
    // {
    //     code: '20',
    //     name: ''
    // },
    {
        code: '21',
        name: 'Ahvenanmaa'
    },
];