import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

import { ProjectSupplementary } from 'types/dataTypes';

type FetchProjectSupplementaries = {
	companyId: string | null;
	projectId: string | null;
};

type FetchProjectSupplementary = {
	companyId: string | null;
	projectId: string | null;
	supplementaryId: string | null;
};

type UpsertProjectSupplementary = {
	companyId: string | null;
	projectId: string | null;
	supplementary: Partial<ProjectSupplementary>;
};

type DeleteProjectSupplementary = {
	companyId: string | null;
	projectId: string | null;
	supplementaryId: string | null;
};

const Tags = {
	ProjectSupplementary: 'projectSupplementary',
};

const baseUrl = 'project';

export const projectSupplementaryApi = createApi({
	reducerPath: 'projectSupplementary',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.ProjectSupplementary],
	endpoints: builder => ({
		fetchProjectSupplementaries: builder.query<ProjectSupplementary[], FetchProjectSupplementaries>({
			query: ({ companyId, projectId }) => {
				if (isNil(companyId) || isNil(projectId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/project/${projectId}/supplementary`,
				};
			},
			providesTags: () => {
				return [{ type: Tags.ProjectSupplementary, id: 'LIST' }];
			},
		}),
		fetchProjectSupplementary: builder.query<ProjectSupplementary[], FetchProjectSupplementary>({
			query: ({ companyId, projectId, supplementaryId }) => {
				if (isNil(companyId) || isNil(projectId) || isNil(supplementaryId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/project/${projectId}/supplementary/${supplementaryId}`,
				};
			},
			providesTags: () => {
				return [{ type: Tags.ProjectSupplementary, id: 'LIST' }];
			},
		}),
		upsertProjectSupplementary: builder.mutation<ProjectSupplementary, UpsertProjectSupplementary>({
			query: ({ companyId, projectId, supplementary }) => {
				if (isNil(companyId) || isNil(projectId) || isNil(supplementary)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/${companyId}/project/${projectId}/supplementary`,
					data: supplementary,
				};
			},
			invalidatesTags: supplementary => {
				return [
					{ type: Tags.ProjectSupplementary, id: 'LIST' },
					{ type: Tags.ProjectSupplementary, id: supplementary?.projectSupplementaryInformationId ?? '' },
				];
			},
		}),
		deleteProjectSupplementary: builder.mutation<boolean, DeleteProjectSupplementary>({
			query: ({ companyId, projectId, supplementaryId }) => {
				if (isNil(companyId) || isNil(supplementaryId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/${companyId}/project/${projectId}/supplementary/${supplementaryId}`,
				};
			},
			invalidatesTags: (result, error, { supplementaryId }) => {
				return [
					{ type: Tags.ProjectSupplementary, id: 'LIST' },
					{ type: Tags.ProjectSupplementary, id: supplementaryId || '' },
				];
			},
		}),
	}),
});

export const {
	useFetchProjectSupplementariesQuery,
	useFetchProjectSupplementaryQuery,
	useUpsertProjectSupplementaryMutation,
	useDeleteProjectSupplementaryMutation,
} = projectSupplementaryApi;
