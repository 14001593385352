import {
	CLOSE_MODAL,
	LOAD_INSTRUMENT_RADAR,
	LOAD_INSTRUMENT_RADAR_ERROR,
	LOAD_INSTRUMENT_RADAR_SUCCESS,
	LOAD_SCENARIOS,
	LOAD_SCENARIOS_ERROR,
	LOAD_SCENARIOS_SUCCESS,
	OPEN_MODAL,
	SAVE_SCENARIO,
	SAVE_SCENARIO_FAIL,
	SAVE_SCENARIO_SUCCESS,
	SET_COMPANY_LIMIT,
} from './constants';

export const loadRadarData = businessId => {
	return {
		type: LOAD_INSTRUMENT_RADAR,
		businessId,
	};
};

export const loadRadarDataSuccess = radar => {
	return {
		type: LOAD_INSTRUMENT_RADAR_SUCCESS,
		radar,
	};
};

export const loadRadarDataError = error => {
	return {
		type: LOAD_INSTRUMENT_RADAR_ERROR,
		error,
	};
};

export const loadScenarios = businessId => {
	return {
		type: LOAD_SCENARIOS,
		businessId,
	};
};

export const loadScenariosSuccess = scenarios => {
	return {
		type: LOAD_SCENARIOS_SUCCESS,
		scenarios,
	};
};

export const loadScenariosError = error => {
	return {
		type: LOAD_SCENARIOS_ERROR,
		error,
	};
};

export const saveScenario = scenario => {
	return {
		type: SAVE_SCENARIO,
		scenario,
	};
};

export const saveScenarioFail = error => {
	return {
		type: SAVE_SCENARIO_FAIL,
		error: error,
	};
};

export const saveScenarioSuccess = scenario => {
	return {
		type: SAVE_SCENARIO_SUCCESS,
		scenario: scenario,
	};
};

export const setCompanyLimit = company => {
	return {
		type: SET_COMPANY_LIMIT,
		company,
	};
};

export const openScenarioModal = () => {
	return {
		type: OPEN_MODAL,
	};
};

export const closeScenarioModal = () => {
	return {
		type: CLOSE_MODAL,
	};
};
