import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Delete';
import {
	Autocomplete,
	Box,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
	createFilterOptions,
} from '@mui/material';
import AddButton from 'components/Button/AddButton';
import ConfirmButton from 'components/Button/ConfirmButton';
import FormControlledDatepicker from 'components/Form/FormControlledDatepicker';
import FormControlledInput from 'components/Form/FormControlledInput';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import { map } from 'lodash';
import { Controller, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Instrument, Project, ProjectTask, projectTaskCategories } from 'types/dataTypes';
import { generateCurrentTimeTicks } from 'utils/helpers/dateExtension';
import ProjectTaskCostForm from './ProjectTaskCostForm';

type GroupProps = {
	tasks: Array<ProjectTask> | null;
	project: Project;
	control: any;
	errors: any;
	groupIndex: string;
	projectTaskGroupId: string | null;
	watch: any;
	setValue: any;
	instrument?: Instrument;
};
type UriParams = {
	id: string;
};

type CustomOption = string | { category: string; value?: string };

const filterOptions = createFilterOptions<CustomOption>();

const ProjectTaskFormComponents: React.FC<{
	task: ProjectTask;
	control: any;
	errors: any;
	taskIndex: number;
	groupIndex: string;
	projectTaskGroupId: string | null;
	watch: any;
	setValue: any;
	remove: any;
	move: any;
	taskCount: number;
	instrument?: Instrument;
}> = ({ task, control, errors, taskIndex, groupIndex, projectTaskGroupId, watch, setValue, remove, move, taskCount, instrument }) => {
	const { formatMessage } = useIntl();
	const { id: projectId } = useParams<UriParams>();

	return (
		<>
			<Controller
				name={`${groupIndex}.tasks[${taskIndex}].sortOrder`}
				defaultValue={taskIndex} // @ts-ignore
				value={taskIndex}
				control={control}
				render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
			/>
			<Controller
				name={`${groupIndex}.tasks[${taskIndex}].projectTaskId`}
				defaultValue={task?.projectTaskId ?? ''} // @ts-ignore
				value={task?.projectTaskId ?? ''}
				control={control}
				render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
			/>
			<Controller
				name={`${groupIndex}.tasks[${taskIndex}].projectId`}
				defaultValue={task?.projectId ?? projectId} // @ts-ignore
				value={task?.projectId ?? projectId}
				control={control}
				render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
			/>
			<Controller
				name={`${groupIndex}.tasks[${taskIndex}].projectTaskGroupId`}
				defaultValue={task?.projectTaskGroupId ?? projectTaskGroupId} // @ts-ignore
				value={task?.projectTaskGroupId ?? projectTaskGroupId}
				control={control}
				render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12} xl={6}>
					<FormControlledInput
						defaultValue={task?.projectTaskName ?? ''}
						name={`${groupIndex}.tasks[${taskIndex}].projectTaskName`}
						control={control}
						error={errors?.projectTaskName?.message}
						placeholder={formatMessage({ id: 'project.task.form.projectTaskName.placeholder' })}
						label={formatMessage({ id: 'project.task.form.projectTaskName' })}
						variant='standard'
					/>
				</Grid>
				<Grid item xs={12} xl={6}>
					<FormControl sx={{ mt: '.5rem' }} fullWidth>
						<FormLabel sx={{ mb: 1 }}>
							<Typography variant='subtitle1'>
								{formatMessage({ id: 'project.task.form.projectTaskCategory.placeholder' })}
							</Typography>
						</FormLabel>
						<Controller
							name={`${groupIndex}.tasks[${taskIndex}].projectTaskCategory`}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									sx={{
										'.MuiInput-root': {
											resize: 'vertical',
											fontSize: ' 1rem',
											paddingTop: '2px',
											paddingBottom: '2px',
										},
									}}
									freeSolo
									options={projectTaskCategories}
									getOptionLabel={option => option}
									isOptionEqualToValue={(option, value) => option === value}
									onChange={(event, newValue) => {
										onChange(newValue || '');
									}}
									onInputChange={(event, newInputValue) => {
										if (newInputValue && !projectTaskCategories.includes(newInputValue)) {
											onChange(newInputValue);
										}
									}}
									value={projectTaskCategories.includes(value) ? value : ''}
									renderInput={params => <TextField {...params} variant='standard' />}
								/>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					{/* @ts-ignore */}
					<FormControlledDatepicker
						defaultValue={task?.startDate ?? ''}
						name={`${groupIndex}.tasks[${taskIndex}].startDate`}
						control={control}
						error={errors?.paidDate?.message}
						label={formatMessage({ id: 'project.task.form.startDate' })}
						variant='standard'
					/>
				</Grid>
				<Grid item xs={6}>
					{/* @ts-ignore */}
					<FormControlledDatepicker
						defaultValue={task?.endDate ?? ''}
						name={`${groupIndex}.tasks[${taskIndex}].endDate`}
						control={control}
						error={errors?.paidDate?.message}
						label={formatMessage({ id: 'project.task.form.endDate' })}
						variant='standard'
					/>
				</Grid>
				<Grid item xs={12}>
					{/* @ts-ignore */}
					<FormControlledWysiwyg
						isTocItem={false}
						marginTop={0}
						inline={true}
						fixedToolbar={true}
						toolbar={true}
						name={`${groupIndex}.tasks[${taskIndex}].projectTaskDescription`}
						control={control}
						defaultValue={task?.projectTaskDescription ?? ''}
						error={errors?.projectTaskDescription?.message}
						label={formatMessage({ id: 'project.task.form.projectTaskDescription' })}
						instructions={formatMessage({ id: 'project.task.form.projectTaskDescription.instructions' })}
						placeholder={formatMessage({ id: 'project.task.form.projectTaskDescription.instructions' })}
					/>
				</Grid>
				<Grid item xs={12}>
					{/* @ts-ignore */}
					<FormControlledWysiwyg
						isTocItem={false}
						marginTop={0}
						inline={true}
						fixedToolbar={true}
						toolbar={true}
						name={`${groupIndex}.tasks[${taskIndex}].projectTaskGoals`}
						control={control}
						defaultValue={task?.projectTaskGoals ?? ''}
						error={errors?.projectTaskGoals?.message}
						label={formatMessage({ id: 'project.task.form.projectTaskGoals' })}
						instructions={formatMessage({ id: 'project.task.form.projectTaskGoals.instructions' })}
						placeholder={formatMessage({ id: 'project.task.form.projectTaskGoals.instructions' })}
					/>
				</Grid>
				<Grid item xs={12}>
					<ProjectTaskCostForm
						costs={task.costs}
						task={task}
						taskIndex={`${groupIndex}.tasks[${taskIndex}]`}
						{...{ control, errors, watch, setValue, instrument }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Stack flexDirection='row' justifyContent='space-between' sx={{ width: '100%' }}>
						<ConfirmButton
							size='small'
							color='error'
							startIcon={<RemoveIcon />}
							confirmAction={() => remove(taskIndex)}
							confirmText={formatMessage({ id: 'project.task.delete' })}
							cancelText={formatMessage({ id: 'user.deactivate.cancel' })}
							confirmBodyText={formatMessage({ id: 'project.task.delete.confirm' })}
							buttonText={formatMessage({ id: 'project.task.delete' })}
							disabled={undefined}
							children={undefined}
							stopPropagation={undefined}
						/>
						<Box>
							<Tooltip title='Delegoi tehtävä ylemmälle tasolle hierarkiassa'>
								<span>
									<IconButton disabled={taskIndex === 0} onClick={() => move(taskIndex, taskIndex - 1)}>
										<KeyboardArrowUp color='primary' />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title='Delegoi tehtävä alemmalle tasolle hierarkiassa'>
								<span>
									<IconButton disabled={taskCount - 1 === taskIndex} onClick={() => move(taskIndex, taskIndex + 1)}>
										<KeyboardArrowDown color='primary' />
									</IconButton>
								</span>
							</Tooltip>
						</Box>
					</Stack>
				</Grid>
			</Grid>
			<Divider orientation='horizontal' flexItem sx={{ mt: 2.5, mb: 2.5, borderColor: 'secondary.border' }} />
		</>
	);
};

const ProjectTasksForm: React.FC<GroupProps> = ({
	tasks,
	project,
	control,
	errors,
	groupIndex,
	projectTaskGroupId,
	watch,
	setValue,
	instrument,
}) => {
	const { fields, move, remove, append } = useFieldArray({
		control,
		name: `${groupIndex}.tasks`,
	});

	const handleMoveTask = (from: number, to: number) => {
		move(from, to);
		updateIndices();
	};

	const updateIndices = () => {
		fields.forEach((_, index) => {
			setValue(`${groupIndex}.tasks.${index}.sortOrder`, index);
		});
	};

	return (
		<Stack>
			{map(fields, (field: ProjectTask, index: number) => {
				return (
					<Box key={field.id} sx={{ mt: '1rem', background: '#fff', p: '1rem' }}>
						<ProjectTaskFormComponents // @ts-ignore
							task={field}
							{...{
								errors,
								control,
								taskIndex: index,
								groupIndex,
								projectTaskGroupId,
								watch,
								setValue,
								remove,
								move: handleMoveTask,
								taskCount: fields?.length ?? 0,
								instrument,
							}}
						/>
					</Box>
				);
			})}
			<Stack>
				{/* @ts-ignore */}
				<AddButton
					onClick={() =>
						append({
							companyId: project.companyId ?? '',
							timelineId: project.timelineId ?? '',
							projectId: project.projectId ?? '',
							projectTaskId: `${project.projectId}_${generateCurrentTimeTicks()}`,
							projectTaskName: '',
							projectTaskDescription: '',
							projectTaskGoals: '',
							costs: [],
							projectTaskGroupId: projectTaskGroupId,
						})
					}
				>
					Lisää tehtävä
				</AddButton>
			</Stack>
		</Stack>
	);
};

export default ProjectTasksForm;
