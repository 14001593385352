import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LeftRightContainer from 'components/Containers/LeftRightContainer';

const ConfirmPopper = ({ anchorElement, isopen, handleClose, confirmAction, confirmText, cancelText, confirmBodyText }) => {
	const id = isopen ? 'simple-popover' : undefined;

	return (
		<Popover
			id={id}
			open={isopen}
			anchorEl={anchorElement}
			onClose={handleClose}
			onClick={e => e.stopPropagation()}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', zIndex: 10003 }}
		>
			<Typography sx={{ p: 3, pt: 4, width: '35rem' }}>{confirmBodyText}</Typography>
			<LeftRightContainer sx={{ p: 2 }}
				left={<Button onClick={handleClose}>{cancelText}</Button>}
				right={<Button onClick={confirmAction}>{confirmText}</Button>} />
		</Popover>
	);
};

export default ConfirmPopper;
