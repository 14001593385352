import React from 'react';

import { styled } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const StyledContainer = styled(Grid)(() => ({
    height: 'calc(100vh - 40px)',
}));

const Spinner = () => (
    <StyledContainer container justifyContent='center' alignItems='center'>
        <Grid item>
            <CircularProgress size={24} color='primary' />
        </Grid>
    </StyledContainer>
);

export default Spinner;