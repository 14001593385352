import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.secondary.line,
        padding: '3rem'
    },
    title: {
        fontSize: '1.8rem',
        fontWeight: 900,
        overflowWrap: 'break-word',
        hyphens: 'auto',
    },
    companyName: {
        fontSize: '1.2rem',
        fontWeight: 900,
        color: theme.palette.success.main,
        marginTop: theme.spacing(1),
    },
    lastModified: {
        fontSize: '0.85rem',
        marginTop: theme.spacing(3),
    },
    mockWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '1rem',
    },
    demoLabel: {
        fontSize: '0.8rem',
        fontWeight: 600,
        padding: '0.25rem 0.75rem',
        borderRadius: '0.75rem',
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.primary.main,
    },
}));

const SidebarHeader = ({ type, companyName, mock }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.root}>
            {mock && (
                <div className={classes.mockWrapper}>
                    <Typography className={classes.demoLabel}>DEMO</Typography>
                </div>
            )}
            <Typography variant='h1' className={classes.title}>
                {type === 'Timeline' ? intl.formatMessage({ id: 'financial.plan.timeline.title' }) : intl.formatMessage({ id: 'financial.plan.title' })}
            </Typography>
            <Typography variant='h2' className={classes.companyName}>
                {companyName}
            </Typography>
            <Typography component='p' className={classes.lastModified}>
                {intl.formatMessage({ id: 'financial.plan.last.modified' })} eilen klo 8.12
            </Typography>
        </div>
    );
};

export default SidebarHeader;
