import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getCompanyId } from 'utils/auth/company';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';

import Spinner from 'components/Spinner/CenteredSpinner';
import { useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import ProjectSummary from 'containers/Projects/Project/ProjectSummary';
import { useFetchInstumentTemplateQuery, useFetchProjectQuery } from 'containers/Projects/projectRTKApi';
import { isAdminToken } from 'utils/auth/token';

const ProjectPlanPrintPage: React.FC<any> = ({ passPageProps }) => {
	const { id }: any = useParams();
	const isAdmin = isAdminToken();

	const companyId = getCompanyId();

	// Company
	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const {
		data: project,
		isLoading: isProjectLoading,
		isFetching: isProjectFetching,
	} = useFetchProjectQuery({ companyId, projectId: id, draft: isAdmin, withData: true }, { skip: !companyId || !id });

	const {
		data: instruments,
		isLoading: isInstrumenstLoading,
		isFetching: isInstrumenstFetching,
	} = useFetchInstrumentsByCompanyQuery({ companyId }, { skip: !companyId });

	const instrument = instruments?.find(item => item.id === project?.instrumentId);

	// Template
	const {
		data: template,
		isLoading: isTemplateLoading,
		isFetching: isTemplateFetching,
	} = useFetchInstumentTemplateQuery(
		{
			instrumentId: instrument?.instrumentTemplateId ?? project?.instrumentId,
			templateVersion: instrument?.instrumentTemplateId ? null : project?.instrumentTemplateVersion,
		},
		{ skip: !id || !project || !instruments }
	);

	const loading =
		isCompanyLoading ||
		isInstrumenstFetching ||
		isInstrumenstLoading ||
		isProjectLoading ||
		isProjectFetching ||
		isTemplateLoading ||
		isTemplateFetching;

	useEffect(() => {
		if (project && Object.keys(project).length !== 0) {
			passPageProps([{ id: project.projectId, name: project.projectName }]);
		}
	}, [passPageProps, project]);

	if (loading) return <Spinner />;

	// @ts-ignore
	return <ProjectSummary project={project} template={template} tasks={project?.tasks} costs={project?.costs} company={company} />;
};

export default ProjectPlanPrintPage;
