import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowRightAltIcon from '@mui/icons-material/Link';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { forwardRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import AdminContentContainer from 'components/Containers/AdminContentContainer';
import {
	getMigratedCompanyBusinessPlan,
	getMigratedCompanyData,
	getMigratedCompanyFinancialPlan,
	getMigratedCompanyProjects,
	getMigratedCompanyScenario,
	saveMigratedCompanyBusinessPlan,
	saveMigratedCompanyFinancialPlan,
	saveMigratedCompanyProjects,
	saveMigratedCompanyScenario,
	setIsLoaded,
	setSelected,
	setTimelineId,
} from 'containers/Admin/CompanyMigration/slices';
import { CompanyDetailsDashboard } from 'containers/Admin/UsersCompany';
import { getCompanyDetails } from 'containers/Admin/UsersCompany/slices';
import { getLiteScore } from 'containers/Score/slices';
import { isContributorToken } from 'utils/auth/token';
import DataMigrationModal from './DataMigrationModal';
import GenerateAnalysisLinkModal from './GenerateAnalysisLinkModal';

const migrateActionMap = {
	profileinfo: getMigratedCompanyScenario,
	businessplan: getMigratedCompanyBusinessPlan,
	financialplan: getMigratedCompanyFinancialPlan,
	projects: getMigratedCompanyProjects,
};

const saveActionMap = {
	profileinfo: saveMigratedCompanyScenario,
	businessplan: saveMigratedCompanyBusinessPlan,
	financialplan: saveMigratedCompanyFinancialPlan,
	projects: saveMigratedCompanyProjects,
};

const AdminFunctions = forwardRef(({ companyId, history, ...props }, ref) => {
	const kanban = useSelector(state => state.kanban);
	const migratedCompany = useSelector(state => state.migratedCompany);
	const dispatch = useDispatch();
	const intl = useIntl();

	const timelineId = kanban?.timeline?.data?.timelineId;

	const isMigratedDataLoaded = migratedCompany?.isLoaded;
	const selected = migratedCompany?.selected;
	const migrateActionKeys = Object.keys(migrateActionMap);

	const score = useSelector(state => state.score);
	const liteScore = score?.lite;
	const liteLoaded = score?.lite?.isLoaded;

	const [open, setOpen] = useState(false);
	const [openMigrationModal, setOpenMigrationModal] = useState(false);

	const openModal = e => {
		e.stopPropagation();
		setOpen(true);
	};

	useEffect(() => {
		if (liteLoaded || liteScore.loading) return; // don't double load lite score

		dispatch(getLiteScore());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchCompanyDetails = e => {
		e.stopPropagation();
		dispatch(getCompanyDetails(companyId));
		history.push(`/admin/company/${companyId}`);
	};

	const openCopyModal = e => {
		e.stopPropagation();
		setOpenMigrationModal(true);
	};

	const migrateData = async (migratedCompanyId, selected) => {
		if (selected?.length === 0) return;

		dispatch(setSelected(selected));
		if (selected.includes('projects')) dispatch(setTimelineId(timelineId));

		const edit = isContributorToken();

		const results = await Promise.allSettled([
			dispatch(getMigratedCompanyData(migratedCompanyId)),
			...selected.map(item => {
				const result = dispatch(migrateActionMap[item](migratedCompanyId, edit));
				return result;
			}),
		]);

		const fulfilled = results.filter(result => result.value === true);
		const success = results.length === fulfilled.length;

		if (openMigrationModal) setOpenMigrationModal(false);

		if (selected.includes('projects')) dispatch(setTimelineId(null));

		if (success) {
			history.push(`/company/${companyId}`);
			dispatch(setIsLoaded(true));
			toast.success(intl.formatMessage({ id: 'data.migration.successed' }));
		} else {
			toast.error(intl.formatMessage({ id: 'data.migration.failed' }));
		}
	};

	const saveMigratedData = async e => {
		e.stopPropagation();

		if (selected.length === 0) return;

		const results = await Promise.allSettled([...selected.map(item => dispatch(saveActionMap[item](companyId)))]);

		const fulfilled = results.filter(result => result.value === true);
		const success = results.length === fulfilled.length;

		if (success) {
			dispatch(setIsLoaded(false));
			dispatch(setSelected([]));
			toast.success(intl.formatMessage({ id: 'data.migration.saving.successed' }));
		} else {
			toast.error(intl.formatMessage({ id: 'data.migration.saving.failed' }));
		}
	};

	const loading =
		migratedCompany?.company.loading ||
		migratedCompany?.scenario.loading ||
		migratedCompany?.businessPlan.main.loading ||
		migratedCompany?.businessPlan.infoItems.loading ||
		migratedCompany?.businessPlan.teamMembers.loading ||
		migratedCompany?.businessPlan.templates.loading ||
		migratedCompany?.businessPlan.economicalData.loading ||
		migratedCompany?.businessPlan.tags.loading ||
		migratedCompany?.businessPlan.attachments.loading ||
		migratedCompany?.financialPlan.loading ||
		migratedCompany?.financialPlan.instruments.loading ||
		migratedCompany?.kanban.timeline.loading ||
		migratedCompany?.kanban.projects.loading;

	return (
		<Box ref={ref} {...props}>
			<AdminContentContainer>
				<Stack direction='column' justifyContent='end' alignItems='end' spacing={2} sx={{ mt: '1rem', pl: '2rem', pr: '2rem' }}>
					<CompanyDetailsDashboard accordionOpen={true} liteScore={liteScore}>
						<Stack direction='row' justifyContent='end' alignItems='end' spacing={2}>
							<Button variant='text' onClick={openModal} endIcon={<OpenInBrowserIcon />}>
								{intl.formatMessage({ id: 'companylist.table.companyDetails.analysislink' })}
							</Button>
							<Button onClick={fetchCompanyDetails} endIcon={<ArrowRightAltIcon />}>
								{intl.formatMessage({ id: 'companylist.table.companyDetails.link' })}
							</Button>
							{/* button disabled temporarilly */}
							<Button variant='outlined' color='primary' onClick={openCopyModal} endIcon={<ContentCopyIcon />}>
								{intl.formatMessage({ id: 'companylist.table.companyDetails.data.migration' })}
							</Button>
							{isMigratedDataLoaded && (
								<Button color='success' disabled={loading} onClick={saveMigratedData}>
									{intl.formatMessage({ id: 'companylist.table.companyDetails.confirm.migrate' })}
									{loading && <ButtonSpinner />}
								</Button>
							)}
						</Stack>
					</CompanyDetailsDashboard>
				</Stack>
			</AdminContentContainer>
			<GenerateAnalysisLinkModal companyId={companyId} open={open} setOpen={setOpen} />
			<DataMigrationModal
				open={openMigrationModal}
				setOpen={setOpenMigrationModal}
				keys={migrateActionKeys}
				migrateData={migrateData}
				loading={loading}
			/>
		</Box>
	);
});

const AdminFunctionsCollapse = ({ show, companyId, history }) => {
	return (
		<Collapse in={show} unmountOnExit>
			<AdminFunctions companyId={companyId} history={history} />
		</Collapse>
	);
};

export default AdminFunctionsCollapse;
