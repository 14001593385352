import React from 'react';
import { Controller } from 'react-hook-form';

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

import FormControlWithLabel from './FormControlWithLabel';

const ITEM_HEIGHT = 51;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 7,
			marginTop: ITEM_PADDING_TOP,
		},
	},
};

export const FormMultiSelect = ({ control, name, data, label, error, defaultValue, placeholder, multiple = true }) => {
	const [selected, setSelected] = React.useState(defaultValue ?? []);

	const handleChange = (event, onChange) => {
		const {
			target: { value },
		} = event;
		const valueArray = typeof value === 'string' ? value.split(',') : value;

		onChange(Object.assign(event, { target: { value: valueArray } }));
		setSelected(valueArray);
	};

	const getNames = selected => {
		const objectArray = [];

		for (let i = 0; i < selected.length; i++) {
			const dataItem = data.find(item => item.value === selected[i]);

			if (!dataItem) continue;

			objectArray.push(dataItem.label);
		}

		return objectArray.join(', ');
	};

	return (
		<FormControlWithLabel error={error} label={label}>
			<Controller
				control={control}
				name={name}
				defaultValue={defaultValue}
				render={({ field: { onChange, value } }) => (
					<Select
						multiple={multiple}
						value={value}
						onChange={event => handleChange(event, onChange)}
						input={
							<OutlinedInput
								sx={{
									p: '8px 32px 8px 14px',
									'&>div': {
										p: 0,
									},
								}}
							/>
						}
						renderValue={selected => getNames(selected)}
						MenuProps={MenuProps}
					>
						<MenuItem disabled value=''>
							<em>{placeholder}</em>
						</MenuItem>
						{data.map(item => (
							<MenuItem key={item.value} value={item.value}>
								<Checkbox checked={selected.indexOf(item.value) > -1} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Select>
				)}
			/>
		</FormControlWithLabel>
	);
};
