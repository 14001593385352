import React from 'react';
import { Controller } from 'react-hook-form';

import { styled, withTheme } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import EventIcon from '@mui/icons-material/Event';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

import fiLocale from 'date-fns/locale/fi';

import FormLabel from './FormLabel';
import FormError from './FormError';
import { StyledTextField } from './FormStyledTextfield';

const StyledEventIcon = styled(withTheme(EventIcon))(({ theme }) => ({
	color: theme.palette.secondary.grey500,
}));

/**
 * Controlled Datepicker, dependencies hoook-form, mui
 *
 * @returns
 */
export default function ({
	defaultValue,
	name,
	control,
	error,
	label,
	variant = 'outlined',
	minDate,
	maxDate,
	disableToolbar = false,
	setInstruction = null,
	instructions = null,
	disabled,
	onInputBlur = () => {},
	...props
}) {
	const hasError = error ? true : false;

	const showInstruction = () => setInstruction && setInstruction(instructions);
	const hideInstruction = () => setInstruction && setInstruction('');

	let timer;

	const handleBlur = () => {
		timer = setTimeout(() => hideInstruction(), 100);
		onInputBlur();
	};

	const handleOpen = () => {
		clearTimeout(timer);
		showInstruction();
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
			<FormControl fullWidth>
				<FormLabel>{label}</FormLabel>
				<Controller
					name={name}
					control={control}
					defaultValue={defaultValue}
					render={({ field: { onChange, value } }) => (
						<DatePicker
							disabled={disabled}
							openTo='month'
							views={['month', 'year']}
							maxDate={maxDate ? new Date(maxDate) : undefined}
							minDate={minDate ? new Date(minDate) : undefined}
							value={value}
							onChange={onChange}
							disableToolbar={disableToolbar}
							autoOk
							variant='inline'
							inputVariant='outlined'
							format='MM/yyyy'
							inputFormat='MM/yyyy'
							placeholder='kk/vvvv'
							disableMaskedInput={true}
							margin='normal'
							keyboardIcon={<StyledEventIcon />}
							KeyboardButtonProps={{ 'aria-label': 'change date' }}
							InputLabelProps={{ shrink: true }}
							style={{ margin: 0, padding: 0, width: '100%' }}
							onOpen={handleOpen}
							onClose={hideInstruction}
							renderInput={params => {
								params.InputProps = Object.assign({}, params.InputProps, { disableUnderline: disabled ? true : false });

								return (
									<StyledTextField
										disabled={disabled}
										variant={variant}
										size='small'
										error={hasError}
										placeholder='kk/vvvv'
										onFocus={showInstruction}
										onBlur={handleBlur}
										{...params}
									/>
								);
							}}
							{...props}
						/>
					)}
				/>
				<FormError error={error} />
			</FormControl>
		</LocalizationProvider>
	);
}
