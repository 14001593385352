import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useReactToPrint } from 'react-to-print';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { makeStyles, useTheme } from '@mui/styles';

import SidebarHeader from './SidebarHeader';
import SidebarContent from './SidebarContent';
// import SidebarFooter from './SidebarFooter';
import MainContent from './MainContent';

const useStyles = makeStyles(theme => ({
    nav: {
        flexShrink: 0,
    },
    sidebarContainer: {
        alignItems: 'stretch',
        minHeight: '100vh',
        backgroundColor: theme.palette.primary.secondary,
    },
    sidebarHeader: {
        width: '100%',
    },
    sidebarContent: {
        width: '100%',
        flexGrow: 1,
        overflow: 'auto',
    },
    sidebarFooter: {
        width: '100%',
    },
    main: {
        flexGrow: 1,
    },
    iconsPanel: {
        minHeight: '100vh',
        maxWidth: theme.spacing(8.5),
        backgroundColor: theme.palette.primary.secondary,
    },
    buttonContainer: {
        padding: theme.spacing(1.2),
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.secondary.line,
        textAlign: 'center',
    },
}));

const FinancialPlanDrawer = ({ open, close, company, financialPlan, timeline, projects, mock, scenario, instruments }) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();

    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const matchesBetween1125And1160 = useMediaQuery(theme.breakpoints.between(1125, 1160));
    const matchesBetween1160AndLg = useMediaQuery(theme.breakpoints.between(1160, 1280));
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const sidebarWidthMdDown = '80%';
    const sidebarWidthMdUp = '18rem';
    const sidebarWidthBetween1125And1160 = '20rem';
    const sidebarWidthBetween1160AndLg = '22rem';
    const sidebarWidthLgUp = '25rem';

    const [selectedId, setSelectedId] = useState();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleSidebarToggle = () => setSidebarOpen(!sidebarOpen);

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `${intl.formatMessage({ id: 'financial.plan.title' })}: ${company?.name}, ${company?.businessId}`,
    });

    const sidebar = (
        <Grid container direction='column' wrap='nowrap' className={classes.sidebarContainer}>
            <Grid item className={classes.sidebarHeader}>
                <SidebarHeader type={financialPlan?.type} companyName={company?.name} mock={mock} />
            </Grid>
            <Grid item className={classes.sidebarContent}>
                <SidebarContent setSelectedId={setSelectedId} />
            </Grid>
            {/* <Grid item className={classes.sidebarFooter}>
                    <SidebarFooter />
                </Grid> */}
        </Grid>
    );

    return (
        <Box display='flex'>
            <Drawer anchor='bottom' open={open} onClose={close} transitionDuration={{ enter: 1000, exit: 1000 }}>
                <nav
                    className={classes.nav}
                    style={{
                        width: lgUp
                            ? sidebarWidthLgUp
                            : matchesBetween1160AndLg
                                ? sidebarWidthBetween1160AndLg
                                : matchesBetween1125And1160
                                    ? sidebarWidthBetween1125And1160
                                    : mdUp && sidebarWidthMdUp,
                    }}
                >
                    {mdUp ? (
                        <Drawer
                            variant='permanent'
                            open
                            PaperProps={{
                                style: {
                                    width: lgUp
                                        ? sidebarWidthLgUp
                                        : matchesBetween1160AndLg
                                            ? sidebarWidthBetween1160AndLg
                                            : matchesBetween1125And1160
                                                ? sidebarWidthBetween1125And1160
                                                : mdUp && sidebarWidthMdUp,
                                },
                            }}
                        >
                            {sidebar}
                        </Drawer>
                    ) : (
                        <Drawer
                            variant='temporary'
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={sidebarOpen}
                            onClose={handleSidebarToggle}
                            PaperProps={{
                                style: {
                                    width: sidebarWidthMdDown,
                                },
                            }}
                        >
                            {sidebar}
                        </Drawer>
                    )}
                </nav>
                <main
                    className={classes.main}
                    style={{
                        marginLeft: lgUp
                            ? sidebarWidthLgUp
                            : matchesBetween1160AndLg
                                ? sidebarWidthBetween1160AndLg
                                : matchesBetween1125And1160
                                    ? sidebarWidthBetween1125And1160
                                    : mdUp && sidebarWidthMdUp,
                    }}
                >
                    <Box display='flex'>
                        <MainContent
                            company={company}
                            scenario={scenario}
                            timeline={timeline}
                            projects={projects}
                            financialPlan={financialPlan}
                            instruments={instruments}
                            mock={mock}
                            selectedId={selectedId}
                            printRef={printRef}
                            mdUp={mdUp}
                        />
                        <Grid container direction='column' className={classes.iconsPanel}>
                            <Grid item className={classes.buttonContainer}>
                                <IconButton aria-label='close' onClick={close}>
                                    <CloseIcon color='primary' />
                                </IconButton>
                            </Grid>
                            {!mock && (
                                <Grid item className={classes.buttonContainer}>
                                    <IconButton aria-label='print' onClick={handlePrint}>
                                        <PrintIcon color='primary' />
                                    </IconButton>
                                </Grid>
                            )}
                            {!mdUp && (
                                <Grid item className={classes.buttonContainer}>
                                    <IconButton aria-label='print' onClick={handleSidebarToggle}>
                                        <MenuOpenIcon color='primary' />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </main>
            </Drawer>
        </Box>
    );
};

export default FinancialPlanDrawer;
