import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import FormLabel from 'components/Form/FormLabel';
import ImageDropzone from './ImageDropzone';
import { convertBase64 } from 'utils/helpers/fileHelper';

const ImageAttachment = ({ setValue, avatar, formLabel = false, readonly }) => {
    const intl = useIntl();

    const [files, setFiles] = useState([]);

    const handleDrop = async droppedFiles => {
        setFiles(droppedFiles.map(file => ({
            ...file,
            preview: URL.createObjectURL(file)
        })));

        const convertedToBase64 = await convertBase64(droppedFiles[0]);

        setAvatarValue(convertedToBase64);
    };

    const setAvatarValue = (value) => {
        setValue('avatar', value, { shouldValidate: true, shouldDirty: true });
    };

    const handleRemove = file => {
        setFiles(prevFiles => prevFiles.filter(_file => _file.path !== file.path));
        setAvatarValue(null);
    };

    return (
        <div>
            {formLabel && <FormLabel> {intl.formatMessage({ id: 'business.plan.form.avatar' })}</FormLabel>}
            <ImageDropzone
                readonly={readonly}
                files={files}
                avatar={avatar}
                onDrop={handleDrop}
                onRemove={handleRemove}
                setValue={setAvatarValue}
            />
        </div>
    );

};

export default ImageAttachment;