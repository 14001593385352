import { createSlice, /* current */ } from '@reduxjs/toolkit';
import { matchPath } from 'react-router';

import { userCompanyAdminApi } from './api';
import { getCompanyDetails } from './slices';

export const initialState = {
	// users
	users: [],
	loading: false,
	usersError: false,
	userActiveStatus: 'all',
	// userDetails
	userDetails: {},
	userDetailsLoading: false,
	userDetailsError: false,
};

export const name = 'users';

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},

		saveCompanyUser(state) {
			state.loading = true;
			state.companyUserError = false;
		},
		saveCompanyUserSuccess(state) {
			state.loading = false;
			state.companyUserError = false;
		},
		saveCompanyUserError(state, action) {
			state.companyUserError = action.payload;
			state.loading = false;
		},

		loadUserDetails(state) {
			state.userDetailsLoading = true;
		},
		loadUserDetailsSuccess(state, action) {
			state.userDetailsLoading = false;
			state.userDetailsError = false;
			state.userDetails = action.payload;
		},
		loadUserDetailsError(state, action) {
			state.userDetailsError = action.payload;
			state.userDetailsLoading = false;
		},

		loadUsers(state) {
			state.loading = true;
			state.usersError = false;
		},
		loadUsersSuccess(state, action) {
			state.loading = false;
			state.usersError = false;
			state.users = action.payload.data;
			state.userActiveStatus = action.payload.userActiveStatus;
		},
		loadUsersError(state, action) {
			state.usersError = action.payload;
			state.loading = false;
			state.users = initialState.users;
		},

		deactivateUser(state) {
			state.loading = true;
			state.companyUserError = false;
		},
		deactivateUserSuccess(state) {
			state.loading = false;
			state.companyUserError = false;
		},
		deactivateUserError(state, action) {
			state.companyUserError = action.payload;
			state.loading = false;
		},

		deleteCompanyRoles(state) {
			state.loading = true;
			state.userDetailsLoading = true;
		},
		deleteCompanyRolesSuccess(state) {
			state.loading = false;
			state.companyUserError = false;
			state.userDetailsError = false;
			state.userDetailsLoading = false;
		},
		deleteCompanyRolesError(state, action) {
			state.companyUserError = action.payload;
			state.loading = false;
			state.userDetailsError = action.payload;
			state.userDetailsLoading = false;
		},

		sendActivationLinkToUser(state) {
			state.loading = true;
			state.userDetailsLoading = true;
		},
		sendActivationLinkToUserSuccess(state) {
			state.loading = false;
			state.companyUserError = false;
			state.userDetailsError = false;
			state.userDetailsLoading = false;
		},
		sendActivationLinkToUserError(state, action) {
			state.loading = false;
			state.userDetailsError = action.payload;
			state.userDetailsLoading = false;
		},
	},
});

export const { reducer } = slice;

export const saveCompanyUser = (user, id) => async dispatch => {
	try {
		dispatch(slice.actions.saveCompanyUser());

		const data = await userCompanyAdminApi.saveCompanyUser(id, user);

		dispatch(slice.actions.saveCompanyUserSuccess(data));
		dispatch(getCompanyDetails(id));
	} catch (error) {
		dispatch(slice.actions.saveCompanyUserError(error));
	}
};

export const getUserDetails = id => async dispatch => {
	try {
		dispatch(slice.actions.loadUserDetails());

		if (!id) {
			const match = matchPath(window.location.pathname, {
				path: '/admin/user/:userId',
			});
			id = match?.params?.userId;
		}

		const data = await userCompanyAdminApi.getUserDetails(id);

		dispatch(slice.actions.loadUserDetailsSuccess(data));
	} catch (error) {
		dispatch(slice.actions.loadUserDetailsError(error));
	}
};

export const getUsersActiveStatus = userActiveStatus => async dispatch => {
	try {
		dispatch(slice.actions.loadUsers());

		const data = await userCompanyAdminApi.getUsersActiveStatus(userActiveStatus);

		dispatch(slice.actions.loadUsersSuccess({ data, userActiveStatus }));
	} catch (error) {
		dispatch(slice.actions.loadUsersError(error));
	}
};

export const deactivateUser = (user, companyId) => async dispatch => {
	try {
		dispatch(slice.actions.deactivateUser());

		const data = await userCompanyAdminApi.deactivateUser(user);

		dispatch(slice.actions.deactivateUserSuccess(data));

		if (companyId) {
			dispatch(getCompanyDetails(companyId));
		} else if (user?.id) {
			dispatch(getUserDetails(user.id));
		}
	} catch (error) {
		dispatch(slice.actions.deactivateUserError(error));
	}
};

export const deleteCompanyRoles = (companyId, userId, companyRoles) => async dispatch => {
	try {
		dispatch(slice.actions.deleteCompanyRoles());

		await userCompanyAdminApi.deleteCompanyRoles(companyRoles);

		dispatch(slice.actions.deleteCompanyRolesSuccess(companyId));
		if (companyId) {
			dispatch(getCompanyDetails(companyId));
		} if (userId) {
			dispatch(getUserDetails(userId));
		}
	} catch (error) {
		dispatch(slice.actions.deleteCompanyRolesError(error));
	}
};

export const sendActivationLinkToUser = username => async dispatch => {
	try {
		dispatch(slice.actions.sendActivationLinkToUser());

		await userCompanyAdminApi.sendActivationLinkToUser(username);

		dispatch(slice.actions.sendActivationLinkToUserSuccess());

		dispatch(getUserDetails(username));

		return true;
	} catch (error) {
		dispatch(slice.actions.sendActivationLinkToUserError(error));

		return false;
	}
};

export default slice;
