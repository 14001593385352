import { Print as PrintIcon } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, Tab, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useReactToPrint } from 'react-to-print';

import ContentContainer from 'components/Containers/ContentContainer';
import HtmlParser from 'components/HtmlParser';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddButton from 'components/Button/AddButton';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import InfoBoxes from 'containers/Projects/Project/ProjectSummary/InfoBoxes';
import ProjectPlanSummary from 'containers/Projects/Project/ProjectSummary/ProjectPlanSummary';
import { projectCostSelector, projectTaskSelector } from 'containers/Projects/slices';
import { isNil } from 'lodash';
import { Company, Project, ProjectCost, ProjectTask } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import { stripText } from 'utils/helpers/stripText';
import ProjectContainer from '../ProjectContainer/ProjectContainer';
import CostsTable from '../ProjectPlan/ProjectPlanPrint/CostsTable/CostsTable';
import EuraCostsTable from '../ProjectPlan/ProjectPlanPrint/CostsTable/EuraCostsTable';
import FinanceTable from '../ProjectPlan/ProjectPlanPrint/CostsTable/FinanceTable';
import OverviewTable from '../ProjectPlan/ProjectPlanPrint/OverviewTable';
import PurchasingTable from '../ProjectPlan/ProjectPlanPrint/PurchasingTable';
import TaskTable from '../ProjectPlan/ProjectPlanPrint/TaskTable';
import WorkTable from '../ProjectPlan/ProjectPlanPrint/WorkTable';
import { ProjectSummarySection } from './Components';
import CreateLinkButton from './CreateLinkButton';
import DynamicProjectApplication from './DynamicProjectApplication';

export const printPageStyle = `
	@page {
		size: a4;
		margin: 0mm;  
		padding: 0;
	}

	@media print {
		html, body {
			width: 210mm;
			height: 297mm;
		}
	}

	.tdPrint {
		display: block;
		background-color: #000;
	}
`;

export type ProjectSummaryMainProps = {
	project: Project;
	template: any;
	readonly?: boolean;
	sx?: any;
	compact?: boolean;
};

export const ProjectInfoTable = ({ company, project }: { company: Company | undefined; project: Project }) => {
	const { formatMessage, formatDate } = useIntl();

	return (
		<ProjectSummarySection showToggle level='h2' title={formatMessage({ id: 'project.summary.info-table.title' })}>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell>
							<FormattedMessage id='project.summary.info-table.company' />
						</TableCell>
						<TableCell>
							{company?.name}, {company?.businessId}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<FormattedMessage id='project.summary.info-table.name' />
						</TableCell>
						<TableCell>{project.projectName}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<FormattedMessage id='project.summary.info-table.application' />
						</TableCell>
						<TableCell>{project.projectApplicationNumber}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<FormattedMessage id='project.summary.info-table.planner' />
						</TableCell>
						<TableCell>
							{stripText(project.dynamic?.people?.responsible) ?? stripText(project.dynamic?.personnel?.contactPerson)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<FormattedMessage id='project.summary.info-table.schedule' />
						</TableCell>
						<TableCell>
							{project?.startDate ? formatDate(project?.startDate) : null} -{' '}
							{project?.endDate ? formatDate(project?.endDate) : null}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<FormattedMessage id='project.summary.info-table.projectPlanDate' />
						</TableCell>
						<TableCell>{project?.projectPlanDate ? formatDate(project?.projectPlanDate) : null}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</ProjectSummarySection>
	);
};

/**
 * Creates project summary contents
 *
 * @param {*} project
 * @param {*} template
 *
 * @returns
 */
const ProjectSummary = ({ project, template, readonly, compact, sx = {} }: ProjectSummaryMainProps) => {
	const intl = useIntl();
	const theme = useTheme();
	const xl = useMediaQuery(theme.breakpoints.up('xl'));
	const printPageRef = React.useRef(null);
	const printPlanPageRef = React.useRef(null);

	const companyId = getCompanyId();

	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const { data: instruments, isLoading: isInstrumentsLoading } = useFetchInstrumentsByCompanyQuery(
		{ companyId },
		{ skip: isNil(companyId) }
	);

	const handlePrintClick = useReactToPrint({
		content: () => printPageRef.current,
		documentTitle: project?.projectName ?? '',
		pageStyle: printPageStyle,
	});

	const handlePrintProjectPlanClick = useReactToPrint({
		content: () => printPlanPageRef.current,
		documentTitle: project?.projectName ?? '',
		pageStyle: printPageStyle,
	});

	const tasks: Array<ProjectTask> = projectTaskSelector(project);
	const costs: Array<ProjectCost> = projectCostSelector(project);

	const instrument = instruments ? instruments.find(item => item.id === project.instrumentId) : null;
	const isBf = instrument?.provider === 'Business Finland';

	const [tab, setTab] = React.useState('wholeProject');
	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setTab(newValue);
	};

	return (
		//@ts-ignore
		<ContentContainer sx={{ '@media print': { display: 'block' }, ...sx }}>
			<TabContext value={tab}>
				{/* @ts-ignore */}
				<ProjectContainer
					direction='column'
					useStickyHeader
					project={project}
					readonly={readonly}
					actions={
						<Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={2}>
							<TabList onChange={handleTabChange} aria-label='lab API tabs example'>
								<Tab label='Projektisuunnitelma' value='projectPlan' />
								<Tab label='Rahoitushakemus' value='wholeProject' />
							</TabList>
							{tab === 'projectPlan' && (
								<AddButton color='primary' icon={<PrintIcon />} sx={{ mr: '1rem' }} onClick={handlePrintProjectPlanClick}>
									<FormattedMessage id='project.summary.print' />
								</AddButton>
							)}
							{tab === 'wholeProject' && (
								<AddButton color='primary' icon={<PrintIcon />} sx={{ mr: '1rem' }} onClick={handlePrintClick}>
									<FormattedMessage id='project.summary.print' />
								</AddButton>
							)}
							<CreateLinkButton project={project} />
						</Stack>
					}
				>
					<TabPanel value='projectPlan'>
						<Box ref={printPlanPageRef}>
							<ProjectPlanSummary project={project} template={template} sx={sx} />
						</Box>
					</TabPanel>
					<TabPanel value='wholeProject'>
						<Box
							ref={printPageRef}
							sx={{
								'@media print': {
									display: 'block',
									'& .MuiGrid-item': {
										maxWidth: '100%',
									},
									'& .MuiGrid-container': {
										display: 'block',
									},
								},
							}}
						>
							<Grid
								container
								sx={{
									'@media print': {
										display: 'block',
									},
								}}
							>
								<Grid
									item
									xs={12}
									xl={compact ? 12 : 3}
									sx={{
										pr: xl ? '2rem' : 0,
										'@media print': {
											display: 'block',
										},
									}}
								>
									<InfoBoxes {...{ project }} />
								</Grid>
								<Grid
									item
									xs={12}
									xl={compact ? 12 : 9}
									sx={{
										'@media print': {
											maxWidth: '100%',
											display: 'block',
										},
									}}
								>
									<ProjectInfoTable company={company} project={project} />

									<ProjectSummarySection
										level='h2'
										title={intl.formatMessage({ id: 'project.overview.projectSummary' })}
										showToggle
									>
										{HtmlParser(project.projectSummary)}
									</ProjectSummarySection>
									<ProjectSummarySection
										showToggle
										level='h2'
										title={intl.formatMessage({ id: 'project.form.projectApplication' })}
									>
										<DynamicProjectApplication {...{ project, template }} />
									</ProjectSummarySection>
									{(!template || template?.showProjectPlan === true) && ( // hide project plan if template is not set and template hides project plan
										<ProjectSummarySection level='h2' title={intl.formatMessage({ id: 'project.plan' })} showToggle>
											<OverviewTable project={project} />
										</ProjectSummarySection>
									)}
									{project?.projectTaskGroups && project?.projectTaskGroups.length > 0 && (
										<ProjectSummarySection
											level='h2'
											title={intl.formatMessage({ id: 'project.taskGroups' })}
											showToggle
											sx={{
												'@media print': {
													float: 'none',
													display: 'block',
													pageBreakBefore: 'auto',
													pageBreakAfter: 'avoid',
													pageBreakInside: 'avoid',
													overflow: 'hidden',
												},
											}}
										>
											{project?.projectTaskGroups?.map((group, index) => {
												return (
													<ProjectSummarySection
														level='h3'
														title={group.groupName}
														showToggle
														key={group.projectTaskGroupId}
														sx={{
															'@media print': {
																float: 'none',
																display: 'block',
																pageBreakBefore: 'avoid',
																pageBreakAfter: 'avoid',
																pageBreakInside: 'avoid',
																overflow: 'hidden',
															},
														}}
													>
														{group.tasks?.map((task, index) => {
															return <TaskTable key={task.projectTaskId} index={index} task={task} />;
														})}
													</ProjectSummarySection>
												);
											})}
										</ProjectSummarySection>
									)}

									<ProjectSummarySection
										level='h3'
										title={intl.formatMessage({ id: 'project.resourse.work.table' })}
										showToggle
									>
										<WorkTable tasks={tasks} costs={costs} showGrouped />
									</ProjectSummarySection>
									<ProjectSummarySection
										level='h3'
										title={intl.formatMessage({ id: 'project.resourse.purchasing.services.table' })}
										showToggle
										sx={{
											'@media print': {
												float: 'none',
												display: 'block',
												pageBreakBefore: 'avoid',
												pageBreakAfter: 'avoid',
												pageBreakInside: 'avoid',
												overflow: 'hidden',
											},
										}}
									>
										<PurchasingTable tasks={tasks} costs={costs} />
									</ProjectSummarySection>
									<ProjectSummarySection
										level='h3'
										title={intl.formatMessage({ id: 'project.resourse.purchasing.services.costs' })}
										showToggle
										sx={{
											paddingBottom: '13rem',
											'@media print': {
												float: 'none',
												display: 'block',
												pageBreakBefore: 'auto',
												pageBreakAfter: 'avoid',
												pageBreakInside: 'avoid',
												overflow: 'hidden',
											},
										}}
									>
										{isBf && (
											<CostsTable
												project={project}
												tasks={tasks}
												costs={costs}
												flatRatePercent={project.flatRateDevelopment ?? 0}
												flatRateInvestment={project.flatRateInvestment ?? 0}
												instrument={instrument}
											/>
										)}
										{!isBf && (
											<EuraCostsTable
												project={project}
												tasks={tasks}
												costs={costs}
												flatRatePercent={project.flatRateDevelopment ?? 0}
												flatRateInvestment={project.flatRateInvestment ?? 0}
											/>
										)}
										{!isBf && <FinanceTable project={project} />}
									</ProjectSummarySection>
								</Grid>
							</Grid>
						</Box>
					</TabPanel>
				</ProjectContainer>
			</TabContext>
		</ContentContainer>
	);
};

export default ProjectSummary;
