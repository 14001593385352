import axios from 'axios';

import { API_ROOT } from 'config/environment';
import { getMsalTokenAsync, msalConfig } from 'utils/auth/msal/msalConfig';
import { getAccessToken, getRefreshToken, getToken, setToken } from 'utils/auth/token';
import { getUser, setUser } from 'utils/auth/user';

export const refreshUri = `${API_ROOT}/authentication/refresh`;
export const ignoreUrls = [
	`${API_ROOT}/authentication`,
	`${API_ROOT}/login`,
	`${API_ROOT}/admin/login`,
	`${API_ROOT}/authentication/refresh`,
];

/**
 * attach token to the request Authorization header
 * You can add your own personal rules for params etc. tokens by URL
 * @param {*} request
 * @param {*} token
 */
export const attachTokenToRequest = (request, token) => {
	request.headers['Authorization'] = 'Bearer ' + token;

	// If there is an edge case where access token is also set in request query,
	// Example: /addr?token=xyz-old-token
	if (/\/addr/.test(request.url)) {
		request.params.token = token;
	}
};

/**
 * Should intercept, only 401 to refresh token
 * @param {Error object} error
 */
export const shouldIntercept = error => {
	try {
		if (ignoreUrls.includes(error.config.url)) return false;

		return error?.response?.status === 401;
	} catch (e) {
		return false;
	}
};

/**
 * Token data { token: {}, user: {} }
 * @param {Token onject token and user} tokenData
 * @param {Axios client object} axiosClient
 */
export const setTokenData = (tokenData = {}) => {
	setCompanyNames(tokenData);

	setToken(tokenData.token);
	setUser(tokenData.user);
};

/**
 * Handle token refresh call to the server
 */
export const handleTokenRefresh = async () => {
	const token = getToken();

	try {
		if (msalConfig.auth.authority.startsWith(token?.authority)) {
			const customApiToken = await getMsalTokenAsync();

			const user = {
				email: customApiToken.account?.idTokenClaims?.preferred_username,
				name: customApiToken.account?.idTokenClaims?.name,
				company: null,
				companies: [],
			};

			return { token: customApiToken, user };
		}
	} catch (err) {
		// let response to cause axios exception -> interceptor works correctly
		console.error(err.message);
	}

	const refreshToken = getRefreshToken(); // get token from local storage
	const accessToken = getAccessToken();

	const response = await axios.post(refreshUri, { refreshToken, token: accessToken });

	return response.data;
};

export const setCompanyNames = ({ user }) => {
	const currentUser = getUser();

	if (!currentUser || !user) return;

	for (let i = 0; i < user.companies?.length; i++) {
		if (user.companies[i].CompanyName != null) continue;

		const companyResponse = currentUser.companies.find(item => item.CompanyId === user.companies[i].CompanyId);

		if (!companyResponse) continue;

		user.companies[i].CompanyName = companyResponse.CompanyName;
	}

	setUser(user);
};
