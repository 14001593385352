import * as yup from 'yup';

export const getSchema = intl => {
	const today = new Date();
	const tomorrow = new Date();
	tomorrow.setDate(today.getDate() + 1);

	return yup.object().shape({
		expires: yup
			.date()
			.min(tomorrow, intl.formatMessage({ id: 'stakeholder.form.expires.error' }))
			.typeError(intl.formatMessage({ id: 'stakeholder.form.expires.error' }))
			.nullable()
			.default(null),
		recipients: yup
			.array()
			.required()
			.min(1, intl.formatMessage({ id: 'atleast.one.recipient.required' }))
			.of(
				yup
					.string()
					.email(`${intl.formatMessage({ id: 'stakeholder.form.email.error' })}`)
					.required(`${intl.formatMessage({ id: 'user.form.email' })} ${intl.formatMessage({ id: 'message.isRequired' })}`)
			),
		project: yup.string().when('sharedData', {
			is: sharedData => sharedData?.includes('Project'),
			then: schema =>
				schema.required(
					`${intl.formatMessage({ id: 'stakeholder.form.project' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
				),
		}),
	});
};
