import { memo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import PageSpinner from 'components/Spinner/PageSpinner';
import Subtitle from 'components/Titles/Subtitle';
import { removeInstrumentMarked, setInstrumentMarked } from 'containers/Instruments/slices';
import GroupedInstruments from '../GroupedInstruments';
import NoCategoryInstrument from '../NoCategoryInstrument';

const arrayFilter = (array, filter) => {
	let values = [];
	for (let i = 0; i < array.length; i++) {
		const ret = filter(array[i]);
		if (ret) values.push(ret);
	}

	return values;
};

const getfilteredCategoryInstrumentList = (categories, instruments) => {
	if (!categories) return [];

	return arrayFilter(categories, categoryItem => {
		const filteredInstruments = instruments?.filter(grantedInstrument =>
			categoryItem.instruments.some(categoryInstrument => grantedInstrument.id === categoryInstrument.instrumentId)
		);

		const limitLevel = parseInt(categoryItem.level) ?? 1;
		const handledInstruments = filteredInstruments?.filter(({ level }) => level >= limitLevel);

		if (!handledInstruments || handledInstruments.length === 0) return null;

		const retItem = Object.assign({}, categoryItem, { filteredInstruments: handledInstruments });

		return retItem;
	});
};

const InstrumentCategoryList = ({
	loading = false,
	categories,
	instruments,
	company,
	profile,
	currentInstrumentId,
	currentCategory,
	scrollToTimeline,
	openProfileModal,
	readonly,
	hideTitle,
	sx = {},
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const changeMarked = (id, isMarked = false) => (isMarked ? dispatch(removeInstrumentMarked(id)) : dispatch(setInstrumentMarked(id)));

	const kanban = useSelector(state => state.kanban);
	const projects = kanban?.projects?.data;

	const businessPlan = useSelector(state => state.businessPlan);
	const costs = businessPlan?.main?.data?.costs;

	const markedInstruments = instruments?.filter(item => item.isMarked);

	const filtered = getfilteredCategoryInstrumentList(categories, instruments);

	const suitableCategoryId = 'suitable';
	const filteredSuitable = filtered?.reduce((acc, item) => {
		return item.categoryId === suitableCategoryId ? item : acc;
	}, {});

	const suitableInstruments = filteredSuitable?.instruments?.filter(instrument => {
		const { id, projectAimArr, projectObjectsArr } = instrument;
		const { categoryId } = filteredSuitable;

		const projectTags = [].concat(projectAimArr ?? [], projectObjectsArr ?? []);

		const innovationInstumentId = 'ebded713-8e2e-48a5-9b81-85a49792ed3a';
		const tempoInstrumentId = '5d4d04d2-4a9a-4b86-a524-3ffe5c266fb9';

		const innovationInstumentIsUsed = projects && projects?.some(({ instrumentId, isUsed }) => instrumentId === innovationInstumentId && isUsed);
		const tempoInstumentIsUsed = projects && projects?.some(({ instrumentId, isUsed }) => instrumentId === tempoInstrumentId && isUsed);

		// eslint-disable-next-line array-callback-return
		const matchCost = projectTags?.some(item => {
			if (categoryId === suitableCategoryId) {
				return costs?.some(cost => cost?.costCategory?.toLowerCase() === item.toLowerCase());
			}
		});

		const renderSuitableInstrument = costs?.length === 0 || (costs?.length > 0 && matchCost) ? true : false;

		if (
			categoryId === suitableCategoryId &&
			(!renderSuitableInstrument ||
				(id === innovationInstumentId && (innovationInstumentIsUsed || tempoInstumentIsUsed)) ||
				(id === tempoInstrumentId && tempoInstumentIsUsed))
		)
			return null;

		return instrument;
	});

	if (loading) return <PageSpinner />;

	if (filtered.length === 0 && markedInstruments?.length === 0) {
		return <NoCategoryInstrument profile={profile} action={openProfileModal} />;
	}

	return (
		<Box sx={{ ...sx }}>
			{!hideTitle && <Subtitle>{intl.formatMessage({ id: 'instrumentlist.category.title' })}</Subtitle>}
			<GroupedInstruments
				title={intl.formatMessage({ id: 'instrumentlist.marked' })}
				company={company}
				instruments={instruments}
				filteredInstruments={markedInstruments}
				changeMarked={changeMarked}
				profile={profile}
				currentInstrumentId={currentCategory === 'marked' ? currentInstrumentId : ''}
				category='marked'
				costs={costs}
				scrollToTimeline={scrollToTimeline}
				readonly={readonly}
			/>
			{filtered.map(({ categoryId, category, description, filteredInstruments }) => {
				const currentInstrumentOpen = currentCategory === category ? currentInstrumentId : '';

				return (
					<GroupedInstruments
						key={category}
						categoryId={categoryId}
						title={category}
						description={description}
						company={company}
						instruments={instruments}
						filteredInstruments={categoryId === suitableCategoryId ? suitableInstruments : filteredInstruments}
						changeMarked={changeMarked}
						profile={profile}
						currentInstrumentId={currentInstrumentOpen}
						category={category}
						costs={costs}
						scrollToTimeline={scrollToTimeline}
						readonly={readonly}
					/>
				);
			})}
		</Box>
	);
};

export default memo(InstrumentCategoryList);
