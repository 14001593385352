import * as React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { isArray, isNil } from 'lodash';
import { differenceInMinutes } from 'date-fns';

type Props<C extends React.ElementType> = TypographyProps<C, { component?: C }> & {
	createDate?: string | null;
	updateDate?: string | null;
};

function RfpLastUpdatedTypography<C extends React.ElementType>({ createDate, updateDate, sx, ...otherProps }: Props<C>) {
	const minutes = React.useMemo(() => {
		let difference = 0;
		if (!isNil(createDate)) {
			difference = differenceInMinutes(new Date(createDate), new Date());
		}
		if (!isNil(updateDate)) {
			difference = differenceInMinutes(new Date(updateDate), new Date());
		}
		return difference;
	}, [createDate, updateDate]);

	if (isNil(createDate) && isNil(updateDate)) {
		return null;
	}

	return (
		<Typography {...otherProps} variant='body2' sx={[{ color: 'text.muted' }, ...(isArray(sx) ? sx : [sx])]}>
			<Box component='span' sx={{ mr: 0.5 }}>
				<FormattedMessage id='rfpLastUpdated.label' />
			</Box>
			<FormattedRelativeTime value={minutes} unit='minute' numeric='auto' updateIntervalInSeconds={60} />
		</Typography>
	);
}

export default RfpLastUpdatedTypography;
