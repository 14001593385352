import React from 'react';

import List from '@mui/material/List';

import InstrumentItem from 'containers/Instruments/InstrumentList/InstrumentItem';

const AllInstruments = ({
	company,
	instruments = [],
	filteredInstruments,
	newInstruments,
	improvedInstruments,
	currentInstrumentId,
	openInstrumentInDrawer,
	scrollToTimeline,
	scrollToCurrentInstrument,
	changeMarked,
	profile,
	costs = [],
	readonly,
}) => {
	const allInstruments = filteredInstruments;

	if (instruments?.length === 0) return null;

	const sortedInstruments = instruments
		.sort((a, b) => improvedInstruments.includes(b.id) - improvedInstruments.includes(a.id))
		.sort((a, b) => newInstruments.includes(b.id) - newInstruments.includes(a.id))
		.sort((a, b) => b.level - a.level);

	const selectInstrument = id => {
		return allInstruments?.find(item => item.id === id);
	};

	return (
		<List sx={{ mt: 2 }}>
			{sortedInstruments.map(instrument => (
				<InstrumentItem
					key={instrument.id}
					company={company}
					instrument={instrument}
					instruments={filteredInstruments}
					newInstruments={newInstruments}
					improvedInstruments={improvedInstruments}
					currentInstrumentId={currentInstrumentId}
					useDrawer={openInstrumentInDrawer}
					scrollToTimeline={scrollToTimeline}
					scrollToCurrentInstrument={scrollToCurrentInstrument}
					changeMarked={changeMarked}
					profile={profile}
					costs={costs}
					selectInstrument={selectInstrument}
					readonly={readonly}
				/>
			))}
		</List>
	);
};

export default AllInstruments;
