import { isEmpty, isNil, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import { Alert, AlertTitle, Box, BoxProps, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import { RfpAttachment } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';
import bytesToSize from 'utils/converters/bytesToSize';

import { useLazyFetchRfpProposalAttachmentFileQuery } from 'containers/Marketplace/rfpCommonApi';

type Props = BoxProps & {
	requestId: string | null | undefined;
	proposalId: string | null | undefined;
	createdByMe: boolean;
	attachments: RfpAttachment[] | null | undefined;
};

const ReadRfpProposalAttachmentList: React.FC<Props> = ({ requestId, proposalId, createdByMe, attachments, ...otherProps }) => {
	const downloadLink = React.useRef<HTMLLinkElement>(null);

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const [fetchRfpProposalAttachmentFile, { isError: isRfpProposalAttachmentFileError }] = useLazyFetchRfpProposalAttachmentFileQuery();

	return (
		<Box {...otherProps}>
			<Typography variant='h4'>
				<FormattedMessage id='rfpProposal.attachments.label' />
			</Typography>
			{isRfpProposalAttachmentFileError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpProposalAttachmentFile.error.message' />
				</Alert>
			)}
			<List>
				{isEmpty(attachments) && (
					<Typography variant='lightText'>
						<FormattedMessage id='rfpAttachments.empty' />
					</Typography>
				)}
				{map(attachments, rfpAttachment => (
					<ListItem key={rfpAttachment.attachmentId} disablePadding>
						<ListItemButton
							onClick={async () => {
								const { data: rfpAttachmentFile } = await fetchRfpProposalAttachmentFile({
									companyId,
									requestId,
									proposalId,
									attachmentId: rfpAttachment.attachmentId,
									published: !createdByMe,
								});
								if (!isNil(rfpAttachmentFile)) {
									const downloadUrl = window.URL.createObjectURL(rfpAttachmentFile);
									const fileName = rfpAttachment.fileName ?? 'attachment';
									if (!isNil(downloadLink.current)) {
										downloadLink.current.href = downloadUrl;
										downloadLink.current.setAttribute('download', fileName);
										downloadLink.current.click();
									}
									window.URL.revokeObjectURL(downloadUrl);
								}
							}}
						>
							<ListItemIcon>
								<FileCopyIcon />
							</ListItemIcon>
							<ListItemText primary={rfpAttachment.name} secondary={bytesToSize(rfpAttachment.length)} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Box component='a' ref={downloadLink} sx={{ display: 'none' }} />
		</Box>
	);
};

export default ReadRfpProposalAttachmentList;
