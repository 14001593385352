import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

const Progress = props => {
    const value = props.value;
    const color = value === 100 ? 'success' : value < 50 ? 'error' : value < 100 && 'warning';

    return (
        <Stack direction='row' spacing={1} alignItems='center' /* sx={{ '& p': { color: `${color}.main` } }} */>
            <LinearProgress variant='determinate' color={color} sx={{ minWidth: { xs: '5rem', sm: '10rem' } }} {...props} />
            <Typography sx={{ color: 'secondary.grey600' }}>{`${value}% valmis`}</Typography>
        </Stack>
    );
};

export default Progress;