import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormControlWithLabel from 'components/Form/FormControlWithLabel';

import { getFlagBitwiseValuesFromInteger } from 'utils/misc/bitwiseOperations';
import scenarioValues from 'utils/mock/scenarioValues.json';

const InstrumentLimiters = ({ control, errors }) => {
	const intl = useIntl();

	const { fields, remove } = useFieldArray({
		control,
		name: 'instrument.limitators',
	});

	const getScenarioValuesByType = (value, item) => {
		const scenarioItemValues = scenarioValues.find(scenarioValue => {
			return scenarioValue.type === item.compareProperty;
		});

		if (!scenarioItemValues) return null;

		let values = scenarioItemValues.values;

		const items = values.map((scenarioItemvalue, index) => {
			const text = index === 0
				? `- ${intl.formatMessage({ id: 'instrument.radar.choice' })} -`
				: intl.formatMessage({ id: `instrument.radar.choice.${item.compareProperty}.${index}` });

			return (
				<MenuItem key={item.compareProperty + index} value={scenarioItemvalue.value}>
					<Checkbox color='primary' checked={value.includes(scenarioItemvalue.value)} />
					<ListItemText primary={text} />
				</MenuItem>
			);
		});

		return items;
	};

	for (let x in fields) {
		let field = fields[x];

		if (field.hideItem !== true && field.compareProperty !== '') continue;

		remove(x);
	}

	return (
		<Grid container spacing={2}>
			{fields.map((item, index) => {
				const id = `instrument.radar.choice.${item.compareProperty}`;

				return (
					<Grid item key={item.id} xs={12} sm={6} md={3}>
						<FormControlWithLabel
							error={errors?.instrument?.provider}
							label={intl.formatMessage({ id: `instrument.radar.choice.${item.compareProperty}` })}
						>
							<Controller
								control={control}
								name={`limitators.${item.compareProperty}`}
								defaultValue={getFlagBitwiseValuesFromInteger(item.requiredValue)} // get flags from integer
								rules={{
									required: `${intl.formatMessage({ id: id })} ${intl.formatMessage({
										id: 'message.isRequired',
									})}`,
								}}
								render={({ field: { onChange, value, } }) => {
									const hasZero = value.findIndex(item => item === 0);
									const hasOthers = value.findIndex(item => item !== 0);

									if (hasZero > -1 && hasOthers > -1) {
										if (hasZero === 0) {
											value.splice(0, 1);
										}

										else value = [0];
									}

									return (
										<TextField
											select
											fullWidth
											size='small'
											id={`instrument.radar.choice.${item.compareProperty}`}
											SelectProps={{
												multiple: true,
												value: value,
												renderValue: selected => {
													selected.sort(function (a, b) {
														return a - b;
													});

													const arrIndex = [0, 1, 2, 4, 8, 16];
													const translated = selected.map(i => {
														if (i === 0) return `- ${intl.formatMessage({ id: 'instrument.radar.choice' })} -`;

														return intl.formatMessage({ id: `instrument.radar.choice.${item.compareProperty}.${arrIndex.indexOf(i)}` });
													});

													return translated.join(', ');
												},
												onChange: (value, selected) => {
													if (selected.props.value === 0) {
														onChange(Object.assign(value, { target: { value: [0] }, }));

														return;
													}

													onChange(value);
												},
											}}
										>
											{getScenarioValuesByType(value, item)}
										</TextField>
									);
								}}
							/>
							{errors && (
								<Typography
									component='p'
									color='error'
									sx={{
										marginLeft: '0.5rem',
									}}
								>
									{errors?.limitators && errors?.limitators[index]
										? <span>{intl.formatMessage({ id: `instrument.radar.choice.${item.compareProperty}` })} {intl.formatMessage({ id: 'common.required' })}</span>
										: null
									}
								</Typography>
							)}
						</FormControlWithLabel>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default InstrumentLimiters;
