import React, { useState } from 'react';
import { Controller /* , useFieldArray */ } from 'react-hook-form';

import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';

import FormLabel from 'components/Form/FormLabel';
import FormError from 'components/Form/FormError';

type Props = {
	name: string;
	title: string;
	defaultValue: any;
	error: string;
	control: any;
	options: Array<SelectOption>;
	disabled: boolean;
};

export type SelectOption = {
	value: string;
	label: string;
};

const FormControlledCheckbox: React.FC<Props> = ({ name, title, defaultValue, error, control, options, disabled }) => {
	const [selected, setSelected] = useState(defaultValue ?? []);

	const handleChange = (event: any, onChange: any, checked: boolean) => {
		const {
			target: { value },
		} = event;

		let valueArray = [...selected];
		if (checked) {
			valueArray = selected.filter((a: string) => a !== value);
		} else {
			valueArray.push(value);
		}

		onChange(Object.assign(event, { target: { value: valueArray } }));

		setSelected(valueArray);
	};

	return (
		<FormControl required component='fieldset'>
			<FormLabel>{title}</FormLabel>
			<FormGroup>
				{options.map((selectItem: any) => {
					const checked = selected.indexOf(selectItem.value) > -1;

					return (
						<Controller
							key={selectItem.value}
							name={name}
							control={control}
							defaultValue={defaultValue}
							render={({ field: { onChange, value } }) => {
								return (
									<FormControlLabel
										control={
											<Checkbox
												disabled={disabled}
												onChange={event => handleChange(event, onChange, checked)}
												checked={checked}
												value={selectItem.value}
											/>
										}
										label={selectItem.label}
									/>
								);
							}}
						/>
					);
				})}
			</FormGroup>
			<FormError error={error} />
		</FormControl>
	);
};

export default FormControlledCheckbox;
