import { Link, useLocation } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { makeStyles, } from '@mui/styles';

import { Button } from '@mui/material';
import FooterLabel from 'components/FooterLabel';
import { FormattedMessage } from 'react-intl';
import getCompanyLink from 'utils/auth/getCompanyLink';

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	divider: {
		backgroundColor: theme.palette.primary.main,
		margin: theme.spacing(0.5),
	},
}));

const Footer = () => {
	const classes = useStyles();
	const location = useLocation();

	return (
		<div className={classes.root}>
			<Grid container justifyContent='center' alignItems='center'>
				<Button
					style={{
						display: 'flex',
						textDecoration: 'none',
					}}
					component={Link}
					to={getCompanyLink('/agreement', location)}
				>
					<FormattedMessage id='terms.and.conditios.link' />
				</Button>
				<Divider orientation='vertical' flexItem className={classes.divider} />
				<Button
					style={{
						display: 'flex',
						textDecoration: 'none',
					}}
					component={Link}
					to={getCompanyLink('/pricing', location)}
				>
					<FormattedMessage id='terms.and.conditios.pricing.link' />
				</Button>
			</Grid>
			<FooterLabel />
		</div>
	);
};

export default Footer;
