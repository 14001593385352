import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import MainTitle from 'components/Titles/MainTitle';
import AllInstruments from './AllInstruments';
import FilterInput from './FilterInput';
import NoInstruments from './NoInstruments';
import QuickCrop from './QuickCrop';
import SuitableInstrumentsModal from './SuitableInstrumentsPanel';

import { usePollMessagesQuery } from 'containers/Messages/api';
import { getCompanyId } from 'utils/auth/company';
import { scrollToCurrentInstrument } from './utils';

const useStyles = makeStyles(theme => ({
	quickCropContainer: {
		flexWrap: 'nowrap',
		paddingTop: theme.spacing(2),
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 700,
	},
	subtitle: {
		paddingTop: theme.spacing(1),
	},
	button: {
		//textTransform: 'none',
		borderRadius: '1rem',
		color: theme.palette.primary.main,
	},
	noInstruments: {
		fontSize: '1rem',
		color: theme.palette.primary.grey,
	},
}));

const InstrumentList = ({
	company,
	filteredInstruments,
	allInstruments,
	hasInstruments = true,
	currentInstrumentId,
	openInstrumentInDrawer,
	pathname,
	scrollToTimeline,
	openProfileModal,
	phrase,
	filterInstruments,
	changeMarked,
	profile = false,
	costs = [],
	readonly,
}) => {
	const classes = useStyles();
	const intl = useIntl();
	const filterModalRef = useRef();

	const companyId = getCompanyId();

	const [open, setOpen] = useState(false);
	const openModal = () => setOpen(true);
	const closeModal = () => setOpen(false);

	const levels = allInstruments?.map(({ level }) => level);
	const uniqueLevels = levels ? [...new Set(levels)] : [];

	const types = allInstruments?.map(({ type }) => type);
	const uniqueTypes = types ? [...new Set(types)] : [];

	const providers = allInstruments?.map(({ provider }) => provider);
	const uniqueProviders = providers ? [...new Set(providers)] : [];

	const projectAims = allInstruments?.map(({ projectAimArr }) => projectAimArr);
	const uniqueProjectAims = projectAims ? [...new Set([].concat(...projectAims))] : [];

	const projectObjects = allInstruments?.map(({ projectObjectsArr }) => projectObjectsArr);
	const uniqueProjectObjects = projectObjects ? [...new Set([].concat(...projectObjects))] : [];

	const projectValues = allInstruments?.map(({ projectValueArr }) => projectValueArr);
	const uniqueProjectValues = projectValues ? [...new Set([].concat(...projectValues))] : [];

	const [checked, setChecked] = useState([
		...uniqueLevels,
		...uniqueTypes,
		...uniqueProviders,
		...uniqueProjectAims,
		...uniqueProjectObjects,
		...uniqueProjectValues,
	]);

	const [allLevels, setAllLevels] = useState(true);
	const [allTypes, setAllTypes] = useState(true);
	const [allProviders, setAllProviders] = useState(true);
	const [allProjectAims, setAllProjectAims] = useState(true);
	const [allProjectObjects, setAllProjectObjects] = useState(true);
	const [allProjectValues, setAllProjectValues] = useState(true);

	const [clickedLevels, setClickedLevels] = useState(uniqueLevels);
	const [clickedTypes, setClickedTypes] = useState(uniqueTypes);

	const renderInstruments = filteredInstruments?.filter(
		({ level, type, provider, projectAimArr, projectObjectsArr, projectValueArr }) => {
			const stringChecker = (str, target) => target?.includes(str);
			const arrChecker = (arr, target) => {
				return target?.some(v => arr.includes(v));
			};

			let data =
				stringChecker(level, checked) &&
				stringChecker(type, checked) &&
				stringChecker(provider, checked) &&
				(allProjectAims || arrChecker(checked, projectAimArr)) &&
				(allProjectObjects || arrChecker(checked, projectObjectsArr)) &&
				(allProjectValues || arrChecker(checked, projectValueArr));

			return data;
		}
	);

	const filterList = (values, uniqueItems) => {
		if (values === undefined) return;

		const checkedWithoutUniqueItems = checked?.filter(item => !uniqueItems?.includes(item));
		const newChecked = [...checkedWithoutUniqueItems, ...values];

		setChecked(newChecked);
	};

	const { data: messages } = usePollMessagesQuery('messages', {
		skip: !companyId,
	});
	const customDataString = messages && messages[0]?.customData;

	let customDataArray = [];
	if (customDataString) {
		try {
			customDataArray = JSON.parse(customDataString);
		} catch (error) {
			// ignore error
		}
	}

	const newInstruments = customDataArray?.filter(({ IsNew }) => IsNew)?.map(({ InstrumentId }) => InstrumentId);
	const improvedInstruments = customDataArray?.filter(({ IsNew }) => IsNew === false)?.map(({ InstrumentId }) => InstrumentId);

	return (
		<div>
			<div>
				{pathname === '/instruments' || pathname === `/company/${companyId}/instruments` ? (
					<MainTitle>{intl.formatMessage({ id: 'fundinginfo.title' })}</MainTitle>
				) : (
					<Typography component='p' color='primary' className={classes.title}>
						{intl.formatMessage({ id: 'fundinginfo.title' })}
					</Typography>
				)}
				{(renderInstruments?.length > 0 || phrase) && (
					<Typography component='p' color='primary' className={classes.subtitle}>
						{intl.formatMessage({ id: 'fundinginfo.subtitle' })}
					</Typography>
				)}
			</div>
			{hasInstruments && (
				<Stack direction='row' justifyContent='space-between' alignItems='flex-start' sx={{ mt: 0.5 }}>
					<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
						<QuickCrop
							filterList={filterList}
							uniqueLevels={uniqueLevels}
							allLevels={allLevels}
							setAllLevels={setAllLevels}
							clickedLevels={clickedLevels}
							setClickedLevels={setClickedLevels}
							uniqueTypes={uniqueTypes}
							allTypes={allTypes}
							setAllTypes={setAllTypes}
							clickedTypes={clickedTypes}
							setClickedTypes={setClickedTypes}
						/>
					</Box>
					<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: { xs: '100%', sm: 'auto' } }}>
						<FilterInput value={phrase} onChange={filterInstruments} />
						{filteredInstruments?.length > 0 && (
							<IconButton onClick={openModal}>
								<FilterListIcon
									color={
										allLevels && allTypes && allProviders && allProjectAims && allProjectObjects && allProjectValues
											? 'default'
											: 'primary'
									}
								/>
							</IconButton>
						)}
					</Stack>
				</Stack>
			)}
			<AllInstruments
				company={company}
				instruments={renderInstruments}
				filteredInstruments={allInstruments}
				newInstruments={newInstruments}
				improvedInstruments={improvedInstruments}
				currentInstrumentId={currentInstrumentId}
				openInstrumentInDrawer={openInstrumentInDrawer}
				scrollToTimeline={scrollToTimeline}
				scrollToCurrentInstrument={scrollToCurrentInstrument}
				changeMarked={changeMarked}
				profile={profile}
				costs={costs}
				readonly={readonly}
			/>
			{renderInstruments?.length === 0 && (
				<NoInstruments
					hasInstruments={hasInstruments}
					renderInstruments={renderInstruments}
					phrase={phrase}
					action={openProfileModal}
					profile={profile}
					clearFilters={() => filterModalRef.current.clearFilters()}
				/>
			)}
			<SuitableInstrumentsModal
				ref={filterModalRef}
				open={open}
				close={closeModal}
				filterList={filterList}
				uniqueLevels={uniqueLevels}
				allLevels={allLevels}
				setAllLevels={setAllLevels}
				clickedLevels={clickedLevels}
				setClickedLevels={setClickedLevels}
				uniqueTypes={uniqueTypes}
				allTypes={allTypes}
				setAllTypes={setAllTypes}
				clickedTypes={clickedTypes}
				setClickedTypes={setClickedTypes}
				uniqueProviders={uniqueProviders}
				allProviders={allProviders}
				setAllProviders={setAllProviders}
				uniqueProjectAims={uniqueProjectAims}
				allProjectAims={allProjectAims}
				setAllProjectAims={setAllProjectAims}
				uniqueProjectObjects={uniqueProjectObjects}
				allProjectObjects={allProjectObjects}
				setAllProjectObjects={setAllProjectObjects}
				uniqueProjectValues={uniqueProjectValues}
				allProjectValues={allProjectValues}
				setAllProjectValues={setAllProjectValues}
				setChecked={setChecked}
			/>
		</div>
	);
};

export default InstrumentList;
