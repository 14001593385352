import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
	root: {
		padding: 0,
		marginBottom: '1rem',
		'&:last-child': {
			marginBottom: 0,
		},
	},
	accordion: {
		boxShadow: 'none',
		width: '100%',
		flexDirection: 'column',
	},
	accordionSummary: {
		backgroundColor: theme.palette.primary.white,
		margin: 0,
		'& .MuiAccordionSummary-content': {
			margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
		},
		'&.Mui-expanded': {
			minHeight: theme.spacing(6),
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {},
		'& .MuiAccordionSummary-expandIconWrapper': {},
	},
	expandIcon: {
		color: theme.palette.primary.main,
		'@media print': {
			display: 'none',
		},
	},
	lineBetweenSummaryAndDetails: {
		height: theme.spacing(0.25),
		backgroundColor: theme.palette.primary.secondary,
	},
	accordionDetails: {
		maxWidth: '100%',
		backgroundColor: theme.palette.primary.white,
		boxShadow: '0px 1px 8px 1px rgba(72, 46, 81, .2)',
	},
	detailsContainer: {
		display: 'flex',
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			paddingBottom: theme.spacing(2),
		},
	},
}));

const AccordionItem: React.FC<any> = ({ title, tags, children, subtitle, note, after, maxHeight = '50rem', color }) => {
	const classes = useStyles();
	const theme: any = useTheme();

	const [open, setOpen] = useState(false);

	const smdDown = useMediaQuery(theme.breakpoints.down('smd'));
	const down1530 = useMediaQuery(theme.breakpoints.down(1530));
	const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));

	const display = { xs: 'none', lg: 'block', xl: 'block' };

	return (
		<Accordion
			square
			expanded={open}
			onChange={() => setOpen(!open)}
			TransitionProps={{ unmountOnExit: true }}
			className={classes.accordion}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				className={classes.accordionSummary}
				sx={{
					background: `linear-gradient(to right, ${color ? color : theme.palette.primary.main} 0% 8px, ${
						theme.palette.primary.white
					} 8px 100%)`,
					boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 6px',
				}}
			>
				<Stack flexDirection='row' justifyContent='space-between' alignItems='center' flex={1} spacing={1} useFlexGap>
					<Box flex={1}>
						<Stack flexDirection={{ xs: 'column', md: 'column' }} flexWrap='wrap' useFlexGap>
							<Typography component='div'>{title}</Typography>
							<Typography component='div'>{subtitle}</Typography>
							{tags?.length > 0 && (
								<Divider
									orientation='vertical'
									flexItem
									sx={{ display: display, mr: 1.5, mt: 0.5, mb: 0.5, borderColor: 'secondary.border' }}
								/>
							)}
						</Stack>
					</Box>
					<Box>
						<Stack
							flexDirection={{ xs: 'column', md: 'row' }}
							justifyContent='space-between'
							alignItems={{ xs: 'center', md: 'flex-end' }}
							spacing={1}
							useFlexGap
						>
							{note}
						</Stack>
					</Box>
				</Stack>
			</AccordionSummary>
			<div className={classes.lineBetweenSummaryAndDetails} />
			<AccordionDetails sx={{ maxHeight: maxHeight, overflow: 'auto' }} classes={{ root: classes.accordionDetails }}>
				<Stack
					className={classes.detailsContainer}
					sx={{
						flexDirection: smdDown || down1530 ? 'column' : 'row',
						alignItems: smdDown || down1530 ? 'flex-start' : 'stretch',
					}}
				>
					{children}
				</Stack>
			</AccordionDetails>
			{after}
		</Accordion>
	);
};

export default AccordionItem;
