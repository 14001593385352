import * as Yup from 'yup';

/**
 * Dynamically generated validation schema. Field array is parsed to Yup validation object
 *
 * Phases:
 * - go through the field validations array by field identification. If Validation array exists reduce yup validation by validate fields
 * - find validation object params from params array for example min value validation
 *          validations: [ "min" ], which is yup min function
 *          params: [ "min": [10, "message"] ] minumum length 10 and error message is "message"
 *      OR:
 *          validation: ["required"]
 *          params: ["reguired": "message"] string value is also accepted instead of array, dependts of yup type
 *
 *
 * @param {array} fields are fields from template
 * @returns
 */
export const createValidationSchema = (fields = []) => {
	const createFieldValidationSchema = (fields = []) => {
		const ObjectSchema = fields.reduce((schema, field) => {
			if (field?.validations?.length) {
				schema[field.id] = field.validations.reduce(
					(yup, type) => {
						if (field.params?.[type]) {
							let params = Array.isArray(field.params[type]) ? [...field.params[type]] : [field.params[type]];

							if (type === 'max' && Array.isArray(params) && Number.isInteger(params[0])) {
								params[0] = 30000; // extend to 30000 for every field max value
							} else if (type === 'max' && !Array.isArray(params) && Number.isInteger(params)) {
								params = 30000;
							}

							yup = yup[type](...params);
						} else {
							yup = yup[type]();
						}

						return yup;
					},
					{ ...Yup }
				);
			}

			return schema;
		}, {});

		return Yup.object().shape({ ...ObjectSchema });
	};

	const ret = {};

	fields.reduce((schema, field) => {
		if (field.type === 'section') {
			const fields = createFieldValidationSchema(field.fields);
			try {
				schema = {};
				schema[field.id] = fields;

				ret[field.id] = fields;

				return ret;
			} catch (error) {
				// no error handling, ignore
			}
		}
	}, {});

	const retSchema = Yup.object().shape({
		dynamic: Yup.object().shape({
			...ret,
		}),
	});

	return retSchema;
};

export default { createValidationSchema };
