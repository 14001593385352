import React from 'react';
import { Helmet, } from 'react-helmet-async';

import CompanyList from 'containers/Admin/UsersCompany/CompanyList';

const CompanyListPage = () => {
    return (
        <>
            <Helmet>
                <title>GrantedAI - company list</title>
            </Helmet>
            <CompanyList />
        </>
    );
};

export default CompanyListPage;