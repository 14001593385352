import emailToName from 'email-to-name';

export const convertEmailToName = email => {
    if (!email) return '';

    let name = emailToName.process(email);

    if (name.includes('-')) {
        const index = name.indexOf('-');

        if (name.charAt(index + 1) !== name.charAt(index + 1).toUpperCase()) {
            name = name.slice(0, index + 1) + name.charAt(index + 1).toUpperCase() + name.slice(index + 2);
        }
    }

    return name;
};
