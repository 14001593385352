import { isNil } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Alert, AlertTitle, Box, Button, Link, Skeleton, Stack, Typography } from '@mui/material';

import { openDialog } from '../dialogSlice';
import { useGetPublicProfileByidQuery } from '../rfpProfileApi';

import BlobDownloader from '../attachment/BlobDownloader';

type Props = {
	companyId: string | null | undefined;
	print?: boolean;
};

const CompanyProfileBadge: React.FC<Props> = ({ companyId, print }) => {
	const dispatch = useDispatch();

	const { data: rfpProfile, isLoading, isError } = useGetPublicProfileByidQuery({ companyId: companyId }, { skip: isNil(companyId) });

	if (isLoading) return <Skeleton height={90} width={90} />;

	if (!rfpProfile?.companyName) {
		return (
			<Typography variant='h4'>
				<FormattedMessage id='profile.badge.title' />
			</Typography>
		);
	}

	return (
		<>
			<Typography variant='h4'>
				<FormattedMessage id='profile.badge.title' />
			</Typography>
			{isError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpProfile.error.message' />
				</Alert>
			)}
			<Stack direction='row' spacing={2}>
				<Box>
					<BlobDownloader url={rfpProfile?.logo}>
						{content => (
							<Box
								component='img'
								src={content}
								sx={{
									height: 90,
								}}
							/>
						)}
					</BlobDownloader>
				</Box>
				<Stack
					direction='column'
					justifyContent='space-between'
					sx={{
						maxHeight: 90,
						'@media print': {
							maxHeight: '100%',
						},
					}}
				>
					<Typography sx={{ fontWeight: 600 }}>{rfpProfile?.companyName}</Typography>
					{print && (
						<>
							<Typography>{rfpProfile.companyIngress}</Typography>
							<Typography variant='subtitle2'>
								<FormattedMessage id='rfpProfile.companyIngress.label' />
							</Typography>
							<Typography>{rfpProfile.generalDescription}</Typography>
						</>
					)}
					<Typography>
						<Link target='_blank' href={rfpProfile?.companySiteUrl ? rfpProfile?.companySiteUrl : undefined}>
							{rfpProfile?.companySiteUrl}
						</Link>
					</Typography>
					<Button
						color='neutral'
						size='small'
						variant='outlined'
						onClick={() => dispatch(openDialog({ name: 'ReadRfpProfileDialog', data: { companyId } }))}
					>
						<FormattedMessage id='marketplace.profile.open' />
					</Button>
				</Stack>
			</Stack>
		</>
	);
};

export default CompanyProfileBadge;
