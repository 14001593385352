import ContentContainer from 'components/Containers/ContentContainer';
import { useFetchInstumentTemplateQuery } from 'containers/Projects/projectRTKApi';
import { Instrument } from 'types/dataTypes';
import InstrumentTemplatesForm from './InstrumentTemplatesForm';

const InstrumentTemplatesWrapper = ({
	instruments,
	instrument,
	templateId,
	handleTemplateSubmit,
}: {
	instruments: Instrument[];
	instrument: Instrument;
	templateId: string;
	handleTemplateSubmit: any;
}) => {
	const { data, isLoading } = useFetchInstumentTemplateQuery(
		{ instrumentId: templateId ?? instrument.id },
		{ skip: !templateId && !instrument?.id }
	);

	if (!data || isLoading) return null;

	const templateInstrument = instruments?.find(instrument => instrument.id === templateId);

	return (
		// @ts-ignore
		<ContentContainer>
			<InstrumentTemplatesForm
				data={Object.assign({}, data, {
					instrumentId: templateInstrument?.id ?? instrument.id,
					provider: templateInstrument?.provider ?? instrument.provider,
					instrumentName: templateInstrument?.name ?? instrument.name,
					templateVersion: data.templateVersion,
				})}
				instrument={instrument}
				handleTemplateSubmit={handleTemplateSubmit}
			/>
		</ContentContainer>
	);
};

export default InstrumentTemplatesWrapper;
