export const mapValuesToTemplate = (objData, template) => {
	for (const value in objData) {
		template = template.replace(`%${value}%`, objData[value]);
	}

	return template;
};

export const resolve = (path, obj) => {
	return path.split('.').reduce(function (prev, curr) {
		return prev ? prev[curr] : null;
	}, obj);
};

export const fetchData = (name, project) => {
	if (project) {
		const values = resolve(name, project);

		return values;
	}
};

export const fetchFromState = (field, state, project) => {
	if (!field.mapping) return '';

	const obj = Object.assign({}, state, { project: project });

	try {
		let val = resolve(field.mapping, obj);
		val = handleMappingWithTemplate(field, val);

		return val;
	} catch (error) {
		return '';
	}
};

export const handleMappingWithTemplate = (field, obj) => {
	if (!field.mappingTemplate) return obj;

	const fields = field.mappingTemplateFields;

	if (Array.isArray(obj)) {
		let mappedString = '';
		for (const item in obj) {
			const values = fields.map(mappedArrayField => {
				return [mappedArrayField, resolve(mappedArrayField, obj[item])];
			});
			const objData = Object.fromEntries(values);

			mappedString += mapValuesToTemplate(objData, field.mappingTemplate);
		}
		return mappedString;
	} else if (typeof obj === 'object') {
		let mappedString = '';

		const values = fields.map(mappedArrayField => {
			return [mappedArrayField, resolve(mappedArrayField, obj)];
		});

		const objData = Object.fromEntries(values);

		mappedString += mapValuesToTemplate(objData, field.mappingTemplate);

		return mappedString;
	}
};

export const controlTypes = {
	text: 'text',
	textbox: 'textbox',
	number: 'number',
	currency: 'currency',
	datetime: 'datetime',
	date: 'date',
	table: 'table',
	section: 'section',
	boolean: 'boolean',
};

export default { mapValuesToTemplate, resolve, fetchFromState, handleMappingWithTemplate, controlTypes, fetchData };
