import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AttachmentList from 'components/AttachmentList';
import Attachments from 'components/Attachments';
import WidgetContainer from 'components/Containers/WidgetContainer';

import { addAttachment, getFile, removeAttachment } from 'containers/Projects/slices';
import { isProjectDraft } from 'containers/Projects/utils';

export default function AttachmentWidget({ projectId, attachments, padding = 0, hideTitle = false }) {
	const { id } = useParams();
	const dispatch = useDispatch();

	const kanban = useSelector(state => state.kanban);
	const projects = kanban.projects;

	const [loading, setLoading] = useState(false);

	const setValue = async (_, files) => {
		setLoading(true);

		const success = await dispatch(addAttachment(projectId, 'project', files));

		setLoading(false);
		if (success) {
			toast.success(<FormattedMessage id='attachments.success' />);
			return;
		}

		toast.error(<FormattedMessage id='attachments.failed' />);
	};

	const attachmentAmount = attachments => {
		if (attachments?.length <= 0) return null;

		return <>{attachments?.length}</>;
	};

	const getAttachmentFile = async attachmentId => {
		return await getFile(attachmentId, isProjectDraft(projects?.data, id));
	};

	const removeFile = async id => {
		const success = await dispatch(removeAttachment(id));

		if (success) {
			toast.success(<FormattedMessage id='attachments.remove.success' />);
		} else {
			toast.error(<FormattedMessage id='attachments.remove.failed' />);
		}
	};

	const AttachmentsTitle = () => {
		if (hideTitle) return null;

		return (
			<>
				<FormattedMessage id='attachments.title' /> {attachmentAmount(attachments)}
			</>
		);
	};

	return (
		<WidgetContainer
			padding={padding}
			sx={{
				height: '100%',
			}}
			title={<AttachmentsTitle />}
			link={<FormattedMessage id='attachments.nolink' />}
		>
			<AttachmentList attachments={attachments} getFile={getAttachmentFile} removeFile={removeFile} />
			<Attachments formLabel={false} setValue={setValue} showFiles={false} loading={loading} />
		</WidgetContainer>
	);
}
