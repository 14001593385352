import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

import ContentContainer from 'components/Containers/ContentContainer';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Button from 'components/Button/Button';
import { CompanyLicensePanel, CompanyDataPanel, CompanyAddressPanel } from './Panels';
import { LicensesTable, CompanyRolesTable } from '../SharedTables';
import LicenseModalForm from './LicenseModalForm';
import UserModalForm from './UserModalForm';
import CompanyUserTable from './CompanyUserTable';

import { getCompanyDetails } from '../slices';
import { getLicenseContantData, saveCompanyLicense, terminateCompanyLicense } from '../licenseSlice';
import { saveCompanyUser, deactivateUser, deleteCompanyRoles } from '../userSlice';

const useStyles = makeStyles(theme => ({
	accordionSummary: {
		margin: 0,
		'& .MuiAccordionSummary-content': {
			margin: '1rem 0.5rem',
		},
		'&.Mui-expanded': {
			minHeight: theme.spacing(6),
		},
	},
	companyName: {
		fontSize: '1.5rem',
		fontWeight: 700,
	},
	title: {
		fontSize: '1.2rem',
		fontWeight: 600,
		color: theme.palette.primary.main,
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	header: {
		fontSize: '1.2rem',
		fontWeight: 600,
		color: theme.palette.primary.main,
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: theme.palette.primary.main,
	},
	itemRow: {
		paddingBottom: theme.spacing(0.5),
	},
	itemKey: {
		whiteSpace: 'nowrap',
		fontWeight: 500,
		color: theme.palette.primary.main,
	},
	itemProp: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: theme.palette.primary.main,
	},
	iconButton: {
		padding: theme.spacing(0.5),
	},
	detailsButton: {
		'&:hover': {
			background: theme.palette.secondary.main,
		},
	},
	lineBetweenSummaryAndDetails: {
		height: theme.spacing(0.25),
		backgroundColor: theme.palette.primary.secondary,
	},
}));

const CompanyDetails = () => {
	const classes = useStyles();
	const intl = useIntl();
	const history = useHistory();
	const params = useParams();
	const dispatch = useDispatch();

	const admin = useSelector(state => state.admin);
	const licensesLoading = useSelector(state => state.licenses?.loading);
	const companyDataLoading = admin?.companyDataLoading;
	const company = admin?.companyData;
	const companyDetailsLoading = admin?.companyDetailsLoading;
	const companyDetails = admin?.companyDetails;
	const companyId = company?.companyId?.trim();

	const [isFetch, setIsFetch] = useState();

	useEffect(() => {
		if (isFetch === params?.companyId) return;

		if (!company || companyId !== params?.companyId) {
			setIsFetch(params.companyId);
			dispatch(getCompanyDetails(params?.companyId));
			dispatch(getLicenseContantData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company, companyId, params, dispatch]);

	const companyLicense = companyDetails?.companyLicense;
	const companyUsers = companyDetails?.companyUsers ?? [];
	const companyRoles = companyDetails?.companyRoles ?? [];
	const licensesState = companyDetails?.companyLicenses ?? [];

	// Constants data for license
	const licenseConstants = useSelector(state => state.licenses?.constants);
	const contractBillingPeriodTypes = licenseConstants?.data?.contractBillingPeriodTypes;
	const contractPeriodTypes = licenseConstants?.data?.contractPeriodTypes;
	const licenseTypes = licenseConstants?.data?.licenseTypes;
	const contractBillingTypes = licenseConstants?.data?.contractBillingTypes;
	const contractTypes = licenseConstants?.data?.contractTypes;
	const licensePackages = licenseConstants?.data?.licensePackages;

	const users = companyUsers?.map(user => ({ ...user, id: user.userContact.rowKey }));
	const licenses = licensesState.map(license => ({ ...license, id: license.rowKey }));

	const fetchUserDetails = userId => history.push(`/admin/user/${userId}`);

	const terminateLicense = async () => {
		return await dispatch(terminateCompanyLicense(params?.companyId));
	};

	const saveLicense = async (license, silently) => {
		const success = await dispatch(saveCompanyLicense(license, params?.companyId, silently));

		if (success.success) {
			toast.success(intl.formatMessage({ id: 'license.form.save.success' }));
		} else {
			let error = 'general';
			try {
				error = JSON.parse(success?.error?.response?.data?.content);
			} catch {
				// ignore
			}

			toast.error(
				<div>
					{intl.formatMessage({ id: 'license.form.save.failed' })} <br />{' '}
					{intl.formatMessage({ id: `license.form.save.failed.errorcode.${error}` })}
				</div>
			);
		}

		return success;
	};

	const [isLicenseModalOpen, setLicenseModalOpen] = useState(false);
	const [licenseObj, setLicenseObj] = useState({});

	const handleLicenseModalOpen = license => {
		if (license && license !== null) {
			license.companyName = license.companyName ?? company?.name;
			setLicenseObj(license);
		} else {
			setLicenseObj({ companyId: params?.companyId, companyName: company?.name ?? '' });
		}
		setLicenseModalOpen(true);
	};

	const handleLicenseModalClose = () => {
		setLicenseObj({});
		setLicenseModalOpen(false);
	};

	const saveUser = user => {
		dispatch(saveCompanyUser(user, params?.companyId));
		handleUserModalClose();
	};

	const [isUserModalOpen, setUserModalOpen] = useState(false);
	const [userObj, setUserObj] = useState({});

	const handleUserModalOpen = user => {
		if (user) {
			setUserObj(user);
		}
		setUserModalOpen(true);
	};
	const handleUserModalClose = () => {
		setUserObj({});
		setUserModalOpen(false);
	};

	const handleDeactivateUser = user => dispatch(deactivateUser(user, params?.companyId));

	const handleDeleteCompanyRoles = selectedRoles => dispatch(deleteCompanyRoles(params?.companyId, null, selectedRoles));

	return (
		<>
			<ContentContainer>
				<Stack direction='row' alignItems='center' spacing={1} sx={{ mb: 3 }}>
					{companyDataLoading ? (
						<CircularProgress size={16} />
					) : (
						<Typography variant='h1' className={classes.companyName}>
							{company?.name}
						</Typography>
					)}
				</Stack>
				{/* Panels */}
				<Grid container direction='row' spacing={2}>
					<Grid item xs={12} md={4}>
						<CompanyLicensePanel loading={companyDetailsLoading} companyLicense={companyLicense} classes={classes} />
					</Grid>
					<Grid item xs={12} md={4}>
						<CompanyDataPanel loading={companyDataLoading} companyData={company} classes={classes} />
					</Grid>
					<Grid item xs={12} md={4}>
						<CompanyAddressPanel loading={companyDataLoading} address={company?.address} classes={classes} />
					</Grid>
				</Grid>
				{/* CompanyLicense Add / Remove */}
				<LeftRightContainer
					left={
						<Typography variant='h2' className={classes.header} sx={{ m: 0 }}>
							{intl.formatMessage({ id: 'companydetails.companylicenses.title' })}
						</Typography>
					}
					right={
						<Stack direction='row' alignItems='center' spacing={2}>
							<Button
								variant='text'
								color='success'
								disabled={companyDetailsLoading}
								startIcon={<AddIcon size='small' />}
								onClick={() => handleLicenseModalOpen({})}
							>
								{intl.formatMessage({ id: 'license.form.add' })}
							</Button>
						</Stack>
					}
					sx={{ mt: 4 }}
				/>
				{/* CompanyLicenses Table */}
				<LicensesTable
					loading={companyDetailsLoading}
					data={licenses}
					classes={classes}
					fetchCompanyDetails={undefined}
					fetchUserDetails={fetchUserDetails}
					openLicenseModal={handleLicenseModalOpen}
				/>
				{/* CompanyUser */}
				<LeftRightContainer
					left={
						<Typography variant='h2' className={classes.header}>
							{intl.formatMessage({ id: 'companydetails.companyusers' })}
						</Typography>
					}
					right={
						<Button
							variant='text'
							color='success'
							disabled={companyDetailsLoading}
							startIcon={<AddIcon size='small' />}
							onClick={() => handleUserModalOpen({})}
						>
							{intl.formatMessage({ id: 'user.form.add' })}
						</Button>
					}
					sx={{ mt: 4 }}
				/>
				{/* CompanyUsers Table */}
				<CompanyUserTable
					loading={companyDetailsLoading}
					data={users}
					classes={classes}
					fetchUserDetails={fetchUserDetails}
					deactivateUser={handleDeactivateUser}
					openUserModal={handleUserModalOpen}
				/>
				{/* CompanyRoles Table */}
				<CompanyRolesTable
					loading={companyDetailsLoading}
					data={companyRoles}
					classes={classes}
					fetchUserDetails={fetchUserDetails}
					handleDeleteCompanyRoles={handleDeleteCompanyRoles}
				/>
			</ContentContainer>
			{licenseConstants?.isLoaded && (
				<LicenseModalForm
					terminateCompanyLicense={terminateLicense}
					company={company}
					license={licenseObj}
					saveLicense={saveLicense}
					handleClose={handleLicenseModalClose}
					isOpen={isLicenseModalOpen}
					{...{
						contractBillingPeriodTypes,
						contractPeriodTypes,
						contractBillingTypes,
						licenseTypes,
						contractTypes,
						licensePackages,
						loading: licensesLoading,
					}}
				/>
			)}
			<UserModalForm user={userObj} saveUser={saveUser} handleClose={handleUserModalClose} isOpen={isUserModalOpen} />
		</>
	);
};

export default CompanyDetails;
