import produce from 'immer';
import _ from 'lodash';
import {
	CLOSE_MODAL,
	LOAD_INSTRUMENT_RADAR,
	LOAD_INSTRUMENT_RADAR_ERROR,
	LOAD_INSTRUMENT_RADAR_SUCCESS,
	LOAD_SCENARIOS,
	LOAD_SCENARIOS_ERROR,
	LOAD_SCENARIOS_SUCCESS,
	OPEN_MODAL,
	RESET_SCENARIO,
	SAVE_SCENARIO,
	SAVE_SCENARIO_FAIL,
	SAVE_SCENARIO_SUCCESS,
	SET_COMPANY_LIMIT,
} from './constants';

export const initialLoading = {
	radar: false,
	scenarios: false,
	saveScenarios: false,
};

export const initialState = {
	loading: initialLoading,
	error: false,
	scenarios: {},
	radar: [],
	open: false,
};

const scenarioReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case LOAD_INSTRUMENT_RADAR:
				draft.loading.radar = true;
				draft.error = false;
				break;
			case LOAD_INSTRUMENT_RADAR_ERROR:
				draft.loading.radar = false;
				draft.error = action.error;
				draft.radar = initialState.radar;
				break;
			case LOAD_INSTRUMENT_RADAR_SUCCESS:
				draft.loading.radar = false;
				draft.error = false;
				draft.radar = action.radar;
				break;
			case LOAD_SCENARIOS:
				draft.loading.scenarios = true;
				draft.error = false;
				break;
			case LOAD_SCENARIOS_ERROR:
				draft.loading.scenarios = false;
				draft.error = action.error;
				draft.scenarios = initialState.scenarios;
				break;
			case LOAD_SCENARIOS_SUCCESS:
				draft.loading.scenarios = false;
				draft.error = false;
				draft.scenarios = action.scenarios;
				break;
			case SAVE_SCENARIO:
				draft.loading.saveScenarios = true;
				draft.error = false;
				break;
			case SAVE_SCENARIO_FAIL:
				draft.loading.saveScenarios = false;
				draft.error = action.error;
				draft.scenarios = initialState.scenarios;
				break;
			case SAVE_SCENARIO_SUCCESS:
				draft.loading.saveScenarios = false;
				draft.error = false;
				draft.scenarios = action.scenario;
				break;
			case OPEN_MODAL:
				draft.open = true;
				break;
			case CLOSE_MODAL:
				draft.open = false;
				break;
			case SET_COMPANY_LIMIT:
				draft.error = action.error;
				draft.company = _.assign({}, action.company);
				break;
			case RESET_SCENARIO:
				return initialState;
			default:
				return state;
		}
	});

export default scenarioReducer;
