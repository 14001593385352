import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { find, findIndex, isNil, remove, set } from 'lodash';

type PopoverState = {
	popovers: {
		name: string;
		data?: any;
	}[];
};

const initialState = { popovers: [] } as PopoverState;

const popoverSlice = createSlice({
	name: 'popover',
	initialState,
	reducers: {
		openPopover(state, action: PayloadAction<{ name: string; data?: any }>) {
			let index = findIndex(state.popovers, { name: action.payload.name });
			if (index <= -1) {
				index = state.popovers.length;
			}
			set(state.popovers, index, { ...action.payload });
		},
		closePopover(state, action: PayloadAction<{ name: string }>) {
			remove(state.popovers, { name: action.payload.name });
		},
	},
});

export const { openPopover, closePopover } = popoverSlice.actions;

export const isPopoverOpen = (name: string) => (state: any) => {
	const popoverState = state[popoverSlice.name] as PopoverState;
	const popover = find(popoverState.popovers, { name });
	return !isNil(popover);
};

export const getPopoverData = (name: string) => (state: any) => {
	const popoverState = state[popoverSlice.name] as PopoverState;
	const popover = find(popoverState.popovers, { name });
	return popover?.data;
};

export default popoverSlice;
