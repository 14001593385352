import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import LinkButton from 'components/Button/LinkButton';
import ProjectTaskCard from '../../Task/ProjectTaskCard';
import ProjectPhaseStepper from './ProjectPhaseStepper';

import getCompanyLink from 'utils/auth/getCompanyLink';

const StyledNameTitle = styled(Typography)(() => ({
	fontWeight: '600',
	fontSize: '2rem',
}));

const StyledProjectDescriptionBox = styled(Grid)(() => ({
	padding: '2rem 4rem',
	'& h3': {
		color: '#000',
		fontWeight: 600,
		fontSize: '1.15rem',
		marginBottom: '1rem',
		marginTop: 0,
	},
}));

const ProjectPhaseContent = styled(Box)(() => ({
	backgroundColor: '#E6E2DC',
	width: '100%',
	'&>div:first-child': {
		backgroundColor: '#EEECE9',
	},
}));

const allCostsEnd = costs => {
	const count = costs.length;
	const ended = costs.every(item => {
		return item.phase === 'paid';
	});

	return count === ended.length;
};

const getProjectPhase = (project, tasks, costs) => {
	if (costs?.length > 0 && allCostsEnd(costs)) return 4;
	else if (costs?.length > 0) return 3;
	else if (tasks?.length > 0) return 2;
	else if (!tasks || tasks?.length === 0) return 1;
	else if (!project || !project.projectName) return 0;

	return 0;
};

const ProjectPlanStep = ({ isActive, projectId }) => {
	const location = useLocation();

	return (
		<Stack>
			<Box>
				<StyledNameTitle component='h3'>
					<FormattedMessage id='project.tips.writeplan' />
				</StyledNameTitle>
			</Box>
			<Box>
				<Typography>
					<FormattedMessage id='project.tips.writeplan.description' />
				</Typography>
				{isActive && (
					<LinkButton
						sx={{ mt: '1rem' }}
						icon={<ArrowRightAltIcon />}
						href={getCompanyLink(`/projects/${projectId}/plan`, location)}
					>
						<FormattedMessage id='project.plan.link' />
					</LinkButton>
				)}
			</Box>
		</Stack>
	);
};

const AddTaskStep = ({ isActive, project }) => (
	<Stack>
		<Box>
			<StyledNameTitle component='h3'>
				<FormattedMessage id='project.tips.addtasks' />
			</StyledNameTitle>
		</Box>
		<Box>
			<Typography>
				<FormattedMessage id='project.tips.addtasks.description' />
			</Typography>
			{isActive && <ProjectTaskCard showModalButton={true} project={project} task={{}} costs={[]} column='tasks' />}
		</Box>
	</Stack>
);

const GetFunding = () => (
	<Box>
		<StyledNameTitle component='h3'>
			<FormattedMessage id='project.tips.apply' />
		</StyledNameTitle>
		<Typography>
			<FormattedMessage id='project.tips.apply.description' />
		</Typography>
	</Box>
);

const FollowCosts = () => (
	<Box>
		<StyledNameTitle component='h3'>
			<FormattedMessage id='project.tips.follow' />
		</StyledNameTitle>
		<Typography>
			<FormattedMessage id='project.tips.follow.description' />
		</Typography>
	</Box>
);

const EndProject = () => (
	<Box>
		<StyledNameTitle component='h3'>
			<FormattedMessage id='project.tips.end' />
		</StyledNameTitle>
		<Typography>
			<FormattedMessage id='project.tips.end.description' />
		</Typography>
	</Box>
);

export default function ProjectPhaseTips({ project, tasks, costs }) {
	const intl = useIntl();

	const phase = getProjectPhase(project, tasks, costs);

	const [activePhase, setActivePhase] = useState(phase);

	useEffect(() => {
		setActivePhase(phase);
	}, [phase]);

	const steps = [
		intl.formatMessage({ id: 'project.steps.init' }),
		intl.formatMessage({ id: 'project.steps.tasks' }),
		intl.formatMessage({ id: 'project.steps.costs' }),
		intl.formatMessage({ id: 'project.steps.follow' }),
		intl.formatMessage({ id: 'project.steps.end' }),
	];

	const StepContent = ({ phase, activePhase, project }) => {
		switch (activePhase) {
			case 0:
				return <ProjectPlanStep isActive={phase === 0} projectId={project.projectId} />;
			case 1:
				return <AddTaskStep isActive={phase === 1} project={project} />;
			case 2:
				return <GetFunding isActive={phase === 2} />;
			case 3:
				return <FollowCosts isActive={phase === 3} />;
			case 4:
				return <EndProject isActive={phase === 4} />;
			default:
				return <ProjectPlanStep isActive={phase === 0} />;
		}
	};

	const onHoverAction = index => {
		setActivePhase(index);
	};

	return (
		<ProjectPhaseContent>
			<Box sx={{ py: '2rem' }}>
				<ProjectPhaseStepper activeStep={activePhase} steps={steps} onHoverAction={onHoverAction} chosenStep={phase} />
			</Box>
			<StyledProjectDescriptionBox container justifyContent='space-between'>
				<StepContent activePhase={activePhase} phase={phase} project={project} />
			</StyledProjectDescriptionBox>
		</ProjectPhaseContent>
	);
}
