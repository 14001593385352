import React from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TextContainer from 'components/Containers/TextContainer';
import htmlParser from 'components/HtmlParser';

const ScoreBackground = ({ businessDescription, companyInfo, market, financial, solidity, companyType }) => {
    const intl = useIntl();

    return (
        <Box >
            <Typography sx={{
                fontSize: '0.8rem',
                fontWeight: 600,
                color: 'primary.main',
                pl: 3,
                pt: 3,
            }}>
                {intl.formatMessage({ id: 'analysis.background.header' })?.toUpperCase()}
            </Typography>
            <TextContainer>
                <p>{htmlParser(companyInfo)}</p>
                <p>{htmlParser(companyType)}</p>
                <p>{htmlParser(market)}</p>
                <p>{htmlParser(businessDescription)}</p>
                <p>{htmlParser(financial)}</p>
                <p>{htmlParser(solidity)}</p>
            </TextContainer>
        </Box>
    );
};

export default ScoreBackground;
