import { createSlice /* current */ } from '@reduxjs/toolkit';
import { loadScenariosSuccess } from 'containers/Scenario/actions';
import { setGrantedScore } from 'containers/Score/slices';

import { setInstruments } from 'containers/Instruments/slices';
import { analysisApi } from './api';

export const name = 'analysis';

export const initialState = {
	isLoaded: false,
	error: false,
	analysis: {},
	isContacting: false,
	contactData: {},
	linkId: null,
	createdLink: {
		data: null,
		error: null,
		loading: false,
	},
};

//reducer actions & reducer state
/**
 * You can call reducer actions directly from React component with dispatch or using actions wrapper (see actions below)
 */
const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},
		setLinkId(state, action) {
			state.linkId = action.payload;
		},
		setIsContacting(state, action) {
			state.isContacting = action.payload;
		},
		setIsContacted(state, action) {
			state.isContacting = false;
			state.contactData = action.payload;
		},
		setContactData(state, action) {
			state.contactData = action.payload;
		},
		setIsLoaded(state, action) {
			state.isLoaded = action.payload;
		},
		loadAnalysis(state, action) {
			state.analysis = action.payload;
			state.isLoaded = true;
		},
		loadAnalysisError(state, action) {
			state.error = action.payload;
			state.isLoaded = true;
			state.analysis = initialState.analysis; // TODO: check this
		},
		createAnalysisLink(state) {
			state.createdLink.data = null;
			state.createdLink.error = null;
			state.createdLink.loading = true;
		},
		createAnalysisLinkSuccess(state, action) {
			state.createdLink.data = action.payload;
			state.createdLink.error = null;
			state.createdLink.loading = false;
		},
		createAnalysisLinkError(state, action) {
			state.createdLink.data = null;
			state.createdLink.error = action.payload;
			state.createdLink.loading = false;
		},
	},
});

export const { reducer } = slice;

//actions
export const resetAnalysisData = () => async dispatch => {
	try {
		dispatch(slice.actions.resetData());

		return true;
	} catch {
		return false;
	}
};

export const loadAnalysis = () => async dispatch => {
	try {
		dispatch(slice.actions.setIsLoaded(false));

		const analysis = await analysisApi.getAnalysis();

		dispatch(slice.actions.loadAnalysis(analysis));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadAnalysisError(error));

		return false;
	}
};

export const contactWithLink = (linkId, type) => async dispatch => {
	try {
		dispatch(slice.actions.setIsContacting(true));
		const analysis = await analysisApi.contactWithLink(linkId, type);

		dispatch(slice.actions.setContactData(analysis));
		dispatch(slice.actions.setIsContacting(false));

		return true;
	} catch (error) {
		dispatch(slice.actions.setIsContacting(false));
		dispatch(slice.actions.loadAnalysisError(error));

		return false;
	}
};

export const loadAnalysisByLink = linkId => async dispatch => {
	try {
		dispatch(slice.actions.setIsLoaded(false));

		const analysisData = await analysisApi.getAnalysisByLink(linkId);

		dispatch(slice.actions.loadAnalysis(analysisData.analysis));
		dispatch(slice.actions.setContactData(analysisData.linkContactInformation));

		dispatch(loadScenariosSuccess(analysisData.scenario));
		dispatch(setGrantedScore(analysisData.score));
		dispatch(setInstruments(analysisData.instruments));

		dispatch(slice.actions.setLinkId(linkId));
		return true;
	} catch (error) {
		dispatch(slice.actions.loadAnalysisError(error));

		return false;
	}
};

export const generateAnalysisLink = data => async dispatch => {
	try {
		dispatch(slice.actions.createAnalysisLink());

		const analysisData = await analysisApi.generateAnalysisLink(data);

		dispatch(slice.actions.createAnalysisLinkSuccess(analysisData));

		return true;
	} catch (error) {
		dispatch(slice.actions.createAnalysisLinkError(error));

		return false;
	}
};

export default slice;
