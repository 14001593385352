import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogProps, IconButton } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeDialog, getDialogData, isDialogOpen, openDialog } from 'containers/Marketplace/dialogSlice';

import ReadRfpProfileForm from 'containers/Marketplace/profile/Read/ReadRfpProfileForm';

type Props = {} & Omit<DialogProps, 'open'>;

const ReadRfpProfileDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const dialogOpen = useSelector(isDialogOpen('ReadRfpProfileDialog'));
	const dialogData = useSelector(getDialogData('ReadRfpProfileDialog'));

	const handleDialogClose = React.useCallback(() => {
		dispatch(closeDialog({ name: 'ReadRfpProfileDialog' }));
	}, [dispatch]);

	const handleEditClick = React.useCallback(() => {
		dispatch(openDialog({ name: 'UpsertRfpProfileDialog' }));
		dispatch(closeDialog({ name: 'ReadRfpProfileDialog' }));
	}, [dispatch]);

	return (
		<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='sm' fullWidth>
			<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
				<CloseIcon />
			</IconButton>
			<ReadRfpProfileForm companyId={dialogData?.companyId} onCloseClick={handleDialogClose} onEditClick={handleEditClick} />
		</Dialog>
	);
};

export default ReadRfpProfileDialog;
