import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useIntl } from 'react-intl';

import DeminimisCard from '.';
const deminimisSum = 300000;

const DeminimisWidget = ({ loading, projects, sx = {} }) => {
	const intl = useIntl();

	// suunniteltu
	const planned = projects?.reduce((acc, project) => {
		if (project?.mock === true) return 0;

		return acc + project?.projectSize;
	}, 0);

	// myönnetty
	const granted = projects?.reduce((acc, project) => {
		if (project?.mock === true) return 0;

		return project?.isUsed ? acc + project?.projectSize : acc;
	}, 0);

	// yhteensä
	const total = projects?.reduce((acc, project) => {
		if (project?.mock === true) return 0;

		return project?.isUsed ? acc + project?.amountToApply : acc;
	}, 0);

	// vapaa
	const currentYear = moment().year();
	const filteredByYear = projects?.filter(({ startDate }) => {
		const startYear = moment(startDate).year();

		return currentYear - startYear < 3;
	});

	const grantedByYear = filteredByYear?.reduce((acc, { isUsed, deminimisAmount }) => {
		return isUsed ? acc + deminimisAmount : acc;
	}, 0);

	const free = deminimisSum - (grantedByYear ?? 0);

	return (
		<Grid container spacing={2} direction='row' justifyContent='space-evenly' alignItems='stretch' sx={sx}>
			<Grid item xs={6} smd={3}>
				<DeminimisCard
					loading={loading}
					title={intl.formatMessage({ id: 'project.valuebox.planned' })}
					value={planned ?? 0}
					subtitle={intl.formatMessage({ id: 'project.valuebox.planned.description' })}
				/>
			</Grid>
			<Grid item xs={6} smd={3}>
				<DeminimisCard
					loading={loading}
					title={intl.formatMessage({ id: 'project.valuebox.granted' })}
					value={granted ?? 0}
					subtitle={intl.formatMessage({ id: 'project.valuebox.granted.description' })}
				/>
			</Grid>
			<Grid item xs={6} smd={3}>
				<DeminimisCard
					loading={loading}
					title={intl.formatMessage({ id: 'project.valuebox.total' })}
					value={total ?? 0}
					subtitle={intl.formatMessage({ id: 'project.valuebox.total.description' })}
				/>
			</Grid>
			<Grid item xs={6} smd={3}>
				<DeminimisCard
					loading={loading}
					title={intl.formatMessage({ id: 'project.valuebox.free' })}
					value={free ?? 0}
					subtitle={currentYear && `${currentYear - 2} - ${currentYear}`}
				/>
			</Grid>
		</Grid>
	);
};

export default DeminimisWidget;
