import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArchiveIcon from '@mui/icons-material/Archive';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import getCompanyLink from 'utils/auth/getCompanyLink';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DropDownMenuButton from 'components/Button/DropDownMenuButton';
import { archiveProject, publishDraftProject } from 'containers/Projects/slices';
import { matchPath } from 'utils/helpers/pathMatch';

const ProjectDashBoardMenu = ({ project }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const archive = async () => {
		setLoading(true);
		const success = await dispatch(archiveProject(project.projectId));
		setLoading(false);

		if (success) {
			history.push(getCompanyLink('/projects', location));
		}
	};

	const publishProject = async () => {
		setLoading(true);
		const success = await dispatch(publishDraftProject(project, getCompanyLink('', location)));
		setLoading(false);

		if (success) {
			toast.success(<FormattedMessage id='project.draft.publish.success' />);

			return;
		}

		toast.error(<FormattedMessage id='project.draft.publish.fail' />);
	};

	const menu = [
		{
			disabled: matchPath(`/projects/${project?.projectId}`, location, true),
			text: <FormattedMessage id='project.dashboard.menu.dashboard' />,
			icon: <DashboardOutlined />,
			onClick: () => history.push(getCompanyLink(`/projects/${project?.projectId}`, location)),
		},
		{
			disabled: matchPath(`/projects/${project?.projectId}/plan`, location, true),
			text: <FormattedMessage id='project.dashboard.menu.edit' />,
			icon: <EditIcon />,
			onClick: () => history.push(getCompanyLink(`/projects/${project?.projectId}/plan`, location)),
		},
		{
			disabled: matchPath(`/projects/${project?.projectId}/summary`, location, true),
			text: <FormattedMessage id='project.dashboard.menu.summary' />,
			icon: <SummarizeIcon />,
			onClick: () => history.push(getCompanyLink(`/projects/${project?.projectId}/summary`, location)),
		},
		{
			dividerPosition: 'top',
			text: <FormattedMessage id='project.dashboard.menu.publish' />,
			icon: <PublishIcon />,
			requireConfirm: true,
			confirmAction: publishProject,
			confirmText: <FormattedMessage id='project.dashboard.menu.published.confirmText' />,
			cancelText: <FormattedMessage id='project.dashboard.menu.published.cancelText' />,
			confirmBodyText: <FormattedMessage id='project.dashboard.menu.published.confirmBodyText' />,
		},
		{
			dividerPosition: 'top',
			text: <FormattedMessage id='project.dashboard.menu.archive' />,
			icon: <ArchiveIcon />,
			requireConfirm: true,
			confirmAction: archive,
			confirmText: <FormattedMessage id='project.dashboard.menu.archive.confirmText' />,
			cancelText: <FormattedMessage id='project.dashboard.menu.archive.cancelText' />,
			confirmBodyText: <FormattedMessage id='project.dashboard.menu.archive.confirmBodyText' />,
		},
	];

	if (!project?.projectId) return null;

	return (
		<DropDownMenuButton
			icon={<MoreHorizIcon />}
			light={true}
			text={<FormattedMessage id='project.dashboard.menu' />}
			items={menu}
			loading={loading}
			sx={{ displayPrint: 'none' }}
		/>
	);
};

export default ProjectDashBoardMenu;
