import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, Grid, Stack } from '@mui/material';

import { FormControlledInput, FormControlledSelect, FormControlledSwitchItem } from 'components/Form';
import AccordionItem from '../../../../components/AccordionItem/AccordionItem';
import { colors } from '../../../../config/theme';
import TestSortableItem from '../../CategoriesPage/TestSortableContainer/TestSortableItem';
import JsonInputForm from './JsonInput';

const NestedFieldArray = ({ nestIndex, control, register, errors, getValues, loading }: any) => {
	const { fields, remove, append, move, prepend } = useFieldArray({
		control,
		name: `fields.${nestIndex}.fields`,
	});
	const { formatMessage } = useIntl();

	const [cursor, setCursor] = useState('grab');

	const handleDragStart = () => setCursor('grabbing');
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragEnd = ({ active, over }: any) => {
		setCursor('grab');

		const activeId = active.id;
		const overId = over.id;

		try {
			if (activeId === overId) return;

			if (activeId !== overId) {
				const oldIndex = fields.findIndex(({ id }) => id === activeId);
				const newIndex = fields.findIndex(({ id }) => id === overId);

				move(oldIndex, newIndex);
			}
		} catch {
			toast.error(formatMessage({ id: 'categories.order.failed' }));
		}
	};

	return (
		<Grid container spacing={1}>
			{errors?.fields?.[nestIndex]?.fields && (
				<Grid item xs={12} sx={{ color: 'red' }}>
					{errors?.fields?.[nestIndex]?.fields?.message}
				</Grid>
			)}

			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
				<SortableContext items={fields} strategy={verticalListSortingStrategy}>
					{fields.map((item: any, index: number) => {
						return (
							<Grid key={item.id} item xs={12}>
								<TestSortableItem id={item.id} handle={true} value={item.id} key={index}>
									<AccordionItem
										color={errors?.fields?.[nestIndex]?.fields?.[index] ? colors.darkRed : colors.purple}
										maxHeight='40rem'
										title={
											<Stack
												flexDirection='row'
												spacing={1}
												useFlexGap
												sx={{
													color: errors?.fields?.[nestIndex]?.fields?.[index] ? colors.darkRed : colors.purple,
												}}
											>
												{errors?.fields?.[nestIndex]?.fields?.[index] && <ErrorIcon />}
												{getValues(`fields.${nestIndex}.fields.${index}.title`)}
											</Stack>
										}
									>
										<Grid container spacing={1}>
											<Grid item xs={12} md={6}>
												<FormControlledInput
													defaultValue={item.id ?? ''}
													name={`fields.${nestIndex}.fields.${index}.id`}
													control={control}
													error={errors?.fields?.[nestIndex]?.fields?.[index]?.id?.message}
													label={'ID'}
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												{item.type === 'boolean' && (
													<>
														<input
															{...register(`fields.${nestIndex}.fields.${index}.checkedLabel`)}
															type='hidden'
															value='Kyllä'
														/>
														<input
															{...register(`fields.${nestIndex}.fields.${index}.notCheckedLabel`)}
															type='hidden'
															value='Ei'
														/>
													</>
												)}

												<FormControlledSelect
													defaultValue={item.type}
													name={`fields.${nestIndex}.fields.${index}.type`}
													control={control}
													error={errors?.fields?.[nestIndex]?.fields?.[index]?.type?.message}
													label={'Type'}
													options={[
														{
															value: 'text',
															label: formatMessage({ id: 'instrument.templates.field.type.text' }),
														},
														{
															value: 'date',
															label: formatMessage({ id: 'instrument.templates.field.type.date' }),
														},
														{
															value: 'boolean',
															label: formatMessage({ id: 'instrument.templates.field.type.boolean' }),
														},
														{
															value: 'textbox',
															label: formatMessage({ id: 'instrument.templates.field.type.textbox' }),
														},
														{
															value: 'table',
															label: formatMessage({ id: 'instrument.templates.field.type.table' }),
														},
														{
															value: 'currency',
															label: formatMessage({ id: 'instrument.templates.field.type.currency' }),
														},
													]}
												/>
											</Grid>

											<Grid item xs={12}>
												<FormControlledInput
													defaultValue={item.title ?? ''}
													name={`fields.${nestIndex}.fields.${index}.title`}
													control={control}
													error={errors?.fields?.[nestIndex]?.fields?.[index]?.title?.message}
													label={'Title'}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlledInput
													defaultValue={item.description ?? ''}
													name={`fields.${nestIndex}.fields.${index}.description`}
													control={control}
													error={errors?.fields?.[nestIndex]?.fields?.[index]?.description?.message}
													label={'Description'}
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlledInput
													defaultValue={item.placeholder ?? ''}
													name={`fields.${nestIndex}.fields.${index}.placeholder`}
													control={control}
													error={errors?.fields?.[nestIndex]?.fields?.[index]?.placeholder?.message}
													label={'placeholder'}
												/>
											</Grid>
											<Grid item xs={12}>
												{/* @ts-ignore */}
												<FormControlledSwitchItem
													defaultValue={item.ai}
													name='ai'
													control={control}
													error={errors?.fields?.[nestIndex]?.fields?.[index]?.ai?.message}
													label={'Use OpenAI?'}
													checkedVal={true}
													notCheckedVal={false}
													checkedLabel={'Kyllä'}
													notCheckedLabel={'Ei'}
												/>
											</Grid>
											<Grid item xs={12}>
												<AccordionItem
													maxHeight='40rem'
													title={
														<Stack flexDirection='row' spacing={1} useFlexGap>
															Validaatio, prompt, asettelu ja mappaus
														</Stack>
													}
												>
													<Grid container spacing={2}>
														<Grid item xs={12} md={6}>
															<FormControlledInput
																defaultValue={item.promptTemplate ?? ''}
																name={`fields.${nestIndex}.fields.${index}.promptTemplate`}
																control={control}
																error={
																	errors?.fields?.[nestIndex]?.fields?.[index]?.promptTemplate?.message
																}
																label={'promptTemplate'}
															/>
														</Grid>
														<Grid item xs={12} md={6}>
															<JsonInputForm
																name={`fields.${nestIndex}.fields.${index}.validations`}
																defaultValue={item.validations}
																control={control}
																label={'Validations'}
															/>
														</Grid>
														<Grid item xs={12} md={6}>
															<JsonInputForm
																label={'Parameters'}
																name={`fields.${nestIndex}.fields.${index}.params`}
																defaultValue={item.params}
																control={control}
															/>
														</Grid>
														<Grid item xs={12} md={6}>
															<JsonInputForm
																name={`fields.${nestIndex}.fields.${index}.scale`}
																defaultValue={item.scale}
																control={control}
																label={'Scale'}
															/>
														</Grid>
														<Grid item xs={12} md={6}>
															<FormControlledInput
																defaultValue={item.mapping ?? ''}
																name={`fields.${nestIndex}.fields.${index}.mapping`}
																control={control}
																error={errors?.fields?.[nestIndex]?.fields?.[index]?.mapping?.message}
																label={'mapping'}
															/>
															<JsonInputForm
																name={`fields.${nestIndex}.fields.${index}.mappingTemplateFields`}
																defaultValue={item.mappingTemplateFields}
																control={control}
																label={'mappingTemplateFields'}
															/>
														</Grid>
														<Grid item xs={12} md={6}>
															<JsonInputForm
																name={`fields.${nestIndex}.fields.${index}.keys`}
																defaultValue={item.keys}
																control={control}
																label={'keys'}
															/>
														</Grid>
													</Grid>
												</AccordionItem>
											</Grid>
											<Grid item xs={12}>
												<Button
													disabled={loading}
													startIcon={<DeleteIcon />}
													variant='contained'
													color='error'
													onClick={() => remove(index)}
												>
													Poista aliosio
												</Button>
											</Grid>
										</Grid>
									</AccordionItem>
								</TestSortableItem>
							</Grid>
						);
					})}
				</SortableContext>
			</DndContext>

			<Grid item xs={12}>
				<Button
					disabled={loading}
					startIcon={<AddIcon />}
					variant='contained'
					color='success'
					onClick={() =>
						append({
							title: 'Uusi aliosio',
							description: '',
							placeholder: '',
							id: '',
							type: '',
							mapping: null,
							scale: null,
							params: null,
							validations: null,
						})
					}
					sx={{ mr: '1rem' }}
				>
					Lisää aliosio
				</Button>
				{/* <Button
					disabled={loading}
					startIcon={<AddIcon />}
					variant='contained'
					color='success'
					onClick={() =>
						prepend({
							title: 'field1',
							description: 'field2',
						})
					}
				>
					Prepend Nested
				</Button> */}
			</Grid>
		</Grid>
	);
};

export default NestedFieldArray;
