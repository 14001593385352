import { yupResolver } from '@hookform/resolvers/yup';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, Fade, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { BusinessPlan } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import { mergeFieldValues } from 'utils/misc/formUtils';

import { useFetchBusinessPlanQuery, useUpsertBusinessPlanMutation } from 'containers/BusinessPlan/businessPlanV2Api';
import { loadBusinessPlan } from 'containers/BusinessPlan/slices';

import AdminContentContainer from 'components/Containers/AdminContentContainer';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import PanelSubtitle from 'components/Titles/PanelSubtitle';
import PanelTitle from 'components/Titles/PanelTitle';
import AiChatWrapper from 'containers/AI/AiChatWrapper/AiChatWrapper';

type FieldValues = Partial<BusinessPlan>;

type Props = {
	onPrevStepClick: () => void;
	onNextStepClick: () => void;
	passIsDirty: (isDirty: boolean, dirtyFields: any) => void;
	passZeroFields: (zeroFields: string[]) => void;
};

const OnboardingStep5 = React.forwardRef<any, Props>(
	({ onNextStepClick, onPrevStepClick, passIsDirty, passZeroFields, ...otherProps }, ref) => {
		const dispatch = useDispatch();
		const { formatMessage } = useIntl();

		const companyId = getCompanyId();

		const validationSchema = React.useMemo(() => {
			return yup.object().shape({
				pitch: yup.string().nullable().max(30000),
			});
		}, []);

		const defaultValues = React.useMemo<FieldValues>(() => {
			return {
				businessId: null,
				companyId: null,
				pitch: '',
			};
		}, []);

		const {
			handleSubmit,
			control,
			reset,
			formState: { isDirty, dirtyFields, errors },
		} = useForm<FieldValues>({
			defaultValues,
			resolver: yupResolver(validationSchema),
		});

		const {
			data: businessPlan,
			isLoading: isBusinessPlanLoading,
			isError: isBusinessPlanError,
		} = useFetchBusinessPlanQuery({ companyId }, { skip: isNil(companyId) });

		const [upsertBusinessPlan, { isLoading: isUpsertLoading, isError: isUpsertError, data: upsertedBusinessPlan }] =
			useUpsertBusinessPlanMutation();

		const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
			async fieldValues => {
				try {
					await upsertBusinessPlan({ companyId, businessPlan: fieldValues }).unwrap();
					dispatch(loadBusinessPlan());
					onNextStepClick();
				} catch (error) {
					console.error(error);
				}
			},
			[dispatch, upsertBusinessPlan, onNextStepClick, companyId]
		);

		React.useEffect(() => {
			passIsDirty?.(isDirty, dirtyFields);
		}, [passIsDirty, isDirty, dirtyFields]);

		React.useEffect(() => {
			if (!isNil(businessPlan) || !isNil(upsertedBusinessPlan)) {
				reset(mergeFieldValues(defaultValues, upsertedBusinessPlan, businessPlan));
			}
		}, [reset, defaultValues, upsertedBusinessPlan, businessPlan]);

		return (
			<Box
				{...otherProps}
				ref={ref}
				component='form'
				onSubmit={handleSubmit(handleFormSubmit)}
				sx={{ p: '2rem', width: '100%', maxWidth: { xs: '100%', md: '60rem' } }}
			>
				<Stack spacing={2} flexDirection='column' useFlexGap>
					<Typography sx={{ fontFamily: 'Aleo', fontWeight: 700, fontSize: '1.429rem', lineHeight: 1.3 }}>
						<FormattedMessage id='onboarding.step5.title' />
					</Typography>
					{isBusinessPlanError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchBusinessPlan.error.message' />
						</Alert>
					)}
					{isUpsertError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='mutation.error.title' />
							</AlertTitle>
							<FormattedMessage id='upsertBusinessPlan.error.message' />
						</Alert>
					)}
					{isBusinessPlanLoading ? (
						<Skeleton height={500} variant='rectangular' />
					) : (
						<Box>
							<Stack spacing={1} flexDirection='row' justifyContent='space-between' alignItems='center'>
								{/* @ts-ignore */}
								<PanelTitle>
									<FormattedMessage id='businessplan.pitch' />
								</PanelTitle>
								<Box>
									{/* @ts-ignore */}
									<CustomTooltip
										title={formatMessage({ id: 'businessplan.pitch.instructions' })}
										placement='top'
										enterTouchDelay={0}
										leaveTouchDelay={2000}
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 200 }}
									>
										<IconButton disableRipple>
											<HelpOutlineIcon />
										</IconButton>
									</CustomTooltip>
									<AdminContentContainer>
										<AiChatWrapper chatId='businessplan.pitch' />
									</AdminContentContainer>
								</Box>
							</Stack>
							{/* @ts-ignore */}
							<PanelSubtitle>
								<FormattedMessage id='businessplan.pitch.description' />
							</PanelSubtitle>
							{/* @ts-ignore */}
							<FormControlledWysiwyg
								inline={true}
								toolbar={true}
								name='pitch'
								control={control}
								error={errors?.pitch?.message}
								renderTextIfNotHovered={true}
								placeholder={formatMessage({ id: 'businessplan.pitch.placeholder' })}
								charactersLimit={30000}
								minHeight='15rem'
							/>
						</Box>
					)}
					{isBusinessPlanLoading ? (
						<Skeleton height={40} variant='rectangular' />
					) : (
						<Stack direction='row' spacing={2}>
							{/* @ts-ignore */}
							<Button
								color='primary'
								variant='outlined'
								sx={{ p: '0.64286rem 1.21429rem', lineHeight: '1.14286rem', fontSize: '1rem' }}
								onClick={onPrevStepClick}
								fullWidth
							>
								<FormattedMessage id='onboarding.step.prev' />
							</Button>
							{/* @ts-ignore */}
							<Button
								color='primary'
								variant='contained'
								sx={{ p: '0.64286rem 1.21429rem', lineHeight: '1.14286rem', fontSize: '1rem' }}
								loading={isUpsertLoading}
								type='submit'
								fullWidth
							>
								<FormattedMessage id='onboarding.step.next' />
							</Button>
						</Stack>
					)}
				</Stack>
			</Box>
		);
	}
);

export default OnboardingStep5;
