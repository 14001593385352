import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    contentWrapper: {
        flex: 1,
    },
    contentContainer: {
        height: '100%',
    },
    title: {
        fontSize: '1.8rem',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
    },
    container: {
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
    },
    textContainer: {
        height: '100%',
    },
    textItemLeft: {
        textAlign: 'right',
        marginRight: theme.spacing(2),
    },
    textItemRight: {
        textAlign: 'left',
        marginLeft: theme.spacing(2),
    },
    subtitle: {
        fontWeight: 600,
    },
    subtitleSmDown: {
        fontWeight: 600,
        paddingTop: theme.spacing(2),
    },
    imageWrapper: {
        textAlign: 'center',
    },
    image: {
        maxWidth: '400px',
    },
    textBox: {
        marginTop: theme.spacing(4),
    },
    grants: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        paddingBottom: theme.spacing(4),
        color: theme.palette.primary.main,
    },
}));

const Slide9 = ({ maxHeight }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container direction='column' alignItems='center' className={classes.root} style={{ maxHeight }}>
            <Grid item className={classes.contentWrapper}>
                <Grid container direction='column' justifyContent='center' className={classes.contentContainer}>
                    <Grid item>
                        <Box textAlign='center'>
                            <Typography className={classes.title}>Ratkaisuna GrantedAI - PRO</Typography>
                        </Box>
                        <Grid container className={classes.container}>
                            <Grid item xs={12} md={4}>
                                <Grid container direction='column' justifyContent='space-between' className={classes.textContainer}>
                                    <Grid item className={classes.textItemLeft}>
                                        <Typography className={classes.subtitle}>Tiedonjako</Typography>
                                        <Typography>
                                            Jaa käyttöoikeuksia GrantedAI:ssa ja esitä rahoituspolkusi keskeisille sidosryhmille.
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.textItemLeft}>
                                        <Typography className={classes.subtitle}>Kohti rahoitupäätöstä</Typography>
                                        <Typography>Asiantuntijamme ovat jatkuvasti klikkauksen päässä, kun heitä tarvitset.</Typography>
                                    </Grid>
                                    <Grid item className={classes.textItemLeft}>
                                        <Typography className={classes.subtitle}>Opi myös muiden virheistä</Typography>
                                        <Typography>GrantedAI listaa helposti hahmotettavassa muodossa sopivimmat vaihtoehtosi.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <img src='fromSlides/9.png' alt='slide9' className={classes.image} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container direction='column' justifyContent='space-between' className={classes.textContainer}>
                                    <Grid item className={classes.textItemRight}>
                                        <Typography className={classes.subtitle}>Scoring</Typography>
                                        <Typography>
                                            Granted Score kertoo yksinkertaisella numerolla yrityksesi kasvurahoituskelpoisuuden.
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.textItemRight}>
                                        <Typography className={classes.subtitle}>Aina ajantasalla</Typography>
                                        <Typography>Tutka ilmoittaa, kun uusi instrumentti on mahdollisuuksiesi piirissä.</Typography>
                                    </Grid>
                                    <Grid item className={classes.textItemRight}>
                                        <Typography className={classes.subtitle}>Tieto lukuisista instrumenteista</Typography>
                                        <Typography>GrantedAI tutkii jatkuvasti lukuisia kasvurahoituslähteitä.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography className={classes.grants}>{intl.formatMessage({ id: 'common.grants' })}</Typography>
            </Grid>
        </Grid>
    );
};

export default Slide9;
