import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { TextField } from '@mui/material';
import GAITextField from './FormStyledTextfield';

import FormError from './FormError';
import FormLabel from './FormLabel';
import InstructionBox from './InstructionBox/InstructionBox';

/**
 * Controlled Currency field, dependencies hoook-form, mui
 *
 * @param {defaultValue} defaultValue
 * @param {name} name
 * @param {control} control form control object from react-hook-forms
 * @param {error} error
 * @param {label} label
 * @param {allowNegative} allowNegative
 * @returns
 */

const FormControlledCurrencyInput = ({
	defaultValue,
	name,
	control,
	error,
	label,
	allowNegative = false,
	setInstruction = null,
	instructions = null,
	decimalScale = 0,
	sx = {},
	onInputBlur = () => { },
	variant = 'outlined',
	useMuiTextField = false,
	...props
}) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<FormControl fullWidth sx={sx}>
			{label && <FormLabel>{label}</FormLabel>}
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field: { onChange, value } }) => {
					return (
						<NumericFormat
							value={value}
							onValueChange={values => onChange(values.floatValue)}
							onFocus={() => {
								if (setInstruction) {
									setInstruction({ text: instructions, top });
								}

								setIsActive(true);
							}}
							onBlur={() => {
								if (setInstruction) {
									setInstruction('');
								}
								setIsActive(false);
								onInputBlur();
							}}
							customInput={useMuiTextField ? TextField : GAITextField}
							variant={variant}
							autoComplete='on'
							thousandSeparator=' '
							decimalSeparator=','
							decimalScale={decimalScale}
							prefix='€  '
							fixedDecimalScale={true}
							//isNumericString={false}
							allowNegative={allowNegative}
							{...props}
						/>
					);
				}}
			/>
			<FormError error={error} />
			{isActive && <InstructionBox value={instructions ? instructions : null} />}
		</FormControl>
	);
};

export default FormControlledCurrencyInput;
