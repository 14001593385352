import axios from 'utils/axios';
import { buildMigrationUri, buildMigrationPlainUri, validateHttpStatus } from 'utils/helpers/uriHelper';

class MigrationApi {
    // company
    async getCompanyData(companyId) {
        const url = buildMigrationUri(companyId, 'company', null, null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    // scenario
    async getScenario(companyId) {
        const url = buildMigrationUri(companyId, 'scenario', null, null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveScenario(companyId, scenarios) { // api/scenario/G-20220708-1
        const url = buildMigrationUri(companyId, 'scenario', null, null);
        const response = await axios.post(url, scenarios, { validateStatus: validateHttpStatus });

        return response.data;
    }

    // business plan
    async getMain(companyId) {
        const url = buildMigrationUri(companyId, 'businessplan', null, null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveMain(companyId, data) {
        const url = buildMigrationUri(companyId, 'businessplan', null, null);
        const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getInfoItems(companyId) {
        const url = buildMigrationUri(companyId, 'businessplan', 'info', null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveInfoItem(companyId, infoItem) { // http://localhost:5050/api/businessplan/3173449-6/info
        const url = buildMigrationUri(companyId, 'businessplan', 'info', null);
        const response = await axios.post(url, infoItem, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getTeamMembers(companyId) {
        const url = buildMigrationUri(companyId, 'businessplan', 'team', null, null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveTeamMember(companyId, teamMember) {
        const url = buildMigrationUri(companyId, 'businessplan', 'team', null);
        const response = await axios.post(url, teamMember, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getEconomicalData(companyId) {
        const url = buildMigrationUri(companyId, 'businessplan', 'economical', null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveEconomicalData(companyId, data) {
        const url = buildMigrationUri(companyId, 'businessplan', 'economical', null);
        const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getTemplates() {
        const url = buildMigrationPlainUri('businessplan', 'templates', null, null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async loadTags() {
        const url = buildMigrationPlainUri('businessplan', 'constants/tags', null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getAttachments(companyId) {
        const url = buildMigrationUri(companyId, 'businessplan', 'attachments', null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveAttachment(companyId, attachment) {
        const url = buildMigrationUri(companyId, 'businessplan', 'attachment', null);
        const response = await axios.post(url, attachment, { validateStatus: validateHttpStatus });

        return response.data;
    }

    // financial plan
    async getFinancialPlan(companyId) {
        const url = buildMigrationUri(companyId, 'financialplan', 'document', null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getFinancialPlanPublished(companyId) {
        const url = buildMigrationUri(companyId, 'financialplan', 'document/published', null);

        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getFinancialPlanInstruments(companyId) {
        const url = buildMigrationUri(companyId, 'financialplan', 'instruments', null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveFinancialPlanData(companyId, data) {
        const url = buildMigrationUri(companyId, 'financialplan', 'document', null);
        const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async publishFinancialPlanData(companyId, data) {
        const url = buildMigrationUri(companyId, 'financialplan', 'document/publish', null);
        const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

        return response.data;
    }

    // kanban
    async getTimeline(companyId) {
        const url = buildMigrationUri(companyId, 'project', 'timeline', null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getProjects(companyId) {
        const url = buildMigrationUri(companyId, 'project', 'projects', null);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async saveProjectWithAttachments(companyId, project, draft) { // api/project/2967478-9/project/attachments?draft=true
        const url = buildMigrationUri(companyId, 'project', 'project/attachments', draft);
        const response = await axios.post(url, project, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async removeProject(companyId, projectId) { // api/project/3173449-6/project/2517646287753348760_2517529741821195074
        const url = buildMigrationUri(companyId, 'project', `project/${projectId}`);
        const response = await axios.delete(url, { validateStatus: validateHttpStatus });

        return response.data;
    }
}

export const migrationApi = new MigrationApi();