import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import FormatCurrency from 'components/Formatters/FormatCurrency';

const StyledKanbanColumn = styled(Paper)(({ transparent }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: transparent,
    boxShadow: 'none',
    //borderRadius: '.5rem',
}));

const ColumnTitle = styled(Box)(() => ({
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    padding: '0.5rem 0',
}));

const KanbanColumn = ({ width = '100%', name, children, footer, transparent, sum, sx, ...other }) => {
    const theme = useTheme();
    const between600and650 = useMediaQuery(theme.breakpoints.between(600, 650));

    return (
        <Box sx={{ overflow: 'auto', ...sx }} {...other}>
            <StyledKanbanColumn
                transparent={transparent ? 'transparent' : theme.palette.primary.secondary}
                sx={{
                    width: {
                        xs: width,
                        sm: width,
                    },
                }}
            >
                <Grid container justify='space-between' alignItems='center'>
                    <Grid item sx={{ flex: 1 }}>
                        <ColumnTitle>
                            <Typography sx={{ textTransform: 'uppercase', pl: 2, fontSize: between600and650 ? '0.9rem' : '1rem' }}>{name}</Typography>
                        </ColumnTitle>
                    </Grid>
                    <Grid item>
                        <ColumnTitle style={{ paddingRight: '1rem', fontSize: between600and650 ? '0.9rem' : '1rem' }}>
                            {sum ? <FormatCurrency value={sum} /> : null}
                        </ColumnTitle>
                    </Grid>
                </Grid>
                {children}
                {footer &&
                    <Box sx={{ p: 2 }}>
                        {footer}
                    </Box>
                }
            </StyledKanbanColumn>
        </Box>
    );
};

export default KanbanColumn;
