import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import ButtonSpinner from 'components/Button/ButtonSpinner';

import { CompanyLicensePanel, CompanyDataPanel, CompanyAddressPanel } from './Panels';
import { getCompanyDetails } from '../slices';
import CompanyNotesList from 'containers/Admin/UsersCompany/CompanyDetails/CompanyNotes/CompanyNotesList';

const useStyles = makeStyles(theme => ({
	accordionSummary: {
		margin: 0,
		// boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
		'& .MuiAccordionSummary-content': {
			margin: '1rem 0.5rem',
		},
		'&.Mui-expanded': {
			minHeight: theme.spacing(6),
		},
	},
	header: {
		fontSize: '1.2rem',
		fontWeight: 600,
		color: theme.palette.primary.main,
	},
	lineBetweenSummaryAndDetails: {
		height: theme.spacing(0.25),
		backgroundColor: theme.palette.primary.secondary,
	},
}));

const CompanyDetailsDashboard = ({ children, accordionOpen = false, liteScore }) => {
	const classes = useStyles();
	const intl = useIntl();
	const params = useParams();
	const dispatch = useDispatch();

	const admin = useSelector(state => state.admin);
	const companyDataLoading = admin?.companyDataLoading;
	const company = admin?.companyData;
	const companyDetailsLoading = admin?.companyDetailsLoading;
	const companyDetails = admin?.companyDetails;
	const companyId = company?.companyId?.trim();

	const getLiteScore = score => {
		if (score > 70) return intl.formatMessage({ id: 'score.lite.high' });
		if (score <= 70 && score >= 30) return intl.formatMessage({ id: 'score.lite.medium' });
		if (score < 30) return intl.formatMessage({ id: 'score.lite.low' });
	};

	useEffect(() => {
		if (!company || Object.keys(company).length === 0 || companyId !== params?.companyId) {
			dispatch(getCompanyDetails(params?.companyId));
		}
	}, [company, companyId, params, dispatch]);

	const companyLicense = companyDetails?.companyLicense;

	const [expanded, setExpanded] = React.useState(accordionOpen);

	return (
		<Accordion elevation={0} expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ width: '100%' }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls='admin-content'
				id='admin-header'
				className={classes.accordionSummary}
			>
				<Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={2}>
					<Box sx={{ pt: 1 }}>
						{companyLicense?.type
							? `${intl.formatMessage({ id: 'select.label.licenseType' })}: ${companyLicense?.type}`
							: `${intl.formatMessage({ id: 'select.label.licenseType' })}: ${intl.formatMessage({
									id: 'companydetails.no.companylicense',
							  })}`}
					</Box>
					<Box>{children}</Box>
				</Stack>
			</AccordionSummary>
			<div className={classes.lineBetweenSummaryAndDetails} />
			<AccordionDetails sx={{ p: 3 }}>
				<Grid container direction='row'>
					<Grid item xs={12} sx={{ mb: '1rem' }}>
						{liteScore?.loading || !liteScore?.isLoaded ? (
							<ButtonSpinner />
						) : (
							<div>
								{getLiteScore(liteScore?.score?.liteScore)}&nbsp;{liteScore?.score?.liteScore}
							</div>
						)}
					</Grid>
					<Grid item xs={12} md={4}>
						<CompanyLicensePanel loading={companyDetailsLoading} companyLicense={companyLicense} classes={classes} />
					</Grid>
					<Grid item xs={12} md={4}>
						<CompanyDataPanel loading={companyDataLoading} companyData={company} classes={classes} />
					</Grid>
					<Grid item xs={12} md={4}>
						<CompanyAddressPanel loading={companyDataLoading} address={company?.address} classes={classes} />
					</Grid>
				</Grid>
				<CompanyNotesList />
			</AccordionDetails>
		</Accordion>
	);
};

export default CompanyDetailsDashboard;
