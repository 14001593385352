import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { Company, FreemiumAccount } from 'types/dataTypes';
import { Tags } from 'types/enumTypes';

type RegisterFreemiumAccount = {
	account: Partial<FreemiumAccount> | null;
	captchaToken: string | null;
};

type SearchCompanies = {
	searchTerm: string | null;
	captchaToken: string | null;
};

const baseUrl = 'registration';

export const registrationV2Api = createApi({
	reducerPath: 'registrationV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.CompanySearch],
	endpoints: builder => ({
		registerFreemiumAccount: builder.mutation<any, RegisterFreemiumAccount>({
			query: ({ account, captchaToken }) => {
				if (isNil(account) || isNil(captchaToken)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: '/',
					data: account,
					headers: { xCaptchaToken: captchaToken },
				};
			},
		}),
		searchCompanies: builder.query<Company[], SearchCompanies>({
			query: ({ searchTerm, captchaToken }) => {
				if (isNil(searchTerm) || isNil(captchaToken)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/company/${searchTerm}`,
					headers: { xCaptchaToken: captchaToken },
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.CompanySearch, id: params.searchTerm ?? 'LIST' }];
			},
		}),
	}),
});

export const { useRegisterFreemiumAccountMutation, useSearchCompaniesQuery, useLazySearchCompaniesQuery } = registrationV2Api;
