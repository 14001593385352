import { MaterialReactTable } from 'material-react-table';
import { Financial } from '../types';

export const FinancialTable: React.FC<{ data: Array<Financial> }> = ({ data, ...otherProps }) => {
	if (!data || data.length === 0) return null;

	return (
		<MaterialReactTable
			{...otherProps}
			data={data ?? []}
			columns={[
				{
					accessorKey: 'Year',
					header: 'Year',
					size: 150,
				},
				{
					accessorKey: 'BalanceSheetTotal',
					header: 'BalanceSheetTotal',
					size: 150,
				},
				{
					accessorKey: 'CurrentRatio',
					header: 'CurrentRatio',
					size: 150,
				},
				{
					accessorKey: 'Equity',
					header: 'Equity',
					size: 150,
				},
				{
					accessorKey: 'OperatingMargin',
					header: 'OperatingMargin',
					size: 150,
				},
				{
					accessorKey: 'OperatingProfit',
					header: 'OperatingProfit',
					size: 150,
				},
				{
					accessorKey: 'Profit',
					header: 'Profit',
					size: 150,
				},
				{
					accessorKey: 'QuickRatio',
					header: 'QuickRatio',
					size: 150,
				},
				{
					accessorKey: 'Staff',
					header: 'Staff',
					size: 150,
				},
				{
					accessorKey: 'StaffChange',
					header: 'StaffChange',
					size: 150,
				},
				{
					accessorKey: 'Turnover',
					header: 'Turnover',
					Cell: ({ cell }) =>
						(cell.getValue<number>() * 1000).toLocaleString('fi', {
							style: 'currency',
							currency: 'EUR',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						}),
					filterVariant: 'range-slider',
					filterFn: 'betweenInclusive', // default (or between)
					muiFilterSliderProps: {
						marks: true,
						max: 200000,
						min: 0,
						step: 10000,
						valueLabelFormat: value =>
							value.toLocaleString('fi', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							}),
					},
				},
				{
					accessorKey: 'TurnoverChange',
					header: 'TurnoverChange',
					size: 150,
				},
				{
					accessorKey: 'TurnoverPerPerson',
					header: 'TurnoverPerPerson',
					size: 150,
				},
			]}
		/>
	);
};
