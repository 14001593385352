import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import ConfirmButton from 'components/Button/ConfirmButton';
import ContentContainer from 'components/Containers/ContentContainer';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Spinner from 'components/Spinner/Spinner';
import { CompanyRolesTable, LicensesTable } from '../SharedTables';
import { UserActivationPanel, UserContactPanel, UserInformationPanel } from './Panels';
import { ClaimsTable, ContractsTable, RolesTable, UserActivationsTable } from './Tables';

import { Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { deactivateUser, deleteCompanyRoles, getUserDetails, sendActivationLinkToUser } from '../userSlice';

const useStyles = makeStyles(theme => ({
	title: {
		fontSize: '1.2rem',
		fontWeight: 600,
		color: theme.palette.primary.main,
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: theme.palette.primary.main,
	},
	itemRow: {
		paddingBottom: theme.spacing(0.5),
	},
	itemKey: {
		whiteSpace: 'nowrap',
		fontWeight: 500,
		color: theme.palette.primary.main,
	},
	itemProp: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: theme.palette.primary.main,
	},
	detailsButton: {
		'&:hover': {
			background: theme.palette.secondary.main,
		},
	},
}));

const UserDetails = () => {
	const classes = useStyles();
	const intl = useIntl();
	const history = useHistory();
	const { userId } = useParams();
	const dispatch = useDispatch();

	const admin = useSelector(state => state.users);
	const userLoading = admin?.userDetailsLoading;
	const userDetails = admin?.userDetails;
	const user = userDetails?.user;

	useEffect(() => {
		if (!userDetails || !user || user.id !== userId) {
			dispatch(getUserDetails(userId));
		}
	}, [userDetails, user, userId, dispatch]);

	const userContact = userDetails?.userContact;
	const roles = user?.roles ?? [];
	const companyRoles = user?.companyRoles ?? [];
	const claims = user?.claims ? user.claims.map(claim => ({ ...claim, id: claim.rowKey })) : [];
	// const logins = user?.logins ? user.logins.map(login => ({ ...login, id: login.timestamp })) : [];
	const userActivations = userDetails?.userActivations
		? userDetails.userActivations.map(activation => ({ ...activation, id: activation.rowKey }))
		: [];

	const userActivation = userDetails?.userActivation;

	const clientContracts = userDetails?.clientContracts
		? userDetails?.clientContracts.map(contract => ({ ...contract, id: `${contract.rowKey}_${contract.partitionKey}` }))
		: [];

	const acceptedClientContracts = userDetails?.acceptedClientContracts
		? userDetails?.acceptedClientContracts.map(contract => ({ ...contract, id: `${contract.rowKey}_${contract.partitionKey}` }))
		: [];

	const companyLicenses = userDetails?.companyLicenses
		? userDetails?.companyLicenses.map(license => ({ ...license, id: license.rowKey }))
		: [];

	const authoredCompanyLicenses = userDetails?.authoredCompanyLicenses
		? userDetails?.authoredCompanyLicenses.map(license => ({ ...license, id: license.rowKey }))
		: [];

	const fetchCompanyDetails = companyId => history.push(`/admin/company/${companyId}`);

	const fetchUserDetails = userId => history.push(`/admin/user/${userId}`);

	const handleDeactivateUser = user => dispatch(deactivateUser(user));

	const handleDeleteCompanyRoles = selectedRoles => dispatch(deleteCompanyRoles(null, userId, selectedRoles));

	const handleSendReactivationLink = async user => {
		const ret = await dispatch(sendActivationLinkToUser(user.userName));

		if (ret === true) {
			toast.success(intl.formatMessage({ id: 'user.sendactivation.send.success' }));

			return;
		}

		toast.error(intl.formatMessage({ id: 'user.sendactivation.send.fail' }));
	};

	return (
		<ContentContainer>
			<LeftRightContainer
				left={
					userLoading ? (
						<Spinner size={16} />
					) : (
						<Typography
							variant='h2'
							color='primary'
							sx={{
								fontSize: '1.4rem',
								fontWeight: 700,
							}}
						>
							{user?.id}
						</Typography>
					)
				}
				right={
					<Stack direction='row' spacing={2}>
						<ConfirmButton
							size='small'
							color='error'
							disabled={userLoading || !user?.isActive}
							startIcon={<PersonRemoveIcon size='small' />}
							confirmAction={() => handleDeactivateUser(user)}
							confirmText={intl.formatMessage({ id: 'user.deactivate.confirm' })}
							cancelText={intl.formatMessage({ id: 'user.deactivate.cancel' })}
							confirmBodyText={intl.formatMessage({ id: 'user.deactivate.body' })}
							buttonText={intl.formatMessage({ id: 'companydetails.users.deactivate' })}
						/>

						<ConfirmButton
							size='small'
							color='success'
							disabled={userLoading || !user || user?.isActive}
							startIcon={<MailOutlineIcon size='small' />}
							confirmAction={() => handleSendReactivationLink(user)}
							confirmText={intl.formatMessage({ id: 'user.sendactivation.confirm' })}
							cancelText={intl.formatMessage({ id: 'user.sendactivation.cancel' })}
							confirmBodyText={intl.formatMessage({ id: 'user.sendactivation.body' })}
							buttonText={intl.formatMessage({ id: 'user.sendactivation.button' })}
						/>
					</Stack>
				}
			/>
			<Grid container direction='row' spacing={2}>
				<Grid item xs={12} md={4}>
					<UserInformationPanel loading={userLoading} classes={classes} user={user} />
				</Grid>
				<Grid item xs={12} md={4}>
					<UserContactPanel loading={userLoading} classes={classes} userContact={userContact} user={user} />
				</Grid>
				<Grid item xs={12} md={4}>
					<UserActivationPanel loading={userLoading} classes={classes} userActivation={userActivation} />
				</Grid>
			</Grid>
			<RolesTable loading={userLoading} data={roles} classes={classes} />
			<CompanyRolesTable
				loading={userLoading}
				data={companyRoles}
				classes={classes}
				fetchCompanyDetails={fetchCompanyDetails}
				handleDeleteCompanyRoles={handleDeleteCompanyRoles}
			/>
			<UserActivationsTable loading={userLoading} data={userActivations} classes={classes} />
			<ClaimsTable loading={userLoading} data={claims} classes={classes} />
			{/* <LoginsTable
                loading={userLoading}
                data={logins}
                classes={classes}
            /> */}
			<ContractsTable
				loading={userLoading}
				data={clientContracts}
				userId={userId}
				classes={classes}
				fetchCompanyDetails={fetchCompanyDetails}
				fetchUserDetails={fetchUserDetails}
				title='clientContracts'
			/>
			<ContractsTable
				loading={userLoading}
				data={acceptedClientContracts}
				userId={userId}
				classes={classes}
				fetchCompanyDetails={fetchCompanyDetails}
				fetchUserDetails={fetchUserDetails}
				title='acceptedClientContracts'
			/>
			<LicensesTable
				loading={userLoading}
				data={companyLicenses}
				userId={userId}
				classes={classes}
				fetchCompanyDetails={fetchCompanyDetails}
				fetchUserDetails={fetchUserDetails}
				title='companyLicenses'
				noDataMessage={intl.formatMessage({ id: 'userdetails.no.companyLicenses' })}
			/>
			<LicensesTable
				loading={userLoading}
				data={authoredCompanyLicenses}
				userId={userId}
				classes={classes}
				fetchCompanyDetails={fetchCompanyDetails}
				fetchUserDetails={fetchUserDetails}
				title='authoredCompanyLicenses'
				noDataMessage={intl.formatMessage({ id: 'userdetails.no.authoredCompanyLicenses' })}
			/>
		</ContentContainer>
	);
};

export default UserDetails;
