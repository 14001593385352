import React from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const InstrumentTagItem = ({ item, highlightedItem, lastItem = false }) => {
    const intl = useIntl();

    return (
        <Tooltip title={highlightedItem ? `${intl.formatMessage({ id: `${item}` })} ${intl.formatMessage({ id: 'instrument.object.tag.hightlight' })}` : ''} >
            <Typography
                component='span'
                sx={{
                    fontSize: highlightedItem ? '0.9rem' : '0.8rem',
                    color: 'secondary.grey500',
                    mt: 1,
                    fontWeight: highlightedItem ? '600' : 'normal',
                }}
            >
                {lastItem ? item : `${item}, `}
            </Typography>
        </Tooltip>
    );
};

export default InstrumentTagItem;