import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { licenseExpired, notFound } from 'config/routeConst';
import { regexAdmin, regexInstrument, regexProfile } from 'utils/constants/regex';
import { matchPath } from 'utils/helpers/pathMatch';

const useStyles = makeStyles(theme => ({
	link: {
		display: 'flex',
		textDecoration: 'none',
		'& p, svg': {
			color: '#6D667F',
		},
		'&:hover, &:focus': {
			textDecoration: 'underline',
			textDecorationColor: theme.palette.primary.main,
			'& p, svg': {
				color: theme.palette.primary.main,
			},
			'& svg': {
				transform: 'scale(1.2)',
			},
		},
	},
	last: {
		cursor: 'default',
		color: '#6D667F',
	},
	icon: {
		color: '#6D667F',
	},
	iconHome: {
		color: theme.palette.primary.main,
	},
}));

const CustomizedBreadcrumbs = ({ pathname, companyId, companyName, isContributor, isAdvanced, pageProps }) => {
	const intl = useIntl();
	const classes = useStyles();
	const location = useLocation();

	const theme = useTheme();
	const smdDown = useMediaQuery(theme.breakpoints.down('smd'));
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
	const lgxDown = useMediaQuery(theme.breakpoints.down('lgx'));

	const staticAnalysis = matchPath('/analysis/:id', location, true);

	// popover
	const [anchorEl, setAnchorEl] = useState(null);
	const [openedPopoverIndex, setOpenedPopoverIndex] = useState(null);
	const handlePopoverOpen = (event, index) => {
		setAnchorEl(event.currentTarget);
		setOpenedPopoverIndex(index);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
		setOpenedPopoverIndex(null);
	};

	if (
		pathname === '/' ||
		pathname === '/agreement' ||
		pathname === '/search' ||
		pathname === '/admin/search' ||
		pathname === '/customers' ||
		pathname === licenseExpired ||
		pathname === notFound ||
		pathname === '/nocompany' ||
		//pathname === `/company/${companyId}` ||
		regexProfile.test(pathname) ||
		staticAnalysis ||
		regexInstrument.test(pathname) ||
		regexAdmin.test(pathname)
	) {
		return null;
	}

	const ignorePath = (path) => {
		if (path.toLowerCase() === 'company') return true;

		return false;
	};

	const pathnames = pathname.split('/').filter(i => i);

	// if (pathnames[0] === 'company' && pathnames[1] === companyId) {
	//     pathnames.splice(0, 2);
	// }

	if (pageProps) {
		pageProps.push({ id: companyId, name: companyName });
	} else {
		pageProps = [{ id: companyId, name: companyName }];
	}

	const CustomPopover = ({ index, text }) => {
		return (
			<Popover
				id='mouse-over-popover'
				open={openedPopoverIndex === index}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
				sx={{ pointerEvents: 'none', top: 25 }}
			>
				<Typography sx={{ py: 0.5, px: 1, fontSize: '0.9rem' }}>{text}</Typography>
			</Popover>
		);
	};

	const LinkText = ({ index, text }) => {
		const ref = useRef();
		const [width, setWidth] = useState(null);

		useEffect(() => {
			setWidth(ref.current?.clientWidth);
		}, [text]);

		const maxWidth = 140; // 10rem

		return (
			<>
				<Typography
					ref={ref}
					sx={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						maxWidth: `${maxWidth}px`
					}}
				>
					{text}
				</Typography>
				{width === maxWidth && <CustomPopover index={index} text={text} />}
			</>
		);
	};

	return (
		<Breadcrumbs
			aria-label='breadcrumb'
			maxItems={smdDown ? 2 : mdDown ? 3 : lgDown ? 5 : lgxDown ? 7 : 8}
			itemsAfterCollapse={smdDown ? 1 : mdDown ? 2 : lgDown ? 4 : lgxDown ? 6 : 7}
			itemsBeforeCollapse={1}
			separator={<NavigateNextIcon fontSize='small' className={classes.icon} />}
			sx={{ pt: 2, pl: { xs: 1, md: 2, lg: 4 } }}
		>
			<Link to={isContributor ? '/admin/search' : isAdvanced ? '/customers' : '/'} color='inherit' className={classes.link}>
				<HomeIcon fontSize='small' className={classes.icon} />
			</Link>
			{pathnames.map((path, index) => {
				const last = index === pathnames.length - 1;
				const to = `/${pathnames.slice(0, index + 1).join('/')}`;

				let item = null;

				if (pageProps) {
					const objFromArray = (arr, key = 'id') => arr.reduce((accumulator, current) => {
						accumulator[current[key]] = current;
						return accumulator;
					}, {});

					const items = objFromArray(pageProps, 'id');

					item = items[path];
				}

				const renderPath = item
					? item.name
					: intl.formatMessage({ id: `breadcrumb.${path.toLowerCase()}` });

				if (ignorePath(path)) return null;
				if (renderPath && renderPath.startsWith('breadcrumb.')) return null;

				return last
					? (
						<Typography key={index} color='primary' className={classes.last}>
							{renderPath}
						</Typography>
					)
					: (
						<Link
							key={index}
							to={to}
							aria-owns={open ? 'mouse-over-popover' : undefined}
							aria-haspopup='true'
							onMouseEnter={e => handlePopoverOpen(e, index)}
							onMouseLeave={handlePopoverClose}
							className={classes.link}
						>
							<LinkText index={index} text={renderPath} />
						</Link>
					);
			})}
		</Breadcrumbs>
	);
};

export default CustomizedBreadcrumbs;
