import React from 'react';
import ContentContainer from '../../../components/Containers/ContentContainer';
import { createClaimsTable } from './claimUtils';
import { Box, Stack, Typography } from '@mui/material';

export const IdTokenData = (props: any) => {
	const tokenClaims = createClaimsTable(props.idTokenClaims);

	const tableRow = Object.keys(tokenClaims).map((key, index) => {
		// @ts-ignore
		const claim: any = tokenClaims[key];

		return (
			<React.Fragment key={index}>
				<Stack direction='row'>
					<Typography sx={{ mr: '1rem', fontWeight: 600 }}>Claim:</Typography>
					<Box>{claim[0]}</Box>
				</Stack>
				<Stack direction='row'>
					<Typography sx={{ mr: '1rem', fontWeight: 600 }}>Value:</Typography>
					<Box sx={{ overflow: 'auto' }}>{claim[1]}</Box>
				</Stack>
				<Box sx={{ mb: '1rem' }}>{claim[2]}</Box>
			</React.Fragment>
		);
	});

	return (
		<>
			{/* @ts-ignore */}
			<ContentContainer>
				<Stack>{tableRow}</Stack>
			</ContentContainer>
		</>
	);
};
