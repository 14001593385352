import React from 'react';
import List from '@mui/material/List';

import MessageItem from './MessageItem';
import MessageNoItem from 'components/MessageNoItem';

const MessagesList = ({ messages, markMessageAsRead }) => {
    if (messages === undefined || messages.length === 0) {
        return (
            <MessageNoItem messageId='notification.no.messages' />
        );
    }

    return (
        <List>
            {messages?.map(({ rowKey, type, created, isRead, customData }, index) => (
                <MessageItem
                    key={rowKey}
                    index={index}
                    rowKey={rowKey}
                    message={type}
                    created={created}
                    isRead={isRead}
                    data={customData}
                    markMessageAsRead={markMessageAsRead}
                />
            ))}
        </List>
    );
};

export default MessagesList;
