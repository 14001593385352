import { isEmpty, isNil } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Autocomplete, AutocompleteProps } from '@mui/material';

import { Company } from 'types/dataTypes';

import { useLazySearchCompaniesQuery } from 'containers/Register/registrationV2Api';

type CustomOption = string | Partial<Company>;

type Props = {} & Omit<AutocompleteProps<CustomOption, false, false, false>, 'options'>;

const CompanySearchAutocomplete = React.forwardRef<any, Props>(({ ...otherProps }, ref) => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const [inputValue, setInputValue] = React.useState('');

	const [searchCompanies, { data: companies, isLoading: isSearchCompaniesLoading, isFetching: isSearchCompaniesFetching }] =
		useLazySearchCompaniesQuery();

	React.useEffect(() => {
		const timeout = setTimeout(async () => {
			const captchaToken = await executeRecaptcha?.('searchCompanies');
			if (!isEmpty(inputValue) && !isNil(captchaToken)) {
				searchCompanies({ searchTerm: inputValue, captchaToken });
			}
		}, 500);
		return () => clearTimeout(timeout);
	}, [inputValue, searchCompanies, executeRecaptcha]);

	return (
		<>
			<Autocomplete
				{...otherProps}
				loading={isSearchCompaniesFetching || isSearchCompaniesLoading}
				loadingText={<FormattedMessage id='common.autocomplete.loading' />}
				ref={ref}
				options={companies ?? []}
				onInputChange={(event, nextInputValue) => {
					setInputValue(nextInputValue);
				}}
				isOptionEqualToValue={(option, value) => {
					const companyId1 = typeof option === 'string' ? option : option?.companyId;
					const companyId2 = typeof value === 'string' ? value : value?.companyId;
					return companyId1 === companyId2;
				}}
				getOptionLabel={option => {
					return typeof option === 'string' ? option : `${option?.name} - ${option?.businessId}` ?? 'n/a';
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				autoHighlight
			/>
		</>
	);
});

export default CompanySearchAutocomplete;
