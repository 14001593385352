import * as React from 'react';
import { useParams } from 'react-router-dom';

import ContentContainer from 'components/Containers/ContentContainer';
import GuidesIndex from 'containers/Guides/GuidesIndex';

const GuidesLandingPage: React.FC = () => {
	const { guideId }: any = useParams();

	return (
		// @ts-ignore
		<ContentContainer>
			<GuidesIndex guideId={guideId} />
		</ContentContainer>
	);
};

export default GuidesLandingPage;
