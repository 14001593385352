import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import FinancialPlan from 'containers/FinancialPlan/FinancialPlan';
import ContentLoaderContainer from 'components/Containers/ContentLoaderContainer';
import MainTitle from 'components/Titles/MainTitle';

const FinancialPlanPage = () => {
    const intl = useIntl();
    const financialPlan = useSelector(state => state.financialPlan);
    const loading = financialPlan?.loading;

    const title = <MainTitle margin={false}>{intl.formatMessage({ id: 'timeline.edit' })}</MainTitle>;

    return (
        <ContentLoaderContainer surrogate={<FinancialPlan />} loading={loading}>
            <FinancialPlan title={title} loading={loading} />
        </ContentLoaderContainer>
    );
};

export default FinancialPlanPage;
