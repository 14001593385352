import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';

import ContentLoaderContainer from 'components/Containers/ContentLoaderContainer';
import AttachFileIcon from 'icons/AttachFile';
import DuplicateIcon from 'icons/Duplicate';
import bytesToSize from 'utils/converters/bytesToSize';

const useStyles = makeStyles(theme => ({
	dropBox: {
		border: `1px dashed ${theme.palette.secondary.border}`,
		borderRadius: '5px',
		padding: '2rem',
		'&:hover': {
			backgroundColor: theme.palette.primary.secondary,
			cursor: 'pointer',
		},
	},
	upload: {
		fontSize: '1rem',
		fontWeight: 500,
		marginTop: theme.spacing(2),
		color: theme.palette.secondary.grey600,
	},
	uploadLink: {
		color: theme.palette.success.main,
	},
	restrictions: {
		fontSize: '0.75rem',
		color: theme.palette.secondary.grey500,
	},
	error: {
		fontSize: '0.75rem',
		color: theme.palette.error.main,
	},
}));

const FileDropzone = ({ loading, files = [], error, onDrop, onRemove, ...other }) => {
	const classes = useStyles();
	const theme = useTheme();
	const intl = useIntl();
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	const uniqueFiles = files.filter((item, index, array) => array.findIndex(arr => arr.path === item.path) === index);

	const rootProps = loading === true ? null : getRootProps();

	return (
		<ContentLoaderContainer loading={loading} {...other}>
			<Box
				textAlign='center'
				className={classes.dropBox}
				sx={{
					mt: 2,
					backgroundColor: isDragActive && theme.palette.primary.secondary,
				}}
				{...rootProps}
			>
				<input {...getInputProps()} />
				<AttachFileIcon fontSize='large' />
				<Box sx={{ mt: 2 }}>
					<Typography className={classes.upload}>
						<Link underline='hover' className={classes.uploadLink}>
							{intl.formatMessage({ id: 'attachments.uploadfile' })}
						</Link>{' '}
						{intl.formatMessage({ id: 'attachments.uploadfile.instructions' })}
					</Typography>
					<Typography className={classes.restrictions}>{intl.formatMessage({ id: 'attachments.uploadfile.limit' })}</Typography>
				</Box>
			</Box>
			{uniqueFiles.length > 0 && (
				<Box sx={{ mt: 2 }}>
					<List>
						{uniqueFiles.map(file => (
							<ListItem key={file.path}>
								<ListItemIcon>
									<DuplicateIcon fontSize='small' />
								</ListItemIcon>
								<ListItemText
									primary={file.name}
									primaryTypographyProps={{
										color: 'textPrimary',
										variant: 'subtitle2',
									}}
									secondary={bytesToSize(file.size)}
								/>
								<Tooltip title='Remove'>
									<IconButton edge='end' onClick={() => onRemove && onRemove(file)}>
										<CloseIcon fontSize='small' />
									</IconButton>
								</Tooltip>
							</ListItem>
						))}
					</List>
				</Box>
			)}
			<Typography className={classes.error}>{error}</Typography>
		</ContentLoaderContainer>
	);
};

export default FileDropzone;
