import { Tooltip } from '@mui/material';

export const StyledTooltip: React.FC<any> = ({ children, title }) => {
	return (
		<Tooltip
			componentsProps={{
				tooltip: {
					sx: {
						mr: '1rem',
						right: '1rem',
						background: '#fff',
						color: 'rgba(0, 0, 0, 0.87)',
						fontSize: '.875rem',
						fontWeight: 300,
						p: '.5rem',
						whiteSpace: 'pre-line',
						'& .MuiTooltip-arrow': {
							color: '#fff',
						},
					},
				},
			}}
			placement='left-start'
			title={title}
			arrow
		>
			{children}
		</Tooltip>
	);
};
