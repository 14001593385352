import axios from 'utils/axios';
import { buildPlainUri, validateHttpStatus, } from 'utils/helpers/uriHelper';

const baseUrl = 'profile';

class ProfileApi {
    async getProfile(link) {
        const url = buildPlainUri(baseUrl, link);

        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }
}

export const profileApi = new ProfileApi();