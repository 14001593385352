import { MaterialReactTable } from 'material-react-table';
import { BFAdmitted } from '../types';

export const BFAdmittedTable: React.FC<{ data: Array<BFAdmitted> }> = ({ data, ...otherProps }) => {
	if (!data || data.length === 0) return null;

	return (
		<MaterialReactTable
			{...otherProps}
			data={data ?? []}
			columns={[
				{
					accessorKey: 'organisaatio',
					header: 'Organisaatio',
					size: 150,
				},
				{
					accessorKey: 'yTunnus',
					header: 'YTunnus',
					size: 150,
				},
				{
					accessorKey: 'myöntämisvuosi',
					header: 'Myöntämisvuosi',
					size: 150,
				},
				{
					accessorKey: 'avustus',
					header: 'Avustus',
					size: 150,
				},
				{
					accessorKey: 'laina',
					header: 'Laina',
					size: 150,
				},
				{
					accessorKey: 'eAKRRahoitus',
					header: 'EAKRRahoitus',
					size: 150,
				},
				{
					accessorKey: 'tutkimusrahoitus',
					header: 'Tutkimusrahoitus',
					size: 150,
				},
				{
					accessorKey: 'yhteensä',
					header: 'Yhteensä',
					size: 150,
				},
			]}
		/>
	);
};
