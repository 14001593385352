import axios from 'utils/axios';
import { validateHttpStatus, buildAdvancedCompanyUri } from 'utils/helpers/uriHelper';

const baseUrl = 'advanced';

class AdvancedApi {
    async createCustomerCompany(company) {
        const url = buildAdvancedCompanyUri(baseUrl, 'customer/company');
        const response = await axios.post(url, company, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getLists() {
        const url = buildAdvancedCompanyUri(baseUrl, 'lists'); // api/advanced/lists
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async getListById(listId) {
        const url = buildAdvancedCompanyUri(baseUrl, `lists/${listId}`);
        const response = await axios.get(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async addList(list) {
        const url = buildAdvancedCompanyUri(baseUrl, 'lists');
        const response = await axios.post(url, list, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async deleteListById(listId) {
        const url = buildAdvancedCompanyUri(baseUrl, `lists/${listId}`);
        const response = await axios.delete(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async deleteCompaniesFromList(listId, companies) {
        const url = buildAdvancedCompanyUri(baseUrl, `lists/${listId}/companies`);
        const response = await axios.delete(url, { data: companies }, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async addCompaniesToList(listId, companies) {
        const url = buildAdvancedCompanyUri(baseUrl, `lists/${listId}`);
        const response = await axios.post(url, companies, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async addImportantMark(customerCompanyId) {
        const url = buildAdvancedCompanyUri(baseUrl, `important/${customerCompanyId}`);
        const response = await axios.post(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    async removeImportantMark(customerCompanyId) {
        const url = buildAdvancedCompanyUri(baseUrl, `important/${customerCompanyId}`);
        const response = await axios.delete(url, { validateStatus: validateHttpStatus });

        return response.data;
    }

    // below is not used yet
    async updateListItem(listId, data) {
        const url = buildAdvancedCompanyUri(baseUrl, `lists/${listId}`);
        const response = await axios.put(url, data, { validateStatus: validateHttpStatus });

        return response.data;
    }
}

export const advancedApi = new AdvancedApi();