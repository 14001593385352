export type RfpProposalItemCost = {
	rfpProposalItemId: string | null;
	rfpItemCostId: string | null;
	costType: RfpCostType | null;
	costClass: RfpCostClass | null;
	price: number | null;
	priceMax: number | null;
	hourEstimate: number | null;
	hourEstimateMax: number | null;
	totalPrice: number | null;
	totalPriceMax: number | null;
	createDate: string | null;
	updateAuthor: string | null;
	updateDate: string | null;
	timestamp: string | null;
};

export interface ITeamMember {
	companyId: string | null;
	memberId: string | null;
	firstname: string | null;
	lastname: string | null;
	position: string | null;
	description: string | null;
	linkedInUrl: string | null | undefined;
	avatar: string | null;
	createDate: string | null;
	updateAuthor: string | null;
	updateDate: string | null;
	timestamp: string | null;
}

export type RfpProposalTeamMember = {
	rfpProposalId: string | null;
	companyId: string | null;
	teamMemberId: string | null;
	firstname: string | null;
	lastname: string | null;
	position: string | null;
	description: string | null;
	linkedInUrl: string | null | undefined;
	avatar: string | null;
	createDate: string | null;
	updateAuthor: string | null;
	updateDate: string | null;
	timestamp: string | null;
};

export type RfpProposalItem = {
	rfpProposalId: string | null;
	rfpProposalItemId: string | null;
	total: number | null;
	description: string | null;
	proposalItemCategory: string | null;
	rfpRequestItemId: string | null;
	requestItemTitle: string | null;
	costs: RfpProposalItemCost[] | null;
	createDate: string | null;
	updateAuthor: string | null;
	updateDate: string | null;
	timestamp: string | null;
};

export type RfpProposal = {
	companyId: string | null;
	companyName: string | null;
	rfpProposalId: string | null;
	status: RfpStatus | null;
	companyOverview: string | null;
	projectOutline: string | null;
	projectBudget: string | null;
	projectTimeline: string | null;
	contactInformation: string | null;
	requestId: string | null;
	createDate: string | null;
	updateAuthor: string | null;
	updateDate: string | null;
	timestamp: string | null;
	attachments: RfpAttachment[] | null;
	rfpProposalItems: RfpProposalItem[] | null;
	rfpProposalTeamMembers: RfpProposalTeamMember[] | null;
};

export type CompanyAddress = {
	city: string | null;
	country: string | null;
	endDate: string | null;
	language: string | null;
	postCode: string | null;
	registrationDate: string | null;
	source: string | null;
	street: string | null;
	type: number | null;
	version: number | null;
};

export type CompanyFunds = {
	ownerFunds: number | null;
	achievedFunds: number | null;
	supportFunds: number | null;
	ownerFundsDescription: string | null;
};

export type CompanyDescription = {
	businessDescription: string | null;
	previousInstruments: string | null;
	developmentCosts: string | null;
	generalEconomy: string | null;
	otherNotes: string | null;
};

export type CompanyChange = {
	changeType: string | null;
	changeTime: string | null;
	user: string | null;
};

export type CompanyName = {
	order: number | null;
	version: number | null;
	name: string | null;
	registrationDate: string | null;
	endDate: string | null;
	source: number | null;
};

export type Company = {
	companyId: string | null;
	businessId: string | null;
	name: string | null;
	companyForm: string | null;
	registrationDate: string | null;
	economicalData: any[] | null;
	address: CompanyAddress | null;
	created: string | null;
	isCustomer: boolean | null;
	detailsUri: string | null;
	ratingAlpha: string | null;
	companyStatus: string | null;
	countrySide: string | null;
	hasIPR: boolean | null;
	iprDescription: string | null;
	internationalization: string | null;
	personnelSize: number | null;
	isGenerated: boolean | null;
	author: string | null;
	authorCompany: string | null;
	robotData: string | null;
	funds: CompanyFunds | null;
	descriptions: CompanyDescription | null;
	changes: CompanyChange[] | null;
	names: CompanyName[] | null;
	auxiliaryNames: string[] | null;
	addresses: string[] | null;
	businessLines: string[] | null;
	registedOffices: string[] | null;
	contactDetails: string[] | null;
	registeredEntries: string[] | null;
	businessIdChanges: string[] | null;
	responsiblePersons: string[] | null;
	ownership: string | null;
	hasTaxDepth: string | null;
};

export type CompanyCustomer = {
	companyId: string | null;
	isCustomer: boolean | null;
	businessId: string | null;
	name: string | null;
	profilingFlowFinished: boolean | null;
	hostCompanyId: string | null;
	referenceUrl: string | null;
	referenceId: string | null;
};

export type RfpAttachment = {
	attachmentId: string | null;
	attachmentParentType: string | null;
	attachmentParentid: string | null;
	contentType: string | null;
	length: number | null;
	name: string | null;
	fileName: string | null;
	base64Data: string | null;
};

export type RfpRequestItem = {
	rfpRequestId: string | null;
	rfpRequestItemId: string | null;
	requestItemCategory: string | null;
	requestItemTitle: string | null;
	requestItemDescription: string | null;
	budget: number | null;
	author: string | null;
	createDate: string | null;
	updateAuthor: string | null;
	updateDate: string | null;
};

export type RfpRequest = {
	companyId: string | null;
	companyName: string | null;
	rfpRequestId: string | null;
	status: RfpStatus | null;
	responseDeadline: string | null;
	attachedProjectId?: string | null;
	attachedProjectCostId?: string | null;
	projectTitle: string | null;
	projectIngress: string | null;
	projectDescription: string | null;
	projectOverview: string | null;
	projectTimeframe: string | null;
	projectTimeStart: string | null;
	projectTimeEnd: string | null;
	projectChallengesAndGoals: string | null;
	projectRequirementsAndEvaluationCriteria: string | null;
	projectContactDetail: string | null;
	projectProposalSubmissionRequirements: string | null;
	projectBudget: number | null;
	offersCount: number | null;
	requestCategories: string | null;
	requestCategoriesArray: string[] | null;
	author: string | null;
	createDate: string | null;
	updateAuthor: string | null;
	updateDate: string | null;
	isTargetedRequest: boolean | null;
	proposalRequestTargetCompanies: string[] | null;
	rfpRequestItems: RfpRequestItem[] | null;
	attachments: RfpAttachment[] | null;
	proposals: RfpProposal[] | null;
	expired: boolean | null;
};

export type CompanyRequests = {
	drafts: RfpRequest[];
	published: RfpRequest[];
};

export type RfpProfileTeamMember = {
	teamMemberId: string | null;
	avatar: string | null;
	description: string | null;
	firstname: string | null;
	lastname: string | null;
	linkedInUrl: string | null;
	position: string | null;
};

export type RfpProfileInfoItem = {
	infoId: string | null;
	title: string | null;
	value: string | null;
};

export type RfpProfileSocialMediaProfile = {
	service: SocialMediaService | null;
	url: string | null;
	altName: string | null;
};

export type EconomicalInfo = {
	year: number;
	month: number;
	turnover: number;
	turnoverChange: number;
	operatingMargin: number;
	operatingProfit: number;
	profit: number;
	profits: number;
	quickRatio: number;
	currentRatio: number;
	equity: number;
	balanceSheetTotal: number;
	balance: number;
	staff: number;
	employees: number;
	staffChange: number;
	turnoverPerPerson: number;
	solvency: number;
	equityRatio: number;
	ebitda: number;
	ebitdas: number;
	ebit: number;
	magic40: number;
};

export type RfpProfile = {
	companyId: string | null;
	companyIngress: string | null;
	companyName: string | null;
	companySiteUrl: string | null | undefined;
	contactInformation: string | null;
	generalDescription: string | null;
	logo: string | null;
	status: RfpStatus | null;
	socialMedia: RfpProfileSocialMediaProfile[] | null;
	infoItems: RfpProfileInfoItem[] | null;
	team: RfpProfileTeamMember[] | null;
	categories: RfpCategory[] | null;
	economicalInfo: EconomicalInfo[] | null;
};

export type PaginationToken = {
	take: number | null;
	nextPartitionKey?: string | null;
	nextRowKey?: string | null;
};

export type PublicProfilesList = {
	data: RfpProfile[] | null;
	token: PaginationToken | null;
};

export type RfpStatus = 'Draft' | 'Published' | 'Archived';

export type RfpAttachmentType = 'RFPRequest' | 'RFPProposal';

export type RfpCategory =
	| 'TechnicalDevelopment'
	| 'ServiceDesign'
	| 'Marketing'
	| 'MarketingClearance'
	| 'TestMarketing'
	| 'Branding'
	| 'IPRJurisprudence'
	| 'Jurisprudence';

export type RfpCostType = 'Fixed' | 'FixedScale' | 'Hourly' | 'HourlyEstimate';

export type RfpCostClass = 'ProjectBased' | 'Maintenance' | 'Training' | 'Support' | 'Licensing';

export type SocialMediaService = 'FACEBOOK' | 'INSTAGRAM' | 'LINKEDIN' | 'TWITTER';

export type RfpCommonData = {
	rfpStatus: RfpStatus[] | null;
	rfpAttachmentType: RfpAttachmentType[] | null;
	rfpCategory: RfpCategory[] | null;
	rfpCostType: RfpCostType[] | null;
	rfpCostClass: RfpCostClass[] | null;
	socialMediaService: SocialMediaService[] | null;
};

export type GrantedAIFeature = 'GrantsMarketplaceProvider' | 'GrantsFinancialService';

export type JwtToken =
	| {
			access_token: string;
			expires_in: number;
			token_type: string;
			refresh_token: string;
			scope: string[];
			expireTime: number;
			expirationDate: string;
	  }
	| null
	| undefined;

export type License = 'advanced' | 'trial' | 'freemium';
export type CompanyRole = {
	CompanyId: string;
	License: License;
	Features: string[];
	Host: any;
	Roles: string[];
};
export class ISAccessToken implements IISAccessToken {
	scope: string[] = [];
	amr: string[] = [];
	client_id!: string;
	sub!: string;
	auth_time!: number;
	idp!: string;
	username!: string;
	email!: string;
	CompanyRoles: CompanyRole[] = [];
	client!: string;
	jti!: string;
	aud!: string;
	iss!: string;
	iat!: number;
	nbf!: number;
	exp!: number;
}
export interface IISAccessToken extends IAccessToken {
	scope: string[];
	amr: string[];
	client_id: string;
	sub: string;
	auth_time: number;
	idp: string;
	username: string;
	email: string;
	CompanyRoles: CompanyRole[];
	client: string;
	jti: string;
}

export class ADAccessToken implements IADAccessToken {
	name!: string;
	preferred_username!: string;
	roles!: string[];
	role!: string;
	sub!: string;
	aud!: string;
	iss!: string;
	iat!: number;
	nbf!: number;
	exp!: number;
}
export interface IADAccessToken extends IAccessToken {
	name: string;
	preferred_username: string;
	roles: string[];
	role: string;
	sub: string;
}

export interface IAccessToken {
	aud: string;
	iss: string;
	iat: number;
	nbf: number;
	exp: number;
}

export type GAIUserRole = 'Granted.Contributor' | 'Granted.UserAdmin';

export type AnalyticsAction = 'CardOpen' | 'InstrumentOpen' | 'LinkClicked';

export const ProjectWorkStatuses: Array<string> = [
	'UnderConstruction', // Projekti työn alla Projekti on luotu GAIssa
	'CustomerCommenting', // Asiakkaalla kommentoitavana Asiakkaalla kommentoitavana
	'SendToApproval', // Lähetetty   Hakemus rahoittajan käsittelyssä
	'FullfilmentRequest', // Täydennyspyyntö Täydennyspyyntö
	'Approved', // Hyväksytty  Hanke on hyväksytty ja meillä ei tekemistä projektin osalta, vaan odotamme raportointeja.Projekti siirtyisi tähän vaiheeseen, kun saadaan myönteinen päätös.
	'Abandoned', // Hylätty Kielteinen päätös.
	'ChangeApplication', // Muutoshakemus Lähes aina jo hyväksytylle hankkeelle
	'Reporting', // Raportointi Projekti siirtyy loppuraportointivaiheeseen.
	'DoneAndApproved', // Valmis  Projektiin ei enää toimenpiteitä
	'DoneAbandoned',
]; // Peruttu Hanke peruttu, konkurssi tms.

export type Project = {
	companyId: string | null;
	timelineId: string | null;
	projectId: string | null;
	projectType: string | null;
	instrumentId: string | null;
	instrumentTemplateVersion: string | null;
	projectName: string | null;
	projectSize: number | null;
	amountToApply: number | null;
	deminimisAmount: number | null;
	projectApplication: string | null;
	projectApplicationNumber: string | null;
	projectSummary: string | null;
	projectBackground: string | null;
	projectService: string | null;
	projectMarket: string | null;
	projectVision: string | null;
	projectDevelopment: string | null;
	startDate: Date | null;
	endDate: Date | null;
	projectPlanDate: Date | null;
	projectPhase: string | null;
	instrumentName: string | null;
	instrumentType: string | null;
	instrumentProvider: string | null;
	projectState: string | null;
	isUsed: boolean | null;
	blobItemPath: string | null;
	dynamic: any;
	author: string | null;
	created: string | null;
	archived: boolean | null;
	archiveDate: string | null;
	archiveAuthor: string | null;
	projectTaskGroups: Array<ProjectTaskGroup> | null;
	flatRateDevelopment: number | null;
	flatRateInvestment: number | null;
	personnelSideCost: number | null;
	advancePayment: number | null;
	attachments?: any;
	projectFinance: Array<ProjectFinance> | null;
	reportDeadline: Date | null;
	projectWorkStatus: string | null;
	projectResponsible: string | null;
	sortOrder: number | null;
} & ISortableItem;
export type ProjectFinance = {
	name: string | null;
	amount: number | null;
	index: number | null;
	id: string | null;
	amountPercent: number | null;
};

export type ProjectTaskGroup = {
	id: string | null;
	projectTaskGroupId: string | null;
	groupName: string | null;
	description: string | null;
	tasks: Array<ProjectTask> | null;
	companyId: string | null;
	created: Date | null;
	timelineId: string | null;
	projectId: string | null;
	sortOrder: number | null;
} & ISortableItem;

export type ProjectSupplementary = {
	projectSupplementaryInformationId: string | null;
	title: string | null;
	content: string | null;
	requestDate: string | null;
	responseDate: string | null;
};

export type Scenario = {
	grantedScore: number | null;
	grantedScoreCalculated: boolean | null;
	grantedScoreCalculatedDate: string | null;
	creator: string | null;
	isBase: boolean | null;
	businessId: string | null;
	id: string | null;
	name: string | null;
	isMainScenario: boolean | null;
	isNotified: boolean | null;
	postalCode: string | null;
	countyId: string | null;
	elyId: string | null;
	tolCode: string | null;
	migrationTime: string | null;
	missingValues: string[];
	totalValues: number | null;
	internationalEta: number | null;
	ipr: number | null;
	team: number | null;
	concept: number | null;
	deminisHistory: number | null;
	personnelSize: number | null;
	turnover: number | null;
	ratingAlpha: number | null;
	age: number | null;
	equityAmount: number | null;
	equityPercentageLevel: number | null;
	balance: number | null;
	countrySide: number | null;
	paysDivident: number | null;
	owner: number | null;
	taxDepth: number | null;
	companyType: number | null;
	employeeRegister: number | null;
	advanceCollectionRegister: number | null;
	responsiblesCreditRate: number | null;
	vatRegister: number | null;
	cashFlow: number | null;
	marketTrend: number | null;
	growtAbility: number | null;
	vacantAssurances: number | null;
};

export type BusinessPlan = {
	companyId: string | null;
	businessId: string | null;
	pitch: string | null;
	problem: string | null;
	solution: string | null;
	markets: string | null;
	globalMarket: string | null;
	vision: string | null;
	visionTagsArray: string[] | null;
	visionTags: string | null;
	tagsArray: string[] | null;
	tags: string | null;
	costs: string | null;
};

export type ProjectTaskCategoryEnum =
	| 'marketingStrategy'
	| 'piloting'
	| 'concepting'
	| 'development'
	| 'ipr'
	| 'ai'
	| 'marketResearch'
	| 'supportProcesses';

export type ProjectTaskCategory = {
	category: string;
	value: ProjectTaskCategoryEnum;
};
export const projectCategoryArray: ProjectTaskCategory[] = [
	{ category: 'Markkinointistrategia ja go-to market', value: 'marketingStrategy' },
	{ category: 'Pilotointi', value: 'piloting' },
	{ category: 'Konseptointi ja prototyypit', value: 'concepting' },
	{ category: 'Tuotekehitys', value: 'development' },
	{ category: 'IPR', value: 'ipr' },
	{ category: 'Tekoäly', value: 'ai' },
	{ category: 'Markkinatutkimus ja koemarkkinointi', value: 'marketResearch' },
	{ category: 'Tukiprosessit', value: 'supportProcesses' },
];

export interface ISortableItem {
	sortOrder: number | null;
}

export type ProjectTask = {
	companyId: string | null;
	timelineId: string | null;
	projectId: string | null;
	projectTaskId: string | null;
	projectTaskName: string | null;
	projectTaskDescription: string | null;
	projectTaskGoals: string | null;
	startDate?: Date | null;
	endDate?: Date | null;
	author?: string | null;
	created?: Date | null;
	archived?: boolean | null;
	archiveDate?: Date | null;
	archiveAuthor?: string | null;
	costs: Array<ProjectCost> | null;
	id: string;
	projectTaskGroupId: string | null;
	projectTaskCategory: ProjectTaskCategoryEnum | null;
	sortOrder: number | null;
} & ISortableItem;

export const projectTaskCategories: string[] = [
	'Johtamisen kehitys',
	'Rekrytointistrategia',
	'Työnantajamielikuva',
	'Organisaation kehittäminen',
	'Markkinatutkimus',
	'Kumppanikartoitus',
	'Markkinointistrategia',
	'Go-to market suunnitelma',
	'Kansainvälistymisstrategia',
	'Pilotointi',
	'Konseptointi',
	'Prototyyppi',
	'Ohjelmistokehitys',
	'IPR',
	'Judiriikka',
	'Koemarkkinointi',
	'Ansaintamallin suunnittelu',
	'Brändi',
	'Messut ja näyttelyt',
	'Muu',
];

export type salaryExpenseTypeEnum = 'monetary' | 'percentage';

export type ProjectCost = {
	companyId: string | null;
	timelineId: string | null;
	projectId: string | null;
	projectTaskId: string | null;
	id: string | null;
	category: CostCategoryEnum | null;
	amountApplied: number;
	amountBudget: number;
	amountGranted: number;
	amountReported: number;
	amountPaid: number;
	startDate: Date | null;
	endDate: Date | null;
	reportedDate: Date | null;
	paidDate: Date | null;
	phase: string;
	description?: string | null;
	type: string;
	name: string;
	workEstimate?: number;
	workEstimateUnit?: string | null;
	employeeName?: string | null;
	employeeRole?: string | null;
	supplierName?: string | null;
	author?: string;
	created?: string;
	archived?: boolean;
	archiveDate?: Date | null;
	archiveAuthor?: string | null;
	salaryExpense?: number | null;
	salaryExpenseType?: salaryExpenseTypeEnum | null;
	files?: any;
	eTag?: string | null;
	rfpCategory: string | null;
	rfpSubCategory: string | null;
	sortOrder: number | null;
} & ISortableItem;

export type projectStateEnum = 'Draft' | 'Published';

export type CostCategoryEnum =
	| 'salary'
	| 'purchasedServices'
	| 'travelExpense'
	| 'exhibition'
	| 'materialsAndSupplies'
	| 'iprProtection'
	| 'domesticServices'
	| 'concernServices'
	| 'servicesEU'
	| 'servicesAbroad'
	| 'equipmentPurchases'
	| 'equipmentPurchasesRent'
	| 'intangibleInvestments'
	| 'landConstructionCosts'
	| 'constructionCosts'
	| 'leasing'
	| 'expense';

export type MainCostCategoryEnum = 'investment' | 'development' | null;
// export type CostCategoryProviderEnum = 'BF' | 'All' | 'ELY';
export const CostCategoryProviderEnumValues = {
	BF: 1 << 1,
	ELY: 1 << 2,
	All: (1 << 0) | (1 << 1), // BF | ELY
} as const;
export type CostCategoryProviderEnum = (typeof CostCategoryProviderEnumValues)[keyof typeof CostCategoryProviderEnumValues];
export function hasProviderFlag(flags: CostCategoryProviderEnum, flag: CostCategoryProviderEnum): boolean {
	return (flags & flag) === flag;
}
export type CostCategory = {
	//name: string;
	mainCategory: MainCostCategoryEnum;
	value: CostCategoryEnum;
	provider: CostCategoryProviderEnum;
};

export const costCategoryArray: CostCategory[] = [
	{ mainCategory: 'development', value: 'salary', provider: CostCategoryProviderEnumValues.All } /* name: 'Palkkakulu', */,
	{ mainCategory: 'development', value: 'purchasedServices', provider: CostCategoryProviderEnumValues.All } /* name: 'Ostopalvelu', */,
	{ mainCategory: 'development', value: 'travelExpense', provider: CostCategoryProviderEnumValues.All } /* name: 'Matkakulu', */,
	{ mainCategory: 'development', value: 'exhibition', provider: CostCategoryProviderEnumValues.All } /* name: 'Messut', */,
	{
		mainCategory: 'development',
		value: 'materialsAndSupplies',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Aineet ja tarvikkeet', */,
	{ mainCategory: 'development', value: 'iprProtection', provider: CostCategoryProviderEnumValues.All } /* name: 'IPR suojaus', */,
	{
		mainCategory: 'development',
		value: 'domesticServices',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Palvelut kotimaisilta pk-yrityksiltä', */,
	{
		mainCategory: 'development',
		value: 'concernServices',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Palvelut konsernilta/instressiyrityksiltä', */,
	{
		mainCategory: 'development',
		value: 'servicesEU',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Palvelut Euroopan sisämarkkina-alueelta', */,
	{
		mainCategory: 'development',
		value: 'servicesAbroad',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Palvelut Euroopan sisämarkkina-alueen ulkopuolelta', */,
	{ mainCategory: 'investment', value: 'equipmentPurchases', provider: CostCategoryProviderEnumValues.All } /* name: 'Laiteostot', */,
	{
		mainCategory: 'investment',
		value: 'equipmentPurchasesRent',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Laitepoistot/-vuokrat', */,
	{
		mainCategory: 'investment',
		value: 'intangibleInvestments',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Aineettomat investoinnit', */,
	{
		mainCategory: 'investment',
		value: 'landConstructionCosts',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Maanrakennuskustannukset', */,
	{
		mainCategory: 'investment',
		value: 'constructionCosts',
		provider: CostCategoryProviderEnumValues.All,
	} /* name: 'Rakennuskustannukset', */,

	{ mainCategory: null, value: 'expense', provider: CostCategoryProviderEnumValues.All } /* name: 'Yleiskulu', */,
	{ mainCategory: null, value: 'leasing', provider: CostCategoryProviderEnumValues.All } /* name: 'Leasing ELY', */,

	// { /* name: 'Kansainvälistyminen', */ mainCategory: 'investment', value: 'internationalization' },
	// { /* name: 'Investointi', */ mainCategory: 'investment', value: 'investment' },
	// { /* name: 'Yleiskulu', */ mainCategory: 'investment', value: 'expense' },
	// { /* name: 'Henkilöstökulu', */ mainCategory: 'investment', value: 'personnel' },
	// { /* name: 'Muu kulu', */ mainCategory: 'investment', value: 'other' },
	// { /* name: 'Rekrytointi', */ mainCategory: 'investment', value: 'reqruiment' },
	// { /* name: 'Ostopalvelut', */ mainCategory: 'investment', value: 'service' },
];

export type Instrument = {
	id: string;
	name: string | null;
	provider: string;
	description: string | null;
	instrumentPromptId: string | null;
	instrumentPrompt: string | null;
	instrumentTemplateId: string | null;
	ingress: string | null;
	isCustom: boolean | null;
	type: string | null;
};

export type InstrumentCategory = {
	categoryId: string | null;
	category: string | null;
	description: string | null;
	leve: string | null;
	instruments: Instrument[] | null;
	referenceId?: string | null;
};

export type FreemiumAccount = {
	email: string | null;
	firstName: string | null;
	lastname: string | null;
	companyName: string | null;
	businessId: string | null;
	phoneNumber: string | null;
	internationalization: string | null;
	interests: string | null;
	haveAcceptedDataPrivacyStatement: boolean | null;
	quideArea: string | null;
};

export type FinancialPlan = {
	published: string | null;
	publishPath: string | null;
	type: string | null;
	businessId: string | null;
	isDraft: true;
	expertComments: string | null;
	base: string | null;
	precondition: string | null;
	notice: string | null;
	followup: string | null;
	internalInformation: string | null;
	modified: string | null;
	created: string | null;
	instruments: Instrument[] | null;
	partitionKey: string | null;
	rowKey: string | null;
	timestamp: string | null;
	eTag: string | null;
};

export type CompanyNote = {
	title: string | null;
	companyId: string | null;
	companyNotesId: string | null;
	author: string | null;
	updateAuthor: string | null;
	created: string | null;
	category: string | null;
	content: string | null;
	updated: string | null;
	partitionKey: string | null;
	rowKey: string | null;
	timestamp: string | null;
	eTag: string | null;
};

export type NoteCategory = 'brief' | 'general' | 'start' | 'financePlan';

export interface InstrumentTemplateFileEntity {
	instrumentId: string; // Assuming GUID as string
	templateVersion: string;
	instrumentName: string;
	provider: string;
	showProjectPlan: boolean;
	fields: InstrumentTemplateField[];
}
export interface InstrumentTemplateField {
	title?: string;
	id: string;
	type: string;
	validations?: string[];
	params?: { [key: string]: any };
	scale?: SizeScale;
	description: string;
	placeholder: string;
	fieldRequired?: boolean; // Note: In TypeScript, boolean is sufficient; no need to convert string to boolean.
	mapping: string;
	mappingTemplateFields?: string[];
	mappingTemplate?: string;
	checkedLabel?: string;
	notCheckedLabel?: string;
	ai?: boolean;
	keys?: Key[];
	fields?: InstrumentTemplateField[];
	// extra properties not in db
	promptTemplate: any;
	template: any;
}

export interface SizeScale {
	xs: number;
	sm?: number;
	lg?: number;
	md?: number;
}

export interface Key {
	title: string;
	keyKey: string;
}

export interface IMonthValue {
	month: string;
	value: string;
}
