import React from 'react';
import * as _ from 'lodash';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    customTooltip: {
        padding: '1rem',
        border: '1px solid #ccc',
        background: 'rgba(255, 255, 255, 0.8)',
    },
    text: {
        color: theme.palette.primary.main,
    },
}));

const CustomTooltip = ({ payload, label, translatedValue }) => {
    const classes = useStyles();

    const iteratePayload = payload => {
        return _.map(payload, item => {
            const val = translatedValue(`instrument.radar.choice.${item.payload.compareProperty}.${item.value}`);

            return <p key={item.name + item.dataKey} className={classes.text}>{`${item.name} : ${val}`}</p>;
        });
    };

    return (
        <div className={classes.customTooltip}>
            <h5 className={classes.text}>{translatedValue(`instrument.radar.choice.${label}`)}</h5>
            {iteratePayload(payload)}
        </div>
    );
};

export default CustomTooltip;
