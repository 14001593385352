import { yupResolver } from '@hookform/resolvers/yup';
import { isNil } from 'lodash';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { Publish as PublishIcon } from '@mui/icons-material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
	Alert,
	AlertTitle,
	Box,
	BoxProps,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	Skeleton,
	Stack,
	Tab,
	TextField,
	Typography,
} from '@mui/material';
import { toast } from 'react-toastify';

import { RfpProfile } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';
import { mergeFieldValues } from 'utils/misc/formUtils';

import { openPopover } from 'containers/Marketplace/popoverSlice';
import { useFetchRfpProfileQuery, usePublishRfpProfileMutation, useUpsertRfpProfileMutation } from 'containers/Marketplace/rfpProfileApi';

import RfpAttachmentAvatarInput from 'containers/Marketplace/attachment/RfpAttachmentAvatarInput';
import RfpCategoryAutocomplete from 'containers/Marketplace/common/RfpCategoryAutocomplete';
import RfpStatusChip from 'containers/Marketplace/common/RfpStatusChip';
import RfpProfileInfoItemList from 'containers/Marketplace/profile/RfpProfileInfoItemList';
import RfpProfileTeamMemberList from 'containers/Marketplace/profile/RfpProfileTeamMemberList';
import UpsertRfpProfileInfoItemDialog from 'containers/Marketplace/profile/UpsertRfpProfileInfoItemDialog';
import UpsertRfpProfileTeamMemberDialog from 'containers/Marketplace/profile/UpsertRfpProfileTeamMemberDialog';
import { hasMarketplaceProviderFeatureTest } from '../common/IsMarketplaceProvider';
import { StyledTooltip } from '../common/StyledTooltip';

type FieldValues = Partial<RfpProfile>;

type Props = BoxProps & {
	onAfterPublish?: () => void;
	onCloseClick?: () => void;
	onDirtyChange?: (isDirty: boolean) => void;
};

const ProfileTabs = {
	BasicInformation: 'BasicInformation',
	InfoItems: 'InfoItems',
	TeamMembers: 'TeamMembers',
};

const UpsertRfpProfileForm: React.FC<Props> = ({ onAfterPublish, onCloseClick, onDirtyChange, ...otherProps }) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = React.useState(ProfileTabs.BasicInformation);
	// @ts-ignore
	const company = useSelector(state => state.admin);
	const isProvider = hasMarketplaceProviderFeatureTest(company, false);

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const {
		data: rfpProfile,
		isLoading,
		isError: isRfpProfileError,
	} = useFetchRfpProfileQuery({ companyId: companyId }, { skip: isNil(companyId) });

	const [upsertRfpProfile, { isError: isUpsertError }] = useUpsertRfpProfileMutation();
	const [publishRfpProfile, { isError: isPublishError, isLoading: isPublishLoading }] = usePublishRfpProfileMutation();

	const validationSchema = React.useMemo(
		() =>
			yup.object({
				companyIngress: yup.string().max(500),
				contactInformation: yup.string().max(2000),
				generalDescription: yup.string().max(2000),
			}),
		[]
	);

	const defaultValues = React.useMemo(
		() => ({
			companyId: null,
			companyName: null,
			logo: null,
			status: 'Draft' as const,
			companyIngress: '',
			companySiteUrl: '',
			contactInformation: '',
			generalDescription: '',
			socialMedia: [{ url: '' }, { url: '' }, { url: '' }, { url: '' }],
			categories: [],
		}),
		[]
	);

	const {
		control,
		reset,
		watch,
		handleSubmit,
		formState: { isDirty, isSubmitting },
	} = useForm<FieldValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const [status] = watch(['status']);

	const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
		async fieldValues => {
			try {
				await upsertRfpProfile({
					companyId,
					rfpProfile: {
						...fieldValues,
						socialMedia: [
							{ service: 'FACEBOOK' as const, url: fieldValues?.socialMedia?.[0].url as string, altName: 'Facebook' },
							{ service: 'INSTAGRAM' as const, url: fieldValues?.socialMedia?.[1].url as string, altName: 'Instagram' },
							{ service: 'LINKEDIN' as const, url: fieldValues?.socialMedia?.[2].url as string, altName: 'LinkedIn' },
							{ service: 'TWITTER' as const, url: fieldValues?.socialMedia?.[3].url as string, altName: 'Twitter' },
						],
					},
				}).unwrap();
				toast.success(formatMessage({ id: 'upsertRfpProfile.success.message' }));
			} catch (error) {
				console.error(error);
			}
		},
		[upsertRfpProfile, formatMessage, companyId]
	);

	React.useEffect(() => {
		onDirtyChange?.(isDirty);
	}, [onDirtyChange, isDirty]);

	React.useEffect(() => {
		if (!isNil(rfpProfile)) {
			reset(mergeFieldValues(defaultValues, rfpProfile), { keepDirtyValues: false });
		}
	}, [reset, defaultValues, rfpProfile]);

	return (
		<>
			<UpsertRfpProfileTeamMemberDialog />
			<UpsertRfpProfileInfoItemDialog />
			<Box {...otherProps} component='form' onSubmit={handleSubmit(handleFormSubmit)}>
				<DialogTitle>
					<FormattedMessage id='rfpProfile.title' />
				</DialogTitle>
				<DialogContent>
					{isRfpProfileError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchRfpProfile.error.message' />
						</Alert>
					)}
					{isUpsertError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='upsertRfpProfile.error.message' />
						</Alert>
					)}
					{isPublishError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='publishRfpProfile.error.message' />
						</Alert>
					)}
					<Stack flexDirection={{ xs: 'column', md: 'row' }} sx={{ mb: '0.5rem' }}>
						<RfpStatusChip status={status} />
					</Stack>
					<TabContext value={activeTab}>
						<TabList
							onChange={(event, tab) => setActiveTab(tab)}
							variant='scrollable'
							scrollButtons='auto'
							aria-label={formatMessage({ id: 'rfpProfile.tabs' })}
						>
							<Tab value={ProfileTabs.BasicInformation} label={<FormattedMessage id='rfpProfile.tabs.basicInformation' />} />
							<Tab value={ProfileTabs.InfoItems} label={<FormattedMessage id='rfpProfile.tabs.infoItems' />} />
							<Tab value={ProfileTabs.TeamMembers} label={<FormattedMessage id='rfpProfile.tabs.teamMembers' />} />
						</TabList>
						<TabPanel value={ProfileTabs.BasicInformation}>
							{isLoading ? (
								<>
									<Skeleton height={60} />
									<Skeleton height={60} />
									<Skeleton height={60} />
									<Skeleton height={60} />
									<Skeleton height={60} />
									<Skeleton height={60} />
								</>
							) : (
								<Stack spacing={1} direction='column'>
									<Typography gutterBottom variant='h4' sx={{ mt: '1rem' }}>
										<FormattedMessage id={'rfpProfile.logo.label'} />
									</Typography>
									<Controller
										name='logo'
										control={control}
										render={({ field, fieldState }) => (
											<>
												<RfpAttachmentAvatarInput
													{...field}
													error={!isNil(fieldState.error)}
													helperText={fieldState.error?.message}
													AvatarProps={{ variant: 'logo1' }}
												/>
											</>
										)}
									/>
									<Typography gutterBottom variant='h4' sx={{ mt: '1rem' }}>
										<FormattedMessage id={'rfpProfile.basicInformation.title'} />
									</Typography>
									<Controller
										name='companyName'
										control={control}
										render={({ field, fieldState }) => (
											<TextField
												{...field}
												label={formatMessage({ id: `rfpProfile.${field.name}.label` })}
												placeholder={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}
												error={!isNil(fieldState.error)}
												helperText={fieldState.error?.message}
											/>
										)}
									/>
									<Controller
										name='categories'
										control={control}
										render={({ field, fieldState }) => (
											<RfpCategoryAutocomplete
												{...field}
												onChange={(event, value) => {
													field.onChange(value);
												}}
												renderInput={params => (
													<StyledTooltip title={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}>
														<TextField
															{...params}
															label={formatMessage({ id: `rfpProfile.${field.name}.label` })}
															placeholder={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													</StyledTooltip>
												)}
											/>
										)}
									/>
									<Controller
										name='companyIngress'
										control={control}
										render={({ field, fieldState }) => (
											<StyledTooltip title={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}>
												<TextField
													{...field}
													error={!isNil(fieldState.error)}
													label={formatMessage({ id: `rfpProfile.${field.name}.label` })}
													placeholder={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}
													helperText={fieldState.error?.message ?? `${field.value?.length} / ${500}`}
													inputProps={{ maxLength: 500 }}
													minRows={3}
													multiline
												/>
											</StyledTooltip>
										)}
									/>
									<Controller
										name='generalDescription'
										control={control}
										render={({ field, fieldState }) => (
											<StyledTooltip title={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}>
												<TextField
													{...field}
													error={!isNil(fieldState.error)}
													label={formatMessage({ id: `rfpProfile.${field.name}.label` })}
													placeholder={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}
													helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
													inputProps={{ maxLength: 2000 }}
													minRows={3}
													multiline
												/>
											</StyledTooltip>
										)}
									/>
									<Controller
										name='companySiteUrl'
										control={control}
										render={({ field, fieldState }) => (
											<TextField
												{...field}
												error={!isNil(fieldState.error)}
												label={formatMessage({ id: `rfpProfile.${field.name}.label` })}
												placeholder={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}
												helperText={fieldState.error?.message}
											/>
										)}
									/>
									<Controller
										name='contactInformation'
										control={control}
										render={({ field, fieldState }) => (
											<StyledTooltip title={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}>
												<TextField
													{...field}
													error={!isNil(fieldState.error)}
													label={formatMessage({ id: `rfpProfile.${field.name}.label` })}
													placeholder={formatMessage({ id: `rfpProfile.${field.name}.placeholder` })}
													helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
													inputProps={{ maxLength: 2000 }}
													minRows={3}
													multiline
												/>
											</StyledTooltip>
										)}
									/>
									<Typography gutterBottom variant='h4' sx={{ mt: '1rem' }}>
										{formatMessage({ id: 'rfpProfile.socialMedia.title' })}
									</Typography>
									<Controller
										name='socialMedia.0.url'
										control={control}
										render={({ field, fieldState }) => (
											<TextField
												{...field}
												error={!isNil(fieldState.error)}
												label={formatMessage({ id: 'rfpProfile.facebookProfile.label' })}
												placeholder={formatMessage({ id: 'rfpProfile.facebookProfile.placeholder' })}
												helperText={fieldState.error?.message}
												InputProps={{
													startAdornment: <InputAdornment position='start'>www.facebook.com/</InputAdornment>,
												}}
											/>
										)}
									/>
									<Controller
										name='socialMedia.1.url'
										control={control}
										render={({ field, fieldState }) => (
											<TextField
												{...field}
												error={!isNil(fieldState.error)}
												label={formatMessage({ id: 'rfpProfile.instagramProfile.label' })}
												placeholder={formatMessage({ id: 'rfpProfile.instagramProfile.placeholder' })}
												helperText={fieldState.error?.message}
												InputProps={{
													startAdornment: <InputAdornment position='start'>www.instagram.com/</InputAdornment>,
												}}
											/>
										)}
									/>
									<Controller
										name='socialMedia.2.url'
										control={control}
										render={({ field, fieldState }) => (
											<TextField
												{...field}
												error={!isNil(fieldState.error)}
												label={formatMessage({ id: 'rfpProfile.linkedInProfile.label' })}
												placeholder={formatMessage({ id: 'rfpProfile.linkedInProfile.placeholder' })}
												helperText={fieldState.error?.message}
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>www.linkedin.com/company/</InputAdornment>
													),
												}}
											/>
										)}
									/>
									<Controller
										name='socialMedia.3.url'
										control={control}
										render={({ field, fieldState }) => (
											<TextField
												{...field}
												error={!isNil(fieldState.error)}
												label={formatMessage({ id: 'rfpProfile.twitterProfile.label' })}
												placeholder={formatMessage({ id: 'rfpProfile.twitterProfile.placeholder' })}
												helperText={fieldState.error?.message}
												InputProps={{
													startAdornment: <InputAdornment position='start'>www.twitter.com/</InputAdornment>,
												}}
											/>
										)}
									/>
								</Stack>
							)}
						</TabPanel>
						<TabPanel value={ProfileTabs.InfoItems}>
							<RfpProfileInfoItemList />
						</TabPanel>
						<TabPanel value={ProfileTabs.TeamMembers}>
							<RfpProfileTeamMemberList />
						</TabPanel>
					</TabContext>
				</DialogContent>
				<DialogActions>
					<Box sx={{ flex: 1 }}>
						<Button
							size='small'
							variant='text'
							color='neutral'
							onClick={() => {
								onCloseClick?.();
							}}
						>
							<FormattedMessage id='rfpProfile.cancel' />
						</Button>
					</Box>
					<LoadingButton disabled={!isDirty} type='submit' variant='outlined' color='neutral' loading={isSubmitting}>
						<FormattedMessage id='rfpProfile.submit' />
					</LoadingButton>
					<LoadingButton
						endIcon={<PublishIcon />}
						loading={isPublishLoading}
						disabled={status !== 'Draft' || isDirty}
						onClick={event => {
							dispatch(
								openPopover({
									name: 'ConfirmPublishPopover',
									data: {
										anchorEl: event.currentTarget,
										text: formatMessage({ id: 'rfp.profile.confirmPublish' }),
										onSubmit: async () => {
											try {
												await publishRfpProfile({ companyId, rfpProfile, list: isProvider }).unwrap();
												onAfterPublish?.();
												toast.success(formatMessage({ id: 'publishRfpProfile.success.message' }));
											} catch (error) {
												console.error(error);
											}
										},
									},
								})
							);
						}}
					>
						<FormattedMessage id='rfpRequest.publish' />
					</LoadingButton>
				</DialogActions>
			</Box>
		</>
	);
};

export default UpsertRfpProfileForm;
