import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import useFormErrorEffect from 'components/Form/utils/useFormErrorEffect';
import { getProjectSchema } from 'containers/Projects/Project/ProjectForm/ProjectValidationSchema';
import ProjectForm from '../ProjectForm';
import ProjectFormExtendedTemplateTableOfContents from './ProjectFormExtendedTemplateTableOfContents';

import { makeStyles } from '@mui/styles';
import { getDirtyKeys } from 'components/Form/utils/utils';
import Subtitle from 'components/Titles/Subtitle';
import { useLazyFetchInstumentTemplateQuery } from 'containers/Projects/projectRTKApi';
import { Instrument, Project } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import getCompanyLink from 'utils/auth/getCompanyLink';
import { isAdminToken } from 'utils/auth/token';
import { generateCurrentTimeTicks } from 'utils/helpers/dateExtension';
import { matchPath } from 'utils/helpers/pathMatch';
import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';
import storage from 'utils/localStorage/localStorageFunctions';
import AiGeneratorForm from '../../../AI/AiGenerator/AiGeneratorForm';
import { useUpsertProjectPromptMutation } from '../../projectRTKApi';
import { finance } from '../../slices';
import ProjectContainer from '../ProjectContainer/ProjectContainer';
import ProjectSaveButtonContainer from '../ProjectForm/ProjectSaveButtonContainer';
import ProjectSummary from '../ProjectSummary';

const useStyles = makeStyles(() => ({
	resizeHandleOuterStyle: {
		flex: '0 0 0.7em',
		position: 'relative',
		outline: 'none',
		backgroundColor: 'transparent', // Assuming you're replacing the CSS  here
	},

	resizeHandleInnerStyle: {
		zIndex: 1000,
		position: 'absolute',
		top: '0.1em',
		bottom: '0.1em',
		left: '0.1em',
		right: '0.1em',
		borderRadius: '0.1em',
		// backgroundColor: 'transparent', // Assuming default state without dragging
		background: 'rgba(255, 255, 255, .6)',
		'&:hover': {
			backgroundColor: '#fff',
		},
	},

	dragging: {
		backgroundColor: '#ccc',
		transition: '#ccc 0.2s linear',
	},

	icon: {
		width: '1em',
		height: '1em',
		position: 'fixed',
		top: 'calc(50% - 0.5rem)',
		transform: 'rotate(90deg)',
	},
}));

export const CustomResizeHandle = ({ className = '', id }: { className?: string; id?: string }) => {
	const [isDragging, setIsDragging] = useState(false);
	const classes = useStyles();

	return (
		<PanelResizeHandle className={[classes.resizeHandleOuterStyle, className].join(' ')} id={id} onDragging={setIsDragging}>
			<div className={`${classes.resizeHandleInnerStyle} ${isDragging && classes.dragging}`}></div>
		</PanelResizeHandle>
	);
};

export const getInitialProject = ({ timelineId, companyId }: { timelineId: string; companyId: string | null }) => {
	return {
		projectId: `${timelineId}_${generateCurrentTimeTicks()}`,
		projectType: '',
		instrumentId: '',
		projectName: '',
		projectSize: 0,
		amountToApply: 0,
		projectSummary: '',
		projectBackground: '',
		projectService: '',
		projectMarket: '',
		projectVision: '',
		projectDevelopment: '',
		startDate: null,
		endDate: null,
		projectPhase: '',
		instrumentName: '',
		instrumentType: '',
		instrumentProvider: '',
		dynamic: {},
		projectFinance: finance,
		companyId: companyId,
		timelineId: timelineId,
		instrumentTemplateVersion: null,
		deminimisAmount: null,
		projectApplication: null,
		projectApplicationNumber: null,
		projectState: null,
		isUsed: null,
		blobItemPath: null,
		author: null,
		created: null,
		archived: null,
		archiveDate: null,
		archiveAuthor: null,
		flatRateDevelopment: null,
		flatRateInvestment: null,
		personnelSideCost: null,
		advancePayment: null,
		projectTaskGroups: [],
		projectPlanDate: null,
		reportDeadline: null,
		projectWorkStatus: null,
		projectResponsible: '',
		sortOrder: 0,
	};
};

const tocSettingTypes = {
	placement: 'placement',
	collapse: 'collapse',
};

type ProjectPlanProps = {
	surrogate: any;
	project: Project;
	instruments: Array<Instrument>;
	chatId: string;
	isLocalBackup: boolean;
	deleteLocalBackup: any;
	updateLocalBackup: any;
	timeline: any;
};
const ProjectPlan: React.FC<ProjectPlanProps> = ({
	surrogate,
	project,
	instruments,
	chatId,
	isLocalBackup,
	deleteLocalBackup = () => {},
	updateLocalBackup = () => {},
	timeline,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const isAdmin = isAdminToken();
	const companyId = getCompanyId();

	const { hash } = useLocation();
	useScrollIntoViewWithOffset(hash?.replace('#', ''), hash, 170);

	const defaultPlacement = storage.get('project');
	const defaultPlacementTop = storage.get('projectTop') ?? ['toc', 'yhteenveto'];

	const [formats, setFormats] = useState(defaultPlacementTop);
	const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
		setFormats(newFormats);
		storage.set('projectTop', newFormats);
	};
	const showToc = formats.indexOf('toc') > -1;
	const showSummary = formats.indexOf('yhteenveto') > -1;

	const [tocPlacement, setTocPlacement] = useState(defaultPlacement?.tocPlacement ?? 'row');
	const [tocCollapsed, setTocCollapsed] = useState(defaultPlacement?.tocCollapsed ?? false);

	const storeTocSettings = (type: string) => {
		let tocPlacementTemp = type === tocSettingTypes.placement ? (tocPlacement === 'row' ? 'row-reverse' : 'row') : tocPlacement;
		let tocCollapsedTemp = type === tocSettingTypes.collapse ? !tocCollapsed : tocCollapsed;

		if (type === tocSettingTypes.placement) setTocPlacement(tocPlacementTemp);
		else if (type === tocSettingTypes.collapse) setTocCollapsed(tocCollapsedTemp);
		else return; // not recognized type

		storage.set('project', { tocPlacement: tocPlacementTemp, tocCollapsed: tocCollapsedTemp });
	};

	const [isLoading, setIsLoading] = useState(false);
	const [outSideButtonLoading, setOutSideButtonLoading] = useState(false);
	const [isAddingSubmit, setIsAddingSubmit] = useState(false);
	const [instruction, setInstruction] = useState('');

	const schema = getProjectSchema(intl);

	const isAdding = matchPath('/projects/add', location);

	if (isAdding) {
		project ??= getInitialProject({ timelineId: timeline.timelineId, companyId });
	}

	const {
		control,
		register,
		unregister,
		setValue,
		getValues,
		watch,
		handleSubmit,
		reset,
		formState: { dirtyFields, errors },
	} = useForm<Project>({
		defaultValues: project,
		resolver: async (data, context, options) => {
			return yupResolver(schema)(data, context, options);
		},
	});

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	useFormErrorEffect(errors);

	const [upsertProjectPrompt, { isLoading: isUpsertLoading }] = useUpsertProjectPromptMutation();

	const onSubmit = async (data: Project, projectSaveFunction: any, outsideButton = false) => {
		if (!projectSaveFunction) {
			toast.error(<FormattedMessage id='project.saving.failed' />);
			return;
		}

		outsideButton ? setOutSideButtonLoading(true) : setIsLoading(true);

		if (isAdding) {
			setIsAddingSubmit(true);
		}

		const storedProject = await dispatch(projectSaveFunction(data));

		// @ts-ignore
		if (data.projectPrompt && isAdmin && storedProject?.projectId) {
			try {
				await upsertProjectPrompt({
					companyId: companyId,
					projectId: storedProject?.projectId, // @ts-ignore
					prompt: data.projectPrompt, // @ts-ignore
					draft: data.draft,
				}).unwrap();
				toast.success('Hakemuksen perustiedot päivitetty');
			} catch (error) {
				toast.error('Hakemuksen perustiedot päivitys epäonnistui');
			}
		}

		setOutSideButtonLoading(false);
		setIsLoading(false);

		if (storedProject) {
			toast.success(<FormattedMessage id='project.saving.successed' />);
			deleteLocalBackup();

			history.push(getCompanyLink(`/projects/${storedProject.projectId}/plan`, location));

			return;
		}

		toast.error(<FormattedMessage id='project.saving.failed' />);
	};

	useEffect(() => {
		// @ts-ignore
		if (!project.timestamp) return;

		const currentValues = getValues();

		// @ts-ignore
		if (currentValues.eTag !== project.eTag) {
			reset(project);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	const setDynamicValidationTemplate = (error: any) => {
		if (!error) return;

		schema.fields = { ...schema.fields, ...error.fields };
	};

	const handleAiGenerateResponse = (response: any, prompt: any) => {
		if (response?.content) {
			const content = response?.content?.replaceAll('\n\n', '<p>');

			setValue(prompt.targetField ?? 'projectSummary', content);
		}
	};

	const selectedInstrument = watch('instrumentId');
	const updatedProjectData = getValues();

	const [templateSearch, { data: template, isLoading: isTemplateLoading, isFetching: isTemplateFetching, status }] =
		useLazyFetchInstumentTemplateQuery();
	const templateLoading = isTemplateLoading || isTemplateFetching;

	useEffect(() => {
		if (!selectedInstrument) return;

		const serachItemTemplate = async () => {
			const instrument = instruments?.find((item: any) => item?.id === (selectedInstrument ?? project?.instrumentId));

			await templateSearch({
				instrumentId: instrument?.instrumentTemplateId ?? project.instrumentId,
				templateVersion: instrument?.instrumentTemplateId ? null : project.instrumentTemplateVersion,
			});
		};

		serachItemTemplate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInstrument]);

	// const refs = useRef();

	// useEffect(() => {
	// 	const groupElement = getPanelGroupElement('group');
	// 	const leftPanelElement = getPanelElement('left-panel');
	// 	const rightPanelElement = getPanelElement('right-panel');
	// 	const resizeHandleElement = getResizeHandleElement('resize-handle');

	// 	// If you want to, you can store them in a ref to pass around
	// 	// @ts-ignore
	// 	refs.current = {
	// 		groupElement,
	// 		leftPanelElement,
	// 		rightPanelElement,
	// 		resizeHandleElement,
	// 	};
	// }, []);

	const [panelWidth, setpanelWidth] = useState([80, 20]);
	//const [isDrawerExpanded] = useLocalStorage(storageKeys.MENU_DRAWER_KEY, null);

	const setWidth = (data: number[]) => {
		setpanelWidth([data[0] - data[0] / 9, data[1] - data[1] / 10]);
	};

	return (
		<>
			<PanelGroup
				direction='horizontal'
				id='group'
				autoSaveId='project-split-panels'
				onLayout={setWidth}
				style={{ overflow: 'visible', maxWidth: '100%' }}
			>
				<Panel
					id='left-panel'
					minSize={20}
					style={{
						minHeight: '90vh',
						overflow: 'visible',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						maxWidth: '100%',
						width: `${panelWidth[0]}%`,
					}}
				>
					<>
						{/* @ts-ignore */}
						<FormProvider
							{...{
								control,
								register,
								unregister,
								setValue,
								getValues,
								watch,
								handleSubmit,
								reset,
							}}
						>
							<ProjectContainer
								useStickyHeader
								project={project}
								isLocalBackup={isLocalBackup}
								deleteLocalBackup={deleteLocalBackup}
								actions={
									<ProjectSaveButtonContainer
										extraButtons={
											<>
												<ToggleButtonGroup value={formats} onChange={handleFormat} aria-label='text formatting'>
													<ToggleButton color='primary' value='toc'>
														{intl.formatMessage({ id: 'project.form.toc.title' })}
													</ToggleButton>
													<ToggleButton color='primary' value='yhteenveto'>
														{intl.formatMessage({ id: 'project.dashboard.menu.summary' })}
													</ToggleButton>
												</ToggleButtonGroup>
												<AiGeneratorForm // @ts-ignore
													handleResponse={handleAiGenerateResponse}
													project={getValues()} // @ts-ignore
													instrument={instruments?.find(
														(item: any) => item.id === (selectedInstrument ?? project?.instrumentId)
													)}
													instrumentId={getValues('instrumentId') ?? project?.instrumentId ?? ''}
												/>
											</>
										}
										{...{
											onSubmit,
											handleSubmit,
											isDirty,
											loading: outSideButtonLoading,
											state: project?.projectState,
											isLocalBackup,
										}}
									/>
								}
							>
								<Grid container spacing={0} direction={tocPlacement}>
									{showToc && (
										<Grid
											item
											md={tocCollapsed ? 1 : 4}
											lg={tocCollapsed ? 1 : 3}
											sx={{
												pl: tocPlacement === 'row' ? 0 : '1.5rem',
												display: { xs: 'none', md: 'block' },
											}}
										>
											<Box sx={{ position: 'sticky', top: '11rem', height: 'calc(80vh)', overflow: 'auto' }}>
												<Stack
													direction={tocCollapsed ? 'row' : 'row'}
													alignContent='space-between'
													alignItems='center'
												>
													<Subtitle mt={0} mb={0} sx={{ flexGrow: 1 }}>
														{intl.formatMessage({ id: 'project.form.toc.title' })}
													</Subtitle>
													<IconButton
														sx={{ width: '2.5rem' }}
														aria-label='download'
														color='primary'
														onClick={() => storeTocSettings(tocSettingTypes.placement)}
													>
														<CompareArrowsIcon />
													</IconButton>
													<IconButton
														sx={{ width: '2.5rem' }}
														aria-label='download'
														color='primary'
														onClick={() => storeTocSettings(tocSettingTypes.collapse)}
													>
														{!tocCollapsed ? (
															<UnfoldLessIcon sx={{ transform: 'rotate(90deg)' }} />
														) : (
															<UnfoldMoreIcon sx={{ transform: 'rotate(90deg)' }} />
														)}
													</IconButton>
												</Stack>
												<ProjectFormExtendedTemplateTableOfContents surrogate={surrogate} />
											</Box>
										</Grid>
									)}
									<Grid
										item
										sx={{ pl: showToc ? (tocPlacement === 'row' ? '1.5rem' : 0) : 0 }}
										xs={12}
										md={showToc ? (tocCollapsed ? 11 : 8) : 12}
										lg={showToc ? (tocCollapsed ? 11 : 9) : 12}
									>
										<ProjectForm
											surrogate={surrogate}
											isLoading={isLoading || templateLoading || isUpsertLoading}
											setDynamicValidationTemplate={setDynamicValidationTemplate}
											project={project ?? {}}
											instruments={instruments}
											handleSubmit={handleSubmit}
											control={control}
											register={register}
											unregister={unregister}
											watch={watch}
											errors={errors}
											setValue={setValue}
											onSubmit={onSubmit}
											showExtended={true}
											setInstruction={setInstruction}
											dirtyFields={dirtyFields}
											isDirty={isDirty}
											outSideButtonLoading={outSideButtonLoading}
											isAddingSubmit={isAddingSubmit}
											getValues={getValues}
											chatId={chatId}
											isLocalBackup={isLocalBackup}
											deleteLocalBackup={deleteLocalBackup}
											updateLocalBackup={updateLocalBackup}
											onInstrumentChange={(id: string) => console.info('onInstrumentChange', id)}
										/>
									</Grid>
								</Grid>
							</ProjectContainer>
						</FormProvider>
					</>
				</Panel>
				{showSummary && (
					<>
						{/* <PanelResizeHandle
								hitAreaMargins={{ coarse: 15, fine: 15 }}
								style={{ background: '#ccc', width: '.2rem', '& :hover': { width: '.4rem' } }}
								id='resize-handle'
							></PanelResizeHandle> */}
						<CustomResizeHandle />
						<Panel id='right-panel' minSize={20}>
							<Stack
								sx={{
									position: 'fixed',
									overflow: 'visible',
									maxHeight: '90vh',
									overflowY: 'auto',
									width: `${panelWidth[1]}%`,
								}}
							>
								<ProjectSummary
									project={updatedProjectData} // @ts-ignore
									template={template}
									readonly={true}
									compact
								/>
							</Stack>
						</Panel>
					</>
				)}
			</PanelGroup>
		</>
	);
};

export default ProjectPlan;
