import { MsalProvider } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, matchPath, useLocation } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import AppRoute from 'components/AppRoute';
import routes from 'config/routes';
import theme from 'config/theme';
import { analytics } from 'utils/misc/analytics';
import useInject from './inject';

import CookieConsent from 'components/CookieConsent/CookieConsent';
import { isAdmin, resetCompanyData } from 'containers/App/appSlice';
import { isNil } from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import { getCompany } from 'utils/auth/company';
import { initializeMsalInstance } from 'utils/auth/msal/msalConfig';
import Toaster from './Toaster';
import './app.css';
import { useGetCompanyContractQuery } from './contractApi';

const App = () => {
	useInject();
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const msalInstance = initializeMsalInstance();

	const selectedCompany = getCompany();
	const companyId = selectedCompany?.CompanyId;

	const contract = useGetCompanyContractQuery({ companyId: companyId, type: 'TermsContract' }, { skip: isNil(companyId) });

	const excludePath = (pathname, arrayOfPaths) => {
		if (!arrayOfPaths || arrayOfPaths.length === 0) return false;

		for (let i = 0; i < arrayOfPaths.length; i++) {
			const match = matchPath(pathname, {
				path: arrayOfPaths[i],
				exact: false,
				strict: false,
			});

			if (match) return true;
		}

		return false;
	};

	useEffect(() => {
		if (excludePath(pathname, ['/customers', '/search', '/admin/search', '/user', '/admin/companylist', '/admin/userlist'])) {
			dispatch(resetCompanyData()); // TODO: it will be better to add some logic to detect previous path --> if previous '/customers' or '/search' or 'user' --> do not reset state (it has already been reset)
		}
	}, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(isAdmin());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const parseRoutes = (array, pathPrefix = '') => {
		let result = [];
		array.forEach(function ({
			path,
			exact,
			masterPage,
			hideFooter,
			pageTheme,
			isPrivate,
			isRequireCompanyData,
			component,
			children,
			...rest
		}) {
			result.push(
				<AppRoute
					{...rest}
					key={pathPrefix + path}
					path={path}
					pathname={pathname}
					masterPage={masterPage}
					hideFooter={hideFooter}
					pageTheme={pageTheme}
					exact={exact}
					isPrivate={isPrivate}
					isRequireCompanyData={isRequireCompanyData}
					component={component}
					analytics={analytics}
					contract={contract}
				/>
			);

			if (Array.isArray(children)) {
				result = result.concat(parseRoutes(children, path));
			}
		});

		return result;
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<ThemeProvider theme={theme}>
				<MsalProvider instance={msalInstance}>
					<StyledEngineProvider injectFirst>
						<CssBaseline />
						<Switch>{parseRoutes(routes)}</Switch>
						<Toaster />
						<CookieConsent />
					</StyledEngineProvider>
				</MsalProvider>
			</ThemeProvider>
		</LocalizationProvider>
	);
};

export default App;
