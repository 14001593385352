import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const FormErrorList = ({ errorMessages }) => {
    const intl = useIntl();

    return (
        <Box sx={{ p: 1 }}>
            <Typography sx={{ fontSize: '1.1rem', fontWeight: 500 }}>{intl.formatMessage({ id: 'toast.error.title' })}</Typography>
            <List>
                {errorMessages.map((message, index) =>
                    <ListItem key={message + index} sx={{ p: 0 }}>
                        <ListItemText>
                            {index < 2 ? `- ${message}` : index === 2 ? '...' : null}
                        </ListItemText>
                    </ListItem>
                )}
            </List>
        </Box>
    );
};

export default FormErrorList;
