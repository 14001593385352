import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useUpdateCompanyOnboardedMutation } from 'containers/Company/companyV2Api';
import { getCompanyId } from 'utils/auth/company';

import Button from 'components/Button/Button';

type Props = {
	onPrevStepClick: () => void;
	onNextStepClick: () => void;
};

const OnboardingStep6 = React.forwardRef<any, Props>(({ onNextStepClick, onPrevStepClick, ...otherProps }, ref) => {
	const companyId = getCompanyId();

	// Update CompanyOnboarding with useUpdateCompanyOnboardedMutation.
	const [updateCompanyOnboarded, { isLoading: isCompanyOnboardedLoading, isError: isCompanyOnboardedError }] =
		useUpdateCompanyOnboardedMutation();

	// Make useCallback hook that calls updateCompanyOnboarded with { companyId }. Unwrap the promise. Call onNextStepClick from handleNextStepClick. Use try catch block. Console log error.
	const handleNextStepClick = React.useCallback(async () => {
		try {
			await updateCompanyOnboarded({ companyId }).unwrap();
			onNextStepClick();
		} catch (error) {
			console.error(error);
		}
	}, [companyId, onNextStepClick, updateCompanyOnboarded]);

	return (
		<Box {...otherProps} ref={ref} sx={{ p: '2rem', width: '100%', maxWidth: { xs: '100%', md: '60rem' } }}>
			<Stack spacing={2} flexDirection='column' useFlexGap>
				{isCompanyOnboardedError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='mutation.error.title' />
						</AlertTitle>
						<FormattedMessage id='updateCompanyOnboarded.error.message' />
					</Alert>
				)}
				<Box>
					<Typography
						sx={{ fontFamily: 'Aleo', fontWeight: 700, fontSize: '1.715rem', lineHeight: 1.3, textAlign: 'center', mb: '2rem' }}
					>
						<FormattedMessage id='onboarding.step6.title' />
					</Typography>
					<Typography sx={{ fontSize: '1.145rem', lineHeight: 1.5, textAlign: 'center', mb: '1rem' }}>
						<FormattedMessage id='onboarding.step6.description' />
					</Typography>
				</Box>
				<Stack direction='row' spacing={2}>
					{/* @ts-ignore */}
					<Button
						color='primary'
						variant='outlined'
						sx={{ p: '0.64286rem 1.21429rem', lineHeight: '1.14286rem', fontSize: '1rem' }}
						onClick={onPrevStepClick}
						fullWidth
					>
						<FormattedMessage id='onboarding.step.prev' />
					</Button>
					{/* @ts-ignore */}
					<Button
						color='primary'
						variant='contained'
						sx={{ p: '0.64286rem 1.21429rem', lineHeight: '1.14286rem', fontSize: '1rem' }}
						loading={isCompanyOnboardedLoading}
						onClick={handleNextStepClick}
						fullWidth
					>
						<FormattedMessage id='onboarding.step.finish' />
					</Button>
				</Stack>
			</Stack>
		</Box>
	);
});

export default OnboardingStep6;
