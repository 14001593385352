import { useState } from 'react';

import { IconButton, Tooltip } from '@mui/material';

import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';

export default function ConfirmButton({
	color = 'primary',
	loading = false,
	disabled,
	confirmText,
	cancelText,
	confirmBodyText,
	confirmAction,
	children,
	stopPropagation,
	sx,
	tooltip = null,
	...props
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const confirm = e => {
		setConfirmOpen(true);
		setAnchorEl(e.currentTarget);
	};

	const confirmActionLaunch = e => {
		if (confirmAction) confirmAction();

		handleClose(e);
	};

	const handleClose = e => {
		stopPropagation && e.stopPropagation();
		setAnchorEl(null);
		setConfirmOpen(false);
	};

	return (
		<>
			<Tooltip title={tooltip}>
				<IconButton
					disabled={loading || disabled}
					size='small'
					color={color}
					onClick={e => {
						stopPropagation && e.stopPropagation();
						confirm(e);
					}}
					sx={{ ...sx }}
					{...props}
				>
					{children}
					{loading && <ButtonSpinner />}
				</IconButton>
			</Tooltip>
			<ConfirmPopper
				confirmAction={confirmActionLaunch}
				handleClose={handleClose}
				isopen={confirmOpen}
				anchorElement={anchorEl}
				confirmText={confirmText}
				cancelText={cancelText}
				confirmBodyText={confirmBodyText}
			/>
		</>
	);
}
