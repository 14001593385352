import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import PageSpinner from 'components/Spinner/PageSpinner';
import ProjectPlan from 'containers/Projects/Project/ProjectPlan';

import { useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import { getInitialProject } from 'containers/Projects/Project/ProjectPlan/ProjectPlan';
import { useFetchProjectQuery, useFetchProjectsQuery, useFetchTimelineQuery } from 'containers/Projects/projectRTKApi';
import { isNil } from 'lodash';
import { ISortableItem, Project } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import { isAdminToken } from 'utils/auth/token';
import { useLocalBackup } from 'utils/helpers/localBackupUtils';
import { matchPath } from 'utils/helpers/pathMatch';

const ProjectPlanPage = ({ passPageProps }: any) => {
	const companyId = getCompanyId();
	const isAdmin = isAdminToken();

	const location = useLocation();
	const { id }: any = useParams();
	const addNewPage = matchPath('/projects/add', location);

	let {
		data: projects,
		isLoading: isProjectsLoading,
		isFetching: isProjectsFetching,
	} = useFetchProjectsQuery({ companyId }, { skip: isNil(companyId) });
	const projectsLoading = isProjectsLoading || isProjectsFetching;

	let {
		data: projectData,
		isLoading: isProjectLoading,
		isFetching: isProjectFetching,
	} = useFetchProjectQuery({ companyId, projectId: id, draft: isAdmin, withData: true }, { skip: !companyId || !id });
	const projectDataLoaded = !isProjectLoading && !isProjectFetching;

	let {
		data: timeline,
		isLoading: isTimelineLoading,
		isFetching: isTimelineFetching,
	} = useFetchTimelineQuery({ companyId }, { skip: !companyId });
	const timelineLoading = isTimelineLoading || isTimelineFetching;

	const { data: instruments, isLoading: isInstrumentsLoading } = useFetchInstrumentsByCompanyQuery(
		{ companyId },
		{ skip: isNil(companyId) }
	);

	const {
		data: projectLocalBackupData,
		isLocalBackup,
		deleteLocalBackup,
		updateLocalBackup,
	} = useLocalBackup('project_backup', id, projectData);

	useEffect(() => {
		if (projectData && Object.keys(projectData).length !== 0) {
			passPageProps([{ id: projectData.projectId, name: projectData.projectName }]);
		}
	}, [passPageProps, projectData]);

	const loading = (projectsLoading || isInstrumentsLoading) ?? false;

	const showLoader = loading || (id && !projectData) || !projectDataLoaded || timelineLoading;
	const projectDataLoading = projectData ? !addNewPage && (!projectData?.dynamic || projectData?.dynamic === null) : false;
	const pageLoading = projectsLoading || projectDataLoading;

	if (showLoader || loading || pageLoading) return <PageSpinner />;

	let project: Project = projectLocalBackupData ?? projectData ?? getInitialProject({ timelineId: timeline[0].timelineId, companyId });

	const sortBySortOrder = (a: ISortableItem, b: ISortableItem) => {
		const sortOrderA = a.sortOrder ?? 0;
		const sortOrderB = b.sortOrder ?? 0;

		return sortOrderA - sortOrderB;
	};

	let clonedProjectTaskGroups = project?.projectTaskGroups
		? project.projectTaskGroups.map(taskGroup => ({
				...taskGroup,

				tasks: taskGroup.tasks ? [...taskGroup.tasks] : [],
		  }))
		: [];

	// Sort the cloned project task groups by sort order
	clonedProjectTaskGroups.sort(sortBySortOrder);
	clonedProjectTaskGroups.forEach(taskGroup => {
		// Sort the cloned tasks inside each task group by sort order
		taskGroup.tasks.sort(sortBySortOrder);
	});

	// Assign the cloned and sorted task groups back to the project's projectTaskGroups property
	project = {
		...project,
		projectTaskGroups: clonedProjectTaskGroups,
	};

	return (
		<ProjectPlan
			timeline={timeline[0]}
			project={project}
			instruments={instruments ?? []}
			chatId={id}
			isLocalBackup={isLocalBackup}
			deleteLocalBackup={deleteLocalBackup}
			updateLocalBackup={updateLocalBackup}
			surrogate={false}
		/>
	);
};

export default ProjectPlanPage;
