import * as React from 'react';
import { Link as DomLink } from 'react-router-dom';

import { styled } from '@mui/styles';
import { Typography } from '@mui/material';

const ButtonWrapper = styled(DomLink)(() => ({
    textDecoration: 'none',
}));

const WidgetLink = styled(Typography, {
    shouldForwardProp: prop => prop !== 'backgroundcolor',
    overridesResolver: (props, styles) => [
        styles.root,
        props.backgroundcolor === 'primary' && styles.primary,
    ],
})(({ backgroundcolor }) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '170%',
    backgroundColor: backgroundcolor,
    textTransform: 'none',
    '& p': {
        padding: '0 0.8rem',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '0.2rem'
        }
    },
}));

export default function TextLink({ color, disabled, children, href }) {

    if (disabled) {
        return (
            <Typography
                component='span'
                backgroundcolor={color ?? '#057871'}
            >
                {children}
            </Typography>
        );
    }

    return (
        <ButtonWrapper to={href}>
            <WidgetLink
                component='span'
                backgroundcolor={color ?? '#057871'}
            >
                {children}
            </WidgetLink>
        </ButtonWrapper>
    );
}