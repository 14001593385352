import React, { useEffect, useState } from 'react';
import { useIntl, FormattedDate } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';

import ContentContainer from 'components/Containers/ContentContainer';
import CustomerForm from './CustomerForm';
import { getCustomerInfo } from 'containers/Advanced/slices';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(theme => ({
    cell: {
        padding: '0.5rem 1rem 0.5rem 0',
        borderBottom: 'none',
        verticalAlign: 'top'
    },
    key: {
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    prop: {
        color: theme.palette.primary.main,
    },
}));

const ModalContent = ({ created, author, botProfileRowKey, contactProfile, /* advancedCustomerName, */ rowKey }) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    const customers = useSelector(state => state.customers);

    const [state, setstate] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        if (state) return;

        const fetchData = async () => {
            let data = await dispatch(getCustomerInfo(rowKey));

            data = Object.assign({ notes: '' }, data);

            setData(data);
            setstate(true);
        };

        fetchData().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowKey]);


    return (
        <ContentContainer>
            <TableContainer>
                <Table aria-label='timeline' size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <Typography component='p' className={classes.key}>
                                    {intl.formatMessage({ id: 'advanced.modal.created' })}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <Typography component='p' className={classes.prop}>
                                    {created ? <FormattedDate value={created} /> : '-'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <Typography component='p' className={classes.key}>
                                    {intl.formatMessage({ id: 'advanced.modal.author' })}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <Typography component='p' className={classes.prop}>
                                    {author ?? '-'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <Typography component='p' className={classes.key}>
                                    {intl.formatMessage({ id: 'advanced.modal.bot' })}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <Typography component='p' className={classes.prop}>
                                    {botProfileRowKey ? <CheckIcon color='primary' /> : '-'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <Typography component='p' className={classes.key}>
                                    {intl.formatMessage({ id: 'advanced.modal.contact' })}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <Typography component='div' className={classes.prop}>
                                    {contactProfile
                                        ? <Box>
                                            {contactProfile?.name &&
                                                <Stack direction='row' spacing={1} sx={{ paddingBottom: 1 }} >
                                                    <PersonIcon color="primary" style={{ opacity: '0.2' }} />
                                                    <Typography color='primary' gutterBottom sx={{ fontSize: '1rem', fontWeight: 600 }}>{contactProfile.name}</Typography>
                                                </Stack>
                                            }
                                            {contactProfile?.email &&
                                                <Stack direction='row' spacing={1} sx={{ paddingBottom: 1 }}>
                                                    <MailOutlineIcon color="primary" style={{ opacity: '0.2' }} />
                                                    <Link href={`mailto:${contactProfile.email}`} sx={{ display: 'block' }}>{contactProfile.email}</Link>
                                                </Stack>
                                            }
                                            {contactProfile?.phonenumber &&
                                                <Stack direction='row' spacing={1}>
                                                    <PhoneIcon color="primary" style={{ opacity: '0.2' }} />
                                                    <Link underline='none' href={`tel:${contactProfile.phonenumber}`} sx={{ display: 'block' }}>{contactProfile.phonenumber}</Link>
                                                </Stack>
                                            }
                                        </Box>
                                        : '-'
                                    }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <Typography component='p' className={classes.key}>
                                    {intl.formatMessage({ id: 'advanced.modal.contact.notes' })}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {customers?.customerLoading && !data
                                    ? <Stack direction='row' spacing={1}>
                                        <CircularProgress size={14} />
                                        <Typography sx={{ ml: '1rem' }}>{intl.formatMessage({ id: 'advanced.modal.contact.loading' })}</Typography>
                                    </Stack>
                                    : data && <CustomerForm data={data} customerId={rowKey} loading={customers?.customerLoading} />}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </ContentContainer>
    );
};

export default ModalContent;
