import { createSlice } from '@reduxjs/toolkit';
import { push } from 'react-router-redux';

import { resetAllData } from 'containers/App/appSlice';
import { saveToLocalStorage } from '../../utils/localStorage/localStorageHandler';
import { companySearchApi } from './api';

export const name = 'companySearch';

export const initialState = {
	loading: false,
	error: null,
	companies: [],
	selected: {},
	isOptionBusinessId: false,
};

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return initialState;
		},
		searchCompanyData: state => {
			state.loading = true;
			state.error = null;
		},
		searchCompanyDataError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.companies = initialState.companies;
		},
		searchCompanyDataSuccess: (state, action) => {
			state.loading = false;
			state.error = null;
			state.companies = action.payload;
		},
		selectCompany: (state, action) => {
			state.loading = false;
			state.error = null;
			state.selected = action.payload;
		},
		setIsOptionBusinessId: (state, action) => {
			state.loading = false;
			state.error = null;
			state.isOptionBusinessId = action.payload;
		},
	},
});

export const { reducer } = slice;

// reset
export const resetSelectedCompany = () => async dispatch => {
	dispatch(slice.actions.resetData());
};

// company data
export const searchCompanyData = name => async dispatch => {
	try {
		dispatch(slice.actions.searchCompanyData());

		try {
			name = name.trim();
		} catch {
			// continue regardless of error
		}

		const freemiumIdRegex = /^([A-Za-z]-\d{8}-\d{1})$/gm;
		const businessIdRegex = /^(\d{7}-\d{1})$/gm;

		const isFreemiumId = !!name.match(freemiumIdRegex); // does it match -> is businessId
		const isBusinessId = !!name.match(businessIdRegex); // does it match -> is businessId

		const companies = await companySearchApi.getSearchCompanyData(name, isFreemiumId || isBusinessId);

		if ((isFreemiumId || isBusinessId) && companies) {
			dispatch(slice.actions.setIsOptionBusinessId(true));

			// is businessId search -> parse data
			const data = [];

			data.push({
				businessId: companies.businessId,
				companyId: companies.companyId,
				name: companies.name,
				registrationDate: companies.registrationDate,
				companyForm: companies.companyForm,
				isCustomer: companies.isCustomer ?? false,
			});

			dispatch(slice.actions.searchCompanyDataSuccess(data));
		} else {
			dispatch(slice.actions.setIsOptionBusinessId(false));
			dispatch(slice.actions.searchCompanyDataSuccess(companies));
		}

		return true;
	} catch (err) {
		dispatch(slice.actions.searchCompanyDataError(err));

		return false;
	}
};

export const selectCompany = selected => async dispatch => {
	try {
		const companyId = selected?.companyId ?? selected?.businessId;
		if (Object.keys(selected).length > 1 && companyId) {
			dispatch(resetAllData());
			saveToLocalStorage({ id: companyId, name: selected.name });
			dispatch(push(`/company/${companyId}`));
		}
	} catch (err) {
		dispatch(slice.actions.searchCompanyDataError(err));
	}
};

export default slice;
