import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		color: theme.palette.primary.main,
		border: 'none',
		padding: 0,
		backgroundColor: 'transparent',
		textAlign: 'right',
	},
	bold: {
		fontWeight: 600,
		fontSize: '1rem',
	},
	leftAlign: {
		textAlign: 'left',
	},
}));

const Currency = ({ value, bold = false, leftAlign = false }) => {
	const classes = useStyles();

	return (
		<CurrencyInput
			value={value}
			groupSeparator=' '
			decimalSeparator=','
			suffix=' €'
			decimalsLimit={2}
			decimalScale={2}
			fixedDecimalLength
			allowNegativeValue={false}
			maxLength={15}
			onValueChange={null}
			onFocus={null}
			disabled={true}
			step={1}
			className={`${classes.root} ${bold && classes.bold} ${leftAlign && classes.leftAlign}`}
		/>
	);
};

export default Currency;
