import React from 'react';
import { useIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles } from '@mui/styles';

import StyledMenu from 'components/StyledMenu';

const useStyles = makeStyles(theme => ({
    menuItem: {
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.white,
            },
        },
    },
}));

const DropdownMenu = props => {
    const { onClose, isContributor, isAdvanced, pathname } = props;
    const classes = useStyles();
    const intl = useIntl();

    const divProps = { ...props };
    delete divProps.isContributor;
    delete divProps.isAdvanced;

    return (
        <StyledMenu {...divProps}>
            {isContributor && (
                <MenuItem onClick={() => onClose('search')} className={classes.menuItem}>
                    <ListItemIcon>
                        <BusinessIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: 'company.search.dropdown' })} />
                </MenuItem>
            )}
            {isAdvanced && (
                <MenuItem onClick={() => onClose('advanced')} className={classes.menuItem}>
                    <ListItemIcon>
                        <PeopleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: 'advanced.drawer.customerlist' })} />
                </MenuItem>

            )}
            {isAdvanced && (
                <MenuItem onClick={() => onClose('advanced')} className={classes.menuItem}>
                    <ListItemIcon>
                        <ConnectWithoutContactIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: 'advanced.drawer.prospectList' })} />
                </MenuItem>
            )}
            {pathname !== '/agreement' && (
                <MenuItem onClick={() => onClose('user')} className={classes.menuItem}>
                    <ListItemIcon>
                        <AccountCircleIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: 'common.own.information' })} />
                </MenuItem>
            )}
            {/* {isContributor && (
                <MenuItem onClick={() => onClose('slideshow')} className={classes.menuItem}>
                    <ListItemIcon>
                        <SlideshowIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: 'common.slideshow' })} />
                </MenuItem>
            )} */}
            <MenuItem onClick={() => onClose()} className={classes.menuItem}>
                <ListItemIcon>
                    <ExitToAppIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'logout' })} />
            </MenuItem>
        </StyledMenu>
    );
};

export default DropdownMenu;
