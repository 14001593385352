const primaryColor = '#482e51';
const primaryColorRgb = '72,46,81';
const errorColor = '#8e0e44';
const warningColor = '#cca111';
const successColor = '#077871';
const successLightColor = '#88C493';
const whiteColor = '#ffffff';
const greyColor = '#bab3a8';
const lightGreyColor = '#f7f5f0';
const secodandaryRgb = '247,245,240';
const lightGreyColor2 = '#e2ddde';
const lightGreyColor3 = '#f8f8f7';
const lightGreyColor4 = '#eee8de';
const lightGreyColor5 = '#fbfaf7';
const lightPurpleColor = '#b2a5b1';
const beigeColor = '#e0d6c4';
const lightBeigeColor = '#f2eee5';
const blueColor = '#1976d2';
const menuActiveColor = '#3c2640';
const pillColor = '#57446e';
const pillTextColor = '#d1cde4';
const grayishBlue = '#5A647C';

// new design:
const grey200Color = '#eeece9';
const grey300Color = '#e7e4df';
const grey400Color = '#9ca3af';
const grey500Color = '#6b7280';
const grey600Color = '#4b5563';
const grey700Color = '#374151';
const grey900Color = '#111827';

const borderColor = '#d1d5db';
const lightBoxFooter = '#f9fafb';

export {
	primaryColor,
	primaryColorRgb,
	errorColor,
	warningColor,
	successColor,
	successLightColor,
	whiteColor,
	greyColor,
	lightGreyColor,
	lightGreyColor2,
	lightGreyColor3,
	lightGreyColor4,
	lightGreyColor5,
	lightPurpleColor,
	beigeColor,
	lightBeigeColor,
	blueColor,
	grey200Color,
	grey300Color,
	grey400Color,
	grey500Color,
	grey600Color,
	grey700Color,
	grey900Color,
	borderColor,
	lightBoxFooter,
	menuActiveColor,
	pillColor,
	pillTextColor,
	grayishBlue,
	secodandaryRgb
};
