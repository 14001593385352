import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import ContentContainer from 'components/Containers/ContentContainer';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import HeaderTitle from 'views/Dashboard/HeaderTitle';
import Score from 'containers/Score';
import Timeline from 'containers/FinancialPlan';
import AnalysisChart from 'components/AnalysisChart';
import NotFound from 'views/NotFound';
import FinancialPlanDrawer from 'containers/FinancialPlan/FinancialPlanDrawer';
import DeminimisWidget from 'views/Dashboard/DeminimisWidget';
import BusinessPlanWidget from 'views/Dashboard/BusinessPlanWidget';
import InstrumentCategoryList from 'containers/Instruments/InstrumentList/InstrumentCategoryList';

import { useInjectReducer } from 'utils/injectors/injectReducer';
import { reducer, name as profileKey } from 'containers/Profile/slices';
import { getProfile } from './slices';
import { mockFinancialPlan } from 'utils/mock/financialPlan';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
	spinnerContainer: {
		height: 'calc(100vh - 64px - 164px)',
		[theme.breakpoints.up('sm')]: {
			height: 'calc(100vh - 64px - 134px)',
		},
	},
	expiredWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100vh - 64px - 164px)',
		[theme.breakpoints.up('sm')]: {
			height: 'calc(100vh - 64px - 134px)',
		},
	},
	expiredMessage: {
		fontSize: '1.5rem',
		[theme.breakpoints.up('sm')]: {
			fontSize: '2rem',
		},
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	scoreWrapper: {
		[theme.breakpoints.up('md')]: {
			minWidth: '380px',
			maxWidth: '450px',
		},
	},
	timelineWrapper: {
		flexGrow: 1,
		minWidth: 0,
		paddingTop: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			paddingTop: 0,
			paddingLeft: theme.spacing(3),
		},
	},
	halfWrapper: {
		flexGrow: 1,
		minWidth: '50%',
		[theme.breakpoints.up('md')]: {
			paddingTop: 0,
		},
	},
	halfWrapperSecond: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: theme.spacing(1),
		},
	},
	halfWrapperFirst: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(1),
		},
	},
	componentWrapper: {
		marginTop: theme.spacing(2),
	},
}));

const Profile = () => {
	useInjectReducer({ key: profileKey, reducer });

	const intl = useIntl();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { link } = useParams();
	const profile = useSelector(state => state.profile);

	useEffect(() => {
		if (profile === undefined || !profile.profile) dispatch(getProfile(link));
	}, [dispatch, profile, link]);

	const loading = profile?.loading;
	const error = profile?.error;
	const data = profile?.profile;

	// HeaderTitle & CompanyProfile & Analysis
	const company = data?.company;
	const economicalData = company?.economicalData;

	// BusinessPlan
	const businessPlan = data?.businessPlan;
	const companyId = company?.companyId;

	// CompanyProfile
	const scenario = data?.mainScenario;

	// FinancialPlan
	const financialPlan = data?.financialPlan;
	const financialPlanData = financialPlan?.financialPlan ?? mockFinancialPlan;

	// Timeline / Projects:
	const timeline = data?.timeline;
	const projects = data?.projects;
	const mock = data?.mock;

	// Instruments & CompanyProfile
	const instruments = data?.instruments;
	const categories = data?.categories;

	const currentInstrumentId = instruments?.currentInstrumentId;
	const currentCategory = instruments?.currentCategory;

	const timelineRef = useRef();
	const padding = 8;

	const timelineContainerRef = useRef();
	const [timelineContainerWidth, setTimelineContainerWidth] = useState(null);

	useLayoutEffect(
		() => {
			const updateWidth = () => {
				setTimelineContainerWidth(timelineContainerRef.current?.clientWidth);
			};

			window.addEventListener('resize', updateWidth);
			updateWidth();

			return () => window.removeEventListener('resize', updateWidth);
		} /*, [] */
	);

	const [xScrollWidth, setXScrollWidth] = useState(timelineContainerWidth);
	const [xScrollLeft, setXScrollLeft] = useState(0);

	useEffect(() => {
		// should be initially at least 1px bigger than (timelineContainerWidth + padding)
		setXScrollWidth(timelineContainerWidth + padding + 1);
	}, [timelineContainerWidth]);

	const onScroll = () => {
		const xScrollWidth = timelineRef.current?.scrollWidth;
		const xScrollLeft = timelineRef.current?.scrollLeft;
		setXScrollWidth(xScrollWidth);
		setXScrollLeft(xScrollLeft);
	};

	const [isHorizontalScrollbar, setIsHorizontalScrollbar] = useState(false);
	const hasHorizontalScrollbar = tableWidth => setIsHorizontalScrollbar(tableWidth + padding + 1 >= timelineContainerWidth);
	const isRenderLeftSmooth = isHorizontalScrollbar && xScrollLeft > padding;
	const isRenderRightSmooth = isHorizontalScrollbar && xScrollWidth + 32 > xScrollLeft + timelineContainerWidth;

	const [isOpenFinancialPlanDrawer, setIsOpenFinancialPlanDrawer] = useState(false);
	const openFinancialPlanDrawer = () => setIsOpenFinancialPlanDrawer(true);
	const closeFinancialPlanDrawer = () => setIsOpenFinancialPlanDrawer(false);

	return loading && !error ? (
		<Grid container justifyContent='center' alignItems='center' className={classes.spinnerContainer}>
			<Grid item>
				<CircularProgress size={24} color='primary' />
			</Grid>
		</Grid>
	) : !loading && error && error.statusCode === 404 ? (
		<NotFound profile={true} />
	) : !loading && error && error.statusCode === 400 ? (
		<div className={classes.expiredWrapper}>
			<Typography variant='h1' color='primary' className={classes.expiredMessage}>
				{intl.formatMessage({ id: 'profile.page.400' })}
			</Typography>
		</div>
	) : !loading && !error && data ? (
		<ContentContainer>
			<LeftRightContainer left={<HeaderTitle company={company} />} right={null} />
			<div className={classes.componentWrapper}>
				<DeminimisWidget projects={projects} />
			</div>
			<div className={`${classes.flexContainer} ${classes.componentWrapper}`}>
				<div className={classes.scoreWrapper}>
					<Score
						score={data?.scores}
						missingValues={scenario?.missingValues}
						hideAnalysis={true}
						/* openProfileModal={openModal} */ profile={true}
					/>
				</div>
				<div className={classes.timelineWrapper}>
					<Timeline
						profile={true}
						ref={timelineRef}
						timeline={timeline}
						projects={projects}
						mock={mock}
						instruments={instruments}
						padding={padding}
						onScroll={onScroll}
						hasHorizontalScrollbar={hasHorizontalScrollbar}
						isRenderRightSmooth={isRenderRightSmooth}
						isRenderLeftSmooth={isRenderLeftSmooth}
						hideContactCalendar={true}
						isStaticProfileConst={true}
						timelineContainerWidth={timelineContainerWidth}
						openFinancialPlanDrawer={openFinancialPlanDrawer}
					/>
				</div>
			</div>
			<div className={`${classes.flexContainer} ${classes.componentWrapper}`}>
				<Box className={`${classes.halfWrapper} ${classes.halfWrapperFirst}`}>
					<AnalysisChart data={economicalData} height={390} />
				</Box>
				<Box className={`${classes.halfWrapper} ${classes.halfWrapperSecond}`}>
					<BusinessPlanWidget businessPlan={businessPlan} companyId={companyId} history={history} profile={true} />
				</Box>
			</div>
			<div className={classes.componentWrapper}>
				<InstrumentCategoryList
					categories={categories}
					company={company}
					instruments={instruments}
					currentInstrumentId={currentInstrumentId}
					currentCategory={currentCategory}
					profile={true}
				/>
			</div>
			<FinancialPlanDrawer
				open={isOpenFinancialPlanDrawer}
				close={closeFinancialPlanDrawer}
				company={company}
				financialPlan={financialPlanData}
				timeline={timeline}
				projects={projects}
				mock={mock}
				scenario={scenario}
				instruments={instruments}
			/>
		</ContentContainer>
	) : (
		<div>Problem rendering component</div>
	);
};

export default Profile;
