import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

import CloudTooltip from 'containers/Scenario/ScenarioForm/CloudTooltip';
import CustomPopover from './CustomPopover';

const useStyles = makeStyles(theme => ({
	formControl: {
		border: `1px solid ${theme.palette.secondary.border}`,
		borderRadius: '5px',
		padding: theme.spacing(1),
		paddingRight: theme.spacing(1.5),
		paddingLeft: theme.spacing(1.5),
		'&:hover': {
			cursor: 'pointer',
			borderColor: theme.palette.secondary.grey400,
		},
		'& .MuiInput-root': {
			color: theme.palette.primary.main,
			marginTop: 0,
		},
		'& .MuiOutlinedInput-input, &:hover .MuiInputLabel-root, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input, & .MuiInputLabel-root.Mui-focused':
		{
			color: theme.palette.primary.main,
			padding: 0,
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
		{
			border: 'none',
		},
		'& svg': {
			top: '-0.5rem',
			right: 0,
		},
	},
	formControlZero: {
		borderColor: theme.palette.error.main,
		'&:hover': {
			borderColor: '#5d092c',
		},
	},
	label: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		color: theme.palette.secondary.grey500,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	disabled: {
		cursor: 'not-allowed',
		borderColor: '#ccc',
		'&:hover': {
			cursor: 'not-allowed',
			borderColor: '#ccc',
		},
	},
}));

const BorderedFormBox = ({ children, name, label, error, popoverContent, isCreatedByBot, openSelect, setOpenSelect, disabled }) => {
	const classes = useStyles();

	// popover
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = event => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Stack direction='row' alignItems='center' spacing={2} sx={{ mt: 0.5, mb: 2 }}>
			<FormControl
				sx={{ background: '#fff' }}
				fullWidth
				onClick={() => setOpenSelect && setOpenSelect(!openSelect)}
				className={`${classes.formControl} ${disabled && classes.disabled} ${error && classes.formControlZero}`}
			>
				<FormLabel>
					<Typography id={name} className={`${classes.label} ${disabled && classes.disabled}`}>
						{label}
					</Typography>
				</FormLabel>
				{children}
			</FormControl>
			<Box>
				<CustomPopover
					id={id}
					open={open}
					anchorEl={anchorEl}
					handleClick={handleClick}
					handleClose={handleClose}
					content={popoverContent}
				/>
			</Box>
			<CloudTooltip isCreatedByBot={isCreatedByBot} />
		</Stack>
	);
};

export default BorderedFormBox;
