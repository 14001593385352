import { isNil } from 'lodash';
import * as React from 'react';

import { Box, Stack } from '@mui/material';

import { CompanyNote } from 'types/dataTypes';

import HtmlParser from 'components/HtmlParser/HtmlParser';

interface Props {
	companyNote?: Partial<CompanyNote> | null;
	first: boolean;
}

const CompanyNoteListItem: React.FC<Props> = ({ companyNote, first }) => {
	// const dispatch = useDispatch();
	// const { formatMessage } = useIntl();

	// const isMyCompanyNote = React.useMemo(() => {
	// 	const user = getUser();
	// 	return user?.email === companyNote?.author;
	// }, [companyNote]);

	if (isNil(companyNote)) {
		return null;
	}

	return (
		<Stack
			flexDirection='column'
			spacing={1}
			// sx={{ py: '1rem', borderBottom: 1, borderTop: first ? 1 : 0, borderColor: 'divider' }}
			useFlexGap
			sx={{ width: '100%' }}
		>
			<Box>{HtmlParser(companyNote.content)}</Box>
			{/* <Stack flexDirection='row' justifyContent='flex-end' spacing={1} useFlexGap>
				{isMyCompanyNote ? (
					<>
						{/* @ts-ignore *
						<Button
							startIcon={<EditIcon />}
							onClick={() => dispatch(openDialog({ name: 'UpsertCompanyNoteDialog', data: companyNote }))}
						>
							{formatMessage({ id: 'company.notes.list.item.edit' })}
						</Button>
					</>
				) : null}
			</Stack> */}
		</Stack>
	);
};

export default CompanyNoteListItem;
