import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { API_ROOT } from 'config/environment';
import { isContributorToken } from 'utils/auth/token';
import { getCompanyId, getCompany, addAdvancedCompanyQuery } from 'utils/auth/company';

/**
 * Add a URL parameter
 * @param {url} string url
 * @param {param} string param the key to set
 * @param {value} string value
 */
export const addParam = (url, param, value) => {
	param = encodeURIComponent(param);

	let a = document.createElement('a');

	param += value ? '=' + encodeURIComponent(value) : '';

	a.href = url;
	a.search += (a.search ? '&' : '') + param;

	return a.href;
};

/**
 * Add a URL parameter (or modify if already exists)
 * @param {url} string url
 * @param {param} string param the key to set
 * @param {value} string value
 */
export const addOrReplaceParam = (url, param, value) => {
	param = encodeURIComponent(param);

	const r = '([&?]|&amp;)' + param + '\\b(?:=(?:[^&#]*))*';
	const regex = new RegExp(r);

	let a = document.createElement('a');

	let str = param + (value ? '=' + encodeURIComponent(value) : '');
	a.href = url;

	let q = a.search.replace(regex, '$1' + str);

	if (q === a.search) {
		a.search += (a.search ? '&' : '') + str;
	} else {
		a.search = q;
	}

	return a.href;
};

export const validateHttpStatus = status => {
	return status >= 200 && status < 300; // default
};

export const buildUri = (baseUrl, fragment, draft, migratedCompanyId) => {
	let companyId = getCompanyId();

	if (migratedCompanyId) {
		companyId = migratedCompanyId;
	}

	let url = fragment ? `${API_ROOT}/${baseUrl}/${companyId}/${fragment}` : `${API_ROOT}/${baseUrl}/${companyId}`;

	if (addAdvancedCompanyQuery()) {
		const company = getCompany();

		url = addParam(url, 'advancedCompanyId', company.CompanyId);
	}

	if (draft && isContributorToken()) {
		url = addParam(url, 'draft', 'true');
	}

	return url;
};

export const buildBaseUrl = (path, fragment) => {
	return fragment ? `${API_ROOT}/${path}/${fragment}` : `${API_ROOT}/${path}`;
};

export const buildPlainUri = (baseUrl, fragment, draft) => {
	let url = fragment ? `${API_ROOT}/${baseUrl}/${fragment}` : `${API_ROOT}/${baseUrl}`;

	if (addAdvancedCompanyQuery()) {
		const company = getCompany();

		url = addParam(url, 'advancedCompanyId', company.CompanyId);
	}

	if (draft && isContributorToken()) {
		url = addParam(url, 'draft', 'true');
	}

	return url;
};

export const buildAdvancedCompanyUri = (baseUrl, fragment) => {
	let url = fragment ? `${API_ROOT}/${baseUrl}/${fragment}` : `${API_ROOT}/${baseUrl}`;

	const company = getCompany();

	url = addParam(url, 'advancedCompanyId', company.CompanyId);

	return url;
};

export const buildMigrationUri = (companyId, baseUrl, fragment /*, draft*/) => {
	let url = fragment ? `${API_ROOT}/${baseUrl}/${companyId}/${fragment}` : `${API_ROOT}/${baseUrl}/${companyId}`;

	if (addAdvancedCompanyQuery()) {
		const company = getCompany();

		url = addParam(url, 'advancedCompanyId', company.CompanyId);
	}

	// TODO: for some reason it doesn't work
	// if (draft && isContributorToken()) {
	//     url = `${url}?draft=true`;
	// }

	return url;
};

export const buildMigrationPlainUri = (baseUrl, fragment, draft) => {
	let url = fragment ? `${API_ROOT}/${baseUrl}/${fragment}` : `${API_ROOT}/${baseUrl}`;

	if (addAdvancedCompanyQuery()) {
		const company = getCompany();

		url = addParam(url, 'advancedCompanyId', company.CompanyId);
	}

	if (draft && isContributorToken()) {
		url = addParam(url, 'draft', 'true');
	}

	return url;
};

export const useQuery = () => {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
};
