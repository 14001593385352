import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

const StyledSpinner = styled(CircularProgress, {
    shouldForwardProp: prop => prop !== 'spinnerColor' && prop !== 'position',
})(({ size, spinnerColor, position }) => ({
    position: position,
    size: size,
    color: spinnerColor,
}));

const Spinner = ({ size = 16, color = '#fff', position = 'absolute' }) => (
    <StyledSpinner size={size} spinnerColor={color} position={position} />
);

export default Spinner;