import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.success.main,
    },
    contentWrapper: {
        flex: 1,
    },
    contentContainer: {
        height: '100%',
    },
    title: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        color: theme.palette.primary.white,
    },
    image: {
        maxWidth: '600px',
    },
    relativeDiv: {
        position: 'relative',
        '& ul': {
            display: 'flex',
            justifyContent: 'space-between',
            listStyleType: 'none',
            paddingLeft: '8rem',
            '&:before': {
                content: "''",
                position: 'absolute',
                height: '1px',
                width: 'calc(100% - 8rem)', // ul left padding
                top: theme.spacing(0.75),
                backgroundColor: theme.palette.primary.white,
            },
            '& li': {
                maxWidth: theme.spacing(8),
            },
        },
    },
    circle: {
        borderRadius: '50%',
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.white,
    },
    text: {
        color: theme.palette.primary.white,
        fontSize: '0.75rem',
    },
    grants: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        paddingBottom: theme.spacing(4),
        color: theme.palette.primary.white,
    },
}));

const Slide7 = ({ maxHeight }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container direction='column' className={classes.root} style={{ maxHeight }}>
            <Grid item className={classes.contentWrapper}>
                <Grid container direction='column' justifyContent='center' className={classes.contentContainer}>
                    <Box textAlign='center'>
                        <Typography className={classes.title}>Eteneminen…</Typography>
                        <img src='fromSlides/7.png' alt='slide7' className={classes.image} />
                    </Box>
                    <div className={classes.relativeDiv}>
                        <ul>
                            <li>
                                <Box>
                                    <Box className={classes.circle}></Box>
                                </Box>
                                <Typography className={classes.text}>KOKEILUJAKSO</Typography>
                            </li>
                            <li>
                                <Box>
                                    <Box className={classes.circle}></Box>
                                </Box>
                                <Typography className={classes.text}>PALVELUNALOITUS</Typography>
                            </li>
                            <li>
                                <Box>
                                    <Box className={classes.circle}></Box>
                                </Box>
                                <Typography className={classes.text}>ASIANTUNTIJAPALAVERI- RAHOITUSPOLUN VALMISTELU</Typography>
                            </li>
                            <li>
                                <Box>
                                    <Box className={classes.circle}></Box>
                                </Box>
                                <Typography className={classes.text}>KVARTAALI TSEKKI</Typography>
                            </li>
                            <li>
                                <Box>
                                    <Box className={classes.circle}></Box>
                                </Box>
                                <Typography className={classes.text}>KVARTAALI TSEKKI</Typography>
                            </li>
                            <li>
                                <Box>
                                    <Box className={classes.circle}></Box>
                                </Box>
                                <Typography className={classes.text}>KVARTAALI TSEKKI</Typography>
                            </li>
                            <li>{/* empty li is required */}</li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
            <Grid item>
                <Typography className={classes.grants}>{intl.formatMessage({ id: 'common.grants' })}</Typography>
            </Grid>
        </Grid>
    );
};

export default Slide7;
