import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';

import Chat from 'containers/Chat/Chat';
import Icon from './icon';
import IconBadged from 'components/IconBadged';

import { getCompany } from 'utils/auth/company';
import { isAdvancedCustomer } from 'utils/auth/company';
import { collaborationDrawerWidth } from 'config/drawerWidth';
import socket from 'utils/socket';

const useStyles = makeStyles(theme => ({
	float: {
		position: 'fixed',
		bottom: '1rem',
		right: '1rem',
		backgroundColor: theme.palette.textPrimary.white,
		boxShadow: '1px 1px 23px 1px rgba(72, 46, 81, .2)',
		padding: '1rem',
		width: '32rem',
		borderRadius: '5px',
		zIndex: 10000,
	},
	circle: {
		width: '4rem',
		height: '4rem',
		backgroundColor: theme.palette.primary.main,
		borderRadius: '4rem',
		textAlign: 'center',
		'&:hover': {
			backgroundColor: 'rgba(72, 46, 81, .9)',
			'& svg': {
				fill: theme.palette.textPrimary.white,
			},
			boxShadow: '1px 1px 10px 1px rgba(72, 46, 81, .11)',
		},
	},
	chatFloating: {
		boxShadow: 'px 5px 18px 5px rgba(72, 46, 81, .5)',
	},
	icon: {
		fill: theme.palette.textPrimary.white,
		'&:hover': {
			fill: theme.palette.primary.main,
		},
	},
	link: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	topContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		'&>h3': {
			color: theme.palette.primary.main
		}
	},
	closeButton: {
		padding: 0,
		alignSelf: 'flex-start',
	},
	text: {
		color: theme.palette.primary.main,
	},
}));

const ChatIcon = ({ pathname, question, isChatDrawerOpen, openChatDrawer, closeChatDrawer, instructionBox, handleClick, disabled = false, isCollaborationDrawerAvailable = false, xlUp = false }) => {
	const classes = useStyles();
	const intl = useIntl();
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'));
	const dispatch = useDispatch();
	const localCompany = getCompany();
	const chat = useSelector(state => state.chat);

	const lastVisit = chat?.lastVisit;
	const lastVisitLoading = chat?.lastVisitLoading;

	const lastVisitTimestamp = lastVisit?.lastVisit;

	const chatActive = chat?.active ?? 0;
	const messages = chat?.chat?.conversations?.filter(({ id }) => id === chatActive)[0]?.messages;

	const unreadMessages = messages?.filter(({ client_msg_id, ts }) => {
		if (!client_msg_id) return;

		const tsFormatted = moment.unix(ts).format();

		return !lastVisitTimestamp && !lastVisitLoading || lastVisitTimestamp && moment(tsFormatted).isAfter(lastVisitTimestamp)
			? true
			: false;
	});

	const unreadMessageCount = unreadMessages?.length ?? 0;

	const [socketLoaded, setSocketLoaded] = useState(false);

	const isAdvancedCustomerConst = isAdvancedCustomer();

	if (!localCompany || isAdvancedCustomerConst) return null;

	if (localCompany?.CompanyName) {
		if (!socketLoaded) {
			socket(dispatch, localCompany);
			setSocketLoaded(true);
		}
	}

	return (
		<div style={{ display: disabled && 'none' }}>
			<Collapse
				in={instructionBox && smUp}
				className={classes.float}
				sx={{ right: isCollaborationDrawerAvailable && xlUp ? `calc(${collaborationDrawerWidth} + 1rem)` : '1rem' }}
			>
				<Grid container>
					<Grid item xs={3}>
						<Grid container justifyContent='center' alignItems='center' style={{ height: '100%', padding: '0 1rem' }}>
							<Grid item>
								<IconButton className={classes.circle} aria-label='chat' onClick={openChatDrawer}>
									<IconBadged badgeContent={unreadMessageCount}>
										<Icon classes={classes.icon} />
									</IconBadged>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={9}>
						<div className={classes.topContainer}>
							<h3>{intl.formatMessage({ id: 'chat.icon.title' })}</h3>
							<IconButton className={classes.closeButton} onClick={handleClick}>
								<Close />
							</IconButton>
						</div>
						<p className={classes.text}>{intl.formatMessage({ id: 'chat.icon.content' })}</p>
						{/* eslint-disable-next-line */}
						<a className={classes.link} onClick={openChatDrawer}>
							{intl.formatMessage({ id: 'chat.icon.link' })}
						</a>
					</Grid>
				</Grid>
			</Collapse>
			<Fade in={!instructionBox || !smUp}>
				<IconButton
					aria-label='chat'
					onClick={openChatDrawer}
					className={`${classes.float} ${classes.circle} ${classes.chatFloating}`}
					sx={{ right: isCollaborationDrawerAvailable && xlUp ? `calc(${collaborationDrawerWidth} + 1rem)` : '1rem' }}
				>
					<IconBadged badgeContent={unreadMessageCount}>
						<Icon classes={classes.icon} />
					</IconBadged>
				</IconButton>
			</Fade>
			{/**
                Helps the scroll. Does not work without it. Renders the chat too early
            */}
			{isChatDrawerOpen ? <Chat open={isChatDrawerOpen} close={closeChatDrawer} chat={chat} question={question} pathname={pathname} /> : null}
		</div>
	);
};

export default ChatIcon;
