import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import AnalysisChart from 'components/AnalysisChart';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Spinner from 'components/Spinner/CenteredSpinner';
import MainTitle from 'components/Titles/MainTitle';
import Score from 'containers/Score';
import getCompanyLink from 'utils/auth/getCompanyLink';
import AnalysisDropdownMenu from './AnalysisDropdownMenu';
import ContactItems from './ContactItems';
import { ScoreCard } from './ScoreCard';
import SuggestedInstrumentsList from './SuggestedInstrumentsList';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { removeInstrumentMarked, setInstrumentMarked } from 'containers/Instruments/slices';
import { useFetchScenarioQuery } from 'containers/Scenario/scenarioV2Api';
import { isNil } from 'lodash';
import { getCompanyId, isFreemiumToken } from 'utils/auth/company';

const useStyles = makeStyles(theme => ({
	flexContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	scoreWrapper: {
		[theme.breakpoints.up('md')]: {
			minWidth: '380px',
			maxWidth: '450px',
		},
	},
	timelineWrapper: {
		flexGrow: 1,
		minWidth: 0,
		paddingTop: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			paddingTop: 0,
			paddingLeft: theme.spacing(3),
		},
	},
	title: {
		fontSize: '1.8rem',
		fontWeight: 600,
		marginBottom: '1rem',
	},
}));

const Analysis = ({ link, error }) => {
	const intl = useIntl();
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const isFreemium = isFreemiumToken();
	const companyId = getCompanyId();

	// Company
	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: isNil(companyId) });

	// Analysis
	const analysis = useSelector(state => state.analysis);
	const analysisLoading = analysis?.loading;
	const analysisData = analysis?.analysis;

	// Instruments
	const instruments = useSelector(state => state.instruments);
	const instrumentsLoading = instruments?.instruments?.loading;
	const instrumentsData = instruments?.instruments?.data;
	const currentInstrumentId = instruments?.currentInstrumentId;

	const changeMarked = (id, isMarked = false) => (isMarked ? dispatch(removeInstrumentMarked(id)) : dispatch(setInstrumentMarked(id)));

	const scrollToCurrentInstrument = id => {
		const headerOffset = 8;
		const element = document.getElementById(id);
		const elementPosition = element?.getBoundingClientRect().top ?? headerOffset;
		const offsetPosition = elementPosition - headerOffset;

		window.scrollBy({
			top: offsetPosition,
			behavior: 'smooth',
		});
	};

	const { data: scenario, isLoading: isScenarioLoading } = useFetchScenarioQuery(
		{ companyId, businessId: company?.businessId },
		{ skip: isNil(companyId) || isCompanyLoading || isCompanyFetching }
	);

	// Scores
	const score = useSelector(state => state.score);
	const scoreData = score?.score;
	const scoreLoading = score?.loading;
	const missingValues = scenario?.missingValues;

	const loading = (isCompanyLoading || analysisLoading || instrumentsLoading || isScenarioLoading || scoreLoading) ?? false;

	const printRef = useRef();
	const hasFullScenario = missingValues == null || missingValues?.length === 0;

	useEffect(() => {
		if (hasFullScenario) return;

		toast.warning(intl.formatMessage({ id: 'analysis.missingScenarioData' }));

		history.push(getCompanyLink('', location));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [missingValues]);

	if (analysis === undefined || analysis === null) {
		return null;
	}

	if (error && error.response?.status === 500) {
		return (
			<Stack alignContent='center' alignItems='center' sx={{ p: '2rem' }}>
				{intl.formatMessage({ id: 'analysis.error' })}
			</Stack>
		);
	}

	return loading ? (
		<Spinner />
	) : (
		<div ref={printRef}>
			<LeftRightContainer
				left={
					<MainTitle margin={0}>
						{company?.name} - {intl.formatMessage({ id: 'breadcrumb.analysis' })}
					</MainTitle>
				}
				right={<AnalysisDropdownMenu printRef={printRef} company={company} />}
			/>
			<div className={classes.flexContainer}>
				<div className={classes.scoreWrapper}>
					<Score score={scoreData} loading={scoreLoading} missingValues={missingValues} />
				</div>
				<div className={classes.timelineWrapper}>
					<AnalysisChart data={company?.economicalData} loading={isCompanyLoading} height={370.5} />
				</div>
			</div>
			<Typography variant='h2' color='primary' className={classes.title}>
				{intl.formatMessage({ id: 'breadcrumb.analysis' })}
			</Typography>
			{analysisData &&
				analysisData?.analysisSections?.length > 0 &&
				analysisData.analysisSections.map((section, index) => {
					return <ScoreCard key={section + index} type={section.type} data={section} />;
				})}
			{link && <ContactItems contactData={analysis.contactData} isContacting={analysis.isContacting} link={analysis.linkId} />}
			<SuggestedInstrumentsList
				title={intl.formatMessage({ id: 'analysis.instruments.internationalization' })}
				scrollToCurrentInstrument={scrollToCurrentInstrument}
				company={company}
				currentInstrumentId={currentInstrumentId}
				instruments={instrumentsData}
				suggests={analysisData?.instruments?.internationalization}
				link={link}
				changeMarked={changeMarked}
			/>
			<SuggestedInstrumentsList
				title={intl.formatMessage({ id: 'analysis.instruments.domesticinvestment' })}
				scrollToCurrentInstrument={scrollToCurrentInstrument}
				company={company}
				currentInstrumentId={currentInstrumentId}
				instruments={instrumentsData}
				suggests={analysisData?.instruments?.domestic}
				link={link}
				changeMarked={changeMarked}
			/>
			<SuggestedInstrumentsList
				title={intl.formatMessage({ id: 'analysis.instruments.load' })}
				scrollToCurrentInstrument={scrollToCurrentInstrument}
				company={company}
				currentInstrumentId={currentInstrumentId}
				instruments={instrumentsData}
				suggests={analysisData?.instruments?.capital}
				link={link}
				changeMarked={changeMarked}
			/>
		</div>
	);
};

export default Analysis;
