import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { concat, isEmpty, isNil, map, without } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { RfpProposalItemCost } from 'types/dataTypes';
import { replaceArrayFieldValues } from 'utils/misc/formUtils';

import { openDialog } from 'containers/Marketplace/dialogSlice';

import RfpProposalItemCostTotalPrice from 'containers/Marketplace/proposal/RfpProposalItemCostTotalPrice';

type Props = {
	label?: string | null;
	helperText?: string | null;
	value?: Partial<RfpProposalItemCost>[] | null;
	onChange?: (value: Partial<RfpProposalItemCost>[]) => void;
} & Omit<FormControlProps, 'onChange'>;

const RfpProposalItemCostInput = React.forwardRef<any, Props>(({ label, helperText, value, onChange, ...otherProps }, ref) => {
	const dispatch = useDispatch();

	return (
		<FormControl {...otherProps} ref={ref}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			<List>
				{isEmpty(value) && (
					<Typography variant='lightText'>
						<FormattedMessage id='rfpProposalItemCosts.empty' />
					</Typography>
				)}
				{map(value, (rfpProposalItemCost, index) => (
					<ListItem
						key={index}
						secondaryAction={
							<IconButton
								color='error'
								onClick={() => {
									onChange?.(without(value, rfpProposalItemCost));
								}}
							>
								<DeleteIcon />
							</IconButton>
						}
						disablePadding
					>
						<ListItemButton
							onClick={() => {
								dispatch(
									openDialog({
										name: 'UpsertRfpProposalItemCostDialog',
										data: {
											fieldValues: rfpProposalItemCost,
											onSubmit: (fieldValues: any) => {
												onChange?.(replaceArrayFieldValues(value, index, fieldValues));
											},
										},
									})
								);
							}}
						>
							<ListItemText
								primary={<FormattedMessage id={`rfpCostClass.${rfpProposalItemCost.costClass}`} />}
								secondary={
									<>
										<Typography component='span' variant='subtitle2' sx={{ mr: 1 }}>
											<FormattedMessage id={`rfpCostType.${rfpProposalItemCost.costType}`} />
										</Typography>
										<RfpProposalItemCostTotalPrice
											component='span'
											totalPrice={rfpProposalItemCost.totalPrice}
											totalPriceMax={rfpProposalItemCost.totalPriceMax}
										/>
									</>
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Box>
				<Button
					size='small'
					variant='text'
					onClick={() => {
						dispatch(
							openDialog({
								name: 'UpsertRfpProposalItemCostDialog',
								data: {
									fieldValues: {},
									onSubmit: (fieldValues: any) => {
										onChange?.(concat([], value, fieldValues));
									},
								},
							})
						);
					}}
					startIcon={<AddIcon />}
				>
					<FormattedMessage id='rfpProposalItemCosts.add' />
				</Button>
			</Box>
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
});

export default RfpProposalItemCostInput;
