import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import FormattedDate from 'components/Formatters/FormattedDate';
import Subtitle from 'components/Titles/Subtitle';

const CompanyAddressPanel = ({ loading, address, classes }) => {
    const intl = useIntl();

    return (
        <>
            <Stack direction='row' spacing={2} alignItems='flex-end'>
                <Subtitle mt={0} padding={0}>{intl.formatMessage({ id: 'companydetails.address.title' })}</Subtitle>
                {loading && <Box sx={{ mb: 2 }}><CircularProgress size={16} sx={{ mb: 0.5 }} /></Box>}
            </Stack>
            {!loading && !address || !loading && address && Object.keys(address).length === 0
                ? <Typography component='p'>{intl.formatMessage({ id: 'companydetails.no.address' })}</Typography>
                : <>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companydetails.address.streetAddress' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {address?.street}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companydetails.address.postCode' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {address?.postCode}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companydetails.address.city' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {address?.city}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companydetails.address.country' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {address?.country}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companydetails.address.registrationDate' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={address?.registrationDate} />
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companydetails.address.endDate' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={address?.endDate} />
                            </Typography>
                        }
                    </Stack>
                </>
            }
        </>
    );
};

export default CompanyAddressPanel;