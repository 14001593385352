import { Close as CloseIcon, Psychology as PsychologyIcon } from '@mui/icons-material';
import { IconButton, Popper } from '@mui/material';
import { throttle } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { aiApi } from 'containers/AI/aiApi';
import { storageKeys } from 'utils/constants/constants';
import { useInjectReducer } from 'utils/injectors/injectReducer';
import storage from 'utils/localStorage/localStorageFunctions';
import useWindowDimensions from 'utils/misc/window';

import AiChat from 'containers/AI/AiChatWrapper/AiChat';
import ResizableBox, { Direction } from 'containers/AI/AiChatWrapper/ResizableBox';

const updateStorage = throttle((width: number, height: number, x: number, y: number) => {
	storage.set(storageKeys.AI_CHAT_KEY, { width, height, x, y });
}, 500);

const minWidth = 400;
const minHeight = 600;

const AiChatWrapper = ({ disabled, chatId, title, sx }: any) => {
	const ai = useSelector((state: any) => state.ai);

	useInjectReducer({ key: aiApi.reducerPath, reducer: aiApi.reducer }); // async process, wait until loaded the state

	const { width: windowWidth, height: windowHeight } = useWindowDimensions();

	const popperRef = React.useRef<HTMLDivElement>(null);

	const [popperBoundingBox, setPopperBoundingBox] = React.useState({ width: 600, height: 600, x: 0, y: 0 });
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const isPopperOpen = Boolean(anchorEl);

	const messages = React.useMemo<{ role: string; content: any }[]>(() => {
		return [];
	}, []);

	const handleCloseClick = React.useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleOpenClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
		event => {
			let nextPopperBoundingBox = storage.get(storageKeys.AI_CHAT_KEY);
			if (nextPopperBoundingBox) {
				if (nextPopperBoundingBox.x > windowWidth - nextPopperBoundingBox.width) {
					nextPopperBoundingBox.x = windowWidth - nextPopperBoundingBox.width;
				}
				if (nextPopperBoundingBox.y > windowHeight - nextPopperBoundingBox.height) {
					nextPopperBoundingBox.y = windowHeight - nextPopperBoundingBox.height;
				}
				setPopperBoundingBox(nextPopperBoundingBox);
			}
			setAnchorEl(event.currentTarget);
		},
		[windowWidth, windowHeight]
	);

	const handleDrag = React.useCallback(
		(movementX: number, movementY: number) => {
			const popper = popperRef.current;
			if (!popper) return;

			const { width, height, x, y } = popper.getBoundingClientRect();

			let nextX = x + movementX;
			nextX = nextX + width > windowWidth ? windowWidth - width : nextX;
			nextX = nextX < 0 ? 0 : nextX;

			let nextY = y + movementY;
			nextY = nextY + height > windowHeight ? windowHeight - height : nextY;
			nextY = nextY < 0 ? 0 : nextY;

			popper.style.transform = `translate(${nextX}px, ${nextY}px)`;

			updateStorage(width, height, nextX, nextY);
		},
		[windowWidth, windowHeight]
	);

	const handleResize = React.useCallback(
		(direction: Direction, movementX: number, movementY: number) => {
			const popper = popperRef.current;
			if (!popper) return;

			const { width, height, x, y } = popper.getBoundingClientRect();

			const resizeTop = () => {
				let nextHeight = height - movementY;
				nextHeight = nextHeight + y > windowHeight ? windowHeight - y : nextHeight;
				nextHeight = nextHeight < minHeight ? minHeight : nextHeight;

				let nextY = y + movementY;
				nextY = nextY + height > windowHeight ? windowHeight - height : nextY;
				nextY = nextY < 0 ? 0 : nextY;

				popper.style.height = `${nextHeight}px`;
				popper.style.transform = `translate(${x}px, ${nextY}px)`;

				updateStorage(width, nextHeight, x, nextY);
			};

			const resizeRight = () => {
				let nextWidth = width + movementX;
				nextWidth = nextWidth + x > windowWidth ? windowWidth - x : nextWidth;
				nextWidth = nextWidth < minWidth ? minWidth : nextWidth;

				popper.style.width = `${nextWidth}px`;

				updateStorage(nextWidth, height, x, y);
			};

			const resizeBottom = () => {
				let nextHeight = height + movementY;
				nextHeight = nextHeight + y > windowHeight ? windowHeight - y : nextHeight;
				nextHeight = nextHeight < minHeight ? minHeight : nextHeight;

				popper.style.height = `${nextHeight}px`;

				updateStorage(width, nextHeight, x, y);
			};

			const resizeLeft = () => {
				let nextWidth = width - movementX;
				nextWidth = nextWidth + x > windowWidth ? windowWidth - x : nextWidth;
				nextWidth = nextWidth < minWidth ? minWidth : nextWidth;

				let nextX = x + movementX;
				nextX = nextX + width > windowWidth ? windowWidth - width : nextX;
				nextX = nextX < 0 ? 0 : nextX;

				popper.style.width = `${nextWidth}px`;
				popper.style.transform = `translate(${nextX}px, ${y}px)`;

				updateStorage(nextWidth, height, nextX, y);
			};

			switch (direction) {
				case 'topLeft': {
					resizeTop();
					resizeLeft();
					break;
				}
				case 'top': {
					resizeTop();
					break;
				}
				case 'topRight': {
					resizeTop();
					resizeRight();
					break;
				}
				case 'right': {
					resizeRight();
					break;
				}
				case 'bottomRight': {
					resizeBottom();
					resizeRight();
					break;
				}
				case 'bottom': {
					resizeBottom();
					break;
				}
				case 'bottomLeft': {
					resizeBottom();
					resizeLeft();
					break;
				}
				case 'left': {
					resizeLeft();
					break;
				}
				default: {
					break;
				}
			}
		},
		[windowWidth, windowHeight]
	);

	if (!ai) return null;

	return (
		<>
			<IconButton
				onClick={isPopperOpen ? handleCloseClick : handleOpenClick}
				color={isPopperOpen ? 'secondary' : 'primary'}
				sx={{ ...sx }}
			>
				{isPopperOpen ? <CloseIcon /> : <PsychologyIcon />}
			</IconButton>
			<Popper
				ref={popperRef}
				open={isPopperOpen}
				anchorEl={anchorEl}
				sx={{
					zIndex: 10000,
					boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
					width: windowWidth < popperBoundingBox.width ? `${windowWidth}px` : `${popperBoundingBox.width}px`,
					height: windowHeight < popperBoundingBox.height ? `${windowHeight}px` : `${popperBoundingBox.height}px`,
					transform: `translate(${popperBoundingBox.x < 0 ? 0 : popperBoundingBox.x}px, ${
						popperBoundingBox.y < 0 ? 0 : popperBoundingBox.y
					}px)`,
				}}
				transition
			>
				<>
					<AiChat
						chatId={chatId}
						title={title}
						messages={messages}
						onCloseClick={handleCloseClick}
						onDrag={handleDrag}
						disabled={disabled}
					/>
					<ResizableBox onResize={handleResize} />
				</>
			</Popper>
		</>
	);
};

export default AiChatWrapper;
