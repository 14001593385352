import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';

import { FormControlledInput } from 'components/Form';
import CustomTooltip from '../../CustomTooltip';
import Button from 'components/Button/Button';

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0,
		margin: 0,
	},
	inputWrapperOld: {
		marginBottom: '2rem',
	},
	inputWrapperNew: {
		marginBottom: '1rem',
	},
	button: {
		//textTransform: 'none',
		fontSize: '0,875rem',
		//fontWeight: 500,
		backgroundColor: theme.palette.success.main,
		color: theme.palette.primary.white,
		'&:hover': {
			opacity: '0.8',
			backgroundColor: theme.palette.success.main,
		},
		'&:disabled': {
			opacity: '0.6',
			color: theme.palette.primary.white,
			backgroundColor: theme.palette.success.main,
		},
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: '-10px',
		marginLeft: '-10px',
		color: theme.palette.primary.white,
	},
}));

const ChangeTempPasswordForm = ({ loading, onSubmit }) => {
	const intl = useIntl();
	const classes = useStyles();

	const projectSchema = yup.object().shape({
		password: yup.string().required(
			`${intl.formatMessage({ id: 'login.placeholder.password' })} ${intl.formatMessage({
				id: 'message.isRequired',
			})}`
		),
		newpassword: yup.string().required(
			`${intl.formatMessage({ id: 'login.placeholder.newpassword' })} ${intl.formatMessage({
				id: 'message.isRequired',
			})}`
		),
		confirmpassword: yup
			.string()
			.required(
				`${intl.formatMessage({ id: 'login.placeholder.confirmpassword' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			)
			.oneOf([yup.ref('newpassword'), null], intl.formatMessage({ id: 'message.passwordsNotMatch' })),
	});

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors, isDirty },
	} = useForm({
		resolver: yupResolver(projectSchema),
	});

	const password = useRef({});
	password.current = watch('newpassword');

	return (
		<Container maxWidth='xs' className={classes.root}>
			<Box
				component='form'
				noValidate
				onSubmit={handleSubmit(data => onSubmit(data))}
				sx={{
					'& .MuiInputBase-root.MuiOutlinedInput-root': {
						backgroundColor: theme => theme.palette.primary.contrastText,
					},
					'& .FormError-root, & .MuiFormControl-root': {
						color: 'primary.white',
					},
				}}
			>
				<div className={classes.inputWrapperOld}>
					<FormControlledInput
						control={control}
						defaultValue=''
						name='password'
						type='password'
						icon={<LockOutlinedIcon color='primary' fontSize='small' />}
						error={errors.password?.message}
						placeholder={intl.formatMessage({ id: 'login.placeholder.password' })}
						disabled={loading}
						InputProps={{
							sx: { p: '0.4286rem 1.2143rem 0.4286rem 1.2143rem' },
						}}
					/>
				</div>
				<div className={classes.inputWrapperNew}>
					<CustomTooltip
						title={`${intl.formatMessage({ id: 'password.validation.strength' })} ${intl.formatMessage({
							id: 'password.validation.strength.unique',
						})}`}
						placement='top'
						disableHoverListener
						enterTouchDelay={0}
						leaveTouchDelay={2000}
						TransitionComponent={Fade}
						TransitionProps={{ timeout: 500 }}
					>
						<span>
							<FormControlledInput
								control={control}
								defaultValue=''
								name='newpassword'
								type='password'
								icon={<LockOpenOutlinedIcon color='primary' fontSize='small' />}
								error={errors.newpassword?.message}
								placeholder={intl.formatMessage({ id: 'login.placeholder.newpassword' })}
								disabled={loading}
								InputProps={{
									sx: { p: '0.4286rem 1.2143rem 0.4286rem 1.2143rem' },
								}}
							/>
						</span>
					</CustomTooltip>
				</div>
				<div className={classes.inputWrapperNew}>
					<FormControlledInput
						control={control}
						defaultValue=''
						name='confirmpassword'
						type='password'
						icon={<LockOpenOutlinedIcon color='primary' fontSize='small' />}
						error={errors.confirmpassword?.message}
						placeholder={intl.formatMessage({ id: 'login.placeholder.confirmpassword' })}
						disabled={loading}
						InputProps={{
							sx: { p: '0.4286rem 1.2143rem 0.4286rem 1.2143rem' },
						}}
					/>
				</div>
				<Box textAlign='right'>
					<Button
						type='submit'
						color='success'
						sx={{ p: '0.85714rem 1.21429rem', lineHeight: '1.28571rem', fontSize: '1.14286rem' }}
						loading={loading}
						fullWidth
					>
						{intl.formatMessage({ id: 'common.submit' })}
					</Button>
				</Box>
			</Box>
		</Container>
	);
};

export default ChangeTempPasswordForm;
