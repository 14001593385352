import axios from 'utils/axios';
import { buildPlainUri, validateHttpStatus } from 'utils/helpers/uriHelper';

const baseUrl = 'instruments';

class InstrumentsAdminApi {

	async getInstruments(isDraft) {
		const url = buildPlainUri(baseUrl, `?isDraft=${isDraft}`);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveInstrument(instrument, isDraft) {
		// `api/instruments?isDraft=${isDraft}`;

		const url = buildPlainUri(baseUrl, `?isDraft=${isDraft}`);
		const response = await axios.post(url, instrument, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async publishSelectedInstruments(silent, selected) {
		// `api/instruments/publish?silentPublish=${silent}`;

		const url = buildPlainUri(baseUrl, `publish?silentPublish=${silent}`);
		const response = await axios.post(url, selected, { validateStatus: validateHttpStatus });

		return response.data;
	}

}

export const instrumentsAdminApi = new InstrumentsAdminApi();
