import { yupResolver } from '@hookform/resolvers/yup';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { RfpProposalItemCost } from 'types/dataTypes';
import { calculateRfpProposalItemCost } from 'utils/helpers/marketplaceUtils';
import { mergeFieldValues } from 'utils/misc/formUtils';

import { closeDialog, getDialogData, isDialogOpen, openDialog } from 'containers/Marketplace/dialogSlice';

import CurrencyInput from 'containers/Marketplace/common/CurrencyInput';
import HourInput from 'containers/Marketplace/common/HourInput';
import NumberController from 'containers/Marketplace/common/NumberController';
import RfpCostClassSelect from 'containers/Marketplace/common/RfpCostClassSelect';
import RfpCostTypeSelect from 'containers/Marketplace/common/RfpCostTypeSelect';
import CalculateRfpProposalItemCostTotalPrice from 'containers/Marketplace/proposal/CalculateRfpProposalItemCostTotalPrice';
import ToggleRfpProposalItemCostCostType from 'containers/Marketplace/proposal/ToggleRfpProposalItemCostCostType';

type Props = {} & Omit<DialogProps, 'open'>;

const UpsertRfpProposalItemCostDialog: React.FC<Props> = ({ sx, ...otherProps }) => {
	const dispatch = useDispatch();

	const { formatMessage } = useIntl();

	const dialogOpen = useSelector(isDialogOpen('UpsertRfpProposalItemCostDialog'));
	const dialogData = useSelector(getDialogData('UpsertRfpProposalItemCostDialog'));

	const validationSchema = React.useMemo(
		() =>
			yup.object({
				costType: yup.string().required(),
				costClass: yup.string().required(),
				price: yup.number().nullable().positive().required(),
				priceMax: yup
					.number()
					.nullable()
					.when('costType', (costType, schema) => {
						if (costType === 'FixedScale') {
							return schema.min(yup.ref<number>('price')).positive().required();
						}
						return schema;
					}),
				hourEstimate: yup
					.number()
					.nullable()
					.when('costType', (costType, schema) => {
						if (costType === 'Hourly' || costType === 'HourlyEstimate') {
							return schema.positive().required();
						}
						return schema;
					}),
				hourEstimateMax: yup
					.number()
					.nullable()
					.when('costType', (costType, schema) => {
						if (costType === 'HourlyEstimate') {
							return schema.min(yup.ref<number>('hourEstimate')).positive().required();
						}
						return schema;
					}),
			}),
		[]
	);

	const defaultValues = React.useMemo(
		() => ({
			costType: 'Fixed' as const,
			costClass: 'ProjectBased' as const,
			price: null,
			priceMax: null,
			hourEstimate: null,
			hourEstimateMax: null,
			totalPrice: null,
			totalPriceMax: null,
		}),
		[]
	);

	const {
		control,
		reset,
		handleSubmit,
		setValue,
		formState: { isDirty, isSubmitting },
	} = useForm<RfpProposalItemCost>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const handleDialogClose = React.useCallback(() => {
		if (isDirty) {
			dispatch(
				openDialog({
					name: 'ConfirmCloseDialog',
					data: {
						onSubmit: () => {
							reset(defaultValues);
							dispatch(closeDialog({ name: 'UpsertRfpProposalItemCostDialog' }));
						},
					},
				})
			);
		} else {
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProposalItemCostDialog' }));
		}
	}, [dispatch, reset, isDirty, defaultValues]);

	const handleFormSubmit = React.useCallback<SubmitHandler<RfpProposalItemCost>>(
		async fieldValues => {
			const { totalPrice, totalPriceMax } = calculateRfpProposalItemCost(fieldValues);
			await dialogData?.onSubmit({ ...fieldValues, totalPrice, totalPriceMax });
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProposalItemCostDialog' }));
		},
		[dispatch, reset, defaultValues, dialogData]
	);

	React.useEffect(() => {
		if (!isNil(dialogData?.fieldValues)) {
			reset(mergeFieldValues(defaultValues, dialogData?.fieldValues), { keepDirtyValues: true });
		}
	}, [reset, defaultValues, dialogData]);

	return (
		<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='xs' fullWidth>
			<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
				<CloseIcon />
			</IconButton>
			<Box component='form' onSubmit={handleSubmit(handleFormSubmit)}>
				<DialogTitle>
					<FormattedMessage id='rfpProposalItemCost.title' />
				</DialogTitle>
				<DialogContent>
					<Stack>
						<Controller
							name='costClass'
							control={control}
							render={({ field, fieldState }) => (
								<RfpCostClassSelect
									{...field}
									error={!isNil(fieldState.error)}
									label={formatMessage({ id: 'rfpProposalItemCost.costClass.label' })}
									placeholder={formatMessage({ id: 'rfpProposalItemCost.costClass.placeholder' })}
									helperText={fieldState.error?.message}
								/>
							)}
						/>
						<Controller
							name='costType'
							control={control}
							render={({ field, fieldState }) => (
								<RfpCostTypeSelect
									{...field}
									onChange={event => {
										field.onChange(event);
										setValue('price', null);
										setValue('priceMax', null);
										setValue('hourEstimate', null);
										setValue('hourEstimateMax', null);
									}}
									error={!isNil(fieldState.error)}
									label={formatMessage({ id: 'rfpProposalItemCost.costType.label' })}
									placeholder={formatMessage({ id: 'rfpProposalItemCost.costType.placeholder' })}
									helperText={fieldState.error?.message}
								/>
							)}
						/>
						<ToggleRfpProposalItemCostCostType control={control} costType='Fixed'>
							<NumberController
								name='price'
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										{...field}
										error={!isNil(fieldState.error)}
										label={formatMessage({ id: 'rfpProposalItemCost.fixedPrice.label' })}
										placeholder={formatMessage({ id: 'rfpProposalItemCost.fixedPrice.placeholder' })}
										helperText={fieldState.error?.message}
										InputProps={{
											inputComponent: CurrencyInput,
											endAdornment: <InputAdornment position='end'>€</InputAdornment>,
										}}
									/>
								)}
							/>
						</ToggleRfpProposalItemCostCostType>
						<ToggleRfpProposalItemCostCostType control={control} costType='FixedScale'>
							<Stack>
								<NumberController
									name='price'
									control={control}
									render={({ field, fieldState }) => (
										<TextField
											{...field}
											error={!isNil(fieldState.error)}
											label={formatMessage({ id: 'rfpProposalItemCost.fixedPriceMin.label' })}
											placeholder={formatMessage({
												id: 'rfpProposalItemCost.fixedPriceMin.placeholder',
											})}
											helperText={fieldState.error?.message}
											InputProps={{
												inputComponent: CurrencyInput,
												endAdornment: <InputAdornment position='end'>€</InputAdornment>,
											}}
										/>
									)}
								/>
								<NumberController
									name='priceMax'
									control={control}
									render={({ field, fieldState }) => (
										<TextField
											{...field}
											error={!isNil(fieldState.error)}
											label={formatMessage({ id: 'rfpProposalItemCost.fixedPriceMax.label' })}
											placeholder={formatMessage({
												id: 'rfpProposalItemCost.fixedPriceMax.placeholder',
											})}
											helperText={fieldState.error?.message}
											InputProps={{
												inputComponent: CurrencyInput,
												endAdornment: <InputAdornment position='end'>€</InputAdornment>,
											}}
										/>
									)}
								/>
							</Stack>
						</ToggleRfpProposalItemCostCostType>
						<ToggleRfpProposalItemCostCostType control={control} costType='Hourly'>
							<Stack>
								<NumberController
									name='price'
									control={control}
									render={({ field, fieldState }) => (
										<TextField
											{...field}
											error={!isNil(fieldState.error)}
											label={formatMessage({ id: 'rfpProposalItemCost.hourPrice.label' })}
											placeholder={formatMessage({ id: 'rfpProposalItemCost.hourPrice.placeholder' })}
											helperText={fieldState.error?.message}
											InputProps={{
												inputComponent: CurrencyInput,
												endAdornment: <InputAdornment position='end'>€</InputAdornment>,
											}}
										/>
									)}
								/>
								<NumberController
									name='hourEstimate'
									control={control}
									render={({ field, fieldState }) => (
										<TextField
											{...field}
											error={!isNil(fieldState.error)}
											label={formatMessage({ id: 'rfpProposalItemCost.hourEstimate.label' })}
											placeholder={formatMessage({
												id: 'rfpProposalItemCost.hourEstimate.placeholder',
											})}
											helperText={fieldState.error?.message}
											InputProps={{
												inputComponent: HourInput,
												endAdornment: <InputAdornment position='end'>h</InputAdornment>,
											}}
										/>
									)}
								/>
							</Stack>
						</ToggleRfpProposalItemCostCostType>
						<ToggleRfpProposalItemCostCostType control={control} costType='HourlyEstimate'>
							<Stack>
								<NumberController
									name='price'
									control={control}
									render={({ field, fieldState }) => (
										<TextField
											{...field}
											error={!isNil(fieldState.error)}
											label={formatMessage({ id: 'rfpProposalItemCost.hourPrice.label' })}
											placeholder={formatMessage({ id: 'rfpProposalItemCost.hourPrice.placeholder' })}
											helperText={fieldState.error?.message}
											InputProps={{
												inputComponent: CurrencyInput,
												endAdornment: <InputAdornment position='end'>€</InputAdornment>,
											}}
										/>
									)}
								/>
								<NumberController
									name='hourEstimate'
									control={control}
									render={({ field, fieldState }) => (
										<TextField
											{...field}
											error={!isNil(fieldState.error)}
											label={formatMessage({ id: 'rfpProposalItemCost.hourEstimateMin.label' })}
											placeholder={formatMessage({
												id: 'rfpProposalItemCost.hourEstimateMin.placeholder',
											})}
											helperText={fieldState.error?.message}
											InputProps={{
												inputComponent: HourInput,
												endAdornment: <InputAdornment position='end'>h</InputAdornment>,
											}}
										/>
									)}
								/>
								<NumberController
									name='hourEstimateMax'
									control={control}
									render={({ field, fieldState }) => (
										<TextField
											{...field}
											error={!isNil(fieldState.error)}
											label={formatMessage({ id: 'rfpProposalItemCost.hourEstimateMax.label' })}
											placeholder={formatMessage({
												id: 'rfpProposalItemCost.hourEstimateMax.placeholder',
											})}
											helperText={fieldState.error?.message}
											InputProps={{
												inputComponent: HourInput,
												endAdornment: <InputAdornment position='end'>h</InputAdornment>,
											}}
										/>
									)}
								/>
							</Stack>
						</ToggleRfpProposalItemCostCostType>
						<Stack flexDirection='row'>
							<Typography color='text.muted'>
								<FormattedMessage id='rfpProposalItemCost.totalPrice.label' />
							</Typography>
							<CalculateRfpProposalItemCostTotalPrice control={control} />
						</Stack>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button size='small' variant='text' color='neutral' onClick={handleDialogClose}>
						<FormattedMessage id='rfpProposalItemCost.cancel' />
					</Button>
					<LoadingButton type='submit' loading={isSubmitting}>
						<FormattedMessage id='rfpProposalItemCost.submit' />
					</LoadingButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default UpsertRfpProposalItemCostDialog;
