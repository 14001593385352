import React from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const CompaniesList = ({ selectedIds, removeCompany }) => {
    const customers = useSelector(state => state.customers);

    return (
        <List>
            {selectedIds.map((item, index) => {
                const companyName = customers.customers.data?.find(({ customerCompanyId }) => customerCompanyId === item)?.advancedCustomerName;

                return (
                    <ListItem key={item + index} disablePadding>
                        <Box display='flex' sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Stack
                                direction='row'
                                spacing={1}
                                sx={{
                                    '& p': {
                                        color: 'secondary.grey700'
                                    }
                                }}
                            >
                                <Typography>-</Typography>
                                <Typography>{companyName} ({item})</Typography>
                            </Stack>
                            <IconButton onClick={() => removeCompany(index)}>
                                <DeleteIcon color='error' />
                            </IconButton>
                        </Box>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default CompaniesList;