import { Box } from '@mui/material';
import * as React from 'react';

import { useGetProfileDataLinkQuery } from 'containers/Stakeholder/stakeholderApi';

import ProjectSummary from 'containers/Projects/Project/ProjectSummary/ProjectSummary';
import { ProjectSkeleton } from 'containers/Stakeholder/Views/Projects/Project/Project';

interface Props {
	linkId: string;
}

const SingleProject: React.FC<Props> = ({ linkId }) => {
	const { data: linkData, isFetching: isLinkFetching, isLoading: isLinkLoading } = useGetProfileDataLinkQuery(linkId);

	const isLoading = isLinkFetching || isLinkLoading;

	if (isLoading) {
		return <ProjectSkeleton />;
	}

	return (
		<Box sx={{ bgcolor: (theme: any) => theme.palette.primary.secondary, width: '100%' }}>
			<ProjectSummary project={linkData?.project} template={linkData?.project?.template} readonly={true} sx={{ p: 2 }} />
		</Box>
	);
};

export default SingleProject;
