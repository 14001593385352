import React from 'react';
import { Stack, Box } from '@mui/material';

const NotFoundContainer = ({ children }) => {

    return (
        <Stack
            sx={{
                width: '100%',
                mt: '2rem',
            }}
            direction='column'
            spacing={4}
            alignItems='center'
            justifyContent='center'
        >
            <Box sx={{
                width: '100%',
                textAlign: 'center',
                p: '2rem'
            }}
            >{children}</Box>
        </Stack>
    );
};

export default NotFoundContainer;