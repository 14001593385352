import React from 'react';
import FormControl from '@mui/material/FormControl';

import FormError from './FormError';

/**
 * Controlled Textfield, dependencies hoook-form, mui
 * 
 * @param {*} error
 * @param {*} children
 * @returns 
 */
export default function ({ error, children }) {
    return (
        <FormControl fullWidth>
            {children}
            <FormError error={error} />
        </FormControl>
    );
}

