import React from 'react';
import { styled } from '@mui/styles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const StyledButton = styled(Button)(({ theme }) => (
    {
        textTransform: 'uppercase',
        fontWeight: 500,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.white,
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.primary.main,
        },
    }
));

export const AddButton = ({ children, ...other }) => {
    return (
        <StyledButton variant="outlined" startIcon={<AddIcon />} {...other}>{children}</StyledButton>
    );
};