import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

const TimeAgo = ({ time, ...props }) => {
	const intl = useIntl();

	if (!time) return null;

	moment.fn.fromNowOrNow = function (a) {
		if (Math.abs(moment().diff(this)) < 1000) { // 1 sec
			return intl.formatMessage({ id: 'time.just.now' });
		}

		return this.fromNow(a);
	};

	const timeAgo = moment(time).fromNowOrNow();

	return <Typography {...props}>{timeAgo}</Typography>;
};

export default TimeAgo;
