export const initialLimitators = [
    {
        compareProperty: 'internationalEta',
        requiredValue: 0,
    },
    {
        compareProperty: 'ipr',
        requiredValue: 0,
    },
    {
        compareProperty: 'team',
        requiredValue: 0,
    },
    {
        compareProperty: 'concept',
        requiredValue: 0,
    },
    {
        compareProperty: 'deminisHistory',
        requiredValue: 0,
    },
    {
        compareProperty: 'personnelSize',
        requiredValue: 0,
    },
    {
        compareProperty: 'turnover',
        requiredValue: 0,
    },
    {
        compareProperty: 'ratingAlpha',
        requiredValue: 0,
    },
    {
        compareProperty: 'taxDepth',
        requiredValue: 0,
    },
    {
        compareProperty: 'age',
        requiredValue: 0,
    },
    {
        compareProperty: 'equityAmount',
        requiredValue: 0,
    },
    {
        compareProperty: 'equityPercentageLevel',
        requiredValue: 0,
    },
    {
        compareProperty: 'balance',
        requiredValue: 0,
    },
    {
        compareProperty: 'countrySide',
        requiredValue: 0,
    },
    {
        compareProperty: 'paysDivident',
        requiredValue: 0,
    },
    {
        compareProperty: 'owner',
        requiredValue: 0,
    },
    {
        compareProperty: 'companyType',
        requiredValue: 0,
    },
    {
        compareProperty: 'employeeRegister',
        requiredValue: 0,
    },
    {
        compareProperty: 'advanceCollectionRegister',
        requiredValue: 0,
    },
    {
        compareProperty: 'vatRegister',
        requiredValue: 0,
    },
    {
        compareProperty: 'cashFlow',
        requiredValue: 0,
    },
    {
        compareProperty: 'responsiblesCreditRate',
        requiredValue: 0,
    },
];
