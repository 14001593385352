import { Box } from '@mui/material';
import { map, uniqBy } from 'lodash';
import { useGetMrrStatisticsQuery } from '../mrrApi';
import { StatisticItemInfoBoxList, flatData } from './StatisticItemInfoBoxList';

export type StatisticItem = {
	lclass: string;
	active: number;
	total: number;
	averageMrr: number;
	maxMrr: number;
	minMrr: number;
	activeMrr: number;
	inactiveMrr: number;
	sub: StatisticItem[] | null;
};

const MRRStatistics = ({ mrrData }: any) => {
	const { data, isFetching, isLoading, isSuccess } = useGetMrrStatisticsQuery('mrr');

	if (!(isFetching || isLoading) && !isSuccess) return <div>Error!</div>;

	if (data && mrrData?.mrrByCompany) {
		const flatten = flatData(data?.licenses?.byClass);
		const mrrlicenses = flatten?.map(item => item.licenses);
		const flattenLicenses = uniqBy(
			mrrlicenses?.flat()?.filter(item => item.isActive && item.type === 'advanced' && item.contractMonthlyValue > 0),
			(item: any) => item.rowKey
		);

		const activeItems = map(mrrData?.mrrByCompany, (item: any) => {
			return item.licenses.map((licensearr: any) => licensearr.license);
		})
			.flat()
			.filter(license => license.isActive && license.type === 'advanced');

		const ret = activeItems.filter(activeItem => {
			const { rowKey, isActive } = activeItem;

			if (!isActive) return false;

			const foundItem = flattenLicenses?.find(item => item.rowKey === rowKey);
			if (foundItem) return false;

			return true;
		});
	}

	return (
		<Box sx={{ mb: '1rem' }}>
			<StatisticItemInfoBoxList statisticItems={data?.licenses?.byClass} />
		</Box>
	);
};

export default MRRStatistics;
