import { useIntl } from 'react-intl';

import ArchiveIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';

import ProjectSaveButtonContainer from './ProjectSaveButtonContainer/ProjectSaveButtonContainer';

const ProjectModalActions = ({
	project,
	isLoading,
	isDeleting,
	isDirty,
	confirmOpen,
	anchorEl,
	setAnchorEl,
	handleSuccess,
	handleSubmit,
	onSubmit,
	setConfirmOpen,
	archive,
}) => {
	const intl = useIntl();

	const confirm = e => {
		setConfirmOpen(true);
		setAnchorEl(e.currentTarget);
	};

	const closeConfirm = e => {
		setConfirmOpen(false);
		setAnchorEl(null);
	};

	return (
		<Box display='flex' sx={{ justifyContent: project?.projectId ? 'space-between' : 'flex-end', alignItems: 'center', width: '100%' }}>
			{project?.projectId && (
				<>
					<Button color='error' disabled={isLoading || isDeleting} onClick={e => confirm(e)} startIcon={<ArchiveIcon />}>
						{intl.formatMessage({ id: 'project.dashboard.menu.archive' })}
						{isDeleting && <ButtonSpinner />}
					</Button>
					<ConfirmPopper
						confirmAction={archive}
						handleClose={closeConfirm}
						isopen={confirmOpen}
						anchorElement={anchorEl}
						confirmText={intl.formatMessage({ id: 'project.dashboard.menu.archive.confirmText' })}
						cancelText={intl.formatMessage({ id: 'project.dashboard.menu.archive.cancelText' })}
						confirmBodyText={intl.formatMessage({ id: 'project.dashboard.menu.archive.confirmBodyText' })}
					/>
				</>
			)}
			<Stack direction='row' spacing={0.5}>
				<ProjectSaveButtonContainer {...{ onSubmit, handleSubmit, isDirty, loading: isLoading, state: project?.projectState }} />
			</Stack>
		</Box>
	);
};

export default ProjectModalActions;
