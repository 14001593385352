import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Stack, Typography } from '@mui/material';

import { useCreateInstrumentAnalyticsMutation } from 'containers/Analytics/analyticsApi';

import { useTheme } from '@emotion/react';
import TextContainer from 'components/Containers/TextContainer';
import htmlParser from 'components/HtmlParser';
import Subtitle from 'components/Titles/Subtitle';
import { getUsernameFromToken } from 'utils/auth/token';

const InstrumentProviderLink = ({ instrument, profile }) => {
	const providerLink = instrument?.providerLink;
	const theme = useTheme();
	const [createInstrumentAnalytics] = useCreateInstrumentAnalyticsMutation();

	if (!providerLink) {
		return null;
	}

	return (
		<Box
			sx={{
				mt: 2,
				p: '1rem',
				pt: '0',
				backgroundColor: theme.palette.primary.secondary,
				mb: profile ? 4 : 'unset',
			}}
		>
			{providerLink?.map((link, linkIndex) => (
				<Box key={`${linkIndex}`} sx={{ mt: 2 }}>
					{link?.title && (
						<Subtitle mt={0} mb={'1.5rem'} padding={0}>
							{link?.title}
						</Subtitle>
					)}
					{link?.description && (
						<Typography component='span' sx={{ fontSize: '1rem' }}>
							<TextContainer sx={{ p: 0, maxWidth: '70rem' }}>
								{htmlParser(link?.description?.replace(/(<p>&nbsp;<\/p>)+/g, ''))}
							</TextContainer>
						</Typography>
					)}
					<Stack spacing={1} flexDirection='row' flexWrap='wrap' useFlexGap>
						{link?.buttons?.map((button, buttonIndex) => (
							<Button
								key={`${linkIndex}${buttonIndex}`}
								variant='contained'
								sx={{ background: '#1976d2' }}
								component='a'
								startIcon={<ArrowForwardIcon />}
								href={button?.url ?? '#'}
								target='_blank'
								onClick={() => {
									createInstrumentAnalytics({
										action: 'LinkClicked',
										instrumentId: instrument?.id,
										companyId: link?.customerCompanyId,
										url: button?.url,
										customData: `user: ${getUsernameFromToken()}, windowUrl: ${window?.location?.href}`,
									});
								}}
							>
								{button?.label ?? 'n/a'}
							</Button>
						))}
					</Stack>
				</Box>
			))}
		</Box>
	);
};

export default InstrumentProviderLink;
