import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { keyframes } from '@mui/system';
// import AnnouncementIcon from '@mui/icons-material/Announcement';

import Spinner from 'components/Spinner/Spinner';
import AddComment from './CommentsDialog/AddComment';
import CommentsList from './CommentsList/CommentsList';
import CustomAvatar from './CustomAvatar';
import TimeAgo from './TimeAgo';

import ConfirmIconButton from 'components/Button/ConfirmIconButton';
import { getLinkSplitted } from 'containers/Collaboration/utils';
import { filter, find } from 'lodash';
import { getCompanyId } from 'utils/auth/company';
import { getCompanyPath } from 'utils/auth/getCompanyPath';
import { isAdminToken } from 'utils/auth/token';
import { getUser } from 'utils/auth/user';
import { convertEmailToName } from 'utils/converters/convertEmailToName';
import { getInitials } from 'utils/helpers/getInitials';

const useStyles = makeStyles(theme => ({
	primary: {
		fontSize: '0.85rem',
		fontWeight: 600,
		color: theme.palette.secondary.grey700,
		whiteSpace: 'break-spaces',
	},
	secondary: {
		fontSize: '0.8rem',
		color: theme.palette.secondary.grey700,
	},
	replies: {
		fontSize: '0.8rem',
		color: theme.palette.secondary.grey600,
	},
}));

const UserAvatarList = ({ authors }) => {
	if (!authors || authors?.length === 0) return null;

	return authors?.map((item, index) => {
		const name = convertEmailToName(item);
		const initials = getInitials(name);

		return <CustomAvatar sx={{ ml: index > 0 ? '.3rem' : 0 }} key={item} initials={initials} />;
	});
};

const ResolveConversationButton = ({
	isAdmin,
	currentUser,
	author,
	resolveConversation,
	resolveConversationLoading,
	handleResolve,
	conversationId,
}) => {
	const intl = useIntl();

	if (!(isAdmin || currentUser === author) || !resolveConversation) return null;

	return (
		<ConfirmIconButton
			sx={{ background: 'rgba(72, 46, 81, 0.08)' }}
			loading={resolveConversationLoading === conversationId}
			disabled={!!resolveConversationLoading}
			tooltip={intl.formatMessage({ id: 'collaboration.conversation.resolve.confirm' })}
			confirmText={intl.formatMessage({ id: 'collaboration.conversation.resolve.confirm' })}
			cancelText={intl.formatMessage({ id: 'collaboration.conversation.resolve.cancel' })}
			confirmBodyText={intl.formatMessage({ id: 'collaboration.conversation.resolve.body' })}
			confirmAction={handleResolve}
			stopPropagation={true}
		>
			{resolveConversationLoading === conversationId ? (
				<Spinner size={16} />
			) : (
				<DoneIcon
					sx={{
						'&:hover': {
							transform: 'scale(1.2)',
						},
					}}
				/>
			)}
		</ConfirmIconButton>
	);
};

const DeleteConversationButton = ({
	isAdmin,
	/* currentUser, author */ removeConversationLoading,
	deleteConversation,
	handleDelete,
	conversationId,
}) => {
	const intl = useIntl();

	if (!isAdmin || !deleteConversation) return null;

	return (
		<ConfirmIconButton
			sx={{ ml: 1, background: 'rgba(72, 46, 81, 0.08)' }}
			disabled={!!removeConversationLoading}
			tooltip={intl.formatMessage({ id: 'collaboration.conversation.remove.confirm' })}
			confirmText={intl.formatMessage({ id: 'collaboration.conversation.remove.confirm' })}
			cancelText={intl.formatMessage({ id: 'collaboration.conversation.remove.cancel' })}
			confirmBodyText={intl.formatMessage({ id: 'collaboration.conversation.remove.body' })}
			confirmAction={handleDelete}
			stopPropagation={true}
		>
			{removeConversationLoading === conversationId ? (
				<Spinner size={16} />
			) : (
				<DeleteIcon
					sx={{
						'&:hover': {
							transform: 'scale(1.2)',
						},
					}}
				/>
			)}
		</ConfirmIconButton>
	);
};

// eslint-disable-next-line no-unused-vars
const Replies = ({ commentsCount, intl, classes }) => {
	if (!commentsCount) return null;

	if (commentsCount < 2)
		return <Typography className={classes.replies}>{intl.formatMessage({ id: 'collaboration.no.replies' })}</Typography>;
	if (commentsCount === 2)
		return (
			<Typography className={classes.replies}>
				{commentsCount - 1} {intl.formatMessage({ id: 'collaboration.reply' })}
			</Typography>
		);
	if (commentsCount > 2)
		return (
			<Typography className={classes.replies}>
				{commentsCount - 1} {intl.formatMessage({ id: 'collaboration.replies' })}
			</Typography>
		);
};

const CommentsCount = ({ commentsCount, show, isRead }) => {
	const intl = useIntl();

	if (!show) return null;

	if (commentsCount <= 1) return null;

	const commentNumber = commentsCount - 1;
	const text =
		commentsCount > 2
			? intl.formatMessage({ id: 'collaboration.conversation.comments.blur' })
			: intl.formatMessage({ id: 'collaboration.conversation.comments.single' });

	const UnreadMessage = () => {
		if (isRead) return null;

		return <>- {intl.formatMessage({ id: 'collaboration.conversation.comments.newmessages' })}</>;
	};

	return (
		<Typography
			sx={{
				fontSize: '0.875rem',
				color: 'rgba(0,0,0,0.5)',
				whiteSpace: 'break-spaces',
			}}
		>
			{commentNumber} {text} <UnreadMessage />
		</Typography>
	);
};

const ConversationItem = ({
	index,
	expandOnLoad = false,
	conversationId,
	link,
	linkTitle,
	createdTime,
	author,
	firstComment,
	commentsCount,
	commentAuthors,
	isRead,
	resolveConversation,
	deleteConversation,
	loadComments,
	addComment,
	deleteComment,
	goToTarget,
	isDashboard,
	conversationItem,
}) => {
	const classes = useStyles();
	const intl = useIntl();
	const isAdmin = isAdminToken();
	const { pathname, hash } = useLocation();
	const companyId = getCompanyId();
	const { email: currentUser } = getUser();

	const collaboration = useSelector(state => state.collaboration);
	const resolveConversationLoading = collaboration?.resolveConversationLoading;
	const removeConversationLoading = collaboration?.removeConversationLoading;
	const removeCommentLoading = collaboration?.removeCommentLoading;

	const [expanded, setExpanded] = useState(expandOnLoad ? conversationId : false);
	const conversation = conversationItem ?? find(collaboration?.conversations?.data, item => item.conversationId === conversationId);
	const comments = conversation?.comments;
	const commentsLoading = !comments || comments?.loading;
	const commentsIsLoaded = conversationItem ? true : comments?.isLoaded;
	const commentsData = conversationItem ? comments : comments?.data;

	const commentsWithoutFirst = filter(commentsData, (_, index, arr) => index !== arr.length - 1);

	const uniqueAuthors = [...new Set(commentAuthors)];

	const handleChange = id => (_, isExpanded) => {
		setExpanded(isExpanded ? id : false);

		if (!isExpanded || (comments && commentsIsLoaded)) return;

		loadComments(id, isRead);
	};

	useEffect(() => {
		if (commentsIsLoaded) scrollToElement(expanded);
	}, [expanded, commentsIsLoaded, commentsCount]);

	const scrollToElement = id => {
		const element = document.getElementById(id);

		if (!element) return;

		element.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
	};

	const linkSplitted = getLinkSplitted(link);
	const pathBase = getCompanyPath('/', pathname, companyId);

	const getPathnameMatchLink = (link, pathname, pathBase, hash) => {
		const linkSplitted = getLinkSplitted(link);

		// dirty --> TODO: make better logic may be
		if (linkSplitted[0] === 'businessplan' || linkSplitted[0] === 'financialplan')
			return pathname + hash === pathBase + linkSplitted[0] + '#' + linkSplitted[1];
		if (
			linkSplitted[0] === 'projects' &&
			linkSplitted.length > 2 &&
			linkSplitted[2] !== 'plan' &&
			linkSplitted[2] !== 'summary' &&
			hash === '#costs'
		)
			return true;
		if (linkSplitted[0] === 'projects' && linkSplitted.length === 2 && hash) return false;
		if (link === 'projects/general') return true;

		return pathname === pathBase + link;
	};

	const isPathnameMatchLink = getPathnameMatchLink(link, pathname, pathBase, hash);

	const getLinkTitle = (linkTitle, linkSplitted, isDashboard, intl) => {
		let ret = '';

		if (linkTitle) ret = linkTitle;
		else if (linkSplitted.length === 2 && linkSplitted[1] === 'general')
			ret = intl.formatMessage({ id: `collaboration.${linkSplitted[0]}.general` });
		else if (isDashboard) ret = intl.formatMessage({ id: 'collaboration.all.general' });
		else
			ret = `${intl.formatMessage({ id: 'collaboration.businessplan.general' })} / ${intl.formatMessage({
				id: `collaboration.${linkSplitted.join('.')}`,
			})}`;

		return ret;
	};

	const handleResolve = () => resolveConversation(conversationId);
	const handleDelete = () => deleteConversation(conversationId);

	const handleClick = (e, link) => {
		e.stopPropagation();
		goToTarget(link);
	};

	const CommentListWrapper = () => {
		if (commentsLoading && commentsCount > 1)
			return (
				<Box sx={{ m: 2, mt: 0 }}>
					<Spinner size={20} />
				</Box>
			);

		return (
			<>
				{commentsIsLoaded && (
					<CommentsList {...{ conversationId, comments: commentsWithoutFirst, isAdmin, removeCommentLoading, deleteComment }} />
				)}
				<AddComment {...{ conversationId, addComment }} sx={{ mb: 2, px: 2 }} />
			</>
		);
	};

	return (
		<Accordion
			square
			disableGutters
			elevation={0}
			expanded={expanded === conversationId}
			onChange={handleChange(conversationId)}
			TransitionProps={{
				unmountOnExit: true,
				onEntered: () => scrollToElement(expanded),
			}}
			sx={{
				direction: 'ltr',
				borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
				'&:before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary
				aria-controls={`${conversationId}-content`}
				expandIcon={null}
				sx={{
					p: 0,
					bgcolor: expanded === conversationId ? 'secondary.grey' : 'primary.secondary',
					'& .MuiAccordionSummary-content': {
						m: 0,
					},
					'&:hover': {
						cursor: 'pointer',
						bgcolor: 'secondary.grey',
					},
				}}
			>
				<Stack direction='column' spacing={1.25} sx={{ width: '100%', p: 2 }}>
					{/* <Box display='flex' sx={{ justifyContent: 'space-between' }}> */}
					<Stack sx={{ width: '100%' }} direction='row' spacing={1} justifyContent='space-between'>
						<Stack direction='row'>
							<UserAvatarList authors={uniqueAuthors} />
						</Stack>
						<Box>
							<ResolveConversationButton
								{...{
									isAdmin,
									currentUser,
									author,
									resolveConversation,
									resolveConversationLoading,
									handleResolve,
									conversationId,
								}}
							/>
							<DeleteConversationButton
								{...{ isAdmin, removeConversationLoading, deleteConversation, handleDelete, conversationId }}
							/>
						</Box>
					</Stack>
					{/* </Box > */}
					<Box display='flex'>
						<Stack direction='row' spacing={0.75}>
							{index !== undefined && (
								<>
									<Typography className={classes.secondary}>{'#' + (index + 1)}</Typography>
									<Typography className={classes.secondary}>-</Typography>
								</>
							)}
							<Typography
								sx={{
									textDecoration: !isPathnameMatchLink && 'underline',
									'&:hover': {
										color: !isPathnameMatchLink && 'secondary.menuActive',
									},
								}}
								className={classes.secondary}
								onClick={e => (isPathnameMatchLink ? null : handleClick(e, link))}
							>
								{getLinkTitle(linkTitle, linkSplitted, isDashboard, intl)}
							</Typography>
						</Stack>
					</Box>
					<Stack direction='column' spacing={1}>
						<Box display='flex' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography className={classes.primary}>{convertEmailToName(author)}</Typography>
							<TimeAgo time={createdTime} sx={{ fontSize: '0.8rem', color: 'secondary.grey700' }} />
						</Box>
						<Typography
							sx={{
								display: '-webkit-box',
								WebkitLineClamp: expanded ? 'unset' : 2,
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								fontSize: '0.85rem',
								color: 'secondary.grey700',
								mt: 0.5,
								whiteSpace: 'break-spaces',
							}}
						>
							{firstComment}
						</Typography>
					</Stack>
					{/* <Box display='flex' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
						<Replies {...{ commentsCount, intl, classes }} />
						{!isRead &&
							<Typography
								sx={{
									fontSize: '0.8rem',
									fontWeight: 500,
									color: '#000',
									animation: `${keyframes`from { opacity: 1; } to { opacity: 0.65; }`} 2s linear 3`,
								}}
							>
								<AnnouncementIcon />
							</Typography>
						}
					</Box> */}
					<CommentsCount show={!expanded} commentsCount={commentsCount} isRead={isRead} />
				</Stack>
			</AccordionSummary>
			<AccordionDetails sx={{ p: 0, bgcolor: 'secondary.grey' }}>
				<CommentListWrapper />
				<span id={expanded || null} />
			</AccordionDetails>
		</Accordion>
	);
};

export default ConversationItem;
