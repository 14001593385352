import { yupResolver } from '@hookform/resolvers/yup';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Link, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import Button from 'components/Button/Button';
import FormControlledInput from 'components/Form/FormControlledInput';

const useStyles = makeStyles(theme => ({
	form: {
		width: '100%',
	},
	wrapper: {
		margin: theme.spacing(3, 0, 2),
		position: 'relative',
	},
	button: {
		padding: theme.spacing(2),
		fontWeight: 600,
		color: theme.palette.primary.white,
		backgroundColor: theme.palette.success.main,
		'&:hover': {
			opacity: '0.8',
			backgroundColor: theme.palette.success.main,
		},
		'&:disabled': {
			opacity: '0.6',
			backgroundColor: theme.palette.success.main,
			color: theme.palette.primary.white,
		},
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: theme.spacing(-1.5), // = CircularProgress size={24} / 2
		marginLeft: theme.spacing(-1.5), // same here
		color: theme.palette.primary.white,
	},
	link: {
		color: theme.palette.primary.white,
		fontSize: '1.1rem',
		'&[aria-current="disabled"]': {
			color: theme.palette.primary.grey,
			cursor: 'default',
			'&:hover': {
				textDecoration: 'none',
			},
		},
	},
}));

const LoginForm = ({ onSubmit }) => {
	const intl = useIntl();
	const loading = useSelector(state => state.app?.loading);

	const classes = useStyles();

	const schema = yup.object().shape({
		username: yup.string().required(
			`${intl.formatMessage({ id: 'login.placeholder.username' })} ${intl.formatMessage({
				id: 'message.isRequired',
			})}`
		),
		password: yup.string().required(
			`${intl.formatMessage({ id: 'login.placeholder.password' })} ${intl.formatMessage({
				id: 'message.isRequired',
			})}`
		),
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
		resolver: yupResolver(schema),
	});

	return (
		<Box
			component='form'
			noValidate
			onSubmit={handleSubmit(onSubmit)}
			sx={{
				'& .MuiInputBase-root.MuiOutlinedInput-root': {
					backgroundColor: theme => theme.palette.primary.contrastText,
				},
				'& .FormError-root': {
					color: 'primary.white',
				},
			}}
		>
			<Stack direction='column' spacing={2} useFlexGap>
				<FormControlledInput
					disabled={loading}
					defaultValue=''
					name='username'
					control={control}
					error={errors?.username?.message}
					placeholder={intl.formatMessage({ id: 'login.placeholder.username' })}
					icon={<PersonOutlineOutlinedIcon color='primary' />}
					InputProps={{
						sx: { p: '0.4286rem 1.2143rem 0.4286rem 1.2143rem' },
					}}
				/>
				<FormControlledInput
					disabled={loading}
					defaultValue=''
					name='password'
					type='password'
					control={control}
					error={errors?.password?.message}
					placeholder={intl.formatMessage({ id: 'login.placeholder.password' })}
					icon={<LockOutlinedIcon color='primary' />}
					InputProps={{
						sx: { p: '0.4286rem 1.2143rem 0.4286rem 1.2143rem' },
					}}
				/>
				{/* @ts-ignore */}
				<Button
					color='success'
					loading={loading}
					onClick={handleSubmit(onSubmit)}
					sx={{ p: '0.85714rem 1.21429rem', lineHeight: '1.28571rem', fontSize: '1.14286rem' }}
					fullWidth
				>
					<FormattedMessage id='login.submit' />
				</Button>
				<Link component={RouterLink} to='/login/forgotpassword' aria-current={loading && 'disabled'} className={classes.link}>
					<FormattedMessage id='login.forgotpassword' />
				</Link>
			</Stack>
		</Box>
	);
};

export default LoginForm;
