import React from 'react';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    input: {
        '& .MuiOutlinedInput-root': {
            height: '3rem',
            borderRadius: 0,
            borderTop: `1px solid ${theme.palette.secondary.main}`,
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
        },
        '& .MuiOutlinedInput-root:hover': {
            borderTopColor: theme.palette.primary.main,
            borderBottomColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    text: {
        fontSize: '0.75rem',
        color: theme.palette.primary.main,
    },
}));

const SearchField = ({ handleChange }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <TextField
            variant='outlined'
            margin='dense'
            fullWidth
            placeholder={intl.formatMessage({ id: 'customers.search.placeholder' }).toUpperCase()}
            onChange={handleChange}
            onKeyDown={e => e.stopPropagation()}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <SearchIcon color='primary' />
                    </InputAdornment>
                ),
                classes: { input: classes.text },
            }}
            classes={{
                root: classes.input,
            }}
        />
    );
};

export default SearchField;
