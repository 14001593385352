import * as yup from 'yup';

export const maxLength = 30000;

export const getProjectSchema = intl => {
	return yup.object().shape({
		dynamic: yup.object().nullable().shape({}),
		projectName: yup
			.string()
			.required(`${intl.formatMessage({ id: 'project.form.projectName' })} ${intl.formatMessage({ id: 'message.isRequired', })}`),
		instrumentName: yup
			.string()
			.required(`${intl.formatMessage({ id: 'project.form.instrumentType' })} ${intl.formatMessage({ id: 'message.isRequired', })}`),
		startDate: yup
			.date()
			.typeError(`${intl.formatMessage({ id: 'project.form.startDate' })} ${intl.formatMessage({ id: 'message.isRequired', })}`),
		endDate: yup
			.date()
			.min(yup.ref('startDate'), intl.formatMessage({ id: 'project.form.endDate.mindate' }))
			.typeError(`${intl.formatMessage({ id: 'project.form.endDate' })} ${intl.formatMessage({ id: 'message.isRequired', })}`),
		projectSize: yup
			.string()
			.required(`${intl.formatMessage({ id: 'project.form.projectSize' })} ${intl.formatMessage({ id: 'message.isRequired', })}`),
		amountToApply: yup
			.string()
			.required(`${intl.formatMessage({ id: 'project.form.amountToApply' })} ${intl.formatMessage({ id: 'message.isRequired', })}`),
		projectSummary: yup
			.string()
			.nullable()
			// .required(`${intl.formatMessage({ id: 'project.form.projectSummary' })} ${intl.formatMessage({ id: 'message.isRequired' })}`)
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`,),
		projectBackground: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`,),
		projectService: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`,),
		projectMarket: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`,),
		projectVision: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`,),
		projectDevelopment: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`,),
	});
};
