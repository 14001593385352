import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SkipPreviousIcon from '@mui/icons-material/FirstPage';
import SkipNextIcon from '@mui/icons-material/LastPage';
import PeopleIcon from '@mui/icons-material/People';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import MenuListItem from 'components/List/MenuListItem';
import MenuListItemIcon from 'components/List/MenuListItemIcon';
import MenuTextItem from 'components/List/MenuTextItem';
import Logo from 'components/Logo';
import LogoSmall from 'components/Logo/LogoSmall';
import AdminDrawer from 'containers/Admin/UsersCompany/AdminDrawer';
import GrantsCompanySearch from 'containers/CompanySearch/GrantsCompanySearch';

import { Stack } from '@mui/system';
import { menuDrawerWidthExpanded, menuDrawerWidthMinimized } from 'config/drawerWidth';
import UpgradeFreemiumCard from 'containers/MasterPage/Freemium/CTAContainer';
import { toggleProjectsSidebar } from 'containers/Projects/slices';
import { isAdminToken } from 'utils/auth/token';
import { regexCompany } from 'utils/constants/regex';
import { convertEmailToName } from 'utils/converters/convertEmailToName';
import { getInitials } from 'utils/helpers/getInitials';
import { matchPath } from 'utils/helpers/pathMatch';
import CompanyMenuItems from './CompanyMenuItems';

const useStyles = makeStyles(theme => ({
	drawerPaperMinimized: {
		width: menuDrawerWidthMinimized,
	},
	drawerPaperExpanded: {
		width: menuDrawerWidthExpanded,
	},
	drawerPaper: {
		backgroundColor: theme.palette.primary.main,
		'&.MuiDrawer-paperAnchorDockedLeft': {
			borderRight: 'none',
		},
	},
	container: {
		minHeight: '100%',
	},
	content: {
		flex: '1 0 auto',
		overflowY: 'auto',
		// custom scrollbar
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.primary.beige,
		},
		[theme.breakpoints.up('lg')]: {
			scrollbarGutter: 'stable',
		},
	},
	footer: {
		flexShrink: 0,
		maxWidth: '100%',
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 700,
	},
	iconButton: {
		'& .MuiListItemIcon-root': {
			minWidth: 0,
		},
	},
	name: {
		color: theme.palette.primary.white,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	button: {
		color: '#bfc8f3',
		//textTransform: 'none',
		padding: 0,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	logo: {
		backgroundColor: theme.palette.primary.main, // required to overlap
		padding: '1rem',
		paddingTop: '2rem',
		[theme.breakpoints.up('lg')]: {
			position: 'sticky',
			top: 0,
			zIndex: 10,
		},
	},
	footerContainer: {
		padding: '1rem',
		[theme.breakpoints.between('sm', 'lg')]: {
			padding: '0.5rem',
		},
	},
	exitIcon: {
		color: theme.palette.primary.white,
		'&:hover': {
			color: theme.palette.primary.beige,
		},
	},
	expandIcon: {
		color: theme.palette.primary.beige,
	},
	moreIcon: {
		color: theme.palette.primary.white,
	},
	avatar: {
		fontWeight: 600,
		backgroundColor: theme.palette.primary.white,
		color: theme.palette.primary.main,
	},
}));

// TODO: fix extra function call if path has not changed

const MenuDrawer = ({
	closeDrawer,
	onClose,
	isAuthenticated,
	isContributor,
	isAdvanced,
	isFreemium,
	pathname,
	localCompanyId,
	localCompanyName,
	companyId,
	companyName,
	companies,
	isDrawerExpanded,
	toggleExpandDrawer,
	openOwnCompanies,
	smDown,
	betweenSmLg,
	isAgreement,
	isLicenseExpired,
	userName,
}) => {
	const classes = useStyles();
	const intl = useIntl();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const kanban = useSelector(state => state.kanban);
	const isProjectsLoaded = kanban?.projects?.isLoaded;
	const projects = kanban?.projects;
	const mock = projects?.mock;
	const isProjectsSidebarOpen = projects?.isSidebarOpen;

	const financialPlan = useSelector(state => state.financialPlan);
	const isFinancialPlanLoaded = financialPlan?.isLoaded;
	const hasFinancialPlan = financialPlan?.financialPlan ? true : false;
	const isMainContentMounted = financialPlan?.isMainContentMounted;

	const [openFinancialPlanContent, setOpenFinancialPlanContent] = useState(false);
	const toggleFinancialPlanContent = () => {
		setOpenFinancialPlanContent(!openFinancialPlanContent);
		if (isProjectsSidebarOpen) dispatch(toggleProjectsSidebar(false));
	};

	useEffect(() => {
		matchPath('/projects', location, false) ? dispatch(toggleProjectsSidebar(true)) : dispatch(toggleProjectsSidebar(false));
	}, [location, dispatch]);

	useEffect(() => {
		matchPath('/financialplan', location, false) || matchPath('/financialplan/edit', location, false)
			? setOpenFinancialPlanContent(true)
			: setOpenFinancialPlanContent(false);
	}, [location]);

	const name = convertEmailToName(userName);
	const initials = getInitials(name);

	const isAdmin = isAdminToken();
	const openAdminView = () => history.push(`/admin/company/${companyId}`);

	const isSearchPage = pathname === '/search' || pathname === '/admin/search';
	const menuItemFilter =
		isAuthenticated &&
		(isAgreement || isAdmin) &&
		(localCompanyId || companyId) &&
		(!isAdvanced || regexCompany.test(pathname)) &&
		!isSearchPage;

	const contentMaxHeight =
		companies?.length <= 1 && !smDown && !betweenSmLg
			? 'calc(100% - 6rem)'
			: companies?.length > 1 && !smDown && !betweenSmLg
				? 'calc(100% - 9.25rem)'
				: 'calc(100% - 7.25rem)';

	return (
		<Drawer
			variant={smDown || (betweenSmLg && isDrawerExpanded) ? 'temporary' : 'permanent'}
			anchor='left'
			open={smDown ? isDrawerExpanded : true}
			onClose={smDown || isDrawerExpanded ? closeDrawer : null}
			classes={{
				paper: clsx(classes.drawerPaper, isDrawerExpanded ? classes.drawerPaperExpanded : classes.drawerPaperMinimized),
			}}
		>
			<Stack flexDirection='column' justifyContent='space-between' sx={{ overflow: 'hidden', flex: '1 1 auto' }} useFlexGap>
				<Box style={{ maxHeight: contentMaxHeight }} className={classes.content}>
					{/* LOGO */}
					<Box textAlign='center' className={classes.logo} style={{ padding: !isDrawerExpanded && 'unset' }}>
						{isDrawerExpanded ? (
							<Stack direction='row' alignItems='baseline'>
								<Box sx={{ flexGrow: 1 }}>
									<Logo pathname={pathname} width={smDown ? '170' : '214'} />
								</Box>
								<IconButton onClick={toggleExpandDrawer}>
									{isDrawerExpanded ? (
										<SkipPreviousIcon sx={{ color: '#fff' }} size='large' />
									) : (
										<SkipNextIcon sx={{ color: '#fff' }} size='large' />
									)}
								</IconButton>
							</Stack>
						) : (
							<>
								<Box>
									<IconButton onClick={toggleExpandDrawer}>
										{isDrawerExpanded ? (
											<SkipPreviousIcon sx={{ color: '#fff' }} size='large' />
										) : (
											<SkipNextIcon sx={{ color: '#fff' }} size='large' />
										)}
									</IconButton>
								</Box>

								<LogoSmall pathname={pathname} width={20} />
							</>
						)}
					</Box>
					<Stack flexDirection='column' spacing={1} justifyContent='space-between' sx={{ height: contentMaxHeight }}>
						<List>
							{/* SEARCH */}
							{isContributor && (
								<>
									{isDrawerExpanded && !isSearchPage ? (
										<MenuListItem hoverCursorDefault={true} beige={true}>
											<GrantsCompanySearch />
										</MenuListItem>
									) : isDrawerExpanded && pathname === '/admin/search' ? null : (
										<MenuListItem
											button
											onClick={!matchPath('/admin/search', location, true) ? () => onClose('/admin/search') : null}
											conditionalStyled={!isDrawerExpanded}
											conditionalHovered={matchPath('/admin/search', location, true)}
										>
											<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
												<SearchIcon />
											</MenuListItemIcon>
										</MenuListItem>
									)}
								</>
							)}
							{/* ADMIN */}
							<AdminDrawer
								pathname={pathname}
								companyId={companyId}
								isDrawerExpanded={isDrawerExpanded}
								betweenSmLg={betweenSmLg}
								onClose={onClose}
								matchPath={matchPath}
							/>
							{/* CUSTOMER LIST */}
							{isAdvanced && (
								<>
									<MenuListItem
										button
										onClick={!matchPath('/customers', location, true) ? () => onClose('/customers') : null}
										conditionalStyled={!isDrawerExpanded}
										conditionalHovered={matchPath('/customers', location, true)}
									>
										<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
											<PeopleIcon />
										</MenuListItemIcon>
										{isDrawerExpanded && (
											<ListItemText
												primary={
													<MenuTextItem>{intl.formatMessage({ id: 'menudrawer.customerlist' })}</MenuTextItem>
												}
											/>
										)}
									</MenuListItem>
								</>
							)}
							<CompanyMenuItems
								{...{
									menuItemFilter,
									pathname,
									companyId,
									betweenSmLg,
									isDrawerExpanded,
									isLicenseExpired,
									isAgreement,
									isAdmin,
									isAdvanced,
									isFreemium,
									onClose,
									localCompanyId,
									isSearchPage,
									userName,
									toggleFinancialPlanContent,
									isProjectsSidebarOpen,
									mock,
									projects,
									isProjectsLoaded,
									smDown,
									isAuthenticated,
									isContributor,
									classes,
									isFinancialPlanLoaded,
									hasFinancialPlan,
									isMainContentMounted,
									openFinancialPlanContent,
									companyName,
									openAdminView,
								}}
							/>
							{/* USER INFO */}
							{!isDrawerExpanded && (
								<MenuListItem
									button
									onClick={pathname !== '/user' ? () => onClose('/user') : null}
									conditionalStyled={!isDrawerExpanded}
									conditionalHovered={matchPath('/user', location, true)}
								>
									<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
										<PersonOutlineIcon />
									</MenuListItemIcon>
								</MenuListItem>
							)}

						</List>
						{isDrawerExpanded && isAgreement && <UpgradeFreemiumCard />}
					</Stack>
				</Box>
				<Box className={classes.footer}>
					{/* SELECT COMPANY */}
					{companies?.length > 1 && (
						<List style={{ padding: 0 }}>
							{isDrawerExpanded ? (
								<MenuListItem beige={true} hoverCursorDefault={true}>
									<ListItemText primary={localCompanyName} />
									<ListItemSecondaryAction>
										<IconButton
											onClick={openOwnCompanies}
											className={classes.iconButton}
											style={{ padding: !isDrawerExpanded && 0 }}
										>
											<ListItemIcon classes={{ root: classes.expandIcon }}>
												<ChevronRightIcon />
											</ListItemIcon>
										</IconButton>
									</ListItemSecondaryAction>
								</MenuListItem>
							) : (
								<MenuListItem beige={true} conditionalStyled={!isDrawerExpanded}>
									<IconButton
										onClick={openOwnCompanies}
										className={classes.iconButton}
										conditionalStyled={!isDrawerExpanded}
									>
										<ListItemIcon classes={{ root: classes.expandIcon }}>
											<ChevronRightIcon />
										</ListItemIcon>
									</IconButton>
								</MenuListItem>
							)}
						</List>
					)}
					<Divider />
					<Stack
						flexDirection='row'
						justifyContent={isDrawerExpanded ? 'space-between' : 'center'}
						alignItems='center'
						className={classes.footerContainer}
						useFlexGap
					>
						{isDrawerExpanded && (
							<Stack flexDirection='row' spacing={2} alignItems='center' useFlexGap>
								<Avatar className={classes.avatar}>{initials}</Avatar>
								<Box>
									<Typography className={classes.name}>{name}</Typography>
									<Button onClick={pathname !== '/user' ? () => onClose('/user') : null} className={classes.button}>
										{intl.formatMessage({ id: 'menudrawer.footer.view.profile' })}
									</Button>
								</Box>
							</Stack>
						)}
						<IconButton onClick={() => onClose()}>
							<ExitToAppIcon className={classes.exitIcon} />
						</IconButton>
					</Stack>
				</Box>
			</Stack>
		</Drawer>
	);
};

export default MenuDrawer;
