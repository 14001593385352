import { createApi } from '@reduxjs/toolkit/query/react';
import { concat, isNil, map } from 'lodash';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { RfpProposal, RfpProposalItem, RfpProposalItemCost, RfpProposalTeamMember } from 'types/dataTypes';

type FetchRfpProposals = {
	companyId?: string | null;
};

type FetchRfpProposal = {
	companyId?: string | null;
	proposalId?: string | null;
	published?: boolean | null;
};

type UpsertRfpProposal = {
	companyId?: string | null;
	requestId?: string | null;
	rfpProposal?: Partial<RfpProposal> | null;
	published?: boolean;
};

type PublishRfpProposal = {
	companyId?: string | null;
	proposalId?: string | null;
};

type DeleteRfpProposal = {
	companyId?: string | null;
	proposalId?: string | null;
	published?: boolean;
};

type FetchRfpProposalItems = {
	companyId?: string | null;
	proposalId?: string | null;
};

type UpsertRfpProposalItem = {
	companyId?: string | null;
	proposalId?: string | null;
	rfpProposalItem?: Partial<RfpProposalItem> | null;
};

type RemoveRfpProposalItem = {
	companyId?: string | null;
	proposalId?: string | null;
	proposalItemId?: string | null;
};

type FetchRfpProposalTeamMembers = {
	companyId?: string | null;
	proposalId?: string | null;
};

type UpsertRfpProposalTeamMember = {
	companyId?: string | null;
	proposalId?: string | null;
	rfpProposalTeamMember?: Partial<RfpProposalTeamMember> | null;
};

type RemoveRfpProposalTeamMember = {
	companyId?: string | null;
	proposalId?: string | null;
	proposalTeamMemberId?: string | null;
};

type FetchRfpProposalItemCosts = {
	companyId?: string | null;
	proposalId?: string | null;
	proposalItemId?: string | null;
};

type UpsertRfpProposalItemCost = {
	companyId?: string | null;
	proposalId?: string | null;
	proposalItemId?: string | null;
	rfpProposalItemCost?: Partial<RfpProposalItemCost> | null;
};

type RemoveRfpProposalItemCost = {
	companyId?: string | null;
	proposalId?: string | null;
	proposalItemId?: string | null;
	proposalItemCostId?: string | null;
};

type FetchRfpRequestProposal = {
	companyId?: string | null;
	requestId?: string | null;
	proposalId?: string | null;
};

enum Tags {
	RfpProposal = 'rfpProposal',
	RfpProposalItem = 'rfpProposalItem',
	RfpProposalTeamMember = 'rfpProposalTeamMember',
	RfpProposalItemCost = 'rfpProposalItemCost',
	PublishedRfpProposal = 'publishedRfpProposal',
}

const baseUrl = 'rfp';

export const rfpProposalApi = createApi({
	reducerPath: 'rfpProposal',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.RfpProposal, Tags.RfpProposalItem, Tags.RfpProposalTeamMember, Tags.RfpProposalItemCost, Tags.PublishedRfpProposal],
	endpoints: builder => ({
		fetchRfpProposals: builder.query<{ drafts: RfpProposal[]; published: RfpProposal[] }, FetchRfpProposals>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/proposal/${companyId}`,
				};
			},
			providesTags: rfpProposals => {
				return concat(
					[{ type: Tags.RfpProposal, id: 'LIST' }],
					map(rfpProposals?.drafts, rfpProposal => ({
						type: Tags.RfpProposal,
						id: rfpProposal?.rfpProposalId || '',
					})),
					map(rfpProposals?.published, rfpProposal => ({
						type: Tags.RfpProposal,
						id: rfpProposal?.rfpProposalId || '',
					}))
				);
			},
		}),
		fetchRfpProposal: builder.query<RfpProposal, FetchRfpProposal>({
			query: ({ companyId, proposalId, published }) => {
				if (isNil(companyId) || isNil(proposalId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/proposal/${companyId}/${proposalId}`,
					params: { published },
				};
			},
			providesTags: rfpProposal => {
				return [{ type: Tags.RfpProposal, id: rfpProposal?.rfpProposalId || '' }];
			},
		}),
		upsertRfpProposal: builder.mutation<RfpProposal, UpsertRfpProposal>({
			query: ({ companyId, requestId, rfpProposal, published }) => {
				if (isNil(companyId) || isNil(requestId) || isNil(rfpProposal) || isNil(published)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/proposal/${companyId}/${requestId}`,
					params: { published },
					data: rfpProposal,
				};
			},
			invalidatesTags: rfpProposal => {
				return [
					{ type: Tags.RfpProposal, id: 'LIST' },
					{ type: Tags.RfpProposal, id: rfpProposal?.rfpProposalId || '' },
				];
			},
		}),
		publishRfpProposal: builder.mutation<RfpProposal, PublishRfpProposal>({
			query: ({ companyId, proposalId }) => {
				if (isNil(companyId) || isNil(proposalId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'PUT',
					url: `/proposal/${companyId}/${proposalId}/publish`,
				};
			},
			invalidatesTags: rfpProposal => {
				return [
					{ type: Tags.RfpProposal, id: 'LIST' },
					{ type: Tags.RfpProposal, id: rfpProposal?.rfpProposalId || '' },
				];
			},
		}),
		deleteRfpProposal: builder.mutation<RfpProposal, DeleteRfpProposal>({
			query: ({ companyId, proposalId, published }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(published)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/proposal/${companyId}/${proposalId}`,
					params: { published },
				};
			},
			invalidatesTags: rfpProposal => {
				return [
					{ type: Tags.RfpProposal, id: 'LIST' },
					{ type: Tags.RfpProposal, id: rfpProposal?.rfpProposalId || '' },
				];
			},
		}),
		fetchRfpProposalItems: builder.query<RfpProposalItem[], FetchRfpProposalItems>({
			query: ({ companyId, proposalId }) => {
				if (isNil(companyId) || isNil(proposalId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/proposal/${companyId}/${proposalId}/item`,
				};
			},
			providesTags: rfpProposalItems => {
				return concat(
					[{ type: Tags.RfpProposalItem, id: 'LIST' }],
					map(rfpProposalItems, rfpProposalItem => ({
						type: Tags.RfpProposalItem,
						id: rfpProposalItem?.rfpProposalItemId || '',
					}))
				);
			},
		}),
		upsertRfpProposalItem: builder.mutation<RfpProposalItem, UpsertRfpProposalItem>({
			query: ({ companyId, proposalId, rfpProposalItem }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(rfpProposalItem)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/proposal/${companyId}/${proposalId}/item`,
					data: rfpProposalItem,
				};
			},
			invalidatesTags: rfpProposalItem => {
				return [
					{ type: Tags.RfpProposalItem, id: 'LIST' },
					{ type: Tags.RfpProposalItem, id: rfpProposalItem?.rfpProposalItemId || '' },
				];
			},
		}),
		removeRfpProposalItem: builder.mutation<RfpProposalItem, RemoveRfpProposalItem>({
			query: ({ companyId, proposalId, proposalItemId }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(proposalItemId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/proposal/${companyId}/${proposalId}/item/${proposalItemId}`,
				};
			},
			invalidatesTags: (result, error, { proposalItemId, proposalId }) => {
				return [
					{ type: Tags.RfpProposalItem, id: 'LIST' },
					{ type: Tags.RfpProposalItem, id: proposalItemId || '' },
				];
			},
		}),
		fetchRfpProposalTeamMembers: builder.query<RfpProposalTeamMember[], FetchRfpProposalTeamMembers>({
			query: ({ companyId, proposalId }) => {
				if (isNil(companyId) || isNil(proposalId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/proposal/${companyId}/${proposalId}/team`,
				};
			},
			providesTags: rfpProposalTeamMembers => {
				return concat(
					[{ type: Tags.RfpProposalTeamMember, id: 'LIST' }],
					map(rfpProposalTeamMembers, rfpProposalTeamMember => ({
						type: Tags.RfpProposalTeamMember,
						id: rfpProposalTeamMember?.teamMemberId || '',
					}))
				);
			},
		}),
		upsertRfpProposalTeamMember: builder.mutation<RfpProposalTeamMember, UpsertRfpProposalTeamMember>({
			query: ({ companyId, proposalId, rfpProposalTeamMember }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(rfpProposalTeamMember)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/proposal/${companyId}/${proposalId}/team`,
					data: rfpProposalTeamMember,
				};
			},
			invalidatesTags: rfpProposalTeamMember => {
				return [
					{ type: Tags.RfpProposalTeamMember, id: 'LIST' },
					{ type: Tags.RfpProposalTeamMember, id: rfpProposalTeamMember?.teamMemberId || '' },
				];
			},
		}),
		removeRfpProposalTeamMember: builder.mutation<RfpProposalTeamMember, RemoveRfpProposalTeamMember>({
			query: ({ companyId, proposalId, proposalTeamMemberId }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(proposalTeamMemberId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/proposal/${companyId}/${proposalId}/team/${proposalTeamMemberId}`,
				};
			},
			invalidatesTags: (result, error, { proposalTeamMemberId, proposalId }) => {
				return [
					{ type: Tags.RfpProposalTeamMember, id: 'LIST' },
					{ type: Tags.RfpProposalTeamMember, id: proposalTeamMemberId || '' },
				];
			},
		}),
		fetchRfpProposalItemCosts: builder.query<RfpProposalItemCost[], FetchRfpProposalItemCosts>({
			query: ({ companyId, proposalId, proposalItemId }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(proposalItemId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/proposal/${companyId}/${proposalId}/item/${proposalItemId}/cost`,
				};
			},
			providesTags: rfpProposalItemCosts => {
				return concat(
					[{ type: Tags.RfpProposalItemCost, id: 'LIST' }],
					map(rfpProposalItemCosts, rfpProposalItemCost => ({
						type: Tags.RfpProposalItemCost,
						id: rfpProposalItemCost?.rfpItemCostId || '',
					}))
				);
			},
		}),
		upsertRfpProposalItemCost: builder.mutation<RfpProposalItemCost, UpsertRfpProposalItemCost>({
			query: ({ companyId, proposalId, proposalItemId, rfpProposalItemCost }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(proposalItemId) || isNil(rfpProposalItemCost)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/proposal/${companyId}/${proposalId}/item/${proposalItemId}/cost`,
					data: rfpProposalItemCost,
				};
			},
			invalidatesTags: rfpProposalItemCost => {
				return [
					{ type: Tags.RfpProposalItemCost, id: 'LIST' },
					{ type: Tags.RfpProposalItemCost, id: rfpProposalItemCost?.rfpItemCostId || '' },
				];
			},
		}),
		removeRfpProposalItemCost: builder.mutation<RfpProposalItemCost, RemoveRfpProposalItemCost>({
			query: ({ companyId, proposalId, proposalItemId, proposalItemCostId }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(proposalItemId) || isNil(proposalItemCostId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/proposal/${companyId}/${proposalId}/item/${proposalItemId}/cost/${proposalItemCostId}`,
				};
			},
			invalidatesTags: (result, error, { proposalItemCostId }) => {
				return [
					{ type: Tags.RfpProposalItemCost, id: 'LIST' },
					{ type: Tags.RfpProposalItemCost, id: proposalItemCostId || '' },
				];
			},
		}),
		fetchRfpRequestProposal: builder.query<RfpProposal, FetchRfpRequestProposal>({
			query: ({ companyId, requestId, proposalId }) => {
				if (isNil(companyId) || isNil(requestId) || isNil(proposalId)) {
					throw new Error('parameter error');
				}

				return {
					method: 'GET',
					url: `/request/${companyId}/${requestId}/proposals/${proposalId}`,
				};
			},
			providesTags: rfpProposal => {
				return [{ type: Tags.PublishedRfpProposal, id: rfpProposal?.rfpProposalId || '' }];
			},
		}),
	}),
});

export const {
	useFetchRfpProposalQuery,
	useLazyFetchRfpProposalQuery,
	useFetchRfpProposalsQuery,
	useUpsertRfpProposalMutation,
	usePublishRfpProposalMutation,
	useFetchRfpProposalItemsQuery,
	useUpsertRfpProposalItemMutation,
	useRemoveRfpProposalItemMutation,
	useFetchRfpProposalTeamMembersQuery,
	useUpsertRfpProposalTeamMemberMutation,
	useRemoveRfpProposalTeamMemberMutation,
	useFetchRfpProposalItemCostsQuery,
	useUpsertRfpProposalItemCostMutation,
	useRemoveRfpProposalItemCostMutation,
	useFetchRfpRequestProposalQuery,
	useDeleteRfpProposalMutation,
} = rfpProposalApi;
