import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import {
	economicalDataError,
	loadAttachmentsError,
	loadAttachmentsSuccess,
	loadEconomicalDataSuccess,
	loadInfoItemsError,
	loadInfoItemsSuccess,
	loadMainError,
	loadMainSuccess,
	loadTeamMembersError,
	loadTeamMembersSuccess,
	loadTemplatesError,
	loadTemplatesSuccess,
} from 'containers/BusinessPlan/slices';
import { getStartDateArr } from 'containers/FinancialPlan/parserTimeline';
import {
	loadFinancialPlanInstrumentsSuccess,
	loadFinancialPlanSuccess,
	loadPublishedFinancialPlanSuccess,
} from 'containers/FinancialPlan/slices';
import {
	getProjectsError,
	getProjectsSuccess,
	getTimelineError,
	getTimelineSuccess /*, loadProjects */,
	handleProjectObject,
} from 'containers/Projects/slices';
import { calculateCostsTotalSum, isProjectDraft } from 'containers/Projects/utils';
import { loadScenariosError, loadScenariosSuccess } from 'containers/Scenario/actions';
import { umlautsDecode } from 'utils/formatters/umlautsDecode';
import { delayWhile } from 'utils/misc/delay';
import { mockFinancialPlan } from 'utils/mock/financialPlan';
import { migrationApi } from './api';

export const name = 'migratedCompany';

export const initialState = {
	isLoaded: false,
	selected: [],
	timelineId: null,
	company: {
		isLoaded: false,
		loading: false,
		error: null,
		company: {},
	},
	scenario: {
		isLoaded: false,
		isSaved: false, // reset all isSaved if it's needed
		loading: false,
		error: null,
		scenarios: {},
	},
	businessPlan: {
		isLoaded: false,
		isSaved: false,
		loading: false,
		error: null,
		isLoadedArray: [],
		main: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: false,
			data: {},
		},
		infoItems: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: false,
			data: [],
		},
		teamMembers: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: false,
			data: [],
		},
		templates: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: false,
			data: {},
		},
		economicalData: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: null,
			data: [],
		},
		tags: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: false,
			data: {},
		},
		attachments: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: false,
			data: {},
		},
	},
	financialPlan: {
		isLoaded: false,
		isSaved: false,
		loading: false,
		error: null,
		financialPlan: null,
		publishedFinancialPlan: null,
		isScrolling: false,
		instrument: null,
		editMode: false,
		isMainContentMounted: false,
		instruments: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: null,
			list: [],
		},
	},
	kanban: {
		isLoaded: false,
		projectDataLoaded: false,
		timeline: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: null,
			data: {},
		},
		projects: {
			isLoaded: false,
			isSaved: false,
			loading: false,
			error: false,
			mock: false,
			byId: {},
			allIds: [],
			isSidebarOpen: false,
		},
		columns: {
			byId: {
				todo: {
					id: 'todo',
					totalSum: costs => calculateCostsTotalSum(costs, 'amountApplied'),
					costIds: [],
				},
				approved: {
					id: 'approved',
					totalSum: costs => calculateCostsTotalSum(costs, 'amountGranted'),
					costIds: [],
				},
				reported: {
					id: 'reported',
					totalSum: costs => calculateCostsTotalSum(costs, 'amountReported'),
					costIds: [],
				},
				paid: {
					id: 'paid',
					totalSum: costs => calculateCostsTotalSum(costs, 'amountPaid'),
					costIds: [],
				},
			},
		},
		costs: {
			isLoaded: false,
			isSaved: false,
			projectId: null,
			byId: {},
			allIds: [],
		},
		tasks: {
			isLoaded: false,
			isSaved: false,
			projectId: null,
			byId: {},
			allIds: [],
		},
		attachments: {
			isLoaded: false,
			isSaved: false,
			projectId: null,
			byId: {},
			allIds: [],
		},
	},
};

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},
		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		},
		setTimelineId: (state, action) => {
			state.timelineId = action.payload;
		},
		loadMigratedCompanyData: state => {
			state.company.loading = true;
			state.company.error = null;
			state.company.isLoaded = false;
		},
		loadMigratedCompanyDataError: (state, action) => {
			state.company.loading = false;
			state.company.error = action.payload;
			state.company.company = initialState.company.data;
			state.company.isLoaded = true;
		},
		loadMigratedCompanyDataSuccess: (state, action) => {
			state.company.loading = false;
			state.company.error = false;
			state.company.company = action.payload;
			state.company.isLoaded = true;
		},
		// scenario
		loadMigratedCompanyScenario: state => {
			state.scenario.loading = true;
			state.scenario.error = null;
			state.scenario.isLoaded = false;
		},
		loadMigratedCompanyScenarioError: (state, action) => {
			state.scenario.loading = false;
			state.scenario.error = action.payload;
			// state.scenario.scenarios = initialState.scenario.scenarios;
			state.scenario.isLoaded = true;
		},
		loadMigratedCompanyScenarioSuccess: (state /*, action */) => {
			state.scenario.loading = false;
			state.scenario.error = false;
			// state.scenario.scenarios = action.payload;
			state.scenario.isLoaded = true;
		},
		saveMigratedCompanyScenario: state => {
			state.scenario.loading = true;
			state.scenario.error = null;
			state.scenario.isSaved = false;
		},
		saveMigratedCompanyScenarioError: (state, action) => {
			state.scenario.loading = false;
			state.scenario.error = action.payload;
			state.scenario.isSaved = true;
		},
		saveMigratedCompanyScenarioSuccess: state => {
			state.scenario.loading = false;
			state.scenario.error = false;
			state.scenario.isSaved = true;
		},
		// businessplan
		loadMigratedCompanyBusinessPlan: state => {
			state.businessPlan.loading = true;
			state.businessPlan.error = null;
			state.businessPlan.isLoaded = false;
		},
		loadMigratedCompanyBusinessPlanError: (state, action) => {
			state.businessPlan.loading = false;
			state.businessPlan.error = action.payload;
			state.businessPlan.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanSuccess: (state /* , action */) => {
			state.businessPlan.loading = false;
			state.businessPlan.error = false;
			state.businessPlan.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanMain: state => {
			state.businessPlan.main.loading = true;
			state.businessPlan.main.error = null;
			state.businessPlan.main.isLoaded = false;
		},
		loadMigratedCompanyBusinessPlanMainError: (state, action) => {
			state.businessPlan.main.loading = false;
			state.businessPlan.main.error = action.payload;
			state.businessPlan.main.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanMainSuccess: (state /* , action */) => {
			state.businessPlan.main.loading = false;
			state.businessPlan.main.error = false;
			state.businessPlan.main.isLoaded = true;
		},
		saveMigratedCompanyBusinessPlan: state => {
			state.businessPlan.loading = true;
			state.businessPlan.error = null;
			state.businessPlan.isSaved = false;
		},
		saveMigratedCompanyBusinessPlanError: (state, action) => {
			state.businessPlan.loading = false;
			state.businessPlan.error = action.payload;
			state.businessPlan.isSaved = true;
		},
		saveMigratedCompanyBusinessPlanSuccess: state => {
			state.businessPlan.loading = false;
			state.businessPlan.error = false;
			state.businessPlan.isSaved = true;
		},
		saveMigratedCompanyBusinessPlanMain: state => {
			state.businessPlan.main.loading = true;
			state.businessPlan.main.error = null;
			state.businessPlan.main.isSaved = false;
		},
		saveMigratedCompanyBusinessPlanMainError: (state, action) => {
			state.businessPlan.main.loading = false;
			state.businessPlan.main.error = action.payload;
			state.businessPlan.main.isSaved = true;
		},
		saveMigratedCompanyBusinessPlanMainSuccess: state => {
			state.businessPlan.main.loading = false;
			state.businessPlan.main.error = false;
			state.businessPlan.main.isSaved = true;
		},
		loadMigratedCompanyBusinessPlanInfoItems: state => {
			state.businessPlan.infoItems.loading = true;
			state.businessPlan.infoItems.error = null;
			state.businessPlan.infoItems.isLoaded = false;
		},
		loadMigratedCompanyBusinessPlanInfoItemsError: (state, action) => {
			state.businessPlan.infoItems.loading = false;
			state.businessPlan.infoItems.error = action.payload;
			// state.businessPlan.infoItems.data = initialState.businessPlan.infoItems.data;
			state.businessPlan.infoItems.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanInfoItemsSuccess: (state /*, action */) => {
			state.businessPlan.infoItems.loading = false;
			state.businessPlan.infoItems.error = false;
			// state.businessPlan.infoItems.data = action.payload;
			state.businessPlan.infoItems.isLoaded = true;
		},
		saveMigratedCompanyBusinessPlanInfoItems: state => {
			state.businessPlan.infoItems.loading = true;
			state.businessPlan.infoItems.error = null;
			state.businessPlan.infoItems.isSaved = false;
		},
		saveMigratedCompanyBusinessPlanInfoItemsError: (state, action) => {
			state.businessPlan.infoItems.loading = false;
			state.businessPlan.infoItems.error = action.payload;
			state.businessPlan.infoItems.isSaved = true;
		},
		saveMigratedCompanyBusinessPlanInfoItemsSuccess: state => {
			state.businessPlan.infoItems.loading = false;
			state.businessPlan.infoItems.error = false;
			state.businessPlan.infoItems.isSaved = true;
		},
		loadMigratedCompanyBusinessPlanTeamMembers: state => {
			state.businessPlan.teamMembers.loading = true;
			state.businessPlan.teamMembers.error = null;
			state.businessPlan.teamMembers.isLoaded = false;
		},
		loadMigratedCompanyBusinessPlanTeamMembersError: (state, action) => {
			state.businessPlan.teamMembers.loading = false;
			state.businessPlan.teamMembers.error = action.payload;
			// state.businessPlan.teamMembers.data = initialState.businessPlan.teamMembers.data;
			state.businessPlan.teamMembers.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanTeamMembersSuccess: (state /*, action */) => {
			state.businessPlan.teamMembers.loading = false;
			state.businessPlan.teamMembers.error = false;
			// state.businessPlan.teamMembers.data = action.payload;
			state.businessPlan.teamMembers.isLoaded = true;
		},
		saveMigratedCompanyBusinessPlanTeamMembers: state => {
			state.businessPlan.teamMembers.loading = true;
			state.businessPlan.teamMembers.error = null;
			state.businessPlan.teamMembers.isSaved = false;
		},
		saveMigratedCompanyBusinessPlanTeamMembersError: (state, action) => {
			state.businessPlan.teamMembers.loading = false;
			state.businessPlan.teamMembers.error = action.payload;
			state.businessPlan.teamMembers.isSaved = true;
		},
		saveMigratedCompanyBusinessPlanTeamMembersSuccess: state => {
			state.businessPlan.teamMembers.loading = false;
			state.businessPlan.teamMembers.error = false;
			state.businessPlan.teamMembers.isSaved = true;
		},
		loadMigratedCompanyBusinessPlanTemplates: state => {
			state.businessPlan.templates.loading = true;
			state.businessPlan.templates.error = null;
			state.businessPlan.templates.isLoaded = false;
		},
		loadMigratedCompanyBusinessPlanTemplatesError: (state, action) => {
			state.businessPlan.templates.loading = false;
			state.businessPlan.templates.error = action.payload;
			// state.businessPlan.templates.data = initialState.businessPlan.templates.data;
			state.businessPlan.templates.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanTemplatesSuccess: (state /*, action */) => {
			state.businessPlan.templates.loading = false;
			state.businessPlan.templates.error = false;
			// state.businessPlan.templates.data = action.payload;
			state.businessPlan.templates.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanEconomicalData: state => {
			state.businessPlan.economicalData.loading = true;
			state.businessPlan.economicalData.error = null;
			state.businessPlan.economicalData.isLoaded = false;
		},
		loadMigratedCompanyBusinessPlanEconomicalDataError: (state, action) => {
			state.businessPlan.economicalData.loading = false;
			state.businessPlan.economicalData.error = action.payload;
			// state.businessPlan.economicalData.data = initialState.businessPlan.economicalData.data;
			state.businessPlan.economicalData.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanEconomicalDataSuccess: (state /*, action */) => {
			state.businessPlan.economicalData.loading = false;
			state.businessPlan.economicalData.error = false;
			// state.businessPlan.economicalData.data = action.payload;
			state.businessPlan.economicalData.isLoaded = true;
		},
		saveMigratedCompanyBusinessPlanEconomicalData: state => {
			state.businessPlan.economicalData.loading = true;
			state.businessPlan.economicalData.error = null;
			state.businessPlan.economicalData.isSaved = false;
		},
		saveMigratedCompanyBusinessPlanEconomicalDataError: (state, action) => {
			state.businessPlan.economicalData.loading = false;
			state.businessPlan.economicalData.error = action.payload;
			state.businessPlan.economicalData.isSaved = true;
		},
		saveMigratedCompanyBusinessPlanEconomicalDataSuccess: state => {
			state.businessPlan.economicalData.loading = false;
			state.businessPlan.economicalData.error = false;
			state.businessPlan.economicalData.isSaved = true;
		},
		loadMigratedCompanyBusinessPlanAttachments: state => {
			state.businessPlan.attachments.loading = true;
			state.businessPlan.attachments.error = null;
			state.businessPlan.attachments.isLoaded = false;
		},
		loadMigratedCompanyBusinessPlanAttachmentsError: (state, action) => {
			state.businessPlan.attachments.loading = false;
			state.businessPlan.attachments.error = action.payload;
			// state.businessPlan.attachments.data = initialState.businessPlan.attachments.data;
			state.businessPlan.attachments.isLoaded = true;
		},
		loadMigratedCompanyBusinessPlanAttachmentsSuccess: (state /*, action */) => {
			state.businessPlan.attachments.loading = false;
			state.businessPlan.attachments.error = false;
			// state.businessPlan.attachments.data = action.payload;
			state.businessPlan.attachments.isLoaded = true;
		},
		saveMigratedCompanyBusinessPlanAttachments: state => {
			state.businessPlan.attachments.loading = true;
			state.businessPlan.attachments.error = null;
			state.businessPlan.attachments.isSaved = false;
		},
		saveMigratedCompanyBusinessPlanAttachmentsError: (state, action) => {
			state.businessPlan.attachments.loading = false;
			state.businessPlan.attachments.error = action.payload;
			state.businessPlan.attachments.isSaved = true;
		},
		saveMigratedCompanyBusinessPlanAttachmentsSuccess: state => {
			state.businessPlan.attachments.loading = false;
			state.businessPlan.attachments.error = false;
			state.businessPlan.attachments.isSaved = true;
		},
		// financialplan
		loadMigratedCompanyFinancialPlan: state => {
			state.financialPlan.loading = true;
			state.financialPlan.error = null;
			state.financialPlan.isLoaded = false;
		},
		loadMigratedCompanyFinancialPlanError: (state, action) => {
			state.financialPlan.loading = false;
			state.financialPlan.error = action.payload;
			// state.financialPlan.financialPlan = initialState.financialPlan;
			state.financialPlan.isLoaded = true;
		},
		loadMigratedCompanyFinancialPlanSuccess: (state /*, action */) => {
			state.financialPlan.loading = false;
			state.financialPlan.error = false;
			// state.financialPlan.financialPlan = action.payload;
			state.financialPlan.isLoaded = true;
		},
		saveMigratedCompanyFinancialPlan: state => {
			state.financialPlan.loading = true;
			state.financialPlan.error = null;
			state.financialPlan.isSaved = false;
		},
		saveMigratedCompanyFinancialPlanError: (state, action) => {
			state.financialPlan.loading = false;
			state.financialPlan.error = action.payload;
			state.financialPlan.isSaved = true;
		},
		saveMigratedCompanyFinancialPlanSuccess: state => {
			state.financialPlan.loading = false;
			state.financialPlan.error = false;
			state.financialPlan.isSaved = true;
		},
		loadMigratedCompanyFinancialPlanPublished: state => {
			state.financialPlan.loading = true;
			state.financialPlan.error = false;
			// state.financialPlan.publishedFinancialPlan = null;
			state.financialPlan.isLoaded = false;
		},
		loadMigratedCompanyFinancialPlanPublishedSuccess: (state /*, action*/) => {
			state.financialPlan.loading = false;
			state.financialPlan.error = false;
			// state.financialPlan.publishedFinancialPlan = action.payload;
			state.financialPlan.isLoaded = true;
		},
		loadMigratedCompanyFinancialInstruments: state => {
			state.financialPlan.instruments.loading = true;
			state.financialPlan.instruments.error = null;
			state.financialPlan.instruments.isLoaded = false;
		},
		loadMigratedCompanyFinancialInstrumentsError: (state, action) => {
			state.financialPlan.instruments.loading = false;
			state.financialPlan.instruments.error = action.payload;
			// state.financialPlan.instruments.list = initialState.financialPlan.instruments.list;
			state.financialPlan.instruments.isLoaded = true;
		},
		loadMigratedCompanyFinancialInstrumentsSuccess: (state /*, action */) => {
			state.financialPlan.instruments.loading = false;
			state.financialPlan.instruments.error = null;
			// state.financialPlan.instruments.list = action.payload;
			state.financialPlan.instruments.isLoaded = true;
		},
		saveMigratedCompanyFinancialInstruments: state => {
			state.financialPlan.instruments.loading = true;
			state.financialPlan.instruments.error = null;
			state.financialPlan.instruments.isSaved = false;
		},
		saveMigratedCompanyFinancialInstrumentsError: (state, action) => {
			state.financialPlan.instruments.loading = false;
			state.financialPlan.instruments.error = action.payload;
			state.financialPlan.instruments.isSaved = true;
		},
		saveMigratedCompanyFinancialInstrumentsSuccess: state => {
			state.financialPlan.instruments.loading = false;
			state.financialPlan.instruments.error = false;
			state.financialPlan.instruments.isSaved = true;
		},
		// kanban
		loadMigratedCompanyKanbanSuccess: state => {
			state.kanban.isLoaded = true;
		},
		loadMigratedCompanyTimeline: state => {
			state.kanban.timeline.loading = true;
			state.kanban.timeline.error = null;
			state.kanban.timeline.isLoaded = false;
		},
		loadMigratedCompanyTimelineError: (state, action) => {
			state.kanban.timeline.loading = false;
			state.kanban.timeline.error = action.payload;
			// state.kanban.timeline.data = initialState.kanban.timeline.data;
			state.kanban.timeline.isLoaded = true;
		},
		loadMigratedCompanyTimelineSuccess: (state /*, action */) => {
			state.kanban.timeline.loading = false;
			state.kanban.timeline.error = false;
			// state.kanban.timeline.data = action.payload;
			state.kanban.timeline.isLoaded = true;
		},
		saveMigratedCompanyTimeline: state => {
			state.kanban.timeline.loading = true;
			state.kanban.timeline.error = null;
			state.kanban.timeline.isSaved = false;
		},
		saveMigratedCompanyTimelineError: (state, action) => {
			state.kanban.timeline.loading = false;
			state.kanban.timeline.error = action.payload;
			state.kanban.timeline.isSaved = true;
		},
		saveMigratedCompanyTimelineSuccess: state => {
			state.kanban.timeline.loading = false;
			state.kanban.timeline.error = false;
			state.kanban.timeline.isSaved = true;
		},
		loadMigratedCompanyProjects: state => {
			state.kanban.projects.loading = true;
			state.kanban.projects.error = null;
			state.kanban.projects.isLoaded = false;
		},
		loadMigratedCompanyProjectsError: (state, action) => {
			state.kanban.projects.loading = false;
			state.kanban.projects.error = action.payload;
			state.kanban.projects.isLoaded = true;
		},
		loadMigratedCompanyProjectsSuccess: state => {
			state.kanban.projects.loading = false;
			state.kanban.projects.error = false;
			state.kanban.projects.isLoaded = true;
		},
		saveMigratedCompanyProjects: state => {
			state.kanban.projects.loading = true;
			state.kanban.projects.error = null;
			state.kanban.projects.isSaved = false;
		},
		saveMigratedCompanyProjectsError: (state, action) => {
			state.kanban.projects.loading = false;
			state.kanban.projects.error = action.payload;
			state.kanban.projects.isSaved = true;
		},
		saveMigratedCompanyProjectsSuccess: state => {
			state.kanban.projects.loading = false;
			state.kanban.projects.error = false;
			state.kanban.projects.isSaved = true;
		},
	},
});

export const { reducer } = slice;

export const { setIsLoaded, setSelected, setTimelineId } = slice.actions;

// reset
export const resetMigratedCompanyData = () => async dispatch => {
	try {
		dispatch(slice.actions.resetData());
	} catch (error) {
		toast.error(<FormattedMessage id='project.reset.failed' />);
	}
};

// company data
export const getMigratedCompanyData = companyId => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyData());

		const company = await migrationApi.getCompanyData(companyId);

		// if (!company.companyId) throw new Error('No business id');

		dispatch(slice.actions.loadMigratedCompanyDataSuccess(company));

		return true;
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyDataError(err));

		return false;
	}
};

// scenario or profile data
export const getMigratedCompanyScenario = companyId => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyScenario());

		const scenario = await migrationApi.getScenario(companyId);

		dispatch(slice.actions.loadMigratedCompanyScenarioSuccess(/* scenario */));
		dispatch(loadScenariosSuccess(scenario));

		return true;
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyScenarioError(err));
		dispatch(loadScenariosError(err));

		return false;
	}
};

export const saveMigratedCompanyScenario = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.saveMigratedCompanyScenario());

		const state = getState();
		const scenarios = state.scenario.scenarios;

		await migrationApi.saveScenario(companyId, scenarios);

		dispatch(slice.actions.saveMigratedCompanyScenarioSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyScenarioError(err));

		return false;
	}
};

// business plan
export const getMigratedCompanyBusinessPlan = companyId => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlan());
		await dispatch(loadMain(companyId));
		await dispatch(loadInfoItems(companyId));
		await dispatch(loadTeamMembers(companyId));
		await dispatch(loadTemplates(companyId));
		await dispatch(loadEconomicalData(companyId));
		await dispatch(loadAttachments(companyId));
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanSuccess());
		return true;
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanError(err));
		return false;
	}
};

const loadMain = companyId => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanMain());

		const main = await migrationApi.getMain(companyId);

		dispatch(slice.actions.loadMigratedCompanyBusinessPlanMainSuccess(/* main */));
		dispatch(loadMainSuccess(main));
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanMainError(err));
		dispatch(loadMainError(err));
	}
};

const loadInfoItems = companyId => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanInfoItems());

		const infoItems = await migrationApi.getInfoItems(companyId);

		dispatch(slice.actions.loadMigratedCompanyBusinessPlanInfoItemsSuccess(infoItems));
		dispatch(loadInfoItemsSuccess(infoItems));
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanInfoItemsError(err));
		dispatch(loadInfoItemsError(err));
	}
};

const loadTeamMembers = companyId => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanTeamMembers());

		const teamMembers = await migrationApi.getTeamMembers(companyId);

		dispatch(slice.actions.loadMigratedCompanyBusinessPlanTeamMembersSuccess(teamMembers));
		dispatch(loadTeamMembersSuccess(teamMembers));
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanTeamMembersError(err));
		dispatch(loadTeamMembersError(err));
	}
};

const loadTemplates = () => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanTemplates());

		const templates = await migrationApi.getTemplates();

		dispatch(slice.actions.loadMigratedCompanyBusinessPlanTemplatesSuccess(templates));
		dispatch(loadTemplatesSuccess(templates));
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanTemplatesError(err));
		dispatch(loadTemplatesError(err));
	}
};

const loadEconomicalData = companyId => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanEconomicalData());

		const economicalData = await migrationApi.getEconomicalData(companyId);

		dispatch(slice.actions.loadMigratedCompanyBusinessPlanEconomicalDataSuccess(economicalData));
		dispatch(loadEconomicalDataSuccess(economicalData));
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanEconomicalDataError(err));
		dispatch(economicalDataError(err));
	}
};

const loadAttachments = companyId => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanAttachments());

		const attachments = await migrationApi.getAttachments(companyId);

		dispatch(slice.actions.loadMigratedCompanyBusinessPlanAttachmentsSuccess(attachments));
		dispatch(loadAttachmentsSuccess(attachments));
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyBusinessPlanAttachmentsError(err));
		dispatch(loadAttachmentsError(err));
	}
};

export const saveMigratedCompanyBusinessPlan = companyId => async dispatch => {
	try {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlan());
		await dispatch(saveMain(companyId));
		await dispatch(saveInfoItems(companyId));
		await dispatch(saveTeamMembers(companyId));
		await dispatch(saveEconomicalData(companyId));
		await dispatch(saveAttachments(companyId));
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanSuccess());
		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanError(err));
		return false;
	}
};

export const saveMain = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanMain());

		const state = getState();
		const main = state.businessPlan.main;
		const data = main.data;

		await migrationApi.saveMain(companyId, data);

		dispatch(slice.actions.saveMigratedCompanyBusinessPlanMainSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanMainError(err));

		return false;
	}
};

export const saveInfoItems = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanInfoItems());

		const state = getState();
		const infoItems = state.businessPlan.infoItems;
		const data = infoItems.data;

		for await (const item of data) {
			await migrationApi.saveInfoItem(companyId, item);
		}

		dispatch(slice.actions.saveMigratedCompanyBusinessPlanInfoItemsSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanInfoItemsError(err));

		return false;
	}
};

export const saveTeamMembers = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanTeamMembers());

		const state = getState();
		const teamMembers = state.businessPlan.teamMembers;
		const data = teamMembers.data;

		for await (const item of data) {
			await migrationApi.saveTeamMember(companyId, item);
		}

		dispatch(slice.actions.saveMigratedCompanyBusinessPlanTeamMembersSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanTeamMembersError(err));

		return false;
	}
};

export const saveEconomicalData = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanEconomicalData());

		const state = getState();
		const economicalData = state.businessPlan.economicalData;
		const data = economicalData.data;

		for await (const item of data) {
			await migrationApi.saveEconomicalData(companyId, item);
		}

		dispatch(slice.actions.saveMigratedCompanyBusinessPlanEconomicalDataSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanEconomicalDataError(err));

		return false;
	}
};

export const saveAttachments = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanAttachments());

		const state = getState();
		const attachments = state.businessPlan.attachments;
		const data = attachments.data;

		for await (const item of data) {
			await migrationApi.saveAttachment(companyId, item);
		}

		dispatch(slice.actions.saveMigratedCompanyBusinessPlanAttachmentsSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyBusinessPlanAttachmentsError(err));

		return true; // return false; skipping attachment errors for now
	}
};

// financial plan
export const getMigratedCompanyFinancialPlan = (companyId, edit) => async dispatch => {
	try {
		dispatch(slice.actions.loadMigratedCompanyFinancialPlan());

		let financialPlanMock = Object.assign({}, mockFinancialPlan);
		financialPlanMock.created = new Date();

		const financialPlan = edit
			? await migrationApi.getFinancialPlan(companyId)
			: await migrationApi.getFinancialPlanPublished(companyId);

		let isMock = !financialPlan;

		if (isMock) {
			dispatch(slice.actions.loadMigratedCompanyFinancialPlanSuccess());
			// dispatch(loadFinancialPlanSuccess(mockTimeline));

			return;
		}

		const decodedfinancialPlan = {
			...financialPlan,
			base: umlautsDecode(financialPlan.base),
			expertComments: umlautsDecode(financialPlan.expertComments),
			followup: umlautsDecode(financialPlan.followup),
			internalInformation: umlautsDecode(financialPlan.internalInformation),
			notice: umlautsDecode(financialPlan.notice),
			precondition: umlautsDecode(financialPlan.precondition),
		};

		dispatch(slice.actions.loadMigratedCompanyFinancialPlanSuccess(/* decodedfinancialPlan */));
		dispatch(loadFinancialPlanSuccess(decodedfinancialPlan));

		if (edit) {
			dispatch(slice.actions.loadMigratedCompanyFinancialPlanPublished());

			await dispatch(getMigratedCompanyFinancialPlanInstruments(companyId));
			const publishedFinancialPlan = await migrationApi.getFinancialPlanPublished(companyId); // get published financial plan for previews
			await dispatch(loadPublishedFinancialPlanSuccess(publishedFinancialPlan));

			dispatch(slice.actions.loadMigratedCompanyFinancialPlanPublishedSuccess(/* publishedFinancialPlan */));
		}

		return true;
	} catch (err) {
		if (err?.response?.status === 404 && !edit) {
			dispatch(slice.actions.loadMigratedCompanyFinancialPlanSuccess(/* mockTimeline */));
			// dispatch(loadFinancialPlanSuccess());
		}
		dispatch(slice.actions.loadMigratedCompanyFinancialPlanError(err));

		return null;
	}
};

export const saveMigratedCompanyFinancialPlan = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.saveMigratedCompanyFinancialPlan());

		const state = getState();
		const financialPlan = state.financialPlan.financialPlan;
		const publishedFinancialPlan = state.financialPlan.financialPlan;

		await migrationApi.saveFinancialPlanData(companyId, financialPlan);
		await migrationApi.publishFinancialPlanData(companyId, publishedFinancialPlan);

		dispatch(slice.actions.saveMigratedCompanyFinancialPlanSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyFinancialPlanError(err));

		return false;
	}
};

// financial plan instruments
export const getMigratedCompanyFinancialPlanInstruments = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.loadMigratedCompanyFinancialInstruments());

		let state = getState();

		if (state.instruments.loading) {
			let count = 0;

			await delayWhile(500, () => {
				count++;
				state = getState();

				return state.instruments.loading && count < 10; // stop looping while
			});
		}

		const instruments = await migrationApi.getFinancialPlanInstruments(companyId);

		let mapped = _.map(instruments, item => (item.points = 0));

		const companyInstruments = state.instruments?.instruments ?? [];

		mapped = _.map(instruments, instrument => {
			const companyInstrument = companyInstruments.find(item => instrument.id === item.id);

			if (companyInstrument) return companyInstrument;

			return instrument;
		});

		mapped = _.orderBy(mapped, ['points', 'sortOrder'], ['desc', 'desc']);

		dispatch(slice.actions.loadMigratedCompanyFinancialInstrumentsSuccess(/* mapped */));
		dispatch(loadFinancialPlanInstrumentsSuccess(mapped));
	} catch (error) {
		dispatch(slice.actions.loadMigratedCompanyFinancialInstrumentsError(error));
	}
};

// projects
export const getMigratedCompanyProjects = companyId => async (dispatch, getState) => {
	try {
		const state = getState();
		const migratedCompany = state.migratedCompany;
		const timelineId = migratedCompany.timelineId;

		const changeTimelineId = id => {
			const splittedId = id.split('_');

			return timelineId + '_' + splittedId[1];
		};

		dispatch(slice.actions.loadMigratedCompanyTimeline());
		dispatch(slice.actions.loadMigratedCompanyProjects());

		const promiseTimeline = migrationApi.getTimeline(companyId);
		const promiseProjects = migrationApi.getProjects(companyId);
		const timeline = await promiseTimeline;
		const projects = await promiseProjects;

		const decodedProjects = projects?.map(project => ({ ...project, projectSummary: umlautsDecode(project.projectSummary) }));
		const mutatedTimeline = getStartDateArr(!decodedProjects.length ? [] : decodedProjects, timeline[0]); // mutates the response object

		for (const project of mutatedTimeline.projects) {
			project.projectId = changeTimelineId(project.projectId);
			project.rowKey = changeTimelineId(project.rowKey);
			project.timelineId = timelineId;
		}

		mutatedTimeline.timeline.timelineId = timelineId;
		mutatedTimeline.timeline.rowKey = timelineId;

		// TODO: (backend!) loop --> for each project --> task request + cost request + attachment request --> change ids

		dispatch(slice.actions.loadMigratedCompanyTimelineSuccess(/* mutatedTimeline.timeline */));
		dispatch(slice.actions.loadMigratedCompanyProjectsSuccess(/* { projects: mutatedTimeline.projects, mock } */));
		dispatch(getTimelineSuccess(mutatedTimeline.timeline));
		dispatch(getProjectsSuccess({ projects: mutatedTimeline.projects, mock: null }));
		dispatch(slice.actions.loadMigratedCompanyKanbanSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.loadMigratedCompanyTimelineError(err));
		dispatch(slice.actions.loadMigratedCompanyProjectsError(err));
		dispatch(getTimelineError(err));
		dispatch(getProjectsError(err));
		dispatch(slice.actions.loadMigratedCompanyKanbanSuccess());

		return false;
	}
};

export const saveMigratedCompanyProjects = companyId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.saveMigratedCompanyProjects());

		const state = getState();
		const kanban = state.kanban;
		const timeline = kanban.timeline;
		const projects = kanban.projects;

		for await (const projectItem of projects.data) {
			const project = await handleProjectObject(timeline, projectItem, true);
			const draft = isProjectDraft(projects.data, projectItem.projectId);
			await migrationApi.saveProjectWithAttachments(companyId, project, draft);
		}

		dispatch(slice.actions.saveMigratedCompanyProjectsSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveMigratedCompanyProjectsError(err));

		return false;
	}
};

export default slice;
