import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = props => {
    const value = props.value;
    const color = value === 100 ? 'success' : !value || value < 50 ? 'error' : value < 100 && 'warning';

    return (
        <Stack direction='row' spacing={1} alignItems='center' sx={{ marginLeft: '2rem', '& p': { color: `${color}.main` } }} >
            <LinearProgress variant='determinate' color={color} sx={{ minWidth: { xs: '5rem', sm: '10rem' } }} {...props} />
            <Typography>{`${value}%`}</Typography>
        </Stack>
    );
};

export default ProgressBar;