import io from 'socket.io-client';

import { addMessage } from 'containers/Chat/chatSlice';
import { getAccessToken } from 'utils/auth/token';

import env from 'config/environment';

const initSocket = (dispatch, company) => {
	const socket = io(env.SOCKET_URL, {
		origins: '*:*',
		transportOptions: {
			polling: {
				extraHeaders: {
					Authorization: getAccessToken(),
					companyId: company?.CompanyId,
					companyName: company?.CompanyName,
				},
			},
		},
	});

	socket.on('private', data => {
		dispatch(addMessage(data));
	});
};

export default initSocket;
