import {
	Box,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Radio,
	Skeleton,
	Typography,
} from '@mui/material';
import { isEmpty, isNil, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { RfpRequestItem } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import { useFetchRfpRequestQuery } from 'containers/Marketplace/rfpRequestApi';
import FormatCurrency from '../../../components/Formatters/FormatCurrency';
import TextCollapse from '../../../components/TextCollapse/TextCollapse';

type Props = {
	requestId?: string | null;
	label?: string | null;
	helperText?: string | null;
	value?: Partial<RfpRequestItem> | string | null;
	onChange?: (value: Partial<RfpRequestItem> | null) => void;
} & Omit<FormControlProps, 'onChange'>;

const RfpProposalItemRequestItemInput = React.forwardRef<any, Props>(
	({ requestId, label, helperText, value, onChange, ...otherProps }, ref) => {
		const companyId = getCompanyId() ?? getCompany()?.CompanyId;

		const { data: rfpRequest, isLoading } = useFetchRfpRequestQuery(
			{ companyId, requestId: requestId, published: true },
			{ skip: isNil(companyId) || isNil(requestId) }
		);

		return (
			<FormControl {...otherProps} ref={ref}>
				{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
				{isLoading ? (
					<>
						<Skeleton height={60} />
						<Skeleton height={60} />
					</>
				) : (
					<List>
						{isEmpty(rfpRequest?.rfpRequestItems) && (
							<Typography variant='lightText'>
								<FormattedMessage id='rfpRequestItems.empty' />
							</Typography>
						)}
						{map(rfpRequest?.rfpRequestItems, rfpRequestItem => (
							<ListItem key={rfpRequestItem.rfpRequestItemId} disablePadding>
								<ListItemButton
									onClick={() => {
										onChange?.(rfpRequestItem);
									}}
								>
									<ListItemIcon>
										<Radio
											checked={
												typeof value === 'string'
													? rfpRequestItem.rfpRequestItemId === value
													: rfpRequestItem.rfpRequestItemId === value?.rfpRequestItemId
											}
											disableRipple
										/>
									</ListItemIcon>
									<ListItemText
										secondaryTypographyProps={{ component: 'div' }}
										primary={rfpRequestItem.requestItemTitle}
										secondary={
											<Box sx={{ maxHeight: '25rem', overflow: 'auto', lineHeight: 'normal' }}>
												<Typography variant='subtitle2' component='span' sx={{ mr: 1 }}>
													{rfpRequestItem.requestItemCategory}
												</Typography>
												<TextCollapse
													expanded={
														typeof value === 'string'
															? rfpRequestItem.rfpRequestItemId === value
															: rfpRequestItem.rfpRequestItemId === value?.rfpRequestItemId
													}
													length={500}
													text={rfpRequestItem?.requestItemDescription}
												/>
												{rfpRequestItem.budget && (
													<Typography
														component='span'
														sx={{ display: 'block', mt: '.5rem', fontSize: '.875rem' }}
													>
														<Typography variant='subtitle2' component='span' sx={{ mr: '0.5rem' }}>
															<FormattedMessage id='rfpProposal.projectBudget.label' />
														</Typography>
														<FormatCurrency value={rfpRequestItem.budget} />
													</Typography>
												)}
											</Box>
										}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				)}
				{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
			</FormControl>
		);
	}
);

export default RfpProposalItemRequestItemInput;
