import React from 'react';

import { Box, Divider, List, Typography } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { RfpProposal } from 'types/dataTypes';
import RequestProposalListItem from './RequestProposalListItem';

interface Props {
	proposals: RfpProposal[] | null | undefined;
}

const RequestProposalList: React.FC<Props> = ({ proposals }) => {
	if (!proposals || proposals.length === 0) return null;

	return (
		<>
			<Box>
				<Typography variant='h4'>
					<FormattedMessage id='rfp.request.proposals.title' />
				</Typography>
				<List>
					{proposals?.map(proposal => (
						<RequestProposalListItem key={proposal.rfpProposalId} proposalItem={proposal} />
					))}
				</List>
			</Box>
			<Divider />
		</>
	);
};

export default RequestProposalList;
