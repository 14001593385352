import { isEmpty, map } from 'lodash';

import { LinkedIn } from '@mui/icons-material';
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';

import TextCollapse from 'components/TextCollapse/TextCollapse';
import { RfpProfileTeamMember } from 'types/dataTypes';
import { getInitials } from 'utils/helpers/getInitials';

import { FormattedMessage } from 'react-intl';
import BlobDownloader from '../../attachment/BlobDownloader';

type TeamMemberListItemProps = {
	member: RfpProfileTeamMember | null | undefined;
};
const TeamMemberListItem: React.FC<TeamMemberListItemProps> = ({ member }) => {
	return (
		<>
			<ListItem>
				<ListItemAvatar>
					<BlobDownloader url={member?.avatar}>
						{src => (
							<Avatar alt='avatar' src={src} variant='portrait1'>
								{getInitials(`${member?.firstname} ${member?.lastname}`)}
							</Avatar>
						)}
					</BlobDownloader>
				</ListItemAvatar>
				<ListItemText
					secondaryTypographyProps={{ component: 'div' }}
					primary={
						<Stack direction='row' sx={{ width: '100%' }} alignItems='center'>
							<Typography flexGrow={1}>
								{member?.firstname} {member?.lastname}
							</Typography>
							{member?.linkedInUrl ? (
								// @ts-ignore do not check if null url
								<IconButton aria-label='Linkedin.com' onClick={() => window.open(member?.linkedInUrl, '_blank')}>
									<LinkedIn />
								</IconButton>
							) : null}
						</Stack>
					}
					secondary={
						<Stack direction='row'>
							<Typography component='div' variant='subtitle2' sx={{ mr: 2, width: '5rem' }}>
								{member?.position}
							</Typography>
							<Box flexGrow={1} sx={{ width: '100%' }}>
								<TextCollapse text={member?.description} />
							</Box>
						</Stack>
					}
				/>
			</ListItem>
		</>
	);
};

type Props = {
	team: RfpProfileTeamMember[] | null | undefined;
};
const ReadProfileTeamList: React.FC<Props> = ({ team }) => {
	return (
		<>
			<List>
				{isEmpty(team) && (
					<Typography variant='lightText' sx={{ mx: '1rem' }}>
						<FormattedMessage id='rfp.profile.team.empty' />
					</Typography>
				)}
				{map(team, (member, index) => (
					<TeamMemberListItem key={index} member={member} />
				))}
			</List>
		</>
	);
};

export default ReadProfileTeamList;
