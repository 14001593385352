import * as React from 'react';
import { IconButton, Drawer, DrawerProps } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import { closeDrawer, getDrawerData, isDrawerOpen } from 'containers/Marketplace/drawerSlice';
import { openDialog } from 'containers/Marketplace/dialogSlice';

import UpsertRfpRequestForm from 'containers/Marketplace/request/UpsertRfpRequestForm';

type Props = DrawerProps & {};

const UpsertRfpRequestDrawer: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const drawerOpen = useSelector(isDrawerOpen('UpsertRfpRequestDrawer'));
	const drawerData = useSelector(getDrawerData('UpsertRfpRequestDrawer'));

	const [isDirty, setIsDirty] = React.useState(false);

	const handleDrawerClose = React.useCallback(() => {
		if (isDirty) {
			dispatch(
				openDialog({
					name: 'ConfirmCloseDialog',
					data: {
						onSubmit: () => {
							dispatch(closeDrawer({ name: 'UpsertRfpRequestDrawer' }));
						},
					},
				})
			);
		} else {
			dispatch(closeDrawer({ name: 'UpsertRfpRequestDrawer' }));
		}
	}, [dispatch, isDirty]);

	const handleAfterPublish = React.useCallback(() => {
		dispatch(closeDrawer({ name: 'UpsertRfpRequestDrawer' }));
	}, [dispatch]);

	const handleAfterRemove = React.useCallback(() => {
		dispatch(closeDrawer({ name: 'UpsertRfpRequestDrawer' }));
	}, [dispatch]);

	const handleDirtyChange = React.useCallback((nextIsDirty: boolean) => {
		setIsDirty(nextIsDirty);
	}, []);

	return (
		<Drawer
			{...otherProps}
			anchor='right'
			open={drawerOpen}
			onClose={handleDrawerClose}
			PaperProps={{
				sx: { width: { xs: '100%', md: '80%', lg: '60%' } },
			}}
		>
			<IconButton sx={{ position: 'absolute', right: 0, top: 0 }} onClick={handleDrawerClose}>
				<CloseIcon />
			</IconButton>
			<UpsertRfpRequestForm
				published={drawerData?.published}
				requestId={drawerData?.requestId}
				onAfterPublish={handleAfterPublish}
				onAfterRemove={handleAfterRemove}
				onDirtyChange={handleDirtyChange}
			/>
		</Drawer>
	);
};

export default UpsertRfpRequestDrawer;
