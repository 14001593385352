import { Box } from '@mui/material';

import { styled } from '@mui/styles';

import BoxContainer from 'components/Containers/BoxContainer';
import KanbanColumn from 'components/Kanban/KanbanColumn';
import AddProjectCost from '../../Costs/AddProjectCost/AddProjectCost';
import ProjectCostCard from '../../Costs/ProjectCostColumn/ProjectCostCard/ProjectCostCard';

const ErrorNoTasks = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	textAlign: 'center',
	fontStyle: 'italic',
	color: theme.palette.secondary.grey500,
	fontSize: '1rem',
}));

const ProjectTaskCostColumn = ({ project, task, costs }) => {
	if (!costs) return null;

	return (
		<BoxContainer>
			<KanbanColumn
				transparent
				name='Kulut'
				width='100%'
				footer={
					task ? (
						<AddProjectCost
							project={project}
							cost={{
								projectTaskId: task.projectTaskId,
								startDate: project?.startDate,
								endDate: project?.endDate,
							}}
						/>
					) : null
				}
			>
				<Box
					sx={{
						flexGrow: 1,
						minHeight: 80,
						overflowY: 'auto',
					}}
				>
					{costs?.length === 0 ? (
						<ErrorNoTasks>Ei kuluja</ErrorNoTasks>
					) : (
						costs?.map((cost, index) => (
							<ProjectCostCard
								cost={cost}
								key={cost.id}
								index={index}
								costId={cost.id}
								taskId={task.projectTaskId}
								taskName={task.projectTaskName}
								isTaskPage={true}
							/>
						))
					)}
				</Box>
			</KanbanColumn>
		</BoxContainer>
	);
};

export default ProjectTaskCostColumn;
