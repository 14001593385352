import { Box, Skeleton } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { FormattedMessage } from 'react-intl';

import WidgetContainer from 'components/Containers/WidgetContainer';
import AddProjectSupplementary from 'containers/Projects/Project/ProjectSupplementaryList/AddProjectSupplementary/AddProjectSupplementary';
import ProjectSupplementaryListItem from 'containers/Projects/Project/ProjectSupplementaryList/ProjectSupplemenaryListItem';
import { useFetchProjectSupplementariesQuery } from 'containers/Projects/projectSupplementaryApi';
import { getCompanyId } from 'utils/auth/company';

const ProjectSupplementaryTitle = ({ hideTitle }) => {
	if (hideTitle) return null;
	return <FormattedMessage id='project.supplementaries' />;
};

const ProjectSupplementaryList = ({ project, padding = 0, hideTitle = false }) => {
	const companyId = getCompanyId();
	const projectId = project?.projectId;

	const { data: supplementaries, isLoading } = useFetchProjectSupplementariesQuery(
		{ companyId, projectId },
		{ skip: isNil(companyId) || isNil(projectId) }
	);

	return (
		<WidgetContainer padding={padding} title={<ProjectSupplementaryTitle hideTitle={hideTitle} />}>
			{isEmpty(supplementaries) && (
				<Box sx={{ fontSize: '0.9rem', color: 'rgba(0,0,0, 0.6)' }}>
					<FormattedMessage id='project.supplementaries.empty' />
				</Box>
			)}
			{(isLoading ? Array.from(new Array(3)) : supplementaries)?.map((supplementary, index) => (
				<Box
					key={supplementary?.projectSupplementaryInformationId ?? index}
					sx={{
						borderBottom: theme => (supplementaries?.length === index + 1 ? 'none' : `1px solid ${theme.palette.divider}`),
						marginBottom: '1rem',
					}}
				>
					{supplementary ? (
						<ProjectSupplementaryListItem supplementary={supplementary} project={project} />
					) : (
						<Skeleton height={46} variant='rectangular' />
					)}
				</Box>
			))}
			<AddProjectSupplementary project={project} />
		</WidgetContainer>
	);
};

export default ProjectSupplementaryList;
