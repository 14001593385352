import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const MissingInfoText = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
}));

export default MissingInfoText;
