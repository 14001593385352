import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    contentWrapper: {
        flex: 1,
    },
    contentContainer: {
        height: '100%',
    },
    title: {
        fontSize: '1.6rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
    },
    box: {
        marginTop: theme.spacing(4),
    },
    li: {
        paddingLeft: theme.spacing(1),
    },
    liText: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1rem',
        },
    },
    grants: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        paddingBottom: theme.spacing(4),
    },
}));

const Slide2 = ({ maxHeight }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container direction='column' alignItems='center' className={classes.root} style={{ maxHeight }}>
            <Grid item className={classes.contentWrapper}>
                <Grid container direction='column' justifyContent='center' className={classes.contentContainer}>
                    <Grid item>
                        <Typography className={classes.title}>Keskustelun agenda</Typography>
                        <Box textAlign='left' className={classes.box}>
                            <ul>
                                <li className={classes.li}>
                                    <Typography gutterBottom className={classes.liText}>
                                        Muutama sana Grantsista
                                    </Typography>
                                </li>
                                <li className={classes.li}>
                                    <Typography gutterBottom className={classes.liText}>
                                        Liiketoiminta ja kokemuksesi rahoituskentästä
                                    </Typography>
                                </li>
                                <li className={classes.li}>
                                    <Typography gutterBottom className={classes.liText}>
                                        Granted AI – työkaluna
                                    </Typography>
                                </li>
                                <li className={classes.li}>
                                    <Typography gutterBottom className={classes.liText}>
                                        Yhteenveto
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography className={classes.grants}>{intl.formatMessage({ id: 'common.grants' })}</Typography>
            </Grid>
        </Grid>
    );
};

export default Slide2;
