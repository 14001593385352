import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import DataGrid from 'components/DataGrid';
import Button from 'components/Button/Button';
import FormattedDate from 'components/Formatters/FormattedDate';

const ContractsTable = ({ loading, data, classes, title }) => {
    const intl = useIntl();
    const history = useHistory();

    const columns = [
        {
            field: 'partitionKey',
            headerName: intl.formatMessage({ id: 'companylist.table.id' }),
            headerClassName: 'styled-header',
            width: 150,
            disableColumnSelector: true,
            disableClickEventBubbling: true,
            renderCell: params => (
                <span className={classes.cell}>
                    <Button
                        variant='text'
                        size='small'
                        color='primary'
                        disabled={loading || !params.row.partitionKey || params.row.partitionKey === 'undefined'}
                        onClick={() => history.push(`/admin/company/${params.row.partitionKey}`)}
                    >
                        {params.row.partitionKey}
                    </Button>
                </span>
            ),
        },
        {
            field: 'userAccepted',
            headerName: intl.formatMessage({ id: 'userdetails.contracts.userAccepted' }),
            headerClassName: 'styled-header',
            width: 350,
            disableColumnSelector: true,
            disableClickEventBubbling: true,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.userAccepted}
                </span>
            ),
        },
        // {
        //     field: 'channel',
        //     headerName: intl.formatMessage({ id: 'userdetails.contracts.channel' }),
        //     headerClassName: 'styled-header',
        //     width: 200,
        //     renderCell: params => (
        //         <span className={classes.cell}>{params.row.channel}</span>
        //     ),
        // },
        // {
        //     field: 'companyDetails',
        //     disableColumnMenu: true,
        //     sortable: false,
        //     headerName: intl.formatMessage({ id: 'companylist.table.companyDetails' }),
        //     headerClassName: 'styled-header',
        //     width: 150,
        //     disableColumnSelector: true,
        //     disableClickEventBubbling: true,
        //     renderCell: params => (
        //         <Button
        //             className={classes.detailsButton}
        //             variant='outlined'
        //             size='small'
        //             color='primary'
        //             disabled={loading || !params.row.partitionKey || params.row.partitionKey === 'undefined'}
        //             onClick={() => fetchCompanyDetails(params.row.partitionKey)}
        //         >
        //             {intl.formatMessage({ id: 'companylist.table.companyDetails.link' })}
        //         </Button>
        //     ),
        // },
        {
            field: 'timestamp',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userlist.table.timestamp' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.timestamp} />
                </span>
            ),
        }
    ];

    return (
        <>
            <Typography variant='h2' className={classes.title}>{intl.formatMessage({ id: `userdetails.${title}.title` })}</Typography>
            {
                loading || !loading && data?.length !== 0
                    ? <DataGrid
                        idField='rowKey'
                        loading={loading}
                        data={data}
                        columns={columns}
                        paginationMode='client'
                    />
                    : <Typography component='p'> {intl.formatMessage({ id: `userdetails.no.${title}` })} </Typography>
            }
        </>
    );
};

export default ContractsTable;