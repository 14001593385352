import { Grid } from '@mui/material';

const ProjectPrintContentValue = ({ children, sx = {}, lastColumn = false, ...props }) => {
	if (lastColumn) {
		sx = Object.assign({}, sx, { width: '190px' });
	}

	return (
		<Grid item
			className='tdPrint'
			sx={{
				border: 'none',
				textAlign: 'left',
				'& p': {
					margin: 0,
				},
				...sx,
			}}
			{...props}
		>
			{children}
		</Grid>
	);
};

export default ProjectPrintContentValue;
