type MonthData = {
	licenses: string[];
	value: number;
	monthNumber: number;
	cumulativeValue: number;
	monthMrrChange: number;
};

type LicenseData = {
	license: Record<string, any>;
	months: Record<string, MonthData>;
};

export const generateCSV = (data: LicenseData[]) => {
	const headers = [
		'companyName',
		'companyId',
		'startDate',
		'endDate',
		'mrrContractStartDate',
		'author',
		'terminationDate',
		'type',
		//'comments', mix up everything
		'contractBillingPeriod',
		'contractBillingPeriodMonth',
		'contractPeriod',
		'contractPeriodMonth',
		'contractBillingType',
		'salesPerson',
		'payingCustomer',
		'severaLink',
		'contractFirstCheckDate',
		'importProduct',
		'importLicenseType',
		'importBillingInstalments',
		'importContractDate',
		'importChurned',
		'contractMonetaryValue',
		'contractMonthlyValue',
		'isRefundLicense',
		'isExpired',
		'isTerminated',
		'isActive',
		'features',
		'package',
		'terminateAuthor',
		'updateTime',
		'updateAuthor',
		'createAuthor',
		'createdTime',
		'mrrType',
		'partitionKey',
		'rowKey',
		'timestamp',
		'eTag',
		...Array.from({ length: 5 * 12 }, (_, i) => {
			const year = 2021 + Math.floor(i / 12);
			const month = (i % 12) + 1;
			return `${year}-${month.toString().padStart(2, '0')}`;
		}),
	];

	const rows = data.map(item => {
		const licenseFields = headers.slice(0, 39).map(header => item.license[header] || '');
		const monthFields = Array.from({ length: 5 * 12 }, (_, i) => {
			const year = 2021 + Math.floor(i / 12);
			const month = (i % 12) + 1;
			const key = `${year}-${month.toString().padStart(2, '0')}`;
			const monthData = item.months[key];

			return monthData ? monthData.value : '';
		});

		return [...licenseFields, ...monthFields];
	});

	const csvContent = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

	return blob;
};

export const parseFromLicensedata = (data: any) => {
	var combineArray = data.map((item: any) => item.licenses).flat();

	return generateCSV(combineArray);
};
