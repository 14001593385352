import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles, useTheme } from '@mui/styles';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { FiberManualRecord } from '@mui/icons-material';
import { ListItemIcon } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';

const useStyles = makeStyles(theme => ({
	root: {
		marginBottom: theme.spacing(2),
		paddingLeft: 0,
		'&>div:first-child li': {
			paddingTop: 0,
		},
	},
	listItemMain: {
		padding: '0.25rem 1rem 0.25rem 0rem',
	},
	itemText: {
		'& span': {
			fontSize: '1rem',
			fontWeight: 600,
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
	},
	itemTextNested: {
		'& span': {
			fontWeight: 400,
		},
	},
	listIcon: {
		minWidth: '2rem',
		justifyContent: 'center',
	},
}));

const NestedHeadingsList = ({ items, scrollIntoViewWithOffset, activeId, nestedSublist = false }) => {
	const classes = useStyles();
	const theme = useTheme();

	if (!items || items.length < 1) return null;

	return (
		<List
			sx={{
				paddingTop: 0,
				paddingBottom: '0.25rem',
			}}
		>
			{items.map(({ id, title, type, items }) => {
				if (!title) return null;

				return (
					<ListItem
						key={id}
						type={type}
						sx={{
							'&:first-of-type': {
								paddingTop: 0,
							},
							'&:last-of-type': {
								paddingBottom: !nestedSublist && '1rem',
							},
							m: 0,
							p: '0.25rem 1rem 0.25rem 1rem',
						}}
					>
						<ListItemText sx={{ m: 0 }} className={`${classes.itemText} ${classes.itemTextNested}`}>
							<Link
								component={RouterLink}
								to={`#${id}`}
								href={`#${id}`}
								underline='none'
								// onClick={e => {
								//     e.preventDefault();
								//     scrollIntoViewWithOffset(id, 0);
								// }}'
								sx={{
									'&:hover': {
										cursor: 'pointer',
									},
									color: id === activeId ? theme.palette.success.main : theme.palette.primary.main,
								}}
							>
								{type === 'H4' && (
									<ListItemIcon className={classes.listIcon}>
										<FiberManualRecord
											sx={{
												color: id === activeId ? theme.palette.success.main : theme.palette.primary.main,
												width: id === activeId ? 10 : 5,
												height: id === activeId ? 10 : 5,
											}}
										/>
									</ListItemIcon>
								)}
								{title}
							</Link>
							<NestedHeadingsList {...{ items, scrollIntoViewWithOffset, activeId, nestedSublist: true }} />
						</ListItemText>
					</ListItem>
				);
			})}
		</List>
	);
};

// Render headings as a list of links
const Headings = ({ surrogate = false, headings, activeId, isContentMounted }) => {
	const classes = useStyles();
	const { hash } = useLocation();

	// scrollIntoViewWithOffset function gives offset capability
	const scrollIntoViewWithOffset = (id, offset = 10) => {
		const element = document.getElementById(id);
		const elementPosition = element?.getBoundingClientRect().top;
		const offsetPosition = elementPosition ? elementPosition + window.scrollY - offset : 0;

		window.scrollTo({
			behavior: 'smooth',
			top: id === 'projectFormBase' ? 0 : offsetPosition,
		});
	};

	useEffect(() => {
		if (hash && isContentMounted) {
			const id = hash.slice(1);
			scrollIntoViewWithOffset(id, 10);
		}
	}, [isContentMounted]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!isContentMounted && surrogate) return null;

	if (!isContentMounted && !surrogate) return <Spinner size={16} mt={2} />;

	return (
		<>
			<List
				disablePadding
				className={classes.root}
				sx={{
					display: {
						xs: 'none',
						md: 'block',
					},
				}}
			>
				{headings.map(({ id, title, items }) => (
					<Fragment key={id}>
						<ListItem className={classes.listItemMain}>
							<ListItemText className={`${classes.itemText} ${id === activeId && classes.activeItem}`}>
								<Link
									component={RouterLink}
									to={`#${id}`}
									underline='none'
									href={`#${id}`}
									sx={{
										'&:hover': {
											cursor: 'pointer',
										},
									}}
								>
									{title}
								</Link>
							</ListItemText>
						</ListItem>
						<Collapse in={true}>
							<NestedHeadingsList {...{ items, scrollIntoViewWithOffset, activeId }} />
						</Collapse>
					</Fragment>
				))}
			</List>
		</>
	);
};

// Create a hook to find headings on the page
const useHeadingsData = isContentMounted => {
	const [nestedHeadings, setNestedHeadings] = useState([]);

	useEffect(() => {
		const headingElements = Array.from(document.querySelectorAll('.panelTitle')); // 'h2, h3, h4,

		const newNestedHeadings = getNestedHeadings(headingElements);
		setNestedHeadings(newNestedHeadings);
	}, [isContentMounted]);

	return nestedHeadings;
};

// Loop through the heading elements and add all h2s to the list. Any h3s will live inside the last known h2
const getNestedHeadings = headingElements => {
	const nestedHeadings = [];

	headingElements.forEach(heading => {
		const { innerText: title, id } = heading;

		if (heading.nodeName === 'H2') {
			nestedHeadings.push({ id, title, items: [] });
		} else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
			nestedHeadings[nestedHeadings.length - 1].items.push({
				id,
				title,
				type: heading.nodeName,
				items: [],
			});
		} else if (heading.nodeName === 'H4' && nestedHeadings.length > 0) {
			nestedHeadings[nestedHeadings.length - 1].items[nestedHeadings[nestedHeadings.length - 1].items.length - 1].items.push({
				id,
				title,
				type: heading.nodeName,
			});
		}
	});

	return nestedHeadings;
};

// Find the currently “active” heading
const useIntersectionObserver = (setActiveId, isContentMounted) => {
	const headingElementsRef = useRef({});

	useEffect(() => {
		const callback = headings => {
			headingElementsRef.current = headings.reduce((map, headingElement) => {
				map[headingElement.target.id] = headingElement;
				return map;
			}, headingElementsRef.current);

			// Get all headings that are currently visible on the page
			const visibleHeadings = [];
			Object.keys(headingElementsRef.current).forEach(key => {
				const headingElement = headingElementsRef.current[key];
				if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
			});

			const getIndexFromId = id => headingElements.findIndex(heading => heading.id === id);

			// If there is only one visible heading, this is our "active" heading
			if (visibleHeadings.length === 1) {
				setActiveId(visibleHeadings[0].target.id);
				// If there is more than one visible heading, choose the one that is closest to the top of the page
			} else if (visibleHeadings.length > 1) {
				const sortedVisibleHeadings = visibleHeadings.sort((a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id));
				setActiveId(sortedVisibleHeadings[0].target.id);
			}
		};

		const observer = new IntersectionObserver(callback, {
			threshold: 0.025,
			// rootMargin: '-110px 0px -40% 0px',
		});

		const headingElements = Array.from(document.querySelectorAll('.panelTitle')); // 'h2, h3'
		headingElements.forEach(element => observer.observe(element));

		return () => observer.disconnect();
	}, [setActiveId, isContentMounted]);
};

const TableOfContents = ({ surrogate }) => {
	const isProjectFormExtendedTemplateMounted = useSelector(state => state.app?.isProjectFormExtendedTemplateMounted);
	const nestedHeadings = useHeadingsData(isProjectFormExtendedTemplateMounted);

	const [activeId, setActiveId] = useState();
	useIntersectionObserver(setActiveId, isProjectFormExtendedTemplateMounted);

	return (
		<nav aria-label='Table of contents'>
			<Headings
				surrogate={surrogate}
				headings={nestedHeadings}
				activeId={activeId}
				isContentMounted={isProjectFormExtendedTemplateMounted}
			/>
		</nav>
	);
};

export default TableOfContents;
