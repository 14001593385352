import moment from 'moment';

export const getAddress = address => {
	if (!address) return null;

	const street = address.street ? `${address.street},` : '';
	const zip = address.zipCode ?? '';
	const city = address.city ?? '';

	const addressCombined = `${street} ${zip} ${city}`;

	return addressCombined.trim().length === 0 ? '-' : addressCombined;
};

export const getDate = date => {
	if (!date) return '-';

	try {
		return moment(date).format('DD.MM.YYYY');
	} catch (error) {
		return '-';
	}
};


export const getDateTime = date => {
	if (!date) return '-';

	try {
		return moment(date).format('DD.MM.YYYY hh:mm');
	} catch (error) {
		return '-';
	}
};
