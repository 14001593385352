import { map } from 'lodash';
import moment from 'moment';

import { SelectOption } from 'components/Multiselect/Multiselect';
import { resolve } from 'containers/Projects/Project/ProjectForm/ProjectFormExtendedTemplate/utils';
import { projectsApi } from 'containers/Projects/projectRTKApi';
import { BusinessPlan, Company, CompanyNote, FinancialPlan, ITeamMember, Instrument, Project, Scenario } from 'types/dataTypes';

import { ExtendedSelectOption } from './AIContextSelector';

const scenarioValues = [0, 1, 2, 4, 8, 16, 32];
const getIndex = (value: any, scenarioValues: any) =>
	scenarioValues.reduce((acc: any, item: any, index: number) => {
		return item === value ? index : acc;
	}, 0);

export type SelectAIPromptBaseDataProps = {
	values: Array<ExtendedSelectOption>;
	company: Company | undefined;
	businessPlan: BusinessPlan | undefined;
	financialPlan: FinancialPlan | undefined;
	projects: Array<Project> | undefined;
	notes: Array<CompanyNote> | undefined;
	instruments: Array<Instrument> | undefined;
	team: Array<ITeamMember> | undefined;
	scenario: Scenario | undefined;
	formatMessage: ({ id }: { id: string }) => string;
};

export type SelectOptions = {
	companyId: string;
	options: any[] | null;
} | null;

export const selectAIPromptBaseData = ({
	values,
	company,
	businessPlan,
	financialPlan,
	projects,
	notes,
	instruments,
	team,
	scenario,
	formatMessage,
}: SelectAIPromptBaseDataProps) => {
	if (!values) return null;

	for (let index = 0; index < values.length; index++) {
		const splittedSelector: string[] = values[index].value?.split('.');

		if (!splittedSelector || splittedSelector?.length === 0) {
			return;
		}

		const area: string = splittedSelector[0];
		const field: string = splittedSelector[1];
		const sub: string = splittedSelector.slice(2).join('.');

		switch (area) {
			case 'company':
				values[index].data =
					'Yrityksen yleiset tiedot. Mukana ovat myös taloustiedot viimeiseltä kolmelta vuodelta, jos tilinpäätökset ovat olleet saatavilla. Summat ovat tuhansissa euroissa. ' +
					JSON.stringify(company);
				break;

			case 'businessPlan':
				const bpKey = field as keyof typeof businessPlan;
				if (businessPlan) values[index].data = businessPlan[bpKey];
				break;

			case 'financialPlan':
				const fpKey = field as keyof typeof financialPlan;

				if (financialPlan) values[index].data = financialPlan[fpKey];
				break;

			case 'notes':
				const note = notes?.find(item => item.rowKey === field);

				if (note) values[index].data = 'Muistiinpanot: ' + note.content;
				break;

			case 'scenario':
				// eslint-disable-next-line no-loop-func
				const options = map(scenario, (value: any, key: string) => {
					const isMissing = scenario?.missingValues?.find((item: string | null) => item?.toLowerCase() === key?.toLowerCase());

					if (typeof value === 'number' && key !== 'grantedScore' && key !== 'totalValues') {
						const val = isMissing
							? 'Ei tietoa'
							: formatMessage({ id: `instrument.radar.choice.${key}.${getIndex(value, scenarioValues)}` });

						return `${formatMessage({ id: `instrument.radar.choice.${key}` })}: ${val}`;
					}
				});

				if (options) values[index].data = 'Profiili: ' + options;

				break;

			case 'projects':
				const project = projects?.find(item => item.projectId === field);

				if (!project) {
					values[index].data = '';
					break;
				}

				values[index].data = `Projekti: ${project?.projectName} ${resolve(sub, project)}`;
				break;

			case 'team':
				const teamMember = team?.find((member: any) => member.memberId === field);

				if (teamMember)
					values[
						index
					].data = `Tiimijäsen: nimi:  ${teamMember.firstname} ${teamMember.lastname}. Rooli: ${teamMember.position}. Kuvaus: ${teamMember.description}`;
				break;

			case 'instruments':
				const instrument = instruments?.find((item: any) => item.id === field);

				if (instrument) {
					values[
						index
					].data = `${instrument.instrumentPrompt} Instrumentti: ${instrument.name} tuottaja: ${instrument.provider} instrumentin kuvaus: ${instrument.description}`;
				}
				break;
		}
	}
};

const exists = (item: string | null | undefined) => {
	return item && item.length > 100;
};

export const addBusinessPlan = (businessPlan: BusinessPlan | undefined, formatMessage: ({ id }: { id: string }) => string) => {
	if (!exists(businessPlan?.pitch)) return null;

	const options: SelectOption[] = [];

	if (exists(businessPlan?.pitch)) options.push({ label: formatMessage({ id: 'businessplan.pitch' }), value: 'businessPlan.pitch' });
	if (exists(businessPlan?.problem))
		options.push({ label: formatMessage({ id: 'businessplan.problem' }), value: 'businessPlan.problem' });
	if (exists(businessPlan?.solution))
		options.push({ label: formatMessage({ id: 'businessplan.solution' }), value: 'businessPlan.solution' });
	if (exists(businessPlan?.markets))
		options.push({ label: formatMessage({ id: 'businessplan.markets' }), value: 'businessPlan.markets' });
	if (exists(businessPlan?.globalMarket))
		options.push({ label: formatMessage({ id: 'businessplan.globalMarket' }), value: 'businessPlan.globalMarket' });
	if (exists(businessPlan?.vision)) options.push({ label: formatMessage({ id: 'businessplan.vision' }), value: 'businessPlan.vision' });

	if (!options || options.length === 0) return null;

	return {
		label: 'Liiketoimintasuunnitelma',
		area: 'businessPlan',
		options: options,
	};
};

export const addFinancialPlan = (financialPlan: FinancialPlan | undefined, formatMessage: ({ id }: { id: string }) => string) => {
	if (!financialPlan || !financialPlan.rowKey) return null;

	const options: SelectOption[] = [];

	if (exists(financialPlan?.internalInformation))
		options.push({
			label: formatMessage({ id: 'timeline.financeplan.internalInformation' }),
			value: 'financialPlan.internalInformation',
		});
	if (exists(financialPlan?.base))
		options.push({
			label: `${formatMessage({ id: 'collaboration.financialplan.general' })}: ${formatMessage({ id: 'timeline.financeplan.base' })}`,
			value: 'financialPlan.base',
		});
	if (exists(financialPlan?.expertComments))
		options.push({
			label: `${formatMessage({ id: 'collaboration.financialplan.general' })}: ${formatMessage({
				id: 'timeline.financeplan.expertComments',
			})}`,
			value: 'financialPlan.expertComments',
		});
	if (exists(financialPlan?.followup))
		options.push({
			label: `${formatMessage({ id: 'collaboration.financialplan.general' })}: ${formatMessage({
				id: 'timeline.financeplan.followup',
			})}`,
			value: 'financialPlan.followup',
		});
	if (exists(financialPlan?.notice))
		options.push({
			label: `${formatMessage({ id: 'collaboration.financialplan.general' })}: ${formatMessage({
				id: 'timeline.financeplan.notice',
			})}`,
			value: 'financialPlan.notice',
		});
	if (exists(financialPlan?.precondition))
		options.push({
			label: `${formatMessage({ id: 'collaboration.financialplan.general' })}: ${formatMessage({
				id: 'timeline.financeplan.precondition',
			})}`,
			value: 'financialPlan.precondition',
		});

	if (!options || options.length === 0) return null;

	return {
		label: formatMessage({ id: 'collaboration.financialplan.general' }),
		area: 'financialPlan',
		options: options,
	};
};

export const addProjects = async (
	projects: Array<Project> | undefined,
	instruments: Array<Instrument> | undefined,
	formatMessage: any,
	dispatch: any
) => {
	const addIfExists = (target: SelectOption[], project: any, field: string) => {
		if (exists(project[field]))
			target.push({
				label: `${project.projectName}: ${formatMessage({ id: `project.form.${field}` })}`,
				value: `projects.${project.projectId}.${field}`,
			});
	};

	if (!projects || projects.length === 0) return null;

	const getOptions = async () => {
		const options = map(projects, async (project: Project) => {
			const children: SelectOption[] = [];

			addIfExists(children, project, 'projectSummary');
			addIfExists(children, project, 'projectVision');
			addIfExists(children, project, 'projectBackground');
			addIfExists(children, project, 'projectService');
			addIfExists(children, project, 'projectMarket');
			addIfExists(children, project, 'projectDevelopment');

			let dynamicTemplate: any = null;
			if (project.dynamic && project.instrumentId) {
				const instrument = instruments?.find(item => item?.id === project?.instrumentId);

				const { data } = await dispatch(
					projectsApi.endpoints.fetchInstumentTemplate.initiate({
						instrumentId: instrument?.instrumentTemplateId ?? project.instrumentId,
						templateVersion: instrument?.instrumentTemplateId ? null : project.instrumentTemplateVersion,
					})
				);

				if (data) {
					dynamicTemplate = data;
				}
			}

			function eachRecursive(obj: any, parent?: string | null) {
				for (let k in obj) {
					if (typeof obj[k] == 'object' && obj[k] !== null) eachRecursive(obj[k], parent ? `${parent}.${k}` : k);
					else if (typeof obj[k] == 'string') {
						if (exists(obj[k])) {
							const parentItem = dynamicTemplate?.fields?.find((item: any) => item.id === parent);

							if (!parentItem) return;

							// eslint-disable-next-line no-loop-func
							const childItem = parentItem?.fields?.find((child: any) => child.id === k);

							children.push({
								label: `${project.projectName}: ${parentItem?.title} ${childItem?.title}`,
								value: `projects.${project.projectId}.dynamic.${parent ? `${parent}.${k}` : k}`,
							});
						}
					}
				}
			}
			eachRecursive(project.dynamic);

			if (children.length === 0) return null;

			try {
				return {
					label: `Projekti: ${project.projectName}`,
					value: `projects.${project.projectId}`,
					children: await Promise.all(children),
				};
			} catch (error) {
				console.error(error);
			}
		});

		return await Promise.all(options);
	};

	const childrenOptions = await getOptions();
	const filteredItems = childrenOptions?.filter(item => item);

	if (!filteredItems || filteredItems.length === 0) return;

	return { label: 'Projektit', area: 'projects', options: filteredItems };
};

export const addNotes = (notesData: Array<CompanyNote> | undefined, formatMessage: any) => {
	if (!notesData || notesData.length === 0) return null;

	const options = map(notesData, note => {
		return {
			label: `${formatMessage({ id: `company.notes.form.category.${note.category}.label` })}: ${moment(note.created).format(
				'D.M.yyyy HH:mm'
			)}`,
			value: `notes.${note.rowKey}`,
		};
	});

	if (!options || options.length === 0) return null;

	return {
		label: 'Muistiinpanot',
		value: 'notes',
		options: options,
	};
};

export const addTeamMembers = (team: Array<ITeamMember> | undefined) => {
	if (!team || team.length === 0) return null;

	const options = map(team, member => {
		return {
			label: `${member.firstname} ${member.lastname}: ${member.position}`,
			value: `team.${member.memberId}`,
		};
	});

	if (!options || options.length === 0) return null;

	return {
		label: 'Tiimi',
		value: 'team',
		options: options,
	};
};

export const addScenario = (scenario: Scenario | undefined, formatMessage: ({ id }: { id: string }) => string) => {
	return {
		label: formatMessage({ id: 'company.search.open.profile' }),
		value: 'scenario',
		options: [
			{
				label: 'Profiili',
				value: 'scenario',
			},
		],
	};
};

export const addCompany = (company: Company | undefined, formatMessage: ({ id }: { id: string }) => string) => {
	return {
		label: 'Yritystiedot',
		value: 'company',
		options: [
			{
				label: 'Yrityksen yleistiedot ja taloustiedot',
				value: 'company',
			},
		],
	};
};
