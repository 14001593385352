import React from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import CloudTooltip from '../CloudTooltip';

const useStyles = makeStyles(theme => ({
	swithRoot: {
		'& .MuiIconButton-root:hover': {
			backgroundColor: 'transparent',
		},
	},
	switchBase: {
		color: theme.palette.primary.white,
		'&$checked': {
			color: theme.palette.primary.white,
		},
		'&$checked + $track': {
			backgroundColor: theme.palette.success.main,
			opacity: 1,
		},
	},
	track: {}, // empty object is required
	checked: {}, // empty object is required
}));

const SwitchGroup = ({ name, items, scenario, control, isCreatedByBot, profile = false, disabled }) => {
	const classes = useStyles();
	const intl = useIntl();

	return (
		<FormControl component='fieldset' sx={{ mt: 1.5 }}>
			<FormLabel component='legend'>
				<Typography gutterBottom color='primary' sx={{ fontWeight: 600 }}>
					{intl.formatMessage({ id: `profile.modal.${name}` })}
				</Typography>
			</FormLabel>
			<FormGroup>
				{items.map(({ type }) => (
					<Stack key={type} direction='row' alignItems='center' spacing={2}>
						<Controller
							name={type}
							control={control}
							defaultValue={scenario[type] || 0}
							render={({ field: { onChange, value } }) => {
								const checked = value >= 2 ? true : false;

								return (
									<FormControlLabel
										control={
											<Switch
												disabled={disabled}
												checked={checked}
												onChange={event => (profile ? null : onChange(event.target.checked ? 2 : 1))}
												name={type}
												classes={{
													root: classes.swithRoot,
													switchBase: classes.switchBase,
													track: classes.track,
													checked: classes.checked,
												}}
											/>
										}
										label={intl.formatMessage({ id: `instrument.radar.choice.${type}` })}
										classes={{ root: classes.label }}
									/>
								);
							}}
						/>
						<CloudTooltip isCreatedByBot={isCreatedByBot} />
					</Stack>
				))}
			</FormGroup>
		</FormControl>
	);
};

export default SwitchGroup;
