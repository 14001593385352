import { BorderedFormBox, FormControlledPostalCodeInput } from 'components/Form';
import FormError from '../FormError';

const BorderedTextBox = ({ name, defaultValue, control, error, yupError = false, label, instructions, isCreatedByBot, disabled }) => {
	return (
		<>
			<BorderedFormBox
				name={name}
				label={label}
				error={error}
				popoverContent={instructions}
				isCreatedByBot={isCreatedByBot}
				disabled={disabled}
			>
				<FormControlledPostalCodeInput name={name} defaultValue={defaultValue} control={control} disabled={disabled} />
			</BorderedFormBox>
			<FormError error={yupError} />
		</>
	);
};

export default BorderedTextBox;
