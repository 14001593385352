import { useState } from 'react';

import PrintIcon from '@mui/icons-material/Print';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import { Box, IconButton } from '@mui/material';
import Subtitle from 'components/Titles/Subtitle';

export const ShowHideInPrintButton = ({
	isPrintable,
	setIsPrintable,
	showToggle,
}: {
	isPrintable: boolean;
	setIsPrintable: any;
	showToggle: boolean;
}) => {
	return (
		<>
			{showToggle && (
				<IconButton
					sx={{ displayPrint: 'none', mt: '.8rem', ml: '1rem' }}
					aria-label='close'
					onClick={() => setIsPrintable(!isPrintable)}
				>
					{isPrintable ? <PrintIcon /> : <PrintDisabledIcon />}
				</IconButton>
			)}
		</>
	);
};

const ProjectSummarySection = ({
	title,
	children,
	level = 'h2',
	showToggle = false,
	sx = {},
	id = null,
	htmlClass = null,
	...props
}: {
	title: string | null;
	children: any;
	level?: string;
	showToggle?: boolean;
	sx?: any;
	id?: string | null;
	htmlClass?: string | null;
}) => {
	const [isPrintable, setIsPrintable] = useState(true);

	return (
		<Box
			id={id ?? ''}
			className={htmlClass ?? ''}
			sx={{
				...sx,
				displayPrint: isPrintable ? 'block' : 'none',
			}}
			{...props}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Subtitle
					mb={0}
					sx={{
						fontSize: {
							h2: '1.7rem',
							h3: '1.3rem',
							h4: '1rem',
						},
						display: !title ? 'none' : 'block',
					}}
				>
					{title}
				</Subtitle>
				<ShowHideInPrintButton {...{ isPrintable, setIsPrintable, showToggle }} />
			</Box>
			{children}
		</Box>
	);
};

export default ProjectSummarySection;
