import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    contentWrapper: {
        flex: 1,
    },
    contentContainer: {
        height: '100%',
    },
    title: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    description: {
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
        fontWeight: 600,
        padding: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    priceDescriptionBox: {
        borderRadius: `0 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        '& ul': {
            listStyleType: 'none',
            padding: theme.spacing(3),
            paddingLeft: theme.spacing(5),
            paddingTop: 0,
            '& li': {
                paddingTop: theme.spacing(1),
                '&:first-child': {
                    paddingTop: 0,
                },
            },
        },
    },
    trial: {
        backgroundColor: theme.palette.primary.beige,
    },
    pro: {
        backgroundColor: theme.palette.success.main,
        backgroundSize: '100% 100%',
    },
    titleBox: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
    },
    advanced: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
    },
    rate: {
        color: theme.palette.primary.white,
        fontSize: '1.2rem',
        fontWeight: 600,
        letterSpacing: theme.spacing(0.4),
    },
    priceBox: {
        width: 'calc(100% - 2rem)',
        padding: theme.spacing(3),
    },
    price: {
        fontWeight: 600,
    },
    bottomPadding: {
        paddingBottom: '1.5rem',
    },
    note: {
        fontSize: '1rem',
    },
    liText: {
        fontSize: '1rem',
        color: theme.palette.primary.white,
    },
    switchBox: {
        paddingLeft: theme.spacing(4),
    },
    switchRoot: {
        '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
        },
    },
    switchBase: {
        color: theme.palette.primary.white,
        '&$checked': {
            color: theme.palette.primary.white,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.success.main,
            opacity: 1,
        },
    },
    track: {}, // empty object is required
    checked: {}, // empty object is required
    label: {
        color: theme.palette.primary.main,
    },
    priceBoxContainer: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },
    priceBoxHold: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        background:
            'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 4%, #f2f2f2 4%, #f2f2f2 96%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%)',
    },
    arrow: {
        backgroundSize: '101% 25rem',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        height: '5rem',
    },
    arrowBeige: {
        backgroundImage: 'url(fromSlides/ulBoxTopBeige.svg)',
    },
    arrowGreen: {
        backgroundImage: 'url(fromSlides/ulBoxTopGreen.svg)',
    },
    arrowPurple: {
        backgroundImage: 'url(fromSlides/ulBoxTopPurple.svg)',
    },
    footer: {
        fontSize: '0.75rem',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        color: theme.palette.primary.main,
    },
}));

const Slide8 = ({ maxHeight }) => {
    const classes = useStyles();

    const [checked, setChecked] = useState(true);
    const handleChange = event => setChecked(event.target.checked);

    return (
        <Grid container direction='column' alignItems='center' className={classes.root} style={{ maxHeight }}>
            <Grid item className={classes.contentWrapper}>
                <Grid container direction='column' justifyContent='center' className={classes.contentContainer}>
                    <Grid item>
                        <Box textAlign='left' className={classes.switchBox}>
                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                classes={{
                                    root: classes.switchRoot,
                                    switchBase: classes.switchBase,
                                    track: classes.track,
                                    checked: classes.checked,
                                }}
                            />
                            <label className={classes.label}>{checked ? 'Vuosimaksu' : 'Kuukausittainen maksu'}</label>
                        </Box>
                        <Typography className={classes.title}>GrantedAI - Hinnoittelu</Typography>
                        <Typography className={classes.description}>
                            GrantedAI antaa laadukkaan kuvan yrityksesi kasvurahoitettavuudesta, sekä pitää huolen, ettei yksikään
                            rahoituksellinen kasvupaikka mene vahingossa ohi.
                        </Typography>
                        <Grid container justifyContent='center' className={classes.priceBoxContainer}>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.priceBoxHold}>
                                    <Box className={`${classes.titleBox} ${classes.trial}`}>
                                        <Typography className={classes.rate}>GrantedAI Trial</Typography>
                                    </Box>
                                    <Grid container justifyContent='center'>
                                        <Box className={classes.priceBox}>
                                            <Typography className={`${classes.price} ${classes.bottomPadding}`}>0€ / 7pv</Typography>
                                        </Box>
                                    </Grid>
                                    <Box className={`${classes.arrow} ${classes.arrowBeige}`}>&nbsp;</Box>
                                    <Box textAlign='left' className={`${classes.trial} ${classes.priceDescriptionBox}`}>
                                        <ul>
                                            <li>
                                                <Typography className={classes.liText}>Kaikki Granted AI PRO - ominaisuudet</Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>Asiantuntija-apu ensiprofiloinnissa</Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>Chat-palvelukanava</Typography>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.priceBoxHold}>
                                    <Box className={`${classes.titleBox} ${classes.pro}`}>
                                        <Typography className={classes.rate}>GrantedAI Pro</Typography>
                                    </Box>
                                    <Grid container justifyContent='center'>
                                        <Box className={classes.priceBox}>
                                            <Typography className={classes.price}>{checked ? '99 € / kk' : '149 € / kk'}</Typography>
                                            <Typography className={classes.note}>
                                                {checked ? 'Laskutetaan vuodeksi kerralla' : 'Laskutus kuukausittain'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Box className={`${classes.arrow} ${classes.arrowGreen}`}>&nbsp;</Box>
                                    <Box textAlign='left' className={`${classes.pro} ${classes.priceDescriptionBox}`}>
                                        <ul>
                                            <li>
                                                <Typography className={classes.liText}>
                                                    Yrityskohtainen profilointi ja Granted-score
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>
                                                    Mahdollisuusanalyysi, instrumenttikortiti ja instrumenttipisteytys
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>Instrumenttisorttaus</Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>Rahoituskriittiset notifikaatiot</Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>Suunnittelutyökalu</Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>Realiaikainen asiantuntija-apu</Typography>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.priceBoxHold}>
                                    <Box className={`${classes.titleBox} ${classes.advanced}`}>
                                        <Typography className={classes.rate}>GrantedAI Pro +</Typography>
                                    </Box>
                                    <Grid container justifyContent='center'>
                                        <Box className={classes.priceBox}>
                                            <Typography className={classes.price}>{checked ? '149 € / kk' : '219 € / kk'}</Typography>
                                            <Typography className={classes.note}>
                                                {checked ? 'Laskutetaan vuodeksi kerralla' : 'Laskutus kuukausittain'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Box className={`${classes.arrow} ${classes.arrowPurple}`}>&nbsp;</Box>
                                    <Box textAlign='left' className={`${classes.advanced} ${classes.priceDescriptionBox}`}>
                                        <ul>
                                            <li>
                                                <Typography className={classes.liText}>Kaikki se herkku, mitä PRO -versiossakin</Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>
                                                    Asiantuntijan kanssa muodostettava rahoitussuunnitelma
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography className={classes.liText}>Dedikoitu rahoitusasiantuntija</Typography>
                                            </li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography className={classes.footer}>
                    {checked
                        ? 'Sopimusehdot:  Sopimusaika on yksi vuosi | Sopimus laskutetaan vuodeksi kerrallaan | Hintoihin lisätään arvonlisävero | Maksuehto on 7pv netto'
                        : 'Sopimusehdot:  Sopimusaika on yksi vuosi | Hintoihin lisätään arvonlisävero | Maksuehto on 7pv netto'}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Slide8;
