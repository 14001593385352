import { isEmpty, isNil } from 'lodash';
import * as React from 'react';

import { RfpRequest } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

type Props = { rfpRequest: Partial<RfpRequest> | undefined; children?: (canUpsert: boolean) => React.ReactNode };

const CanUpsertRfpRequest: React.FC<Props> = ({ rfpRequest, children }) => {
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const canUpsert = React.useMemo(() => {
		if (isNil(rfpRequest)) {
			return true;
		}
		return rfpRequest?.companyId === companyId && rfpRequest?.status !== 'Archived' && isEmpty(rfpRequest?.proposals);
	}, [rfpRequest, companyId]);

	return <>{children?.(canUpsert)}</>;
};

export default CanUpsertRfpRequest;
