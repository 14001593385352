import { isEmpty, map } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Skeleton, Stack, Typography } from '@mui/material';

import { useFetchCompanyNotesQuery } from 'containers/Admin/UsersCompany/companyNotesV2Api';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import { getCompanyId } from 'utils/auth/company';

import AccordionItem from 'components/AccordionItem/AccordionItem';
import Subtitle from 'components/Titles/Subtitle';
import CompanyNoteListItem from 'containers/Admin/UsersCompany/CompanyDetails/CompanyNotes/CompanyNoteListItem';
import UpsertCompanyNoteDialog from 'containers/Admin/UsersCompany/CompanyDetails/CompanyNotes/UpsertCompanyNoteDialog';
import { NoteCategory } from 'types/dataTypes';
import { getUser } from 'utils/auth/user';
import { convertEmailToName } from 'utils/converters/convertEmailToName';
import NoteByCategory from './NoteByCategory';

interface Props {}

const CompanyNotesList: React.FC<Props> = () => {
	const companyId = getCompanyId();

	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { data: companyNotes, isError, isLoading, isFetching } = useFetchCompanyNotesQuery({ companyId }, { skip: !companyId });

	const isMyCompanyNote = (companyNote: any) => {
		const user = getUser();

		return user?.email === companyNote?.author;
	};

	return (
		<>
			<UpsertCompanyNoteDialog />
			<Stack flexDirection='column' spacing={1} useFlexGap>
				<Stack flexDirection='row' justifyContent='space-between' alignItems='center' spacing={1} useFlexGap>
					{/* @ts-ignore */}
					<Subtitle mt='1rem' padding={0}>
						{formatMessage({
							id: 'company.notes.list.title',
						})}
					</Subtitle>
					{/* @ts-ignore */}
					<Button
						color='success'
						startIcon={<AddIcon />}
						onClick={() => dispatch(openDialog({ name: 'UpsertCompanyNoteDialog' }))}
					>
						{formatMessage({
							id: 'company.notes.list.create',
						})}
					</Button>
				</Stack>
				{isError && (
					<Alert severity='error'>
						<AlertTitle>{formatMessage({ id: 'query.error.title' })}</AlertTitle>
						{formatMessage({ id: 'company.notes.list.fetch.error' })}
					</Alert>
				)}
				<Stack flexDirection='column' spacing={1} useFlexGap>
					{isLoading || isFetching ? (
						<>
							<Skeleton height={100} variant='rectangular' />
							<Skeleton height={100} variant='rectangular' />
							<Skeleton height={100} variant='rectangular' />
							<Skeleton height={100} variant='rectangular' />
						</>
					) : (
						<>
							{isEmpty(companyNotes) ? <Typography>{formatMessage({ id: 'company.notes.list.empty' })}</Typography> : null}
							{map(companyNotes, (companyNote, index) => (
								<AccordionItem
									key={companyNote.rowKey}
									title={
										<Stack flexDirection='row' spacing={1} useFlexGap>
											<Typography>
												{companyNote.title ? (
													companyNote.title
												) : companyNote.category ? (
													<>
														{formatMessage({ id: `company.notes.list.item.category.${companyNote.category}` })}
														{' - '}
														<Typography variant='lightText' component='span'>
															{isEmpty(companyNote.created)
																? ''
																: DateTime.fromISO(companyNote.created as string).toFormat(
																		'dd.MM.yyyy HH:mm'
																  )}
														</Typography>
													</>
												) : (
													formatMessage({ id: 'company.notes.list.item.no-title' })
												)}
											</Typography>
										</Stack>
									}
									subtitle={
										<Stack flexDirection='row' spacing={1} useFlexGap>
											<Typography variant='lightText' component='div'>
												{isEmpty(companyNote.author)
													? formatMessage({ id: 'company.notes.list.item.author.empty' })
													: convertEmailToName(companyNote.author)}
											</Typography>
											<Typography variant='lightText' component='div'>
												{isEmpty(companyNote.created)
													? ''
													: DateTime.fromISO(companyNote.created as string).toFormat('dd.MM.yyyy HH:mm')}
											</Typography>
										</Stack>
									}
									note={
										<Stack flexDirection='row' alignItems='flex-end' spacing={0.75} useFlexGap>
											<NoteByCategory category={companyNote.category as NoteCategory} />
										</Stack>
									}
									after={
										<Stack flexDirection='row' justifyContent='flex-end' spacing={1} useFlexGap>
											{isMyCompanyNote(companyNote) ? (
												<Button
													startIcon={<EditIcon />}
													onClick={() =>
														dispatch(openDialog({ name: 'UpsertCompanyNoteDialog', data: companyNote }))
													}
												>
													{formatMessage({ id: 'company.notes.list.item.edit' })}
												</Button>
											) : null}
										</Stack>
									}
								>
									<CompanyNoteListItem key={companyNote?.companyNotesId} companyNote={companyNote} first={index === 0} />
								</AccordionItem>
							))}
						</>
					)}
				</Stack>
			</Stack>
		</>
	);
};

export default CompanyNotesList;
