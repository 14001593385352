import { Controller, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Divider, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import Button from 'components/Button/Button';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import { FormControlledInput } from 'components/Form';

const ProvideLinkButtons = ({ instrument, errors, control, linkIndex }) => {
	const intl = useIntl();

	const { fields, append, remove } = useFieldArray({
		control,
		name: `instrument.providerLink.${linkIndex}.buttons`,
	});

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} display='flex' justifyContent='flex-end'>
					<Button startIcon={<AddIcon />} onClick={() => append({ title: '', description: '' })}>
						{intl.formatMessage({ id: 'instrument.form.providerLink.buttons.append' })}
					</Button>
				</Grid>
				<Grid item xs={12}>
					{fields.map((field, buttonIndex) => (
						<Grid key={field.id} container spacing={2}>
							<Grid item xs={12} md={5}>
								<Controller
									name={`providerLink[${linkIndex}].buttons[${buttonIndex}].id`}
									control={control}
									defaultValue={field?.id ?? ''}
									render={() => <input type='hidden' value={field?.id ?? ''} />}
								/>
								<FormControlledInput
									variant='standard'
									defaultValue={field?.label ?? ''}
									name={`providerLink[${linkIndex}].buttons[${buttonIndex}].label`}
									control={control}
									error={errors?.providerLink?.[linkIndex]?.buttons?.[buttonIndex]?.message}
									label={intl.formatMessage({ id: 'instrument.form.providerLink.buttons.label' })}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControlledInput
									variant='standard'
									defaultValue={field?.url ?? ''}
									name={`providerLink[${linkIndex}].buttons[${buttonIndex}].url`}
									control={control}
									error={errors?.providerLink?.[linkIndex]?.buttons?.[buttonIndex]?.url}
									label={intl.formatMessage({ id: 'instrument.form.providerLink.buttons.url' })}
								/>
							</Grid>
							<Grid item xs={12} md={1} display='flex' justifyContent='flex-end' alignItems='center'>
								<IconButton onClick={() => remove(buttonIndex)}>
									<DeleteIcon color='error' />
								</IconButton>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</>
	);
};

const ProviderLink = ({ instrument, errors, control }) => {
	const intl = useIntl();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'instrument.providerLink',
	});

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} display='flex' justifyContent='flex-end'>
					<Button startIcon={<AddIcon />} onClick={() => append({ title: '', description: '' })}>
						{intl.formatMessage({ id: 'instrument.form.providerLink.append' })}
					</Button>
				</Grid>
				<Grid item xs={12}>
					{fields.map((field, linkIndex) => (
						<Grid key={field.id} container spacing={2} mb={4}>
							{linkIndex > 0 && (
								<Grid item xs={12}>
									<Divider sx={{ mt: 2 }} />
								</Grid>
							)}
							<Grid item xs={11}>
								<Grid container spacing={2}>
									<Controller
										name={`providerLink[${linkIndex}].id`}
										control={control}
										defaultValue={field?.id ?? ''}
										render={() => <input type='hidden' value={field?.id ?? ''} />}
									/>
									<Grid item xs={12}>
										<FormControlledInput
											defaultValue={field.title ?? ''}
											name={`providerLink[${linkIndex}].title`}
											control={control}
											error={errors?.providerLink?.title?.message}
											label={intl.formatMessage({ id: 'instrument.form.providerLink.title' })}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControlledInput
											defaultValue={field.customerBusinessId ?? ''}
											name={`providerLink[${linkIndex}].customerBusinessId`}
											control={control}
											error={errors?.providerLink?.customerBusinessId?.message}
											label={intl.formatMessage({ id: 'instrument.form.providerLink.customerBusinessId' })}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControlledWysiwyg
											inline={false}
											fixedToolbar={true}
											toolbar={true}
											name={`providerLink[${linkIndex}].description`}
											control={control}
											defaultValue={field.description ?? ''}
											error={errors?.providerLink?.description?.message}
											label={intl.formatMessage({ id: 'instrument.form.providerLink.description' })}
										/>
									</Grid>
									<Grid item xs={12}>
										<ProvideLinkButtons
											instrument={instrument}
											control={control}
											errors={errors}
											linkIndex={linkIndex}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={1} display='flex' justifyContent='flex-end' alignItems='center'>
								<IconButton onClick={() => remove(linkIndex)}>
									<DeleteIcon color='error' />
								</IconButton>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</>
	);
};

export default ProviderLink;
