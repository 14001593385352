import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.main,
        boxShadow: theme.shadows[2],
        fontSize: '1rem',
        padding: '1rem',
    },
}))(Tooltip);

export default LightTooltip;
