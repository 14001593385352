import React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge, {
    shouldForwardProp: prop => prop !== 'conversation',
})(({ theme, conversation }) => ({
    '& .MuiBadge-badge': {
        right: conversation ? -9 : -5, // temporarily?! to avoid style difference
        top: conversation ? -17 : -12, // same here
        border: `1px solid ${theme.palette.primary.white}`,
        lineHeight: 0
    },
}));

const IconBadged = ({ badgeContent, conversation = false, children }) => {
    return (
        <StyledBadge badgeContent={badgeContent} conversation={conversation} color='success'>
            {children}
        </StyledBadge>
    );
};

export default IconBadged;