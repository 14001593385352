import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

const ModalTitle = ({ advancedCustomerName, rowKey }) => {
    return (
        <Stack direction='row' spacing={2}>
            <Typography color='primary' sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                {advancedCustomerName}
            </Typography>
            <Chip
                label={rowKey}
                size='small'
                sx={{
                    color: 'seconary.grey700',
                    '& span': {
                        color: 'primary.main'
                    }
                }}
            />
        </Stack>
    );
};

export default ModalTitle;
