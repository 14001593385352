import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, TextField } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { RfpCategory } from 'types/dataTypes';

import { closeDialog, getDialogData, isDialogOpen } from 'containers/Marketplace/dialogSlice';

import RfpCategoryAutocomplete from 'containers/Marketplace/common/RfpCategoryAutocomplete';

type Props = {} & Omit<DialogProps, 'open'>;

const FilterPublicRfpProfilesDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const dialogOpen = useSelector(isDialogOpen('FilterPublicRfpProfilesDialog'));
	const dialogData = useSelector(getDialogData('FilterPublicRfpProfilesDialog'));

	const [categories, setCategories] = React.useState<RfpCategory[]>([]);

	React.useEffect(() => {
		setCategories(dialogData?.filterValues?.categories ?? []);
	}, [dialogData]);

	const handleDialogClose = React.useCallback(() => {
		dispatch(closeDialog({ name: 'FilterPublicRfpProfilesDialog' }));
	}, [dispatch]);

	const handleDialogSubmit = React.useCallback(() => {
		dialogData?.onSubmit?.({ categories });
		dispatch(closeDialog({ name: 'FilterPublicRfpProfilesDialog' }));
	}, [dispatch, dialogData, categories]);

	return (
		<>
			<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='smd' fullWidth>
				<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					<FormattedMessage id='filterPublicRfpProfiles.title' />
				</DialogTitle>
				<DialogContent>
					<RfpCategoryAutocomplete
						value={categories}
						onChange={(event, value) => setCategories(value)}
						renderInput={params => (
							<TextField
								{...params}
								label={formatMessage({ id: 'filterPublicRfpProfiles.categories.label' })}
								placeholder={formatMessage({ id: 'filterPublicRfpProfiles.categories.placeholder' })}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant='text' size='small' color='neutral' onClick={handleDialogClose}>
						<FormattedMessage id='filterPublicRfpProfiles.cancel' />
					</Button>
					<Button size='small' onClick={handleDialogSubmit}>
						<FormattedMessage id='filterPublicRfpProfiles.submit' />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default FilterPublicRfpProfilesDialog;
