import { useIntl } from 'react-intl';

import { Skeleton } from '@mui/material';

import { useGetScenarioDataQuery } from 'containers/Stakeholder/stakeholderApi';

import Button from 'components/Button/Button';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import ScenarioModal from 'containers/Scenario/ScenarioModal';
import { useDispatch } from 'react-redux';
import Error from '../Components/Error';

export const ScenarioSkeleton = () => {
	return <Skeleton variant='rectangular' height={40} sx={{ width: '15rem' }} />;
};

const Scenario = ({ linkId }) => {
	const dispatch = useDispatch();
	const intl = useIntl();

	const {
		data: scenario,
		isFetching: isScenarioFetching,
		isLoading: isScenarioLoading,
		isSuccess: isScenarioSuccess,
	} = useGetScenarioDataQuery(linkId);

	if (isScenarioFetching || isScenarioLoading) return <ScenarioSkeleton />;

	if (!isScenarioSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.scenario.error' })}</Error>;

	return (
		<>
			<Button
				disabled={isScenarioFetching || isScenarioLoading}
				color='success'
				onClick={() => dispatch(openDialog({ name: 'ScenarioDialog' }))}
			>
				{intl.formatMessage({ id: 'profile.info.button.text' })}
			</Button>
			<ScenarioModal scenario={scenario?.scenario} readonly={true} profile={true} />
		</>
	);
};

export default Scenario;
