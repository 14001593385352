import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';

import ModalDialog from 'components/Dialog/ModalDialog';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Button from 'components/Button/Button';
import ChipList from './ChipList';

const SuitableInstrumentsModal = forwardRef(({
    open,
    close,
    filterList,
    // levels
    uniqueLevels,
    allLevels,
    setAllLevels,
    clickedLevels,
    setClickedLevels,
    // types
    uniqueTypes,
    allTypes,
    setAllTypes,
    clickedTypes,
    setClickedTypes,
    // providers
    uniqueProviders,
    allProviders,
    setAllProviders,
    // aims tags
    uniqueProjectAims,
    allProjectAims,
    setAllProjectAims,
    // object tags
    uniqueProjectObjects,
    allProjectObjects,
    setAllProjectObjects,
    // project monetary values
    uniqueProjectValues,
    allProjectValues,
    setAllProjectValues,
    setChecked,
}, ref) => {
    const intl = useIntl();

    useImperativeHandle(ref, () => ({
        clearFilters() {
            makeActiveAll();
        }
    }));

    const makeActiveAll = () => {
        setClickedLevels(uniqueLevels);
        setAllLevels(true);
        setClickedTypes(uniqueTypes);
        setAllTypes(true);
        setClickedProviders(uniqueProviders);
        setAllProviders(true);
        setClickedProjectAims(uniqueProjectAims);
        setAllProjectAims(true);
        setClickedProjectObjects(uniqueProjectObjects);
        setAllProjectObjects(true);
        setClickedProjectValues(uniqueProjectValues);
        setAllProjectValues(true);
        setChecked([
            ...uniqueLevels,
            ...uniqueTypes,
            ...uniqueProviders,
            ...uniqueProjectAims,
            ...uniqueProjectObjects,
            ...uniqueProjectValues,
        ]);
    };

    const allShown = allLevels && allTypes && allProviders && allProjectAims && allProjectObjects && allProjectValues;

    const [clickedProviders, setClickedProviders] = useState(uniqueProviders);
    const [clickedProjectAims, setClickedProjectAims] = useState(uniqueProjectAims);
    const [clickedProjectObjects, setClickedProjectObjects] = useState(uniqueProjectObjects);
    const [clickedProjectValues, setClickedProjectValues] = useState(uniqueProjectValues);

    return (
        <ModalDialog
            open={open}
            onClose={close}
            title={intl.formatMessage({ id: 'suitable.instrument.panel.title' })}
            maxWidth='lg'
            actions={
                <LeftRightContainer
                    left={
                        <Button disabled={allShown} variant='text' color='primary' onClick={makeActiveAll}>
                            {intl.formatMessage({ id: 'button.remove.filters' })}
                        </Button>
                    }
                    right={
                        <Button variant='text' color='primary' onClick={close}>
                            {intl.formatMessage({ id: 'suitable.instrument.panel.close' })}
                        </Button>
                    }
                />
            }
        >
            <Box sx={{ p: 3, pt: 1 }}>
                <ChipList
                    label='levels'
                    filterList={filterList}
                    uniqueItems={uniqueLevels?.sort().reverse()}
                    all={allLevels}
                    setAll={setAllLevels}
                    clicked={clickedLevels}
                    setClicked={setClickedLevels}
                />
                <ChipList
                    label='types'
                    filterList={filterList}
                    uniqueItems={uniqueTypes}
                    all={allTypes}
                    setAll={setAllTypes}
                    clicked={clickedTypes}
                    setClicked={setClickedTypes}
                    sx={{ mt: '1rem' }}
                />

                <ChipList
                    label='providers'
                    filterList={filterList}
                    uniqueItems={uniqueProviders}
                    all={allProviders}
                    setAll={setAllProviders}
                    clicked={clickedProviders}
                    setClicked={setClickedProviders}
                    sx={{ mt: '1rem' }}
                />
                <ChipList
                    label='projectAims'
                    filterList={filterList}
                    uniqueItems={uniqueProjectAims}
                    all={allProjectAims}
                    setAll={setAllProjectAims}
                    clicked={clickedProjectAims}
                    setClicked={setClickedProjectAims}
                    sx={{ mt: '1rem' }}
                />
                <ChipList
                    label='projectObjects'
                    filterList={filterList}
                    uniqueItems={uniqueProjectObjects}
                    all={allProjectObjects}
                    setAll={setAllProjectObjects}
                    clicked={clickedProjectObjects}
                    setClicked={setClickedProjectObjects}
                    sx={{ mt: '1rem' }}
                />

                <ChipList
                    label='projectValues'
                    filterList={filterList}
                    uniqueItems={uniqueProjectValues}
                    all={allProjectValues}
                    setAll={setAllProjectValues}
                    clicked={clickedProjectValues}
                    setClicked={setClickedProjectValues}
                    sx={{ mt: '1rem' }}
                />
            </Box>
        </ModalDialog>
    );
});

export default SuitableInstrumentsModal;
