import React from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import { useGetCompanyDataQuery, useGetInstrumentsDataQuery, useGetBaseDataQuery, useGetBusinessPlanDataQuery } from 'containers/Stakeholder/stakeholderApi';
import InstrumentCategoryList from 'containers/Instruments/InstrumentList/InstrumentCategoryList';

import Error from '../Components/Error';
import Loader from '../Components/Loader/Loader';
import { HtmlParserComponent } from 'components/HtmlParser/HtmlParser';
import EconomicalTableReadonly from 'containers/BusinessPlan/EconomicalTable/EconomicalTableReadonly';
import { combineEconomyData } from 'containers/BusinessPlan/utils';

const Landing = ({ linkId }) => {
	const intl = useIntl();
	const theme = useTheme();

	const {
		data: companyData,
		isFetching: isCompanyFetching,
		isLoading: isCompanyLoading,
		isSuccess: isCompanySuccess
	} = useGetCompanyDataQuery(linkId);

	const {
		data: instrumentsData,
		isFetching: isInstrumentsFetching,
		isLoading: isInstrumentsLoading,
		isSuccess: isInstrumentsSuccess
	} = useGetInstrumentsDataQuery(linkId);

	const {
		data: baseData,
		isFetching: isBaseDataFetching,
		isLoading: isBaseDataLoading,
		isSuccess: isBaseDataSuccess
	} = useGetBaseDataQuery(linkId);

	const {
		data: businessPlanData,
		isFetching: isBusinessPlanFetching,
		isLoading: isBusinessPlanLoading,
		isSuccess: isBusinessPlanSuccess
	} = useGetBusinessPlanDataQuery(linkId);

	if (isBaseDataFetching || isBaseDataLoading) return <Loader />;
	if (isCompanyLoading || isCompanyFetching) return <Loader />;

	if (!isBaseDataSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.basedata.error' })}</Error>;

	const CompanyBusinessPlanData = () => {
		if (isBusinessPlanFetching || isBusinessPlanLoading) return <Loader />;
		if (isCompanyLoading || isCompanyFetching) return <Loader />;

		if (!isBusinessPlanSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.analysis.error' })}</Error>;

		const economicalData = combineEconomyData(
			companyData?.company?.economicalData ?? [],
			businessPlanData?.businessPlan?.economicalData ?? []
		);

		return (
			<>
				<Box sx={{
					padding: theme.spacing(2),
					background: theme.palette.primary.white,
					mb: theme.spacing(2),
					display: businessPlanData?.businessPlan?.main?.pitch ? 'block' : 'none'
				}}>
					<HtmlParserComponent html={businessPlanData?.businessPlan?.main?.pitch} />
				</Box>
				<Box sx={{ padding: theme.spacing(2), background: theme.palette.primary.white, mb: theme.spacing(2) }}>
					<EconomicalTableReadonly id='economicalData' data={economicalData} />
				</Box>
			</>
		);
	};

	const InstrumentCategoryContainer = () => {
		if (!isInstrumentsFetching && !isInstrumentsLoading && !isInstrumentsSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.instruments.error' })}</Error>;

		return (
			<InstrumentCategoryList
				readonly={true}
				loading={isInstrumentsLoading || isInstrumentsFetching}
				categories={baseData?.categories}
				company={companyData?.company}
				instruments={instrumentsData?.instruments}
				profile={false}
			/>
		);
	};

	return (
		<>
			<CompanyBusinessPlanData />
			<InstrumentCategoryContainer />
		</>
	);
};

export default Landing;
