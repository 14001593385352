import { useIntl } from 'react-intl';

import MainTitle from 'components/Titles/MainTitle';
import Landing from 'containers/Admin/Landing';

import ContentContainer from 'components/Containers/ContentContainer';

const SearchPage = () => {
	const intl = useIntl();

	return (
		<ContentContainer>
			<MainTitle>{intl.formatMessage({ id: 'searchpage.title' })}</MainTitle>
			<Landing />
		</ContentContainer>
	);
};

export default SearchPage;
