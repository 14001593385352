import React from 'react';
import { Controller } from 'react-hook-form';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';

import FormControlWithLabel from 'components/Form/FormControlWithLabel';

const CustomAutocomplete = withStyles({
	inputRoot: {
		'&&[class*="MuiOutlinedInput-root"] $input': {
			paddingTop: 2,
			paddingBottom: 2
		},
	},
	input: {} // required
})(Autocomplete);

export default function ({ name, label, error, control, options, disabled, defaultValue, placeholder, freeSolo = false }) {
	const renderOption = (props, option) => typeof option === 'string' || option instanceof String
		? <Box component='li' {...props}>{option || ''}</Box>
		: <Box component='li' {...props}>{option.value || ''}</Box>;

	const isOptionEqualToValue = (option, value) => typeof option === 'string' || option instanceof String
		? option === value
		: option.value === value.value;

	const getOptionLabel = option => typeof option === 'string' || option instanceof String
		? option || ''
		: option.value || '';

	return (
		<FormControlWithLabel
			error={error}
			label={label}
		>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field: { onChange, value, } }) => {
					return (
						<CustomAutocomplete
							value={value}
							disabled={disabled}
							disableClearable
							clearOnEscape
							freeSolo={freeSolo}
							options={options}
							renderOption={renderOption}
							isOptionEqualToValue={isOptionEqualToValue}
							getOptionLabel={getOptionLabel}
							onChange={(_, data) => onChange(data)}
							renderInput={params => {
								return (
									<TextField
										{...params}
										error={error ? true : false}
										variant='outlined'
										size='small'
										fullWidth
										placeholder={placeholder}
										InputLabelProps={{ shrink: true }}
									/>
								);
							}}
						/>
					);
				}}
			/>
		</FormControlWithLabel>
	);
}
