import React from 'react';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@mui/styles';

import BellIcon from 'components/BellIcon';
import PersonIcon from 'components/PersonIcon';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SlideshowIcon from '@mui/icons-material/Slideshow';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.grey,
    },
    badge: {
        padding: 0,
        height: '1.2rem',
        lineHeight: 'unset',
    },
}));

const NotificationBadge = ({ icon, messagesNumber = 0, iconSize, noColor }) => {
    const classes = useStyles();

    return (
        <Badge
            color='secondary'
            badgeContent={messagesNumber}
            className={!noColor ? classes.root : null}
            classes={{ badge: classes.badge }}
        >
            {icon === 'notification' ? (
                <BellIcon width={iconSize} />
            ) : icon === 'person' ? (
                <PersonIcon width={iconSize} />
            ) : icon === 'menu' ? (
                <MenuIcon width={iconSize} />
            ) : icon === 'dropdown' ? (
                <ArrowDropDownIcon width={iconSize} />
            ) : icon === 'slideshow' ? (
                <SlideshowIcon width={iconSize} />
            ) : null}
        </Badge>
    );
};

export default NotificationBadge;
