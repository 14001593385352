import axios from 'utils/axios';
import { validateHttpStatus, buildPlainUri } from 'utils/helpers/uriHelper';

const baseUrl = 'company';

class ChatApi {
	async getChatLastVisit(companyId) {
		const url = buildPlainUri(baseUrl, `${companyId}/chat`);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveChatLastVisit(companyId) {
		const url = buildPlainUri(baseUrl, `${companyId}/chat`);
		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const chatApi = new ChatApi();
