import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

const CustomTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.primary.white,
		color: theme.palette.primary.main,
		boxShadow: theme.shadows[2],
		fontSize: '1rem',
		padding: '1rem',
	},
	tooltipPlacementTop: {
		margin: theme.spacing(-1.9),
	},
}))(Tooltip);

export default CustomTooltip;
