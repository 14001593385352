import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { isContributorToken } from 'utils/auth/token';
import { isAdvancedToken } from 'utils/auth/company';

import { regexProfile } from 'utils/constants/regex';
import { matchPath } from 'utils/helpers/pathMatch';

const useStyles = makeStyles(theme => ({
	logo: {
		'& .a': {
			fill: theme.palette.primary.beige,
		},
		'& .b': {
			fill: theme.palette.secondary.light,
		},
	},
}));

const LogoSmall = ({ pathname, width, slideshow }) => {
	const location = useLocation();
	const classes = useStyles();
	const isContributor = isContributorToken();
	const isAdvanced = isAdvancedToken();
	const staticAnalysis = matchPath('/analysis/:id', location, true);

	const migratedCompany = useSelector(state => state.migratedCompany);
	const isMigratedDataLoaded = migratedCompany?.isLoaded;

	const logo = (
		<svg className={classes.logo} xmlns='http://www.w3.org/2000/svg' width={width} viewBox='0 0 23 23'>
			<path className="a" d="M58.144,182.23a6.106,6.106,0,0,1,3.2-.857,7.882,7.882,0,0,1,3.093.666,9.256,9.256,0,0,1,2.776,1.808l3.3-4.061a12.856,12.856,0,0,0-4.188-2.427,14.335,14.335,0,0,0-4.949-.9,13.151,13.151,0,0,0-6.266,1.491,11.239,11.239,0,0,0-4.41,4.109,10.988,10.988,0,0,0-1.6,5.853,11.385,11.385,0,0,0,1.555,5.9,11.138,11.138,0,0,0,4.267,4.14A12.292,12.292,0,0,0,61,199.456a16.436,16.436,0,0,0,4.9-.809,14.824,14.824,0,0,0,4.362-2.11v-8.09H63.7l-1.745,4.441H65.25v.54a8.776,8.776,0,0,1-3.839,1.078,6.273,6.273,0,0,1-3.236-.857,6.057,6.057,0,0,1-2.284-2.363,6.913,6.913,0,0,1-.825-3.379,6.76,6.76,0,0,1,.825-3.331A6.163,6.163,0,0,1,58.144,182.23Z" transform="translate(-49.102 -176.456)"></path>
		</svg>
	);

	return (isContributor && (pathname === '/search' || pathname === '/admin/search')) ||
		(isAdvanced && !isContributor && pathname === '/customers') ||
		(!isContributor && !isAdvanced && pathname === '/') ||
		regexProfile.test(pathname) || staticAnalysis ||
		slideshow || isMigratedDataLoaded ? (
		<Box sx={{ mt: '1rem' }}>
			{logo}
		</Box>
	) : (
		<Box sx={{ mt: '1rem' }}>
			<Link to={isContributor ? '/admin/search' : isAdvanced ? '/customers' : '/'}>
				{logo}
			</Link>
		</Box>
	);
};

export default LogoSmall;
