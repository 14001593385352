import { findItemByIdWithField } from '../../Projects/slices';
import { getLinkSplitted, separator } from '../utils';

export const getPathHashOrLink = link => {
	const linkSplitted = getLinkSplitted(link);

	if (link === 'projects/general' || linkSplitted[0] === 'businessplan' || linkSplitted[0] === 'financialplan')
		return link.replace('/', '#');

	if (!linkSplitted[2] || linkSplitted[2] === 'plan' || linkSplitted[2] === 'summary') {
		if (linkSplitted.length === 3) return link;

		if (!linkSplitted[2]) return `${linkSplitted[0]}/${linkSplitted[1]}`;

		// TODO: fix hardcoding with collaboration links, create flexiple way to use hash links
		return `${linkSplitted[0]}/${linkSplitted[1]}/${linkSplitted[2]}#${linkSplitted[3]}`;
	}

	return `${linkSplitted[0]}/${linkSplitted[1]}#costs`;
};

export const getCommentLink = (
	temporaryLink,
	projectId,
	isDashboard,
	generalPage,
	isGeneralConversation,
	isProjectsProject,
	isProjectsProjectPlan,
	isProjectSummary
) => {
	if (temporaryLink) return temporaryLink?.link ?? temporaryLink;
	if (isDashboard) return '';
	if (isGeneralConversation) return `${generalPage}/general`;
	if (isProjectsProject) return `projects/${projectId}`;
	if (isProjectsProjectPlan) return `projects/${projectId}/plan`;
	if (isProjectSummary) return `projects/${projectId}/summary`;

	return null;
};

export const getFilteredByPageData = (
	data,
	isDashboard,
	isProjectsProject,
	isProjectsProjectPlan,
	isProjectsProjectSummary,
	projectId,
	generalPage
) => {
	if (isDashboard) return data;

	if (isProjectsProject) {
		return data?.filter(({ link }) => {
			const linkSplitted = getLinkSplitted(link);

			return projectId === linkSplitted[1];
		});
	}

	if (isProjectsProjectPlan) {
		return data?.filter(({ link }) => {
			const linkSplitted = getLinkSplitted(link);

			return projectId === linkSplitted[1] && linkSplitted[2] === 'plan';
		});
	}

	if (isProjectsProjectSummary) {
		return data?.filter(({ link }) => {
			const linkSplitted = getLinkSplitted(link);

			return projectId === linkSplitted[1] && linkSplitted[2] === 'summary';
		});
	}

	return data?.filter(({ link }) => {
		const linkSplitted = getLinkSplitted(link);

		return generalPage === linkSplitted[0];
	});
};

export const getLinkTitleWithProjectData = (
	projectId,
	projects,
	tasks,
	costs,
	temporaryLink,
	isProjectsProject,
	isProjectsProjectPlan,
	isProjectsProjectSummary,
	intl
) => {
	const temporaryLinkSplitted = temporaryLink?.splitted ?? [];

	const projectIndex = temporaryLinkSplitted.findIndex(item => projects?.data.findIndex(project => project.projectId === item));
	const findId = isProjectsProjectPlan || isProjectsProject ? projectId : temporaryLinkSplitted[projectIndex];
	const project = findItemByIdWithField(projects?.data, 'projectId', findId);

	const projectName = project?.projectName;

	const taskIndex = temporaryLinkSplitted.findIndex(item => tasks?.findIndex(task => task.projectTasksId === item));

	const taskName = tasks[temporaryLinkSplitted[taskIndex]]?.projectTaskName;

	const costIndex = temporaryLinkSplitted.findIndex(item => costs?.findIndex(cost => cost.id === item));
	const costName = costs[temporaryLinkSplitted[costIndex]]?.name;

	const projectsName = intl.formatMessage({ id: 'collaboration.projects.general' });

	const baseStr = `${projectsName}${separator}${projectName}${separator}`;
	const projectPlanStr = `${baseStr}${intl.formatMessage({ id: 'collaboration.projects.projectplan' })}`;
	const projectSummaryStr = `${baseStr}${intl.formatMessage({ id: 'collaboration.projects.summary' })}`;

	if (costName) return `${baseStr}${taskName}${separator}${costName}`;

	if (taskName) return `${baseStr}${taskName}`;

	if (projectName && !isProjectsProjectPlan && !isProjectsProjectSummary) return `${projectsName}${separator}${projectName}`;

	if (isProjectsProjectPlan && temporaryLink?.linkTitle) return `${projectPlanStr}${separator}${temporaryLink.linkTitle}`;

	if (isProjectsProjectSummary && temporaryLink?.linkTitle) return `${projectSummaryStr}${separator}${temporaryLink.linkTitle}`;

	if (projectName && isProjectsProjectPlan && temporaryLinkSplitted?.length === 4) {
		// get project plan child items
		return `${projectPlanStr}${separator}${intl.formatMessage({
			id: `collaboration.projects.projectplan.${temporaryLinkSplitted[3]}`,
		})}`;
	}

	if (projectName && isProjectsProjectPlan) return `${projectPlanStr}`;
	if (projectName && isProjectsProjectSummary) return `${projectSummaryStr}`;

	return null;
};
