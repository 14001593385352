import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';
import CustomPrompt from 'components/CustomPrompt';
import { FormControlledInput } from 'components/Form';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';

import { getDirtyKeys } from 'components/Form/utils/utils';
import { deleteProjectTask } from 'containers/Projects/slices';
import { getProjectTaskValidationSchema } from './taskValidationSchema';

const ProjectTaskForm = forwardRef(({ projectId, task, passIsDirty, setTaskLoading, isPrompt, setIsPrompt, onSubmit }, formRef) => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const taskSchema = getProjectTaskValidationSchema(intl);
	const {
		handleSubmit,
		control,
		reset,
		formState: { dirtyFields, errors },
	} = useForm({
		defaultValues: useMemo(() => {
			return task;
		}, [task]),
		resolver: yupResolver(taskSchema),
	});

	const isDirty = getDirtyKeys(dirtyFields).length > 0;

	useEffect(() => {
		passIsDirty && passIsDirty(isDirty, dirtyFields);
	}, [isDirty, dirtyFields, passIsDirty]);

	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const closeConfirm = () => {
		setAnchorEl(null);
		setConfirmOpen(false);
	};

	const handleDelete = async () => {
		setIsPrompt(false);
		setTaskLoading(true);
		closeConfirm();

		const success = await dispatch(deleteProjectTask(task.projectTaskId, projectId));

		if (success) {
			setTaskLoading(false);
			toast.success(<FormattedMessage id='project.task.delete.success' />);
		} else {
			setTaskLoading(false);
			toast.error(<FormattedMessage id='project.task.delete.failed' />);
		}
	};

	useEffect(() => {
		// ensure that in update hidden field are updated
		reset(task);
	}, [task]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<form ref={formRef} noValidate onSubmit={handleSubmit(data => onSubmit(data))}>
			{isPrompt && <CustomPrompt dirtyFields={dirtyFields} isDirty={isDirty} translation='project.task.form' />}
			<Box sx={{ mb: 2, p: '1.5rem' }}>
				{errors?.projectId?.message}
				<Controller
					name='projectTaskId'
					defaultValue={task?.projectTaskId ?? ''}
					value={task?.projectTaskId ?? ''}
					control={control}
					render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
				/>
				<Controller
					name='projectId'
					defaultValue={task?.projectId ?? projectId}
					value={task?.projectId ?? projectId}
					control={control}
					render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
				/>
				<Controller
					name='eTag'
					defaultValue={task?.eTag ?? null}
					value={task?.eTag ?? null}
					control={control}
					render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
				/>
				<Grid container direction='column' spacing={2}>
					<Grid item>
						<FormControlledInput
							defaultValue={task?.projectTaskName ?? ''}
							name='projectTaskName'
							control={control}
							error={errors?.projectTaskName?.message}
							label={intl.formatMessage({ id: 'project.task.form.projectTaskName' })}
							instructions={intl.formatMessage({ id: 'project.task.form.projectTaskName.instructions' })}
							placeholder={intl.formatMessage({ id: 'project.task.form.projectTaskName.placeholder' })}
						/>
					</Grid>
					<Grid item>
						<FormControlledInput
							defaultValue={task?.projectTaskCategory ?? ''}
							name='projectTaskCategory'
							control={control}
							error={errors?.projectTaskCategory?.message}
							placeholder={intl.formatMessage({ id: 'project.task.form.projectTaskCategory.placeholder' })}
							variant='standard'
						/>
					</Grid>
					<Grid item>
						<FormControlledInput
							defaultValue={task?.projectTaskCategory ?? ''}
							name='projectTaskCategory'
							control={control}
							error={errors?.projectTaskCategory?.message}
							placeholder={intl.formatMessage({ id: 'project.task.form.projectTaskCategory.placeholder' })}
							variant='standard'
						/>
					</Grid>
					<Grid item>
						<FormControlledWysiwyg
							marginTop={0}
							inline={true}
							fixedToolbar={true}
							toolbar={true}
							name='projectTaskDescription'
							control={control}
							defaultValue={task?.projectTaskDescription ?? ''}
							error={errors?.projectTaskDescription?.message}
							label={intl.formatMessage({ id: 'project.task.form.projectTaskDescription' })}
							instructions={intl.formatMessage({ id: 'project.task.form.projectTaskDescription.instructions' })}
							placeholder={intl.formatMessage({ id: 'project.task.form.projectTaskDescription.instructions' })}
						/>
					</Grid>
					<Grid item>
						<FormControlledWysiwyg
							marginTop={0}
							inline={true}
							fixedToolbar={true}
							toolbar={true}
							name='projectTaskGoals'
							control={control}
							defaultValue={task?.projectTaskGoals ?? ''}
							error={errors?.projectTaskGoals?.message}
							label={intl.formatMessage({ id: 'project.task.form.projectTaskGoals' })}
							instructions={intl.formatMessage({ id: 'project.task.form.projectTaskGoals.instructions' })}
							placeholder={intl.formatMessage({ id: 'project.task.form.projectTaskGoals.instructions' })}
						/>
					</Grid>
				</Grid>
			</Box>
			<ConfirmPopper
				confirmAction={handleDelete}
				handleClose={closeConfirm}
				isopen={confirmOpen}
				anchorElement={anchorEl}
				confirmText={intl.formatMessage({ id: 'project.task.delete.confirmText' })}
				cancelText={intl.formatMessage({ id: 'project.task.delete.cancelText' })}
				confirmBodyText={intl.formatMessage({ id: 'project.task.delete.confirmBodyText' })}
			/>
		</form>
	);
});

export default ProjectTaskForm;
