import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import MessagesList from './MessagesList';
import AlertMessage from './AlertMessage';
import MainTitle from 'components/Titles/MainTitle';
import ContentContainer from 'components/Containers/ContentContainer';

const NotificationCenter = ({ messages, markMessageAsRead, closeMessagesDrawer }) => {
    const intl = useIntl();

    const messagesNumber = messages?.length && messages.filter(({ isRead }) => !isRead).length;

    return (
        <ContentContainer>
            <Box
                sx={{
                    position: closeMessagesDrawer && 'sticky',
                    top: closeMessagesDrawer && 0,
                    zIndex: 10,
                }}
            >
                <Box display='flex' sx={{ justifyContent: 'space-between' }}>
                    <MainTitle>{intl.formatMessage({ id: 'notification.center' })}</MainTitle>
                    {closeMessagesDrawer && (
                        <IconButton aria-label='close' onClick={closeMessagesDrawer}>
                            <CloseIcon color='primary' />
                        </IconButton>
                    )}
                </Box>
                {messagesNumber > 0 && <AlertMessage number={messagesNumber} />}
            </Box>
            <MessagesList {...{ messages, markMessageAsRead }} />
        </ContentContainer>
    );
};

export default NotificationCenter;
