import { ArticleOutlined as ArticleOutlinedIcon, Lock as LockIcon } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'components/Button/Button';
import CenteringContainer from 'components/Containers/CenteringContainer';
import AnalysisTrialDialog from './AnalysisTrialDialog';
import Pill from './Pill';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { isFreemiumToken, isTrialToken } from 'utils/auth/company';
import getCompanyLink from 'utils/auth/getCompanyLink';
import { matchPath } from 'utils/helpers/pathMatch';

const useStyles = makeStyles(() => ({
	box: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textTransform: 'uppercase',
	},
	scorebox: {
		textAlign: 'center',
		color: '#fff',
		fontWeight: 600,
		fontSize: '2rem',
		marginBottom: '-0.8rem',
		width: '100%',
	},
	score: {
		width: '3.8rem',
		display: 'inline-block',
		position: 'relative',
		bottom: '1.5rem',
	},
	floater: {
		width: '4rem',
		display: 'inline-block',
		borderTop: '2px dashed rgba(255, 255, 255, 0.47)',
		height: '1.5rem',
		'&:first-child': {
			borderLeft: '2px dashed rgba(255, 255, 255, 0.47)',
		},
		'&:last-child': {
			borderRight: '2px dashed rgba(255, 255, 255, 0.47)',
		},
	},
}));

const ScoreBox = ({ score, missingValues, openProfileModal, profile = false, hideAnalysis = false, analysisLinkUrl }) => {
	const classes = useStyles();
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const analysisLink = analysisLinkUrl ?? getCompanyLink('/analysis', location);

	const scoreLevel = {
		LOW: 'low',
		MED: 'med',
		HIGH: 'high',
	};

	const getScoreLevelGrant = score => {
		if (score > 85) return scoreLevel.HIGH;
		if (score <= 85 && score >= 60) return scoreLevel.MED;
		if (score < 60) return scoreLevel.LOW;
	};
	const getScoreLevelLoan = score => {
		if (score > 80) return scoreLevel.HIGH;
		if (score <= 80 && score >= 50) return scoreLevel.MED;
		if (score < 50) return scoreLevel.LOW;
	};
	const getScoreLevelInvestment = score => {
		if (score > 80) return scoreLevel.HIGH;
		if (score <= 80 && score >= 40) return scoreLevel.MED;
		if (score < 40) return scoreLevel.LOW;
	};

	const isTrial = isTrialToken();
	const isFreemium = isFreemiumToken();
	const isAnalysisPage = matchPath('/analysis', location, false);

	const isMissingValues = missingValues?.length !== 0;

	const MissingValuesInstructions = () => {
		if (!isMissingValues) {
			return (
				<div className={classes.scorebox}>
					<div className={classes.floater}>&nbsp;</div>
					<div className={classes.score}>{score?.grantedScore}</div>
					<div className={classes.floater}>&nbsp;</div>
				</div>
			);
		}

		return (
			<Box
				display='flex'
				sx={{
					flexDirection: 'column',
					alignItems: 'center',
					mb: 1,
				}}
			>
				<Stack direction='row' spacing={0.5}>
					{!profile ? (
						<Link
							component='button'
							onClick={openProfileModal}
							sx={{
								fontSize: '1rem',
								fontFamily: 'Poppins, sans-serif',
								color: 'primary.white',
								textDecorationColor: 'white',
								lineHeight: 1.5,
							}}
						>
							{intl.formatMessage({ id: 'score.link.fill.profile' })}
						</Link>
					) : (
						<Typography sx={{ color: 'pill.secondary' }}>{intl.formatMessage({ id: 'score.link.fill.profile' })}</Typography>
					)}
					<Typography sx={{ color: 'pill.secondary' }}>{intl.formatMessage({ id: 'score.fill.profile.text1' })}</Typography>
				</Stack>
				<Typography sx={{ color: 'pill.secondary' }}>{intl.formatMessage({ id: 'score.fill.profile.text2' })}</Typography>
			</Box>
		);
	};

	return (
		<>
			<Stack direction='row' alignItems='flex-end' spacing={0}>
				<Box>
					<h3>{intl.formatMessage({ id: 'score.title' })}</h3>
					<MissingValuesInstructions />
					<div className={classes.box}>
						<Pill
							disabled={isMissingValues}
							title={intl.formatMessage({ id: 'score.grant' })}
							score={score?.grant}
							highText={intl.formatMessage({ id: 'score.grant.high' })}
							mediumText={intl.formatMessage({ id: 'score.grant.medium' })}
							lowText={intl.formatMessage({ id: 'score.grant.low' })}
							getScoreLevel={getScoreLevelGrant}
						/>
						<Pill
							disabled={isMissingValues}
							title={intl.formatMessage({ id: 'score.loan' })}
							score={score?.loan}
							highText={intl.formatMessage({ id: 'score.loan.high' })}
							mediumText={intl.formatMessage({ id: 'score.loan.medium' })}
							lowText={intl.formatMessage({ id: 'score.loan.low' })}
							getScoreLevel={getScoreLevelLoan}
						/>
						<Pill
							disabled={isMissingValues}
							title={intl.formatMessage({ id: 'score.investment' })}
							score={score?.investment}
							highText={intl.formatMessage({ id: 'score.investment.high' })}
							mediumText={intl.formatMessage({ id: 'score.investment.medium' })}
							lowText={intl.formatMessage({ id: 'score.investment.low' })}
							getScoreLevel={getScoreLevelInvestment}
						/>
					</div>
				</Box>
			</Stack>
			{!isAnalysisPage && !hideAnalysis && (
				<CenteringContainer>
					{isTrial ? (
						<AnalysisTrialDialog sx={{ mt: 2 }} />
					) : isFreemium ? (
						<Button
							color={'pill'}
							disabled={false}
							startIcon={<LockIcon />}
							onClick={() => dispatch(openDialog({ name: 'FreemiumCTADialog' }))}
							sx={{ mt: 2 }}
						>
							{intl.formatMessage({ id: 'score.button.analysis' })}
						</Button>
					) : (
						<Button
							color={isMissingValues ? 'pill' : 'success'}
							disabled={isMissingValues ? true : false}
							startIcon={<ArticleOutlinedIcon />}
							onClick={() => history.push(analysisLink)}
							sx={{ mt: 2 }}
						>
							{intl.formatMessage({ id: 'score.button.analysis' })}
						</Button>
					)}
				</CenteringContainer>
			)}
		</>
	);
};

export default ScoreBox;
