import axios from 'utils/axios';
import { buildPlainUri, buildUri, validateHttpStatus } from 'utils/helpers/uriHelper';

const baseUrl = 'analysis';

class AnalysisApi {
	async getAnalysis() {
		const url = buildUri(baseUrl);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
	async getAnalysisByLink(linkId) {
		const url = buildPlainUri(baseUrl, `link/${linkId}`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
	async contactWithLink(linkId, type) {
		const url = buildPlainUri(baseUrl, `link/${linkId}?type=${type}`);

		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
	async generateAnalysisLink({ companyId, expiration = 10000, shrinked = true }) {
		const url = buildPlainUri('admin', `analysis/link/${companyId}?expiration=${expiration}&shrinked=${shrinked}`);

		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const analysisApi = new AnalysisApi();   
