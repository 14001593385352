import { Box, Grid, /* Button, Switch, */ Stack, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { FormControlledCurrencyInput, FromControlledHiddenField } from 'components/Form';
import CollaborationLauncher from 'containers/Collaboration/CollaborationLauncher';
import ChartToggleTabs from '../BusinessPlanPrint/ChartToggleTabs';
import EconomicalChart from '../BusinessPlanPrint/EconomicalChart';

import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';
import { renderKeys } from '../renderKeys';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
	backgroundColor: theme.palette.primary.white,
	paddingBottom: '2rem',
}));

const StyledEconomicalInfo = styled(Typography)(() => ({
	paddingTop: '15px',
	paddingBottom: '7px',
}));

const EconomicalTable = ({ id, control, getValues, readonly = false, onInputBlur = () => { } }) => {
	const intl = useIntl();
	const { hash } = useLocation();

	useScrollIntoViewWithOffset(id, hash);

	const currentYear = new Date().getFullYear();

	const { fields } = useFieldArray({
		control,
		name: 'economicalData',
	});

	const [economicalData, setEconomicalData] = useState(fields);
	const [view, setView] = useState(false);

	const showChartFunc = useCallback(() => {
		const values = getValues();
		setEconomicalData(values?.economicalData ?? fields);
		setView(!view);
		setTab(!view ? 1 : 0);
	});

	useEffect(() => {
		showChartFunc();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields]);

	const [tab, setTab] = useState(view ? 1 : 0);

	return (
		<StyledTableContainer id={id}>
			<Box display='flex' sx={{ justifyContent: 'space-between', alignItems: 'start' }}>
				<Stack direction='row' spacing={4} justifyContent='space-between' alignItems='center' sx={{ flexGrow: 1, mb: '1rem' }}>
					<Typography sx={{ p: '1rem 0 0 1rem', mb: '1rem', fontWeight: 500, fontSize: '1.2rem' }}>
						{intl.formatMessage({ id: 'business.plan.economical.table.title' })}
					</Typography>
					<ChartToggleTabs tab={tab} setTab={showChartFunc} />
				</Stack>
				<CollaborationLauncher disabled={readonly} link={`businessplan/${id}`} sx={{ m: 1 }} />
			</Box>
			<Box sx={{ display: view && 'none' }}>
				<EconomicalChart data={economicalData} />
			</Box>
			<Grid sx={{ display: !view && 'none' }} container flexWrap='nowrap'>
				<Grid item xs sx={{ mr: '1rem', ml: '1rem', minWidth: '15rem' }}>
					{renderKeys.map((name, itemIndex) => {
						return (
							<StyledEconomicalInfo
								key={name + itemIndex}
								sx={{
									fontWeight: 500,
								}}
							>
								{intl.formatMessage({ id: `business.plan.economical.table.${name}` })}
							</StyledEconomicalInfo>
						);
					})}
				</Grid>
				{fields.map((field, index) => {
					return (
						<Grid
							item
							xs
							key={field.id + index}
							sx={{
								mr: '1rem',
							}}
						>
							<FromControlledHiddenField
								name={`economicalData.${index}.isEditable`}
								defaultValue={field.isEditable ?? false}
								control={control}
							/>
							{renderKeys.map((item, itemIndex) => {
								const uniqueKey = item + field[item] + itemIndex;

								if (item === 'year') {
									return (
										<React.Fragment key={uniqueKey}>
											<Typography
												sx={{
													fontWeight: field.year === currentYear ? 700 : 500,
													textDecoration: field.year === currentYear ? 'underline' : 'none',
												}}
											>
												{field[item]}
											</Typography>
											<FromControlledHiddenField
												name={`economicalData.${index}.year`}
												defaultValue={field.year}
												control={control}
											/>
										</React.Fragment>
									);
								}

								if (!field.isEditable || readonly) {
									return (
										<React.Fragment key={uniqueKey}>
											<FromControlledHiddenField
												name={`economicalData.${index}.${item}`}
												defaultValue={field[item] ?? 0}
												control={control}
											/>
											<StyledEconomicalInfo>{field[item] ? Math.round(field[item]) : 0}</StyledEconomicalInfo>
										</React.Fragment>
									);
								}

								return (
									<Box key={uniqueKey}>
										<FormControlledCurrencyInput
											defaultValue={field[item] ?? 0}
											name={`economicalData.${index}.${item}`}
											control={control}
											error={undefined}
											variant='standard'
											decimalScale={0}
											onInputBlur={onInputBlur}
										/>
									</Box>
								);
							})}
						</Grid>
					);
				})}
			</Grid>
		</StyledTableContainer>
	);
};

export default EconomicalTable;
