import React from 'react';
import { useIntl } from 'react-intl';

import { useGetCompanyDataQuery, useGetInstrumentsDataQuery } from 'containers/Stakeholder/stakeholderApi';
import Instruments from 'containers/Instruments';

import Error from '../Components/Error';
import Loader from '../Components/Loader/Loader';

const InstrumentsContainer = ({ linkId }) => {
    const intl = useIntl();

    const {
        data,
        isLoading,
        isFetching,
        isSuccess
    } = useGetInstrumentsDataQuery(linkId);

    const {
        data: company,
        isLoading: isCompanyLoading,
        isFetching: isCompanyFetching,
        isSuccess: isCompanySuccess
    } = useGetCompanyDataQuery(linkId);

    if (isLoading || isFetching || isCompanyLoading || isCompanyFetching) return <Loader />;

    if (!isSuccess || !isCompanySuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.instruments.error' })}</Error>;

    return (
        <>
            <Instruments
                loading={isLoading || isFetching}
                instruments={data.instruments}
                currentInstrumentId={null}
                company={company?.company}
                pathname={null}
                costs={null}
                openProfileModal={null}
                readonly={true}
                profile={true}
            />
        </>
    );
};

export default InstrumentsContainer;