import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Calculate from '@mui/icons-material/Calculate';
import RemoveIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, IconButton, InputAdornment, Stack, Tooltip, Typography } from '@mui/material';
import AddButton from 'components/Button/AddButton';
import ConfirmButton from 'components/Button/ConfirmButton';
import BoxContainer from 'components/Containers/BoxContainer';
import { FormControlledCurrencyInput, FormControlledInput } from 'components/Form';
import { map } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Instrument, Project, ProjectTask, ProjectTaskGroup } from 'types/dataTypes';
import { generateCurrentTimeTicks } from 'utils/helpers/dateExtension';
import { projectCostSelector } from '../../../slices';
import { calculateOptimalFlatrateSum, parseCosts } from '../../ProjectPlan/ProjectPlanPrint/CostsTable/CostsTable';
import ProjectTasksForm from './ProjectTasksForm';
import RatesFormHelper from './RateFormHelper/RatesFormHelper';

type Group = {
	name: string;
	description: string | null;
	tasks: Array<ProjectTask> | null;
};
type GroupProps = {
	project: Project;
	control: any;
	errors: any;
	watch: any;
	setValue: any;
	instrument?: Instrument;
};

const ProjectTaskGroupFormFields: React.FC<{
	projectTaskGroup: ProjectTaskGroup;
	index: number;
	control: any;
	errors: any;
	project: Project;
	watch: any;
	setValue: any;
	remove: any;
	count: number;
	move: any;
	instrument?: Instrument;
}> = ({ projectTaskGroup, index, control, errors, project, watch, setValue, remove, move, count, instrument }) => {
	const { formatMessage } = useIntl();

	// const name = watch(`projectTaskGroups[${index}].groupName`);

	return (
		<BoxContainer>
			<Controller
				name={`projectTaskGroups[${index}].sortOrder`}
				defaultValue={index} // @ts-ignore
				value={index}
				control={control}
				render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
			/>
			<Controller
				name={`projectTaskGroups[${index}].projectTaskGroupId`}
				defaultValue={projectTaskGroup?.projectTaskGroupId ?? ''}
				control={control}
				render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
			/>
			<Controller
				name={`projectTaskGroups[${index}].projectId`}
				defaultValue={projectTaskGroup?.projectId ?? ''}
				control={control}
				render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
			/>
			<Controller
				name={`projectTaskGroups[${index}].timelineId`}
				defaultValue={projectTaskGroup?.timelineId ?? ''}
				control={control}
				render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
			/>

			{/* <Typography component='h3' id={`projectTaskGroups[${index}].projectTaskGroupId`} className='panelTitle'>
				{name}
			</Typography> */}
			<Typography component='div' id={`projectTaskGroups[${index}].projectTaskGroupId`} className='panelTitle'>
				<FormControlledInput
					defaultValue={projectTaskGroup?.groupName ?? ''}
					name={`projectTaskGroups[${index}].groupName`}
					control={control}
					error={errors?.projectTaskGroup?.groupName?.message}
					placeholder={formatMessage({ id: 'project.task.form.projectTaskGroupName' })}
					variant='standard'
				/>
			</Typography>

			<ProjectTasksForm
				tasks={projectTaskGroup.tasks ?? []}
				{...{
					project,
					control,
					errors,
					projectTaskGroupId: projectTaskGroup.projectTaskGroupId,
					groupIndex: `projectTaskGroups[${index}]`,
					watch,
					setValue,
					instrument,
				}}
			/>
			<Box>
				<Stack flexDirection='row' justifyContent='space-between' sx={{ width: '100%' }}>
					<ConfirmButton
						size='small'
						color='error'
						startIcon={<RemoveIcon />}
						confirmAction={() => remove(index)}
						confirmText={formatMessage({ id: 'project.task.group.delete' })}
						cancelText={formatMessage({ id: 'user.deactivate.cancel' })}
						confirmBodyText={formatMessage({ id: 'project.task.group.delete.confirm' })}
						buttonText={formatMessage({ id: 'project.task.group.delete' })}
						disabled={undefined}
						children={undefined}
						stopPropagation={undefined}
					/>
					<Box>
						<Tooltip title='Delegoi tehtäväryhmä ylemmälle tasolle hierarkiassa'>
							<span>
								<IconButton disabled={index === 0} onClick={() => move(index, index - 1)}>
									<KeyboardArrowUp color='primary' />
								</IconButton>
							</span>
						</Tooltip>
						<Tooltip title='Delegoi tehtäväryhmä alemmalle tasolle hierarkiassa'>
							<span>
								<IconButton disabled={count - 1 === index} onClick={() => move(index, index + 1)}>
									<KeyboardArrowDown color='primary' />
								</IconButton>
							</span>
						</Tooltip>
					</Box>
				</Stack>
			</Box>
		</BoxContainer>
	);
};

type ProjectTaskPredefinedProps = {
	project: Project;
	control: any;
	errors: any;
	instrument?: Instrument;
};
const ProjectTaskPredefinedValues: React.FC<ProjectTaskPredefinedProps> = ({ project, control, errors, instrument }) => {
	const { formatMessage } = useIntl();
	const { getValues, setValue } = useFormContext();

	const costs = projectCostSelector(project);
	const parsedCosts = parseCosts(costs);

	const flatRateDevelopment = useWatch({ control, name: 'flatRateDevelopment' });
	const flatRateDevelopmentSum = useWatch({ control, name: 'flatRateDevelopmentSum' });

	const sum = ((project.projectSize ?? 0) * flatRateDevelopment) / 100;

	const isFlatRateDevelopmentChanging = useRef(false);
	const isFlatRateDevelopmentSumChanging = useRef(false);

	useEffect(() => {
		if (!isFlatRateDevelopmentChanging.current) {
			isFlatRateDevelopmentSumChanging.current = true;

			setValue('flatRateDevelopmentSum', sum);
		}
		isFlatRateDevelopmentChanging.current = false;
	}, [flatRateDevelopment, sum, setValue]);

	useEffect(() => {
		if (!isFlatRateDevelopmentSumChanging.current) {
			const newFlatRateDevelopment: number = (flatRateDevelopmentSum / (project.projectSize ?? 0)) * 100;
			isFlatRateDevelopmentChanging.current = true;

			setValue('flatRateDevelopment', newFlatRateDevelopment);
		}
		isFlatRateDevelopmentSumChanging.current = false;
	}, [flatRateDevelopmentSum, parsedCosts.costByCategory.development, project.projectSize, setValue]);

	return (
		<BoxContainer>
			<Grid container spacing={2}>
				<Grid item xs={12} md={3} lg={3}>
					<FormControlledInput
						type='number'
						defaultValue={project?.flatRateDevelopment ?? ''}
						name='flatRateDevelopment'
						control={control}
						error={errors?.flatRateDevelopment?.message}
						label={formatMessage({ id: 'project.form.flatRateDevelopment' })}
						startAdornment={<InputAdornment position='start'>{'%'}</InputAdornment>}
					/>
					<FormControlledCurrencyInput
						defaultValue={(project.projectSize ?? 0) * ((project?.flatRateDevelopment ?? 0) / 100)}
						name='flatRateDevelopmentSum'
						control={control}
						label={formatMessage({ id: 'project.form.flatRateDevelopmentSum' })}
					/>
					<Button
						sx={{ mt: '.5rem' }}
						size='medium'
						color='primary'
						variant='contained'
						onClick={() => {
							// @ts-ignore
							const missingFlatRate = calculateOptimalFlatrateSum(getValues(), 'development', instrument);

							setValue('flatRateDevelopment', missingFlatRate.missingFlatRatePercent);
							if (missingFlatRate.missingFlatRatePercent > 20) {
								toast.warning(formatMessage({ id: 'project.form.flatRateDevelopment.over' }));
							}
						}}
						endIcon={<Calculate />}
					>
						{formatMessage({ id: 'project.form.flatRateDevelopment.optimate-button' })}
					</Button>
				</Grid>
				<Grid item xs={12} md={3} lg={3}>
					<FormControlledInput
						type='number'
						defaultValue={project?.flatRateInvestment ?? ''}
						name='flatRateInvestment'
						control={control}
						error={errors?.flatRateInvestment?.message}
						label={formatMessage({ id: 'project.form.flatRateInvestment' })}
						startAdornment={<InputAdornment position='start'>{'%'}</InputAdornment>}
					/>
				</Grid>
				<Grid item xs={12} md={3} lg={3}>
					<FormControlledInput
						type='number'
						defaultValue={project?.personnelSideCost ?? ''}
						name='personnelSideCost'
						control={control}
						error={errors?.personnelSideCost?.message}
						label={formatMessage({ id: 'project.form.personnelSideCost' })}
						startAdornment={<InputAdornment position='start'>{'%'}</InputAdornment>}
					/>
				</Grid>
				<Grid item xs={12} md={3} lg={3}>
					<FormControlledInput
						type='number'
						defaultValue={project?.advancePayment ?? ''}
						name='advancePayment'
						control={control}
						error={errors?.advancePayment?.message}
						label={formatMessage({ id: 'project.form.advancePayment' })}
						startAdornment={<InputAdornment position='start'>{'%'}</InputAdornment>}
					/>
				</Grid>
			</Grid>
			<RatesFormHelper />
		</BoxContainer>
	);
};

const ProjectTaskGroupForm: React.FC<GroupProps> = ({ project, control, errors, watch, setValue, instrument }) => {
	const { fields, move, remove, append } = useFieldArray({
		control,
		name: 'projectTaskGroups',
	});
	// const flatRateDevelopment = useWatch({
	// 	control,
	// 	name: 'flatRateDevelopment',
	// });
	// const flatRateInvestment = useWatch({
	// 	control,
	// 	name: 'flatRateInvestment',
	// });
	// const personnelSideCost = useWatch({
	// 	control,
	// 	name: 'personnelSideCost',
	// });
	// const advancePayment = useWatch({
	// 	control,
	// 	name: 'advancePayment',
	// });

	const handleMoveTaskGroup = (from: number, to: number) => {
		move(from, to);
		updateIndices();
	};

	const updateIndices = () => {
		fields.forEach((_, index) => {
			setValue(`projectTaskGroups.${index}.sortOrder`, index);
		});
	};

	return (
		<Stack>
			<ProjectTaskPredefinedValues {...{ project, control, errors, instrument }} />
			{map(fields, (field: ProjectTaskGroup, index: number) => {
				return (
					<Box key={field.id} sx={{ mt: '1rem' }}>
						<ProjectTaskGroupFormFields
							projectTaskGroup={field}
							{...{
								control,
								index,
								errors,
								project,
								watch,
								setValue,
								remove,
								move: handleMoveTaskGroup,
								count: fields?.length ?? 0,
								instrument,
							}}
						/>
					</Box>
				);
			})}
			<Stack>
				{/* @ts-ignore */}
				<AddButton
					onClick={() =>
						append({
							groupName: null,
							description: '',
							companyId: project.companyId ?? '',
							timelineId: project.timelineId ?? '',
							projectId: project.projectId ?? '',
							projectTaskGroupId: `${project.projectId}_${generateCurrentTimeTicks()}`,
							tasks: [],
						})
					}
				>
					<FormattedMessage id='project.form.taskgroup.add' />
				</AddButton>
			</Stack>
		</Stack>
	);
};

export default ProjectTaskGroupForm;
