import React from 'react';

import { Tab, Tabs } from '@mui/material';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

export default function ChartToggleTabs({ tab = 0, setTab, }) {
    const handleChange = (_, tab) => setTab(tab);

    return (
        <Tabs value={tab} onChange={handleChange} >
            <Tab label={<BarChartOutlinedIcon />} />
            <Tab label={<FormatListBulletedOutlinedIcon />} />
        </Tabs>
    );
}
