import React from 'react';
import { useIntl } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        border: `1px solid ${theme.palette.secondary.border}`,
        borderRadius: '5px',
        padding: theme.spacing(1),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        minWidth: 250,
        '&:hover': {
            cursor: 'pointer',
            borderColor: theme.palette.secondary.grey400,
        },
        '& .MuiInput-root': {
            color: theme.palette.primary.main,
            marginTop: 0,
        },
        '& .MuiOutlinedInput-input, &:hover .MuiInputLabel-root, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input, & .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.primary.main,
            padding: 0
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& svg': {
            top: '-0.5rem',
            right: 0
        }
    },
    label: {
        color: theme.palette.secondary.grey500,
        '&:hover': {
            cursor: 'pointer'
        },
    },
    select: {
        backgroundColor: theme.palette.primary.white,
    },
    menuItemTop: {
        color: theme.palette.textPrimary.main,
    },
    menuItem: {
        color: theme.palette.primary.main,
    },
    menuItemSelected: {
        color: theme.palette.primary.main,
    },
    text: {
        fontSize: '1.1rem',
        fontWeight: 500
    }
}));

const SelectFilter = ({ values, currentValue, setCurrentValue, label }) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();

    return (
        <FormControl fullWidth className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
                <Typography className={classes.label}>
                    {intl.formatMessage({ id: `select.label.${label}` })}
                </Typography>
            </FormLabel>
            <Select
                variant='outlined'
                value={currentValue}
                onChange={event => setCurrentValue(event.target.value)}
                classes={{
                    select: classes.select,
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            bgcolor: theme.palette.primary.white,
                        },
                    },
                }}
            >
                {values.map((value, index) => {
                    return (
                        <MenuItem
                            key={value + index}
                            value={value}
                            classes={{
                                root: classes.menuItem,
                                selected: classes.menuItemSelected,
                            }}
                        >
                            <Typography className={classes.text}>
                                {intl.formatMessage({ id: `select.currentValue.${label}.${value}` })}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default SelectFilter;
