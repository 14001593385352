import React from 'react';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const Loader = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" width='80%' height='3rem' animation="wave" />
            </Grid>
            <Grid item xs={12} md={8}>
                <Skeleton sx={{ mb: '2rem' }} variant="rectangular" width='100%' height='15rem' animation="wave" />
                <Skeleton variant="rectangular" width='100%' height='20rem' animation="wave" />
            </Grid>
            <Grid item xs={12} md={4}>
                <Skeleton sx={{ mb: '2rem' }} variant="rectangular" width='100%' height='20rem' animation="wave" />
                <Skeleton variant="rectangular" width='100%' height='20rem' animation="wave" />
            </Grid>
        </Grid>
    );
};

export default Loader;