import axios from 'utils/axios';
import { buildUri, validateHttpStatus, buildPlainUri } from 'utils/helpers/uriHelper';

const baseUrl = 'company';

class CompanyApi {
	async getCompanyData() {
		const url = buildUri(baseUrl, '');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getCompanyDataById(companyId) {
		const url = buildPlainUri(baseUrl, companyId);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async createCompany(company) {
		const url = buildPlainUri(baseUrl, 'create');
		const response = await axios.post(url, company, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getChatLastVisit(companyId) {
		const url = buildPlainUri(baseUrl, `${companyId}/chat`);
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveChatLastVisit(companyId) {
		const url = buildPlainUri(baseUrl, `${companyId}/chat`);
		const response = await axios.post(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getCompanyCustomer() {
		const url = buildUri(baseUrl, 'companydata');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });
		return response.data;
	}
}

export const companyApi = new CompanyApi();
