import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import BoxContainer from 'components/Containers/BoxContainer';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import Subtitle from 'components/Titles/Subtitle';

import { Stack } from '@mui/material';
import CollaborationLauncher from 'containers/Collaboration/CollaborationLauncher';
import { maxLength } from '../ProjectValidationSchema';

const ProjectFormExtended = ({
	project,
	control,
	register,
	unregister,
	errors,
	showExtended,
	setInstruction,
	getValues,
	updateLocalBackup = () => { },
}) => {
	const intl = useIntl();

	useEffect(() => {
		register('files');

		return () => {
			unregister('files');
		};
	}, [register, unregister]);

	return (
		<>
			<Stack direction='row' alignContent='space-between' alignItems='center' sx={{ mt: '2rem' }}>
				<Subtitle id='projectFormBaseExtended' className='panelTitle' mb={0} padding={0} mt={0} sx={{ flexGrow: 1 }}>
					{intl.formatMessage({ id: 'project.form.base.extended.title' })}
				</Subtitle>
				<CollaborationLauncher
					linkTitle={intl.formatMessage({ id: 'project.form.base.extended.title' })}
					link={`projects/${project.projectId}/plan/projectFormBaseExtended`}
					sx={{ m: 1 }}
				/>
			</Stack>
			<BoxContainer show={showExtended} sx={{ mt: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} id='projectBackground'>
						<FormControlledWysiwyg
							inline={true}
							disabled={project?.mock}
							toolbar={true}
							name='projectBackground'
							placeholder={intl.formatMessage({ id: 'project.form.projectBackground.instructions' })}
							control={control}
							defaultValue={project?.projectBackground ?? ''}
							error={errors?.projectBackground?.message}
							label={intl.formatMessage({ id: 'project.form.projectBackground' })}
							instructions={intl.formatMessage({ id: 'project.form.projectBackground.instructions' })}
							setInstruction={setInstruction}
							charactersLimit={maxLength}
							onBlurAction={() => updateLocalBackup(getValues())}
						/>
					</Grid>
					<Grid item xs={12} id='projectService'>
						<FormControlledWysiwyg
							inline={true}
							disabled={project?.mock}
							toolbar={true}
							name='projectService'
							placeholder={intl.formatMessage({ id: 'project.form.projectService.instructions' })}
							control={control}
							defaultValue={project?.projectService ?? ''}
							error={errors?.projectService?.message}
							label={intl.formatMessage({ id: 'project.form.projectService' })}
							instructions={intl.formatMessage({ id: 'project.form.projectService.instructions' })}
							setInstruction={setInstruction}
							charactersLimit={maxLength}
							onBlurAction={() => updateLocalBackup(getValues())}
						/>
					</Grid>
					<Grid item xs={12} id='projectMarket'>
						<FormControlledWysiwyg
							inline={true}
							disabled={project?.mock}
							toolbar={true}
							name='projectMarket'
							placeholder={intl.formatMessage({ id: 'project.form.projectMarket.instructions' })}
							control={control}
							defaultValue={project?.projectMarket ?? ''}
							error={errors?.projectMarket?.message}
							label={intl.formatMessage({ id: 'project.form.projectMarket' })}
							instructions={intl.formatMessage({ id: 'project.form.projectMarket.instructions' })}
							setInstruction={setInstruction}
							charactersLimit={maxLength}
							onBlurAction={() => updateLocalBackup(getValues())}
						/>
					</Grid>
					<Grid item xs={12} id='projectVision'>
						<FormControlledWysiwyg
							inline={true}
							disabled={project?.mock}
							toolbar={true}
							name='projectVision'
							placeholder={intl.formatMessage({ id: 'project.form.projectVision.instructions' })}
							control={control}
							defaultValue={project?.projectVision ?? ''}
							error={errors?.projectVision?.message}
							label={intl.formatMessage({ id: 'project.form.projectVision' })}
							instructions={intl.formatMessage({ id: 'project.form.projectVision.instructions' })}
							setInstruction={setInstruction}
							charactersLimit={maxLength}
							onBlurAction={() => updateLocalBackup(getValues())}
						/>
					</Grid>
					<Grid item xs={12} id='projectDevelopment'>
						<FormControlledWysiwyg
							inline={true}
							disabled={project?.mock}
							toolbar={true}
							name='projectDevelopment'
							placeholder={intl.formatMessage({ id: 'project.form.projectDevelopment.instructions' })}
							control={control}
							defaultValue={project?.projectDevelopment ?? ''}
							error={errors?.projectDevelopment?.message}
							label={intl.formatMessage({ id: 'project.form.projectDevelopment' })}
							instructions={intl.formatMessage({ id: 'project.form.projectDevelopment.instructions' })}
							setInstruction={setInstruction}
							charactersLimit={maxLength}
							onBlurAction={() => updateLocalBackup(getValues())}
						/>
					</Grid>
				</Grid>
			</BoxContainer>
		</>
	);
};

export default ProjectFormExtended;
