import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import tolClasses from 'utils/constants/tolClasses.json';

const useStyles = makeStyles(theme => ({
	input: {
		'&::placeholder': {
			opacity: 1,
			color: theme.palette.primary.main,
		},
	},
}));

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const TolOptionItem = ({ selected, option, onClick }) => {
	return (
		<li>
			<Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} onClick={onClick} />
			{`(${option.targetCode}) - ${option.targetName}`}
		</li>
	);
};

const paddingDisable = {
	'& > div': {
		padding: '0 !important',
		paddingRight: '4rem !important',
		'& input': {
			padding: '0 !important',
		},
		'& .MuiAutocomplete-endAdornment': {
			top: '-10px',
			right: '0 !important',
		},
	},
};

const TolTextField = ({ disablePadding, params, loading }) => {
	const classes = useStyles();

	let sx = disablePadding ? paddingDisable : {};

	return (
		<TextField
			sx={sx}
			{...params}
			placeholder='Valitse...'
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<React.Fragment>
						{loading ? <CircularProgress color='inherit' size={20} /> : null}
						{params.InputProps.endAdornment}
					</React.Fragment>
				),
				classes: { input: classes.input },
			}}
		/>
	);
};

export default function TolSelector({ name, multiple = true, defaultValue = [], control, disablePadding = false, disabled }) {
	const intl = useIntl();

	const [searchPattern, setSearchPattern] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	if (typeof defaultValue === 'string') {
		const defaultTol = tolClasses.find(item => item.targetCode === defaultValue);

		if (defaultTol) {
			defaultValue = defaultTol;
		}
	}

	useEffect(() => {
		let active = true;
		setLoading(true);

		(async () => {
			if (!searchPattern) {
				setOptions([]);

				setLoading(false);
				return;
			}

			// filter numbers after two digits and if text four characters -> slow rendering if more values
			if ((!isNaN(searchPattern) && searchPattern?.length < 2) || (isNaN(searchPattern) && searchPattern?.length < 4)) {
				setOptions([]);

				setLoading(false);
				return;
			}

			const results = tolClasses.filter(item => {
				const nameStarts =
					item.targetCode.toLowerCase().startsWith(searchPattern.toLowerCase()) ||
					item.targetName.toLowerCase().includes(searchPattern.toLowerCase());

				return nameStarts;
			});

			if (active) {
				setOptions([...results]);
				setOpen(results.length > 0);
				setLoading(false);
			}
		})();

		return () => {
			active = false;
		};
	}, [searchPattern]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	const getOptionLabel = option => {
		if (typeof option === 'string') {
			option = tolClasses.find(item => item.targetCode === option);
		}

		if (!option) return '';

		if (!option.targetCode && !option.targetName) {
			return '';
		} else {
			return `(${option.targetCode}) - ${option.targetName}`;
		}
	};

	const handleIsOptionEqualToValue = (option, value) => {
		if (typeof value === 'string' || value instanceof String) {
			return option.targetCode === value;
		} else {
			for (let key of Object.keys(option)) {
				if (option[key] !== value[key]) {
					return false;
				}

				return true;
			}
		}

		return false;
	};

	const handleOnChange = (data, onChange) => {
		if (!data) return;

		setOpen(false);

		if (!multiple)
			// return string value only
			return onChange(data.targetCode);

		return onChange(data);
	};

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { value, onChange } }) => {
				return (
					<Autocomplete
						id='tol-class-select'
						noOptionsText={
							searchPattern
								? intl.formatMessage({ id: 'instrument.edit.form.tols.noopt' })
								: intl.formatMessage({ id: 'instrument.edit.form.tols.noopt.instructions' })
						}
						multiple={multiple}
						value={value}
						disableCloseOnSelect
						open={open}
						groupBy={option => option.sourceName}
						onOpen={() => setOpen(true)}
						onClose={() => setOpen(false)}
						isOptionEqualToValue={(option, value) => handleIsOptionEqualToValue(option, value)}
						onInputChange={(_, data) => setSearchPattern(data)}
						onChange={(_, data) => handleOnChange(data, onChange)}
						getOptionLabel={option => getOptionLabel(option)}
						options={options}
						loading={loading}
						renderInput={params => <TolTextField {...{ disablePadding, params, loading }} />}
						renderOption={({ key, onClick }, option, { selected }) => (
							<TolOptionItem key={key} {...{ option, selected, onClick }} />
						)}
						sx={{ padding: 0 }}
						disabled={disabled}
					/>
				);
			}}
		/>
	);
}
