import { useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectors/injectReducer';

import { mrrApi } from './mrrApi';
import MRRData from './MRRData';

const MRR = () => {
	useInjectReducer({ key: mrrApi.reducerPath, reducer: mrrApi.reducer }); // async process, wait until loaded the state

	const mrr = useSelector((state: any) => state?.mrr);

	if (!mrr) return null;

	return <MRRData />;
};

export default MRR;
