import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { storageKeys } from 'utils/constants/constants';
import storage from 'utils/localStorage/localStorageFunctions';

import Button from 'components/Button/Button';

const CookieConsent: React.FC = () => {
	const consentRef = React.useRef<HTMLDivElement>(null);

	const [open, setOpen] = React.useState(false);
	const [height, setHeight] = React.useState(0);

	const handleDeclineClick = React.useCallback(() => {
		setOpen(false);
		storage.set(storageKeys.COOKIE_CONSENT_KEY, 'false');
	}, []);

	const handleAcceptClick = React.useCallback(() => {
		setOpen(false);
		storage.set(storageKeys.COOKIE_CONSENT_KEY, 'true');
	}, []);

	const calculateHeight = React.useCallback(() => {
		const clientHeight = consentRef?.current?.clientHeight ?? 0;
		setHeight(clientHeight);
	}, [consentRef]);

	React.useEffect(() => {
		calculateHeight();
		window.addEventListener('resize', calculateHeight);
		return () => {
			window.removeEventListener('resize', calculateHeight);
		};
	}, [calculateHeight]);

	React.useEffect(() => {
		if (!open) return;
		const interval = setInterval(calculateHeight, 2000);
		return () => {
			clearInterval(interval);
		};
	}, [calculateHeight, open]);

	React.useEffect(() => {
		const consent = storage.get(storageKeys.COOKIE_CONSENT_KEY);
		if (consent !== 'true' && consent !== 'false') {
			setOpen(true);
		}
	}, []);

	return (
		<>
			<Box
				ref={consentRef}
				sx={{
					display: open ? 'block' : 'none',
					zIndex: theme => theme.zIndex.drawer + 1,
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					backgroundColor: 'primary.beige',
					p: '2rem',
				}}
			>
				<Stack direction={{ xs: 'column' }} spacing={2} alignItems='center' useFlexGap>
					<Typography>
						<FormattedMessage id='cookie.consent.text' />
					</Typography>
					<Stack direction='row' spacing={2} useFlexGap>
						{/* @ts-ignore */}
						<Button variant='outlined' color='primary' onClick={handleDeclineClick}>
							<FormattedMessage id='cookie.consent.decline' />
						</Button>
						{/* @ts-ignore */}
						<Button variant='contained' color='primary' onClick={handleAcceptClick}>
							<FormattedMessage id='cookie.consent.accept' />
						</Button>
					</Stack>
				</Stack>
			</Box>
			<Box className='spacer' sx={{ display: open ? 'block' : 'none', height: `${height}px` }} />
		</>
	);
};

export default CookieConsent;
