import moment from 'moment';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { useIntl } from 'react-intl';

import { Box, Skeleton } from '@mui/material';

import MrrMonthDialog from './MrrMonthDialog';

const MRRCompanyChart = ({ data, showTotal = true, loading }: any) => {
	const intl = useIntl();

	const [open, setOpen] = useState(false);
	const [monthData, setMonthData] = useState(null);

	if (loading) {
		return (
			<>
				<Skeleton variant='rectangular' width='100%' height={200} sx={{ mb: '2rem' }} />
			</>
		);
	}

	const allCompanyMonths = {
		'2021-01': {
			month: '2021-01',
			total: 0,
			proTotal: 0,
			advancedTotal: 0,
			packageTotal: 0,
			marketplaceTotal: 0,
			financialServiceTotal: 0,
			totalByType: {},
		},
		'2021-02': {
			month: '2021-02',
			total: 0,
			proTotal: 0,
			advancedTotal: 0,
			packageTotal: 0,
			marketplaceTotal: 0,
			financialServiceTotal: 0,
			totalByType: {},
		},
		...data.allCompanyMonths,
	};

	const chartSeries: any[] = Object.values(allCompanyMonths);
	const months: any[] = Object.keys(allCompanyMonths);
	const years: any[] = [];

	months.forEach(element => {
		const year = element.split('-')[0];

		const hasVal = years.find(item => item === year);
		if (!hasVal) years.push(year);
	});

	const arr: any[] = [];

	for (let i = 0; i < months.length; i++) {
		let total = parseInt(chartSeries[i].total);
		let proTotal = parseInt(chartSeries[i].totalByType['licensed'] ?? 0);
		let advancedTotal = parseInt(chartSeries[i].totalByType['advanced'] ?? 0);
		let packageTotal = parseInt(chartSeries[i].totalByType['package'] ?? 0);
		let marketplaceTotal = parseInt(chartSeries[i].totalByType['GrantsMarketplaceProvider'] ?? 0);
		let financialServiceTotal = parseInt(chartSeries[i].totalByType['GrantsFinancialService'] ?? 0);

		arr.push({
			month: months[i],
			...chartSeries[i],
			total: isNaN(total) ? 0 : total,
			proTotal: isNaN(proTotal) ? 0 : proTotal,
			advancedTotal: isNaN(advancedTotal) ? 0 : advancedTotal,
			packageTotal: isNaN(packageTotal) ? 0 : packageTotal,
			marketplaceTotal: isNaN(marketplaceTotal) ? 0 : marketplaceTotal,
			financialServiceTotal: isNaN(financialServiceTotal) ? 0 : financialServiceTotal,
			...data.monthsChurn[months[i]],
		});
	}

	const minValueGain =
		arr && arr.length > 0
			? arr?.reduce((acc, loc) => {
					let accMinVal = acc.valueGain - acc.valueLost;
					let locMinVal = loc.valueGain - loc.valueLost;

					return accMinVal < locMinVal ? { mrrChange: accMinVal, ...acc } : { mrrChange: locMinVal, ...loc };
			  })
			: null;

	const maxValueGain =
		arr && arr.length > 0
			? arr?.reduce((acc, loc) => {
					if (!showTotal) {
						const accCompare = acc.valueGain > acc.valueLost ? acc.valueGain : acc.valueLost;
						const locCompare = loc.valueGain > loc.valueLost ? loc.valueGain : loc.valueLost;

						return accCompare > locCompare ? { ...acc, compareValue: accCompare } : { ...loc, compareValue: locCompare };
					}

					return acc.total > loc.total ? { ...acc } : { ...loc };
			  })
			: null;

	let options = {
		series: [
			{
				name: intl.formatMessage({ id: 'mrr.total' }),
				type: 'column',
				data: arr.map(item => item.total ?? 0),
			},
			{
				name: intl.formatMessage({ id: 'license.form.licenseType.licensed' }),
				type: 'column',
				data: arr.map(item => item.proTotal ?? 0),
			},
			{
				name: intl.formatMessage({ id: 'license.form.licenseType.advanced' }),
				type: 'column',
				data: arr.map(item => item.advancedTotal ?? 0),
			},
			{
				name: intl.formatMessage({ id: 'license.form.licenseType.package' }),
				type: 'column',
				data: arr.map(item => item.packageTotal ?? 0),
			},
			{
				name: intl.formatMessage({ id: 'license.form.licenseType.GrantsMarketplaceProvider' }),
				type: 'column',
				data: arr.map(item => item.marketplaceTotal ?? 0),
			},
			{
				name: intl.formatMessage({ id: 'license.form.licenseType.GrantsFinancialService' }),
				type: 'column',
				data: arr.map(item => item.financialServiceTotal ?? 0),
			},
			{
				name: intl.formatMessage({ id: 'mrr.valueGain' }),
				type: 'column',
				data: arr.map(item => item.valueGain),
			},
			{
				name: intl.formatMessage({ id: 'mrr.valueLost' }),
				type: 'column',
				data: arr.map(item => item.valueLost),
			},
			{
				name: intl.formatMessage({ id: 'mrr.refunds' }),
				type: 'column',
				data: arr.map(item => item.refundValue),
			},
			{
				name: intl.formatMessage({ id: 'mrr.monthlyTotal' }),
				type: 'column',
				data: arr.map(item => item.valueGain - item.valueLost),
			},
		],
		options: {
			states: {
				normal: {
					filter: {
						type: 'none',
						value: 0,
					},
				},
				hover: {
					filter: {
						type: 'lighten',
						value: 0.15,
					},
				},
				active: {
					allowMultipleDataPointsSelection: false,
					filter: {
						type: 'darken',
						value: 0.35,
					},
				},
			},
			chart: {
				height: 600,
				onHover: (event: any, chartElement: any) => {
					event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
				},
				events: {
					click: (event: any, chartContext: any, config: any) => {
						if (config?.dataPointIndex >= 0) {
							setMonthData({ ...arr[config.dataPointIndex], ...chartSeries[config.dataPointIndex] });
							setOpen(true);
						}
					},
					xAxisLabelClick: (event: any, chartContext: any, config: any) => {
						if (config?.labelIndex >= 0) {
							setMonthData({ ...arr[config.dataPointIndex], ...chartSeries[config.dataPointIndex] });
							setOpen(true);
						}
					},
					dataPointMouseEnter: function (event: any) {
						if (event?.path?.[0]?.style) event.path[0].style.cursor = 'pointer';
					},
				},
			},
			plotOptions: {
				bar: {
					columnWidth: '70%',
				},
			},
			dataLabels: {
				enabled: false,
			},
			legend: {
				position: 'top',
				horizontalAlign: 'center',
				offsetX: -20,
				labels: {
					onHover: function (e: any) {
						e.native.target.style.cursor = 'pointer';
					},
					onLeave: function (e: any) {
						e.native.target.style.cursor = 'default';
					},
				},
			},
			xaxis: {
				categories: months,
				labels: {
					formatter: function (val: any) {
						try {
							const monthValue = val.split('-');
							const year = parseInt(monthValue[0]);
							const date = new Date(year, monthValue[1] - 1, 1);

							let monthName = moment(date).format('MMM');

							return monthName.substring(0, 3);
						} catch (error) {
							return val;
						}
					},
				},
				group: {
					style: {
						fontSize: '10px',
						fontWeight: 700,
					},
					groups: years.map(year => ({ title: year, cols: 12 })),
				},
			},
			colors: showTotal
				? ['#33b2df', '#00E39C', '#FEB019', '#f44336', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7']
				: ['#00E39C', '#f44336', '#33b2df', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7'],
			yaxis: [
				{
					labels: {
						formatter: (value: any) => Math.round(value ?? 0),
						show: true,
						align: 'right',
						minWidth: 0,
						maxWidth: 160,
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label',
						},
						offsetX: 0,
						offsetY: 0,
						rotate: 0,
					},
					title: {
						text: intl.formatMessage({ id: 'mrr.value.title' }),
					},
					max: Math.round((showTotal ? maxValueGain?.total + 2000 : maxValueGain?.compareValue + 1000) / 1000) * 1000,
					min: showTotal ? 0 : minValueGain?.mrrChange < 0 ? Math.round((minValueGain?.mrrChange - 1000) / 1000) * 1000 : 0,
				},
			],
			tooltip: {
				shared: true,
				intersect: false,
				onDatasetHover: {
					highlightDataSeries: true,
				},
				y: {
					formatter: function (val: any) {
						return `${parseInt(val)} €`;
					},
				},
			},
		},
	};

	if (!showTotal) {
		options.series = options.series.slice(6, options.series.length);
	} else {
		options.series = options.series.slice(0, 6);
	}

	return (
		<Box
			sx={{
				background: '#fff',
				maxWidth: '100%',
				overflowX: 'auto',
				overflowY: 'hidden',
				mb: '1rem',
				py: '1rem',
			}}
		>
			<MrrMonthDialog data={monthData} isOpen={open} closeModal={() => setOpen(false)} {...{ loading }} />
			{/* @ts-ignore */}
			<Chart type='bar' series={options.series} options={options.options} height={420} />
		</Box>
	);
};

export default MRRCompanyChart;
