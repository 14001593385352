import React from 'react';
import { useIntl } from 'react-intl';

import HtmlDialog from 'components/Dialog/HtmlDialog';
import Box from '@mui/material/Box';

const AnalysisTrialDialog = (props) => {
    const intl = useIntl();

    return (
        <Box {...props}>
            <HtmlDialog
                color='success'
                size='normal'
                content={
                    <div>
                        <p>{intl.formatMessage({ id: 'analysis.trial.dialog.content' })}</p>
                        <p>{intl.formatMessage({ id: 'analysis.trial.dialog.content.list.title' })}</p>
                        <ul>
                            <li>{intl.formatMessage({ id: 'analysis.trial.dialog.content.li.0' })}</li>
                            <li>{intl.formatMessage({ id: 'analysis.trial.dialog.content.li.1' })}</li>
                            <li>{intl.formatMessage({ id: 'analysis.trial.dialog.content.li.2' })}</li>
                            <li>{intl.formatMessage({ id: 'analysis.trial.dialog.content.li.3' })}</li>
                        </ul>
                    </div>
                }
                title={intl.formatMessage({ id: 'analysis.trial.dialog.title' })}
                variant='contained'
                buttonText={intl.formatMessage({ id: 'score.button.analysis' })}
                maxWidth='md'
            />
        </Box>
    );
};

export default AnalysisTrialDialog;