import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import Subtitle from 'components/Titles/Subtitle';
import { getInstrumentRatings } from 'containers/Instruments/slices';
import { FormattedMessage } from 'react-intl';
import { getAccessToken } from 'utils/auth/token';
import ExpertReview from '../ExpertReview/ExpertReview';

const InstrumentRatings = ({ id, selectInstrument, profile }) => {
	const dispatch = useDispatch();
	const [ratings, setRatings] = useState([]);

	useEffect(() => {
		const fetchRating = async () => {
			if (!getAccessToken()) return;

			const ratingsVal = await dispatch(getInstrumentRatings(id));

			setRatings(ratingsVal);
		};

		fetchRating().catch(console.error);
	}, [dispatch, id]);

	if (!ratings || ratings?.length === 0) return null;

	return (
		<Box sx={{ mt: profile ? 'unset' : 4, mb: profile ? 4 : 'unset' }}>
			<Subtitle>
				<FormattedMessage id='expert.reviews.title' />
			</Subtitle>
			<Typography color='primary'>
				<FormattedMessage id='expert.reviews.header' />
			</Typography>
			{ratings?.map((item, index) => {
				const instrument = selectInstrument(id);

				if (!instrument) return null;

				return (
					<Box key={item.instrumentRatingId + index} sx={{ mb: '2rem', mt: index === 0 ? '4rem' : '2rem' }}>
						<ExpertReview rating={item} />
						{index + 1 !== ratings.length && <Divider sx={{ my: '2rem' }} />}
					</Box>
				);
			})}
		</Box>

	);
};

export default InstrumentRatings;
