import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import InstrumentAutocomplete from 'containers/FinancialPlan/InstrumentModal/InstrumentAutocomplete';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Subtitle from 'components/Titles/Subtitle';
import Button from 'components/Button/Button';
import FormControlWithLabel from 'components/Form/FormControlWithLabel';

export default function RelatedInstruments({ instruments, errors, control, setValue }) {
    const intl = useIntl();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'instrument.relatedInstruments',
    });

    const setItem = (index, instrument) => setValue(`relatedInstruments.${index}`, instrument);

    return (
        <>
            <LeftRightContainer
                left={
                    fields.length > 0 &&
                    <Subtitle mt={0} mb={2}>
                        {intl.formatMessage({ id: 'instrument.edit.form.related' })}
                    </Subtitle>
                }
                right={
                    <Button startIcon={<AddIcon />} onClick={() => append({ name: null, instrumentId: null })}>
                        {intl.formatMessage({ id: 'instrument.edit.form.related.append' })}
                    </Button>
                }
            />
            <Grid container spacing={2} alignItems='end'>
                {
                    fields.map((field, index) => {
                        const instrument = instruments.find(({ id }) => id === field.instrumentId);

                        return (
                            <React.Fragment key={field.id + index}>
                                <Grid item xs={10} md={6}>
                                    <FormControlWithLabel fullWidth>
                                        <Controller
                                            name={`relatedInstruments.${index}.id`}
                                            defaultValue={field?.instrumentId ?? ''}
                                            control={control}
                                            render={({ field: { value, } }) => <input type='hidden' value={value ?? ''} />}
                                        />
                                        <Controller
                                            name={`relatedInstruments.${index}.name`}
                                            defaultValue={field?.name ?? ''}
                                            control={control}
                                            render={({ field: { onChange, } }) => (
                                                <InstrumentAutocomplete
                                                    variant='standard'
                                                    onChange={(e) => { onChange(e); }}
                                                    instrument={instrument}
                                                    instruments={instruments}
                                                    setNewInstrument={instrument => setItem(index, instrument)}
                                                    control={control}
                                                />
                                            )}
                                        />
                                        {errors && (
                                            <Typography component='p'>
                                                {errors?.relatedInstruments?.message}
                                            </Typography>
                                        )}
                                    </FormControlWithLabel>
                                </Grid>
                                <Grid item xs={2} md={6}>
                                    <IconButton onClick={() => remove(index)}>
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </Grid>
                            </React.Fragment>
                        );
                    })
                }
            </Grid>
        </>
    );
}