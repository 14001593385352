import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { Grid, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import LinkIcon from '@mui/icons-material/Link';
import GetAppIcon from '@mui/icons-material/GetApp';

import { generateAnalysisLink } from 'containers/Analysis/slices';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import FormControlledSwitchItem from 'components/Form/FormControlledSwitchItem';
import ModalDialog from 'components/Dialog/ModalDialog';
import FormControlledInput from 'components/Form/FormControlledInput';
import { FromControlledHiddenField } from 'components/Form';
import Button from 'components/Button/Button';
import Subtitle from 'components/Titles/Subtitle';

export default function GenerateAnalysisLinkModal({ companyId, open, setOpen }) {
    const dispatch = useDispatch();
    const intl = useIntl();

    const createdLink = useSelector(state => state.analysis?.createdLink);


    const { control, handleSubmit, } = useForm({
        defaultValues: {
            companyId: companyId,
            shrinked: true,
            expires: 15000
        },
    });

    const onSubmit = (data) => {
        dispatch(generateAnalysisLink(data));
    };

    const url = `${window.location.protocol}//${window.location.host}/analysis/${createdLink?.data?.rowKey}`;

    return (
        <ModalDialog
            open={open}
            actions={
                <LeftRightContainer
                    left={
                        <Button variant='text' color='primary' onClick={() => setOpen(false)}>
                            {intl.formatMessage({ id: 'common.close' })}
                        </Button>
                    }
                    right={
                        <Button
                            disabled={createdLink?.loading ?? false}
                            onClick={handleSubmit(data => onSubmit(data))}
                            endIcon={<GetAppIcon />}
                        >
                            {intl.formatMessage({ id: 'companylist.table.companyDetails.analysislink' })}
                        </Button>
                    }
                />
            }
            fixedHeight={4}
            maxHeight='29.5rem'

        >
            <form>
                <Grid container sx={{ padding: '1.5rem' }} spacing={2}>
                    <Grid item xs={12}>
                        <FromControlledHiddenField
                            name='companyId'
                            defaultValue={companyId}
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlledSwitchItem
                            defaultValue={true}
                            name='shrinked'
                            control={control}
                            label={intl.formatMessage({ id: 'analysis.link.form.shrinked' })}
                            checkedVal={true}
                            notCheckedVal={false}
                            checkedLabel={intl.formatMessage({ id: 'analysis.link.form.shrinked.checked' })}
                            notCheckedLabel={intl.formatMessage({ id: 'analysis.link.form.shrinked.notChecked' })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlledInput
                            defaultValue={10000}
                            name='expiration'
                            control={control}
                            type='number'
                            step={1}
                            label={intl.formatMessage({ id: 'analysis.link.form.expiration' })}
                        />
                    </Grid>
                    {createdLink?.data &&
                        <Grid item xs={12}>
                            <Subtitle mt={0} mb={0}>{intl.formatMessage({ id: 'analysis.link.results' })}</Subtitle>
                            <Typography>
                                {intl.formatMessage({ id: 'analysis.link.expirationday' })}:
                                <Typography component='span' sx={{ pl: '1rem' }}>
                                    {moment(createdLink.data.expires).format('DD.MM.YYYY')}
                                </Typography>
                            </Typography>
                            <Typography>
                                {intl.formatMessage({ id: 'analysis.link.form.shrinked' })}:
                                <Typography component='span' sx={{ pl: '1rem' }}>
                                    {intl.formatMessage({ id: `analysis.link.form.shrinked.${createdLink.data.shrinked}` })}
                                </Typography>
                            </Typography>
                            <TextField
                                variant="standard"
                                fullWidth
                                disabled
                                placeholder='-'
                                value={createdLink.data.rowKey ? url : ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LinkIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    mt: 0.5,
                                    '& .MuiInput-input': {
                                        cursor: 'text'
                                    }
                                }}
                            />
                        </Grid>
                    }
                </Grid>
            </form>
        </ModalDialog>
    );
}



