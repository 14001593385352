import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

const data = [
	{
		Rahoittaja: 'ELY',
		Instrumentti: 'Yrityksen kehittämisavustus (kehittäminen)',
		Henkilösivukulut: '26,44 %',
		'Flat rate/Yleiskustannukset': '7,00 %',
		Tarkennukset: '',
	},
	{
		Rahoittaja: 'ELY',
		Instrumentti: 'Yrityksen kehittämisavustus (investointi)',
		Henkilösivukulut: '26,44 %',
		'Flat rate/Yleiskustannukset': '1,50 %',
		Tarkennukset: '',
	},
	{ Rahoittaja: 'BF', Instrumentti: 'Talent', Henkilösivukulut: '30,00 %', 'Flat rate / Yleiskustannukset': '20,00 %', Tarkennukset: '' },
	{ Rahoittaja: 'BF', Instrumentti: 'Tempo', Henkilösivukulut: '30,00 %', 'Flat rate / Yleiskustannukset': '20,00 %', Tarkennukset: '' },
	{
		Rahoittaja: 'BF',
		Instrumentti: 'Market Explorer',
		Henkilösivukulut: '30,00 %',
		'Flat rate/Yleiskustannukset': '20,00 %',
		Tarkennukset: '',
	},
	{
		Rahoittaja: 'BF',
		Instrumentti: 'Tk-laina ja tk-avustus',
		Henkilösivukulut: '30 - 50 %',
		'Flat rate/Yleiskustannukset': '10 - 50 %',
		Tarkennukset: `Henkilösivukustannuksiksi voidaan hyväksyä enintään 50 % maksetuista tehollisen työajan palkoista, jotka on hyväksytty projektille. 
Jos henkilöille ei makseta loma-ajan palkkaa, henkilösivukustannuksena hyväksytään 30 % projektille kuuluvasta palkasta.

Yleiskustannuksiksi voidaan hyväksyä hakijayrityksen henkilömäärän mukainen prosenttiosuus rahapalkkojen ja henkilösivukustannusten yhteismäärästä:

- alle 10 henkilöä:
	10 % jos yrityksen yleiskustannukset ovat pienet (yrityksellä ei esimerkiksi ole toimitiloja).
	20 % jos yrityksellä on toimitilat, joista se maksaa käypää hintaa ja sen kirjanpito on ulkoistettu
- 10 - 19 henkilöä: 20 %
- 20 - 49 henkilöä: 30 %
- 50 henkilöä tai enemmän: 50 %
			`,
	},
];

const RatesFormHelper = () => {
	return (
		<Box sx={{ mt: '1rem' }}>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>Esimerkkitaulukko</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<TableContainer component={Paper} sx={{ boxShadow: 0 }}>
						<Table size='small' sx={{ border: 'none' }}>
							<TableHead>
								<TableRow>
									<TableCell>Rahoittaja</TableCell>
									<TableCell>Instrumentti</TableCell>
									<TableCell>Henkilösivukulut</TableCell>
									<TableCell>Flat rate / Yleiskustannukset</TableCell>
									<TableCell>Tarkennukset</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((row, index) => (
									<TableRow key={index}>
										<TableCell sx={{ verticalAlign: 'top' }}>{row.Rahoittaja}</TableCell>
										<TableCell sx={{ verticalAlign: 'top' }}>{row.Instrumentti}</TableCell>
										<TableCell sx={{ verticalAlign: 'top' }}>{row.Henkilösivukulut}</TableCell>
										<TableCell sx={{ verticalAlign: 'top' }}>{row['Flat rate/Yleiskustannukset']}</TableCell>
										<TableCell sx={{ verticalAlign: 'top' }}>
											<Typography component='pre' sx={{ margin: 0 }}>
												{row.Tarkennukset}
											</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default RatesFormHelper;
