import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import getCompanyLink from 'utils/auth/getCompanyLink';

const useStyles = makeStyles(theme => ({
	root: {
		paddingLeft: '3.25rem',
		'&>div:first-child li': {
			paddingTop: 0,
		},
		[theme.breakpoints.between('lg', 'xl')]: {
			maxWidth: '22.5rem',
		},
		[theme.breakpoints.up('xl')]: {
			maxWidth: '22.5rem',
		},
	},
	listItemMain: {
		padding: '0.25rem 1rem 0.25rem 0.5rem',
	},
	itemText: {
		'& span': {
			fontSize: '1rem',
			fontWeight: 600,
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			color: theme.palette.primary.secondary,
		},
		'&:hover': {
			cursor: 'pointer',
		},
	},
	activeItemText: {
		'& span': {
			color: theme.palette.primary.beige,
		},
	},
	itemTextNested: {
		'& span': {
			fontWeight: 400,
		},
	},
	listIcon: {
		minWidth: '2rem',
		justifyContent: 'center',
	},
	icon: {
		width: 5,
		height: 5,
		color: theme.palette.primary.secondary,
	},
	activeIcon: {
		width: 10,
		height: 10,
		color: theme.palette.primary.beige,
	},
	itemButton: {
		display: 'inline',
		color: theme.palette.primary.white,
	},
}));

const ProjectsSidebarContent = ({ loading, projects }) => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const { id } = useParams();

	let sortableProjectArray = [...projects?.data];
	let renderedProjects = sortableProjectArray?.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp));

	const [projectCount, setProjectCount] = useState(6);
	const renderAll = () => setProjectCount(renderedProjects?.length);

	if (!renderedProjects) return null;

	return (
		!loading && (
			<List disablePadding className={classes.root}>
				{renderedProjects?.map(({ projectId, projectName }, index) =>
					index < projectCount ? (
						<ListItem key={projectId} className={classes.listItemMain}>
							<ListItemIcon className={classes.listIcon}>
								<FiberManualRecordIcon className={projectId === id ? classes.activeIcon : classes.icon} />
							</ListItemIcon>
							<ListItemText
								component='a'
								onClick={e => {
									e.preventDefault();
									history.push(getCompanyLink(`/projects/${projectId}`, location));
								}}
								className={`${classes.itemText} ${projectId === id && classes.activeItemText}`}
							>
								{projectName}
							</ListItemText>
						</ListItem>
					) : index === projectCount ? (
						<ListItem key={index} button onClick={renderAll} className={classes.itemButton}>
							...
						</ListItem>
					) : null
				)}
			</List>
		)
	);
};

export default ProjectsSidebarContent;
