import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

import StyledDivider from '../StyledDivider';
import ConversationList from './ConversationList';
import AddComment from './ConversationList/ConversationItem/CommentsDialog/AddComment';

import { getCompanyId, isFreemiumToken } from 'utils/auth/company';
import { getCompanyPath } from 'utils/auth/getCompanyPath';
import {
	matchBusinessPlan,
	matchDashboard,
	matchFinancialPlan,
	matchProjects,
	matchProjectsProject,
	matchProjectsProjectPlan,
	matchProjectsProjectSummary,
} from 'utils/helpers/pathMatch';
import {
	createComment,
	createConversation,
	getComments,
	getConversationsByCompanyId,
	isCollaborationActive,
	markConversationAsResolved,
	removeComment,
	removeConversation,
	resetTemporaryLink,
	unfilterConversations,
} from '../slices';
import { separator } from '../utils';

import TooltippedIconButton from 'components/Button/TooltippedIconButton';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { filter } from 'lodash';
import { findItemByIdWithField } from '../../Projects/slices';
import { getCommentLink, getFilteredByPageData, getLinkTitleWithProjectData, getPathHashOrLink } from './drawerUtils';

const Header = ({ toggleDrawer, children }) => {
	const intl = useIntl();

	return (
		<>
			<Stack flexDirection='row' justifyContent='space-between' sx={{ pl: 2 }}>
				<Typography
					sx={{
						fontSize: '1.15rem',
						color: 'secondary.grey900',
						py: 1,
					}}
				>
					{intl.formatMessage({ id: 'collaboration.conversations.title' })}
				</Typography>
				<IconButton
					aria-label='close'
					onClick={toggleDrawer}
					sx={{
						color: 'secondary.grey500',
					}}
				>
					<CloseIcon />
				</IconButton>
			</Stack>
			{children}
		</>
	);
};

const FilterHeader = ({ filteredBy, temporaryLink, linkTitle, targetStr, generalPage, getFilteredPageName, unfilterList }) => {
	const intl = useIntl();
	if (generalPage === 'all') return null;

	return (
		<Stack
			direction='row'
			alignContent='space-between'
			alignItems='center'
			sx={{ width: '100%', px: 2, pb: 1, fontStyle: 'italic', opacity: '0.5' }}
		>
			<Typography sx={{ fontSize: '0.8rem', flexGrow: 1 }}>{getFilteredPageName(linkTitle, targetStr, generalPage)}</Typography>
			{filteredBy || temporaryLink ? (
				<TooltippedIconButton tooltip={intl.formatMessage({ id: 'common.delete.filter' })} onClick={unfilterList}>
					<FilterAltOffIcon
						sx={{
							transform: 'scale(0.85)',
							'&:hover': {
								transform: 'scale(1)',
							},
						}}
					/>
				</TooltippedIconButton>
			) : null}
		</Stack>
	);
};

const CollaborationDrawer = ({ isOpen, toggleDrawer, width }) => {
	const intl = useIntl();
	const theme = useTheme();
	const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { id: projectId } = useParams();
	const companyId = getCompanyId();
	const isFreemium = isFreemiumToken();

	const { pathname } = location;

	const collaboration = useSelector(state => state.collaboration);
	const links = collaboration?.links;
	const temporaryLink = collaboration?.temporaryLink;
	const conversations = collaboration?.conversations;
	const data = conversations?.data;
	const loading = conversations?.loading;
	const filteredBy = conversations?.filteredBy;

	const { data: company } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const isDashboard = matchDashboard(pathname);
	const isBusinessplan = matchBusinessPlan(pathname);
	const isFinancialplan = matchFinancialPlan(pathname);
	const isProjects = matchProjects(pathname);
	const isProjectsProject = matchProjectsProject(pathname);
	const isProjectsProjectPlan = matchProjectsProjectPlan(pathname);
	const isProjectsProjectSummary = matchProjectsProjectSummary(pathname);

	useEffect(() => {
		dispatch(getConversationsByCompanyId(isFreemium));
	}, [companyId, isFreemium]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(resetTemporaryLink());
		dispatch(unfilterConversations());
	}, [location]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!isCollaborationActive()) return null;

	const generalPage = isDashboard
		? 'all'
		: isBusinessplan
			? 'businessplan'
			: isFinancialplan
				? 'financialplan'
				: isProjects && 'projects';

	const isGeneralConversation = isFinancialplan || (isBusinessplan && !temporaryLink) || isProjects;

	const correctedLoading = isBusinessplan || isProjectsProject ? loading && links?.length > 0 : loading; // loading or its imitation

	const filteredByResolvedData = filter(data, ({ resolved }) => !resolved);
	const filteredByPageData = getFilteredByPageData(
		filteredByResolvedData,
		isDashboard,
		isProjectsProject,
		isProjectsProjectPlan,
		isProjectsProjectSummary,
		projectId,
		generalPage
	); // is used for these pages when collaboration launch is not rendered
	const filterByLinkData = filteredBy ? filter(filteredByPageData, ({ link }) => link === filteredBy) : filteredByPageData;

	const targetArr = temporaryLink?.splitted.map((item, index) => (index === 0 ? item : '.' + item));
	const targetStr = targetArr?.join(''); // currently used for businessplan (not dynamic IDs)

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const projects = useSelector(state => state.kanban?.projects);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const tasks = useSelector(state => state.kanban?.tasks) ?? [];
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const costs = useSelector(state => state.kanban?.costs) ?? [];

	const linkTitle = getLinkTitleWithProjectData(
		projectId,
		projects,
		tasks,
		costs,
		temporaryLink,
		isProjectsProject,
		isProjectsProjectPlan,
		isProjectsProjectSummary,
		intl
	); // is used for pages with id and lazy looading (like Projects currently)

	const getFilteredPageName = (linkTitle, targetStr, generalPage) => {
		if (linkTitle) return linkTitle;
		if (targetStr)
			return `${intl.formatMessage({ id: 'collaboration.businessplan.general' })}${separator}${intl.formatMessage({
				id: targetStr,
			})}`;
		if (generalPage) return intl.formatMessage({ id: `collaboration.${generalPage}.general` });

		const project = findItemByIdWithField(projects?.data, 'projectId', projectId);

		// else currently project plan:
		return `${intl.formatMessage({ id: 'collaboration.projects.general' })}${separator}${project?.projectName}`;
	};

	const goToTarget = link => {
		const pathBase = getCompanyPath('/', pathname, companyId);
		const pathHashOrLink = getPathHashOrLink(link);

		history.push(`${pathBase}${pathHashOrLink}`);
	};

	// dispatches
	const loadComments = (conversationId, isRead) => dispatch(getComments(conversationId, isRead));
	const addComment = (conversationId, comment) => dispatch(createComment(conversationId, comment));
	const addConversation = (link, comment) =>
		dispatch(createConversation(link, comment, linkTitle ?? getFilteredPageName(linkTitle, targetStr, generalPage), company?.name));
	const resolveConversation = id => dispatch(markConversationAsResolved(id));
	const deleteConversation = id => dispatch(removeConversation(id));
	const deleteComment = (conversationId, commentId) => dispatch(removeComment(conversationId, commentId));
	const unfilterList = () => {
		dispatch(resetTemporaryLink());
		dispatch(unfilterConversations());
	};

	return (
		<Drawer
			anchor='right'
			variant={xlUp ? 'permanent' : 'temporary'}
			open={isOpen}
			onClose={toggleDrawer}
			ModalProps={{
				BackdropProps: {
					invisible: true,
				},
			}}
			PaperProps={{
				sx: {
					bgcolor: 'primary.secondary',
					width: isOpen ? width : 0,
					borderColor: 'rgba(0, 0, 0, 0.05)',
				},
			}}
			sx={{ zIndex: !xlUp && 10002 }} // is greater than Chat.js = 10001
		>
			{isFreemium ? (
				<Box
					sx={{
						width: '100%',
						height: { xs: '15rem', lg: '25rem' },
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Stack spacing={2} flexDirection='column' useFlexGap>
						<Typography
							sx={{
								color: 'primary.grayishBlue',
								fontWeight: 500,
								textTransform: 'uppercase',
								textAlign: 'center',
								fontSize: '1.143rem',
							}}
						>
							<FormattedMessage id='collaboration.conversations.title' />
						</Typography>
						<Typography sx={{ color: 'primary.grayishBlue', textAlign: 'center' }}>
							<FormattedMessage id='collaboration.conversations.upgrade.freemium' />
						</Typography>
					</Stack>
				</Box>
			) : (
				<>
					<Header {...{ toggleDrawer }}>
						<FilterHeader
							{...{ filteredBy, temporaryLink, linkTitle, targetStr, generalPage, getFilteredPageName, unfilterList }}
						/>
					</Header>
					<StyledDivider />
					<Box sx={{ direction: 'ltr' }}>
						<AddComment
							link={getCommentLink(
								temporaryLink,
								projectId,
								isDashboard,
								generalPage,
								isGeneralConversation,
								isProjectsProject,
								isProjectsProjectPlan,
								isProjectsProjectSummary
							)}
							addConversation={addConversation}
							focused={temporaryLink ? true : false}
							sx={{ pt: 2, px: 2, pb: 2.5 }}
						/>
						<StyledDivider />
					</Box>
					<ConversationList
						{...{
							loading: correctedLoading,
							data: filterByLinkData,
							resolveConversation,
							deleteConversation,
							loadComments,
							addComment,
							deleteComment,
							goToTarget,
							isDashboard,
						}}
					/>
				</>
			)}
		</Drawer>
	);
};

export default CollaborationDrawer;
