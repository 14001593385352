import { isNil } from 'lodash';
import * as React from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import { useDispatch } from 'react-redux';

import PostAddIcon from '@mui/icons-material/PostAdd';
import { Alert, AlertTitle, Box, BoxProps, Button, DialogContent, DialogTitle, Divider, Skeleton, Stack, Typography } from '@mui/material';

import { getCompany, getCompanyId } from 'utils/auth/company';

import { useFetchRfpRequestQuery } from 'containers/Marketplace/rfpRequestApi';

import RfpLastUpdatedTypography from 'containers/Marketplace/common/RfpLastUpdatedTypography';
import RfpStatusChip from 'containers/Marketplace/common/RfpStatusChip';
import { openDrawer } from 'containers/Marketplace/drawerSlice';
import CompanyProfileBadge from 'containers/Marketplace/profile/CompanyProfileBadge';
import CanUpsertRfpRequest from 'containers/Marketplace/request/CanUpsertRfpRequest';
import ReadRfpRequestAttachmentList from 'containers/Marketplace/request/Read/ReadRfpRequestAttachmentList';
import ReadRfpRequestItemList from 'containers/Marketplace/request/Read/ReadRfpRequestItemList';
import ReadRfpRequestTargetCompanyList from 'containers/Marketplace/request/Read/ReadRfpRequestTargetCompanyList';
import CanUpsertRfpProposal from '../../proposal/CanUpsertRfpProposal';
import RequestProposalList from '../RequestProposalList/RequestProposalList';
import PrintRftRequest from './PrintRftRequest';

type Props = BoxProps & {
	requestId: string | null;
	published: boolean;
	createdByMe: boolean;
	onEditClick: () => void;
};

const ReadRfpRequestForm: React.FC<Props> = ({ requestId, published, createdByMe, onEditClick, ...otherProps }) => {
	const dispatch = useDispatch();
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const {
		data: rfpRequest,
		isLoading,
		isError,
	} = useFetchRfpRequestQuery(
		{ companyId, requestId, published: published || createdByMe === false },
		{ skip: isNil(companyId) || isNil(requestId) }
	);

	return (
		<Box {...otherProps}>
			<DialogTitle>
				<Stack direction='row'>
					<FormattedMessage id='rfpRequest.title' />
				</Stack>
			</DialogTitle>
			<DialogContent>
				{isError && (
					<Alert severity='error'>
						<AlertTitle>
							<FormattedMessage id='query.error.title' />
						</AlertTitle>
						<FormattedMessage id='fetchRfpRequest.error.message' />
					</Alert>
				)}
				<Stack spacing={3}>
					<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
						<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
							{isLoading ? (
								<Skeleton height={120} />
							) : (
								<>
									<Box flex='1'>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectTitle.label' />
										</Typography>
										<Typography>{rfpRequest?.projectTitle}</Typography>
									</Box>
									<Stack flexDirection={{ xs: 'column', sm: 'row' }}>
										<Box flex='1'>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectBudget.label' />
											</Typography>
											<Typography>
												<FormattedNumber value={rfpRequest?.projectBudget ?? 0} currency='EUR' style='currency' />
											</Typography>
										</Box>
										<Box flex='1'>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.responseDeadline.label' />
											</Typography>
											<Typography>
												<FormattedDate value={rfpRequest?.responseDeadline ?? ''} />
												<Typography component='span' sx={{ ml: '0.5rem' }}>
													<FormattedTime value={rfpRequest?.responseDeadline ?? ''} />
												</Typography>
											</Typography>
										</Box>
									</Stack>
								</>
							)}
						</Stack>
						<Stack
							flex='1'
							flexDirection='row'
							alignItems='flex-start'
							justifyContent='flex-end'
							maxWidth={{ xs: '100%', md: '40%' }}
						>
							{isLoading ? (
								<Skeleton width={200} height={100} />
							) : (
								<>
									<PrintRftRequest requestId={requestId} published={published} createdByMe={createdByMe} />
									<CanUpsertRfpRequest rfpRequest={rfpRequest}>
										{canUpsert =>
											canUpsert && (
												<Button size='small' onClick={() => onEditClick?.()}>
													<FormattedMessage id='rfpRequest.edit' />
												</Button>
											)
										}
									</CanUpsertRfpRequest>
									<CanUpsertRfpProposal rfpRequest={rfpRequest} rfpProposal={undefined}>
										{canUpsert =>
											canUpsert && (
												<Button
													size='small'
													endIcon={<PostAddIcon />}
													onClick={() => {
														dispatch(
															openDrawer({
																name: 'UpsertRfpProposalDrawer',
																data: {
																	requestId: rfpRequest?.rfpRequestId,
																	published: rfpRequest?.status === 'Published',
																},
															})
														);
													}}
												>
													<FormattedMessage id='publishedRfpRequests.rfpProposal.create' />
												</Button>
											)
										}
									</CanUpsertRfpProposal>
								</>
							)}
						</Stack>
					</Stack>
					<Stack flexDirection='row' alignItems='center'>
						{isLoading ? (
							<Skeleton width={300} height={60} />
						) : (
							<>
								<RfpStatusChip status={rfpRequest?.status} />
								<RfpLastUpdatedTypography updateDate={rfpRequest?.updateDate} />
							</>
						)}
					</Stack>
					<Divider />
					<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
						<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
							{isLoading ? (
								<>
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
									<Skeleton height={100} />
								</>
							) : (
								<>
									<RequestProposalList proposals={rfpRequest?.proposals ?? []} />
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectIngress.label' />
										</Typography>
										<Typography>{rfpRequest?.projectIngress}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectDescription.label' />
										</Typography>
										<Typography>{rfpRequest?.projectDescription}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectOverview.label' />
										</Typography>
										<Typography>{rfpRequest?.projectOverview}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectTimeframe.label' />
										</Typography>
										<Typography>{rfpRequest?.projectTimeframe}</Typography>
									</Box>
									<Stack flexDirection='row'>
										<Box sx={{ flex: 1 }}>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectTimeStart.label' />
											</Typography>
											<Typography>
												<FormattedDate value={rfpRequest?.projectTimeStart ?? ''} />
											</Typography>
										</Box>
										<Box sx={{ flex: 1 }}>
											<Typography variant='h4'>
												<FormattedMessage id='rfpRequest.projectTimeEnd.label' />
											</Typography>
											<Typography>
												<FormattedDate value={rfpRequest?.projectTimeEnd ?? ''} />
											</Typography>
										</Box>
									</Stack>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectChallengesAndGoals.label' />
										</Typography>
										<Typography>{rfpRequest?.projectChallengesAndGoals}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectRequirementsAndEvaluationCriteria.label' />
										</Typography>
										<Typography>{rfpRequest?.projectRequirementsAndEvaluationCriteria}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectProposalSubmissionRequirements.label' />
										</Typography>
										<Typography>{rfpRequest?.projectProposalSubmissionRequirements}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpRequest.projectContactDetail.label' />
										</Typography>
										<Typography>{rfpRequest?.projectContactDetail}</Typography>
									</Box>
								</>
							)}
						</Stack>
						<Stack flex='1' maxWidth={{ xs: '100%', md: '40%' }}>
							{isLoading ? (
								<>
									<Skeleton height={100} />
									<Skeleton height={100} />
								</>
							) : (
								<>
									<CompanyProfileBadge companyId={rfpRequest?.companyId} />
									{createdByMe && rfpRequest?.isTargetedRequest && (
										<ReadRfpRequestTargetCompanyList companyIds={rfpRequest?.proposalRequestTargetCompanies} />
									)}
									<ReadRfpRequestItemList rfpRequestItems={rfpRequest?.rfpRequestItems} />
									<ReadRfpRequestAttachmentList
										requestId={requestId}
										createdByMe={createdByMe}
										attachments={rfpRequest?.attachments}
									/>
								</>
							)}
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
		</Box>
	);
};

export default ReadRfpRequestForm;
