import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(() => (
    {
        textTransform: 'none'
    }
));

export default function AddButton({ children, onClick, icon, color = 'success', size = 'large', sx }) {


    return (
        <StyledButton color={color} size={size} startIcon={icon ?? <AddIcon />} onClick={onClick} sx={{ ...sx }}>
            {children}
        </StyledButton>
    );
}
