export default {
    'h1': {
        marginTop: '12pt',
        marginRight: '42.55pt',
        marginBottom: '6pt',
        marginLeft: '0cm',
        marginHeight: '120%',
        pageBreakAfter: 'avoid',
        fontSize: '28pt',
        color: '#482e51',
    },
    'h2': {
        marginTop: '18pt',
        marginRight: '42.55pt',
        marginBottom: '12pt',
        marginLeft: '0cm',
        marginHeight: '120%',
        pageBreakAfter: 'avoid',
        fontSize: '14pt',
        color: '#482e51',
    },
    'h3': {
        marginTop: '9pt',
        marginRight: '42.55pt',
        marginBottom: '8pt',
        marginLeft: '0cm',
        marginHeight: '120%',
        pageBreakAfter: 'avoid',
        fontSize: '11pt',
        color: '#007871',
    },
    'p, li': {
        color: '#482e51',
    },
};