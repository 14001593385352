import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import Logo from 'components/Logo';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 50%, ${theme.palette.success.main} 50%)`,
    },
    wrapper: {
        maxWidth: '75%',
    },
    title: {
        color: theme.palette.primary.white,
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        },
        fontWeight: 600,
        letterSpacing: theme.spacing(0.5),
        paddingTop: theme.spacing(6),
    },
    name: {
        fontSize: '0.75rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
        color: theme.palette.primary.white,
        fontWeight: 600,
        letterSpacing: theme.spacing(1),
        paddingTop: theme.spacing(4),
    },
}));

const Slide1 = ({ companyName, maxHeight, xs }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container justifyContent='center' alignItems='center' className={classes.root} style={{ maxHeight }}>
            <Grid item className={classes.wrapper}>
                <Logo width={xs ? 250 : 400} slideshow={true} />
                <Typography className={classes.title}>{intl.formatMessage({ id: 'slide1.title' })?.toUpperCase()}</Typography>
                <Typography className={classes.name}>{companyName?.toUpperCase()}</Typography>
            </Grid>
        </Grid>
    );
};

export default Slide1;
