import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PublicIcon from '@mui/icons-material/Public';
import { makeStyles } from '@mui/styles';

import InfoItemModal from '../InfoItemModal';

const useStyles = makeStyles(theme => ({
    card: {
        border: `1px solid ${theme.palette.secondary.line}`,
        borderRadius: 5,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1.5),
        '&:first-child': {
            marginTop: 0,
        },
        '&:hover': {
            cursor: 'pointer',
            // transform: 'scale(1.02)',
            border: `1px solid ${theme.palette.secondary.grey500}`,
            backgroundColor: theme.palette.secondary.grey,
        }
    },
    icon: {
        color: theme.palette.secondary.grey500,
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    title: {
        fontSize: '1.2rem',
        color: theme.palette.secondary.grey900,
        paddingBottom: theme.spacing(0.5)
    },
    value: {
        fontSize: '0.9rem',
        color: theme.palette.secondary.grey400,
    },
}));

const InfoItemCard = ({ infoItem, readonly }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Grid container alignItems='center' onClick={() => setOpen(true)} className={classes.card}>
                <Grid item>
                    <PublicIcon className={classes.icon} />
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                    <Typography className={classes.position}>{infoItem.title}</Typography>
                    <Typography className={classes.value}>{infoItem.value}</Typography>
                </Grid>
            </Grid>
            {infoItem && <InfoItemModal open={open} setOpen={setOpen} infoItem={infoItem} readonly={readonly} />}
        </>
    );
};

export default InfoItemCard;