import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Button from 'components/Button/Button';

import { matchCompanyPath } from 'utils/helpers/pathMatch';
import { regexInstrument } from 'utils/constants/regex';

const AdminFunctionsButton = ({ companyId, isContributor, isAdminFunctionsRendered, setIsAdminFunctionsRendered }) => {
    const intl = useIntl();
    const location = useLocation();
    const { pathname } = location;
    const companyPage = matchCompanyPath(location);

    if (!isContributor || !companyId || !companyPage || regexInstrument.test(pathname)) return null;

    return (
        <Box
            sx={{
                minWidth: '12rem',
                pt: 2,
                pr: 3
            }}
        >
            <Button
                variant='text'
                onClick={() => setIsAdminFunctionsRendered(!isAdminFunctionsRendered)}
                endIcon={isAdminFunctionsRendered ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            >
                {intl.formatMessage({ id: 'admin.show.company.info' })}
            </Button>
        </Box>
    );
};

export default AdminFunctionsButton;