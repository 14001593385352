import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const ProjectPhaseStepper = ({ activeStep, steps, onHoverAction, chosenStep }) => {
    const setActive = (index) => {
        onHoverAction(index);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step
                        sx={{
                            ':hover': {
                                cursor: 'pointer',
                                opacity: .8,
                            }
                        }}
                        key={label}
                        onMouseOver={() => setActive(index)}
                        onMouseOut={() => setActive(chosenStep)}
                    >
                        <StepLabel>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default ProjectPhaseStepper;