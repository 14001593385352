import React from 'react';
import { useIntl } from 'react-intl';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

import DataGrid from 'components/DataGrid';
import Button from 'components/Button/Button';
import FormattedDate from 'components/Formatters/FormattedDate';

import { capitalizeFirstLetter } from 'utils/converters/capitalizeFirstLetter';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/styles';


const NoLicenses = ({ intl, openLicenseModal, noDataMessage }) => {
    const theme = useTheme();

    return (
        <Stack sx={{ width: '100%', background: theme.palette.secondary.light, p: '2rem' }} direction='column' spacing={4} alignItems='center' justifyContent='center'>
            <Box>{noDataMessage ? noDataMessage : intl.formatMessage({ id: 'license.no.lisenses' })}</Box>
            {openLicenseModal && <Button
                color='success'
                startIcon={<AddIcon size='small' />}
                onClick={() => openLicenseModal({})}
            >
                {intl.formatMessage({ id: 'license.form.add' })}
            </Button>}
        </Stack>
    );
};

const LicensesTable = ({ loading, data, classes, fetchCompanyDetails = null, openLicenseModal, title = null, noDataMessage = null }) => {
    const intl = useIntl();

    const columns = [
        {
            field: 'companyId',
            headerName: intl.formatMessage({ id: 'companylist.table.id' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <>
                    {params.row.companyId}
                </>
            ),
        },
        {
            field: 'type',
            headerName: intl.formatMessage({ id: 'companylist.table.type' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>{capitalizeFirstLetter(params.row.type)}</span>
            ),
        },
        {
            field: 'status',
            headerName: intl.formatMessage({ id: 'companylist.table.status' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.isActive ? 'Active' : params.row.isExpired ? 'Expired' : params.row.isTerminated ? 'Terminated' : ''}
                </span>
            ),
        },
        {
            field: 'isActive',
            headerName: intl.formatMessage({ id: 'companylist.table.isActive' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.isActive + ''}` })}
                </span>
            ),
        },
        {
            field: 'startDate',
            type: 'date',
            headerName: intl.formatMessage({ id: 'companylist.table.startDate' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.startDate} />
                </span>
            ),
        },
        {
            field: 'endDate',
            type: 'date',
            headerName: intl.formatMessage({ id: 'companylist.table.endDate' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.endDate} />
                </span>
            ),
        },
        {
            field: 'author',
            headerName: intl.formatMessage({ id: 'companylist.table.author' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <>
                    {params.row.author ?? ''}
                </>
            ),
        },
        {
            field: 'comments',
            headerName: intl.formatMessage({ id: 'companylist.table.comments' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={classes.cell}>{params.row.comments}</span>
            ),
        },
        {
            field: 'terminationDate',
            type: 'date',
            headerName: intl.formatMessage({ id: 'companylist.table.terminationDate' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.terminationDate} />
                </span>
            ),
        },
        {
            field: 'terminateAuthor',
            headerName: intl.formatMessage({ id: 'companylist.table.terminateAuthor' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.terminateAuthor &&
                        <>
                            {params.row.terminateAuthor}
                        </>
                    }
                </span>
            ),
        },
        {
            field: 'isTerminated',
            headerName: intl.formatMessage({ id: 'companylist.table.isTerminated' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.isTerminated + ''}` })}
                </span>
            ),
        },
        {
            field: 'timestamp',
            type: 'date',
            headerName: intl.formatMessage({ id: 'companylist.table.timestamp' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.timestamp} />
                </span>
            ),
        }
    ];

    if (fetchCompanyDetails) {
        columns.splice(1, 0, {
            field: 'companyDetails',
            disableColumnMenu: true,
            sortable: false,
            headerName: intl.formatMessage({ id: 'companylist.table.companyDetails' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <Button
                    className={classes.detailsButton}
                    variant='text'
                    size='small'
                    color='primary'
                    disabled={loading || !params.row.companyId || params.row.companyId === 'undefined'}
                    onClick={() => fetchCompanyDetails(params.row.companyId)}
                >
                    {intl.formatMessage({ id: 'companylist.table.companyDetails.link' })}
                </Button>
            ),
        });
    }

    return (
        <Box sx={{ mt: !title && 1 }}>
            {title && <Typography variant='h2' className={classes.title}>{intl.formatMessage({ id: `userdetails.${title}.title` })}</Typography>}
            {
                loading || !loading && data?.length !== 0
                    ? <DataGrid
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                        idField='rowKey'
                        loading={loading}
                        data={data}
                        columns={columns}
                        paginationMode='client'
                        onRowClick={(params) => openLicenseModal(params.row)}
                    /> : <NoLicenses {...{ intl, openLicenseModal, noDataMessage }} />
            }
        </Box>
    );
};

export default LicensesTable;