import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import FormattedDate from 'components/Formatters/FormattedDate';
import Subtitle from 'components/Titles/Subtitle';

const CompanyDataPanel = ({ loading, companyData, classes }) => {
    const intl = useIntl();

    return (
        <>
            <Stack direction='row' spacing={2} alignItems='flex-end'>
                <Subtitle mt={0} padding={0}>{intl.formatMessage({ id: 'companydetails.title' })}</Subtitle>
                {loading && <Box sx={{ mb: 2 }}><CircularProgress size={16} sx={{ mb: 0.5 }} /></Box>}
            </Stack>
            {!loading && !companyData || !loading && companyData && Object.keys(companyData).length === 0
                ? <Typography component='p'>{intl.formatMessage({ id: 'companydetails.no.companyData' })}</Typography>
                : <>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companylist.table.id' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {companyData?.businessId}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companylist.table.name' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {companyData?.name}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companydetails.company.companyForm' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {companyData?.companyForm}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companydetails.company.registrationDate' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={companyData?.registrationDate} />
                            </Typography>
                        }
                    </Stack>
                </>
            }
        </>
    );
};

export default CompanyDataPanel;