import * as React from 'react';

import { SxProps, Typography, useTheme } from '@mui/material';
import { isArray } from 'lodash';

type Props = {
	margin?: boolean;
	children?: React.ReactElement | any;
	component?: any;
	sx?: SxProps;
};

const MainTitle: React.FC<Props> = ({ children, margin = true, component = 'h1', sx = {} }) => {
	const theme = useTheme();

	return (
		<Typography
			sx={[
				{
					...theme.typography.h1,
					color: 'primary.main',
					fontSize: '2rem',
					fontWeight: 600,
					marginBottom: margin ? '1.5rem' : 0,
					marginTop: 0,
					marginLeft: 0,
				},
				...(isArray(sx) ? sx : [sx]),
			]}
			component={component}
		>
			{children}
		</Typography>
	);
};

export default MainTitle;
