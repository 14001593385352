import { useState } from 'react';

import ReactDOMServer from 'react-dom/server';

import { yupResolver } from '@hookform/resolvers/yup';
import { isNil, map } from 'lodash';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import EditNoteIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LoadingButton from '@mui/lab/LoadingButton';
import { Checkbox, FormControlLabel, Skeleton, Stack, Typography } from '@mui/material';

import FormControlledInput from 'components/Form/FormControlledInput';
import { useFetchBusinessPlanQuery } from 'containers/BusinessPlan/businessPlanV2Api';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchScenarioQuery } from 'containers/Scenario/scenarioV2Api';

import Subtitle from 'components/Titles/Subtitle';
import { useCreateInstrumentAnalysisMutation, useGetInstrumentAnalysisQuery } from './instrumentAnalysisApi';

const ParsedCompanyScenarioData = ({ scenario, formatMessage }: any) => {
	const scenarioValues = [0, 1, 2, 4, 8, 16, 32];

	const getIndex = (value: any, scenarioValues: any) =>
		scenarioValues.reduce((acc: any, item: any, index: number) => {
			return item === value ? index : acc;
		}, 0);

	return (
		<table>
			<thead>{formatMessage({ id: 'basic.financial.info' })}</thead>
			<tbody>
				{map(scenario, (value: any, key: string) => {
					const isMissing = scenario?.missingValues?.find((item: string | null) => item?.toLowerCase() === key?.toLowerCase());

					if (typeof value === 'number' && key !== 'grantedScore' && key !== 'totalValues')
						return (
							<tr>
								<td>{formatMessage({ id: `instrument.radar.choice.${key}` })}:</td>
								<td>
									{isMissing
										? 'Ei tietoa'
										: formatMessage({
												id: `instrument.radar.choice.${key}.${getIndex(value, scenarioValues)}`,
										  })}
								</td>
							</tr>
						);
				})}
			</tbody>
		</table>
	);
};

const ParsedBusinessPlan = ({ businessPlan, formatMessage }: any) => {
	if (!businessPlan) {
		return null;
	}

	return (
		<table>
			<thead>{formatMessage({ id: 'basic.financial.info' })}</thead>
			<tbody>
				<tr>
					<td>{formatMessage({ id: 'businessplan.pitch' })}</td>
					<td>{businessPlan.pitch}</td>
				</tr>
				<tr>
					<td>{formatMessage({ id: 'businessplan.problem' })}</td>
					<td>{businessPlan.problem}</td>
				</tr>
				<tr>
					<td>{formatMessage({ id: 'businessplan.solution' })}</td>
					<td>{businessPlan.solution}</td>
				</tr>
				<tr>
					<td>{formatMessage({ id: 'businessplan.markets' })}</td>
					<td>{businessPlan.markets}</td>
				</tr>
				<tr>
					<td>{formatMessage({ id: 'businessplan.globalMarket' })}</td>
					<td>{businessPlan.globalMarket}</td>
				</tr>
				<tr>
					<td>{formatMessage({ id: 'businessplan.vision' })}</td>
					<td>{businessPlan.vision}</td>
				</tr>
				<tr>
					<td>{formatMessage({ id: 'businessplan.costs' })}</td>
					<td>
						<table>
							{map(businessPlan.costs, (cost: any) => {
								return (
									<tr>
										<td>{formatMessage({ id: 'businessPlan.costs.costName' })}</td>
										<td>{cost.costName}</td>
										<td>{formatMessage({ id: 'businessPlan.costs.costCategory' })}</td>
										<td>{cost.costCategory}</td>
										<td>{formatMessage({ id: 'businessPlan.costs.costAmount' })}</td>
										<td>{cost.costAmount}</td>
										<td>{formatMessage({ id: 'businessPlan.costs.startTime' })}</td>
										<td>{cost.startTime}</td>
										<td>{formatMessage({ id: 'businessPlan.costs.endTime' })}</td>
										<td>{cost.endTime}</td>
									</tr>
								);
							})}
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const InstrumentAnalysisContent = ({ analysis, sx = {} }: any) => {
	if (!analysis) return null;

	return <Stack sx={{ ...sx }}>{analysis?.content}</Stack>;
};

type InstrumentAnalysisProps = {
	instrument: any;
	companyId: string | null;
	sx?: {};
};

const InstrumentAnalysis: React.FC<InstrumentAnalysisProps> = ({ instrument, companyId, sx = {} }) => {
	const { formatMessage } = useIntl();

	const [promptVisible, setPromptVisible] = useState(false);
	const [modify, setModify] = useState(false);

	const [createInstrumentAnalysis, { isLoading: isGenerating }] = useCreateInstrumentAnalysisMutation();
	const { data: analysisData, isLoading: isAnalysisLoading } = useGetInstrumentAnalysisQuery(
		{ instrumentId: instrument?.id, companyId },
		{ skip: isNil(companyId) || isNil(instrument?.id) }
	);
	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: isNil(companyId) });

	const { data: scenarioData, isLoading: isScenarioLoading } = useFetchScenarioQuery(
		{ companyId, businessId: company?.businessId },
		{ skip: isNil(companyId) || isCompanyLoading || isCompanyFetching }
	);

	const { data: businessPlan, isLoading: isBusinessPlanLoading } = useFetchBusinessPlanQuery({ companyId }, { skip: isNil(companyId) });

	const htmlStringScenario = ReactDOMServer.renderToString(
		<ParsedCompanyScenarioData scenario={scenarioData} formatMessage={formatMessage} />
	);
	const htmlStringBP = ReactDOMServer.renderToString(<ParsedBusinessPlan businessPlan={businessPlan} formatMessage={formatMessage} />);

	const generateAnalysis = async (data: any) => {
		await createInstrumentAnalysis({
			instrumentId: instrument?.id,
			companyId,
			body: {
				scenario: htmlStringScenario,
				businessPlan: htmlStringBP,
				instrument: `Analysoitava instrumentti on ${instrument.name}. ${instrument?.description}`,
				prompt: data?.prompt,
			},
			forceRegeneration: true,
		}).unwrap();
		setModify(false);
	};

	const schema = yup.object().shape({
		prompt: yup.string().required(`${formatMessage({ id: 'ai.instrument-analysis.form.prompt.required' })}`),
	});
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			prompt: formatMessage({ id: 'ai.instrument-analysis.form.prompt' }, { instrument: instrument.name, company: company?.name }),
		},
		resolver: yupResolver(schema),
	});

	const isLoading = isBusinessPlanLoading || isScenarioLoading || isAnalysisLoading || isCompanyLoading;
	const missingScenarioData: boolean = (scenarioData?.missingValues?.length ?? 0) > 0;
	const missingBusinessPlanData: boolean = !businessPlan?.pitch;

	return (
		<Stack sx={{ ...sx }}>
			<Stack direction='row' alignContent='space-between'>
				<Subtitle sx={{ flexGrow: 1 }} mb={0} mt={0} padding='0' id={undefined} className={undefined}>
					{formatMessage({ id: 'ai.instrument-analysis.form.title' })}
				</Subtitle>
				{analysisData && !isLoading && !modify && (
					<LoadingButton
						onClick={() => setModify(true)}
						variant='text'
						loading={isAnalysisLoading || isGenerating}
						startIcon={<EditNoteIcon />}
						loadingPosition='start'
					>
						{formatMessage({ id: 'ai.instrument-analysis.form.button.generate' })}
					</LoadingButton>
				)}
			</Stack>
			{isLoading && (
				<Stack>
					<Skeleton height={59} sx={{ width: '70%' }} />
					<Skeleton height={50} sx={{ width: '70%' }} animation='wave' />
				</Stack>
			)}
			{((!analysisData && !isLoading) || modify) && (
				<>
					<Typography sx={{ my: '1rem', fontStyle: 'italic' }}>
						{formatMessage({ id: 'ai.instrument-analysis.description' })}
					</Typography>
					{missingScenarioData && (
						<Stack direction='row' alignItems='center'>
							<ErrorIcon htmlColor='#bbb' />
							<Typography sx={{ ml: '1rem', my: '1rem', fontStyle: 'italic' }}>
								{formatMessage({ id: 'ai.instrument-analysis.missing-scenario' })}
							</Typography>
						</Stack>
					)}
					{missingBusinessPlanData && (
						<Stack direction='row' alignItems='center'>
							<ErrorIcon htmlColor='#bbb' />
							<Typography sx={{ ml: '1rem', my: '1rem', fontStyle: 'italic' }}>
								{formatMessage({ id: 'ai.instrument-analysis.missing-businessplan' })}
							</Typography>
						</Stack>
					)}
					<form onSubmit={handleSubmit(data => generateAnalysis(data))}>
						{/* @ts-ignore */}
						<FormControlledInput
							sx={{ display: promptVisible ? 'block' : 'none' }}
							defaultValue={
								formatMessage(
									{ id: 'ai.instrument-analysis.form.prompt' },
									{ instrument: instrument.name, company: company?.name }
								) ?? ''
							}
							name='prompt'
							control={control}
							// @ts-ignore
							error={errors?.prompt?.message}
							//label={formatMessage({ id: 'ai.instrument-analysis.form.prompt' })}
							rows={4}
						/>
						<Stack direction='row-reverse' spacing={2} sx={{ mt: '1rem' }}>
							<LoadingButton
								type='submit'
								variant='contained'
								color='primary'
								loading={isAnalysisLoading || isGenerating}
								startIcon={<PsychologyIcon />}
								loadingPosition='start'
							>
								{!isGenerating
									? formatMessage({ id: 'ai.instrument-analysis.form.button' })
									: formatMessage({ id: 'ai.instrument-analysis.generating' })}
							</LoadingButton>
							<FormControlLabel
								control={
									<Checkbox
										disabled={isAnalysisLoading || isGenerating}
										checked={promptVisible}
										onClick={() => setPromptVisible(!promptVisible)}
									/>
								}
								label={formatMessage({ id: 'ai.instrument-analysis.form.prompt.toggle' })}
							/>
						</Stack>
					</form>
				</>
			)}
			{!modify && <InstrumentAnalysisContent sx={{ mt: '2rem' }} analysis={analysisData} />}
		</Stack>
	);
};

export default InstrumentAnalysis;
