import * as React from 'react';
import { Box, CircularProgress, Stack, Typography, useMediaQuery } from '@mui/material';
import { Check as CheckIcon, Circle as CircleIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

type Step = 'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6';

type StepIndicatorProps = {
	step: Step;
	past: boolean;
	active: boolean;
	future: boolean;
	first: boolean;
};

const getStepNumber = (step: Step) => {
	return parseInt(step.replace('step', ''));
};

const getNextStep = (step: Step) => {
	let nextStep: Step = 'step2';
	if (step === 'step2') {
		nextStep = 'step3';
	} else if (step === 'step3') {
		nextStep = 'step4';
	} else if (step === 'step4') {
		nextStep = 'step5';
	} else if (step === 'step5') {
		nextStep = 'step6';
	} else if (step === 'step6') {
		return null;
	}
	return nextStep;
};

const StepIndicator: React.FC<StepIndicatorProps> = ({ step, active, past, future, first }) => {
	return (
		<Stack spacing={1} flexDirection='row' alignItems='center' useFlexGap>
			<Box
				sx={{
					width: '3rem',
					height: '3px',
					borderRadius: '2px',
					backgroundColor: future ? '#C5C3C6' : '#6A588D',
					display: first ? 'none' : 'block',
				}}
			/>
			<Stack spacing={0.5} flexDirection='row' alignItems='center' useFlexGap>
				{past && <CheckIcon sx={{ fontSize: '18px', color: '#6A588D' }} />}
				{future && <CircleIcon sx={{ fontSize: '10px', color: '#C5C3C6' }} />}
				{active && <CircleIcon sx={{ fontSize: '10px', color: '#6A588D' }} />}
				<Typography sx={{ fontWeight: 500, color: future ? '#C5C3C6' : '#6A588D' }}>
					<FormattedMessage id={`onboarding.${step}.label`} />
				</Typography>
			</Stack>
		</Stack>
	);
};

type MobileStepIndicatorProps = {
	step: Step;
	nextStep: Step | null;
	stepNumber: number;
	lastStepNumber: number;
};

const MobileStepIndicator: React.FC<MobileStepIndicatorProps> = ({ step, nextStep, stepNumber, lastStepNumber }) => {
	return (
		<Stack spacing={2} flexDirection='row' alignItems='center' useFlexGap>
			<Box sx={{ position: 'relative', width: '50px', height: '50px' }}>
				<CircularProgress variant='determinate' value={100} size={50} sx={{ color: '#C5C3C6' }} />
				<CircularProgress
					variant='determinate'
					value={(stepNumber / lastStepNumber) * 100}
					size={50}
					sx={{ position: 'absolute', left: 0, top: 0 }}
				/>
				<Typography
					sx={{
						position: 'absolute',
						top: 'calc(50% - 10px)',
						left: 'calc(50% - 12px)',
						color: '#6A588D',
						width: '24px',
						textAlign: 'center',
					}}
				>{`${stepNumber}/${lastStepNumber}`}</Typography>
			</Box>
			<Box>
				<Typography sx={{ fontSize: '1.286rem', fontWeight: 500 }}>
					<FormattedMessage id={`onboarding.${step}.label`} />
				</Typography>
				{nextStep && (
					<Typography sx={{ fontSize: '1rem', fontWeight: 400, color: '#C5C3C6', wordBreak: 'break-word' }}>
						<Typography component='span' sx={{ mr: '0.5rem' }}>
							<FormattedMessage id={'onboarding.indicator.next.step'} />
						</Typography>
						<FormattedMessage id={`onboarding.${nextStep}.label`} />
					</Typography>
				)}
			</Box>
		</Stack>
	);
};

type Props = {
	step: Step;
};

const OnboardingStepIndicator: React.FC<Props> = ({ step }) => {
	const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

	if (isDesktop) {
		return (
			<Box sx={{ width: '100%', p: '3rem 1rem 1rem' }}>
				<Stack
					spacing={{ xs: 1, md: 2 }}
					flexDirection={{ xs: 'row' }}
					justifyContent={{ xs: 'space-between', md: 'center' }}
					useFlexGap
				>
					<StepIndicator
						step='step1'
						active={step === 'step1'}
						past={getStepNumber(step) > 1}
						future={getStepNumber(step) < 1}
						first={true}
					/>
					<StepIndicator
						step='step2'
						active={step === 'step2'}
						past={getStepNumber(step) > 2}
						future={getStepNumber(step) < 2}
						first={false}
					/>
					<StepIndicator
						step='step3'
						active={step === 'step3'}
						past={getStepNumber(step) > 3}
						future={getStepNumber(step) < 3}
						first={false}
					/>
					<StepIndicator
						step='step4'
						active={step === 'step4'}
						past={getStepNumber(step) > 4}
						future={getStepNumber(step) < 4}
						first={false}
					/>
					<StepIndicator
						step='step5'
						active={step === 'step5'}
						past={getStepNumber(step) > 5}
						future={getStepNumber(step) < 5}
						first={false}
					/>
					<StepIndicator
						step='step6'
						active={step === 'step6'}
						past={getStepNumber(step) > 6}
						future={getStepNumber(step) < 6}
						first={false}
					/>
				</Stack>
			</Box>
		);
	}

	return (
		<Box sx={{ width: '100%', p: '2rem 1rem 1rem' }}>
			<MobileStepIndicator
				step={step}
				nextStep={getNextStep(step)}
				stepNumber={getStepNumber(step)}
				lastStepNumber={getStepNumber('step6')}
			/>
		</Box>
	);
};

export default OnboardingStepIndicator;
