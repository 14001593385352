import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

const ExpertReview = ({ rating }) => {
    const intl = useIntl();
    const theme = useTheme();
    const smdDown = useMediaQuery(theme.breakpoints.down('smd'));

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} direction={smdDown ? 'column-reverse' : 'row'}>
                <Grid item sx={{ flex: 1, fontStyle: 'italic' }}>
                    <Typography color='primary'>{rating?.description}</Typography>
                </Grid>
                <Grid item>
                    {['administrationRating', 'processComplexityRating', 'usabilityRating'].map(item => {
                        return (
                            <Grid container spacing={2} key={item}>
                                <Grid item>
                                    <Rating
                                        name={item}
                                        max={3}
                                        value={rating[item] ?? 0}
                                        readOnly
                                        sx={{ color: 'primary.main' }}
                                    />
                                </Grid>
                                <Grid item sx={{ flex: 1 }}>
                                    <Typography color='primary' sx={{ fontWeight: 500, }}>
                                        {intl.formatMessage({ id: `expert.reviews.${item}` })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ExpertReview;