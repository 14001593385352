import React, { useState, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ModalDialog from 'components/Dialog/ModalDialog';
import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import AddToAnotherListForm from 'containers/Advanced/AddToAnotherListForm';
import CompaniesList from 'containers/Advanced/CompaniesList';

const AddToAnotherListModal = forwardRef(({ lists, selectedIds, isOpen, close, submitForm, onSubmit, addCompaniesToListLoading, removeSelectedCompany }, ref) => {
    const intl = useIntl();

    const [isDirty, setIsDirty] = useState(false);

    return (
        <ModalDialog
            open={isOpen}
            onClose={close}
            title={selectedIds.length > 0 ? intl.formatMessage({ id: 'customer.page.update.list.modal.title' }) : intl.formatMessage({ id: 'Luo tyhjä lista' })}
            actions={
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                    <Button
                        variant='text'
                        color='primary'
                        onClick={close}
                    >
                        {intl.formatMessage({ id: 'common.close' })}
                    </Button>
                    <Button
                        color='success'
                        disabled={!isDirty || addCompaniesToListLoading}
                        onClick={submitForm}
                    >
                        {intl.formatMessage({ id: 'customer.page.add.companies.to.list.button' })}
                        {addCompaniesToListLoading && <ButtonSpinner />}
                    </Button>
                </Stack>
            }
            fixedHeight={4}
            maxHeight='38rem'
        >
            <Box sx={{ p: 3 }}>
                <AddToAnotherListForm ref={ref} lists={lists} onSubmit={onSubmit} setIsDirty={setIsDirty} addCompaniesToListLoading={addCompaniesToListLoading} />
                {selectedIds.length > 0 &&
                    <Typography sx={{ mt: 2, fontWeight: 500, color: 'secondary.grey700' }}>{intl.formatMessage({ id: 'customer.page.modal.form.companies.tobeincluded' })}</Typography>
                }
                <CompaniesList selectedIds={selectedIds} removeCompany={removeSelectedCompany} />
            </Box>
        </ModalDialog>
    );
});

export default AddToAnotherListModal;
