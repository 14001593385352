import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

import TeamMemberModal from '../TeamMemberModal';

const StyledButton = styled(Button)(({ theme }) => (
    {
        marginTop: theme.spacing(2),
        textTransform: 'none'
    }
));

const TeamMemberModalIgniter = ({ title }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <StyledButton color='success' size='large' startIcon={<AddIcon />} onClick={() => setOpen(true)}>{title}</StyledButton>
            <TeamMemberModal open={open} setOpen={setOpen} teamMember={{ firstname: '', lastname: '', position: '', description: '', linkedInUrl: '', avatar: '' }} />
        </>
    );
};

export default TeamMemberModalIgniter;
