import * as React from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { isDate, isNil } from 'lodash';
import { format, isAfter, isBefore, isValid, parseISO } from 'date-fns';
import Reference from 'yup/lib/Reference';

type Props = { children: React.ReactNode };

declare module 'yup' {
	interface StringSchema {
		isoDate(message?: string): this;
		minIsoDate(min: Date | Reference<string>, message?: string): this;
		maxIsoDate(max: Date | Reference<string>, message?: string): this;
		phoneNumber(message?: string): this;
	}
}

const isValidDate = (date: any) => isDate(date) && isValid(date);

Yup.addMethod(Yup.string, 'isoDate', function () {
	return this.test({
		name: 'isoDate',
		exclusive: true,
		test: () => true,
	});
});

Yup.addMethod(Yup.string, 'minIsoDate', function () {
	return this.test({
		name: 'minIsoDate',
		exclusive: true,
		test: () => true,
	});
});

Yup.addMethod(Yup.string, 'phoneNumber', function () {
	return this.test({
		name: 'phoneNumber',
		exclusive: true,
		test: () => true,
	});
});

Yup.addMethod(Yup.string, 'maxIsoDate', function () {
	return this.test({
		name: 'maxIsoDate',
		exclusive: true,
		test: () => true,
	});
});

export const YupProvider: React.FC<Props> = ({ children }) => {
	const { formatMessage } = useIntl();

	React.useEffect(() => {
		Yup.addMethod(Yup.string, 'phoneNumber', function (message?: string) {
			return this.test({
				name: 'phoneNumber',
				exclusive: true,
				test: function (value, context) {
					if (!value) {
						return true;
					}
					const regex = /^[0-9\+\-\(\)\s]{6,20}$/;
					if (!regex.test(value)) {
						return context.createError({
							path: context.path,
							message: message || formatMessage({ id: 'validation.string.phoneNumber' }),
						});
					}
					return true;
				},
			});
		});

		Yup.addMethod(Yup.string, 'isoDate', function (message?: string) {
			return this.test({
				name: 'isoDate',
				exclusive: true,
				test: (value, context) => {
					if (isNil(value)) {
						return true;
					}
					const valueDate = parseISO(value);
					if (!isValidDate(valueDate)) {
						return context.createError({
							path: context.path,
							message: message || formatMessage({ id: 'validation.string.isoDate' }),
						});
					}
					return true;
				},
			});
		});

		Yup.addMethod(Yup.string, 'minIsoDate', function (min: Date | Reference<string>, message?: string) {
			return this.test({
				name: 'minIsoDate',
				exclusive: true,
				test: function (value, context) {
					if (isNil(value)) {
						return true;
					}
					const valueDate = parseISO(value);
					let minDate;
					if (min instanceof Date) {
						minDate = min;
					} else {
						minDate = parseISO(this.resolve(min));
					}
					if (isValidDate(valueDate) && isValidDate(minDate) && isBefore(valueDate, minDate)) {
						return context.createError({
							path: context.path,
							message:
								message || formatMessage({ id: 'validation.string.isoDate.min' }, { min: format(minDate, 'dd.MM.yyyy') }),
							params: { min: format(minDate, 'dd.MM.yyyy') },
						});
					}
					return true;
				},
			});
		});

		Yup.addMethod(Yup.string, 'maxIsoDate', function (max: Date | Reference<string>, message?: string) {
			return this.test({
				name: 'maxIsoDate',
				exclusive: true,
				test: function (value, context) {
					if (isNil(value)) {
						return true;
					}
					const valueDate = parseISO(value);
					let maxDate;
					if (max instanceof Date) {
						maxDate = max;
					} else {
						maxDate = parseISO(this.resolve(max));
					}
					if (isValidDate(valueDate) && isValidDate(maxDate) && isAfter(valueDate, maxDate)) {
						return context.createError({
							path: context.path,
							message:
								message || formatMessage({ id: 'validation.string.isoDate.max' }, { max: format(maxDate, 'dd.MM.yyyy') }),
							params: { max: format(maxDate, 'dd.MM.yyyy') },
						});
					}
					return true;
				},
			});
		});

		Yup.setLocale({
			string: {
				max: ({ max }) => formatMessage({ id: 'validation.string.max' }, { max }),
				min: ({ min }) => formatMessage({ id: 'validation.string.min' }, { min }),
				email: () => formatMessage({ id: 'validation.string.email' }),
			},
			mixed: {
				required: () => formatMessage({ id: 'validation.mixed.required' }),
			},
			number: {
				min: ({ min }) => formatMessage({ id: 'validation.number.min' }, { min }),
				max: ({ max }) => formatMessage({ id: 'validation.number.max' }, { max }),
				positive: () => formatMessage({ id: 'validation.number.positive' }),
			},
			array: {
				min: ({ min }) => formatMessage({ id: 'validation.array.min' }, { min }),
			},
		});
	}, [formatMessage]);

	return <>{children}</>;
};
