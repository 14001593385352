import { Button, Checkbox, FormControlLabel, Menu, MenuItem, createSvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import React, { useRef, useState } from 'react';
import { generateUid } from '../../utils/helpers/uid';

const ChevronDownIcon = createSvgIcon(
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
		<path
			fillRule='evenodd'
			d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
			clipRule='evenodd'
		/>
	</svg>,
	'ChevronDown'
);

export interface SelectOption {
	label: string;
	value: string;
	children?: Array<SelectOption>;
}

export interface MultiSelectProps {
	label: string;
	onChange?: (value: SelectOption[]) => void;
	options: SelectOption[];
	value: SelectOption[];
	children?: any;
	sx?: any;
}

const MultiSelect: FC<MultiSelectProps> = props => {
	const { label, onChange, options, value, children, ...other } = props;
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const [openMenu, setOpenMenu] = useState<boolean>(false);

	const handleOptionToggle = (event: ChangeEvent<HTMLInputElement>, option: SelectOption): void => {
		let newValue = [...value];

		if (event.target.checked) {
			newValue.push(option);
		} else {
			newValue = newValue.filter(item => item.value !== event.target.value);
		}

		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<>
			<Button
				color='inherit'
				endIcon={<ChevronDownIcon fontSize='small' />}
				onClick={() => setOpenMenu(true)}
				ref={anchorRef}
				variant='text'
				{...other}
			>
				{label}
			</Button>
			<Menu
				anchorEl={anchorRef.current}
				elevation={1}
				onClose={() => setOpenMenu(false)}
				open={openMenu}
				PaperProps={{
					style: {
						maxWidth: 'auto',
						overflow: 'hidden',
					},
				}}
				sx={{ zIndex: 20001 }}
			>
				{options?.map(option => {
					return (
						<MenuItem
							sx={{
								justifyContent: 'left',
								minWidth: 0,
								display: 'block',
								p: '.4rem',
								fontSize: '.8rem',
							}}
							key={generateUid()}
						>
							{!option?.children && (
								<FormControlLabel
									control={
										<Checkbox
											sx={{ fontSize: '.8rem' }}
											checked={value.findIndex(item => item.value === option.value) > -1}
											color='primary'
											onChange={e => {
												handleOptionToggle(e, option);
											}}
											value={option.value}
											data-name={option.label}
										/>
									}
									label={option.label}
								/>
							)}
							{option?.children && (
								<MultiSelect
									key={generateUid()}
									label={option.label}
									onChange={onChange}
									options={option?.children}
									value={value}
								/>
							)}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

MultiSelect.propTypes = {
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	options: PropTypes.array.isRequired,
	value: PropTypes.array.isRequired,
};

export default React.memo(MultiSelect);
