import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PanelTitle from 'components/Titles/PanelTitle';
import * as _ from 'lodash';
import moment from 'moment';
import { useIntl } from 'react-intl';

// import Timeline from 'containers/FinancialPlan';
import FinancialPlanProjectList from 'containers/Projects/FinancialPlanProjectList';
import TimelineContainer from 'views/Dashboard/TimelineContainer';
import MainSection from './MainSection';

import { Divider } from '@mui/material';
import htmlParser from 'components/HtmlParser';
import { getAddress, getDate } from 'containers/Company/CompanyInfo/utils';
import { setMainContentMounted } from 'containers/FinancialPlan/slices';
import { sort } from 'containers/Projects/utils';
import { getEuroFormatted } from 'utils/formatters/getEuroFormatted';

const useStyles = makeStyles(theme => ({
	keyText: {
		fontSize: '1rem',
		fontWeight: 600,
	},
	propText: {
		fontSize: '1rem',
		maxWidth: 'lg',
		'& p': {
			marginBottom: 0,
		},
	},
	profileContainer: {
		[theme.breakpoints.down('md')]: {
			'&>div:nth-child(odd)': {
				backgroundColor: theme.palette.primary.secondary,
			},
		},
		[theme.breakpoints.up('md')]: {
			backgroundColor: theme.palette.primary.secondary,
			'& div:nth-child(4n), div:nth-child(4n-1)': {
				backgroundColor: theme.palette.primary.white,
			},
		},
	},
	profileBox: {
		padding: theme.spacing(1),
		color: theme.palette.primary.main,
		[theme.breakpoints.up('md')]: {
			display: 'inline-block',
			width: '50%',
			minHeight: theme.spacing(8),
			maxHeight: theme.spacing(8),
		},
	},
	instrumentTitle: {
		fontSize: '1.1rem',
		fontWeight: 500,
	},
	instrumentText: {
		fontSize: '1rem',
		color: theme.palette.primary.main,
	},
	instrumentName: {
		fontSize: '1.1rem',
		fontWeight: 500,
		color: theme.palette.primary.main,
	},
	instrumentDate: {
		fontSize: '1.0rem',
		fontWeight: 500,
		paddingTop: '1px',
	},
	cell: {
		width: '50%',
		padding: '6px 16px 6px 0',
		borderBottom: 'none',
	},
}));

const FinancialPlanTimeline = ({ timeline, company, instruments }) => {
	if (timeline) return timeline;

	return <TimelineContainer scrollToNoInsruments={null} companyId={company?.companyId} instruments={instruments} printView={true} />;
};

const MainContent = ({ financialPlan, projects, company, scenario, instruments, printRef, timeline }) => {
	const classes = useStyles();
	const intl = useIntl();
	const dispatch = useDispatch();

	const scenarioValues = [0, 1, 2, 4, 8, 16, 32];

	const getIndex = (value, scenarioValues) =>
		scenarioValues.reduce((acc, item, index) => {
			return item === value ? index : acc;
		}, 0);

	const timelineProjects = projects && sort(projects, item => item.startDate);

	useEffect(() => {
		dispatch(setMainContentMounted(true));
		return () => {
			dispatch(setMainContentMounted(false));
		};
	}, [dispatch]);

	return (
		<div ref={printRef}>
			{/* Cover letter */}
			<MainSection id='coverLetter' hidden={!financialPlan?.base}>
				<PanelTitle variant='h2' id='coverLetter' className={'tocTitle'}>
					{intl.formatMessage({ id: 'financial.plan.coverLetter' })}
				</PanelTitle>
				<Typography component='div' className={classes.propText}>
					{htmlParser(financialPlan?.base)}
				</Typography>
			</MainSection>
			{/* CompanyInfo: */}
			<MainSection id='companyinfo'>
				<PanelTitle variant='h2' id='companyinfo' sx={{ mb: '2rem' }} className={'tocTitle'}>
					{intl.formatMessage({ id: 'editing.panel.companyinfo' })}
				</PanelTitle>
				<Grid container spacing={4}>
					<Grid item>
						<Grid container direction='column'>
							<Grid item>
								<Typography component='p' className={classes.keyText}>
									{intl.formatMessage({ id: 'common.business.id' })}:
								</Typography>
							</Grid>
							<Grid item>
								<Typography component='p' className={classes.keyText}>
									{intl.formatMessage({ id: 'common.address' })}:
								</Typography>
							</Grid>
							<Grid item>
								<Typography component='p' className={classes.keyText}>
									{intl.formatMessage({ id: 'common.foundation.year' })}:
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container direction='column'>
							<Grid item>
								<Typography component='p' className={classes.propText}>
									{company?.businessId}&nbsp;
								</Typography>
							</Grid>
							<Grid item>
								<Typography color='primary' className={classes.propText}>
									{getAddress(company?.address)}&nbsp;
								</Typography>
							</Grid>
							<Grid item>
								<Typography component='p' className={classes.propText}>
									{getDate(company?.registrationDate) ?? '-'}&nbsp;
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</MainSection>
			{/* Profile Info: */}
			<MainSection id='financialInfo'>
				<PanelTitle variant='h2' id='financialInfo' sx={{ mb: '2rem' }} className={'tocTitle'}>
					{intl.formatMessage({ id: 'basic.financial.info' })}
				</PanelTitle>
				<Box className={`${classes.profileContainer} profileContainerPrint`}>
					{_.map(scenario, (value, key) => {
						const isMissing = scenario?.missingValues?.find(item => item?.toLowerCase() === key?.toLowerCase());

						if (typeof value === 'number' && key !== 'grantedScore' && key !== 'totalValues')
							return (
								<Box key={key} className={`${classes.profileBox} profileBoxPrint`}>
									<Grid container>
										<Grid item xs={12} lgx={6}>
											<Typography component='label' htmlFor={key} className={classes.keyText}>
												{intl.formatMessage({ id: `instrument.radar.choice.${key}` })}:
											</Typography>
										</Grid>
										<Grid item xs={12} lgx={6}>
											<Typography component='span' className={classes.propText}>
												{isMissing
													? '-'
													: intl.formatMessage({
														id: `instrument.radar.choice.${key}.${getIndex(value, scenarioValues)}`,
													})}
											</Typography>
										</Grid>
									</Grid>
								</Box>
							);
					})}
				</Box>
			</MainSection>
			{/* General Conditions: */}
			<MainSection id='generalConditions' hidden={!financialPlan?.precondition}>
				<PanelTitle variant='h2' id='generalConditions' className={'tocTitle'}>
					{intl.formatMessage({ id: 'financial.plan.generalConditionsTitle' })}
				</PanelTitle>
				<Typography component='div' className={classes.propText}>
					{htmlParser(financialPlan?.precondition)}
				</Typography>
			</MainSection>
			{/* Notice: */}
			<MainSection id='notice' hidden={!financialPlan?.notice}>
				<PanelTitle variant='h2' id='notice' className={'tocTitle'}>
					{intl.formatMessage({ id: 'financial.plan.noticeTitle' })}
				</PanelTitle>
				<Typography component='div' className={classes.propText}>
					{htmlParser(financialPlan?.notice)}
				</Typography>
			</MainSection>
			{/* Recomendations: */}
			<MainSection id='recommendations' hidden={!financialPlan?.expertComments}>
				<PanelTitle variant='h2' id='recommendations' className={'tocTitle'}>
					{intl.formatMessage({ id: 'financial.plan.recomendationsTitle' })}
				</PanelTitle>
				<Typography component='div' className={classes.propText}>
					{htmlParser(financialPlan?.expertComments)}
				</Typography>
			</MainSection>
			{/* Timeline: */}
			<MainSection id='timeline'>
				<PanelTitle variant='h2' id='timeline' sx={{ mb: '2rem' }} className={'tocTitle'}>
					{intl.formatMessage({ id: 'financial.plan.timeline' })}
				</PanelTitle>
				<FinancialPlanTimeline {...{ timeline, company, instruments }} />
				{timelineProjects?.map(
					(
						{
							instrumentId,
							created,
							startDate,
							endDate,
							instrumentName,
							projectSummary,
							instrumentProvider,
							amountToApply,
							projectSize,
						},
						index
					) => {
						const instrumentIngress = instruments?.reduce((acc, item) => {
							return item.id === instrumentId ? item.ingress : acc;
						}, '');

						return (
							<Box
								sx={{ mt: '2rem', '&:last-child': { '& hr': { display: 'none' } } }}
								key={instrumentId + created}
								id={`instrument_${index + 1}`}
							>
								<Grid container alignItems='center' spacing={1}>
									<Grid item>
										<PanelTitle
											id={`instrument_${index + 1}`}
											variant='h3'
											className={`${classes.instrumentName} tocTitle`}
										>
											{index + 1}. {instrumentName}
										</PanelTitle>
									</Grid>
									<Grid item>
										<Typography component='p' className={classes.instrumentDate}>
											({moment(startDate).format('MM.YYYY')}-{moment(endDate).format('MM.YYYY')})
										</Typography>
									</Grid>
								</Grid>
								<Typography
									component='div'
									className={classes.propText}
									sx={{
										'& p, span, ul, li': {
											color: '#482E51 !important',
										},
									}}
								>
									{htmlParser(instrumentIngress?.replace(/(<p>&nbsp;<\/p>)+/g, ''))}
								</Typography>
								<Typography component='div' className={classes.propText} sx={{ mb: 1 }}>
									{htmlParser(projectSummary)}
								</Typography>
								<TableContainer>
									<Table aria-label='timeline' size='small'>
										<TableBody>
											<TableRow>
												<TableCell className={classes.cell}>
													<Typography component='p' className={classes.keyText}>
														{intl.formatMessage({ id: 'timeline.tooltip.schedule' })}
													</Typography>
												</TableCell>
												<TableCell className={classes.cell}>
													<Typography component='p' className={classes.propText}>
														{moment(startDate).format('DD.MM.YYYY')}-{moment(endDate).format('DD.MM.YYYY')}
													</Typography>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className={classes.cell}>
													<Typography component='p' className={classes.keyText}>
														{intl.formatMessage({ id: 'timeline.tooltip.provider' })}:
													</Typography>
												</TableCell>
												<TableCell className={classes.cell}>
													<Typography component='p' className={classes.propText}>
														{instrumentProvider}
													</Typography>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className={classes.cell}>
													<Typography component='p' className={classes.keyText}>
														{intl.formatMessage({ id: 'timeline.tooltip.grantAmount' })}:
													</Typography>
												</TableCell>
												<TableCell className={classes.cell}>
													<Typography component='p' className={classes.propText}>
														{getEuroFormatted(amountToApply)}
													</Typography>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className={classes.cell}>
													<Typography component='p' className={classes.keyText}>
														{intl.formatMessage({ id: 'timeline.tooltip.projectSize' })}:
													</Typography>
												</TableCell>
												<TableCell className={classes.cell}>
													<Typography component='p' className={classes.propText}>
														{getEuroFormatted(projectSize)}
													</Typography>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
								<Divider sx={{ m: '1rem .5rem' }} />
							</Box>
						);
					}
				)}
			</MainSection>
			{/* Follow Up: */}
			<MainSection id='followup' hidden={!financialPlan?.followup}>
				<PanelTitle variant='h2' id='followup' className={'tocTitle'}>
					{intl.formatMessage({ id: 'financial.plan.followupTitle' })}
				</PanelTitle>
				<Typography component='div' className={classes.propText}>
					{htmlParser(financialPlan?.followup)}
				</Typography>
			</MainSection>
			{/* Instruments: */}
			{/* TODO: figure out what should be here?! */}
			{/* {timeline?.instruments?.some(item => item.instrumentDescription !== null) ? (
                <MainSection id='instruments'>
                    <PanelTitle id='instruments'  className={'tocTitle'}>
                        {intl.formatMessage({ id: 'financial.plan.instrumentDescriptions' })}
                    </Typography>
                    {timeline?.instruments?.map(instrument => {
                        const { id, name, instrumentDescription, provider, endDate } = instrument;

                        if (!instrumentDescription) return null;

                        return (
                            <Box key={id + endDate}>
                                <Typography component='p' className={classes.instrumentTitle}>
                                    {provider} * {name}
                                </Typography>
                                <Typography component='div' className={classes.instrumentText}>
                                    {htmlParser(instrumentDescription)}
                                </Typography>
                            </Box>
                        );
                    })}
                </MainSection>
            ) : null} */}
			<FinancialPlanProjectList instruments={instruments} projects={projects} />
		</div>
	);
};

export default MainContent;
