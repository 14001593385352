import React from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Grid';
import { useTheme } from '@mui/styles';

import PanelTitle from 'components/Titles/PanelTitle';
import PanelSubtitle from 'components/Titles/PanelSubtitle';
import InfoItemModalIgniter from '../InfoItemPanel/InfoItemModalIgniter';
import TeamMemberModalIgniter from '../TeamMemberPanel/TeamMemberModalIgniter';

const Panel = ({ loading, title, subtitle, modalIgniter, mb, children, readonly }) => {
    const theme = useTheme();
    const intl = useIntl();

    const Igniter = ({ modalIgniter, readonly }) => {
        if (readonly) {
            return null;
        }

        return modalIgniter === 'info'
            ? <InfoItemModalIgniter title={intl.formatMessage({ id: 'business.plan.modal.title.info' })} readonly={readonly} />
            : modalIgniter === 'team'
                ? <TeamMemberModalIgniter title={intl.formatMessage({ id: 'business.plan.modal.title.member' })} readonly={readonly} />
                : null;
    };

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.primary.white,
                padding: theme.spacing(2),
                mb: mb ?? 'unset'
            }}
        >
            <PanelTitle>{title}</PanelTitle>
            <PanelSubtitle >{subtitle}</PanelSubtitle>
            <Box />
            <Box
                sx={{
                    maxHeight: !readonly ? '20rem' : '30rem',
                    paddingRight: theme.spacing(1),
                    paddingLeft: theme.spacing(1),
                    overflowY: loading ? 'visible' : 'auto',
                    overflowX: loading ? 'visible' : 'hidden',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                }}
            >
                {children}
            </Box>
            <Igniter {...{ modalIgniter, readonly }} />
        </Box>
    );
};

export default Panel;
