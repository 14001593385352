import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	name: {
		fontWeight: 600,
	},
	dialogName: {
		fontSize: '1.2rem',
		fontWeight: 600,
		paddingRight: theme.spacing(1),
		color: theme.palette.primary.main,
		[theme.breakpoints.up('sm')]: {
			fontSize: '1.4rem',
		},
	},
	input: {
		fontSize: '1rem',
		// color: theme.palette.secondary.grey500,
	},
}));

const filter = createFilterOptions({
	stringify: option => option.name + option.provider,
});

const InstrumentAutocomplete = ({
	variant = 'outlined',
	instrument,
	instruments,
	setNewInstrument,
	onChange,
	disabled = false,
	setInstruction = null,
	instructions = null,
	noCreation = false,
	readonly,
	onInputBlur = () => { },
}) => {
	const classes = useStyles();

	const renderOption = (props, { name, provider }) =>
		provider ? (
			<li {...props}>
				{' '}
				{name} - {provider}{' '}
			</li>
		) : (
			<li {...props}>{name}</li>
		);

	const getOptionLabel = option => {
		// Value selected with enter, right from the input
		if (typeof option === 'string') {
			return option;
		}
		// Add "xxx" option created dynamically
		if (option.inputValue) {
			return option.inputValue;
		}
		if (!option) {
			return '';
		}
		// Regular option
		return option.provider ? `${option.name} - ${option.provider}` : option.name;
	};

	const filterOptions = (options, params) => {
		const filtered = filter(options, params);
		// Suggest the creation of a new value
		if (params.inputValue !== '' && !noCreation) {
			filtered.push({
				inputValue: params.inputValue,
				name: `Lisää "${params.inputValue}"`,
			});
		}
		return filtered;
	};

	const handleChange = newValue => {
		setNewInstrument &&
			setNewInstrument({
				...instrument,
				isCustom: newValue.id ? false : true,
				name: newValue.name ?? null,
				type: newValue.type ?? null,
				provider: newValue.provider ?? null,
				id: newValue.id ?? null,
			});

		if (noCreation) {
			return {
				id: newValue.id,
				name: newValue.name,
				provider: newValue.provider,
			};
		}

		// Create a new value from the user input
		if (newValue && newValue.inputValue) {
			return newValue?.inputValue ?? '';
		} else if (newValue && typeof newValue === 'string') {
			return newValue ?? '';
		} else {
			return newValue?.name ?? '';
		}
	};

	return (
		<Autocomplete
			disabled={disabled}
			value={instrument}
			getOptionLabel={getOptionLabel}
			filterOptions={filterOptions}
			renderOption={renderOption}
			freeSolo
			disableClearable
			onChange={(_, data) => onChange(handleChange(data))}
			options={instruments?.slice().sort((a, b) => {
				const nameA = a.provider?.toUpperCase() ?? ''; // ignore upper and lowercase
				const nameB = b.provider?.toUpperCase() ?? ''; // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			})} // @ts-ignore
			groupBy={option => option.provider ?? 'Muut'}
			renderInput={params => {
				return (
					<TextField
						{...params}
						variant={readonly ? 'standard' : variant}
						size='small'
						fullWidth
						inputProps={{
							...params.inputProps,
							type: 'search',
							classes: { input: classes.input },
							// value: getOptionLabel(instrument),
						}}
						onFocus={() => setInstruction && setInstruction(instructions)}
						onBlur={() => {
							setInstruction && setInstruction('');
							onInputBlur();
						}}
					/>
				);
			}}
		/>
	);
};

export default InstrumentAutocomplete;
