import React from 'react';
import { useIntl } from 'react-intl';

import { useGetProjectDataQuery, useGetCompanyDataQuery, useGetInstrumentsDataQuery, useGetTimelineDataQuery, useGetScenarioDataQuery } from 'containers/Stakeholder/stakeholderApi';

import TimelineContainer from 'views/Dashboard/TimelineContainer/TimelineContainerNoData';
import { handleTimelineProjectsForUi } from 'containers/Projects/slices';

import Error from '../Components/Error';
import Loader from '../Components/Loader/Loader';
import FinancialPlan from 'views/FinancialPlanPage/FinancialPlan';

const Timeline = ({ linkId }) => {
    const intl = useIntl();

    const {
        data: projectData,
        isFetching: isProjectFetching,
        isLoading: isProjectLoading,
        isSuccess: isProjectSuccess
    } = useGetProjectDataQuery(linkId);

    const {
        data: timelineData,
        isFetching: isTimelineFetching,
        isLoading: isTimelineLoading,
        isSuccess: isTimelineSuccess
    } = useGetTimelineDataQuery(linkId);

    const company = useGetCompanyDataQuery(linkId);
    const instruments = useGetInstrumentsDataQuery(linkId);
    const scenario = useGetScenarioDataQuery(linkId);

    if (!projectData || !company.data || !instruments.data || !timelineData || !scenario.data) return <Loader />;

    if (!isTimelineSuccess || !isProjectSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.scenario.error' })}</Error>;

    const handledData = handleTimelineProjectsForUi(projectData.projects);

    return (
        <>
            <FinancialPlan
                company={company.data?.company}
                financialPlan={timelineData.financialPlan}
                publishedFinancialPlan={timelineData.financialPlan}
                projects={handledData.projects}
                scenario={scenario.data?.scenario}
                instruments={instruments.data?.instruments}
                readonly={true}
                sx={{ p: 0 }}
                timeline={
                    <TimelineContainer
                        loading={isProjectFetching || isProjectLoading}
                        projects={handledData.projects}
                        timeline={handledData.timeline}
                        companyId={company.data?.companyId}
                        instruments={instruments.data?.instruments}
                        pathname=''
                        printView={false}
                    />
                }
            />
        </>
    );
};

export default Timeline;