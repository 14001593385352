import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.grey400}`,
    color: theme.palette.secondary.grey600,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    fontSize: '0.875rem',
    maxWidth: '400px',
  },
  // tooltipPlacementTop: {
  //   margin: theme.spacing(0.5), --> does not work
  // },
}))(Tooltip);

export default CustomTooltip;
