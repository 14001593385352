import { yupResolver } from '@hookform/resolvers/yup';
import { filter, map, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { Box, FormControl } from '@mui/material';

import { getCompanyId } from 'utils/auth/company';

import { AIContextSelector } from '../AIContextSelector/AIContextSelector';

import ConfirmButton from 'components/Button/ConfirmButton';
import { resolve } from 'containers/Projects/Project/ProjectForm/ProjectFormExtendedTemplate/utils';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Instrument } from 'types/dataTypes';
import { useFetchInstrumentPromptQuery, usePostChatMessageMutation } from '../aiApi';
import AiGeneratorDialog from './AiGeneratorDialog';

export interface OpenAIMessage {
	role: string;
	content: string;
}

export interface Props {
	disabled: boolean;
	title: string;
	handleResponse?: (response: any) => void;
	project: any;
	instrumentId: string;
	instrument: Instrument;
}

const AiGeneratorForm = ({ title, handleResponse, project, instrumentId, instrument }: Props) => {
	const companyId = getCompanyId();

	const [aiLoading, setAiLoading] = React.useState(false);
	const [promptBase, setPromptBase] = React.useState<any[]>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const { formatMessage } = useIntl();

	const schema = yup.object().shape({
		content: yup.string(),
	});

	const { data: promptData } = useFetchInstrumentPromptQuery(
		{
			instrumentId: instrument?.instrumentPromptId ?? instrumentId,
		},
		{ skip: !(instrument?.instrumentPromptId ?? instrumentId) }
	);

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	const [postMessage] = usePostChatMessageMutation();
	const handleAiPostMessage = async (question: any) => {
		setLoading(true);
		setAiLoading(true);

		const callAi = async (promptDataItem: any) => {
			if (!promptDataItem) return;

			const reqMessages = [{ role: 'user', content: promptDataItem.prompt }];

			if (question.content) {
				reqMessages.push(question);
			}

			// filter prompt base items to correspond only needed
			let filteredPromptBase = filter(promptBase, (base: any) => {
				return promptDataItem?.context.find((item: string) => item === base.value);
			});

			const mappedPromptBase = map(filteredPromptBase, (item: any) => {
				return {
					role: 'user',
					content: item.data,
				};
			});

			if (promptDataItem?.context.find((item: string) => item === 'instrument') && instrument) {
				mappedPromptBase.push({
					role: 'user',
					content: `${instrument.instrumentPrompt} Instrumentti: ${instrument.name} - ${instrument.provider}. Instrumentin kuvaus: ${instrument.description}`,
				});
			}

			const projectFields = promptDataItem?.context?.filter((item: string) => item?.startsWith('project'));

			if (projectFields && project) {
				// eslint-disable-next-line array-callback-return
				const vals = projectFields.map((projectItem: any) => {
					const splittedSelector: string[] = projectItem?.split('.');
					const sub: string = splittedSelector.slice(1).join('.');

					return {
						role: 'user',
						content: `Projekti: ${project?.projectName} ${resolve(sub, project)}`,
					};
				});

				mappedPromptBase.push(...vals?.filter((item: any) => item));
			}

			const prompt = [...reqMessages, ...mappedPromptBase];

			const chatId = `${project?.projectId}.${instrumentId}`;

			const response = await postMessage({ companyId, chatId, messages: prompt });
			//const response = { data: { role: 'assistant', content: 'AI answer' } };

			// @ts-ignore
			if (response.data?.message) {
				// @ts-ignore
				toast.error(response.data?.message);

				return;
			}

			if (handleResponse) {
				// @ts-ignore
				handleResponse(response?.data[0], promptDataItem);
			}
		};

		let promises = [];
		for (const item of promptData) {
			promises.push(callAi(item));
		}

		Promise.all(promises).then(values => {
			setLoading(false);
			setAiLoading(false);

			reset();
		});

		// setLoading(false);
		// setAiLoading(false);

		// reset();
	};

	const onSelectedChanged = (values: any) => {
		const uniqueItems = uniqBy(values, (item: any) => item.value);

		setPromptBase(uniqueItems);
	};

	const parsePromptPreselectedData = (data: any) => {
		return [].concat(...data.map(({ context }: any) => context || []));
	};

	if (!promptData) return null;

	return (
		<>
			<AiGeneratorDialog
				instrumentPromptId={instrument?.instrumentPromptId ?? instrumentId}
				handleAiGenerateResponse={handleResponse}
				project={project}
				instrumentId={instrumentId}
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
			/>
			<div style={{ display: 'none' }}>
				<Box>
					<AIContextSelector
						companyId={companyId}
						onSelectedChanged={onSelectedChanged}
						preSelectedItems={parsePromptPreselectedData(promptData)}
					/>
				</Box>
			</div>
			<Box component='form' onSubmit={handleSubmit(data => handleAiPostMessage(data))}>
				<FormControl sx={{ m: '0 !important' }}>
					{/* @ts-ignore */}
					<ConfirmButton
						size='large'
						variant='contained'
						// color='error'
						startIcon={<PsychologyAltIcon />}
						confirmAction={handleSubmit(data => {
							handleAiPostMessage(data);
						})}
						confirmText={formatMessage({ id: 'ai-generator.confirm.confirm' })}
						cancelText={formatMessage({ id: 'ai-generator.confirm.cancel' })}
						confirmBodyText={formatMessage({ id: 'ai-generator.confirm.body' })}
						buttonText={formatMessage({ id: 'ai-generator.confirm.remove' })}
						loading={isLoading || aiLoading}
						disabled={isLoading || aiLoading}
					/>

					{/* <Button
						size='large'
						variant='contained'
						startIcon={<PsychologyAltIcon />}
						disabled={isLoading || aiLoading}
						onClick={handleSubmit(data => {
							handleAiPostMessage(data);
						})}
					>
						Generoi
					</Button> */}
					{/* <ButtonGroup variant='contained' aria-label='split button'>
						<Button
							startIcon={<PsychologyAltIcon />}
							disabled={isLoading || aiLoading}
							onClick={handleSubmit(data => {
								handleAiPostMessage(data);
							})}
						>
							Generoi
						</Button>
						<Button disabled={isLoading || aiLoading} size='medium' onClick={() => setModalOpen(true)}>
							<AddCircleOutlineOutlinedIcon />
						</Button>
					</ButtonGroup> */}
				</FormControl>
			</Box>
		</>
	);
};

export default AiGeneratorForm;
