import { concat, isNil, map } from 'lodash';
import { createApi } from '@reduxjs/toolkit/query/react';

import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

import { RfpAttachment, RfpCommonData } from 'types/dataTypes';

type FetchRfpCommonData = void;

type FetchRfpAttachments = {
	companyId?: string | null;
	parentId?: string | null;
};

type FetchRfpRequestAttachmentFile = {
	companyId?: string | null;
	requestId?: string | null;
	published?: boolean;
	attachmentId?: string | null;
};

type FetchRfpProposalAttachmentFile = {
	companyId?: string | null;
	requestId?: string | null;
	proposalId?: string | null;
	published?: boolean;
	attachmentId?: string | null;
};

type UpsertRfpAttachment = {
	companyId?: string | null;
	parentId?: string | null;
	parentType?: string | null;
	rfpAttachments?: Partial<RfpAttachment>[] | null;
};

type RemoveRfpAttachment = {
	companyId?: string | null;
	parentId?: string | null;
	attachmentId?: string | null;
};

enum Tags {
	CommonData = 'commonData',
	RfpAttachment = 'rfpAttachment',
}

const baseUrl = 'rfp';

export const rfpCommonApi = createApi({
	reducerPath: 'rfpCommon',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.CommonData, Tags.RfpAttachment],
	endpoints: builder => ({
		fetchCommonData: builder.query<RfpCommonData, FetchRfpCommonData>({
			query: () => {
				return {
					method: 'GET',
					url: '/common/data',
				};
			},
			providesTags: () => {
				return [{ type: Tags.CommonData, id: 'LIST' }];
			},
		}),
		fetchRfpAttachments: builder.query<RfpAttachment[], FetchRfpAttachments>({
			query: ({ companyId, parentId }) => {
				if (isNil(companyId) || isNil(parentId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/attachment/${companyId}/${parentId}`,
				};
			},
			providesTags: rfpAttachments => {
				return concat(
					[{ type: Tags.RfpAttachment, id: 'LIST' }],
					map(rfpAttachments, rfpAttachment => ({
						type: Tags.RfpAttachment,
						id: rfpAttachment.attachmentId || '',
					}))
				);
			},
		}),
		fetchRfpRequestAttachmentFile: builder.query<Blob, FetchRfpRequestAttachmentFile>({
			query: ({ companyId, requestId, attachmentId, published }) => {
				if (isNil(companyId) || isNil(requestId) || isNil(attachmentId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: published
						? `/published/request/${companyId}/${requestId}/attachment/${attachmentId}`
						: `/attachment/${companyId}/${requestId}/${attachmentId}`,
					responseType: 'blob',
				};
			},
		}),
		fetchRfpProposalAttachmentFile: builder.query<Blob, FetchRfpProposalAttachmentFile>({
			query: ({ companyId, requestId, proposalId, attachmentId, published }) => {
				if (isNil(companyId) || isNil(proposalId) || isNil(attachmentId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: published
						? `/request/${companyId}/${requestId}/proposals/${proposalId}/attachment/${attachmentId}`
						: `/attachment/${companyId}/${proposalId}/${attachmentId}`,
					responseType: 'blob',
				};
			},
		}),
		upsertRfpAttachment: builder.mutation<RfpAttachment, UpsertRfpAttachment>({
			query: ({ companyId, parentId, parentType, rfpAttachments }) => {
				if (isNil(companyId) || isNil(parentId) || isNil(parentType) || isNil(rfpAttachments)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/attachment/${companyId}`,
					data: {
						attachmentParentId: parentId,
						attachmentType: parentType,
						attachments: rfpAttachments,
					},
				};
			},
			invalidatesTags: rfpAttachment => {
				return [
					{ type: Tags.RfpAttachment, id: 'LIST' },
					{ type: Tags.RfpAttachment, id: rfpAttachment?.attachmentId || '' },
				];
			},
		}),
		removeRfpAttachment: builder.mutation<RfpAttachment, RemoveRfpAttachment>({
			query: ({ companyId, parentId, attachmentId }) => {
				if (isNil(companyId) || isNil(parentId) || isNil(attachmentId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/attachment/${companyId}/${parentId}/${attachmentId}`,
				};
			},
			invalidatesTags: (result, error, { attachmentId }) => {
				return [
					{ type: Tags.RfpAttachment, id: 'LIST' },
					{ type: Tags.RfpAttachment, id: attachmentId || '' },
				];
			},
		}),
	}),
});

export const {
	useFetchCommonDataQuery,
	useFetchRfpAttachmentsQuery,
	useLazyFetchRfpRequestAttachmentFileQuery,
	useLazyFetchRfpProposalAttachmentFileQuery,
	useUpsertRfpAttachmentMutation,
	useRemoveRfpAttachmentMutation,
} = rfpCommonApi;
