import { useCallback, useEffect, useState } from 'react';

export const useLocalStorage = (key: string, defaultValue: any = null) => {
	// pull the initial value from local storage if it is already set
	const [state, setState] = useState(() => {
		const exValue = localStorage.getItem(key);

		if (exValue) {
			return JSON.parse(exValue);
		}

		return defaultValue;
	});

	// save the new value when it changes
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [state]); // eslint-disable-line react-hooks/exhaustive-deps

	// memoize a storage watcher callback back because everything in hooks should be memoized
	const storageWatcher = useCallback(
		(e: any) => {
			try {
				if (e.newValue) {
					const newDat = JSON.parse(e.newValue || 'null');
					// update ours if we
					setState((currState: any) => {
						return newDat === state ? newDat : currState;
					});
				}
			} catch {
				// ditch exception
			}
		},
		[state]
	); // eslint-disable-line react-hooks/exhaustive-deps

	// install the watcher
	useEffect(() => {
		window.addEventListener('storage', storageWatcher);
		// stop listening on remove
		return () => {
			window.removeEventListener('storage', storageWatcher);
		};
	}, [state]); // eslint-disable-line react-hooks/exhaustive-deps

	return [state, setState];
};
