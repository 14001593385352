import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ContentContainer from 'components/Containers/ContentContainer';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Spinner from 'components/Spinner/Spinner';
import MainTitle from 'components/Titles/MainTitle';
import AttachmentPanel from './AttachmentPanel';
import BusinessPlanForm from './BusinessPlanForm/index';
import InfoItemPanel from './InfoItemPanel';
import TeamMemberPanel from './TeamMemberPanel';

import LocalBackupAlert from 'components/LocalBackup/LocalBackupAlert';
import { getTags } from 'containers/BusinessPlan/slices';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { getCompanyId } from 'utils/auth/company';
import { getCompanyPath } from 'utils/auth/getCompanyPath';
import { useLocalBackup } from 'utils/helpers/localBackupUtils';
import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';
import { removeAttachment, updateMain } from './slices';
import { combineEconomyData, parseEconomicalData } from './utils';

const BusinessPlanPage = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname, hash } = useLocation();
	const formRef = useRef();

	const companyId = getCompanyId();

	useScrollIntoViewWithOffset('general', hash);

	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const instruments = useSelector(state => state.instruments?.instruments);
	const businessPlan = useSelector(state => state.businessPlan);

	const companyEconomicalData = company?.economicalData;

	// main + tags
	const main = businessPlan?.main;
	const mainLoading = main?.loading;
	const mainData = main?.data;
	const businessId = mainData?.businessId;

	// infoItems
	const infoItems = businessPlan?.infoItems;
	const infoItemsLoading = infoItems?.loading;
	const infoItemsData = infoItems?.data;
	// teamMembers
	const teamMembers = businessPlan?.teamMembers;
	const teamMembersLoading = teamMembers?.loading;
	const teamMembersData = teamMembers?.data;
	// templates
	const templates = businessPlan?.templates;
	const templatesLoading = templates?.loading;
	const templatesData = templates?.data;
	// economicaldata
	const economics = businessPlan?.economicalData;
	const economicalData = economics?.data;
	// attachments
	const attachments = businessPlan?.attachments;
	const attachmentsData = attachments;
	const attachmentsLoaded = attachments?.isLoaded;
	const attachmentsLoading = attachments?.loading;

	const mainAndEconomicalData = useMemo(() => {
		return {
			...mainData,
			costs: mainData?.costs ?? [],
			economicalData: companyEconomicalData
				? combineEconomyData(companyEconomicalData, economicalData)
				: parseEconomicalData(economicalData),
		};
	}, [mainData, companyEconomicalData, economicalData]);

	const {
		data: businessPlanLocalBackupData,
		isLocalBackup,
		deleteLocalBackup,
		updateLocalBackup,
	} = useLocalBackup('business_plan_backup', companyId, mainAndEconomicalData);

	// portal
	const container = useRef(null);

	const [loading, setLoading] = useState(false);
	const [isDirty, setIsDirty] = useState(false);

	const [tags, setTags] = useState(null);

	useEffect(() => {
		if (isEmpty(instruments?.data)) return;

		const fetchTags = async () => {
			const ret = await dispatch(getTags(instruments?.data));
			setTags(ret);
		};

		fetchTags().catch(console.error);
	}, [dispatch, instruments]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = async data => {
		setLoading(true);
		const success = await dispatch(updateMain(data));
		deleteLocalBackup();
		setLoading(false);
		if (success) {
			toast.success(intl.formatMessage({ id: 'business.plan.info.saving.successed' }));
		} else {
			toast.error(intl.formatMessage({ id: 'business.plan.info.saving.failed' }));
		}
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const economicalDataLoading = economics?.loading || isCompanyLoading;

	const businessPlanPrintPath = getCompanyPath('/businessplan/print', pathname, businessId);

	const removeFile = async id => {
		const success = await dispatch(removeAttachment(id));

		if (success) {
			toast.success(intl.formatMessage({ id: 'attachments.remove.success' }));
		} else {
			toast.error(intl.formatMessage({ id: 'attachments.remove.failed' }));
		}
	};

	return (
		<ContentContainer id='general'>
			<LocalBackupAlert isLocalBackup={isLocalBackup} deleteLocalBackup={deleteLocalBackup} />
			<LeftRightContainer
				left={isCompanyLoading ? <Spinner size={16} /> : <MainTitle margin={0}>{company?.name}</MainTitle>}
				right={
					<Grid container spacing={2} alignItems='center'>
						<Grid item>
							<Button variant='outlined' borderColor='grey600' onClick={() => history.push(businessPlanPrintPath)}>
								{intl.formatMessage({ id: 'business.plan.preview.button' })}
							</Button>
						</Grid>
						<Grid item>
							<Button color='success' disabled={loading ? true : isLocalBackup ? false : !isDirty} onClick={submitForm}>
								{intl.formatMessage({ id: 'business.plan.publish.button' })}
								{loading && <ButtonSpinner />}
							</Button>
						</Grid>
					</Grid>
				}
			/>
			<Box ref={container} sx={{ mt: 2 }} />
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					{economicalDataLoading || !instruments?.isLoaded ? ( // TODO: get rid of economicalDataLoading dependency --> move it inside own component
						<Spinner size={16} />
					) : (
						<BusinessPlanForm
							data={businessPlanLocalBackupData}
							tags={tags}
							mainLoading={mainLoading}
							templatesLoading={templatesLoading}
							businessId={businessId}
							companyId={companyId}
							ref={formRef}
							templates={templatesData}
							onSubmit={onSubmit}
							setIsDirty={setIsDirty}
							container={container.current}
							updateLocalBackup={updateLocalBackup}
						/>
					)}
				</Grid>
				<Grid item xs={12} md={4}>
					<InfoItemPanel loading={infoItemsLoading} data={infoItemsData} />
					<TeamMemberPanel loading={teamMembersLoading} data={teamMembersData} />
					<AttachmentPanel {...{ attachmentsLoaded, attachments: attachmentsData, attachmentsLoading, removeFile }} />
				</Grid>
			</Grid>
		</ContentContainer>
	);
};

export default BusinessPlanPage;
