import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';

import ProjectAddCard from './ProjectAddCard';
import ProjectCard from './ProjectCard/index';

import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';

const ProjectList = ({ projects }) => {
	if (!projects || !projects.data || projects.data.length === 0) return null;

	let sortableProjectArray = [...projects?.data];
	let sortedProjectList = sortableProjectArray?.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp));

	return sortedProjectList.map(
		(
			{
				rowKey,
				projectId,
				projectName,
				projectState,
				projectSize,
				timestamp,
				projectDescription,
				instrumentName,
				instrumentProvider,
				amountToApply,
			},
			index
		) => {
			return (
				<Grid item key={rowKey + index} xs={12} md={4} xl={3}>
					<ProjectCard
						{...{
							projectId,
							projectName,
							projectState,
							projectSize,
							timestamp,
							projectDescription,
							instrumentName,
							instrumentProvider,
							amountToApply,
						}}
					/>
				</Grid>
			);
		}
	);
};

const ProjectGridList = ({ id, projects, addProject }) => {
	const { hash } = useLocation();
	useScrollIntoViewWithOffset(id, hash, 110);

	return (
		<main id={id}>
			<Grid container spacing={3}>
				<ProjectList projects={projects} />
				<Grid item xs={12} md={4} xl={3}>
					<ProjectAddCard addProject={addProject} />
				</Grid>
			</Grid>
		</main>
	);
};

export default ProjectGridList;
