import React from 'react';

import { Box, FormHelperText, Stack } from '@mui/material';
import InformationCircle from 'components/Icons/InformationCircle';

const InstructionBox = ({ value, }) => {
    if (!value) return null;

    return (
        <FormHelperText
            sx={{
                p: '1rem',
                pl: '1rem',
                pr: '1rem',
                m: 0,
                mt: '1rem',
                fontSize: '.9rem',
                background: '#f7f5f0'
            }}
            component='div'
        >
            <Stack direction='row' alignItems='flex-start'>
                <InformationCircle sx={{ mr: '1rem' }} />
                <Box sx={{ whiteSpace: 'pre-wrap' }}>{value}</Box>
            </Stack>
        </FormHelperText >
    );
};


export default InstructionBox;