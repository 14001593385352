import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { isBusinessId } from 'utils/helpers/companyUtils';

import { Company } from 'types/dataTypes';

type SearchCompanies = {
	companyId: string | null;
	searchTerm: string | null;
};

const Tags = {
	CompanySearch: 'companySearch',
};

const baseUrl = 'company';

export const companySearchV2Api = createApi({
	reducerPath: 'companySearchV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.CompanySearch],
	endpoints: builder => ({
		searchCompanies: builder.query<Company[], SearchCompanies>({
			query: ({ companyId, searchTerm }) => {
				if (isNil(companyId) || isNil(searchTerm)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: isBusinessId(searchTerm) ? `/${searchTerm}` : `/name/${searchTerm}`,
				};
			},
			providesTags: () => {
				return [{ type: Tags.CompanySearch, id: 'LIST' }];
			},
		}),
	}),
});

export const { useSearchCompaniesQuery } = companySearchV2Api;
