import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmButton from 'components/Button/ConfirmButton';
import ContentContainer from 'components/Containers/ContentContainer';
import ContentLoaderContainer from 'components/Containers/ContentLoaderContainer';
import StyledChip from 'components/Tags/StyledChip';
import Instruments from 'containers/Instruments';
import TimelineContainer from 'views/Dashboard/TimelineContainer';
import FinancialPlanForm from './FinancialPlanForm';

import LocalBackupAlert from 'components/LocalBackup/LocalBackupAlert';
import { secodandaryRgb } from 'config/colors';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { replaceCompanyInformation } from 'containers/FinancialPlan/FinancialPlan/dynamicDataReplace';
import { saveFinancialPlan } from 'containers/FinancialPlan/slices';
import { getCompanyId } from 'utils/auth/company';
import { useLocalBackup } from 'utils/helpers/localBackupUtils';

const FinancialPlan = ({ title, loading }) => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const formRef = useRef();

	const companyId = getCompanyId();
	const { data: company } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const financialPlan = useSelector(state => state.financialPlan);
	const timelineLoading = financialPlan?.loading;
	const draftFinancialPlan = financialPlan?.financialPlan;
	const publishedFinancialPlan = financialPlan?.publishedFinancialPlan;

	const financialPlanData = useMemo(() => {
		const data = draftFinancialPlan?.partitionKey
			? draftFinancialPlan
			: publishedFinancialPlan
				? publishedFinancialPlan
				: draftFinancialPlan;

		return {
			...data,
			expertComments: replaceCompanyInformation(data?.expertComments, company) ?? '',
			notice: replaceCompanyInformation(data?.notice, company) ?? '',
			precondition: replaceCompanyInformation(data?.precondition, company) ?? '',
			base: replaceCompanyInformation(data?.base, company) ?? '',
			internalInformation: replaceCompanyInformation(data?.internalInformation, company) ?? '',
			followup: replaceCompanyInformation(data?.followup, company) ?? '',
		};
	}, [company, draftFinancialPlan, publishedFinancialPlan]);

	const {
		data: financialPlanLocalBackupData,
		isLocalBackup,
		deleteLocalBackup,
		updateLocalBackup,
	} = useLocalBackup('financial_plan_backup', companyId, financialPlanData);

	const businessPlan = useSelector(state => state.businessPlan);
	const costs = businessPlan?.main?.data?.costs;

	const [isDirty, setIsDirty] = useState(false);
	const [isDraftLoading, setIsDraftLoading] = useState(false);
	const [isPublishLoading, setIsPublishLoading] = useState(false);

	const saveTimelineChanges = async (timeline, draft) => {
		if (draft) {
			setIsDraftLoading(true);
		} else {
			setIsPublishLoading(true);
		}
		const success = await dispatch(saveFinancialPlan(timeline, draft, company));

		if (success) {
			if (draft) {
				toast.success(intl.formatMessage({ id: 'timeline.saveTimeline.draft.success' }));
			} else {
				toast.success(intl.formatMessage({ id: 'timeline.saveTimeline.publish.success' }));
			}

			setIsDraftLoading(false);
			setIsPublishLoading(false);
			deleteLocalBackup();

			return;
		}

		if (draft) {
			toast.error(intl.formatMessage({ id: 'timeline.saveTimeline.draft.failed' }));
		} else {
			toast.error(intl.formatMessage({ id: 'timeline.saveTimeline.publish.failed' }));
		}

		setIsDraftLoading(false);
		setIsPublishLoading(false);
	};

	const getDate = date => {
		try {
			if (date === undefined) return '-';

			const da = moment(date);
			if (da.isValid()) return moment(date).format('LLL');

			return '-';
		} catch (error) {
			return '-';
		}
	};

	const getDates = timeline => {
		const isDraft = !timeline.published || timeline.modified > timeline.published;

		return (
			<List sx={{ p: 0 }}>
				<ListItem sx={{ p: 0 }}>
					<ListItemAvatar sx={{ mr: '1rem' }}>
						<StyledChip
							label={
								isDraft
									? intl.formatMessage({ id: 'timeline.saveTimeline.state.draft' })
									: intl.formatMessage({ id: 'timeline.saveTimeline.state.published' })
							}
						/>
					</ListItemAvatar>
					<ListItemText>{isDraft ? getDate(timeline.modified) : getDate(timeline.published)}</ListItemText>
				</ListItem>
				{isDraft && (
					<ListItem sx={{ p: 0 }}>
						<ListItemAvatar sx={{ mr: '1rem' }}>
							<StyledChip label={intl.formatMessage({ id: 'timeline.saveTimeline.state.published' })} />
						</ListItemAvatar>
						<ListItemText>{getDate(timeline.published)}</ListItemText>
					</ListItem>
				)}
			</List>
		);
	};

	const handlePublishAction = () => {
		const action = formRef.current.getFormData(saveTimelineChanges, false);

		if (action && typeof action === 'function') {
			action();
		} else {
			toast.error(intl.formatMessage({ id: 'timeline.saveTimeline.publish.failed' }));
		}
	};

	const handleClick = () => {
		const action = formRef.current.getFormData(saveTimelineChanges, true);

		if (action && typeof action === 'function') {
			action();
		} else {
			toast.error(intl.formatMessage({ id: 'timeline.saveTimeline.draft.failed' }));
		}
	};

	if (!financialPlanLocalBackupData) return null;

	return (
		<ContentContainer>
			<Box
				sx={{
					position: { xs: 'relative', md: 'sticky' },
					top: 0,
					zIndex: theme => theme.zIndex.appBar - 1,
					backgroundColor: 'primary.secondary',
					boxShadow: `rgba(${secodandaryRgb}, 1) 10px 1px 6px`,
				}}
			>
				<LocalBackupAlert isLocalBackup={isLocalBackup} deleteLocalBackup={deleteLocalBackup} />
				<Stack
					direction={{ xs: 'column', md: 'row' }}
					justifyContent='space-between'
					alignItems='flex-start'
					spacing={2}
					sx={{ pb: '1rem' }}
				>
					<Box sx={{ flexGrow: 1 }}>{title}</Box>
					<Stack sx={{}} direction={{ xs: 'column', sm: 'row' }} justifyContent='flex-end'>
						<ConfirmButton
							sx={{ mr: '1rem' }}
							confirmAction={handlePublishAction}
							confirmText={intl.formatMessage({ id: 'timeline.saveTimeline.publish' })}
							cancelText={intl.formatMessage({ id: 'timeline.saveTimeline.publish.cancel' })}
							confirmBodyText={intl.formatMessage({ id: 'timeline.saveTimeline.publish.description' })}
							buttonText={intl.formatMessage({ id: 'timeline.saveTimeline.publish' })}
							color='primary'
							loading={isPublishLoading}
							disabled={timelineLoading}
						/>
						<Button
							color='success'
							disabled={timelineLoading || isDraftLoading ? true : isLocalBackup ? false : !isDirty}
							onClick={handleClick}
						>
							{intl.formatMessage({ id: 'timeline.saveTimeline.draft' })}
							{isDraftLoading && <ButtonSpinner />}
						</Button>
					</Stack>
				</Stack>
			</Box>
			<Grid container spacing={1} justifyContent='center' sx={{ mt: 0 }}>
				<Grid item xs={12} xl={3}>
					{getDates(financialPlanLocalBackupData)}
				</Grid>
				<Grid item xs={12} xl={9}>
					<ContentLoaderContainer surrogate={<div></div>} loading={loading}>
						{!loading && (
							<FinancialPlanForm
								data={financialPlanLocalBackupData}
								timelineLoading={timelineLoading}
								isDraftLoading={isDraftLoading}
								isPublishLoading={isPublishLoading}
								company={company}
								saveTimeline={saveTimelineChanges}
								ref={formRef}
								passIsDirty={isDirty => setIsDirty(isDirty)}
								updateLocalBackup={updateLocalBackup}
							/>
						)}
					</ContentLoaderContainer>
				</Grid>
			</Grid>
			<Grid container justifyContent='center' sx={{ mt: 4 }}>
				<Grid item xs={12}>
					<TimelineContainer
						scrollToNoInsruments={null}
						companyId={company?.companyId}
						instruments={financialPlan?.instruments.list}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent='center' sx={{ mt: 4 }}>
				<Grid item xs={12}>
					<Instruments
						instruments={financialPlan?.instruments.list}
						company={company}
						loading={!financialPlan?.instruments.isLoaded}
						scrollToTimeline={null}
						costs={costs}
					/>
				</Grid>
			</Grid>
		</ContentContainer>
	);
};

export default FinancialPlan;
