import React from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { Box, Skeleton, Stack, Typography } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import { useGetProjectDataQuery } from 'containers/Stakeholder/stakeholderApi';
import Project from './Project';
import { Link } from 'react-router-dom';
import Error from '../Components/Error';

export const ProjectSkeleton = () => {
	return (
		<Stack direction='column' sx={{ mb: '1.5rem' }}>
			<Skeleton variant='rectangular' height={100} sx={{ mb: '20px', width: '100%' }} />
			<Skeleton variant='rectangular' height={100} sx={{ mb: '20px', width: '100%' }} />
			<Skeleton variant='rectangular' height={100} sx={{ mb: '20px', width: '100%' }} />
			<Skeleton variant='rectangular' height={100} sx={{ mb: '20px', width: '100%' }} />
		</Stack>
	);
};

const ProjectItem = ({ projectItem }) => {
	const intl = useIntl();
	if (!projectItem) return null;

	return (
		<>
			<Link to={`projects/${projectItem.projectId}`} style={{ textDecoration: 'none' }}>
				<Stack
					variant='contained'
					sx={{
						background: '#fff',
						p: '1rem',
						mb: '1rem',
						color: '#482e51',
						'&:hover': {
							opacity: '0.8',
						},
					}}
				>
					<Stack direction='row'>
						<Box
							sx={{
								flexGrow: 1,
								fontSize: '1.1rem',
								fontWeight: 500,
								mb: '1rem',
							}}
						>
							{projectItem.projectName}
						</Box>
						<Box>
							{moment(projectItem.startDate).format('D.M.YYYY')} - {moment(projectItem.endDate).format('D.M.YYYY')}
						</Box>
					</Stack>
					<Stack direction='row' alignItems='center' alignContent='center'>
						<Box sx={{ flexGrow: 1 }}>
							{projectItem.instrumentName} - {projectItem.instrumentProvider}
						</Box>

						<BusinessCenterIcon sx={{ mr: '1rem' }} />
						<Typography sx={{ mt: '.2rem' }}>
							{projectItem.instrumentType
								? intl.formatMessage({ id: `check.item.${projectItem.instrumentType}` })
								: intl.formatMessage({ id: 'instruments.edit.check.item.' })}
						</Typography>
					</Stack>
				</Stack>
			</Link>
		</>
	);
};

const NoData = () => {
	const intl = useIntl();

	return (
		<Stack
			sx={{ width: '100%', background: '#fff', p: '2rem' }}
			direction='column'
			spacing={4}
			alignItems='center'
			justifyContent='center'
		>
			<Box>{intl.formatMessage({ id: 'stakeholder.projects.nodata' })}</Box>
		</Stack>
	);
};

const Projects = ({ linkId }) => {
	const { param } = useParams();
	const intl = useIntl();

	const {
		data: projectsData,
		isFetching: projectsIsFetching,
		isLoading: projectsIsLoading,
		isSuccess: projectsIsSuccess,
	} = useGetProjectDataQuery(linkId);

	if (projectsIsFetching || projectsIsLoading) return <ProjectSkeleton />;

	if (!projectsIsSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.projects.error' })}</Error>;

	if (param) return <Project linkId={linkId} projectId={param} />;

	if (!projectsData?.projects || projectsData?.projects?.length === 0) return <NoData />;

	return projectsData?.projects?.map(projectItem => <ProjectItem key={projectItem?.projectId} projectItem={projectItem} />);
};

export default Projects;
