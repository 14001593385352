import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/system';

const StyledListIconItem = styled(ListItemIcon, {
	shouldForwardProp: prop => prop !== 'conditionalStyled',
})(({ theme, conditionalStyled }) => ({
	color: theme.palette.primary.white,
	minWidth: conditionalStyled ? 'unset' : '2.5rem',
}));

const MenuListItemIcon = ({ conditionalStyled, children }) => {
	return <StyledListIconItem conditionalStyled={conditionalStyled}>{children}</StyledListIconItem>;
};

export default MenuListItemIcon;
