import { isEmpty, isNil } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Autocomplete, AutocompleteProps, Typography } from '@mui/material';

import { Company } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';

import { useSearchCompaniesQuery } from 'containers/CompanySearch/companySearchV2Api';

type CustomOption = string | Company;

type Props = { searchTermParam?: string } & Omit<AutocompleteProps<CustomOption, false, false, false>, 'options'>;

const CompanySearchAutocomplete = React.forwardRef<any, Props>(({ searchTermParam = '', ...otherProps }, ref) => {
	const companyId = getCompanyId();

	const [selectedValue, setSelectedValue] = React.useState<CustomOption | null>(null);

	const [inputValue, setInputValue] = React.useState(searchTermParam);
	const [searchTerm, setSearchTerm] = React.useState('');
	const [initialDataLoaded, setInitialDataLoaded] = React.useState(searchTermParam === '' ? true : false);
	const [showMessage, setShowMessage] = React.useState(false);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setSearchTerm(inputValue);
		}, 500);
		return () => clearTimeout(timeout);
	}, [inputValue]);

	const {
		data: companies,
		isFetching: isSearchCompaniesFetching,
		isLoading: isSearchCompaniesLoading,
	} = useSearchCompaniesQuery({ companyId, searchTerm }, { skip: isNil(companyId) || isEmpty(searchTerm) });

	React.useEffect(() => {
		if (initialDataLoaded) return;

		if (!initialDataLoaded && searchTermParam === searchTerm && companies !== undefined) {
			if (companies?.[0]) {
				setSelectedValue(companies?.[0]);
				// @ts-ignore override event passing from useEffect
				if (otherProps?.onChange) otherProps.onChange(null, companies?.[0], 'selectOption');
			} else {
				setShowMessage(true);
			}

			setInitialDataLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTermParam, companies, searchTerm]);

	return (
		<>
			{!initialDataLoaded && (isSearchCompaniesFetching || isSearchCompaniesLoading) && (
				<Typography variant='lightText'>
					<FormattedMessage id='common.autocomplete.loading-initial-data' />
				</Typography>
			)}
			<Autocomplete
				{...otherProps}
				disabled={!initialDataLoaded}
				loading={isSearchCompaniesFetching || isSearchCompaniesLoading}
				loadingText={<FormattedMessage id='common.autocomplete.loading' />}
				ref={ref}
				options={companies ?? []}
				onInputChange={(_, nextInputValue) => {
					setInputValue(nextInputValue);
				}}
				isOptionEqualToValue={(option, value) => {
					const optionId = typeof option === 'string' ? option : option?.companyId;
					const valueId = typeof value === 'string' ? value : value?.companyId;

					return optionId === valueId;
				}}
				getOptionLabel={option => {
					return typeof option === 'string' ? option : `${option?.name} - ${option?.businessId}` ?? 'n/a';
				}}
				onChange={(_, value) => {
					setSelectedValue(value);

					if (otherProps?.onChange) otherProps.onChange(_, value, 'selectOption');
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				autoHighlight
				value={selectedValue}
			/>
			{showMessage && companies?.length === 0 && (
				<Typography variant='lightText'>
					<FormattedMessage id='common.autocomplete.default-not-found' />
				</Typography>
			)}
		</>
	);
});

export default CompanySearchAutocomplete;
