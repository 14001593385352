import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import BoxContainer from 'components/Containers/BoxContainer';
import { FormControlledCurrencyInput, FormControlledInput, FormControlledSwitchItem } from 'components/Form';
import FormControlledDatepicker from 'components/Form/FormControlledDatepicker';
import PanelTitle from 'components/Titles/PanelTitle';
import Subtitle from 'components/Titles/Subtitle';
import { setIsMountedProjectFormExtended } from 'containers/App/appSlice';
import EconomicalTable from './EconomicalTable';

import { Stack } from '@mui/material';
import WysiwygEditorPanel from 'components/WysiwygEditorPanel';
import CollaborationLauncher from 'containers/Collaboration/CollaborationLauncher';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { InstrumentTemplateField, Project } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import { financialReportKeys, formatEconomicalDataArray } from 'utils/formatters/formatEconomicalData';
import { createValidationSchema } from './dynamicValidationTemplateGenerator';
import { controlTypes, fetchData, fetchFromState, resolve } from './utils';

type SubSectionProps = {
	item: InstrumentTemplateField;
	name: string;
	errors: any;
	project: Project;
	control: any;
	state: any;
	setInstruction: any;
	setValue: any;
	chatId: string;
	onInputBlur: any;
};

const SubSection: React.FC<SubSectionProps> = ({
	item,
	name,
	errors,
	project,
	control,
	state,
	setInstruction,
	setValue,
	chatId,
	onInputBlur = () => {},
}) => {
	return (
		<>
			<Divider sx={{ mt: '1rem' }} />
			<PanelTitle id={item?.id} className='panelTitle' variant='h3' sx={{ mb: '1rem', mt: '1rem' }}>
				{item.title}
			</PanelTitle>
			<Grid container columnSpacing={2} rowSpacing={0}>
				{item.fields?.map(field => {
					const scale = field.scale ? field.scale : { xs: 12 };

					return (
						<Grid item key={`${name}.${field.id}`} id={`${name.replaceAll(',', '-')}-${field.id}`} {...scale}>
							{getControl(
								field,
								`${name}.${field.id}`,
								errors,
								project,
								control,
								state,
								setInstruction,
								setValue,
								chatId,
								onInputBlur,
								true
							)}
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

const getControl = (
	field: InstrumentTemplateField,
	name: string,
	errors: any,
	project: Project,
	control: any,
	state: any,
	setInstruction: any,
	setValue: any,
	chatId: string,
	onInputBlur = () => {},
	showTitle?: boolean
) => {
	const err = resolve(name, errors);
	let defaultValue = fetchData(name, project) ?? fetchFromState(field, state, project);

	if (!defaultValue) defaultValue = field.ai ? field.promptTemplate : '';
	if (!defaultValue) defaultValue = field.template;

	switch (field.type) {
		case controlTypes.textbox:
			return (
				// @ts-ignore
				<WysiwygEditorPanel
					key={name}
					name={name}
					defaultValue={defaultValue}
					title={showTitle ? field.title : null}
					templates={[]}
					control={control}
					error={err}
					setValue={setValue}
					placeholder={field.placeholder}
					instructions={field.description}
					charactersLimit={field?.params?.max?.[0]}
					aiEnabled={field.ai}
					aiPromptTemplate={field.promptTemplate}
					chatId={chatId}
					onInputBlur={onInputBlur}
				/>
			);
		case controlTypes.text:
			return (
				<FormControlledInput
					key={name}
					disabled={false}
					defaultValue={defaultValue}
					name={name}
					control={control}
					error={err}
					label={field.title}
					setInstruction={setInstruction}
					instructions={field.description}
					placeholder={field.placeholder}
					sx={{ pt: '1rem' }}
					onInputBlur={onInputBlur}
				/>
			);
		case controlTypes.currency:
			return (
				<FormControlledCurrencyInput
					key={name}
					id={name}
					disabled={false}
					defaultValue={defaultValue}
					name={name}
					control={control}
					error={err}
					label={field.title}
					setInstruction={setInstruction}
					instructions={field.description}
					placeholder={field.placeholder}
					sx={{ pt: '1rem' }}
					onInputBlur={onInputBlur}
				/>
			);
		case controlTypes.date:
			return (
				<FormControlledDatepicker
					key={name}
					id={name}
					disabled={false}
					defaultValue={defaultValue}
					name={name}
					control={control}
					error={err}
					label={field.title}
					setInstruction={setInstruction} // @ts-ignore
					instructions={field.description}
					placeholder={field.placeholder}
					sx={{ pt: '1rem' }}
					onInputBlur={onInputBlur}
				/>
			);
		case controlTypes.boolean:
			return (
				// @ts-ignore
				<FormControlledSwitchItem
					key={name}
					id={name}
					disabled={false}
					defaultValue={!!defaultValue}
					name={name}
					control={control}
					error={err}
					label={field.title}
					setInstruction={setInstruction}
					instructions={field.description}
					placeholder={field.placeholder}
					sx={{ pt: '1rem' }}
					checkedVal={true}
					notCheckedVal={false}
					checkedLabel={field.checkedLabel}
					notCheckedLabel={field.notCheckedLabel}
					onInputBlur={onInputBlur}
				/>
			);
		case controlTypes.section:
			return (
				<SubSection {...{ item: field, name, errors, project, control, state, setInstruction, setValue, chatId, onInputBlur }} />
			);
		case controlTypes.table: {
			const data = defaultValue;

			return (
				<EconomicalTable
					field={field} // @ts-ignore
					data={formatEconomicalDataArray(data)}
					renderKeys={financialReportKeys}
					control={control}
					errors={errors}
					onInputBlur={onInputBlur}
				/>
			);
		}
		default:
			return;
	}
};

type ProjectFormExtendedTemplateProps = {
	project: Project;
	control: any;
	register: any;
	unregister: any;
	errors: any;
	setDynamicValidationTemplate: any;
	setInstruction: any;
	template: any;
	setValue: any;
	getValues: any;
	chatId: string;
	updateLocalBackup: any;
};

const ProjectFormExtendedTemplate: React.FC<ProjectFormExtendedTemplateProps> = ({
	project,
	control,
	register,
	unregister,
	errors,
	setDynamicValidationTemplate,
	setInstruction,
	template,
	setValue,
	getValues,
	chatId,
	updateLocalBackup,
}) => {
	const companyId = getCompanyId();
	const dispatch = useDispatch();
	const intl = useIntl();
	const state = useSelector(state => state);

	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const schema = createValidationSchema(template?.fields);
	setDynamicValidationTemplate(schema);

	useEffect(() => {
		register('files');

		return () => {
			unregister('files');
		};
	}, [register, unregister]);

	useEffect(() => {
		dispatch(setIsMountedProjectFormExtended(true));
		return () => {
			dispatch(setIsMountedProjectFormExtended(false));
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!template || !project || !project.dynamic || isCompanyLoading || isCompanyFetching) return null;

	let newState = Object.assign({}, state, { company: { company: company } });

	return (
		<>
			<Stack direction='row' alignContent='space-between' alignItems='center' sx={{ mt: '2rem' }}>
				<Subtitle id='projectFormApplication' className='panelTitle' mt={0} mb={0} padding={0} sx={{ flexGrow: 1 }}>
					{intl.formatMessage({ id: 'project.form.projectApplication' })}
				</Subtitle>
				<CollaborationLauncher
					linkTitle={intl.formatMessage({ id: 'project.form.projectApplication' })}
					link={`projects/${project.projectId}/plan/projectFormApplication`}
					sx={{ m: 1 }}
				/>
			</Stack>

			{template.fields.map((item: InstrumentTemplateField) => {
				if (!item.fields) {
					const scale = item.scale ? item.scale : { xs: 12 };

					return (
						<BoxContainer key={item.title} sx={{ mt: 2 }}>
							<Stack direction='row' alignContent='space-between' alignItems='center' sx={{ mb: '1rem' }}>
								<PanelTitle id={item?.id} className='panelTitle' variant='h3' sx={{ flexGrow: 1 }}>
									{item.title}
								</PanelTitle>
								<CollaborationLauncher
									linkTitle={item.title}
									link={`projects/${project.projectId}/plan/${item?.id}`}
									sx={{}}
								/>
							</Stack>
							<Grid item id={`dynamic-${item?.id}`} {...scale}>
								{getControl(
									item,
									`dynamic.${item?.id}`,
									errors,
									project,
									control,
									newState,
									setInstruction,
									setValue,
									chatId,
									() => updateLocalBackup(getValues()),
									false
								)}
							</Grid>
						</BoxContainer>
					);
				}

				return (
					<BoxContainer key={item.title} sx={{ mt: 2 }}>
						<Stack direction='row' alignContent='space-between' alignItems='center' sx={{ mb: '1rem' }}>
							<PanelTitle id={item?.id} className='panelTitle' variant='h3' sx={{ flexGrow: 1 }}>
								{item.title}
							</PanelTitle>
							<CollaborationLauncher linkTitle={item.title} link={`projects/${project.projectId}/plan/${item?.id}`} sx={{}} />
						</Stack>
						<Grid container columnSpacing={2} rowSpacing={0}>
							{item.fields?.map((field: InstrumentTemplateField) => {
								const scale = field.scale ? field.scale : { xs: 12 };

								return (
									<Grid item key={field.id} id={`dynamic-${item?.id}-${field.id}`} {...scale}>
										{getControl(
											field,
											`dynamic.${item?.id}.${field.id}`,
											errors,
											project,
											control,
											newState,
											setInstruction,
											setValue,
											chatId,
											() => updateLocalBackup(getValues()),
											true
										)}
									</Grid>
								);
							})}
						</Grid>
					</BoxContainer>
				);
			})}
		</>
	);
};

export const MemorizedProjectFormExtendedTemplate = memo(ProjectFormExtendedTemplate);
export default MemorizedProjectFormExtendedTemplate;
