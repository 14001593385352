import { filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { collaborationDrawerWidth } from 'config/drawerWidth';
import { matchPath } from 'utils/helpers/pathMatch';

import CollaborationDrawer from 'containers/Collaboration/CollaborationDrawer/CollaborationDrawer';
import { toggleCollaborationDrawer } from 'containers/Collaboration/slices';
import { useCallback } from 'react';
import ToggleCollaborationDrawerButton from '../ToggleCollaborationDrawerButton';

const CollaborationDrawerWrapper = ({ isCollaborationDrawerAvailable, instructionBox, location, hasChat }) => {
	const dispatch = useDispatch();

	const collaboration = useSelector(state => state.collaboration);

	const isDrawerOpen = collaboration?.isDrawerOpen;

	const toggleDrawer = useCallback(() => dispatch(toggleCollaborationDrawer()), [dispatch]);

	const unreadConversations = filter(collaboration?.conversations?.data, ({ isRead }) => !isRead);
	const unreadConversationCount = unreadConversations?.length;

	const isDashboard = matchPath('/', location, true);

	if (!isCollaborationDrawerAvailable) return null;

	return (
		<>
			<CollaborationDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} width={collaborationDrawerWidth} />
			<ToggleCollaborationDrawerButton
				toggleDrawer={toggleDrawer}
				unreadConversationCount={isDashboard ? unreadConversationCount : 0}
				instructionBox={instructionBox}
				hasChat={hasChat}
			/>
		</>
	);
};

export default CollaborationDrawerWrapper;
