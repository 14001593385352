import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    tdColumn: {
        padding: 0,
        // backgroundColor: theme.palette.primary.white,
        // '&:nth-child(1), &:nth-child(3n+4)': {
        //     borderLeft: '1px solid rgba(72, 46, 81, 0.02)',
        // },
        // '&:last-child': {
        //     borderRight: '1px solid rgba(72, 46, 81, 0.02)',
        // },
    },
    tr: {
        height: 0,
        backgroundColor: theme.palette.primary.secondary,
    },
}));

// have to have to get the colspan to work correctly
const TColumns = ({ numberOfMonth, columnWidth }) => {
    const classes = useStyles();

    let items = [];
    for (let i = 0; i < numberOfMonth; i++) {
        items.push(
            <td key={i} className={classes.tdColumn} width={columnWidth}>
                {' '}
            </td>
        );
    }
    return <tr className={classes.tr}>{items}</tr>;
};

export default TColumns;
