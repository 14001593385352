import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ContentContainer from 'components/Containers/ContentContainer';
import Spinner from 'components/Spinner/Spinner';
import MainTitle from 'components/Titles/MainTitle';
import AttachmentPanel from './AttachmentPanel';
import BusinessPlanForm from './BusinessPlanForm/index';
import InfoItemPanel from './InfoItemPanel';
import TeamMemberPanel from './TeamMemberPanel';

import { Stack } from '@mui/material';
import { getInstrumentTags } from 'containers/Instruments/slices';
import { getCompanyPath } from 'utils/auth/getCompanyPath';
import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';
import { removeAttachment, updateMain } from './slices';
import { combineEconomyData, parseEconomicalData } from './utils';

const BusinessPlanContainer = ({ company, instruments, businessPlan, readonly, sx = {}, economicalData = [], hideTitle = false }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname, hash } = useLocation();
	const formRef = useRef();

	useScrollIntoViewWithOffset('general', hash);

	// main + tags
	const main = businessPlan?.main;
	const businessId = main?.businessId;

	// templates
	const templates = businessPlan?.templates;
	const templatesLoading = templates?.loading;
	const templatesData = templates?.data;
	// attachments
	const attachments = businessPlan?.attachments;

	const mainAndEconomicalData = useMemo(() => {
		return {
			...main,
			economicalData: company?.economicalData
				? combineEconomyData(company?.economicalData, economicalData)
				: parseEconomicalData(economicalData),
		};
	}, [main, company, economicalData]);

	// portal
	const container = useRef(null);

	const [loading, setLoading] = useState(false);
	const [isDirty, setIsDirty] = useState(false);

	const [tags, setTags] = useState(null);

	useEffect(() => {
		if (tags) return;

		const fetchTags = async () => {
			const ret = await dispatch(getInstrumentTags(instruments));

			setTags(ret);
		};

		fetchTags().catch(console.error);
	}, [dispatch, tags]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = async data => {
		setLoading(true);
		const success = await dispatch(updateMain(data));
		setLoading(false);

		if (success) {
			toast.success(intl.formatMessage({ id: 'business.plan.info.saving.successed' }));
		} else {
			toast.error(intl.formatMessage({ id: 'business.plan.info.saving.failed' }));
		}
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const businessPlanPrintPath = getCompanyPath('/businessplan/print', pathname, businessId);

	const removeFile = async id => {
		const success = await dispatch(removeAttachment(id));

		if (success) {
			toast.success(intl.formatMessage({ id: 'attachments.remove.success' }));
		} else {
			toast.error(intl.formatMessage({ id: 'attachments.remove.failed' }));
		}
	};

	return (
		<ContentContainer id='general' sx={sx}>
			<Stack direction='row' alignItems='center' alignContent='space-between' sx={{ width: '100%' }}>
				{!hideTitle && (
					<Box sx={{ flexGrow: 1 }}>
						<MainTitle margin={0} sx={{ flexGrow: 1 }}>
							{company?.name}
						</MainTitle>
					</Box>
				)}
				<Box sx={{ display: readonly ? 'none' : 'block' }}>
					<Button
						variant='outlined'
						borderColor='grey600'
						onClick={() => history.push(businessPlanPrintPath)}
						sx={{ mr: '1rem' }}
					>
						{intl.formatMessage({ id: 'business.plan.preview.button' })}
					</Button>
					<Button color='success' disabled={loading || !isDirty} onClick={submitForm}>
						{intl.formatMessage({ id: 'business.plan.publish.button' })}
						{loading && <ButtonSpinner />}
					</Button>
				</Box>
			</Stack>
			<Box ref={container} sx={{ mt: 2 }} />
			<Grid container spacing={2} sx={{ mt: readonly ? '.5rem' : 0 }}>
				<Grid item xs={12} md={8}>
					{loading ? (
						<Spinner size={16} />
					) : (
						<BusinessPlanForm
							data={mainAndEconomicalData}
							tags={tags}
							readonly={readonly}
							instruments={instruments}
							mainLoading={false}
							templatesLoading={templatesLoading}
							businessId={businessId}
							ref={formRef}
							templates={templatesData}
							onSubmit={onSubmit}
							setIsDirty={setIsDirty}
							container={container.current}
						/>
					)}
				</Grid>
				<Grid item xs={12} md={4}>
					<InfoItemPanel data={businessPlan.infoItems} readonly={readonly} />
					<TeamMemberPanel data={businessPlan.teamMembers} readonly={readonly} />
					{!readonly && (
						<AttachmentPanel
							{...{ attachmentsLoaded: true, attachments, attachmentsLoading: false, removeFile }}
							readonly={readonly}
						/>
					)}
				</Grid>
			</Grid>
		</ContentContainer>
	);
};

export default BusinessPlanContainer;
