import { useHistory } from 'react-router-dom';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { useTheme } from '@mui/styles';

export const SubMenuList = ({ children }: any) => {
	const theme = useTheme();

	return (
		<List
			sx={{
				paddingLeft: '3.25rem',
				'&>div:first-child li': {
					paddingTop: 0,
				},
				// @ts-ignore
				[theme.breakpoints.between('lg', 'xl')]: {
					maxWidth: '22.5rem',
				},
				// @ts-ignore
				[theme.breakpoints.up('xl')]: {
					maxWidth: '22.5rem',
				},
			}}
		>
			{children}
		</List>
	);
};

export const SubMenuItem = ({
	navigateUrl,
	title,
	active,
	clickAction,
}: {
	navigateUrl: string;
	title: string;
	active: boolean | null;
	clickAction?: any;
}) => {
	const history = useHistory();
	const theme = useTheme();

	return (
		<ListItem
			sx={{
				p: 0,
				'& hover': {
					cursor: 'pointer',
				},
				m: 0,
			}}
		>
			<ListItemIcon
				sx={{
					minWidth: '2rem',
					justifyContent: 'center',
				}}
			>
				<FiberManualRecordIcon
					sx={{
						width: 10,
						height: 10,
						// @ts-ignore
						color: active ? theme.palette.primary.beige : theme.palette.primary.secondary,
					}}
				/>
			</ListItemIcon>
			<ListItemText
				sx={{
					'& span': {
						fontSize: '1rem',
						fontWeight: 600,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						// @ts-ignore
						color: active ? theme.palette.primary.beige : theme.palette.primary.secondary,
					},
					'&:hover': {
						cursor: 'pointer',
					},
				}}
				onClick={e => {
					e.preventDefault();
					history.push(navigateUrl);

					if (clickAction) clickAction();
				}}
			>
				{title}
			</ListItemText>
		</ListItem>
	);
};
