import { createSlice } from '@reduxjs/toolkit';

import { getStartDateArr } from 'containers/FinancialPlan/parserTimeline';
import categories from 'utils/mock/categories.json';

import { profileApi } from './profileApi';
import { setInstruments } from 'containers/Instruments/slices';
import { umlautsDecode } from 'utils/formatters/umlautsDecode';

export const name = 'profile';

export const initialState = {
	loading: false,
	error: false,
	profile: {},
	profileLink: '',
};

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetProfileData() {
			return { ...initialState };
		},
		loadProfile(state) {
			state.loading = true;
			state.error = false;
		},
		loadProfileError(state, action) {
			state.error = action.payload.error;
			state.loading = false;
			state.profile = initialState.profile;
		},
		loadProfileSuccess(state, action) {
			state.loading = false;
			state.error = false;
			state.profile = action.payload;
		},
	},
});

export const { reducer } = slice;

export const { resetProfileData } = slice.actions;

export const getProfile = link => async dispatch => {
	try {
		dispatch(slice.actions.loadProfile());

		const profileData = await profileApi.getProfile(link);

		const data = { ...profileData, categories };

		let timeline = data?.timeline;
		let projects = data?.projects;

		const decodedProjects = projects?.map(project => {
			return { ...project, dynamic: null, projectSummary: umlautsDecode(project.projectSummary) };
		});

		const mutatedTimeline = getStartDateArr(decodedProjects, timeline); // mutates the response object

		dispatch(slice.actions.loadProfileSuccess({ ...data, ...mutatedTimeline, mock: null }));
		dispatch(setInstruments(data?.instruments));
	} catch (error) {
		dispatch(slice.actions.loadProfileError(error));
	}
};
