import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import Button from 'components/Button/Button';
import ConfirmButton from 'components/Button/ConfirmButton';
import { FormControlledInput, FormControlledSelect, FormError, FromControlledHiddenField } from 'components/Form';
import NestedInstruments from '../NestedInstruments';

const useStyles = makeStyles(theme => ({
	box: {
		padding: theme.spacing(2),
		overflow: 'hidden',
	},
	middleBox: {
		padding: theme.spacing(1),
	},
	title: {
		fontWeight: 600,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	instrument: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		paddingTop: theme.spacing(1),
		'&:first-child': {
			paddingTop: 0,
		},
	},
}));

const CategoryCard = ({
	index,
	id,
	categoryId,
	category,
	level,
	options,
	control,
	errors,
	instruments,
	cursor,
	setValue,
	clearErrors,
	remove,
}) => {
	const intl = useIntl();
	const classes = useStyles();
	const nestedRef = useRef();

	const categoryErrorMessage = errors?.categories && errors?.categories[index]?.category?.message;
	const levelErrorMessage = errors?.categories && errors?.categories[index]?.level?.message;
	const instrumentsErrorMessage = errors?.categories && errors?.categories[index]?.instruments?.message;

	const { attributes, listeners, setNodeRef, transform, transition /*, isDragging */ } = useSortable({ id });

	const deleteCategory = index => remove(index);

	return (
		<Card
			ref={setNodeRef}
			sx={{
				borderRadius: 0,
				boxShadow: 'none',
				transform: CSS.Translate.toString(transform),
				transition,
				height: '100%',
				// zIndex: isDragging ? 100 : 'auto', // TODO: check --> does not work
			}}
		>
			{/* Hidden Field */}
			<FromControlledHiddenField name={`categories.${index}.categoryId`} defaultValue={categoryId ?? ''} control={control} />
			<Stack sx={{ height: '100%' }} direction='column' alignItems='stretch' justifyContent='space-between'>
				<Box>
					<Box className={classes.box}>
						<Stack direction='row' spacing={1} alignItems='flex-end'>
							<IconButton
								{...listeners}
								{...attributes}
								sx={{
									px: 0,
									mb: categoryErrorMessage ? 2.5 : 0,
									'&:hover': {
										cursor,
									},
								}}
							>
								<DragIndicatorIcon />
							</IconButton>
							<FormControlledInput
								variant='standard'
								name={`categories.${index}.category`}
								defaultValue={category ?? ''}
								control={control}
								placeholder={intl.formatMessage({ id: 'category.name' })}
								error={categoryErrorMessage}
							/>
							<Box sx={{ width: '30%' }}>
								<FormControlledSelect
									variant='standard'
									name={`categories.${index}.level`}
									defaultValue={level ?? ''}
									control={control}
									options={options}
									disabledLable={true}
									error={levelErrorMessage}
								/>
							</Box>
						</Stack>
					</Box>
					<Divider />
					<Box className={classes.middleBox}>
						{instrumentsErrorMessage && (
							<Box sx={{ p: 2 }}>
								<FormError error={instrumentsErrorMessage} />
							</Box>
						)}
						<NestedInstruments
							ref={nestedRef}
							nestIndex={index}
							setValue={setValue}
							{...{ category, control, errors, instruments, clearErrors }}
						/>
					</Box>
				</Box>
				<Box>
					<Divider />
					<Box display='flex' sx={{ justifyContent: 'space-between', p: 2 }}>
						<Button variant='text' color='primary' startIcon={<AddIcon />} onClick={() => nestedRef.current?.appendNested()}>
							<Typography>{intl.formatMessage({ id: 'category.add.instrument' })}</Typography>
						</Button>
						<ConfirmButton
							variant='outlined'
							color='error'
							confirmAction={() => deleteCategory(index)}
							confirmText={intl.formatMessage({ id: 'category.remove.confirm' })}
							cancelText={intl.formatMessage({ id: 'category.remove.cancel' })}
							confirmBodyText={intl.formatMessage({ id: 'category.remove.body' })}
							buttonText={intl.formatMessage({ id: 'common.delete' })}
						/>
					</Box>
				</Box>
			</Stack>
		</Card>
	);
};

export default CategoryCard;
