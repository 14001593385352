import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import LinkIcon from '@mui/icons-material/Link';

import AddButton from 'components/Button/AddButton';
import AdminContentContainer from 'components/Containers/AdminContentContainer';
import LinkPopover from 'components/LinkPopover';
import { getCompanyId } from 'utils/auth/company';

const CreateLinkButton = ({ project }) => {
	const intl = useIntl();
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClose = () => setAnchorEl(null);
	const copiedToClipboard = () => toast.success(intl.formatMessage({ id: 'customerlist.popover.copy.url.success' }));
	const open = Boolean(anchorEl);

	const companyId = getCompanyId();

	return (
		<AdminContentContainer>
			<AddButton
				color='primary'
				icon={<LinkIcon variant='contained' size='large' />}
				onClick={e => {
					e.stopPropagation();
					setAnchorEl(e.currentTarget);
				}}
			>
				{intl.formatMessage({ id: 'project.summary.link' })}
			</AddButton>
			<LinkPopover
				open={open}
				anchorEl={anchorEl}
				handleClose={handleClose}
				link={`/company/${companyId}/projects/${project.projectId}/summary`}
				copiedToClipboard={copiedToClipboard}
			/>
		</AdminContentContainer>
	);
};

export default CreateLinkButton;
