import { createApi } from '@reduxjs/toolkit/query/react';
import { compact, concat, isNil, map } from 'lodash';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { CompanyRequests, RfpRequest, RfpRequestItem } from 'types/dataTypes';

type FetchRfpRequests = {
	companyId?: string | null;
};

type FetchRfpRequest = {
	companyId?: string | null;
	requestId?: string | null;
	published?: boolean | null;
};

type FetchPublishedRfpRequest = {
	companyId?: string | null;
	requestId?: string | null;
};

type UpsertRfpRequest = {
	companyId?: string | null;
	rfpRequest?: Partial<RfpRequest> | null;
	published?: boolean;
};

type PublishRfpRequest = {
	companyId?: string | null;
	requestId?: string | null;
};

type DeleteRfpRequest = {
	companyId?: string | null;
	requestId?: string | null;
	published?: boolean;
};

type FetchRfpRequestItems = {
	companyId?: string | null;
	requestId?: string | null;
};

type UpsertRfpRequestItem = {
	companyId?: string | null;
	requestId?: string | null;
	rfpRequestItem?: Partial<RfpRequestItem> | null;
};

type RemoveRfpRequestItem = {
	companyId?: string | null;
	requestId?: string | null;
	requestItemId?: string | null;
};

enum Tags {
	RfpRequest = 'rfpRequest',
	RfpRequestItem = 'rfpRequestItem',
	PublishedRfpRequest = 'publishedRfpRequest',
}

const baseUrl = 'rfp';

export const rfpRequestApi = createApi({
	reducerPath: 'rfpRequest',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.RfpRequest, Tags.RfpRequestItem, Tags.PublishedRfpRequest],
	endpoints: builder => ({
		fetchRfpRequests: builder.query<CompanyRequests, FetchRfpRequests>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/request/${companyId}`,
				};
			},
			providesTags: statuses => {
				const rfpRequests = compact(concat([], statuses?.drafts, statuses?.published));
				return concat(
					[{ type: Tags.RfpRequest, id: 'LIST' }],
					map(rfpRequests, rfpRequest => ({
						type: Tags.RfpRequest,
						id: rfpRequest?.rfpRequestId || '',
					}))
				);
			},
		}),
		fetchRfpRequest: builder.query<RfpRequest, FetchRfpRequest>({
			query: ({ companyId, requestId, published }) => {
				if (isNil(companyId) || isNil(requestId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: published ? `/published/request/${companyId}/${requestId}` : `/request/${companyId}/${requestId}`,
				};
			},
			providesTags: rfpRequest => {
				return [{ type: Tags.RfpRequest, id: rfpRequest?.rfpRequestId || '' }];
			},
		}),
		fetchPublishedRfpRequests: builder.query<RfpRequest[], FetchRfpRequests>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/published/request/${companyId}`,
				};
			},
			providesTags: rfpRequests => {
				return concat(
					[{ type: Tags.RfpRequest, id: 'LIST' }],
					map(rfpRequests, rfpRequest => ({
						type: Tags.RfpRequest,
						id: rfpRequest?.rfpRequestId || '',
					}))
				);
			},
		}),
		upsertRfpRequest: builder.mutation<RfpRequest, UpsertRfpRequest>({
			query: ({ companyId, rfpRequest, published }) => {
				if (isNil(companyId) || isNil(rfpRequest) || isNil(published)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/request/${companyId}`,
					params: { published },
					data: rfpRequest,
				};
			},
			invalidatesTags: rfpRequest => {
				return [
					{ type: Tags.RfpRequest, id: 'LIST' },
					{ type: Tags.RfpRequest, id: rfpRequest?.rfpRequestId || '' },
				];
			},
		}),
		publishRfpRequest: builder.mutation<RfpRequest, PublishRfpRequest>({
			query: ({ companyId, requestId }) => {
				if (isNil(companyId) || isNil(requestId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'PUT',
					url: `/request/${companyId}/${requestId}/publish`,
				};
			},
			invalidatesTags: rfpRequest => {
				return [
					{ type: Tags.RfpRequest, id: 'LIST' },
					{ type: Tags.RfpRequest, id: rfpRequest?.rfpRequestId || '' },
				];
			},
		}),
		deleteRfpRequest: builder.mutation<RfpRequest, DeleteRfpRequest>({
			query: ({ companyId, requestId, published }) => {
				if (isNil(companyId) || isNil(requestId) || isNil(published)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/request/${companyId}/${requestId}`,
					params: { published },
				};
			},
			invalidatesTags: rfpRequest => {
				return [
					{ type: Tags.RfpRequest, id: 'LIST' },
					{ type: Tags.RfpRequest, id: rfpRequest?.rfpRequestId || '' },
				];
			},
		}),
		fetchRfpRequestItems: builder.query<RfpRequestItem[], FetchRfpRequestItems>({
			query: ({ companyId, requestId }) => {
				if (isNil(companyId) || isNil(requestId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/request/${companyId}/${requestId}/item`,
				};
			},
			providesTags: rfpRequestItems => {
				return concat(
					[{ type: Tags.RfpRequestItem, id: 'LIST' }],
					map(rfpRequestItems, rfpRequestItem => ({
						type: Tags.RfpRequestItem,
						id: rfpRequestItem?.rfpRequestItemId || '',
					}))
				);
			},
		}),
		upsertRfpRequestItem: builder.mutation<RfpRequestItem, UpsertRfpRequestItem>({
			query: ({ companyId, requestId, rfpRequestItem }) => {
				if (isNil(companyId) || isNil(requestId) || isNil(rfpRequestItem)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/request/${companyId}/${requestId}/item`,
					data: rfpRequestItem,
				};
			},
			async onQueryStarted(params, { dispatch, queryFulfilled }) {
				try {
					const { data: itemData } = await queryFulfilled;

					dispatch(
						rfpRequestApi.util.updateQueryData(
							'fetchRfpRequest',
							{ companyId: params.companyId, requestId: params.requestId, published: true },
							draft => {
								if (!draft?.rfpRequestItems) draft.rfpRequestItems = [];

								const index = draft?.rfpRequestItems.findIndex(item => item.rfpRequestItemId === itemData.rfpRequestItemId);

								if (index < 0) {
									draft?.rfpRequestItems?.push(itemData);
								} else {
									draft.rfpRequestItems[index] = itemData;
								}
							}
						)
					);
				} catch (ex) {
					console.error(ex);
				}
			},
			invalidatesTags: rfpRequestItem => {
				return [
					{ type: Tags.RfpRequestItem, id: 'LIST' },
					{ type: Tags.RfpRequestItem, id: rfpRequestItem?.rfpRequestItemId || '' },
				];
			},
		}),
		removeRfpRequestItem: builder.mutation<RfpRequestItem, RemoveRfpRequestItem>({
			query: ({ companyId, requestId, requestItemId }) => {
				if (isNil(companyId) || isNil(requestId) || isNil(requestItemId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/request/${companyId}/${requestId}/item/${requestItemId}`,
				};
			},
			// async onQueryStarted(params, { dispatch, queryFulfilled }) {
			// 	try {
			// 		await queryFulfilled;

			// 		dispatch(
			// 			rfpRequestApi.util.updateQueryData(
			// 				'fetchRfpRequest',
			// 				{ companyId: params.companyId, requestId: params.requestId, published: true },
			// 				draft => {
			// 					if (!draft?.rfpRequestItems) draft.rfpRequestItems = [];

			// 					draft.rfpRequestItems = draft?.rfpRequestItems.filter(
			// 						item => item.rfpRequestItemId !== params.requestItemId
			// 					);
			// 				}
			// 			)
			// 		);
			// 	} catch (ex) {
			// 	}
			// },
			invalidatesTags: (result, error, { requestItemId, requestId }) => {
				return [
					{ type: Tags.RfpRequestItem, id: 'LIST' },
					{ type: Tags.RfpRequestItem, id: requestItemId || '' },
				];
			},
		}),
	}),
});

export const {
	useFetchRfpRequestsQuery,
	useFetchRfpRequestQuery,
	useLazyFetchRfpRequestQuery,
	useFetchPublishedRfpRequestsQuery,
	useUpsertRfpRequestMutation,
	usePublishRfpRequestMutation,
	useFetchRfpRequestItemsQuery,
	useUpsertRfpRequestItemMutation,
	useRemoveRfpRequestItemMutation,
	useDeleteRfpRequestMutation,
} = rfpRequestApi;
