import { useIntl } from 'react-intl';

import StraightIcon from '@mui/icons-material/Straight';
import DataGrid from 'components/DataGrid';
import { generateUid } from 'utils/helpers/uid';

type MRRLicenseDataGridProps = {
	rowData: any;
	loading: boolean;
	rowClick?: any;
	extraColumns?: any;
	showValue?: boolean;
	showValueChange?: boolean;
	autoHeight?: boolean;
	heigth: number | string;
	rowsPerPage?: number;
};

const MRRLicenseDataGrid = ({
	rowData,
	loading,
	rowClick,
	extraColumns,
	showValue = true,
	showValueChange = true,
	autoHeight = true,
	heigth,
	rowsPerPage = 20,
}: MRRLicenseDataGridProps) => {
	const intl = useIntl();

	const hasClientValue = rowData[0]?.clientValue !== undefined ? true : false;

	const columns = [
		{
			field: 'companyName',
			headerName: intl.formatMessage({ id: 'mrr.companyName' }),
			width: 300,
			renderCell: (params: any) => (
				<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.companyName}</span>
			),
		},
		{
			field: 'companyId',
			headerName: intl.formatMessage({ id: 'mrr.companyId' }),
			width: 100,
			renderCell: (params: any) => params.row.companyId,
		},
		{
			field: 'licensePackage',
			headerName: intl.formatMessage({ id: 'mrr.package' }),
			width: 150,
			valueGetter: ({ value }: any) => {
				return value && intl.formatMessage({ id: `license.form.licensePackage.${value}` });
			},
		},
		{
			field: 'clientValue',
			type: 'number',
			hide: !hasClientValue,
			renderHeader: () => <>{intl.formatMessage({ id: 'mrr.clientValue' })}</>,
			width: 100,
			renderCell: (params: any) => Number(parseInt(params.row.clientValue)),
		},
		{
			field: 'value',
			type: 'number',
			hide: hasClientValue || !showValue,
			renderHeader: () => <>{intl.formatMessage({ id: 'mrr.value' })}</>,
			width: 70,
			renderCell: (params: any) => Number(parseInt(params.row.value ?? params.row.contractMonthlyValue)),
		},
		{
			field: 'valueChange',
			type: 'number',
			hide: hasClientValue || !showValueChange,
			renderHeader: () => <>{intl.formatMessage({ id: 'mrr.valueChange' })}</>,
			width: 100,
			renderCell: (params: any) => {
				const valueChange = Number(parseInt(params.row.valueChange));
				return (
					<>
						{valueChange}
						{showValue && (
							<StraightIcon
								sx={{ transform: valueChange < 0 ? 'rotate(180deg) scaleX(-1)' : 'none' }}
								color={valueChange > 0 ? 'success' : 'error'}
							/>
						)}
					</>
				);
			},
		},
		{
			field: 'startDate',
			headerName: intl.formatMessage({ id: 'mrr.startDate' }),
			width: 120,
			type: 'dateTime',
			//renderCell: params => moment(params.row.startDate).format('DD.MM.YYYY')
		},
		{
			field: 'endDate',
			headerName: intl.formatMessage({ id: 'mrr.endDate' }),
			width: 120,
			type: 'dateTime',
			//renderCell: params => params.row.endDate ? moment(params.row.endDate).format('DD.MM.YYYY') : null
		},
		{
			field: 'type',
			headerName: intl.formatMessage({ id: 'mrr.type' }),
			width: 150,
			renderCell: (params: any) =>
				intl.formatMessage({ id: `license.form.licenseType.${params.row.type ?? params.row.licenseType}` }),
		},
		{
			field: 'package',
			headerName: intl.formatMessage({ id: 'mrr.package' }),
			width: 150,
			valueGetter: (params: any) => {
				return !params.row.package ? null : intl.formatMessage({ id: `license.form.licensePackage.${params.row.package}` });
			},
		},
	];

	if (extraColumns && extraColumns.length > 0) {
		for (let index = 0; index < extraColumns.length; index++) {
			columns.push(extraColumns[index]);
		}
	}

	return (
		<>
			<DataGrid
				sx={{
					'&:hover': { cursor: 'pointer' },
				}}
				autoHeight={autoHeight}
				heigth={heigth} // @ts-ignore
				rowHeight={37}
				getRowId={generateUid}
				idField='rowKey'
				loading={loading}
				data={rowData}
				columns={columns}
				onRowClick={rowClick}
				initialState={{ pinnedColumns: { left: ['companyName'] } }}
				paginationMode='client'
				rowsPerPage={rowsPerPage}
			/>
		</>
	);
};

export default MRRLicenseDataGrid;
