import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
// import * as yup from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';

// import ConversationList from './ConversationList';
import ConversationWindow from './ConversationWindow';
import EditableField from './EditableField';
import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';

import { setAsRead, changeActive, setToggleView, sendMessage } from 'containers/Chat/chatSlice';

const height = '60vh';

const useStyles = makeStyles(theme => ({
	root: {
		zIndex: 10001, // is greater than ChatIcon.js = 10000
	},
	paper: {
		maxWidth: '70%',
		position: 'fixed',
		left: '30%',
		bottom: '1rem',
		right: '1rem',
		borderRadius: '5px',
		[theme.breakpoints.down('md')]: {
			maxWidth: '70%',
			left: '30%',
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			left: '0',
			right: '0',
			bottom: '0',
		},
	},
	container: {
		height: height,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.primary.white,
		overflow: 'hidden',
		[theme.breakpoints.down('sm')]: {
			height: '90%',
		},
	},
	gridContainer: {
		minHeight: '100%',
	},
	content: {
		flex: '1 0 auto',
	},
	footer: {
		flexShrink: 0,
	},
	conversationListContainer: {
		maxWidth: '25rem',
		backgroundColor: theme.palette.primary.secondary,
	},
	conversationListHeader: {
		position: 'sticky',
		top: 0,
		paddingTop: theme.spacing(3),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(3),
		paddingLeft: theme.spacing(2),
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.secondary.main,
		backgroundColor: theme.palette.primary.secondary,
	},
	conversationListHeaderBox: {
		flexGrow: 1,
	},
	conversationListHeaderText: {
		fontSize: '1.8rem',
		fontWeight: 700,
	},
	name: {
		fontSize: '0.85rem',
		fontWeight: 600,
	},
	date: {
		float: 'right',
	},
	conversationListFooter: {
		position: 'sticky',
		bottom: 0,
		backgroundColor: theme.palette.primary.secondary,
	},
	newConversationButton: {
		margin: theme.spacing(5),
		color: theme.palette.success.main,
		borderColor: theme.palette.success.main,
	},
	conversationWindowContainer: {
		backgroundColor: theme.palette.primary.white,
	},
	conversationWindowHeader: {
		position: 'sticky',
		top: 0,
		display: 'flex',
		padding: theme.spacing(1),
		boxShadow: `0 0 8px 0 ${theme.palette.secondary.main}`,
		backgroundColor: theme.palette.primary.white,
	},
	conversationWindowFooter: {
		position: 'sticky',
		bottom: 0,
		borderTop: '1px solid',
		borderColor: theme.palette.secondary.main,
		padding: theme.spacing(2),
		backgroundColor: theme.palette.primary.white,
	},
	textField: {
		flexGrow: 1,
	},
	input: {
		color: theme.palette.primary.main,
		fontStyle: 'italic',
		fontSize: '1rem',
		fontWeight: 400,
		// '&::placeholder': {},
	},
	wrapper: {
		position: 'relative',
	},
	mainLoading: {
		color: theme.palette.success.main,
	},
	conversationTitleLoading: {
		position: 'absolute',
		top: theme.spacing(2.5),
		right: theme.spacing(2.5),
	},
	scrollableConversationListBox: {
		overflow: 'auto',
		height: `calc(${height} - 224px)`, // header = 108px, footer = 116px
		[theme.breakpoints.down('sm')]: {
			height: 'calc(100vh - 224px)',
		},
	},
	scrollableConversationWindowBox: {
		overflow: 'auto',
		height: `calc(${height} - 171.59px)`, // header = 68.59px, footer = 103px
		[theme.breakpoints.down('sm')]: {
			height: 'calc(100vh - 171.59px)',
		},
	},
	closeButton: {
		padding: theme.spacing(2),
	},
}));

const Chat = ({ open, close, chat, question, pathname }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const intl = useIntl();
	const md = useMediaQuery(theme.breakpoints.up('md'));

	const loading = chat?.loading;
	const messageLoading = chat?.messageLoading;
	const active = chat?.active ?? 0;
	const toggleView = chat?.toggleView;
	const conversationTitleLoading = chat?.conversationTitleLoading;
	const conversationTitle = chat?.chat?.conversations?.filter(({ id }) => id === active)[0]?.title;
	// const defaultConversationTitle = intl.formatMessage({ id: 'chat.conversation.title' });
	const messages = chat?.chat?.conversations?.filter(({ id }) => id === active)[0]?.messages;
	const messagesLength = messages?.length;
	const pattern = /^\s*\n/gm;

	// const schema = yup.object().shape({
	//     message: yup.string().trim().required()
	// });

	const {
		control,
		handleSubmit,
		reset,
		watch,
		formState: { /* errors, */ isDirty },
	} = useForm({
		defaultValues: {
			message: '',
		},
		// resolver: yupResolver(schema),
	});

	const watchMessage = watch('message', false);

	const messagesEndRef = useCallback(
		node => {
			if (node !== null) {
				node.scrollIntoView({ behavior: 'auto' });
			}
		},
		[messagesLength]
	); // eslint-disable-line react-hooks/exhaustive-deps

	const setCaretPosition = (ctrl, pos) => {
		// Modern browsers
		if (ctrl.setSelectionRange) {
			ctrl.focus();
			ctrl.setSelectionRange(pos, pos);
		} // IE8 and below
		else if (ctrl.createTextRange) {
			const range = ctrl.createTextRange();
			range.collapse(true);
			range.moveEnd('character', pos);
			range.moveStart('character', pos);
			range.select();
		}
	};

	const onRefChange = useCallback(
		node => {
			if (node !== null && question) {
				const textarea = node.querySelector('textarea');
				textarea.value += '\n';
				setCaretPosition(textarea, textarea.value.length);
			}
		},
		[question]
	);

	const setActive = (active, newMessagesAmount) => {
		if (newMessagesAmount) dispatch(setAsRead(active));
		dispatch(changeActive(active));
		if (!md && !toggleView) dispatch(setToggleView(true));
	};

	const [isSubmitted, setIsSubmitted] = useState(false);

	useEffect(() => {
		setActive(0);
	}, []); // eslint-disable-line

	const onSubmit = ({ message }) => {
		if (!message || pattern.test(message)) return;

		dispatch(sendMessage(message));
		setIsSubmitted(true);

		reset();
	};

	if (pathname === '/agreement') return null;

	if (chat === undefined || chat === null) {
		return (
			<React.Fragment>
				<div>Problem rendering component</div>
			</React.Fragment>
		);
	}

	return (
		<Drawer
			anchor='bottom'
			open={open}
			onClose={close}
			transitionDuration={{ enter: 500, exit: 500 }}
			classes={{
				root: classes.root,
				paper: classes.paper,
			}}
		>
			<div className={classes.container}>
				{loading ? (
					<Box textAlign='center'>
						<CircularProgress size={48} className={classes.mainLoading} />
					</Box>
				) : (
					<Grid container>
						{/* {(md || (!md && !toggleView)) && (
                            <Grid item xs={12} md={4} className={classes.conversationListContainer}>
                                <Grid container direction='column' className={classes.container}>
                                    <Grid item>
                                        <Grid container alignItems='center' className={classes.conversationListHeader}>
                                            <Grid item>
                                                <IconButton aria-label='close' onClick={close} className={classes.closeButton}>
                                                    <CloseIcon color='primary' />
                                                </IconButton>
                                            </Grid>
                                            <Grid item className={classes.conversationListHeaderBox}>
                                                <Box textAlign='center'>
                                                    <Typography
                                                        component='p'
                                                        color='primary'
                                                        align='center'
                                                        className={classes.conversationListHeaderText}
                                                    >
                                                        {intl.formatMessage({ id: 'chat.financial.message' })}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box className={classes.scrollableConversationListBox}>
                                            <ConversationList
                                                conversations={chat.chat?.conversations}
                                                active={active}
                                                setActive={setActive}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item className={[classes.footer, classes.conversationListFooter]}>
                                        <Box textAlign='center' className={classes.wrapper}>
                                            <Button
                                                variant='outlined'
                                                onClick={createNewConversation}
                                                className={classes.newConversationButton}
                                            >
                                                {intl.formatMessage({ id: 'chat.button.new.conversation' })}
                                            </Button>
                                            {conversationLoading && <CircularProgress size={24} className={classes.buttonLoading} />}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )} */}
						{/* {(md || (!md && toggleView)) && ( */}
						<Grid item xs={12} className={classes.conversationWindowContainer}>
							{active === null ? (
								<Box className={classes.root}>Something here</Box>
							) : (
								<Grid container direction='column' className={classes.gridContainer}>
									<Grid item>
										<Box className={classes.conversationWindowHeader}>
											{/* {!md && (
                                                    <IconButton aria-label='close' onClick={() => dispatch(setToggleView(false))}>
                                                        <ArrowBackIosIcon color='primary' />
                                                    </IconButton>
                                                )} */}
											<EditableField active={active} conversationTitle={conversationTitle} />
											{conversationTitleLoading && (
												<CircularProgress size={32} className={classes.conversationTitleLoading} />
											)}
											<IconButton aria-label='close' onClick={close} className={classes.closeButton}>
												<CloseIcon color='primary' />
											</IconButton>
										</Box>
										<Box className={classes.scrollableConversationWindowBox}>
											<ConversationWindow chat={chat.chat} active={active} />
											<span ref={messagesEndRef} />
										</Box>
									</Grid>
									<Grid item className={`${classes.footer} ${classes.conversationWindowFooter}`}>
										<form noValidate onSubmit={handleSubmit(data => onSubmit(data))}>
											<Grid container alignItems='center' spacing={1}>
												<Grid item className={classes.textField}>
													<Controller
														name='message'
														control={control}
														defaultValue={
															!isSubmitted && question && Object.keys(question).length !== 0 && question.title
																? intl.formatMessage(
																		{ id: 'chat.input.question' },
																		{ question: question.text }
																  )
																: !isSubmitted &&
																  question &&
																  Object.keys(question).length !== 0 &&
																  !question.title
																? intl.formatMessage(
																		{ id: 'chat.input.question.no.title' },
																		{ question: question.text }
																  )
																: ''
														}
														render={({ field: { value, onChange } }) => {
															return (
																<TextField
																	ref={onRefChange}
																	value={value}
																	onChange={onChange}
																	name='message'
																	variant='standard'
																	placeholder={intl.formatMessage({ id: 'chat.input.placeholder' })}
																	autoFocus // eslint-disable-line
																	fullWidth
																	multiline
																	minRows={3}
																	maxRows={5}
																	InputProps={{
																		disableUnderline: true,
																		classes: { input: classes.input },
																	}}
																/>
															);
														}}
													/>
												</Grid>
												<Grid item className={classes.wrapper}>
													<Button
														type='submit'
														variant='contained'
														color='success'
														disabled={!isDirty || !watchMessage.trim()}
													>
														{intl.formatMessage({ id: 'common.submit' })}
														{messageLoading && <ButtonSpinner />}
													</Button>
												</Grid>
											</Grid>
										</form>
									</Grid>
								</Grid>
							)}
						</Grid>
						{/* )} */}
					</Grid>
				)}
			</div>
		</Drawer>
	);
};

export default Chat;
