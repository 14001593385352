import { truncate } from 'lodash';
import { useState } from 'react';

import { Tooltip, Typography } from '@mui/material';

type CollapseProps = {
	text: string | null | undefined;
	length?: number;
	expanded?: boolean;
	print?: boolean;
};

const TextCollapse = ({ text, length = 500, expanded, print }: CollapseProps) => {
	const [showMore, setShowMore] = useState(false);

	if (!text) return null;

	const truncatedText = truncate(text, {
		length: length,
		separator: ' ',
	});

	if (!text) return null;

	if (text.length > length && expanded !== undefined) {
		return (
			<>
				<Typography component='span' variant='body2'>
					{expanded ? text : truncatedText}
				</Typography>
			</>
		);
	}

	if (text.length > length) {
		return (
			<Tooltip title={showMore ? 'Supista' : 'Laajenna'}>
				<Typography
					component='span'
					variant='body2'
					sx={{
						'&:hover': {
							cursor: 'pointer',
						},
					}}
					onClick={() => {
						if (expanded) return;

						setShowMore(!showMore);
					}}
				>
					{showMore || expanded || print ? text : truncatedText}
				</Typography>
			</Tooltip>
		);
	}

	return (
		<>
			<Typography component='span' variant='body2' onClick={() => setShowMore(!showMore)}>
				{showMore ? text : truncatedText}
			</Typography>
		</>
	);
};

export default TextCollapse;
