export const companyValues = ['tolCode', 'postalCode', 'countyId'];

export const basicValues = [
	'companyType',
	'age',
	'personnelSize',
	'owner',
	'countrySide',
	'employeeRegister',
	'advanceCollectionRegister',
	'vatRegister',
];

export const businessValues = ['internationalEta', 'ipr', 'team', 'concept', 'marketTrend', 'growtAbility'];

export const financialValues = [
	'equityPercentageLevel',
	'balance',
	'cashFlow',
	'turnover',
	'equityAmount',
	'deminisHistory',
	'ratingAlpha',
	'responsiblesCreditRate',
	'vacantAssurances',
	'taxDepth',
	'paysDivident',
];

export const basicSelectItemValues = ['companyType', 'age', 'personnelSize', 'owner', 'countrySide'];

export const basicSwitchGroupValues = ['employeeRegister', 'advanceCollectionRegister', 'vatRegister'];

export const allValues = [...companyValues, ...basicValues, ...businessValues, ...financialValues];
