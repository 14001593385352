import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import MasterPage from 'containers/MasterPage';

import { getCompany, getCompanyId, hasCompanyAcceptedAgreement, isAdvancedToken } from 'utils/auth/company';
import { getAccessToken, isContributorToken } from 'utils/auth/token';

import { licenseExpired, notFound } from 'config/routeConst';
import { loadAdminData, loadData } from 'containers/App/appSlice';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';

const AppRoute = ({
	path,
	pathname,
	exact,
	masterPage,
	hideFooter,
	pageTheme,
	title,
	isPrivate,
	isRequireCompanyData,
	isContributorRoute,
	isAdminRoute,
	component: Component,
	analytics,
	contract,
	...rest
}) => {
	const pathCompanyId = Object.keys(rest.computedMatch.params).length ? rest.computedMatch.params.companyId?.trim() : false;

	const prevLocation = useLocation();
	const accessToken = getAccessToken();
	const localCompany = getCompany();
	const companyId = getCompanyId();
	const selectedCompany = getCompany();
	const isContributor = isContributorToken();
	const isAdvanced = isAdvancedToken();
	const hasAcceptedAgreement = hasCompanyAcceptedAgreement();
	const dispatch = useDispatch();

	const app = useSelector(state => state.app);
	const isDataLoaded = app?.isDataLoaded;
	const isAdminDataLoaded = app?.isAdminDataLoaded;

	const isLicenseExpired = localCompany?.License === 'expired';

	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const isAuthenticated = accessToken !== null;

	const status = company?.status;

	useEffect(() => {
		if (!isRequireCompanyData) return;
		if (isDataLoaded === undefined) return;

		if (!isDataLoaded || (isDataLoaded && !isCompanyLoading && pathCompanyId && status !== 200 && companyId !== pathCompanyId)) {
			dispatch(loadData());
		}
	}, [isCompanyLoading, companyId, status, isRequireCompanyData, isDataLoaded, company, pathCompanyId, dispatch]);

	useEffect(() => {
		if (isAdminRoute && !isAdminDataLoaded && isContributor) {
			dispatch(loadAdminData());
		}
	}, [dispatch, isAdminDataLoaded, isAdminRoute]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		analytics.addPageView(`${pathname}${window.location.search}`);
		analytics.addCompanyPageView(pathname, isContributor, isAdvanced, localCompany);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const isSearchPage = pathname === '/search' || pathname === '/admin/search';

	return (
		<Route
			{...rest}
			path={path}
			exact={exact}
			render={props => {
				if (isContributorRoute && !isContributor) {
					return <Redirect to={{ pathname: notFound }} />;
				}
				if (isPrivate && !isAuthenticated) {
					return <Redirect to={{ pathname: '/login', state: prevLocation?.pathname === '/login' ? null : prevLocation }} />;
				} else if (
					isAuthenticated &&
					(!hasAcceptedAgreement || contract?.error?.data === 'ContractNotFoundException') &&
					pathname !== `/company/${companyId}/agreement` &&
					pathname !== `/company/${companyId}/pricing` &&
					pathname !== '/agreement' &&
					pathname !== '/termsconditions' &&
					pathname !== '/pricing' &&
					!isSearchPage &&
					pathname !== '/profile' &&
					pathname !== '/user'
				) {
					return (
						<Redirect
							to={{
								pathname: `/company/${companyId}/agreement`,
								state: prevLocation?.pathname === '/login' ? null : prevLocation,
							}}
						/>
					);
				} else if (isLicenseExpired && pathname !== licenseExpired && pathname !== '/user') {
					return <Redirect to={{ pathname: licenseExpired }} />;
				} else if ((isSearchPage && !isContributor) || (pathname === '/customers' && !isAdvanced) || path === '*') {
					return <Redirect to={{ pathname: notFound }} />;
				} else if (masterPage) {
					if (pathname === '/' && isContributor) {
						return <Redirect to={{ pathname: '/admin/search' }} />; // if admin user redirect to search if root is opened
					}
					if (pathname === '/' && isAdvanced) {
						return <Redirect to={{ pathname: '/customers' }} />; // if admin user redirect to search if root is opened
					}
					if (pathname === '/') {
						return <Redirect to={{ pathname: `/company/${companyId ?? selectedCompany?.CompanyId}` }} />; // if admin user redirect to search if root is opened
					}

					return (
						<MasterPage
							component={Component}
							hideFooter={hideFooter}
							pageTheme={pageTheme}
							isLicenseExpired={isLicenseExpired}
							title={title}
							{...props}
						/>
					);
				} else {
					return <Component pathname={pathname} {...props} />;
				}
			}}
		/>
	);
};

export default AppRoute;
