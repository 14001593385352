import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Grid, TableCell, TableFooter, TableHead, useTheme } from '@mui/material';
import FormatCurrency from 'components/Formatters/FormatCurrency';
import HtmlParser from 'components/HtmlParser';
import { sumBy } from 'lodash';
import { ProjectCost, ProjectTask } from 'types/dataTypes';
import { decimalFormatter } from 'utils/formatters/numberFormatters';
import ProjectPrintTableCell from '../ProjectPrintTableItems/ProjectPrintTableCell';
import ProjectPrintTableCellTitle from '../ProjectPrintTableItems/ProjectPrintTableCellTitle';

const EmployeesTable = ({ taskCosts }: { taskCosts: ProjectCost[] }) => {
	const intl = useIntl();

	const employees = taskCosts?.filter(item => item.workEstimate);

	if (!employees || employees.length === 0) return null;

	return (
		<>
			<Grid item xs={12} xl={2}>
				<ProjectPrintTableCellTitle rowHeader={true} usetablecell={false}>
					{intl.formatMessage({ id: 'project.task.table.employees' })}
				</ProjectPrintTableCellTitle>
			</Grid>
			<Grid item xs={12} xl={10}>
				<ProjectPrintTableCell usetablecell={false}>
					<Table
						size='small'
						sx={{
							'@media print': {
								width: '100% !important',
								tableLayout: 'fixed',
							},
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell sx={{ width: '70%', '@media print': { width: '320pt' } }}>
									{intl.formatMessage({ id: 'project.task.table.costs.employees' })}
								</TableCell>
								<TableCell sx={{ textAlign: 'right' }}>
									{intl.formatMessage({ id: 'project.task.table.costs.workEstimate' })}
								</TableCell>
								<TableCell sx={{ textAlign: 'right' }}>
									{intl.formatMessage({ id: 'project.task.table.costs.salary' })}
								</TableCell>
								<TableCell sx={{ textAlign: 'right' }}>
									{intl.formatMessage({ id: 'project.task.table.costs.expense' })}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{employees.map(({ id, employeeName, workEstimate, amountApplied, employeeRole, name }, index, array) => (
								<TableRow key={id}>
									<TableCell sx={{ width: '70%', '@media print': { width: '320pt' } }}>
										<Typography key={id} sx={{ mb: index !== array.length - 1 ? 1 : 0 }}>
											{[employeeName, employeeRole, name].filter(n => n).join(', ')}
										</Typography>
									</TableCell>
									<TableCell sx={{ textAlign: 'right' }}>{`${decimalFormatter.format(
										workEstimate ?? 0
									)} htkk`}</TableCell>
									<TableCell sx={{ textAlign: 'right' }}>
										<FormatCurrency value={amountApplied} />
									</TableCell>
									<TableCell sx={{ textAlign: 'right' }}>
										<FormatCurrency value={amountApplied * (workEstimate ?? 0)} />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell sx={{ fontSize: '.9rem', color: '#303F5A', fontWeight: 600 }}>
									{intl.formatMessage({ id: 'project.task.table.costs.total' })}
								</TableCell>
								<TableCell sx={{ fontSize: '.9rem', color: '#303F5A', textAlign: 'right', fontWeight: 600 }}>
									{`${decimalFormatter.format(sumBy(employees, item => item.workEstimate ?? 0))} htkk`}
								</TableCell>
								<TableCell></TableCell>
								<TableCell sx={{ fontSize: '.9rem', color: '#303F5A', textAlign: 'right', fontWeight: 600 }}>
									<FormatCurrency value={sumBy(employees, item => item.amountApplied * (item.workEstimate ?? 0))} />
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</ProjectPrintTableCell>
			</Grid>
		</>
	);
};

const CostsTableRows = ({ task }: { task: ProjectTask }) => {
	const intl = useIntl();

	const subcontracting = task.costs?.filter(item => item.category !== 'salary');

	return (
		<>
			{subcontracting && subcontracting?.length > 0 && (
				<>
					<Grid item xs={12} xl={2}>
						<ProjectPrintTableCellTitle rowHeader={true} usetablecell={false}>
							{intl.formatMessage({ id: 'project.task.table.subcontracting' })}
						</ProjectPrintTableCellTitle>
					</Grid>
					<Grid item xs={12} xl={10}>
						<ProjectPrintTableCell usetablecell={false}>
							<Table
								size='small'
								sx={{
									'@media print': {
										width: '100% !important',
										tableLayout: 'fixed',
									},
								}}
							>
								<TableHead>
									<TableRow>
										<TableCell width='200' sx={{ width: '30%' }}>
											{intl.formatMessage({ id: 'project.task.table.costs.supplier' })}
										</TableCell>
										<TableCell width='480' sx={{ width: '60%' }}>
											{intl.formatMessage({ id: 'project.resourse.purchasing.services.table.work' })}
										</TableCell>
										<TableCell sx={{ textAlign: 'right' }}>
											{intl.formatMessage({ id: 'project.task.table.costs.expense' })}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{subcontracting.map(({ id, name, amountApplied, supplierName }, index, array) => (
										<TableRow key={index}>
											<TableCell>{supplierName}</TableCell>
											<TableCell>
												<Typography key={id} sx={{ mb: index !== array.length - 1 ? 1 : 0 }}>
													{name}
												</Typography>
											</TableCell>
											<TableCell sx={{ textAlign: 'right' }}>
												<FormatCurrency value={amountApplied} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TableCell sx={{ fontSize: '.9rem', color: '#303F5A', fontWeight: 600 }}>
											{intl.formatMessage({ id: 'project.task.table.costs.total' })}
										</TableCell>
										<TableCell></TableCell>
										<TableCell sx={{ fontSize: '.9rem', color: '#303F5A', textAlign: 'right', fontWeight: 600 }}>
											<FormatCurrency value={sumBy(subcontracting, item => item.amountApplied)} />
										</TableCell>
									</TableRow>
								</TableFooter>
							</Table>
						</ProjectPrintTableCell>
					</Grid>
				</>
			)}
		</>
	);
};

const TaskTable = ({ index, task }: { index: number; task: ProjectTask }) => {
	const intl = useIntl();
	const theme = useTheme();
	const hasDates = task.startDate || task.endDate;

	return (
		<TableContainer
			sx={{
				mt: index > 0 ? 4 : 0,
				background: '#fff',
				'@media print': {
					pageBreakInside: 'avoid',
					overflow: 'hidden',
					pageBreakBefore: 'avoid',
				},
			}}
		>
			{/* <Table sx={{ width: '100%' }}>
				<TableBody>
					<TableRow>
						<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true}>
							{intl.formatMessage({ id: 'project.task.table' })} {index + 1}.
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCellTitle tableColumnHeader={true}>{task?.projectTaskName}</ProjectPrintTableCellTitle>
					</TableRow>
					<TableRow>
						<ProjectPrintTableCellTitle rowHeader={true}>
							{intl.formatMessage({ id: 'project.task.table.desription' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCell>{HtmlParser(task?.projectTaskDescription)}</ProjectPrintTableCell>
					</TableRow>
					<EmployeesTable taskCosts={task.costs ?? []} />

					<CostsTableRows {...{ task }} />

					<TableRow>
						<ProjectPrintTableCellTitle rowHeader={true}>
							{intl.formatMessage({ id: 'project.task.table.projectTaskGoals' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCell>{HtmlParser(task?.projectTaskGoals)}</ProjectPrintTableCell>
					</TableRow>
				</TableBody>
			</Table> */}

			<Grid container>
				<Grid item xs={12} sm={2} sx={{ borderBottom: `4px solid ${theme.palette.secondary}` }}>
					<ProjectPrintTableCellTitle tableColumnHeader={true} usetablecell={false}>
						{intl.formatMessage({ id: 'project.task.table' })} {index + 1}.
					</ProjectPrintTableCellTitle>
				</Grid>
				<Grid item xs={12} sm={10}>
					<ProjectPrintTableCellTitle tableColumnHeader={true} usetablecell={false}>
						{task?.projectTaskName}
					</ProjectPrintTableCellTitle>
				</Grid>

				{hasDates && (
					<>
						<Grid item xs={12} xl={2}>
							<ProjectPrintTableCellTitle rowHeader={true} usetablecell={false}>
								{intl.formatMessage({ id: 'project.task.table.schedule' })}
							</ProjectPrintTableCellTitle>
						</Grid>
						<Grid item xs={12} xl={10}>
							<ProjectPrintTableCell usetablecell={false}>
								<Typography component='span' sx={{ mr: '1rem' }}>
									{task?.startDate ? intl.formatDate(task?.startDate) : null}
									{' - '}
									{task?.endDate ? intl.formatDate(task?.endDate) : null}
								</Typography>
							</ProjectPrintTableCell>
						</Grid>
					</>
				)}

				<Grid item xs={12} xl={2}>
					<ProjectPrintTableCellTitle rowHeader={true} usetablecell={false}>
						{intl.formatMessage({ id: 'project.task.table.desription' })}
					</ProjectPrintTableCellTitle>
				</Grid>
				<Grid item xs={12} xl={10}>
					<ProjectPrintTableCell usetablecell={false}>{HtmlParser(task?.projectTaskDescription)}</ProjectPrintTableCell>
				</Grid>

				<EmployeesTable taskCosts={task.costs ?? []} />
				<CostsTableRows {...{ task }} />

				<Grid item xs={12} xl={2}>
					<ProjectPrintTableCellTitle rowHeader={true} usetablecell={false}>
						{intl.formatMessage({ id: 'project.task.table.projectTaskGoals' })}
					</ProjectPrintTableCellTitle>
				</Grid>
				<Grid item xs={12} xl={10}>
					<ProjectPrintTableCell usetablecell={false}>{HtmlParser(task?.projectTaskGoals)}</ProjectPrintTableCell>
				</Grid>
			</Grid>
		</TableContainer>
	);
};

export default TaskTable;
