import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { AnalyticsAction } from 'types/dataTypes';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

type CreateInstrumentAnalytics = {
	instrumentId: string | null;
	companyId: string | null;
	action: AnalyticsAction | null;
	url: string | null;
	customData: any;
};

const baseUrl = 'analytics';

/**
 * Create analytics request when instrument is called
 */
export const analyticsApi = createApi({
	reducerPath: 'analytics',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [],
	endpoints: builder => ({
		createInstrumentAnalytics: builder.mutation<void, CreateInstrumentAnalytics>({
			query: ({ instrumentId, companyId, action, url, customData }) => {
				if (isNil(instrumentId) || isNil(action)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: '/instrument',
					data: {
						instrumentId,
						companyId,
						action,
						url,
						customData,
					},
				};
			},
		}),
	}),
});

export const { useCreateInstrumentAnalyticsMutation } = analyticsApi;
