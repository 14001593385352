import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Skeleton, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import { default as Box, default as Grid } from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import LeftRightContainer from 'components/Containers/LeftRightContainer';
import CustomMenu from 'components/CustomMenu';
import CustomTooltip from 'components/CustomTooltip';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import Spinner from 'components/Spinner/Spinner';
import PanelSubtitle from 'components/Titles/PanelSubtitle';
import PanelTitle from 'components/Titles/PanelTitle';
import CollaborationLauncher from 'containers/Collaboration/CollaborationLauncher';
import Tags from '../../Tags';

import AdminContentContainer from 'components/Containers/AdminContentContainer';
import { isEmpty } from 'lodash';
import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';
import AiChatWrapper from '../../../AI/AiChatWrapper/AiChatWrapper';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.primary.white,
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	iconButton: {
		padding: theme.spacing(0.5),
	},
	template: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

const AISkeleton = () => {
	return (
		<Stack direction='column' sx={{ p: '1rem' }}>
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 25 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 25 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
		</Stack>
	);
};

const EditorPanel = ({
	mainLoading,
	templatesLoading,
	value,
	title,
	templates,
	control,
	error,
	setValue,
	tagName,
	tagDefaultValue,
	placeholder,
	children,
	tagOptions = [],
	maxLength,
	readonly,
	onInputBlur = () => { },
}) => {
	const classes = useStyles();
	const intl = useIntl();
	const { hash } = useLocation();

	useScrollIntoViewWithOffset(title, hash);

	const [anchorEl, setAnchorEl] = useState(null);
	const [currentData, setCurrentData] = useState(value);
	const [aiLoading, setAILoading] = useState(false);
	const [aiDisabled, setAiDisabled] = useState(false);

	const open = Boolean(anchorEl);
	const handleClick = event => setAnchorEl(event.currentTarget);
	const handleClose = content => {
		setAnchorEl(null);
		if (typeof content === 'string') setValue(title, content, { shouldValidate: true });
	};

	const [isEditor, setIsEditor] = useState(false);

	const setAiReturnValue = returnValue => {
		if (returnValue) setValue(title, returnValue, { shouldValidate: true });

		setAILoading(false);
		setAiDisabled(returnValue ? true : false);
	};

	const setOnLoadingAIFetch = loading => {
		setAILoading(loading);
	};

	return (
		<Box id={title} className={classes.root}>
			<LeftRightContainer
				left={<PanelTitle>{intl.formatMessage({ id: `businessplan.${title}` })}</PanelTitle>}
				right={
					<Grid container alignItems='center'>
						{templatesLoading ? (
							<Grid item>
								<Spinner size={12} />
							</Grid>
						) : (
							templates?.length > 0 &&
							isEditor && (
								<Grid item>
									<IconButton onClick={handleClick} className={classes.iconButton}>
										<ArticleOutlinedIcon />
									</IconButton>
									<CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
										{templates?.map(({ title, content }, index) => (
											<MenuItem key={index} onClick={() => handleClose(content)}>
												<Typography className={classes.template}>{title}</Typography>
											</MenuItem>
										))}
									</CustomMenu>
								</Grid>
							)
						)}
						<Grid item>
							<CollaborationLauncher disabled={readonly} link={`businessplan/${title}`} />
						</Grid>
						<Grid item>
							<CustomTooltip
								title={intl.formatMessage({ id: `businessplan.${title}.instructions` })}
								placement='top'
								enterTouchDelay={0}
								leaveTouchDelay={2000}
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 200 }}
							>
								<IconButton disableRipple className={classes.iconButton}>
									<HelpOutlineIcon />
								</IconButton>
							</CustomTooltip>
							{!readonly && (
								<AdminContentContainer>
									<AiChatWrapper
										title={intl.formatMessage({ id: `businessplan.${title}` })}
										chatId={`businessplan.${title}`}
										disabled={currentData ? false : true}
										onLoading={setOnLoadingAIFetch}
										onChanged={setAiReturnValue}
									/>
								</AdminContentContainer>
							)}
						</Grid>
					</Grid>
				}
			/>
			<PanelSubtitle className={classes.subtitle}>{intl.formatMessage({ id: `businessplan.${title}.description` })}</PanelSubtitle>
			{!isEmpty(tagName) && (
				<Box sx={{ mt: 1 }}>
					<Tags
						readonly={readonly}
						loading={mainLoading}
						name={tagName}
						defaultValue={tagDefaultValue}
						control={control}
						setValue={setValue}
						options={tagOptions}
						onInputBlur={onInputBlur}
					/>
				</Box>
			)}
			{mainLoading || aiLoading ? (
				<AISkeleton />
			) : (
				<FormControlledWysiwyg
					inline={true}
					toolbar={true}
					name={title}
					control={control}
					defaultValue={value ?? ''}
					error={error}
					renderTextIfNotHovered={true}
					isEditor={isEditor}
					setIsEditor={setIsEditor}
					placeholder={placeholder}
					charactersLimit={maxLength}
					disabled={readonly || aiLoading}
					onChanged={setValue}
					onBlurAction={value => {
						setCurrentData(value);
						onInputBlur();
					}}
				/>
			)}
			{children}
		</Box>
	);
};

export default EditorPanel;
