import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    section: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        padding: theme.spacing(4),
        background: 'white',
        marginBottom: '1rem'
    },
    content: {
        '& h2, h3, h4, p, label, span, li, td': {
            color: theme.palette.primary.main,
        },
    },
}));

const MainSection = ({ id, hidden, children }) => {
    const classes = useStyles();

    if (hidden) return null;

    return (
        <div id={id} className={`${classes.section} ${id === 'timeline' && 'timelineBox'} sectionPrint`}>
            <div className={`${classes.content} contentPrint`}>{children}</div>
        </div>
    );
};

export default MainSection;
