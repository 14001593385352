import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	IconButton,
	Slider,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import FormatCurrency from 'components/Formatters/FormatCurrency';
import PanelTitle from 'components/Titles/PanelTitle';
import { sumBy } from 'lodash';
import moment from 'moment';
import MRRLicenseDataGrid from '../../MRRLicenseDataGrid/MRRLicenseDataGrid';

const StyledSlider = styled(Slider, {
	shouldForwardProp: (prop: string) => prop !== 'backgroundcolor',
	overridesResolver: (props: any, styles: any) => [styles.root, props.backgroundcolor === 'primary' && styles.primary], // @ts-ignore
})(({ backgroundcolor = 'green' }) => ({
	color: backgroundcolor,
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: '50% 50% 50% 0',
		transformOrigin: 'bottom left',
		transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
}));

const NetValueLevels = [
	{ level: 'extreme', min: 5000, color: '#00E39C' },
	{ level: 'good', min: 3500, color: '#00E39C' },
	{ level: 'ok', min: 2000, color: '#FFFF00' },
	{ level: 'weak', min: 500, color: '#FEB019' },
	{ level: 'critical', min: -1, color: '#fb3b3b' },
];
function valuetext(value: number, level: string) {
	return `${value} ${level}`;
}

const MoodSlider = ({ value, color, level }: { value: number; color: string; level: string }) => {
	const intl = useIntl();

	const marks = [
		{
			label: <Box sx={{ fontSize: '.7rem' }}>{intl.formatMessage({ id: `mrr.changetable.level.${NetValueLevels[4].level}` })}</Box>,
			value: -3000,
		},
		{
			label: <Box sx={{ fontSize: '.7rem', fontWeight: 600 }}>0</Box>,
			value: 0,
		},
		{
			label: <Box sx={{ fontSize: '.7rem' }}>2000</Box>,
			value: 2000,
		},
		{
			label: <Box sx={{ fontSize: '.7rem' }}>4000</Box>,
			value: 4000,
		},
		{
			label: <Box sx={{ fontSize: '.7rem' }}>6000</Box>,
			value: 6000,
		},
	];

	return (
		<Box sx={{ width: 300 }}>
			<StyledSlider
				disabled
				min={-3000}
				step={1}
				max={6000}
				aria-label='mrr-level-slider'
				defaultValue={value}
				getAriaValueText={() => valuetext(value, level)}
				color='secondary'
				marks={marks} // @ts-ignore
				backgroundcolor={color}
				sx={{ color: `${color} !important` }}
			/>
		</Box>
	);
};

export const LicenseValueTable = ({ data = [], title, sx = {} }: { data: any[]; title: string; sx?: any }) => {
	return (
		<Table stickyHeader size='small' sx={sx}>
			<TableHead>
				<TableRow>
					<TableCell>&nbsp;</TableCell>
					<TableCell width={120}>Arvo</TableCell>
					<TableCell width={120}>Määrä</TableCell>
					<TableCell width={120}>Keskiarvo</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell sx={{ fontWeight: '500' }}>{title}</TableCell>
					<TableCell>
						<FormatCurrency value={total(data?.map(item => item.valueChange)) ?? 0} maximumFractionDigits={0} />
					</TableCell>
					<TableCell>{data?.length ?? 0} kpl</TableCell>
					<TableCell>
						<FormatCurrency value={average(data?.map(item => item.valueChange)) ?? 0} maximumFractionDigits={0} />
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography sx={{ pl: '1rem', fontSize: '0.875rem' }}>Pro</Typography>
					</TableCell>
					<TableCell>
						<FormatCurrency
							value={total(data?.filter(item => item?.mrrType === 'licensed').map(item => item.valueChange)) ?? 0}
							maximumFractionDigits={0}
						/>
					</TableCell>
					<TableCell>{data?.filter(item => item?.mrrType === 'licensed')?.length ?? 0} kpl</TableCell>
					<TableCell>
						<FormatCurrency
							value={average(data?.filter(item => item?.mrrType === 'licensed').map(item => item.valueChange)) ?? 0}
							maximumFractionDigits={0}
						/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography sx={{ pl: '1rem', fontSize: '0.875rem' }}>Advanced</Typography>
					</TableCell>
					<TableCell>
						<FormatCurrency
							value={total(data?.filter(item => item?.mrrType === 'advanced').map(item => item.valueChange)) ?? 0}
							maximumFractionDigits={0}
						/>
					</TableCell>
					<TableCell>{data?.filter(item => item?.mrrType === 'advanced')?.length ?? 0} kpl</TableCell>
					<TableCell>
						<FormatCurrency
							value={average(data?.filter(item => item?.mrrType === 'advanced').map(item => item.valueChange)) ?? 0}
							maximumFractionDigits={0}
						/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography sx={{ pl: '1rem', fontSize: '0.875rem' }}>Toimittajapalvelu</Typography>
					</TableCell>
					<TableCell>
						<FormatCurrency
							value={
								total(data?.filter(item => item?.mrrType === 'GrantsMarketplaceProvider').map(item => item.valueChange)) ??
								0
							}
							maximumFractionDigits={0}
						/>
					</TableCell>
					<TableCell>{data?.filter(item => item?.mrrType === 'GrantsMarketplaceProvider')?.length ?? 0} kpl</TableCell>
					<TableCell>
						<FormatCurrency
							value={
								average(
									data?.filter(item => item?.mrrType === 'GrantsMarketplaceProvider').map(item => item.valueChange)
								) ?? 0
							}
							maximumFractionDigits={0}
						/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography sx={{ pl: '1rem', fontSize: '0.875rem' }}>Rahoittajapalvelu</Typography>
					</TableCell>
					<TableCell>
						<FormatCurrency
							value={
								total(data?.filter(item => item?.mrrType === 'GrantsFinancialService').map(item => item.valueChange)) ?? 0
							}
							maximumFractionDigits={0}
						/>
					</TableCell>
					<TableCell>{data?.filter(item => item?.mrrType === 'GrantsFinancialService')?.length ?? 0} kpl</TableCell>
					<TableCell>
						<FormatCurrency
							value={
								average(data?.filter(item => item?.mrrType === 'GrantsFinancialService').map(item => item.valueChange)) ?? 0
							}
							maximumFractionDigits={0}
						/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography sx={{ pl: '1rem', fontSize: '0.875rem' }}>Palvelupaketit</Typography>
					</TableCell>
					<TableCell>
						<FormatCurrency
							value={total(data?.filter(item => item?.mrrType === 'package').map(item => item.valueChange)) ?? 0}
							maximumFractionDigits={0}
						/>
					</TableCell>
					<TableCell>{data?.filter(item => item?.mrrType === 'package')?.length ?? 0} kpl</TableCell>
					<TableCell>
						<FormatCurrency
							value={average(data?.filter(item => item?.mrrType === 'package').map(item => item.valueChange)) ?? 0}
							maximumFractionDigits={0}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

// eslint-disable-next-line no-unused-vars
const MrrChangeTable = ({ data = [], title, total }: { data: any[]; title: string; total: number }) => {
	const intl = useIntl();
	const history = useHistory();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	if (!data) return null;

	const mappedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => {
		return (
			<TableRow key={item.companyId}>
				<TableCell>
					<Button variant='text' onClick={() => history.push(`/admin/company/${item.companyId}`)}>
						{item.companyId}
					</Button>
				</TableCell>
				<TableCell>{item.companyName}</TableCell>
				<TableCell>{item.startDate ? moment(item.startDate).format('DD.M.YYYY') : null}</TableCell>
				<TableCell>{item.endDate ? moment(item.endDate).format('DD.M.YYYY') : null}</TableCell>
				<TableCell sx={{ textAlign: 'right', pr: '.5rem !important' }}>
					<FormatCurrency value={item.value ?? 0} maximumFractionDigits={0} />
				</TableCell>
			</TableRow>
		);
	});

	return (
		<TableContainer
			sx={{
				'& table td, & table th': {
					padding: 0,
				},
			}}
		>
			<PanelTitle sx={{ mb: '1rem' }}>{title}</PanelTitle>
			<Table stickyHeader size='small'>
				<TableHead>
					<TableRow>
						<TableCell width={130}>{intl.formatMessage({ id: 'mrr.changetable.companyId' })}</TableCell>
						<TableCell>{intl.formatMessage({ id: 'mrr.changetable.name' })}</TableCell>
						<TableCell width={120}>{intl.formatMessage({ id: 'mrr.changetable.startTime' })}</TableCell>
						<TableCell width={120}>{intl.formatMessage({ id: 'mrr.changetable.endTime' })}</TableCell>
						<TableCell width={120} sx={{ textAlign: 'right', pr: '.5rem !important' }}>
							{intl.formatMessage({ id: 'mrr.changetable.value' })}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{mappedData}</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell sx={{ border: 'none' }}></TableCell>
						<TableCell colSpan={3} sx={{ border: 'none', textAlign: 'right', fontSize: '0.875rem' }}>
							{intl.formatMessage({ id: 'mrr.changetable.total' })}
						</TableCell>
						<TableCell sx={{ border: 'none', textAlign: 'right', fontSize: '0.875rem', pr: '.5rem !important' }}>
							<FormatCurrency value={total ?? 0} maximumFractionDigits={0} />
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component='div'
				count={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</TableContainer>
	);
};

// eslint-disable-next-line no-unused-vars
function median(values: any[]) {
	if (!values || values?.length === 0) return 0;

	values.sort(function (a, b) {
		return a - b;
	});

	let half = Math.floor(values.length / 2);

	if (values.length % 2) return values[half];

	return (values[half - 1] + values[half]) / 2.0;
}

function average(values: any[]) {
	if (!values || values?.length === 0) return 0;

	return total(values) / values.length;
}

function total(values: any[]) {
	if (!values || values?.length === 0) return 0;

	let total = 0;

	values.map(item => {
		total = total + item;
	});

	return total;
}

function netValueCalc(gain: number, lost: number, refunds: number) {
	gain = gain ?? 0;
	lost = lost ?? 0;
	refunds = refunds ?? 0;

	const value = gain - lost + refunds;
	let level = NetValueLevels.find(item => value > item.min);

	if (!level) level = NetValueLevels[NetValueLevels.length - 1];

	return { value, level, gain, lost, refunds };
}

const MrrMonthDialog = ({ data, isOpen, closeModal, loading }: { data: any; isOpen: boolean; closeModal: any; loading: boolean }) => {
	const intl = useIntl();

	const netValue = netValueCalc(
		data?.valueGain,
		data?.valueLost,
		sumBy(data?.refunds, (item: any) => item.valueChange ?? 0)
	);

	const newLicenses = data?.new?.filter((item: any) => {
		return item.valueChange === item.value;
	});
	const lostLicenses = data?.churn?.filter((item: any) => {
		return item.value === 0;
	});

	const changedChurnLicenses =
		data?.churn?.filter((item: any) => {
			return item.value > 0 || item.value < 0 || item.valueChange > 0;
		}) ?? [];
	const gainedLicenses =
		data?.new?.filter((item: any) => {
			return item.value > item.valueChange;
		}) ?? [];
	const allChangedLicenses = [...changedChurnLicenses, ...gainedLicenses];

	return (
		<>
			<Dialog
				aria-labelledby={'test'}
				aria-describedby={'test-a'}
				fullWidth
				maxWidth='lg'
				open={isOpen}
				onClose={closeModal}
				scroll='paper'
			>
				<DialogContent>
					<Stack sx={{ width: '100%' }} direction='row' alignItems='center' alignContent='space-between'>
						<Typography sx={{ fontSize: '1.2rem', fontWeight: 500, flexGrow: 1 }}>{data?.month}</Typography>
						<Box textAlign='right'>
							<IconButton aria-label='close' onClick={closeModal}>
								<CloseIcon />
							</IconButton>
						</Box>
					</Stack>
					<Stack sx={{ width: '100%' }} direction='row' alignContent='space-between'>
						<Table sx={{ ml: '1rem', mb: '2rem' }} size='small'>
							<TableBody>
								<TableRow>
									<TableCell>MRR-muutos:</TableCell>
									<TableCell>
										<Box sx={{ fontWeight: 400, color: netValue.level.color }}>
											<Typography component='span' sx={{ fontWeight: 600 }}>
												<FormatCurrency value={netValue.value} maximumFractionDigits={0} />
											</Typography>
											<MoodSlider
												color={netValue.level.color}
												value={netValue.value}
												level={intl.formatMessage({ id: `mrr.changetable.level.${netValue.level.level}` })}
											/>
										</Box>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>MRR lisäys:</TableCell>
									<TableCell>
										<FormatCurrency value={netValue.gain} maximumFractionDigits={0} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>MRR menetys:</TableCell>
									<TableCell>
										<FormatCurrency value={netValue.lost} maximumFractionDigits={0} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Vaikuttavat hyvitykset:</TableCell>
									<TableCell>
										<FormatCurrency value={netValue.refunds} maximumFractionDigits={0} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Stack>
					<TableContainer sx={{ mb: '1rem' }}>
						<LicenseValueTable title={intl.formatMessage({ id: 'mrr.changetable.gain.title' })} data={newLicenses} />
						<LicenseValueTable
							sx={{ mt: '1rem' }}
							title={intl.formatMessage({ id: 'mrr.changetable.lost.title' })}
							data={lostLicenses}
						/>
						<LicenseValueTable
							sx={{ mt: '1rem' }}
							title={intl.formatMessage({ id: 'mrr.changetable.changed.title' })}
							data={allChangedLicenses}
						/>
					</TableContainer>

					<Typography sx={{ mt: '2rem', mb: '1rem' }}>{intl.formatMessage({ id: 'mrr.changetable.gain.title' })}</Typography>
					<MRRLicenseDataGrid
						showValueChange={false}
						rowData={newLicenses}
						loading={loading}
						autoHeight={false}
						heigth={'20rem'}
						rowsPerPage={100}
					/>

					<Typography sx={{ mt: '2rem', mb: '1rem' }}>{intl.formatMessage({ id: 'mrr.changetable.lost.title' })}</Typography>
					<MRRLicenseDataGrid
						showValue={false}
						rowData={lostLicenses}
						loading={loading}
						autoHeight={false}
						heigth={'20rem'}
						rowsPerPage={100}
					/>

					<Typography sx={{ mt: '2rem', mb: '1rem' }}>{intl.formatMessage({ id: 'mrr.changetable.changed.title' })}</Typography>
					<MRRLicenseDataGrid
						rowData={allChangedLicenses}
						loading={loading}
						autoHeight={false}
						heigth={'20rem'}
						rowsPerPage={100}
					/>

					<Typography sx={{ mt: '2rem', mb: '1rem' }}>{intl.formatMessage({ id: 'mrr.changetable.refund.title' })}</Typography>
					<MRRLicenseDataGrid rowData={data?.refunds} loading={loading} autoHeight={false} heigth={'20rem'} rowsPerPage={100} />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default MrrMonthDialog;
