import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PrintIcon from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import Button from 'components/Button/Button';
import DropDownMenuButton from 'components/Button/DropDownMenuButton';
import ContentContainer from 'components/Containers/ContentContainer';
import MainTitle from 'components/Titles/MainTitle';
import MainContent from './MainContent';

import getCompanyLink from 'utils/auth/getCompanyLink';
import { isContributorToken } from 'utils/auth/token';
import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';

import { secodandaryRgb } from 'config/colors';
import { getTimePassed } from '../../../containers/Projects/ProjectList/ProjectCard/ProjectCard';
import './index.css';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
	},
	demoLabel: {
		fontWeight: 500,
		padding: '0.3rem 1rem',
		borderRadius: '1rem',
		color: theme.palette.primary.white,
		backgroundColor: theme.palette.primary.main,
	},
	icon: {
		position: 'fixed',
		right: theme.spacing(1),
		top: theme.spacing(9),
		[theme.breakpoints.up('sm')]: {
			top: theme.spacing(1),
		},
	},
}));

const FinancialPlan = ({
	company,
	financialPlan,
	publishedFinancialPlan,
	projects,
	scenario,
	instruments,
	readonly,
	timeline,
	sx = {},
}) => {
	const classes = useStyles();
	const intl = useIntl();
	const location = useLocation();
	const history = useHistory();

	const { hash } = location;
	useScrollIntoViewWithOffset('general', hash);

	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
		documentTitle: `${intl.formatMessage({ id: 'financial.plan.title' })}: ${company?.name}, ${company?.businessId}`,
	});

	const [showPublished, setShowPublished] = useState(false);
	const [financialPlanShow, setFinancialPlanShow] = useState(
		publishedFinancialPlan ? publishedFinancialPlan : financialPlan?.partitionKey ? financialPlan : null
	);

	const isContributor = isContributorToken();

	const toggleFinancialPlan = () => {
		setShowPublished(!showPublished);
		setFinancialPlanShow(!showPublished ? publishedFinancialPlan : financialPlan);
	};

	const isTemplate = !financialPlan?.partitionKey;

	const getAdminSwitch = () => {
		if (!isContributor || readonly) return null;

		return (
			<>
				<DropDownMenuButton
					sx={{ ml: '1rem', display: !publishedFinancialPlan && !financialPlan?.rowKey && 'none' }}
					loading={false}
					text={
						financialPlanShow?.isDraft
							? intl.formatMessage({ id: 'financial.plan.state.draft' })
							: publishedFinancialPlan
								? intl.formatMessage({ id: 'financial.plan.state.published' })
								: '-'
					}
					items={[
						{
							disabled: financialPlanShow?.isDraft || financialPlan?.isDraft === undefined || isTemplate,
							text:
								!financialPlan?.isDraft || isTemplate
									? intl.formatMessage({ id: 'financial.plan.state.draft.notexists' })
									: intl.formatMessage({ id: 'financial.plan.state.draft' }),
							requireConfirm: false,
							confirmAction: toggleFinancialPlan,
						},
						{
							disabled: !publishedFinancialPlan || !financialPlanShow?.isDraft,
							text: !publishedFinancialPlan
								? intl.formatMessage({ id: 'financial.plan.notpublished' })
								: intl.formatMessage({ id: 'financial.plan.state.published' }),
							requireConfirm: false,
							confirmAction: toggleFinancialPlan,
						},
					]}
				/>
				<Button
					sx={{ ml: '1rem' }}
					onClick={() => history.push(getCompanyLink('/financialplan/edit', location))}
					className={classes.iconButton}
					icon={<ModeEditIcon />}
				>
					{intl.formatMessage({ id: 'financial.plan.edit' })}
				</Button>
			</>
		);
	};

	return (
		<ContentContainer id='general' sx={sx}>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				spacing={2}
				sx={{
					position: { xs: 'relative', md: 'sticky' },
					top: 0,
					zIndex: theme => theme.zIndex.appBar - 1,
					mb: '.5rem',
					backgroundColor: 'primary.secondary',
					boxShadow: `rgba(${secodandaryRgb}, 1) 10px 1px 6px`,
				}}
			>
				<Stack direction='row'>
					<MainTitle margin={false}>
						{financialPlan
							? intl.formatMessage({ id: 'financial.plan.title' })
							: intl.formatMessage({ id: 'financial.plan.title.timeline' })}
					</MainTitle>

					{financialPlan?.mock && !isContributor && (
						<Box sx={{ width: '5rem', ml: '2rem' }}>
							<Typography className={classes.demoLabel}>DEMO</Typography>
						</Box>
					)}
				</Stack>
				<Box sx={{ display: financialPlan ? 'block' : 'none' }}>
					<MuiButton onClick={handlePrint} variant='contained' color='white' startIcon={<PrintIcon />}>
						{intl.formatMessage({ id: 'financial.plan.print' })}
					</MuiButton>
					{getAdminSwitch()}
				</Box>
			</Stack>
			{financialPlan?.rowKey && (
				<Typography variant='stamp'>
					{intl.formatMessage({ id: 'financial.plan.state.published' })} {getTimePassed(financialPlan?.created)}
				</Typography>
			)}
			<Box>
				<MainContent
					financialPlan={financialPlanShow}
					projects={projects}
					company={company}
					scenario={scenario}
					instruments={instruments}
					printRef={printRef}
					timeline={timeline}
				/>
			</Box>
		</ContentContainer>
	);
};

export default FinancialPlan;
