const localStorageFunctions = {
	set: (key: string, value: any) => {
		if (!key || !value) {
			return;
		}

		if (typeof value === 'object') {
			value = JSON.stringify(value);
		}

		localStorage.setItem(key, value);
	},
	get: (key: string): any => {
		let value = localStorage.getItem(key);

		if (!value) {
			return null;
		}

		// assume it is an object that has been stringified
		if (value[0] === '{' || value[0] === '[') {
			value = JSON.parse(value);
		}

		return value;
	},
	remove: (key: string) => {
		if (!key) {
			return;
		}

		localStorage.removeItem(key);
	},
};

export default localStorageFunctions;
