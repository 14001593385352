import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { Box, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import { useRequestForgottenPasswordMutation } from 'containers/Account/passwordV2Api';

import Logo from 'icons/Logo';
import RequestTemporaryPasswordForm from './RequestTemporaryPasswordForm/RequestTemporaryPasswordForm';

type FieldValues = {
	username: string | null;
};

const RequestTemporaryPassword: React.FC = () => {
	const [requestForgottenPassword, { isLoading }] = useRequestForgottenPasswordMutation();

	const onSubmit = React.useCallback(
		async (fieldValues: FieldValues) => {
			try {
				await requestForgottenPassword(fieldValues).unwrap();
				toast.success(<FormattedMessage id='request.temporarily.password.success.message' />);
			} catch (error) {
				toast.error(<FormattedMessage id='request.temporarily.password.error.message' />);
			}
		},
		[requestForgottenPassword]
	);

	return (
		<>
			<Helmet>
				<title>GrantedAI - request temporary password</title>
			</Helmet>
			<Stack
				direction='column'
				spacing={2}
				sx={{ minHeight: { xs: '40rem', md: '100%' }, padding: '1.143rem', backgroundColor: theme => theme.palette.primary.main }}
				useFlexGap
			>
				<Logo />
				<Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Stack direction='column' spacing={2} sx={{ width: '100%', maxWidth: '30rem' }} useFlexGap>
						<Typography
							variant='h1'
							sx={{
								color: theme => theme.palette.primary.contrastText,
								fontSize: '1.714rem',
								fontWeight: 700,
								lineHeight: 1.3,
								textAlign: 'center',
							}}
						>
							<FormattedMessage id='requestTemporaryPassword.title' />
						</Typography>
						<Typography
							sx={{
								color: theme => theme.palette.primary.contrastText,
								fontSize: '1.143rem',
								fontWeight: 400,
								lineHeight: 1.5,
								textAlign: 'center',
							}}
						>
							<FormattedMessage id='requestTemporaryPassword.description' />
						</Typography>
						{/* @ts-ignore */}
						<RequestTemporaryPasswordForm onSubmit={onSubmit} loading={isLoading} />
					</Stack>
				</Box>
			</Stack>
		</>
	);
};

export default RequestTemporaryPassword;
