import { useIntl } from 'react-intl';

import BusinessPlanContainer from 'containers/BusinessPlan/BusinessPlanContainer';
import { useGetBusinessPlanDataQuery, useGetCompanyDataQuery, useGetInstrumentsDataQuery } from 'containers/Stakeholder/stakeholderApi';

import { combineEconomyData } from 'containers/BusinessPlan/utils';
import Error from '../Components/Error';
import Loader from '../Components/Loader/Loader';

const BusinessPlan = ({ linkId }) => {
	const intl = useIntl();

	const {
		data: businessPlan,
		isFetching: isBusinessPlanFetching,
		isLoading: isBusinessPlanLoading,
		isSuccess: isBusinessPlanSuccess
	} = useGetBusinessPlanDataQuery(linkId);

	const {
		data: instruments,
		isFetching: isInstrumentsFetching,
		isLoading: isInstrumentsLoading,
		// isSuccess: isInstrumentsSuccess
	} = useGetInstrumentsDataQuery(linkId);

	const {
		data: company,
		isFetching: isCompanyFetching,
		isLoading: isCompanyLoading,
		// isSuccess: isCompanySuccess
	} = useGetCompanyDataQuery(linkId);

	if (
		isBusinessPlanFetching ||
		isBusinessPlanLoading ||
		isInstrumentsFetching ||
		isInstrumentsLoading ||
		isCompanyFetching ||
		isCompanyLoading
	)
		return <Loader />;

	if (!isBusinessPlanSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.businessplan.error' })}</Error>;

	const economicalData = combineEconomyData(company?.company?.economicalData, businessPlan?.businessPlan?.economicalData ?? []);

	return (
		<BusinessPlanContainer
			sx={{ padding: 0 }}
			hideTitle
			readonly
			company={company?.company}
			instruments={instruments?.instruments}
			businessPlan={businessPlan?.businessPlan}
			economicalData={economicalData}
		/>
	);
};

export default BusinessPlan;
