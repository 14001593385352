import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '.5rem',
    },
    attributeContainer: {
        margin: '0.5rem 0'
    },
    attribute: {
        display: 'inline-block',
        padding: '0.3rem 1rem',
        backgroundColor: '#e5e7ea',
        borderRadius: '1rem'
    },
}));

const InstrumentAttributes = ({ instrument }) => {
    const classes = useStyles();
    const intl = useIntl();

    const renderBooleanAttribute = (titleId, attr) => {
        if (!attr) return null;

        return (
            <div className={classes.attributeContainer}>
                <Typography color='primary' className={classes.attribute}>
                    {intl.formatMessage({ id: titleId })}
                </Typography>
            </div>
        );
    };

    const renderQuantityAttribute = (titleId, attr, unit) => {
        if (!attr) return null;

        return (
            <div className={classes.attributeContainer}>
                <Typography color='primary' className={classes.attribute}>
                    {intl.formatMessage({ id: titleId })}: {attr}{unit}
                </Typography>
            </div>
        );
    };

    const renderMinMaxAttribute = (titleId, min, max, unit) => {
        if (!min && !max) return null;

        return (
            <div className={classes.attributeContainer}>
                <Typography color='primary' className={classes.attribute}>
                    {intl.formatMessage({ id: titleId })}: {
                        min && max ? `${min}-${max}${unit}` :
                            !min && max ? `0-${max}${unit}` :
                                min && !max && `yli ${min}${unit}`
                    }
                </Typography>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            {renderBooleanAttribute('instrument.deminimis', instrument?.isDeminimis)}
            {renderQuantityAttribute('instrument.projectSize', instrument?.projectSizeEur, '€')}
            {renderQuantityAttribute('instrument.expectedPendingTime', instrument?.expectedPendingTime, 'kk')}
            {renderMinMaxAttribute('instrument.amountPercentage', instrument?.amountMinPercentage, instrument?.amountMaxPercentage, '%')}
            {renderMinMaxAttribute('instrument.amount', instrument?.minAmount, instrument?.maxAmount, '€')}
            {renderQuantityAttribute('instrument.prepayment', instrument?.prepayment, '€')}
            {renderQuantityAttribute('instrument.timeframe', instrument?.timeFrame, 'kk')}
            {renderQuantityAttribute('instrument.installmentsFreeTime', instrument?.installmentsFreeTime, 'kk')}
        </div>
    );
};

export default InstrumentAttributes;
