import React, { forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import Slideshow from 'components/Slideshow';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDialog-container': {
            maxHeight: '900px',
        },
        '& .MuiDialog-paperFullWidth': {
            width: '100%',
        },
        '& .MuiDialog-paperScrollPaper': {
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                maxHeight: '100%',
            },
        },
        '& .MuiDialog-paper': {
            [theme.breakpoints.down('sm')]: {
                margin: 0,
            },
        },
        '& .MuiDialogContent-root:first-child': {
            paddingTop: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
    },
    dialogContent: {
        backgroundColor: theme.palette.primary.white,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    closeIcon: {
        color: theme.palette.primary.main,
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const SlideshowModal = ({ open, close, companyName }) => {
    const classes = useStyles();

    return (
        <Dialog
            aria-labelledby='slideshow'
            aria-describedby='slideshow'
            fullWidth
            maxWidth='lg'
            open={open}
            onClose={close}
            scroll='paper'
            TransitionComponent={Transition}
            classes={{
                root: classes.root,
            }}
        >
            <DialogContent className={classes.dialogContent}>
                {/* TODO: May be move it to each slide --> bgColor */}
                <Box textAlign='right'>
                    <IconButton aria-label='close' onClick={close}>
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </Box>
                <Slideshow companyName={companyName} maxHeight='760px' />
            </DialogContent>
        </Dialog>
    );
};

export default SlideshowModal;
