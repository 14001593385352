import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 64px - 164px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 64px - 134px)',
        },
    },
    message: {
        fontSize: '1.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
        },
    },
}));

const NoCompany = () => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Grid container direction='column' justifyContent='center' alignItems='center' className={classes.root}>
            <Grid item>
                <Typography variant='h1' color='primary' className={classes.message}>
                    {intl.formatMessage({ id: '404.message.sorry' })}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='h1' color='primary' className={classes.message}>
                    {intl.formatMessage({ id: '404.message.no.company' })}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default NoCompany;
