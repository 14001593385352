import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { makeStyles, useTheme } from '@mui/styles';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TeamMemberAvatar from '../../../../TeamMemberCard/TeamMemberAvatar';

const useStyles = makeStyles(theme => ({
    dropBox: {
        border: `1px solid ${theme.palette.secondary.border}`,
        borderRadius: '50%',
        padding: '1rem',
    },
    personIcon: {
        width: '100% ',
        height: '100% ',
        color: theme.palette.secondary.border,
    },
    image: {
        maxWidth: '100%',
        borderRadius: '50%',
    },
    addButton: {
        position: 'absolute',
        display: 'flex',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.success.main,
        borderRadius: '50%',
        top: '70%',
        right: 0,
        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.02)',
        },
    },
    deleteButton: {
        position: 'absolute',
        backgroundColor: theme.palette.error.main,
        top: '70%',
        right: 0,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
            transform: 'scale(1.02)',
        },
    },
    // changeButton: {
    //     position: 'absolute',
    //     display: 'flex',
    //     padding: theme.spacing(1),
    //     backgroundColor: theme.palette.primary.main,
    //     borderRadius: '50%',
    //     top: '70%',
    //     left: 0,
    //     '&:hover': {
    //         cursor: 'pointer',
    //     },
    // },
    icon: {
        color: theme.palette.primary.white,
    },
}));

const ImageDropzone = ({
    files = [],
    avatar,
    onDrop,
    onRemove,
    setValue,
    readonly,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/*',
        multiple: false,
        maxFiles: 1,
        maxSize: 5e+6, // bytes --> 5MB
        onDrop,
    });

    const rootProps = getRootProps();

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const [isAvatar, setIsAvatar] = useState(true);

    if (readonly) {
        if (!avatar || !isAvatar) return <PersonOutlineOutlinedIcon className={classes.personIcon} />;

        return <TeamMemberAvatar avatar={avatar} isForm={true} />;
    }

    return (
        <Box sx={{ maxWidth: '10rem', margin: '0 auto' }}>
            {files.length === 0 && (!avatar || !isAvatar)
                ? <Box
                    textAlign='center'
                    className={classes.dropBox}
                    sx={{
                        position: 'relative',
                        backgroundColor: isDragActive && theme.palette.primary.secondary,
                    }}
                >
                    <PersonOutlineOutlinedIcon className={classes.personIcon} />
                    <div className={classes.addButton} {...rootProps} >
                        <AddOutlinedIcon fontSize='small' className={classes.icon} />
                        <input {...getInputProps()} />
                    </div>
                </Box>
                : files.length === 0 && avatar && isAvatar
                    ? <Box sx={{ position: 'relative' }} >
                        <TeamMemberAvatar avatar={avatar} isForm={true} />
                        <IconButton
                            onClick={() => {
                                setIsAvatar(false);
                                setValue('');
                            }}
                            className={classes.deleteButton}
                        >
                            <DeleteOutlinedIcon fontSize='small' className={classes.icon} />
                        </IconButton>
                        {/* <div className={classes.changeButton} {...rootProps} >
                            <EditOutlinedIcon fontSize='small' className={classes.icon} />
                            <input {...getInputProps()} />
                        </div> */}
                    </Box>
                    : files?.map(file => (
                        <Box key={file.path} sx={{ position: 'relative' }}>
                            <img
                                alt='Avatar'
                                src={file.preview}
                                className={classes.image}
                            />
                            <IconButton onClick={() => onRemove && onRemove(file)} className={classes.deleteButton}>
                                <DeleteOutlinedIcon fontSize='small' className={classes.icon} />
                            </IconButton>
                            {/* <div className={classes.changeButton} {...rootProps} >
                                <EditOutlinedIcon fontSize='small' className={classes.icon} />
                                <input {...getInputProps()} />
                            </div> */}
                        </Box>
                    ))
            }
        </Box>
    );
};


export default ImageDropzone;
