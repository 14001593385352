import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

type RequestCompanyInstrumentAnalysis = {
	companyId: string | null;
	instrumentId: string | null;
};

type RequestGenerateCompanyInstrumentAnalysis = {
	companyId: string | null;
	instrumentId: string | null;
	body: any;
	forceRegeneration: boolean;
};

const Tags = {
	InstrumentCompanyTag: 'instrument-company',
};

const baseUrl = 'instrument/analysis';

export const instrumentAnalysisApi = createApi({
	reducerPath: 'instrument_analysis',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.InstrumentCompanyTag],
	endpoints: builder => ({
		getInstrumentAnalysis: builder.query<any, RequestCompanyInstrumentAnalysis>({
			query: ({ companyId, instrumentId }) => {
				if (isNil(companyId) || isNil(instrumentId)) {
					throw new Error('parameter error');
				}

				return {
					method: 'GET',
					url: `/${companyId}/${instrumentId}`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.InstrumentCompanyTag, id: `${params.companyId}_${params.instrumentId}` ?? '' }];
			},
		}),
		createInstrumentAnalysis: builder.mutation<any, RequestGenerateCompanyInstrumentAnalysis>({
			query: ({ companyId, instrumentId, body, forceRegeneration }) => {
				if (isNil(companyId) || isNil(instrumentId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/${companyId}/${instrumentId}?force-override=${forceRegeneration}`,
					data: body,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.InstrumentCompanyTag, id: `${params.companyId}_${params.instrumentId}` ?? '' }];
			},
		}),
	}),
});

export const { useGetInstrumentAnalysisQuery, useCreateInstrumentAnalysisMutation } = instrumentAnalysisApi;
