import { useIntl } from 'react-intl';

// 'intl' service singleton reference
let intl: any;

export const IntlGlobalProvider = ({ children }: any) => {
	intl = useIntl(); // Keep the 'intl' service reference
	return children;
};

// Getter function to expose the read-only 'intl' service
export const appIntl = () => {
	return intl;
};
