import { InputBaseComponentProps } from '@mui/material';
import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

type Props = InputBaseComponentProps & {};

const CurrencyInput = React.forwardRef<NumericFormatProps, Props>(({ onChange, defaultValue, ...otherProps }, ref) => {
	return (
		<NumericFormat
			{...otherProps}
			getInputRef={ref}
			onValueChange={values => {
				onChange?.({
					target: {
						value: values.floatValue,
					},
				} as any);
			}}
			thousandSeparator=' '
			decimalSeparator=','
		/>
	);
});

export default CurrencyInput;
