import { Close as CloseIcon } from '@mui/icons-material';
import { Drawer, DrawerProps, IconButton } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeDrawer, getDrawerData, isDrawerOpen, openDrawer } from 'containers/Marketplace/drawerSlice';

import ReadRfpRequestForm from 'containers/Marketplace/request/Read/ReadRfpRequestForm';

type Props = DrawerProps & {};

const ReadRfpRequestDrawer: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const drawerOpen = useSelector(isDrawerOpen('ReadRfpRequestDrawer'));
	const drawerData = useSelector(getDrawerData('ReadRfpRequestDrawer'));

	const handleDrawerClose = React.useCallback(() => {
		dispatch(closeDrawer({ name: 'ReadRfpRequestDrawer' }));
	}, [dispatch]);

	const handleEditClick = React.useCallback(() => {
		dispatch(
			openDrawer({
				name: 'UpsertRfpRequestDrawer',
				data: { requestId: drawerData?.requestId, published: drawerData?.published },
			})
		);
		dispatch(closeDrawer({ name: 'ReadRfpRequestDrawer' }));
	}, [dispatch, drawerData]);

	return (
		<>
			<Drawer
				{...otherProps}
				anchor='right'
				open={drawerOpen}
				onClose={handleDrawerClose}
				PaperProps={{
					sx: { width: { xs: '100%', md: '80%', lg: '60%' } },
				}}
			>
				<IconButton sx={{ position: 'absolute', right: 0, top: 0 }} onClick={handleDrawerClose}>
					<CloseIcon />
				</IconButton>
				<ReadRfpRequestForm
					requestId={drawerData?.requestId}
					published={drawerData?.published}
					createdByMe={drawerData?.createdByMe}
					onEditClick={handleEditClick}
				/>
			</Drawer>
		</>
	);
};

export default ReadRfpRequestDrawer;
