import * as React from 'react';
import { useIntl } from 'react-intl';

import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';

import { useDeleteChatMessageMutation, useGetChatHistoryQuery } from 'containers/AI/aiApi';
import { getCompanyId } from 'utils/auth/company';

import DraggableBox from 'containers/AI/AiChatWrapper/DraggableBox';
import ConfirmButton from '../../../components/Button/ConfirmButton';
import { AIContextSelector } from '../AIContextSelector/AIContextSelector';
import ChatFlow from './ChatFlow';
import ChatForm from './ChatForm';

export interface OpenAIMessage {
	role: string;
	content: string;
}

export interface Props {
	messages: Array<OpenAIMessage>;
	disabled: boolean;
	chatId: string;
	title: string;
	onCloseClick?: () => void;
	onDrag?: (x: number, y: number) => void;
	inputDefaultValue?: string;
}

const AiChat = ({ messages = [], chatId, title, onCloseClick, onDrag, inputDefaultValue = '' }: Props) => {
	const companyId = getCompanyId();
	const intl = useIntl();
	const [aiLoading, setAiLoading] = React.useState(false);
	const [promptBase, setPromptBase] = React.useState(null);

	const {
		data: chatHistoryData,
		isFetching: chatHistoryIsFetching,
		isLoading: chatHistoryIsLoading,
	} = useGetChatHistoryQuery({
		companyId: companyId,
		chatId: chatId,
	});
	const [deleteChatMessages, { isLoading: isDeleteLoading }] = useDeleteChatMessageMutation();

	const loading = chatHistoryIsLoading || chatHistoryIsFetching;

	const [headerHeight, setHeaderHeight] = React.useState(0);

	const headerRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const header = headerRef.current;
		if (!header) return;

		setHeaderHeight(header.offsetHeight ?? 0);
	}, []);

	const onSelectedChanged = (values: any) => {
		setPromptBase(values);
	};

	return (
		<Stack flexDirection='column' sx={{ bgcolor: 'background.paper', position: 'relative', width: '100%', height: '100%' }}>
			<DraggableBox onDrag={onDrag} sx={{ bgcolor: '#e0d6c4', '&:hover': { cursor: 'grab' } }}>
				<Stack ref={headerRef} flexDirection='row' justifyContent='space-between' spacing={2} sx={{ p: '0.5rem' }} useFlexGap>
					<Typography variant='h3' sx={{ alignSelf: 'center' }}>
						{title}
					</Typography>
					<IconButton onClick={onCloseClick} color='primary'>
						<CloseIcon />
					</IconButton>
				</Stack>
			</DraggableBox>
			<Stack flexDirection='column' justifyContent='space-between' sx={{ height: `calc(100% - ${headerHeight}px)` }} useFlexGap>
				<AIContextSelector companyId={companyId} onSelectedChanged={onSelectedChanged} />
				<ChatFlow sx={{ width: '100%' }} loading={loading} chat={chatHistoryData} aiLoading={aiLoading} />
				<ChatForm
					inputDefaultValue={inputDefaultValue}
					promptBase={promptBase}
					chatHistory={chatHistoryData}
					chatId={chatId}
					messages={messages}
					loading={loading}
					setAiLoading={setAiLoading}
					handledResponse={() => {}}
					deleteButton={
						// @ts-ignore
						<ConfirmButton
							variant='outlined'
							color='error'
							loading={isDeleteLoading}
							confirmAction={() => deleteChatMessages({ companyId, chatId })}
							confirmText={intl.formatMessage({ id: 'ai.chat.remove.confimation' })}
							cancelText={intl.formatMessage({ id: 'ai.chat.remove.cancel' })}
							confirmBodyText={intl.formatMessage({ id: 'ai.chat.remove.message' })}
							buttonText={intl.formatMessage({ id: 'ai.chat.remove.delete' })}
						/>
					}
				/>
			</Stack>
		</Stack>
	);
};

export default AiChat;
