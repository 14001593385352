import { concat, isNil, map } from 'lodash';
import { createApi } from '@reduxjs/toolkit/query/react';

import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

import { RfpProfile, RfpProfileInfoItem, RfpProfileTeamMember, PaginationToken, PublicProfilesList } from 'types/dataTypes';

type FetchRfpProfile = {
	companyId?: string | null;
};

type UpsertRfpProfile = {
	companyId?: string | null;
	rfpProfile?: Partial<RfpProfile> | null;
	publish?: boolean | null;
};

type PublishRfpProfile = {
	companyId?: string | null;
	rfpProfile?: Partial<RfpProfile> | null;
	list?: boolean;
};

type FetchRfpProfileTeamMembers = {
	companyId?: string | null;
};

type UpsertRfpProfileTeamMember = {
	companyId?: string | null;
	rfpProfileTeamMember?: Partial<RfpProfileTeamMember> | null;
};

type RemoveRfpProfileTeamMember = {
	companyId?: string | null;
	teamMemberId?: string | null;
};

type FetchRfpProfileInfoItems = {
	companyId?: string | null;
};

type UpsertRfpProfileInfoItem = {
	companyId?: string | null;
	rfpProfileInfoItem?: Partial<RfpProfileInfoItem> | null;
};

type RemoveRfpProfileInfoItem = {
	companyId?: string | null;
	infoItemId?: string | null;
};

type FetchPublicProfilesList = {
	token?: PaginationToken | null;
};

enum Tags {
	RfpProfiles = 'rfpProfiles',
	RfpProfile = 'rfpProfile',
	RfpProfileTeamMember = 'rfpProfileTeamMember',
	RfpProfileInfoItem = 'rfpProfileInfoItem',
}

const baseUrl = 'rfp';

export const rfpProfileApi = createApi({
	reducerPath: 'rfp',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.RfpProfile, Tags.RfpProfileTeamMember, Tags.RfpProfileInfoItem, Tags.RfpProfiles],
	endpoints: builder => ({
		fetchRfpProfile: builder.query<RfpProfile, FetchRfpProfile>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/profile/${companyId}`,
				};
			},
			providesTags: rfpProfile => {
				return [{ type: Tags.RfpProfile, id: rfpProfile?.companyId || '' }];
			},
		}),
		upsertRfpProfile: builder.mutation<RfpProfile, UpsertRfpProfile>({
			query: ({ companyId, rfpProfile, publish }) => {
				if (isNil(companyId) || isNil(rfpProfile)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/profile/${companyId}`,
					data: rfpProfile,
					params: { publish },
				};
			},
			invalidatesTags: rfpProfile => {
				return [{ type: Tags.RfpProfile, id: rfpProfile?.companyId || '' }];
			},
		}),
		publishRfpProfile: builder.mutation<RfpProfile, PublishRfpProfile>({
			query: ({ companyId, rfpProfile, list = false }) => {
				if (isNil(companyId) || isNil(rfpProfile)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/profile/${companyId}?publish=true&list=${list}`,
					data: rfpProfile,
				};
			},
			invalidatesTags: rfpProfile => {
				return [{ type: Tags.RfpProfile, id: rfpProfile?.companyId || '' }];
			},
		}),
		fetchRfpProfileTeamMembers: builder.query<RfpProfileTeamMember[], FetchRfpProfileTeamMembers>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/profile/${companyId}/team`,
				};
			},
			providesTags: rfpProfileTeamMembers => {
				return concat(
					[{ type: Tags.RfpProfileTeamMember, id: 'LIST' }],
					map(rfpProfileTeamMembers, rfpProfileTeamMember => ({
						type: Tags.RfpProfileTeamMember,
						id: rfpProfileTeamMember.teamMemberId || '',
					}))
				);
			},
		}),
		upsertRfpProfileTeamMember: builder.mutation<RfpProfileTeamMember, UpsertRfpProfileTeamMember>({
			query: ({ companyId, rfpProfileTeamMember }) => {
				if (isNil(companyId) || isNil(rfpProfileTeamMember)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/profile/${companyId}/team`,
					data: rfpProfileTeamMember,
				};
			},
			invalidatesTags: rfpProfileTeamMember => {
				return [
					{ type: Tags.RfpProfileTeamMember, id: 'LIST' },
					{ type: Tags.RfpProfileTeamMember, id: rfpProfileTeamMember?.teamMemberId || '' },
				];
			},
		}),
		removeRfpProfileTeamMember: builder.mutation<RfpProfileTeamMember, RemoveRfpProfileTeamMember>({
			query: ({ companyId, teamMemberId }) => {
				if (isNil(companyId) || isNil(teamMemberId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/profile/${companyId}/team/${teamMemberId}`,
				};
			},
			invalidatesTags: (result, error, { teamMemberId }) => {
				return [
					{ type: Tags.RfpProfileTeamMember, id: 'LIST' },
					{ type: Tags.RfpProfileTeamMember, id: teamMemberId || '' },
				];
			},
		}),
		fetchRfpProfileInfoItems: builder.query<RfpProfileInfoItem[], FetchRfpProfileInfoItems>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/profile/${companyId}/items`,
				};
			},
			providesTags: rfpProfileInfoItems => {
				return concat(
					[{ type: Tags.RfpProfileInfoItem, id: 'LIST' }],
					map(rfpProfileInfoItems, rfpProfileInfoItem => ({
						type: Tags.RfpProfileInfoItem,
						id: rfpProfileInfoItem.infoId || '',
					}))
				);
			},
		}),
		upsertRfpProfileInfoItem: builder.mutation<RfpProfileInfoItem, UpsertRfpProfileInfoItem>({
			query: ({ companyId, rfpProfileInfoItem }) => {
				if (isNil(companyId) || isNil(rfpProfileInfoItem)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/profile/${companyId}/items`,
					data: rfpProfileInfoItem,
				};
			},
			invalidatesTags: rfpProfileInfoItem => {
				return [
					{ type: Tags.RfpProfileInfoItem, id: 'LIST' },
					{ type: Tags.RfpProfileInfoItem, id: rfpProfileInfoItem?.infoId || '' },
				];
			},
		}),
		removeRfpProfileInfoItem: builder.mutation<RfpProfileInfoItem, RemoveRfpProfileInfoItem>({
			query: ({ companyId, infoItemId }) => {
				if (isNil(companyId) || isNil(infoItemId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/profile/${companyId}/items/${infoItemId}`,
				};
			},
			invalidatesTags: (result, error, { infoItemId }) => {
				return [
					{ type: Tags.RfpProfileInfoItem, id: 'LIST' },
					{ type: Tags.RfpProfileInfoItem, id: infoItemId || '' },
				];
			},
		}),
		//#region public profile info
		getPublicProfiles: builder.query<PublicProfilesList, FetchPublicProfilesList>({
			query: ({ token }) => {
				return {
					method: 'GET',
					url: '/profiles',
					params: {
						take: token?.take,
						nextPartitionKey: token?.nextPartitionKey,
						nextRowKey: token?.nextRowKey,
					},
				};
			},
			providesTags: [{ type: Tags.RfpProfiles, id: 'LIST' }],
		}),
		getPublicProfileByid: builder.query<RfpProfile, FetchRfpProfile>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/profiles/${companyId}`,
				};
			},
			providesTags: rfpProfile => {
				return [{ type: Tags.RfpProfile, id: rfpProfile?.companyId || '' }];
			},
		}),
		//#endregion
	}),
});

export const {
	useFetchRfpProfileQuery,
	useLazyFetchRfpProfileQuery,
	useUpsertRfpProfileMutation,
	usePublishRfpProfileMutation,
	useFetchRfpProfileTeamMembersQuery,
	useUpsertRfpProfileTeamMemberMutation,
	useRemoveRfpProfileTeamMemberMutation,
	useFetchRfpProfileInfoItemsQuery,
	useUpsertRfpProfileInfoItemMutation,
	useRemoveRfpProfileInfoItemMutation,
	//#region public profile info
	useGetPublicProfilesQuery,
	useGetPublicProfileByidQuery,
	//#endregion
} = rfpProfileApi;
