import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Typography, Box, /* Button, Switch, */ Stack } from '@mui/material';
import { styled } from '@mui/styles';

import EconomicalChart from '../BusinessPlanPrint/EconomicalChart';
import ChartToggleTabs from '../BusinessPlanPrint/ChartToggleTabs';

import { renderKeys } from '../renderKeys';
import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    backgroundColor: theme.palette.primary.white,
    paddingBottom: '2rem',
}));

const StyledEconomicalInfo = styled(Typography)(() => ({
    paddingTop: '15px',
    paddingBottom: '7px',
}));

const EconomicalTableReadonly = ({ id, data }) => {
    const intl = useIntl();
    const { hash } = useLocation();

    useScrollIntoViewWithOffset(id, hash);

    const currentYear = new Date().getFullYear();

    const [economicalData, setEconomicalData] = useState(data);
    const [view, setView] = useState(false);
    const [tab, setTab] = useState(view ? 1 : 0);

    const showChartFunc = () => {
        setEconomicalData(economicalData);
        setView(!view);
        setTab(!view ? 1 : 0);
    };

    return (
        <StyledTableContainer id={id}>
            <Stack direction='row' spacing={4} alignItems='center' alignContent='space-between' sx={{ mb: '1rem' }}>
                <Typography sx={{ flexGrow: 1, p: '1rem 0 0 0', mb: '1rem', fontWeight: 500, fontSize: '1.2rem' }}>
                    {intl.formatMessage({ id: 'business.plan.economical.table.title' })}
                </Typography>
                <ChartToggleTabs tab={tab} setTab={showChartFunc} />
            </Stack>
            <Box sx={{ display: view && 'none' }}>
                <EconomicalChart data={economicalData} />
            </Box>
            <Grid sx={{ display: !view && 'none' }} container flexWrap='nowrap'>
                <Grid item xs sx={{ mr: '1rem', ml: '1rem', minWidth: '15rem' }}>
                    {renderKeys.map((name, itemIndex) => {
                        return (
                            <StyledEconomicalInfo
                                key={name + itemIndex}
                                sx={{
                                    fontWeight: 500
                                }}
                            >
                                {intl.formatMessage({ id: `business.plan.economical.table.${name}` })}
                            </StyledEconomicalInfo>
                        );
                    })}
                </Grid>
                {data.map((field, index) => {

                    return (
                        <Grid item xs key={index}
                            sx={{
                                mr: '1rem',
                            }}>
                            {renderKeys.map((item, itemIndex) => {
                                const uniqueKey = item + field[item] + itemIndex;

                                if (item === 'year') {
                                    return (
                                        <React.Fragment key={uniqueKey}>
                                            <Typography
                                                sx={{
                                                    fontWeight: field.year === currentYear ? 700 : 500,
                                                    textDecoration: field.year === currentYear ? 'underline' : 'none',
                                                }}
                                            >
                                                {field[item]}
                                            </Typography>
                                        </React.Fragment>
                                    );
                                }

                                return (
                                    <React.Fragment key={uniqueKey}>
                                        <StyledEconomicalInfo>
                                            {field[item] ? Math.round(field[item]) : 0}
                                        </StyledEconomicalInfo>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    );
                })}
            </Grid>
        </StyledTableContainer>
    );
};

export default EconomicalTableReadonly;
