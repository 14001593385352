import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import FormattedDate from 'components/Formatters/FormattedDate';

const UserActivationPanel = ({ loading, classes, userActivation }) => {
    const intl = useIntl();

    return (
        <>
            <Stack direction='row' spacing={2} alignItems='flex-end'>
                <Typography variant='h2' className={classes.title}>{intl.formatMessage({ id: 'userdetails.userActivation.title' })}</Typography>
                {loading && <Box sx={{ mb: 2 }}><CircularProgress size={16} sx={{ mb: 0.5 }} /></Box>}
            </Stack>
            {!loading && !userActivation || !loading && userActivation && Object.keys(userActivation).length === 0
                ? <Typography component='p'>{intl.formatMessage({ id: 'userdetails.no.userActivation' })}</Typography>
                : <>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.userActivation.isActivated' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {intl.formatMessage({ id: `admin.tables.${userActivation?.isActivated + ''}` })}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.userActivation.linkCreateTime' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={userActivation?.linkCreateTime} />
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.userActivation.activateTime' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={userActivation?.activateTime} />
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.username' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userActivation?.userId}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companylist.table.timestamp' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={userActivation?.timestamp} />
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.userActivation.isExpired' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {intl.formatMessage({ id: `admin.tables.${userActivation?.isExpired + ''}` })}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.userActivation.expirationReason' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {userActivation?.expirationReason}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.userActivation.expirationTime' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={userActivation?.expirationTime} />
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.userActivation.validUntil' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={userActivation?.validUntil} />
                            </Typography>
                        }
                    </Stack>
                </>
            }
        </>
    );
};

export default UserActivationPanel;