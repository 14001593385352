import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Skeleton, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import { default as Box, default as Grid } from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import Spinner from 'components/Spinner/Spinner';
import PanelSubtitle from 'components/Titles/PanelSubtitle';
import PanelTitle from 'components/Titles/PanelTitle';

import AiChatWrapper from 'containers/AI/AiChatWrapper';
import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';

import AdminContentContainer from 'components/Containers/AdminContentContainer';
import CustomMenu from 'components/CustomMenu';
import CustomTooltip from 'components/CustomTooltip';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.primary.white,
		marginTop: theme.spacing(2),
	},
	iconButton: {
		padding: theme.spacing(0.5),
	},
	template: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

const AISkeleton = () => {
	return (
		<Stack direction='column' sx={{ p: '1rem' }}>
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 25 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 25 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
			<Skeleton width='100%' animation='wave' height={10} style={{ marginBottom: 6 }} />
		</Stack>
	);
};

const WysiwygEditorPanel = ({
	mainLoading,
	templatesLoading,
	value,
	defaultValue,
	title,
	templates,
	control,
	error,
	setValue,
	placeholder,
	children,
	charactersLimit,
	readonly,
	name,
	instructions,
	subtitle,
	aiEnabled,
	aiPromptTemplate,
	chatId,
	onInputBlur,
}) => {
	const classes = useStyles();
	const { hash } = useLocation();

	useScrollIntoViewWithOffset(name, hash);

	const [anchorEl, setAnchorEl] = useState(null);
	const [currentData, setCurrentData] = useState(value);
	const [aiLoading, setAILoading] = useState(false);

	const open = Boolean(anchorEl);
	const handleClick = event => setAnchorEl(event.currentTarget);
	const handleClose = content => {
		setAnchorEl(null);
		if (typeof content === 'string') setValue(name, content, { shouldValidate: true });
	};

	const setAiReturnValue = returnValue => {
		if (returnValue) {
			setValue(name, returnValue, { shouldValidate: false });
		}

		setAILoading(false);
	};

	const setOnLoadingAIFetch = loading => {
		setAILoading(loading);
	};

	return (
		<Box id={name} className={classes.root}>
			<Stack direction='row' alignItems='center'>
				<PanelTitle id={name.replaceAll('.', '-')} className='panelTitle' variant='h4' sx={{ flexGrow: 1 }}>
					{title}
				</PanelTitle>
				<Stack direction='row' alignItems='center' alignContent='flex-end'>
					<Grid container alignItems='center'>
						{templatesLoading ? (
							<Grid item>
								<Spinner size={12} />
							</Grid>
						) : (
							templates?.length > 0 && (
								<Grid item>
									<IconButton onClick={handleClick} className={classes.iconButton}>
										<ArticleOutlinedIcon />
									</IconButton>
									<CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
										{templates?.map(({ title, content }, index) => (
											<MenuItem key={index} onClick={() => handleClose(content)}>
												<Typography className={classes.template}>{title}</Typography>
											</MenuItem>
										))}
									</CustomMenu>
								</Grid>
							)
						)}
						<Grid item>
							{instructions && (
								<CustomTooltip
									title={instructions}
									placement='top'
									enterTouchDelay={0}
									leaveTouchDelay={2000}
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 200 }}
								>
									<IconButton disableRipple className={classes.iconButton}>
										<HelpOutlineIcon />
									</IconButton>
								</CustomTooltip>
							)}
							{aiEnabled && (
								<AdminContentContainer>
									{chatId && (
										<AiChatWrapper
											title={title}
											chatId={`${chatId}_${name}`}
											disabled={currentData ? false : true}
											onLoading={setOnLoadingAIFetch}
											onChanged={setAiReturnValue}
										/>
									)}
								</AdminContentContainer>
							)}
						</Grid>
					</Grid>
				</Stack>
			</Stack>
			<PanelSubtitle className={classes.subtitle}>{subtitle}</PanelSubtitle>
			{mainLoading || aiLoading ? (
				<AISkeleton />
			) : (
				<FormControlledWysiwyg
					inline={true}
					toolbar={true}
					name={name}
					control={control}
					defaultValue={currentData ? currentData : defaultValue ? defaultValue : aiPromptTemplate ? aiPromptTemplate : ''}
					error={error}
					placeholder={placeholder}
					instructions={instructions}
					charactersLimit={charactersLimit}
					minHeight={`${(placeholder?.length || 400) / 100 + 3}rem`}
					disabled={readonly || aiLoading}
					onChanged={setValue}
					onBlurAction={value => {
						setCurrentData(value);
						onInputBlur();
					}}
				/>
			)}
			{children}
		</Box>
	);
};

export default WysiwygEditorPanel;
