import { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import ConfirmUnsaved from './ConfirmUnsaved';

const CustomPrompt = ({ dirtyFields = {}, isDirty = false, translation, migration }) => {
	const history = useHistory();
	const location = useLocation();

	const [isOpen, setIsOpen] = useState(false);
	const [lastLocation, setLastLocation] = useState(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const onClose = () => setIsOpen(false);

	const shouldBlockNavigation = pathname => location.pathname === pathname ? false : true;

	const handleBlockedNavigation = nextLocation => {
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation.pathname)) {
			setIsOpen(true);
			setLastLocation(nextLocation);

			return false;
		}

		return true;
	};

	const handleConfirmNavigation = () => {
		setIsOpen(false);
		setConfirmedNavigation(true);
	};

	useEffect(() => {
		const beforeUnloadListener = event => {
			event.preventDefault();
			event.returnValue = '';
		};

		if (isDirty) {
			window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
		}

		return () => {
			window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
		};
	}, [isDirty]);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			history.push(lastLocation.pathname);
		}
	}, [confirmedNavigation, lastLocation, history]);

	return (
		<>
			<Prompt when={isDirty} message={handleBlockedNavigation} />
			<ConfirmUnsaved
				open={isOpen}
				handleCancel={onClose}
				handleConfirm={handleConfirmNavigation}
				dirtyFields={dirtyFields}
				translation={translation}
				migration={migration}
			/>
		</>
	);
};

export default CustomPrompt;
