import * as yup from 'yup';

export const maxLength = 30000;

export const getFinancialPlanSchema = (intl) => {
    const schema = yup.object().shape({
        internalInformation: yup
            .string()
            .max(maxLength, intl.formatMessage({ id: 'financial.plan.max.length' }, { value: maxLength })),
        base: yup
            .string()
            .required(`${intl.formatMessage({ id: 'timeline.financeplan.base' })} ${intl.formatMessage({ id: 'common.required' })}`)
            .max(maxLength, intl.formatMessage({ id: 'financial.plan.max.length' }, { value: maxLength })),
        expertComments: yup
            .string()
            .required(`${intl.formatMessage({ id: 'timeline.financeplan.expertComments' })} ${intl.formatMessage({ id: 'common.required' })}`)
            .max(maxLength, intl.formatMessage({ id: 'financial.plan.max.length' }, { value: maxLength })),
        precondition: yup
            .string()
            .required(`${intl.formatMessage({ id: 'timeline.financeplan.precondition' })} ${intl.formatMessage({ id: 'common.required' })}`)
            .max(maxLength, intl.formatMessage({ id: 'financial.plan.max.length' }, { value: maxLength })),
        notice: yup
            .string()
            .required(`${intl.formatMessage({ id: 'timeline.financeplan.notice' })} ${intl.formatMessage({ id: 'common.required' })}`)
            .max(maxLength, intl.formatMessage({ id: 'financial.plan.max.length' }, { value: maxLength })),
        followup: yup
            .string()
            .required(`${intl.formatMessage({ id: 'timeline.financeplan.followup' })} ${intl.formatMessage({ id: 'common.required' })}`)
            .max(maxLength, intl.formatMessage({ id: 'financial.plan.max.length' }, { value: maxLength }))
    });

    return schema;
};
