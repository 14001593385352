import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import { HtmlParserComponent } from 'components/HtmlParser/HtmlParser';
import { closeDialog, isDialogOpen } from 'containers/Marketplace/dialogSlice';
import { FormattedMessage, useIntl } from 'react-intl';

const DataPrivacyStatementDialog: React.FC = () => {
	const dispatch = useDispatch();
	const intl = useIntl();

	const dialogOpen = useSelector(isDialogOpen('DataPrivacyStatementDialog'));

	const handleDialogClose: any = React.useCallback(() => {
		dispatch(closeDialog({ name: 'DataPrivacyStatementDialog' }));
	}, [dispatch]);

	return (
		<Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth='lg' fullWidth scroll='paper'>
			<DialogTitle sx={{ p: '1rem 2rem' }}>
				<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
					<FormattedMessage id='data.privacy.statement.title' />
					<IconButton aria-label='close' sx={{ marginRight: '-0.8rem' }} onClick={handleDialogClose}>
						<CloseIcon />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent sx={{ p: '1rem 2rem' }}>
				{/* 
				// @ts-ignore */}
				<HtmlParserComponent html={intl.formatMessage({ id: 'data.privacy.statement.text' })} />
			</DialogContent>
			<DialogActions>
				{/* @ts-ignore */}
				<Button onClick={handleDialogClose} variant='' color='primary'>
					<FormattedMessage id='data.privacy.statement.close' />
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DataPrivacyStatementDialog;
