import PageSpinner from 'components/Spinner/PageSpinner';

import { yupResolver } from '@hookform/resolvers/yup';
import ContentContainer from 'components/Containers/ContentContainer';
import { getDirtyKeys } from 'components/Form/utils/utils';
import { useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import ProjectContainer from 'containers/Projects/Project/ProjectContainer/ProjectContainer';
import ProjectForm from 'containers/Projects/Project/ProjectForm';
import ProjectSaveButtonContainer from 'containers/Projects/Project/ProjectForm/ProjectSaveButtonContainer';
import { getProjectSchema } from 'containers/Projects/Project/ProjectForm/ProjectValidationSchema';
import { getInitialProject } from 'containers/Projects/Project/ProjectPlan/ProjectPlan';
import { useFetchTimelineQuery, useUpsertProjectPromptMutation } from 'containers/Projects/projectRTKApi';
import { isNil } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Instrument, Project } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import getCompanyLink from 'utils/auth/getCompanyLink';

const FormWrapper = ({ instruments, timeline }: { instruments: Instrument[]; timeline: any }) => {
	const dispatch = useDispatch();
	const companyId = getCompanyId();
	const intl = useIntl();
	const schema = getProjectSchema(intl);
	const project = getInitialProject({ timelineId: timeline.timelineId, companyId });
	const history = useHistory();
	const location = useLocation();

	const [outSideButtonLoading, setOutSideButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [upsertProjectPrompt, { isLoading: isUpsertLoading }] = useUpsertProjectPromptMutation();

	const onSubmit = async (data: Project, projectSaveFunction: any, outsideButton = false) => {
		if (!projectSaveFunction) {
			toast.error(<FormattedMessage id='project.saving.failed' />);
			return;
		}

		outsideButton ? setOutSideButtonLoading(true) : setIsLoading(true);

		const storedProject = await dispatch(projectSaveFunction(data));

		// @ts-ignore
		if (data.projectPrompt && isAdmin && storedProject?.projectId) {
			try {
				await upsertProjectPrompt({
					companyId: companyId,
					projectId: storedProject?.projectId, // @ts-ignore
					prompt: data.projectPrompt, // @ts-ignore
					draft: data.draft,
				}).unwrap();
				toast.success('Hakemuksen perustiedot päivitetty');
			} catch (error) {
				toast.error('Hakemuksen perustiedot päivitys epäonnistui');
			}
		}

		setOutSideButtonLoading(false);
		setIsLoading(false);

		if (storedProject) {
			toast.success(<FormattedMessage id='project.saving.successed' />);
			outSideButtonLoading && setOutSideButtonLoading(false);
			history.push(getCompanyLink(`/projects/${storedProject.projectId}/plan`, location));

			return;
		}

		toast.error(<FormattedMessage id='project.saving.failed' />);
	};

	const {
		control,
		register,
		unregister,
		setValue,
		getValues,
		watch,
		handleSubmit,
		reset,
		formState: { dirtyFields, errors },
	} = useForm<Project>({
		defaultValues: project,
		resolver: async (data, context, options) => {
			return yupResolver(schema)(data, context, options);
		},
	});

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	return (
		<>
			{/* @ts-ignore */}
			<ContentContainer>
				<ProjectContainer
					useStickyHeader
					project={project}
					isLocalBackup={false}
					deleteLocalBackup={() => {}}
					hideDropdownMenu
					actions={
						<ProjectSaveButtonContainer
							{...{
								extraButtons: undefined,
								onSubmit,
								handleSubmit,
								isDirty,
								loading: outSideButtonLoading,
								state: project?.projectState,
								isLocalBackup: false,
							}}
						/>
					}
				></ProjectContainer>
				{/* @ts-ignore */}
				<FormProvider
					{...{
						control,
						register,
						unregister,
						setValue,
						getValues,
						watch,
						handleSubmit,
						reset,
					}}
				>
					<ProjectForm
						readonly={false}
						isLoading={isLoading}
						isDeleting={false}
						isDirty={false}
						dirtyFields={dirtyFields}
						project={project}
						instruments={instruments}
						handleSubmit={handleSubmit}
						control={control}
						register={register}
						unregister={unregister}
						watch={watch}
						errors={errors}
						setValue={setValue}
						onSubmit={onSubmit}
						showExtended={false}
						noMargin={true}
						summary={true}
						closeModal={null}
						onSuccess={() => {}}
						reset={reset}
						getValues={getValues}
					/>
				</FormProvider>
			</ContentContainer>
		</>
	);
};

const CreateProjectPage = ({ passPageProps }: any) => {
	const companyId = getCompanyId();

	const {
		data: timeline,
		isLoading: isTimelineLoading,
		isFetching: isTimelineFetching,
	} = useFetchTimelineQuery({ companyId }, { skip: !companyId });
	const timelineLoading = isTimelineLoading || isTimelineFetching;

	const { data: instruments, isLoading: isInstrumentsLoading } = useFetchInstrumentsByCompanyQuery(
		{ companyId },
		{ skip: isNil(companyId) }
	);

	const loading = isInstrumentsLoading ?? false;

	const showLoader = loading || timelineLoading;

	if (showLoader || loading) return <PageSpinner />;

	const project = getInitialProject({ timelineId: timeline[0].timelineId, companyId });

	return <FormWrapper {...{ instruments: instruments ?? [], timeline: timeline[0] }} />;
};

export default CreateProjectPage;
