import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toInteger } from 'lodash';

export const sliceDataToPage = (page: number, rowsPerPage: number, data: any[]) => {
	const start = (page - 1) * rowsPerPage;

	const pagedData = data ? data?.slice(start, start + rowsPerPage) : [];

	return pagedData;
};

export interface PaginatorState {
	page: number;
	rowsPerPage: number;
	pagedData: any[];
	data: any[];
}

export interface Action {
	type: string;
	payload?: any;
}

export const initialState: PaginatorState = {
	page: 1,
	rowsPerPage: 20,
	pagedData: [],
	data: [],
};

const paginatorSlice = createSlice({
	name: 'paginator',
	initialState,
	reducers: {
		init(state) {
			const { page, rowsPerPage, pagedData, data } = state;

			return {
				page,
				rowsPerPage,
				pagedData,
				data,
			};
		},
		setData(state, action: PayloadAction<any[]>) {
			state.data = action.payload;
		},
		setPage(state, action: PayloadAction<number>) {
			const { payload: page } = action;

			state.page = toInteger(page);
			state.pagedData = sliceDataToPage(toInteger(page), state.rowsPerPage, state.data);
		},
		setRowsPerPage(state, action: PayloadAction<number>) {
			state.rowsPerPage = toInteger(action.payload);
		},
		setPagedData(state, action: PayloadAction<any[]>) {
			state.pagedData = action.payload;
		},
	},
});

export const { init, setData, setPage, setPagedData, setRowsPerPage } = paginatorSlice.actions;

export default paginatorSlice.reducer;
