import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import BoxContainer from 'components/Containers/BoxContainer';
import CustomPrompt from 'components/CustomPrompt';
import Spinner from 'components/Spinner/Spinner';
import Subtitle from 'components/Titles/Subtitle';
import ProjectFormBase from './ProjectFormBase';
import ProjectFormExtended from './ProjectFormExtended';
import ProjectFormExtendedTemplate from './ProjectFormExtendedTemplate/ProjectFormExtendedTemplate';

import Attachments from 'components/Attachments';
import CollaborationLauncher from 'containers/Collaboration/CollaborationLauncher';
import ProjectSupplementaryList from 'containers/Projects/Project/ProjectSupplementaryList/ProjectSupplementaryList';
import { projectsApi, useLazyFetchInstumentTemplateQuery } from 'containers/Projects/projectRTKApi';
import { Instrument } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import { isAdminToken } from '../../../../utils/auth/token';
import AttachmentWidget from '../ProjectDashboard/AttachmentWidget';
import FinanceTable from './ProjectFinancials/FinanceTable';
import ProjectTaskGroupForm from './ProjectTaskGroups/ProjectTaskGroupForm';

const ProjectForm: React.FC<any> = ({
	surrogate,
	isLoading,
	project,
	instruments,
	onSubmit,
	handleSubmit,
	control,
	register,
	unregister,
	setValue,
	watch,
	errors,
	setDynamicValidationTemplate,
	showExtended,
	noMargin,
	setInstruction,
	dirtyFields,
	isDirty = false,
	outSideButtonLoading,
	isAddingSubmit,
	readonly,
	getValues,
	chatId,
	onInstrumentChange = null,
	updateLocalBackup = () => {},
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const companyId = getCompanyId();
	const isAdmin = isAdminToken();

	const [instrument, setInstrument] = useState<Instrument | undefined>(undefined);

	useEffect(() => {
		register('files');

		return () => {
			unregister('files');
		};
	}, [register, unregister]);

	const [templateSearch, { data: template, isLoading: isTemplateLoading, isFetching: isTemplateFetching, status }] =
		useLazyFetchInstumentTemplateQuery();
	const templateLoading = isTemplateLoading || isTemplateFetching;

	const changeInstrument = async (id: string) => {
		const instrument = instruments?.find((item: any) => item?.id === id);

		await templateSearch({ instrumentId: instrument?.instrumentTemplateId ?? id });
		setValue('instrumentId', id);

		if (onInstrumentChange) {
			// @ts-ignore
			onInstrumentChange(id);
		}
	};

	useEffect(() => {
		if (!project?.instrumentId || (template && template.instrumentId === project?.instrumentId)) return;

		const instrument = instruments?.find((item: any) => item?.id === project?.instrumentId);
		setInstrument(instrument);

		const fetchData = async () => {
			await templateSearch({
				instrumentId: instrument?.instrumentTemplateId ?? project.instrumentId,
				templateVersion: instrument?.instrumentTemplateId ? null : project.instrumentTemplateVersion,
			});

			const prompt = isAdmin
				? await dispatch(
						projectsApi.endpoints.fetchProjectPrompt.initiate({
							companyId,
							projectId: project.projectId,
							draft: true,
						})
				  )
				: null;

			// @ts-ignore
			if (prompt?.data) {
				// @ts-ignore
				setValue('projectPrompt', prompt?.data?.projectPrompt);
			}
		};

		fetchData().catch(console.error);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project?.instrumentId]);

	const loading = templateLoading === true || isLoading; // @ts-ignore
	const showProjectPlan = template?.showProjectPlan || (templateLoading === false && !template);

	return (
		<>
			<form noValidate onSubmit={handleSubmit((data: any) => onSubmit(data))}>
				{!outSideButtonLoading && !isAddingSubmit && (
					<CustomPrompt dirtyFields={dirtyFields} isDirty={isDirty} translation='project.form' migration={undefined} />
				)}
				{!noMargin && (
					<Stack direction='row' alignContent='space-between' alignItems='center'>
						<Subtitle id='projectFormBase' className='panelTitle' mt={0} mb={0} padding={'0'} sx={{ flexGrow: 1 }}>
							{intl.formatMessage({ id: 'project.form.base.title' })}
						</Subtitle>
						<CollaborationLauncher
							linkTitle={intl.formatMessage({ id: 'project.form.base.title' })}
							link={`projects/${project.projectId}/plan/projectFormBase`}
							sx={{ m: 1 }}
							disabled={undefined}
						/>
					</Stack>
				)}
				<BoxContainer sx={{ mt: noMargin ? 0 : 2 }}>
					<ProjectFormBase
						register={register}
						setValue={setValue}
						unregister={unregister}
						watch={watch}
						project={project}
						instruments={instruments}
						control={control}
						errors={errors}
						getValues={getValues}
						summary={!showExtended}
						setInstruction={setInstruction}
						changeInstrument={changeInstrument}
						readonly={readonly}
						updateLocalBackup={updateLocalBackup}
					/>
				</BoxContainer>
				{showExtended && !surrogate && (
					<>
						{loading ? (
							<Spinner size={16} mt={3} />
						) : (
							<>
								<ProjectFormExtendedTemplate
									setDynamicValidationTemplate={setDynamicValidationTemplate}
									project={project}
									control={control}
									register={register}
									unregister={unregister}
									setValue={setValue}
									errors={errors}
									setInstruction={setInstruction}
									template={template} // @ts-ignore
									readonly={readonly}
									getValues={getValues}
									chatId={chatId}
									updateLocalBackup={updateLocalBackup}
								/>
								{showProjectPlan && (
									<ProjectFormExtended
										project={project}
										control={control}
										register={register}
										unregister={unregister} // @ts-ignore
										setValue={setValue}
										getValues={getValues}
										errors={errors}
										setInstruction={setInstruction}
										showExtended={showExtended}
										readonly={readonly}
										updateLocalBackup={updateLocalBackup}
									/>
								)}
							</>
						)}
					</>
				)}
				{showExtended && !surrogate && project?.projectId && (
					<>
						<Subtitle id='costs' className='panelTitle' mt='2rem' mb='2rem' padding={'0'} sx={undefined}>
							{intl.formatMessage({ id: 'project.costs' })}
						</Subtitle>

						<ProjectTaskGroupForm {...{ setValue, errors, control, watch, project, instrument }} />
					</>
				)}
				{showExtended && !surrogate && project?.projectId && (
					<>
						<Subtitle id='finance' className='panelTitle' mt='2rem' mb='2rem' padding={'0'} sx={undefined}>
							{intl.formatMessage({ id: 'project.finance' })}
						</Subtitle>

						<FinanceTable project={project} control={control} />
					</>
				)}
			</form>
			{showExtended && !surrogate && (
				<>
					{project?.projectId && (
						<>
							<Subtitle id='supplementaries' className='panelTitle' mt='2rem' mb={0} padding={'0'} sx={undefined}>
								{intl.formatMessage({ id: 'project.supplementaries' })}
							</Subtitle>
							<BoxContainer sx={{ mt: noMargin ? 0 : 2, p: 0 }}>
								<ProjectSupplementaryList project={project} hideTitle={true} />
							</BoxContainer>
						</>
					)}
					<Subtitle id='attachments' className='panelTitle' mt={'2rem'} mb={0} padding={'0'} sx={undefined}>
						{intl.formatMessage({ id: 'project.form.attachments' })}
					</Subtitle>
					<BoxContainer sx={{ mt: noMargin ? 0 : 2 }}>
						{!project?.projectId ? ( // @ts-ignore
							<Attachments setValue={setValue} formLabel={false} />
						) : (
							<AttachmentWidget attachments={project?.attachments} projectId={project?.projectId} hideTitle={true} />
						)}
					</BoxContainer>
				</>
			)}
		</>
	);
};

export default ProjectForm;
