import { yupResolver } from '@hookform/resolvers/yup';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/system/Stack';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { FormControlledInput } from 'components/Form';
import Spinner from 'components/Spinner/Spinner';
import { useState } from 'react';

const AddComment = ({ conversationId, link, addComment, addConversation, focused, sx }) => {
	const intl = useIntl();
	const createCommentLoading = useSelector(state => state.collaboration?.createCommentLoading);
	const createConversationLoading = useSelector(state => state.collaboration?.createConversationLoading);
	const maxLength = 1000;

	const [isActive, setisActive] = useState(false);

	const schema = yup.object().shape({
		comment: yup
			.string()
			.max(maxLength, `${intl.formatMessage({ id: 'collaboration.validation.max.length' }, { value: maxLength })}`)
			.required(`${intl.formatMessage({ id: 'collaboration.comment' })} ${intl.formatMessage({ id: 'message.isRequired' })}`),
	});

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: {
			comment: '',
		},
		resolver: yupResolver(schema),
	});

	const onSubmit = ({ comment }) => {
		reset();
		conversationId ? addComment(conversationId, comment) : addConversation(link, comment);
	};

	return (
		<Stack direction='row' spacing={1.5} alignItems='center' sx={{ width: '100%', ...sx }}>
			<form noValidate onSubmit={handleSubmit(data => onSubmit(data))} style={{ width: '100%', backgroundColor: '#fff' }}>
				<FormControlledInput
					isActiveFunc={setisActive}
					defaultValue=''
					name='comment'
					placeholder={
						conversationId
							? intl.formatMessage({ id: 'collaboration.leave.comment' })
							: intl.formatMessage({ id: 'collaboration.new.conversation' })
					}
					control={control}
					error={errors?.comment?.message}
					focused={focused}
					rows={!isActive ? undefined : 2}
					endAdornment={
						<InputAdornment position='end'>
							<IconButton type='submit' edge='end' disabled={!isDirty} sx={{ color: 'secondary.grey700' }}>
								{createCommentLoading === conversationId || createConversationLoading ? (
									<Spinner size={16} />
								) : (
									<ArrowCircleUpIcon />
								)}
							</IconButton>
						</InputAdornment>
					}
				/>
			</form>
		</Stack>
	);
};

export default AddComment;
