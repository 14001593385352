import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/styles';
import { useState } from 'react';

export const StyledTextField = styled(TextField)(({ theme }) => ({
	'& input, & textarea': {
		fontSize: '1rem',
		resize: 'vertical',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		height: 'unset',
	},
}));

/**
 * Controlled Textfield, dependencies hoook-form, mui
 *
 * @param {*} defaultValue
 * @param {*} name
 * @param {*} control
 * @param {*} error
 * @param {*} label
 * @param {*} rows
 * @returns
 */
/**
 *
 * @param {error} errror parameter
 * @param  {...any} other
 * @returns
 */

const FormStyledTextfield = ({
	type = 'text',
	variant = 'outlined',
	value,
	onChange,
	error,
	placeholder,
	icon,
	endAdornment,
	startAdornment,
	rows,
	maxRows,
	size,
	disabled,
	step = null,
	postalCode = false,
	showDisabledAsText = false,
	focused = false,
	...props
}) => {
	const hasError = error ? true : false;
	const multiline = rows ? true : false;
	const [showPassword, setShowPassword] = useState(false);

	const handleClick = () => setShowPassword(prevState => !prevState);
	const handleMouseDown = event => event.preventDefault();

	if (disabled && showDisabledAsText) return value;

	return (
		<StyledTextField
			{...props}
			type={type && showPassword ? 'text' : type}
			variant={variant}
			size={size}
			fullWidth
			value={value ?? ''}
			onChange={onChange}
			error={hasError}
			multiline={multiline}
			placeholder={placeholder}
			icon={icon}
			minRows={rows ?? 5}
			maxRows={maxRows ?? rows * 10 ?? 10}
			disabled={disabled}
			InputProps={{
				...props.InputProps,
				startAdornment: startAdornment ? startAdornment : icon && <InputAdornment position='start'>{icon}</InputAdornment>,
				endAdornment:
					endAdornment ||
					(type === 'password' && (
						<InputAdornment position='end'>
							<IconButton
								aria-label='toggle password visibility'
								onClick={handleClick}
								onMouseDown={handleMouseDown}
								edge='end'
								color='primary'
								disabled={disabled}
							>
								{showPassword ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
							</IconButton>
						</InputAdornment>
					)),
			}}
			inputProps={{
				step: type === 'number' && !step ? 0.01 : type === 'number' && step ? step : undefined,
				min: type === 'number' ? 0 : undefined,
				max: postalCode ? 99999 : undefined,
			}}
			autoFocus={focused} // eslint-disable-line
		/>
	);
};

export default FormStyledTextfield;
