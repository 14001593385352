import * as React from 'react';
import { Box, BoxProps, CircularProgress, Link, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { isArray } from 'lodash';

import { handleFiles } from 'utils/helpers/fileHelper';
import { Attachment } from 'types/commonTypes';

import AttachFile from 'icons/AttachFile';

type Props = {
	loading?: boolean;
	options?: DropzoneOptions;
	onDrop?: (attachments: Attachment[]) => void;
} & Omit<BoxProps, 'onDrop'>;

const AttachmentDropZone = React.forwardRef<any, Props>(({ sx, loading, options, onDrop, ...otherProps }, ref) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		...options,
		onDrop: async (acceptedFiles, rejectedFiles, event) => {
			options?.onDrop?.(acceptedFiles, rejectedFiles, event);
			const attachments = await handleFiles(acceptedFiles);
			onDrop?.(attachments);
		},
	});

	return (
		<Box
			{...otherProps}
			{...getRootProps()}
			ref={ref}
			sx={[
				{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					borderWidth: 1,
					borderStyle: 'dashed',
					borderColor: 'divider',
					borderRadius: 1,
					paddingY: 2,
					paddingX: 1,
					cursor: 'pointer',
					backgroundColor: isDragActive ? 'background.default' : 'transparent',
					minHeight: '125px',
				},
				...(isArray(sx) ? sx : [sx]),
			]}
		>
			<Box component='input' {...getInputProps()} />
			{loading ? (
				<CircularProgress />
			) : (
				<>
					<AttachFile sx={{ fontSize: '2rem' }} />
					<Typography variant='subtitle1' sx={{ mt: 2, textAlign: 'center' }}>
						<Link sx={{ marginRight: 0.5 }} underline='hover'>
							<FormattedMessage id='dropZone.link' />
						</Link>
						<FormattedMessage id='dropZone.text' />
					</Typography>
					<Typography variant='body2' color='text.muted'>
						<FormattedMessage id='dropZone.limit' />
					</Typography>
				</>
			)}
		</Box>
	);
});

export default AttachmentDropZone;
