import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { Alert, Button, Collapse } from '@mui/material';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
	isLocalBackup: boolean;
	deleteLocalBackup: () => void;
};

const LocalBackupAlert: React.FC<Props> = ({ isLocalBackup, deleteLocalBackup }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const [confirmOpen, setConfirmOpen] = React.useState(false);

	const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> | undefined = event => {
		setConfirmOpen(true);
		setAnchorEl(event?.currentTarget ?? null);
	};

	const handleConfirmAction = () => {
		deleteLocalBackup();
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
		setConfirmOpen(false);
	};

	return (
		<>
			<ConfirmPopper
				confirmAction={handleConfirmAction}
				handleClose={handleClose}
				isopen={confirmOpen}
				anchorElement={anchorEl}
				confirmText={<FormattedMessage id='localBackup.confirmRemove.submit' />}
				cancelText={<FormattedMessage id='localBackup.confirmRemove.cancel' />}
				confirmBodyText={<FormattedMessage id='localBackup.confirmRemove.text' />}
			/>
			<Collapse in={isLocalBackup}>
				<Alert
					severity='info'
					sx={{ mb: 2 }}
					action={
						<Button color='inherit' onClick={handleDeleteClick} startIcon={<DeleteForeverIcon />} sx={{ fontSize: '0.857rem' }}>
							<FormattedMessage id='localBackup.button.remove' />
						</Button>
					}
				>
					<FormattedMessage id='localBackup.saved.text' />
				</Alert>
			</Collapse>
		</>
	);
};

export default LocalBackupAlert;
