import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import ContentContainer from 'components/Containers/ContentContainer';
import DataGrid from 'components/DataGrid';
import FormattedDate from 'components/Formatters/FormattedDate';
import { capitalizeFirstLetter } from 'utils/converters/capitalizeFirstLetter';
import LicenseSearchForm from './LicenseSearchForm/LicenseSearchForm';
import MainTitle from 'components/Titles/MainTitle';

const useStyles = makeStyles(theme => ({
    cell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.primary.main,
    },
    companyName: {
        fontWeight: 600,
    },
    noCompanies: {
        marginTop: theme.spacing(4),
    },
    detailsButton: {
        '&:hover': {
            background: theme.palette.secondary.main,
        }
    }
}));

const CompanyList = () => {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();

    const licensesState = useSelector(state => state.licenses);

    const licenses = licensesState?.licenses ?? [];
    const loading = licensesState?.loading;
    const error = licensesState?.companyDetailsError;
    const initialLicenseActiveStatus = licensesState?.licenseActiveStatus ?? '';
    const initialLicenseType = licensesState?.licenseType ?? '';

    const fetchCompanyDetails = companyId => history.push(`/admin/company/${companyId}`);

    const columns = [
        {
            field: 'companyId',
            headerName: intl.formatMessage({ id: 'companylist.table.id' }),
            headerClassName: 'styled-header',
            width: 150,
            disableClickEventBubbling: true,
            renderCell: params => (
                <>
                    {params.row.companyId}
                </>
            ),
            disableColumnSelector: true,
        },
        {
            field: 'companyName',
            headerName: intl.formatMessage({ id: 'companylist.table.name' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={`${classes.cell} ${classes.companyName}`}>{params.row.companyName ? params.row.companyName : ''}</span>
            ),
        },
        // {
        //     field: 'companyDetails',
        //     disableColumnMenu: true,
        //     sortable: false,
        //     headerName: intl.formatMessage({ id: 'companylist.table.companyDetails' }),
        //     headerClassName: 'styled-header',
        //     width: 150,
        //     disableColumnSelector: true,
        //     disableClickEventBubbling: true,
        //     renderCell: params => (
        //         <Button
        //             className={classes.detailsButton}
        //             variant='outlined'
        //             size='small'
        //             color='primary'
        //             disabled={loading || !params.row.companyId || params.row.companyId === 'undefined'}
        //             onClick={() => fetchCompanyDetails(params.row.companyId)}
        //         >
        //             {intl.formatMessage({ id: 'companylist.table.companyDetails.link' })}
        //         </Button>
        //     ),
        // },
        {
            field: 'type',
            headerName: intl.formatMessage({ id: 'companylist.table.type' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>{capitalizeFirstLetter(params.row.type)}</span>
            ),
        },
        {
            field: 'status',
            headerName: intl.formatMessage({ id: 'companylist.table.status' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>{params.row.isActive ? 'Active' : params.row.isExpired ? 'Expired' : params.row.isTerminated ? 'Terminated' : ''}</span>
            ),
        },
        {
            field: 'startDate',
            type: 'date',
            headerName: intl.formatMessage({ id: 'companylist.table.startDate' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.startDate} />
                </span>
            ),
        },
        {
            field: 'startWeek',
            // type: 'date', --> TODO: check this filtering
            headerName: intl.formatMessage({ id: 'companylist.table.startWeek' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>{params.row.startWeek}</span>
            ),
        },
        {
            field: 'author',
            headerName: intl.formatMessage({ id: 'companylist.table.author' }),
            headerClassName: 'styled-header',
            width: 350,
            disableClickEventBubbling: true,
            disableColumnSelector: true,
            renderCell: params => (
                <>
                    {params.row.author ?? ''}
                </>
            ),
        },
        {
            field: 'comments',
            headerName: intl.formatMessage({ id: 'companylist.table.comments' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={classes.cell}>{params.row.comments}</span>
            ),
        },
        {
            field: 'endDate',
            type: 'date',
            headerName: intl.formatMessage({ id: 'companylist.table.endDate' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.endDate} />
                </span>
            ),
        },
        {
            field: 'terminationDate',
            type: 'date',
            headerName: intl.formatMessage({ id: 'companylist.table.terminationDate' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.terminationDate} />
                </span>
            ),
        },
        {
            field: 'terminateAuthor',
            headerName: intl.formatMessage({ id: 'companylist.table.terminateAuthor' }),
            headerClassName: 'styled-header',
            width: 350,
            disableClickEventBubbling: true,
            disableColumnSelector: true,
            renderCell: params => (
                <span className={classes.cell}>{params.row.terminateAuthor}</span>
            ),
        },
        {
            field: 'isActive',
            headerName: intl.formatMessage({ id: 'companylist.table.isActive' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.isActive + ''}` })}
                </span>
            ),
        },
        {
            field: 'isExpired',
            headerName: intl.formatMessage({ id: 'companylist.table.isExpired' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.isExpired + ''}` })}
                </span>
            ),
        },
        {
            field: 'isTerminated',
            headerName: intl.formatMessage({ id: 'companylist.table.isTerminated' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {intl.formatMessage({ id: `admin.tables.${params.row.isTerminated + ''}` })}
                </span>
            ),
        },
        {
            field: 'timestamp',
            type: 'date',
            headerName: intl.formatMessage({ id: 'companylist.table.timestamp' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.timestamp} />
                </span>
            ),
        }
    ];

    const data = licenses?.map(item => ({
        ...item,
        id: item.rowKey,
        startWeek: moment.utc(item.startDate).format('w / YYYY'),
    }));

    return (
        <ContentContainer>
            <MainTitle>{intl.formatMessage({ id: 'admin.drawer.companylist' })}</MainTitle>

            {loading !== undefined && <LicenseSearchForm {...{ initialLicenseActiveStatus, initialLicenseType, loading }} />}
            {loading || !loading && data && data.length > 0
                ? (
                    <DataGrid
                        sx={{
                            '&:hover': { cursor: 'pointer' }
                        }}
                        loading={loading}
                        data={data}
                        columns={columns}
                        paginationMode='client'
                        mt={1}
                        onRowClick={(params) => fetchCompanyDetails(params.row.companyId)}
                    />
                )
                : error
                    ? (
                        <Box className={classes.noCompanies}>
                            <Typography component='p' color='primary'>
                                {intl.formatMessage({ id: 'companylist.error' })}:
                            </Typography>
                            <Typography component='p' color='primary' sx={{ fontWeight: 600 }}>
                                {error.status} {error.data?.content ?? ''}
                            </Typography>
                        </Box>
                    )
                    : (
                        <Box className={classes.noCompanies}>
                            <Typography component='p' color='primary'>
                                {intl.formatMessage({ id: 'companylist.no.companies' })}
                            </Typography>
                        </Box>
                    )}
        </ContentContainer>
    );
};

export default CompanyList;
