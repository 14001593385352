import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { useIntl } from 'react-intl';

import DataItem from './DataItem';
import Progress from './Progress';
import Tags from './Tags';
import TeamAvatars from './TeamAvatars';

import { getCompanyPath } from 'utils/auth/getCompanyPath';

const BusinessPlanWidget = ({ businessPlan, companyId, history, pathname, profile = false }) => {
	const intl = useIntl();
	const theme = useTheme();
	const mdUp = useMediaQuery(theme.breakpoints.up('md'));

	const businessPlanPath = profile ? '' : getCompanyPath('/businessPlan', pathname, companyId);

	const mainLoading = profile ? false : businessPlan?.main?.loading;
	const mainData = profile ? businessPlan : businessPlan?.main?.data;

	const teamMembersLoading = profile ? false : businessPlan?.teamMembers?.loading;
	const teamMembersData = profile ? businessPlan?.teamMembers : businessPlan?.teamMembers?.data;

	const valuesSum =
		!!teamMembersData?.length +
		!!mainData?.pitch +
		!!mainData?.problem +
		!!mainData?.solution +
		!!mainData?.markets +
		!!mainData?.globalMarket +
		!!mainData?.vision;

	const progressValue = Math.round((valuesSum * 100) / 7);

	return (
		<Box
			display='flex'
			sx={{
				flexDirection: 'column',
				color: 'white',
				height: '100%',
				maxHeight: mdUp ? '509.5px' : '100%', // maxHeight same as Analysis height
				bgcolor: 'primary.white',
				p: 3,
			}}
		>
			<>
				<Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
					<Typography variant='h2' color='primary' sx={{ fontSize: '1.4rem', fontWeight: 700 }}>
						{intl.formatMessage({ id: 'business.plan.title' })}
					</Typography>
					{!profile && (
						<IconButton onClick={() => history.push(businessPlanPath)} sx={{ mr: -1.5 }}>
							<EditOutlinedIcon color='primary' />
						</IconButton>
					)}
				</Stack>
				<Progress value={progressValue || 0} />
				<Box
					sx={{
						mt: 3,
						mx: -1,
						px: 1,
						overflowX: 'hidden',
						overflowY: 'auto',
						'&::-webkit-scrollbar': {
							width: '5px',
						},
						'& div:first-of-type': {
							'&>p': {
								mt: 0,
							},
						},
						'& div:last-child': {
							'& hr': {
								display: 'none',
							},
						},
					}}
				>
					<Tags loading={mainLoading} tags={mainData?.tagsArray} />
					<TeamAvatars intl={intl} loading={teamMembersLoading} data={teamMembersData} />
					<DataItem intl={intl} theme={theme} title='pitch' loading={mainLoading} text={mainData?.pitch} />
					<DataItem intl={intl} theme={theme} title='problem' loading={mainLoading} text={mainData?.problem} />
					<DataItem intl={intl} theme={theme} title='solution' loading={mainLoading} text={mainData?.solution} />
					<DataItem intl={intl} theme={theme} title='markets' loading={mainLoading} text={mainData?.markets} />
					<DataItem intl={intl} theme={theme} title='globalMarket' loading={mainLoading} text={mainData?.globalMarket} />
					<DataItem intl={intl} theme={theme} title='vision' loading={mainLoading} text={mainData?.vision} />
				</Box>
			</>
		</Box>
	);
};

export default BusinessPlanWidget;
