import { useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ProjectCostList from 'containers/Projects/Project/Costs/ProjectCostList';
import ProjectPhaseTips from 'containers/Projects/Project/ProjectDashboard/ProjectPhaseTips';
import AttachmentWidget from './AttachmentWidget';
import ProjectPlanWidgets from './ProjectPlanWidgets';

import ContentContainer from 'components/Containers/ContentContainer';
import { useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import { useSelector } from 'react-redux';
import { getCompanyId } from 'utils/auth/company';
import { useScrollIntoViewWithOffset } from 'utils/helpers/useScrollIntoViewWithOffset';
import { useFetchInstumentTemplateQuery } from '../../projectRTKApi';
import { projectCostSelector, projectTaskSelector } from '../../slices';
import ProjectContainer from '../ProjectContainer/ProjectContainer';

const ProjectDashboard = ({ project }) => {
	const { id } = useParams();
	const companyId = getCompanyId();
	const { hash } = useLocation();
	const state = useSelector(state => state.kanban);

	useScrollIntoViewWithOffset('costs', hash, 30);

	const {
		data: instruments,
		isLoading: isInstrumenstLoading,
		isFetching: isInstrumenstFetching,
	} = useFetchInstrumentsByCompanyQuery({ companyId }, { skip: !companyId });

	const instrument = instruments?.find(item => item.id === project?.instrumentId);

	const instrumentId = instrument?.instrumentTemplateId ?? project.instrumentId;

	const {
		data: template,
		isLoading: isTemplateLoading,
		isFetching: isTemplateFetching,
	} = useFetchInstumentTemplateQuery(
		{
			instrumentId,
			templateVersion: instrument?.instrumentTemplateId ? null : project?.instrumentTemplateVersion,
		},
		{ skip: !id || !project || !instruments || !instrumentId }
	);

	const tasks = state.tasks ?? projectTaskSelector(project);
	const costs = state.costs ?? projectCostSelector(project);

	return (
		<ContentContainer id='general'>
			<ProjectContainer project={project}>
				<Box>
					<ProjectPhaseTips project={project} tasks={tasks} costs={costs} />
				</Box>
				<Box>
					<Grid container spacing={2} direction='row' justifyContent='space-between' alignItems='stretch'>
						<Grid item xs={12} md={7} lg={8} xl={8}>
							<ProjectPlanWidgets project={project} template={template} />
						</Grid>
						<Grid item xs={12} md={5} lg={4} xl={4}>
							<AttachmentWidget attachments={project?.attachments} projectId={id} padding={3} />
						</Grid>
					</Grid>
				</Box>
				<Box>
					<ProjectCostList project={project} tasks={tasks} costs={costs} padding={3} />
				</Box>
			</ProjectContainer>
		</ContentContainer>
	);
};

export default ProjectDashboard;
