import React from 'react';
import { styled } from '@mui/styles';
import { Typography } from '@mui/material';

const PanelSubtitle = styled(Typography)(({ theme }) => (
    {
        fontSize: '1rem',
        color: theme.palette.secondary.grey600,
        marginBottom: theme.spacing(2),
        fontStyle: 'italic',
    }
));

export default function ({ children }) {
    return (
        <PanelSubtitle>{children}</PanelSubtitle>
    );
}