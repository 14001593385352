import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UserTable from './UserTable';
import ContentContainer from 'components/Containers/ContentContainer';
import MainTitle from 'components/Titles/MainTitle';
import SelectFilter from 'components/SelectFilter';

import { getUsersActiveStatus } from '../userSlice';

const UserList = () => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const admin = useSelector(state => state.users);

    const usersLoading = admin?.usersLoading;
    // const usersError = admin?.usersError;
    const users = admin?.users ?? [];
    const initialUserActiveStatus = admin?.userActiveStatus ?? '';

    const [currentStatus, setCurrentStatus] = useState(initialUserActiveStatus);

    const fetchUsers = userActiveStatus => dispatch(getUsersActiveStatus(userActiveStatus));

    const fetchUserDetails = userId => history.push(`/admin/user/${userId}`);

    return (
        <ContentContainer>
            <MainTitle>{intl.formatMessage({ id: 'admin.drawer.userlist' })}</MainTitle>
            <Box sx={{ width: '10rem' }}>
                <SelectFilter
                    values={['all', 'active', 'inactive']}
                    currentValue={currentStatus || initialUserActiveStatus}
                    setCurrentValue={value => {
                        setCurrentStatus(value);
                        fetchUsers(value);
                    }}
                    label='userList'
                />
            </Box>
            {usersLoading || (!usersLoading && users && users.length > 0) ? (
                <UserTable
                    loading={usersLoading}
                    users={users}
                    fetchUserDetails={fetchUserDetails}
                />
            ) : (
                <Box sx={{ mt: 4 }}>
                    <Typography component='p' color='primary'>
                        {intl.formatMessage({ id: 'userlist.no.users' })}
                    </Typography>
                </Box>
            )}
        </ContentContainer >
    );
};

export default UserList;
