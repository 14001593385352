import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';

import Button from 'components/Button/Button';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ModalDialog from 'components/Dialog/ModalDialog';

import { getDirtyKeys } from 'components/Form/utils/utils';

const ConfirmUnsaved = ({ open, handleCancel, handleConfirm, dirtyFields = {}, translation = '', migration = false }) => {
	const intl = useIntl();

	const dirtyKeys = getDirtyKeys(dirtyFields);

	return (
		<ModalDialog
			maxWidth='sm'
			open={open}
			onClose={handleCancel}
			title={intl.formatMessage({ id: 'custom.prompt.dialog.title' })}
			actions={
				<LeftRightContainer
					left={
						<Button variant='text' color='primary' onClick={handleCancel}>
							{intl.formatMessage({ id: 'shared.cancel' })}
						</Button>
					}
					right={
						<Button variant='contained' color='success' onClick={handleConfirm}>
							{intl.formatMessage({ id: 'confirm.window.discard.and.go' })}
						</Button>
					}
				/>
			}
			position='absolute'
			top={50}
		>
			<Box sx={{ p: 3 }}>
				<Typography color='primary'>
					{migration
						? intl.formatMessage({ id: 'custom.prompt.dialog.content.up.migration' })
						: intl.formatMessage({ id: 'custom.prompt.dialog.content.up' })}
				</Typography>
				<List>
					{dirtyKeys?.map(item => (
						<ListItem
							key={item}
							sx={{
								paddingTop: 0,
								paddingBottom: 0,
							}}
						>
							-&nbsp;
							<ListItemText
								primary={
									<Typography color='primary' sx={{ fontWeight: 600, fontSize: 15 }}>
										{intl.formatMessage({ id: `${translation}.${item}` })}
									</Typography>
								}
							/>
						</ListItem>
					))}
				</List>
				<Typography color='primary'>{intl.formatMessage({ id: 'custom.prompt.dialog.content.down' })}</Typography>
			</Box>
		</ModalDialog>
	);
};

export default ConfirmUnsaved;
