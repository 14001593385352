import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchScenarioQuery } from 'containers/Scenario/scenarioV2Api';
import { getCompanyId } from 'utils/auth/company';

import ContentContainer from 'components/Containers/ContentContainer';
import Instruments from 'containers/Instruments';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import ScenarioModal from 'containers/Scenario/ScenarioModal';

const InstrumentsPage = ({ pathname }) => {
	const dispatch = useDispatch();

	const companyId = getCompanyId();

	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: isNil(companyId) });

	const businessPlan = useSelector(state => state.businessPlan);
	const costs = businessPlan?.main?.data?.costs;

	const instruments = useSelector(state => state.instruments);
	const instrumentsLoading = instruments?.instruments?.loading;
	const instrumentsData = instruments?.instruments?.data;
	const currentInstrumentId = instruments?.currentInstrumentId;

	const { data: scenario, isLoading: isScenarioLoading } = useFetchScenarioQuery(
		{ companyId, businessId: company?.businessId },
		{ skip: isNil(companyId) || isCompanyLoading || isCompanyFetching }
	);

	const loading = (instrumentsLoading || isCompanyLoading) ?? false;

	const openScenarioDialog = () => {
		dispatch(openDialog({ name: 'ScenarioDialog' }));
	};

	return (
		<ContentContainer>
			<Instruments
				loading={loading}
				instruments={instrumentsData}
				currentInstrumentId={currentInstrumentId}
				company={company}
				pathname={pathname}
				openProfileModal={openScenarioDialog}
				costs={costs}
			/>
			{instrumentsData?.length === 0 && <ScenarioModal scenario={scenario} loading={isScenarioLoading} />}
		</ContentContainer>
	);
};

export default InstrumentsPage;
