import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import App from 'containers/App';
import store from 'configureStore';
import history from 'utils/misc/history';
import { IntlProviderWrapper } from 'contexts/IntlWrapper';
import { YupProvider } from 'contexts/YupContext';

import * as serviceWorker from './serviceWorker';

import './index.css';

const app = (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<IntlProviderWrapper>
				<YupProvider>
					<HelmetProvider>
						{/* <Helmet bodyAttributes={{ style: 'background-color: #fff' }}> <- breaks fullScreen dialogs */}
						<Helmet>
							<title>GrantedAI</title>
						</Helmet>
						<App />
					</HelmetProvider>
				</YupProvider>
			</IntlProviderWrapper>
		</ConnectedRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
