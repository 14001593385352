import { FormattedMessage } from 'react-intl';

import { isAdminToken } from 'utils/auth/token';
import AiChatWrapper from './AiChatWrapper';

const AiChatIcon = () => {
	const isAdmin = isAdminToken();

	if (!isAdmin) return null;

	return (
		<AiChatWrapper
			title={<FormattedMessage id='ai.chat.general.title' />}
			chatId='general'
			sx={{
				position: 'fixed',
				bottom: '6rem',
				right: '1rem',
				color: 'primary.white',
				padding: '1rem',
				boxShadow: 'px 5px 18px 5px rgba(72, 46, 81, .5)',
				width: '4rem',
				height: '4rem',
				backgroundColor: 'primary.main',
				borderRadius: '4rem',
				textAlign: 'center',
				'&:hover': {
					backgroundColor: 'rgba(72, 46, 81, .9)',
					'& svg': {
						fill: 'primary.white',
					},
					boxShadow: '1px 1px 10px 1px rgba(72, 46, 81, .11)',
				},
				zIndex: 22,
			}}
		/>
	);
};

export default AiChatIcon;
