import { Close as CloseIcon } from '@mui/icons-material';
import { Box, CircularProgress, Dialog, IconButton, Slide, Stack } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { closeDialog, isDialogOpen } from 'containers/Marketplace/dialogSlice';
import { getCompanyId } from 'utils/auth/company';
import { isBusinessId } from 'utils/helpers/companyUtils';

import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved/ConfirmUnsaved';
import OnboardingStep1 from 'containers/Onboarding/OnboardingStep1';
import OnboardingStep2 from 'containers/Onboarding/OnboardingStep2';
import OnboardingStep3 from 'containers/Onboarding/OnboardingStep3';
import OnboardingStep4 from 'containers/Onboarding/OnboardingStep4';
import OnboardingStep5 from 'containers/Onboarding/OnboardingStep5';
import OnboardingStep6 from 'containers/Onboarding/OnboardingStep6';
import OnboardingStepIndicator from 'containers/Onboarding/OnboardingStepIndicator';
import HeaderTitle from 'views/Dashboard/HeaderTitle';

type Props = {};

type Step = 'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6';

const steps: { [key: string]: Step } = {
	step1: 'step1',
	step2: 'step2',
	step3: 'step3',
	step4: 'step4',
	step5: 'step5',
	step6: 'step6',
};

const OnboardingDialog: React.FC<Props> = () => {
	const dispatch = useDispatch();

	const companyId = getCompanyId();

	const [step, setStep] = React.useState<Step>(steps.step1);
	const [isTransitioning, setIsTransitioning] = React.useState(false);
	const [zeroFields, setZeroFields] = React.useState<string[]>([]);
	const [confirmOpen, setConfirmOpen] = React.useState(false);

	const handleConfirm = React.useRef(() => {});
	const isDirty = React.useRef(false);
	const dirtyFields = React.useRef({});

	const dialogOpen = useSelector(isDialogOpen('OnboardingDialog'));

	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	React.useEffect(() => {
		if (company) {
			const businessId = company?.businessId;
			if (isBusinessId(businessId)) {
				setStep(steps.step2);
			}
		}
	}, [company, companyId]);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setIsTransitioning(false);
		}, 500);
		return () => clearTimeout(timeout);
	}, [step]);

	const handleConfirmClose = React.useCallback(() => {
		setConfirmOpen(false);
		isDirty.current = false;
		dirtyFields.current = {};
	}, []);

	const handleConfirmOpen = React.useCallback(
		(callback: () => void) => {
			setConfirmOpen(true);
			handleConfirm.current = () => {
				handleConfirmClose();
				callback();
			};
		},
		[handleConfirmClose]
	);

	const handleStepChange = React.useCallback(
		(nextStep: Step, checkDirty: boolean) => {
			if (isDirty?.current && checkDirty) {
				handleConfirmOpen(() => {
					setIsTransitioning(true);
					setStep(nextStep);
				});
			} else {
				setIsTransitioning(true);
				setStep(nextStep);
			}
		},
		[handleConfirmOpen]
	);

	const handleDialogClose = React.useCallback(() => {
		if (isDirty?.current) {
			setConfirmOpen(true);
			handleConfirmOpen(() => {
				dispatch(closeDialog({ name: 'OnboardingDialog' }));
			});
		} else {
			dispatch(closeDialog({ name: 'OnboardingDialog' }));
		}
	}, [dispatch, handleConfirmOpen]);

	return (
		<Dialog
			open={dialogOpen}
			onClose={handleDialogClose}
			PaperProps={{ sx: { backgroundColor: '#fff' /* '#F7F5F0' */ } }}
			maxWidth='lg'
			fullWidth
		>
			<IconButton sx={{ position: 'absolute', top: 0, right: 0, fontSize: '2rem' }} onClick={handleDialogClose}>
				<CloseIcon />
			</IconButton>
			{/*  @ts-ignore */}
			<ConfirmUnsaved
				open={confirmOpen}
				handleCancel={handleConfirmClose}
				handleConfirm={handleConfirm?.current}
				dirtyFields={dirtyFields?.current}
				translation='instrument.radar.choice'
			/>
			<Box sx={{ width: '100%' }}>
				<OnboardingStepIndicator step={step} />
			</Box>
			<Box sx={{ flex: 1 }}>
				{isCompanyLoading ? (
					<Box
						sx={{
							width: '100%',
							minHeight: '40rem',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<CircularProgress />
					</Box>
				) : (
					<>
						<Stack alignItems='center' sx={{ width: '100%' }}>
							<HeaderTitle company={company} loading={isCompanyLoading} sx={{ mt: '1rem' }} />
						</Stack>
						<Box
							sx={{
								minHeight: { xs: '35rem', md: '40rem' },
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Slide direction='left' in={step === steps.step1 && !isTransitioning} mountOnEnter unmountOnExit>
								<OnboardingStep1
									onNextStepClick={() => {
										handleStepChange(steps.step2, false);
									}}
									passIsDirty={(nextIsDirty, nextDirtyFields) => {
										isDirty.current = nextIsDirty;
										dirtyFields.current = nextDirtyFields;
									}}
									passZeroFields={nextZeroFields => {
										setZeroFields(nextZeroFields);
									}}
								/>
							</Slide>
							<Slide direction='left' in={step === steps.step2 && !isTransitioning} mountOnEnter unmountOnExit>
								<OnboardingStep2
									hideOnPrevStep={isBusinessId(company?.businessId)}
									onNextStepClick={() => {
										handleStepChange(steps.step3, false);
									}}
									onPrevStepClick={() => {
										handleStepChange(steps.step1, true);
									}}
									passIsDirty={(nextIsDirty, nextDirtyFields) => {
										isDirty.current = nextIsDirty;
										dirtyFields.current = nextDirtyFields;
									}}
									passZeroFields={nextZeroFields => {
										setZeroFields(nextZeroFields);
									}}
								/>
							</Slide>
							<Slide direction='left' in={step === steps.step3 && !isTransitioning} mountOnEnter unmountOnExit>
								<OnboardingStep3
									onNextStepClick={() => {
										handleStepChange(steps.step4, false);
									}}
									onPrevStepClick={() => {
										handleStepChange(steps.step2, true);
									}}
									passIsDirty={(nextIsDirty, nextDirtyFields) => {
										isDirty.current = nextIsDirty;
										dirtyFields.current = nextDirtyFields;
									}}
									passZeroFields={nextZeroFields => {
										setZeroFields(nextZeroFields);
									}}
								/>
							</Slide>
							<Slide direction='left' in={step === steps.step4 && !isTransitioning} mountOnEnter unmountOnExit>
								<OnboardingStep4
									onNextStepClick={() => {
										handleStepChange(steps.step5, false);
									}}
									onPrevStepClick={() => {
										handleStepChange(steps.step3, true);
									}}
									passIsDirty={(nextIsDirty, nextDirtyFields) => {
										isDirty.current = nextIsDirty;
										dirtyFields.current = nextDirtyFields;
									}}
									passZeroFields={nextZeroFields => {
										setZeroFields(nextZeroFields);
									}}
								/>
							</Slide>
							<Slide direction='left' in={step === steps.step5 && !isTransitioning} mountOnEnter unmountOnExit>
								<OnboardingStep5
									onNextStepClick={() => {
										handleStepChange(steps.step6, false);
									}}
									onPrevStepClick={() => {
										handleStepChange(steps.step4, true);
									}}
									passIsDirty={(nextIsDirty, nextDirtyFields) => {
										isDirty.current = nextIsDirty;
										dirtyFields.current = nextDirtyFields;
									}}
									passZeroFields={nextZeroFields => {
										setZeroFields(nextZeroFields);
									}}
								/>
							</Slide>
							<Slide direction='left' in={step === steps.step6 && !isTransitioning} mountOnEnter unmountOnExit>
								<OnboardingStep6
									onNextStepClick={() => {
										handleDialogClose();
									}}
									onPrevStepClick={() => {
										handleStepChange(steps.step5, false);
									}}
								/>
							</Slide>
						</Box>
					</>
				)}
			</Box>
		</Dialog>
	);
};

export default OnboardingDialog;
