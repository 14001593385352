import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import TeamMemberAvatar from 'containers/BusinessPlan/TeamMemberPanel/TeamMemberCard/TeamMemberAvatar';

const TeamAvatarsStack = ({ intl, loading, data }) => {
    return (
        <Box>
            <Typography color='primary' gutterBottom sx={{ fontWeight: 600 }}>
                {intl.formatMessage({ id: 'business.plan.member.card.title' })}
            </Typography>
            {loading
                ? <CircularProgress size={16} color='primary' sx={{ mt: 2 }} />
                : <Grid
                    container
                    spacing={1}
                >
                    {data?.map(({ memberId, avatar, firstname, lastname }) =>
                        <Grid item key={memberId}>
                            <TeamMemberAvatar avatar={avatar} firstname={firstname} lastname={lastname} size={30} circular={true} spinnerSize={30} />
                        </Grid>
                    )}
                </Grid>
            }
        </Box>
    );
};

export default TeamAvatarsStack;