import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import * as _ from 'lodash';
import { forwardRef, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import CustomPrompt from 'components/CustomPrompt';
import BorderedTextBox from 'components/Form/BorderedTextBox';
import BorderedSearchBox from './BorderedSearchBox';
import SelectItem from './SelectItem';
import SwitchGroup from './SwitchGroup';

import { getDirtyKeys } from 'components/Form/utils/utils';
import countys from 'utils/constants/countys';
import scenarioValues from 'utils/mock/scenarioValues.json';
import { basicSelectItemValues, basicSwitchGroupValues, businessValues, financialValues } from '../ScenarioDialog/groupedValues';

const ScenarioForm = forwardRef(({ scenario, onSubmit, passIsDirty, passZeroFields, activeStep, profile, readonly }, formRef) => {
	const intl = useIntl();

	const schema = yup.object().shape({
		postalCode: yup.string().test('length', intl.formatMessage({ id: 'validation.postal.code' }), val => val.length === 5),
	});

	const {
		control,
		handleSubmit,
		reset,
		watch,
		formState: { errors, dirtyFields },
	} = useForm({
		defaultValues: useMemo(() => {
			return scenario;
		}, [scenario]),
		resolver: yupResolver(schema),
	});

	const currentValuesObject = watch();

	const zeroFields = Object.keys(
		Object.fromEntries(
			Object.entries(currentValuesObject).filter(([_, value]) => value === 0) // eslint-disable-line
		)
	);

	const regexExactlyFiveDigits = /^\d{5}$/;
	const incorrectPostalCode = !regexExactlyFiveDigits.test(currentValuesObject.postalCode);
	const missingCountyId = !currentValuesObject.countyId;
	const missingTolCode = !currentValuesObject.tolCode || currentValuesObject.tolCode?.length === 0;

	if (incorrectPostalCode) {
		zeroFields.push('postalCode');
	}

	if (missingCountyId) {
		zeroFields.push('countyId');
	}

	if (missingTolCode) {
		zeroFields.push('tolCode');
	}

	const zeroFieldsString = zeroFields.join();

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	useEffect(() => {
		passIsDirty && passIsDirty(isDirty, dirtyFields);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDirty, dirtyFields]);

	useEffect(() => {
		passZeroFields && passZeroFields(zeroFieldsString);
	}, [zeroFieldsString, passZeroFields]);

	const scenarioValuesObj = _.chain(scenarioValues).keyBy('type').mapValues('values').value();

	const basicSwitchGroupItems = arr => scenarioValues.filter(({ type }) => arr.includes(type));

	const isCreatedByBot = scenario?.creator === 'DATA_MAPPER';

	if (scenario === undefined || scenario === null) return null;

	return (
		<form
			ref={formRef}
			onSubmit={handleSubmit(data => {
				onSubmit(data);
				reset(data);
			})}
		>
			<CustomPrompt dirtyFields={dirtyFields} isDirty={isDirty} translation='instrument.radar.choice' />
			<Box sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
				<BorderedTextBox
					name='postalCode'
					defaultValue={scenario.postalCode ?? ''}
					error={incorrectPostalCode}
					yupError={errors?.postalCode?.message}
					label={intl.formatMessage({ id: 'instrument.form.postCodeArea' })}
					control={control}
					instructions={intl.formatMessage({ id: 'instrument.form.postCodeArea.instructions' })}
					isCreatedByBot={isCreatedByBot}
					disabled={readonly}
				/>
				<SelectItem
					type='countyId'
					values={countys?.map(county => ({ value: county.code, label: county.name }))}
					scenario={scenario}
					zeroValues={missingCountyId}
					control={control}
					profile={profile}
					isCreatedByBot={isCreatedByBot}
					disabled={readonly}
				/>
				<BorderedSearchBox
					name='tolCode'
					multiple={false}
					control={control}
					defaultValue={scenario.tolCode ?? []}
					error={missingTolCode}
					label={intl.formatMessage({ id: 'instrument.edit.form.tols.class' })}
					instructions={intl.formatMessage({ id: 'instrument.edit.form.tols.class.instructions' })}
					isCreatedByBot={isCreatedByBot}
					disabled={readonly}
				/>
			</Box>
			{basicSelectItemValues.map(value => {
				return (
					<Box key={value} sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
						<SelectItem
							type={value}
							values={scenarioValuesObj[value]}
							scenario={scenario}
							zeroValues={zeroFields.includes(value)}
							control={control}
							isCreatedByBot={isCreatedByBot}
							profile={profile}
							disabled={readonly}
						/>
					</Box>
				);
			})}
			<Box sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
				<SwitchGroup
					name='registers'
					items={basicSwitchGroupItems(basicSwitchGroupValues)}
					scenario={scenario}
					control={control}
					isCreatedByBot={isCreatedByBot}
					profile={profile}
					disabled={readonly}
				/>
			</Box>
			{businessValues.map(value => {
				return (
					<Box key={value} sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
						<SelectItem
							type={value}
							values={scenarioValuesObj[value]}
							scenario={scenario}
							zeroValues={zeroFields.includes(value)}
							control={control}
							isCreatedByBot={isCreatedByBot}
							profile={profile}
							disabled={readonly}
						/>
					</Box>
				);
			})}
			{financialValues.map(value => {
				return (
					<Box key={value} sx={{ display: activeStep === 2 ? 'block' : 'none' }}>
						<SelectItem
							type={value}
							values={scenarioValuesObj[value]}
							scenario={scenario}
							zeroValues={zeroFields.includes(value)}
							control={control}
							isCreatedByBot={isCreatedByBot}
							profile={profile}
							disabled={readonly}
						/>
					</Box>
				);
			})}
		</form>
	);
});

export default ScenarioForm;
