import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			'& h1': {
				textAlign: 'center',
			},
		},
	},
	header: {
		fontSize: '2rem',
		fontWeight: 600,
	},
}));

export const BusinessIdChip = ({ businessId, sx = {} }) => {
	const theme = useTheme();

	return (
		<Typography
			component='div'
			sx={{
				display: 'inline-block',
				background: theme.palette.secondary.grey300,
				color: theme.palette.primary.grayishBlue,
				padding: '.1rem .5rem',
				borderRadius: '.8rem',
				mt: '.4rem',
				fontSize: '.8rem',
				fontWeight: 500,
				...sx
			}}
		>
			{businessId}
		</Typography>
	);
};

const HeaderTitle = ({ company, loading, sx = {} }) => {
	const classes = useStyles();

	if (company === undefined) return null;

	if (loading) return <CircularProgress size={20} color='primary' />;

	return (
		<Box className={classes.root} sx={{ mb: '1rem', ...sx }}>
			<Typography variant='h1' color='primary' className={classes.header}>
				{company.name}
			</Typography>
			<BusinessIdChip businessId={company.businessId} />
		</Box>
	);
};

export default HeaderTitle;
