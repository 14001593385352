import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { closeDialog, isDialogOpen } from 'containers/Marketplace/dialogSlice';

import CTAContainer from './CTAContainer';

type Props = Omit<DialogProps, 'open'> & {};

const FreemiumCTADialog: React.FC<Props> = ({ sx, ...otherProps }) => {
	const dispatch = useDispatch();

	const dialogOpen = useSelector(isDialogOpen('FreemiumCTADialog'));

	const handleDialogClose = React.useCallback(() => {
		dispatch(closeDialog({ name: 'FreemiumCTADialog' }));
	}, [dispatch]);

	return (
		<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='md' fullWidth>
			<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
				<CloseIcon />
			</IconButton>
			<DialogTitle>
				<FormattedMessage id='freemium.upgrade.dialog.title' />
			</DialogTitle>
			<DialogContent sx={{ p: '2rem' }}>
				<CTAContainer />
			</DialogContent>
		</Dialog>
	);
};

export default FreemiumCTADialog;
