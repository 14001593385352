import { yupResolver } from '@hookform/resolvers/yup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Button from 'components/Button/Button';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ModalDialog from 'components/Dialog/ModalDialog';
import { FormControlledInput } from 'components/Form';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

const UserModalForm = ({ user, isOpen, handleClose, saveUser }) => {
	const intl = useIntl();
	const formRef = useRef();

	const roles = [
		{ value: 'Company.Read', label: `${intl.formatMessage({ id: 'user.form.roles.read' })}` },
		{ value: 'Company.Contributor', label: `${intl.formatMessage({ id: 'user.form.roles.contributor' })}` },
		{ value: 'Company.Notified', label: `${intl.formatMessage({ id: 'user.form.roles.notified' })}` }
	];

	const [selectedRoles, setSelectedRoles] = useState([]);

	const selectRole = (role) => {
		const rolesNew = selectedRoles?.includes(role)
			? selectedRoles?.filter(name => name !== role)
			: [...(selectedRoles ?? []), role];
		setSelectedRoles(rolesNew);

		return rolesNew;
	};

	const schema = yup.object().shape({
		firstname: yup.string(),
		lastname: yup.string(),
		email: yup.string().email(`${intl.formatMessage({ id: 'user.form.username.error' })}`)
			.required((`${intl.formatMessage({ id: 'user.form.email' })} ${intl.formatMessage({ id: 'message.isRequired' })}`)),
		roles: yup.array().required().min(1, `${intl.formatMessage({ id: 'user.form.email' })} ${intl.formatMessage({ id: 'message.isRequired' })}`)
	});


	const { control, formState: { errors }, handleSubmit, reset } = useForm({
		defaultValues: {
			user,
		},
		resolver: yupResolver(schema),
	});

	const onSubmit = data => {
		setSelectedRoles([]);
		reset();
		saveUser(data);
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	return (
		<ModalDialog
			open={isOpen}
			onClose={() => {
				setSelectedRoles([]);
				handleClose();
			}}
			title={intl.formatMessage({ id: 'user.form.title' })}
			actions={
				<LeftRightContainer
					left={
						<Button
							variant='text'
							onClick={() => {
								setSelectedRoles([]);
								handleClose();
							}}
						>
							{intl.formatMessage({ id: 'shared.cancel' })}
						</Button>
					}
					right={
						<Button color='success' onClick={submitForm}>
							{intl.formatMessage({ id: 'shared.save' })}
						</Button>
					}
				/>
			}
		>
			{/* Form */}
			<form ref={formRef} onSubmit={handleSubmit(data => onSubmit(data))}>
				<Grid container spacing={2} sx={{ p: 3 }}>
					<Grid item xs={12}>
						<FormControlledInput
							defaultValue={user?.email ?? ''}
							name='email'
							control={control}
							error={errors?.email?.message}
							label={intl.formatMessage({ id: 'user.form.email' })}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlledInput
							defaultValue={user?.firstname ?? ''}
							name='firstname'
							control={control}
							error={errors?.firstname?.message}
							label={intl.formatMessage({ id: 'user.form.firstname' })}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlledInput
							defaultValue={user?.lastname ?? ''}
							name='lastname'
							control={control}
							error={errors?.lastname?.message}
							label={intl.formatMessage({ id: 'user.form.lastname' })}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl component="fieldset">
							<FormLabel component="legend">{intl.formatMessage({ id: 'user.form.roles.title' })}</FormLabel>
							<FormGroup>
								{roles.map((role) => (
									<FormControlLabel key={role.value}
										control={
											<Controller
												defaultValue={[]}
												name="roles"
												render={({ field: { onChange, } }) => {
													return (
														<Switch
															checked={selectedRoles.includes(role.value)}
															onChange={() => onChange(selectRole(role.value))}
														/>
													);
												}}
												control={control}
												error={errors?.roles?.message}
												helperText={errors?.roles ? errors.roles.message : null}
											/>
										}
										label={role?.label}
									/>
								))}
							</FormGroup>
							<FormHelperText error={errors?.roles ? true : false}>{intl.formatMessage({ id: 'user.form.roles.error.notchosen' })}</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</form>
		</ModalDialog>
	);
};

export default UserModalForm;
