import axios from 'utils/axios';
import { buildUri, validateHttpStatus } from 'utils/helpers/uriHelper';

const baseUrl = 'scenario';
const instrumentsUrl = 'instruments';

class ScoreApi {
	async getGrantedScore() {
		const url = buildUri(baseUrl, 'score');

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getLiteScore() {
		const url = buildUri(`${instrumentsUrl}/score/lite`);

		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const scoreApi = new ScoreApi();
