import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { getCompany } from 'utils/auth/company';
import axios from 'utils/axios';
import env from 'config/environment';
import { chatApi } from 'containers/Chat/chatApi';

interface ChatState {
	loading: boolean;
	error: boolean;
	chat: any;
	active: any;
	messageLoading: boolean;
	conversationLoading: boolean;
	conversationTitleLoading: boolean;
	asReadLoading: boolean;
	toggleView: boolean;
	lastVisit: any;
	lastVisitLoading: boolean;
	lastVisitError: any;
}

const initialState: ChatState = {
	loading: false,
	error: false,
	chat: null,
	active: null,
	messageLoading: false,
	conversationLoading: false,
	conversationTitleLoading: false,
	asReadLoading: false,
	toggleView: false,
	lastVisit: null,
	lastVisitLoading: false,
	lastVisitError: null,
};

export const name = 'chat';

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		sendMessage: state => {
			state.messageLoading = true;
			state.error = false;
		},
		sendMessageSuccess: state => {
			state.messageLoading = false;
			state.error = false;
		},
		sendMessageError: (state, action: any) => {
			state.messageLoading = false;
			state.error = action.error;
		},
		loadChat: state => {
			state.loading = true;
			state.error = false;
		},
		loadChatSuccess: (state, action) => {
			state.loading = false;
			state.error = false;
			state.chat = action.payload;
		},
		loadChatError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.chat = initialState.chat;
		},
		addMessage: state => {
			state.messageLoading = true;
			state.error = false;
		},
		addMessageSuccess: (state, action) => {
			state.messageLoading = false;
			state.error = false;
			state.chat = action.payload.chat;
			state.active = action.payload.active;
		},
		addMessageError: (state, action) => {
			state.messageLoading = false;
			state.error = action.payload;
		},
		createConversation: state => {
			state.conversationLoading = true;
		},
		createConversationSuccess: (state, action) => {
			state.conversationLoading = false;
			state.error = false;
			state.chat = action.payload.chat;
			state.active = action.payload.active;
		},
		createConversationError: (state, action) => {
			state.error = action.payload;
			state.conversationLoading = false;
		},
		changeConversationTitle: state => {
			state.conversationTitleLoading = true;
		},
		changeConversationTitleSuccess: (state, action) => {
			state.conversationTitleLoading = false;
			state.error = false;
			state.chat = action.payload.chat;
			state.active = action.payload.active;
		},
		changeConversationTitleError: (state, action) => {
			state.conversationTitleLoading = false;
			state.error = action.payload;
		},
		setAsRead: state => {
			state.asReadLoading = true;
		},
		setAsReadSuccess: (state, action) => {
			state.asReadLoading = false;
			state.error = false;
			state.chat = action.payload.chat;
			state.active = action.payload.active;
		},
		setAsReadError: (state, action) => {
			state.asReadLoading = false;
			state.error = action.payload;
		},
		getLastVisit: state => {
			state.lastVisitLoading = true;
			state.lastVisitError = null;
		},
		getLastVisitSuccess: (state, action) => {
			state.lastVisitLoading = false;
			state.lastVisitError = null;
			state.lastVisit = action.payload;
		},
		getLastVisitError: (state, action) => {
			state.lastVisitLoading = false;
			state.lastVisitError = action.payload;
		},
		setLastVisit: state => {
			state.lastVisitLoading = true;
			state.lastVisitError = null;
		},
		setLastVisitSuccess: (state, action) => {
			state.lastVisitLoading = false;
			state.lastVisitError = null;
			state.lastVisit = action.payload;
		},
		setLastVisitError: (state, action) => {
			state.lastVisitLoading = false;
			state.lastVisitError = action.payload;
		},
		changeActive: (state, action) => {
			state.active = action.payload;
		},
		setToggleView: (state, action) => {
			state.toggleView = action.payload;
		},
		resetChat: state => {
			state.chat = null;
		},
	},
});

export const { reducer } = slice;

export const { changeActive, setToggleView, resetChat } = slice.actions;

export const sendMessage =
	(message: any, isChatHidden = false) =>
	async (dispatch: any) => {
		try {
			dispatch(slice.actions.sendMessage());
			const company = getCompany();
			await axios.post(`${env.SOCKET_URL}/send/message`, {
				message,
				company: Object.assign({}, company, {
					businessId: company.CompanyId,
					name: company.CompanyName,
				}),
			});
			dispatch(slice.actions.sendMessageSuccess());
			if (isChatHidden) {
				toast.success(<FormattedMessage id='chat.input.contact.request.successed' />);
			}
		} catch (error) {
			dispatch(slice.actions.sendMessageError(error));
			if (isChatHidden) {
				toast.error(<FormattedMessage id='chat.input.contact.request.failed' />);
			}
		}
	};

export const loadChat = () => async (dispatch: any) => {
	try {
		dispatch(slice.actions.loadChat());
		const company = getCompany();
		if (!company) return;

		const history = await axios.post(`${env.SOCKET_URL}/channelhistory`, {
			company: Object.assign({}, company, {
				businessId: company.CompanyId,
				name: company.CompanyName,
			}),
		});

		let reversed = [];

		if (history.data.messages) {
			reversed = history.data.messages.reverse();
		}

		const chatItem = {
			userId: '',
			userName: 'test',
			userImageUrl: '',
			conversations: [
				{
					id: 0,
					title: 'GrantedAI Chat: ' + company.CompanyName,
					person: {
						name: '',
						imageUrl: '',
					},
					messages: reversed,
				},
			],
		};

		dispatch(slice.actions.loadChatSuccess({ chat: chatItem, active: 0 }));
	} catch (err) {
		dispatch(slice.actions.loadChatError(err));
	}
};

export const addMessage = (message: any) => async (dispatch: any, getState: any) => {
	try {
		dispatch(slice.actions.addMessage());
		const chat = getState()?.chat?.chat;

		const createFirstConversation = (title: string) => {
			const firstConversation = {
				id: 0,
				title: title,
				person: {
					name: 'Consultant Name',
					imageUrl: '',
				},
				messages: [message],
			};

			return [firstConversation];
		};

		const newChat = {
			...chat,
			conversations: chat?.conversations?.length
				? chat.conversations.map((conversation: any) => {
						if (conversation.id === 0) {
							return {
								...conversation,
								messages: [...conversation.messages, message],
							};
						}
						return conversation;
				  })
				: createFirstConversation('Granted AI Chat'),
		};

		dispatch(slice.actions.addMessageSuccess({ chat: newChat, active: 0 }));
	} catch (err) {
		dispatch(slice.actions.addMessageError(err));
	}
};

export const createConversation = (title: any) => async (dispatch: any, getState: any) => {
	try {
		dispatch(slice.actions.createConversation());
		const chat = getState()?.chat?.chat;

		// const id = chat?.conversations && chat?.conversations[chat?.conversations?.length - 1]?.id + 1;
		const id = (chat?.conversations && chat?.conversations[0]?.id - 1) || -1;

		const newConversation = {
			id,
			title,
			person: {
				name: 'Consultant Name',
				imageUrl: '',
			},
			messages: [],
		};

		const newChat = {
			...chat,
			conversations: [newConversation, ...chat.conversations],
		};
		dispatch(slice.actions.createConversationSuccess({ chat: newChat, active: id }));
		dispatch(slice.actions.setToggleView(true));
	} catch (err) {
		dispatch(slice.actions.createConversationError(err));
	}
};

export const changeConversationTitle = (title: any, active: any) => async (dispatch: any, getState: any) => {
	try {
		dispatch(slice.actions.changeConversationTitle());
		const chat = getState()?.chat?.chat;

		const newChat = {
			...chat,
			conversations: chat?.conversations?.map((conversation: any) => {
				if (conversation.id === active) {
					return {
						...conversation,
						title,
					};
				}
				return conversation;
			}),
		};

		dispatch(slice.actions.changeConversationTitleSuccess({ chat: newChat, active }));
	} catch (err) {
		dispatch(slice.actions.createConversationError(err));
	}
};

export const setAsRead = (active: any) => async (dispatch: any, getState: any) => {
	try {
		dispatch(slice.actions.setAsRead());
		const chat = getState()?.chat?.chat;

		const newChat = {
			...chat,
			conversations: chat?.conversations?.map((conversation: any) => {
				if (conversation.id === active) {
					return {
						...conversation,
						messages: [
							...conversation.messages?.map((message: any) => (message?.isNew ? { ...message, isNew: false } : message)),
						],
					};
				}
				return conversation;
			}),
		};

		dispatch(slice.actions.setAsReadSuccess({ chat: newChat, active }));
	} catch (err) {
		dispatch(slice.actions.setAsReadError(err));
	}
};

export const getLastVisit = () => async (dispatch: any, getState: any) => {
	try {
		dispatch(slice.actions.getLastVisit());
		const company = getCompany();
		if (!company) return;

		const lastVisit = await chatApi.getChatLastVisit(company.CompanyId);

		dispatch(slice.actions.getLastVisitSuccess(lastVisit));
	} catch (err) {
		dispatch(slice.actions.getLastVisitError(err));
	}
};

export const setLastVisit = () => async (dispatch: any, getState: any) => {
	try {
		dispatch(slice.actions.setLastVisit());
		const company = getCompany();
		if (!company) return;

		const lastVisit = await chatApi.saveChatLastVisit(company.CompanyId);

		dispatch(slice.actions.setLastVisitSuccess(lastVisit));
	} catch (err) {
		dispatch(slice.actions.setLastVisitError(err));
	}
};

export default slice;
