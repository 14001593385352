import React from 'react';
import { useIntl } from 'react-intl';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip } from 'recharts';
import { useTheme } from '@mui/styles';

import CustomTooltip from './CustomTooltip';
import './radar.css';

const InstrumentRadar = ({ name, companyName, radarSize, smDown, instrument }) => {
	const theme = useTheme();
	const intl = useIntl();

	const fill = '#ccc';

	const radar = [
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'internationalEta',
			requiredHighInt: 5,
			companyHighInt: 0,
			requiredLowInt: 3,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'ipr',
			requiredHighInt: 1,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'team',
			requiredHighInt: 2,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'concept',
			requiredHighInt: 5,
			companyHighInt: 0,
			requiredLowInt: 2,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'deminisHistory',
			requiredHighInt: 3,
			companyHighInt: 0,
			requiredLowInt: 2,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'personnelSize',
			requiredHighInt: 4,
			companyHighInt: 0,
			requiredLowInt: 2,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'turnover',
			requiredHighInt: 1,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'ratingAlpha',
			requiredHighInt: 3,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'taxDepth',
			requiredHighInt: 5,
			companyHighInt: 0,
			requiredLowInt: 3,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'age',
			requiredHighInt: 2,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'equityAmount',
			requiredHighInt: 4,
			companyHighInt: 0,
			requiredLowInt: 2,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'equityPercentageLevel',
			requiredHighInt: 2,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'balance',
			requiredHighInt: 4,
			companyHighInt: 0,
			requiredLowInt: 2,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'countrySide',
			requiredHighInt: 4,
			companyHighInt: 0,
			requiredLowInt: 2,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'paysDivident',
			requiredHighInt: 5,
			companyHighInt: 0,
			requiredLowInt: 3,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'owner',
			requiredHighInt: 2,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'companyType',
			requiredHighInt: 4,
			companyHighInt: 0,
			requiredLowInt: 3,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'employeeRegister',
			requiredHighInt: 4,
			companyHighInt: 0,
			requiredLowInt: 3,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'advanceCollectionRegister',
			requiredHighInt: 5,
			companyHighInt: 0,
			requiredLowInt: 4,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'vatRegister',
			requiredHighInt: 1,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'cashFlow',
			requiredHighInt: 2,
			companyHighInt: 0,
			requiredLowInt: 1,
		},
		{
			comparerStringType: 'IntLimitator',
			compareProperty: 'responsiblesCreditRate',
			requiredHighInt: 4,
			companyHighInt: 0,
			requiredLowInt: 2,
		},
	];

	const translatedValue = key => {
		const val = intl.formatMessage({ id: key });

		return val === key ? 'undefined' : val;
	};

	return (
		<RadarChart
			data={radar}
			width={radarSize}
			height={radarSize}
			margin={{
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,
			}}
		>
			<PolarGrid gridType='circle' />
			<PolarAngleAxis dataKey='compareProperty' tickFormatter={() => ''} />
			<PolarRadiusAxis angle={90} domain={[0, 5]} tick={false} tickCount={6} />
			<Tooltip content={<CustomTooltip translatedValue={translatedValue} />} />
			<Radar name={name} dataKey='requiredLowInt' stroke={fill} strokeWidth={3} fill={fill} fillOpacity={0.4} />
			<Radar
				name={companyName ?? translatedValue('instrument.radar.tooltip.compareValue')}
				dataKey='companyHighInt'
				stroke={theme.palette.primary.main}
				fill={theme.palette.primary.main}
				fillOpacity={0.3}
				strokeWidth={3}
			/>
		</RadarChart>
	);
};

export default InstrumentRadar;
