import React, { forwardRef, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';

import { FormControlledInput, FormControlledAutocomplete } from 'components/Form';
import CustomPrompt from 'components/CustomPrompt';

import { countries } from 'utils/constants/countries';
import { companyForms } from 'utils/constants/companyForms';
import { getDirtyKeys } from 'components/Form/utils/utils';

const CreateCustomCompanyForm = forwardRef(({ disabled = false, initialValues, onSubmit, passIsDirty }, ref) => {
	const intl = useIntl();

	const schema = yup.object().shape({
		name: yup
			.string()
			.required(
				`${intl.formatMessage({ id: 'customer.page.modal.form.companyName' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
			),
		companyForm: yup
			.object()
			.nullable()
			.required(
				`${intl.formatMessage({ id: 'customer.page.modal.form.companyForm' })} ${intl.formatMessage({ id: 'message.isRequired' })}`
			),
		detailsUri: yup
			.string()
			.url(intl.formatMessage({ id: 'customer.page.modal.form.detailsUri.notValid' }))
			.nullable(),
	});

	const {
		control,
		handleSubmit,
		reset,
		formState: { dirtyFields, errors },
	} = useForm({
		defaultValues: useMemo(() => {
			return initialValues;
		}, [initialValues]),
		resolver: yupResolver(schema),
	});

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	useEffect(() => {
		passIsDirty && passIsDirty(isDirty);
	}, [isDirty, passIsDirty]);

	return (
		<form
			ref={ref}
			onSubmit={handleSubmit(data => {
				onSubmit(data);
				reset(data);
			})}
		>
			{/* TODO: check why it does not work */}
			<CustomPrompt dirtyFields={dirtyFields} isDirty={isDirty} translation='test' />
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<FormControlledInput
						name='name'
						defaultValue={initialValues?.name ?? ''}
						disabled={disabled}
						control={control}
						error={errors?.name?.message}
						label={intl.formatMessage({ id: 'customer.page.modal.form.companyName' })}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormControlledAutocomplete
						name='companyForm'
						label={intl.formatMessage({ id: 'customer.page.modal.form.companyForm' })}
						error={errors?.companyForm?.message}
						control={control}
						options={companyForms}
						disabled={disabled}
						defaultValue={initialValues?.companyForm}
						placeholder='Valitse...'
					/>
				</Grid>
				<Grid item xs={8} md={6}>
					<FormControlledInput
						name='address.street'
						defaultValue={initialValues?.address?.street ?? ''}
						disabled={disabled}
						control={control}
						error={errors?.address?.street?.message}
						label={intl.formatMessage({ id: 'customer.page.modal.form.street' })}
						placeholder='Esimerkkikuja 3, A1'
					/>
				</Grid>
				<Grid item xs={4} md={6}>
					<FormControlledInput
						name='address.postCode'
						defaultValue={initialValues?.address?.postCode ?? ''}
						disabled={disabled}
						control={control}
						error={errors?.address?.postCode?.message}
						label={intl.formatMessage({ id: 'customer.page.modal.form.postCode' })}
						placeholder='00000'
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormControlledInput
						name='address.city'
						defaultValue={initialValues?.address?.city ?? ''}
						disabled={disabled}
						control={control}
						error={errors?.address?.city?.message}
						label={intl.formatMessage({ id: 'customer.page.modal.form.city' })}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormControlledAutocomplete
						name='address.country'
						label={intl.formatMessage({ id: 'customer.page.modal.form.country' })}
						error={errors?.address?.country?.message}
						control={control}
						options={countries}
						freeSolo={true}
						disabled={disabled}
						defaultValue={initialValues?.address?.country || ''}
						placeholder='Hae tai valitse...'
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormControlledInput
						name='detailsUri'
						defaultValue={initialValues?.detailsUri ?? ''}
						disabled={disabled}
						control={control}
						error={errors?.detailsUri?.message}
						label={intl.formatMessage({ id: 'customer.page.modal.form.detailsUri' })}
						placeholder='https://verkkosivu.fi'
					/>
				</Grid>
			</Grid>
		</form>
	);
});

export default CreateCustomCompanyForm;
