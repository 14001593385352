import * as React from 'react';
import { Control, FieldValues, useWatch } from 'react-hook-form';

import { calculateRfpProposalItemCost } from 'utils/helpers/marketplaceUtils';

import RfpProposalItemCostTotalPrice from 'containers/Marketplace/proposal/RfpProposalItemCostTotalPrice';

type Props<FV extends FieldValues> = { control: Control<FV> };

function CalculateRfpProposalItemCostTotalPrice<FV extends FieldValues>({ control }: Props<FV>) {
	const rfpProposalItemCost = useWatch({ control });

	const { totalPrice, totalPriceMax } = calculateRfpProposalItemCost(rfpProposalItemCost);

	return <RfpProposalItemCostTotalPrice totalPrice={totalPrice} totalPriceMax={totalPriceMax} />;
}

export default CalculateRfpProposalItemCostTotalPrice;
