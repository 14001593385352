import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import * as React from 'react';

type ContentContainerProps = {
	component?: string;
	color?: string;
	useMinheight?: boolean;
	maxWidth?: string;
	children: any;
	sx?: any;
};

const ContentContainer = React.forwardRef<any, ContentContainerProps>(
	({ component = 'div', color = 'transparent', useMinheight = false, maxWidth = 'xl', children, sx = {}, ...other }, ref) => {
		const theme = useTheme();

		return (
			<Box
				ref={ref}
				sx={{
					// @ts-ignore
					padding: { xs: theme.spacing(1), md: theme.spacing(2), lg: theme.spacing(4) },
					backgroundColor: color,
					minHeight: useMinheight ? '100vh' : 'unset',
					// @ts-ignore
					maxWidth: maxWidth && theme.breakpoints.values[maxWidth],
					'@media print': {
						padding: 0,
					},
					...sx,
				}}
				// @ts-ignore
				component={component}
				{...other}
			>
				{children}
			</Box>
		);
	}
);

export default ContentContainer;
