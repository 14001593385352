export const reduceFilterArray = (array) => {
    return array?.reduce(
        (acc, value) => ({
            ...acc,
            [value]: (acc[value] || 0) + 1,
        }),
        {}
    );
};

export const scrollToCurrentInstrument = id => {
    const headerOffset = 8;
    const element = document.getElementById(id);
    const elementPosition = element?.getBoundingClientRect().top ?? headerOffset;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth',
    });
};