import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';
import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import ModalDialog from 'components/Dialog/ModalDialog';
import FormatCurrency from 'components/Formatters/FormatCurrency';
import ColumnAddButton from 'components/Kanban/ColumnAddButton';
import KanbanCard from 'components/Kanban/KanbanCard';
import ProjectTask from '../ProjectTask/ProjectTask';
// import { costTaskSelector } from 'containers/Projects/utils';

import { createProjectTask, deleteProjectTask, updateProjectTask } from 'containers/Projects/slices';

const Pill = styled(Box)(() => ({
	width: '6rem',
	color: '#fff',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
}));

const Graph = styled(Box)(() => ({
	height: '0.8rem',
	width: '5.5rem',
	borderRadius: '10px',
	backgroundColor: 'rgba(200, 200, 255, 0.24)',
	'&:hover': {
		opacity: '0.8',
		cursor: 'pointer',
	},
}));

const CostResults = styled(Box)(() => ({
	'& svg': {
		top: '.2rem',
		position: 'relative',
		marginRight: '.5rem',
	},
	'& span': {
		marginRight: '.5rem',
	},
}));

const PillContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	width: '100%',
	marginTop: '.5rem',
	'& span': {
		top: '.2rem',
	},
	'& span:last-child': {
		marginLeft: 'auto',
	},
}));

const TaskNameStyled = styled(Typography)(() => ({
	fontWeight: 500,
	fontSize: '1.1rem',
	textTransform: 'capitalize',
}));

const TaskModalButton = ({ projectId, projectTaskId, projectTaskName, costs, activeTask, showModalButton, isColumnAdd, handleOpen }) => {
	const intl = useIntl();

	if (activeTask?.projectTaskId && !showModalButton)
		return <ProjectKanbanCard {...{ projectId, projectTaskId, projectTaskName, costs, handleOpen }} />;

	return isColumnAdd ? (
		<ColumnAddButton onClick={handleOpen}>{intl.formatMessage({ id: 'project.task.add' })}</ColumnAddButton>
	) : (
		<Button sx={{ mt: '1rem' }} color='success' icon={<AddIcon />} onClick={handleOpen}>
			{intl.formatMessage({ id: 'project.task.addlink' })}
		</Button>
	);
};

const ProjectKanbanCard = ({ projectId, projectTaskId, projectTaskName, costs, handleOpen }) => {
	return (
		<KanbanCard
			title={projectTaskName}
			cardMain={<TaskNameStyled component='span'>{projectTaskName}</TaskNameStyled>}
			link={`projects/${projectId}/${projectTaskId}`}
			onClick={handleOpen}
		>
			<PillContainer>{costs ? <ProjectTaskPill costs={costs} /> : null}</PillContainer>
		</KanbanCard>
	);
};

const ProjectTaskPill = ({ costs }) => {
	let amountCosts = 0;
	let amountApproved = 0;
	let amountReported = 0;

	const calculateCostsTotals = costs => {
		for (let i = 0; i < costs.length; i++) {
			amountApproved += costs[i].amountGranted ?? 0;
			amountReported += costs[i].amountReported ?? 0;

			amountCosts++;
		}
	};

	calculateCostsTotals(costs);

	/// hyväksytty ja raportoitu
	const total = amountReported / amountApproved;
	const gradient = '7,120,113';

	const baseGradient = `linear-gradient(90deg, rgba(${gradient},1) ${total * 100}%, rgba(255,255,255,0.24) ${total * 100}%)`;

	return (
		<>
			{amountCosts > 0 ? (
				<CostResults>
					<AssignmentIcon fontSize='small' />
					<span>{amountCosts}</span>
				</CostResults>
			) : null}
			<Pill>
				<Graph sx={{ background: baseGradient }}>&nbsp;</Graph>
			</Pill>
			<span>
				<FormatCurrency value={amountReported} />
			</span>
		</>
	);
};

const ProjectTaskCard = ({ project, task, costs, showModalButton = false, isColumnAdd = false }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const formRef = useRef();

	const [open, setOpen] = useState(false);
	const [activeTask, setActiveTask] = useState(task);

	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setActiveTask(task);
	};

	const [loading, setLoading] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [dirtyFields, setDirtyFields] = useState({});

	const setTaskLoading = async data => setLoading(data);

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
	const [isUnsavedConfirmOpen, setIsUnsavedConfirmOpen] = useState(false);
	const closeUnsavedConfirm = () => setIsUnsavedConfirmOpen(false);
	const closeUnsavedConfirmAndModal = () => {
		setIsUnsavedConfirmOpen(false);
		setOpen(false);
	};

	const confirm = e => {
		setConfirmOpen(true);
		setAnchorEl(e.currentTarget);
	};

	const handleDelete = async () => {
		const success = await dispatch(deleteProjectTask(activeTask.projectTaskId, project.projectId));

		if (success) {
			toast.success(intl.formatMessage({ id: 'project.task.delete.success' }));
			handleClose();

			return;
		}

		toast.error(intl.formatMessage({ id: 'project.task.delete.fail' }));
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const closeConfirm = () => {
		setAnchorEl(null);
		setConfirmOpen(false);
	};

	const [isPrompt, setIsPrompt] = useState(true);

	const handleDetailsUpdate = async data => {
		let savedTask = null;
		let isUpdate = false;
		setTaskLoading(true);

		if (data.projectTaskId) {
			isUpdate = true;
			savedTask = await dispatch(updateProjectTask(data));
		} else {
			setIsPrompt(false);
			savedTask = await dispatch(createProjectTask(data));
		}

		if (savedTask) {
			toast.success(intl.formatMessage({ id: 'project.task.success' }));
			setTaskLoading(false);
			setIsPrompt(true);
			setActiveTask(savedTask);

			if (!isUpdate) handleClose(); // close modal if not updating task

			return true;
		} else {
			setTaskLoading(false);

			setIsPrompt(true);
			toast.error(intl.formatMessage({ id: 'project.task.failed' }));

			return false;
		}
	};

	// is not update or not add button
	if (!activeTask?.projectTaskId && !showModalButton) return null;

	return (
		<>
			<TaskModalButton
				{...{
					projectId: project?.projectId,
					projectTaskId: task?.projectTaskId,
					projectTaskName: task?.projectTaskName,
					costs,
					activeTask,
					showModalButton,
					isColumnAdd,
					handleOpen,
				}}
			/>
			<ModalDialog
				onClose={handleClose}
				open={open}
				title={intl.formatMessage({ id: 'project.task.add.title' })}
				actions={
					<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
						{activeTask?.projectTaskId && (
							<Button onClick={e => confirm(e)}>{intl.formatMessage({ id: 'project.task.delete' })}</Button>
						)}
						<Button disabled={loading || !isDirty} color='success' onClick={submitForm}>
							{intl.formatMessage({ id: 'shared.save' })}
							{loading && <ButtonSpinner />}
						</Button>
					</Stack>
				}
			>
				<ProjectTask
					ref={formRef}
					project={project}
					task={activeTask}
					costs={costs}
					loaded={true}
					passIsDirty={(isDirty, dirtyFields) => {
						setIsDirty(isDirty);
						setDirtyFields(dirtyFields);
					}}
					setTaskLoading={setTaskLoading}
					isPrompt={isPrompt}
					setIsPrompt={setIsPrompt}
					onSubmit={handleDetailsUpdate}
				/>
				<ConfirmUnsaved
					open={isUnsavedConfirmOpen}
					handleCancel={closeUnsavedConfirm}
					handleConfirm={closeUnsavedConfirmAndModal}
					dirtyFields={dirtyFields}
					translation='project.task.form'
				/>
				<ConfirmPopper
					confirmAction={handleDelete}
					handleClose={closeConfirm}
					isopen={confirmOpen}
					anchorElement={anchorEl}
					confirmText={intl.formatMessage({ id: 'project.task.delete.confirmText' })}
					cancelText={intl.formatMessage({ id: 'project.task.delete.cancelText' })}
					confirmBodyText={intl.formatMessage({ id: 'project.task.delete.confirmBodyText' })}
				/>
			</ModalDialog>
		</>
	);
};

export default ProjectTaskCard;
