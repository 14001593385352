import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';

import Pagination from '@mui/material/Pagination';

export default function Paginator({ token, disabled }) {
	const apiRef = useGridApiContext();
	const page = useGridSelector(apiRef, gridPageSelector);
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	return (
		<Pagination
			disabled={disabled}
			size='small'
			color='primary'
			count={pageCount}
			page={page + 1}
			onChange={(_, value) => apiRef.current.setPage(value - 1)}
			showLastButton={!token}
			showFirstButton
		/>
	);
}
