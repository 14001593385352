export const currencyFormatter = new Intl.NumberFormat('fi-FI', {
	style: 'currency',
	currency: 'EUR',
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
});

export const percentFormatter = new Intl.NumberFormat('fi-FI', {
	style: 'percent',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const decimalFormatter = new Intl.NumberFormat('fi-FI', {
	style: 'decimal',
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
});

export const percentFormatterWithFractionDigits = new Intl.NumberFormat('fi-FI', {
	style: 'percent',
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
});

export const intFormatter = new Intl.NumberFormat('fi-FI', {
	style: 'decimal',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
	useGrouping: false,
});
