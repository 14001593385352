import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import Spinner from 'components/Spinner/CenteredSpinner';
import ConversationItem from './ConversationItem';

const ConversationList = ({
	loading,
	data,
	resolveConversation,
	deleteConversation,
	loadComments,
	addComment,
	deleteComment,
	goToTarget,
	isDashboard,
}) => {
	const intl = useIntl();

	if (loading) return <Spinner />;

	if (!data || data.length === 0)
		return (
			<Box display='flex' sx={{ justifyContent: 'center', p: 2 }}>
				<Typography sx={{ color: 'secondary.grey700' }}>{intl.formatMessage({ id: 'collaboration.no.conversations' })}</Typography>
			</Box>
		);

	return (
		<List
			sx={{
				direction: 'rtl',
				overflowY: 'auto',
				scrollbarGutter: 'stable',
				'&::-webkit-scrollbar': {
					width: '5px',
					height: '5px',
				},
				'&::-webkit-scrollbar-thumb': {
					// bgcolor: 'primary.grey',
				},
				py: 0,
			}}
		>
			{data?.map(
				({ conversationId, link, linkTitle, createdTime, author, content, commentsCount, commentAuthorsArray, isRead }, index) => {
					// TODO: change lastComment to firstComment
					return (
						<ConversationItem
							key={conversationId}
							{...{
								index,
								conversationId,
								link,
								linkTitle,
								createdTime,
								author,
								firstComment: content,
								commentsCount,
								commentAuthors: commentAuthorsArray,
								isRead,
								resolveConversation,
								deleteConversation,
								loadComments,
								addComment,
								deleteComment,
								goToTarget,
								isDashboard,
							}}
						/>
					);
				}
			)}
		</List>
	);
};

export default ConversationList;
