import { useIntl } from 'react-intl';

import { FileDownloadRounded } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { parseFromLicensedata } from './CSVByLicenseGenerator';
import { CSVGenerator } from './CSVGenerator';

export const downloadCSV = async (csvData: any, name: string) => {
	const blob = new Blob([csvData], { type: 'data:text/csv;charset=utf-8,' });
	const blobURL = window.URL.createObjectURL(blob);

	// Create new tag for download file
	const anchor = document.createElement('a');
	anchor.download = name;
	anchor.href = blobURL;
	anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':');
	anchor.click();

	// Remove URL.createObjectURL. The browser should not save the reference to the file.
	setTimeout(() => {
		// For Firefox it is necessary to delay revoking the ObjectURL
		URL.revokeObjectURL(blobURL);
	}, 100);
};

export const CSVLicenseDownloadButton = ({ data }: any) => {
	const intl = useIntl();

	return (
		<Button
			variant='contained'
			color='primary'
			startIcon={<FileDownloadRounded />}
			onClick={() => downloadCSV(parseFromLicensedata(data.mrrByCompany), 'licenseTable.csv')}
		>
			{intl.formatMessage({ id: 'mrr.all-licenses' })}
		</Button>
	);
};

const CSVDownloadButton = ({ data }: any) => {
	const intl = useIntl();

	if (!data) return null;

	return (
		<Box>
			<Button
				variant='contained'
				color='primary'
				startIcon={<FileDownloadRounded />}
				onClick={() => downloadCSV(CSVGenerator(data), 'mrrtable.csv')}
			>
				{intl.formatMessage({ id: 'mrr.loadCsv' })}
			</Button>
		</Box>
	);
};

export default CSVDownloadButton;
