import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { CompanyNote } from 'types/dataTypes';
import { mergeFieldValues } from 'utils/misc/formUtils';

import companyNoteBriefTemplate from 'containers/Admin/UsersCompany/CompanyDetails/CompanyNotes/companyNoteBriefTemplate';

import { FormControlledInput, FormControlledSelect } from 'components/Form';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';

interface FieldValues extends Partial<CompanyNote> {}

interface Props {
	companyNote?: CompanyNote | null;
	onDirtyChange: (isDirty: boolean, dirtyFields: { [key: string]: boolean }) => void;
	onFormSubmit: (fieldValues: FieldValues) => void;
}

const UpsertCompanyNoteForm = React.forwardRef<HTMLFormElement, Props>(({ companyNote, onDirtyChange, onFormSubmit }, formRef) => {
	const { formatMessage } = useIntl();

	const validationSchema = React.useMemo(() => {
		return Yup.object().shape({
			content: Yup.string().required(),
			category: Yup.string().required(),
		});
	}, []);

	const defaultValues = React.useMemo<FieldValues>(
		() => ({
			companyNotesId: null,
			companyId: null,
			content: '',
			category: '',
		}),
		[]
	);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting, isDirty, dirtyFields },
	} = useForm<FieldValues>({
		defaultValues,
		resolver: yupResolver(validationSchema),
	});

	React.useEffect(() => {
		onDirtyChange?.(isDirty, dirtyFields);
	}, [onDirtyChange, isDirty, dirtyFields]);

	React.useEffect(() => {
		reset(mergeFieldValues(defaultValues, companyNote));
	}, [reset, defaultValues, companyNote]);

	return (
		<Box component='form' onSubmit={handleSubmit(onFormSubmit)} sx={{ width: '100%', height: '100%' }} ref={formRef}>
			<Stack flexDirection='column' spacing={1} useFlexGap>
				<Stack sx={{ flex: '1 0 auto', overflowY: 'auto' }} flexDirection='column' spacing={2} useFlexGap>
					<Box sx={{ width: '100%', maxWidth: '20rem' }}>
						<FormControlledSelect
							control={control}
							name='category'
							defaultValue={companyNote?.category ?? ''}
							disabled={isSubmitting}
							error={errors?.category?.message}
							label={formatMessage({ id: 'company.notes.form.category' })}
							options={[
								{ value: 'general', label: formatMessage({ id: 'company.notes.form.category.general.label' }) },
								{ value: 'brief', label: formatMessage({ id: 'company.notes.form.category.brief.label' }) },
								{ value: 'start', label: formatMessage({ id: 'company.notes.form.category.start.label' }) },
								{ value: 'financePlan', label: formatMessage({ id: 'company.notes.form.category.financePlan.label' }) },
							]}
							onInputChange={(event: any) => {
								const nextCategory = event?.target?.value;
								if (nextCategory === 'brief') {
									setValue('content', companyNoteBriefTemplate, { shouldDirty: true });
								}
							}}
						/>
					</Box>
					{/* @ts-ignore */}
					<FormControlledInput
						defaultValue={companyNote?.title ?? ''}
						name='title'
						control={control}
						error={errors?.title?.message}
						label={formatMessage({ id: 'company.notes.form.title' })}
					/>
					{/* @ts-ignore */}
					<FormControlledWysiwyg
						control={control}
						name='content'
						defaultValue={companyNote?.content ?? ''}
						error={errors?.content?.message}
						disabled={isSubmitting}
						label={formatMessage({ id: 'company.notes.form.content' })}
						placeholder={formatMessage({ id: 'company.notes.form.content.placeholder' })}
						marginTop={0}
						charactersLimit={30000}
						toolbar
						inline
						autoresize
					/>
				</Stack>
			</Stack>
		</Box>
	);
});

export default UpsertCompanyNoteForm;
