import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    input: {
        fontSize: '1.4rem',
        fontWeight: 500,
        color: theme.palette.primary.main,
        padding: theme.spacing(1.5),
        '&.Mui-disabled': {
            '-webkit-text-fill-color': theme.palette.primary.main,
        },
    },
}));

const EditableField = ({ active, conversationTitle = '', updateConversationTitle /*conversationTitleLoading*/ }) => {
    const classes = useStyles();
    const [title, setTitle] = useState(conversationTitle);

    useEffect(() => {
        setTitle(conversationTitle);
    }, [conversationTitle]);

    const onChange = event => setTitle(event.target.value);

    const onSave = () => {
        if (!title) setTitle(conversationTitle);
        if (title && title !== conversationTitle) updateConversationTitle(title, active);
    };

    const onKeyDown = event => {
        if (event.key === 'Escape' || event.key === 'Enter') {
            event.target.blur();
        }
    };

    return (
        <TextField
            variant='standard'
            value={title}
            onChange={onChange}
            onBlur={onSave}
            onKeyDown={onKeyDown}
            fullWidth
            disabled
            InputProps={{
                disableUnderline: true,
                classes: {
                    input: classes.input,
                },
            }}
        />
    );
};

export default EditableField;
