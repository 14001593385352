import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import WidgetContainer from 'components/Containers/WidgetContainer';
import AttachmentList from 'components/AttachmentList';
import Attachments from 'components/Attachments';

import { addAttachment, getAttachment } from '../slices';
import { Typography } from '@mui/material';

const AttachmentPanel = ({ attachmentsLoaded, attachments, attachmentsLoading, removeFile, readonly }) => {
    const dispatch = useDispatch();
    // const hasAttachments = attachments?.length > 0;

    const [loading, setLoading] = useState(false);

    const setValue = async (_, files) => {
        setLoading(true);
        const success = await dispatch(addAttachment(files));
        setLoading(false);

        if (success) {
            toast.success(<FormattedMessage id='attachments.success' />);
            return;
        }

        toast.error(<FormattedMessage id='attachments.failed' />);
    };

    const AttachmentAmount = ({ attachments }) => {
        if (!attachments || !attachments?.length || attachments?.length === 0) return null;

        return <Typography component='span' sx={{ display: 'inline-block', fontSize: '0.9rem' }}>&nbsp;({attachments?.length})</Typography>;
    };

    const getAttachmentFile = async attachmentId => getAttachment(attachmentId);

    return (
        <WidgetContainer
            borderRadius={0}
            title={
                <>
                    <FormattedMessage id='attachments.title' />
                    <AttachmentAmount attachments={attachments} />
                </>
            }
            // footerContent={hasAttachments && <FormattedMessage id='attachments.footer' />}
            link={<FormattedMessage id='attachments.nolink' />}
            padding={2}
            fontWeight={400}
        >
            <AttachmentList {...{ attachmentsLoaded, attachments, attachmentsLoading, removeFile, getFile: getAttachmentFile }} />
            {!readonly && <Attachments loading={loading} formLabel={false} setValue={setValue} showFiles={false} />}
        </WidgetContainer>
    );
};

export default AttachmentPanel;