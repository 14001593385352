import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

import { CompanyNote } from 'types/dataTypes';

interface FetchCompanyNotes {
	companyId: string | null;
}

interface FetchCompanyNote {
	companyId: string | null;
	companyNotesId: string | null;
}

interface UpsertCompanyNote {
	companyId: string | null;
	companyNote: Partial<CompanyNote> | null;
}

interface DeleteCompanyNote {
	companyId: string | null;
	companyNotesId: string | null;
}

const Tags = {
	CompanyNote: 'companyNote',
};

const baseUrl = 'notes';

export const companyNotesV2Api = createApi({
	reducerPath: 'companyNotesV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.CompanyNote],
	endpoints: builder => ({
		fetchCompanyNotes: builder.query<CompanyNote[], FetchCompanyNotes>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}`,
				};
			},
			providesTags: () => {
				return [{ type: Tags.CompanyNote, id: 'LIST' }];
			},
		}),
		fetchCompanyNote: builder.query<CompanyNote, FetchCompanyNote>({
			query: ({ companyId, companyNotesId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/${companyNotesId}`,
				};
			},
			providesTags: companyNote => {
				return [{ type: Tags.CompanyNote, id: companyNote?.companyNotesId ?? '' }];
			},
		}),
		upsertCompanyNote: builder.mutation<CompanyNote, UpsertCompanyNote>({
			query: ({ companyId, companyNote }) => {
				if (isNil(companyId) || isNil(companyNote)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/${companyId}`,
					data: companyNote,
				};
			},
			invalidatesTags: companyNotes => {
				return [
					{ type: Tags.CompanyNote, id: companyNotes?.companyId ?? '' },
					{ type: Tags.CompanyNote, id: 'LIST' },
				];
			},
		}),
		deleteCompanyNote: builder.mutation<CompanyNote, DeleteCompanyNote>({
			query: ({ companyId, companyNotesId }) => {
				if (isNil(companyId) || isNil(companyNotesId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'DELETE',
					url: `/${companyId}/${companyNotesId}`,
				};
			},
			invalidatesTags: companyNotes => {
				return [
					{ type: Tags.CompanyNote, id: companyNotes?.companyId ?? '' },
					{ type: Tags.CompanyNote, id: 'LIST' },
				];
			},
		}),
	}),
});

export const { useFetchCompanyNotesQuery, useFetchCompanyNoteQuery, useUpsertCompanyNoteMutation, useDeleteCompanyNoteMutation } =
	companyNotesV2Api;
