import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactToPrint } from 'react-to-print';
import Box from '@mui/material/Box';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PrintIcon from '@mui/icons-material/Print';

import DropDownMenuButton from 'components/Button/DropDownMenuButton';


const AnalysisDropdownMenu = ({ company, printRef }) => {
    const intl = useIntl();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `${intl.formatMessage({ id: 'breadcrumb.analysis' })}: ${company?.name}, ${company?.businessId}`,
    });

    const menu = [
        {
            text: <FormattedMessage id='analysis.menu.print' />,
            icon: <PrintIcon />,
            onClick: () => handlePrint()
        },
    ];

    return (
        <Box
            sx={{
                '@media print': {
                    display: 'none'
                }
            }}
        >
            <DropDownMenuButton
                icon={<MoreHorizIcon />}
                light={true}
                text={<FormattedMessage id='project.dashboard.menu' />}
                items={menu}
                loading={false}
            />
        </Box>
    );
};

export default AnalysisDropdownMenu;
