import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

const baseUrl = 'stakeholder';

export const stakeholderApi = createApi({
	reducerPath: 'stakeholder',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: ['Stakeholder'],
	endpoints: builder => ({
		getCompanySharedLinks: builder.query({
			query: companyId => {
				return {
					method: 'GET',
					url: `/link/${companyId}`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getProfileDataLinkParts: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/parts`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getProfileDataLink: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getCompanyData: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/companydata`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getScoreData: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/score`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getAnalysisData: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/analysis`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getInstrumentsData: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/instruments`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getTimelineData: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/financialplan`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getProjectData: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/projects`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getProjectDataById: builder.query({
			query: ({ linkId, projectId }) => {
				return {
					method: 'GET',
					url: `/${linkId}/projects/${projectId}`,
				};
			},
			providesTags: (_result, _err, data) => [
				{
					type: 'Stakeholder',
					projectId: data?.project?.projectId,
				},
			],
		}),
		getScenarioData: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/scenario`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getBusinessPlanData: builder.query({
			query: linkId => {
				return {
					method: 'GET',
					url: `/${linkId}/businessplan`,
				};
			},
			providesTags: ['Stakeholder'],
		}),
		getBaseData: builder.query({
			query: () => {
				return {
					method: 'GET',
					url: '/base',
				};
			},
			providesTags: ['Stakeholder'],
		}),
		switchReceiverDisabledStatus: builder.mutation({
			query: ({ companyId, receiverId }) => {
				return {
					method: 'POST',
					url: `/${companyId}/receiver/${receiverId}`,
				};
			},
			invalidatesTags: ['Stakeholder'],
		}),
		disableLink: builder.mutation({
			query: ({ companyId, linkId }) => {
				return {
					method: 'POST',
					url: `/${companyId}/${linkId}`,
				};
			},
			invalidatesTags: ['Stakeholder'],
		}),
		saveShareLink: builder.mutation({
			query: ({ companyId, linkData }) => {
				const url = linkData?.sharedData?.includes('Project') ? `/${companyId}/project/${linkData?.project}` : `/${companyId}`;
				return {
					method: 'POST',
					url,
					data: linkData,
				};
			},
			invalidatesTags: ['Stakeholder'],
		}),
	}),
});

export const {
	useGetCompanySharedLinksQuery,
	useGetProfileDataLinkQuery,
	useGetProfileDataLinkPartsQuery,
	useGetScoreDataQuery,
	useGetAnalysisDataQuery,
	useGetCompanyDataQuery,
	useGetInstrumentsDataQuery,
	useGetBaseDataQuery,
	useGetTimelineDataQuery,
	useGetProjectDataQuery,
	useGetScenarioDataQuery,
	useGetBusinessPlanDataQuery,
	useSwitchReceiverDisabledStatusMutation,
	useDisableLinkMutation,
	useSaveShareLinkMutation,
	useGetProjectDataByIdQuery,
} = stakeholderApi;
