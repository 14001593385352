export const combineEconomyData = (companyEconomicalData = [], economicalData = []) => {
	let ret = [...(companyEconomicalData ?? [])];

	for (let item of economicalData ?? []) {
		if (companyEconomicalData?.findIndex(dataItem => dataItem.year === item.year) >= 0) continue;

		ret.push(Object.assign({ isEditable: true }, item));
	}

	ret.sort((a, b) => a.year - b.year);
	const last = ret[ret.length - 1];

	const currentYear = new Date().getFullYear();
	const lastPossibleYear = currentYear + 2;

	const lastYear = last?.year ?? currentYear - 1;

	const yearsToAdd = lastPossibleYear - lastYear;
	const firstYearToAdd = currentYear - (currentYear - lastYear) + 1;

	for (let i = 0; i < yearsToAdd; i++) {
		ret.push({ isEditable: true, year: firstYearToAdd + i });
	}

	return ret;
};

export const parseEconomicalData = economicalData => {
	let ret = [];

	for (let item of economicalData ?? []) {
		ret.push(Object.assign({ isEditable: true }, item));
	}

	ret.sort((a, b) => a.year - b.year);
	const last = ret[ret.length - 1];

	const currentYear = new Date().getFullYear();
	const lastPossibleYear = currentYear + 2;

	const lastYear = last?.year ?? currentYear - 1;

	const yearsToAdd = lastPossibleYear - lastYear;
	const firstYearToAdd = currentYear - (currentYear - lastYear) + 1;

	for (let i = 0; i < yearsToAdd; i++) {
		ret.push({ isEditable: true, year: firstYearToAdd + i });
	}

	return ret;
};
