export type FinancialReportKeysType = {
	title: string;
	key: string;
};

export const financialReportKeys: FinancialReportKeysType[] = [
	{
		title: 'Tilikausi',
		key: 'year',
	},
	{
		title: 'Henkilöstö',
		key: 'employees',
	},
	{
		title: 'Liikevaihto',
		key: 'turnover',
	},
	{
		title: 'Tilikauden voitto',
		key: 'profit',
	},
	{
		title: 'T&K kustannukset',
		key: 'developmentCosts',
	},
	{
		title: 'Vienti',
		key: 'export',
	},
	{
		title: 'Taseen loppusumma',
		key: 'balance',
	},
	{
		title: 'Oma pääoma',
		key: 'equity',
	},
	{
		title: 'Sijoitukset omaan pääomaan',
		key: 'equityInvestments',
	},
	{
		title: 'Osakepääoma tai muu vastaava sidottu pääoma',
		key: 'otherCapital',
	},
	{
		title: 'Pääomalaina',
		key: 'capitalLoan',
	},
];

export type FinancialReport = {
	index: number;
	id: string;
	fiscalYear: string;
	year: any;
	month: number;
	turnover: number;
	turnoverChange: number;
	operatingMargin: number;
	operatingProfit: number;
	profit: number;
	profits: number;
	quickRatio: number;
	currentRatio: number;
	equity: number;
	balanceSheetTotal: number;
	balance: number;
	staff: number;
	employees: number;
	staffChange: number;
	turnoverPerPerson: number;
	solvency: number;
	equityRatio: number;
	ebitda: number;
	ebitdas: number;
	ebit: number;
	magic40: number;
};

const scaleFieldsBy1000 = (report: FinancialReport, fields: (keyof FinancialReport)[]): FinancialReport => {
	const scaledReport = { ...report }; // create a shallow copy to avoid mutating the original object
	fields.forEach(field => {
		if (typeof scaledReport[field] === 'number') {
			// @ts-ignore
			scaledReport[field] *= 1000;
		}
	});
	return scaledReport;
};

export const formatEconomicalData = (report: FinancialReport) => {
	if (!report) return null;

	const scaledReport = scaleFieldsBy1000(report, [
		'balance',
		'balanceSheetTotal',
		'operatingMargin',
		'turnover',
		'profit',
		'profits',
		'equity',
		'turnoverPerPerson',
	]);

	return scaledReport;
};

export const formatEconomicalDataArray = (reports: FinancialReport[]) => {
	if (!reports) return [];

	let data = reports.map(report => formatEconomicalData(report));

	return data?.sort((a, b) => a?.year - b?.year);
};
