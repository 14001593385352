import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Pill from './Pill';

const ScoreHeader = ({ type, score, scoreLevel, level }) => {
    const intl = useIntl();

    const title = intl.formatMessage({ id: 'analysis.granted.score' });
    const typeT = intl.formatMessage({ id: `analysis.type.${type}` });

    return (
        <Grid container alignItems='center' justifyContent='space-between' sx={{ p: 3 }}>
            <Grid item>
                <Typography color='primary' sx={{ fontWeight: 600 }}>
                    {type === 'general' ? title : `${title} - ${typeT}`}
                </Typography>
            </Grid>
            <Grid item sx={{ width: '7rem' }}>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item>
                        <Pill score={score} scoreLevel={scoreLevel} level={level} />
                    </Grid>
                    <Grid item>
                        <Typography color='primary'>
                            {score}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default ScoreHeader;
