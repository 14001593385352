import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ProjectTask from 'containers/Projects/Project/Task/ProjectTask';
import { useFetchProjectQuery } from 'containers/Projects/projectRTKApi';
import { projectCostSelector, projectTaskSelector } from 'containers/Projects/slices';
import { costTaskSelector } from 'containers/Projects/utils';
import { isAdminToken } from 'utils/auth/token';
import { getCompanyId } from '../../../utils/auth/company';

const ProjectTaskPage = ({ passPageProps }) => {
	const { id, taskId } = useParams();
	const companyId = getCompanyId();
	const isAdmin = isAdminToken();

	const {
		data: project,
		isLoading: isProjectLoading,
		isFetching: isProjectFetching,
	} = useFetchProjectQuery({ companyId, projectId: id, draft: isAdmin, withData: true }, { skip: !companyId || !id });

	const tasks = projectTaskSelector(project);
	const costs = projectCostSelector(project);

	const loaded = !isProjectLoading && !isProjectFetching;
	const task = tasks?.find(item => item.projectTaskId === taskId);

	useEffect(() => {
		if (project && task) {
			passPageProps([
				{ id: project?.projectId, name: project?.projectName },
				{ id: task?.projectTaskId, name: task?.projectTaskName },
			]);
		} else if (project) {
			passPageProps([{ id: project?.projectId, name: project?.projectName }]);
		}
	}, [passPageProps, project, task]);

	if (!tasks?.isLoaded) return null;

	return <ProjectTask project={project} task={task} costs={costTaskSelector(costs, task?.projectTaskId)} loaded={loaded} />;
};

export default ProjectTaskPage;
