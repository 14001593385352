import React from 'react';
import { useIntl } from 'react-intl';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/styles';

import { renderKeys } from '../../renderKeys';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    backgroundColor: theme.palette.primary.white,
    marginTop: '0.5rem',
}));

const PrintEconomicalTable = ({ data }) => {
    const intl = useIntl();
    const currentYear = new Date().getFullYear();

    return (
        <StyledTableContainer>
            <Grid container sx={{ color: 'primary.main' }}>
                <Grid item xs sx={{ mr: '1rem', minWidth: '15rem' }}>
                    {renderKeys.map((name, itemIndex) => {
                        return (
                            <Box sx={{ pl: '1rem', pt: '0.3rem', height: '2.4rem', fontWeight: 500 }} key={name + itemIndex}>
                                {intl.formatMessage({ id: `business.plan.economical.table.${name}` })}
                            </Box>
                        );
                    })}
                </Grid>
                {data.map((field, index) => {
                    return (
                        <Grid item xs key={field + index} sx={{ mr: '1rem' }}>
                            {renderKeys.map((item, itemIndex) => {
                                if (item === 'year') {
                                    return (
                                        <React.Fragment key={field[item] + itemIndex}>
                                            <Box sx={{
                                                pt: '0.3rem',
                                                height: '2.4rem',
                                                fontWeight: field.year === currentYear ? 700 : 500,
                                            }}>
                                                {field[item]}
                                            </Box>
                                        </React.Fragment>
                                    );
                                }

                                if (field.isEditable) {
                                    return (
                                        <React.Fragment key={item + itemIndex}>
                                            <Box sx={{ pt: '0.3rem', height: '2.4rem' }}>
                                                {0}
                                            </Box>
                                        </React.Fragment>
                                    );
                                }

                                return (
                                    <React.Fragment key={field[item] + itemIndex}>
                                        <Box sx={{ pt: '0.3rem', height: '2.4rem' }}>
                                            {Math.round(field[item])}
                                        </Box>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    );
                })}
            </Grid>
        </StyledTableContainer>
    );
};

export default PrintEconomicalTable;
