import React from 'react';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import Spinner from 'components/Spinner/Spinner';
import BlurredBox from 'components/BlurredBox';
import htmlParser from 'components/HtmlParser';

const transform = node => {
    if (node.type === 'tag' && node.name === 'br') {
        return null;
    }
};

const DataItem = ({ intl, theme, title, loading, text }) => {
    return (
        <Box>
            <Typography
                color='primary'
                gutterBottom
                sx={{
                    fontWeight: 600,
                    mt: 2
                }}
            >
                {intl.formatMessage({ id: `businessplan.${title}` })}
            </Typography>
            {loading
                ? <Spinner size={16} />
                : <BlurredBox
                    maxHeight='3rem'
                    offset={0.5}
                    sx={{
                        '&>p': {
                            color: theme.palette.secondary.grey600,
                        }
                    }}
                >
                    {text ? htmlParser(text, { transform }) : '-'}
                </BlurredBox>
            }
            <Divider sx={{ mt: 2 }} />
        </Box>
    );
};

export default DataItem;