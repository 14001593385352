import { isNil } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert, AlertTitle, Box, BoxProps, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import { getCompany, getCompanyId } from 'utils/auth/company';

import { useFetchRfpProposalQuery, useFetchRfpRequestProposalQuery } from 'containers/Marketplace/rfpProposalApi';
import { useFetchRfpRequestQuery } from '../../rfpRequestApi';

import ReadRfpProposalAttachmentList from 'containers/Marketplace/proposal/Read/Attachments/ReadRfpProposalAttachmentList';
import RfpProposalTeamMemberList from 'containers/Marketplace/proposal/Read/Team/RfpProposalTeamMemberList';
import RfpPrintButton from '../../common/RfpPrintButton';
import CompanyProfileBadge from '../../profile/CompanyProfileBadge';
import RfpProposalItemList from './Items/RfpProposalItemList';

type Props = BoxProps & {
	requestId: string | null;
	proposalId: string | null;
	published: boolean;
	createdByMe: boolean;
	sx?: any;
};

const PrintRfpProposal: React.FC<Props> = ({ requestId, proposalId, published, createdByMe, sx = {}, ...otherProps }) => {
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const { data: rfpProposal1, isError: isRfpProposalError1 } = useFetchRfpProposalQuery(
		{ companyId, proposalId, published },
		{ skip: isNil(companyId) || isNil(requestId) || !createdByMe }
	);

	const { data: rfpProposal2, isError: isRfpProposalError2 } = useFetchRfpRequestProposalQuery(
		{ companyId, requestId, proposalId },
		{ skip: isNil(companyId) || isNil(requestId) || isNil(proposalId) || createdByMe }
	);

	const { data: rfpRequest, isError: isRfpRequestError } = useFetchRfpRequestQuery(
		{ companyId, requestId, published: true },
		{ skip: isNil(companyId) || isNil(requestId) }
	);

	const rfpProposal = rfpProposal1 ?? rfpProposal2;
	const isRfpProposalError = isRfpProposalError1 ?? isRfpProposalError2;

	return (
		<Box sx={sx}>
			<RfpPrintButton documentTitle={<FormattedMessage id='rfpRequest.title' />}>
				<Box {...otherProps}>
					<DialogTitle>
						<Typography component='span' sx={{ fontWeight: 600 }}>
							<FormattedMessage id='rfpProposal.title' /> -
						</Typography>
						<Typography component='span' sx={{ ml: '.5rem' }}>
							{rfpRequest?.projectTitle} -
						</Typography>
						<Typography component='span' sx={{ ml: '.5rem', fontWeight: 600 }}>
							{rfpProposal?.companyName}
						</Typography>
					</DialogTitle>
					<DialogContent>
						{isRfpProposalError && (
							<Alert severity='error'>
								<AlertTitle>
									<FormattedMessage id='query.error.title' />
								</AlertTitle>
								<FormattedMessage id='fetchRfpProposal.error.message' />
							</Alert>
						)}
						{isRfpRequestError && (
							<Alert severity='error'>
								<AlertTitle>
									<FormattedMessage id='query.error.title' />
								</AlertTitle>
								<FormattedMessage id='fetchRfpRequest.error.message' />
							</Alert>
						)}
						<Stack spacing={3}>
							<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
								<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpProposal.projectOutline.label' />
										</Typography>
										<Typography>{rfpProposal?.projectOutline}</Typography>
									</Box>
									<Box>
										<RfpProposalTeamMemberList print teamMembers={rfpProposal?.rfpProposalTeamMembers} />
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpProposal.projectBudget.label' />
										</Typography>
										<Typography>{rfpProposal?.projectBudget}</Typography>
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpProposal.projectTimeline.label' />
										</Typography>
										<Typography>{rfpProposal?.projectTimeline}</Typography>
									</Box>
									<Box>
										<RfpProposalItemList print rfpProposalItems={rfpProposal?.rfpProposalItems} />
									</Box>
									<Box>
										<Typography variant='h4'>
											<FormattedMessage id='rfpProposal.contactInformation.label' />
										</Typography>
										<Typography>{rfpProposal?.contactInformation}</Typography>
									</Box>
								</Stack>
								<Stack flex='1' maxWidth={{ xs: '100%', md: '40%' }}>
									<CompanyProfileBadge companyId={rfpProposal?.companyId} print />
									<ReadRfpProposalAttachmentList
										requestId={requestId}
										proposalId={rfpProposal?.rfpProposalId}
										createdByMe={createdByMe}
										attachments={rfpProposal?.attachments}
									/>
								</Stack>
							</Stack>
						</Stack>
					</DialogContent>
				</Box>
			</RfpPrintButton>
		</Box>
	);
};

export default PrintRfpProposal;
