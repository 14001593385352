import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { resetSelectedCompany, searchCompanyData } from './slices';

import LightTooltip from 'components/Tooltips/LightTooltip';
import { BusinessIdChip } from '../../views/Dashboard/HeaderTitle/HeaderTitle';

const useStyles = makeStyles(theme => ({
	input: {
		// TODO: check this important styles (if any other way to get rid of them)
		'& .MuiAutocomplete-inputRoot': {
			padding: '0.25rem 0.5rem',
			paddingRight: '1.5rem !important',
		},
		'& input': {
			backgroundColor: theme.palette.primary.white,
			paddingTop: '4.5px !important',
			paddingBottom: '4.5px !important',
		},
	},
	paper: {
		backgroundColor: theme.palette.primary.white,
		'& [aria-selected="true"]': {
			backgroundColor: theme.palette.primary.white,
		},
		'& [aria-focus="true"]': {
			backgroundColor: theme.palette.primary.white,
		},
		'& [aria-selected="true"]:hover, & [aria-focus="true"]:hover': {
			cursor: 'default',
		},
		'& .MuiAutocomplete-option[aria-disabled="true"]': {
			opacity: 1,
		},
		'& .MuiAutocomplete-option[aria-selected="true"]': {
			backgroundColor: theme.palette.primary.white,
		},
	},
	text: {
		color: theme.palette.primary.main,
	},
	popupIndicator: {
		display: 'none',
	},
	noOptions: {
		display: 'none',
	},
	loading: {
		display: 'none',
	},
	customerName: {
		fontSize: '1rem',
		fontWeight: 600,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
}));

const CompanySearch = ({ customers, disabled = false, passIsDirty = null, passOption = null }) => {
	const classes = useStyles();
	const intl = useIntl();
	const dispatch = useDispatch();
	const companySearch = useSelector(state => state.companySearch);

	const loading = companySearch?.loading;
	const companies = companySearch?.companies;
	const isOptionBusinessId = companySearch?.isOptionBusinessId;

	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [debouncedValue] = useDebounce(inputValue, 500);
	const [delayedDebouncedValue] = useDebounce(debouncedValue, 250); // required delay for rendering no items text

	const booleanValue = !!inputValue && value && !!Object.keys(value).length;

	useEffect(() => {
		if (passIsDirty) {
			passIsDirty(booleanValue);
		}
	}, [booleanValue, passIsDirty]);

	useEffect(() => {
		if (debouncedValue === '' && companies?.length > 0) dispatch(resetSelectedCompany());
	}, [dispatch, debouncedValue, companies]);

	useEffect(() => {
		if (debouncedValue?.length > 2 && inputValue !== value?.name && debouncedValue !== value?.name) {
			dispatch(searchCompanyData(debouncedValue));
		} else {
			dispatch(resetSelectedCompany());
		}
	}, [debouncedValue, dispatch]); // eslint-disable-line

	const getOptionLabel = option => {
		if (isOptionBusinessId) {
			return option?.companyId ?? option?.businessId ?? option;
		} else {
			return option?.name ?? option;
		}
	};

	const getOptionDisabled = option => customers.some(e => e.customerCompanyId === option.businessId);
	const disableEvents = event => event.stopPropagation();

	// controlled tooltip
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const renderInput = params => {
		return (
			<LightTooltip
				open={!value && !disabled && open}
				onOpen={handleOpen}
				onClose={handleClose}
				title={intl.formatMessage({ id: 'company.search.tooltip' })}
				arrow
			>
				<TextField
					{...params}
					variant='outlined'
					placeholder={intl.formatMessage({ id: 'company.search.placeholder' })}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon color={disabled ? 'secondary' : 'primary'} />
							</InputAdornment>
						),
						endAdornment: (
							<>
								{loading ? <CircularProgress color='primary' size={20} sx={{ mr: 1 }} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
						classes: {
							input: classes.text,
						},
					}}
					classes={{
						root: classes.input,
					}}
				/>
			</LightTooltip>
		);
	};

	const renderOption = (props, option) => {
		if (inputValue?.length > 2) {
			return (
				<ListItem key={option.businessId} onClick={disableEvents} alignItems='center' {...props}>
					<ListItemText
						primary={
							<Typography
								component='span'
								color='primary'
								className={classes.customerName}
								sx={{
									opacity: customers.some(e => e.customerCompanyId === option.businessId) ? 0.5 : 1,
								}}
							>
								{option.name}
								<BusinessIdChip sx={{ ml: '.5rem' }} businessId={option.companyId} />
							</Typography>
						}
					/>
				</ListItem>
			);
		}
	};

	return (
		<>
			<Autocomplete
				freeSolo
				fullWidth
				loading={loading}
				disabled={disabled}
				clearOnEscape
				clearOnBlur
				getOptionLabel={getOptionLabel}
				getOptionDisabled={getOptionDisabled}
				options={companies && !(companies.code < 200 || companies.code > 299) ? companies : []}
				value={value}
				inputValue={inputValue}
				onChange={(_, value) => {
					setValue(value);
					passOption && passOption(value);
				}}
				onBlur={() => !inputValue && setValue(null)}
				onInputChange={(_, value) => setInputValue(value)}
				renderInput={renderInput}
				renderOption={renderOption}
				classes={{
					paper: classes.paper,
					noOptions: classes.noOptions,
					loading: classes.loading,
					popupIndicator: classes.popupIndicator,
				}}
			/>
			{!value &&
				inputValue.length > 2 &&
				debouncedValue.length > 2 &&
				delayedDebouncedValue.length > 2 &&
				!loading &&
				companies?.length === 0 && (
					<Typography color='primary' sx={{ mt: 2 }}>
						{intl.formatMessage({ id: 'filter.no.items' })}
					</Typography>
				)}
		</>
	);
};

export default CompanySearch;
