import { cloneDeep, isEmpty, omit } from 'lodash';
import * as React from 'react';

import { isAdminToken } from 'utils/auth/token';
import storage from 'utils/localStorage/localStorageFunctions';

export type StorageKey = 'business_plan_backup' | 'project_backup' | 'financial_plan_backup';

export const useLocalBackup = (storageKey: StorageKey, localBackupKey: string, initialData: any) => {
	const [data, setData] = React.useState();
	const [isLocalBackup, setIsLocalBackup] = React.useState(false);

	React.useEffect(() => {
		const storageData = storage.get(storageKey);
		const localBackupData = storageData?.[localBackupKey];

		if (!localBackupKey) {
			setData(cloneDeep(initialData));
			setIsLocalBackup(false);
		} else if (!isEmpty(initialData) && isEmpty(localBackupData)) {
			setData(cloneDeep(initialData));
			setIsLocalBackup(false);
		} else if (!isEmpty(initialData) && localBackupData.eTag !== initialData.eTag) {
			setData(cloneDeep(initialData));
			setIsLocalBackup(false);
		} else {
			setData(cloneDeep(localBackupData));
			setIsLocalBackup(true);
		}
	}, [storageKey, localBackupKey, initialData]);

	const deleteLocalBackup = React.useCallback(() => {
		const storageData = storage.get(storageKey);
		storage.set(storageKey, omit(storageData, localBackupKey));
		setIsLocalBackup(false);
	}, [storageKey, localBackupKey]);

	const updateLocalBackup = React.useCallback(
		(nextLocalBackupData: any) => {
			if (!localBackupKey) return;

			const storageData = storage.get(storageKey);
			setIsLocalBackup(true);
			storage.set(storageKey, {
				...(storageData ?? {}),
				[localBackupKey]: nextLocalBackupData,
			});
		},
		[storageKey, localBackupKey]
	);

	if (!isAdminToken()) {
		return { data: initialData, isLocalBackup: false, deleteLocalBackup: () => {}, updateLocalBackup: () => {} };
	}

	return { data, isLocalBackup, deleteLocalBackup, updateLocalBackup };
};
