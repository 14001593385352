import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

import { getCompanyId } from 'utils/auth/company';
import { useUpsertProjectSupplementaryMutation } from 'containers/Projects/projectSupplementaryApi';

import ModalDialog from 'components/Dialog/ModalDialog';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import ProjectSupplementaryForm from 'containers/Projects/Project/ProjectSupplementaryList/ProjectSupplementaryForm/ProjectSupplementaryForm';
import AddButton from 'components/Button/AddButton';

const AddProjectSupplementary = ({ project }) => {
	const { formatMessage } = useIntl();
	const formRef = useRef();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [dirtyFields, setDirtyFields] = useState({});
	const [isUnsavedConfirmOpen, setIsUnsavedConfirmOpen] = useState(false);

	const companyId = getCompanyId();
	const projectId = project?.projectId;

	const [upsertSupplementary] = useUpsertProjectSupplementaryMutation();

	const handleOpen = () => setOpen(true);

	const handleClose = hideUnsavedConfirm => {
		if (isDirty && !hideUnsavedConfirm) {
			setIsUnsavedConfirmOpen(true);
		} else {
			setOpen(false);
		}
	};

	const onSubmit = async data => {
		setLoading(true);
		await handleUpsert(data);
		setLoading(false);
	};

	const handleUpsert = async supplementary => {
		try {
			await upsertSupplementary({ companyId, projectId, supplementary });
			handleClose?.(true);
			toast.success(formatMessage({ id: 'project.supplementary.form.success' }));
		} catch (err) {
			console.error(err);
			toast.error(formatMessage({ id: 'project.supplementary.form.fail' }));
		}
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const closeUnsavedConfirm = () => setIsUnsavedConfirmOpen(false);

	const closeUnsavedConfirmAndModal = () => {
		setIsUnsavedConfirmOpen(false);
		setOpen(false);
	};

	const initialSupplementaryInformation = {};

	return (
		<>
			<AddButton color='primary' onClick={handleOpen} sx={{ mt: 2 }}>
				<FormattedMessage id='project.supplementary.add' />
			</AddButton>
			<ModalDialog
				onClose={() => handleClose(false)}
				open={open}
				title={<FormattedMessage id='project.supplementary.add.title' />}
				actions={
					<LeftRightContainer
						left={null}
						right={
							<Button disabled={loading || !isDirty} color='success' onClick={submitForm}>
								{formatMessage({ id: 'shared.save' })}
								{loading && <ButtonSpinner />}
							</Button>
						}
					/>
				}
			>
				<ProjectSupplementaryForm
					ref={formRef}
					supplementary={initialSupplementaryInformation}
					onSubmit={onSubmit}
					passIsDirty={(isDirty, dirtyFields) => {
						setIsDirty(isDirty);
						setDirtyFields(dirtyFields);
					}}
				/>
				<ConfirmUnsaved
					open={isUnsavedConfirmOpen}
					handleCancel={closeUnsavedConfirm}
					handleConfirm={closeUnsavedConfirmAndModal}
					dirtyFields={dirtyFields}
					translation='project.supplementary.form'
				/>
			</ModalDialog>
		</>
	);
};

export default AddProjectSupplementary;
