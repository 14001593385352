export function delayPromise(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export async function delay(time = 500, action = null) {
    await delayPromise(time); // wait 500 milliseconds for default

    if (action) return action();

    return true;
}

export async function delayWhile(delayTime, delayAction) {
    let shouldContinue = true;
    while (shouldContinue) {
        shouldContinue = await delay(delayTime, delayAction);
    }

    return true;
}
