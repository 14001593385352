import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ContentContainer from 'components/Containers/ContentContainer';
import Spinner from 'components/Spinner/CenteredSpinner';
import MainTitle from 'components/Titles/MainTitle';
import CategoryForm from './CategoryForm';

import { loadInstrumentCategories } from 'containers/Instruments/slices';
import { loadInstruments, name, reducer } from '../InstrumentsEditPage/slices';

import { useInjectReducer } from 'utils/injectors/injectReducer';

const CategoriesPage = () => {
	useInjectReducer({ key: name, reducer: reducer });

	const dispatch = useDispatch();
	const formRef = useRef();
	const intl = useIntl();

	const adminInstruments = useSelector(state => state.admininstruments);
	const instrumentsData = adminInstruments?.instruments;
	const instrumentsLoading = adminInstruments?.loading;

	const instruments = useSelector(state => state.instruments);
	const categories = instruments?.categories;
	const isLoaded = categories?.isLoaded;
	const categoriesLoading = categories?.loading;
	const categoriesSaveLoading = categories?.saveLoading;
	const data = categories?.data;

	const loading = instrumentsLoading || categoriesLoading;

	const [isFormDirty, setIsFormDirty] = useState(false);

	useEffect(() => {
		if (!adminInstruments) {
			dispatch(loadInstruments());
		}
	}, [adminInstruments, dispatch]);

	useEffect(() => {
		if (!isLoaded) {
			dispatch(loadInstrumentCategories());
		}
	}, [isLoaded, dispatch]);

	const submitForm = () => formRef.current?.submitForm();
	const resetForm = () => formRef.current?.resetForm();

	return loading ? (
		<Spinner />
	) : (
		<ContentContainer>
			<Box display='flex' sx={{ justifyContent: 'space-between' }}>
				<MainTitle>{intl.formatMessage({ id: 'categories.page.title' })}</MainTitle>
				<Stack direction='row' spacing={2}>
					<Button
						disabled={loading || categoriesSaveLoading || !isFormDirty}
						variant='outlined'
						color='primary'
						onClick={resetForm}
					>
						{intl.formatMessage({ id: 'reset.form' })}
					</Button>
					<Button
						disabled={loading || categoriesSaveLoading || !isFormDirty}
						variant='contained'
						color='success'
						onClick={submitForm}
					>
						{intl.formatMessage({ id: 'shared.save' })}
						{loading || (categoriesSaveLoading && <ButtonSpinner />)}
					</Button>
				</Stack>
			</Box>
			<CategoryForm ref={formRef} categories={data} instruments={instrumentsData} setIsFormDirty={setIsFormDirty} />
		</ContentContainer>
	);
};

export default CategoriesPage;
