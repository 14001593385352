import { Box } from '@mui/material';
import * as React from 'react';

import { useIntl } from 'react-intl';

import ContentContainer from 'components/Containers/ContentContainer';

import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import { ProjectInfoTable } from 'containers/Projects/Project/ProjectSummary/ProjectSummary';
import { projectCostSelector, projectTaskSelector } from 'containers/Projects/slices';
import { isNil } from 'lodash';
import { Project, ProjectCost, ProjectTask } from 'types/dataTypes';
import { getCompanyId } from 'utils/auth/company';
import CostsTable from '../ProjectPlan/ProjectPlanPrint/CostsTable/CostsTable';
import EuraCostsTable from '../ProjectPlan/ProjectPlanPrint/CostsTable/EuraCostsTable';
import FinanceTable from '../ProjectPlan/ProjectPlanPrint/CostsTable/FinanceTable';
import OverviewTable from '../ProjectPlan/ProjectPlanPrint/OverviewTable';
import PurchasingTable from '../ProjectPlan/ProjectPlanPrint/PurchasingTable';
import TaskTable from '../ProjectPlan/ProjectPlanPrint/TaskTable';
import WorkTable from '../ProjectPlan/ProjectPlanPrint/WorkTable';
import { ProjectSummarySection } from './Components';
import DynamicProjectApplication from './DynamicProjectApplication';

export type ProjectPlanSummaryProps = {
	project: Project;
	template: any;
	sx?: any;
};

/**
 * Creates project summary contents
 *
 * @param {*} project
 * @param {*} template
 *
 * @returns
 */
const ProjectPlanSummary = ({ project, template, sx = {} }: ProjectPlanSummaryProps) => {
	const intl = useIntl();
	const printPageRef = React.useRef(null);
	const companyId = getCompanyId();

	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });
	const { data: instruments, isLoading: isInstrumentsLoading } = useFetchInstrumentsByCompanyQuery(
		{ companyId },
		{ skip: isNil(companyId) }
	);

	const tasks: Array<ProjectTask> = projectTaskSelector(project);
	const costs: Array<ProjectCost> = projectCostSelector(project);

	const instrument = instruments ? instruments.find(item => item.id === project.instrumentId) : null;
	const isBf = instrument?.provider === 'Business Finland';

	return (
		<ContentContainer sx={{ '@media print': { display: 'block' }, ...sx }}>
			<Box ref={printPageRef}>
				<ProjectInfoTable company={company} project={project} />
				<DynamicProjectApplication {...{ project, template, limits: ['projectPlan'] }} />
				{(!template || template?.showProjectPlan === true) && ( // hide project plan if template is not set and template hides project plan
					<ProjectSummarySection level='h2' title={intl.formatMessage({ id: 'project.plan' })} showToggle>
						<OverviewTable project={project} />
					</ProjectSummarySection>
				)}
				{project?.projectTaskGroups && project?.projectTaskGroups.length > 0 && (
					<>
						{project?.projectTaskGroups?.map((group, index) => {
							return (
								<ProjectSummarySection
									level='h3'
									title={group.groupName}
									showToggle
									key={group.projectTaskGroupId}
									sx={{
										'@media print': {
											float: 'none',
											display: 'block',
											pageBreakBefore: 'avoid',
											pageBreakAfter: 'avoid',
											pageBreakInside: 'avoid',
											overflow: 'hidden',
										},
									}}
								>
									{group.tasks?.map((task, index) => {
										return <TaskTable key={task.projectTaskId} index={index} task={task} />;
									})}
								</ProjectSummarySection>
							);
						})}
					</>
				)}

				<ProjectSummarySection level='h3' title={intl.formatMessage({ id: 'project.resourse.work.table' })} showToggle>
					<WorkTable tasks={tasks} costs={costs} showGrouped />
				</ProjectSummarySection>
				<ProjectSummarySection
					level='h3'
					title={intl.formatMessage({ id: 'project.resourse.purchasing.services.table' })}
					showToggle
					sx={{
						'@media print': {
							float: 'none',
							display: 'block',
							pageBreakBefore: 'avoid',
							pageBreakAfter: 'avoid',
							pageBreakInside: 'avoid',
							overflow: 'hidden',
						},
					}}
				>
					<PurchasingTable tasks={tasks} costs={costs} />
				</ProjectSummarySection>
				<ProjectSummarySection
					level='h3'
					title={intl.formatMessage({ id: 'project.resourse.purchasing.services.costs' })}
					showToggle
					sx={{
						paddingBottom: '13rem',
						'@media print': {
							float: 'none',
							display: 'block',
							pageBreakBefore: 'auto',
							pageBreakAfter: 'avoid',
							pageBreakInside: 'avoid',
							overflow: 'hidden',
						},
					}}
				>
					{isBf && (
						<CostsTable
							project={project}
							tasks={tasks}
							costs={costs}
							flatRatePercent={project.flatRateDevelopment ?? 0}
							flatRateInvestment={project.flatRateInvestment ?? 0}
							instrument={instrument}
						/>
					)}
					{!isBf && (
						<EuraCostsTable
							project={project}
							tasks={tasks}
							costs={costs}
							flatRatePercent={project.flatRateDevelopment ?? 0}
							flatRateInvestment={project.flatRateInvestment ?? 0}
						/>
					)}
					{!isBf && <FinanceTable project={project} />}
				</ProjectSummarySection>
			</Box>
		</ContentContainer>
	);
};

export default ProjectPlanSummary;
