import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import { useIntl } from 'react-intl';

import StyledTooltip from './StyledTooltip';

const CloudTooltip = ({ isCreatedByBot }) => {
	const intl = useIntl();

	if (!isCreatedByBot) {
		return null;
	}

	return (
		<StyledTooltip title={intl.formatMessage({ id: 'tooltip.cloud' })}>
			<CloudDoneOutlinedIcon color='success' />
		</StyledTooltip>
	);
};

export default CloudTooltip;
