import { yupResolver } from '@hookform/resolvers/yup';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { RfpProfileTeamMember, RfpProposalTeamMember } from 'types/dataTypes';
import { mergeFieldValues } from 'utils/misc/formUtils';

import { closeDialog, getDialogData, isDialogOpen, openDialog } from 'containers/Marketplace/dialogSlice';

import RfpAttachmentAvatarInput from 'containers/Marketplace/attachment/RfpAttachmentAvatarInput';
import SelectRfpProfileTeamMemberDialog from 'containers/Marketplace/profile/SelectRfpProfileTeamMemberDialog';
import { StyledTooltip } from '../common/StyledTooltip';

type FieldValues = Partial<RfpProposalTeamMember>;

type Props = {} & Omit<DialogProps, 'open'>;

const UpsertRfpProposalTeamMemberDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const { formatMessage } = useIntl();

	const dialogOpen = useSelector(isDialogOpen('UpsertRfpProposalTeamMemberDialog'));
	const dialogData = useSelector(getDialogData('UpsertRfpProposalTeamMemberDialog'));

	const validationSchema = React.useMemo(
		() =>
			yup.object({
				costs: yup.array().min(1),
				firstname: yup.string().required(),
				lastname: yup.string().required(),
				description: yup.string().max(2000),
			}),
		[]
	);

	const defaultValues = React.useMemo(
		() => ({
			avatar: null,
			firstname: '',
			lastname: '',
			position: '',
			description: '',
			linkedInUrl: '',
		}),
		[]
	);

	const {
		control,
		reset,
		handleSubmit,
		setValue,
		formState: { isDirty, isSubmitting },
	} = useForm<FieldValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const handleDialogClose = React.useCallback(() => {
		if (isDirty) {
			dispatch(
				openDialog({
					name: 'ConfirmCloseDialog',
					data: {
						onSubmit: () => {
							reset(defaultValues);
							dispatch(closeDialog({ name: 'UpsertRfpProposalTeamMemberDialog' }));
						},
					},
				})
			);
		} else {
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProposalTeamMemberDialog' }));
		}
	}, [dispatch, reset, isDirty, defaultValues]);

	const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
		async fieldValues => {
			await dialogData?.onSubmit(fieldValues);
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProposalTeamMemberDialog' }));
		},
		[dispatch, reset, defaultValues, dialogData]
	);

	React.useEffect(() => {
		if (!isNil(dialogData?.fieldValues)) {
			reset(mergeFieldValues(defaultValues, dialogData?.fieldValues), { keepDirtyValues: true });
		}
	}, [reset, defaultValues, dialogData]);

	return (
		<>
			<SelectRfpProfileTeamMemberDialog />
			<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose} maxWidth='sm' fullWidth>
				<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
					<CloseIcon />
				</IconButton>
				<Box component='form' onSubmit={handleSubmit(handleFormSubmit)}>
					<DialogTitle>
						<FormattedMessage id='rfpProposalTeamMember.title' />
					</DialogTitle>
					<DialogContent>
						<Stack>
							<Box>
								<Button
									size='small'
									variant='text'
									onClick={() => {
										dispatch(
											openDialog({
												name: 'SelectRfpProfileTeamMemberDialog',
												data: {
													onSelect: (rfpProfileTeamMember: RfpProfileTeamMember) => {
														setValue('avatar', rfpProfileTeamMember.avatar);
														setValue('firstname', rfpProfileTeamMember.firstname);
														setValue('lastname', rfpProfileTeamMember.lastname);
														setValue('position', rfpProfileTeamMember.position);
														setValue('description', rfpProfileTeamMember.description);
														setValue('linkedInUrl', rfpProfileTeamMember.linkedInUrl);
													},
												},
											})
										);
									}}
								>
									<FormattedMessage id='rfpProposalTeamMember.profileTeamMember.title' />
								</Button>
							</Box>
							<Controller
								name='avatar'
								control={control}
								render={({ field, fieldState }) => (
									<RfpAttachmentAvatarInput
										{...field}
										label={formatMessage({ id: `rfpProposalTeamMember.${field.name}.label` })}
										AvatarProps={{ variant: 'portrait1' }}
									/>
								)}
							/>
							<Controller
								name='firstname'
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										{...field}
										error={!isNil(fieldState.error)}
										label={formatMessage({ id: `rfpProposalTeamMember.${field.name}.label` })}
										placeholder={formatMessage({ id: `rfpProposalTeamMember.${field.name}.placeholder` })}
										helperText={fieldState.error?.message}
									/>
								)}
							/>
							<Controller
								name='lastname'
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										{...field}
										error={!isNil(fieldState.error)}
										label={formatMessage({ id: `rfpProposalTeamMember.${field.name}.label` })}
										placeholder={formatMessage({ id: `rfpProposalTeamMember.${field.name}.placeholder` })}
										helperText={fieldState.error?.message}
									/>
								)}
							/>
							<Controller
								name='position'
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										{...field}
										error={!isNil(fieldState.error)}
										label={formatMessage({ id: `rfpProposalTeamMember.${field.name}.label` })}
										placeholder={formatMessage({ id: `rfpProposalTeamMember.${field.name}.placeholder` })}
										helperText={fieldState.error?.message}
									/>
								)}
							/>
							<Controller
								name='description'
								control={control}
								render={({ field, fieldState }) => (
									<StyledTooltip title={formatMessage({ id: `rfpProposalTeamMember.${field.name}.placeholder` })}>
										<TextField
											{...field}
											error={!isNil(fieldState.error)}
											label={formatMessage({ id: `rfpProposalTeamMember.${field.name}.label` })}
											placeholder={formatMessage({ id: `rfpProposalTeamMember.${field.name}.placeholder` })}
											helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
											inputProps={{ maxLength: 2000 }}
											minRows={3}
											multiline
										/>
									</StyledTooltip>
								)}
							/>
							<Controller
								name='linkedInUrl'
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										{...field}
										error={!isNil(fieldState.error)}
										label={formatMessage({ id: `rfpProposalTeamMember.${field.name}.label` })}
										placeholder={formatMessage({ id: `rfpProposalTeamMember.${field.name}.placeholder` })}
										helperText={fieldState.error?.message}
									/>
								)}
							/>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button size='small' variant='text' color='neutral' onClick={handleDialogClose}>
							<FormattedMessage id='rfpProposalTeamMember.cancel' />
						</Button>
						<LoadingButton type='submit' loading={isSubmitting}>
							<FormattedMessage id='rfpProposalTeamMember.submit' />
						</LoadingButton>
					</DialogActions>
				</Box>
			</Dialog>
		</>
	);
};

export default UpsertRfpProposalTeamMemberDialog;
