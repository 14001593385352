import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { Scenario } from 'types/dataTypes';

type FetchScenario = {
	companyId: string | null;
	businessId?: string | null;
};

type UpsertScenario = {
	companyId: string | null;
	scenario: Partial<Scenario> | null;
};

const Tags = {
	Scenario: 'scenario',
};

const baseUrl = 'scenario';

export const scenarioV2Api = createApi({
	reducerPath: 'scenarioV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.Scenario],
	endpoints: builder => ({
		fetchScenario: builder.query<Scenario, FetchScenario>({
			query: ({ companyId, businessId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: businessId ? `/${companyId}?businessId=${businessId}` : `/${companyId}`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.Scenario, id: params?.companyId ?? '' }];
			},
		}),
		upsertScenario: builder.mutation<Scenario, UpsertScenario>({
			query: ({ companyId, scenario }) => {
				if (isNil(companyId) || isNil(scenario)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/${companyId}`,
					data: scenario,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.Scenario, id: params?.companyId ?? '' }];
			},
		}),
	}),
});

export const { useFetchScenarioQuery, useUpsertScenarioMutation } = scenarioV2Api;
