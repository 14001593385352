import { isNil } from 'lodash';
import * as React from 'react';

import { RfpProposal, RfpRequest } from 'types/dataTypes';
import { getCompany, getCompanyId, hasMarketplaceProviderFeature } from 'utils/auth/company';
import { isAdminToken } from 'utils/auth/token';

type Props = {
	rfpRequest: Partial<RfpRequest> | undefined;
	rfpProposal: Partial<RfpProposal> | undefined;
	children?: (canUpsert: boolean) => React.ReactNode;
};

const CanUpsertRfpProposal: React.FC<Props> = ({ rfpRequest, rfpProposal, children }) => {
	const companyId = getCompanyId() ?? getCompany()?.CompanyId;
	const isAdmin = isAdminToken();
	const isMarketplaceProvider = hasMarketplaceProviderFeature();

	const canUpsert = React.useMemo(() => {
		if (isNil(rfpProposal)) {
			return rfpRequest?.companyId !== companyId && rfpRequest?.expired === false && (isAdmin || isMarketplaceProvider);
		}
		return (
			rfpProposal?.companyId === companyId &&
			rfpProposal?.status !== 'Archived' &&
			rfpRequest?.companyId !== companyId &&
			rfpRequest?.expired === false
		);
	}, [rfpRequest, rfpProposal, companyId, isAdmin, isMarketplaceProvider]);

	return <>{children?.(canUpsert)}</>;
};

export default CanUpsertRfpProposal;
