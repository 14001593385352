import React from 'react';
import { Controller } from 'react-hook-form';
import Rating from '@mui/material/Rating';
import FormControl from '@mui/material/FormControl';

import FormLabel from './FormLabel';
import FormError from './FormError';

export default function FormControlledRating({ name, control, defaultValue, error, label, max = 3 }) {

    return (
        <FormControl fullWidth>
            <FormLabel>{label}</FormLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{ required: true }}
                render={({ field: { onChange, value, } }) => <Rating name={name} onChange={onChange} value={Number(value)} max={max} defaultValue={defaultValue} />}
            />
            <FormError error={error} />
        </FormControl>
    );
}
