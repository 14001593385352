import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import ScenarioDialog from 'containers/Scenario/ScenarioDialog';

import { updateGrantedScore } from 'containers/Advanced/slices';
import { loadAnalysis } from 'containers/Analysis/slices';
import { loadInstruments } from 'containers/Instruments/slices';
import { closeDialog, isDialogOpen } from 'containers/Marketplace/dialogSlice';
import { useUpsertScenarioMutation } from 'containers/Scenario/scenarioV2Api';
import { getScenarioGrantedScore } from 'containers/Score/slices';
import { addAdvancedCompanyQuery, getCompanyId } from 'utils/auth/company';

const ScenarioModal = ({ scenario, loading = false, profile, readonly }) => {
	const dispatch = useDispatch();
	const formRef = useRef();
	const { formatMessage } = useIntl();

	const companyId = getCompanyId();

	const [isDirty, setIsDirty] = useState(false);
	const [dirtyFields, setDirtyFields] = useState();

	const [upsertScenario, { isLoading: isUpsertLoading }] = useUpsertScenarioMutation();

	const dialogOpen = useSelector(isDialogOpen('ScenarioDialog'));

	const closeModal = () => {
		if (isDirty) {
			setIsConfirmOpen(true);
		} else {
			dispatch(closeDialog({ name: 'ScenarioDialog' }));
		}
	};

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const closeConfirm = () => setIsConfirmOpen(false);

	const closeConfirmAndModal = () => {
		setIsConfirmOpen(false);
		dispatch(closeDialog({ name: 'ScenarioDialog' }));
	};

	const onSubmit = async fieldValues => {
		try {
			const upsertedScenario = await upsertScenario({ companyId, scenario: fieldValues }).unwrap();
			dispatch(loadAnalysis());
			dispatch(loadInstruments());
			dispatch(getScenarioGrantedScore(upsertedScenario));
			if (addAdvancedCompanyQuery()) {
				dispatch(updateGrantedScore(companyId));
			}
			dispatch(closeDialog({ name: 'ScenarioDialog' }));
			toast.success(formatMessage({ id: 'scenario.saving.successed' }));
		} catch (error) {
			toast.error(formatMessage({ id: 'scenario.saving.failed' }));
		}
	};

	const submitForm = () => {
		formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
	};

	return (
		<>
			<ConfirmUnsaved
				open={isConfirmOpen}
				handleCancel={closeConfirm}
				handleConfirm={closeConfirmAndModal}
				dirtyFields={dirtyFields}
				translation='instrument.radar.choice'
			/>
			<ScenarioDialog
				readonly={readonly}
				formRef={formRef}
				open={dialogOpen}
				close={closeModal}
				initialLoading={loading}
				saveLoading={isUpsertLoading}
				scenario={scenario}
				onSubmit={onSubmit}
				submitForm={submitForm}
				profile={profile}
				isDirty={isDirty}
				passIsDirty={(isDirty, dirtyFields) => {
					setIsDirty(isDirty);
					setDirtyFields(dirtyFields);
				}}
			/>
		</>
	);
};

export default ScenarioModal;
