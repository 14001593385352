import { Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import MessageNoItem from 'components/MessageNoItem';
import InstrumentToChat from 'containers/Instruments/InstrumentList/InstrumentItem/InstrumentToChat';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.secondary.lightBeige,
		marginTop: '2rem',
	},
}));

const NoInstruments = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Stack flexDirection='row' justifyContent='space-between' alignItems='center' spacing={1} useFlexGap>
				<MessageNoItem messageId='timeline.no.instruments' />

				<Box>
					<InstrumentToChat />
				</Box>
			</Stack>
		</div>
	);
};

export default NoInstruments;
