import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Skeleton, Stack } from '@mui/material';
//import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/HighlightOff';

import ConfirmButton from 'components/Button/ConfirmButton';
import ModalDialog from 'components/Dialog/ModalDialog';
import Spinner from 'components/Spinner/Spinner';
import ConversationItem from 'containers/Collaboration/CollaborationDrawer/ConversationList/ConversationItem/ConversationItem';
import {
	useAddCommentMutation,
	useDeleteCommentMutation,
	useDeleteConversationMutation,
	useGetConversationQuery,
	useMarkConversationReadMutation,
	useMarkConversationResolvedMutation,
} from 'containers/Collaboration/collaborationApi';

const ConversationPopUp = ({ conversationData, open, closeModal }) => {
	const intl = useIntl();
	const history = useHistory();

	const conversation = useGetConversationQuery({
		companyId: conversationData.companyId,
		conversationId: conversationData.conversationId,
	});
	// eslint-disable-next-line no-unused-vars
	const markAsRead = useMarkConversationReadMutation(
		{ companyId: conversationData.companyId, conversationId: conversationData.conversationId },
		{
			skip:
				!conversation.isSuccess ||
				conversation.data?.conversationId !== conversationData?.conversationId ||
				conversationData?.isRead,
		}
	);

	const [deleteConversation, { isUpdating: isDeleting }] = useDeleteConversationMutation();

	const [markAsHandled, { isLoading, originalArgs }] = useMarkConversationResolvedMutation();

	const [addComment] = useAddCommentMutation();
	const handleCommentAdd = (_, comment) => {
		addComment({ companyId: conversationData.companyId, conversationId: conversationData.conversationId, comment: { comment } });
	};

	const [deleteComment] = useDeleteCommentMutation();
	const handleDeleteComment = (conversationId, commentId) => {
		deleteComment({ companyId: conversationData.companyId, conversationId, commentId });
	};

	const goToTarget = link => {
		const path = `/company/${conversationData.companyId}/`;
		const pathHash = link?.includes('project') ? link : link.replace('/', '#');

		history.push(`${path}${pathHash}`);
	};

	const ConversationItemResolver = () => {
		if (conversation.isFetching || conversation.isLoading) {
			return (
				<>
					<Stack direction='row' sx={{ p: '1rem' }}>
						<Skeleton variant='circular' height={40} width={40} />
						<Skeleton variant='rectangular' height={150} sx={{ ml: '20px', width: 'calc(100% - 60px)' }} />
					</Stack>
					<Stack direction='row' sx={{ p: '1rem', pl: '2rem' }}>
						<Skeleton variant='circular' height={40} width={40} />
						<Skeleton variant='rectangular' height={40} sx={{ ml: '20px', width: 'calc(100% - 60px)' }} />
					</Stack>
					<Stack direction='row' sx={{ p: '1rem', pl: '2rem' }}>
						<Skeleton variant='circular' height={40} width={40} />
						<Skeleton variant='rectangular' height={40} sx={{ ml: '20px', width: 'calc(100% - 60px)' }} />
					</Stack>
					<Stack direction='row' sx={{ p: '1rem', pl: '4rem' }}>
						<Skeleton variant='circular' height={40} width={40} />
						<Skeleton variant='rectangular' height={40} sx={{ ml: '20px', width: 'calc(100% - 60px)' }} />
					</Stack>
				</>
			);
		}

		if (!conversation?.data) return null;

		const { conversationId, link, createdTime, author, uniqueAuthors, commentsCount, isRead, content /* resolved */ } =
			conversation.data;

		return (
			<>
				<ConversationItem
					{...{
						linkTitle: conversationData.linkTitle,
						firstComment: content,
						conversationId,
						link,
						createdTime,
						conversationItem: conversation.data,
						author,
						uniqueAuthors,
						addComment: handleCommentAdd,
						deleteConversation: null,
						deleteComment: handleDeleteComment,
						goToTarget,
						expandOnLoad: true,
						commentsCount,
						isRead,
					}}
				/>
			</>
		);
	};

	const title =
		conversation?.data?.content?.length > 100 ? conversation?.data?.content?.slice(0, 100) + '...' : conversation?.data?.content;
	const showMarkAsHandled = conversationData => {
		if (originalArgs && originalArgs.conversationId === conversationData.conversationId) return false;

		return !conversationData?.resolved;
	};

	return (
		<>
			<ModalDialog
				maxWidth='md'
				open={open}
				onClose={closeModal}
				title={title}
				position='absolute'
				top={50}
				actions={
					<>
						<ConfirmButton
							variant='outlined'
							color='primary'
							disabled={isDeleting}
							confirmText={intl.formatMessage({ id: 'collaboration.conversation.remove.confirm' })}
							cancelText={intl.formatMessage({ id: 'collaboration.conversation.remove.cancel' })}
							confirmBodyText={intl.formatMessage({ id: 'collaboration.conversation.remove.body' })}
							confirmAction={() =>
								deleteConversation({
									companyId: conversationData.companyId,
									conversationId: conversationData.conversationId,
								})
							}
							stopPropagation={true}
							startIcon={isDeleting ? <Spinner size={16} /> : <DeleteIcon color='error' />}
						>
							{intl.formatMessage({ id: 'collaboration.conversation.remove.confirm' })}
						</ConfirmButton>
						{showMarkAsHandled(conversationData) ? (
							<ConfirmButton
								variant='outlined'
								color='primary'
								disabled={isLoading}
								confirmText={intl.formatMessage({ id: 'collaboration.conversation.resolve.confirm' })}
								cancelText={intl.formatMessage({ id: 'collaboration.conversation.resolve.cancel' })}
								confirmBodyText={intl.formatMessage({ id: 'collaboration.conversation.resolve.body' })}
								confirmAction={() =>
									markAsHandled({
										companyId: conversationData.companyId,
										conversationId: conversationData.conversationId,
									})
								}
								stopPropagation={true}
								startIcon={isLoading ? <Spinner size={16} /> : <DoneIcon color='primary' />}
							>
								{intl.formatMessage({ id: 'collaboration.conversation.resolve.confirm' })}
							</ConfirmButton>
						) : null}
					</>
				}
			>
				<ConversationItemResolver />
			</ModalDialog>
		</>
	);
};

export default ConversationPopUp;
