import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { Instrument, InstrumentCategory } from 'types/dataTypes';

type FetchInstruments = {
	companyId: string | null;
};

type FetchInstrumentCategories = {
	name: string | null;
};

type UpsertInstrumentCategories = {
	name: string | null;
	categories: Partial<InstrumentCategory> | null;
};

const Tags = {
	Instrument: 'instrument',
	InstrumentCategory: 'instrumentCategory',
};

const baseUrl = 'instruments';

export const instrumentV2Api = createApi({
	reducerPath: 'instrumentV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.Instrument],
	endpoints: builder => ({
		fetchInstruments: builder.query({
			query: () => {
				return {
					method: 'GET',
					url: '/',
				};
			},
			providesTags: () => {
				return [{ type: Tags.Instrument, id: 'LIST' }];
			},
		}),
		fetchInstrumentsByCompany: builder.query<Instrument[], FetchInstruments>({
			query: ({ companyId }) => {
				return {
					method: 'GET',
					url: `/${companyId}`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.Instrument, id: 'LIST' }];
			},
		}),
		fetchInstrumentCategories: builder.query<InstrumentCategory[], FetchInstrumentCategories>({
			query: params => {
				return {
					method: 'GET',
					url: params?.name ? `/categories/${params.name}` : '/categories',
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.InstrumentCategory, id: params?.name ?? 'LIST' }];
			},
		}),
		upsertInstrumentCategories: builder.mutation<InstrumentCategory[], UpsertInstrumentCategories>({
			query: ({ name, categories }) => {
				if (isNil(categories)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: name ? `/categories/${name}` : '/categories',
					data: categories,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.InstrumentCategory, id: params?.name ?? 'LIST' }];
			},
		}),
	}),
});

export const {
	useFetchInstrumentsQuery,
	useFetchInstrumentsByCompanyQuery,
	useFetchInstrumentCategoriesQuery,
	useUpsertInstrumentCategoriesMutation,
} = instrumentV2Api;
