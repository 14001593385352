import * as React from 'react';
import { useSelector } from 'react-redux';

import { find } from 'lodash';
import { hasMarketplaceProviderFeature } from 'utils/auth/company';
import { isAdminToken } from 'utils/auth/token';

type Props = {
	children?: (isMarkerplaceProvider: boolean) => React.ReactNode;
	adminOverride?: boolean | null | undefined;
	override?: boolean | null | undefined;
};

export const hasMarketplaceProviderFeatureTest = (
	company: any,
	adminOverride: boolean | null | undefined,
	override?: boolean | null | undefined
) => {
	if (override) {
		return true;
	}

	const isAdmin = isAdminToken();

	let isMarkerplaceProvider = hasMarketplaceProviderFeature();

	if (isAdmin) {
		if (adminOverride) {
			isMarkerplaceProvider = true;
		} else {
			const license = find(company?.companyDetails?.companyLicenses, (item: any) => item.isActive);

			const hasMarketplaceFeature = find(license?.features, (item: any) => item === 'GrantsMarketplaceProvider');

			// @ts-ignore
			isMarkerplaceProvider = hasMarketplaceFeature ? true : false;
		}
	}

	return isMarkerplaceProvider;
};

const IsMarketplaceProvider: React.FC<Props> = ({ children, adminOverride, override }) => {
	// @ts-ignore
	const company = useSelector(state => state.admin);

	return <>{children?.(hasMarketplaceProviderFeatureTest(company, adminOverride, override))}</>;
};

export default IsMarketplaceProvider;
