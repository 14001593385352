import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { forwardRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import ConfirmDialog from 'components/ConfirmDialog';
import { FormControlledCurrencyInput, FormControlledDatepicker, FormControlledInput, FormControlledSelect } from 'components/Form';
import FormControlWithLabel from 'components/Form/FormControlWithLabel';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import Switch from 'components/SwitchItem';
import InstrumentLimiters from './InstrumentLimiters';
import InstrumentRatings from './InstrumentRatings';
import ProviderLink from './ProviderLink';
import RelatedInstruments from './RelatedInstruments';
import TolClasses from './TolClasses';

import { FormMultiSelect } from 'components/Form/FormMultiSelect';
import countys from 'utils/constants/countys';
import InstrumentAutocomplete from '../../../FinancialPlan/InstrumentModal/InstrumentAutocomplete';
import InstrumentTemplatesWrapper from '../InstrumentTemplates/InstrumentTemplateWrapper';

const InstrumentForm = forwardRef(
	(
		{ onSubmit, instrument, instruments, providers, projectAims, projectObjects, projectValues, tab, setError, setIsFormDirty },
		formRef
	) => {
		const intl = useIntl();

		const [open, setOpen] = useState(false);

		const createInstrumentSchema = yup.object().shape({
			name: yup.string().required(
				`${intl.formatMessage({ id: 'instrument.form.name' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			),
			url: yup.string().url(`${intl.formatMessage({ id: 'instrument.form.url.validation' })}`),
			provider: yup.string().required(
				`${intl.formatMessage({ id: 'instrument.form.provider' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			),
			type: yup.string().required(
				`${intl.formatMessage({ id: 'instrument.form.type' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			),
			endTime: yup
				.date()
				.nullable()
				.default(null)
				.test('is-greater', intl.formatMessage({ id: 'timeline.instrument.endTime.mindate' }), (value, { parent }) => {
					const { startTime } = parent;

					if (!startTime || !value) return true; // if either one is false no need for validation

					const start = moment(startTime);

					return moment(value, 'dd.MM.yyyy').isSameOrAfter(moment(start, 'dd.MM.yyyy'));
				}),
			ingress: yup.string().required(
				`${intl.formatMessage({ id: 'instrument.form.ingress' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			),
			description: yup.string().required(
				`${intl.formatMessage({ id: 'instrument.form.description' })} ${intl.formatMessage({
					id: 'message.isRequired',
				})}`
			),
			limitators: yup
				.array()
				.transform((_, orig) => {
					const entries = Object.entries(orig).map(([key, value]) => {
						return { type: key, values: value };
					});

					return entries;
				})
				.of(
					yup.object().shape({
						type: yup.string().required(),
						values: yup.array().required().min(1),
					})
				),
		});

		const {
			control,
			handleSubmit,
			formState: { errors, isDirty },
			watch,
			setValue,
		} = useForm({
			defaultValues: {
				instrument,
			},
			resolver: yupResolver(createInstrumentSchema),
		});

		const error = !!Object.keys(errors).length; // check if any errors in the array

		const startTimeWatch = watch('startTime', instrument?.startTime);
		const templateIdWatch = watch('instrumentTemplateId', instrument?.instrumentTemplateId);

		const publish = handleSubmit(data => (data.isDraft = false));

		const [filteredProviders, setFilteredProviders] = useState(providers);
		const filterProviders = provider => providers?.filter(p => p.toLowerCase().includes(provider.toLowerCase()));

		const [filteredInstruments, setFilteredInstruments] = useState(providers);
		const filterInstruments = instrument => instruments?.filter(p => p.name?.toLowerCase().includes(instrument.name?.toLowerCase()));

		useEffect(() => {
			setIsFormDirty && setIsFormDirty(isDirty);
		}, [isDirty, setIsFormDirty]);

		useEffect(() => {
			error ? setError && setError(true) : setError && setError(false);
		}, [error, setError]);

		const handleTemplateSubmit = templateData => {
			setValue('instrumentTemplateId', templateData.instrumentId);
		};

		return (
			<>
				<form ref={formRef} onSubmit={handleSubmit((data, event) => onSubmit(data, event.nativeEvent.detail))}>
					<Grid container spacing={2} sx={{ display: tab === 0 ? 'flex' : 'none' }}>
						<Grid item xs={12} md={6}>
							<Controller
								name='id'
								control={control}
								defaultValue={instrument?.id ?? ''}
								render={() => <input type='hidden' value={instrument?.id ?? ''} />}
							/>
							<FormControlledInput
								defaultValue={instrument?.name ?? ''}
								name='name'
								control={control}
								error={errors?.name?.message}
								label={intl.formatMessage({ id: 'instrument.form.name' })}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormControlWithLabel
								error={errors?.instrument?.provider}
								label={intl.formatMessage({ id: 'instrument.form.provider' })}
							>
								<Controller
									name='provider'
									control={control}
									defaultValue={instrument?.provider ?? ''}
									render={({ field: { onChange, value } }) => (
										<Autocomplete
											value={value}
											options={filteredProviders}
											freeSolo
											getOptionLabel={option => option}
											onChange={(_, data) => {
												if (data !== null) return onChange(data);
											}}
											onInputChange={(_, data) => {
												setFilteredProviders(filterProviders(data));
												onChange(data);
											}}
											renderInput={params => (
												<TextField
													{...params}
													variant='outlined'
													size='small'
													margin='dense'
													fullWidth
													InputLabelProps={{ type: 'search' }}
													sx={{ mt: 0 }}
												/>
											)}
										/>
									)}
								/>
							</FormControlWithLabel>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControlledInput
								defaultValue={instrument?.postCodeAreaStart ?? ''}
								name='postCodeAreaStart'
								control={control}
								error={errors?.postCodeAreaStart?.message}
								label={intl.formatMessage({ id: 'instrument.form.postCodeAreaStart' })}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControlledInput
								defaultValue={instrument?.postCodeAreaEnd ?? ''}
								name='postCodeAreaEnd'
								control={control}
								error={errors?.postCodeAreaEnd?.message}
								label={intl.formatMessage({ id: 'instrument.form.postCodeAreaEnd' })}
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid item>
								<FormMultiSelect
									placeholder={intl.formatMessage({ id: 'instrument.form.county.placeholder' })}
									name='countyId'
									defaultValue={instrument?.countyId ?? []}
									control={control}
									error={errors?.countyId?.message}
									data={countys?.map(county => {
										return {
											value: county.code,
											label: county.name,
										};
									})}
									label={intl.formatMessage({ id: 'instrument.form.county' })}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormControlledDatepicker
								defaultValue={instrument?.startTime ?? null}
								name='startTime'
								control={control}
								error={errors?.startTime?.message}
								label={intl.formatMessage({ id: 'timeline.instrument.startTime' })}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormControlledDatepicker
								defaultValue={instrument?.endTime ?? null}
								name='endTime'
								control={control}
								error={errors?.endTime?.message}
								label={intl.formatMessage({ id: 'timeline.instrument.endTime' })}
								minDate={startTimeWatch ?? new Date()}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControlledSelect
								defaultValue={instrument?.sortOrder ?? ''}
								name='sortOrder'
								control={control}
								error={errors?.sortOrder?.message}
								label={intl.formatMessage({ id: 'instrument.form.sortOrder' })}
								options={[
									{
										value: 0,
										label: intl.formatMessage({ id: 'instrument.form.choose' }),
									},
									{
										value: 1,
										label: intl.formatMessage({ id: 'instrument.form.sortorder.1' }),
									},
									{
										value: 2,
										label: intl.formatMessage({ id: 'instrument.form.sortorder.2' }),
									},
									{
										value: 3,
										label: intl.formatMessage({ id: 'instrument.form.sortorder.3' }),
									},
								]}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControlledSelect
								defaultValue={instrument?.type ?? ''}
								name='type'
								control={control}
								error={errors?.type?.message}
								label={intl.formatMessage({ id: 'instrument.form.type' })}
								options={[
									{
										value: 'loan',
										label: intl.formatMessage({ id: 'instrument.form.type.loan' }),
									},
									{
										value: 'support',
										label: intl.formatMessage({ id: 'instrument.form.type.support' }),
									},
									{
										value: 'guarantee',
										label: intl.formatMessage({ id: 'instrument.form.type.guarantee' }),
									},
									{
										value: 'investment',
										label: intl.formatMessage({ id: 'instrument.form.type.investment' }),
									},
								]}
							/>
						</Grid>
						<Grid item xs={6}>
							<Switch
								type='isDeminimis'
								checkedId='instrument.form.isDeminimis.checked'
								labelId='instrument.form.isDeminimis.label'
								value={instrument?.isDeminimis ?? false}
								control={control}
								checkedVal={true}
								notCheckedVal={false}
							/>
						</Grid>
						<Grid item xs={6}>
							<Switch
								type='disabled'
								checkedId='instrument.form.disabled.checked'
								labelId='instrument.form.disabled.label'
								value={instrument?.disabled ?? false}
								control={control}
								checkedVal={false}
								notCheckedVal={true}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlledWysiwyg
								inline={false}
								fixedToolbar={true}
								toolbar={true}
								name='ingress'
								control={control}
								defaultValue={instrument?.ingress ?? ''}
								error={errors?.ingress?.message}
								label={intl.formatMessage({ id: 'instrument.form.ingress' })}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlledWysiwyg
								inline={false}
								fixedToolbar={true}
								toolbar={true}
								name='description'
								control={control}
								defaultValue={instrument?.description ?? ''}
								error={errors?.description?.message}
								label={intl.formatMessage({ id: 'instrument.form.description' })}
							/>
						</Grid>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<FormControlledWysiwyg
								inline={false}
								fixedToolbar={true}
								toolbar={true}
								name='additionalInformation'
								control={control}
								defaultValue={instrument?.additionalInformation ?? ''}
								error={errors?.additionalInformation?.message}
								label={intl.formatMessage({ id: 'instrument.form.additionalInformation' })}
							/>
						</Grid>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<FormControlledWysiwyg
								inline={false}
								fixedToolbar={true}
								toolbar={true}
								name='instrumentPrompt'
								control={control}
								defaultValue={instrument?.instrumentPrompt ?? ''}
								error={errors?.instrumentPrompt?.message}
								label={intl.formatMessage({ id: 'instrument.form.instrumentPrompt' })}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormControlledCurrencyInput
								defaultValue={instrument?.minAmount ?? 0}
								name='minAmount'
								control={control}
								error={undefined}
								label={intl.formatMessage({ id: 'instrument.form.minAmount' })}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormControlledCurrencyInput
								defaultValue={instrument?.maxAmount ?? 0}
								name='maxAmount'
								control={control}
								error={undefined}
								label={intl.formatMessage({ id: 'instrument.form.maxAmount' })}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormControlledCurrencyInput
								defaultValue={instrument?.prepayment ?? 0}
								name='prepayment'
								control={control}
								error={undefined}
								label={intl.formatMessage({ id: 'instrument.form.prepayment' })}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormControlledCurrencyInput
								defaultValue={instrument?.projectSizeEur ?? 0}
								name='projectSizeEur'
								control={control}
								error={undefined}
								label={intl.formatMessage({ id: 'instrument.form.projectSizeEur' })}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormControlledInput
								defaultValue={instrument?.amountMinPercentage ?? ''}
								name='amountMinPercentage'
								control={control}
								label={intl.formatMessage({ id: 'instrument.form.amountMinPercentage' })}
								type='number'
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormControlledInput
								defaultValue={instrument?.amountMaxPercentage ?? ''}
								name='amountMaxPercentage'
								control={control}
								label={intl.formatMessage({ id: 'instrument.form.amountMaxPercentage' })}
								type='number'
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormControlledInput
								defaultValue={instrument?.installmentsFreeTime ?? ''}
								name='installmentsFreeTime'
								control={control}
								label={intl.formatMessage({ id: 'instrument.form.installmentsFreeTime' })}
								type='number'
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormControlledInput
								defaultValue={instrument?.expectedPendingTime ?? ''}
								name='expectedPendingTime'
								control={control}
								label={intl.formatMessage({ id: 'instrument.form.expectedPendingTime' })}
								type='number'
							/>
						</Grid>
						<Grid item xs={12} sx={{ mt: 2 }}>
							<Controller
								name='projectAim'
								control={control}
								defaultValue={instrument?.projectAimArr ?? []}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										value={value}
										freeSolo
										multiple
										options={projectAims}
										getOptionLabel={option => option}
										onChange={(_, data) => onChange(data)}
										renderInput={params => (
											<TextField
												size='small'
												{...params}
												fullWidth
												label={intl.formatMessage({ id: 'instrument.form.projectAim' })}
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sx={{ mt: 1 }}>
							<Controller
								name='projectObjects'
								control={control}
								defaultValue={instrument?.projectObjectsArr ?? []}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										value={value}
										freeSolo
										multiple
										options={projectObjects}
										getOptionLabel={option => option}
										onChange={(_, data) => onChange(data)}
										renderInput={params => (
											<TextField
												size='small'
												{...params}
												fullWidth
												label={intl.formatMessage({ id: 'instrument.form.projectObjects' })}
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sx={{ mt: 1 }}>
							<Controller
								name='projectValue'
								control={control}
								defaultValue={instrument?.projectValueArr ?? []}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										value={value}
										freeSolo
										multiple
										options={projectValues}
										getOptionLabel={option => option}
										onChange={(_, data) => onChange(data)}
										renderInput={params => (
											<TextField
												{...params}
												fullWidth
												label={intl.formatMessage({ id: 'instrument.form.projectValue' })}
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TolClasses instrument={instrument} errors={errors} control={control} />
						</Grid>
						<Grid item xs={12}>
							<RelatedInstruments instruments={instruments} errors={errors} control={control} setValue={setValue} />
						</Grid>
					</Grid>
					<Box sx={{ display: tab === 1 ? 'block' : 'none' }}>
						<InstrumentLimiters errors={errors} control={control} />
					</Box>
					<Box sx={{ display: tab === 2 ? 'block' : 'none' }}>
						<InstrumentRatings instrumentId={instrument?.id} errors={errors} control={control} setValue={setValue} />
					</Box>
					<Box sx={{ display: tab === 3 ? 'block' : 'none' }}>
						<ProviderLink instrument={instrument} errors={errors} control={control} />
					</Box>

					<Box sx={{ display: tab === 4 ? 'block' : 'none' }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<FormControlWithLabel
									error={errors?.instrument?.instrumentTemplateId}
									label={intl.formatMessage({ id: 'instrument.form.instrumentTemplateId' })}
								>
									<Controller
										name='instrumentTemplateId'
										control={control}
										defaultValue={instrument?.instrumentTemplateId ?? ''}
										render={({ field: { onChange } }) => {
											return (
												<InstrumentAutocomplete
													variant='outlined'
													instrument={
														instruments.find(item => item.id === instrument?.instrumentTemplateId) ?? instrument
													}
													instruments={instruments}
													setNewInstrument={data => onChange(data.id)}
													onChange={() => { }}
												/>
											);
										}}
									/>
								</FormControlWithLabel>

								<FormControlledInput
									variant='standard'
									disabled
									defaultValue={instrument?.instrumentTemplateId ?? ''}
									name='instrumentTemplateId'
									control={control}
									type='text'
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControlledInput
									defaultValue={instrument?.instrumentPromptId ?? ''}
									name='instrumentPromptId'
									control={control}
									label={intl.formatMessage({ id: 'instrument.form.instrumentPromptId' })}
									type='text'
								/>
							</Grid>
						</Grid>
					</Box>
				</form>
				<Box sx={{ display: tab === 4 ? 'block' : 'none' }}>
					<InstrumentTemplatesWrapper
						instruments={instruments}
						instrument={instrument}
						templateId={templateIdWatch ?? instrument?.instrumentTemplateId}
						handleTemplateSubmit={handleTemplateSubmit}
					/>
				</Box>
				<ConfirmDialog
					title={intl.formatMessage({ id: 'instrument.form.save' })}
					open={open}
					setOpen={setOpen}
					onConfirm={publish}
					buttonOkId='instrument.form.save'
					titleId='instrument.form.save'
					submitApproveText={intl.formatMessage({ id: 'instrument.form.save' })}
				>
					{intl.formatMessage({ id: 'instrument.form.confirm' })}
				</ConfirmDialog>
			</>
		);
	}
);

export default InstrumentForm;
