import { yupResolver } from '@hookform/resolvers/yup';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { closeDialog, getDialogData, isDialogOpen, openDialog } from 'containers/Marketplace/dialogSlice';
import { mergeFieldValues } from 'utils/misc/formUtils';

type FieldValues = {
	infoId: string | null;
	title: string | null;
	value: string | null;
};

type Props = {} & Omit<DialogProps, 'open'>;

const UpsertRfpProfileInfoItemDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const { formatMessage } = useIntl();

	const dialogOpen = useSelector(isDialogOpen('UpsertRfpProfileInfoItemDialog'));
	const dialogData = useSelector(getDialogData('UpsertRfpProfileInfoItemDialog'));

	const validationSchema = React.useMemo(
		() =>
			yup.object({
				title: yup.string().required(),
				value: yup.string().required(),
			}),
		[]
	);

	const defaultValues = React.useMemo(
		() => ({
			infoId: null,
			title: '',
			value: '',
		}),
		[]
	);

	const {
		control,
		reset,
		handleSubmit,
		formState: { isDirty, isSubmitting },
	} = useForm<FieldValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const handleDialogClose = React.useCallback(() => {
		if (isDirty) {
			dispatch(
				openDialog({
					name: 'ConfirmCloseDialog',
					data: {
						onSubmit: () => {
							reset(defaultValues);
							dispatch(closeDialog({ name: 'UpsertRfpProfileInfoItemDialog' }));
						},
					},
				})
			);
		} else {
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProfileInfoItemDialog' }));
		}
	}, [dispatch, reset, isDirty, defaultValues]);

	const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
		async fieldValues => {
			await dialogData?.onSubmit(fieldValues);
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProfileInfoItemDialog' }));
		},
		[dispatch, reset, defaultValues, dialogData]
	);

	React.useEffect(() => {
		if (!isNil(dialogData?.fieldValues)) {
			reset(mergeFieldValues(defaultValues, dialogData?.fieldValues), { keepDirtyValues: true });
		}
	}, [reset, defaultValues, dialogData]);

	return (
		<Dialog {...otherProps} maxWidth='xs' open={dialogOpen} onClose={handleDialogClose} fullWidth>
			<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
				<CloseIcon />
			</IconButton>
			<Box component='form' onSubmit={handleSubmit(handleFormSubmit)}>
				<DialogTitle>
					<FormattedMessage id='rfpProfileInfoItem.title' />
				</DialogTitle>
				<DialogContent>
					<Stack>
						<Controller
							name='title'
							control={control}
							render={({ field, fieldState }) => (
								<TextField
									{...field}
									error={!isNil(fieldState.error)}
									label={formatMessage({ id: `rfpProfileInfoItem.${field.name}.label` })}
									placeholder={formatMessage({ id: `rfpProfileInfoItem.${field.name}.placeholder` })}
									helperText={fieldState.error?.message}
								/>
							)}
						/>
						<Controller
							name='value'
							control={control}
							render={({ field, fieldState }) => (
								<TextField
									{...field}
									error={!isNil(fieldState.error)}
									label={formatMessage({ id: `rfpProfileInfoItem.${field.name}.label` })}
									placeholder={formatMessage({ id: `rfpProfileInfoItem.${field.name}.placeholder` })}
									helperText={fieldState.error?.message}
								/>
							)}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button variant='text' size='small' color='neutral' onClick={handleDialogClose}>
						<FormattedMessage id='rfpProfileInfoItem.cancel' />
					</Button>
					<LoadingButton type='submit' loading={isSubmitting}>
						<FormattedMessage id='rfpProfileInfoItem.submit' />
					</LoadingButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default UpsertRfpProfileInfoItemDialog;
