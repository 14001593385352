import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { makeStyles } from '@mui/styles';

import InstructionPopover from 'components/CustomPopover';
import DataGrid from 'components/DataGrid';
import ModalDialog from 'components/Dialog/ModalDialog';
import ConfirmButton from 'components/Button/ConfirmButton';
import ConfirmIconButton from 'components/Button/ConfirmIconButton';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ModalContent from '../../ModalContent';
import ModalTitle from '../../ModalTitle';
import FormattedDate from 'components/Formatters/FormattedDate';
import HoverPopover from 'components/CustomPopover/HoverPopover';

import { markAsHandled, removeProspect } from 'containers/Advanced/slices';
import { getLiteScoreColor } from '../../utils';

const useStyles = makeStyles(theme => ({
    container: {
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    },
    inputWrapper: {
        width: '100%',
        [theme.breakpoints.between('sm', '700')]: {
            maxWidth: theme.spacing(28),
        },
        [theme.breakpoints.up('700')]: {
            maxWidth: theme.spacing(40),
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.spacing(50),
        },
    },
    table: {
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.primary.white,
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '& .MuiDataGrid-columnHeader--sortable, & .MuiDataGrid-columnHeader': {
            '&:focus, &:focus-within': {
                outline: 'none',
            },
        },
        '& .MuiTablePagination-caption': {
            display: 'none',
        },
        // TODO: check why top is not correct by default
        '& .MuiDataGrid-overlay': {
            top: '112px !important',
        },
        '& .MuiCircularProgress-root': {
            height: '1.5rem !important',
            width: '1.5rem !important',
        },
    },
    popper: {
        top: '1.5rem !important',
    },
    tooltip: {
        padding: '0.5rem',
        border: '1px solid #ccc',
        background: 'rgba(255, 255, 255, 0.9)',
        color: theme.palette.primary.main,
        fontSize: '1rem',
    },
    header: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1rem',
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    cell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1rem',
        color: theme.palette.primary.main,
    },
    customerName: {
        fontWeight: 700,
    },
    customerCompanyId: {
        color: theme.palette.primary.main,
    },
    circle: {
        height: '0.875rem',
        width: '0.875rem',
        borderRadius: '0.875rem',
    },
    grantedScore: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    iconButton: {
        lineHeight: 0,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    error: {
        color: theme.palette.error.main,
    },
    errorStatus: {
        color: theme.palette.error.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingTop: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.white,
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.primary.main,
        },
    },
    buttonLoading: {
        position: 'absolute',
        color: theme.palette.primary.main,
    },
    row: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
}));

const ProspectList = ({
    customersLoading,
    removeLoading,
    prospects,
    rowsPerPage,
    fetchdata,
    reloadCustomers,
    setIsData,
    phrase,
    toggleImportant
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const [currentId, setCurrentId] = useState(null);

    const isLoaded = prospects?.isLoaded;
    const token = prospects?.token;
    const data = prospects?.data;

    useEffect(() => {
        if (data?.length > 0) {
            setIsData(true);
        }
    }, [data, setIsData]);

    const filteredProspects = data?.filter(({ rowKey, advancedCustomerName }) => rowKey?.toLowerCase().includes(phrase.toLowerCase()) || advancedCustomerName?.toLowerCase().includes(phrase.toLowerCase()));

    const dataWithoutNegativeScores = filteredProspects?.map(item => {
        return ({
            ...item,
            grantedScore: !item.grantedScore
                ? null
                : +item.grantedScore < 0
                    ? 0
                    : +item.grantedScore
        });
    });

    // modal
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const toggleModal = data => {
        setModalOpen(!modalOpen);
        setModalData(data);
    };

    const [rowCount, setRowCount] = useState(0);

    const handleProspectRemove = async customer => {
        setCurrentId(customer.rowKey);

        const success = await dispatch(removeProspect(customer));

        if (success) {
            toast.success(<FormattedMessage id='advanced.prospects.remove.success' />);
            setRowCount(rowCount - 1);

            if (modalOpen) setModalOpen(false);
            if (modalData) setModalData(null);

            return;
        }

        toast.error(<FormattedMessage id='advanced.prospects.remove.failed' />);
    };

    const [moveLoading, setMoveLoading] = useState(false);
    const handleProspectMove = async customer => {
        setCurrentId(customer.rowKey);
        setMoveLoading(true);
        const success = await dispatch(markAsHandled(customer));
        setMoveLoading(false);

        if (success) {
            toast.success(<FormattedMessage id='advanced.prospects.move.success' />);
            setRowCount(rowCount - 1);

            if (modalOpen) setModalOpen(false);
            if (modalData) setModalData(null);

            return;
        }

        toast.error(<FormattedMessage id='advanced.prospects.move.failed' />);
    };

    const columns = [
        {
            field: 'liteScoreValue',
            headerClassName: 'styled-header',
            width: 100,
            renderHeader: () => (
                <HoverPopover
                    sx={{ fontSize: '0.875rem' }}
                    id='popperitemlite'
                    content={intl.formatMessage({ id: 'customerlist.table.liteScore.instructions' })}
                    title={intl.formatMessage({ id: 'customerlist.table.liteScoreValue' })}
                />
            ),
            renderCell: params => {
                const liteScoreValue = params.row.liteScoreValue;

                return (
                    <Grid container alignItems='center'>
                        <Grid item style={{ paddingBottom: '0.125rem' }}>
                            {liteScoreValue !== null &&
                                <div
                                    className={classes.circle}
                                    style={{
                                        backgroundColor: getLiteScoreColor(liteScoreValue)
                                    }}
                                />
                            }
                        </Grid>
                        <Grid item style={{ paddingLeft: '0.375rem' }}>
                            <Typography
                                className={classes.grantedScore}
                                style={{
                                    color: getLiteScoreColor(liteScoreValue)
                                }}
                            >
                                {liteScoreValue}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: 'rowKey',
            headerName: intl.formatMessage({ id: 'customerlist.table.profile.navigate' }),
            headerClassName: 'styled-header',
            width: 120,
            disableClickEventBubbling: true,
            disableColumnMenu: false,
            renderCell: params => (
                <Tooltip title={params.id} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span className={classes.cell}>
                        <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={e => {
                                e.stopPropagation();
                                history.push(`/company/${params.id}`);
                            }}
                            className={classes.button}
                        >
                            {params.id}
                        </Button>
                    </span>
                </Tooltip>
            ),
        },
        // {
        //     field: 'important',
        //     headerName: intl.formatMessage({ id: 'customerlist.table.important' }),
        //     headerClassName: 'styled-header',
        //     width: 80,
        //     renderCell: params => (
        //         <span className={classes.cell}>
        //             <IconButton
        //                 color='primary'
        //                 onClick={e => {
        //                     e.stopPropagation();
        //                     toggleImportant(params.row.customerCompanyId, params.row.important);
        //                 }}
        //             >
        //                 {params.row.important ? <StarIcon /> : <StarOutlineIcon />}
        //             </IconButton>
        //         </span>
        //     ),
        // },
        {
            field: 'advancedCustomerName',
            headerName: intl.formatMessage({ id: 'customerlist.table.name' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <Tooltip
                    title={params.row.advancedCustomerName ? params.row.advancedCustomerName : ''}
                    placement='top'
                    classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                >
                    <>
                        <span className={`${classes.cell} ${classes.customerName}`}>{params.row.advancedCustomerName}</span>
                        {/* <span className={classes.customerCompanyId}>&nbsp;{params.row.customerCompanyId}</span> */}
                    </>
                </Tooltip>
            ),
        },
        {
            field: 'contactInfo',
            headerName: intl.formatMessage({ id: 'customerlist.table.contact.info' }),
            headerClassName: 'styled-header',
            width: 120,
            renderCell: params => {
                if (params.row.contactProfile) {
                    return (
                        <CheckIcon color='primary' />
                    );
                }
            },
        },
        {
            field: 'created',
            type: 'date',
            headerName: intl.formatMessage({ id: 'customerlist.table.profile.creation.date' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <Tooltip
                    title={<FormattedDate value={params.row.created} />}
                    placement='top'
                    classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                >
                    <span className={classes.cell}>
                        <FormattedDate value={params.row.created} />
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'markAsHandled',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderHeader: () => (
                <>
                    {intl.formatMessage({ id: 'customerlist.table.markashandled' })}
                    <InstructionPopover
                        id='popperitem'
                        content={intl.formatMessage({ id: 'customerlist.table.markashandled.instructions' })}
                    />
                </>
            ),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params =>
                params.row.statusValue === 1 ? (
                    <Box textAlign='center'>
                        <ConfirmButton
                            variant='outlined'
                            color='primary'
                            loading={moveLoading && !modalOpen && currentId === params.row.rowKey}
                            disabled={moveLoading && !modalOpen && currentId === params.row.rowKey}
                            confirmText={intl.formatMessage({ id: 'advanced.prospects.move.confirm' })}
                            cancelText={intl.formatMessage({ id: 'advanced.prospects.move.cancel' })}
                            confirmBodyText={intl.formatMessage({ id: 'advanced.prospects.move.body' })}
                            confirmAction={() => handleProspectMove(params.row)}
                            stopPropagation={true}
                        >
                            {intl.formatMessage({ id: 'customerlist.table.markashandled.link' })}
                        </ConfirmButton>
                    </Box >
                ) : null,
        },
        {
            field: 'removeCustomer',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderHeader: () => (
                <>
                    {intl.formatMessage({ id: 'customerlist.table.remove' })}
                    <InstructionPopover
                        id='popperitem'
                        content={intl.formatMessage({ id: 'customerlist.table.removeCustomer.instructions' })}
                    />
                </>
            ),
            headerClassName: 'styled-header',
            width: 120,
            renderCell: params => (
                <Box textAlign='center'>
                    <ConfirmIconButton
                        color='primary'
                        loading={removeLoading && currentId === params.row.rowKey}
                        disabled={removeLoading && currentId === params.row.rowKey}
                        confirmText={intl.formatMessage({ id: 'advanced.prospects.remove.confirm' })}
                        cancelText={intl.formatMessage({ id: 'advanced.prospects.remove.cancel' })}
                        confirmBodyText={intl.formatMessage({ id: 'advanced.prospects.remove.body' })}
                        confirmAction={() => handleProspectRemove(params.row)}
                        stopPropagation={true}
                        sx={{ mr: 2 }}
                    >
                        <DeleteIcon />
                    </ConfirmIconButton>
                </Box>
            ),
        },
    ];

    return (
        !isLoaded || (isLoaded && filteredProspects?.length !== 0) ? (
            <>
                <DataGrid
                    idField='rowKey'
                    loading={!isLoaded || customersLoading}
                    data={dataWithoutNegativeScores}
                    columns={columns}
                    token={token}
                    rowsPerPage={rowsPerPage}
                    fetchdata={fetchdata}
                    reloadData={reloadCustomers}
                    onRowClick={params => toggleModal(params.row)}
                    classes={{
                        row: classes.row
                    }}
                    mt={2}
                />
                <ModalDialog
                    open={modalOpen}
                    onClose={() => toggleModal(null)}
                    title={<ModalTitle advancedCustomerName={modalData?.advancedCustomerName} rowKey={modalData?.rowKey} />}
                    actions={
                        <LeftRightContainer
                            alignItems='flex-start'
                            left={
                                <Button variant='text' color='primary' onClick={() => toggleModal(null)}>
                                    {intl.formatMessage({ id: 'common.close' })}
                                </Button>
                            }
                            right={
                                <Grid container justifyContent='flex-end' spacing={2}>
                                    <Grid item>
                                        <ConfirmButton
                                            variant='outlined'
                                            color='primary'
                                            loading={removeLoading}
                                            disabled={removeLoading}
                                            confirmAction={() => handleProspectRemove(modalData)}
                                            confirmText={intl.formatMessage({ id: 'advanced.prospects.remove.confirm' })}
                                            cancelText={intl.formatMessage({ id: 'advanced.prospects.remove.cancel' })}
                                            confirmBodyText={intl.formatMessage({ id: 'advanced.prospects.remove.body' })}
                                            startIcon={<DeleteIcon />}
                                            hoverRed={true}
                                        >
                                            {intl.formatMessage({ id: 'common.delete' })}
                                        </ConfirmButton>
                                    </Grid>
                                    <Grid item>
                                        <ConfirmButton
                                            variant='outlined'
                                            color='primary'
                                            loading={moveLoading}
                                            disabled={moveLoading}
                                            confirmText={intl.formatMessage({ id: 'advanced.prospects.move.confirm' })}
                                            cancelText={intl.formatMessage({ id: 'advanced.prospects.move.cancel' })}
                                            confirmBodyText={intl.formatMessage({ id: 'advanced.prospects.move.body' })}
                                            confirmAction={() => handleProspectMove(modalData)}
                                            startIcon={<PlaylistPlayIcon />}
                                            stopPropagation={true}
                                        >
                                            {intl.formatMessage({ id: 'customerlist.table.markashandled.link' })}
                                        </ConfirmButton>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' color='primary' /* startIcon={<AddLinkIcon />} */ onClick={() => history.push(`/company/${modalData?.rowKey}`)}>
                                            {intl.formatMessage({ id: 'customerlist.table.open.profile' })}
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        />
                    }
                >
                    <ModalContent
                        created={modalData?.created}
                        author={modalData?.author}
                        botProfileRowKey={modalData?.botProfileRowKey}
                        contactProfile={modalData?.contactProfile}
                        advancedCustomerName={modalData?.advancedCustomerName}
                        rowKey={modalData?.rowKey}
                    />
                </ModalDialog>
            </>
        ) : (
            <Box sx={{ mt: 2 }}>
                <Typography component='p' color='primary' align='center'>
                    {intl.formatMessage({ id: phrase ? 'filter.no.items' : 'customerlist.no.prospects' })}
                </Typography>
            </Box>
        )
    );
};

export default ProspectList;
