import { matchPath } from 'react-router-dom';
import { getCompanyId } from 'utils/auth/company';

/**
 * Parses url with company prefix
 *
 * @param url suffix uri to the company uri
 * @returns parsed uri
 */
const GetCompanyLink = (url, location) => {
	if (!location) throw Error('Location is required in getCompanyLink');

	const match = matchPath(location.pathname, {
		path: '/company/:businessId',
		exact: false,
		strict: false,
	});

	if (match) return `/company/${getCompanyId()}${url}`;

	return url;
};

export default GetCompanyLink;
