import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import axios from 'utils/axios/index';
import { getCompany } from 'utils/auth/company';
import { API_ROOT } from 'config/environment';
import { CUSTOMER_FETCH_SIZE } from 'config/environment';
import { advancedApi } from './advancedApi';

export const name = 'customers';

const initialState = {
	loading: false,
	error: false,
	customers: {
		isLoaded: false,
		data: [],
		token: null,
	},
	prospects: {
		data: [],
		token: null,
		isLoaded: false,
	},
	isCustomersLoaded: false,
	grantedScore: [],
	removeLoading: false,
	customerLoading: false,
	creatingCustomerLoading: false,
	creatingCustomerError: null,
	// lists
	lists: [],
	loadListsLoading: false,
	loadListsError: false,
	loadListByIdLoading: false,
	loadListByIdError: null,
	createListLoading: false,
	createListError: null,
	deleteListByIdLoading: false,
	deleteListByIdError: null,
	deleteCompaniesFromListLoading: false,
	deleteCompaniesFromListError: null,
	addCompaniesToListLoading: false,
	addCompaniesToListError: null,
	addImportantMarkError: null,
	removeImportantMarkError: null
};

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},
		setRemoveLoading(state, action) {
			state.removeLoading = action.payload;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		loadCustomersError(state, action) {
			state.error = action.payload;
			state.customers = initialState.customers;
		},
		loadCustomers(state, action) {
			const isImportantFound = action.payload.data.some(({ important }) => important);
			state.customers = { ...action.payload, isLoaded: true, isImportantFound };
			state.isCustomersLoaded = true;
		},
		loadNextPage(state, action) {
			state.customers.data.push(...action.payload.data);
			state.customers.token = action.payload.token;
		},
		loadProspectsError(state, action) {
			state.error = action.payload;
			state.prospects = initialState.prospects;
		},
		loadProspects(state, action) {
			const isImportantFound = action.payload.data.some(({ important }) => important);
			state.prospects = { ...action.payload, isLoaded: true, isImportantFound };
		},
		loadProspectsNextPage(state, action) {
			state.prospects.data.push(...action.payload.data);
			state.prospects.token = action.payload.token;
		},
		addCustomers(state, action) {
			state.customers.data.push(action.payload);
		},
		markCustomerHandled(state, action) {
			const customerIndex = state.customers.data.findIndex(i => i.rowKey === action.payload.rowKey);
			state.customers.data[customerIndex] = action.payload;

			const index = state.prospects.data.findIndex(i => i.rowKey === action.payload.rowKey);

			if (index >= 0) {
				state.prospects.data.splice(index, 1); // index is found
			}
		},
		loadGrantedScore(state, action) {
			state.grantedScore.push(action.payload);
		},
		updateGrantedScore(state, action) {
			const grantedScoreIndex = state.grantedScore.findIndex(i => i.businessId === action.payload.businessId);
			state.grantedScore[grantedScoreIndex] = action.score;
		},
		removeCustomerFromList(state, action) {
			state.removeLoading = false;
			const index = state.customers.data.findIndex(item => item.rowKey === action.payload);

			if (index >= 0) {
				state.customers.data.splice(index, 1); // index is found
			}
		},
		removeProspectFromList(state, action) {
			state.removeLoading = false;
			const index = state.prospects.data.findIndex(item => item.rowKey === action.payload);

			if (index >= 0) {
				state.prospects.data.splice(index, 1); // index is found
			}
		},
		setCustomerLoading(state, action) {
			state.customerLoading = action.payload;
		},
		setCreatingCustomer(state) {
			state.creatingCustomerLoading = true;
		},
		setCreatingCustomerError(state, action) {
			state.creatingCustomerLoading = false;
			state.creatingCustomerError = action.payload;
		},
		setCreatingCustomerSuccess: (state, action) => {
			state.creatingCustomerLoading = false;
			state.customers.data.unshift(action.payload);
		},
		loadLists(state) {
			state.loadListsLoading = true;
			state.loadListsError = false;
		},
		loadListsError(state, action) {
			state.loadListsLoading = false;
			state.loadListsError = action.payload;
		},
		loadListsSuccess: (state, action) => {
			state.loadListsLoading = false;
			state.lists = action.payload;
		},
		loadListById(state) {
			state.loadListByIdLoading = true;
			state.loadListByIdError = false;
		},
		loadListByIdError(state, action) {
			state.loadListByIdLoading = false;
			state.loadListByIdError = action.payload;
		},
		loadListByIdSuccess: (state, action) => {
			state.loadListByIdLoading = false;

			const updatedLists = state.lists.map(item => {
				if (item.companyListId === action.payload.companyListId) {
					const data = action.payload.companies;

					const dataWithScores = data.map(item => {
						const customer = state.customers.data.find(({ customerCompanyId }) => customerCompanyId === item.businessId);

						return {
							...item,
							advancedCustomerName: customer ? customer.advancedCustomerName : null,
							contactProfile: customer ? customer.contactProfile : null,
							grantedScore: customer ? customer.grantedScore : null,
							liteScoreValue: customer ? customer.liteScoreValue : null,
							important: customer ? customer.important : false,
						};
					});

					item.isLoaded = true;
					item.data = dataWithScores;
				}

				return item;
			});

			state.lists = updatedLists;
		},
		createListLoading(state) {
			state.createListLoading = true;
			state.createListError = null;
		},
		createListError(state, action) {
			state.createListLoading = false;
			state.createListError = action.payload;
		},
		createListSuccess: (state, action) => {
			state.createListLoading = false;
			state.createListError = null;
			state.lists.unshift(action.payload);
		},
		deleteListByIdLoading(state) {
			state.deleteListByIdLoading = true;
			state.deleteListByIdError = null;
		},
		deleteListByIdError(state, action) {
			state.deleteListByIdLoading = false;
			state.deleteListByIdError = action.payload;
		},
		deleteListByIdSuccess: (state, action) => {
			state.deleteListByIdLoading = false;
			state.deleteListByIdError = null;
			state.lists = state.lists.filter(({ companyListId }) => companyListId !== action.payload);
		},
		deleteCompaniesFromListLoading(state) {
			state.deleteCompaniesFromListLoading = true;
			state.deleteCompaniesFromListError = null;
		},
		deleteCompaniesFromListError(state, action) {
			state.deleteCompaniesFromListLoading = false;
			state.deleteCompaniesFromListError = action.payload;
		},
		deleteCompaniesFromListSuccess: (state, action) => {
			state.deleteCompaniesFromListLoading = false;
			state.deleteCompaniesFromListError = null;

			const updatedListIndex = state.lists.findIndex(({ companyListId }) => companyListId === action.payload.listId);
			const updatedList = state.lists[updatedListIndex];
			updatedList.data = updatedList.data.filter(({ businessId }) => !action.payload.companiesIds.includes(businessId));

			state.lists[updatedListIndex] = updatedList;
		},
		addCompaniesToListLoading(state) {
			state.addCompaniesToListLoading = true;
			state.addCompaniesToListError = null;
		},
		addCompaniesToListError(state, action) {
			state.addCompaniesToListLoading = false;
			state.addCompaniesToListError = action.payload;
		},
		addCompaniesToListSuccess: (state, action) => {
			state.addCompaniesToListLoading = false;
			state.addCompaniesToListError = null;

			const updatedListIndex = state.lists.findIndex(({ companyListId }) => companyListId === action.payload.listId);
			const updatedList = state.lists[updatedListIndex];
			updatedList.data = action.payload.companies.map(item => {
				const customer = state.customers.data.find(({ customerCompanyId }) => customerCompanyId === item.businessId);

				return {
					...item,
					advancedCustomerName: customer ? customer.advancedCustomerName : null,
					contactProfile: customer ? customer.contactProfile : null,
					grantedScore: customer ? customer.grantedScore : null,
					liteScoreValue: customer ? customer.liteScoreValue : null,
				};
			});
			updatedList.isLoaded = true;

			state.lists[updatedListIndex] = updatedList;
		},
		addImportantMarkError(state, action) {
			state.addImportantMarkError = action.payload;
		},
		addImportantMarkSuccess: (state, action) => {
			// update customers:
			const updatedCustomerIndex = state.customers.data.findIndex(({ customerCompanyId }) => customerCompanyId === action.payload);
			if (updatedCustomerIndex >= 0) state.customers.data[updatedCustomerIndex].important = true;

			// update prospects:
			const updatedProspectIndex = state.prospects.data.findIndex(({ customerCompanyId }) => customerCompanyId === action.payload);
			if (updatedProspectIndex >= 0) state.prospects.data[updatedProspectIndex].important = true;

			// update other lists:
			if (state.lists.length > 0) {
				for (const list of state.lists) {
					if (!list.data) return;

					const updatedListIndex = list.data.findIndex(({ businessId }) => businessId === action.payload);
					if (updatedListIndex >= 0) list.data[updatedListIndex].important = true;
				}
			}

		},
		removeImportantMarkError(state, action) {
			state.removeImportantMarkError = action.payload;
		},
		removeImportantMarkSuccess: (state, action) => {
			// update customers:
			const updatedCustomerIndex = state.customers.data.findIndex(({ customerCompanyId }) => customerCompanyId === action.payload);
			if (updatedCustomerIndex > 0) state.customers.data[updatedCustomerIndex].important = false;

			// update prospects:
			const updatedProspectIndex = state.prospects.data.findIndex(({ customerCompanyId }) => customerCompanyId === action.payload);
			if (updatedProspectIndex > 0) state.prospects.data[updatedProspectIndex].important = false;

			// update other lists:
			if (state.lists.length > 0) {
				for (const list of state.lists) {
					if (!list.data) return;

					const updatedListIndex = list.data.findIndex(({ businessId }) => businessId === action.payload);
					if (updatedListIndex >= 0) list.data[updatedListIndex].important = false;
				}
			}
		},
	}
});

export const { reducer } = slice;

export const resetCustomers = () => async dispatch => {
	dispatch(slice.actions.resetData());

	return true;
};

export const loadCustomers = () => async dispatch => {
	try {
		dispatch(slice.actions.setLoading(true));
		const company = getCompany();
		const response = await axios.get(`${API_ROOT}/advanced/customer?advancedCompanyId=${company.CompanyId}&take=${CUSTOMER_FETCH_SIZE}`);

		// const customerList = response.data?.data;
		// customerList?.map(({ rowKey, grantedScore }) => dispatch(loadGrantedScore(rowKey, grantedScore)));

		dispatch(slice.actions.loadCustomers(response.data));
		dispatch(slice.actions.setLoading(false));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadCustomersError(error));

		return false;
	}
};

export const loadNextPage = () => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.setLoading(true));

		const state = getState();
		const { customers } = state.customers;
		const token = customers?.token;

		if (!token) {
			dispatch(slice.actions.setLoading(false));

			return true;
		}

		const company = getCompany();

		const response = await axios.get(
			`${API_ROOT}/advanced/customer?advancedCompanyId=${company.CompanyId}&take=${CUSTOMER_FETCH_SIZE}&nextpartition=${token.nextPartitionKey}&nextrow=${token.nextRowKey}`
		);

		// for (let i = 0; i < response.data.data.length; i++) {
		//     dispatch(loadGrantedScore(response.data.data[i].rowKey, response.data.data[i].grantedScore));
		// }

		dispatch(slice.actions.loadNextPage(response.data));
		dispatch(slice.actions.setLoading(false));

		return true;
	} catch (err) {
		return false;
	}
};

export const addCustomer = (customer) => async dispatch => {
	try {
		const company = getCompany();

		const newCustomer = {
			CustomerCompanyId: customer.businessId,
			AdvancedCustomerName: customer.name,
		};

		const response = await axios.post(`${API_ROOT}/advanced/customer?advancedCompanyId=${company.CompanyId}`, newCustomer);

		if (!response || !response.data) throw Error('customer_not_found');

		dispatch(loadGrantedScore(response.data.rowKey, response.data.grantedScore));
		dispatch(slice.actions.addCustomers(response.data));

		return true;
	} catch (err) {
		return false;
	}
};

export const markAsHandled = (customer) => async dispatch => {
	try {
		const company = getCompany();

		const response = await axios.post(`${API_ROOT}/advanced/customer/${customer.rowKey}/handle?advancedCompanyId=${company.CompanyId}`);

		dispatch(slice.actions.markCustomerHandled(response.data));
		dispatch(loadGrantedScore(response.data.rowKey, response.data.grantedScore));
		dispatch(slice.actions.addCustomers(response.data));

		return true;
	} catch (err) {
		return false;
	}
};

export const removeCustomer = (customer) => async dispatch => {
	try {
		dispatch(slice.actions.setLoading(true));
		dispatch(slice.actions.setRemoveLoading(true));

		const company = getCompany();

		const response = await axios.post(`${API_ROOT}/advanced/customer/${customer.rowKey}/remove?advancedCompanyId=${company.CompanyId}`);

		dispatch(slice.actions.removeCustomerFromList(response.data));
		dispatch(slice.actions.setLoading(false));

		return true;
	} catch (err) {
		return false;
	}
};

export const loadGrantedScore = (id, grantedScore) => async dispatch => {
	try {
		if (grantedScore) {
			dispatch(slice.actions.loadGrantedScore({
				businessId: id,
				grantedScore: grantedScore,
				grantedScoreCalculated: true,
				grantedScoreCalculatedDate: new Date(),
			}));

			return true;
		}

		const company = getCompany();
		const response = await axios.get(`${API_ROOT}/scenario/${id}?advancedCompanyId=${company.CompanyId}`);

		if (!response || !response.data) return;

		dispatch(slice.actions.loadGrantedScore({
			businessId: id,
			grantedScore: response.data.grantedScore,
			grantedScoreCalculated: response.data.grantedScoreCalculated,
			grantedScoreCalculatedDate: response.data.grantedScoreCalculatedDate,
		}));

		return true;
	} catch (error) {
		return false;
	}
};

export const updateGrantedScore = (id) => async dispatch => {
	try {
		const company = getCompany();
		const response = await axios.get(`${API_ROOT}/scenario/${id}?advancedCompanyId=${company.CompanyId}`);

		dispatch(
			slice.actions.updateGrantedScore({
				businessId: id,
				grantedScore: response.data.grantedScore,
				grantedScoreCalculated: response.data.grantedScoreCalculated,
				grantedScoreCalculatedDate: response.data.grantedScoreCalculatedDate,
			})
		);

		return true;
	} catch (error) {
		return false;
	}
};

export const loadProspects = () => async dispatch => {
	try {
		const company = getCompany();
		const response = await axios.get(`${API_ROOT}/advanced/prospects?advancedCompanyId=${company.CompanyId}&take=${CUSTOMER_FETCH_SIZE}`);

		// for (let i = 0; i < response?.data?.data?.length; i++) {
		//     dispatch(loadGrantedScore(response.data.data[i].rowKey, response.data.data[i].grantedScore));
		// }

		dispatch(slice.actions.loadProspects(response.data));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadProspectsError(error));

		return false;
	}
};

export const loadProspectsNextPage = () => async (dispatch, getState) => {
	try {
		const state = getState();

		const token = state.customers?.prospects?.token;

		if (!token) {
			dispatch(slice.actions.setLoading(false));

			return null;
		}

		dispatch(slice.actions.setLoading(true));

		const company = getCompany();
		const response = await axios.get(
			`${API_ROOT}/advanced/prospects?advancedCompanyId=${company.CompanyId}&take=${CUSTOMER_FETCH_SIZE}&nextpartition=${token.nextPartitionKey}&nextrow=${token.nextRowKey}`
		);

		// for (let i = 0; i < response?.data?.data?.length; i++) {
		//     dispatch(loadGrantedScore(response.data.data[i].rowKey, response.data.data[i].grantedScore));
		// }

		dispatch(slice.actions.loadProspectsNextPage(response.data));
		dispatch(slice.actions.setLoading(false));

		return true;
	} catch (error) {
		return false;
	}
};

export const removeProspect = (customer) => async dispatch => {
	try {
		dispatch(slice.actions.setLoading(true));
		dispatch(slice.actions.setRemoveLoading(true));

		const company = getCompany();

		const response = await axios.delete(`${API_ROOT}/advanced/prospects/${customer.rowKey}?advancedCompanyId=${company.CompanyId}`);

		dispatch(slice.actions.removeProspectFromList(response.data));
		dispatch(slice.actions.setLoading(false));

		return true;
	} catch (error) {
		return false;
	}
};

export const storeCustomerInfo = (customer) => async dispatch => {
	try {
		dispatch(slice.actions.setCustomerLoading(true));

		const company = getCompany();
		const response = await axios.post(`${API_ROOT}/advanced/customer/${customer.rowKey}/info?advancedCompanyId=${company.CompanyId}`, customer);

		dispatch(slice.actions.setCustomerLoading(false));

		return response.data;
	} catch (error) {
		return false;
	}
};

export const getCustomerInfo = (customerId) => async dispatch => {
	try {
		dispatch(slice.actions.setCustomerLoading(true));

		const company = getCompany();
		const response = await axios.get(`${API_ROOT}/advanced/customer/${customerId}/info?advancedCompanyId=${company.CompanyId}`);

		dispatch(slice.actions.setCustomerLoading(false));

		return response.data;
	} catch (error) {
		return false;
	}
};

export const createCustomerCompany = company => async dispatch => {
	try {
		dispatch(slice.actions.setCreatingCustomer());

		const createdCustomer = await advancedApi.createCustomerCompany(company);

		dispatch(slice.actions.setCreatingCustomerSuccess(createdCustomer));

		return true;
	} catch (error) {
		dispatch(slice.actions.setCreatingCustomerError(error));

		return null;
	}
};

export const loadLists = () => async dispatch => {
	try {
		dispatch(slice.actions.loadLists());

		const lists = await advancedApi.getLists();

		dispatch(slice.actions.loadListsSuccess(lists));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadListsError(error));

		return false;
	}
};

export const loadListById = listId => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.loadListById());

		const state = getState();
		const customersIsLoaded = state.customers?.customers?.isLoaded;

		const list = await advancedApi.getListById(listId);

		if (!customersIsLoaded) {
			await dispatch(loadCustomers());
		}

		dispatch(slice.actions.loadListByIdSuccess(list));

		return true;
	} catch (error) {
		dispatch(slice.actions.loadListByIdError(error));

		return false;
	}
};

export const createList = list => async dispatch => {
	try {
		dispatch(slice.actions.createListLoading());

		const createdList = await advancedApi.addList(list);

		dispatch(slice.actions.createListSuccess(createdList));
		toast.success(<FormattedMessage id='customer.page.create.list.success' />);

		return createdList.companyListId;
	} catch (error) {
		dispatch(slice.actions.createListError(error));
		toast.error(<FormattedMessage id='customer.page.create.list.failed' />);

		return false;
	}
};

export const deleteListById = listId => async dispatch => {
	try {
		dispatch(slice.actions.deleteListByIdLoading());

		await advancedApi.deleteListById(listId);

		dispatch(slice.actions.deleteListByIdSuccess(listId));
		toast.success(<FormattedMessage id='customer.page.delete.list.success' />);

		return true;
	} catch (error) {
		dispatch(slice.actions.deleteListByIdError(error));
		toast.error(<FormattedMessage id='customer.page.delete.list.failed' />);

		return false;
	}
};

export const deleteCompaniesFromList = (listId, companiesIds) => async dispatch => {
	try {
		dispatch(slice.actions.deleteCompaniesFromListLoading());

		await advancedApi.deleteCompaniesFromList(listId, companiesIds);

		dispatch(slice.actions.deleteCompaniesFromListSuccess({ listId, companiesIds }));
		toast.success(<FormattedMessage id='customer.page.delete.companies.from.list.success' />);

		return true;
	} catch (error) {
		dispatch(slice.actions.deleteCompaniesFromListError(error));
		toast.error(<FormattedMessage id='customer.page.delete.companies.from.list.failed' />);

		return false;
	}
};

export const addCompaniesToList = (listId, companiesIds) => async dispatch => {
	try {
		dispatch(slice.actions.addCompaniesToListLoading());

		const response = await advancedApi.addCompaniesToList(listId, companiesIds);
		const { companies } = response;

		dispatch(slice.actions.addCompaniesToListSuccess({ listId, companies }));
		toast.success(<FormattedMessage id='customer.page.add.companies.to.list.success' />);

		return true;
	} catch (error) {
		dispatch(slice.actions.addCompaniesToListError(error));
		toast.error(<FormattedMessage id='customer.page.add.companies.to.list.failed' />);

		return false;
	}
};

export const addImportantMark = customerCompanyId => async dispatch => {
	try {
		await advancedApi.addImportantMark(customerCompanyId);

		dispatch(slice.actions.addImportantMarkSuccess(customerCompanyId));
		toast.success(<FormattedMessage id='customer.page.add.important.mark.success' />);

		return true;
	} catch (error) {
		dispatch(slice.actions.addImportantMarkError(error));
		toast.error(<FormattedMessage id='customer.page.add.important.mark.failed' />);

		return false;
	}
};

export const removeImportantMark = customerCompanyId => async dispatch => {
	try {
		await advancedApi.removeImportantMark(customerCompanyId);

		dispatch(slice.actions.removeImportantMarkSuccess(customerCompanyId));
		toast.success(<FormattedMessage id='customer.page.remove.important.mark.success' />);

		return true;
	} catch (error) {
		dispatch(slice.actions.removeImportantMarkError(error));
		toast.error(<FormattedMessage id='customer.page.remove.important.mark.failed' />);

		return false;
	}
};

export default slice;
