import FormControl from '@mui/material/FormControl';
import { Controller } from 'react-hook-form';

import TextField from './FormStyledTextfield';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ name, defaultValue, control, fullWidth = true, disabled }) {
	return (
		<FormControl fullWidth={fullWidth}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field: { onChange, value } }) => (
					<TextField
						disabled={disabled}
						value={value}
						placeholder='00000'
						onChange={e => {
							const numericValue = e.target.value.replace(/\D/g, '');
							onChange(numericValue);
						}}
						variant='outlined'
						inputProps={{
							maxLength: 5, // Maximum length of 5 characters
						}}
					/>
				)}
			/>
		</FormControl>
	);
}
