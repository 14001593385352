import { useIntl } from 'react-intl';

import { Divider, Grid, Skeleton } from '@mui/material';

import { useTheme } from '@mui/styles';
import ProjectSummary from 'containers/Projects/Project/ProjectSummary';
import { useGetCompanyDataQuery, useGetProjectDataByIdQuery } from 'containers/Stakeholder/stakeholderApi';
import Error from '../../Components/Error';

export const ProjectSkeleton = () => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Skeleton variant='rectangular' height={50} sx={{ mb: '20px', width: '100%' }} />
				<Skeleton variant='rectangular' height={30} sx={{ mb: '20px', width: '25%' }} />
				<Divider />
			</Grid>
			<Grid item xs={12} sm={4} md={2}>
				<Skeleton variant='rectangular' height={100} sx={{ mb: '20px', width: '100%' }} />
				<Skeleton variant='rectangular' height={100} sx={{ mb: '20px', width: '100%' }} />
				<Skeleton variant='rectangular' height={100} sx={{ mb: '20px', width: '100%' }} />
				<Skeleton variant='rectangular' height={100} sx={{ mb: '20px', width: '100%' }} />
			</Grid>
			<Grid item xs={12} sm={8} md={10}>
				<Skeleton variant='rectangular' height={30} sx={{ mb: '20px', width: '100%' }} />
				<Skeleton variant='rectangular' height={300} sx={{ mb: '20px', width: '100%' }} />
				<Skeleton variant='rectangular' height={30} sx={{ mb: '20px', width: '100%' }} />
				<Skeleton variant='rectangular' height={150} sx={{ mb: '20px', width: '100%' }} />
				<Skeleton variant='rectangular' height={150} sx={{ mb: '20px', width: '100%' }} />
			</Grid>
		</Grid>
	);
};

const Project = ({ linkId, projectId }) => {
	const intl = useIntl();
	const theme = useTheme();

	const {
		data: projectData,
		isFetching: projectIsFetching,
		isLoading: projectIsLoading,
		isSuccess: projectIsSuccess,
	} = useGetProjectDataByIdQuery({ linkId, projectId });

	const { data: company, isFetching: isCompanyFetching, isLoading: isCompanyLoading } = useGetCompanyDataQuery(linkId);

	if (projectIsFetching || projectIsLoading || isCompanyFetching || isCompanyLoading) return <ProjectSkeleton />;

	if (!projectIsSuccess) return <Error>{intl.formatMessage({ id: 'stakeholder.projects.error' })}</Error>;

	return (
		<>
			<ProjectSummary
				project={projectData?.project}
				template={projectData?.project?.template}
				company={company?.company}
				readonly={true}
				// @ts-ignore
				sx={{ padding: theme.spacing(2) }}
			/>
		</>
	);
};

export default Project;
