import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { Project } from 'types/dataTypes';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';
import { finance } from './slices';

const baseUrl = 'project';

export const TAGS = {
	PROJECTS: 'PROJECTS',
	PROJECT: 'PROJECT',
	TEMPLATES: 'TEMPLATES',
	PROJECTPROMPT: 'PROJECTPROMPT',
};

export const projectsApi = createApi({
	reducerPath: 'projects',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [TAGS.PROJECTS, TAGS.PROJECT, TAGS.TEMPLATES],
	endpoints: builder => ({
		fetchProjects: builder.query<Project[], { companyId: string | null }>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}

				return {
					method: 'GET',
					url: `/${companyId}/projects`,
				};
			},
			providesTags: [TAGS.PROJECTS],
		}),
		fetchProject: builder.query<Project, { companyId: string | null; projectId: string; draft: boolean; withData?: boolean }>({
			query: ({ companyId, projectId, draft, withData = false }) => {
				if (isNil(companyId) || isNil(projectId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: withData
						? `/${companyId}/project/${projectId}/all?draft=${draft}`
						: `/${companyId}/project/${projectId}?draft=${draft}`,
				};
			},
			transformResponse: (response: Project): Project => {
				// @ts-ignore
				if (!response) return null;

				if (!response.projectFinance || response.projectFinance.length === 0) {
					response.projectFinance = finance;
				}
				return response;
			},
			providesTags: (data, error, params) => {
				return [{ type: TAGS.PROJECT, id: params?.projectId ?? '' }];
			},
		}),
		upsertProject: builder.mutation<Project, { companyId: string | null; project: Project; draft?: boolean | null }>({
			query: ({ companyId, project, draft }) => {
				if (isNil(companyId) || isNil(project) || isNil(prompt)) {
					throw new Error('parameter error');
				}

				return {
					method: 'POST',
					url: draft ? `/${companyId}/project/draft` : `/${companyId}/project/attachments`,
					params: draft ? { draft: draft } : {},
					headers: { 'content-type': 'text/json' },
					data: project,
				};
			},
			invalidatesTags: (_result, _err, params) => {
				return [{ type: TAGS.PROJECT, id: _result?.projectId ?? '' }];
			},
		}),
		fetchProjectPrompt: builder.query<Project, { companyId: string | null; projectId: string; draft?: boolean | null }>({
			query: ({ companyId, projectId, draft }) => {
				if (isNil(companyId) || isNil(projectId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/project/${projectId}/prompt`,
					params: draft ? { draft: draft } : {},
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: TAGS.PROJECTPROMPT, id: params?.projectId ?? '' }];
			},
		}),
		upsertProjectPrompt: builder.mutation<
			Project,
			{ companyId: string | null; projectId: string; prompt: string; draft?: boolean | null }
		>({
			query: ({ companyId, projectId, draft, prompt }) => {
				if (isNil(companyId) || isNil(projectId) || isNil(prompt)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/${companyId}/project/${projectId}/prompt`,
					params: draft ? { draft: draft } : {},
					headers: { 'content-type': 'text/plain' },
					data: prompt,
				};
			},
			invalidatesTags: (_result, _err, params) => {
				return [{ type: TAGS.PROJECTPROMPT }, { type: TAGS.PROJECTPROMPT, id: params?.projectId ?? '' }];
			},
		}),
		fetchTimeline: builder.query({
			query: ({ companyId }) => {
				return {
					method: 'GET',
					url: `/${companyId}/timeline`,
				};
			},
			providesTags: [TAGS.PROJECTS],
		}),
		fetchInstumentTemplate: builder.query<any, { instrumentId: string | null | undefined; templateVersion?: string | null }>({
			query: ({ instrumentId, templateVersion }) => {
				if (isNil(instrumentId)) {
					throw new Error(`parameter error instrumentId:  ${instrumentId}`);
				}

				return {
					method: 'GET',
					url: `/project/templates/${instrumentId}`,
					params: { version: templateVersion },
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: TAGS.TEMPLATES, id: params?.instrumentId ?? '' }];
			},
		}),
		upsertInstrumentTemplate: builder.mutation<any, { instrumentId: string; template: any; templateVersion?: string | null }>({
			query: ({ template, instrumentId, templateVersion }) => {
				if (isNil(template) || isNil(instrumentId)) {
					throw new Error('parameter error, template is required');
				}

				return {
					method: 'POST',
					url: `/project/templates/${instrumentId}`,
					params: templateVersion ? { version: templateVersion } : {},
					data: template,
				};
			},
			invalidatesTags: (_result, _err, params) => {
				return [{ type: TAGS.TEMPLATES }, { type: TAGS.TEMPLATES, id: params?.instrumentId ?? '' }];
			},
		}),
		fetchProjectTasks: builder.query<Project, { companyId: string | null; projectId: string; draft: boolean }>({
			query: ({ companyId, projectId, draft }) => {
				if (isNil(companyId) || isNil(projectId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/project/${projectId}/tasks?draft=${draft}`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: TAGS.PROJECT, id: params?.projectId ?? '' }];
			},
		}),
		fetchProjectCosts: builder.query<Project, { companyId: string | null; projectId: string; draft: boolean }>({
			query: ({ companyId, projectId, draft }) => {
				if (isNil(companyId) || isNil(projectId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/costs/${projectId}?draft=${draft}`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: TAGS.PROJECT, id: params?.projectId ?? '' }];
			},
		}),
	}),
});

export const {
	useFetchProjectsQuery,
	useFetchProjectQuery,
	useFetchInstumentTemplateQuery,
	useLazyFetchInstumentTemplateQuery,
	useFetchTimelineQuery,
	useUpsertInstrumentTemplateMutation,
	useFetchProjectPromptQuery,
	useUpsertProjectPromptMutation,
	useFetchProjectTasksQuery,
	useFetchProjectCostsQuery,
	useUpsertProjectMutation,
} = projectsApi;
