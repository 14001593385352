import React, { useState, useRef, useLayoutEffect } from 'react';
import Drawer from '@mui/material/Drawer';

import Instrument from '../Instrument';

const InstrumentDrawer = ({ open, close, instrument, instruments, company }) => {
	const radarContainerRef = useRef();
	const [radarSize, setRadarSize] = useState(null);

	useLayoutEffect(() => {
		const maxSize = 400;
		const updateWidth = () => {
			setRadarSize(radarContainerRef.current?.clientWidth < maxSize ? radarContainerRef.current?.clientWidth : maxSize);
		};

		window.addEventListener('resize', updateWidth);
		updateWidth();

		return () => window.removeEventListener('resize', updateWidth);
	}, []);

	return (
		<Drawer
			anchor='bottom'
			// sx={{ '& .MuiPaper-root': { height: '100vh' } }}
			open={open}
			onClose={close}
			transitionDuration={{ enter: 1000, exit: 1000 }}
		>
			<Instrument
				ref={radarContainerRef}
				instrument={instrument}
				instruments={instruments}
				company={company}
				radarSize={radarSize}
				profile={true}
				closeInstrumentDrawer={close}
			/>
		</Drawer>
	);
};

export default InstrumentDrawer;
