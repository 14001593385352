import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import AdminContentContainer from 'components/Containers/AdminContentContainer';
import { getCompanyDetails } from 'containers/Admin/UsersCompany/slices';
import { resetSelectedCompany, searchCompanyData, selectCompany } from 'containers/CompanySearch/slices';
import { BusinessIdChip } from '../../../../views/Dashboard/HeaderTitle/HeaderTitle';

const useStyles = makeStyles(theme => ({
	input: {
		backgroundColor: theme.palette.primary.white,
	},
	paper: {
		backgroundColor: theme.palette.primary.white,
		'& .MuiAutocomplete-option[aria-selected="true"]': {
			backgroundColor: theme.palette.primary.white,
			padding: theme.spacing(1),
			paddingLeft: theme.spacing(3),
		},
	},
	text: {
		color: theme.palette.primary.main,
	},
	endAdornment: {
		display: 'none',
	},
	noOptions: {
		display: 'none',
	},
	loading: {
		display: 'none',
	},
	adminContent: {
		marginLeft: theme.spacing(2),
	},
}));

const CompanySearch = () => {
	const classes = useStyles();
	const intl = useIntl();
	const history = useHistory();

	const dispatch = useDispatch();
	const companySearch = useSelector(state => state.companySearch);

	const loading = companySearch?.loading;
	const companies = companySearch?.companies;
	const isOptionBusinessId = companySearch?.isOptionBusinessId;

	const [value, setValue] = useState('');
	const [debouncedValue] = useDebounce(value, 500);

	useEffect(() => {
		if (debouncedValue.length > 2) {
			dispatch(searchCompanyData(debouncedValue));
		} else {
			dispatch(resetSelectedCompany());
		}
	}, [debouncedValue, dispatch]);

	const onSelectCompany = (_, value) => {
		dispatch(selectCompany(value));
		dispatch(resetSelectedCompany());
	};

	const getOptionLabel = option => {
		if (isOptionBusinessId) {
			return option?.businessId ?? option;
		} else {
			return option?.name ?? option;
		}
	};

	const onChangeName = event => setValue(event.target.value);

	const options = companies && !(companies.code < 200 || companies.code > 299) ? companies : [];

	const sortedOptions = [...options].sort((a, b) => b.isCustomer - a.isCustomer);


	const openCompanyAdminPage = companyId => {
		dispatch(getCompanyDetails(companyId));
		history.push(`/admin/company/${companyId}`);
	};

	const renderInput = params => (
		<TextField
			variant='outlined'
			placeholder={intl.formatMessage({ id: 'company.search.placeholder' })}
			onChange={onChangeName}
			{...params}
			InputProps={{
				...params.InputProps,
				startAdornment: (
					<InputAdornment position='start'>
						<SearchIcon color='primary' />
					</InputAdornment>
				),
				endAdornment: (
					<>
						{loading ? <CircularProgress color='inherit' size={24} /> : null}
						{params.InputProps.endAdornment}
					</>
				),
				classes: { input: classes.text },
			}}
			classes={{
				root: classes.input,
			}}
		/>
	);

	const renderOption = (props, option) => (
		<ListItem key={option.businessId} alignItems='center' {...props}>
			<Grid container>
				<Grid item>
					<ListItemText
						className={classes.customerNameWrapper}
						primary={
							<Typography component='span' color='primary' className={classes.customerName}>
								{option.name}
								<BusinessIdChip sx={{ ml: '.5rem' }} businessId={option.companyId} />
							</Typography>
						}
					/>
				</Grid>
				<Grid item className={classes.adminContent}>
					<AdminContentContainer>
						<Button
							size='small'
							variant='contained'
							color='primary'
							onClick={e => {
								e.stopPropagation();
								openCompanyAdminPage(isEmpty(option?.companyId) ? option?.businessId : option?.companyId);
							}}
						>
							{intl.formatMessage({ id: 'companylist.table.companyDetails.link' })}
						</Button>
					</AdminContentContainer>
				</Grid>
			</Grid>
		</ListItem>
	);

	return (
		<>
			<Autocomplete
				freeSolo
				fullWidth
				disableClearable
				clearOnEscape
				getOptionLabel={getOptionLabel}
				options={sortedOptions}
				value={value}
				onChange={onSelectCompany}
				loading={loading}
				renderInput={renderInput}
				renderOption={renderOption}
				classes={{
					paper: classes.paper,
					endAdornment: classes.endAdornment,
					noOptions: classes.noOptions,
					loading: classes.loading,
				}}
			/>
		</>
	);
};

export default CompanySearch;
