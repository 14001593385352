import * as yup from 'yup';

export const maxLength = 30000;

export const getSchema = intl => {
	return yup.object().shape({
		pitch: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`),
		problem: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`),
		solution: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`),
		markets: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`),
		globalMarket: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`),
		vision: yup
			.string()
			.nullable()
			.max(maxLength, `${intl.formatMessage({ id: 'project.form.validation.max.length' }, { value: maxLength })}`),
		costs: yup
			.array()
			.of(
				yup.object().shape({
					costName: yup.string().required(intl.formatMessage({ id: 'businessPlan.costs.costName.required' })),
					costCategory: yup.string().required(intl.formatMessage({ id: 'businessPlan.costs.costCategory.required' })),
					costAmount: yup.number().required(intl.formatMessage({ id: 'businessPlan.costs.costAmount.required' })),
					startTime: yup.date().typeError(
						`${intl.formatMessage({ id: 'businessPlan.costs.startDate' })} ${intl.formatMessage({
							id: 'message.isRequired',
						})}`
					),
					endTime: yup
						.date()
						.min(yup.ref('startTime'), intl.formatMessage({ id: 'businessPlan.costs.endDate.mindate' }))
						.typeError(
							`${intl.formatMessage({ id: 'businessPlan.costs.endDate' })} ${intl.formatMessage({
								id: 'message.isRequired',
							})}`
						),
				})
			)
			.nullable(),
	});
};
