import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid } from '@mui/material';

import { FormControlledInput, FormControlledSwitchItem, FromControlledHiddenField } from 'components/Form';
import { useUpsertInstrumentTemplateMutation } from 'containers/Projects/projectRTKApi';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Instrument } from 'types/dataTypes';
import FieldArray from './FieldArray';

const InstrumentTemplatesForm = ({
	data,
	instrument,
	handleTemplateSubmit,
}: {
	data: any;
	instrument: Instrument;
	handleTemplateSubmit: any;
}) => {
	const { formatMessage } = useIntl();

	const schema = yup.object().shape({
		instrumentId: yup.string().required(),
		fields: yup
			.array()
			.required()
			.min(1)
			.of(
				yup.object().shape({
					id: yup.string().required(formatMessage({ id: 'message.isRequired' })),
					title: yup.string().required(formatMessage({ id: 'message.isRequired' })),
					type: yup.string().required(formatMessage({ id: 'message.isRequired' })),
					fields: yup
						.array()
						.required()
						.min(1)
						.of(
							yup.object().shape({
								id: yup.string().required(formatMessage({ id: 'message.isRequired' })),
								title: yup.string().required(formatMessage({ id: 'message.isRequired' })),
								type: yup.string().required(formatMessage({ id: 'message.isRequired' })),
							})
						),
				})
			),
	});

	const {
		control,
		register,
		handleSubmit,
		getValues,
		formState: { errors, isDirty },
		reset,
		setValue,
	} = useForm({
		defaultValues: data,
		resolver: yupResolver(schema),
	});

	const [upsertInstrumentTemplate, { isLoading: isUpsertLoading }] = useUpsertInstrumentTemplateMutation();

	const onSubmit = async (template: any, isUpdate: boolean, isCopy: boolean) => {
		try {
			let res = await upsertInstrumentTemplate({
				template: template,
				instrumentId: isCopy ? instrument.id : template.instrumentId ?? instrument.id,
				templateVersion: isCopy ? null : isUpdate && data.templateVersion ? data.templateVersion : null,
			}).unwrap();

			if (isCopy) {
				reset({
					instrumentId: instrument.id,
					templateVersion: res.templateVersion,
					instrumentName: instrument.name,
					provider: instrument.provider,
					showProjectPlan: data.showProjectPlan,
					fields: data.fields,
				});

				if (handleTemplateSubmit) {
					handleTemplateSubmit({
						instrumentId: instrument.id,
						templateVersion: res.templateVersion,
						instrumentName: instrument.name,
						provider: instrument.provider,
						showProjectPlan: data.showProjectPlan,
						fields: data.fields,
					});
				}
			}

			toast.success('Hakemuksen template tallennettu');
		} catch (e: any) {
			toast.error(e?.message);
		}
	};

	useEffect(() => {
		const currentFormValues = getValues();

		if (currentFormValues.instrumentId !== data.instrumentId || currentFormValues?.templateVersion !== data.templateVersion) {
			reset(data);
			return;
		}

		if (!currentFormValues?.templateVersion || !data.templateVersion) return;

		if (currentFormValues?.templateVersion !== data.templateVersion) {
			reset(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Box component='form' onSubmit={handleSubmit(data => onSubmit(data, false, false))}>
			<Grid container sx={{ mb: '1rem' }} spacing={1}>
				<Grid item xs={12} md={6} lg={4}>
					<FromControlledHiddenField defaultValue={instrument.id} name={'instrumentId'} control={control} />

					<FormControlledInput
						disabled
						variant='standard'
						defaultValue={instrument.name}
						name={'instrumentName'}
						control={control}
						error={errors?.instrumentName?.message}
						label={formatMessage({ id: 'instrument.form.template.instrumentName' })}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<FormControlledInput
						disabled
						variant='standard'
						defaultValue={instrument.provider}
						name={'provider'}
						control={control}
						error={errors?.provider?.message}
						label={formatMessage({ id: 'instrument.form.template.provider' })}
					/>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<FormControlledInput
						disabled
						variant='standard'
						defaultValue={data.templateVersion}
						placeholder={data.templateVersion ? data.templateVersion : 'Ei tallennettua versiota'}
						name={'templateVersion'}
						control={control}
						error={errors?.templateVersion?.message}
						label={formatMessage({ id: 'instrument.form.template.templateVersion' })}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					{/* @ts-ignore */}
					<FormControlledSwitchItem
						defaultValue={data.showProjectPlan}
						name='showProjectPlan'
						control={control}
						error={errors?.showProjectPlan?.message}
						label={formatMessage({ id: 'instrument.form.template.showProjectPlan' })}
						checkedVal={true}
						notCheckedVal={false}
						checkedLabel={'Kyllä'}
						notCheckedLabel={'Ei'}
					/>
				</Grid>
			</Grid>

			<FieldArray {...{ control, register, data, getValues, setValue, errors, loading: isUpsertLoading }}>
				<Box>
					<Button startIcon={<CachedIcon />} disabled={isUpsertLoading} onClick={() => reset(data)} sx={{ mr: '1rem' }}>
						Palauta arvot
					</Button>

					{data.templateVersion && (
						<Button
							disabled={isUpsertLoading}
							startIcon={<SaveIcon />}
							variant='contained'
							color='success'
							onClick={handleSubmit(data => onSubmit(data, true, false))}
							sx={{ mr: '1rem' }}
						>
							Tallenna ja päivitä
						</Button>
					)}

					<Button
						disabled={isUpsertLoading}
						color='success'
						variant='contained'
						startIcon={<AddIcon />}
						onClick={handleSubmit(data => onSubmit(data, false, false))}
					>
						Tallenna ja luo uusi versio
					</Button>

					<Button
						disabled={isUpsertLoading}
						color='success'
						variant='contained'
						startIcon={<AddIcon />}
						sx={{ ml: '1rem' }}
						onClick={handleSubmit(data => onSubmit(data, false, true))}
					>
						Tallenna ja luo kopio
					</Button>
				</Box>
			</FieldArray>
		</Box>
	);
};

export default InstrumentTemplatesForm;
