import React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

const Tags = ({ loading = false, tags = [] }) => {
    return (
        loading
            ? <CircularProgress size={16} color='primary' sx={{ mb: 2 }} />
            : !loading && tags.length > 0
                ? <Stack direction='row' alignItems='center' sx={{ flexWrap: 'wrap', mb: 2 }}>
                    {tags.map((tag, index) =>
                        <Chip
                            key={tag + index}
                            label={tag}
                            size='small'
                            sx={{
                                backgroundColor: 'primary.secondary',
                                color: 'secondary.grey700',
                                mr: 1,
                                mt: 1
                            }}
                        />
                    )}
                </Stack>
                : null
    );
};

export default Tags;