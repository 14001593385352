import React from 'react';
import { useIntl } from 'react-intl';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { StyledTextField } from 'components/Form/FormStyledTextfield';


const FilterInput = ({ value, onChange }) => {
    const intl = useIntl();

    return (
        <StyledTextField
            id='search'
            variant='standard'
            value={value || ''}
            onChange={onChange}
            placeholder={intl.formatMessage({ id: 'instrument.list.filter.placeholder' })}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <SearchIcon fontSize='small' />
                    </InputAdornment>
                ),
            }}
            sx={{
                width: '100%',
                minWidth: { xs: '20rem', sm: '20rem', lg: '20rem' },
            }}
        />
    );
};

export default FilterInput;
