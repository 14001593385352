export const umlautsDecode = text => {
  if (!text) return text;

  return (
    text
      .replaceAll('&auml;', 'ä')
      .replaceAll('&Auml;', 'Ä')
      .replaceAll('&ouml;', 'ö')
      .replaceAll('&Ouml;', 'Ö')
      .replaceAll('&aring;', 'å')
      .replaceAll('&Aring;', 'Å')
      .replaceAll('&nbsp;', ' ')
  );
};