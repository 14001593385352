import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { useIntl } from 'react-intl';

import { renderKeys } from '../../renderKeys';

const colors = {
	0: '#482e51',
	1: '#077871',
	2: '#8e0e44',
	3: '#1976d2',
	4: '#cca111',
};

const EconomicalChart = ({ data }) => {
	const theme = useTheme();
	const intl = useIntl();

	const renderFields = renderKeys.filter(item => item !== 'year');

	const renderData = {
		series: renderFields.map((item, index) => {
			return {
				color: colors[index],
				data: data?.map(field => Math.round(field[item])),
				name: item,
			};
		}),
		xaxis: {
			dataPoints: data?.map(({ year }) => year),
		},
	};

	const initiallySelectedFields = renderFields.filter(item => item !== 'balance' && item !== 'equity');

	const [selectedSeries, setSelectedSeries] = useState(initiallySelectedFields);

	const handleChange = (event, name) => {
		if (!event.target.checked) {
			setSelectedSeries(selectedSeries.filter(item => item !== name));
		} else {
			setSelectedSeries([...selectedSeries, name]);
		}
	};

	const chartSeries = renderData.series.filter(item => selectedSeries.includes(item.name));

	const chartOptions = {
		chart: {
			background: 'transparent',
			stacked: false,
			toolbar: {
				show: false,
			},
		},
		colors: chartSeries.map(item => item.color),
		dataLabels: {
			enabled: false,
		},
		grid: {
			borderColor: theme.palette.divider,
			yaxis: {
				lines: {
					show: false,
				},
			},
			xaxis: {
				lines: {
					show: false,
				},
			},
		},
		legend: {
			show: false,
		},
		markers: {
			hover: {
				size: undefined,
				sizeOffset: 2,
			},
			radius: 0,
			size: 1,
			strokeWidth: 0,
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent'],
		},
		theme: {
			mode: theme.palette.mode,
		},
		tooltip: {
			y: {
				formatter: undefined,
				title: {
					formatter: name => intl.formatMessage({ id: `business.plan.economical.table.${name}` }).replace(' tEur', ''),
				},
			},
		},
		xaxis: {
			axisBorder: {
				color: theme.palette.divider,
			},
			axisTicks: {
				color: theme.palette.divider,
				show: true,
			},
			categories: renderData.xaxis.dataPoints,
			labels: {
				style: {
					colors: theme.palette.text.secondary,
				},
			},
		},
		yaxis: [
			{
				axisBorder: {
					color: theme.palette.divider,
					show: true,
				},
				axisTicks: {
					color: theme.palette.divider,
					show: true,
				},
				labels: {
					formatter: value => Math.round(value),
					style: {
						colors: theme.palette.text.secondary,
					},
				},
				title: {
					text: intl.formatMessage({ id: 'thousand.euro' }),
					rotate: -90,
					offsetX: 0,
					offsetY: 0,
					style: {
						color: undefined,
						fontSize: '0.85rem',
						fontFamily: 'Poppins, sans-serif',
						fontWeight: 400,
						cssClass: 'apexcharts-yaxis-title',
					},
				},
			},
		],
	};

	return (
		<Box>
			<Box
				display='flex'
				sx={{
					alignItems: 'center',
					flexWrap: 'wrap',
				}}
			>
				{renderData.series.map(item => (
					<Box
						key={item.name}
						display='flex'
						sx={{
							alignItems: 'center',
							mr: 2,
						}}
					>
						<Checkbox
							checked={selectedSeries.some(visibleItem => visibleItem === item.name)}
							onChange={event => handleChange(event, item.name)}
							sx={{
								color: `${item.color} !important`,
								'&.Mui-checked': {
									color: item.color,
								},
							}}
						/>
						<Typography color='primary' variant='subtitle2'>
							{intl.formatMessage({ id: `business.plan.economical.table.${item.name}` }).replace(' tEur', '')}
						</Typography>
					</Box>
				))}
			</Box>
			<Chart options={chartOptions} series={chartSeries} type='bar' height={350} />
		</Box>
	);
};

export default EconomicalChart;
