import React from 'react';
import { styled, withTheme } from '@mui/styles';

const FormLabel = styled(withTheme('div'))(({ theme }) => (
    {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.palette.secondary.grey700,
        marginBottom: '0.5rem',
    }
));

export default function ({ children }) {
    return (
        <FormLabel>
            {children}
        </FormLabel>
    );
}