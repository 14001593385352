import { includes } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import { default as MenuItem, default as Typography } from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';

import { IconButton, Stack } from '@mui/material';
import StyledMenu from 'components/StyledMenu';
import { loadCustomers } from 'containers/Advanced/slices';
import { loadData, resetCompanyData } from 'containers/App/appSlice';
import { setCompany } from 'utils/auth/company';

const useStyles = makeStyles((theme: any) => ({
	companyName: {
		color: theme.palette.primary.main,
		fontWeight: 600,
		[theme.breakpoints.down('sm')]: {
			minWidth: '18rem',
		},
		minWidth: '30rem',
		'&:hover': {
			backgroundColor: theme.palette.primary.white,
			cursor: 'auto',
		},
	},
	menuItem: {
		'& .MuiListItemText-root': {
			color: theme.palette.primary.main,
			[theme.breakpoints.down('sm')]: {
				maxWidth: '14rem',
			},
		},
		'& .MuiListItemText-primary': {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemText-root, & .MuiListItemText-secondary': {
				color: theme.palette.primary.white,
			},
		},
	},
	currentItem: {
		backgroundColor: theme.palette.secondary.lightBeige,
		'&:hover': {
			cursor: 'default',
			backgroundColor: theme.palette.secondary.lightBeige,
			'& .MuiListItemText-root, & .MuiListItemText-secondary, & span': {
				cursor: 'default',
				color: theme.palette.primary.main,
				backgroundColor: 'transparent',
			},
		},
	},
	customerName: {
		fontSize: '1rem',
		fontWeight: 600,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	businessId: {
		fontSize: '1rem',
		paddingRight: 0,
	},
}));

type CompaniesDropdownProps = {
	open: boolean;
	user: any;
	localCompanyId: string | null;
	pathname: string;
	isMenuDrawerOpen: boolean;
	anchorOwnCompanies: any;
	setIsMenuDrawerOpen: Dispatch<SetStateAction<boolean>>;
	setAnchorOwnCompanies: Dispatch<SetStateAction<null>>;
};

const OwnCompaniesDropdown: React.FC<CompaniesDropdownProps> = ({
	open,
	user,
	localCompanyId,
	pathname,
	isMenuDrawerOpen,
	anchorOwnCompanies,
	setIsMenuDrawerOpen,
	setAnchorOwnCompanies,
}) => {
	const prevLocation = useLocation();

	const classes = useStyles();
	const theme = useTheme();
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();
	//@ts-ignore
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));

	const companies = user?.companies;

	const handleCloseOwnCompanies = (company: any) => {
		if (anchorOwnCompanies !== null) setAnchorOwnCompanies(null);
		if (isMenuDrawerOpen) setIsMenuDrawerOpen(false);

		if (company && Object.keys(company).length !== 0 && company.constructor === Object) {
			dispatch(resetCompanyData());

			const { CompanyId, License } = company;
			const noAgreement = includes(company.Roles, 'Company.ContractMissing');

			setCompany(company);

			if (noAgreement) {
				if (pathname !== `/company/${CompanyId}/agreement`) {
					history.push(`/company/${CompanyId}/agreement`, prevLocation);
				}

				return;
			}

			if (License === 'advanced' && CompanyId && typeof CompanyId === 'string') {
				history.push('/customers');
				dispatch(loadCustomers());
			} else if (License === 'expired' && CompanyId && typeof CompanyId === 'string') {
				history.push('/license_expired');
			} else {
				if (pathname !== `/company/${CompanyId}`) {
					history.push(`/company/${CompanyId}`);
				}
				dispatch(loadData());
			}
		}
	};

	return (
		<StyledMenu {...{ open, anchorEl: anchorOwnCompanies }}>
			<Stack direction='row'>
				<Typography className={classes.companyName}>
					{intl.formatMessage({ id: 'own.companies.dropdown.choose.company' })}
				</Typography>
				<IconButton
					aria-label='close'
					onClick={() => {
						setIsMenuDrawerOpen(false);
						setAnchorOwnCompanies(null);
					}}
				>
					<CloseIcon />
				</IconButton>
			</Stack>
			<Divider />
			{companies?.map((company: any) => {
				const { CompanyId, CompanyName } = company;

				return (
					<MenuItem
						key={CompanyId}
						onClick={localCompanyId !== CompanyId ? () => handleCloseOwnCompanies(company) : undefined}
						className={`${classes.menuItem} ${localCompanyId === CompanyId && classes.currentItem}`}
					>
						<ListItemText
							primary={
								smDown ? (
									CompanyName
								) : (
									<Grid container alignItems='center'>
										<Grid item style={{ maxWidth: '20rem' }}>
											<Typography component='span' className={classes.customerName}>
												{CompanyName}
											</Typography>
										</Grid>
										<Grid item style={{ marginLeft: 'auto', paddingLeft: '3rem' }}>
											<Typography component='span' className={classes.businessId}>
												{CompanyId}
											</Typography>
										</Grid>
									</Grid>
								)
							}
							secondary={smDown ? CompanyId : null}
						/>
					</MenuItem>
				);
			})}
		</StyledMenu>
	);
};

export default OwnCompaniesDropdown;
