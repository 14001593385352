import { financialplanEdit, licenseExpired, notFound, projectsAdd, themeConst as theme } from 'config/routeConst';
import CategoriesPage from 'containers/Admin/CategoriesPage';
import InstrumentsEditPage from 'containers/Admin/InstrumentsEditPage';
import MRR from 'containers/Admin/MRR/MRR';
import ReferencesPage from 'containers/Admin/ReferencesPage/ReferencesPage';
import SharedLinks from 'containers/Stakeholder/SharedLinks/SharedLinks';
import Stakeholder from 'containers/Stakeholder/Stakeholder';
import { ChangePassword, LoginTemporaryPassword, RequestTemporaryPassword } from 'views/Account';
import ActivateAccount from 'views/Account/ActivateAccount';
import { CompanyDetailsPage, CompanyListPage, UserDetailsPage, UserListPage } from 'views/Admin';
import AnalysisPage from 'views/AnalysisPage';
import AnalysisLinkPage from 'views/AnalysisPage/AnalysisLinkPage';
import BusinessPlanPage from 'views/BusinessPlanPage';
import BusinessPlanPrintPage from 'views/BusinessPlanPage/BusinessPlanPrintPage';
import Pricing from 'views/Contracts/Pricing/PricingView';
import CustomersPage from 'views/CustomersPage';
import Dashboard from 'views/Dashboard';
import FinancialPlanPage from 'views/FinancialPlanPage';
import EditFinancialPlan from 'views/FinancialPlanPage/FinancialPlanForm';
import InstrumentPage from 'views/InstrumentPage';
import InstrumentsPage from 'views/InstrumentsPage';
import LicensePage from 'views/LicensePage';
import LoginPage from 'views/Login/LoginPage';
import MsalLoginPage from 'views/Login/MsalLoginPage';
import MsalLoginRedirectPage from 'views/Login/MsalLoginRedirectPage';
import TermsAndConditionsPage from 'views/Login/TermsAndConditionsPage';
import MarketPlacePage from 'views/MarketplacePage';
import MessagesPage from 'views/MessagesPage';
import NoCompany from 'views/NoCompany';
import NotFound from 'views/NotFound';
import ProfilePage from 'views/ProfilePage';
import ProjectPage from 'views/ProjectsPage/ProjectPage';
import ProjectPlanPage from 'views/ProjectsPage/ProjectPlanPage';
import ProjectPlanPrintPage from 'views/ProjectsPage/ProjectPlanPrintPage/ProjectPlanPrintPage';
import ProjectTaskPage from 'views/ProjectsPage/ProjectTaskPage';
import ProjectsPage from 'views/ProjectsPage/ProjectsPage';
import RegisterPage from 'views/Register/RegisterPage';
import SearchPage from 'views/SearchPage';
import UserPage from 'views/UserPage';
import InstrumentTemplatesForm from '../containers/Admin/InstrumentsEditPage/InstrumentTemplates/InstrumentTemplatesForm';
import CustomerDataGrid from '../containers/CustomerData/CustomerDataGrid';
import GuidesLandingPage from '../views/Guides/GuidesLandingPage';
import CreateProjectPage from '../views/ProjectsPage/CreateProjectPage';

const routes = [
	{
		path: '/activate/:id',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: ActivateAccount,
		title: 'activate',
	},
	{
		path: '/admin/login',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: MsalLoginPage,
		title: 'login admin',
	},
	{
		path: '/admin/login/redirect',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: MsalLoginRedirectPage,
		title: 'login admin',
	},
	{
		path: '/redirect',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: MsalLoginRedirectPage,
		title: 'login admin redirect',
	},
	{
		path: '/admin/login/msal',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: MsalLoginPage,
		title: 'login admin msal',
	},
	{
		path: '/register',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: RegisterPage,
		title: 'register',
	},
	{
		path: '/login',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: LoginPage,
		title: 'login',
	},
	{
		path: '/login/forgotpassword/:id',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: LoginTemporaryPassword,
		title: 'forgot password',
	},
	{
		path: '/login/forgotpassword',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: RequestTemporaryPassword,
		title: 'forgot password',
	},
	{
		path: ['/agreement', '/company/:companyId/agreement'],
		exact: true,
		masterPage: true,
		isPrivate: false,
		isRequireCompanyData: false,
		component: TermsAndConditionsPage,
		title: 'agreement',
	},
	{
		path: '/termsconditions',
		exact: true,
		masterPage: true,
		isPrivate: false,
		isRequireCompanyData: false,
		component: TermsAndConditionsPage,
		title: 'terms and conditions',
	},
	{
		path: ['/pricing', '/company/:companyId/pricing'],
		exact: true,
		masterPage: true,
		isPrivate: false,
		isRequireCompanyData: false,
		component: Pricing,
		title: 'pricing',
	},
	{
		path: '/user',
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: false,
		component: UserPage,
		title: 'user',
		children: [
			{
				path: '/user/password',
				exact: true,
				masterPage: false,
				isPrivate: true,
				isRequireCompanyData: false,
				component: ChangePassword,
				title: 'user password',
			},
		],
	},
	{
		path: ['/admin', '/admin/companylist'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isAdminRoute: true,
		component: CompanyListPage,
		isRequireCompanyData: false,
		title: 'admin',
		children: [
			{
				path: '/admin/mrr',
				exact: true,
				masterPage: true,
				isPrivate: true,
				idAdminRoute: true,
				isRequireCompanyData: false,
				component: MRR,
				pageTheme: theme.beige,
				title: 'admin mrrr',
			},
			{
				path: '/admin/company/:companyId',
				exact: true,
				masterPage: true,
				isPrivate: true,
				isAdminRoute: true,
				isRequireCompanyData: false,
				component: CompanyDetailsPage,
				title: 'admin company',
			},
			{
				path: '/admin/user/:userId',
				exact: true,
				masterPage: true,
				isPrivate: true,
				isAdminRoute: true,
				isRequireCompanyData: false,
				component: UserDetailsPage,
				title: 'admin user',
			},
			{
				path: '/admin/userlist',
				exact: true,
				masterPage: true,
				isPrivate: true,
				isAdminRoute: true,
				isRequireCompanyData: false,
				component: UserListPage,
				title: 'admin user list',
			},
			{
				path: '/admin/instruments',
				exact: true,
				masterPage: true,
				isPrivate: true,
				isAdminRoute: true,
				isRequireCompanyData: false,
				component: InstrumentsEditPage,
				title: 'admin instruments',
			},
			{
				path: '/admin/templates',
				exact: true,
				masterPage: true,
				isPrivate: true,
				isAdminRoute: true,
				isRequireCompanyData: false,
				component: InstrumentTemplatesForm,
				title: 'admin instruments templates',
			},
			{
				path: '/admin/categories',
				exact: true,
				masterPage: true,
				isPrivate: true,
				isAdminRoute: true,
				isRequireCompanyData: false,
				component: CategoriesPage,
				pageTheme: theme.beige,
				title: 'admin categories',
			},
			{
				path: '/admin/references',
				exact: true,
				masterPage: true,
				isPrivate: true,
				isAdminRoute: true,
				isRequireCompanyData: false,
				component: ReferencesPage,
				pageTheme: theme.beige,
				title: 'admin references',
			},
		],
	},
	{
		path: ['/search', '/admin/search'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: false,
		component: SearchPage,
		title: 'search',
	},
	{
		path: licenseExpired,
		exact: true,
		masterPage: true,
		hideFooter: false,
		isPrivate: true,
		isRequireCompanyData: false,
		component: LicensePage,
		title: 'license expired',
	},
	{
		path: '/profile/:link',
		masterPage: true,
		isPrivate: false,
		isRequireCompanyData: false,
		pageTheme: theme.beige,
		component: ProfilePage,
		title: 'profile',
	},
	{
		path: ['/instruments', '/company/:companyId/instruments'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		pageTheme: theme.beige,
		component: InstrumentsPage,
		title: 'instruments list',
		children: [
			{
				path: ['/instruments/:id', '/company/:companyId/instruments/:id'],
				exact: true,
				masterPage: true,
				isPrivate: true,
				isRequireCompanyData: true,
				hideFooter: false,
				component: InstrumentPage,
				title: 'instrument',
			},
		],
	},
	{
		path: ['/projects', '/company/:companyId/projects'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		component: ProjectsPage,
		pageTheme: theme.beige,
		title: 'projects',
		children: [
			{
				path: [`${projectsAdd}`, `/company/:companyId${projectsAdd}`],
				exact: true,
				masterPage: true,
				isPrivate: true,
				isRequireCompanyData: true,
				hideFooter: false,
				pageTheme: theme.beige,
				component: CreateProjectPage,
				title: 'projects add new',
			},
			{
				path: ['/projects/:id', '/company/:companyId/projects/:id'],
				exact: true,
				masterPage: true,
				isPrivate: true,
				isRequireCompanyData: true,
				hideFooter: false,
				component: ProjectPage,
				pageTheme: theme.beige,
				title: 'project dashboard',
				children: [
					{
						path: ['/projects/:id/plan', '/company/:companyId/projects/:id/plan'],
						exact: true,
						masterPage: true,
						isPrivate: true,
						isRequireCompanyData: true,
						hideFooter: false,
						component: ProjectPlanPage,
						pageTheme: theme.beige,
						title: 'project plan',
					},
					{
						path: ['/projects/:id/print', '/company/:companyId/projects/:id/print'],
						exact: true,
						masterPage: true,
						isPrivate: true,
						isRequireCompanyData: true,
						hideFooter: false,
						component: ProjectPlanPrintPage,
						pageTheme: theme.beige,
						title: 'project print',
					},
					{
						path: ['/projects/:id/summary', '/company/:companyId/projects/:id/summary'],
						exact: true,
						masterPage: true,
						isPrivate: true,
						isRequireCompanyData: true,
						hideFooter: false,
						component: ProjectPlanPrintPage,
						pageTheme: theme.beige,
						title: 'project summary',
					},
					{
						path: ['/projects/:id/:taskId', '/company/:companyId/projects/:id/:taskId'],
						exact: true,
						masterPage: true,
						isPrivate: true,
						isRequireCompanyData: true,
						hideFooter: false,
						component: ProjectTaskPage,
						pageTheme: theme.beige,
						title: 'project task',
					},
				],
			},
		],
	},
	{
		path: ['/messages', '/company/:companyId/messages'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		pageTheme: theme.beige,
		component: MessagesPage,
		title: 'messages',
	},
	{
		path: ['/marketplace', '/company/:companyId/marketplace'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		pageTheme: theme.beige,
		component: MarketPlacePage,
		title: 'marketplace',
		children: [
			{
				path: ['/marketplace/:tabId', '/company/:companyId/marketplace/:tabId'],
				exact: true,
				masterPage: true,
				isPrivate: true,
				isRequireCompanyData: true,
				pageTheme: theme.beige,
				component: MarketPlacePage,
				title: 'marketplace',
			},
		],
	},
	{
		path: ['/analysis', '/company/:companyId/analysis'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		pageTheme: theme.beige,
		component: AnalysisPage,
		title: 'analysis',
	},
	{
		path: ['/analysis/:link'],
		exact: true,
		masterPage: true,
		isPrivate: false,
		isRequireCompanyData: false,
		pageTheme: theme.beige,
		component: AnalysisLinkPage,
		title: 'analysis link',
	},
	{
		path: ['/financialplan', '/company/:companyId/financialplan'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		component: FinancialPlanPage,
		pageTheme: theme.beige,
		title: 'financialplan',
		children: [
			{
				path: [financialplanEdit, `/company/:companyId${financialplanEdit}`],
				exact: true,
				masterPage: true,
				isPrivate: true,
				isContributorRoute: true,
				isRequireCompanyData: true,
				pageTheme: theme.beige,
				component: EditFinancialPlan,
				title: 'financialplan edit',
			},
		],
	},
	{
		path: ['/businessplan', '/company/:companyId/businessplan'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		pageTheme: theme.beige,
		component: BusinessPlanPage,
		title: 'businessplan',
		children: [
			{
				path: ['/businessplan/print', '/company/:companyId/businessplan/print'],
				exact: true,
				masterPage: true,
				isPrivate: true,
				isRequireCompanyData: true,
				component: BusinessPlanPrintPage,
				pageTheme: theme.white,
				title: 'businessplan print',
			},
		],
	},
	{
		path: ['/share', '/company/:companyId/share'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		component: SharedLinks,
		title: 'share',
		pageTheme: theme.beige,
	},
	{
		path: '/stakeholder/:linkId',
		exact: true,
		masterPage: false,
		isPrivate: false,
		isRequireCompanyData: false,
		component: Stakeholder,
		title: 'stakeholder',
		children: [
			{
				path: '/stakeholder/:linkId/:area',
				exact: true,
				masterPage: false,
				isPrivate: false,
				isRequireCompanyData: false,
				component: Stakeholder,
				title: 'stakeholder tab 1',
				children: [
					{
						path: '/stakeholder/:linkId/:area/:param',
						exact: true,
						component: Stakeholder,
						title: 'stakeholder tab 1',
					},
				],
			},
		],
	},
	{
		path: '/customers',
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: false,
		component: CustomersPage,
		title: 'customers',
	},
	{
		path: ['/customerdata'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: false,
		component: CustomerDataGrid,
		title: 'customer-data',
	},
	{
		path: ['/', '/company/:companyId'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		pageTheme: theme.beige,
		component: Dashboard,
		title: 'dashboard',
	},
	{
		path: ['/guides', '/company/:companyId/guides'],
		exact: true,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: true,
		component: GuidesLandingPage,
		title: 'guides',
		pageTheme: theme.beige,
		children: [
			{
				path: ['/guides/:guideId', '/company/:companyId/guides/:guideId'],
				exact: true,
				masterPage: true,
				isPrivate: true,
				isRequireCompanyData: true,
				component: GuidesLandingPage,
				pageTheme: theme.beige,
				title: 'guides',
			},
		],
	},
	{
		path: notFound,
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: false,
		component: NotFound,
		title: '404',
	},
	{
		path: '/nocompany',
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: false,
		component: NoCompany,
		title: 'no company',
	},
	{
		path: '*',
		masterPage: true,
		isPrivate: true,
		isRequireCompanyData: false,
		component: NotFound,
		title: '',
	},
];

export default routes;
