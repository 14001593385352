import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';

import { useIntl } from 'react-intl';

import InfoBox from 'components/InfoBox';

import { ShowHideInPrintButton } from 'containers/Projects/Project/ProjectSummary/Components/ProjectSummarySection';
import { Project } from 'types/dataTypes';

type InfoBoxesProps = {
	project: Project;
};

/**
 * Creates Info boxes
 *
 * @param {*} project
 *
 * @returns
 */
const InfoBoxes = ({ project }: InfoBoxesProps) => {
	const intl = useIntl();
	const theme = useTheme();
	const xl = useMediaQuery(theme.breakpoints.up('xl'));

	const [isPrintable, setIsPrintable] = useState(true);

	return (
		<Box sx={{ displayPrint: isPrintable ? 'block' : 'none' }}>
			<Grid container spacing={2} alignItems='stretch'>
				<Grid item xs={6} xl={12} sx={{ mt: xl ? '2rem' : 0 }}>
					<InfoBox
						sx={{ height: '100%' }}
						title={intl.formatMessage({ id: 'project.print.summary.infobox.startDate.title' })}
						description={intl.formatMessage({ id: 'project.print.summary.infobox.startDate.description' })}
						value={project.startDate}
						dataType='date'
					/>
				</Grid>
				<Grid item xs={6} xl={12}>
					<InfoBox
						sx={{ height: '100%' }}
						title={intl.formatMessage({ id: 'project.print.summary.infobox.endDate.title' })}
						description={intl.formatMessage({ id: 'project.print.summary.infobox.endDate.description' })}
						value={project.endDate}
						dataType='date'
					/>
				</Grid>
				<Grid item xs={4} xl={12}>
					<InfoBox
						sx={{ height: '100%' }}
						title={intl.formatMessage({ id: 'project.print.summary.infobox.projectSize.title' })}
						description={intl.formatMessage({ id: 'project.print.summary.infobox.projectSize.description' })}
						value={project.projectSize}
						dataType='currency'
					/>
				</Grid>
				<Grid item xs={4} xl={12}>
					<InfoBox
						sx={{ height: '100%' }}
						title={intl.formatMessage({ id: 'project.print.summary.infobox.amountToApply.title' })}
						description={intl.formatMessage({ id: 'project.print.summary.infobox.amountToApply.description' })}
						value={project.amountToApply}
						dataType='currency'
					/>
				</Grid>
				<Grid item xs={4} xl={12}>
					<InfoBox
						sx={{ height: '100%' }}
						title={intl.formatMessage({ id: 'project.print.summary.infobox.deminimisAmount.title' })}
						description={intl.formatMessage({
							id: 'project.print.summary.infobox.deminimisAmount.description',
						})}
						value={project.deminimisAmount}
						dataType='currency'
					/>
				</Grid>
				<Grid item xs={4} xl={12}>
					<ShowHideInPrintButton {...{ isPrintable, setIsPrintable, showToggle: true }} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default InfoBoxes;
