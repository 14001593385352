import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import history from 'utils/misc/history';

import { passwordV2Api } from 'containers/Account/passwordV2Api';
import { companyNotesV2Api } from 'containers/Admin/UsersCompany/companyNotesV2Api';
import { analyticsApi } from 'containers/Analytics/analyticsApi';
import { contractApi } from 'containers/App/contractApi';
import { businessPlanV2Api } from 'containers/BusinessPlan/businessPlanV2Api';
import { conversationsApi } from 'containers/Collaboration/collaborationApi';
import { companyV2Api } from 'containers/Company/companyV2Api';
import { companySearchV2Api } from 'containers/CompanySearch/companySearchV2Api';
import { customerSearchApi } from 'containers/CustomerData/customerSearchApi';
import { financialPlanV2Api } from 'containers/FinancialPlan/financialPlanV2Api';
import { instrumentAnalysisApi } from 'containers/Instruments/InstrumentAIAnalysis/instrumentAnalysisApi';
import { instrumentV2Api } from 'containers/Instruments/instrumentV2Api';
import { rfpCommonApi } from 'containers/Marketplace/rfpCommonApi';
import { rfpProfileApi } from 'containers/Marketplace/rfpProfileApi';
import { rfpProposalApi } from 'containers/Marketplace/rfpProposalApi';
import { rfpRequestApi } from 'containers/Marketplace/rfpRequestApi';
import { messagesApi } from 'containers/Messages/api';
import { projectsApi } from 'containers/Projects/projectRTKApi';
import { projectSupplementaryApi } from 'containers/Projects/projectSupplementaryApi';
import { registrationV2Api } from 'containers/Register/registrationV2Api';
import { scenarioV2Api } from 'containers/Scenario/scenarioV2Api';

import appSlice from 'containers/App/appSlice';
import contractSlice from 'containers/App/contractSlice';
import dialogSlice from 'containers/Marketplace/dialogSlice';
import drawerSlice from 'containers/Marketplace/drawerSlice';
import popoverSlice from 'containers/Marketplace/popoverSlice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const createReducer = (injectedReducers = {}) => {
	const rootReducer = combineReducers({
		router: connectRouter(history),

		messages: messagesApi.reducer,
		conversations: conversationsApi.reducer,

		[rfpCommonApi.reducerPath]: rfpCommonApi.reducer,
		[rfpProfileApi.reducerPath]: rfpProfileApi.reducer,
		[rfpRequestApi.reducerPath]: rfpRequestApi.reducer,
		[rfpProposalApi.reducerPath]: rfpProposalApi.reducer,
		[companyV2Api.reducerPath]: companyV2Api.reducer,
		[analyticsApi.reducerPath]: analyticsApi.reducer,
		[projectSupplementaryApi.reducerPath]: projectSupplementaryApi.reducer,
		[companySearchV2Api.reducerPath]: companySearchV2Api.reducer,
		[scenarioV2Api.reducerPath]: scenarioV2Api.reducer,
		[businessPlanV2Api.reducerPath]: businessPlanV2Api.reducer,
		[projectsApi.reducerPath]: projectsApi.reducer,
		[instrumentV2Api.reducerPath]: instrumentV2Api.reducer,
		[passwordV2Api.reducerPath]: passwordV2Api.reducer,
		[registrationV2Api.reducerPath]: registrationV2Api.reducer,
		[financialPlanV2Api.reducerPath]: financialPlanV2Api.reducer,
		[companyNotesV2Api.reducerPath]: companyNotesV2Api.reducer,
		[contractApi.reducerPath]: contractApi.reducer,
		[instrumentAnalysisApi.reducerPath]: instrumentAnalysisApi.reducer,
		[customerSearchApi.reducerPath]: customerSearchApi.reducer,

		[appSlice.name]: appSlice.reducer,
		[dialogSlice.name]: dialogSlice.reducer,
		[popoverSlice.name]: popoverSlice.reducer,
		[drawerSlice.name]: drawerSlice.reducer,
		[contractSlice.name]: contractSlice.reducer,

		...injectedReducers,
	});

	return rootReducer;
};

export default createReducer;
