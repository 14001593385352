import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContentText, DialogProps, DialogTitle } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { closeDialog, getDialogData, isDialogOpen } from 'containers/Marketplace/dialogSlice';

type Props = Omit<DialogProps, 'open'> & {};

const ConfirmCloseDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = React.useState(false);

	const dialogOpen = useSelector(isDialogOpen('ConfirmCloseDialog'));
	const dialogData = useSelector(getDialogData('ConfirmCloseDialog'));

	const handleDialogClose = React.useCallback(() => {
		dispatch(closeDialog({ name: 'ConfirmCloseDialog' }));
	}, [dispatch]);

	return (
		<Dialog {...otherProps} open={dialogOpen} onClose={handleDialogClose}>
			<DialogTitle>
				<FormattedMessage id='confirmClose.title' />
			</DialogTitle>
			<DialogContentText>
				<FormattedMessage id='confirmClose.text' />
			</DialogContentText>
			<DialogActions>
				<Button variant='text' size='small' color='neutral' onClick={handleDialogClose}>
					<FormattedMessage id='confirmClose.cancel' />
				</Button>
				<LoadingButton
					loading={isLoading}
					onClick={async () => {
						setIsLoading(true);
						await dialogData?.onSubmit?.();
						handleDialogClose();
						setIsLoading(false);
					}}
				>
					<FormattedMessage id='confirmClose.submit' />
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmCloseDialog;
