export const storageKeys = {
	TOKEN_KEY: 'token',
	USER_KEY: 'user',
	AGREEMENT_KEY: 'agreement',
	COMPANY_KEY: 'company',
	CHAT_KEY: 'chat',
	COOKIE_CONSENT_KEY: 'cookie_consent',
	ONBOARDING_KEY: 'onboarding',
	BUSINESS_PLAN_KEY: 'business_plan',
	FINANCIAL_PLAN_KEY: 'financial_plan',
	PROJECT_KEY: 'project',
	MENU_DRAWER_KEY: 'menu_drawer',
	AI_CHAT_KEY: 'ai_chat',
};
