import { Box, Button, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { getCompanyId } from 'utils/auth/company';

import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ModalDialog from 'components/Dialog/ModalDialog';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ProjectSupplementaryForm from 'containers/Projects/Project/ProjectSupplementaryList/ProjectSupplementaryForm/ProjectSupplementaryForm';
import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved/ConfirmUnsaved';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';
import { useDeleteProjectSupplementaryMutation, useUpsertProjectSupplementaryMutation } from 'containers/Projects/projectSupplementaryApi';
import { HtmlParserComponent } from 'components/HtmlParser/HtmlParser';
import { truncate } from 'lodash';
import TimeAgo from '../../../Collaboration/CollaborationDrawer/ConversationList/ConversationItem/TimeAgo/TimeAgo';

export const SupplementaryUpdateItem = ({ supplementary, sx = {} }) => {
	if (!supplementary) return null;

	return (
		<>
			<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }} sx={{ mt: '1rem', ...sx }} useFlexGap>
				<Stack spacing={0.5} flexDirection='column' useFlexGap>
					<Stack spacing={0.5} flexDirection='row' useFlexGap>
						<Typography sx={{ fontSize: '.8rem' }} color='text.secondary'>
							<FormattedMessage id='project.supplementary.form.created' />
						</Typography>
						<TimeAgo color='text.secondary' sx={{ fontSize: '.8rem' }} time={supplementary?.created} />
					</Stack>
					<Typography sx={{ fontSize: '.8rem', fontStyle: 'italic' }} color='text.secondary'>
						{supplementary?.author}
					</Typography>
				</Stack>
				{supplementary?.updateAuthor && (
					<Stack spacing={0.5} flexDirection='column' useFlexGap>
						<Stack spacing={0.5} flexDirection='row' useFlexGap>
							<Typography sx={{ fontSize: '.8rem' }} color='text.secondary'>
								<FormattedMessage id='project.supplementary.form.updated' />
							</Typography>
							<TimeAgo color='text.secondary' sx={{ fontSize: '.8rem' }} time={supplementary?.updated} />
						</Stack>
						<Typography sx={{ fontSize: '.8rem', fontStyle: 'italic' }} color='text.secondary'>
							{supplementary?.updateAuthor}
						</Typography>
					</Stack>
				)}
			</Stack>
		</>
	);
};

const ProjectSupplementaryListItem = ({ project, supplementary }) => {
	const { formatMessage } = useIntl();
	const formRef = useRef();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [dirtyFields, setDirtyFields] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [isUnsavedConfirmOpen, setIsUnsavedConfirmOpen] = useState(false);

	const companyId = getCompanyId();
	const projectId = project?.projectId;

	const [upsertSupplementary] = useUpsertProjectSupplementaryMutation();
	const [deleteSupplementary, { isLoading: isDeleting }] = useDeleteProjectSupplementaryMutation();

	const handleOpen = () => setOpen(true);

	const handleClose = hideUnsavedConfirm => {
		if (isDirty && !hideUnsavedConfirm) {
			setIsUnsavedConfirmOpen(true);
		} else {
			setOpen(false);
		}
	};

	const confirm = e => {
		setConfirmOpen(true);
		setAnchorEl(e.currentTarget);
	};

	const closeConfirm = () => {
		setAnchorEl(null);
		setConfirmOpen(false);
	};

	const handleDelete = async () => {
		try {
			await deleteSupplementary({ companyId, projectId, supplementaryId: supplementary?.projectSupplementaryInformationId });
			handleClose?.(true);
			toast.success(formatMessage({ id: 'project.supplementary.delete.success' }));
		} catch (err) {
			console.error(err);
			toast.error(formatMessage({ id: 'project.supplementary.delete.fail' }));
		}
	};

	const onSubmit = async data => {
		setLoading(true);
		await handleUpsert(data);
		setLoading(false);
	};

	const handleUpsert = async supplementary => {
		try {
			await upsertSupplementary({ companyId, projectId, supplementary });
			handleClose?.(true);
			toast.success(formatMessage({ id: 'project.supplementary.form.success' }));
		} catch (err) {
			console.error(err);
			toast.error(formatMessage({ id: 'project.supplementary.form.fail' }));
		}
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const closeUnsavedConfirm = () => setIsUnsavedConfirmOpen(false);
	const closeUnsavedConfirmAndModal = () => {
		setIsUnsavedConfirmOpen(false);
		setOpen(false);
	};

	return (
		<>
			<Box
				sx={{
					p: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					'&:hover': {
						cursor: 'pointer',
						backgroundColor: 'rgba(0, 0, 0, .02)',
					},
				}}
				onClick={handleOpen}
				onKeyDown={handleOpen}
				role='button'
				tabIndex='0'
			>
				<Stack spacing={0.5} flexDirection='column' sx={{ width: '100%' }} useFlexGap>
					<Typography noWrap>{supplementary?.title}</Typography>
					<Box>
						<HtmlParserComponent html={truncate(supplementary?.content || '', { length: 350 })} />
					</Box>
					<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }} useFlexGap>
						<Stack spacing={0.5} flexDirection='row' useFlexGap>
							<Typography>
								<FormattedMessage id='project.supplementary.form.requestDate' />
							</Typography>
							<Typography color='text.secondary'>
								{supplementary?.requestDate ? <FormattedDate value={supplementary?.requestDate} /> : '-'}
							</Typography>
						</Stack>
						<Stack spacing={0.5} flexDirection='row' useFlexGap>
							<Typography>
								<FormattedMessage id='project.supplementary.form.responseDate' />
							</Typography>
							<Typography color='text.secondary'>
								{supplementary?.responseDate ? <FormattedDate value={supplementary?.responseDate} /> : '-'}
							</Typography>
						</Stack>
					</Stack>
					<SupplementaryUpdateItem supplementary={supplementary} />
				</Stack>
			</Box>
			<ModalDialog
				onClose={() => handleClose(false)}
				open={open}
				title={<FormattedMessage id='project.supplementary.add.title' />}
				actions={
					<LeftRightContainer
						left={
							<Button disabled={isDeleting} onClick={e => confirm(e)}>
								{formatMessage({ id: 'project.supplementary.delete' })}
							</Button>
						}
						right={
							<Button disabled={loading || !isDirty} color='success' onClick={submitForm}>
								{formatMessage({ id: 'shared.save' })}
								{loading && <ButtonSpinner />}
							</Button>
						}
					/>
				}
			>
				<ProjectSupplementaryForm
					ref={formRef}
					supplementary={supplementary}
					onSubmit={onSubmit}
					passIsDirty={(isDirty, dirtyFields) => {
						setIsDirty(isDirty);
						setDirtyFields(dirtyFields);
					}}
				/>
				<ConfirmUnsaved
					open={isUnsavedConfirmOpen}
					handleCancel={closeUnsavedConfirm}
					handleConfirm={closeUnsavedConfirmAndModal}
					dirtyFields={dirtyFields}
					translation='project.supplementary.form'
				/>
				<ConfirmPopper
					confirmAction={handleDelete}
					handleClose={closeConfirm}
					isopen={confirmOpen}
					anchorElement={anchorEl}
					confirmText={formatMessage({ id: 'project.supplementary.delete.confirmText' })}
					cancelText={formatMessage({ id: 'project.supplementary.delete.cancelText' })}
					confirmBodyText={formatMessage({ id: 'project.supplementary.delete.confirmBodyText' })}
				/>
			</ModalDialog>
		</>
	);
};

export default ProjectSupplementaryListItem;
