import { Box, TableCell } from '@mui/material';
import { useTheme } from '@mui/styles';

const ProjectPrintTableCellTitle = ({
	children = '',
	tableColumnHeader = false,
	rowHeader = false,
	usetablecell = true,
	sx = {},
}: {
	children: any;
	tableColumnHeader?: boolean;
	rowHeader?: boolean;
	usetablecell?: boolean;
	sx?: any;
}) => {
	const theme = useTheme();

	if (tableColumnHeader) {
		sx = Object.assign({}, sx, {
			// @ts-ignore
			borderBottom: `4px solid ${theme.palette.primary.secondary}`,
		});
	}

	sx = Object.assign(
		{},
		{
			border: 'none', // @ts-ignore
			color: theme.palette.primary.main,
			fontSize: '1.1rem',
			fontWeight: 500,
			width: rowHeader ? '25rem' : 'auto',
			'@media print': {
				width: 'auto',
			},
			lineHeight: '1.43',
			verticalAlign: 'inherit',
			textAlign: 'left',
			padding: 2,
		},
		sx
	);

	if (usetablecell)
		return (
			<TableCell className='tdOverviewPrint tdPrint' sx={sx}>
				{children}
			</TableCell>
		);

	return (
		<Box className='tdOverviewPrint tdPrint' sx={sx}>
			{children}
		</Box>
	);
};

export default ProjectPrintTableCellTitle;
