import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

const BackLink = ({ pathname, name }) => {
    const history = useHistory();

    const pathNameSplit = pathname.split('/').filter(i => i);
    // TODO: logic temporarily simplified, check if routes change
    const link = pathNameSplit.length === 4 && !name
        ? 'Takaisin' // fallback, just in case
        : pathNameSplit.length === 4 && name
            ? `< ${name}`
            : pathNameSplit.length === 3
                ? '< Instrumentit'
                : '< Dashboard';

    return (
        <Box sx={{ pt: 2, pl: 2 }}>
            <Link
                underline='hover'
                color='primary'
                onClick={() => history.goBack()}
                sx={{
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }}>
                {link}
            </Link>
        </Box>
    );
};

export default BackLink;
