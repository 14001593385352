import * as React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import environment from 'config/environment';

import Register from 'containers/Register/Register';

const RegisterPage: React.FC = () => {
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={environment.RECAPTCHA_KEY ?? ''}
			language='fi'
			container={{ element: 'google-recaptcha', parameters: { badge: 'inline' } }}
			useEnterprise
		>
			<Register />
		</GoogleReCaptchaProvider>
	);
};

export default RegisterPage;
