import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/system';

import ContactCard from './ContactCard';
import ThankYouCard from './ThankYouCard';

import ContentLoaderContainer from 'components/Containers/ContentLoaderContainer';
import { contactWithLink } from 'containers/Analysis/slices';

export default function ContactItems({ contactData, isContacting, link }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const intl = useIntl();

    if (!contactData || !contactData.contact) return null;

    const action = (type) => {
        const success = dispatch(contactWithLink(link, type));

        if (success) {
            toast.success(intl.formatMessage({ id: 'analysis.contact.success' }));

            return;
        }

        toast.error(intl.formatMessage({ id: 'analysis.contact.fail' }));
    };

    if (contactData && contactData.contactRequested) {
        return (
            <ThankYouCard title={intl.formatMessage({ id: 'analysis.contact.thanks' })} description={intl.formatMessage({ id: 'analysis.contact.thanks.description' })} />
        );
    }

    return (
        <ContentLoaderContainer loading={isContacting}>
            <Grid container alignItems="stretch" direction="row" justifyContent="space-between">
                <Grid item
                    xs={12} smd={6} lg={4}
                    sx={{
                        [theme.breakpoints.down('smd')]: {
                            pb: '1rem'
                        },
                        [theme.breakpoints.up('smd') && theme.breakpoints.down('lg')]: {
                            pr: '0.3rem'
                        },
                        [theme.breakpoints.up('lg')]: {
                            p: '0 .3rem 0 0'
                        },
                    }}
                >
                    <ContactCard
                        number={1}
                        title='Katso toimenpiteet, joilla parannat yrityksesi rahoitettavuutta 189€ + alv'
                        action={() => action('analysis')}
                        actionText={intl.formatMessage({ id: 'analysis.contact.analysis.cta' })}
                        icon={'article_outlined'}
                    />
                </Grid>
                <Grid item
                    xs={12} smd={6} lg={4}
                    sx={{
                        [theme.breakpoints.down('smd')]: {
                            pb: '1rem'
                        },
                        [theme.breakpoints.up('smd') && theme.breakpoints.down('lg')]: {
                            pl: '0.3rem'
                        },
                        [theme.breakpoints.up('lg')]: {
                            p: '0 .3rem 0 .3rem'
                        },
                    }}
                >
                    <ContactCard
                        number={2}
                        title='Katso millaiset rahoitusinstrumentit soveltuvat yrityksellesi - Kokeile Granted AI PRO:ta 7 pv maksutta'
                        action={() => action('trial')}
                        actionText={intl.formatMessage({ id: 'analysis.contact.trial.cta' })}
                        icon={'business_center'}
                    />
                </Grid>
                <Grid item
                    xs={12} smd={6} lg={4}
                    sx={{
                        [theme.breakpoints.up('smd') && theme.breakpoints.down('lg')]: {
                            mt: '.6rem',
                            pr: '.3rem'
                        },
                        [theme.breakpoints.up('lg')]: {
                            p: '0 0 0 .3rem'
                        },
                    }}
                >
                    <ContactCard
                        number={2}
                        title='Pyydä asiantuntijan yhteydenottoa. Asiantuntijamme on sinuun yhteydessä mahdollisimman nopeasti.'
                        action={() => action('expert')}
                        actionText={intl.formatMessage({ id: 'analysis.contact.contact.cta' })}
                        icon={'forum'}
                    />
                </Grid>
            </Grid>
        </ContentLoaderContainer>
    );
}