import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

const Tags = {
	ContractTag: 'contract',
	UpgradeFreemium: 'upgradeFreemium',
};

const baseUrl = 'contract';

export type ContractTypes = 'TermsContract';

type ApproveContractProps = {
	companyId: string | null;
	contract: {};
};

type GetContractProps = {
	companyId: string | null;
	type: ContractTypes;
};

type GetContractByTypeProps = {
	type: ContractTypes;
};

type GetContractByTypeVersionProps = {
	type: ContractTypes;
	version: string | null;
};

export const contractApi = createApi({
	reducerPath: 'contractApi',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }, true),
	tagTypes: [Tags.ContractTag],
	endpoints: builder => ({
		approveContract: builder.mutation<any, ApproveContractProps>({
			query: ({ companyId, contract }) => {
				if (isNil(companyId) || isNil(contract)) {
					throw new Error('parameter error');
				}

				return {
					method: 'POST',
					url: `/${companyId}`,
					data: contract,
				};
			},
			invalidatesTags: (data, error, params) => [{ type: Tags.ContractTag, id: params?.companyId ?? '' }],
		}),
		getCompanyContract: builder.query<any, GetContractProps>({
			query: ({ companyId, type }) => {
				if (isNil(companyId) || isNil(type)) {
					throw new Error('parameter error');
				}

				return {
					method: 'GET',
					url: `/${type}/${companyId}`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.ContractTag, id: params?.companyId ?? '' }];
			},
		}),
		getLatestContract: builder.query<any, GetContractByTypeProps>({
			query: ({ type }) => {
				if (isNil(type)) {
					throw new Error('parameter error');
				}

				return {
					method: 'GET',
					url: `/data/${type}`,
				};
			},
			providesTags: [Tags.ContractTag],
		}),
		getContractWithVersion: builder.query<any, GetContractByTypeVersionProps>({
			query: ({ type, version }) => {
				if (isNil(type)) {
					throw new Error('parameter error');
				}

				return {
					method: 'GET',
					url: `/data/${type}/${version}`,
				};
			},
			providesTags: [Tags.ContractTag],
		}),
		upgradeFreemium: builder.mutation<any, { companyId: string }>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}

				return {
					method: 'POST',
					url: `/upgrade/${companyId}`,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.UpgradeFreemium, id: params?.companyId ?? '' }];
			},
		}),
		fetchUpgradeRequest: builder.query<any, { companyId: string }>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}

				return {
					method: 'GET',
					url: `/upgrade/${companyId}`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.UpgradeFreemium, id: params?.companyId ?? '' }];
			},
		}),
	}),
});

export const {
	useApproveContractMutation,
	useGetCompanyContractQuery,
	useUpgradeFreemiumMutation,
	useFetchUpgradeRequestQuery,
	useGetLatestContractQuery,
	useGetContractWithVersionQuery,
} = contractApi;
