import React from 'react';
import moment from 'moment';

import { Stack, Typography } from '@mui/material';
import CurrencyValue from 'components/Currency/CurrencyValue';

const InfoBox = ({ title, value, description, dataType, sx = {} }) => {
	let parsedValue = value;

	if (dataType && value) {
		switch (dataType) {
			case 'date':
				parsedValue = moment(value).format('D.M.yyyy');
				break;
			case 'currency':
				parsedValue = <CurrencyValue value={value} />;
				break;
			default:
				break;
		}
	}

	return (
		<Stack
			direction='column'
			spacing={1}
			sx={{
				background: '#fff',
				p: '1.5rem',
				'@media print': {
					p: 0,
				},
				...sx,
			}}
		>
			<Typography
				component='span'
				sx={{
					textTransform: 'uppercase',
					color: '#73798C',
					fontWeight: 600,
					mt: '0 !important',
				}}
			>
				{title}
			</Typography>
			<Typography
				component='span'
				sx={{
					fontWeight: 600,
					fontSize: '1.6rem',
					color: '#503960',
					mt: '0 !important',
				}}
			>
				{parsedValue}
			</Typography>
			<Typography
				component='span'
				sx={{
					color: '#73798C',
					mt: '0 !important',
				}}
			>
				{description}
			</Typography>
		</Stack>
	);
};

export default InfoBox;
