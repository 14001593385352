import { Stack } from '@mui/material';

import ContentContainer from 'components/Containers/ContentContainer';
import MainTitle from 'components/Titles/MainTitle';
import { useIntl } from 'react-intl';
import CSVDownloadButton, { CSVLicenseDownloadButton } from './CSVDownload/CSVDownloadButton';
import MRRCompanyChart from './MRRCompanyChart';
import MRRLicenseTable from './MRRLicenseTable';
import MRRStatistics from './MRRStatistics/MRRStatistics';
import { useGetMrrDataQuery } from './mrrApi';

const MRRData = () => {
	const intl = useIntl();

	const { data, isFetching, isLoading, isSuccess } = useGetMrrDataQuery('mrr');

	if (!(isFetching || isLoading) && !isSuccess) return <div>Error!</div>;

	return (
		// @ts-ignore
		<ContentContainer>
			<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mb: '1rem' }}>
				<MainTitle margin={false}>{intl.formatMessage({ id: 'mrr.title' })}</MainTitle>
				<CSVLicenseDownloadButton data={data} />
			</Stack>
			<MRRStatistics mrrData={data} />
			<MRRCompanyChart loading={isFetching || isLoading} data={data} />
			<MRRCompanyChart loading={isFetching || isLoading} data={data} showTotal={false} />

			<Stack sx={{ mb: '1rem' }} direction='row' alignItems='center' justifyContent='flex-end'>
				<CSVDownloadButton data={data} />
			</Stack>
			<MRRLicenseTable data={data} loading={isFetching || isLoading} />
		</ContentContainer>
	);
};

export default MRRData;
