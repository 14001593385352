import * as React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import environment from 'config/environment';
import Login from 'containers/Login/Login';
import { useLocation } from 'react-router';

const LoginPage: React.FC = () => {
	const prevLocation = useLocation();

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={environment.RECAPTCHA_KEY ?? ''}
			language='fi'
			container={{ element: 'google-recaptcha', parameters: { badge: 'inline' } }}
			useEnterprise
		>
			{/* @ts-ignore */}
			<Login redirect={prevLocation} />
		</GoogleReCaptchaProvider>
	);
};

export default LoginPage;
