import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const InstrumentHeader = ({ name, provider, projectTags, projectValueArr, profile = false, closeInstrumentDrawer }) => {
    return (
        <Box
            display={profile ? 'flex' : 'block'}
        >
            <Box
                sx={{
                    flexGrow: profile && 1,
                    p: 3,
                    pr: profile ? 0 : 3,
                    bgcolor: 'success.main',
                    alignItems: 'center',
                }}
            >
                <Stack direction='row' alignItems='flex-end' spacing={2} sx={{ flexWrap: 'wrap' }}>
                    <Typography
                        variant='h2'
                        sx={{
                            fontSize: '1.6rem',
                            fontWeight: 600,
                            color: 'primary.white'
                        }}
                    >
                        {name}
                    </Typography>
                    <Typography sx={{ color: 'primary.white' }}>{provider}</Typography>
                </Stack>
                {projectTags?.length > 0 && (
                    <Stack direction='row' sx={{ flexWrap: 'wrap', mt: 0.5 }}>
                        {projectTags.map((item, index) =>
                            <Typography
                                key={item + index} sx={{
                                    color: 'primary.white',
                                    borderRadius: 4,
                                    border: '1px solid #fff',
                                    padding: '0 0.75rem',
                                    margin: '0.5rem 1rem 0.5rem 0',
                                    '&:last-child': {
                                        margin: '0.5rem 0',
                                    }
                                }}
                            >
                                {item}
                            </Typography>
                        )}
                    </Stack>
                )}
                {!!projectValueArr?.length && (
                    <Stack direction='row' spacing={1} sx={{ mt: 0.5 }}>
                        {projectValueArr?.map((item, index) =>
                            <Typography key={item + index} sx={{ color: 'primary.white' }}>
                                {item}
                            </Typography>
                        )}
                    </Stack>
                )}
            </Box>
            {profile &&
                <Box sx={{ bgcolor: 'success.main' }}>
                    <IconButton aria-label='close' onClick={closeInstrumentDrawer} sx={{ m: 1.5 }}>
                        <CloseIcon sx={{ color: 'primary.white' }} />
                    </IconButton>
                </Box>
            }
        </Box>
    );
};

export default InstrumentHeader;