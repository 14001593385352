import { createApi } from '@reduxjs/toolkit/query/react';

import { buildUri } from 'utils/helpers/uriHelper';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

const baseUrl = 'message';

export const pollingInterval = 120000;

export const messagesApi = createApi({
	reducerPath: 'messages',
	baseQuery: axiosBaseQuery(),
	endpoints: builder => ({
		pollMessages: builder.query({
			query: () => {
				return {
					method: 'GET',
					url: buildUri(baseUrl, ''), // url: ''
				};
			},
			providesTags: ['Messages'],
		}),
		markAsRead: builder.mutation({
			query: messageId => {
				return {
					method: 'POST',
					url: buildUri(baseUrl, `${messageId}`),
					body: messageId,
				};
			},
			async onQueryStarted(uniqueIdentifier, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					messagesApi.util.updateQueryData('pollMessages', 'messages', draft => {
						let itemIndex = draft?.findIndex(item => item.rowKey === uniqueIdentifier);

						if (itemIndex >= 0) draft[itemIndex].isRead = true;
					})
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
	}),
});

export const { usePollMessagesQuery, useMarkAsReadMutation } = messagesApi;
