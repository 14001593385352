import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import ProjectModalFormBase from 'containers/Projects/Project/ProjectForm/ProjectModalFormBase';
import ProfileStepper from './ProfileStepper';

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: '#e6e2dc',
		width: '100%',
		//borderRadius: '0.5rem',
		'&>div:first-child': {
			backgroundColor: '#eeece9',
		},
	},
	projectPhase: {
		padding: '2rem',
	},
}));

const ProfilePhase = ({ loading, completeProfile, isProjects, instruments, openProfileModal }) => {
	const classes = useStyles();
	const intl = useIntl();

	const steps = [
		intl.formatMessage({ id: 'profile.phase.init' }),
		intl.formatMessage({ id: 'profile.phase.timeline' }),
		// intl.formatMessage({ id: 'profile.phase.financial.plan' }),
	];

	// const getProfilePhase = (completeProfile, isProjects) => !completeProfile ? 0 : !isProjects ? 1 : 2;
	// const phase = getProfilePhase(completeProfile, isProjects);

	const [dirtyFields, setDirtyFields] = useState({});

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = dirtyFields => {
		if (dirtyFields && Object.keys(dirtyFields)?.length > 0) {
			setDirtyFields(dirtyFields);
		} else {
			setOpen(false);
		}
	};

	const closeConfirm = () => setDirtyFields(false);
	const closeConfirmAndModal = () => {
		setDirtyFields(false);
		setOpen(false);
	};

	return (
		<>
			<Box className={classes.root}>
				<Box className={classes.projectPhase}>
					<ProfileStepper
						loading={loading}
						activeStep={2}
						steps={steps}
						completeProfile={completeProfile}
						isProjects={isProjects}
						openProfileModal={openProfileModal}
						openProjectModal={handleOpen}
					/>
				</Box>
			</Box>
			<ProjectModalFormBase instruments={instruments} open={open} handleClose={handleClose} />
			<ConfirmUnsaved
				open={Object.keys(dirtyFields)?.length > 0}
				handleCancel={closeConfirm}
				handleConfirm={closeConfirmAndModal}
				dirtyFields={dirtyFields}
				translation='project.form'
			/>
		</>
	);
};

export default ProfilePhase;
