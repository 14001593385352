import { useInjectReducer } from 'utils/injectors/injectReducer';

import scenarioReducer from 'containers/Scenario/reducer';

import { name as migrationKey, reducer as migrationReducer } from 'containers/Admin/CompanyMigration/slices';
import { name as companyLicenseKey, reducer as companyLicenseReducer } from 'containers/Admin/UsersCompany/licenseSlice';
import { name as adminKey, reducer as adminReducer } from 'containers/Admin/UsersCompany/slices';
import { name as companyUserKey, reducer as companyUserReducer } from 'containers/Admin/UsersCompany/userSlice';
import { name as advancedReducerName, reducer as advancedReducer } from 'containers/Advanced/slices';
import { name as analysisReducerName, reducer as analysisReducer } from 'containers/Analysis/slices';
import { name as businessPlanKey, reducer as businessPlanReducer } from 'containers/BusinessPlan/slices';
import { name as collaborationKey, reducer as collaborationReducer } from 'containers/Collaboration/slices';
import { name as companySearchName, reducer as companySearchReducer } from 'containers/CompanySearch/slices';
import { name as financialPlanReducerName, reducer as financialPlanReducer } from 'containers/FinancialPlan/slices';
import { name as instrumentReducerName, reducer as instrumentReducer } from 'containers/Instruments/slices';
import { name as profileKey, reducer as profileReducer } from 'containers/Profile/slices';
import { name as projectReducerName, reducer as projectsReducer } from 'containers/Projects/slices';
import { name as scoreReducerName, reducer as scoreReducer } from 'containers/Score/slices';
import { name as chatReducerName, reducer as chatReducer } from 'containers/Chat/chatSlice';

const scenarioKey = 'scenario';

const useInjectors = () => {
	useInjectReducer({ key: financialPlanReducerName, reducer: financialPlanReducer });
	useInjectReducer({ key: advancedReducerName, reducer: advancedReducer });
	useInjectReducer({ key: scenarioKey, reducer: scenarioReducer });
	useInjectReducer({ key: scoreReducerName, reducer: scoreReducer });
	useInjectReducer({ key: instrumentReducerName, reducer: instrumentReducer });
	useInjectReducer({ key: analysisReducerName, reducer: analysisReducer });
	useInjectReducer({ key: projectReducerName, reducer: projectsReducer });
	useInjectReducer({ key: businessPlanKey, reducer: businessPlanReducer });
	useInjectReducer({ key: companySearchName, reducer: companySearchReducer });
	useInjectReducer({ key: profileKey, reducer: profileReducer });
	useInjectReducer({ key: adminKey, reducer: adminReducer });
	useInjectReducer({ key: companyLicenseKey, reducer: companyLicenseReducer });
	useInjectReducer({ key: companyUserKey, reducer: companyUserReducer });
	useInjectReducer({ key: migrationKey, reducer: migrationReducer });
	useInjectReducer({ key: collaborationKey, reducer: collaborationReducer });
	useInjectReducer({ key: chatReducerName, reducer: chatReducer });

	return null;
};

export default useInjectors;
