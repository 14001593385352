import { useIntl } from 'react-intl';

import FormControlledCheckbox, { SelectOption } from 'components/Form/FormControlledCheckbox';
import { GrantedAIFeature } from 'types/dataTypes';

type Props = {
	errors: string;
	control: any;
	licenseData: any;
};

export type FeatureListSelectOption = {
	value: GrantedAIFeature;
} & SelectOption;

const FeatureList: React.FC<Props> = ({ control, errors, licenseData }) => {
	const intl = useIntl();

	const featuresConst: Array<FeatureListSelectOption> = [
		// {
		// 	value: 'GrantsConsultingLicense',
		// 	label: intl.formatMessage({ id: 'admin.license.features.option.GrantsConsultingLicense' }),
		// },
		{
			value: 'GrantsMarketplaceProvider',
			label: intl.formatMessage({ id: 'admin.license.features.option.GrantsMarketplaceProvider' }),
		},
		{
			value: 'GrantsFinancialService',
			label: intl.formatMessage({ id: 'admin.license.features.option.GrantsFinancialService' }),
		},
		// {
		// 	value: 'GratnsMarketplaceConsumer',
		// 	label: intl.formatMessage({ id: 'admin.license.features.option.GrantsMarketplaceConsumer' }),
		// },
	];

	return (
		<>
			<FormControlledCheckbox
				title={intl.formatMessage({ id: 'admin.license.features.title' })}
				name={'features'}
				defaultValue={licenseData?.features}
				control={control}
				options={featuresConst}
				disabled={false}
				error={errors}
			/>
		</>
	);
};

export default FeatureList;
