import * as React from 'react';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	FormControlProps,
	FormControl,
	FormHelperText,
	FormLabel,
	Typography,
} from '@mui/material';
import { Delete as DeleteIcon, FileCopy as FileCopyIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { compact, concat, isEmpty, isNil, map, without } from 'lodash';

import { RfpAttachment } from 'types/dataTypes';
import bytesToSize from 'utils/converters/bytesToSize';

import AttachmentDropZone from 'containers/Marketplace/attachment/AttachmentDropZone';

type Props = {
	label?: string | null;
	helperText?: string | null;
	value?: Partial<RfpAttachment>[] | null;
	onChange?: (value: Partial<RfpAttachment>[]) => void;
} & Omit<FormControlProps, 'onChange'>;

const RfpAttachmentInput = React.forwardRef<any, Props>(({ label, helperText, value, onChange, ...otherProps }, ref) => {
	return (
		<FormControl {...otherProps} ref={ref}>
			{!isNil(label) && <FormLabel sx={theme => ({ ...theme.typography.h4 })}>{label}</FormLabel>}
			<List>
				{isEmpty(value) && (
					<Typography variant='lightText'>
						<FormattedMessage id='rfpAttachments.empty' />
					</Typography>
				)}
				{map(value, attachment => (
					<ListItem
						key={attachment.name}
						secondaryAction={
							<IconButton
								color='error'
								onClick={() => {
									onChange?.(without(value, attachment));
								}}
							>
								<DeleteIcon />
							</IconButton>
						}
					>
						<ListItemIcon>
							<FileCopyIcon />
						</ListItemIcon>
						<ListItemText
							primary={attachment.name}
							primaryTypographyProps={{ sx: { wordWrap: 'break-word' } }}
							secondary={bytesToSize(attachment.length)}
						/>
					</ListItem>
				))}
			</List>
			<AttachmentDropZone
				onDrop={attachments => {
					onChange?.(compact(concat([], value, attachments)));
				}}
			/>
			{!isNil(helperText) && <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>}
		</FormControl>
	);
});

export default RfpAttachmentInput;
