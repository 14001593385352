import { BorderedFormBox } from 'components/Form';
import TolSelector from 'components/TolSelector';

const SearchBoxItem = ({ name, label, defaultValue, control, error, instructions, isCreatedByBot, disabled }) => {
	return (
		<BorderedFormBox name={name} label={label} error={error} popoverContent={instructions} isCreatedByBot={isCreatedByBot}>
			<TolSelector
				name={name}
				multiple={false}
				defaultValue={defaultValue}
				control={control}
				disablePadding={true}
				disabled={disabled}
			/>
		</BorderedFormBox>
	);
};

export default SearchBoxItem;
