import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/styles';

import StyledChip from 'components/Tags/StyledChip';
import CollaborationLauncher from 'containers/Collaboration/CollaborationLauncher';

import { matchProjectsProject } from 'utils/helpers/pathMatch';

const CardContainer = styled(Box)(() => ({
    outline: 'none',
}));

const StyledCard = styled(Card)(({ theme, raised }) => ({
    borderRadius: '0',
    cursor: 'pointer',
    ...(raised && {
        backgroundColor: 'background.paper',
    }),
    '&:hover': {
        backgroundColor: theme.palette.secondary.grey200,
    },
    '& >:last-child': {
        paddingBottom: theme.spacing(2)
    },
}));

const KanbanCard = ({ dragging = false, children, tagContent, cardMain, link, onClick, title }) => {
    const { pathname } = useLocation();
    const isProjectsProject = matchProjectsProject(pathname);

    return (
        <CardContainer>
            <StyledCard
                raised={dragging}
                variant={dragging ? 'elevation' : 'outlined'}
                onClick={onClick}
            >
                <CardContent>
                    {tagContent &&
                        <Box display='flex' sx={{ mb: '.5rem', justifyContent: 'space-between', alignItems: 'center' }}>
                            <StyledChip label={tagContent} />
                            {link && isProjectsProject && <CollaborationLauncher linkTitle={title} link={link} spacing={0} size='small' sx={{ mr: -1 }} />}
                        </Box>
                    }
                    <Box display='flex' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {cardMain}
                        </Typography>
                        {!tagContent && link && isProjectsProject && <CollaborationLauncher linkTitle={title} link={link} spacing={0} size='small' sx={{ mr: -1 }} />}
                    </Box>
                    {children}
                </CardContent>
            </StyledCard>
        </CardContainer>
    );
};

export default KanbanCard;
