import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Timeline from 'containers/FinancialPlan';
import { isAdvancedOrCustomerToken } from 'utils/auth/company';
import { isAdminToken, isContributorToken, isStaticProfile } from 'utils/auth/token';

export default function TimelineContainer({ scrollToNoInsruments, companyId, instruments, pathname = '', printView = false }) {
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));

	const isAdmin = isAdminToken();
	const isContributor = isContributorToken();
	const isAdvancedCompanyOrCustomer = isAdvancedOrCustomerToken();
	const isStaticProfileConst = isStaticProfile();

	// Financial Plan
	const financialPlan = useSelector(state => state.financialPlan);

	// Kanban
	const kanban = useSelector(state => state.kanban);
	const timeline = kanban?.timeline?.data;
	const projects = kanban?.projects;
	const mock =
		!isAdmin && !isContributor && !isAdvancedCompanyOrCustomer ? financialPlan?.financialPlan?.mock && projects?.mock : projects?.mock;

	const projectsLoading = !projects?.isLoaded;
	const timelineRef = useRef();
	const padding = 8; // padding = 8px --> Timeline.js, container style

	const timelineContainerRef = useRef();
	const [timelineContainerWidth, setTimelineContainerWidth] = useState(null);

	useLayoutEffect(() => {
		const updateWidth = () => {
			setTimelineContainerWidth(timelineContainerRef.current?.clientWidth);
		};

		window.addEventListener('resize', updateWidth);
		updateWidth();

		return () => window.removeEventListener('resize', updateWidth);
	}, []);

	const [xScrollWidth, setXScrollWidth] = useState(timelineContainerWidth);
	const [xScrollLeft, setXScrollLeft] = useState(0);

	useEffect(() => {
		// should be initially at least 1px bigger than (timelineContainerWidth + padding)
		setXScrollWidth(timelineContainerWidth + padding + 1);
	}, [timelineContainerWidth]);

	const onScroll = () => {
		const xScrollWidth = timelineRef.current?.scrollWidth;
		const xScrollLeft = timelineRef.current?.scrollLeft;
		setXScrollWidth(xScrollWidth);
		setXScrollLeft(xScrollLeft);
	};

	const [isHorizontalScrollbar, setIsHorizontalScrollbar] = useState(false);
	const hasHorizontalScrollbar = tableWidth => setIsHorizontalScrollbar(tableWidth + padding + 1 >= timelineContainerWidth);
	const isRenderLeftSmooth = isHorizontalScrollbar && xScrollLeft > padding;
	const isRenderRightSmooth = isHorizontalScrollbar && xScrollWidth + 32 > xScrollLeft + timelineContainerWidth;

	return (
		<div
			ref={timelineContainerRef}
			style={{
				height:
					projectsLoading && smDown
						? '30rem'
						: projectsLoading && !smDown
							? '35rem'
							: projects.data && projects.data.length === 0 && smDown
								? '30rem'
								: '100%',
				maxHeight: !smDown && !printView && '35rem',
			}}
		>
			<div
				style={{
					height: '100%',
					maxWidth: timelineContainerWidth,
					minWidth: '100%',
				}}
			>
				<Timeline
					ref={timelineRef}
					loading={projectsLoading}
					timeline={timeline}
					projects={projects?.data}
					mock={mock}
					instruments={instruments}
					padding={padding}
					onScroll={onScroll}
					hasHorizontalScrollbar={hasHorizontalScrollbar}
					isRenderRightSmooth={isRenderRightSmooth}
					isRenderLeftSmooth={isRenderLeftSmooth}
					scrollToNoInsruments={scrollToNoInsruments}
					hideContactCalendar={isAdvancedCompanyOrCustomer}
					isStaticProfileConst={isStaticProfileConst}
					companyId={companyId}
					pathname={pathname}
					timelineContainerWidth={timelineContainerWidth}
					printView={printView}
				/>
			</div>
		</div>
	);
}
