import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import FormatCurrency from 'components/Formatters/FormatCurrency';
import { calculateExpenseAmount, calculatedExpensePercentage } from 'containers/Projects/Project/Costs/ProjectCostForm/ProjectCostForm';
import { groupBy, map, orderBy, sumBy } from 'lodash';
import { ProjectCost, ProjectTask } from 'types/dataTypes';
import { currencyFormatter, decimalFormatter } from 'utils/formatters/numberFormatters';
import ProjectPrintTableCell from '../ProjectPrintTableItems/ProjectPrintTableCell';
import ProjectPrintTableCellTitle from '../ProjectPrintTableItems/ProjectPrintTableCellTitle';

const GroupedCosts = ({ tasks, costs }: { tasks: Array<ProjectTask>; costs: Array<ProjectCost> }) => {
	const { formatMessage } = useIntl();
	const groupedEmployees = groupBy(costs, 'employeeName');

	const groupedEntries = Object.entries(groupedEmployees);

	let workAmountSum = sumBy(costs, (costItem: ProjectCost) => {
		return costItem.workEstimate ?? 0;
	});
	let totalSum = sumBy(costs, (costItem: ProjectCost) => {
		const workEstimate = costItem.workEstimate ?? 0;

		return (costItem.amountApplied ?? 0) * workEstimate;
	});

	return (
		<>
			{groupedEntries.map(([key, value]) => {
				const total = sumBy(value, item => item.amountApplied * (item.workEstimate ?? 0));
				const workEstimate = sumBy(value, item => item.workEstimate ?? 0);
				const task = map(value, item => item.name)
					?.filter(n => n)
					?.filter((origname, index, arr) => arr.findIndex(name => name === origname) === index)
					.join(', ');
				const role = value[0].employeeRole;

				return (
					<TableRow key={key}>
						<ProjectPrintTableCell>
							{key}
							{role ? ` - ${role}` : ''}
						</ProjectPrintTableCell>
						<ProjectPrintTableCell>{task}</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
							{decimalFormatter.format(workEstimate ?? 0)}
						</ProjectPrintTableCell>
						<ProjectPrintTableCell sx={{ textAlign: 'right' }}>{currencyFormatter.format(total ?? 0)}</ProjectPrintTableCell>
					</TableRow>
				);
			})}
			<TableRow>
				<ProjectPrintTableCell>
					<Typography component='span' sx={{ fontWeight: 600, fontSize: '1rem' }}>
						{formatMessage({ id: 'project.cost.total' })}
					</Typography>
				</ProjectPrintTableCell>
				<ProjectPrintTableCell>{''}</ProjectPrintTableCell>
				<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
					<Typography component='span' sx={{ fontWeight: 600, fontSize: '1rem' }}>
						{workAmountSum > 0
							? `${decimalFormatter.format(workAmountSum ?? 0)} ${formatMessage({ id: 'project.task.table.person.hours' })}`
							: '-'}
					</Typography>
				</ProjectPrintTableCell>
				<ProjectPrintTableCell sx={{ width: '200px', textAlign: 'right' }} lastColumn={true}>
					<Typography component='span' sx={{ fontWeight: 600, fontSize: '1rem' }}>
						{totalSum > 0 ? <FormatCurrency value={totalSum} /> : '-'}
					</Typography>
				</ProjectPrintTableCell>
			</TableRow>
		</>
	);
};

const MappedCosts = ({ tasks, costs }: { tasks: Array<ProjectTask>; costs: Array<ProjectCost> }) => {
	const intl = useIntl();
	let sum = 0;
	let totalSum = 0;

	return (
		<>
			{costs?.map(
				({ id, employeeName, employeeRole, name, workEstimate, category, amountApplied, salaryExpense, salaryExpenseType }) => {
					const calculatedExpenses = calculateExpenseAmount(salaryExpenseType, salaryExpense, amountApplied);
					const expensePercentage = calculatedExpensePercentage(salaryExpenseType, salaryExpense, amountApplied);
					sum += workEstimate ?? 0;
					totalSum += (amountApplied + calculatedExpenses) * (workEstimate ?? 0);

					return (
						<TableRow key={id}>
							<ProjectPrintTableCell>
								{employeeName}
								{employeeRole && ` - ${employeeRole}`}
							</ProjectPrintTableCell>
							<ProjectPrintTableCell>
								{name}
								<br />
								{intl.formatMessage({ id: 'project.cost.form.salary.expense' })} ({expensePercentage} %)
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								{workEstimate
									? `${decimalFormatter.format(workEstimate ?? 0)} ${intl.formatMessage({
											id: 'project.task.table.person.hours',
									  })}`
									: '-'}
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								{amountApplied ? <FormatCurrency value={amountApplied} /> : '-'}
								<br />
								{calculatedExpenses ? <FormatCurrency value={calculatedExpenses} /> : '-'}
							</ProjectPrintTableCell>
						</TableRow>
					);
				}
			)}
			<TableRow>
				<ProjectPrintTableCell>
					<Typography component='span' sx={{ fontWeight: 600, fontSize: '1rem' }}>
						{intl.formatMessage({ id: 'project.cost.total' })}
					</Typography>
				</ProjectPrintTableCell>
				<ProjectPrintTableCell>{''}</ProjectPrintTableCell>
				<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
					<Typography component='span' sx={{ fontWeight: 600, fontSize: '1rem' }}>
						{sum > 0 ? `${sum} ${intl.formatMessage({ id: 'project.task.table.person.hours' })}` : '-'}
					</Typography>
				</ProjectPrintTableCell>
				<ProjectPrintTableCell sx={{ width: '200px', textAlign: 'right' }} lastColumn={true}>
					<Typography component='span' sx={{ fontWeight: 600, fontSize: '1rem' }}>
						{totalSum > 0 ? <FormatCurrency value={totalSum} /> : '-'}
					</Typography>
				</ProjectPrintTableCell>
			</TableRow>
		</>
	);
};

const WorkTable = ({ tasks, costs, showGrouped }: { tasks: Array<ProjectTask>; costs: Array<ProjectCost>; showGrouped: boolean }) => {
	const intl = useIntl();

	costs =
		orderBy(
			costs?.filter(item => item.category === 'salary'),
			['employeeName', 'workEstimate'],
			['asc', 'desc']
		) ?? [];

	return (
		<TableContainer
			sx={{
				background: '#fff',
				'@media print': {
					overflowX: 'visible',
					pageBreakInside: 'avoid',
				},
			}}
		>
			<Table sx={{ width: '100%' }}>
				<TableBody>
					<TableRow>
						<ProjectPrintTableCellTitle tableColumnHeader={true} rowHeader={true}>
							{intl.formatMessage({ id: 'project.resourse.work.table' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCellTitle tableColumnHeader={true}>
							{intl.formatMessage({ id: 'project.resourse.work.table.task' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCellTitle tableColumnHeader={true} sx={{ textAlign: 'right' }}>
							{intl.formatMessage({ id: 'project.resourse.work.table.workload' })}
						</ProjectPrintTableCellTitle>
						<ProjectPrintTableCellTitle tableColumnHeader={true} sx={{ textAlign: 'right' }}>
							{intl.formatMessage({ id: 'project.resourse.work.table.salary' })}
						</ProjectPrintTableCellTitle>
						{/* <ProjectPrintTableCellTitle sx={{ width: '200px', textAlign: 'right' }} tableColumnHeader={true}>
							{intl.formatMessage({ id: 'project.resourse.purchasing.services.table.cost.total' })}
						</ProjectPrintTableCellTitle> */}
					</TableRow>
					{showGrouped && <GroupedCosts {...{ costs, tasks }} />}
					{!showGrouped && <MappedCosts {...{ costs, tasks }} />}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default WorkTable;
