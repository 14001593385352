import React from 'react';
import { Controller } from 'react-hook-form';

/**
 * Hidden form field
 *
 * @param {any} defaultValue
 * @param {string} name
 * @param {any} control
 */
const FormControlledHiddenField = ({ defaultValue, name, control }) => {
	return (
		<Controller
			name={name}
			defaultValue={defaultValue}
			control={control}
			render={({ field: { value } }) => <input type='hidden' value={value ?? ''} />}
		/>
	);
};

export default FormControlledHiddenField;
