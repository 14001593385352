import * as React from 'react';
import { Chip, ChipProps, useTheme } from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { isArray } from 'lodash';

import { RfpStatus } from 'types/dataTypes';

type Props = ChipProps & {
	status?: RfpStatus | null;
};

const RfpStatusChip: React.FC<Props> = ({ status, sx, ...otherProps }) => {
	const theme = useTheme();

	const color = React.useMemo(() => {
		if (status === 'Draft') {
			return theme.palette.status.draft;
		}
		if (status === 'Published') {
			return theme.palette.status.published;
		}
		if (status === 'Archived') {
			return theme.palette.status.archived;
		}
	}, [theme, status]);

	return (
		<Chip
			{...otherProps}
			variant='filled'
			icon={<CircleIcon />}
			sx={[{ '& .MuiChip-icon': { color } }, ...(isArray(sx) ? sx : [sx])]}
			label={<FormattedMessage id={`rfpStatus.${status}`} />}
		/>
	);
};

export default RfpStatusChip;
