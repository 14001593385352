import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

import PageSpinner from 'components/Spinner/PageSpinner';
import InstrumentList from './InstrumentList';
import { removeInstrumentMarked, setInstrumentMarked } from './slices';

const Instruments = ({
	loading,
	currentInstrumentId,
	instruments,
	company,
	pathname = '',
	scrollToTimeline,
	openProfileModal,
	costs = [],
	readonly,
	profile,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const [phrase, setPhrase] = useState('');
	const filterInstruments = event => setPhrase(event.target.value);

	const filteredInstruments = instruments?.filter(
		(
			{ name, provider, ingress, description } // implement filter in the corresponding field
		) =>
			name.toLowerCase().includes(phrase.toLowerCase()) ||
			provider.toLowerCase().includes(phrase.toLowerCase()) ||
			ingress.toLowerCase().includes(phrase.toLowerCase()) ||
			description.toLowerCase().includes(phrase.toLowerCase())
	);

	const changeMarked = (id, isMarked = false) => (isMarked ? dispatch(removeInstrumentMarked(id)) : dispatch(setInstrumentMarked(id)));

	if (company === undefined || company === null) return null;

	if (instruments === undefined || instruments === null) {
		return <Typography align='center'>{intl.formatMessage({ id: 'no.instruments' })}</Typography>;
	}


	return loading ? (
		<PageSpinner />
	) : (
		<InstrumentList
			hasInstruments={instruments?.length > 0}
			allInstruments={instruments}
			filteredInstruments={filteredInstruments}
			currentInstrumentId={currentInstrumentId}
			company={company}
			pathname={pathname}
			scrollToTimeline={scrollToTimeline}
			openProfileModal={openProfileModal}
			phrase={phrase}
			filterInstruments={filterInstruments}
			changeMarked={changeMarked}
			costs={costs}
			readonly={readonly}
			profile={profile}
		/>
	);
};

export default Instruments;
