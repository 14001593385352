import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Close as CloseIcon, LinkedIn } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';

import { closeDialog, getDialogData, isDialogOpen } from 'containers/Marketplace/dialogSlice';

import BlobDownloader from 'containers/Marketplace/attachment/BlobDownloader';
import { FormattedMessage } from 'react-intl';
import { RfpProposalTeamMember } from 'types/dataTypes';

type Props = {} & Omit<DialogProps, 'open'>;

const RfpProposalTeamMemberDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const dialogOpen = useSelector(isDialogOpen('ReadRfpTeamMemberDialog'));
	const rfpProposalTeamMember: RfpProposalTeamMember = useSelector(getDialogData('ReadRfpTeamMemberDialog'));

	if (!rfpProposalTeamMember) return null;

	return (
		<Dialog
			{...otherProps}
			open={dialogOpen}
			onClose={() => dispatch(closeDialog({ name: 'ReadRfpTeamMemberDialog' }))}
			maxWidth='sm'
			PaperProps={{
				sx: {
					overflowY: 'visible',
				},
			}}
			fullWidth
		>
			<IconButton
				onClick={() => dispatch(closeDialog({ name: 'ReadRfpTeamMemberDialog' }))}
				sx={{ position: 'absolute', top: 0, right: 0 }}
			>
				<CloseIcon />
			</IconButton>

			<Box sx={{ pb: 2, pt: 5, px: 4 }}>
				<Stack flexDirection='column' alignItems='center'>
					<DialogTitle sx={{ p: 0 }}>
						<Typography sx={{ textAlign: 'center' }}>
							{rfpProposalTeamMember?.firstname} {rfpProposalTeamMember?.lastname}
						</Typography>
						<Typography sx={{ textAlign: 'center' }}>{rfpProposalTeamMember?.position}</Typography>
					</DialogTitle>
					{rfpProposalTeamMember?.linkedInUrl ? (
						// @ts-ignore do not check if null url
						<IconButton aria-label='Linkedin.com' onClick={() => window.open(rfpProposalTeamMember?.linkedInUrl, '_blank')}>
							<LinkedIn />
						</IconButton>
					) : null}
				</Stack>
			</Box>
			<DialogContent>
				{rfpProposalTeamMember?.avatar && (
					<BlobDownloader url={rfpProposalTeamMember?.avatar}>
						{content => (
							<Box
								component='img'
								src={content}
								sx={{
									width: 120,
									height: 120,
									position: 'absolute',
									top: '-85px',
									left: 'calc(50% - 60px)',
									borderRadius: '60px',
								}}
							/>
						)}
					</BlobDownloader>
				)}
				<Typography variant='body1' sx={{ wordWrap: 'break-word' }}>
					{rfpProposalTeamMember?.description}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					size='small'
					variant='text'
					color='neutral'
					onClick={() => dispatch(closeDialog({ name: 'ReadRfpTeamMemberDialog' }))}
				>
					<FormattedMessage id='rfpProfile.close' />
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default RfpProposalTeamMemberDialog;
