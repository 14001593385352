import React from 'react';
import Menu from '@mui/material/Menu';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.primary.white,
        // border: `1px solid ${theme.palette.secondary.grey400}`,
        borderRadius: 5,
        color: theme.palette.secondary.grey600,
    },
}));

const CustomMenu = props => {
    const { children } = props;
    const classes = useStyles();

    return (
        <Menu
            classes={{
                paper: classes.paper,
            }}
            {...props}
        >
            {children}
        </Menu>
    );
};

export default CustomMenu;
