import AttachmentList from 'components/AttachmentList';
import { getFile } from 'containers/Projects/slices';
import { Project } from 'types/dataTypes';

export default function ProjectCostAttachments({ searchPattern, project }: { searchPattern: string | null; project: Project | undefined }) {
	if (!project) return null;

	const attachments = project.attachments ?? [];

	if (!searchPattern) return null;

	const getAttachmentFile = async (attachmentId: string) => {
		return await getFile(attachmentId, project?.projectState === 'Draft');
	};

	return (
		<AttachmentList
			sx={{ mt: '1rem' }}
			searchPattern={searchPattern}
			attachments={attachments}
			hideEmpty={true}
			getFile={getAttachmentFile}
			removeFile={undefined}
		/>
	);
}
