import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';

// import { getInitials } from 'utils/getInitials';
// import ChatbotIcon from 'components/ChatbotIcon';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'auto',
    },
    name: {
        fontSize: '0.85rem',
        fontWeight: 400,
        color: theme.palette.primary.main,
    },
    date: {
        float: 'right',
    },
    incoming: {
        width: '75%',
        float: 'left',
        borderRadius: theme.spacing(1),
        margin: theme.spacing(4),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: 0,
    },
    outgoing: {
        width: '75%',
        float: 'right',
        borderRadius: theme.spacing(1),
        margin: theme.spacing(4),
        marginLeft: 0,
        backgroundColor: theme.palette.primary.secondary,
    },
    userAvatar: {
        color: theme.palette.primary.beige,
        backgroundColor: theme.palette.primary.white,
    },
    botAvatar: {
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.primary.main,
    },
    messageText: {
        whiteSpace: 'pre-wrap',
    },
    messageFooter: {
        display: 'block',
        marginTop: '0.5rem',
    },
}));

const ConversationWindow = ({ chat, active }) => {
    const classes = useStyles();
    const intl = useIntl();

    const conversation = chat?.conversations?.filter(({ id }) => id === active)[0];
    const messages = conversation?.messages;

    const parseUsername = blocks => {
        if (!blocks) return null;

        const block = blocks?.find(item => item.block_id === 'username');

        if (!block) return null;

        const email = block.text?.text;

        if (!email) return null;

        return email;
    };

    const parseUsernameAbbr = blocks => {
        const email = parseUsername(blocks);

        if (!email) return null;

        const arry = email.split('@');
        const arrys = arry[0].split('.');

        let ret = 'na';

        if (arrys.length > 1) ret = `${arrys[0][0]}${arrys[1][0]}`;
        else ret = `${arrys[0][0]}${arrys[0][1]}`;

        return ret.toUpperCase();
    };

    const parseMessage = (blocks, text) => {
        if (!blocks) return text;

        const block = blocks?.find(item => item.block_id === 'message');

        if (!block) return text;

        return block.text?.text;
    };

    return (
        <List className={classes.root}>
            {messages?.length ? (
                messages?.map(({ text, isIncoming, bot_id, ts, userinfo, blocks }) => {
                    let date = null;
                    try {
                        date = moment.unix(ts).format('DD.MM.YYYY HH:mm');
                    } catch {
                        // ignore error
                    }

                    if (!bot_id) isIncoming = true; // not bot

                    const className = isIncoming ? classes.incoming : classes.outgoing;

                    return (
                        <ListItem key={text + ts} alignItems='flex-start' className={className}>
                            <ListItemAvatar>
                                {isIncoming ? (
                                    <Avatar alt='consultant' src={userinfo?.avatar} />
                                ) : (
                                    <Avatar className={classes.userAvatar}>{parseUsernameAbbr(blocks)}</Avatar>
                                )}
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography component='span' variant='subtitle1' color='primary' className={classes.messageText}>
                                        {parseMessage(blocks, text)}
                                    </Typography>
                                }
                                secondary={
                                    <span className={classes.messageFooter}>
                                        <Typography component='span' variant='body2' color='primary' className={classes.name}>
                                            {isIncoming ? userinfo?.realName : parseUsername(blocks)}
                                        </Typography>
                                        <Typography component='span' variant='body2' color='primary' className={classes.date}>
                                            {date}
                                        </Typography>
                                    </span>
                                }
                            />
                        </ListItem>
                    );
                })
            ) : (
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt='chat bot' className={classes.botAvatar}>
                            <QuestionAnswerIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography component='p' color='primary'>
                                {intl.formatMessage({ id: 'chat.bot.message' })}
                            </Typography>
                        }
                        secondary={null}
                    />
                </ListItem>
            )}
        </List>
    );
};

export default ConversationWindow;
