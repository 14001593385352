import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

import deal from 'assets/images/deal.jpg';
import { FormattedMessage } from 'react-intl';
import { isFreemiumToken } from 'utils/auth/company';

const CTABox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: theme.spacing(2),
	//borderRadius: theme.shape.borderRadius,
	backgroundColor: theme.palette.background.paper, // @ts-ignore
	//boxShadow: theme.shadows[2],
	marginLeft: '6px !important',
}));

const CTAImage = styled(Box)(({ theme }) => ({
	width: '100px',
	height: 'auto',
	marginRight: theme.spacing(2),
	borderRadius: theme.shape.borderRadius,
}));

const CTAContainer = ({ wide = false }: { wide?: boolean }) => {
	const isFreemium = isFreemiumToken();

	if (!isFreemium) return null;

	return (
		<CTABox>
			{/* @ts-ignore */}
			<CTAImage component='img' src={deal} />

			<Box>
				<Typography variant='body1' sx={{ mb: 2 }}>
					Rahoitushakupalvelussa huolehdimme tukirahoituskokonaisuuden suunnittelun, hakemisen ja raportoinnin puolestanne, jotta
					voitte keskittyä ydinosaamiseenne!
				</Typography>

				<Button
					variant='contained'
					color='primary'
					href='https://grants.fi/maksuton-konsultaatio-tukirahoituksesta/'
					target='_blank'
					sx={{
						textTransform: 'none', // this will keep the button text case as it is - example: do not uppercase
					}}
				>
					<FormattedMessage id='freemium.upgrade.card.button' />
				</Button>
			</Box>
		</CTABox>
	);
};

export default CTAContainer;
