import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';

import FormLabel from 'components/Form/FormLabel';

function not(a, b) {
	return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter(value => b.indexOf(value) !== -1);
}

const ShareTransferList = ({ title, items, fixedItems = [], option, passSelected = null }) => {
	const [checked, setChecked] = useState([]);
	const [left, setLeft] = useState(items);
	const [right, setRight] = useState(fixedItems);

	const changeRight = nextRight => {
		setRight(nextRight);
		passSelected(nextRight);
	};

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = value => () => {
		if (isFixedItem(value)) return;

		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		changeRight(right.concat(left));
		setLeft([]);
	};

	const handleCheckedRight = () => {
		changeRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		changeRight(not(right, rightChecked));
		setLeft(left.concat(rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		changeRight(fixedItems);
		setLeft(left.concat(right?.filter(item => !isFixedItem(item))));
	};

	const isFixedItem = item => fixedItems?.find(fixedItem => fixedItem.key === item.key);

	useEffect(() => {
		passSelected(fixedItems);
	}, [passSelected, fixedItems]);

	const customList = items => (
		<Paper sx={{ width: 250, height: '100%', minHeight: 250, overflow: 'auto' }}>
			<List dense component='div' role='list'>
				{items.map(item => {
					const labelId = `transfer-list-item-${item.value}-label`;
					const fixed = isFixedItem(item);

					return (
						<ListItem key={item.key} role='listitem' onClick={handleToggle(item)}>
							<ListItemIcon>
								<Checkbox
									disabled={fixed ? true : false}
									checked={checked.indexOf(item) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={item.title} />
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Paper>
	);

	return (
		<Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
			<Grid item xs={12}>
				<FormLabel> {title}</FormLabel>
			</Grid>
			<Grid item>{customList(left)}</Grid>
			<Grid item>
				<Grid container direction='column' alignItems='center'>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleAllRight}
						disabled={left.length === 0 || !option}
						aria-label='move all right'
					>
						≫
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleCheckedRight}
						disabled={leftChecked.length === 0 || !option}
						aria-label='move selected right'
					>
						&gt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleCheckedLeft}
						disabled={rightChecked.length === 0 || !option}
						aria-label='move selected left'
					>
						&lt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleAllLeft}
						disabled={right.filter(item => !isFixedItem(item)).length === 0 || !option}
						aria-label='move all left'
					>
						≪
					</Button>
				</Grid>
			</Grid>
			<Grid item>{customList(right)}</Grid>
		</Grid>
	);
};

export default ShareTransferList;
