import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

const baseUrl = 'ai';

const TAGS = {
	CHAT: 'CHAT',
	COMPLETION: 'COMPLETION',
};

type RequestForgottenPassword = {
	companyId: string | null;
	chatId: string;
	messages: any[];
	model?: string | null;
};

export const aiApi = createApi({
	reducerPath: 'ai',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [TAGS.COMPLETION, TAGS.CHAT],
	endpoints: builder => ({
		getOpenAiChatCompletion: builder.query({
			query: data => {
				if (!data) throw Error('Data is null');

				return {
					method: 'POST',
					url: '/completion',
					data: data,
				};
			},
			providesTags: [TAGS.COMPLETION],
		}),
		getChatHistory: builder.query({
			query: ({ companyId, chatId }) => {
				return {
					method: 'GET',
					url: `/chat/${companyId}/${chatId}`,
				};
			},
			providesTags: (_result, _err, data) => [{ type: TAGS.CHAT, id: data.chatId }],
		}),
		fetchInstrumentPrompt: builder.query({
			query: ({ instrumentId }) => {
				if (!instrumentId) throw Error('Data is null');

				return {
					method: 'GET',
					url: `/prompt/${instrumentId}`,
				};
			},
			// providesTags: (_result, _err, data) => [{ type: TAGS.CHAT, id: data.chatId }],
		}),
		postChatMessage: builder.mutation<any, RequestForgottenPassword>({
			query: ({ companyId, chatId, messages, model }) => {
				if (!companyId || !chatId) throw Error('Missing parameters');

				let params = null;
				if (model) {
					params = new URLSearchParams([['model', model]]);
				}

				return {
					method: 'POST',
					url: `/chat/${companyId}/${chatId}`,
					data: messages,
					params: params,
				};
			},
			async onQueryStarted(params, { dispatch, queryFulfilled }) {
				try {
					// no need to wait, when asking something: optimistic update
					const optimisticPatchResult = dispatch(
						aiApi.util.updateQueryData('getChatHistory', { companyId: params.companyId, chatId: params.chatId }, draft => {
							draft.chatContent.push(params.messages[params.messages.length - 1]);
						})
					);
					queryFulfilled.catch(optimisticPatchResult.undo);

					// pessimistic update wait response
					let chatContent: any;
					const { data } = await queryFulfilled;

					if (typeof data === 'string') {
						chatContent = { role: 'assistant', content: 'An error occured while using AI.' };
					} else {
						chatContent = data;
					}

					dispatch(
						aiApi.util.updateQueryData('getChatHistory', { companyId: params.companyId, chatId: params.chatId }, draft => {
							if (Array.isArray(chatContent)) draft.chatContent.push(...chatContent);
							else draft.chatContent.push(chatContent);
						})
					);
				} catch (ex) {
					console.error(ex);
				}
			},
		}),
		deleteChatMessage: builder.mutation<any, any>({
			query: ({ companyId, chatId }) => {
				if (!companyId || !chatId) throw Error('Missing parameters');

				let params = null;

				return {
					method: 'DELETE',
					url: `/chat/${companyId}/${chatId}`,
					params: params,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: TAGS.CHAT, id: params.chatId }];
			},
		}),
	}),
});

export const {
	useGetOpenAiChatCompletionQuery,
	useGetChatHistoryQuery,
	useFetchInstrumentPromptQuery,
	usePostChatMessageMutation,
	useDeleteChatMessageMutation,
} = aiApi;
