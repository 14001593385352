import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { userCompanyAdminApi } from './api';
import { getCompanyDetails } from './slices';

export const initialState = {
	constants: {
		data: {
			monetaryMethods: [],
			licenseSubTypes: [],
		},
		isLoaded: null,
		error: null,
	},
	licenses: [],
	licenseActiveStatus: 'active',
	licenseType: 'all',
	loading: false,
	error: false,
};

export const name = 'licenses';

export const getLicenseContantData = createAsyncThunk(
	'licenses/getLicenseContantData',
	async () => await userCompanyAdminApi.getLicenseConstantData()
);

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},
		loadLicenses(state) {
			state.loading = true;
			state.error = false;
		},
		loadLicensesSuccess(state, action) {
			state.loading = false;
			state.error = false;
			state.licenses = action.payload.data;
			state.licenseActiveStatus = action.payload.licenseActiveStatus;
			state.licenseType = action.payload.licenseType;
		},
		loadLicensesError(state, action) {
			state.error = action.payload;
			state.loading = false;
			state.licenses = initialState.licenses;
			state.licenseActiveStatus = initialState.licenseActiveStatus;
			state.licenseType = initialState.licenseType;
		},

		saveCompanyLicense(state) {
			state.loading = true;
			state.error = false;
		},
		saveCompanyLicenseSuccess(state) {
			state.loading = false;
			state.error = false;
		},
		saverror(state, action) {
			state.error = action.payload;
			state.loading = false;
		},

		terminateCompanyLicense(state) {
			state.loading = true;
			state.error = false;
		},
		terminateCompanyLicenseSuccess(state) {
			state.loading = false;
			state.error = false;
		},
		terminateError(state, action) {
			state.error = action.payload;
			state.loading = false;
		},
	},
	extraReducers: builder => {
		builder.addCase(getLicenseContantData.fulfilled, (state, action) => {
			state.constants.data = action.payload;
			state.constants.isLoaded = true;
		});
	},
});

export const { reducer } = slice;

export const getLicenses = (licenseActiveStatus, licenseType) => async dispatch => {
	try {
		dispatch(slice.actions.loadLicenses());
		const data = await userCompanyAdminApi.getLicensesByStatusAndType(licenseActiveStatus, licenseType);

		dispatch(slice.actions.loadLicensesSuccess({ data, licenseActiveStatus, licenseType }));
	} catch (error) {
		dispatch(slice.actions.loadLicensesError(error));
	}
};

export const saveCompanyLicense = (license, id, silently) => async dispatch => {
	try {
		dispatch(slice.actions.saveCompanyLicense());

		const data = await userCompanyAdminApi.saveCompanyLicense(id, license, silently);

		dispatch(slice.actions.saveCompanyLicenseSuccess(data));
		dispatch(getCompanyDetails(id));

		return { success: true, license: license };
	} catch (error) {
		dispatch(slice.actions.saverror(error));

		return { success: false, error: error };
	}
};

export const terminateCompanyLicense = id => async dispatch => {
	try {
		dispatch(slice.actions.terminateCompanyLicense());

		const data = await userCompanyAdminApi.terminateCompanyLicense(id);

		dispatch(slice.actions.terminateCompanyLicenseSuccess(data));
		dispatch(getCompanyDetails(id));

		return true;
	} catch (error) {
		dispatch(slice.actions.terminateError(error));

		return false;
	}
};

export default slice;
