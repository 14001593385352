import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import Button from 'components/Button/Button';
import ConfirmIconButton from 'components/Button/ConfirmIconButton';
import { FormControlledInput } from 'components/Form';
import FormControlledRating from 'components/Form/FormControlledRatingInput';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';

export default function InstrumentRatings({ instrumentId, errors, control }) {
    const intl = useIntl();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'instrument.ratings',
    });

    return (
        <>
            <Box display='flex' justifyContent='flex-end'>
                <Button startIcon={<AddIcon />} onClick={() => append({ instrumentId })}>
                    {intl.formatMessage({ id: 'instrument.edit.form.ratings.append' })}
                </Button>
            </Box>
            <Grid container spacing={2}>
                {fields.map((field, index) => {
                    return (
                        <React.Fragment key={field + index}>
                            {index > 0 &&
                                <Grid item xs={12}>
                                    <Divider sx={{ mt: 2 }} />
                                </Grid>
                            }
                            <Grid item xs={12} >
                                <Controller
                                    name={`ratings.${index}.instrumentId`}
                                    control={control}
                                    defaultValue={field?.instrumentId ?? ''}
                                    render={() => <input type='hidden' value={field?.instrumentId ?? ''} />}
                                />
                                <Controller
                                    name={`ratings.${index}.instrumentRatingId`}
                                    control={control}
                                    defaultValue={field?.instrumentRatingId ?? ''}
                                    render={() => <input type='hidden' value={field?.instrumentRatingId ?? ''} />}
                                />
                                <FormControlledInput
                                    defaultValue={field?.ratingTitle ?? ''}
                                    name={`ratings.${index}.ratingTitle`}
                                    control={control}
                                    error={errors?.ratings?.message}
                                    label={intl.formatMessage({ id: 'instrument.form.rating.title' })}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormControlledInput
                                    defaultValue={field?.ratingAuthor ?? ''}
                                    name={`ratings.${index}.ratingAuthor`}
                                    control={control}
                                    error={errors?.ratings?.message}
                                    label={intl.formatMessage({ id: 'instrument.form.rating.ratingAuthor' })}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormControlledWysiwyg
                                    inline={false}
                                    toolbar={true}
                                    name={`ratings.${index}.description`}
                                    control={control}
                                    defaultValue={field?.description ?? ''}
                                    error={errors?.ratings?.message}
                                    label={intl.formatMessage({ id: 'instrument.form.rating.description' })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlledRating
                                    defaultValue={field?.administrationRating ?? 0}
                                    name={`ratings.${index}.administrationRating`}
                                    control={control}
                                    error={errors?.ratings?.message}
                                    label={intl.formatMessage({ id: 'instrument.form.rating.administrationRating' })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlledRating
                                    defaultValue={field?.processComplexityRating ?? 0}
                                    name={`ratings.${index}.processComplexityRating`}
                                    control={control}
                                    error={errors?.ratings?.message}
                                    label={intl.formatMessage({ id: 'instrument.form.rating.processComplexityRating' })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlledRating
                                    defaultValue={field?.usabilityRating ?? 0}
                                    name={`ratings.${index}.usabilityRating`}
                                    control={control}
                                    error={errors?.ratings?.message}
                                    label={intl.formatMessage({ id: 'instrument.form.rating.usabilityRating' })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='flex-end'>
                                    <ConfirmIconButton
                                        color='error'
                                        disabled={false}
                                        confirmText={intl.formatMessage({ id: 'admin.ratings.remove.confirm' })}
                                        cancelText={intl.formatMessage({ id: 'admin.ratings.remove.cancel' })}
                                        confirmBodyText={intl.formatMessage({ id: 'admin.ratings.remove.body' })}
                                        confirmAction={() => remove(index)}
                                        stopPropagation={true}
                                        sx={{ mr: 2 }}
                                    >
                                        <DeleteIcon />
                                    </ConfirmIconButton>
                                </Box>
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </>
    );
}