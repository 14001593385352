import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import { sumBy } from 'lodash';
import { Project } from 'types/dataTypes';
import { currencyFormatter, percentFormatterWithFractionDigits } from 'utils/formatters/numberFormatters';
import ProjectPrintTableCell from '../ProjectPrintTableItems/ProjectPrintTableCell';
import ProjectPrintTableCellTitle from '../ProjectPrintTableItems/ProjectPrintTableCellTitle';

const FinanceTable = ({ project }: { project: Project }) => {
	const intl = useIntl();

	const totalPercents = sumBy(project.projectFinance, item => item?.amountPercent ?? 0);
	const totalAmount = sumBy(project.projectFinance, item => item?.amount ?? 0);

	return (
		<>
			<TableContainer
				sx={{
					mt: '1rem',
					background: '#fff',
					'@media print': {
						overflowX: 'visible',
						pageBreakInside: 'avoid',
					},
				}}
			>
				<Table>
					<TableBody>
						<TableRow>
							<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true}>
								{intl.formatMessage({ id: 'project.cost.finance' })}
							</ProjectPrintTableCellTitle>
							<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true} sx={{ textAlign: 'right' }}>
								{intl.formatMessage({ id: 'project.cost.amount' })}
							</ProjectPrintTableCellTitle>
							<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true} sx={{ textAlign: 'right' }}>
								{intl.formatMessage({ id: 'project.cost.percentage' })}
							</ProjectPrintTableCellTitle>
						</TableRow>
						{project?.projectFinance?.map(financeItem => {
							let percent = financeItem.amountPercent;
							if (percent) {
								percent = percent / 100;
							}

							const percentage = percentFormatterWithFractionDigits.format(percent ?? 0);

							return (
								<TableRow key={financeItem.name}>
									<ProjectPrintTableCell>{financeItem.name}</ProjectPrintTableCell>
									<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
										{currencyFormatter.format(financeItem.amount ?? 0)}
									</ProjectPrintTableCell>
									<ProjectPrintTableCell sx={{ textAlign: 'right' }}>{percentage}</ProjectPrintTableCell>
								</TableRow>
							);
						})}

						<TableRow>
							<ProjectPrintTableCell lastColumn={true}>
								<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
									{intl.formatMessage({ id: 'project.cost.total' })}
								</Typography>
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }} lastColumn={true}>
								<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
									{currencyFormatter.format(totalAmount ?? 0)}
								</Typography>
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }} lastColumn={true}>
								<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
									{percentFormatterWithFractionDigits.format((totalPercents ?? 0) / 100)}
								</Typography>
							</ProjectPrintTableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default FinanceTable;
