import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';
import Slide6 from './Slide6';
import Slide7 from './Slide7';
import Slide8 from './Slide8';
import Slide9 from './Slide9';
import Slide10 from './Slide10';
// import Slide11 from './Slide11';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './index.css';

const Slideshow = ({ companyName, maxHeight }) => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Carousel
            autoPlay={false}
            interval={600000}
            showArrows={!xs && true}
            showStatus={false}
            useKeyboardArrows={true}
            dynamicHeight={true}
            showThumbs={false}
            centerMode={true}
            centerSlidePercentage={100}
            // renderThumbs={() => [<Slide1 name='Reetu Tuominen' xs={xs} />, <Slide2 />], ...}
        >
            <Slide1 companyName={companyName} maxHeight={maxHeight} xs={xs} />
            <Slide2 maxHeight={maxHeight} />
            <Slide3 maxHeight={maxHeight} />
            <Slide4 maxHeight={maxHeight} />
            <Slide5 maxHeight={maxHeight} />
            <Slide6 maxHeight={maxHeight} />
            <Slide7 maxHeight={maxHeight} />
            <Slide8 maxHeight={maxHeight} />
            <Slide9 maxHeight={maxHeight} />
            <Slide10 maxHeight={maxHeight} />
            {/* <Slide11 email='reetu.tuominen@grants.fi' phone='+358405555190' linkedin='https://www.linkedin.com/in/reetu-tuominen' maxHeight={maxHeight} /> */}
        </Carousel>
    );
};

export default Slideshow;
