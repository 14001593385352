import { isNil } from 'lodash';
import * as React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Check as CheckIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import devices from 'assets/images/devices.png';

import { auth } from 'containers/App/appSlice';
import LoginForm from 'containers/Login/LoginForm';
import Logo from 'icons/Logo';

type LoginProps = {
	redirect: {
		pathname: string | null;
		state: {
			hash: string | null;
			pathname: string | null;
			search: string | null;
		};
	} | null;
};

const Login: React.FC<LoginProps> = ({ redirect }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const onSubmit = async ({ username, password }: { username: string; password: string }) => {
		const captchaToken = await executeRecaptcha?.('authenticate');
		if (isNil(captchaToken)) {
			return toast.error(<FormattedMessage id='register.error.captcha' />);
		}
		const success = await dispatch(auth(username, password, captchaToken, redirect?.state));
		if (!success) {
			toast.error(<FormattedMessage id='error.code.invalid_username_or_password' />);
		}
	};

	return (
		<Stack
			direction={{ xs: 'column', md: 'row' }}
			spacing={2}
			sx={{ minHeight: { xs: '70rem', md: '100%' }, padding: '1.143rem', backgroundColor: theme => theme.palette.primary.main }}
			useFlexGap
		>
			<Stack direction='column' spacing={2} sx={{ flex: 1, padding: '2.286rem', backgroundColor: '#3C2640' }} useFlexGap>
				<Logo />
				<Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Stack direction='column' spacing={2} sx={{ width: '100%', maxWidth: '30rem' }} useFlexGap>
						<Typography
							variant='h1'
							sx={{
								color: theme => theme.palette.primary.contrastText,
								fontSize: '1.714rem',
								fontWeight: 700,
								lineHeight: 1.3,
								textAlign: 'center',
							}}
						>
							<FormattedMessage id='login.title' />
						</Typography>
						<Box component='img' src={devices} sx={{ display: 'block', width: '274px', height: '274px', margin: '0 auto' }} />
						<Stack direction='column' spacing={2} sx={{ color: theme => theme.palette.primary.contrastText }}>
							<Stack direction='row' spacing={2}>
								<CheckIcon />
								<Typography>
									<FormattedMessage id='register.benefits.1.title' />
								</Typography>
							</Stack>
							<Stack direction='row' spacing={2}>
								<CheckIcon />
								<Typography>
									<FormattedMessage id='register.benefits.2.title' />
								</Typography>
							</Stack>
							<Stack direction='row' spacing={2}>
								<CheckIcon />
								<Typography>
									<FormattedMessage id='register.benefits.3.title' />
								</Typography>
							</Stack>
							{/* <Stack direction='row' spacing={2}>
								<Typography sx={{ color: theme => theme.palette.primary.contrastText }}>
									<FormattedMessage id='register.benefits.4.title' />
									<Link component={RouterLink} to='/register' sx={{ color: 'inherit', fontWeight: 600 }}>
										<FormattedMessage id='register.register' />
									</Link>
								</Typography>
							</Stack> */}
						</Stack>
					</Stack>
				</Box>
			</Stack>
			<Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Stack direction='column' spacing={2} sx={{ width: '100%', maxWidth: '30rem' }} useFlexGap>
					<LoginForm onSubmit={onSubmit} />
					<Box id='google-recaptcha' sx={{ display: 'flex', justifyContent: 'flex-start', visibility: 'hidden' }} />
					<small style={{ color: '#fff' }}>
						{formatMessage({ id: 'register.google.captcha' })}&nbsp;
						<a target='_blank' style={{ color: '#fff' }} href='https://policies.google.com/privacy?hl=fi' rel='noreferrer'>
							{formatMessage({ id: 'register.google.captcha.privacy-link' })}
						</a>
						{formatMessage({ id: 'register.google.captcha.and' })}
						<a target='_blank' style={{ color: '#fff' }} href='https://policies.google.com/terms?hl=fi' rel='noreferrer'>
							{formatMessage({ id: 'register.google.captcha.terms-link' })}
						</a>
					</small>
				</Stack>
			</Box>
		</Stack>
	);
};

export default Login;
