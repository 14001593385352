import { FormattedNumber } from 'react-intl';

type Props = {
	value?: number | bigint | null;
	defaultValue?: any | null | undefined;
	numberStyle?: 'currency' | 'unit' | 'decimal' | 'percent' | undefined;
};

const CurrencyValue: React.FC<Props> = ({ value, defaultValue = null, numberStyle = 'currency' }) => {
	if (!value) return defaultValue;

	// eslint-disable-next-line react/style-prop-object
	return <FormattedNumber value={value} style={numberStyle} currency={'EUR'} minimumFractionDigits={0} maximumFractionDigits={0} />;
};

export default CurrencyValue;
