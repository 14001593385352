import * as React from 'react';
import { isNil } from 'lodash';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { formatISO, getYear, isDate, isValid } from 'date-fns';

type Props<FV extends FieldValues, N extends FieldPath<FV>> = ControllerProps<FV, N> & {};

function DateController<FV extends FieldValues, N extends FieldPath<FV>>({ render, ...otherProps }: Props<FV, N>) {
	return (
		<Controller
			{...otherProps}
			render={({ field, ...renderProps }) => {
				const fieldValue = field.value as any;
				return render?.({
					field: {
						...field,
						value: isNil(fieldValue) ? '' : fieldValue,
						onChange: (value: null | string | Date) => {
							field.onChange(
								isDate(value) && isValid(value) && getYear(value as Date) > 1100 ? formatISO(value as Date) : value
							);
						},
					},
					...renderProps,
				});
			}}
		/>
	);
}

export default DateController;
