import React, { useState, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import CreateCustomCompanyForm from 'containers/Advanced/CreateCustomCompanyForm';
import CompanySearch from 'containers/CompanySearch';
import ModalDialog from 'components/Dialog/ModalDialog';
import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';

const initialValues = {
    businessId: '',
    name: '',
    companyForm: null,
    detailsUri: '',
    address: {
        street: '',
        postCode: '',
        city: '',
        country: ''
    }
};

const AddCustomerModal = forwardRef(({ data, isOpen, close, selected, createLoading, addLoading, submitForm, addAsCustomer, setOption, onSubmit }, ref) => {
    const intl = useIntl();

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isAutocompleteDirty, setIsAutocompleteDirty] = useState(false);

    const [tab, setTab] = useState(0);
    const handleTabChange = (_, tab) => {
        setTab(tab);

        if (tab === 0) {
            setIsFormDirty(false);
        } else {
            setIsAutocompleteDirty(false);
        }
    };

    return (
        <ModalDialog
            open={isOpen}
            onClose={close}
            title={
                <Stack direction='row' spacing={4} alignItems='center'>
                    <Box sx={{ maxWidth: '20rem' }}>
                        <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {intl.formatMessage({ id: selected ? 'customer.page.modal.add.prospect' : 'customer.page.modal.add.customer' })}
                        </Typography>
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                        <Tabs value={tab} onChange={handleTabChange}>
                            <Tab label={intl.formatMessage({ id: 'customer.page.modal.search' })} />
                            <Tab label={intl.formatMessage({ id: 'customer.page.modal.add.new' })} />
                        </Tabs>
                    </Box>
                </Stack>
            }
            actions={
                <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                    <Button
                        variant='text'
                        color='primary'
                        onClick={close}>{intl.formatMessage({ id: 'common.close' })}
                    </Button>
                    <Button
                        color='success'
                        disabled={!isFormDirty && !isAutocompleteDirty || createLoading || addLoading}
                        onClick={isFormDirty ? submitForm : isAutocompleteDirty ? addAsCustomer : null}
                    >
                        {intl.formatMessage({ id: 'shared.save' })}
                        {(createLoading || addLoading) && <ButtonSpinner />}
                    </Button>
                </Stack>
            }
            fixedHeight={4}
            maxHeight='38rem'
        >
            <Box sx={{ p: 3 }}>
                {tab === 0
                    ? <CompanySearch
                        customers={data ?? []}
                        disabled={isFormDirty || createLoading || addLoading}
                        passIsDirty={isDirty => setIsAutocompleteDirty(isDirty)}
                        passOption={option => setOption(option)}
                    />
                    : <CreateCustomCompanyForm
                        ref={ref}
                        disabled={isAutocompleteDirty || createLoading || addLoading}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        passIsDirty={isDirty => setIsFormDirty(isDirty)}
                    />
                }
            </Box>
        </ModalDialog>

    );
});

export default AddCustomerModal;
