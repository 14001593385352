import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { IconButton, Stack } from '@mui/material';

const TestSortableItem = props => {
	const { attributes, listeners, setNodeRef, transform, transition, isDragging, sx = {} } = useSortable({ id: props.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		margin: '.5rem',
		zIndex: isDragging ? '100' : 'auto',
		opacity: isDragging ? 0.3 : 1,
		...sx,
	};

	return (
		<div ref={setNodeRef} style={style}>
			<Stack direction='row'>
				<IconButton sx={{ height: '3rem', mt: '.7rem' }} {...listeners} {...attributes}>
					<DragIndicatorIcon />
				</IconButton>
				{props.children ?? props.value}
			</Stack>
		</div>
	);
};

export default TestSortableItem;
