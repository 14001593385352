import React from 'react';
import { Controller } from 'react-hook-form';

import { styled, withTheme } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import EventIcon from '@mui/icons-material/Event';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';

import fiLocale from 'date-fns/locale/fi';

import FormLabel from './FormLabel';
import FormError from './FormError';
import { StyledTextField } from './FormStyledTextfield';

const StyledEventIcon = styled(withTheme(EventIcon))(({ theme }) => ({
	color: theme.palette.secondary.grey500,
}));

/**
 * Controlled Datepicker, dependencies hoook-form, mui
 *
 * @returns
 */
const FormControlledDatepicker = ({
	defaultValue,
	name,
	control,
	error,
	label,
	variant = 'outlined',
	minDate,
	maxDate,
	disableToolbar = false,
	setInstruction = null,
	instructions = null,
	sx = {},
	onInputBlur = () => {},
	...props
}) => {
	const hasError = error ? true : false;

	const showInstruction = () => setInstruction && setInstruction(instructions);
	const hideInstruction = () => setInstruction && setInstruction('');

	let timer;

	const handleBlur = () => {
		timer = setTimeout(() => hideInstruction(), 100);
		onInputBlur();
	};

	const handleOpen = () => {
		clearTimeout(timer);
		showInstruction();
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
			<FormControl fullWidth sx={sx}>
				<FormLabel>{label}</FormLabel>
				<Controller
					name={name}
					control={control}
					defaultValue={defaultValue}
					render={({ field: { onChange, value } }) => (
						<DatePicker
							maxDate={maxDate ? new Date(maxDate) : undefined}
							minDate={minDate ? new Date(minDate) : undefined}
							value={value}
							onChange={date => {
								if (date === null) onChange(null);

								// if user is typing the date, and it is has year after 1100, no reason for date before that
								if (date && date?.getFullYear() > 1100)
									onChange(new Date(date?.getFullYear(), date?.getMonth(), date?.getDate(), 12, 0, 0, 0));
							}}
							disableToolbar={disableToolbar}
							autoOk
							variant='inline'
							inputVariant='outlined'
							format='dd.MM.yyyy'
							inputFormat='dd.MM.yyyy'
							placeholder='pp.kk.vvvv'
							disableMaskedInput={true}
							margin='normal'
							keyboardIcon={<StyledEventIcon />}
							KeyboardButtonProps={{ 'aria-label': 'change date' }}
							InputLabelProps={{ shrink: true }}
							style={{ margin: 0, padding: 0, width: '100%' }}
							onOpen={handleOpen}
							onClose={hideInstruction}
							renderInput={params => {
								return (
									<StyledTextField
										variant={variant}
										{...params}
										size='small'
										error={hasError}
										placeholder='pp.kk.vvvv'
										onFocus={showInstruction}
										onBlur={handleBlur}
									/>
								);
							}}
							{...props}
						/>
					)}
				/>
				<FormError error={error} />
			</FormControl>
		</LocalizationProvider>
	);
};

export default FormControlledDatepicker;
