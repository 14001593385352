import { Box, CircularProgress, Link, Paper } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import htmlParser from 'components/HtmlParser';
import { useGetCompanyContractQuery, useGetContractWithVersionQuery, useGetLatestContractQuery } from 'containers/App/contractApi';
import AgreementActions from 'containers/MasterPage/AgreementActions';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { canSignCompanyContract, getCompany, getCompanyId } from 'utils/auth/company';
import styles from '../priceStyles';

const ContractView = ({ error, specific, handleChange, confirmAgreement, isSigned, loading }) => {
	const company = getCompany();
	const companyId = company?.CompanyId ?? getCompanyId();

	const {
		data: companyContract,
		isLoading: isCompanyContractLoading,
		isFetching: isCompanyContractFetching,
	} = useGetCompanyContractQuery({ companyId, type: 'TermsContract' }, { skip: !companyId });

	const {
		data: contractData,
		isLoading: isContractLoading,
		isFetching: isContractFetching,
	} = useGetContractWithVersionQuery(
		{ companyId, type: companyContract?.contractType, version: companyContract?.contractVersion },
		{ skip: !companyId || !companyContract }
	);

	const {
		data: newContractData,
		isLoading: isNewContractLoading,
		isFetching: isNewContractFetching,
	} = useGetLatestContractQuery(
		{ companyId, type: 'TermsContract' },
		{ skip: contractData || isCompanyContractLoading || isCompanyContractFetching || isContractLoading || isContractFetching }
	);

	const isLoading =
		isNewContractLoading ||
		isNewContractFetching ||
		isContractLoading ||
		isContractFetching ||
		isCompanyContractLoading ||
		isCompanyContractFetching;

	const contractHtml = useMemo(() => {
		return htmlParser(contractData?.contractContentData ?? newContractData?.contractContentData);
	}, [contractData, newContractData]);

	const userCanSignCompanyContract = canSignCompanyContract();

	return (
		<Container>
			<Typography component='div' align='left' color='primary'>
				<h1>
					<FormattedMessage id='terms.and.conditios.modal.header' />
				</h1>
				{isLoading ? (
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '10rem' }}>
						<CircularProgress />
					</Box>
				) : (
					<>
						<Paper sx={{ p: 2, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', mb: 6, }}>
							<Typography variant='body1' color='text.muted'>
								<FormattedMessage id='terms.and.conditios.instruction.p-1' />
							</Typography>
							<Typography sx={{ mt: 1 }} variant='body1' color='text.muted'>
								<FormattedMessage id='terms.and.conditios.instruction.p-2' />
							</Typography>
							<Typography sx={{ mt: 1 }} variant='body1' color='text.muted'>
								<Link href='https://grants.fi/yhteystiedot/' target='_blank'>
									<FormattedMessage id='terms.and.conditios.instruction.p-3' />
								</Link>
							</Typography>
						</Paper>
						<Box sx={{ ...styles }}>{contractHtml}</Box>
					</>
				)}
			</Typography>
			{!isSigned && userCanSignCompanyContract && (
				<AgreementActions
					loading={loading}
					error={error}
					specific={specific}
					handleChange={handleChange}
					confirmAgreement={confirmAgreement}
				/>
			)}
		</Container>
	);
};

export default ContractView;
