import { useState, useCallback } from 'react';

export const useClientWidth = width => {
  const [clientWidth, setClientWidth] = useState(null);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setClientWidth(node.clientWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return [clientWidth, measuredRef];
};