import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Stack } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

import ModalDialog from 'components/Dialog/ModalDialog';
import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';

import ShareLinkForm from '../ShareLinkForm/ShareLinkForm';
import AddButton from 'components/Button/AddButton';

const SharelinkFormModalDialog = ({ loading }) => {
    const intl = useIntl();
    const formRef = useRef();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const submitForm = () => {
        return formRef.current.dispatchEvent(new CustomEvent('submit', { cancelable: true, bubbles: true }));
    };

    return (
        <>
            <AddButton onClick={handleOpen} icon={<ShareIcon />}>
                {intl.formatMessage({ id: 'stakeholder.form.add-link-share' })}
            </AddButton>
            <ModalDialog
                onClose={handleClose}
                open={open}
                title={intl.formatMessage({ id: 'stakeholder.form.title' })}
                actions={
                    <Stack sx={{ width: '100%' }} direction='row' justifyContent='space-between' alignItems='flex-start'>
                        <Button variant='text' color='primary' disabled={null} onClick={handleClose} sx={{ flexGrow: 1 }}>
                            {intl.formatMessage({ id: 'common.close' })}
                        </Button>
                        <Button type='submit' variant='contained' color='success' disabled={null} onClick={submitForm}>
                            {intl.formatMessage({ id: 'stakeholder.form.save' })}
                            {loading && <ButtonSpinner />}
                        </Button>
                    </Stack>
                }
            >
                <ShareLinkForm ref={formRef} />
            </ModalDialog >
        </>
    );
};

export default SharelinkFormModalDialog;