import { Box, Stack } from '@mui/system';
import Subtitle from 'components/Titles/Subtitle';

const ProjectSummaryTextSection = ({ title, children, variant }: { title: string; children: any; variant?: any }) => {
	return (
		<Stack direction='row'>
			<Subtitle variant={variant} mt={0} mb={0} padding={0} sx={{ fontSize: '1rem', width: '20rem' }}>
				{title}
			</Subtitle>
			<Box>{children}</Box>
		</Stack>
	);
};

export default ProjectSummaryTextSection;
