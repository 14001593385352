import { filter, replace, truncate } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Collapse, IconButton, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';

import { ListItemType } from 'components/Pagination/Paginator';
import RfpStatusChip from 'containers/Marketplace/common/RfpStatusChip';
import { openDrawer } from 'containers/Marketplace/drawerSlice';
import CanUpsertRfpProposal from 'containers/Marketplace/proposal/CanUpsertRfpProposal';
import { RfpProposal, RfpRequest } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';
import RfpLastUpdatedTypography from '../../common/RfpLastUpdatedTypography';
import { PaginatedProposalList } from '../../proposal/List/RfpProposalList';

interface IDataListItemProps extends ListItemType<RfpProposal> {
	data?: RfpProposal;
	rfpRequest: RfpRequest | undefined;
}

export const ProposalListItem: React.FC<IDataListItemProps> = ({ data, rfpRequest }) => {
	const dispatch = useDispatch();

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	return (
		<ListItem
			key={data?.rfpProposalId}
			disablePadding
			secondaryAction={
				<CanUpsertRfpProposal rfpRequest={rfpRequest} rfpProposal={data}>
					{canUpsert =>
						canUpsert && (
							<IconButton
								onClick={() => {
									dispatch(
										openDrawer({
											name: 'UpsertRfpProposalDrawer',
											data: {
												requestId: data?.requestId,
												proposalId: data?.rfpProposalId,
												published: data?.status === 'Published',
											},
										})
									);
								}}
							>
								<EditIcon />
							</IconButton>
						)
					}
				</CanUpsertRfpProposal>
			}
		>
			<ListItemButton
				onClick={() => {
					dispatch(
						openDrawer({
							name: 'ReadRfpProposalDrawer',
							data: {
								requestId: data?.requestId,
								proposalId: data?.rfpProposalId,
								published: data?.status === 'Published',
								createdByMe: data?.companyId === companyId,
							},
						})
					);
				}}
			>
				<ListItemText
					primary={
						<Stack direction='row' alignItems='center' sx={{ mb: '.3rem', pr: '13rem' }}>
							<RfpStatusChip status={data?.status} />
							<RfpLastUpdatedTypography updateDate={data?.updateDate} createDate={data?.createDate} />
						</Stack>
					}
					secondary={
						<Typography component='span' variant='lightText' sx={{ display: 'block', pr: '11rem', pl: '7rem' }}>
							{truncate(replace(data?.projectOutline || '', /\r|\n|\r\n/g, ' '), { length: 250 })}
						</Typography>
					}
				/>
			</ListItemButton>
		</ListItem>
	);
};

type Props = {
	rfpProposals: { drafts: RfpProposal[]; published: RfpProposal[] } | undefined;
	rfpRequest: RfpRequest | undefined;
	expanded: boolean;
};

const RequestProposalList: React.FC<Props> = ({ rfpProposals, rfpRequest, expanded }) => {
	const publishedProposals = filter(rfpProposals?.published, item => item.requestId === rfpRequest?.rfpRequestId);
	const draftProposals = filter(rfpProposals?.drafts, item => item.requestId === rfpRequest?.rfpRequestId);

	const proposalList = [...publishedProposals, ...draftProposals];

	if (!proposalList || proposalList.length === 0) return null;

	return (
		<>
			<Collapse
				in={expanded}
				sx={{ width: '100%', backgroundColor: 'background.paper', m: 0, p: 0, top: '-.5rem', position: 'relative' }}
			>
				<PaginatedProposalList proposals={proposalList} dataListItem={<ProposalListItem rfpRequest={rfpRequest} />} />
			</Collapse>
		</>
	);
};

type RfpRequestProposalsButtonProps = {
	active?: boolean;
	onClick: () => void;
	rfpProposals: { drafts: RfpProposal[]; published: RfpProposal[] } | undefined;
	rfpRequest: RfpRequest | undefined;
};
export const RfpRequestProposalsButton: React.FC<RfpRequestProposalsButtonProps> = ({ rfpProposals, rfpRequest, onClick, active }) => {
	if (!rfpRequest) return null;

	const publishedProposals = filter(rfpProposals?.published, item => item.requestId === rfpRequest?.rfpRequestId);
	const draftProposals = filter(rfpProposals?.drafts, item => item.requestId === rfpRequest?.rfpRequestId);

	const proposalList = [...publishedProposals, ...draftProposals];

	if (!proposalList || proposalList?.length === 0) return null;

	// if (proposalList?.length === 1) {
	// 	return (
	// 		<Button variant='text' onClick={onClick} endIcon={active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
	// 			<FormattedMessage id='rfp.list.proposals.single' values={{ proposalCount: proposalList?.length }} />
	// 		</Button>
	// 	);
	// }

	return (
		<Button size='small' variant='text' onClick={onClick} endIcon={active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
			<FormattedMessage
				id={proposalList?.length === 1 ? 'rfp.list.proposals.single' : 'rfp.list.proposals.count'}
				values={{ proposalCount: proposalList?.length }}
			/>
		</Button>
	);
};

export default RequestProposalList;
