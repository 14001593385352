import React from 'react';
import { Controller } from 'react-hook-form';

import { FormControl, FormLabel } from '@mui/material';
import CodeEditor from '@uiw/react-textarea-code-editor';
import '@uiw/react-textarea-code-editor/dist.css';

const JsonInputForm: React.FC<any> = ({ label, control, name, defaultValue, fullWidth = true, sx = {} }) => {
	document.documentElement.setAttribute('data-color-mode', 'dark');

	return (
		<FormControl fullWidth={fullWidth} sx={sx}>
			{label && <FormLabel>{label}</FormLabel>}
			<Controller
				name={name}
				defaultValue={JSON.stringify(defaultValue)}
				control={control}
				render={({ field: { onChange, value } }) => {
					return (
						<CodeEditor
							value={JSON.stringify(defaultValue)}
							language='json'
							placeholder='{ type valida json here }'
							onChange={evn => onChange(JSON.parse(evn.target.value))}
							padding={15}
							style={{
								fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
							}}
						/>
					);
				}}
			/>
		</FormControl>
	);
};

export default JsonInputForm;
