import * as React from 'react';

import { Box } from '@mui/material';
import HtmlParser from 'components/HtmlParser/HtmlParser';

type Props = {
	description: string;
};

const InstrumentCategoryDescription: React.FC<Props> = ({ description }) => {
	return (
		<Box
			sx={{
				position: 'relative',
				overflowY: 'hidden',
				marginBottom: '1rem',
				padding: '1rem',
				backgroundColor: '#fff',
				'& *': {
					color: 'primary.main',
				},
			}}
		>
			{HtmlParser(description)}
		</Box>
	);
};

export default InstrumentCategoryDescription;
