import * as React from 'react';
import { Alert, AlertTitle, Autocomplete, AutocompleteProps } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { RfpCategory } from 'types/dataTypes';

import { useFetchCommonDataQuery } from '../rfpCommonApi';

type Props = Omit<AutocompleteProps<RfpCategory, true, false, false>, 'options' | 'value'> & {
	value: RfpCategory[] | null | undefined;
};

const RfpCategoryAutocomplete = React.forwardRef<any, Props>(({ value, ...otherProps }, ref) => {
	const { formatMessage } = useIntl();

	const { data: commonData, isLoading, isError } = useFetchCommonDataQuery();

	return (
		<>
			{isError && (
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='query.error.title' />
					</AlertTitle>
					<FormattedMessage id='fetchRfpCommonData.error.message' />
				</Alert>
			)}
			<Autocomplete
				{...otherProps}
				ref={ref}
				options={commonData?.rfpCategory ?? []}
				value={value ?? []}
				loading={isLoading}
				getOptionLabel={option => formatMessage({ id: `rfpCategory.${option}` })}
				multiple
			/>
		</>
	);
});

export default RfpCategoryAutocomplete;
