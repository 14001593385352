import { EventType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
	auth: {
		clientId: 'f1727495-5012-46ce-887d-446b91063cd3', // This is the ONLY mandatory field that you need to supply.
		authority: 'https://login.microsoftonline.com/80267ce2-681e-47b6-998c-12727c6fe96e/', // Defaults to "https://login.microsoftonline.com/common"
		redirectUri: '/admin/login/redirect', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
		postLogoutRedirectUri: '/admin/login', // Indicates the page to navigate after logout.
		// navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
	},
	cache: {
		cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level: LogLevel, message: string | null, containsPii: boolean) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						//console.info(message);
						return;
					case LogLevel.Verbose:
						//console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
					default:
						return;
				}
			},
		},
	},
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
	scopes: ['https://grants-api.azurewebsites.net/default'],
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
	scopes: ['openid', 'profile'],
	loginHint: 'example@domain.net',
};

const msalInstance = new PublicClientApplication(msalConfig);
export const initializeMsalInstance = () => {
	// Default to using the first account if no account is active on page load
	if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
		const accountInfo = msalInstance.getActiveAccount();

		// Account selection logic is app dependent. Adjust as needed for different use cases.
		msalInstance.setActiveAccount(accountInfo);
	}

	// Listen for sign-in event and set active account
	msalInstance.addEventCallback(event => {
		// @ts-ignore
		if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload?.account) {
			// @ts-ignore
			const account = event?.payload?.account;
			msalInstance.setActiveAccount(account);
		}
	});

	return msalInstance;
};

export const getMsalTokenAsync = async () => {
	if (!msalInstance.getActiveAccount()) initializeMsalInstance();

	if (!msalInstance.getActiveAccount()) return null;

	try {
		return await msalInstance.acquireTokenSilent(loginRequest);
	} catch (err) {
		console.error(err);
		return null;
	}
};
