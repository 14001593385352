import { createApi } from '@reduxjs/toolkit/query/react';
import { isNil } from 'lodash';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

type SearchData = {
	filters: any[];
	select: number;
	attach: string;
};

const Tags = {
	CustomerDataSearch: 'customerDataSearch',
};

const baseUrl = 'customer';

export const customerSearchApi = createApi({
	reducerPath: 'customerSearchApi',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.CustomerDataSearch],

	endpoints: builder => ({
		constantData: builder.query<any, any>({
			query: () => {
				return {
					method: 'GET',
					url: '/contants',
				};
			},
		}),
		searchCustomers: builder.query<any, SearchData>({
			query: ({ filters, select = 1000, attach = 'None' }) => {
				if (isNil(filters)) {
					throw new Error('parameter error');
				}

				return {
					method: 'POST',
					url: `?select=${select}&attach=${attach}`,
					data: filters,
				};
			},
			providesTags: () => {
				return [{ type: Tags.CustomerDataSearch, id: 'LIST' }];
			},
		}),
	}),
});

export const { useConstantDataQuery, useSearchCustomersQuery, useLazySearchCustomersQuery } = customerSearchApi;
