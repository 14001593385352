import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import Timeline from 'containers/FinancialPlan';

import { isStaticProfile } from 'utils/auth/token';
import { isAdvancedOrCustomerToken } from 'utils/auth/company';

import { useTheme } from '@mui/styles';

export default function TimelineContainer({ timeline, projects, mock, scrollToNoInsruments, companyId, pathname = '', instruments, printView }) {
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));

	const timelineRef = useRef();
	const padding = 8; // padding = 8px --> Timeline.js, container style

	const timelineContainerRef = useRef();
	const [timelineContainerWidth, setTimelineContainerWidth] = useState(null);

	useLayoutEffect(() => {
		const updateWidth = () => {
			setTimelineContainerWidth(timelineContainerRef.current?.clientWidth);
		};

		window.addEventListener('resize', updateWidth);
		updateWidth();

		return () => window.removeEventListener('resize', updateWidth);
	}, []);

	const [xScrollWidth, setXScrollWidth] = useState(timelineContainerWidth);
	const [xScrollLeft, setXScrollLeft] = useState(0);

	useEffect(() => {
		// should be initially at least 1px bigger than (timelineContainerWidth + padding)
		setXScrollWidth(timelineContainerWidth + padding + 1);
	}, [timelineContainerWidth]);

	const onScroll = () => {
		const xScrollWidth = timelineRef.current?.scrollWidth;
		const xScrollLeft = timelineRef.current?.scrollLeft;
		setXScrollWidth(xScrollWidth);
		setXScrollLeft(xScrollLeft);
	};

	const [isHorizontalScrollbar, setIsHorizontalScrollbar] = useState(false);
	const hasHorizontalScrollbar = tableWidth => setIsHorizontalScrollbar(tableWidth + padding + 1 >= timelineContainerWidth);
	const isRenderLeftSmooth = isHorizontalScrollbar && xScrollLeft > padding;
	const isRenderRightSmooth = isHorizontalScrollbar && xScrollWidth + 32 > xScrollLeft + timelineContainerWidth;

	const isAdvancedCompanyOrCustomer = isAdvancedOrCustomerToken();
	const isStaticProfileConst = isStaticProfile();

	return (
		<div
			ref={timelineContainerRef}
			style={{
				height:
					projects && Object.keys(projects).length === 0 && mdDown
						? '18rem'
						: '100%',
				maxHeight: !mdDown && !printView && '35rem',
			}}
		>
			<div
				style={{
					height: '100%',
					maxWidth: timelineContainerWidth,
				}}
			>
				<Timeline
					ref={timelineRef}
					loading={false}
					timeline={timeline}
					projects={projects}
					mock={mock}
					instruments={instruments}
					padding={padding}
					onScroll={onScroll}
					hasHorizontalScrollbar={hasHorizontalScrollbar}
					isRenderRightSmooth={isRenderRightSmooth}
					isRenderLeftSmooth={isRenderLeftSmooth}
					scrollToNoInsruments={scrollToNoInsruments}
					hideContactCalendar={isAdvancedCompanyOrCustomer}
					isStaticProfileConst={isStaticProfileConst}
					companyId={companyId}
					pathname={pathname}
					timelineContainerWidth={timelineContainerWidth}
					printView={printView}
				/>
			</div>
		</div>
	);
}
