export const LOAD_INSTRUMENT_RADAR = 'Grants/scenario/LOAD_INSTRUMENT_RADAR';
export const LOAD_INSTRUMENT_RADAR_SUCCESS = 'Grants/scenario/LOAD_INSTRUMENT_RADAR_SUCCESS';
export const LOAD_INSTRUMENT_RADAR_ERROR = 'Grants/scenario/LOAD_INSTRUMENT_RADAR_ERROR';

export const LOAD_SCENARIOS = 'Grants/scenario/LOAD_SCENARIOS';
export const LOAD_SCENARIOS_SUCCESS = 'Grants/scenario/LOAD_SCENARIOS_SUCCESS';
export const LOAD_SCENARIOS_ERROR = 'Grants/scenario/LOAD_SCENARIOS_ERROR';
export const SAVE_SCENARIO = 'Grants/scenario/SAVE_SCENARIO';
export const SAVE_SCENARIO_FAIL = 'Grants/scenario/SAVE_SCENARIO_FAIL';
export const SAVE_SCENARIO_SUCCESS = 'Grants/scenario/SAVE_SCENARIO_SUCCESS';

export const SET_COMPANY_LIMIT = 'Grants/scenario/SET_COMPANY_LIMIT';

export const OPEN_MODAL = 'Grants/scenario/OPEN_MODAL';
export const CLOSE_MODAL = 'Grants/scenario/CLOSE_MODAL';

export const RESET_SCENARIO = 'Grants/scenario/RESET_SCENARIO';
