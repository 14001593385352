import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

import UserAdminContentContainer from 'components/Containers/UserAdminContentContainer';
import MenuListItem from 'components/List/MenuListItem';
import MenuListItemIcon from 'components/List/MenuListItemIcon';
import MenuTextItem from 'components/List/MenuTextItem';
import StyledMenu from 'components/StyledMenu';

import { toggleIsAdminPanelCollapsed } from 'containers/App/appSlice';

const useStyles = makeStyles(theme => ({
	menuItem: {
		'& .MuiListItemText-root': {
			color: theme.palette.primary.main,
			[theme.breakpoints.down('sm')]: {
				maxWidth: '14rem',
			},
		},
		'& .MuiListItemText-primary': {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemText-root, & .MuiListItemText-secondary': {
				color: theme.palette.primary.white,
			},
		},
	},
	currentItem: {
		backgroundColor: theme.palette.secondary.lightBeige,
		'&:hover': {
			cursor: 'default',
			backgroundColor: theme.palette.secondary.lightBeige,
			'& .MuiListItemText-root, & .MuiListItemText-secondary, & span': {
				cursor: 'default',
				color: theme.palette.primary.main,
				backgroundColor: 'transparent',
			},
		},
	},
}));

const adminPanelItems = ['search', 'userlist', 'companylist', 'instruments', 'categories', 'references', 'mrr'];

const AdminDrawer = ({ pathname, isDrawerExpanded, betweenSmLg, onClose }) => {
	const intl = useIntl();
	const classes = useStyles();
	const dispatch = useDispatch();
	const isAdminPanelCollapsed = useSelector(state => state.app?.isAdminPanelCollapsed);

	const toggleCollapse = () => dispatch(toggleIsAdminPanelCollapsed(!isAdminPanelCollapsed));

	const handleClick = item => {
		if (pathname === `/admin/${item}`) return;
		onClose(`/admin/${item}`);
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const openMenu = event => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	useEffect(() => {
		if (isAdminPanelCollapsed === undefined && pathname && pathname.startsWith('/admin')) {
			dispatch(toggleIsAdminPanelCollapsed(true));
		}
	}, [dispatch, isAdminPanelCollapsed, pathname]);

	return (
		<UserAdminContentContainer>
			{isDrawerExpanded ? (
				<>
					<MenuListItem button conditionalStyled={!isDrawerExpanded} onClick={toggleCollapse}>
						<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
							<AdminPanelSettingsIcon />
						</MenuListItemIcon>
						<ListItemText primary={<MenuTextItem>{intl.formatMessage({ id: 'admin.drawer.title' })}</MenuTextItem>} />
						{isAdminPanelCollapsed ? (
							<ExpandLess sx={{ color: 'primary.white' }} />
						) : (
							<ExpandMore sx={{ color: 'primary.white' }} />
						)}
					</MenuListItem>
					<Collapse in={isAdminPanelCollapsed} unmountOnExit>
						{adminPanelItems.map(item => {
							return (
								<MenuListItem
									button
									key={item}
									onClick={() => handleClick(item)}
									conditionalHovered={pathname === `/admin/${item}`}
									adminPanelItem
								>
									<ListItemText
										primary={
											<MenuTextItem adminPanelItem>{intl.formatMessage({ id: `admin.drawer.${item}` })}</MenuTextItem>
										}
									/>
								</MenuListItem>
							);
						})}
					</Collapse>
				</>
			) : (
				<>
					<MenuListItem button conditionalStyled={!isDrawerExpanded} onClick={openMenu}>
						<MenuListItemIcon conditionalStyled={!isDrawerExpanded}>
							<AdminPanelSettingsIcon />
						</MenuListItemIcon>
					</MenuListItem>
					<StyledMenu
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						variant='menu' // used just as bool --> to avoid warning if pass real bool
					>
						{adminPanelItems.map(item => {
							return (
								<MenuItem
									key={item}
									onClick={() => handleClick(item)}
									className={`${classes.menuItem} ${pathname === `/admin/${item}` && classes.currentItem}`}
								>
									<ListItemText primary={intl.formatMessage({ id: `admin.drawer.${item}` })} />
								</MenuItem>
							);
						})}
					</StyledMenu>
				</>
			)}
			<Divider sx={{ pt: !isDrawerExpanded || !isAdminPanelCollapsed ? 0 : 1 }} />
		</UserAdminContentContainer>
	);
};

export default AdminDrawer;
