import { yupResolver } from '@hookform/resolvers/yup';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	FormControl,
	FormLabel,
	IconButton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { closeDialog, getDialogData, isDialogOpen, openDialog } from 'containers/Marketplace/dialogSlice';
import { mergeFieldValues } from 'utils/misc/formUtils';

import RfpAttachmentAvatarInput from 'containers/Marketplace/attachment/RfpAttachmentAvatarInput';
import { StyledTooltip } from '../common/StyledTooltip';

type FieldValues = {
	teamMemberId: string | null;
	firstname: string | null;
	lastname: string | null;
	position: string | null;
	description: string | null;
	linkedInUrl: string | null;
	avatar: string | null;
};

type Props = {} & Omit<DialogProps, 'open'>;

const UpsertRfpProfileTeamMemberDialog: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const { formatMessage } = useIntl();

	const dialogOpen = useSelector(isDialogOpen('UpsertRfpProfileTeamMemberDialog'));
	const dialogData = useSelector(getDialogData('UpsertRfpProfileTeamMemberDialog'));

	const validationSchema = React.useMemo(
		() =>
			yup.object({
				firstname: yup.string().required(),
				lastname: yup.string().required(),
				description: yup.string().max(2000),
			}),
		[]
	);

	const defaultValues = React.useMemo(
		() => ({
			teamMemberId: null,
			firstname: '',
			lastname: '',
			position: '',
			description: '',
			linkedInUrl: '',
			avatar: null,
		}),
		[]
	);

	const {
		control,
		reset,
		handleSubmit,
		formState: { isDirty, isSubmitting },
	} = useForm<FieldValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const handleDialogClose = React.useCallback(() => {
		if (isDirty) {
			dispatch(
				openDialog({
					name: 'ConfirmCloseDialog',
					data: {
						onSubmit: () => {
							reset(defaultValues);
							dispatch(closeDialog({ name: 'UpsertRfpProfileTeamMemberDialog' }));
						},
					},
				})
			);
		} else {
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProfileTeamMemberDialog' }));
		}
	}, [dispatch, reset, isDirty, defaultValues]);

	const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
		async fieldValues => {
			await dialogData?.onSubmit(fieldValues);
			reset(defaultValues);
			dispatch(closeDialog({ name: 'UpsertRfpProfileTeamMemberDialog' }));
		},
		[dispatch, reset, defaultValues, dialogData]
	);

	React.useEffect(() => {
		if (!isNil(dialogData?.fieldValues)) {
			reset(mergeFieldValues(defaultValues, dialogData?.fieldValues), { keepDirtyValues: true });
		}
	}, [reset, defaultValues, dialogData]);

	return (
		<Dialog {...otherProps} maxWidth='xs' open={dialogOpen} onClose={handleDialogClose} fullWidth>
			<IconButton onClick={handleDialogClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
				<CloseIcon />
			</IconButton>
			<Box component='form' onSubmit={handleSubmit(handleFormSubmit)}>
				<DialogTitle>
					<FormattedMessage id='rfpProfileTeamMember.title' />
				</DialogTitle>
				<DialogContent>
					<Stack>
						<Controller
							name='avatar'
							control={control}
							render={({ field, fieldState }) => (
								<FormControl fullWidth>
									<FormLabel>
										<Typography gutterBottom variant='h4' sx={{ mt: '1rem' }}>
											<FormattedMessage id={`rfpProfileTeamMember.${field.name}.label`} />
										</Typography>
									</FormLabel>
									<RfpAttachmentAvatarInput {...field} AvatarProps={{ variant: 'logo1' }} />
								</FormControl>
							)}
						/>
						<Typography gutterBottom variant='h4' sx={{ mt: '1rem' }}>
							<FormattedMessage id='rfpProfileTeamMember.generalinfo.title' />
						</Typography>
						<Controller
							name='firstname'
							control={control}
							render={({ field, fieldState }) => (
								<TextField
									{...field}
									error={!isNil(fieldState.error)}
									label={formatMessage({ id: `rfpProfileTeamMember.${field.name}.label` })}
									placeholder={formatMessage({ id: `rfpProfileTeamMember.${field.name}.placeholder` })}
									helperText={fieldState.error?.message}
								/>
							)}
						/>
						<Controller
							name='lastname'
							control={control}
							render={({ field, fieldState }) => (
								<TextField
									{...field}
									error={!isNil(fieldState.error)}
									label={formatMessage({ id: `rfpProfileTeamMember.${field.name}.label` })}
									placeholder={formatMessage({ id: `rfpProfileTeamMember.${field.name}.placeholder` })}
									helperText={fieldState.error?.message}
								/>
							)}
						/>
						<Controller
							name='position'
							control={control}
							render={({ field, fieldState }) => (
								<TextField
									{...field}
									error={!isNil(fieldState.error)}
									label={formatMessage({ id: `rfpProfileTeamMember.${field.name}.label` })}
									placeholder={formatMessage({ id: `rfpProfileTeamMember.${field.name}.placeholder` })}
									helperText={fieldState.error?.message}
								/>
							)}
						/>
						<Controller
							name='description'
							control={control}
							render={({ field, fieldState }) => (
								<StyledTooltip title={formatMessage({ id: `rfpProfileTeamMember.${field.name}.placeholder` })}>
									<TextField
										{...field}
										label={formatMessage({ id: `rfpProfileTeamMember.${field.name}.label` })}
										placeholder={formatMessage({ id: `rfpProfileTeamMember.${field.name}.placeholder` })}
										error={!isNil(fieldState.error)}
										helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
										inputProps={{ maxLength: 2000 }}
										minRows={4}
										multiline
									/>
								</StyledTooltip>
							)}
						/>
						<Controller
							name='linkedInUrl'
							control={control}
							render={({ field, fieldState }) => (
								<TextField
									{...field}
									error={!isNil(fieldState.error)}
									label={formatMessage({ id: `rfpProfileTeamMember.${field.name}.label` })}
									placeholder={formatMessage({ id: `rfpProfileTeamMember.${field.name}.placeholder` })}
									helperText={fieldState.error?.message}
								/>
							)}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button variant='text' size='small' color='neutral' onClick={handleDialogClose}>
						<FormattedMessage id='rfpProfileTeamMember.cancel' />
					</Button>
					<LoadingButton type='submit' variant='contained' loading={isSubmitting}>
						<FormattedMessage id='rfpProfileTeamMember.submit' />
					</LoadingButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default UpsertRfpProfileTeamMemberDialog;
