import { Skeleton, Stack } from '@mui/material';

import Score from 'containers/Score';
import { useGetScenarioDataQuery, useGetScoreDataQuery } from 'containers/Stakeholder/stakeholderApi';
import HeaderTitle from 'views/Dashboard/HeaderTitle';
import DeminimisCounter, { DeminimisSkeleton } from '../DeminimisCounter/DeminimisCounter';
import Scenario from '../Scenario';

export const HeaderSkeleton = () => {
	return (
		<Stack sx={{ width: '100%' }} alignContent='center' alignItems='space-between' direction='column'>
			<Skeleton variant='rectangular' height={40} sx={{ width: '100%', mt: '2rem' }} />
			<Skeleton variant='rectangular' height={25} sx={{ width: '10rem', mt: '1rem', mb: '2rem' }} />
			<DeminimisSkeleton />
			<Skeleton variant='rectangular' height={400} sx={{ width: '100%', mt: '2rem' }} />
			<Stack direction='row' justifyContent='center' alignItems='center' spacing={2} sx={{ mt: '1rem' }}>
				<Skeleton variant='rectangular' height={50} sx={{ width: '10rem' }} />
				<Skeleton variant='rectangular' height={50} sx={{ width: '10rem' }} />
				<Skeleton variant='rectangular' height={50} sx={{ width: '10rem' }} />
				<Skeleton variant='rectangular' height={50} sx={{ width: '10rem' }} />
			</Stack>
			<Skeleton variant='rectangular' height={400} sx={{ width: '100%', mt: '2rem' }} />
			<Skeleton variant='rectangular' height={400} sx={{ width: '100%', mt: '2rem' }} />
		</Stack>
	);
};

const Header = ({ linkId, company, hideAnalysis, linkParts }) => {
	const { data, isFetching, isLoading } = useGetScoreDataQuery(linkId);

	const {
		data: scenarioData,
		isFetching: scenarioIsFetching,
		isLoading: scenarioIsLoading,
		/* isSuccess: scenarioIsSuccess, */
	} = useGetScenarioDataQuery(linkId);

	if (scenarioIsFetching || scenarioIsLoading || isFetching || isLoading) return null;

	const missingValues = scenarioData?.scenario?.missingValues;

	return (
		<>
			<Stack sx={{ width: '100%' }} alignContent='center' alignItems='center' direction='row'>
				<HeaderTitle company={company} sx={{ mt: '2rem', mb: '2rem', flexGrow: 1 }} />
				{linkParts?.includes('Scenario') && <Scenario linkId={linkId} />}
			</Stack>
			{linkParts?.includes('Projects') && <DeminimisCounter linkId={linkId} />}
			{linkParts?.includes('Score') && (
				<Stack sx={{ width: '100%', background: '#482e51' }} alignContent='center' alignItems='center' direction='column'>
					<Score
						analysisLinkUrl={`/stakeholder/${linkId}/analysis`}
						height={'27rem'}
						score={data?.score}
						loading={isLoading || isFetching || scenarioIsFetching || scenarioIsLoading}
						missingValues={missingValues}
						hideAnalysis={!hideAnalysis}
						profile={true}
					/>
				</Stack>
			)}
		</>
	);
};

export default Header;
