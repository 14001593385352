import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';

import TeamMemberAvatar from './TeamMemberAvatar';
import TeamMemberModal from '../TeamMemberModal';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '&:first-child': {
            //marginTop: 0,
            display: 'none'
        },
    },
    container: {
        '&:hover': {
            cursor: 'pointer',
            // transform: 'scale(1.02)',
            backgroundColor: theme.palette.secondary.grey,
        }
    },
    name: {
        display: 'inline-block',
        fontSize: '1.2rem',
        color: theme.palette.secondary.grey900,
        paddingRight: theme.spacing(0.5),
    },
    position: {
        fontSize: '0.9rem',
        color: theme.palette.secondary.grey700,
    },
    description: {
        fontSize: '0.9rem',
        marginTop: theme.spacing(0.5),
        color: theme.palette.secondary.grey600,
    },
    link: {
        display: 'block',
        fontSize: '0.9rem',
        marginTop: theme.spacing(0.5),
        color: theme.palette.secondary.grey600,
    },
}));

const TeamMemberCard = ({ teamMember, readonly }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Divider className={classes.divider} />
            <Grid container /* spacing={2} */ onClick={() => setOpen(true)} className={classes.container}>
                <Grid item sx={{ paddingRight: 2, paddingBottom: 1 }}>
                    <TeamMemberAvatar avatar={teamMember.avatar} firstname={teamMember.firstname} lastname={teamMember.lastname} />
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                    <Box>
                        <Typography className={classes.name}>{teamMember.firstname}</Typography>
                        <Typography className={classes.name}>{teamMember.lastname}</Typography>
                    </Box>
                    <Typography className={classes.position}>{teamMember.position}</Typography>
                    <Typography className={classes.description}>{teamMember.description}</Typography>
                    {teamMember.linkedInUrl &&
                        <Box onClick={event => event.stopPropagation()}>
                            <Link className={classes.link} href={teamMember.linkedInUrl} target='_blank'>LinkedIn Profile</Link>
                        </Box>
                    }
                </Grid>
            </Grid>
            {teamMember && <TeamMemberModal open={open} setOpen={setOpen} teamMember={teamMember} readonly={readonly} />}
        </>
    );
};

export default TeamMemberCard;
