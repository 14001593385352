import moment from 'moment';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import DataGrid from 'components/DataGrid';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => {
	const intl = useIntl();
	const history = useHistory();

	const getDate = time => {
		if (!time) return '';

		return moment(time).format('DD.MM.YYYY HH:mm');
	};

	if (data === null) return <div>null</div>;

	const columns = [
		{
			field: 'id',
			headerName: intl.formatMessage({ id: 'history.datagrid.id' }),
			width: 180,
			renderCell: params => (
				<Typography textAlign='center' sx={{ pl: 1 }}>
					{params.id}
				</Typography>
			),
		},
		{
			field: 'name',
			disableColumnMenu: true,
			sortable: false,
			filterable: false,
			headerName: intl.formatMessage({ id: 'history.datagrid.name' }),
			width: 450,
			renderCell: params => <Typography textAlign='center'>{params.row.company.name}</Typography>,
		},
		{
			field: 'time',
			disableColumnMenu: true,
			sortable: false,
			filterable: false,
			headerName: intl.formatMessage({ id: 'history.datagrid.time' }),
			width: 200,
			renderCell: params => <Typography textAlign='center'>{getDate(params.row.time)}</Typography>,
		},
	];

	return (
		<DataGrid
			useHoverStyles={true}
			getRowId={row => row.company.id}
			columns={columns}
			pageSize={50}
			disableSelectionOnClick
			idField='company.id'
			loading={false}
			data={data}
			paginationMode='client'
			onRowClick={row => {
				history.push(`/company/${row.id}`);
			}}
		/>
	);
};
