import React, { useState } from 'react';

import InfoItemModal from '../InfoItemModal';
import AddButton from 'components/Button/AddButton';

const InfoItemModalIgniter = ({ title, readonly }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            {!readonly && <AddButton sx={{ mt: 0.5 }} onClick={() => setOpen(true)}>{title}</AddButton>}
            <InfoItemModal open={open} setOpen={setOpen} infoItem={{ title: '', value: '' }} readonly={readonly} />
        </>
    );
};

export default InfoItemModalIgniter;
