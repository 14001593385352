import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

import IconBadged from 'components/IconBadged';

const ToggleCollaborationDrawerButton = ({ toggleDrawer, unreadConversationCount = 0, instructionBox, hasChat = false }) => {
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<IconButton
			aria-label='collaboration drawer'
			onClick={toggleDrawer}
			sx={{
				position: 'fixed',
				bottom: hasChat ? '1rem' : instructionBox && smUp ? '14.5rem' : '6rem',
				right: '1rem',
				color: 'primary.white',
				padding: '1rem',
				boxShadow: 'px 5px 18px 5px rgba(72, 46, 81, .5)',
				width: '4rem',
				height: '4rem',
				backgroundColor: 'primary.main',
				borderRadius: '4rem',
				textAlign: 'center',
				'&:hover': {
					backgroundColor: 'rgba(72, 46, 81, .9)',
					'& svg': {
						fill: 'primary.white',
					},
					boxShadow: '1px 1px 10px 1px rgba(72, 46, 81, .11)',
				},
				zIndex: 22,
			}}
		>
			<IconBadged badgeContent={unreadConversationCount} conversation={true}>
				<EditOutlinedIcon />
			</IconBadged>
		</IconButton>
	);
};

export default ToggleCollaborationDrawerButton;
