import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

import DataGrid from 'components/DataGrid';
import FormattedDate from 'components/Formatters/FormattedDate';

import { capitalizeFirstLetter } from 'utils/converters/capitalizeFirstLetter';

const ClaimsTable = ({ loading, data, classes }) => {
    const intl = useIntl();

    const columns = [
        {
            field: 'claimValue',
            headerName: intl.formatMessage({ id: 'userdetails.claim.claimValue' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>{params.row.claimValue}</span>
            ),
        },
        {
            field: 'claimType',
            headerName: intl.formatMessage({ id: 'userdetails.claim.claimType' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>{capitalizeFirstLetter(params.row.claimType)}</span>
            ),
        },
        {
            field: 'timestamp',
            type: 'date',
            headerName: intl.formatMessage({ id: 'userlist.table.timestamp' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    <FormattedDate value={params.row.timestamp} />
                </span>
            ),
        },
        {
            field: 'userId',
            headerName: intl.formatMessage({ id: 'userdetails.role.userId' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={classes.cell}>{params.row.userId}</span>
            ),
        }
    ];

    return (
        <>
            <Typography variant='h2' className={classes.title}>{intl.formatMessage({ id: 'userdetails.claims.title' })}</Typography>
            {
                loading || !loading && data?.length !== 0
                    ? <DataGrid
                        idField='rowKey'
                        loading={loading}
                        data={data}
                        columns={columns}
                        paginationMode='client'
                    />
                    : <Typography component='p'>{intl.formatMessage({ id: 'userdetails.no.claims' })}</Typography>
            }
        </>
    );
};

export default ClaimsTable;