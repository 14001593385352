import React from 'react';
import { useIntl } from 'react-intl';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/styles';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import CustomTooltip from 'components/CustomTooltip';
import DataGrid from 'components/DataGrid';
import ConfirmButton from 'components/Button/ConfirmButton';
import Button from 'components/Button/Button';
import FormattedDate from 'components/Formatters/FormattedDate';

const NoUsers = ({ intl, openUserModal }) => {
    const theme = useTheme();

    return (
        <Stack sx={{ width: '100%', background: theme.palette.secondary.light, p: '2rem' }} direction='column' spacing={4} alignItems='center' justifyContent='center'>
            <Box>{intl.formatMessage({ id: 'companydetails.no.companyUsers' })}</Box>
            <Button
                color='success'
                startIcon={<AddIcon size='small' />}
                onClick={() => openUserModal({})}
            >
                {intl.formatMessage({ id: 'user.form.add' })}
            </Button>
        </Stack>
    );
};

const CompanyUserTable = ({ loading, data, classes, fetchUserDetails = null, deactivateUser = null, openUserModal }) => {
    const intl = useIntl();

    const columns = [
        {
            field: 'username',
            headerName: intl.formatMessage({ id: 'companydetails.users.username' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <Button
                    size='small'
                    color='primary'
                    textCase='none'
                    onClick={() => fetchUserDetails(params.row.userContact?.grantedUsername)}
                >
                    {params.row.userContact?.grantedUsername}
                </Button>
            ),
        },
        {
            field: 'isActive',
            headerName: intl.formatMessage({ id: 'userlist.table.isActive' }),
            headerClassName: 'styled-header',
            width: 150,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.user.isActive ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            field: 'deactivateUser',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'companydetails.users.deactivate' }),
            headerClassName: 'styled-header',
            width: 200,
            renderHeader: () => (
                <Typography component='strong'>
                    {intl.formatMessage({ id: 'companydetails.users.deactivate' })}
                    <CustomTooltip
                        sx={{ fontSize: '0.5rem' }}
                        title={intl.formatMessage({ id: 'userlist.deactivate.tooltip' })}
                        placement='top'
                        enterTouchDelay={0}
                        leaveTouchDelay={2000}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 200 }}
                    >
                        <IconButton
                            disableRipple
                            className={classes.iconButton}
                        >
                            <HelpOutlineIcon />
                        </IconButton>
                    </CustomTooltip>
                </Typography>
            ),
            renderCell: params => (
                <ConfirmButton
                    size='small'
                    color='error'
                    startIcon={<PersonRemoveIcon size='small' />}
                    confirmAction={() => deactivateUser(params.row.user)}
                    confirmText={intl.formatMessage({ id: 'user.deactivate.confirm' })}
                    cancelText={intl.formatMessage({ id: 'user.deactivate.cancel' })}
                    confirmBodyText={intl.formatMessage({ id: 'user.deactivate.body' })}
                    buttonText={intl.formatMessage({ id: 'companydetails.users.deactivate' })}
                    disabled={!params.row.user.isActive}
                />
            ),
        },
        {
            field: 'firstname',
            headerName: intl.formatMessage({ id: 'companydetails.users.firstname' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.userContact?.firstname ? params.row.userContact?.firstname : ''}
                </span>
            ),
        },
        {
            field: 'lastname',
            headerName: intl.formatMessage({ id: 'companydetails.users.lastname' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.userContact?.lastname ? params.row.userContact?.lastname : ''}
                </span>
            ),
        },
        {
            field: 'created',
            type: 'date',
            headerName: intl.formatMessage({ id: 'customerlist.table.profile.creation.date' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.userContact?.created ? <FormattedDate value={params.row.userContact?.created} /> : ''}
                </span>
            ),
        },
        {
            field: 'phoneNumber',
            headerName: intl.formatMessage({ id: 'userlist.table.phoneNumber' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.userContact?.phonenumber ? params.row.userContact?.phonenumber : ''}
                </span>
            ),
        },
        {
            field: 'postalCode',
            headerName: intl.formatMessage({ id: 'companydetails.users.postalCode' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.userContact?.postalcode ? params.row.userContact?.postalcode : ''}
                </span>
            ),
        },
        {
            field: 'streetAddress',
            headerName: intl.formatMessage({ id: 'companydetails.users.streetAddress' }),
            headerClassName: 'styled-header',
            width: 200,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.userContact?.streetAddress ? params.row.userContact?.streetAddress : ''}
                </span>
            ),
        },
        {
            field: 'email',
            headerName: intl.formatMessage({ id: 'userlist.table.email' }),
            headerClassName: 'styled-header',
            width: 350,
            renderCell: params => (
                <span className={classes.cell}>
                    {params.row.userContact?.email ? params.row.userContact?.email : ''}
                </span>
            ),
        }
    ];
    return (
        <Box sx={{ mt: 1 }}>
            {
                loading || !loading && data?.length !== 0
                    ? <DataGrid
                        idField='rowKey'
                        loading={loading}
                        data={data}
                        columns={columns}
                        paginationMode='client'
                    /> : <NoUsers {...{ intl, openUserModal }} />
            }
        </Box>
    );
};

export default CompanyUserTable;