import { useIntl } from 'react-intl';

import { Box, Divider, Typography } from '@mui/material';

import htmlParser from 'components/HtmlParser';
import PanelTitle from 'components/Titles/PanelTitle';
import MainSection from 'views/FinancialPlanPage/FinancialPlan/MainContent/MainSection';

const FinancialPlanProjectList = ({ projects, instruments }) => {
	const intl = useIntl();

	const InstrumentItem = (instrument, lastItem) => {
		if (!instrument || !instrument.id) return null;

		const description = instrument?.description?.replace(/(<p>&nbsp;<\/p>)+/g, '');

		return (
			<Box sx={{ mt: '2rem' }} key={instrument?.id + instrument?.endDate}>
				<PanelTitle sx={{ my: '2rem' }}>
					{instrument?.provider} - {instrument?.name}
				</PanelTitle>
				<Typography component='div'>{htmlParser(description)}</Typography>
				{!lastItem && <Divider sx={{ mb: '2rem', mt: '2rem' }} />}
			</Box>
		);
	};

	const generateProjectList = projects => {
		try {
			if (!projects) return null;

			let projectInstruments = projects
				.filter(project => {
					const instrument = instruments?.find(item => item.id === project.instrumentId);

					if (!instrument) return false;

					return true;
				})
				.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp))
				.map((project, index) => {
					const instrument = instruments?.find(item => item.id === project.instrumentId);

					if (!instrument) return null;

					return { ...instrument, sort: index };
				});

			if (!projectInstruments || projectInstruments.length === 0) {
				return null;
			}

			let filteredArr = projectInstruments.reduce((acc, current) => {
				if (!acc) return acc.concat([current]);

				const x = acc.find(item => item?.id === current?.id);
				if (!x) {
					return acc.concat([current]);
				} else {
					return acc;
				}
			}, []);

			return filteredArr
				.sort((x, y) => y.sort - x.sort)
				.map((instrument, index) => InstrumentItem(instrument, filteredArr?.length === index + 1));
		} catch {
			// ignore exceptions
		}
	};

	if (!projects || !instruments) return null;

	const projectList = generateProjectList(projects);

	if (!projectList) return null;

	return (
		<MainSection id='instruments'>
			<PanelTitle id='instruments' className={'tocTitle'}>
				{intl.formatMessage({ id: 'financial.plan.instrumentDescriptions' })}
			</PanelTitle>
			{projectList}
		</MainSection>
	);
};

export default FinancialPlanProjectList;
