import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const ProfileStepper = ({ loading, activeStep, steps, completeProfile, isProjects, openProfileModal, openProjectModal }) => {
    const Icon = ({ index, completeProfile, isProjects }) => {
        if (loading) return <HourglassBottomIcon color='primary' />;
        else if (index === 0 && completeProfile) return <CheckCircleIcon color='primary' />;
        else if (index === 1 && isProjects) return <CheckCircleIcon color='primary' />;
        else return <CircleIcon color='primary' />;
    };

    const openModal = index => index === 0 ? openProfileModal() : index === 1 ? openProjectModal() : null;

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepButton
                                disabled={loading || index === 0 && completeProfile || index === 1 && isProjects}
                                disableRipple={true}
                                icon={<Icon index={index} completeProfile={completeProfile} isProjects={isProjects} />}
                                sx={{
                                    '&>span:hover': {
                                        transform: 'scale(1.05)',
                                    }
                                }}
                                onClick={() => openModal(index)}
                            >
                                <Typography variant='inherit' color='primary'>
                                    {label}
                                </Typography>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
};

export default ProfileStepper;