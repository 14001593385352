import React from 'react';
import { Box } from '@mui/material';

export default function ({ children, component = 'div', sx }) {
    return (
        <Box sx={{
            py: 1,
            px: 3,
            maxWidth: 'xl',
            '& p': {
                // textAlign: 'justify',
                color: 'primary.main'
            },
            color: 'primary.main',
            ...sx
        }}
            component={component}
        >
            {children}
        </Box>
    );
}