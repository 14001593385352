import React from 'react';
import { useSelector } from 'react-redux';

import { useInjectReducer } from 'utils/injectors/injectReducer';
import { stakeholderApi } from '../stakeholderApi';
import SharedLinkList from './SharedLinkList';
import ContentContainer from 'components/Containers/ContentContainer';

const SharedLinks = () => {
    useInjectReducer({ key: stakeholderApi.reducerPath, reducer: stakeholderApi.reducer }); // async process, wait until loaded the state

    const profile = useSelector(state => state.stakeholder);

    if (!profile) return null;

    return (
        <ContentContainer>
            <SharedLinkList />
        </ContentContainer>
    );
};

export default SharedLinks;