import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';

import PageSpinner from 'components/Spinner/PageSpinner';
import NotificationCenter from './NotificationCenter';
import { usePollMessagesQuery, useMarkAsReadMutation, pollingInterval } from './api';

const Messages = ({ companyLoading, closeMessagesDrawer }) => {
    const intl = useIntl();

    const { data: messages, loading: messagesLoading } = usePollMessagesQuery(
        'messages',
        {
            pollingInterval,
        }
    );

    // eslint-disable-next-line no-empty-pattern
    const [markAsRead, { }] = useMarkAsReadMutation();

    const markMessageAsRead = messageId => markAsRead(messageId);

    if (messages === undefined || messages === null) return <Typography align='center'>{intl.formatMessage({ id: 'common.rendering.problem' })}</Typography>;
    if (companyLoading || messagesLoading) return <PageSpinner />;

    return (
        <NotificationCenter {...{ messages, markMessageAsRead, closeMessagesDrawer }} />
    );
};

export default Messages;
