import React from 'react';
import { useIntl } from 'react-intl';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Grid from '@mui/material/Grid';
import { withStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        backgroundColor: theme.palette.primary.white,
    },
    link: {
        fontSize: '0.75rem',
        padding: theme.spacing(1),
    },
    icon: {
        padding: theme.spacing(1),
    },
}));

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.secondary,
        color: theme.palette.primary.main,
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const CustomPopover = ({ open, anchorEl, handleClose, link, copiedToClipboard, }) => {
    const classes = useStyles();
    const intl = useIntl();

    const url = window.origin + '/profile/' + link;

    const copyUrl = () => {
        navigator.clipboard.writeText(url);
        copiedToClipboard();
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            classes={{
                paper: classes.popoverPaper,
            }}
        >
            <Grid container alignItems='center'>
                <Grid item>
                    <Typography className={classes.link}>{url}</Typography>
                </Grid>
                <Grid item>
                    <LightTooltip title={intl.formatMessage({ id: 'customerlist.popover.copy.url' })} placement='top'>
                        <IconButton onClick={copyUrl} className={classes.icon}>
                            <FileCopyIcon fontSize='small' color='primary' />
                        </IconButton>
                    </LightTooltip>
                </Grid>
            </Grid>
        </Popover>
    );
};

export default CustomPopover;
