// regex level path/:id
export const regexInstrument = /\/instruments\/(.*)/;
export const regexProject = /\/projects\/(.*)/;
export const regexProjects = /\/projects/;
export const regexCompany = /\/company\/(.*)/;
export const regexProfile = /\/profile\/(.*)/;
export const regexAdmin = /\/admin\/(.*)/;

// string simple path --> TODO: replace string to variables in all files?!
export const searchPath = '/search';
export const customersPath = '/customers';
export const financialplanPath = '/financialplan';
export const messagesPath = '/messages';
export const instrumentsPath = '/instruments';
export const licenseexpiredPath = '/license_expired';
export const userPath = '/user';

// other
export const notEmptyHtmlOrString = /^(?!(<p><\/p>\n|)$)/;
export const numberWithDecimals = /^([0-9]+.?[0-9]*|.[0-9]+)$/;
