import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Stack from '@mui/material/Stack';

import ModalDialog from 'components/Dialog/ModalDialog';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import Button from 'components/Button/Button';
import CompanySearch from 'containers/CompanySearch';
import TransferList from './TransferList';
import ButtonSpinner from 'components/Button/ButtonSpinner';

const DataMigrationModal = ({ open, setOpen, keys, migrateData, loading }) => {
	const customers = useSelector(state => state.customers);
	const data = customers?.customers?.data;
	const intl = useIntl();

	const [option, setOption] = useState(null);
	const [selected, setSelected] = useState([]);

	return (
		<ModalDialog
			onClose={() => {
				setOpen(false);
				setOption(null);
			}}
			open={open}
			title={intl.formatMessage({ id: 'companylist.table.companyDetails.data.migration' })}
			actions={
				<LeftRightContainer
					left={
						<Button variant='text' onClick={() => setOpen(false)}>
							{intl.formatMessage({ id: 'common.close' })}
						</Button>
					}
					right={
						<Button
							color='primary'
							disabled={selected.length === 0 || loading}
							onClick={() => {
								migrateData(option?.partitionKey, selected);
								setOption(null);
							}}
						>
							{intl.formatMessage({ id: 'companylist.table.companyDetails.migrate' })}
							{loading && <ButtonSpinner />}
						</Button>
					}
				/>
			}
		>
			<Stack direction='column' spacing={2} sx={{ p: 3 }}>
				<CompanySearch customers={data ?? []} passOption={option => setOption(option)} />
				<TransferList keys={keys} option={option} passSelected={selected => setSelected(selected)} />
			</Stack>
		</ModalDialog>
	);
};

export default DataMigrationModal;
