export const companyForms = [
	{ key: 'OY', value: 'Osakeyhtiö' },
	{ key: 'OYJ', value: 'Julkinen osakeyhtiö' },
	{ key: 'TMI', value: 'Yksityinen elinkeinonharjoittaja' },
	{ key: 'AY', value: 'Avoin yhtiö' },
	{ key: 'KY', value: 'Kommandiittiyhtiö' },
	{ key: 'RY', value: 'Rekisteröity yhdistys' },
	{ key: 'OSK', value: 'Osuuskunta' },
	{ key: 'MHY', value: 'Metsänhoitoyhdistys' },
];
