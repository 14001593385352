import AssignmentIcon from '@mui/icons-material/Assignment';
import Box from '@mui/material/Box';
import { forwardRef, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/Button/Button';
import ButtonSpinner from 'components/Button/ButtonSpinner';
import ConfirmPopper from 'components/ConfirmDialog/ConfirmPopper';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import ModalDialog from 'components/Dialog/ModalDialog';
import FormatCurrency from 'components/Formatters/FormatCurrency';
import KanbanCard from 'components/Kanban/KanbanCard';
import { deleteCost, updateCost } from 'containers/Projects/slices';
import ProjectCostForm from '../../ProjectCostForm';

import { calculateExpenseAmount } from 'containers/Projects/Project/Costs/ProjectCostForm/ProjectCostForm';

const ProjectCostCard = forwardRef(({ cost, costId, taskId, taskName, projectId, isTaskPage, ...other }, ref) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const formRef = useRef();

	const getCostAmount = cost => {
		switch (cost?.phase) {
			case 'todo':
				return cost?.amountApplied + calculateExpenseAmount(cost.salaryExpenseType, cost.salaryExpense, cost?.amountApplied);
			case 'approved':
				return cost?.amountGranted + calculateExpenseAmount(cost.salaryExpenseType, cost.salaryExpense, cost?.amountGranted);
			case 'reported':
				return cost?.amountReported + calculateExpenseAmount(cost.salaryExpenseType, cost.salaryExpense, cost?.amountReported);
			case 'paid':
				return cost?.amountPaid + calculateExpenseAmount(cost.salaryExpenseType, cost.salaryExpense, cost?.amountPaid);
			default:
				return cost?.amountApplied + calculateExpenseAmount(cost.salaryExpenseType, cost.salaryExpense, cost?.amountApplied);
		}
	};

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = hideUnsavedConfirm => {
		if (isDirty && !hideUnsavedConfirm) {
			setIsUnsavedConfirmOpen(true);
		} else {
			setOpen(false);
		}
	};

	const [loading, setLoading] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [dirtyFields, setDirtyFields] = useState({});

	const onSubmit = async data => {
		setLoading(true);
		await handleUpdate(data);
		setLoading(false);
	};

	const handleUpdate = async data => {
		try {
			if (data?.id !== '') {
				await dispatch(updateCost(data.id, data));
			}

			if (handleClose) handleClose(true);

			toast.success(intl.formatMessage({ id: 'project.cost.form.success' }));
		} catch (err) {
			console.error(err);
			toast.error(intl.formatMessage({ id: 'project.cost.form.fail' }));
		}
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const confirm = e => {
		setConfirmOpen(true);
		setAnchorEl(e.currentTarget);
	};

	const closeConfirm = () => {
		setAnchorEl(null);
		setConfirmOpen(false);
	};

	const handleDelete = async () => {
		try {
			await dispatch(deleteCost(cost.id, cost.projectId));
			toast.success(intl.formatMessage({ id: 'project.cost.delete.success' }));
		} catch (err) {
			console.error(err);
			toast.error(intl.formatMessage({ id: 'project.cost.delete.fail' }));
		}
	};

	const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const [isUnsavedConfirmOpen, setIsUnsavedConfirmOpen] = useState(false);
	const closeUnsavedConfirm = () => setIsUnsavedConfirmOpen(false);
	const closeUnsavedConfirmAndModal = () => {
		setIsUnsavedConfirmOpen(false);
		setOpen(false);
	};

	return (
		<Box ref={ref} sx={{ outline: 'none', mb: '.5rem', mx: '.5rem' }} {...other}>
			<KanbanCard
				title={cost.name}
				tagContent={intl.formatMessage({ id: `project.cost.categories.${cost.category}` })}
				cardMain={cost.name}
				link={`projects/${projectId}/${taskId}/${costId}`}
				onClick={handleOpen}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'nowrap',
						width: '100%',
						mt: '.5rem',
						'& span': {
							ml: '.5rem',
							top: '.2rem',
						},
						'& span:last-child': {
							ml: 'auto',
						},
					}}
				>
					<AssignmentIcon fontSize='small' />
					<span
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							marginRight: '0.25rem',
						}}
					>
						{taskName}
					</span>
					<span>
						<FormatCurrency value={getCostAmount(cost)} />
					</span>
				</Box>
			</KanbanCard>
			<ModalDialog
				onClose={() => handleClose(false)}
				open={open}
				title={cost?.name}
				actions={
					<LeftRightContainer
						left={<Button onClick={e => confirm(e)}>{intl.formatMessage({ id: 'project.cost.delete' })}</Button>}
						right={
							<Button disabled={loading || !isDirty} color='success' onClick={submitForm}>
								{intl.formatMessage({ id: 'shared.save' })}
								{loading && <ButtonSpinner />}
							</Button>
						}
					/>
				}
			>
				<ProjectCostForm
					ref={formRef}
					cost={cost}
					onSubmit={onSubmit}
					passIsDirty={(isDirty, dirtyFields) => {
						setIsDirty(isDirty);
						setDirtyFields(dirtyFields);
					}}
					isTaskPage={isTaskPage}
				/>
				<ConfirmUnsaved
					open={isUnsavedConfirmOpen}
					handleCancel={closeUnsavedConfirm}
					handleConfirm={closeUnsavedConfirmAndModal}
					dirtyFields={dirtyFields}
					translation='project.cost.form'
				/>
				<ConfirmPopper
					confirmAction={handleDelete}
					handleClose={closeConfirm}
					isopen={confirmOpen}
					anchorElement={anchorEl}
					confirmText={intl.formatMessage({ id: 'project.cost.delete.confirmText' })}
					cancelText={intl.formatMessage({ id: 'project.cost.delete.cancelText' })}
					confirmBodyText={intl.formatMessage({ id: 'project.cost.delete.confirmBodyText' })}
				/>
			</ModalDialog>
		</Box>
	);
});

export default ProjectCostCard;
