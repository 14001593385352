import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import 'moment/locale/fi';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import AdminContentContainer from 'components/Containers/AdminContentContainer';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import FormatCurrency from 'components/Formatters/FormatCurrency';
import StyledChip from 'components/Tags/StyledChip';

import getCompanyLink from 'utils/auth/getCompanyLink';

const useStyles = makeStyles(() => ({
	croppedContainer: {
		maxWidth: '100%',
	},
	cropped: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

export const getTimePassed = timestamp => {
	moment.locale('fi');
	const date = moment(timestamp);

	return date.fromNow();
};

const ProjectCard = ({ projectId, projectName, projectState, projectSize, timestamp, projectDescription, instrumentName, instrumentProvider, amountToApply }) => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();

	const link = getCompanyLink(`/projects/${projectId}`, location);
	const minHeight1Row = '1.5rem';
	const minHeight2Rows = '5.35rem';

	return (
		<Card
			sx={{
				borderRadius: 0,
				boxShadow: 'none',
				'&:hover': {
					cursor: 'pointer',
					backgroundColor: 'rgba(255,255,255,0.6)',
				},
			}}
			onClick={() => history.push(link)}
		>
			<Box sx={{ p: 2, px: 3, minHeight: minHeight2Rows }}>
				<LeftRightContainer
					left={projectName}
					right={
						projectState?.toLowerCase() === 'draft' && (
							<AdminContentContainer>
								<StyledChip
									label={<FormattedMessage id={`project.state.${projectState?.toLowerCase() ?? 'published'}`} />}
								/>
							</AdminContentContainer>
						)
					}
					cropped={true}
				/>
				<Typography component='div' color='textSecondary' variant='body2'>
					<FormattedMessage id='project.updated' />
					{' '}
					{getTimePassed(timestamp)}
				</Typography>
			</Box>
			<Box sx={{ px: 3, minHeight: minHeight1Row }} className={classes.croppedContainer}>
				<Typography color='textSecondary' variant='body2' className={classes.cropped}>
					{projectDescription}
				</Typography>
			</Box>
			<Box sx={{ px: 3, py: 2, mb: 1, minHeight: minHeight2Rows }} className={classes.croppedContainer}>
				<Typography color='textPrimary' variant='subtitle2' className={classes.cropped}>
					{instrumentName} - {instrumentProvider ?? 'Oma'}
				</Typography>
				<Typography color='textSecondary' variant='body2' className={classes.cropped}>
					<FormattedMessage id='project.instrument' />
				</Typography>
			</Box>
			<Divider />
			<Box sx={{ alignItems: 'center', display: 'flex', pl: 2, pr: 3, py: 2, px: 3 }}>
				<Grid alignItems='center' container justifyContent='space-between' spacing={3}>
					<Grid item>
						<Typography color='textPrimary' variant='subtitle2' className={classes.cropped}>
							<FormatCurrency value={projectSize} />
						</Typography>
						<Typography color='textSecondary' variant='body2' className={classes.cropped}>
							<FormattedMessage id='project.size' />
						</Typography>
					</Grid>
					<Grid item>
						<Typography color='textPrimary' variant='subtitle2' className={classes.cropped}>
							<FormatCurrency value={amountToApply} />
						</Typography>
						<Typography color='textSecondary' variant='body2' className={classes.cropped}>
							<FormattedMessage id='project.supportAmount' />
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Card>
	);
};

export default ProjectCard;
