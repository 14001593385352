import React from 'react';
import { useIntl } from 'react-intl';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { makeStyles } from '@mui/styles';

import Button from 'components/Button/Button';
import ChipItem from './Chip/Chip';

const useStyles = makeStyles(theme => ({
    list: {
        [theme.breakpoints.up('smd')]: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    },
}));

const ChipList = ({
    label,
    filterList,
    uniqueItems,
    clicked,
    setClicked,
    all,
    setAll,
    sx = {},
}) => {
    const classes = useStyles();
    const intl = useIntl();

    const toggleOne = item => {
        const updatedItems = clicked?.includes(item) ? clicked?.filter(elem => elem !== item) : [...clicked, item];

        setClicked(updatedItems);
        filterList(updatedItems, uniqueItems);

        if (updatedItems.length !== uniqueItems.length) {
            setAll(false);
        } else {
            setAll(true);
        }
    };

    const toggleAll = () => {
        if (all) {
            setClicked([]);
            filterList([], uniqueItems);
            setAll(false);
        } else {
            setClicked(uniqueItems);
            filterList(uniqueItems, uniqueItems);
            setAll(true);
        }
    };

    return (
        uniqueItems?.length > 0 &&
        <>
            {label && (
                <Stack direction='row' alignItems='center' spacing={1} sx={{ ...sx }}>
                    <Typography
                        sx={{
                            paddingLeft: '0.5rem',
                            fontWeight: 600,
                            color: 'primary.main',
                        }}
                    >
                        {intl.formatMessage({ id: `check.item.${label}` })}:
                    </Typography>
                    <Button
                        variant='text'
                        startIcon={all ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                        textCase='none'
                        onClick={() => toggleAll(label)}
                    >
                        {intl.formatMessage({ id: 'button.choose.all' })}
                    </Button>
                </Stack>
            )}
            <Box
                className={classes.list}
                sx={{
                    mt: 1,
                    p: 0,
                }}
            >
                {uniqueItems?.map(item => {
                    return (
                        <ChipItem key={item} {...{ item, label, toggleOne, clicked, }} />
                    );
                })}
            </Box>
        </>
    );
};

export default ChipList;
