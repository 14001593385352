import * as React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { FormattedNumber } from 'react-intl';
import { isEqual } from 'lodash';

type Props<C extends React.ElementType> = TypographyProps<C, { component?: C }> & {
	totalPrice?: number | null;
	totalPriceMax?: number | null;
};

function RfpProposalItemCostTotalPrice<C extends React.ElementType>({ totalPrice, totalPriceMax, ...otherProps }: Props<C>) {
	return (
		<Typography {...otherProps}>
			<FormattedNumber value={totalPrice || 0} currency='EUR' style='currency' />
			{isEqual(totalPrice, totalPriceMax) ? null : (
				<>
					<Typography component='span' sx={{ mx: 0.5 }}>
						-
					</Typography>
					<FormattedNumber value={totalPriceMax || 0} currency='EUR' style='currency' />
				</>
			)}
		</Typography>
	);
}

export default RfpProposalItemCostTotalPrice;
