import { compact, concat, filter, includes, isNil, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Badge, Box, BoxProps, Skeleton, Tab, Typography } from '@mui/material';

import { PaginationToken } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';

import { useGetPublicProfilesQuery } from 'containers/Marketplace/rfpProfileApi';
import { useFetchRfpProposalsQuery } from 'containers/Marketplace/rfpProposalApi';
import { useFetchPublishedRfpRequestsQuery, useFetchRfpRequestsQuery } from 'containers/Marketplace/rfpRequestApi';

import IsMarketplaceProvider, { hasMarketplaceProviderFeatureTest } from 'containers/Marketplace/common/IsMarketplaceProvider';
import PublishedRfpRequestList from 'containers/Marketplace/request/List/PublishedRfpRequestList';
import RfpRequestList from 'containers/Marketplace/request/List/RfpRequestList';
import getCompanyLink from '../../../utils/auth/getCompanyLink';
import PublicProfileList from '../profile/PublicProfileListing/PublicProfileList';

type Props = BoxProps & {};

const ListTabs = {
	Profiles: 'providers',
	Published: 'requests',
	Personal: 'my-requests',
	Proposals: 'proposals',
};

const RfpListTabs: React.FC<Props> = ({ ...otherProps }) => {
	const { formatMessage } = useIntl();
	const location = useLocation();

	// @ts-ignore
	const company = useSelector(state => state.admin);
	const isProvider = hasMarketplaceProviderFeatureTest(company, false);

	// @ts-ignore
	const { tabId } = useParams();

	React.useEffect(() => {
		setActiveTab(tabId ?? ListTabs.Profiles);
	}, [tabId]);

	const [activeTab, setActiveTab] = React.useState(ListTabs.Profiles);
	const [fetchToken] = React.useState<PaginationToken>({ take: 1000, nextPartitionKey: null, nextRowKey: null });

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const { data: rfpProfiles, isLoading: isPublicProfilesLoading } = useGetPublicProfilesQuery({ token: fetchToken });
	const { data: publishedRfpRequests, isLoading: isPublishedRfpRequestsLoading } = useFetchPublishedRfpRequestsQuery(
		{ companyId },
		{ skip: isNil(companyId) || !isProvider }
	);

	const { data: rfpRequests, isLoading: isRfpRequestsLoading } = useFetchRfpRequestsQuery({ companyId }, { skip: isNil(companyId) });
	const { data: rfpProposals, isLoading: isRfpProposalsLoading } = useFetchRfpProposalsQuery({ companyId }, { skip: isNil(companyId) });

	const rfpRequestCount = React.useMemo(() => {
		return compact(concat([], rfpRequests?.drafts, rfpRequests?.published)).length;
	}, [rfpRequests]);

	const rfpProposalCount = React.useMemo(() => {
		return filter(compact(concat([], rfpProposals?.drafts, rfpProposals?.published)), rfpProposal => {
			return includes(map(publishedRfpRequests, 'rfpRequestId'), rfpProposal?.requestId);
		}).length;
	}, [publishedRfpRequests, rfpProposals]);

	const isLoading = isPublicProfilesLoading || isPublishedRfpRequestsLoading || isRfpRequestsLoading || isRfpProposalsLoading;

	return (
		<Box {...otherProps}>
			<TabContext value={activeTab}>
				{isLoading ? (
					<Skeleton width={400} height={50} />
				) : (
					<IsMarketplaceProvider>
						{isMarketplaceProvider => (
							<TabList
								onChange={(event, tab) => setActiveTab(tab)}
								variant='scrollable'
								scrollButtons='auto'
								aria-label={formatMessage({ id: 'rfpListTabs.label' })}
							>
								<Tab
									component={Link}
									to={getCompanyLink('/marketplace/providers', location)}
									value={ListTabs.Profiles}
									label={
										<Badge badgeContent={rfpProfiles?.data?.length}>
											<Typography sx={{ fontWeight: 'inherit', paddingRight: 2 }}>
												<FormattedMessage id='rfpListTabs.publicRfpProfiles.label' />
											</Typography>
										</Badge>
									}
								/>
								<Tab
									component={Link}
									to={getCompanyLink('/marketplace/requests', location)}
									value={ListTabs.Published}
									sx={{ display: isMarketplaceProvider ? 'inline-flex' : 'none' }}
									label={
										<Badge badgeContent={publishedRfpRequests?.length}>
											<Typography sx={{ fontWeight: 'inherit', paddingRight: 2 }}>
												<FormattedMessage id='rfpListTabs.publishedRfpRequests.label' />
											</Typography>
										</Badge>
									}
								/>
								<Tab
									component={Link}
									to={getCompanyLink('/marketplace/my-requests', location)}
									value={ListTabs.Personal}
									label={
										<Badge badgeContent={rfpRequestCount}>
											<Typography sx={{ fontWeight: 'inherit', paddingRight: 2 }}>
												<FormattedMessage id='rfpListTabs.rfpRequests.label' />
											</Typography>
										</Badge>
									}
								/>
								<Tab
									component={Link}
									to={getCompanyLink('/marketplace/proposals', location)}
									value={ListTabs.Proposals}
									sx={{ display: isMarketplaceProvider ? 'inline-flex' : 'none' }}
									label={
										<Badge badgeContent={rfpProposalCount}>
											<Typography sx={{ fontWeight: 'inherit', paddingRight: 2 }}>
												<FormattedMessage id='rfpListTabs.rfpProposals.label' />
											</Typography>
										</Badge>
									}
								/>
							</TabList>
						)}
					</IsMarketplaceProvider>
				)}
				<TabPanel value={ListTabs.Profiles}>
					<PublicProfileList />
				</TabPanel>
				<TabPanel value={ListTabs.Published}>
					<PublishedRfpRequestList />
				</TabPanel>
				<TabPanel value={ListTabs.Personal}>
					<RfpRequestList />
				</TabPanel>
				<TabPanel value={ListTabs.Proposals}>
					<PublishedRfpRequestList filterByCompanyProposals={true} />
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export default RfpListTabs;
