import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import ContentContainer from 'components/Containers/ContentContainer';
import TextContainer from 'components/Containers/TextContainer';
import MainTitle from 'components/Titles/MainTitle';
import Subtitle from 'components/Titles/Subtitle';
import InstrumentItem from 'containers/Instruments/InstrumentList/InstrumentItem';
import InstrumentRadar from '../InstrumentRadar';
import InstrumentRadarFreemium from '../InstrumentRadarFreemium';
import InstrumentToChat from '../InstrumentToChat';
import BackLink from './BackLink';
import InstrumentAttributes from './InstrumentAttributes';
import InstrumentHeader from './InstrumentHeader';
import NoValid from './NoValid';

import { getCompanyId, isAdvancedToken, isFreemiumToken } from 'utils/auth/company';
import { isAdminToken } from 'utils/auth/token';

import Button from 'components/Button/Button';
import AdminContentContainer from 'components/Containers/AdminContentContainer';
import htmlParser from 'components/HtmlParser';
import InstrumentProviderLink from 'containers/Instruments/InstrumentList/InstrumentItem/InstrumentProviderLink/InstrumentProviderLink';
import { useDispatch } from 'react-redux';
import InstrumentAnalysis from '../../../InstrumentAIAnalysis/InstrumentAnalysis';
import InstrumentRatings from './InstrumentRatings/InstrumentRatings';

const useStyles = makeStyles((theme: any) => ({
	radarWrapper: {
		display: 'flex',
		justifyContent: 'center',
	},
	noValidContainer: {
		marginTop: '3rem',
	},
	attributesContainer: {
		marginTop: '2rem',
	},
	attributesTitle: {
		fontSize: '1.4rem',
		fontWeight: 600,
	},
	attributesText: {
		marginTop: '1rem',
	},
	ingress: {
		fontSize: '1rem',
	},
	url: {
		fontSize: '1.1rem',
		margin: '1rem 0',
	},
	link: {
		color: theme.palette.success.main,
		'&:hover': {
			color: theme.palette.success.main,
			opacity: 0.8,
		},
	},
}));

const Instrument: React.FC<any> = forwardRef(
	({ instrument, instruments, currentInstrumentId, company, radarSize, profile, closeInstrumentDrawer }, radarContainerRef) => {
		const classes = useStyles();
		const isAdvanced = isAdvancedToken();
		const isAdmin = isAdminToken();
		const isFreemium = isFreemiumToken();
		const { state }: any = useLocation();
		const dispatch = useDispatch();
		const companyId = getCompanyId();

		const id = instrument?.id;
		const name = instrument?.name;
		const description = instrument?.description?.replace(/(<p>&nbsp;<\/p>)+/g, '');
		const limitators = instrument?.limitators;
		const provider = instrument?.provider;
		const url = instrument?.url;
		const projectTags = [].concat(instrument?.projectAimArr ?? [], instrument?.projectObjectsArr ?? []);

		const projectValueArr = instrument?.projectValueArr;

		const noValidLimitators = limitators?.filter(({ isValid }: { isValid: boolean }) => !isValid);
		const noAttributesToRender =
			!instrument?.isDeminimis &&
			!instrument?.projectSizeEur &&
			!instrument?.expectedPendingTime &&
			!instrument?.amountMinPercentage &&
			!instrument?.amountMaxPercentage &&
			!instrument?.minAmount &&
			!instrument?.maxAmount &&
			!instrument?.prepayment &&
			!instrument?.timeFrame &&
			!instrument?.installmentsFreeTime;

		const selectInstrument = (id: string) => {
			return instruments?.find((item: any) => item.id === id);
		};

		return (
			<>
				<InstrumentHeader
					name={name}
					provider={provider}
					projectTags={projectTags}
					projectValueArr={projectValueArr}
					profile={profile}
					closeInstrumentDrawer={closeInstrumentDrawer}
				/>
				{state?.pathname && <BackLink pathname={state?.pathname} name={state?.name} />}
				{/* @ts-ignore */}
				<ContentContainer>
					<Grid container spacing={4}>
						<Grid item xs={12} md={4} xl={3}>
							<Box ref={radarContainerRef} sx={{ position: 'relative' }}>
								<Box className={classes.radarWrapper}>
									{isFreemium ? (
										<InstrumentRadarFreemium
											instrument={instrument}
											name={name}
											companyName={company?.name}
											radarSize={radarSize}
											smDown={undefined}
										/>
									) : (
										<InstrumentRadar
											instrument={instrument}
											data={limitators}
											name={name}
											companyName={company?.name}
											radarSize={radarSize}
											smDown={undefined}
										/>
									)}
								</Box>
								{isFreemium && (
									<Box
										sx={{
											position: 'absolute',
											top: 0,
											right: 0,
											bottom: 0,
											left: 0,
											width: '100%',
											height: `${radarSize + 25}px`,
											backgroundColor: 'rgba(255, 255, 255, 0.6)',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<Typography sx={{ textAlign: 'center', p: '1rem' }}>
											<FormattedMessage id='instrument.radar.upgrade.freemium' />
										</Typography>
										{/* @ts-ignore */}
										<Button
											variant='contained'
											color='primary'
											href='https://grants.fi/maksuton-konsultaatio-tukirahoituksesta/'
											target='_blank'
											sx={{
												textTransform: 'none', // this will keep the button text case as it is - example: do not uppercase
											}}
										>
											<FormattedMessage id='freemium.upgrade.card.button' />
										</Button>
									</Box>
								)}
							</Box>
							{!isFreemium && noValidLimitators?.length > 0 && (
								<div className={classes.noValidContainer}>
									{noValidLimitators.map(({ compareProperty, requiredValue, comparerStringType }: any) => {
										if (requiredValue === 0 && comparerStringType === 'IntLimitator') return null;
										return <NoValid key={id + compareProperty} compareProperty={compareProperty} />;
									})}
								</div>
							)}
							{!noAttributesToRender && (
								<div className={classes.attributesContainer}>
									<Typography color='primary' className={classes.attributesTitle}>
										<FormattedMessage id='instrument.attributes' />
									</Typography>
									<InstrumentAttributes instrument={instrument} />
								</div>
							)}
						</Grid>
						<Grid item xs={12} md={8} xl={9}>
							<MainTitle>{name}</MainTitle>
							{url && (
								<Typography className={classes.url}>
									<Link href={url} target='_blank' rel='noopener noreferrer' className={classes.link}>
										{url}
									</Link>
								</Typography>
							)}
							<Typography component='div' color='primary' className={classes.ingress}>
								<TextContainer sx={{ p: 0 }}>{htmlParser(description)}</TextContainer>
							</Typography>
							<InstrumentProviderLink instrument={instrument} profile={profile} />
							{instrument?.relatedInstruments?.length > 0 && (
								<Box sx={{ mt: 4, mb: profile ? 4 : 'unset' }}>
									{/*  @ts-ignore */}
									<Subtitle>
										<FormattedMessage id='related.instruments.title' />
									</Subtitle>
									{instrument?.relatedInstruments.map(({ id }: any, index: number) => {
										const instrument = selectInstrument(id);
										if (!instrument) return null;

										return (
											// @ts-ignore
											<InstrumentItem
												key={instrument.id + index}
												instrument={instrument}
												instruments={instruments}
												currentInstrumentId={currentInstrumentId}
												company={company}
												related={true}
												profile={profile}
												boxShadow='rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
												showDetails={false}
												selectInstrument={selectInstrument}
											/>
										);
									})}
								</Box>
							)}
							<InstrumentRatings {...{ id, selectInstrument, profile }} />
							<AdminContentContainer>
								<InstrumentAnalysis instrument={instrument} companyId={companyId} sx={{ mt: '4rem' }} />
							</AdminContentContainer>
							{!profile && !isAdvanced && !isAdmin && <InstrumentToChat instrumentName={name} color='success' mt={3} />}
						</Grid>
					</Grid>
				</ContentContainer>
			</>
		);
	}
);

export default Instrument;
