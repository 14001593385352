import React from 'react';
import Drawer from '@mui/material/Drawer';

import Messages from 'containers/Messages';


const MessagesDrawer = ({ isDrawerOpen, closeDrawer }) => {
    return (
        <Drawer
            anchor='right'
            open={isDrawerOpen}
            onClose={closeDrawer}
            PaperProps={{
                sx: {
                    bgcolor: 'primary.secondary',
                }
            }}
        >
            <Messages closeMessagesDrawer={closeDrawer} />
        </Drawer>
    );
};

export default MessagesDrawer;
