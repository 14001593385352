import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import CurrencyValue from 'components/Currency/CurrencyValue';
import { Project } from 'types/dataTypes';
import { percentFormatterWithFractionDigits } from 'utils/formatters/numberFormatters';
import ProjectPrintTableCell from '../ProjectPrintTableItems/ProjectPrintTableCell';
import ProjectPrintTableCellTitle from '../ProjectPrintTableItems/ProjectPrintTableCellTitle';
import { calculateFlatrateSum, parseCosts } from './CostsTable';

// export type CostSums = {
// 	salary: number;
// 	personnelSideCosts: number;
// 	travelExpenses: number;
// 	expenses: number;
// 	purchasedServices: number;
// 	materialsAndSupplies: number;
// 	sum: number;
// };

// export const parseCosts = (task: ProjectTask, costs: ProjectCost[]) => {
// 	let salary = 0;
// 	let personnelSideCosts = 0;
// 	let travelExpenses = 0;
// 	let expenses = 0;
// 	let purchasedServices = 0;
// 	let materialsAndSupplies = 0;
// 	let sum = 0;

// 	forEach(costs, ({ category, amountApplied, salaryExpense, salaryExpenseType, workEstimate }) => {
// 		if (category === 'salary') {
// 			salary += amountApplied * (workEstimate ?? 0); //@ts-ignore
// 			personnelSideCosts += calculateExpenseAmount(salaryExpenseType, salaryExpense, amountApplied) * (workEstimate ?? 0);
// 		} else if (category === 'travelExpense') {
// 			travelExpenses += amountApplied;
// 		} else if (category === 'purchasedServices') {
// 			purchasedServices += amountApplied;
// 		} else if (category === 'materialsAndSupplies') {
// 			materialsAndSupplies += amountApplied;
// 		} else {
// 			expenses += amountApplied;
// 		}

// 		sum = salary + expenses + personnelSideCosts + travelExpenses + purchasedServices + materialsAndSupplies;
// 	});

// 	return { salary, personnelSideCosts, travelExpenses, expenses, purchasedServices, materialsAndSupplies, sum };
// };

// export const parseTasksCosts = (tasks: any, costs: ProjectCost[]) => {
// 	let taskSums: CostSums = {
// 		salary: 0,
// 		personnelSideCosts: 0,
// 		travelExpenses: 0,
// 		expenses: 0,
// 		purchasedServices: 0,
// 		materialsAndSupplies: 0,
// 		sum: 0,
// 	};

// 	forEach(tasks, (task: ProjectTask) => {
// 		const costSums: ProjectCost[] | null = costTaskSelector(costs, task?.projectTaskId);

// 		if (!task || !costSums) return;

// 		var cost = parseCosts(task, costSums);

// 		taskSums.salary += cost.salary;
// 		taskSums.personnelSideCosts += cost.personnelSideCosts;
// 		taskSums.travelExpenses += cost.travelExpenses;
// 		taskSums.purchasedServices += cost.purchasedServices;
// 		taskSums.materialsAndSupplies += cost.materialsAndSupplies;
// 		taskSums.expenses += cost.salary + cost.expenses;

// 		taskSums.sum += cost.sum;
// 	});

// 	return taskSums;
// };

const EuraCostsTable = ({
	project,
	tasks,
	costs,
	flatRatePercent,
	flatRateInvestment,
}: {
	project: Project;
	tasks: any;
	costs: any;
	flatRatePercent: number;
	flatRateInvestment: number;
}) => {
	const intl = useIntl();

	const costSums = parseCosts(costs);

	const flatrateDevelopmentSum = calculateFlatrateSum(project, null, flatRatePercent, costSums, 'development');
	const flatrateInvestmentSum = calculateFlatrateSum(project, null, flatRateInvestment, costSums, 'investment');

	return (
		<>
			<TableContainer
				sx={{
					background: '#fff',
					'@media print': {
						overflowX: 'visible',
						pageBreakInside: 'avoid',
					},
				}}
			>
				<Table>
					<TableBody>
						<TableRow>
							<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true}>
								{intl.formatMessage({ id: 'project.cost.estimate' })}
							</ProjectPrintTableCellTitle>
							<ProjectPrintTableCellTitle rowHeader={true} tableColumnHeader={true} sx={{ textAlign: 'right' }}>
								{intl.formatMessage({ id: 'project.cost.amount' })}
							</ProjectPrintTableCellTitle>
						</TableRow>
						<TableRow>
							<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.salarycosts' })}</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								{costSums.salary ? <CurrencyValue value={costSums.salary} /> : '-'}
							</ProjectPrintTableCell>
						</TableRow>
						{Object.keys(costSums.costByCategory.categories).map(key => (
							<TableRow key={key}>
								<ProjectPrintTableCell>
									{intl.formatMessage({ id: `project.cost.categories.${key}` })}
								</ProjectPrintTableCell>
								<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
									<CurrencyValue value={costSums.costByCategory.categories[key]} />
								</ProjectPrintTableCell>
							</TableRow>
						))}
						{/* <TableRow>
							<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.travelExpenses' })}</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								{costSums.travelExpenses ? <CurrencyValue value={costSums.travelExpenses} /> : '-'}
							</ProjectPrintTableCell>
						</TableRow>

						<TableRow>
							<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.categories.service' })}</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								{costSums.purchasedServices ? <CurrencyValue value={costSums.purchasedServices} /> : '-'}
							</ProjectPrintTableCell>
						</TableRow>

						<TableRow>
							<ProjectPrintTableCell>
								{intl.formatMessage({ id: 'project.cost.categories.materialsAndSupplies' })}
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								{costSums.materialsAndSupplies ? <CurrencyValue value={costSums.materialsAndSupplies} /> : '-'}
							</ProjectPrintTableCell>
						</TableRow> */}

						{/* {costSums && costSums.expenses > 0 && (
							<TableRow>
								<ProjectPrintTableCell>{intl.formatMessage({ id: 'project.cost.other.costs' })}</ProjectPrintTableCell>
								<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
									{costSums.expenses ? <CurrencyValue value={costSums.expenses} /> : '-'}
								</ProjectPrintTableCell>
							</TableRow>
						)} */}
						<TableRow>
							<ProjectPrintTableCell>
								{intl.formatMessage(
									{ id: 'project.cost.table.other.flatrate.title' },
									{ percent: percentFormatterWithFractionDigits.format(flatRatePercent / 100) }
								)}
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								{flatrateDevelopmentSum ? <CurrencyValue value={flatrateDevelopmentSum} /> : '-'}
							</ProjectPrintTableCell>
						</TableRow>
						<TableRow>
							<ProjectPrintTableCell>
								{intl.formatMessage(
									{ id: 'project.cost.flatrate.investment' },
									{ percent: percentFormatterWithFractionDigits.format(flatRateInvestment / 100) }
								)}
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }}>
								{flatrateInvestmentSum ? <CurrencyValue value={flatrateInvestmentSum} /> : '-'}
							</ProjectPrintTableCell>
						</TableRow>
						<TableRow>
							<ProjectPrintTableCell lastColumn={true}>
								<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
									{intl.formatMessage({ id: 'project.cost.total' })}
								</Typography>
							</ProjectPrintTableCell>
							<ProjectPrintTableCell sx={{ textAlign: 'right' }} lastColumn={true}>
								<Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
									{costSums.sum > 0 ? (
										<CurrencyValue value={costSums.sum + flatrateDevelopmentSum + flatrateInvestmentSum} />
									) : (
										'-'
									)}
								</Typography>
							</ProjectPrintTableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default EuraCostsTable;
