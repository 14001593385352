export const mockFinancialPlan = {
    mock: true,
    published: null,
    publishPath: null,
    businessId: null,
    modified: null,
    created: '0001-01-01T00:00:00',
    // instruments: [
    //     {
    //         id: '49914018-e831-491d-aa5d-0aa98c3622e9',
    //         name: 'Demo-hanke 1',
    //         description: '<p>Asiantuntijan kirjoittama kuvaus instrumentin hyödyntämisestä Yritys Oy näkökulmasta.</p>\n',
    //         instrumentDescription:
    //             '<!-- wp:paragraph -->\n<p>Instrumentti on tarkoitettu tukemaan yrityksen liiketoiminnan kasvua ja kehitystä. Tässä osiossa on kuvattuna instrumentin yleisiä ehtoja ja vaateita.</p>\n',
    //         instrumentIngress:
    //             '<!-- wp:paragraph -->\n<p>Instrumentti on tarkoitettu tukemaan yrityksen liiketoiminnan kasvua ja kehitystä. Tässä osiossa on kuvattuna instrumentin yleisiä ehtoja ja vaateita.</p>\n',
    //         provider: 'Toimija',
    //         type: 'loan',
    //         startTime: '2021-01-01T00:00:00Z',
    //         endTime: '2021-06-01T00:00:00Z',
    //         isUsed: false,
    //         grantAmount: 100000,
    //         projectSize: 250000,
    //         modified: '2021-05-12T07:58:29.669812+00:00',
    //         partitionKey: 'instruments_3173449-6',
    //         rowKey: '2517827890212787691_976b01be81e846ca8fa92cb78933cceb',
    //         timestamp: '0001-01-01T00:00:00+00:00',
    //         eTag: null,
    //         monthDuration: 5,
    //         monthFromStart: 0,
    //         level: 3,
    //     },
    //     {
    //         id: 'c003f999-d1ef-4bad-b959-5794f0e2a82c',
    //         name: 'Demo-hanke 2',
    //         description: '<p>Asiantuntijan kirjoittama kuvaus instrumentin hyödyntämisestä Yritys Oy näkökulmasta.</p>\n',
    //         instrumentDescription:
    //             '<!-- wp:paragraph -->\n<p>Instrumentti on tarkoitettu tukemaan yrityksen liiketoiminnan kasvua ja kehitystä. Tässä osiossa on kuvattuna instrumentin yleisiä ehtoja ja vaateita.</p>\n',
    //         instrumentIngress:
    //             '<!-- wp:paragraph -->\n<p>Instrumentti on tarkoitettu tukemaan yrityksen liiketoiminnan kasvua ja kehitystä. Tässä osiossa on kuvattuna instrumentin yleisiä ehtoja ja vaateita.</p>\n',
    //         provider: 'Toimija',
    //         type: 'loan',
    //         startTime: '2021-05-01T00:00:00Z',
    //         endTime: '2022-05-01T00:00:00Z',
    //         isUsed: false,
    //         grantAmount: 50000,
    //         projectSize: 75000,
    //         modified: '2021-05-12T07:58:29.669812+00:00',
    //         partitionKey: 'instruments_3173449-6',
    //         rowKey: '2517819905408215631_034bf3269f354fedac84bba82ad40dda',
    //         timestamp: '0001-01-01T00:00:00+00:00',
    //         eTag: null,
    //         monthDuration: 12,
    //         monthFromStart: 4,
    //         level: 2,
    //     },
    //     {
    //         id: 'af243995-372c-4d70-b08d-745f7a488624',
    //         name: 'Demo-hanke 3',
    //         description: '<p>Asiantuntijan kirjoittama kuvaus instrumentin hyödyntämisestä Yritys Oy näkökulmasta.</p>\n',
    //         instrumentDescription:
    //             '<!-- wp:paragraph -->\n<p>Instrumentti on tarkoitettu tukemaan yrityksen liiketoiminnan kasvua ja kehitystä. Tässä osiossa on kuvattuna instrumentin yleisiä ehtoja ja vaateita.</p>\n',
    //         instrumentIngress:
    //             '<!-- wp:paragraph -->\n<p>Instrumentti on tarkoitettu tukemaan yrityksen liiketoiminnan kasvua ja kehitystä. Tässä osiossa on kuvattuna instrumentin yleisiä ehtoja ja vaateita.</p>\n',
    //         provider: 'Toimija',
    //         type: 'support',
    //         startTime: '2021-08-01T00:00:00Z',
    //         endTime: '2022-06-01T00:00:00Z',
    //         isUsed: false,
    //         grantAmount: 5000,
    //         projectSize: 10000,
    //         modified: '2021-05-12T07:58:29.669812+00:00',
    //         partitionKey: 'instruments_3173449-6',
    //         rowKey: '2517814951347187628_cf37f44bbca2442fa0bc17206d2c8ff3',
    //         timestamp: '0001-01-01T00:00:00+00:00',
    //         eTag: null,
    //         monthDuration: 10,
    //         monthFromStart: 7,
    //         level: 0,
    //     },
    //     {
    //         id: 'e44b51fd-2e74-4f8a-9b35-52dade5d3f7e',
    //         name: 'Demo-hanke 4',
    //         description: '<p>Asiantuntijan kirjoittama kuvaus instrumentin hyödyntämisestä Yritys Oy näkökulmasta.</p>\n',
    //         instrumentDescription:
    //             '<!-- wp:paragraph -->\n<p>Instrumentti on tarkoitettu tukemaan yrityksen liiketoiminnan kasvua ja kehitystä. Tässä osiossa on kuvattuna instrumentin yleisiä ehtoja ja vaateita.</p>\n',
    //         instrumentIngress:
    //             '<!-- wp:paragraph -->\n<p>Instrumentti on tarkoitettu tukemaan yrityksen liiketoiminnan kasvua ja kehitystä. Tässä osiossa on kuvattuna instrumentin yleisiä ehtoja ja vaateita.</p>\n',
    //         provider: 'Toimija',
    //         type: 'support',
    //         startTime: '2022-04-01T00:00:00Z',
    //         endTime: '2023-07-01T00:00:00Z',
    //         isUsed: false,
    //         grantAmount: 2000,
    //         projectSize: 4000,
    //         modified: '2021-05-12T07:58:29.669812+00:00',
    //         partitionKey: 'instruments_3173449-6',
    //         rowKey: '2517827889642853477_4bbaec17cb34488f88976ab6da190548',
    //         timestamp: '0001-01-01T00:00:00+00:00',
    //         eTag: null,
    //         monthDuration: 15,
    //         monthFromStart: 15,
    //         level: 1,
    //     },
    // ],
    partitionKey: null,
    rowKey: null,
    timestamp: '0001-01-01T00:00:00+00:00',
    eTag: null,
    startTime: '2020-12-31T22:00:00.000Z',
    endTime: '2023-05-31T20:59:59.999Z',
    numberOfMonth: 30,
    expertComments:
        '<p>Esittelemme tässä rahoitussuunnitelmassa suosituksemme eri rahoitusinstrumenttien hyödyntämiseksi yrityksen Yritys Oy kehittämishankkeissa.</p>\n<p>Osiossa käydään läpi asiakkaan tilanne ja asianatuntija luo lausunnon rahoituksen yrityksen tilasta ja rahoitusmahdollisuuksista.</p>\n',
    base: '<p>Osiossa kuvataan yrityksen perustietoja ja alustetaan rahoitussuunnitelma. Osio toimii suunnitelman johdatuksena.</p>\n',
    precondition:
        '<p>Osiossa kuvataan julkisen rahoituksen edellytykset ja tuodaan esiin yrityksen edellytyksiä yrityksen näkökulmasta.</p>\n',
    notice: '<p>Osiossa kuvataan tukirahoituksen haussa huomioitavia asioita. Osiossa tuodaan esiin yrityksen tilan merkitystä rahoituksen myöntämiseen ja käydään läpi korkealla tasolla rahoituksen aikatauluja ja yleisiä ehtoja.</p>\n',
    followup:
        '<p>Osiossa käydään läpi sovitut jatkotoimenpiteet rahoitussuunnitelman esittelystä ja mahdollisista yhteisistä tapaamisista.</p>\n',
};
