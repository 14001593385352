import React from 'react';
import { Helmet, } from 'react-helmet-async';

import UserDetails from 'containers/Admin/UsersCompany/UserDetails';

const UserDetailsPage = () => {
    return (
        <>
            <Helmet>
                <title>GrantedAI - user details</title>
            </Helmet>
            <UserDetails />
        </>
    );
};

export default UserDetailsPage;