import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { Stack } from '@mui/material';

import Button from 'components/Button/Button';
import FormControlledSelect from 'components/Form/FormControlledSelect';

import { getLicenses } from 'containers/Admin/UsersCompany/licenseSlice';

const LicenseSearchForm = ({ initialLicenseActiveStatus, initialLicenseType, loading }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const { control, handleSubmit, formState: { isDirty, errors } } = useForm({
        defaultValues: {
            licenseType: initialLicenseType,
            licenseActiveStatus: initialLicenseActiveStatus
        },
    });

    const searchLicenses = ({ licenseActiveStatus, licenseType }) => {
        dispatch(getLicenses(licenseActiveStatus, licenseType));
    };

    return (
        <form onSubmit={handleSubmit((data) => searchLicenses(data))}>
            <Stack direction='row' alignItems='flex-end' spacing={2} sx={{ width: { xs: '100%', md: '70%', lg: '50%' } }}>
                <FormControlledSelect
                    defaultValue={initialLicenseActiveStatus}
                    name='licenseActiveStatus'
                    control={control}
                    error={errors?.licenseActiveStatus?.message}
                    label={intl.formatMessage({ id: 'select.label.licenseActiveStatus' })}
                    options={
                        [
                            {
                                value: 'all',
                                label: intl.formatMessage({ id: 'select.currentValue.licenseActiveStatus.all' }),
                            },
                            {
                                value: 'active',
                                label: intl.formatMessage({ id: 'select.currentValue.licenseActiveStatus.active' }),
                            },
                            {
                                value: 'inactive',
                                label: intl.formatMessage({ id: 'select.currentValue.licenseActiveStatus.inactive' }),
                            },
                        ]
                    }
                />
                <FormControlledSelect
                    defaultValue={initialLicenseType}
                    name='licenseType'
                    control={control}
                    error={errors?.licenseType?.message}
                    label={intl.formatMessage({ id: 'select.label.licenseType' })}
                    options={
                        [
                            {
                                value: 'all',
                                label: intl.formatMessage({ id: 'select.currentValue.licenseType.all' }),
                            },
                            {
                                value: 'advanced',
                                label: intl.formatMessage({ id: 'select.currentValue.licenseType.advanced' }),
                            },
                            {
                                value: 'licensed',
                                label: intl.formatMessage({ id: 'select.currentValue.licenseType.licensed' }),
                            },
                            {
                                value: 'trial',
                                label: intl.formatMessage({ id: 'select.currentValue.licenseType.trial' }),
                            },
                            {
                                value: 'freemium',
                                label: intl.formatMessage({ id: 'select.currentValue.licenseType.freemium' }),
                            }
                        ]
                    }
                />
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={loading || !isDirty}
                    sx={{ mb: '.2rem' }}
                >
                    {intl.formatMessage({ id: 'companylist.select.search' })}
                </Button>
            </Stack>
        </form>
    );
};

export default LicenseSearchForm;