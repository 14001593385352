import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import DataGrid from 'components/DataGrid';
import { getDateTime } from 'containers/Company/CompanyInfo/utils';
import ConversationPopUp from './ConversationPopup/ConversationPopUp';

import { Stack } from '@mui/system';
import { getUsernameFromToken } from 'utils/auth/token';

const MessagesNumberBox = ({ number }) => {
    if (!number) return null;

    return (
        <Box
            textAlign='center'
            sx={{
                backgroundColor: '#584b67',
                width: '1.5rem',
                borderRadius: '0.75rem',
            }}
        >
            <Typography sx={{ color: 'primary.white' }}>{number}</Typography>
        </Box>
    );
};

const lasCommentDateComparer = (a, b) => {
    if (a.lastCommentTime < b.lastCommentTime) {
        return 1;
    }
    if (a.lastCommentTime > b.lastCommentTime) {
        return -1;
    }

    return 0;
};

const isReadComparer = (a, b) => {
    if (a?.isRead && !b?.isRead) {
        return 1;
    }
    if (!a?.isRead && b?.isRead) {
        return -1;
    }

    return lasCommentDateComparer(a, b); // secondary sort by lastCommentTime
};

const Conversations = ({ conversations }) => {
    const intl = useIntl();

    const columns = [
        {
            field: 'companyId',
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.companyId' }),
            width: 150,
            renderCell: params => (
                <Typography sx={{ fontSize: '0.875rem' }} component='div' textAlign='center'>
                    {params.row.companyId}
                </Typography>
            ),
        },
        {
            field: 'companyName',
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.companyName' }),
            width: 200,
            renderCell: params => (
                <Typography sx={{ fontSize: '0.875rem' }} component='div' textAlign='center'>
                    {params.row.companyName}
                </Typography>
            ),
        },
        {
            field: 'linkTitle',
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.link' }),
            width: 200,
            renderCell: params => (
                <Typography sx={{ fontSize: '0.875rem' }} component='div' textAlign='center'>
                    {params.row.linkTitle ?? params.row.link}
                </Typography>
            ),
        },
        {
            field: 'isRead',
            disableColumnMenu: true,
            sortable: true,
            filterable: true,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.isRead' }),
            width: 60,
            renderCell: params => (
                <Typography sx={{ fontSize: '0.875rem' }} component='div' textAlign='center'>
                    {params.row.isRead ? <MarkChatReadIcon /> : <ChatBubbleOutlineIcon />}
                </Typography>
            ),
        },
        {
            field: 'resolved',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.resolved' }),
            width: 80,
            renderCell: params => (
                <Typography sx={{ fontSize: '0.875rem' }} component='div' textAlign='center'>
                    {
                        params.row.resolved
                            ? <div>{intl.formatMessage({ id: 'admin.landing.conversation.datagrid.conversation.resolved' })}</div>
                            : <div>{intl.formatMessage({ id: 'admin.landing.conversation.datagrid.conversation.open' })}</div>
                    }
                </Typography>
            ),
        },
        {
            field: 'content',
            disableColumnMenu: true,
            sortable: false,
            filterable: true,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.content' }),
            width: 300,
            renderCell: params => (
                <Typography sx={{ fontSize: '0.875rem' }} component='div' textAlign='center'>
                    {params.row.content}
                </Typography>
            ),
        },
        {
            field: 'createdTime',
            sortable: true,
            filterable: true,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.createdTime' }),
            width: 150,
            type: 'date',
            valueGetter: ({ value }) => value && getDateTime(value),
        },
        {
            field: 'lastCommentTime',
            sortable: true,
            filterable: true,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.lastCommentTime' }),
            width: 150,
            type: 'dateTime',
            valueGetter: ({ value }) => value && getDateTime(value),
        },
        {
            field: 'commentsCount',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.commentCount' }),
            width: 100,
            renderCell: params => (
                <Typography sx={{ fontSize: '0.875rem' }} component='div' textAlign='center'>
                    {params.row.commentsCount}
                </Typography>
            ),
        },
        {
            field: 'commentAuthors',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            headerName: intl.formatMessage({ id: 'admin.landing.conversation.datagrid.commentAuthors' }),
            width: 250,
            renderCell: params => (
                <Typography sx={{ fontSize: '0.875rem' }} component='div' textAlign='left'>
                    {params.row.commentAuthorsArray.map(item => <div key={item}>{item}</div>)}
                </Typography>
            ),
        },
    ];

    const main = 'main';

    const [showAll, setShowAll] = useState(false);

    const [rowModalOpen, setRowModalOpen] = useState(false);
    const [rowModalData, setRowModalData] = useState(null);
    const [messageTable, setmessageTable] = useState(main);

    let activeConverstations = conversations?.data?.conversations?.filter(item => {
        if (showAll) return !item.deleted;

        return !item.deleted && !item.resolved;
    }) ?? [];
    activeConverstations?.sort(isReadComparer);

    const personalActiveConversations = activeConverstations?.filter(item => item.commentAuthorsArray.some(author => author === getUsernameFromToken()));

    const unreadMessagesAll = activeConverstations?.filter(item => !item.isRead).length;
    const unreadMessagesPersonal = personalActiveConversations?.filter(item => !item.isRead).length;

    const data = conversations?.data?.conversations
        ? messageTable === main
            ? activeConverstations
            : personalActiveConversations
        : [];

    const mainSelected = messageTable !== main;

    return (
        <>
            <Stack direction='row' alignContent='space-between' sx={{ mb: '1rem', width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Button color='primary'
                        variant={mainSelected ? 'outlined' : 'contained'}
                        onClick={() => setmessageTable(main)}
                        endIcon={<MessagesNumberBox number={unreadMessagesAll} />}
                    >
                        {intl.formatMessage({ id: 'admin.landing.conversation.button.all' })}
                    </Button>
                    <Button sx={{ ml: '1rem' }}
                        variant={!mainSelected ? 'outlined' : 'contained'}
                        onClick={() => setmessageTable('own')}
                        endIcon={<MessagesNumberBox number={unreadMessagesPersonal} />}
                    >
                        {intl.formatMessage({ id: 'admin.landing.conversation.button.own' })}
                    </Button>
                </Box>
                <Box>
                    <FormControlLabel
                        value="end"
                        control={
                            <Switch
                                color='primary'
                                checked={showAll}
                                onChange={() => setShowAll(!showAll)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={intl.formatMessage({ id: 'admin.landing.conversation.show.all' })}
                        labelPlacement="end"
                    />
                </Box>
            </Stack>
            {!rowModalData ? null : <ConversationPopUp conversationData={rowModalData} open={rowModalOpen} closeModal={() => setRowModalOpen(false)} />}
            <DataGrid
                useHoverStyles={true}
                getRowId={(row) => row.conversationId}
                columns={columns}
                pageSize={50}
                disableSelectionOnClick
                idField='conversationId'
                loading={conversations.isFetching || conversations.isLoading}
                data={data}
                paginationMode='client'
                onRowClick={(row) => {
                    setRowModalData(Object.assign(row?.row));
                    setRowModalOpen(true);
                }}
            // initialState={{
            //     ...data.initialState,
            //     sorting: {
            //         ...data.initialState?.sorting,
            //         // sortModel: [
            //         //     {
            //         //         field: 'isRead',
            //         //         sort: 'asc',
            //         //     },
            //         //     {
            //         //         field: 'lastCommentTime',
            //         //         sort: 'desc',
            //         //     },
            //         // ],
            //     },
            // }}
            />
        </>
    );
};

export default Conversations;