import { createSlice } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { instrumentsApi } from './api';
import { companyApi } from 'containers/Company/companyApi';

export const name = 'instruments';

export const initialState = {
	instruments: {
		loading: false,
		error: null,
		data: [],
		isLoaded: false,
	},
	categories: {
		isLoaded: false,
		loading: false,
		saveLoading: false,
		error: null,
		data: [],
	},
	currentInstrumentId: '',
	currentCategory: '',
};

//reducer actions & reducer state
/**
 * You can call reducer actions directly from React component with dispatch or using actions wrapper (see actions below)
 */
const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},
		loadInstruments(state) {
			state.instruments.loading = true;
			state.instruments.error = false;
		},
		loadInstrumentsError(state, action) {
			state.instruments.loading = false;
			state.instruments.error = action.payload;
			state.instruments.data = initialState.instruments.data;
			state.instruments.isLoaded = true;
		},
		loadInstrumentsSuccess(state, action) {
			state.instruments.loading = false;
			state.instruments.error = false;
			state.instruments.data = action.payload;
			state.instruments.isLoaded = true;
		},
		setCurrentInstrumentsId(state, action) {
			state.currentInstrumentId = action.payload.instrument;
			state.currentCategory = action.payload.category;
		},
		removeInstrumentMarked(state, action) {
			state.instruments.data.find(({ id }) => id === action.payload).isMarked = false;
		},
		setInstrumentMarked(state, action) {
			state.instruments.data.find(({ id }) => id === action.payload).isMarked = true;
		},
		// categories
		loadCategories(state) {
			state.categories.isLoaded = false;
			state.categories.loading = true;
			state.categories.error = false;
		},
		loadCategoriesError(state, action) {
			state.categories.isLoaded = true;
			state.categories.loading = false;
			state.categories.error = action.payload;
			state.categories.data = initialState.categories.data;
		},
		loadCategoriesSuccess(state, action) {
			state.categories.isLoaded = true;
			state.categories.loading = false;
			state.categories.error = false;
			state.categories.data = action.payload;
		},
		saveCategories(state) {
			state.categories.saveLoading = true;
			state.categories.error = false;
		},
		saveCategoriesError(state, action) {
			state.categories.saveLoading = false;
			state.categories.error = action.payload;
		},
		saveCategoriesSuccess(state) {
			state.categories.saveLoading = false;
			state.categories.error = false;
		},
	},
});

export const { reducer } = slice;

export const resetInstruments = () => async dispatch => {
	dispatch(slice.actions.resetData());
};

export const loadInstruments = () => async dispatch => {
	try {
		dispatch(slice.actions.loadInstruments());

		const instruments = await instrumentsApi.getInstruments();
		const marked = await getMarkedInstruments();

		const handledMarked = instruments?.map(item => {
			const isMarked = marked?.some(el => el.rowKey === item.id);
			item.isMarked = isMarked;

			return item;
		});

		dispatch(slice.actions.loadInstrumentsSuccess(handledMarked));

		dispatch(loadInstrumentCategories());

		return handledMarked;
	} catch (err) {
		dispatch(slice.actions.loadInstrumentsError(err));

		return null;
	}
};

export const getMarkedInstruments = async () => {
	try {
		const marked = await instrumentsApi.getInstrumentsMarked();

		return marked;
	} catch (err) {
		return null;
	}
};

export const getInstrumentRatings = instrumentId => async (dispatch, getState) => {
	try {
		const state = getState();
		const instruments = state.instruments.instruments;

		const ratings = await instrumentsApi.getInstrumentRatings(instrumentId);

		const updatedInstruments = instruments?.data?.map(item => {
			if (item.id === instrumentId) {
				return {
					...item,
					ratings,
				};
			}

			return item;
		});

		dispatch(slice.actions.loadInstrumentsSuccess(updatedInstruments));

		return ratings;
	} catch (err) {
		return [];
	}
};

export const setInstruments = instruments => async dispatch => {
	try {
		dispatch(slice.actions.loadInstruments());
		dispatch(slice.actions.loadInstrumentsSuccess(instruments));

		return instruments;
	} catch (err) {
		dispatch(slice.actions.loadInstrumentsError(err));

		return null;
	}
};

export const setCurrentInstrumentId = (instrumentId, category) => async dispatch => {
	try {
		dispatch(slice.actions.setCurrentInstrumentsId({ instrument: instrumentId, category: category }));

		return true;
	} catch {
		return false;
	}
};

export const getInstrumentTags = instruments => async (_dispatch, getState) => {
	const state = getState();

	instruments = instruments ?? state.instruments?.instruments?.data;

	const projectAims = instruments?.map(({ projectAimArr }) => projectAimArr);
	const projectObjects = instruments?.map(({ projectObjectsArr }) => projectObjectsArr);
	const projectValues = instruments?.map(({ projectValueArr }) => projectValueArr);

	const uniqueProjectAims = projectAims ? [...new Set([].concat(...projectAims))] : [];
	const uniqueProjectObjects = projectObjects ? [...new Set([].concat(...projectObjects))] : [];
	const uniqueProjectValues = projectValues ? [...new Set([].concat(...projectValues))] : [];

	return { uniqueProjectAims, uniqueProjectObjects, uniqueProjectValues };
};

export const removeInstrumentMarked = id => async dispatch => {
	try {
		dispatch(slice.actions.removeInstrumentMarked(id));

		await instrumentsApi.removeInstrumentMarked(id);

		toast.success(<FormattedMessage id='instrument.unmarked.success' />);

		return true;
	} catch (err) {
		toast.error(<FormattedMessage id='instrument.unmarked.failed' />);
		dispatch(slice.actions.setInstrumentMarked(id));

		return false;
	}
};

export const setInstrumentMarked = id => async dispatch => {
	try {
		dispatch(slice.actions.setInstrumentMarked(id));

		await instrumentsApi.saveInstrumentMarked(id);

		toast.success(<FormattedMessage id='instrument.marked.success' />);

		return true;
	} catch (err) {
		toast.error(<FormattedMessage id='instrument.marked.failed' />);
		dispatch(slice.actions.removeInstrumentMarked(id));

		return false;
	}
};

// categories
export const loadInstrumentCategories = name => async dispatch => {
	try {
		dispatch(slice.actions.loadCategories());

		const categories = name ? await instrumentsApi.getInstrumentCategoriesByName(name) : await instrumentsApi.getInstrumentCategories();

		dispatch(slice.actions.loadCategoriesSuccess(categories));

		return categories;
	} catch (err) {
		dispatch(slice.actions.loadCategoriesError(err));

		return null;
	}
};

export const saveInstrumentCategories = (addedCategories, name) => async dispatch => {
	try {
		dispatch(slice.actions.saveCategories());

		name
			? await instrumentsApi.saveInstrumentCategoriesByName(addedCategories, name)
			: await instrumentsApi.saveInstrumentCategories(addedCategories);

		dispatch(slice.actions.saveCategoriesSuccess());

		return true;
	} catch (err) {
		dispatch(slice.actions.saveCategoriesError(err));

		return false;
	}
};

export default slice;
