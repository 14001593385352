import Divider from '@mui/material/Divider';
import { useIntl } from 'react-intl';

import ColumnAddButton from 'components/Kanban/ColumnAddButton';
import KanbanColumn from 'components/Kanban/KanbanColumn';
import Spinner from 'components/Spinner/Spinner';
import ProjectTaskCard from '../ProjectTaskCard';

import { costTaskSelector } from 'containers/Projects/utils';

const ProjectTaskList = ({ loading, project, tasks, costs }) => {
	if (loading) return <Spinner size={20} />;

	return tasks?.map((task, index) => {
		return (
			<ProjectTaskCard
				key={task.projectTaskId}
				index={index}
				project={project}
				task={task}
				costs={costTaskSelector(costs, task.projectTaskId)}
				column='tasks'
			/>
		);
	});
};

const ProjectTaskColumn = ({ project, tasks, costs }) => {
	const loading = false;
	const intl = useIntl();

	if (!project) return null;

	return (
		<>
			<KanbanColumn
				name='tehtävät'
				footer={
					project ? (
						<ProjectTaskCard
							isColumnAdd={true}
							showModalButton={true}
							customButton={<ColumnAddButton>{intl.formatMessage({ id: 'project.task.add' })}</ColumnAddButton>}
							project={project}
							task={{}}
							costs={[]}
							column='tasks'
						/>
					) : null
				}
			>
				<ProjectTaskList {...{ loading, project, tasks, costs }} />
			</KanbanColumn>
			<Divider orientation='vertical' variant='middle' flexItem sx={{ pl: '1rem', mt: 0 }} />
		</>
	);
};

export default ProjectTaskColumn;
