import React from 'react';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/styles';

const ProjectPrintContentTitle = ({ children, sx = {}, tableColumnHeader = false, ...props }) => {
    const theme = useTheme();

    if (!children) return null;

    if (tableColumnHeader) {
        sx = Object.assign({}, sx, {
            backgroundColor: theme.palette.primary.secondary,
        });
    }

    return (
        <Grid item
            className='tdOverviewPrint tdPrint'
            sx={{
                border: 'none',
                verticalAlign: 'top',
                color: theme.palette.primary.main,
                fontSize: '1.1rem',
                fontWeight: 500,
                ...sx
            }}
            {...props}
        >
            {children}
        </Grid>
    );
};

export default ProjectPrintContentTitle;