import * as yup from 'yup';
import React, { useEffect, useMemo, forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';

import { getDirtyKeys } from 'components/Form/utils/utils';

import { FormControlledDatepicker, FormControlledInput, FromControlledHiddenField } from 'components/Form';
import BoxContainer from 'components/Containers/BoxContainer';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import { SupplementaryUpdateItem } from '../ProjectSupplemenaryListItem';

const ProjectSupplementaryForm = forwardRef(({ supplementary, onSubmit, passIsDirty }, formRef) => {
	const { formatMessage } = useIntl();

	const validationSchema = useMemo(() => {
		return yup.object({
			title: yup.string().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return supplementary;
	}, [supplementary]);

	const {
		handleSubmit,
		control,
		register,
		unregister,
		formState: { dirtyFields, errors },
	} = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema),
	});

	const dirtyKeys = getDirtyKeys(dirtyFields);
	const isDirty = dirtyKeys.length > 0;

	useEffect(() => {
		register('files');
		return () => {
			unregister('files');
		};
	}, [register, unregister]);

	useEffect(() => {
		passIsDirty && passIsDirty(isDirty, dirtyFields);
	}, [isDirty, dirtyFields, passIsDirty]);

	return (
		<form ref={formRef} noValidate onSubmit={handleSubmit(data => onSubmit(data))}>
			<BoxContainer footer={null}>
				<FromControlledHiddenField
					name='projectSupplementaryInformationId'
					defaultValue={supplementary?.projectSupplementaryInformationId ?? null}
					control={control}
				/>
				<Stack spacing={2} flexDirection='column' useFlexGap>
					<FormControlledInput
						defaultValue={supplementary?.title ?? ''}
						name='title'
						control={control}
						error={errors?.title?.message}
						label={formatMessage({ id: 'project.supplementary.form.title' })}
					/>
					<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }} useFlexGap>
						<FormControlledDatepicker
							defaultValue={supplementary?.requestDate ?? new Date()}
							name='requestDate'
							control={control}
							error={errors?.requestDate?.message}
							label={formatMessage({ id: 'project.supplementary.form.requestDate' })}
						/>
						<FormControlledDatepicker
							defaultValue={supplementary?.responseDate ?? new Date()}
							name='responseDate'
							control={control}
							error={errors?.responseDate?.message}
							label={formatMessage({ id: 'project.supplementary.form.responseDate' })}
						/>
					</Stack>
					<FormControlledWysiwyg
						inline={false}
						fixedToolbar={true}
						toolbar={true}
						name='content'
						control={control}
						defaultValue={supplementary?.content ?? ''}
						error={errors?.content?.message}
						label={formatMessage({ id: 'project.supplementary.form.content' })}
					/>
				</Stack>
				<SupplementaryUpdateItem supplementary={supplementary} sx={{ my: '1rem' }} />
			</BoxContainer>
		</form>
	);
});

export default ProjectSupplementaryForm;
