export const getErrorFields = (path, errors) => {
	const result = [];

	for (let prop in errors) {
		// Object.keys(errors).map()?!
		const localPath = path.concat([prop]);

		if (Array.isArray(errors[prop])) {
			errors[prop].forEach((el, index) => {
				if (el && Object.keys(el).length > 0) {
					getErrorFields(path.concat([prop, index]), el).forEach(f => {
						result.push({ id: f.id, message: f.message });
					});
				}
			});
		} else if ('message' in errors[prop]) {
			result.push({
				id: '#' + localPath.join('-'),
				message: errors[prop].message,
			});
		} else {
			getErrorFields(localPath, errors[prop]).forEach(f => result.push(f));
		}
	}

	return result;
};

export const getDirtyKeys = dirtyFields => {
	const dirtyKeys = [];

	Array.isArray(dirtyFields) ? dirtyKeysFromArray(dirtyFields, dirtyKeys) : dirtyKeysFromObject(dirtyFields, dirtyKeys);

	return [...new Set(dirtyKeys)];
};

const dirtyKeysFromObject = (object, dirtyKeys, name) =>
	Object.keys(object).filter(key => {
		const value = object[key];

		if (typeof value === 'boolean' && value && key !== 'isEditable') dirtyKeys.push(name ?? key);
		else if (Array.isArray(value)) dirtyKeysFromArray(value, dirtyKeys, name ?? key);
		else if (typeof value === 'object') dirtyKeysFromObject(value, dirtyKeys, name ?? key);
	});

const dirtyKeysFromArray = (array, dirtyKeys, key) => {
	if (key) {
		const isPassed = array.some(item => {
			if (typeof item === 'boolean' && item !== 'isEditable') return item;
			else if (typeof item === 'object') return dirtyKeysFromObject(item, dirtyKeys, key).length > 0;
			else if (Array.isArray(item)) return dirtyKeysFromArray(item, dirtyKeys, key).length > 0;
		});

		if (isPassed) dirtyKeys.push(key);
	}
};
