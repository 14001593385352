import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow } from '@mui/material';
import { styled } from '@mui/styles';

import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
	DataGrid,
	GridActionsCellItem,
	GridColumns,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	GridRowsProp,
	MuiEvent,
} from '@mui/x-data-grid';

import AddButton from 'components/Button/AddButton';
import { FormControlledInput, FromControlledHiddenField } from 'components/Form';
import { Controller, useFieldArray } from 'react-hook-form';
import { gridTranslations } from 'translations/fi/gridTranslations';
import { FinancialReport, FinancialReportKeysType, financialReportKeys } from 'utils/formatters/formatEconomicalData';
import { currencyFormatter, intFormatter } from 'utils/formatters/numberFormatters';
import { generateUid } from 'utils/helpers/uid';
import CurrencyField from '../../../../../../components/CurrencyField';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
	// @ts-ignore
	backgroundColor: theme.palette.primary.white,
	paddingBottom: '2rem',
}));

export const EconomicalTable = ({
	control,
	data,
	field,
	onInputBlur = () => {},
}: {
	control?: any;
	data: any;
	field: any;
	onInputBlur?: any;
}) => {
	const intl = useIntl();
	const currentYear = new Date().getFullYear();

	const combineEconomyData = (companyEconomicalData: FinancialReport[]) => {
		let ret = [...(companyEconomicalData ?? [])];
		ret.sort((a, b) => a.year - b.year);

		const last = ret[ret.length - 1];

		const lastPossibleYear = last?.year === currentYear && control ? currentYear + 1 : currentYear + 0;

		const lastYear = last?.year ?? currentYear - 1;

		const yearsToAdd = lastPossibleYear - lastYear;
		const firstYearToAdd = currentYear - (currentYear - lastYear) + 1;

		for (let i = 0; i < yearsToAdd; i++) {
			// @ts-ignore
			ret.push({ isEditable: true, year: firstYearToAdd + i });
		}

		return ret;
	};

	useEffect(() => {
		seteconomicalData(combineEconomyData(data));
	}, []);

	const [economicalData, seteconomicalData] = useState<FinancialReport[]>([]);

	const addRow = () => {
		let data: Array<FinancialReport> = [...economicalData];
		// @ts-ignore
		data.push({ isEditable: true, year: new Date().getFullYear() });

		seteconomicalData(data);
	};

	const removeRow = (index: number) => {
		let data: Array<FinancialReport> = [...economicalData];
		// @ts-ignore
		data.splice(index, 1);

		seteconomicalData(data);
	};

	const renderKeys = financialReportKeys;

	if (!control) {
		return (
			<TableContainer
				sx={{
					background: '#fff',
					'@media print': {
						overflowX: 'visible',
						pageBreakInside: 'avoid',
					},
				}}
			>
				<Table>
					<TableBody>
						{renderKeys?.map((financialReportKey: FinancialReportKeysType, rowIndex: number) => {
							return (
								<TableRow key={financialReportKey.key}>
									<TableCell
										sx={{
											fontWeight: 500,
											borderBottom: (
												theme // @ts-ignore
											) => (rowIndex === 0 ? `4px solid ${theme.palette.primary.secondary}` : 'none'),
										}}
									>
										<span>
											{financialReportKey.title ??
												intl.formatMessage({ id: `project.economical.${financialReportKey.key}` })}
										</span>
									</TableCell>
									{economicalData.map((economicalCol: any, colIndex: number) => {
										const colYear = economicalCol?.['year'];
										const colValue = economicalCol?.[financialReportKey.key];
										const colKey = `${financialReportKey.key}${colYear}_${colIndex}`;

										if (financialReportKey.key === 'year') {
											return (
												<TableCell
													key={colKey}
													sx={{
														fontWeight: colYear === currentYear ? 700 : 400,
														textDecoration: colYear === currentYear ? 'underline' : 'none',
														textAlign: control ? 'left' : 'right', // @ts-ignore
														borderBottom: theme => `4px solid ${theme.palette.primary.secondary}`,
													}}
												>
													<span>{colYear}</span>
												</TableCell>
											);
										}

										return (
											<TableCell
												key={colKey}
												sx={{
													fontWeight: 500,
													textAlign: 'right',
													borderBottom: 'none',
												}}
											>
												<span>{colValue || '-'}</span>
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	return (
		<StyledTableContainer>
			<Table>
				<TableBody>
					<TableRow>
						{renderKeys.map((financialReportKey, itemIndex: number) => {
							return (
								<TableCell
									key={financialReportKey.key + itemIndex}
									sx={{
										fontWeight: 500,
									}}
								>
									{financialReportKey.title ?? intl.formatMessage({ id: `project.economical.${financialReportKey.key}` })}
								</TableCell>
							);
						})}
					</TableRow>
					{economicalData.map((dataItem, index) => {
						return (
							<TableRow key={index}>
								{renderKeys.map((financialReportKey, itemIndex: number) => {
									const uniqueKey = financialReportKey.key + dataItem['year'] + '_' + itemIndex;

									if (financialReportKey.key === 'year') {
										return (
											<TableCell key={uniqueKey} sx={{ pb: '1.2rem', fontSize: '1rem' }}>
												<Box
													sx={{
														fontWeight: dataItem.year === currentYear ? 700 : 500,
														textDecoration: dataItem.year === currentYear ? 'underline' : 'none',
														textAlign: !control ? 'right' : 'left',
													}}
												>
													<FormControlledInput
														fullWidth={false} // @ts-ignore
														defaultValue={dataItem[financialReportKey.key] ?? ''}
														name={`dynamic.${field.id}.${index}.${financialReportKey.key}`}
														control={control}
														variant='standard'
														size='small'
														onInputBlur={onInputBlur}
													/>
												</Box>
												<FromControlledHiddenField
													name={`dynamic.${field.id}.${index}.year`}
													defaultValue={dataItem.year}
													control={control}
												/>
											</TableCell>
										);
									}

									return (
										<TableCell key={uniqueKey}>
											{financialReportKey.key === 'employees' ? (
												<FormControlledInput
													fullWidth={false} // @ts-ignore
													defaultValue={dataItem[financialReportKey.key] ?? ''}
													name={`dynamic.${field.id}.${index}.${financialReportKey.key}`}
													control={control}
													variant='standard'
													type='number'
													step={1}
													size='small'
													onInputBlur={onInputBlur}
												/>
											) : (
												<Controller
													name={`dynamic.${field.id}.${index}.${financialReportKey.key}`}
													control={control} // @ts-ignore
													defaultValue={dataItem[financialReportKey.key] ?? ''}
													render={({ field: { onChange, value } }) => (
														<CurrencyField
															value={value}
															onChange={onChange}
															forbidNegative={true}
															positionEnd={true}
															decimals={0}
														/>
													)}
												/>
											)}
										</TableCell>
									);
								})}
								<TableCell>
									<Button onClick={() => removeRow(index)}>poista</Button>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell>
							<Button onClick={addRow}>Lisää rivi</Button>
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</StyledTableContainer>
	);
};

// export default EconomicalTable;
interface FooterToolbarProps {
	setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
	append: any;
	data: any;
}
function FooterToolbar({ setRows, setRowModesModel, append, data }: FooterToolbarProps) {
	const { formatMessage } = useIntl();

	const row = {
		id: generateUid(),
		fiscalYear: '',
		year: null,
		month: null,
		turnover: null,
		turnoverChange: null,
		operatingMargin: null,
		operatingProfit: null,
		profit: null,
		profits: null,
		quickRatio: null,
		currentRatio: null,
		equity: null,
		balanceSheetTotal: null,
		balance: null,
		staff: null,
		employees: null,
		staffChange: null,
		turnoverPerPerson: null,
		solvency: null,
		equityRatio: null,
		ebitda: null,
		ebitdas: null,
		ebit: null,
		magic40: null,
	};

	const handleClick = () => {
		setRows(oldRows => [...oldRows, row]);

		setRowModesModel(oldModel => ({
			...oldModel,
			[row.id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
		}));

		append(row);
	};

	return (
		<Stack alignContent='flex-end' alignItems='flex-end' sx={{ p: 1, display: 'flex', width: '100%' }}>
			{/* @ts-ignore */}
			<AddButton onClick={handleClick}>Lisää rivi</AddButton>
		</Stack>
	);
}

const EconomicalGrid = ({ control, data, field }: { control: any; data: FinancialReport[] | null; field: any }) => {
	const intl = useIntl();
	const [rows, setRows] = useState<any>([]);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

	const { fields, remove, append, update } = useFieldArray({
		control,
		name: `dynamic.${field.id}`,
		keyName: 'key',
	});

	useEffect(() => {
		let newArr: FinancialReport[] = [];

		let array = fields && fields.length > 0 ? fields : data ?? [];

		let index = 0;
		for (let dataitem of array) {
			// @ts-ignore
			const fiscalYear = dataitem.month ? `${dataitem.year}.${dataitem.month}` : dataitem.year;

			// @ts-ignore
			dataitem = Object.assign({ id: generateUid(), fiscalYear }, dataitem, { index });
			// @ts-ignore
			newArr.push(dataitem);
			index++;

			if (!fields || fields.length === 0) {
				append(dataitem);
			}
		}

		setRows(newArr);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields]);

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
	};

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = false;
	};

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = false;
	};

	const handleDeleteClick = (id: GridRowId) => () => {
		setRows(rows.filter((row: FinancialReport) => row.id !== id));

		const cost = rows.find((item: FinancialReport) => item.id === id);

		if (!cost) return;

		remove(cost.index);
	};

	const processRowUpdate = (newRow: GridRowModel) => {
		const updatedRow = { ...newRow, isNew: false };
		setRows(rows.map((row: FinancialReport) => (row.id === newRow.id ? updatedRow : row)));

		const cost = rows.find((item: FinancialReport) => item.id === newRow.id);

		if (cost) {
			update(cost.index, updatedRow);
		}

		return updatedRow;
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row: FinancialReport) => row.id === id);
		// @ts-ignore
		if (editedRow!.isNew) {
			setRows(rows.filter((row: FinancialReport) => row.id !== id));
		}
	};

	const columns: GridColumns = [
		{
			headerName: 'Tilikausi',
			field: 'fiscalYear',
			editable: true,
		},
		{
			field: 'year',
			headerName: 'Vuosi',
			editable: true,
			valueFormatter: ({ value }) => intFormatter.format(value),
		},
		{
			field: 'month',
			headerName: 'Kuukausi',
			editable: true,
			type: 'number',
		},
		{
			headerName: 'Henkilöstö',
			field: 'employees',
			editable: true,
			type: 'number',
		},
		{
			headerName: 'Liikevaihto',
			field: 'turnover',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			headerName: 'Tilikauden voitto',
			field: 'profit',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			headerName: 'T&K kustannukset',
			field: 'developmentCosts',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			headerName: 'Vienti',
			field: 'export',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			headerName: 'Taseen loppusumma',
			field: 'balance',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			headerName: 'Oma pääoma',
			field: 'equity',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			headerName: 'Sijoitukset omaan pääomaan',
			field: 'equityInvestments',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			headerName: 'Osakepääoma tai muu vastaava sidottu pääoma',
			field: 'otherCapital',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			headerName: 'Pääomalaina',
			field: 'capitalLoan',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			type: 'number',
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Toiminnot',
			width: 100,
			cellClassName: 'actions',
			getActions: ({ id }) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(id)}
							color='inherit'
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label='Edit'
						className='textPrimary'
						onClick={handleEditClick(id)}
						color='inherit'
					/>,
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label='Poista rivi'
						onClick={handleDeleteClick(id)}
						color='inherit'
						showInMenu
					/>,
				];
			},
		},
	];

	return (
		<DataGrid
			localeText={gridTranslations(intl)}
			rows={rows}
			columns={columns}
			autoHeight
			hideFooterPagination
			rowModesModel={rowModesModel}
			onRowModesModelChange={newModel => setRowModesModel(newModel)}
			onRowEditStart={handleRowEditStart}
			onRowEditStop={handleRowEditStop}
			processRowUpdate={processRowUpdate}
			editMode='row'
			components={{
				Footer: FooterToolbar,
			}}
			componentsProps={{
				footer: { setRows, setRowModesModel, append, data },
			}}
			rowHeight={38}
			experimentalFeatures={{ newEditingApi: true }}
			hideFooter={false}
		/>
	);
};

export default EconomicalGrid;
