import * as React from 'react';
import { List, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { RfpProposal } from 'types/dataTypes';
import { DataList, IListItem, PageActions } from 'components/Pagination/Paginator';
import { PaginatorProvider } from 'components/Pagination/PaginationContext';

type ListProps = {
	proposals: RfpProposal[] | undefined;
	dataListItem: React.FunctionComponentElement<IListItem>;
	showEmptyMessage?: boolean;
	emptyMessageId?: string;
};

export const PaginatedProposalList: React.FC<ListProps> = ({
	proposals,
	dataListItem,
	showEmptyMessage = true,
	emptyMessageId = 'rfpProposals.list.empty',
}) => {
	return (
		<List>
			{isEmpty(proposals) && showEmptyMessage && (
				<Typography variant='lightText'>
					<FormattedMessage id={emptyMessageId} />
				</Typography>
			)}
			{proposals && proposals.length > 0 && (
				<PaginatorProvider rowsPerPage={5} data={proposals}>
					<DataList dataListItem={dataListItem} />
					<PageActions />
				</PaginatorProvider>
			)}
		</List>
	);
};
