import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import FormattedDate from 'components/Formatters/FormattedDate';

const UserInformationPanel = ({ loading, user, classes }) => {
    const intl = useIntl();

    return (
        <>
            <Stack direction='row' spacing={2} alignItems='flex-end'>
                <Typography variant='h2' className={classes.title}>{intl.formatMessage({ id: 'userdetails.user.title' })}</Typography>
                {loading && <Box sx={{ mb: 2 }}><CircularProgress size={16} sx={{ mb: 0.5 }} /></Box>}
            </Stack>
            {!loading && !user || !loading && user && Object.keys(user).length === 0
                ? <Typography component='p'>{intl.formatMessage({ id: 'userdetails.no.userInfo' })}</Typography>
                : <>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.user.id' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {user?.id}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.user.userName' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {user?.userName}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.user.isActive' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {intl.formatMessage({ id: `admin.tables.${user?.isActive + ''}` })}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.user.lockoutEnabled' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {intl.formatMessage({ id: `admin.tables.${user?.lockoutEnabled + ''}` })}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userdetails.user.lockoutEndDate' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={user?.lockoutEndDate} />
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.accessFailedCount' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {user?.accessFailedCount}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'userlist.table.twoFactorEnabled' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                {intl.formatMessage({ id: `admin.tables.${user?.twoFactorEnabled + ''}` })}
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='row' spacing={0.5} className={classes.itemRow}>
                        <Typography component='p' className={classes.itemKey}>
                            {intl.formatMessage({ id: 'companylist.table.timestamp' })}:
                        </Typography>
                        {!loading &&
                            <Typography component='p' className={classes.itemProp}>
                                <FormattedDate value={user?.timestamp} />
                            </Typography>
                        }
                    </Stack>
                </>
            }
        </>
    );
};

export default UserInformationPanel;