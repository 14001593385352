import { useConstantDataQuery, useLazySearchCustomersQuery } from './customerSearchApi';

import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { Autocomplete, Button, FormControl, FormGroup, InputLabel, NativeSelect, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import ContentContainer from 'components/Containers/ContentContainer';
import { find } from 'lodash';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { BFAdmittedTable, ContactTable, FinancialTable } from './Tables';

import { CompanyTableRow } from './types';

const parseFilters = (columnFilters: any[], columns: any[]) => {
	let filters = [...columnFilters];

	for (let i = 0; i < filters.length; i++) {
		const field = find(columns, (item: any) => item.accessorKey === filters[i].id);
		filters[i] = Object.assign({}, filters[i], { mode: field?._filterFn });
	}

	return filters;
};

const CustomerDataGrid = () => {
	const { formatMessage } = useIntl();

	const columns = useMemo<Array<MRT_ColumnDef<CompanyTableRow>>>(
		() => [
			{
				enableHiding: false,
				accessorKey: 'BusinessId',
				header: 'Y-Tunnus',
				size: 50,
				columnFilterModeOptions: ['equals', 'contains', 'startsWith'],
				filterFn: 'equals',
			},
			{
				enableHiding: false,
				accessorKey: 'GeneralName',
				header: 'Nimi',
				size: 150,
				columnFilterModeOptions: ['contains', 'equals', 'startsWith'],
				filterFn: 'contains',
			},
			{
				accessorKey: 'GeneralBusinessForm',
				header: 'Yritysmuoto',
				size: 25,
			},
			{
				accessorKey: 'TolCode',
				header: 'Tol-koodi',
				size: 50,
				columnFilterModeOptions: ['equals', 'contains', 'startsWith'],
				filterFn: 'contains',
			},
			{
				accessorKey: 'IndustryTol2008Primary',
				header: 'Tol',
				size: 50,
				filterFn: 'contains',
			},
			{
				accessorFn: (row: any) => new Date(row.FetchTime),
				id: 'FetchTime',
				header: 'Päivitetty',
				sortingFn: 'datetime',
				Cell: ({ cell }: any) => {
					const dt = new Date(cell.getValue());

					if (!dt?.getTime()) return null;

					return dt.toDateString();
				},
			},
			{
				accessorKey: 'GeneralHasWww',
				header: 'WWW',
				size: 50,
				filterFn: 'equals',
				hide: true,
			},
			{
				accessorKey: 'GeneralMainEmail',
				header: 'Email',
				size: 50,
				filterFn: 'equals',
				hide: true,
			},
			{
				accessorKey: 'GeneralMainPhoneNumber',
				header: 'Phone',
				size: 50,
				filterFn: 'equals',
				hide: true,
			},
			{
				accessorKey: 'LocationPostalCodeName',
				header: 'Lokaatio',
				size: 50,
				columnFilterModeOptions: ['equals', 'contains', 'startsWith'],
				filterFn: 'equals',
			},
			{
				accessorFn: (row: any) => new Date(row.RegistersEmployerRegister),
				id: 'RegistersEmployerRegister',
				header: 'RegistersEmployerRegister',
				size: 50,
				sortingFn: 'datetime',
				Cell: ({ cell }: any) => {
					const dt = new Date(cell.getValue());

					if (!dt?.getTime()) return null;

					return dt.toDateString();
				},
			},
			{
				accessorFn: (row: any) => new Date(row.RegistersPrepaymentRegister),
				id: 'RegistersPrepaymentRegister',
				header: 'RegistersPrepaymentRegister',
				size: 50,
				sortingFn: 'datetime',
				Cell: ({ cell }: any) => {
					const dt = new Date(cell.getValue());

					if (!dt?.getTime()) return null;

					return dt.toDateString();
				},
			},
			{
				accessorFn: (row: any) => new Date(row.RegistersTradeRegister),
				id: 'RegistersTradeRegister',
				header: 'RegistersTradeRegister',
				size: 50,
				sortingFn: 'datetime',
				Cell: ({ cell }: any) => {
					const dt = new Date(cell.getValue());

					if (!dt?.getTime()) return null;

					return dt.toDateString();
				},
			},
			{
				accessorFn: (row: any) => new Date(row.RegistersVatliability),
				id: 'RegistersVatliability',
				header: 'RegistersVatliability',
				size: 50,
				sortingFn: 'datetime',
				Cell: ({ cell }: any) => {
					const dt = new Date(cell.getValue());

					if (!dt?.getTime()) return null;

					return dt.toDateString();
				},
			},
			{
				accessorKey: 'SegmentationGrowthclass',
				header: 'Segmentti',
				size: 50,
				filterFn: 'equals',
			},
			{ header: 'Source', accessorKey: 'Source' },
			{ header: 'Secondary Industry (TOL2008)', accessorKey: 'IndustryTol2008Secondary' },
			{ header: 'Primary Industry (Profinder)', accessorKey: 'IndustryProfinderPrimary' },
			{ header: 'Secondary Industry (Profinder)', accessorKey: 'IndustryProfinderSecondary' },
			{ header: 'Industry Service Type', accessorKey: 'IndustryServiceType' },
			{ header: 'Website Exists', accessorKey: 'GeneralHasWWW', type: 'boolean' },
			{ header: 'Main Office Profinder ID', accessorKey: 'GeneralMainOfficeProfinderId' },
			{ header: 'Profinder ID', accessorKey: 'GeneralProfinderId' },
			{ header: 'Founded Date', accessorKey: 'GeneralFounded', type: 'date' },
			{ header: 'Founded', accessorKey: 'Founded', type: 'date' },
			{ header: 'Products Produced', accessorKey: 'GeneralProduces' },
			{ header: 'Mobility', accessorKey: 'GeneralMobility' },
			{ header: 'Marketing Name', accessorKey: 'GeneralMarketingName' },
			{ header: 'Office Type', accessorKey: 'GeneralOfficeType' },
			{ header: 'Staff Category', accessorKey: 'GeneralStaffCategory' },
			{ header: 'Province', accessorKey: 'LocationProvince' },
			{ header: 'Geo X', accessorKey: 'LocationGeoX', type: 'numeric' },
			{ header: 'Geo Y', accessorKey: 'LocationGeoY', type: 'numeric' },
			{ header: 'Address', accessorKey: 'LocationAddress' },
			{ header: 'City', accessorKey: 'LocationCity' },
			{ header: 'Postal Code', accessorKey: 'LocationPostalCode' },
			{ header: 'Region Code', accessorKey: 'LocationRegionCode' },
			{ header: 'Municipality Code', accessorKey: 'LocationMunicipalityCode' },
			{ header: 'Region', accessorKey: 'LocationRegion' },
			{ header: 'Neighborhood', accessorKey: 'LocationNeighborhood' },
			{ header: 'PO Address', accessorKey: 'LocationPOAddress' },
			{ header: 'PO Postal Code', accessorKey: 'LocationPOPostalCode' },
			{ header: 'Province Code', accessorKey: 'LocationProvinceCode' },
			{ header: 'PO Postal Code Name', accessorKey: 'LocationPOPostalCodeName' },
			{ header: 'E-invoicing Service Address', accessorKey: 'EinvoicingServiceAddress' },
			{ header: 'E-invoicing Address', accessorKey: 'EinvoicingAddress' },
			{
				header: 'Turnover',
				accessorKey: 'FiguresTurnover',
				type: 'currency',
				currencySetting: { currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 },
				Cell: ({ cell }) =>
					(cell.getValue<number>() * 1000).toLocaleString('fi', {
						style: 'currency',
						currency: 'EUR',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}),
			},
			{ header: 'Equity Ratio (%)', accessorKey: 'FiguresEquityRatio', type: 'numeric' },
			{
				header: 'Profit',
				accessorKey: 'FiguresProfit',
				type: 'currency',
				currencySetting: { currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 },
				Cell: ({ cell }) =>
					(cell.getValue<number>() * 1000).toLocaleString('fi', {
						style: 'currency',
						currency: 'EUR',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}),
			},
			{ header: 'Turnover Change (% YOY)', accessorKey: 'FiguresTurnoverChange', type: 'numeric' },
			{ header: 'Financial Year', accessorKey: 'FiguresFinancialYear' },
			{ header: 'Staff Count', accessorKey: 'FiguresStaff', type: 'numeric' },
			{ header: 'Personnel Change', accessorKey: 'FiguresPersonnelChange', type: 'numeric' },
			{ header: 'EBITDA', accessorKey: 'FiguresEBITDA', type: 'numeric' },
			{ header: 'Quick Ratio', accessorKey: 'FiguresQuickRatio', type: 'numeric' },
			{
				header: 'Total Assets',
				accessorKey: 'FiguresTotalAssets',
				type: 'currency',
				currencySetting: { currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 },
				Cell: ({ cell }) =>
					(cell.getValue<number>() * 1000).toLocaleString('fi', {
						style: 'currency',
						currency: 'EUR',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}),
			},
			{
				header: 'Operating Profit',
				accessorKey: 'FiguresOperatingProfit',
				type: 'currency',
				currencySetting: { currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 },
				Cell: ({ cell }) =>
					(cell.getValue<number>() * 1000).toLocaleString('fi', {
						style: 'currency',
						currency: 'EUR',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}),
			},
			{ header: 'Current Ratio', accessorKey: 'FiguresCurrentRatio', type: 'numeric' },
			{ header: 'Turnover Category', accessorKey: 'FiguresTurnoverCategory' },
			{ header: 'ROE (%)', accessorKey: 'FiguresRoe', type: 'numeric' },
			{ header: 'Parent Company Profinder ID', accessorKey: 'ConcernParentCompanyProfinderId' },
			{ header: 'Parent Company Business ID', accessorKey: 'ConcernParentCompanyBusinessId' },
			{ header: 'Data Fetch Exception', accessorKey: 'DetailedDataFetchException' },
			{ header: 'Data Successfully Fetched', accessorKey: 'DetailedDataFetched', type: 'boolean' },
			{ header: 'Has Severa Project', accessorKey: 'HasSeveraProject', type: 'boolean' },
			{ header: 'Has Severa Proposal', accessorKey: 'HasSeveraProposal', type: 'boolean' },
			{ header: 'Has Severa Activity', accessorKey: 'HasSeveraActivity', type: 'boolean' },
			{ header: 'Figures Amazing 40', accessorKey: 'FiguresAmazing40', type: 'number' },
			{ header: 'Main TOL Class', accessorKey: 'MainTolClass' },
			{ header: 'Has Severa Invoice', accessorKey: 'HasSeveraInvoice', type: 'boolean' },
		],
		[]
	);

	const [tableParams, setTableParams] = useState<{ attachData: string; select: number }>({
		// serverSideFilter: true,
		attachData: 'None',
		select: 100,
	});
	const [dataIncludes, setDataIncludes] = useState<string[]>([]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value },
		} = event;

		setTableParams({
			...tableParams,
			[event.target.name]: value === 'on' ? event.target.checked : value,
		});
	};

	const [customQuery, setCustomQuery] = useState<string | null>(null);
	const [columnFilters, setColumnFilters] = useState<any[]>([]);
	const [parsedFilters, setParsedFilters] = useState<any[]>([]);

	let { data: constants } = useConstantDataQuery({});
	const [customerSearchTrigger, customerSearchStatus, previousArgs] = useLazySearchCustomersQuery();
	const { data, isLoading, isFetching } = customerSearchStatus;

	const table = useMaterialReactTable({
		// @ts-ignore
		columns,
		// @ts-ignore
		data: data ?? [],
		renderDetailPanel:
			!tableParams.attachData || tableParams.attachData === 'None'
				? undefined
				: ({ row }) => {
						return (
							<Stack sx={{ background: '#ccc' }} spacing={4}>
								<ContactTable data={row?.original?.Contacts} />
								<FinancialTable data={row?.original?.Financials} />
								<BFAdmittedTable data={row?.original?.BFAdmitted} />
							</Stack>
						);
				  },
		manualFiltering: false, //tableParams.serverSideFilter, //turn off client-side filtering
		onColumnFiltersChange: setColumnFilters,
		state: {
			columnFilters,
			isLoading: isLoading || isFetching,
			showProgressBars: isLoading || isFetching, //progress bars while refetching
			// isSaving: isLoading || isFetching, //progress bars and save button spinners
		},
		enableColumnFilterModes: true,
		initialState: {
			showColumnFilters: false,
			columnVisibility: {
				BusinessId: true, // showing only a few key columns by default
				Source: true,
				GeneralName: true,
				LocationCity: true,
				GeneralFounded: false,
				FiguresTurnover: true,
				// Assuming the rest are less important for initial display and hiding them
				RegistersVatliability: false,
				RegistersEmployerRegister: false,
				RegistersTradeRegister: false,
				RegistersPrepaymentRegister: false,
				TolCode: false,
				IndustryTol2008Secondary: false,
				IndustryTol2008Primary: false,
				IndustryProfinderPrimary: false,
				IndustryProfinderSecondary: false,
				SegmentationGrowthclass: false,
				IndustryServiceType: false,
				GeneralHasWWW: false,
				GeneralMainOfficeProfinderId: false,
				GeneralProfinderId: false,
				GeneralMainEmail: false,
				GeneralMainPhoneNumber: false,
				GeneralBusinessForm: false,
				GeneralProduces: false,
				GeneralMobility: false,
				GeneralMarketingName: false,
				GeneralOfficeType: false,
				GeneralStaffCategory: false,
				LocationProvince: false,
				LocationGeoX: false,
				LocationGeoY: false,
				LocationAddress: false,
				LocationPostalCode: false,
				LocationRegionCode: false,
				LocationMunicipalityCode: false,
				LocationRegion: false,
				LocationNeighborhood: false,
				LocationPOAddress: false,
				LocationPOPostalCode: false,
				LocationProvinceCode: false,
				LocationPostalCodeName: false,
				LocationPOPostalCodeName: false,
				EinvoicingServiceAddress: false,
				EinvoicingAddress: false,
				FiguresEquityRatio: false,
				FiguresProfit: false,
				FiguresTurnoverChange: false,
				FiguresFinancialYear: false,
				FiguresStaff: false,
				FiguresPersonnelChange: false,
				FiguresEBITDA: false,
				FiguresQuickRatio: false,
				FiguresTotalAssets: false,
				FiguresOperatingProfit: false,
				FiguresCurrentRatio: false,
				FiguresTurnoverCategory: false,
				FiguresRoe: false,
				ConcernParentCompanyProfinderId: false,
				ConcernParentCompanyBusinessId: false,
				FetchTime: false,
				DetailedDataFetchException: false,
				DetailedDataFetched: false,
				HasSeveraProject: false,
				HasSeveraProposal: false,
				HasSeveraActivity: true,
				FiguresAmazing40: true,
				Founded: true,
				MainTolClass: false,
				HasSeveraInvoice: false,
			},
		},
	});

	const changeCustomQuery = (event: any) => {
		if (event.target) {
			const {
				target: { value },
			} = event;
			setCustomQuery(value);
		} else {
			setCustomQuery(event);
		}
	};

	const launchCustomQuery = async (event: any) => {
		let filters = [...parseFilters(columnFilters, columns)];

		if (customQuery) {
			filters.push({
				id: 'CustomQuery',
				value: customQuery,
				mode: 'custom',
			});
		}

		setParsedFilters(filters);

		const res = await customerSearchTrigger({
			select: tableParams.select,
			filters,
			attach: tableParams.attachData,
		});
	};

	const handleIncludes = (data: string[]) => {
		if (!data) setDataIncludes(['None']);

		setDataIncludes(data.filter(item => item !== 'None'));

		setTableParams({
			...tableParams,
			attachData: data.join(),
		});
	};

	if (!constants) return <div>latailee tietoja.... oleppas hyvä ja odottele</div>;

	return (
		// @ts-ignore
		<ContentContainer>
			<h1>Yritysdata</h1>
			<Stack direction='column' sx={{ width: '100%' }}>
				<FormGroup>
					{constants && constants?.includes && (
						<Autocomplete
							value={dataIncludes}
							freeSolo
							multiple
							options={constants?.includes?.filter((item: string) => item !== 'None' && item !== 'All')}
							getOptionLabel={option => formatMessage({ id: `customerdata.include.choice.${option}` })}
							onChange={(_, data) => handleIncludes(data)}
							renderInput={params => (
								<TextField {...params} fullWidth label={'Liitettävät tiedot'} InputLabelProps={{ shrink: true }} />
							)}
						/>
					)}

					{/* <FormControlLabel
						control={<Switch checked={tableParams.serverSideFilter} onChange={handleChange} name='serverSideFilter' />}
						label='Server-side filtering'
					/> */}
					<FormControl fullWidth>
						<InputLabel variant='standard' htmlFor='uncontrolled-native'>
							Tulokset
						</InputLabel>
						<NativeSelect
							defaultValue={30}
							inputProps={{
								name: 'select',
								id: 'uncontrolled-native',
							}}
							name='select'
							//@ts-ignore
							onChange={handleChange}
						>
							<option value={100}>100</option>
							<option value={1000}>1 000</option>
							<option value={10000}>10 000</option>
							<option value={1000000}>Max</option>
						</NativeSelect>
					</FormControl>
					<TextField label='Query' multiline rows={4} defaultValue='' variant='filled' onChange={changeCustomQuery} />
					{/* 
					<CodeEditor
						language='json'
						placeholder='{ type valida json here }'
						onChange={evn => changeCustomQuery(evn.target.value)}
						padding={15}
						data-color-mode='light'
						style={{
							fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
						}}
					/> */}

					<Button onClick={launchCustomQuery}>Hae kaikkea</Button>
				</FormGroup>
			</Stack>

			<MaterialReactTable table={table} />
		</ContentContainer>
	);
};

export default CustomerDataGrid;
