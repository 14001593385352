import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import FormControlWithLabel from './FormControlWithLabel';

const useStyles = makeStyles(theme => ({
	formControl: {
		paddingTop: theme.spacing(2),
	},
	switchRoot: {
		'& .MuiIconButton-root:hover': {
			backgroundColor: 'transparent',
		},
	},
	switchBase: {
		color: theme.palette.primary.white,
		'&$checked': {
			color: theme.palette.primary.white,
		},
		'&$checked + $track': {
			backgroundColor: theme.palette.success.main,
			opacity: 1,
		},
	},
	track: {}, // empty object is required
	checked: {}, // empty object is required
}));

/**
 * Renders switch form control (checkbox) parameters:
 * @param {name} name of the scenario item
 * @param {scenarios} scenarios scenario object
 * @param {control} control form control (react hook form)
 * @param {checkedVal} checkedVal value -> when checked is true
 * @param {notCheckedVal} notCheckedVal value -> when checked is false
 * @returns
 */
const FormControlledSwitchItem = ({
	name,
	defaultValue,
	label,
	error,
	control,
	checkedVal,
	notCheckedVal,
	checkedLabel,
	notCheckedLabel,
	disabled,
	sx,
	onInputBlur = () => {},
}) => {
	const classes = useStyles();

	return (
		<FormControlWithLabel label={label} error={error} sx={sx}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field: { onChange, value } }) => {
					const checked = value === notCheckedVal ? false : value === checkedVal ? true : value;

					return (
						<FormControlLabel
							control={
								<Switch
									disabled={disabled}
									checked={checked}
									onChange={event => onChange(event.target.checked ? checkedVal : notCheckedVal)}
									onBlur={onInputBlur}
									classes={{
										root: classes.switchRoot,
										switchBase: classes.switchBase,
										track: classes.track,
										checked: classes.checked,
									}}
								/>
							}
							label={checked ? checkedLabel : notCheckedLabel}
						/>
					);
				}}
			/>
		</FormControlWithLabel>
	);
};

export default FormControlledSwitchItem;
