import * as React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import StatusChip from 'components/Chip/StatusChip';
import MainTitle from 'components/Titles/MainTitle';
import { Project } from 'types/dataTypes';

import { useTheme } from '@mui/styles';
import LocalBackupAlert from 'components/LocalBackup/LocalBackupAlert';
import { secodandaryRgb } from 'config/colors';
import { FormattedMessage } from 'react-intl';
import { isLoggedIn } from 'utils/auth/token';
import ProjectDashBoardMenu from '../ProjectDashboard/ProjectDashBoardMenu/ProjectDashBoardMenu';

interface IProjectContainerProps {
	project: Project;
	children?: JSX.Element | JSX.Element[];
	actions?: JSX.Element | JSX.Element[];
	useStickyHeader?: boolean;
	isLocalBackup: boolean;
	deleteLocalBackup: () => void;
	readonly?: boolean;
	hideDropdownMenu?: boolean;
	direction?: 'row' | 'column' | 'column-reverse' | 'row-reverse';
}

const ProjectContainer: React.FunctionComponent<IProjectContainerProps> = ({
	project,
	children,
	actions,
	useStickyHeader = false,
	isLocalBackup,
	deleteLocalBackup = () => {},
	readonly = false,
	hideDropdownMenu = false,
	direction = 'row',
}) => {
	const theme = useTheme();
	const isAuthenticatedUser = isLoggedIn() && !readonly;

	const sticky = useStickyHeader
		? {
				position: 'sticky',
				top: 0,
				zIndex: 100, // @ts-ignore
				background: theme.palette.primary.secondary,
				//background: `linear-gradient(180deg, rgba(${secodandaryRgb},1) 85%, rgba(${secodandaryRgb},0) 100%)`, // @ts-ignore
				//borderLeft: `3px solid ${theme.palette.primary.secondary}`, // @ts-ignore
				boxShadow: `rgba(${secodandaryRgb}, 1) 10px 1px 6px`,
		  }
		: {};

	return (
		<Stack spacing={2}>
			<Box sx={{ pt: 1, ...sticky }}>
				<LocalBackupAlert isLocalBackup={isLocalBackup} deleteLocalBackup={deleteLocalBackup} />
				<Stack direction={direction} justifyContent='space-between' spacing={2}>
					<Box>
						<Stack direction='row' alignItems='center'>
							<MainTitle margin={false}>
								{project?.projectId && project?.projectName ? (
									project?.projectName
								) : (
									<FormattedMessage id='project.new.project' />
								)}
							</MainTitle>
							<StatusChip sx={{ ml: '1rem' }} status={project?.projectState} />
						</Stack>
						<Typography sx={{ mt: '.5rem', fontSize: '1rem' }} color='primary'>
							{project?.instrumentName} {project?.instrumentProvider ? ` - ${project.instrumentProvider}` : ''}
						</Typography>
					</Box>
					<Stack direction='row' spacing={1} alignItems='flex-end'>
						<Box>{actions}</Box>
						{isAuthenticatedUser && !hideDropdownMenu && <ProjectDashBoardMenu project={project} />}
					</Stack>
				</Stack>
				<Divider sx={{ my: '1rem', mb: '1rem' }} />
			</Box>

			{children}
		</Stack>
	);
};

export default ProjectContainer;
