import { Alert, AlertProps, AlertTitle, Button, Typography } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getCompany, getCompanyId } from 'utils/auth/company';

import { openDialog } from 'containers/Marketplace/dialogSlice';
import { useFetchRfpProfileQuery } from 'containers/Marketplace/rfpProfileApi';

type Props = AlertProps & {};

const RfpProfileStatusDraftAlert: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const { data: rfpProfile, isLoading } = useFetchRfpProfileQuery({ companyId }, { skip: isNil(companyId) });

	if (isLoading || rfpProfile?.status === 'Published') {
		return null;
	}

	return (
		<Alert {...otherProps} severity='info'>
			<AlertTitle>
				<FormattedMessage id='rfpProfileStatusDraft.title' />
			</AlertTitle>
			<Typography color='inherit' sx={{ mb: '0.5rem' }}>
				<FormattedMessage id='rfpProfileStatusDraft.text' />
			</Typography>
			<Button
				size='small'
				onClick={() => {
					dispatch(openDialog({ name: 'UpsertRfpProfileDialog', data: { companyId } }));
				}}
			>
				<FormattedMessage id='rfpProfile.edit' />
			</Button>
		</Alert>
	);
};

export default RfpProfileStatusDraftAlert;
