import { isNil, map } from 'lodash';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import EditIcon from '@mui/icons-material/Edit';
import {
	Avatar,
	Box,
	BoxProps,
	Button,
	Chip,
	DialogActions,
	DialogContent,
	DialogTitle,
	Link,
	Skeleton,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';

import { useGetPublicProfileByidQuery } from 'containers/Marketplace/rfpProfileApi';
import { getCompany, getCompanyId } from 'utils/auth/company';

import EconomicalChart from 'containers/BusinessPlan/BusinessPlanPrint/EconomicalChart';
import BlobDownloader from 'containers/Marketplace/attachment/BlobDownloader';
import RfpProfileSocialMediaIconButton from 'containers/Marketplace/profile/RfpProfileSocialMediaIconButton';
import ReadProfileTeamList from './ReadProfileTeamList';

type Props = BoxProps & {
	companyId: string;
	onEditClick: () => void;
	onCloseClick: () => void;
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

const ReadRfpProfileForm: React.FC<Props> = ({ companyId, onEditClick, onCloseClick, ...otherProps }) => {
	const currentCompanyId = getCompanyId() ?? getCompany()?.CompanyId;
	const { formatMessage } = useIntl();
	const [tab, setTab] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	const { data: rfpProfile, isLoading } = useGetPublicProfileByidQuery({ companyId }, { skip: isNil(companyId) });

	const economicalData = [];

	if (rfpProfile?.economicalInfo) {
		const sortedEconomicalData = rfpProfile?.economicalInfo.slice().sort((a, b) => a.year - b.year);
		economicalData.push(...sortedEconomicalData);
	}

	return (
		<>
			<Box {...otherProps}>
				<Box sx={{ pt: 4, px: 2 }}>
					{isLoading ? (
						<>
							<Skeleton height={100} />
						</>
					) : (
						<Stack
							spacing={2}
							flexDirection={{ xs: 'column', sm: 'row' }}
							justifyContent='center'
							alignItems={{ xs: 'center', sm: 'flex-start' }}
						>
							{isLoading ? (
								<Skeleton height={100} width={100} />
							) : (
								<BlobDownloader url={rfpProfile?.logo}>{src => <Avatar variant='logo1' src={src} />}</BlobDownloader>
							)}
							<Stack flexDirection='column' alignItems={{ xs: 'center', sm: 'flex-start' }}>
								<DialogTitle sx={{ p: 0 }}>{rfpProfile?.companyName}</DialogTitle>
								<Chip variant='filled' label={rfpProfile?.companyId} />
								{!isNil(rfpProfile?.companySiteUrl) && (
									<Link href={rfpProfile?.companySiteUrl} target='blank'>
										{rfpProfile?.companySiteUrl}
									</Link>
								)}
								<Stack flexDirection='row'>
									{map(rfpProfile?.socialMedia, socialMediaProfile => (
										<RfpProfileSocialMediaIconButton
											key={socialMediaProfile.service}
											service={socialMediaProfile.service}
											profile={socialMediaProfile.url}
										/>
									))}
								</Stack>
							</Stack>
						</Stack>
					)}
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={tab} onChange={handleChange} aria-label='profile tabs'>
							<Tab label={formatMessage({ id: 'rfp.profile.tabs.general' })} {...a11yProps(0)} />
							<Tab label={formatMessage({ id: 'rfp.profile.tabs.team' })} {...a11yProps(1)} />
							{economicalData && economicalData.length > 0 && (
								<Tab label={formatMessage({ id: 'rfp.profile.tabs.economy' })} {...a11yProps(2)} />
							)}
						</Tabs>
					</Box>
				</Box>
				<DialogContent sx={{ borderTop: 'none' }}>
					{isLoading ? (
						<>
							<Skeleton height={100} />
							<Skeleton height={100} />
							<Skeleton height={100} />
						</>
					) : (
						<>
							<TabPanel value={tab} index={0}>
								<Box
									sx={{
										display: rfpProfile?.categories?.length && rfpProfile?.categories?.length > 0 ? 'block' : 'none',
										mt: '.5rem',
									}}
								>
									{map(rfpProfile?.categories, (category, index) => (
										<Chip
											key={index}
											variant='filled'
											sx={{ mr: '.5rem' }}
											label={<FormattedMessage id={`rfp.rfpCategory.${category}`} />}
										/>
									))}
								</Box>
								<Typography variant='subtitle1' sx={{ mt: '1rem', px: 0.5 }}>
									<FormattedMessage id='rfp.profile.ingress' />
								</Typography>
								<Typography variant='body1' sx={{ py: 2, px: 0.5 }}>
									{rfpProfile?.companyIngress}
								</Typography>
								<Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
									{map(rfpProfile?.infoItems, infoItem => (
										<Box
											key={infoItem.infoId}
											sx={{ px: '14px', py: '12px', border: 1, borderColor: 'divider', borderRadius: 1 }}
										>
											<Typography variant='subtitle2' sx={{ lineHeight: 1.5, color: 'text.secondary' }}>
												{infoItem.title}
											</Typography>
											<Typography variant='body1'>{infoItem.value}</Typography>
										</Box>
									))}
								</Box>

								<Typography variant='subtitle1' sx={{ mt: '1rem', px: 0.5 }}>
									<FormattedMessage id='rfp.profile.description' />
								</Typography>
								<Typography variant='body1' sx={{ py: 2, px: 0.5 }}>
									{rfpProfile?.generalDescription}
								</Typography>

								{rfpProfile?.contactInformation && (
									<>
										<Typography sx={{ px: 0.5 }} variant='h4'>
											<FormattedMessage id='rfp.profile.contact.title' />
										</Typography>
										<Typography sx={{ px: 0.5 }} variant='body1'>
											{rfpProfile?.contactInformation}
										</Typography>
									</>
								)}
							</TabPanel>
							<TabPanel value={tab} index={1}>
								<ReadProfileTeamList team={rfpProfile?.team} />
							</TabPanel>
							<TabPanel value={tab} index={2}>
								<EconomicalChart data={economicalData ?? []} />
							</TabPanel>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
						<Button variant='text' size='small' color='neutral' onClick={onCloseClick}>
							<FormattedMessage id='rfpProfile.close' />
						</Button>
						{companyId === currentCompanyId && (
							<Button size='small' endIcon={<EditIcon />} variant='contained' color='primary' onClick={onEditClick}>
								<FormattedMessage id='rfpProfile.edit' />
							</Button>
						)}
					</Stack>
				</DialogActions>
			</Box>
		</>
	);
};

export default ReadRfpProfileForm;
