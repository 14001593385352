import * as yup from 'yup';

export const getProjectTaskValidationSchema = (intl) => {
    return yup.object().shape({
        projectTaskName: yup.string().required(
            `${intl.formatMessage({ id: 'project.task.form.projectTaskName' })} ${intl.formatMessage({ id: 'message.isRequired', })}`
        ),
        projectId: yup.string().required(
            `${intl.formatMessage({ id: 'project.task.form.projectId' })} ${intl.formatMessage({ id: 'message.isRequired', })}`
        ),
        projectTaskDescription: yup.string().required(
            `${intl.formatMessage({ id: 'project.task.form.projectTaskDescription' })} ${intl.formatMessage({ id: 'message.isRequired', })}`
        ),
        projectTaskGoals: yup.string().required(
            `${intl.formatMessage({ id: 'project.task.form.projectTaskGoals' })} ${intl.formatMessage({ id: 'message.isRequired', })}`
        ),
    });
};