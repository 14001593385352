import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomerList from './CustomerList';
import { loadNextPage, loadCustomers } from 'containers/Advanced/slices';
import { ROWS_PER_PAGE } from 'config/environment';

const Customers = ({ phrase, setIsData, selectedIds, setSelectedIds, toggleImportant }) => {
    const dispatch = useDispatch();

    const customers = useSelector(state => state.customers);
    const isCustomersLoaded = customers?.isCustomersLoaded;

    useEffect(() => {
        if (isCustomersLoaded === false) dispatch(loadCustomers(ROWS_PER_PAGE));
    }, [isCustomersLoaded]); // eslint-disable-line

    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

    const fetchdata = rowCount => {
        if (customers.loading) {
            // don't fetch next page if loading
            return;
        }

        setRowsPerPage(rowCount);
        dispatch(loadNextPage(rowCount));
    };

    const reloadCustomers = rowCount => {
        setRowsPerPage(rowCount);
        dispatch(loadCustomers(rowCount));
    };

    return (
        <CustomerList
            customersLoading={customers?.loading}
            removeLoading={customers?.removeLoading}
            customers={customers?.customers}
            rowsPerPage={rowsPerPage}
            fetchdata={fetchdata}
            reloadCustomers={reloadCustomers}
            setIsData={setIsData}
            phrase={phrase}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            toggleImportant={toggleImportant}
        />
    );
};

export default Customers;
