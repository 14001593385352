import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import LeftRightContainer from 'components/Containers/LeftRightContainer';
import ConfirmUnsaved from 'components/CustomPrompt/ConfirmUnsaved';
import StyledChip from 'components/Tags/StyledChip';
import ProjectModalFormBase from 'containers/Projects/Project/ProjectForm/ProjectModalFormBase';
import CustomTooltip from '../CustomTooltip';

import { isContributorToken } from 'utils/auth/token';
import { getEuroFormatted } from 'utils/formatters/getEuroFormatted';

import { projectState } from 'containers/Projects/utils';
import { isFreemiumToken } from 'utils/auth/company';
import './index.css';

const useStyles = makeStyles(theme => ({
	td: {
		textOverflow: 'ellipsis',
		padding: 0,
		textAlign: 'center',
	},
	tooltipContainer: {
		padding: '0.5rem',
	},
	titleContainer: {
		marginBottom: '1.25rem',
	},
	tooltipProjectName: {
		fontSize: '1.1rem',
		fontWeight: 600,
		marginBottom: theme.spacing(0.5),
	},
	tooltipInstrumentFlexContainer: {
		display: 'flex',
	},
	tooltipInstrumentName: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	tooltipInstrumentProvider: {
		flex: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	tooltipDate: {
		fontSize: '0.9rem',
		marginBottom: theme.spacing(1),
	},
	flexContainer: {
		display: 'flex',
		marginTop: theme.spacing(1),
	},
	tooltipKey: {
		flex: 1,
		fontSize: '0.9rem',
		fontWeight: 500,
		marginRight: theme.spacing(1),
		minWidth: '7.5rem',
	},
	tooltipProp: {
		flex: 1,
		fontSize: '0.9rem',
	},
	gridContainer: {
		height: theme.spacing(5),
		borderRadius: theme.spacing(1),
		marginBottom: theme.spacing(2),
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		backgroundColor: theme.palette.primary.beige,
		cursor: 'pointer',
	},
	gridItem: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		zIndex: 10,
		width: '100%',
	},
	gridContainerPrint: {
		height: theme.spacing(5),
		marginBottom: theme.spacing(2),
		backgroundColor: 'transparent',
	},
	name: {
		display: 'inline-block',
		fontSize: '0.8rem',
		fontWeight: 700,
		color: theme.palette.primary.white,
		height: '100%',
	},
	namePrintRight: {
		fontSize: '0.8rem',
		fontWeight: 700,
		paddingLeft: theme.spacing(2),
		color: theme.palette.primary.main,
	},
	namePrintLeft: {
		fontSize: '0.8rem',
		fontWeight: 700,
		paddingRight: theme.spacing(2),
		color: theme.palette.primary.main,
	},
	transparent: {
		display: 'none',
	},
}));

const TimelineProjectItem = ({ project, instruments, printView, profile, isCustom, level, instrumentId, readonly }) => {
	const classes = useStyles();
	const theme = useTheme();
	const intl = useIntl();
	const isFreemium = isFreemiumToken();

	const [projectOpen, setProjectOpen] = useState(false);
	const [tooltipId, setTooltipId] = useState(false);
	const [dirtyFields, setDirtyFields] = useState({});

	const openProjectModal = () => setProjectOpen(true);
	const closeConfirm = () => {
		setDirtyFields(false);
	};
	const closeConfirmAndModal = () => {
		setDirtyFields(false);
		setProjectOpen(false);
	};

	const handleProjectModalClose = dirtyFields => {
		if (dirtyFields && !dirtyFields.nativeEvent && Object.keys(dirtyFields)?.length > 0) {
			setDirtyFields(dirtyFields);
		} else {
			setProjectOpen(false);
		}
	};

	const title = (
		<div className={classes.tooltipContainer}>
			<div className={classes.titleContainer}>
				<LeftRightContainer
					left={
						<Typography component='p' className={classes.tooltipProjectName}>
							{project.projectName}
						</Typography>
					}
					right={
						project.projectState === projectState.draft && isContributorToken() ? (
							<StyledChip label={intl.formatMessage({ id: 'financial.plan.state.draft' })} />
						) : (
							''
						)
					}
				/>
				<div className={classes.tooltipInstrumentFlexContainer}>
					<Typography component='p' className={classes.tooltipInstrumentName}>
						{project.instrumentName}&nbsp;-
					</Typography>
					<Typography component='p' className={classes.tooltipInstrumentProvider}>
						&nbsp;{project.instrumentProvider}
					</Typography>
				</div>
				<Typography component='p' className={classes.tooltipDate}>
					{moment(project.startDate).format('DD.MM.YYYY')}-{moment(project.endDate).format('DD.MM.YYYY')}
				</Typography>
			</div>
			<div className={classes.flexContainer}>
				<Typography component='p' className={classes.tooltipKey}>
					{intl.formatMessage({ id: 'timeline.tooltip.grantAmount' })}:
				</Typography>
				<Typography component='p' className={classes.tooltipProp}>
					{getEuroFormatted(project.amountToApply)}
				</Typography>
			</div>
			<div className={classes.flexContainer}>
				<Typography component='p' className={classes.tooltipKey}>
					{intl.formatMessage({ id: 'timeline.tooltip.projectSize' })}:
				</Typography>
				<Typography component='p' className={classes.tooltipProp}>
					{getEuroFormatted(project.projectSize)}
				</Typography>
			</div>
		</div>
	);

	return (
		<>
			<ProjectModalFormBase
				readonly={readonly}
				open={projectOpen}
				handleClose={handleProjectModalClose}
				project={project}
				instruments={instruments}
				datareset={true}
			/>
			<ConfirmUnsaved
				open={Object.keys(dirtyFields)?.length > 0}
				handleCancel={closeConfirm}
				handleConfirm={closeConfirmAndModal}
				dirtyFields={dirtyFields}
				translation='project.form'
			/>
			<Grid
				container
				justifyContent='center'
				alignItems='center'
				onClick={() => !printView && !profile && !project?.mock && openProjectModal()}
				className={classes.gridContainer}
				style={{
					backgroundColor: isFreemium
						? theme.palette.primary.grayishBlue
						: isCustom
							? theme.palette.primary.main
							: level === 0 || !level
								? theme.palette.error.main
								: level === 1
									? theme.palette.primary.darkYellow
									: level === 2
										? theme.palette.success.main
										: level === 3 || (isCustom && theme.palette.primary.main),
					opacity: instrumentId === tooltipId && 0.8,
				}}
				sx={{
					border: project.projectState === projectState.draft && isContributorToken() ? '3px dotted #ccc' : null,
					position: 'relative',
					opacity: project.projectState === projectState.draft && isContributorToken() ? 0.8 : 1,
					'&::after': {
						content:
							project.projectState === projectState.draft && isContributorToken()
								? `"${intl.formatMessage({ id: 'financial.plan.state.draft' })}"`
								: '""',
						position: 'absolute',
						zIndex: '1000',
						top: '0.6rem',
						right: '-3.7rem',
						fontSize: '1rem',
						textTransform: 'uppercase',
						color: '#ccc',
						fontWeight: 600,
					},
				}}
			>
				<CustomTooltip
					onOpen={() => setTooltipId(instrumentId)}
					onClose={() => setTooltipId(false)}
					title={title}
					placement='top'
					enterTouchDelay={0}
					leaveTouchDelay={2000}
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 200 }}
				>
					<Grid item className={classes.gridItem}>
						<Typography variant='subtitle1' className={`${classes.name} namePrint`}>
							{project.instrumentName?.toUpperCase()}&nbsp;-
						</Typography>
						<Typography variant='subtitle1' className={`${classes.name} namePrint`}>
							&nbsp;{project.projectName?.toUpperCase()}
						</Typography>
					</Grid>
				</CustomTooltip>
			</Grid>
		</>
	);
};

export default TimelineProjectItem;
