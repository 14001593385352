import React from 'react';

import { IconButton, Tooltip } from '@mui/material';

const TooltippedIconButton = ({ tooltip, children, onClick }) => {
    return (
        <Tooltip title={tooltip}>
            <IconButton color='primary' onClick={onClick} sx={{ ml: 1, background: 'rgba(72, 46, 81, 0.08)' }}>
                {children}
            </IconButton>
        </Tooltip>
    );
};

export default TooltippedIconButton;