import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';

import { FormControlledInput } from 'components/Form';
import ImageAttachment from './ImageAttachment';

const TeamMemberForm = forwardRef(({ control, errors, teamMember, onSubmit, handleSubmit, setValue, readonly }, formRef) => {
    const intl = useIntl();

    return (
        <form ref={formRef} noValidate onSubmit={handleSubmit(data => onSubmit(data))}>
            <Controller
                name='memberId'
                defaultValue={teamMember.memberId ?? ''}
                control={control}
                render={({ field: { value, } }) => <input type='hidden' value={value ?? ''} />}
            />
            <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                    <ImageAttachment setValue={setValue} avatar={teamMember.avatar} readonly={readonly} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={2} direction='column'>
                        <Grid item xs={12}>
                            <FormControlledInput
                                disabled={readonly}
                                defaultValue={teamMember.firstname ?? ''}
                                name='firstname'
                                control={control}
                                error={errors?.name?.message}
                                label={intl.formatMessage({ id: 'business.plan.form.firstname' })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlledInput
                                disabled={readonly}
                                defaultValue={teamMember.lastname ?? ''}
                                name='lastname'
                                control={control}
                                error={errors?.name?.message}
                                label={intl.formatMessage({ id: 'business.plan.form.lastname' })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlledInput
                                disabled={readonly}
                                defaultValue={teamMember.position ?? ''}
                                name='position'
                                control={control}
                                error={errors?.position?.message}
                                label={intl.formatMessage({ id: 'business.plan.form.position' })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlledInput
                                disabled={readonly}
                                defaultValue={teamMember.description ?? ''}
                                name='description'
                                control={control}
                                error={errors?.description?.message}
                                label={intl.formatMessage({ id: 'business.plan.form.description' })}
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlledInput
                                disabled={readonly}
                                defaultValue={teamMember.linkedInUrl ?? ''}
                                name='linkedInUrl'
                                control={control}
                                error={errors?.linkedInUrl?.message}
                                label={intl.formatMessage({ id: 'business.plan.form.linkedInUrl' })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
});

export default TeamMemberForm;
