import moment from 'moment';
import { forwardRef, useCallback, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';

import MissingInfoBox from 'components/MissingInfoBox/MissingInfoBox';
import MissingInfoText from 'components/MissingInfoText/MissingInfoText';
import ProjectModalForm from 'containers/Projects/Project/ProjectForm/ProjectModalForm';
import PastNowDiv from './PastNowDiv';
import SmoothDiv from './SmoothDiv';
import TColumns from './TColumns';
import TFooterQuarter from './TFooterQuarter';
import TFooterYear from './TFooterYear';
import TimelineMain from './TimelineMain';

import Button from 'components/Button/Button';
import { sort } from 'containers/Projects/utils';
import { isFreemiumToken } from 'utils/auth/company';
import { getAccessToken } from 'utils/auth/token';
import { regexCompany } from 'utils/constants/regex';
import { useClientWidth } from 'utils/helpers/useClientWidth';

const useStyles = makeStyles(theme => ({
	timelineContainerWrapper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		'@media print': {
			pageBreakAfter: 'always',
		},
	},
	relativeDiv: {
		position: 'relative',
		flexGrow: 1,
	},
	container: {
		position: 'relative',
		height: '100%',
		overflow: 'auto',
		'&::-webkit-scrollbar': {
			width: '5px',
			height: '5px',
		},
	},
	titleContainer: {
		marginBottom: theme.spacing(2),
	},
	title: {
		fontSize: '1.3125rem',
		fontWeight: 600,
	},
	demoLabel: {
		fontWeight: 700,
		paddingTop: theme.spacing(0.5),
		paddingRight: theme.spacing(1.5),
		paddingBottom: theme.spacing(0.5),
		paddingLeft: theme.spacing(1.5),
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		color: theme.palette.primary.white,
		backgroundColor: theme.palette.primary.main,
		borderRadius: '0.875rem',
	},
	quarter: {
		fontSize: '0.8125rem',
	},
	table: {
		tableLayout: 'fixed',
		borderCollapse: 'collapse',
		background: 'linear-gradient(90deg, rgba(72, 46, 81, 0.2) 1px, transparent 1px)',
		backgroundColor: theme.palette.primary.white,
		height: '100%',
		margin: '0 auto',
		'@media print': {
			width: '650px !important',
			background: 'transparent',
			'& tr td': {
				width: 'auto !important',
			},
		},
	},
	tfoot: {
		backgroundColor: theme.palette.primary.white,
		position: 'sticky',
		bottom: '-1px',
		zIndex: 21,
	},
	tbody: {
		position: 'relative',
		'& tr:last-child': {
			'& td:last-child > div': {
				marginBottom: theme.spacing(1),
			},
		},
	},
	tbodyFirstZero: {
		'& tr:nth-child(2)': {
			'& td:first-child > div': {
				marginTop: theme.spacing(1),
			},
		},
	},
	tbodyFirstNotZero: {
		'& tr:nth-child(2)': {
			'& td:nth-child(2) > div': {
				marginTop: theme.spacing(1),
			},
		},
	},
	openButtonWrapper: {
		paddingLeft: theme.spacing(2),
	},
	contactButton: {
		//textTransform: 'none',
		textDecoration: 'underline',
		//fontWeight: 600,
		padding: 0,
	},
	quarterButton: {
		//textTransform: 'none',
	},
	bookTime: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
	},
	updated: {
		fontSize: '1rem',
		fontWeight: 300,
		color: 'rgba(72, 46, 81, 0.6)',
	},
	demoText: {
		fontSize: '1rem',
		color: theme.palette.primary.main,
	},
	addInstrumentButtonBox: {
		paddingTop: theme.spacing(2),
	},
	addInstrumentButton: {
		//textTransform: 'none',
		//fontWeight: 600,
		color: theme.palette.primary.white,
		backgroundColor: theme.palette.success.main,
		'&:hover': {
			backgroundColor: theme.palette.success.main,
			opacity: 0.8,
		},
	},
	missing: {
		height: '30rem',
		[theme.breakpoints.up('md')]: {
			height: '35rem',
		},
	},
	loading: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		backgroundColor: '#eeece9',
	},
}));

const Timeline = forwardRef(
	(
		{
			loading,
			timeline,
			projects,
			instruments,
			onScroll,
			hasHorizontalScrollbar,
			isRenderRightSmooth,
			isRenderLeftSmooth,
			padding,
			hideContactCalendar,
			scrollToNoInsruments,
			companyId,
			pathname = '',
			timelinePrintWidth,
			timelineContainerWidth,
			printView = false,
			profile = false,
			openFinancialPlanDrawer,
			maxHeight,
			readonly,
		},
		ref
	) => {
		const classes = useStyles();
		const intl = useIntl();
		const theme = useTheme();
		const history = useHistory();
		const down480 = useMediaQuery(theme.breakpoints.down(480));
		const mdUp = useMediaQuery(theme.breakpoints.up('md'));
		const accessToken = getAccessToken();
		const isAuthenticated = accessToken !== null;
		const isFreemium = isFreemiumToken();

		const numberOfMonth = timeline?.numberOfMonth >= 12 ? timeline?.numberOfMonth : 12;

		const [column, setColumn] = useState(null);
		const [tableWidth, setTableWidth] = useState(null);

		const minColumnWidth = 45;
		const timelineContainerWrapperPadding = printView ? 0 : 16;

		const [clientWidth, measuredRef] = useClientWidth(timelineContainerWidth); // this is probably only needed for Profile page, otherwise using the container width is enough
		const width = timelinePrintWidth ?? clientWidth;

		const calculateTableWidth = useCallback(
			width => {
				if (width) {
					const columnWidth = Math.floor((width - (padding * 2 + timelineContainerWrapperPadding * 2)) / numberOfMonth - 1);

					// const extraPixel = !printView && ref.current?.scrollHeight > ref.current?.clientHeight ? 2 : 1; // 2 --> if vertical scrollbar
					if (columnWidth < minColumnWidth) {
						setTableWidth(numberOfMonth * minColumnWidth + 1);
						setColumn(minColumnWidth);
					} else {
						setTableWidth(numberOfMonth * columnWidth + 1);
						setColumn(columnWidth);
					}
				}
			},
			[padding, timelineContainerWrapperPadding, numberOfMonth]
		);

		useLayoutEffect(() => {
			calculateTableWidth(width);
		}, [calculateTableWidth, width]);

		useLayoutEffect(() => {
			if (hasHorizontalScrollbar) {
				hasHorizontalScrollbar(tableWidth);
			}
		}, [hasHorizontalScrollbar, tableWidth]);

		const startDate = timeline?.startDate;
		const tbodyFirstZero = instruments && instruments[0]?.monthFromStart === 0;

		const timelineProjects = projects && sort(projects, item => item.startDate);

		const projectsWithLevel = timelineProjects?.map(project => ({
			...project,
			level: instruments.find(item => item.id === project.instrumentId)?.level ?? 0,
		}));

		let numberOfMonthArray = [];
		for (let i = 1; i <= numberOfMonth; i++) {
			numberOfMonthArray.push(i);
		}

		const quarters = numberOfMonthArray.filter(item => item % 3 === 0);

		const containerElement = ref?.current;

		const containerHeight = containerElement?.clientHeight;
		const containerWidth = containerElement?.clientWidth;

		const scrollHeight = containerElement?.scrollHeight;
		const scrollWidth = containerElement?.scrollWidth;

		const isContainerVerticalScrollbar = scrollHeight > containerHeight;
		const isContainerHorizontalScrollbar = scrollWidth > containerWidth;

		const monthsFromStartToNow = moment(new Date()).diff(new Date(startDate), 'months', true).toFixed(3);
		const scrollXCoordinate = Math.floor((monthsFromStartToNow / numberOfMonth) * scrollWidth) - containerWidth / 2;

		useLayoutEffect(() => {
			if (containerElement && isContainerHorizontalScrollbar) {
				containerElement.scroll({
					left: scrollXCoordinate,
					behavior: 'smooth',
				});
			}
		}, [containerElement, isContainerHorizontalScrollbar, scrollXCoordinate]);

		const financialPlanPath = regexCompany.test(pathname) ? `/company/${companyId}/financialplan` : '/financialplan';

		const minPrintHeight = '20rem';

		const openFinancialPlan = () => (profile ? openFinancialPlanDrawer() : history.push(financialPlanPath));

		if (loading) {
			return (
				<div className={classes.loading}>
					<CircularProgress size={24} />
				</div>
			);
		}

		return projects && Object.keys(projects)?.length ? (
			<div
				ref={measuredRef}
				className={classes.timelineContainerWrapper}
				style={{
					height: printView && 'unset', // is required for print mode
					minHeight: printView && minPrintHeight,
					maxHeight: maxHeight ? maxHeight : printView && 'none',
					padding: printView ? 0 : timelineContainerWrapperPadding,
					backgroundColor: width ? theme.palette.primary.white : theme.palette.primary.secondary, // for loading purposes
				}}
			>
				{!printView && (
					<Grid
						container
						alignItems='center'
						wrap='nowrap'
						className={classes.titleContainer}
						style={{ marginBottom: down480 && 0 }}
					>
						<Grid item style={{ flexGrow: 1 }}>
							<Grid container justifyContent='space-between' alignItems='center'>
								<Grid item>
									<Grid container>
										<Grid item>
											<Typography color='primary' className={classes.title}>
												{intl.formatMessage({ id: 'financial.plan.timeline.title' })}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Typography component='p' className={classes.updated}>
										<FormattedMessage id='timeline.updated' /> {moment(timeline?.created).format('DD.MM.YYYY HH:mm')}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						{!down480 && !printView && !readonly && !isFreemium && (
							<Grid item className={classes.openButtonWrapper} style={{ minWidth: '12rem' }}>
								<Button color='success' onClick={openFinancialPlan}>
									{intl.formatMessage({ id: 'financial.plan.open.button' })}
								</Button>
							</Grid>
						)}
					</Grid>
				)}
				{down480 && !printView && !readonly && !isFreemium && (
					<Box textAlign='right'>
						<Button
							variant='contained'
							color='primary'
							onClick={() => history.push(financialPlanPath)}
							className={classes.openButton}
							style={{ marginTop: '0.5rem', marginBottom: '0.75rem' }}
						>
							{intl.formatMessage({ id: 'financial.plan.open.button' })}
						</Button>
					</Box>
				)}
				{!printView && !readonly && !profile && hideContactCalendar && (
					<Grid container className={classes.titleContainer}>
						<Grid item>
							<Typography className={classes.demoText}>
								Voit ottaa meihin yhteyttä suoraan asiantuntijaan klikkaamalla alla olevaa{' '}
								<Button color='primary' onClick={scrollToNoInsruments} className={classes.contactButton}>
									{intl.formatMessage({ id: 'common.take.contact' })}
								</Button>
							</Typography>
						</Grid>
					</Grid>
				)}
				<div className={classes.relativeDiv}>
					<div
						ref={ref}
						onScroll={onScroll}
						style={{
							paddingRight: `${padding}px`,
							paddingLeft: `${padding}px`,
							// FIXME: this is not precise calculation
							maxHeight: maxHeight
								? maxHeight
								: printView
									? 'none'
									: profile && !mdUp
										? '24.2rem'
										: profile && mdUp
											? '29.2rem'
											: !printView && !profile
												? '25rem'
												: 'none',
						}}
						className={`${classes.container} timelineContainerPrint`}
					>
						<table
							className={classes.table}
							style={{
								width: tableWidth,
								backgroundSize: `${column * 3}px`,
								minHeight: printView && minPrintHeight,
							}}
						>
							<tfoot className={classes.tfoot}>
								<TFooterQuarter quarters={quarters} />
								<TFooterYear quarters={quarters} startDate={startDate} column={column} padding={padding} />
							</tfoot>
							<tbody className={`${classes.tbody} ${tbodyFirstZero ? classes.tbodyFirstZero : classes.tbodyFirstNotZero} `}>
								<TColumns numberOfMonth={numberOfMonth} columnWidth={column} />
								<TimelineMain
									instruments={instruments}
									projects={projectsWithLevel}
									numberOfMonth={numberOfMonth}
									printView={printView}
									profile={profile}
									readonly={readonly}
								/>
								<PastNowDiv column={column} monthsFromStartToNow={monthsFromStartToNow} />
							</tbody>
						</table>
					</div>
					{isRenderLeftSmooth && <SmoothDiv left={true} isHorizontalScrollbar={isContainerHorizontalScrollbar} />}
					{isRenderRightSmooth && (
						<SmoothDiv
							right={true}
							isHorizontalScrollbar={isContainerHorizontalScrollbar}
							isVerticalScrollbar={isContainerVerticalScrollbar}
						/>
					)}
				</div>
				{isAuthenticated && !printView && !readonly && !profile && (
					<Box textAlign='right' className={classes.addInstrumentButtonBox}>
						<ProjectModalForm instruments={instruments} readonly={readonly} />
					</Box>
				)}
			</div>
		) : (
			<div className={classes.missing}>
				<MissingInfoBox>
					<MissingInfoText>{intl.formatMessage({ id: 'timeline.missing' })}</MissingInfoText>
					<Typography sx={{ m: 1, fontSize: '1rem' }}>
						{intl.formatMessage({ id: readonly || profile ? 'timeline.profile.noInstruments' : 'timeline.updateProfile' })}
					</Typography>
					{!readonly && <ProjectModalForm instruments={instruments} first={true} readonly={readonly} />}
				</MissingInfoBox>
			</div>
		);
	}
);

export default Timeline;
