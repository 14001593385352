import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';
import { isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AnalysisChart from 'components/AnalysisChart';
import Button from 'components/Button/Button';
import ContentContainer from 'components/Containers/ContentContainer';
import NoInstruments from 'components/NoInstruments';
import Spinner from 'components/Spinner/Spinner';
import ScenarioModal from 'containers/Scenario/ScenarioModal';
import Score from 'containers/Score';
import BusinessPlanWidget from './BusinessPlanWidget';
import DeminimisWidget from './DeminimisWidget';
import HeaderTitle from './HeaderTitle';
import ProfilePhase from './ProfilePhase';
import TimelineContainer from './TimelineContainer/TimelineContainer';

import { getCompanyId, isAdvancedOrCustomerToken } from 'utils/auth/company';
import { isStaticProfile } from 'utils/auth/token';

import { useFetchCompanyCustomerQuery, useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { useFetchInstrumentCategoriesQuery } from 'containers/Instruments/instrumentV2Api';
import { setCurrentInstrumentId } from 'containers/Instruments/slices';
import { openDialog } from 'containers/Marketplace/dialogSlice';
import { useFetchScenarioQuery } from 'containers/Scenario/scenarioV2Api';

import InstrumentCategoryList from 'containers/Instruments/InstrumentList/InstrumentCategoryList';

const useStyles = makeStyles(theme => ({
	flexContainer: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	scoreWrapper: {
		[theme.breakpoints.up('md')]: {
			minWidth: '380px',
			maxWidth: '450px',
		},
	},
	timelineWrapper: {
		flexGrow: 1,
		minWidth: 0,
		paddingTop: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			paddingTop: 0,
			paddingLeft: theme.spacing(2),
		},
	},
	componentWrapper: {
		marginTop: theme.spacing(3),
	},
}));

const Dashboard = ({ openChat, pathname, passPageProps }) => {
	const intl = useIntl();
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));

	const companyId = getCompanyId();

	const {
		data: company,
		isLoading: isCompanyLoading,
		isFetching: isCompanyFetching,
	} = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	const { data: companyCustomer, isLoading: isCompanyCustomerLoading } = useFetchCompanyCustomerQuery(
		{ companyId },
		{ skip: !companyId }
	);

	const { data: scenario, isLoading: isScenarioLoading } = useFetchScenarioQuery(
		{ companyId, businessId: company?.businessId },
		{ skip: isNil(companyId) || isCompanyLoading || isCompanyFetching }
	);

	const { data: instrumentCategories, isLoading: isInstrumentCategoriesLoading } = useFetchInstrumentCategoriesQuery();

	const { data: referenceCategories, isLoading: isReferenceCategoriesLoading } = useFetchInstrumentCategoriesQuery({
		name: 'references',
	});

	const categories = useMemo(() => {
		if (companyCustomer && referenceCategories) {
			const filteredReferenceCategories = referenceCategories.filter(category => {
				return category?.referenceId === companyCustomer?.referenceId;
			});
			if (filteredReferenceCategories && filteredReferenceCategories.length !== 0) {
				return filteredReferenceCategories;
			}
		}
		return instrumentCategories;
	}, [companyCustomer, instrumentCategories, referenceCategories]);

	// Score
	const score = useSelector(state => state.score);
	const scoreData = score?.score;
	const scoreLoading = score?.loading;
	const missingValues = scenario?.missingValues;

	// Kanban
	const kanban = useSelector(state => state.kanban);
	const projects = kanban?.projects;
	const projectsLoading = !projects?.isLoaded;
	const mock = projects?.mock;
	const projectsArr = projects?.data ?? [];

	const timelineContainerRef = useRef();

	const scrollToTimeline = () => {
		const headerOffset = 30;
		const elementPosition = timelineContainerRef.current?.getBoundingClientRect().top;
		const offsetPosition = elementPosition - headerOffset;

		window.scrollBy({
			top: offsetPosition,
			behavior: 'smooth',
		});
	};

	// Instruments & CompanyProfile
	const instruments = useSelector(state => state.instruments);
	const instrumentsData = instruments?.instruments?.data;
	const instrumentsLoading = instruments?.instruments?.loading;

	const currentInstrumentId = instruments?.currentInstrumentId;
	const currentCategory = instruments?.currentCategory;

	const isAdvancedCompanyOrCustomer = isAdvancedOrCustomerToken();
	const isStaticProfileConst = isStaticProfile();

	// BusinessPlan
	const businessPlan = useSelector(state => state.businessPlan);

	// NoInstruments
	const noInstrumentsRef = useRef();

	const scrollToNoInsruments = () => {
		const headerOffset = smDown ? 80 : 16;
		const elementPosition = noInstrumentsRef.current?.getBoundingClientRect().top;
		const offsetPosition = elementPosition - headerOffset;

		window.scrollBy({
			top: offsetPosition,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		if (company && Object.keys(company)?.length !== 0) {
			passPageProps([{ id: company?.companyId, name: company?.name }]);
		}
	}, [passPageProps, company]);

	// clean up setCurrentInstrumentId & close scenario modal
	useEffect(() => {
		return () => {
			dispatch(setCurrentInstrumentId(''));
		};
	}, [dispatch]);

	const notCompleteProfile = useMemo(() => {
		return scenario && Object.keys(scenario)?.length > 0 && scenario?.missingValues?.length > 0;
	}, [scenario]);

	const openScenarioDialog = useCallback(() => {
		if (notCompleteProfile) {
			dispatch(openDialog({ name: 'OnboardingDialog' }));
		} else {
			dispatch(openDialog({ name: 'ScenarioDialog' }));
		}
	}, [dispatch, notCompleteProfile]);

	return (
		<ContentContainer>
			<Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' alignItems='flex-start' spacing={2}>
				<HeaderTitle company={company} loading={isCompanyLoading || isCompanyFetching} />
				<Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={1}>
					{isScenarioLoading ? (
						<Spinner size={16} />
					) : (
						notCompleteProfile && (
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<InfoOutlinedIcon color='primary' sx={{ mr: 0.5 }} />
								<Typography color='primary' sx={{ width: '120px', fontSize: '0.9rem', pt: 0.25 }}>
									{intl.formatMessage({ id: 'fill.information.text' })}
								</Typography>
							</Box>
						)
					)}
					<Button disabled={isScenarioLoading} color='success' onClick={openScenarioDialog}>
						{intl.formatMessage({ id: notCompleteProfile ? 'profile.not.complete.button.text' : 'profile.info.button.text' })}
					</Button>
				</Stack>
			</Stack>
			{(notCompleteProfile || mock) && (
				<div className={classes.componentWrapper}>
					<ProfilePhase
						loading={isScenarioLoading || projectsLoading}
						completeProfile={!notCompleteProfile}
						isProjects={!mock}
						instruments={instrumentsData}
						openProfileModal={openScenarioDialog}
					/>
				</div>
			)}
			<div className={classes.componentWrapper}>
				<DeminimisWidget loading={projectsLoading} projects={projectsArr} />
			</div>
			<div className={`${classes.flexContainer} ${classes.componentWrapper}`}>
				<div className={classes.scoreWrapper}>
					<Score loading={scoreLoading} score={scoreData} missingValues={missingValues} openProfileModal={openScenarioDialog} />
				</div>
				<div ref={timelineContainerRef} className={classes.timelineWrapper}>
					<TimelineContainer
						scrollToNoInsruments={scrollToNoInsruments}
						companyId={company?.companyId}
						pathname={pathname}
						instruments={instrumentsData}
					/>
				</div>
			</div>
			{mock && !isAdvancedCompanyOrCustomer && !isStaticProfileConst && <NoInstruments />}
			<Grid container spacing={2} sx={{ mt: 1 }}>
				<Grid item xs={12} md={6}>
					<AnalysisChart data={company?.economicalData} loading={isCompanyLoading || isCompanyFetching} height={390} />
				</Grid>
				<Grid item xs={12} md={6}>
					<BusinessPlanWidget businessPlan={businessPlan} companyId={company?.companyId} pathname={pathname} history={history} />
				</Grid>
			</Grid>
			<div className={classes.componentWrapper}>
				<InstrumentCategoryList
					loading={
						instrumentsLoading ||
						isInstrumentCategoriesLoading ||
						isReferenceCategoriesLoading ||
						isCompanyCustomerLoading ||
						isCompanyLoading ||
						isCompanyFetching
					}
					categories={categories}
					company={company}
					instruments={instrumentsData}
					profile={false}
					currentInstrumentId={currentInstrumentId}
					currentCategory={currentCategory}
					scrollToTimeline={scrollToTimeline}
					openProfileModal={openScenarioDialog}
				/>
			</div>
			<ScenarioModal scenario={scenario} loading={isScenarioLoading} />
		</ContentContainer>
	);
};

export default Dashboard;
