import React from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/styles';

const StyledAddCardButtonContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const StyledAddCardButton = styled(Button)(() => ({
    color: '#42526E'
}));

export default function ({ children, onClick }) {
    return (
        <StyledAddCardButtonContainer>
            <StyledAddCardButton onClick={onClick}>
                {children}
            </StyledAddCardButton>
        </StyledAddCardButtonContainer>
    );
}