import { compact, concat, find, get, isArray, isEmpty, isObject, map, mapValues } from 'lodash';

const getFieldValue = (fieldValueKey: string, fieldValuesArray: any[]) => {
	const fieldValues = map(fieldValuesArray, fieldValues => get(fieldValues, fieldValueKey));
	return find(fieldValues, fieldValue => {
		return fieldValue !== null && fieldValue !== undefined && fieldValue !== '';
	});
};

export const mergeFieldValues = (defaultValues: any, ...fieldValuesArray: any): any => {
	return mapValues(defaultValues, (defaultValue, defaultValueKey) => {
		let fieldValue = getFieldValue(defaultValueKey, fieldValuesArray);
		if (isArray(defaultValue)) {
			if (isEmpty(defaultValue)) {
				return fieldValue ?? defaultValue;
			}
			return map(defaultValue, (defaultValueItem, defaultValueItemIndex) => {
				const fieldValueItem = get(fieldValue, defaultValueItemIndex);
				if (isObject(defaultValueItem)) {
					return mergeFieldValues(defaultValueItem, fieldValueItem);
				}
				return fieldValueItem ?? defaultValueItem;
			});
		}
		return fieldValue ?? defaultValue;
	});
};

export const replaceArrayFieldValues = (fieldValuesArray: any[] | null | undefined, fromIndex: number, fieldValues: any) => {
	if (isEmpty(fieldValuesArray)) {
		return compact(concat([], fieldValues));
	}
	return map(fieldValuesArray, (fieldValuesItem, fieldValuesItemIndex) => {
		return fieldValuesItemIndex === fromIndex ? fieldValues : fieldValuesItem;
	});
};
