import { matchPath as routerMatchPath } from 'react-router-dom';

const companyPathPrefix = '/company/:businessId';
/**
 * Parses url if match
 *
 * @param path comparison path
 * @param location router useLocation
 * @param exact use exact path
 * @returns parsed uri
 */
export const matchPath = (path, location, exact = true) => {
	if (!location) throw Error('Location is required in getCompanyLink');

	const isCompanyPath = matchCompanyPath(location);

	if (isCompanyPath) {
		path = companyPathPrefix + path;
	}

	const match = routerMatchPath(location.pathname, {
		path: path,
		exact: exact,
		strict: false,
	});

	if (match) {
		return true;
	}

	return false;
};

export const getMatch = (path, location, exact) => {
	return routerMatchPath(location.pathname, {
		path: path,
		exact: exact,
		strict: false,
	});
};

export const matchCompanyPath = (location, exact = false) => {
	const match = getMatch(companyPathPrefix, location, exact);

	if (match) return true;

	return false;
};

export const matchDashboard = path => {
	const match = routerMatchPath(path, {
		path: ['/', '/company/:id/'],
		exact: true,
		strict: false,
	});

	if (match) return true;

	return false;
};

export const matchBusinessPlan = path => {
	const match = routerMatchPath(path, {
		path: ['/businessplan', '/company/:id/businessplan'],
		exact: true,
		strict: false,
	});

	if (match) return true;

	return false;
};

export const matchFinancialPlan = path => {
	const match = routerMatchPath(path, {
		path: ['/financialplan', '/company/:id/financialplan'],
		exact: true,
		strict: false,
	});

	if (match) return true;

	return false;
};

export const matchProjects = path => {
	const match = routerMatchPath(path, {
		path: ['/projects', '/company/:id/projects'],
		exact: true,
		strict: false,
	});

	if (match) return true;

	return false;
};

export const matchProjectsProject = path => {
	const match = routerMatchPath(path, {
		path: ['/projects/:projectId', '/company/:id/projects/:projectId'],
		exact: true,
		strict: false,
	});

	if (match) return true;

	return false;
};

export const matchProjectsProjectPlan = path => {
	const match = routerMatchPath(path, {
		path: ['/projects/:projectId/plan', '/company/:id/projects/:projectId/plan'],
		exact: true,
		strict: false,
	});

	if (match) return true;

	return false;
};

export const matchProjectsProjectSummary = path => {
	const match = routerMatchPath(path, {
		path: ['/projects/:projectId/summary', '/company/:id/projects/:projectId/summary'],
		exact: true,
		strict: false,
	});

	if (match) return true;

	return false;
};
