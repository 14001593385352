import * as React from 'react';
import reducer, { sliceDataToPage, PaginatorState, initialState, setData, setPage } from './paginatorSlice';

type PaginatorProviderProps = {
	data: Array<any>;
	rowsPerPage?: number;
	children: React.ReactNode;
};

const PaginatorContext = React.createContext<
	| {
			state: PaginatorState;
			dispatch: React.Dispatch<any>;
	  }
	| undefined
>(undefined);

function PaginatorProvider({ data, rowsPerPage = 10, children }: PaginatorProviderProps) {
	//TODO: build proper initialization instead
	const firstPage = sliceDataToPage(initialState.page, rowsPerPage, data);

	const init: PaginatorState = { ...initialState, data, rowsPerPage, pagedData: firstPage };

	const [state, dispatch] = React.useReducer(reducer, init);

	const value = { state, dispatch };

	React.useEffect(() => {
		dispatch(setData(data));
		dispatch(setPage(1));
	}, [data]); // if data is updated

	return <PaginatorContext.Provider value={value}>{children}</PaginatorContext.Provider>;
}

function usePaginator() {
	const context = React.useContext(PaginatorContext);
	if (context === undefined) {
		throw new Error('usePaginator must be used within a PaginatorProvider');
	}

	return context;
}

export { PaginatorProvider, usePaginator, sliceDataToPage };
