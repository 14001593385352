import React from 'react';
import { useSelector } from 'react-redux';

import InstrumentItem from 'containers/Instruments/InstrumentList/InstrumentItem';
import Subtitle from 'components/Titles/Subtitle';

const SuggestedInstrumentsList = ({
	instruments,
	title,
	suggests,
	currentInstrumentId,
	company,
	scrollToCurrentInstrument,
	link,
	changeMarked,
}) => {
	const allInstruments = useSelector(state => state.instruments?.instruments?.data);

	if (!suggests || !instruments) return null;

	const selectInstrument = id => {
		return allInstruments?.find(item => item.id === id);
	};

	const getFirstSuggests = suggests => {
		if (suggests.length <= 3) return suggests;

		return suggests.slice(0, 3);
	};

	if (suggests.length === 0) return null;

	return (
		<div>
			<Subtitle>{title}</Subtitle>
			{getFirstSuggests(suggests).map((item, index) => {
				const instrument = selectInstrument(item.id);

				if (!instrument) return null;

				return (
					<InstrumentItem
						key={instrument.id + index}
						instrument={instrument}
						instruments={instruments}
						currentInstrumentId={currentInstrumentId}
						company={company}
						scrollToCurrentInstrument={scrollToCurrentInstrument}
						link={link}
						changeMarked={changeMarked}
						selectInstrument={selectInstrument}
					/>
				);
			})}
		</div>
	);
};

export default SuggestedInstrumentsList;
