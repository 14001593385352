import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/styles';

const ContentLoaderContainerStyled = styled(Box)(({ opacity }) => ({
	opacity: opacity,
	position: 'relative',
}));

/**
 * @param {loading} loading shows spinner if loading
 * @param {surrogate} surrogate shows surrogate while loading (i.e. forms)
 * @param {children} children elements for loader
 * @returns
 */
const ContentLoaderContainer = ({ loading = false, size = 32, surrogate = false, children, ...props }) => {
	const getChildren = () => {
		if (surrogate && loading) return surrogate;

		return children;
	};

	return (
		<ContentLoaderContainerStyled opacity={loading ? 0.5 : 1} {...props}>
			<Box
				sx={{
					display: loading ? 'block' : 'none',
					position: 'fixed',
					left: `calc(50% - ${size}px)`,
					top: `calc(50% - ${size}px)`,
					zIndex: 100,
				}}
			>
				<CircularProgress size={size} />
			</Box>

			{getChildren()}
		</ContentLoaderContainerStyled>
	);
};

export default ContentLoaderContainer;
