import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';


export default function LinkButton({ disabled, children, icon, href, sx = {}, color = 'primary.main' }) {
    const theme = useTheme();

    return (
        <Box sx={{
            position: 'relative',
            marginTop: theme.spacing(2),
            display: 'inline-block',
            '& a': {
                textDecoration: 'none'
            },
            ...sx
        }}>
            <Link to={href}>
                <Button
                    variant='contained'
                    disabled={disabled}
                    color={color}
                    sx={{
                        fontSize: '0,875rem',
                        '&:hover': {
                            opacity: '0.8',
                        },
                    }}
                    endIcon={icon}
                >
                    {children}
                </Button>
            </Link>
        </Box>
    );
}