import FormControl from '@mui/material/FormControl';
import { Controller } from 'react-hook-form';

import { Typography } from '@mui/material';
import WYSIWYGEditor from '../ControlledEditor/WYSIWYGEditor';
import FormError from './FormError';
import FormLabel from './FormLabel';

/**
 * Controlled Textfield, dependencies hoook-form, mui
 *
 * @param {string} defaultValue
 * @param {string} name
 * @param {*} control - react hook from control
 * @param {string} error
 * @param {string} label
 * @returns
 */
const FormControlledWysiwyg = ({
	sx = {},
	defaultValue,
	name,
	control,
	error,
	label,
	toolbar = false,
	height = 1000,
	maxHeight = 1200,
	disabled = false,
	setInstruction,
	isEditor,
	setIsEditor,
	inline,
	placeholder,
	noPaddings = true,
	autoresize = false,
	instructions = null,
	minHeight = '6rem',
	fixedToolbar = false,
	component = 'h3',
	marginTop = '2rem',
	charactersLimit,
	titleIcon,
	onBlurAction,
	isTocItem = true,
}) => {
	const FormLableItem = () => {
		if (!label) return null;

		return (
			<FormLabel>
				<Typography
					component={component}
					sx={{
						mt: marginTop,
						color: 'rgb(55, 65, 81)',
						fontSize: '1rem',
						fontWeight: 500,
					}}
					id={name}
					className={isTocItem ? 'panelTitle' : null}
				>
					{label}
				</Typography>
				{titleIcon}
			</FormLabel>
		);
	};

	return (
		<FormControl fullWidth sx={{ ...sx }}>
			<FormLableItem />
			<Controller
				name={name}
				control={control}
				render={({ field }) => {
					if (!field.value) {
						field.value = defaultValue;
					}

					return (
						<WYSIWYGEditor
							{...field}
							defaultValue={defaultValue}
							placeholder={placeholder}
							onBlurAction={onBlurAction}
							name={name}
							disabled={disabled}
							charactersLimit={charactersLimit}
							instructions={instructions}
							maxHeight={maxHeight}
							fixedToolbar={fixedToolbar}
						/>
					);
				}}
			/>
			<FormError sx={{ mt: '1rem' }} error={error} />
		</FormControl>
	);
};

export default FormControlledWysiwyg;
