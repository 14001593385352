import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';

const baseUrl = 'admin';

export const mrrApi = createApi({
	reducerPath: 'mrr',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: ['MRR', 'Statistics'],
	endpoints: builder => ({
		getMrrData: builder.query({
			query: () => {
				return {
					method: 'GET',
					url: '/mrr/v2',
				};
			},
			providesTags: ['MRR'],
		}),
		getMrrStatistics: builder.query({
			query: () => {
				return {
					method: 'GET',
					url: '/mrr/statistics',
				};
			},
			providesTags: ['Statistics'],
		}),
	}),
});

export const { useGetMrrDataQuery, useGetMrrStatisticsQuery } = mrrApi;
