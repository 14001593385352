import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/material';
import { styled } from '@mui/styles';

import Link from 'components/Button/Link';
import LeftRightContainer from './LeftRightContainer';

const WidgetContainer = styled(Box, {
	shouldForwardProp: prop => prop !== 'borderradius' && prop !== 'sx',
})(({ theme, padding = 3, borderradius }) => ({
	padding: theme.spacing(padding),
	background: theme.palette.primary.white,
	borderRadius: borderradius,
	height: '100%'
}));

export const WidgetTitle = styled(Box)(({ theme, fontWeight = 600 }) => ({
	color: theme.palette.primary.main,
	fontStyle: 'normal',
	fontWeight: fontWeight,
	fontSize: '1.2rem',
	lineHeight: '200%',
}));

export const WidgetLink = styled(Link)(() => ({
	fontStyle: 'normal',
	fontWeight: '600',
	fontSize: '13px',
	lineHeight: '170%',
	color: '#057871',
}));

const WidgetFooter = styled(Box)(() => ({
	fontStyle: 'normal',
	fontWeight: 'normal',
	fontSize: '14px',
	lineHeight: '143 %',
	color: 'rgba(66, 82, 110, 0.86)',
}));

const WidgetContent = styled(Box)(() => ({
	margin: '1rem 0',
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
	children,
	title,
	footerContent,
	href,
	link,
	padding,
	fontWeight,
	borderRadius = '0',
	component = 'div',
	sx,
	...props
}) {
	return (
		<Box sx={{ ...sx }}>
			<WidgetContainer component={component} padding={padding} borderradius={borderRadius} {...props}>
				<LeftRightContainer
					left={
						<WidgetTitle component='div' fontWeight={fontWeight}>
							{title}
						</WidgetTitle>
					}
					right={
						href ? (
							<WidgetLink href={href} icon={<ArrowForwardIcon />}>
								{link ? link : 'href'}
							</WidgetLink>
						) : null
					}
				/>
				<WidgetContent>{children}</WidgetContent>
				{footerContent && <WidgetFooter>{footerContent}</WidgetFooter>}
			</WidgetContainer>
		</Box>
	);
}
