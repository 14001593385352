import * as React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import {
	Circle as CircleIcon,
	Facebook as FacebookIcon,
	Instagram as InstagramIcon,
	LinkedIn as LinkedInIcon,
	Twitter as TwitterIcon,
} from '@mui/icons-material';
import { isArray, isNil } from 'lodash';

import { SocialMediaService } from 'types/dataTypes';

type Props<C extends React.ElementType> = IconButtonProps<C, { component?: C }> & {
	service?: SocialMediaService | null;
	profile?: string | null;
};

function RfpProfileSocialMediaIconButton<C extends React.ElementType>({ service = 'FACEBOOK', profile, sx, ...otherProps }: Props<C>) {
	const serviceUrl = React.useMemo(() => {
		if (service === 'FACEBOOK') {
			return `https://www.facebook.com/${profile}`;
		}
		if (service === 'INSTAGRAM') {
			return `https://www.instagram.com/${profile}`;
		}
		if (service === 'LINKEDIN') {
			return `https://www.linkedin.com/company/${profile}`;
		}
		if (service === 'TWITTER') {
			return `https://www.twitter.com/${profile}`;
		}
		return null;
	}, [service, profile]);

	if (!profile) return null;

	return (
		<IconButton
			{...otherProps}
			component='a'
			disabled={isNil(serviceUrl)}
			href={serviceUrl || '#'}
			target='blank'
			sx={[{ color: 'text.primary' }, ...(isArray(sx) ? sx : [sx])]}
		>
			{service === 'FACEBOOK' ? (
				<FacebookIcon />
			) : service === 'INSTAGRAM' ? (
				<InstagramIcon />
			) : service === 'LINKEDIN' ? (
				<LinkedInIcon />
			) : service === 'TWITTER' ? (
				<TwitterIcon />
			) : (
				<CircleIcon />
			)}
		</IconButton>
	);
}

export default RfpProfileSocialMediaIconButton;
