import { createTheme } from '@mui/material';
import { fiFI as fiFi1 } from '@mui/material/locale';
import { fiFI as fiFi2 } from '@mui/x-date-pickers/locales';

import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from 'config/themeAugmentation';

const colors = {
	green: '#057871',
	purple: '#482E51',
	yellow: '#D4A712',
	darkRed: '#8e0e44',
	lightGreen: '#459F78',
	white: '#FFFFFF',
	grayscale900: '#303F5A',
	grayscale700: '#5A647C',
	grayscale600: '#73798C',
	grayscale500: '#8F94A3',
	grayscale400: '#C5C3C6',
	grayscale300: '#E7E4DF',
	grayscale200: '#EEECE9',
	grayscale100: '#F7F5F0',
	grayscale50: '#FBFAF8',
};

const fonts = {
	aleo: 'Aleo',
	poppins: 'Poppins',
};

const theme = createTheme(
	{
		breakpoints: {
			values: {
				xs: 0,
				xsm: 450,
				sm: 600,
				smd: 750,
				md: 960,
				mdl: 1050,
				lg: 1280,
				lgx: 1450,
				xl: 1920,
			},
		},
		shape: {
			borderRadius: 4,
		},
		palette: {
			primary: {
				main: colors.green,
			},
			secondary: {
				main: colors.purple,
			},
			error: {
				main: colors.darkRed,
			},
			status: {
				draft: colors.yellow,
				published: colors.lightGreen,
				archived: colors.darkRed,
			},
			neutral: {
				main: colors.grayscale900,
			},
			text: {
				primary: colors.grayscale900,
				secondary: colors.grayscale700,
				muted: colors.grayscale600,
			},
			background: {
				default: colors.grayscale100,
				paper: colors.white,
			},
			action: {
				active: colors.grayscale500,
			},
			divider: colors.grayscale400,
		},
		typography: {
			fontFamily: fonts.poppins,
			fontSize: 14,
			h1: {
				fontFamily: fonts.aleo,
				fontWeight: 700,
				fontSize: '2rem',
				lineHeight: 1.2,
				color: colors.purple,
			},
			h2: {
				fontFamily: fonts.aleo,
				fontWeight: 600,
				fontSize: '1.3rem',
				lineHeight: 1.2,
				color: colors.purple,
			},
			h3: {
				fontFamily: fonts.aleo,
				fontWeight: 700,
				fontSize: '1.2rem',
				lineHeight: 1.2,
				color: colors.purple,
			},
			h4: {
				fontFamily: fonts.poppins,
				fontWeight: 500,
				fontSize: '1rem',
				lineHeight: 1.2,
				fontStyle: 'normal',
				color: colors.purple,
				marginTop: '1rem',
				marginBottom: '1rem',
			},
			subtitle1: {
				fontFamily: fonts.poppins,
				fontWeight: 500,
				fontSize: '1rem',
				lineHeight: 1.2,
			},
			subtitle2: {
				fontFamily: fonts.poppins,
				fontWeight: 500,
				fontSize: '0.875rem',
				lineHeight: 1.2,
			},
			body1: {
				fontFamily: fonts.poppins,
				fontWeight: 400,
				fontSize: '1rem',
				lineHeight: 1.2,
				whiteSpace: 'break-spaces',
			},
			body2: {
				fontFamily: fonts.poppins,
				fontWeight: 400,
				fontSize: '0.875rem',
				lineHeight: 1.2,
				whiteSpace: 'break-spaces',
			},
			button: {
				fontFamily: fonts.poppins,
				fontWeight: 500,
				fontSize: '1rem',
				lineHeight: 1.2,
				textTransform: 'uppercase',
			},
			label: {
				fontFamily: fonts.poppins,
				fontWeight: 500,
				fontSize: '0.875rem',
				lineHeight: 1.5,
			},
			listItem: {
				marginTop: '.2rem',
				fontSize: '.875rem',
				display: '-webkit-box',
				WebkitLineClamp: 3,
				WebkitBoxOrient: 'vertical',
				overflow: 'hidden',
				maxHeight: '4rem',
			},
			lightText: {
				fontFamily: fonts.poppins,
				color: colors.grayscale600,
				lineHeight: 1.2,
				fontSize: '0.875rem',
			},
		},
		components: {
			MuiDateTimePicker: {
				defaultProps: {
					ampm: false,
					inputFormat: 'dd.MM.yyyy HH:mm',
				},
			},
			MuiDatePicker: {
				defaultProps: {
					inputFormat: 'dd.MM.yyyy',
				},
			},
			MuiStack: {
				defaultProps: {
					useFlexGap: true,
					spacing: 1,
				},
			},
			MuiAlert: {
				styleOverrides: {
					root: {
						marginBottom: '8px',
					},
				},
			},
			MuiAvatar: {
				variants: [
					{
						props: { variant: 'logo1' },
						style: {
							borderRadius: 4,
							width: '100px',
							height: '100px',
						},
					},
					{
						props: { variant: 'logo2' },
						style: {
							borderRadius: 4,
							width: '70px',
							height: '70px',
						},
					},
					{
						props: { variant: 'logo3' },
						style: {
							borderRadius: 4,
							width: '45px',
							height: '45px',
						},
					},
					{
						props: { variant: 'portrait1' },
						style: {
							borderRadius: '50%',
							width: '45px',
							height: '45px',
						},
					},
				],
			},
			MuiBadge: {
				defaultProps: {
					color: 'primary',
					showZero: true,
				},
			},
			MuiTabPanel: {
				styleOverrides: {
					root: {
						padding: '1rem 0 2rem',
					},
				},
			},
			MuiTypography: {
				styleOverrides: {
					h1: {
						color: colors.purple,
					},
				},
				variants: [{ props: { variant: 'label' }, style: { color: colors.grayscale700 } }],
			},
			MuiLoadingButton: {
				defaultProps: {
					variant: 'contained',
					color: 'primary',
					size: 'medium',
				},
			},
			MuiButton: {
				defaultProps: {
					variant: 'contained',
					color: 'primary',
					size: 'medium',
				},
				styleOverrides: {
					root: {
						fontSize: '1rem',
						'@media print': {
							display: 'none',
						},
					},
					outlined: ({ ownerState }) => ({
						...(ownerState.color === 'neutral' && {
							borderColor: colors.grayscale400,
						}),
					}),
					iconSizeSmall: {
						'& > *:first-child': {
							fontSize: 12,
							marginRight: 2,
						},
					},
					iconSizeMedium: {
						'& > *:first-child': {
							fontSize: 16,
							marginRight: 2,
						},
					},
					iconSizeLarge: {
						'& > *:first-child': {
							fontSize: 20,
							marginRight: 2,
						},
					},
					sizeSmall: {
						padding: '6px 8px',
						'&.MuiButton-contained': {
							padding: '7px 8px', // has no borders like outlined, TODO: check text variant
						},
					},
					sizeMedium: {
						padding: '9px 8px',
						'&.MuiButton-contained': {
							padding: '10px 8px', // has no borders like outlined
						},
					},
					sizeLarge: {
						padding: '11px 17px',
						'&.MuiButton-contained': {
							padding: '12px 8px', // has no borders like outlined
						},
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						'& .MuiOutlinedInput-root': {},
					},
				},
				defaultProps: {
					variant: 'outlined',
					fullWidth: true,
				},
			},
			MuiInputBase: {
				styleOverrides: {
					input: {
						height: '1.143rem',
						fontWeight: 400,
						fontSize: '1rem',
						lineHeight: 1.2,
						'&::placeholder': {
							opacity: 1,
							color: colors.grayscale500,
						},
					},
				},
			},
			MuiSelect: {
				styleOverrides: {
					select: {
						height: '1.143rem',
						minHeight: '1.143rem',
					},
				},
			},
			MuiAutocomplete: {
				defaultProps: {
					ChipProps: {
						size: 'small',
					},
				},
				styleOverrides: {
					root: {
						'& .MuiOutlinedInput-root': {
							padding: '30px 14px 12px 14px',
						},
						'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
							padding: '4px 4px 4px 6px',
						},
					},
				},
			},
			MuiOutlinedInput: {
				defaultProps: {
					notched: false,
				},
				styleOverrides: {
					root: {
						backgroundColor: colors.white,
						padding: '30px 14px 12px 14px',
					},
					input: {
						padding: 0,
					},
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						fontWeight: 500,
						fontSize: '0.875rem',
						lineHeight: 1.5,
					},
				},
			},
			MuiFormControl: {
				defaultProps: {
					fullWidth: true,
				},
			},
			MuiInputLabel: {
				defaultProps: {
					shrink: true,
				},
				styleOverrides: {
					root: {
						transform: 'none',
					},
					outlined: {
						top: '12px',
						left: '14px',
					},
				},
			},
			MuiChip: {
				defaultProps: {
					variant: 'outlined',
					size: 'small',
				},
				styleOverrides: {
					root: {
						fontSize: '0.875rem',
						lineHeight: 1.3,
						fontWeight: 500,
					},
					icon: {
						fontSize: '0.714rem',
					},
					deleteIcon: {
						color: colors.grayscale500,
						'&:hover': {
							color: colors.grayscale500,
						},
					},
					filled: {
						borderColor: colors.grayscale200,
						backgroundColor: colors.grayscale200,
						color: colors.grayscale700,
					},
					outlined: {
						borderColor: colors.grayscale300,
						backgroundColor: colors.grayscale50,
						color: colors.grayscale900,
					},
					sizeSmall: {
						padding: '2px 6px',
					},
					sizeMedium: {
						padding: '3px 8px',
					},
				},
			},
			MuiList: {
				styleOverrides: {
					root: {},
				},
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						backgroundColor: colors.white,
						marginBottom: '.5rem',
					},
				},
			},
			MuiListItemButton: {
				styleOverrides: {
					root: {
						height: '100%',
					},
				},
			},
			MuiListItemText: {
				styleOverrides: {
					primary: {
						fontWeight: 500,
						fontSize: '1rem',
						lineHeight: 1.5,
						color: colors.grayscale900,
						wordWrap: 'break-word',
					},
					secondary: {
						fontWeight: 400,
						fontSize: '0.875rem',
						lineHeight: 1.3,
						color: colors.grayscale500,
					},
				},
			},
			MuiDrawer: {
				styleOverrides: {
					root: {
						position: 'relative',
					},
					paper: {
						backgroundColor: colors.grayscale50,
					},
				},
			},
			MuiLink: {
				defaultProps: {
					underline: 'none',
				},
			},
			MuiDialogTitle: {
				styleOverrides: {
					root: {
						padding: '1rem',
					},
				},
			},
			MuiDialogContentText: {
				styleOverrides: {
					root: {
						padding: '1rem',
					},
				},
			},
			MuiDialogContent: {
				styleOverrides: {
					root: {
						borderTop: '1px solid',
						borderBottom: '1px solid',
						borderColor: colors.grayscale400,
						padding: '1rem',
						'.MuiDialogTitle-root+&': {
							paddingTop: '1rem',
						},
					},
				},
			},
			MuiDialogActions: {
				styleOverrides: {
					root: {
						padding: '1rem',
					},
				},
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						backgroundColor: colors.grayscale50,
					},
				},
			},
			MuiInputAdornment: {
				styleOverrides: {
					root: {
						color: colors.grayscale600,
					},
				},
			},
			MuiSkeleton: {
				defaultProps: {
					animation: 'wave',
				},
				styleOverrides: {
					root: {
						transform: 'scale(1, 0.80)',
					},
				},
			},
		},
	},
	fiFi1,
	fiFi2
);

export default theme;
