import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogTitle, Popover, PopoverProps } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { closePopover, getPopoverData, isPopoverOpen } from 'containers/Marketplace/popoverSlice';

type Props = Omit<PopoverProps, 'open'> & {};

const ConfirmRemovePopover: React.FC<Props> = ({ ...otherProps }) => {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = React.useState(false);

	const popoverOpen = useSelector(isPopoverOpen('ConfirmRemovePopover'));
	const popoverData = useSelector(getPopoverData('ConfirmRemovePopover'));

	const handlePopoverClose = React.useCallback(() => {
		dispatch(closePopover({ name: 'ConfirmRemovePopover' }));
	}, [dispatch]);

	return (
		<Popover
			{...otherProps}
			open={popoverOpen}
			anchorEl={popoverData?.anchorEl}
			onClose={handlePopoverClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<DialogTitle>{popoverData?.text}</DialogTitle>
			<DialogContent sx={{ border: 'none' }}>{popoverData?.description}</DialogContent>
			<DialogActions>
				<Button variant='text' size='small' color='neutral' onClick={handlePopoverClose}>
					<FormattedMessage id='confirmRemove.cancel' />
				</Button>
				<LoadingButton
					variant='contained'
					color='error'
					loading={isLoading}
					onClick={async () => {
						setIsLoading(true);
						await popoverData.onSubmit?.();
						handlePopoverClose();
						setIsLoading(false);
					}}
				>
					<FormattedMessage id='confirmRemove.submit' />
				</LoadingButton>
			</DialogActions>
		</Popover>
	);
};

export default ConfirmRemovePopover;
