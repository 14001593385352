import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import htmlParser from 'components/HtmlParser';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
	FormControlledCurrencyInput,
	FormControlledDatepicker,
	FormControlledInput,
	FormControlledSelect,
	FormControlledSwitchItem,
	FromControlledHiddenField,
} from 'components/Form';
import FormControlWithLabel from 'components/Form/FormControlWithLabel';
import FormControlledWysiwyg from 'components/Form/FormControlledWysiwyg';
import InstrumentAutocomplete from 'containers/FinancialPlan/InstrumentModal/InstrumentAutocomplete';

import FormLabel from 'components/Form/FormLabel';

import { Autocomplete, FormControl, Stack, TextField } from '@mui/material';
import AdminContentContainer from 'components/Containers/AdminContentContainer';
import { toast } from 'react-toastify';
import { Instrument, Project, ProjectWorkStatuses } from 'types/dataTypes';
import { isAdminToken } from 'utils/auth/token';
import { maxLength } from '../ProjectValidationSchema';

type ProjectFormBaseProps = {
	register: any;
	unregister: any;
	setValue: any;
	project: any;
	watch: any;
	instruments: Instrument[];
	control: any;
	errors: any;
	summary: boolean;
	setInstruction: any;
	changeInstrument: any;
	readonly: boolean;
	getValues: any;
	updateLocalBackup: any;
};

const ProjectFormBase: React.FC<ProjectFormBaseProps> = ({
	register,
	unregister,
	setValue,
	project,
	watch,
	instruments,
	control,
	errors,
	summary = false,
	setInstruction,
	changeInstrument,
	readonly,
	getValues,
	updateLocalBackup = () => {},
}) => {
	const intl = useIntl();

	const [instrumentIsSet, setInstrumentIsSet] = useState(false); // TODO: quick fix replace with better
	const isAdmin = isAdminToken();

	const [instrument, setInstrument] = useState({
		id: project?.instrumentId ?? '',
		name: project?.instrumentName ?? '',
		type: project?.instrumentType ?? '',
		provider: project?.instrumentProvider ?? '',
	});

	const ingress =
		Object.keys(project).length > 0 &&
		instruments?.reduce((acc: any, item: any) => {
			return item.id === instrument?.id ? item?.ingress : acc;
		}, '');

	useEffect(() => {
		register('files');

		return () => {
			unregister('files');
		};
	}, [register, unregister]);

	const watchStartDate = watch('startDate', project?.startDate);

	useEffect(() => {
		let data: Project = getValues();

		if (!data.projectTaskGroups || !watchStartDate) return;

		for (let item of data.projectTaskGroups) {
			if (!item.tasks) continue;

			for (let task of item.tasks) {
				if (!task?.startDate) continue;

				try {
					if (watchStartDate && task?.startDate && new Date(watchStartDate).getTime() > new Date(task?.startDate).getTime()) {
						toast.warning('Projektin aloituspäivämäärä on suurempi kuin tehtävän aloituspäivämäärä. Tarkista päivämäärät.');
					}
				} catch (error) {
					console.error(error);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchStartDate]);

	const setNewInstrument = (data: Instrument) => {
		setValue('instrumentIsCustom', data.isCustom);
		setValue('instrumentId', data.id);
		setValue('instrumentName', data.name);
		setValue('instrumentProvider', data.provider);
		setValue('instrumentType', data.type);

		changeInstrument(data.id);
		setInstrument(data);
	};

	useEffect(() => {
		if (project && project.projectId && !instrumentIsSet) {
			setInstrument({
				id: project?.instrumentId ?? '',
				name: project?.instrumentName ?? '',
				type: project?.instrumentType ?? '',
				provider: project?.instrumentProvider ?? '',
			});
			setInstrumentIsSet(true);
		}
	}, [project]); // eslint-disable-line

	const options = [
		{ name: 'Aki Pohjola', email: 'aki.pohjola@grants.fi' },
		{ name: 'Heli Saari', email: 'heli.saari@grants.fi' },
		{ name: 'Jari Hakanen', email: 'jari.hakanen@grants.fi' },
		{ name: 'Kari Heinonen', email: 'kari.heinonen@grants.fi' },
		{ name: 'Kimmo Louhelainen', email: 'kimmo.louhelainen@grants.fi' },
		{ name: 'Miikka Liimatainen', email: 'miikka.liimatainen@grants.fi' },
		{ name: 'Mikko Hölli', email: 'mikko.holli@grants.fi' },
		{ name: 'Minni Heinilä', email: 'minni.heinila@grants.fi' },
		{ name: 'Pietari Sipponen', email: 'pietari.sipponen@grants.fi' },
		{ name: 'Svetlana Smagina', email: 'svetlana.smangina@grants.fi' },
		{ name: 'Teija Metso', email: 'teija.metso@grants.fi' },
		{ name: 'Tiina Harjunpää', email: 'tiina.harjunpaa@grants.fi' },
	];

	return (
		<>
			{/* Hidden Fields */}
			<FromControlledHiddenField name='timelineId' defaultValue={project?.timelineId ?? ''} control={control} />
			<FromControlledHiddenField name='projectId' defaultValue={project?.projectId ?? ''} control={control} />
			<FromControlledHiddenField name='eTag' defaultValue={project?.eTag ?? ''} control={control} />
			<FromControlledHiddenField name='instrumentIsCustom' defaultValue={project?.instrumentIsCustom ?? ''} control={control} />
			<FromControlledHiddenField name='instrumentId' defaultValue={project?.instrumentId ?? ''} control={control} />
			<FromControlledHiddenField name='instrumentProvider' defaultValue={project?.instrumentProvider ?? ''} control={control} />
			<FromControlledHiddenField name='instrumentType' defaultValue={project?.instrumentType ?? ''} control={control} />
			<Grid container spacing={2}>
				<Grid item xs={12} xl={6} id='projectName'>
					<FormControlledInput
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.projectName ?? ''}
						name='projectName'
						control={control}
						error={errors?.projectName?.message}
						label={intl.formatMessage({ id: 'project.form.projectName' })}
						setInstruction={setInstruction}
						instructions={intl.formatMessage({ id: 'project.form.projectName.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} xl={6} id='instrumentName'>
					<FormControlWithLabel
						error={errors?.instrumentName?.message}
						label={intl.formatMessage({ id: 'project.form.instrumentType' })}
					>
						<Controller
							name='instrumentName'
							control={control}
							defaultValue={project?.instrumentName ?? ''}
							render={({ field: { onChange } }) => (
								<InstrumentAutocomplete
									disabled={project?.mock || readonly}
									readonly={readonly}
									onChange={onChange}
									instrument={instrument}
									instruments={instruments}
									setNewInstrument={setNewInstrument}
									control={control}
									setInstruction={setInstruction} // @ts-ignore
									instructions={intl.formatMessage({ id: 'project.form.instrumentType.instructions' })}
									onInputBlur={() => updateLocalBackup(getValues())}
								/>
							)}
						/>
					</FormControlWithLabel>
				</Grid>
				<Grid item xs={12} md={6} id='startDate'>
					<FormControlledDatepicker
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.startDate ?? ''}
						name='startDate'
						control={control}
						error={errors?.startDate?.message}
						label={intl.formatMessage({ id: 'project.form.startDate' })}
						setInstruction={setInstruction} // @ts-ignore
						instructions={intl.formatMessage({ id: 'project.form.startDate.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} md={6} id='endDate'>
					<FormControlledDatepicker
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.endDate ?? ''}
						name='endDate'
						control={control}
						error={errors?.endDate?.message}
						label={intl.formatMessage({ id: 'project.form.endDate' })}
						minDate={watchStartDate ?? new Date()}
						setInstruction={setInstruction} // @ts-ignore
						instructions={intl.formatMessage({ id: 'project.form.endDate.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} md={6} id='reportDeadline'>
					<FormControlledDatepicker
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.reportDeadline ?? ''}
						name='reportDeadline'
						control={control}
						error={errors?.reportDeadline?.message}
						label={intl.formatMessage({ id: 'project.form.reportDeadline' })}
						minDate={watchStartDate ?? new Date()}
						setInstruction={setInstruction} // @ts-ignore
						instructions={intl.formatMessage({ id: 'project.form.reportDeadline.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} md={6} id='projectSize'>
					<FormControlledCurrencyInput
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.projectSize ?? 0}
						name='projectSize'
						control={control}
						error={errors?.projectSize?.message}
						label={intl.formatMessage({ id: 'project.form.projectSize' })}
						setInstruction={setInstruction}
						instructions={intl.formatMessage({ id: 'project.form.projectSize.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} md={6} id='amountToApply'>
					<FormControlledCurrencyInput
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.amountToApply ?? 0}
						name='amountToApply'
						control={control}
						error={errors?.amountToApply?.message}
						label={intl.formatMessage({ id: 'project.form.amountToApply' })}
						setInstruction={setInstruction}
						instructions={intl.formatMessage({ id: 'project.form.amountToApply.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} md={6} id='deminimisAmount'>
					<FormControlledCurrencyInput
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.deminimisAmount ?? 0}
						name='deminimisAmount'
						control={control}
						error={errors?.deminimisAmount?.message}
						label={intl.formatMessage({ id: 'project.form.deminimisAmount' })}
						setInstruction={setInstruction}
						instructions={intl.formatMessage({ id: 'project.form.deminimisAmount.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} md={6} id='projectApplicationNumber'>
					<FormControlledInput
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.projectApplicationNumber ?? ''}
						name='projectApplicationNumber'
						control={control}
						error={errors?.projectApplicationNumber?.message}
						label={intl.formatMessage({ id: 'project.form.projectApplicationNumber' })}
						setInstruction={setInstruction}
						instructions={intl.formatMessage({ id: 'project.form.projectApplicationNumber.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} md={6} id='projectPlanDate'>
					<FormControlledDatepicker
						variant={readonly ? 'standard' : 'outlined'}
						disabled={project?.mock || readonly}
						defaultValue={project?.projectPlanDate ?? ''}
						name='projectPlanDate'
						control={control}
						error={errors?.projectPlanDate?.message}
						label={intl.formatMessage({ id: 'project.form.projectPlanDate' })}
						setInstruction={setInstruction} // @ts-ignore
						instructions={intl.formatMessage({ id: 'project.form.projectPlanDate.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={12} md={6} id='isUsed'>
					{/* @ts-ignore */}
					<FormControlledSwitchItem
						disabled={project?.mock || readonly}
						defaultValue={project?.isUsed ?? false}
						name='isUsed'
						control={control}
						error={errors?.isUsed?.message}
						label={intl.formatMessage({ id: 'project.form.isUsed' })}
						setInstruction={setInstruction}
						checkedVal={true}
						notCheckedVal={false}
						checkedLabel={intl.formatMessage({ id: 'project.form.isUsed.checked' })}
						notCheckedLabel={intl.formatMessage({ id: 'project.form.isUsed.notChecked' })}
						instructions={intl.formatMessage({ id: 'project.form.isUsed.instructions' })}
						onInputBlur={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormControlledSelect
						defaultValue={project?.projectWorkStatus ?? ''}
						name='projectWorkStatus'
						control={control} // @ts-ignore
						error={errors?.projectWorkStatus?.message}
						label={intl.formatMessage({ id: 'project.form.projectWorkStatus' })} // @ts-ignore
						options={ProjectWorkStatuses.map(item => {
							return {
								value: item,
								label: intl.formatMessage({ id: `project.form.projectWorkStatus.${item}` }),
							};
						})}
					/>
				</Grid>
				{isAdmin && (
					<Grid item xs={6}>
						<FormControl fullWidth>
							<FormLabel>
								<Stack direction='row' justifyContent='space-between' alignItems='center'>
									{intl.formatMessage({ id: 'project.form.projectResponsible' })}
								</Stack>
							</FormLabel>

							<Controller
								name='projectResponsible'
								control={control}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										sx={{ '.MuiOutlinedInput-root': { padding: '2px 8px' } }}
										freeSolo
										options={options}
										getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
										isOptionEqualToValue={(option, value) => option.email === value}
										onChange={(event, newValue) => {
											onChange(newValue ? newValue.email : '');
										}}
										onInputChange={(event, newInputValue) => {
											if (newInputValue && !options.find(opt => opt.email === newInputValue)) {
												onChange(newInputValue);
											}
										}}
										value={options.find(opt => opt.email === value) || value || ''}
										renderInput={params => <TextField {...params} variant={readonly ? 'standard' : 'outlined'} />}
									/>
								)}
							/>
						</FormControl>
					</Grid>
				)}

				<Grid item xs={12} id='projectSummary'>
					{/* @ts-ignore */}
					<FormControlledWysiwyg
						minHeight='12rem'
						maxHeight={summary ? '10rem' : 0}
						inline={true}
						fixedToolbar={true}
						disabled={project?.mock || readonly}
						toolbar={true}
						name='projectSummary'
						control={control}
						defaultValue={project?.projectSummary ?? ''}
						error={errors?.projectSummary?.message}
						label={intl.formatMessage({ id: 'project.form.projectSummary' })}
						setInstruction={setInstruction}
						instructions={intl.formatMessage({ id: 'project.form.projectSummary.instructions' })}
						placeholder={intl.formatMessage({ id: 'project.form.projectSummary.instructions' })}
						charactersLimit={maxLength}
						onBlurAction={() => updateLocalBackup(getValues())}
					/>
				</Grid>
				{!summary && (
					<AdminContentContainer>
						<Grid item xs={12} id='projectPrompt'>
							{/* @ts-ignore */}
							<FormControlledWysiwyg
								minHeight='12rem'
								maxHeight={summary ? '10rem' : 0}
								inline={true}
								fixedToolbar={true}
								disabled={project?.mock || readonly}
								toolbar={true}
								name='projectPrompt'
								control={control}
								defaultValue={project?.projectPrompt ?? ''}
								error={errors?.projectPrompt?.message}
								setInstruction={setInstruction}
								instructions={intl.formatMessage({ id: 'project.form.projectPrompt.instructions' })}
								label={intl.formatMessage({ id: 'project.form.projectPrompt' })}
								placeholder={intl.formatMessage({ id: 'project.form.projectPrompt.instructions' })}
								charactersLimit={maxLength}
								onBlurAction={() => updateLocalBackup(getValues())}
							/>
						</Grid>
					</AdminContentContainer>
				)}
				{ingress && isAdmin && (
					<Grid item xs={12} id='ingress'>
						<Box
							sx={{
								p: '1rem',
								pl: '1rem',
								pr: '1rem',
								m: 0,
								mt: '1rem',
								fontSize: '.9rem',
								background: 'rgb(249, 250, 251)',
							}}
						>
							<FormLabel>{intl.formatMessage({ id: 'project.form.ingress' })}</FormLabel>
							{htmlParser(ingress)}
						</Box>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default ProjectFormBase;
