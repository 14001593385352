import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageSpinner from 'components/Spinner/PageSpinner';
import Instrument from 'containers/Instruments/InstrumentList/InstrumentItem/Instrument';
import { setCurrentInstrumentId } from 'containers/Instruments/slices';
import { useQuery } from 'utils/helpers/uriHelper';
import { useCreateInstrumentAnalyticsMutation } from 'containers/Analytics/analyticsApi';
import { useFetchCompanyQuery } from 'containers/Company/companyV2Api';
import { getCompanyId } from 'utils/auth/company';

const InstrumentPage = ({ passPageProps }) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const query = useQuery();

	const companyId = getCompanyId();

	const [createInstrumentAnalytics] = useCreateInstrumentAnalyticsMutation();

	useEffect(() => {
		if (!id) {
			createInstrumentAnalytics({ action: 'InstrumentOpen', instrumentId: id });
		}
	}, [createInstrumentAnalytics, id]);

	// set setCurrentInstrumentId
	useEffect(() => {
		if (!id) return;
		dispatch(setCurrentInstrumentId(id, query.get('category')));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id]);

	// Instruments
	const instruments = useSelector(state => state.instruments);
	const instrumentsLoading = instruments?.instruments?.loading;
	const instrumentsData = instruments?.instruments?.data;
	const currentInstrumentId = instruments?.currentInstrumentId;

	const instrument = instrumentsData?.reduce((acc, item) => {
		return item.id === id ? item : acc;
	}, {});

	// Company
	const { data: company, isLoading: isCompanyLoading } = useFetchCompanyQuery({ companyId }, { skip: !companyId });

	useEffect(() => {
		if (instrument && Object.keys(instrument).length !== 0) {
			passPageProps([{ id: instrument.id, name: instrument.name }]);
		}
	}, [passPageProps, instrument]);

	const loading = (instrumentsLoading || isCompanyLoading) ?? false;

	const radarContainerRef = useRef();
	const [radarSize, setRadarSize] = useState(null);

	useLayoutEffect(() => {
		const maxSize = 400;
		const updateWidth = () => {
			setRadarSize(radarContainerRef.current?.clientWidth < maxSize ? radarContainerRef.current?.clientWidth : maxSize);
		};

		window.addEventListener('resize', updateWidth);
		updateWidth();

		return () => window.removeEventListener('resize', updateWidth);
	}, []);

	return loading ? (
		<PageSpinner />
	) : (
		<Instrument
			ref={radarContainerRef}
			instrument={instrument}
			instruments={instrumentsData}
			currentInstrumentId={currentInstrumentId}
			company={company}
			radarSize={radarSize}
		/>
	);
};

export default InstrumentPage;
