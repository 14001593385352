import AddLinkIcon from '@mui/icons-material/AddLink';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareIcon from '@mui/icons-material/IosShare';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import InstructionPopover from 'components/CustomPopover';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmButton from 'components/Button/ConfirmButton';
import LeftRightContainer from 'components/Containers/LeftRightContainer';
import DataGrid from 'components/DataGrid';
import ModalDialog from 'components/Dialog/ModalDialog';
import FormattedDate from 'components/Formatters/FormattedDate';
import LinkPopover from '../Customers/CustomerList/LinkPopover';
import ModalContent from '../ModalContent';
import ModalTitle from '../ModalTitle';

import { ROWS_PER_PAGE } from 'config/environment';
import { loadListById } from 'containers/Advanced/slices';
import { getGrantedScoreColor, getLiteScoreColor } from '../utils';

const useStyles = makeStyles(theme => ({
	cell: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: '1rem',
		color: theme.palette.primary.main,
	},
	customerName: {
		fontWeight: 700,
	},
	circle: {
		height: '0.875rem',
		width: '0.875rem',
		borderRadius: '0.875rem',
	},
	grantedScore: {
		fontSize: '1rem',
		fontWeight: 600,
	},
	error: {
		color: theme.palette.error.main,
	},
	errorStatus: {
		color: theme.palette.error.main,
		fontSize: '1rem',
		fontWeight: 'bold',
		paddingTop: theme.spacing(0.5),
		paddingLeft: theme.spacing(0.5),
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.white,
		'&:hover': {
			opacity: '0.8',
			backgroundColor: theme.palette.primary.main,
		},
	},
	buttonLoading: {
		position: 'absolute',
		color: theme.palette.primary.main,
	},
	row: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

const CreatedList = (
	{ id, lists, selectedIds, setSelectedIds, removeCompany, removeCompanyLoading, profileLoading, profileLink /* toggleImportant */ },
	ref
) => {
	const classes = useStyles();
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();

	const loading = useSelector(state => state.customers?.loadListByIdLoading);

	const currentList = lists?.find(({ companyListId }) => companyListId === id);
	const isLoaded = currentList?.isLoaded;
	const data = currentList?.data;

	useEffect(() => {
		if (id && (!isLoaded || !data)) dispatch(loadListById(id));
	}, [id, isLoaded, data]); // eslint-disable-line react-hooks/exhaustive-deps

	// popover
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClose = () => setAnchorEl(null);
	const isPopoverOpen = Boolean(anchorEl) && !profileLoading;

	// modal
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalData, setModalData] = useState(null);

	const toggleModal = data => {
		setIsModalOpen(!isModalOpen);
		setModalData(data);
	};

	useImperativeHandle(ref, () => ({
		closeModal: () => isModalOpen && toggleModal(null),
	}));

	const copiedToClipboard = () => toast.success(intl.formatMessage({ id: 'customerlist.popover.copy.url.success' }));

	const columns = [
		{
			field: 'liteScoreValue',
			headerName: intl.formatMessage({ id: 'customerlist.table.liteScoreValue' }),
			headerClassName: 'styled-header',
			width: 100,
			renderCell: params => {
				const liteScoreValue = params.row.liteScoreValue;

				return (
					<Grid container alignItems='center'>
						<Grid item style={{ paddingBottom: '0.125rem' }}>
							{liteScoreValue !== null && (
								<div
									className={classes.circle}
									style={{
										backgroundColor: getLiteScoreColor(liteScoreValue),
									}}
								/>
							)}
						</Grid>
						<Grid item style={{ paddingLeft: '0.375rem' }}>
							<Typography
								className={classes.grantedScore}
								style={{
									color: getLiteScoreColor(liteScoreValue),
								}}
							>
								{liteScoreValue}
							</Typography>
						</Grid>
					</Grid>
				);
			},
		},
		{
			field: 'grantedScore',
			headerName: intl.formatMessage({ id: 'customerlist.table.grantedScore' }),
			headerClassName: 'styled-header',
			width: 100,
			renderCell: params => {
				const grantedScores = params.row.grantedScore;

				return (
					<Grid container alignItems='center'>
						<Grid item style={{ paddingBottom: '0.125rem' }}>
							{grantedScores !== null && (
								<div
									className={classes.circle}
									style={{
										backgroundColor: getGrantedScoreColor(grantedScores),
									}}
								/>
							)}
						</Grid>
						<Grid item style={{ paddingLeft: '0.375rem' }}>
							<Typography
								className={classes.grantedScore}
								style={{
									color: getGrantedScoreColor(grantedScores),
								}}
							>
								{grantedScores}
							</Typography>
						</Grid>
					</Grid>
				);
			},
		},
		{
			field: 'rowKey',
			headerName: intl.formatMessage({ id: 'customerlist.table.profile.navigate' }),
			headerClassName: 'styled-header',
			width: 120,
			renderCell: params => (
				<span className={classes.cell}>
					<Button
						variant='contained'
						size='small'
						color='primary'
						onClick={e => {
							e.stopPropagation();
							history.push(`/company/${params.row.businessId}`);
						}}
						className={classes.button}
					>
						{params.row.businessId}
					</Button>
				</span>
			),
		},
		// {
		//     field: 'important',
		//     headerName: intl.formatMessage({ id: 'customerlist.table.important' }),
		//     headerClassName: 'styled-header',
		//     width: 80,
		//     renderCell: params => (
		//         <span className={classes.cell}>
		//             <IconButton
		//                 color='primary'
		//                 onClick={e => {
		//                     e.stopPropagation();
		//                     toggleImportant(params.row.businessId, params.row.important || false);
		//                 }}
		//             >
		//                 {params.row.important ? <StarIcon /> : <StarOutlineIcon />}
		//             </IconButton>
		//         </span>
		//     )
		// },
		{
			field: 'advancedCustomerName',
			headerName: intl.formatMessage({ id: 'customerlist.table.name' }),
			headerClassName: 'styled-header',
			width: 350,
			renderCell: params => <span className={`${classes.cell} ${classes.customerName}`}>{params.row.advancedCustomerName}</span>,
		},
		{
			field: 'contactInfo',
			headerName: intl.formatMessage({ id: 'customerlist.table.contact.info' }),
			headerClassName: 'styled-header',
			sortable: false,
			width: 120,
			renderCell: params => {
				if (params.row.contactProfile) {
					return <CheckIcon color='primary' />;
				}
			},
		},
		{
			field: 'created',
			type: 'date',
			headerName: intl.formatMessage({ id: 'customerlist.table.profile.creation.date' }),
			headerClassName: 'styled-header',
			width: 200,
			renderCell: params => (
				<span className={classes.cell}>
					<FormattedDate value={params.row.created} />
				</span>
			),
		},
		{
			field: 'createProfile',
			disableColumnMenu: true,
			sortable: false,
			filterable: false,
			renderHeader: () => (
				<>
					{intl.formatMessage({ id: 'customerlist.table.create.profile.link' })}
					<InstructionPopover
						id='popperitem'
						content={intl.formatMessage({ id: 'customerlist.table.create.profile.link.instructions' })}
					/>
				</>
			),
			headerClassName: 'styled-header',
			width: 200,
			renderCell: params => (
				<Box textAlign='center'>
					<Button
						variant='outlined'
						size='small'
						color='primary'
						disabled={profileLoading}
						onClick={e => {
							e.stopPropagation();
							history.push(`/company/${params.row.businessId}/share`);
						}}
					>
						{intl.formatMessage({ id: 'customerlist.table.create.profile.link' })}
						{profileLoading && <CircularProgress size={24} className={classes.buttonLoading} />}
					</Button>
				</Box>
			),
		},
	];

	if ((isLoaded && !data) || data?.length === 0)
		return (
			<Box sx={{ mt: 2 }}>
				<Typography component='p' color='primary' align='center'>
					{intl.formatMessage({ id: /* phrase ? 'filter.no.items' : */ 'customerlist.no.customers' })}
				</Typography>
			</Box>
		);

	return (
		<>
			<DataGrid
				idField='businessId'
				loading={loading}
				data={data?.filter(item => item.advancedCustomerName) || []} // TODO: fix this, filter out no name -> actually, do not bring non existing customers
				columns={columns}
				token={null}
				rowsPerPage={ROWS_PER_PAGE}
				onRowClick={params => toggleModal(params.row)}
				checkboxSelection
				onSelectionModelChange={selected => setSelectedIds(selected)}
				selectionModel={selectedIds}
				disableSelectionOnClick={true}
				classes={{
					row: classes.row,
				}}
				mt={2}
			/>
			<ModalDialog
				open={isModalOpen}
				onClose={() => toggleModal(null)}
				title={<ModalTitle advancedCustomerName={modalData?.advancedCustomerName} rowKey={modalData?.rowKey} />}
				actions={
					<LeftRightContainer
						alignItems='flex-start'
						left={
							<Button variant='text' color='primary' onClick={() => toggleModal(null)}>
								{intl.formatMessage({ id: 'common.close' })}
							</Button>
						}
						right={
							<Grid container justifyContent='flex-end' spacing={2}>
								<Grid item>
									<ConfirmButton
										variant='outlined'
										color='primary'
										loading={removeCompanyLoading}
										disabled={removeCompanyLoading}
										confirmAction={() => removeCompany(id, [modalData.id])}
										confirmText={intl.formatMessage({ id: 'advanced.prospects.remove.confirm' })}
										cancelText={intl.formatMessage({ id: 'advanced.prospects.remove.cancel' })}
										confirmBodyText={intl.formatMessage({ id: 'advanced.prospects.remove.body' })}
										startIcon={<DeleteIcon />}
										hoverRed={true}
									>
										{intl.formatMessage({ id: 'common.delete' })}
									</ConfirmButton>
								</Grid>
								<Grid item>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<IosShareIcon />}
										disabled={profileLoading}
										onClick={() => {
											history.push(`/company/${modalData?.id}/share`);
										}}
									>
										{intl.formatMessage({ id: 'customerlist.table.create.profile.link' })}
										{profileLoading && <CircularProgress size={24} className={classes.buttonLoading} />}
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										color='primary'
										startIcon={<AddLinkIcon />}
										onClick={() => history.push(`/company/${modalData.id}`)}
									>
										{intl.formatMessage({ id: 'customerlist.table.open.profile' })}
									</Button>
								</Grid>
							</Grid>
						}
					/>
				}
			>
				<ModalContent
					created={modalData?.created}
					author={modalData?.author}
					botProfileRowKey={modalData?.botProfileRowKey}
					contactProfile={modalData?.contactProfile}
					advancedCustomerName={modalData?.advancedCustomerName}
					rowKey={modalData?.rowKey}
				/>
			</ModalDialog>
			<LinkPopover
				open={isPopoverOpen /*&& currentId === params.row.rowKey*/}
				anchorEl={anchorEl}
				handleClose={handleClose}
				link={profileLink}
				copiedToClipboard={copiedToClipboard}
			/>
		</>
	);
};

export default forwardRef(CreatedList);
