import { createSlice /* current */ } from '@reduxjs/toolkit';
import * as _ from 'lodash';

import { getStartDateArr } from 'containers/FinancialPlan/parserTimeline';
import { financialPlanApi } from './financialPlanApi';
import { publishAllDraftProjects } from 'containers/Projects/slices';
import { isContributorToken } from 'utils/auth/token';
import { mockFinancialPlan } from 'utils/mock/financialPlan';
import { delayWhile } from 'utils/misc/delay';
import { umlautsDecode } from 'utils/formatters/umlautsDecode';
import { replaceCompanyInformation } from './FinancialPlan/dynamicDataReplace';

export const name = 'financialPlan';

const initialState = {
	loading: false,
	error: false,
	financialPlan: null,
	publishedFinancialPlan: null,
	isScrolling: false,
	instrument: null,
	editMode: false,
	isLoaded: false,
	isMainContentMounted: false,
	instruments: {
		isLoaded: false,
		list: [],
	},
};

const slice = createSlice({
	name: name,
	initialState,
	reducers: {
		resetData() {
			return { ...initialState };
		},
		loadFinancialPlanSuccess(state, action) {
			state.financialPlan = action.payload;
		},
		loadPublishedFinancialPlanSuccess(state, action) {
			state.publishedFinancialPlan = action.payload;
		},
		storeFinancilaPlan(state, action) {
			state.financialPlan = action.payload;
		},
		setMainContentMounted(state, action) {
			state.isMainContentMounted = action.payload;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setError(state, action) {
			state.error = action.payload;
			state.loading = false;
		},
		loadFinancialPlanInstrumentsSuccess(state, action) {
			state.instruments.list = action.payload;
			state.instruments.isLoaded = true;
		},
	},
});

export const { reducer } = slice;

export const { loadFinancialPlanSuccess, loadPublishedFinancialPlanSuccess, loadFinancialPlanInstrumentsSuccess } = slice.actions;

export const loadFinancialPlan = edit => async dispatch => {
	try {
		dispatch(slice.actions.setLoading(true));

		let financialPlanMock = Object.assign({}, mockFinancialPlan);
		financialPlanMock.created = new Date();

		let financialPlan = edit ? await financialPlanApi.getFinancialPlan() : await financialPlanApi.getFinancialPlanPublished();

		let isMock = !financialPlan;

		if (isMock) {
			// dispatch(slice.actions.loadFinancialPlanSuccess(mockTimeline));
			dispatch(slice.actions.setLoading(false));

			return;
		}

		const decodedfinancialPlan = {
			...financialPlan,
			base: umlautsDecode(financialPlan.base),
			expertComments: umlautsDecode(financialPlan.expertComments),
			followup: umlautsDecode(financialPlan.followup),
			internalInformation: umlautsDecode(financialPlan.internalInformation),
			notice: umlautsDecode(financialPlan.notice),
			precondition: umlautsDecode(financialPlan.precondition),
		};

		dispatch(slice.actions.loadFinancialPlanSuccess(decodedfinancialPlan));

		if (edit) {
			await dispatch(loadFinancialPlanInstruments());
			const publishedFinancialPlan = await financialPlanApi.getFinancialPlanPublished(); // get published financial plan for previews

			await dispatch(slice.actions.loadPublishedFinancialPlanSuccess(publishedFinancialPlan));
		}

		dispatch(slice.actions.setLoading(false));

		return true;
	} catch (error) {
		if (error?.response?.status === 404 && !edit) {
			//dispatch(slice.actions.loadFinancialPlanSuccess(mockTimeline));
		}
		dispatch(slice.actions.setError(error));

		return null;
	}
};

export const loadFinancialPlanInstruments = () => async (dispatch, getState) => {
	try {
		let state = getState();

		if (state.instruments?.instruments?.loading) {
			let count = 0;

			await delayWhile(500, () => {
				count++;
				state = getState();

				return state.instruments.instruments.loading && count < 10; // stop looping while
			});
		}

		const instruments = await financialPlanApi.getInstruments();

		let mapped = _.map(instruments, item => (item.points = 0));

		const companyInstruments = state.instruments?.instruments?.data ?? [];

		mapped = _.map(instruments, instrument => {
			const companyInstrument = companyInstruments.find(item => instrument.id === item.id);

			if (companyInstrument) return companyInstrument;

			return instrument;
		});

		mapped = _.orderBy(mapped, ['points', 'sortOrder'], ['desc', 'desc']);

		dispatch(slice.actions.loadFinancialPlanInstrumentsSuccess(mapped));
	} catch (error) {
		dispatch(slice.actions.setError(error));
	}
};

export const setMainContentMounted = isLoaded => async dispatch => {
	try {
		dispatch(slice.actions.setMainContentMounted(isLoaded));
	} catch (error) {
		dispatch(slice.actions.setError(error));
	}
};

export const publishFinancialPlanProjects = () => async dispatch => {
	try {
		const response = await dispatch(publishAllDraftProjects());

		return response;
	} catch (error) {
		dispatch(slice.actions.setError(error));
	}
};

export const saveFinancialPlan = (financialPlan, draft, company) => async (dispatch, getState) => {
	try {
		dispatch(slice.actions.setLoading(true));

		financialPlan.base = replaceCompanyInformation(financialPlan.base, company);
		financialPlan.expertComments = replaceCompanyInformation(financialPlan.expertComments, company);
		financialPlan.precondition = replaceCompanyInformation(financialPlan.precondition, company);
		financialPlan.notice = replaceCompanyInformation(financialPlan.notice, company);
		financialPlan.followup = replaceCompanyInformation(financialPlan.followup, company);

		financialPlan.draft = draft;

		let response = null;

		if (draft) {
			response = await financialPlanApi.saveFinancialPlanData(financialPlan);
		} else {
			response = await financialPlanApi.saveFinancialPlanData(financialPlan);
			response = await financialPlanApi.publishFinancialPlanData(financialPlan);
		}

		getStartDateArr(response); // mutates the response object

		await dispatch(slice.actions.storeFinancilaPlan(response));

		if (!draft) {
			// publish all draft projects for the company
			await dispatch(publishFinancialPlanProjects());
		}

		await dispatch(loadFinancialPlan(isContributorToken()));

		dispatch(slice.actions.setLoading(false));

		return response;
	} catch (error) {
		dispatch(slice.actions.setError(error));
	}
};

export const resetFinancialPlanData = () => async dispatch => {
	try {
		dispatch(slice.actions.resetData());

		return true;
	} catch (error) {
		dispatch(slice.actions.setError(error));

		return null;
	}
};

export default slice;
