import { yupResolver } from '@hookform/resolvers/yup';
import { DeleteSharp as DeleteSharpIcon, Publish as PublishIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, BoxProps, DialogContent, DialogTitle, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { RfpAttachment, RfpProfile, RfpProposal, RfpProposalItem } from 'types/dataTypes';
import { getCompany, getCompanyId } from 'utils/auth/company';
import { mergeFieldValues } from 'utils/misc/formUtils';

import { useFetchRfpProfileQuery } from 'containers/Marketplace/rfpProfileApi';
import { useFetchRfpRequestQuery } from 'containers/Marketplace/rfpRequestApi';
import { openPopover } from '../popoverSlice';
import {
	useDeleteRfpProposalMutation,
	useFetchRfpProposalQuery,
	usePublishRfpProposalMutation,
	useUpsertRfpProposalMutation,
} from '../rfpProposalApi';

import RfpAttachmentInput from 'containers/Marketplace/attachment/RfpAttachmentInput';
import RfpAttachmentList from 'containers/Marketplace/attachment/RfpAttachmentList';
import RfpLastUpdatedTypography from 'containers/Marketplace/common/RfpLastUpdatedTypography';
import RfpStatusChip from 'containers/Marketplace/common/RfpStatusChip';
import CompanyProfileBadge from 'containers/Marketplace/profile/CompanyProfileBadge';
import RfpProfileStatusDraftAlert from 'containers/Marketplace/profile/RfpProfileStatusDraftAlert';
import RfpProposalItemInput from 'containers/Marketplace/proposal/RfpProposalItemInput';
import RfpProposalItemList from 'containers/Marketplace/proposal/RfpProposalItemList';
import RfpProposalTeamMemberInput from 'containers/Marketplace/proposal/RfpProposalTeamMemberInput';
import RfpProposalTeamMemberList from 'containers/Marketplace/proposal/RfpProposalTeamMemberList';
import UpsertRfpProposalItemDialog from 'containers/Marketplace/proposal/UpsertRfpProposalItemDialog';
import UpsertRfpProposalTeamMemberDialog from 'containers/Marketplace/proposal/UpsertRfpProposalTeamMemberDialog';
import { StyledTooltip } from '../common/StyledTooltip';

type Props = BoxProps & {
	requestId: string | null;
	proposalId: string | null;
	published: boolean;
	onAfterPublish?: () => void;
	onAfterRemove?: () => void;
	onDirtyChange?: (isDirty: boolean) => void;
};

type FieldValues = Partial<RfpProposal>;

const convertRfpProfileToFieldValues = (rfpProfile?: RfpProfile | null): FieldValues => {
	return {
		companyId: rfpProfile?.companyId,
		companyName: rfpProfile?.companyName,
		companyOverview: rfpProfile?.companyIngress,
		contactInformation: rfpProfile?.contactInformation,
	};
};

const UpsertRfpProposalForm: React.FC<Props> = ({
	requestId,
	proposalId,
	published,
	onAfterPublish,
	onAfterRemove,
	onDirtyChange,
	...otherProps
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const companyId = getCompanyId() ?? getCompany()?.CompanyId;

	const {
		data: rfpProfile,
		isLoading: isRfpProfileLoading,
		isError: isRfpProfileError,
	} = useFetchRfpProfileQuery({ companyId }, { skip: isNil(companyId) });

	const {
		data: rfpRequest,
		isLoading: isRfpRequestLoading,
		isError: isRfpRequestError,
	} = useFetchRfpRequestQuery({ companyId, requestId, published: true }, { skip: isNil(companyId) || isNil(requestId) });

	const {
		data: rfpProposal,
		isLoading: isRfpProposalLoading,
		isError: isRfpProposalError,
	} = useFetchRfpProposalQuery({ companyId, proposalId, published }, { skip: isNil(companyId) || isNil(proposalId) });

	const [upsertRfpProposal, { data: upsertedRfpProposal, isError: isUpsertError }] = useUpsertRfpProposalMutation();
	const [publishRfpProposal, { isError: isPublishError, isLoading: isPublishing }] = usePublishRfpProposalMutation();
	const [deleteRfpProposal, { isError: isDeleteError, isLoading: isDeleting }] = useDeleteRfpProposalMutation();

	const validationSchema = React.useMemo(
		() =>
			yup.object({
				projectOutline: yup.string().max(2000).required(),
				projectBudget: yup.string().max(2000).required(),
				projectTimeline: yup.string().max(2000).required(),
				contactInformation: yup.string().max(2000).required(),
				companyOverview: yup.string().max(2000).required(),
			}),
		[]
	);

	const defaultValues = React.useMemo(
		() => ({
			rfpProposalId: null,
			status: 'Draft' as const,
			createDate: null,
			updateDate: null,
			companyId: null,
			companyName: null,
			companyOverview: '',
			projectOutline: '',
			projectBudget: '',
			projectTimeline: '',
			contactInformation: '',
			rfpProposalItems: [],
			attachments: [],
			rfpProposalTeamMembers: [],
		}),
		[]
	);

	const {
		control,
		handleSubmit,
		reset,
		watch,
		formState: { isSubmitting, isDirty },
		getValues,
		setValue,
	} = useForm<FieldValues>({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const [rfpProposalId, updateDate, createDate, status] = watch(['rfpProposalId', 'updateDate', 'createDate', 'status']);

	const hasProposalId = Boolean(rfpProposalId);
	const isDraft = status === 'Draft';

	const handleFormSubmit = React.useCallback<SubmitHandler<FieldValues>>(
		async fieldValues => {
			try {
				await upsertRfpProposal({
					companyId,
					requestId,
					rfpProposal: fieldValues,
					published: fieldValues?.status === 'Published',
				}).unwrap();
				toast.success(formatMessage({ id: 'upsertRfpProposal.success.message' }));
			} catch (error) {
				console.error(error);
			}
		},
		[upsertRfpProposal, formatMessage, companyId, requestId]
	);

	React.useEffect(() => {
		onDirtyChange?.(isDirty);
	}, [onDirtyChange, isDirty]);

	React.useEffect(() => {
		if (!isNil(rfpProposal) || !isNil(rfpProfile)) {
			reset(mergeFieldValues(defaultValues, rfpProposal, convertRfpProfileToFieldValues(rfpProfile)));
		}
	}, [reset, defaultValues, rfpProposal, rfpProfile]);

	React.useEffect(() => {
		if (!isNil(upsertedRfpProposal)) {
			reset(mergeFieldValues(defaultValues, upsertedRfpProposal));
		}
	}, [reset, defaultValues, upsertedRfpProposal]);

	const onRemoveAttachment = (removedAttachmentId: string) => {
		const attachments = getValues()?.attachments;
		setValue('attachments', attachments?.filter(item => item.attachmentId !== removedAttachmentId) ?? []);
	};

	const onRemoveItem = (removedItemId: string) => {
		const items = getValues()?.rfpProposalItems;
		setValue('rfpProposalItems', items?.filter(item => item.rfpProposalItemId !== removedItemId) ?? []);
	};

	const onUpsertItem = (upsertItem: RfpProposalItem) => {
		const items = getValues()?.rfpProposalItems;

		if (!items || items.length === 0) return;

		const index = items.findIndex(item => item.rfpProposalItemId === upsertItem.rfpProposalItemId);

		if (index < 0) {
			items.push(upsertItem);
		} else {
			items[index] = upsertItem;
		}

		setValue('rfpProposalItems', items);
	};

	const onUpsertAttachment = (upsertItem: RfpAttachment) => {
		const attachments = getValues()?.attachments;

		if (!attachments || attachments.length === 0) return;

		const index = attachments.findIndex(item => item.attachmentId === upsertItem.attachmentId);

		if (index < 0) {
			attachments.push(upsertItem);
		} else {
			attachments[index] = upsertItem;
		}

		setValue('attachments', attachments);
	};

	const isLoading = isRfpProfileLoading || isRfpRequestLoading || isRfpProposalLoading;

	return (
		<>
			<UpsertRfpProposalItemDialog />
			<UpsertRfpProposalTeamMemberDialog />
			<Box {...otherProps} component='form' onSubmit={handleSubmit(handleFormSubmit)}>
				<DialogTitle>
					<Typography component='span' sx={{ fontWeight: 600 }}>
						<FormattedMessage id='rfpProposal.title' /> -
					</Typography>
					<Typography component='span' sx={{ ml: '.5rem' }}>
						{rfpRequest?.projectTitle} -
					</Typography>
					<Typography component='span' sx={{ ml: '.5rem', fontWeight: 600 }}>
						{rfpRequest?.companyName}
					</Typography>
				</DialogTitle>
				<DialogContent>
					{isRfpProfileError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchRfpProfile.error.message' />
						</Alert>
					)}
					{isRfpRequestError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchRfpRequest.error.message' />
						</Alert>
					)}
					{isRfpProposalError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='query.error.title' />
							</AlertTitle>
							<FormattedMessage id='fetchRfpProposal.error.message' />
						</Alert>
					)}
					{isUpsertError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='mutation.error.title' />
							</AlertTitle>
							<FormattedMessage id='upsertRfpProposal.error.message' />
						</Alert>
					)}
					{isPublishError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='mutation.error.title' />
							</AlertTitle>
							<FormattedMessage id='publishRfpProposal.error.message' />
						</Alert>
					)}
					{isDeleteError && (
						<Alert severity='error'>
							<AlertTitle>
								<FormattedMessage id='mutation.error.title' />
							</AlertTitle>
							<FormattedMessage id='deleteRfpProposal.error.message' />
						</Alert>
					)}
					<RfpProfileStatusDraftAlert />
					<Stack spacing={3}>
						<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
							<Stack flex='1' flexDirection='row' alignItems='flex-start' justifyContent='flex-end'>
								{isLoading ? (
									<Skeleton width={200} height={100} />
								) : (
									<>
										<LoadingButton
											startIcon={<DeleteSharpIcon />}
											variant='outlined'
											color='error'
											loading={isDeleting}
											disabled={!hasProposalId || isDirty}
											onClick={event => {
												dispatch(
													openPopover({
														name: 'ConfirmRemovePopover',
														data: {
															anchorEl: event.currentTarget,
															text: formatMessage({ id: 'rfpProposal.confirmRemove' }),
															description: formatMessage({ id: 'rfpRequestItems.confirmRemove.description' }),
															onSubmit: async () => {
																try {
																	await deleteRfpProposal({
																		companyId,
																		proposalId: rfpProposalId,
																		published: status === 'Published',
																	}).unwrap();

																	toast.success(
																		formatMessage({ id: 'deleteRfpProposal.success.message' })
																	);
																	onAfterRemove?.();
																} catch (error) {
																	console.error(error);
																}
															},
														},
													})
												);
											}}
										>
											<FormattedMessage id='rfpProposal.delete' />
										</LoadingButton>
										<LoadingButton
											type='submit'
											variant={isDraft ? 'outlined' : 'contained'}
											color={isDraft ? 'neutral' : 'primary'}
											loading={isSubmitting}
										>
											<FormattedMessage id='rfpProposal.submit' />
										</LoadingButton>
										{isDraft && (
											<LoadingButton
												endIcon={<PublishIcon />}
												loading={isPublishing}
												disabled={!hasProposalId || isDirty || rfpProfile?.status !== 'Published'}
												onClick={async event => {
													dispatch(
														openPopover({
															name: 'ConfirmPublishPopover',
															data: {
																anchorEl: event.currentTarget,
																text: formatMessage({ id: 'rfpProposal.confirmPublish' }),
																onSubmit: async () => {
																	try {
																		await publishRfpProposal({
																			companyId,
																			proposalId: rfpProposalId,
																		}).unwrap();
																		toast.success(
																			formatMessage({ id: 'publishRfpProposal.success.message' })
																		);
																		onAfterPublish?.();
																	} catch (error) {
																		console.error(error);
																	}
																},
															},
														})
													);
												}}
											>
												<FormattedMessage id='rfpProposal.publish' />
											</LoadingButton>
										)}
									</>
								)}
							</Stack>
						</Stack>
						<Stack flexDirection='row' alignItems='center'>
							{isLoading ? (
								<Skeleton width={300} height={60} />
							) : (
								<>
									<RfpStatusChip status={status} />
									<RfpLastUpdatedTypography createDate={createDate} updateDate={updateDate} />
								</>
							)}
						</Stack>
						<Stack spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
							<Stack flex='1' maxWidth={{ xs: '100%', md: '60%' }}>
								{isLoading ? (
									<>
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
									</>
								) : (
									<>
										<Controller
											name='projectOutline'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='projectBudget'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='projectTimeline'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='contactInformation'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}>
													<TextField
														{...field}
														label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}
														error={!isNil(fieldState.error)}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
										<Controller
											name='companyOverview'
											control={control}
											render={({ field, fieldState }) => (
												<StyledTooltip title={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}>
													<TextField
														{...field}
														error={!isNil(fieldState.error)}
														label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
														placeholder={formatMessage({ id: `rfpProposal.${field.name}.placeholder` })}
														helperText={fieldState.error?.message ?? `${field.value?.length} / ${2000}`}
														inputProps={{ maxLength: 2000 }}
														minRows={3}
														multiline
													/>
												</StyledTooltip>
											)}
										/>
									</>
								)}
							</Stack>
							<Stack flex='1' maxWidth={{ xs: '100%', md: '40%' }}>
								{isLoading ? (
									<>
										<Skeleton height={100} />
										<Skeleton height={100} />
										<Skeleton height={100} />
									</>
								) : (
									<>
										<CompanyProfileBadge companyId={companyId} />
										<Controller
											name='rfpProposalItems'
											control={control}
											render={({ field, fieldState }) => (
												<>
													{hasProposalId ? (
														<RfpProposalItemList
															control={control}
															onRemove={onRemoveItem}
															// onUpsert={onUpsertItem}
															requestId={rfpRequest?.rfpRequestId}
															proposalId={rfpProposalId}
															label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													) : (
														<RfpProposalItemInput
															{...field}
															requestId={rfpRequest?.rfpRequestId}
															label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													)}
												</>
											)}
										/>
										<Controller
											name='rfpProposalTeamMembers'
											control={control}
											render={({ field, fieldState }) => (
												<>
													{hasProposalId ? (
														<RfpProposalTeamMemberList
															control={control}
															// onRemove={onRemoveItem}
															proposalId={rfpProposalId}
															label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													) : (
														<RfpProposalTeamMemberInput
															{...field}
															label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													)}
												</>
											)}
										/>
										<Controller
											name='attachments'
											control={control}
											render={({ field, fieldState }) => (
												<>
													{hasProposalId ? (
														<RfpAttachmentList
															requestId={rfpRequest?.rfpRequestId}
															proposalId={rfpProposalId}
															parentType='RFPProposal'
															label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
															onRemoveAttachment={onRemoveAttachment}
															onUpsertAttachment={onUpsertAttachment}
														/>
													) : (
														<RfpAttachmentInput
															{...field}
															label={formatMessage({ id: `rfpProposal.${field.name}.label` })}
															error={!isNil(fieldState.error)}
															helperText={fieldState.error?.message}
														/>
													)}
												</>
											)}
										/>
									</>
								)}
							</Stack>
						</Stack>
					</Stack>
				</DialogContent>
			</Box>
		</>
	);
};

export default UpsertRfpProposalForm;
