import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, useTheme } from '@mui/styles';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const red = '133,36,68';
const yellow = '197,159,57';
const green = '7,120,113';

const useStyles = makeStyles(() => ({
    graph: {
        height: '1.5rem',
        width: '5rem',
        borderRadius: '80px',
        backgroundColor: 'rgba(255, 255, 255, 0.24)',
        '&:hover': {
            opacity: '0.8',
            cursor: 'pointer',
        },
    },
    popover: {
        padding: '1rem',
        maxWidth: '35rem',
    },
}));

const Pill = ({ score, scoreLevel, level }) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickOpen = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const gradient = level === scoreLevel.HIGH ? green : level === scoreLevel.MED ? yellow : red;
    const baseGradient = `linear-gradient(90deg, rgba(${gradient},1) ${score}%, ${theme.palette.primary.main} ${score}%)`;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const highText = intl.formatMessage({ id: 'score.grant.high' });
    const mediumText = intl.formatMessage({ id: 'score.grant.medium' });
    const lowText = intl.formatMessage({ id: 'score.grant.low' });

    return (
        <div>
            <div className={classes.graph} style={{ background: baseGradient }} onClick={handleClickOpen} aria-hidden='true' />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.popover} sx={{ p: 2 }}>
                    {level === scoreLevel.HIGH ? highText : level === scoreLevel.MED ? mediumText : lowText} <strong>{score}</strong>.
                </Typography>
            </Popover>
        </div>
    );
};

export default Pill;

