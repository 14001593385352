import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import ErrorIcon from '@mui/icons-material/Error';

const Error = ({ children, error }) => {
    const theme = useTheme();
    const intl = useIntl();

    const AdditionalErrorBox = () => {
        const additionalError = error?.data?.content && JSON.parse(error?.data?.content);

        if (!additionalError) return null;

        const errorText = intl.formatMessage({ id: `stakeholder.error.${additionalError}` });

        if (errorText === `stakeholder.error.${additionalError}`) return null;

        return (
            <Box sx={{ pl: '2.5rem', mt: '1rem' }}>
                {errorText}
            </Box>
        );
    };

    return (
        <Stack sx={{
            padding: '2rem',
            backgroundColor: theme.palette.error.main,
            color: theme.palette.primary.white,

        }}>
            <Stack direction='row'>
                <ErrorIcon sx={{ mr: '1rem' }} />
                {children}
            </Stack>
            <AdditionalErrorBox />
        </Stack>
    );
};

export default Error;