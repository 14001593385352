import { Box, TableCell } from '@mui/material';

const ProjectPrintTableCell = ({
	children,
	sx = {},
	lastColumn = false,
	usetablecell = true,
}: {
	children: any;
	sx?: any;
	lastColumn?: boolean;
	usetablecell?: boolean;
}) => {
	if (lastColumn) {
		sx = Object.assign({}, { width: '190px' }, sx);
	}

	sx = Object.assign(
		{},
		{
			border: 'none',
			textAlign: 'left',
			verticalAlign: 'top',
			fontSize: '0.95rem',
			'& p': {
				margin: 0,
			},
			p: '1rem 1.5rem',
		},
		sx
	);

	if (usetablecell)
		return (
			<TableCell className='tdPrint' sx={sx}>
				{children}
			</TableCell>
		);

	return (
		<Box className='tdPrint' sx={sx}>
			{children}
		</Box>
	);
};

export default ProjectPrintTableCell;
