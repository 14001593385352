import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { useActivateAccountMutation } from 'containers/Account/passwordV2Api';

import Logo from 'icons/Logo';
import ActivateAccountForm from './ActivateAccountForm';

type Props = {
	match: any;
};

type FieldValues = {
	id: string | null;
	username: string | null;
	password: string | null;
	newpassword: string | null;
	confirmpassword: string | null;
};

const ActivateAccount: React.FC<Props> = ({ match }) => {
	const history = useHistory();

	const [activateAccount, { isLoading }] = useActivateAccountMutation();
	const onSubmit = React.useCallback(
		async (fieldValues: FieldValues) => {
			if (fieldValues?.newpassword !== fieldValues?.confirmpassword) {
				toast.error(<FormattedMessage id='message.passwordsNotMatch' />);
				return;
			}
			try {
				await activateAccount(fieldValues).unwrap();
				toast.success(<FormattedMessage id='login.account.activate.success.message' />);
				history.push('/login');
			} catch (error: any) {
				if (error?.data === 'User activation link not found') {
					toast.error(<FormattedMessage id={'error.code.not_found'} />);
					return;
				}

				toast.error(<FormattedMessage id={`error.code.${error?.data}`} />);
			}
		},
		[history, activateAccount]
	);

	return (
		<>
			<Helmet>
				<title>GrantedAI - activate account</title>
			</Helmet>
			<Stack
				direction='column'
				spacing={2}
				sx={{ minHeight: { xs: '40rem', md: '100%' }, padding: '1.143rem', backgroundColor: theme => theme.palette.primary.main }}
				useFlexGap
			>
				<Logo />
				<Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Stack direction='column' spacing={2} sx={{ width: '100%', maxWidth: '30rem' }} useFlexGap>
						<Typography
							variant='h1'
							sx={{
								color: theme => theme.palette.primary.contrastText,
								fontSize: '1.714rem',
								fontWeight: 700,
								lineHeight: 1.3,
								textAlign: 'center',
							}}
						>
							<FormattedMessage id='login.activateAccount.title' />
						</Typography>
						<Typography
							sx={{
								color: theme => theme.palette.primary.contrastText,
								fontSize: '1.143rem',
								fontWeight: 400,
								lineHeight: 1.5,
								textAlign: 'center',
							}}
						>
							<FormattedMessage id='login.activateAccount.fillform' />
						</Typography>
						{/* @ts-ignore */}
						<ActivateAccountForm id={match?.params?.id} loading={isLoading} onSubmit={onSubmit} activate />
					</Stack>
				</Box>
			</Stack>
		</>
	);
};

export default ActivateAccount;
