import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'utils/axios/axiosBaseQuery';
import { buildBaseUrl } from 'utils/helpers/uriHelper';

import { isNil } from 'lodash';
import { FinancialPlan, Instrument } from 'types/dataTypes';

type GetFinancialPlan = {
	companyId: string | null;
};

type GetPublishedFinancialPlan = {
	companyId: string | null;
};

type SaveFinancialPlan = {
	companyId: string | null;
	financialPlan: Partial<FinancialPlan>;
};

type PublishFinancialPlan = {
	companyId: string | null;
	financialPlan: Partial<FinancialPlan>;
};

type GetInstruments = {
	companyId: string | null;
};

const Tags = {
	FinancialPlan: 'financialPlan',
	Instrument: 'instrument',
};

const baseUrl = 'financialplan';

export const financialPlanV2Api = createApi({
	reducerPath: 'financialPlanV2',
	baseQuery: axiosBaseQuery({ baseUrl: buildBaseUrl(baseUrl) }),
	tagTypes: [Tags.FinancialPlan, Tags.Instrument],
	endpoints: builder => ({
		getFinancialPlan: builder.query<FinancialPlan, GetFinancialPlan>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/document`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.FinancialPlan, id: params?.companyId ?? '' }];
			},
		}),
		saveFinancialPlan: builder.mutation<FinancialPlan, SaveFinancialPlan>({
			query: ({ companyId, financialPlan }) => {
				if (isNil(companyId) || isNil(financialPlan)) {
					throw new Error('parameter error');
				}
				return {
					method: 'PUT',
					url: `/${companyId}/document`,
					data: financialPlan,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.FinancialPlan, id: params?.companyId ?? '' }];
			},
		}),
		publishFinancialPlan: builder.mutation<FinancialPlan, PublishFinancialPlan>({
			query: ({ companyId, financialPlan }) => {
				if (isNil(companyId) || isNil(financialPlan)) {
					throw new Error('parameter error');
				}
				return {
					method: 'POST',
					url: `/${companyId}/document/publish`,
					data: financialPlan,
				};
			},
			invalidatesTags: (data, error, params) => {
				return [{ type: Tags.FinancialPlan, id: params?.companyId ?? '' }];
			},
		}),
		getInstruments: builder.query<Instrument[], GetInstruments>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/instruments`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.Instrument, id: params?.companyId ?? '' }];
			},
		}),
		getPublishedFinancialPlan: builder.query<FinancialPlan, GetPublishedFinancialPlan>({
			query: ({ companyId }) => {
				if (isNil(companyId)) {
					throw new Error('parameter error');
				}
				return {
					method: 'GET',
					url: `/${companyId}/document/published`,
				};
			},
			providesTags: (data, error, params) => {
				return [{ type: Tags.FinancialPlan, id: params?.companyId ?? '' }];
			},
		}),
	}),
});

export const {
	useGetFinancialPlanQuery,
	useSaveFinancialPlanMutation,
	usePublishFinancialPlanMutation,
	useGetInstrumentsQuery,
	useGetPublishedFinancialPlanQuery,
} = financialPlanV2Api;
