import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
	title: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.white,
	},
	content: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.white,
	},
	buttonsBox: {
		justifyContent: 'space-around',
		paddingBottom: theme.spacing(2),
		backgroundColor: theme.palette.primary.white,
	},
	submitButton: {
		color: theme.palette.primary.white,
		backgroundColor: theme.palette.success.main,
		'&:hover': {
			opacity: '0.8',
			backgroundColor: theme.palette.success.main,
		},
	},
}));

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm, submitApproveText }) => {
	const intl = useIntl();
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='confirm-dialog'>
			<DialogTitle align='center' id='confirm-dialog' className={classes.title}>
				{intl.formatMessage({ id: title })}
			</DialogTitle>
			<DialogContent align='center' className={classes.content}>
				{children}
			</DialogContent>
			<DialogActions className={classes.buttonsBox}>
				<Button onClick={() => setOpen(false)}>{intl.formatMessage({ id: 'shared.cancel' })}</Button>
				<Button
					variant='contained'
					onClick={() => {
						setOpen(false);
						onConfirm();
					}}
					className={classes.submitButton}
				>
					{submitApproveText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
