import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { compact, join, split } from 'lodash';
import { useController } from 'react-hook-form';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const Tags = ({ loading, businessId, name, defaultValue, control, setValue, options = [], readonly, onInputBlur = () => {} }) => {
	const intl = useIntl();

	const [inputValue, setInputValue] = useState('');

	const { field } = useController({ name, control, defaultValue });

	if (readonly) {
		return compact(split(field?.value, ';'))?.map((option, index) => {
			return <Chip key={index} label={option} size='small' color='primary' style={{ marginRight: '0.75rem' }} />;
		});
	}

	return loading ? (
		<CircularProgress size={16} color='primary' />
	) : (
		<Stack direction='row' spacing={1.5} mb={2}>
			{businessId && (
				<Chip
					label={businessId}
					size='small'
					sx={{
						color: 'seconary.grey700',
						marginTop: '3px',
					}}
				/>
			)}
			<Autocomplete
				multiple
				freeSolo
				fullWidth
				disableClearable
				filterSelectedOptions
				options={options}
				getOptionLabel={option => option}
				value={compact(split(field?.value, ';')) ?? []}
				inputValue={inputValue}
				onChange={(_, data) => {
					setValue(name, join(data, ';'), { shouldDirty: true });
					onInputBlur();
				}}
				onInputChange={(_, newInputValue) => {
					setInputValue(newInputValue);
				}}
				renderTags={(value, getTagProps) => {
					return value.map((option, index) => (
						<Chip
							key={index}
							label={option}
							size='small'
							color='primary'
							style={{ marginRight: '0.75rem' }}
							{...getTagProps({ index })}
						/>
					));
				}}
				renderInput={params => {
					return (
						<TextField
							{...params}
							fullWidth
							variant='standard'
							placeholder={!readonly ? `${intl.formatMessage({ id: 'business.plan.new.tag' })}` : ''}
							InputProps={{ ...params.InputProps, disableUnderline: true }}
						/>
					);
				}}
				disabled={readonly}
			/>
		</Stack>
	);
};

export default Tags;
