import axios from 'utils/axios';
import { buildPlainUri, buildUri, validateHttpStatus } from 'utils/helpers/uriHelper';

const baseUrl = 'businessplan';

class BusinessPlanApi {
	async getInfoItems() {
		const url = buildUri(baseUrl, 'info');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveInfoItem(infoItem: any) {
		const url = buildUri(baseUrl, 'info');
		const response = await axios.post(url, infoItem, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async removeInfoItem(id: string) {
		const url = buildUri(baseUrl, `info/${id}`);
		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getEconomicalData() {
		const url = buildUri(baseUrl, 'economical');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async saveEconomicalData(data: any) {
		const url = buildUri(baseUrl, 'economical');
		const response = await axios.post(url, data, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async removeEconomicalData(year: string | number) {
		const url = buildUri(baseUrl, `economical/${year}`);
		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getTemplates() {
		const url = buildPlainUri(baseUrl, 'templates');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async loadTags() {
		const url = buildPlainUri(baseUrl, 'constants/tags');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getAttachments() {
		const url = buildUri(baseUrl, 'attachments');
		const response = await axios.get(url, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async getAttachmentFile(attachmentId: string) {
		const url = buildUri(baseUrl, `attachment/${attachmentId}`);
		const response = await axios.get(url, {
			responseType: 'blob', //DO NOT TOUCH: important -> won't work without
			validateStatus: validateHttpStatus,
		});

		return response.data;
	}

	async addAttachment(data: any) {
		const url = buildUri(baseUrl, 'attachment');
		const response = await axios.post(url, data.attachments, { validateStatus: validateHttpStatus });

		return response.data;
	}

	async removeAttachment(attachmentId: string) {
		const url = buildUri(baseUrl, `attachment/${attachmentId}`);
		const response = await axios.delete(url, { validateStatus: validateHttpStatus });

		return response.data;
	}
}

export const businessPlanApi = new BusinessPlanApi();
