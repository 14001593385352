import * as React from 'react';
import { isNil } from 'lodash';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

type Props<FV extends FieldValues = FieldValues, N extends FieldPath<FV> = FieldPath<FV>> = ControllerProps<FV, N> & {};

function NumberController<FV extends FieldValues = FieldValues, N extends FieldPath<FV> = FieldPath<FV>>({
	render,
	...otherProps
}: Props<FV, N>) {
	return (
		<Controller
			{...otherProps}
			render={({ field, ...otherRenderProps }) => {
				const fieldValue = field.value as any;
				return render?.({
					field: {
						...field,
						value: isNil(fieldValue) ? '' : fieldValue,
						onChange: event => {
							const targetValue = parseFloat(event.target.value);
							field.onChange({
								...event,
								target: {
									...event.target,
									value: isNaN(targetValue) ? null : targetValue,
								},
							});
						},
					},
					...otherRenderProps,
				});
			}}
		/>
	);
}

export default NumberController;
