import { useIntl } from 'react-intl';

import BoxContainer from 'components/Containers/BoxContainer';
import { finance, projectCostSelector } from 'containers/Projects/slices';
import { sumBy } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Instrument, Project, ProjectFinance } from 'types/dataTypes';

import { Box, Stack } from '@mui/material';
import { DataGrid, GridColumns, GridFooterContainer, GridRowModel } from '@mui/x-data-grid';

import { useFetchInstrumentsByCompanyQuery } from 'containers/Instruments/instrumentV2Api';
import { calculateExpenseAmount } from 'containers/Projects/Project/Costs/ProjectCostForm/ProjectCostForm';
import { useEffect } from 'react';
import { gridTranslations } from 'translations/fi/gridTranslations';
import { currencyFormatter, percentFormatterWithFractionDigits } from 'utils/formatters/numberFormatters';
import { getCompanyId } from '../../../../../utils/auth/company';
import { calculateFlatrateSum, parseCosts } from '../../ProjectPlan/ProjectPlanPrint/CostsTable/CostsTable';

const GridFooter = (props: any) => {
	const { getValues } = useFormContext();

	const { total }: { total: number } = props;

	const data: ProjectFinance[] = getValues('projectFinance');

	const totalPercents = sumBy(data, item => item?.amountPercent ?? 0);
	const totalAmount = sumBy(data, item => item?.amount ?? 0);

	return (
		<GridFooterContainer sx={{ p: '.8rem' }}>
			<Stack direction='column'>
				<Stack direction='row'>
					<Box sx={{ width: 500, fontWeight: 600 }}>Rahoitus yhteensä</Box>
					<Box sx={{ width: 180, textAlign: 'right', fontWeight: 600 }}>{currencyFormatter.format(totalAmount ?? 0)}</Box>
					<Box sx={{ width: 100, textAlign: 'right', fontWeight: 600 }}>
						{percentFormatterWithFractionDigits.format(totalPercents / 100)}
					</Box>
					{/* <Box>{percentFormatterWithFractionDigits.format(totalPercents)}</Box> */}
				</Stack>
				<Stack direction='row' sx={{ mt: '.5rem' }}>
					<Box sx={{ width: 500, fontWeight: 600 }}>Kulut yhteensä</Box>
					<Box sx={{ width: 180, textAlign: 'right', fontWeight: 600 }}>{currencyFormatter.format(total ?? 0)}</Box>
				</Stack>
			</Stack>
		</GridFooterContainer>
	);
};

const FinanceGrid = ({ data, total, instrument }: { data: ProjectFinance[]; total: number; instrument: Instrument | undefined }) => {
	const { setValue, getValues } = useFormContext();

	const intl = useIntl();

	const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
		const amountChanged = newRow.amount !== oldRow.amount;
		const percentChanged = newRow.amountPercent !== oldRow.amountPercent;

		if (amountChanged) {
			newRow.amountPercent = (newRow.amount / total) * 100;
		}

		if (percentChanged) {
			newRow.amount = (newRow.amountPercent / 100) * total;
		}

		let currentData = [...getValues('projectFinance')];
		currentData[newRow.index] = newRow;

		setValue('projectFinance', currentData, { shouldDirty: true });

		return newRow;
	};

	const columns: GridColumns = [
		{ field: 'id', headerName: 'Id', type: 'string', editable: false, hide: true },
		{ field: 'name', headerName: 'Nimi', width: 500, type: 'string', editable: true },
		{
			field: 'amount',
			headerName: 'Osuus €',
			width: 200,
			type: 'number',
			editable: true,
			valueFormatter: ({ value }) => currencyFormatter.format(value ?? 0),
			// valueGetter: (params: any) => {
			// 	const cost: ProjectFinance = params.row;

			// 	if (cost.id === 'eugovfunding' && cost.amountPercent === 0 && instrument) {
			// 		// @ts-ignore
			// 		return (instrument.amountMaxPercentage / 100) * total;
			// 	}

			// 	return cost.amount;
			// },
		},
		{
			field: 'amountPercent',
			headerName: 'Osuus %',
			width: 100,
			type: 'number',
			editable: true,
			valueFormatter: ({ value }) => {
				if (value) {
					value = value / 100;
				}

				return percentFormatterWithFractionDigits.format(value ?? 0);
			},
			// valueGetter: (params: any) => {
			// 	const cost: ProjectFinance = params.row;

			// 	if (cost.id === 'eugovfunding' && cost.amountPercent === 0 && instrument) {
			// 		// @ts-ignore
			// 		return instrument.amountMaxPercentage;
			// 	}

			// 	return cost.amountPercent;
			// },
		},
	];

	if (!data) return <div>No project finance data</div>;

	return (
		<Stack spacing={4}>
			<DataGrid
				getRowId={row => row.id}
				localeText={gridTranslations(intl)}
				autoHeight
				rows={data}
				columns={columns}
				onProcessRowUpdateError={error => console.error(error)}
				//rowModesModel={rowModesModel}
				//onRowModesModelChange={newModel => setRowModesModel(newModel)}
				//onRowEditStart={handleRowEditStart}
				//onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				components={{
					Footer: GridFooter,
				}}
				componentsProps={{
					footer: { total },
				}}
				hideFooterPagination
				hideFooter={false}
				disableSelectionOnClick
				experimentalFeatures={{ newEditingApi: true }}
			/>
		</Stack>
	);
};

const FinanceTable = ({ project, control }: { project: Project; control: any }) => {
	const { getValues, setValue, watch } = useFormContext();
	// @ts-ignore
	let data: Project = Object.assign({}, getValues());
	const companyId = getCompanyId();

	const projectFinanceWatch = watch('projectFinance'); // force changes
	const flatRateDevelopment = watch('flatRateDevelopment'); // force changes

	const {
		data: instruments,
		isLoading: isInstrumenstLoading,
		isFetching: isInstrumenstFetching,
	} = useFetchInstrumentsByCompanyQuery({ companyId: companyId }, { skip: !data || !companyId });

	const instrument = instruments?.find(item => item.id === data.instrumentId);

	// @ts-ignore
	const costs = projectCostSelector(getValues());

	const total = sumBy(costs, cost => {
		if (cost.category === 'salary') {
			const salary = cost.amountApplied * (cost.workEstimate ?? 0); //@ts-ignore
			const personnelSideCosts =
				calculateExpenseAmount(cost.salaryExpenseType, cost.salaryExpense, cost.amountApplied) * (cost.workEstimate ?? 0);

			return salary + personnelSideCosts;
		}

		return cost.amountApplied;
	});

	const costSums = parseCosts(costs);
	const flatrateDevelopmentSum = calculateFlatrateSum(project, instrument, flatRateDevelopment ?? 0, costSums, 'development');
	const flatrateInvestmentSum = calculateFlatrateSum(project, instrument, flatRateDevelopment ?? 0, costSums, 'investment');

	const dataLoaded = data.projectFinance !== null && data.projectFinance.length !== 0 && !isInstrumenstLoading && !isInstrumenstFetching;

	useEffect(() => {
		if (!dataLoaded) return;

		let financeFields = [...(data?.projectFinance ?? finance)];
		for (let index = 0; index < financeFields.length; index++) {
			if (!financeFields[index] || !financeFields[index].amountPercent) continue;

			// @ts-ignore
			financeFields[index].amount = (financeFields[index].amountPercent * total) / 100;
		}

		setValue('projectFinance', financeFields, { shouldDirty: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [total]);

	if (dataLoaded) {
		let field = data?.projectFinance?.find(item => item.id === 'eugovfunding');

		if (field && field.amountPercent && field?.amountPercent > 0 && field.amount === 0) {
			// @ts-ignore
			field.amount = (instrument?.amountMaxPercentage * total) / 100;
		}

		if (field && field.amountPercent === 0 && instrument) {
			// @ts-ignore
			field.amountPercent = instrument.amountMaxPercentage; // @ts-ignore
			field.amount = (instrument.amountMaxPercentage * total) / 100;
		}
	}

	return (
		<BoxContainer>
			{dataLoaded && (
				<FinanceGrid
					{...{
						data: data.projectFinance ?? finance,
						total: total + flatrateDevelopmentSum + flatrateInvestmentSum,
						instrument,
					}}
				/>
			)}
		</BoxContainer>
	);
};

export default FinanceTable;
