import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/material';

import Logo from 'components/Logo/Logo';

const useStyles = makeStyles(theme => ({
	container: {
		minHeight: '100%',
		maxWidth: '100%',
		backgroundColor: theme.palette.primary.main,
	},
	box: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(3),
		maxWidth: '35rem',
		backgroundColor: theme.palette.primary.white,
	},
	logo: {
		marginBottom: theme.spacing(5),
	},
}));

const LoginWrapper = ({ children, inverse = false }) => {
	const classes = useStyles();

	return (
		<Stack
			sx={{ pt: '4rem' }}
			direction='row-reverse'
			justifyContent='center'
			alignItems='flex-start'
			spacing={2}
			className={classes.container}
		>
			<Box className={classes.box}>
				<Box textAlign='center' className={classes.logo}>
					<Logo inverse width='214' />
				</Box>
				{children}
			</Box>
		</Stack>
	);
};

export default LoginWrapper;
