import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Portal } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import CustomPrompt from 'components/CustomPrompt';
import EconomicalTable from '../EconomicalTable';
import Tags from '../Tags';
import CostArray from './CostsArray';
import EditorPanel from './EditorPanel';

import useFormErrorEffect from 'components/Form/utils/useFormErrorEffect';
import { getDirtyKeys } from 'components/Form/utils/utils';
import { getSchema, maxLength } from './buinessPlanSchema';

const BusinessPlanForm = forwardRef(
	(
		{
			mainLoading,
			templatesLoading,
			businessId,
			data,
			templates,
			onSubmit,
			setIsDirty,
			container,
			tags = {},
			readonly = false,
			updateLocalBackup = () => { },
		},
		formRef
	) => {
		const intl = useIntl();
		const schema = getSchema(intl);

		const {
			handleSubmit,
			control,
			setValue,
			getValues,
			watch,
			reset,
			formState: { dirtyFields, errors },
		} = useForm({
			defaultValues: {
				pitch: '',
				problem: '',
				solution: '',
				markets: '',
				globalMarket: '',
				vision: '',
				costs: [],
			},
			resolver: yupResolver(schema),
		});

		useEffect(() => {
			reset(data);
		}, [reset, data]);

		const dirtyKeys = getDirtyKeys(dirtyFields);
		const isDirty = dirtyKeys.length > 0;

		useFormErrorEffect(errors);

		useEffect(() => {
			setIsDirty && setIsDirty(isDirty);
		}, [isDirty, setIsDirty]);

		// portal
		const [portal, setPortal] = useState(false);
		useEffect(() => {
			if (!portal) {
				setPortal(true);
			}
		}, [portal]);

		const PortalTags = () => {
			if (portal && container) {
				return (
					<Portal container={container}>
						<Tags
							readonly={readonly}
							loading={mainLoading}
							businessId={businessId}
							name='tags'
							defaultValue={data?.tags ?? ''}
							control={control}
							setValue={setValue}
							options={tags?.businessPlanTags ?? []}
							onInputBlur={() => updateLocalBackup(getValues())}
						/>
					</Portal>
				);
			}

			return (
				<Tags
					readonly={readonly}
					loading={mainLoading}
					businessId={businessId}
					name='tags'
					defaultValue={data?.tags ?? ''}
					control={control}
					setValue={setValue}
					options={tags?.businessPlanTags ?? []}
					onInputBlur={() => updateLocalBackup(getValues())}
				/>
			);
		};

		return (
			<form
				ref={formRef}
				noValidate
				onSubmit={handleSubmit(data => {
					onSubmit(data);
					reset(data);
				})}
			>
				<CustomPrompt dirtyFields={dirtyFields} isDirty={isDirty} translation={'businessplan'} />
				<Box>
					<PortalTags />
				</Box>
				<EconomicalTable
					id='economicalData'
					control={control}
					getValues={getValues}
					readonly={readonly}
					onInputBlur={() => updateLocalBackup(getValues())}
				/>
				<EditorPanel
					mainLoading={mainLoading}
					templatesLoading={templatesLoading}
					value={data && Object.keys(data).length > 0 ? data?.pitch : ''}
					title='pitch'
					placeholder={intl.formatMessage({ id: 'businessplan.pitch.placeholder' })}
					templates={templates && Object.keys(templates).length > 0 ? templates['pitch'] : ''}
					control={control}
					error={errors?.pitch}
					setValue={setValue}
					maxLength={maxLength}
					readonly={readonly}
					onInputBlur={() => updateLocalBackup(getValues())}
				/>
				<EditorPanel
					mainLoading={mainLoading}
					templatesLoading={templatesLoading}
					value={data && Object.keys(data).length > 0 ? data?.problem : ''}
					title='problem'
					placeholder={intl.formatMessage({ id: 'businessplan.problem.placeholder' })}
					templates={templates && Object.keys(templates).length > 0 ? templates['problem'] : ''}
					control={control}
					error={errors?.problem}
					setValue={setValue}
					maxLength={maxLength}
					readonly={readonly}
					onInputBlur={() => updateLocalBackup(getValues())}
				/>
				<EditorPanel
					mainLoading={mainLoading}
					templatesLoading={templatesLoading}
					value={data && Object.keys(data).length > 0 ? data?.solution : ''}
					title='solution'
					placeholder={intl.formatMessage({ id: 'businessplan.solution.placeholder' })}
					templates={templates && Object.keys(templates).length > 0 ? templates['solution'] : ''}
					control={control}
					error={errors?.solution}
					setValue={setValue}
					maxLength={maxLength}
					readonly={readonly}
					onInputBlur={() => updateLocalBackup(getValues())}
				/>
				<EditorPanel
					mainLoading={mainLoading}
					templatesLoading={templatesLoading}
					value={data && Object.keys(data).length > 0 ? data?.markets : ''}
					title='markets'
					placeholder={intl.formatMessage({ id: 'businessplan.markets.placeholder' })}
					templates={templates && Object.keys(templates).length > 0 ? templates['markets'] : ''}
					control={control}
					error={errors?.markets}
					setValue={setValue}
					maxLength={maxLength}
					readonly={readonly}
					onInputBlur={() => updateLocalBackup(getValues())}
				/>
				<EditorPanel
					mainLoading={mainLoading}
					templatesLoading={templatesLoading}
					value={data && Object.keys(data).length > 0 ? data?.globalMarket : ''}
					title='globalMarket'
					placeholder={intl.formatMessage({ id: 'businessplan.globalMarket.placeholder' })}
					templates={templates && Object.keys(templates).length > 0 ? templates['globalMarket'] : ''}
					control={control}
					error={errors?.globalMarket}
					setValue={setValue}
					maxLength={maxLength}
					readonly={readonly}
					onInputBlur={() => updateLocalBackup(getValues())}
				/>
				<EditorPanel
					mainLoading={mainLoading}
					templatesLoading={templatesLoading}
					value={data && Object.keys(data).length > 0 ? data?.vision : ''}
					title='vision'
					placeholder={intl.formatMessage({ id: 'businessplan.vision.placeholder' })}
					templates={templates && Object.keys(templates).length > 0 ? templates['vision'] : ''}
					control={control}
					error={errors?.vision}
					setValue={setValue}
					tagName='visionTags'
					tagDefaultValue={data?.visionTags ?? ''}
					tagOptions={tags?.uniqueProjectAims ?? []}
					maxLength={maxLength}
					readonly={readonly}
					onInputBlur={() => updateLocalBackup(getValues())}
				>
					<CostArray {...{ control, watch, errors, tags, readonly, onInputBlur: () => updateLocalBackup(getValues()) }} />
				</EditorPanel>
			</form>
		);
	}
);

export default BusinessPlanForm;
