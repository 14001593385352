import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    contentWrapper: {
        flex: 1,
    },
    contentContainer: {
        height: '100%',
    },
    title: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: theme.palette.primary.main,
    },
    container: {
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
    },

    contentBox: {
        backgroundColor: '#f5f5f5',
    },
    relativeContainer: {
        position: 'relative',
    },
    absoluteContainer: {
        position: 'absolute',
        top: theme.spacing(-1.8),
    },
    iconBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(1.8),
        width: theme.spacing(3.6),
        height: theme.spacing(3.6),
    },
    avatarBox: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    review: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    name: {
        fontWeight: 600,
        fontSize: '1rem',
        padding: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
    },
    company: {
        padding: theme.spacing(4),
        paddingTop: 0,
    },
    grants: {
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        },
        fontWeight: 700,
        paddingBottom: theme.spacing(4),
        color: theme.palette.primary.main,
    },
}));

const Slide9 = ({ maxHeight }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container direction='column' alignItems='center' className={classes.root} style={{ maxHeight }}>
            <Grid item className={classes.contentWrapper}>
                <Grid container direction='column' justifyContent='center' className={classes.contentContainer}>
                    <Grid item>
                        <Box textAlign='center'>
                            <Typography className={classes.title}>Miten edetään …</Typography>
                        </Box>
                        <Grid container spacing={4} className={classes.container}>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.contentBox}>
                                    <Grid container direction='column' alignItems='center' className={classes.relativeContainer}>
                                        <Grid item className={classes.absoluteContainer}>
                                            <Box className={classes.iconBox}>
                                                <FormatQuoteIcon color='primary' />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box className={classes.avatarBox}>
                                                <Avatar
                                                    alt='Antti Lähtevänoja'
                                                    src='https://docplayer.fi/docs-images/106/172213120/images/2-0.jpg'
                                                    className={classes.avatar}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box textAlign='left'>
                                        <Typography className={classes.review}>
                                            On tullut fiilis, että Grantsilla oikeasti välitetään, ja mietitte toiminnot asiakkaan
                                            parhailmaan mukaan. Granted AI on hyvin selkeä - parhaita sovelluksia mitä olen käyttänyt. On
                                            erittäin selvää, että olette tuottaneet meille paljon lisäarvoa!
                                        </Typography>
                                    </Box>
                                    <Typography className={classes.name}>Antti Lähtevänoja</Typography>
                                    <Typography className={classes.company}>Toimitusjaohtaja, Invested Group Oy</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.contentBox}>
                                    <Grid container direction='column' alignItems='center' className={classes.relativeContainer}>
                                        <Grid item className={classes.absoluteContainer}>
                                            <Box className={classes.iconBox}>
                                                <FormatQuoteIcon color='primary' />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box className={classes.avatarBox}>
                                                <Avatar
                                                    alt='Eero Tilsala'
                                                    src='https://biovoima.com/wp-content/uploads/2020/01/Suomen-Biovoima-Eero-Tilsala-BW-1.jpg'
                                                    className={classes.avatar}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box textAlign='left'>
                                        <Typography className={classes.review}>
                                            Granted AI - tyokalu on toiminut verrattomana apuna kehittämisrahoitusviidakossa. Erityisesti
                                            olemme hyötyneet selkeästä etenemissuunnitelmasta ja ilmoitusten mahdollistamasta napeasta
                                            reagointimahdollisuudesta muun muassa kustannustukien osalta.
                                        </Typography>
                                    </Box>
                                    <Typography className={classes.name}>Eero Tilsala</Typography>
                                    <Typography className={classes.company}>Toimitusjohtaja, Suomen Biovoima Oy</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.contentBox}>
                                    <Grid container direction='column' alignItems='center' className={classes.relativeContainer}>
                                        <Grid item className={classes.absoluteContainer}>
                                            <Box className={classes.iconBox}>
                                                <FormatQuoteIcon color='primary' />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box className={classes.avatarBox}>
                                                <Avatar
                                                    alt='Ilkka Ahola'
                                                    src='https://media-exp1.licdn.com/dms/image/C4D03AQHYl4kRyodqgg/profile-displayphoto-shrink_100_100/0/1553520372331?e=1636588800&v=beta&t=FKgn8FVg5Bw3aYSvT-W_ChZBXWfpPi0Ne6Qzrphu93k'
                                                    className={classes.avatar}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box textAlign='left'>
                                        <Typography className={classes.review}>
                                            Granted AI on selkeä ja helppokäyttöinen työkalu, joka tiivistetysti kokoaa perustiedot eri
                                            tukivaihtoehdoista. Työkalu myös havainnollisesti linjaa, mitkä tuet voisivat oletusarvoisesti
                                            olla yrityksen haettavissa ja hyödynnettavissä.
                                        </Typography>
                                    </Box>
                                    <Typography className={classes.name}>Ilkka Ahola</Typography>
                                    <Typography className={classes.company}>Toimitusjohtaja, Solution Compass Oy</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography className={classes.grants}>{intl.formatMessage({ id: 'common.grants' })}</Typography>
            </Grid>
        </Grid>
    );
};

export default Slide9;
