import * as _ from 'lodash';
import moment from 'moment';

const getMonths = momentDate => {
	// get months function for getting month number according the year
	const months = Number(momentDate.format('MM'));
	const years = Number(momentDate.format('YYYY'));

	return months + years * 12;
};

/**
 * Mutates the timeline object and loop instruments for date and month calculation
 * @param {'Timeline object from the service'} timeline
 * @returns
 */
export const getStartDateArr = (projects = [], timeline = {}) => {
	try {
		if (projects.length === 0) return { projects, timeline };

		const start = _.map(projects, item => item.startDate); // map timelime instruments first dates
		const end = _.map(projects, item => item.endDate); // map timeline instruments last dates

		const minDate = moment(_.min(start)); // get min val from the array
		const maxDate = moment(_.max(end)); // get max val from the array

		const startDate = minDate.startOf('year'); // only full years: first of january
		const endDate = maxDate.endOf('month'); // only full years: last of december
		const currentDate = moment();

		const comparedEndDate = moment(endDate).isBefore(currentDate, 'year'); // compare by year
		const handledEndDate = comparedEndDate ? currentDate : endDate; // larger of compared

		let numberOfMonth = handledEndDate.diff(minDate, 'month') + 1; // get number of months from years in timeline

		while (numberOfMonth % 3 !== 0) {
			numberOfMonth += 1;
		}

		let dynamicTimeline = { ...timeline };

		dynamicTimeline.startDate = startDate;
		dynamicTimeline.endDate = handledEndDate;
		dynamicTimeline.numberOfMonth = numberOfMonth;

		const updatedProjects = projects.map(project => {
			// mutate the project --> TODO: may be add some check if monthDuration/ monthFromStart values don't exist
			const startMonth = getMonths(moment(project.startDate));
			const endMonth = getMonths(moment(project.endDate));

			const monthDuration = endMonth - startMonth + 1;
			const monthFromStart = startMonth - getMonths(moment(dynamicTimeline.startDate));

			return { ...project, monthDuration, monthFromStart };
		});

		return { timeline: dynamicTimeline, projects: updatedProjects };
	} catch {
		// continue regardless of error
	}
};

export const handleProject = (project, timeline) => {
	// loop instruments and mutate the object
	const startMonth = getMonths(moment(project.startDate));
	const endMonth = getMonths(moment(project.endDate));

	const monthDuration = endMonth - startMonth + 1;
	const monthFromStart = startMonth - getMonths(moment(timeline.startDate));

	return { ...project, monthDuration, monthFromStart };
};
