import { useIntl } from 'react-intl';

import { Button, Stack } from '@mui/material';

import ModalDialog from 'components/Dialog/ModalDialog';
import { AIInstrumentContentGenerator } from '../AIContextSelector/AIInstrumentContentGenerator';
import AiGeneratorWrapper from './AiGeneratorWrapper';

const AiGeneratorDialog = ({ handleAiGenerateResponse, project, instrumentId, modalOpen, setModalOpen, instrumentPromptId }: any) => {
	const intl = useIntl();

	return (
		<>
			<ModalDialog
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				title={intl.formatMessage({ id: 'customer.page.modal.add.new.company' })}
				actions={
					<Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
						<Button variant='text' color='primary' onClick={() => setModalOpen(false)}>
							{intl.formatMessage({ id: 'common.close' })}
						</Button>
					</Stack>
				}
			>
				{instrumentId ?? project?.instrumentId}
				<AiGeneratorWrapper>
					<AIInstrumentContentGenerator
						handleResponse={handleAiGenerateResponse}
						chatId={project?.projectId}
						instrumentId={instrumentId}
						instrumentPromptId={instrumentPromptId}
					/>
				</AiGeneratorWrapper>
			</ModalDialog>
		</>
	);
};

export default AiGeneratorDialog;
